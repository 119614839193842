import { CHANGE_CHARACTER, PANEL_SUCCESS, ADD_CHARACTER_PANEL_SUCCESS, LOAD_TILES_SUCCESS, ADD_TILE } from "../actionTypes";
import { PANEL_WIDTH, PANEL_HEIGHT } from '../../services/geography';
import tileMap from '../../services/tile-map';

const initialState = {
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_CHARACTER: {
      return {
        ...initialState
      }
    }

    case PANEL_SUCCESS: {
      const panel = action.payload;

      const updatedState = { ...state }
      delete updatedState.characterPanel

      return {
        ...updatedState,
        ...panel,
        // neighbours: JSON.parse(panel.neighbours),
        floorLayer: getFloorLayerFromPanel(panel)
      };
    }

    case ADD_TILE :{
      const tile = action.payload;

      if (tile.tileType.name !== 'IRRIGATION_CHANNEL') {
        return { ...state }
      }

      const panel = { ...state }

      panel.floorLayer.push({
        x: tile.position.x,
        y: tile.position.y,
        tileType: 'WATER',
        id: 'river',
      })

      drawNeighbours(panel.floorLayer, 'WATER', tileMap['WATER.LEFT.RIGHT.'], { isDiagonalRelevent: true, connectOnBorder: true });

      return {
        ...panel,
      };
    }

    case LOAD_TILES_SUCCESS: {
      const panel = { ...state }

      const irrigationChannels = action.payload.filter(tile => (tile.tileType?.name === 'IRRIGATION_CHANNEL')).map(tile => ({ ...tile, x: tile.position.x, y: tile.position.y }));

      irrigationChannels.forEach(({ x, y }) => {
        panel.floorLayer.push({
          x,
          y,
          tileType: 'WATER',
          id: 'river',
        })
      })

      drawNeighbours(panel.floorLayer, 'WATER', tileMap['WATER.LEFT.RIGHT.'], { isDiagonalRelevent: true, connectOnBorder: true });

      return {
        ...panel,
      };
    }

    case ADD_CHARACTER_PANEL_SUCCESS: {
      const characterPanel = action.payload;

      return {
        ...state,
        characterPanel
      }
    }
    default:
      return state;
  }
}

function getBit(bytes, index) {
  const shiftAmount = (PANEL_WIDTH * PANEL_HEIGHT) - index;

  return Number(BigInt('0b' + bytes) >> BigInt(shiftAmount) & BigInt(1))
}

export function getFloorLayerFromPanel(panel) {
  if (!panel.cliffs && !panel.oceans && !panel.rivers) {
    return [];
  }
  const cliffs = BigInt('0x' + panel.cliffs).toString(2);
  const oceans = BigInt('0x' + panel.oceans).toString(2);
  const rivers = BigInt('0x' + panel.rivers).toString(2);

  const floorLayer = []

  for (var i=0; i < (PANEL_WIDTH * PANEL_HEIGHT); i++) {
    const tile = {};

    const isCliff = getBit(cliffs, i + 1)
    const isRiver = getBit(rivers, i + 1)
    const isOcean = getBit(oceans, i + 1)

    if (isCliff && panel.elevation < 150) {
      tile.tileType = 'Cliff'
    } else if (isCliff && panel.elevation >= 150) {
      tile.tileType = 'MOUNTAIN'
    }

    if (isRiver) {
      tile.tileType = 'WATER'
    }

    if (isOcean) {
      tile.tileType = 'OCEAN'
    }

    if (tile.tileType) {
      const x = (i % PANEL_WIDTH);
      const y = Math.floor(i / PANEL_WIDTH);

      floorLayer.push({
        x,
        y,
        tileType: tile.tileType,
        id: isCliff ? 'cliff' : isRiver ? 'river' : 'ocean'
      })
    }
  }

  drawNeighbours(floorLayer, 'WATER', tileMap['WATER.LEFT.RIGHT.'], { isDiagonalRelevent: true, connectOnBorder: true });

  if (panel.elevation >= 150) {
    drawNeighbours(floorLayer, 'MOUNTAIN', tileMap['MOUNTAIN.LEFT.RIGHT.'], { isDiagonalRelevent: false, connectOnBorder: true });
  }

  return floorLayer;
}

export function drawNeighbours(layerData, tileKey, index, options) {
  const baseTileKey = tileKey + '.';

  layerData.forEach((tile) => {
    if (tile.tileType !== tileKey) {
      return;
    }

    let newTileKey = baseTileKey;

    let top = layerData.find(leftTile => (leftTile.x === tile.x && leftTile.y === tile.y - 1))
    let bottomLeft = layerData.find(leftTile => (leftTile.x === tile.x - 1 && leftTile.y === tile.y - 1))
    let bottomRight = layerData.find(leftTile => (leftTile.x === tile.x + 1 && leftTile.y === tile.y - 1))

    let left = layerData.find(leftTile => (leftTile.x === tile.x - 1 && leftTile.y === tile.y))
    let right = layerData.find(leftTile => (leftTile.x === tile.x + 1 && leftTile.y === tile.y))

    let bottom = layerData.find(leftTile => (leftTile.x === tile.x && leftTile.y === tile.y + 1))
    let topLeft = layerData.find(leftTile => (leftTile.x === tile.x - 1 && leftTile.y === tile.y + 1))
    let topRight = layerData.find(leftTile => (leftTile.x === tile.x + 1 && leftTile.y === tile.y + 1))

    if (options?.connectionTileIndex && (left?.tileType === options?.connectionTileIndex || right?.tileType === options?.connectionTileIndex || top?.tileType === options?.connectionTileIndex || bottom?.tileType === options?.connectionTileIndex)) {
      newTileKey += 'CONNECTED.';
    }

    if (left?.tileType === tileKey) {
      newTileKey += 'LEFT.';
    }

    if (right?.tileType === tileKey) {
      newTileKey += 'RIGHT.';
    }

    if (top?.tileType === tileKey) {
      newTileKey += 'TOP.';
    }

    if (bottom?.tileType === tileKey) {
      newTileKey += 'BOTTOM.';
    }

    if (options?.isDiagonalRelevent) {
      let isGapAdded = false;

      if (right?.tileType === tileKey && top?.tileType === tileKey) {
        if (bottomRight?.tileType !== index && options?.startingIndexes?.indexOf(bottomRight?.tileType) === -1 && (!options?.diagonalIndexes || options?.diagonalIndexes?.indexOf(bottomRight?.tileType) === -1)) {
          newTileKey += 'TR-'
          isGapAdded = true;
        }
      }

      if (right?.tileType === tileKey && bottom?.tileType === tileKey) {
        if (topRight?.tileType !== index && options?.startingIndexes?.indexOf(topRight?.tileType) === -1 && (!options?.diagonalIndexes || options?.diagonalIndexes?.indexOf(topRight?.tileType) === -1)) {
          newTileKey += 'BR-'
          isGapAdded = true;
        }
      }

      if (left?.tileType === tileKey && bottom?.tileType === tileKey) {
        if (topLeft?.tileType !== index && options?.startingIndexes?.indexOf(topLeft?.tileType) === -1 && (!options?.diagonalIndexes || options?.diagonalIndexes?.indexOf(topLeft?.tileType) === -1)) {
          newTileKey += 'BL-'
          isGapAdded = true;
        }
      }

      if (left?.tileType === tileKey && top?.tileType === tileKey) {
        if (bottomLeft?.tileType !== index && options?.startingIndexes?.indexOf(bottomLeft?.tileType) === -1 && (!options?.diagonalIndexes || options?.diagonalIndexes?.indexOf(bottomLeft?.tileType) === -1)) {
          newTileKey += 'TL-'
          isGapAdded = true;
        }
      }

      if (isGapAdded) {
        newTileKey += 'GAP'
      }
    }

    if (newTileKey === baseTileKey) {
      newTileKey += 'NONE.'
    }

    tile.tileKey = newTileKey;
  });
}

function isEmptyIndex() {
  return false;
}