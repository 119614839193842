export const snapjawFaceSentences = [
    "A large, frightening face with many eyes and mouths",
    "A face that is dripping with blood and saliva",
    "A face that is contorted in a demonic grin",
    "A face that is shrouded in darkness",
    "A face with bulging, bloodshot",
    "this monster's face is horrifying to behold",
    "A truly nightmarish creature, this monster's face is a mass ofundulating flesh",
    "patches of oozing, raw flesh",
    "burnt, blackened skin",
    "sickly pallor",
    "swollen, bulging eyes",
    "sharp, jagged teeth",
    "Growling loudly",
    "drooling viscous saliva",
    "quivering, muscular form",
    "A monstrous face with a wide open mouth full of sharp teeth",
    "Glowing red eyes that stare deep into your soul",
    "Large horns that curl back from its forehead",
    "A face that is half-human, half- beast",
    "Ominous and foreboding, it's face is one that is sure to send a chill down your spine",
]

export const snapjawBodySentences = [
	"A body that fills a space with its presence",
    "A body that looms over everything",
    "A body that is an impenetrable mass",
    "Vast and all-consuming",
    "Endless and unending",
    "Without mercy or pity",
    "Only hunger and death",
    "An unquenchable thirst for blood",
    "A maw that never stops devouring",
    "Disgusting, mutated features that are barely recognizable as human",
    "Bulging muscles that strain against the creature's skin",
    "Unrivaled in size",
    "Dominant in nature",
    "Gigantic in build",
    "Enormous body that intimidates all who look upon it",
    "Muscular frame with ample amounts of fur that seems to always be ready to pounce",
]

export const snapjawSoundWords = [
	"Roaring",
    "screeching",
    "hissing",
    "growling",
    "bellowing",
    "thunderous",
    "ear-piercing",
    "deafening",
    "earth-shattering",
    "bone-crushing",
    "Roaring",
    "bellowing",
    "screeching",
    "howling",
    "yowling",
    "crying",
    "wailing",
    "yelling",
    "screaming",
    "shrieking",
    "devastating",
    "destructive",
    "ruinous",
    "catastrophic",
    "cataclysmic",
    "implosive",
    "explosive",
    "deafening",
    "disorienting",
    "blinding",
    "Rumbling",
    "Snarling",
    "Smacking",
    "Trembling",
    "Growling",
    "Rasping",
    "Bellowing",
    "Roaring",
    "Charging",
    "Thrashing",
    "clawing",
    "stomping",
    "thundering",
    "roaring",
    "bellowing",
    "crashing",
    "smashing",
    "bashing",
    "breaking"
]

export const snapjawFurWords = [
        "Soft",
    "Thick",
    "luxurious",
    "downy",
    "velvety",
    "shaggy",
    "fuzzy",
    "woolly",
    "hairy",
    "furry",
    "dense",
    "fluffy",
    "thick",
    "course",
    "luxurious",
    "downy",
    "furry",
    "soft",
    "velvety",
    "supple",
    "Soft",
    "fluffy",
    "thick",
    "warm",
    "silky",
    "smooth",
    "luxurious",
    "dense",
    "furry",
    "hairy",
    "furry",
    "hairy",
    "scaly",
    "slippery",
    "slimy",
    "prickly",
    "hard",
    "tough",
    "sticky",
    "smooth",
    "Fluffy",
    "soft",
    "smooth",
    "warm",
    "wet",
    "dry",
    "itchy",
    "scaly",
    "hard"
]

export const snapjawSmellWords = [
   "Pungent",
    "acrid",
    "rank",
    "fetid",
    "putrid",
    "malodorous",
    "reeky",
    "noisome",
    "stinking",
    "stenchy",
    "Intoxicating",
    "Pungent",
    "Thick",
    "suffocating",
    "noxious",
    "cloying",
    "thick",
    "nauseating",
    "eye-watering",
    "overwhelming",
    "Overpowering",
    "Pungent",
    "Acrid",
    "Nauseating",
    "Eye-watering",
    "Sickening",
    "Fetid",
    "putrid",
    "rank",
    "foul",
    "Pungent",
    "Rancid",
    "Ammonia-like",
    "Eye-watering",
    "Stomach-churning",
    "Putrid",
    "Foul",
    "disgusting",
    "revolting",
    "vile",
    "Disgusting",
    "Nauseating",
    "Stomach-turning",
    "Repulsive",
    "Revolting",
    "Sickening",
    "vomit-inducing",
    "retch-inducing",
    "puke-inducing",
    "gut-wrench"
]

export const snapjawBehaviourSentences = [
	"it is glaring at you with its beady eyes",
"it is slowly stalking towards you",
"it is drooling all over its razor sharp teeth",
"it is making a strange hissing noise",
"it is staring intently at you from afar",
"it is seemingly unaware of your presence but slowly, inexorably approaching",
"it is eyes alight with hunger as it moves closer and closer",
"it is watching you with its eerie, soulless eyes",
"it is glaring at you with its beady eyes",
"it is flicking its tongue out at you",
"it is hissing at you",
"it is uncoiling its body",
"it is giving off a strange scent",
"it is making a weird noise",
"it is leaving a trail of slime behind it",
]