import seedrandom from 'seedrandom';

export const colours = [
	'Red',
	'Yellow',
	'Green',
	'Blue',
	'Orange',
	'Indigo',
	'Violet',
	'Brown',
	'Black',
	'White',
	'Grey',
	'Navy',
	'Purple',
	'Lime',
	'Lemon',
]

const sizes = [
	'Large',
	'Small',
	'Medium',
	'Tiny',
	'Huge',
]

const shapes = [
	'Long',
	'Wide',
	'Crooked',
	'Knobbly',
]

const thicknesses = [
	'Thick',
	'Skinny',
]

const smells = [
	'Sweet smelling',
	'Acrid smelling',
]

const sounds = [
	'giggling',
	'rasping',
	'scraping'
]

export const getKeyDescription = (_id) => {
	const MAX_KEY_DESCRIPTION_LENGTH = 20;

	const seed = seedrandom(`key-description ${_id}`);

	const colour = getRandomItemFromArray(colours, seedrandom(`key-description colour ${_id}`)).toLowerCase();
	const size = getRandomItemFromArray(sizes, seedrandom(`key-description size ${_id}`)).toLowerCase();
	const shape = getRandomItemFromArray(shapes, seedrandom(`key-description shape ${_id}`)).toLowerCase();
	const thickness = getRandomItemFromArray(thicknesses, seedrandom(`key-description thickness ${_id}`)).toLowerCase();
	const smell = getRandomItemFromArray(smells, seedrandom(`key-description smell ${_id}`)).toLowerCase();
	const sound = getRandomItemFromArray(sounds, seedrandom(`key-description sound ${_id}`)).toLowerCase();

	const strings = [
		`${size} ${thickness} ${colour}`,
		`${size} ${colour}`,
		`${colour}`,
		`${size} ${thickness}`,
		`${size}`,
		`${size} ${sound}`,
		`${sound} ${colour}`,
		`${size} ${sound}`,
		`${thickness} ${sound} ${colour}`,
		`${thickness} ${colour}`,
		`${shape} ${colour}`,
		`${size} ${shape}`,
		`${shape} ${sound} ${colour}`,
		`${shape} ${colour}`,
		`${smell} ${colour}`,
		`${smell}`,
		`${smell} ${colour}`,
		`${shape} ${smell}`,
	];

	const shortStrings = strings.filter(string => (string.length <= MAX_KEY_DESCRIPTION_LENGTH));

	return getRandomItemFromArray(shortStrings, seed);
}

function getRandomItemFromArray(items, seed) {
    // const randomNumber = seed();
    return items[Math.floor(seed() * items.length)];
}