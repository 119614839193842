import {
    GET_COIN_RECIPES_LOADING,
    GET_COIN_RECIPES_SUCCESS,
    GET_COIN_RECIPES_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';

export const getCoinRecipesSuccess = payload => ({
    type: GET_COIN_RECIPES_SUCCESS,
    payload
})

export const getCoinRecipesFailure = () => ({
    type: GET_COIN_RECIPES_FAILURE
})

export const getCoinRecipesAsync = payload => dispatch => {
    return dispatch(getRecursivelyAsync('coin-recipes', getCoinRecipesSuccess, 0));
}

export const createCoinRecipeAsync = payload => dispatch => {
    const { name, coinTypeId, quantity, ingredients } = payload;

    return client.service('coin-recipes').create({
        quantity,
        name,
        coinTypeId,
        ingredients
    })
        .then((response) => {
            console.log('response: ', response);
            return response
        })
        .catch((error) => {
            console.error('error', error);
        })
}