import {
    CREATE_ORGANISATION_LOADING,
    CREATE_ORGANISATION_SUCCESS,
    CREATE_ORGANISATION_FAILURE,
    GET_ORGANISATIONS_LOADING,
    GET_ORGANISATIONS_SUCCESS,
    GET_ORGANISATIONS_FAILURE,
    ADD_ORGANISATION,
    UPDATE_ORGANISATION,
    REMOVE_ORGANISATION,
    CLEAR_ORGANISATIONS
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

export const createOrganisationSuccess = payload => ({
    type: CREATE_ORGANISATION_SUCCESS,
    payload
})

export const createOrganisationFailure = () => ({
    type: CREATE_ORGANISATION_FAILURE
})

export const createOrganisationAsync = payload => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'organisations',
        name: 'create',
        data: {
            ...payload
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getOrganisationsSuccess = payload => {
    return ({
        type: GET_ORGANISATIONS_SUCCESS,
        payload
    })
}

export const getOrganisationsFailure = () => ({
    type: GET_ORGANISATIONS_FAILURE
})

export const addOrganisation = payload => {
    return ({
        type: ADD_ORGANISATION,
        payload
    })
}

export const updateOrganisationsSuccess = payload => {
    return ({
        type: UPDATE_ORGANISATION,
        payload
    })
}

export const updateOrganisation = payload => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'organisations',
        commandType: 'patch',
        data: {
            ...payload
        }
    })
}

export const removeOrganisationSuccess = payload => {
    return ({
        type: REMOVE_ORGANISATION,
        payload
    })
}

export const deleteOrganisationAsync = payload => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'organisations',
        commandType: 'remove',
        data: {
            ...payload
        }
    })
}

