import { GET_FISH_SUCCESS, HARVEST_FISH_SUCCESS, ADD_FISH, UPDATE_FISH, REMOVE_FISH } from "../actionTypes";

const initialState = {
  fish: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_FISH_SUCCESS: {
      const minerals = action.payload;

      return {
        ...state,
        fish: [...action.payload],
      }
    }

    case ADD_FISH: {
      return {
        ...state,
        fish:[ ...state.fish, action.payload ]
      }
    }

    case UPDATE_FISH: {
      return {
        ...state,
        fish: [
          ...state.fish.filter(tool => tool._id !== action.payload._id),
          { ...state.fish.find(tool => tool._id === action.payload._id), ...action.payload }
        ],
      }
    }

    case REMOVE_FISH: {
      return {
        ...state,
        fish: [ ...state.fish.filter(tool => (tool._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
