import { GET_CONSTRUCTION_RECIPES_SUCCESS } from "../actionTypes";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CONSTRUCTION_RECIPES_SUCCESS: {
      const constructionRecipes = action.payload;

      console.log(action.payload);

      if (!constructionRecipes){
        return [...state];
      }
      
      return [...state, ...action.payload]
    }

    default:
      return state;
  }
}
