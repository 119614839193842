import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import { newOrganisation, showOrganisation, disableKeyboardMovement, enableKeyboardMovement, showHelp, startLooking, hideAllMenus } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectCharacter,
    selectCharacters,
    selectOrganisations,
    selectCharacterOrganisation
} from '../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import { createOrganisationAsync } from '../../redux/actions/organisation.actions'

import Menu from '../utils/menu/Menu';
import TextInput from '../utils/text-input/TextInput';

import { client } from '../../services/client';

// import './Diary.css';

class OrganisationList extends React.Component {
    state = {
        navigationHandler: this.navigationHandler.bind(this),
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.state.navigationHandler)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.state.navigationHandler);
    }

    navigationHandler(event) {
        if (this.state.isSetTextShowing || this.state.isMemberSelectShowing) {
            return;
        }

        if (event.key === 'n') {
            this.props.newOrganisation()
            return;
        }
    }

    onOrganisationChosen(option) {
    	console.log('option: ', option)
    	this.props.showOrganisation({ organisationId: option._id })
    }

    render() {
        return (
            <div className="conversation-tree-wizard">
                <div className="conversation-tree-wizard-container">
                    <Menu options={this.props.organisations.map(organisation => ({ ...organisation, text: organisation.name, hint: ` ${organisation.membershipLevel}` }))}
                        menuContainer="conversation-tree-wizard-container"
                        optionChosen={this.onOrganisationChosen.bind(this)}
                    />
                </div>
                <div className="conversation-tree-actions-container">
                    <p><span className="action">N</span> - New organisation</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const characters = selectCharacters(state);
    const character = selectCharacter(state);
    const organisations = selectOrganisations(state);

    const adminOrganisations = organisations.filter(org => (org.adminIds?.find(adminId => (adminId === character._id))))
    const modOrganisations = organisations.filter(org => (org.modIds?.find(modId => (modId === character._id))))
    const memberOrganisations = organisations.filter(org => (org.memberIds?.find(memberId => (memberId === character._id))))
    const inviteeOrganisations = organisations.filter(org => (org.inviteeIds?.find(memberId => (memberId === character._id))))

    const anyMemberTypeOrganisations = [
    	...adminOrganisations.map(org => ({ ...org, membershipLevel: 'Admin' })),
    	...modOrganisations.map(org => ({ ...org, membershipLevel: 'Mod' })),
    	...memberOrganisations.map(org => ({ ...org, membershipLevel: 'Member' }))
	]

	const invitedMemberOrganisations = [
		...inviteeOrganisations.map(org => ({ ...org, membershipLevel: 'Invited' })),
	]

    const noneOrganisations = organisations
    	.filter(org => (![...anyMemberTypeOrganisations, ...invitedMemberOrganisations].find(candidateOrg => (candidateOrg._id === org._id))))
    	.map(org => ({ ...org, membershipLevel: 'None Member' }))

    return {
        characters: characters.map(character => ({ ...character, text: character.name })),
        organisations: [ ...anyMemberTypeOrganisations, ...invitedMemberOrganisations, ...noneOrganisations ]
    }
}

export default connect(
    mapStateToProps,
    { hideAllMenus, newOrganisation, showOrganisation }
)(OrganisationList);