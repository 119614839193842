import {
    GET_SKILL_TYPES_SUCCESS,
    GET_SKILL_TYPES_FAILURE,
} from '../actionTypes';
import { clearAllTiles, loadTilesSuccess } from './tile.actions';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';

import { client } from '../../services/client';

export const getSkillTypesSuccess = payload => {
    return ({
        type: GET_SKILL_TYPES_SUCCESS,
        payload
    })
}

export const getSkillTypesFailure = payload => ({
    type: GET_SKILL_TYPES_FAILURE
})

export const getSkillTypesAsync = payload => dispatch => {
    return client.service('skill-types').find()
        .then((response) => {
            dispatch(getSkillTypesSuccess(response));
            return response;
        })
        .catch((error) => {
            console.error('error', error);
            dispatch(showUnknownError({ message: error.message }));
        })
}