export const beanSmellTypes = [
    "Nutty",
    "Earthy",
    "Woody",
    "Floral",
    "Fruity",
    "Bright",
    "Dank",
    "Pungent",
    "Skunky",
    "Sweaty",
    "floral",
    "musky",
    "sweet",
    "woody",
    "fresh",
    "herbal",
    "Creamy",
    "Nutty",
    "Sweet",
    "Spicy",
    "Floral",
    "Fruity",
    "of baneberry",
    "Pungent",
    "Musky",
    "Dank",
    "Moist",
    "Humid",
    "Light",
    "Floral",
    "Sweet",
    "Intense",
    "Musty",
    "Damp",
    "Earthy",
    "lactonic and fresh",
    "of musk and melon",
    "floral and vegetal",
    "strange and familiar, like aniseed",
    "of off tea",
    "humid and floral",
    "of sharp sweets",
    "of anise and leafy greens",
    "of watered down milk",
    "plastic and smoky",
    "of an open green space at dusk",
    "of burning candy",
    "of sticky hands",
    "of soft spiciness",
    "invitingly sweet",
    "of new vines",
    "of trodden flowers underfoot",
    "of sappy, broken stems",
    "anise and aquatic",
    "of stretched rubber",
    "of spiced heavy cream",
    "of penned animals",
]

export const beanBranchTypes = [
    "Vibrant",
    "Lush",
    "Verdant",
    "vines running along its",
    "sticky",
    "sweaty",
    "green",
    "lush",
    "Extraneous",
    "bushy",
    "verdure",
    "overgrown",
    "leafy",
    "unkempt",
    "sprawling",
    "wild",
    "rampant",
    "unchecked",
    "leafy",
    "Dangling",
    "woody",
    "dark green leaves and white flowers on its",
    "vining",
    "tall",
    "climbing",
    "opportunistic",
    "long pods dangling from its",
    "flat pods hanging from its",
    "colourful beans tucked in pods borne on its",
    "sparsely pilose",
]

export const beanLeafTypes = [
    "speckled",
    "variegated",
    "robust",
    "pristine",
    "Vibrant",
    "Lush",
    "Verdant",
    "smooth-edged",
    "oval",
    "alternate",
    "plain",
    "simple",
    "ovate",
    "bushy",
    "trifoliate",
    "unfurling",
    "happy",
    "insect-bitten",
    "spiralling",
    "jagged",
    "crinkled",
    "heart-shaped",
    "bumpy",
    "veined"
]