export const tumblesticksSmellTypes = [
	"of filthy water",
    "of dank air",
    "Sour",
    "putrid",
    "rank",
    "fetid",
    "foul",
    "stinking",
    "reeking",
    "malodorous",
    "putrescent",
    "of tilled soil",
    "Sweet",
    "of stale beer",
    "of stewed cabbage",
    "of wet dog",
    "of sour milk",
    "of cheese gone off",
    "of Ginger",
    "of Musk",
    "of Cinnamon",
    "of sweet cane",
    "of sugarcane",
    "of honey plant",
    "of molasses",
    "of sugar",
    "of wine",
    "of mentholic mineral",
    "energising",
    "of nature recycling human work",
    "of mildewed ruins",
    "of green decay",
    "of a verdant orangery",
    "of a louche domicile",
    "of fertile loam",
    "of brandy",
    "of tart fruit and chypre",
    "distilled",
    "of wet boletus",
    "of petrichor and synthetic flowers",
    "of false grass",
    "of quiet concentration",
    "of distilled, prepared air",
    "of archived pains",
    "of domestic waste",
    "of crisp tajin",
    "of condensation on stems",
    "of sugar-gorged bacteria",
    "of a sunken riverbed",
    "of rust and tar",
    "of marine sediment",
]

export const tumblesticksBranchTypes = [
	"wire coil",
    "Woody",
    "Seedy",
    "Thorny",
    "sturdy",
    "smooth",
    "brittle",
    "Prickly",
    "spiky",
    "barbed",
    "needle-like",
    "jagged",
    "rough",
    "uneven",
    "hollow",
    "dry and brittle",
    "ankle-tickling",
    "wind-riding",
    "opportunistic",
    "ready to break",
    "styrofoam",
    "seed-bursting",
    "light",
    "wind-blown",
    "unrooted",
    "whorl-patterned",
    "empty",
    "barren",
    "sand-covered",
    "wax dripping from its",
    "seed-pods juggled in its",
    "animated",
    "dancing",
    "fresh",
    "cloth scraps snagged on its",
    "bright copper",
    "sure",
    "pipe-like",
    "fluted",
    "delicate",
    "inexhaustible",
    "never still",
    "tiny bones collected under its"
    "heddle-rod",
]

export const tumblesticksLeafTypes = [
	"pincushion",
    "Windswept",
    "Tumble",
    "Sandybloom",
    "prickly",
    "Frosty",
    "Gentle rain",
    "lackadaisical",
    "lazy",
    "playful",
    "frisky",
    "serrated",
    "noxious",
    "illusory",
    "tripointed",
    "bugle-shaped",
    "slowly unfurling",
    "finger-like",
    "twirling",
    "streamer-like",
    "nibbled-on",
    "lightly patterned",
    "dust-trimmed",
    "ember-coloured",
    "mollusks hidden in the eaves of its",
    "laughing",
    "flickering",
    "mocking",
    "bobbing and swaying",
    "spindly",
    "twill",
    "taut",
    "recently grazed upon",
    "insect stowaways under its",
]