import React, { useState } from "react";
import { connect } from 'react-redux'
import { client } from '../../services/client';

import {
    selectCharacter,
    selectCharacters,
    selectNearbyConstructions,
} from '../../redux/selectors';
import { showQuantityInput } from '../../redux/actions/keyboard-shortcuts.actions';

import { updateGuardHouseAsync } from '../../redux/actions/construction.actions';

import QuantityInput from '../utils/quantity-input/QuantityInput';
import Menu from '../utils/menu/Menu';
import DirectionInput from '../utils/direction-input/DirectionInput';

import './GuardHouse.css';

class GuardHouse extends React.Component {

  constructor() {
    super();

    this.state = {
    	handleNavigation: this.handleNavigation.bind(this),
    }
    
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.state.handleNavigation)
  }

  componentDidMount() {
    window.addEventListener('keydown', this.state.handleNavigation);
  }

  async handleNavigation(event) {
    if (event.key === 'w') {
    	// show the characters list menu
    	this.setState({
    		action: 'ADD_CHARACTER_TO_WHITELIST',
    		isCharacterSelectionShowing: true,
    		characterList: this.props.characters
    			.filter(character => (!this.props.guardHouse.whitelistCharacterIds || this.props.guardHouse.whitelistCharacterIds?.indexOf(character._id) === -1))
    			.map(character => ({ ...character, text: character.name }))
    	})
    }

    if (event.key === 'r') {
    	if (!this.props.guardHouse.whitelistCharacterIds) {
    		return
    	}
    	// show the characters list menu
    	this.setState({
    		action: 'REMOVE_CHARACTER_FROM_WHITELIST',
    		isCharacterSelectionShowing: true,
    		characterList: this.props.characters
    			.filter(character => (this.props.guardHouse.whitelistCharacterIds?.indexOf(character._id) > -1))
    			.map(character => ({ ...character, text: character.name }))
    	})
    }

    if (event.key === 'b') {
    	// show the characters list menu
    	this.setState({
    		action: 'ADD_CHARACTER_TO_BLACKLIST',
    		isCharacterSelectionShowing: true,
    		characterList: this.props.characters
    			.filter(character => (!this.props.guardHouse.blacklistCharacterIds || this.props.guardHouse.blacklistCharacterIds?.indexOf(character._id) === -1))
    			.map(character => ({ ...character, text: character.name }))
    	})
    }

    if (event.key === 'f') {
    	if (!this.props.guardHouse.blacklistCharacterIds) {
    		return
    	}
    	// show the characters list menu
    	this.setState({
    		action: 'REMOVE_CHARACTER_FROM_BLACKLIST',
    		isCharacterSelectionShowing: true,
    		characterList: this.props.characters
    			.filter(character => (this.props.guardHouse.blacklistCharacterIds?.indexOf(character._id) > -1))
    			.map(character => ({ ...character, text: character.name }))
    	})
    }

    if (event.key === 'd') {
    	// just do the toggle
    	this.props.updateGuardHouseAsync({
  			...this.props.guardHouse,
  			isForcedWeaponDrop: this.props.guardHouse.isForcedWeaponDrop ? false : true
  		})
    }

    if (event.key === 'p') {
    	// show a number input
    	this.setState({ isQuantityInputShowing: true });

      const price = await new Promise((resolve, reject) => {
          this.onQuantitySupplied = (quantity) => {
              this.setState({isQuantityInputShowing: false})
              resolve(Number(quantity));
          }

          this.onQuantityClosed = () => {
              this.setState({isQuantityInputShowing: false})
              reject('No quantity supplied');
          }
      });

      this.props.updateGuardHouseAsync({
  			...this.props.guardHouse,
  			price
  		})
    }

    if (event.key === 'ArrowUp') {
    	this.setState({ isDirectionInputShowing: true })

    	const direction = await new Promise((resolve, reject) => {
          this.onDirectionSupplied = (direction) => {
              this.setState({isDirectionInputShowing: false})
              resolve(direction);
          }

          this.onDirectionClosed = () => {
              this.setState({isDirectionInputShowing: false})
              reject('No direction supplied');
          }
      });

      this.props.updateGuardHouseAsync({
  			...this.props.guardHouse,
  			direction
  		})
    }
  }

  characterChosen(option) {
	let whitelist = [];

	if (this.props.guardHouse.whitelistCharacterIds) {
		whitelist = [ ...this.props.guardHouse.whitelistCharacterIds ]
	}

	let blacklist = [];

	if (this.props.guardHouse.blacklistCharacterIds) {
		blacklist = [ ...this.props.guardHouse.blacklistCharacterIds ]
	}

	let whitelistNames = [];

	if (this.props.guardHouse.whitelistCharacters) {
		whitelistNames = [ ...this.props.guardHouse.whitelistCharacters ]
	}

	let blacklistNames = [];

	if (this.props.guardHouse.blacklistCharacters) {
		blacklistNames = [ ...this.props.guardHouse.blacklistCharacters ]
	}

  	if (this.state.action === 'ADD_CHARACTER_TO_WHITELIST') {
  		this.props.updateGuardHouseAsync({
  			...this.props.guardHouse,
  			whitelistCharacterIds: [ ...whitelist, option._id ],
  			whitelistCharacters: [ ...whitelistNames, option.name ],
  			blacklistCharacterIds: [],
  			blacklistCharacters: [],
  		})
  	}

  	if (this.state.action === 'REMOVE_CHARACTER_FROM_WHITELIST') {
  		this.props.updateGuardHouseAsync({
  			...this.props.guardHouse,
  			whitelistCharacterIds: whitelist.filter(id => (id !== option._id)),
  			whitelistCharacters: [ ...whitelistNames.filter(name => (name !== option.name)) ],
  			blacklistCharacterIds: [],
  			blacklistCharacters: [],
  		})
  	}

  	if (this.state.action === 'ADD_CHARACTER_TO_BLACKLIST') {
  		this.props.updateGuardHouseAsync({
  			...this.props.guardHouse,
  			whitelistCharacterIds: [],
  			whitelistCharacters: [],
  			blacklistCharacterIds: [ ...blacklist, option._id ],
  			blacklistCharacters: [ ...blacklistNames, option.name ],
  		})
  	}

  	if (this.state.action === 'REMOVE_CHARACTER_FROM_BLACKLIST') {
  		this.props.updateGuardHouseAsync({
  			...this.props.guardHouse,
  			whitelistCharacterIds: [],
  			whitelistCharacters: [],
  			blacklistCharacterIds: blacklist.filter(id => (id !== option._id)),
  			blacklistCharacters: [ ...blacklistNames.filter(name => (name !== option.name)) ],
  		})
  	}

  	this.setState({
  		isCharacterSelectionShowing: false
  	})
  }

  render() {
    return (
    	<div>
    		{ this.state.isCharacterSelectionShowing ? 
    			(
    				<Menu options={this.state.characterList} optionChosen={this.characterChosen.bind(this)} />
				) :
    			(
    				<div className="guard-house">
			    		<div className="top">
					    	<div>Whitelist</div>
					    	<div className={this.props.guardHouse?.whitelistCharacters?.length > 0 ? "list-text" : "explanation-text"}>
						    	{
						    		this.props.guardHouse?.whitelistCharacters?.length > 0 ? (
						    			this.props.guardHouse?.whitelistCharacters.map((characterName, index) => (
						    				<span key={index}>
						    					{characterName},&nbsp;
						    				</span>
					    				))
					    			) : (
					    				<span>No characters on the whitelist</span>
					    			)
						    	}
					    	</div>

					    	<div>Blacklist</div>
					    	<div className={this.props.guardHouse?.blacklistCharacters?.length > 0 ? "list-text" : "explanation-text"}>
						    	{
						    		this.props.guardHouse?.blacklistCharacters?.length > 0 ? (
						    			this.props.guardHouse?.blacklistCharacters.map((characterName, index) => (
						    				<span key={index}>
						    					{characterName},&nbsp;
						    				</span>
					    				))
					    			) : (
					    				<span>No characters on the blacklist</span>
					    			)
						    	}
						    </div>

					    	<div className="weapon-deposit">Is weapon deposit required for entry? - <span className="shortcut">{this.props.guardHouse?.isForcedWeaponDrop ? 'Yes' : 'No'}</span></div>

					    	<div className="weapon-deposit">Direction - <span className="shortcut">{this.props.guardHouse?.direction || 'not set'}</span></div>

					    	<div>Price - <span className="shortcut">{this.props.guardHouse?.price || 0}</span></div>
				    	</div>

				    	{this.state.isQuantityInputShowing &&
                <QuantityInput
                    quantity={this.state.maxQuantity}
                    onQuantitySupplied={this.onQuantitySupplied}
                    onQuantityClosed={this.onQuantityClosed}
                />
              }

              {this.state.isDirectionInputShowing &&
	              <DirectionInput
	              		text={"Side of the door which is 'outside':"}
	                  onDirectionSupplied={this.onDirectionSupplied}
	                  onDirectionClosed={this.onDirectionClosed}
	              />
	            }

				    	<div className="bottom">
				    		<div>
					    		<p><span className="shortcut">W</span> - Add character to whitelist</p>
					    		<p><span className="shortcut">R</span> - Remove character from whitelist</p>
					    		<p><span className="shortcut">B</span> - Add character to blacklist</p>
					    		<p><span className="shortcut">F</span> - Remove character from blacklist</p>
				    		</div>
				    		<div>
					    		<p><span className="shortcut">D</span> - Toggle weapon deposit</p>
					    		<p><span className="shortcut">P</span> - Add/edit price</p>
					    		<p><span className="shortcut">UP</span> - Edit direction</p>
				    		</div>
				    	</div>
    				</div>
				)
    		}
    	</div>
	)
  }
}

const mapStateToProps = state => {
	const character = selectCharacter(state);
	const characters = selectCharacters(state);
	const constructions = selectNearbyConstructions(state, character);

	const guardHouse = constructions.find(construction => (construction.position.x === character.position.x && construction.position.y === character.position.y && construction.construction?.name?.indexOf('Guard House') > -1));

    return {
    	character,
    	characters,
    	guardHouse
    }
}

export default connect(
    mapStateToProps,
    {
      updateGuardHouseAsync,
      showQuantityInput
    }
)(GuardHouse);