export const shortGrassSmellTypes = [
    "of Grit",
    "of arid winds",
    "of Petrified Wood",
    "of Sagebrush",
    "of Juniper",
    "of mud-soaked soil",
    "of aching earth",
    "of Sandy Beach",
    "of a smoldering flame",
    "of Fresh Air",
    "of Gentle Wind",
    "of dewy sunlight",
    "of a humid orchard",
    "of sour people musk",
    "of Nutty Musk",
    "of Wildflowers",
    "of ozone and meadow flowers",
    "of amber-hued earth",
    "of Cut Grass",
    "of simpler times",
    "of trodden stems",
    "of lacquered wood",
    "of clove-studded ebony",
    "of benzoin and cinnamon",
    "of bay and cedar",
    "of mimetic vanilla",
    "of imitation skank",
    "of dark green dry foliage",
    "of brooding energy",
    "of forceful sweets",
    "of green apples",
    "of tart pips",
    "of dust-covered orange peels",
    "real and infinitely dear",
    "of a small to-do",
    "of day beginning to break",
    "steadfast",
    "of foul things forgotten",
    "of housed mollusks",
    "of hungry undergrowth",
    "of boggy pond murk",
    "trampled",
    "high-spirited",
    "of the crawling earth",
    "of roiling life",
    "world-weary",
    "of the folly of life",
    "ephemeral",
    "of an unperishing spring",
    "wind-blown",
    "of dry brushwood",
    "of tamped-down freesia",
    "of sweat and pollen",
    "of undeclared taxes",
    "clove and crushed green",
    "of the atmosphere of a forest, carried on the wind",
    "of an almondine dessert",
    "of musk and leather",
]

export const shortGrassBranchTypes = [

]

export const shortGrassLeafTypes = [
    "Short",
    "Crusty",
    "Dry",
    "Yellow",
    "Dead",
    "spring green",
    "blue green",
    "Decaying",
    "Diseased",
    "Half-Dead",
    "Mouldy",
    "Rotting",
    "unhealthy",
    "desiccated",
    "dried",
    "uncomplicated",
    "jolly",
    "thin",
    "Warped",
    "Dark Green",
    "Thick",
    "Dry",
    "Light",
    "Tall",
    "Lonely",
    "Bumpy",
    "Parched",
    "humble",
    "pleasant",
    "silky",
    "primitive",
    "shorn",
    "trampled-down",
    "mud-caked",
    "tender",
    "humiliated",
    "trusting",
]