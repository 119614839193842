import {
    UPDATE_EMBARK_CHARACTER,
} from '../actionTypes'
import store from '../store';

import { client } from '../../services/client';

export const updateCharacter = payload => {
    return ({
        type: UPDATE_EMBARK_CHARACTER,
        payload
    })
}