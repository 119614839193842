import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_ARMOUR_SUCCESS, ADD_ARMOUR, UPDATE_ARMOUR, REMOVE_ARMOUR, GET_TRADE_ARMOUR_SUCCESS, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  characterArmour: [],
  tileArmour: [],
  tradeArmour: [],
  armour: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState
      }
    }

    case GET_ARMOUR_SUCCESS: {
      const armour = action.payload;

      return {
        ...state,
        armour: [...armour.filter(armourInstance => armourInstance.panelId)],
        characterArmour: [...state.characterArmour, ...armour.filter(armourInstance => armourInstance.characterId)],
        tileArmour: [...armour.filter(armourInstance => armourInstance.tileId)],
      }
    }

    case GET_TRADE_ARMOUR_SUCCESS: {
      const armour = action.payload;

      return {
        ...state,
        tradeArmour: [...armour],
      }
    }

    case ADD_ARMOUR: {
      let characterArmour = [...state.characterArmour];
      let tileArmour = [...state.tileArmour];
      let armour = [...state.armour];

      if (action.payload.characterId) {
        characterArmour = [ ...characterArmour, action.payload ]
      }

      if (action.payload.tileId) {
        tileArmour = [ ...tileArmour, action.payload ]
      }

      if (action.payload.panelId) {
        armour = [ ...armour, action.payload ]
      }

      return {
        ...state,
        characterArmour: [ ...characterArmour ],
        tileArmour: [ ...tileArmour ],
        armour: [ ...armour ],
      }
    }

    case UPDATE_ARMOUR: {
      let characterArmour = [...state.characterArmour];
      let tileArmour = [...state.tileArmour];
      let armour = [...state.armour];

      if (action.payload.characterId) {
        characterArmour = [
          ...characterArmour.filter(armour => armour._id !== action.payload._id),
          { ...characterArmour.find(armour => armour._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.tileId) {
        tileArmour = [
          ...tileArmour.filter(armour => armour._id !== action.payload._id),
          { ...tileArmour.find(armour => armour._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.panelId) {
        armour = [
          ...armour.filter(armour => armour._id !== action.payload._id),
          { ...armour.find(armour => armour._id === action.payload._id), ...action.payload }
        ]
      }

      return {
        ...state,
        characterArmour: [ ...characterArmour ],
        tileArmour: [ ...tileArmour ],
        armour: [ ...armour ],
      }
    }

    case REMOVE_ARMOUR: {
      return {
        ...state,
        characterArmour: [ ...state.characterArmour.filter(armour => (armour._id !== action.payload._id)) ],
        tileArmour: [ ...state.tileArmour.filter(armour => (armour._id !== action.payload._id)) ],
        armour: [ ...state.armour.filter(armour => (armour._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
