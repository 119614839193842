export const needlewoodSmellTypes = [
    "of leaves",
    "of sap",
    "of resin",
    "of dirt",
    "of oil",
    "of tar",
    "of earth",
    "of root",
    "of damp",
    "of mould",
    "of rot",
    "of musk",
    "of dirt",
    "metallic",
    "of rust",
    "of earth",
    "of must",
    "rugged",
    "of roses spun from turpentine",
    "of defiant waters",
    "of boiled red currant",
    "of buttery flowers",
    "of shrill brass instruments",
    "of warm quinoline",
    "of dark styrax",
    "of cinammon and cherry",
    "of painstakingly printed fragmentation",
    "of sweet tea on vinyl",
    "of celery and electronics",
    "of vacuum-sealed stasis",
    "of industrial sleaze",
    "of breaking the assembly line",
    "of lilac and bound hides",
    "of veins and circuitry",
    "freakish",
    "lightning-struck",
    "ossified",
    "of wired memories",
    "of melting machinery",
    "of an unbroken picket line",
    "didactic",
    "of old architecture",
    "of cyber hyacinth"
    "of fruits and solvent",
    "of sweltering skin",
    "computer-generated",
    "of plasticine husks",
    "of slumbering machines of war",
    "of biro on apple peel",
    "feathery tar",
    "of safraline",
]

export const needlewoodBranchTypes = [
	"Sparse",
    "Thin",
    "Tall",
    "Lanky",
    "biting",
    "Wiry",
    "barbed",
    "unkind",
    "brown",
    "green",
    "pointed",
    "long",
    "thin",
    "rigid",
    "blade-like",
    "sharp",
    "pointy",
    "tiny",
    "needle-like",
    "needle",
    "sharp",
    "pointy",
    "long lances on its",
    "thorn laden",
    "dangerous, long",
    "dangerous",
    "trenchant",
    "merciless",
    "pointed barbs along its",
    "thorny",
    "stressed",
    "fast growing",
    "short lived",
    "spine-laced",
    "mildew-coloured",
    "grey",
    "spiralling",
    "segmented",
    "smooth",
    "powdery",
    "hostile",
    "abrasive",
    "treacherous",
    "spear-stricking",
    "battle",
]

export const needlewoodLeafTypes = [
	"tawny",
    "thin",
    "pointed",
    "sparse",
    "smooth",
    "dry",
    "brittle",
    "frail",
    "soft",
    "flame-like",
    "twice compound",
    "thick",
    "thin",
    "Dry",
    "Thin",
    "Smooth",
    "Long",
    "Soft",
    "Sharp",
    "vibrant",
    "creamy flowers under its",
    "Yellow",
    "marred",
    "oily",
    "black",
    "green",
    "gold",
    "verdure",
    "needles",
    "long",
    "soap-like",
    "pointy",
    "sharp",
    "fan-like",
    "stick-like",
    "thin",
    "xerophytic",
    "papery",
    "elliptical",
    "more long than wide",
    "flattened",
    "rather small",
    "rather unassuming",
]