import React from "react";
import { connect } from 'react-redux'

import { serialize } from '../side-bar/looking/Looking';

import ImageSurface from '../edit-drawing-surface/ImageSurface'

import './ItemDescription.css';

class ItemDescription extends React.Component {

    constructor() {
        super();

        this.state = {}
    }

    render() {
        return (
            <div className={(this.props.item?.graffiti || this.props.item?.pixelsArray) ? "bottom-ting" : "hidden"}>
                {(this.props.item?.pixelsArray && this.props.item?.pixelsArray.length > 0)? (
                    <ImageSurface pixelsArray={this.props.item?.pixelsArray} width={this.props.item?.width || 20} height={this.props.item?.height || 15} sizeUnit="ch"/>
                ) : (
                    <div className="bottom-ting-inner">{this.props.item?.graffiti ? serialize({ children: this.props.item?.graffiti }) : ''}</div>
                )}
            </div>
        )
    }
}

const mapToStateProps = state => {
    return {}
}

export default connect(
    mapToStateProps,
    {
        
    }
)(ItemDescription);