export const hareFaceSentences = [
"its face is dominated by a pair of long, spiraling horns.",
"its face is smeared with blood, and its horns are dripping with it.",
"Devilish good looks.",
"Crafty eyes.",
"Mischievous grin.",
"playfully wicked.",
"Flashing eyes.",
"full of life.",
"Pointed, perky ears",
"A long, slender face",
"A short, stubby nose",
"Two large, black eyes",
"A wide, gaping mouth",
"A pair of long, horns",
"it face is slightly triangular in shape.",
"It has two small, black eyes.",
"Its nose is long and pointed.",
"It has two large, furry ears.",
"Two long, furry horns protrude from the top of its head.",
"Its long ears droop down, framing a face with a dark brown fur.",
"Two large horns protrude from the top of its head, and its bright yellow eyes seem almost to be grinning.",
]

export const hareBodySentences = [
"Fluffy ears adorned with black tufts",
"A long, slender body highlighted by a creamy coat",
"Sleek muscles rippling beneath the surface",
"A short, stubby tail that is barely noticeable",
"There is a black tuft on the end of its tail.",
"It has long ears which are black on the tips.",
"two short, curved horns protruding from its head",
"long, powerful hind legs for leaping",
"long twitching ears",
"beady black eyes",
"shy and timid creature",
"its body is small and fragile",
"its fur is a light brown, almost blond",
"it has large, prominent eyes",
"its ears are long and thin",
"its nose is short and stubby",
"it has a short, fluffy tail",
]

export const hareSoundWords = [
"Whistling", "honking", "bleating", "trilling", "howling", "roaring", "bugling", "braying", "yelping", "yipping",
"screeching", "flailing", "yelping", "kicking", "high-pitched", "frantic", "wild", "uncontrolled", "desperate", "maniacal",
]

export const hareFurWords = [
    "Wispy",
    "Fluffy",
    "Downy",
    "Furry",
    "Hairy",
    "Soft",
    "Velvety",
    "Fluffy",
    "Soft",
    "Pristine",
    "Genuine",
    "Velvety",
    "Luxurious",
    "Downy",
    "Silky",
    "Heavenly",
    "proud",
    "sturdy",
    "determined",
    "resilient",
    "hardworking",
    "courageoust",
    "loyal",
    "reliable",
    "witty",
    "wooly",
    "shaggy",
    "hairy",
    "luxuriant",
    "thick",
    "fluffy",
    "downy",
    "soft",
    "velvety",
    "silky",
    "Worn",
    "Furry",
    "Scruffy",
    "Fluffy",
    "Itchy",
    "Soft",
    "Debris",
    "scratched"
]

export const hareSmellWords = [
    "Pungent", "musky", "gamey", "wild", "earthy", "bloody","Metallic", "iron like",
]

export const hareBehaviourSentences = [
"it is twitching its nose.",
"it is stamping its feet.",
"it is bobbing its head.",
"it is flicking its ears",
"it is staring blankly ahead and does not appear to be aware of its surroundings",
"it is standing completely still and does not appear to be breathing",
"it's eyes are dilated and it has a vacant stare",
"it is standing on its hind legs.",
"it is wiggling its ears.",
"it is stamping its feet.",
"it is staring at me.",
"it is foaming at the mouth",
"it's eyes are glowing",
]