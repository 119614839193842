export const bermudaGrassSmellTypes = [
    "warm",
    "sweet",
    "earthy",
    "moist",
    "morning-dewy",
    "herbal",
    "bitter",
    "of cabbage and melon",
    "green",
    "mown",
    "of clippings",
    "trimmed",
    "cut",
    "coastal",
    "Salty",
    "Damp",
    "Coastal",
    "Breezy",
    "Fresh",
    "salty",
    "oceanic",
    "breezy",
    "green",
    "herbal",
    "earthy",
    "camphoraceous",
    "dry",
    "unassuming",
    "Aromatic",
    "Earthy",
    "Fragrant",
    "Pleasant",
    "Pungent",
    "Renewing",
    "Relaxing",
    "Stimulating",
    "suffocating",
    "dreadful",
    "of watery amber",
    "soft and flowery",
    "of seawater and mint",
    "icy and salty",
    "of a sandy breeze",
    "of a faded memory, reproduced synthetically"
    "of rotting algae",
    "of marine cadavers",
    "of a past utopia that never was",
    "of patchouli and the seaside",
    "of a summer dust cloud",
    "crushed mint and leaves",
    "fresh and cool",
    "luxe",
    "of citrus and musk",
    "of beach heather",
    "of a secluded cove",
    "of a tool carved from wood",
    "of dew-filled moss",
    "of discarded mollusk homes",
    "of a damp cellar",
    "of a stagnant pond",
    "of sunbleached wood",
    "of a barnacle-laden harbour",
    "of an overcast sea",
    "of trade on the water",
]

export const bermudaGrassBranchTypes = [
]

export const bermudaGrassLeafTypes = [
    "blade",
    "bristle",
    "grey-green",
    "greenish-yellow",
    "rough-edged",
    "flattened",
    "purple-tinged",
    "deep roots and unassuming"
    "frond",
    "clumping",
    "verdant",
    "lush",
    "green",
    "neat",
    "tidy",
    "tender",
    "fast-growing",
    "leafy",
    "fragrant",
    "springy",
    "straightforward",
    "tough",
    "blooming",
    "fresh",
    "pristine",
    "Wispy",
    "Green",
    "Long",
    "Strong",
    "Flexible",
    "Enduring",
    "Resilient",
    "Hardy",
    "robust",
    "Vibrant",
    "Lively",
    "soft",
    "thin",
    "long",
    "pointy",
    "dark green",
    "shiny",
    "waxy",
    "smooth",
    "spear-headed",
    "strong",
    "stark",
    "dense",
    "thick",
    "salt-borne",
    "uncomplicated",
    "stout",
    "burly",
    "patches pulled from its",
    "delicately indestructable",

]