import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_KEYS_SUCCESS, GET_TRADE_KEYS_SUCCESS, ADD_KEY, UPDATE_KEY, REMOVE_KEY, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  characterKeys: [],
  tileKeys: [],
  tradeKeys: [],
  keys: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_KEYS_SUCCESS: {
      const keys = action.payload;

      return {
        ...state,
        keys: [...keys.filter(keyInstance => keyInstance.panelId)],
        characterKeys: [...state.characterKeys, ...keys.filter(keyInstance => keyInstance.characterId)],
        tileKeys: [...keys.filter(keyInstance => keyInstance.tileId)],
      }
    }

    case GET_TRADE_KEYS_SUCCESS: {
      const keys = action.payload;

      return {
        ...state,
        tradeKeys: [...keys],
      }
    }

    case ADD_KEY: {
      let characterKeys = [...state.characterKeys];
      let tileKeys = [...state.tileKeys];
      let keys = [...state.keys];

      if (action.payload.characterId) {
        characterKeys = [ ...characterKeys, action.payload ]
      }

      if (action.payload.tileId) {
        tileKeys = [ ...tileKeys, action.payload ]
      }

      if (action.payload.panelId) {
        keys = [ ...keys, action.payload ]
      }

      return {
        ...state,
        characterKeys: [ ...characterKeys ],
        tileKeys: [ ...tileKeys ],
        keys: [ ...keys ],
      }
    }

    case UPDATE_KEY: {
      let characterKeys = [...state.characterKeys];
      let tileKeys = [...state.tileKeys];
      let keys = [...state.keys];

      if (action.payload.characterId) {
        characterKeys = [
          ...characterKeys.filter(key => key._id !== action.payload._id),
          { ...characterKeys.find(key => key._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.tileId) {
        tileKeys = [
          ...tileKeys.filter(key => key._id !== action.payload._id),
          { ...tileKeys.find(key => key._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.panelId) {
        keys = [
          ...keys.filter(key => key._id !== action.payload._id),
          { ...keys.find(key => key._id === action.payload._id), ...action.payload }
        ]
      }

      return {
        ...state,
        characterKeys: [ ...characterKeys ],
        tileKeys: [ ...tileKeys ],
        keys: [ ...keys ],
      }
    }

    case REMOVE_KEY: {
      return {
        ...state,
        characterKeys: [ ...state.characterKeys.filter(key => (key._id !== action.payload._id)) ],
        tileKeys: [ ...state.tileKeys.filter(key => (key._id !== action.payload._id)) ],
        keys: [ ...state.keys.filter(key => (key._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
