import React from "react";
import { connect } from 'react-redux'

import { selectConstructionRecipes } from '../../../redux/selectors';
import { client } from '../../../services/client';

class ConstructionTypes extends React.Component {

    render() {
        const listItems = this.props.constructionRecipes
            .filter(constructionRecipe => (constructionRecipe.constructionType.name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase()) > -1))
            .sort((a, b) => (a.constructionType.name.localeCompare(b.constructionType.name)))
            .map((constructionRecipe) => {
                return (
                    <li key={constructionRecipe.constructionType._id}
                        onClick={() => (this.props.onItemSelect(constructionRecipe))}>
                        <span className={this.props.selectedItem?._id === constructionRecipe._id ? "selected": ""}>{constructionRecipe.constructionType.name}</span>
                    </li>
                );
            })
        return (
            <ul>{listItems}</ul>
        )
    }
}

const mapStateToProps = state => {
    const constructionRecipes = selectConstructionRecipes(state)

    return { constructionRecipes }
}

export default connect(
    mapStateToProps,
    { }
)(ConstructionTypes);