import {
    CREATE_METAL_LOADING,
    CREATE_METAL_SUCCESS,
    CREATE_METAL_FAILURE,
    GET_METALS_LOADING,
    GET_METALS_SUCCESS,
    GET_METALS_FAILURE,
    ADD_METAL,
    UPDATE_METAL,
    REMOVE_METAL,
    GET_TRADE_METALS_SUCCESS,
    GET_TRADE_METALS_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

import { moveItemFailure } from './move-item.actions';

export const createMetalSuccess = payload => ({
    type: CREATE_METAL_SUCCESS,
    payload
})

export const createMetalFailure = () => ({
    type: CREATE_METAL_FAILURE
})

export const createMetalAsync = payload => dispatch => {
    const { _id, metalTypeId, selectedCharacterId, quantity } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'metal-instances',
        name: 'create',
        data: {
            metalTypeId,
            selectedCharacterId,
            quantity
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getMetalsSuccess = payload => {
    return ({
        type: GET_METALS_SUCCESS,
        payload
    })
}

export const getMetalsFailure = () => ({
    type: GET_METALS_FAILURE
})

export const getMetalsAsync = payload => dispatch => {
    let query = { $or: [{ panelId: payload.panelId, z: payload.z}, { characterId: payload.characterId}] }

    if (payload.isNotFirstInitialisation) {
        query = { panelId: payload.panelId, z: payload.z}
    }

    return client.service('metal-instances').find({ query })
        .then((response) => {
            response = response.map(metal => ({ ...metal, metalType: payload.metalTypes.find(type => type._id === metal.metalTypeId) }))
            dispatch(getMetalsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const getTradeMetalsSuccess = payload => {
    return ({
        type: GET_TRADE_METALS_SUCCESS,
        payload
    })
}

export const getTradeMetalsFailure = () => ({
    type: GET_TRADE_METALS_FAILURE
})

export const getTradeMetalsAsync = payload => dispatch => {
    return client.service('metal-instances').find({ query: { characterId: payload.characterId, markedForTradeCount: { $gte: 1 } }})
        .then((response) => {
            response = response.map(metal => ({ ...metal, metalType: payload.metalTypes.find(type => type._id === metal.metalTypeId) }))
            dispatch(getTradeMetalsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const addMetalToTileAsync = payload => dispatch => {
    const { metalInstance, tileId, tileTypeId } = payload;
    const { metalTypeId, quantity, recipeId, characterId, _id } = metalInstance;

    colyClient.room.send('doAction', {
        serviceName: 'metal-instances',
        commandType: 'create',
        data: {
            metalTypeId,
            quantity,
            tileId,
            tileTypeId,
            panelId: "",
            position: {},
            characterId: ""
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addMetalToCharacterAsync = payload => dispatch => {
    const { metalInstance, characterId } = payload;
    const { createdAt, metalTypeId, quantity, recipeId, _id } = metalInstance;

    colyClient.room.send('doSuperAction', {
        serviceName: 'metal-instances',
        name: 'fetch',
        data: {
            metalTypeId,
            quantity,
            tileId: "",
            characterId
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addMetal = payload => {
    return ({
        type: ADD_METAL,
        payload
    })
}

export const updateMetal = payload => {
    return ({
        type: UPDATE_METAL,
        payload
    })
}

export const removeMetal = payload => {
    return ({
        type: REMOVE_METAL,
        payload
    })
}
