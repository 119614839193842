export const swapperFaceSentences = [
"a face that is always swapping expressions and never settling on one",
"a face that is always changing",
"a face that is always in flux, always changing",
"Frameless, featureless face.",
"All-seeing, unblinking eyes.",
"A perpetual, toothy grin.",
"A nose that is little more than two slits.",
"A mouth that is a vertical slit.",
]

export const swapperBodySentences = [
"its body is lush.",
"its body is curvy.",
"its body is juicy.",
"its body is sultry.",
"its body is alluring.",
"its body is captivating.",
"Lean and lanky",
"long, gangly limbs",
"large, expressive eyes",
"jet black hair",
"small, sharp teeth",
"delicate hands",
"its body is ageless and beautiful.",
"its body is strong and supple.",
"its body is graceful and agile.",
"its body is well-proportioned and elegant.",
]

export const swapperSoundWords = [
    "Clanking", "jangling", "rattling", "dinging", "donging", "chiming", "tinkling", "tolling", "knelling", "gong",
]

export const swapperFurWords = [
 
]

export const swapperSmellWords = [
"Burnt", "smoky", "ashy", "residue", "pungent", "acrid", "sharp", "chemic", "antiseptic", "acrid",
]

export const swapperBehaviourSentences = [

]