import {
    GET_WRITING_IMPLEMENT_RECIPES_LOADING,
    GET_WRITING_IMPLEMENT_RECIPES_SUCCESS,
    GET_WRITING_IMPLEMENT_RECIPES_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';

export const getWritingImplementRecipesSuccess = payload => ({
    type: GET_WRITING_IMPLEMENT_RECIPES_SUCCESS,
    payload
})

export const getWritingImplementRecipesFailure = () => ({
    type: GET_WRITING_IMPLEMENT_RECIPES_FAILURE
})

export const getWritingImplementRecipesAsync = payload => dispatch => {
    return dispatch(getRecursivelyAsync('writing-implement-recipes', getWritingImplementRecipesSuccess, 0));
}