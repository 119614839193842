import React from "react";
import { connect } from 'react-redux'
import TextInput from '../utils/text-input/TextInput';

import store from '../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import { clearPlotAsync, tileTypes, createTileAsync } from '../../redux/actions/tile.actions';
import { createToolAsync } from '../../redux/actions/tool.actions';
import { createMachineAsync } from '../../redux/actions/machine.actions';
import { createMetalAsync } from '../../redux/actions/metal.actions';
import { createMaterialAsync } from '../../redux/actions/material.actions';
import { plantSeedAsync, cultivatePlantAsync, harvestPlantAsync } from '../../redux/actions/plant.actions';
import { buildConstructionAsync } from '../../redux/actions/construction.actions';
import { cookFoodAsync, cookAnotherFoodAsync, eatFoodAsync, deleteFoodAsync } from '../../redux/actions/food.actions';
import { gatherMineralAsync } from '../../redux/actions/mineral.actions';
import { createWagonAsync } from '../../redux/actions/wagon.actions';
import { createCoinAsync } from '../../redux/actions/coin.actions';
import { createBoatAsync } from '../../redux/actions/boat.actions';
import { createBrainchipAsync } from '../../redux/actions/brainchip.actions';
import { createTentAsync } from '../../redux/actions/tent.actions';
import { upgradeWorkshopAsync } from '../../redux/actions/workshop.actions';
import { 
    equipTentLocally, 
    unequipTentLocally,
    equipWorkshopLocally,
    unequipWorkshopLocally
} from '../../redux/actions/character.actions';
import { createLockAsync } from '../../redux/actions/lock.actions';
import { createWritingSurfaceAsync } from '../../redux/actions/writing-surface.actions';
import { createWritingImplementAsync } from '../../redux/actions/writing-implement.actions';
import { createFurnitureAsync } from '../../redux/actions/furniture.actions';
import { createWeaponAsync } from '../../redux/actions/weapon.actions';
import { createArmourAsync } from '../../redux/actions/armour.actions';
import { createClothingAsync } from '../../redux/actions/clothing.actions';
import { createJewelleryAsync } from '../../redux/actions/jewellery.actions';
import { saveLastCraft, closeCrafting } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    showWorkshop,
    disableKeyboardMovement,
    enableKeyboardMovement,
    showHelp,
    startLooking,
    hideAllMenus,
    showWritingSurface
} from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectPanel,
    selectMineralAtPosition,
    selectTileTypeByName,
    selectCharacter,
    selectWorkshopTypeId,
    selectWorkshopTypeById,
    selectRecipesByWorkshopTypeId,
    selectToolTypes,
    selectWorkshopByPosition,
    selectCharacterLevels,
    selectSkills,
    selectNearbyCharacters,
    selectFloorLayerItemAtPosition,
    selectConstructionByPosition,
    selectPlantInstanceIdAtTile,
    selectNeighbouringTents,
    selectWorkshopUpgradeRecipe,
    selectInventory,
    selectCharacterFood,
    selectTiles,
    selectCharacterMinerals,
    selectCharacterTools,
    selectCharacterMetals,
    selectCharacterKeys,
    selectCharacterWritingSurfaces,
    selectCharacterWritingImplements,
    selectCharacterWeapons,
    selectCharacterArmour,
    selectCharacterClothing,
    selectCharacterJewellery,
    selectCharacterTents,
    selectCharacterLocks,
    selectIsQuantityInputOpen,
    selectIsCharacterListShowing,
    selectNeighbouringCharacters,
    selectCharacterTiles,
    selectTileInventory,
    selectTileFood,
    selectTileMinerals,
    selectTileTools,
    selectTileMetals,
    selectTileKeys,
    selectTileWritingSurfaces,
    selectTileWritingImplements,
    selectTileWeapons,
    selectTileArmour,
    selectTileClothing,
    selectTileJewellery,
    selectTileTents,
    selectTileLocks,
    selectWorkshopTypes,
    selectSelectedCharacterId,
    selectWorkshops,
} from '../../redux/selectors';
import { toolTypeNames } from './tool-type-names';
import { itemWorkshopDescriptions } from './item-workshop-descriptions';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';

// import { actions } from './workshop-actions';

import { client } from '../../services/client';

import { BALANCE } from '../../services/balance-config';

import './Workshop.css';

import Menu from '../utils/menu/Menu';

class Wizard extends React.Component {
    state = {
        items: [
        ],
        navigationHandler: this.navigationHandler.bind(this)
    }

    navigationHandler(event) {
        if (event.key === 's' || event.key === 'S') {
            this.setState({
                isSearchShowing: true
            });
            return;
        }

        if (event.key === 'u' || event.key === 'U' && this.props.currentWorkshop.level < 10) {
            this.props.upgradeWorkshopAsync({
                level: (this.props.currentWorkshop.level || 1) + 1,
                _id: this.props.currentWorkshop._id
            })

            return;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.state.navigationHandler)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.state.navigationHandler);
    }

    selectRecipe(recipe) {
        console.log('hi there ', recipe);
        if (!recipe) {
            return;
        }

        this.setState({
            selectedRecipe: recipe,
        })
    }

    buildRecipe(recipe) {
        console.log('wow dude', recipe);
        store.dispatch(showWorkshop({ workshopTypeId: recipe._id, selectedCharacterId: this.props.selectedCharacterId }));
    }

    render() {
        return (
            <div className="workshop">
                <div className="workshop-sidebar">
                    <div className="items">
                        <Menu options={this.props.items}
                            isShortcutHidden={true}
                            highlightedOption={this.selectRecipe.bind(this)}
                            optionChosen={this.buildRecipe.bind(this)}
                            menuContainer="items"
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    let workshopTypes = selectWorkshopTypes(state)
    const selectedCharacterId = selectSelectedCharacterId(state);
    const workshops = selectWorkshops(state);

    workshopTypes = workshopTypes
        .filter(type => {
            if (type.name === `Builder's Yard`) {
                return true;
            }

            return workshops.find(workshop => (type._id === workshop.workshopTypeId))
        })
        .map(type => ({ ...type, text: type.name }));

    workshopTypes.unshift({
        text: 'Build new workshop'
    })

    return {
        items: workshopTypes,
        selectedCharacterId
    }
}

export default connect(
    mapStateToProps,
    {
        
    }
)(Wizard);