export const discordantSmellTypes = [
    "of cable ties",
    "of Lentils",
    "of Celery",
    "of Caraway",
    "Acrid",
    "Ammonia-like",
    "Burning",
    "Chemical",
    "Pungent",
    "acrid",
    "rank",
    "fetid",
    "putrid",
    "malodorous",
    "noisome",
    "reeky",
    "stinking",
    "foul",
    "of sheep's wool",
    "of broom",
    "of garlic",
    "of onion",
    "of sage",
    "of Mayweed",
    "of Chickweed",
    "of Dock",
    "of Ground Ivy",
    "of Henbit",
    "of Sow thistle",
    "revolting",
    "of synthetic wood",
    "mellow and inoffensive",
    "cedar with a oudy flair",
    "post-modern",
    "not dissimilar to disinfectant",
    "of terpinic wood",
    "piercing",
    "of a pile of cut wood",
    "of a cut trail through the woods",
    "creamy and rich",
    "of chemical wood",
    "of the moment before lightning strikes",
    "of sweet woods and frizzy music",
    "of spiced wood",
    "of rotten wooden dirtiness",
    "sharply green and peppery",
    "of pine and glue",
    "of warm spices and satsuma",
    "plush and joyful",
    "of sweat and machinery",
]

export const discordantBranchTypes = [
    "grasping",
    "Thistley",
    "Writhing",
    "pendulous",
    "Coiling",
    "Tangled",
    "Trailing",
    "Unkempt",
    "Leafy",
    "enduring",
    "Clinging",
    "Thorny",
    "Elegant",
    "barnacled",
    "Tranquil",
    "tentacle",
    "Horned",
    "Fleshy",
    "Tubular",
    "Putrid",
    "lustrous",
    "cabling",
    "colour-clad",
    "motherboard",
    "cacophonous",
]

export const discordantLeafTypes = [
    "large",
    "thin",
    "pointy",
    "light",
    "jewelled",
    "spotted",
    "iridescent",
    "shrill",
    "dissonant",
    "cacophonous",
    "ruinous",
    "technicolour",
    "garish",
    "opalescent",
    "lustrous",
    "polychromatic",
    "sensationalised",
    "a shock of colourful",
    "shocking",
    "confounding",
    "confusing",
    "pied",
    "prismatic",
    "psychadelic",
    "multicoloured",
    "deleterious",
    "detrimental",
    "pernicious",
    "adverse",
    "inimical",
    "rattling",
    "quavering",
    "tremulous",
    "rhythmic",
    "arrhythmic",
    "swaying",
    "pendulous",
    "lilting",
    "undulating",
    "rainbow",
]