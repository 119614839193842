export const tigerFaceSentences = [
"A massive, toothy grin filled with sharp fangs.",
"A long, curved snout dripping with venom.",
"Two beady eyes glowing with malevolent intent.",
"Two furry ears sprout from the top of the furry face.",
"A long, furry snout protrudes from the face.",
"Two beady eyes sit atop the snout.",
"below the eyes, a set of large, sharp fangs jut out.",
"A gigantic face with bulging eyes.",
"A huge, furry face with long, sharp teeth.",
]

export const tigerBodySentences = [
]

export const tigerSoundWords = [
    "Terrible",
    "Odd",
    "Intimidating",
    "Aggressive",
    "Frightening",
    "Scary",
    "Monstrous",
    "Large",
    "Unique",
    "Emotional",
    "Flailing",
    "Snarling",
    "Chittering",
    "Slobbering",
    "Scuttling",
    "Skittering",
    "Prowling",
    "Rumbling",
    "Stammering",
    "Shambling",
    "Chitinous",
    "Clacking",
    "Crawling",
    "Hissing",
    "Pattering",
    "Scratching",
    "Snapping",
    "Stalking",
    "Thieving",
    "Venomous",
    "Scurrying",
    "scuttling",
    "skittering",
    "darting",
    "dashing",
    "fleeing",
    "racing",
    "chasing",
    "bounding",
    "leaping",
    "Scurrying",
    "scuttling",
    "skittering",
    "clicking",
    "clacking",
    "scurrying",
    "rustling",
    "scratching",
    "skittering",
    "scuttling"
]

export const tigerFurWords = [
    "prickly",
    "course",
    "rough",
    "scaly",
    "dry",
    "tough",
    "leathery",
    "hard",
    "thick",
    "Thick",
    "hairy",
    "course",
    "warm",
    "itchy",
    "oily",
    "rank",
    "flea-ridden",
    "mangy",
    "scabby",
    "thick",
    "oily",
    "coarse",
    "smelly",
    "prickly",
    "matted",
    "stained",
    "secretion-coated",
    "rank",
    "disgustingly unkempt",
    "Thick",
    "course",
    "oily",
    "smelly",
    "matted",
    "tangled",
    "crusty",
    "itchy",
    "dry",
    "brittle",
    "fuzzy",
    "soft",
    "delicate",
    "luxurious",
    "thick",
    "plush",
    "warm",
    "enviable",
    "rare",
    "expensive"
]

export const tigerSmellWords = [
"Earthy",
"Gamey",
"Woody",
"Crisp",
"Fruity",
"Floral",
"Spicy",
"Sweet",
"Tangy",
"Pungent",
"Gamey",
"Earthy",
"rank",
"musty",
"dank",
"fetid",
"reeking",
"malodorous",
"putrid",
"Foul",
"Pungent",
"Ammonia-like",
"Decomposing",
"Rancid",
"Gamey",
"musty",
"Dank",
"fetid",
"putrid",
"Pungent",
"Unforgettable",
"Sharp",
"Intense",
"Distinct",
"Overwhelming",
"nauseating",
"putrid",
"vomitous",
"raunchy",
]

export const tigerBehaviourSentences = [
"flicking its tail back and forth",
"baring its teeth",
"hissing at you",
"It is crouching down low, almost like it's ready to pounce.",
"Its tail is slowly twitching back and forth.",
]