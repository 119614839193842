import { GET_PLANT_PRODUCTS_SUCCESS } from "../actionTypes";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PLANT_PRODUCTS_SUCCESS: {
      const plantProducts = action.payload;

      if (!plantProducts){
        return [...state];
      }

      return [
        ...state,
        ...plantProducts
      ]
    }
    default:
      return state;
  }
}
