export const thistlebushSmellTypes = [
    "of Rose",
    "Herbal",
    "Minty",
    "Pine",
    "Sensual",
    " Woody",
    "of birch",
    "of tea",
    "of verbena",
    "of musty chocolate",
    "of wet earth",
    "of sweetened dirt",
    "eye-watering",
    "of water-damaged wood",
    "of mould spores",
    "of dusty sandalwood",
    "of cocoa powder and mud",
    "of sweat and hesitation",
    "of ointment for a rash you once had",
    "of dank syrup",
    "of forgotten cellar wares",
    "of blunt sweetness",
    "of a carpet of pine needles",
    "of raspberry and sharp pine",
    "of kudzu-covered woods",
    "of sweet pumpkin",
    "of cloying spices",
    "of frozen earth",
    "of lonely, cold spaces",
    "of mulled drink",
    "conventional",
    "of soft, rotten fruit",
    "of sludgy icewater",
    "mildewy",
    "of rotten leaves",
    "disturbing",
    "of a bared soul",
    "carnal and tuberose",
    "of bitter drinks",
    "of mysore",
    "of incense smoke",
    "of burrowing beasts",
    "of figgy damiana",
    "aphrodisial",
    "of baked earth",
    "mesmerising",
    "of stormblown crowns",
    "of long, desk-bound days",
    "of sacred leafy spaces",
    "of pungent roots",
    "stomach-tightening",
]

export const thistlebushBranchTypes = [
	"Jagged",
    "Torn",
    "Rough",
    "Tough",
    "Bumpy",
    "Hard",
    "Spiky",
    "prickly",
    "spiney",
    "sharp",
    "Prickly",
    "pointy",
    "barbed",
    "jagged",
    "harsh",
    "biting",
    "stinging",
    "overprotective",
    "unnecessary",
    "scarred",
    "gnawed",
    "stalwart",
    "naked",
    "lacerating",
    "heavy",
    "humbled",
    "sated",
    "obedient",
    "scaled",
    "silent",
    "sickened",
    "whispering",
    "strange symbols carved into its"
    "camouflaged insects in its"
]

export const thistlebushLeafTypes = [
	"tiny",
    "thistle",
    "wreathed",
    "shriveled",
    "rain-catching",
    "hissing",
    "feathery",
    "ovulate",
    "down-like",
    "mottled",
    "vivid",
    "dazzling",
    "slender skinny",
    "sucked dry",
    "waning",
    "listless",
    "wick-like",
    "oily",
    "chewable",
    "fine hairs on its",
]