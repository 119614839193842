import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_BOATS_SUCCESS, ADD_BOAT, UPDATE_BOAT, REMOVE_BOAT, BEGIN_UPDATE_BOAT, UPDATE_BOAT_FAILED, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  boats: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }

    case GET_BOATS_SUCCESS: {
      const boats = action.payload;

      return {
        ...state,
        boats: [...boats.map(boat => ({ ...boat, lastPosition: boat.position })).filter(boatInstance => boatInstance.panelId)],
      }
    }

    case ADD_BOAT: {
      let boats = [...state.boats];

      if (action.payload.panelId) {
        boats = [ ...boats, action.payload ]
      }

      return {
        ...state,
        boats: [ ...boats ],
      }
    }

    case BEGIN_UPDATE_BOAT: {
      let boats = [...state.boats];

      if (action.payload.panelId) {
        const boat = boats.find(boat => boat._id === action.payload._id)
        boats = [
          ...boats.filter(boat => boat._id !== action.payload._id),
          { ...boat, ...action.payload, lastPosition: {...boat.position} }
        ]
      }

      return {
        ...state,
        boats: [ ...boats ],
      }
    }

    case UPDATE_BOAT_FAILED: {
      let boats = [...state.boats];

      if (action.payload.panelId) {
        const boat = boats.find(boat => boat._id === action.payload._id)
        boats = [
          ...boats.filter(boat => boat._id !== action.payload._id),
          { ...boat, ...action.payload, position: {...boat.lastPosition} }
        ]
      }

      return {
        ...state,
        boats: [ ...boats ],
      }
    }

    case UPDATE_BOAT: {
      let boats = [...state.boats];

      if (action.payload.panelId) {
        boats = [
          ...boats.filter(boat => boat._id !== action.payload._id),
          { ...boats.find(boat => boat._id === action.payload._id), ...action.payload }
        ]
      }

      return {
        ...state,
        boats: [ ...boats ],
      }
    }

    case REMOVE_BOAT: {
      return {
        ...state,
        boats: [ ...state.boats.filter(boat => (boat._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
