import { differenceInDays, addDays } from 'date-fns';

export const Season = {
    SPRING: 'SPRING',
    SUMMER: 'SUMMER',
    AUTUMN: 'AUTUMN',
    WINTER: 'WINTER'
};

export const LastDayOf = {
    SPRING: 4,
    SUMMER: 9,
    AUTUMN: 14,
    WINTER: 19
};

export const FIRST_DAY = new Date(2021, 1, 1);
export const DAYS_IN_YEAR = 20;

export function getSeason() {
    const daysSinceFirstDay = differenceInDays(new Date(), FIRST_DAY);

    const dayOfYear = daysSinceFirstDay % DAYS_IN_YEAR

    if (dayOfYear < 5) {
        return Season.SPRING
    }


    if (dayOfYear < 10) {
        return Season.SUMMER
    }


    if (dayOfYear < 15) {
        return Season.AUTUMN
    }

    return Season.WINTER
}

export function getSeasonOn(date) {
    const daysSinceFirstDay = differenceInDays(date, FIRST_DAY);

    const dayOfYear = daysSinceFirstDay % DAYS_IN_YEAR

    if (dayOfYear < 5) {
        return Season.SPRING
    }


    if (dayOfYear < 10) {
        return Season.SUMMER
    }


    if (dayOfYear < 15) {
        return Season.AUTUMN
    }

    return Season.WINTER
}

export function getLastDayOfPreviousHarvestSeason(harvestSeason) {
    const currentDay = new Date();
    const daysSinceFirstDay = differenceInDays(currentDay, FIRST_DAY);

    const dayOfYear = daysSinceFirstDay % DAYS_IN_YEAR

    let newDateModifier = -dayOfYear + LastDayOf[harvestSeason];

    if (newDateModifier > 0) {
        newDateModifier -= 20;
    }

    if (dayOfYear < 5) {
        return addDays(currentDay, newDateModifier)
    }

    if (dayOfYear < 10) {
        return addDays(currentDay, newDateModifier)
    }

    if (dayOfYear < 15) {
        return addDays(currentDay, newDateModifier)
    }

    return addDays(currentDay, newDateModifier);
}