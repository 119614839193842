export const pricklelandsFlowerSmellTypes = [
	"of Jasmine",
    "tart",
    "sweet",
    "sour",
    "enormous",
    "prickly",
    "dense",
    "jagged",
    "sharp",
    "of moss",
    "of pear",
    "of bhut jolokia",
    "Thick",
    "Musky",
    "Dank",
    "Earthy",
    "of cherry blossom",
    "of a vinted drink",
    "herbal and woody",
    "of imagined grass",
    "herbaceous",
    "of beautiful smoke",
    "of off-putting dirtiness",
    "nutty straw",
    "of dry greenery",
    "of biting capsicum",
    "of dentistry",
    "of jasmine and fumes",
    "saponaceous",
    "of humid and sweat",
    "of buttery white florals",
    "of crisp plumeria",
    "of muted limes",
    "of sweetened plastics",
    "of apricot",
    "of persimmon",
    "of clean cloth and bonbons",
    "of musky plum",
    "abysmal",
    "of fresh fruit",
    "of violet leaf and petrol",
    "sickening and saccharine",
    "of soured apples",
    "of vinegared roses",
    "sticky sweet",
    "of cherry blossoms",
    "of crystallised sugar",
    "of vanilla and spun sugar",
    "of iris and guava",
    "of jammy rose",
    "of grapefruit and peony",
]

export const pricklelandsFlowerBranchTypes = [
    "a large inflorescence above its",
    "a heavy pink head and hairy branches",
    "a heliotropic head atop coarse",
    "a sun-suckling flower and stiff",
    "braided, bright",
    "haughty ray flowers and toothed",
    "tall, roughly-haired",
    "a many-petaled dome with tall",
    "spirally arranged petals atop its coarse",
    "long, green",
    "stalking",
    "towering",
    "heliotropic",
    "slowly bobbing",
    "minutely rotating",
    "warm, green",
    "sun-kissed",
    "downy-mildewed",
    "dry",
    "open",
    "inviting",
    "cheery",
    "solar-pleasing",
    "appealing",
    "oily",
]

export const pricklelandsFlowerLeafTypes = [
	"glossy",
    "green",
    "spiny",
    "sharp",
    "Large",
    "Broad",
    "Thick",
    "Green",
    "Waxy",
    "Smooth",
    "Velvety",
    "Curled",
    "Spiraled",
    "Twisty",
    "heart-shaped",
    "oil-soaked",
    "broad",
    "toothed",
    "alternate",
    "long",
    "airy",
    "swaying",
]