export const webShooterFaceSentences = [
"A face with eight long, spindly spider legs coming out of the sides",
"A face with large fangs and bright red eyes",
"A big, round mouth filled with sharp teeth.",
"Long, sharp teeth",
"A spider-like creature with a human-like face, red eyes, and a long, pointed mouth.",
"A creature with a large, round head and a face that is half-human, half- insect.",
"it has a long, thin face and sharp teeth",
"it has a large, bulbous head with six beady eyes and a long, mucous-covered snout.",
]

export const webShooterBodySentences = [
"Its long, spindly legs are perfect for spinning webs",
"Its many eyes help it to see in all directions",
"Its body is covered in a hard, chitinous exoskeleton",
"Long, spindly legs ",
"An abdomen full of webbing ",
"Small, shiny black eyes ",
"Web-covered arms ",
"A glob of webbing for a nose ",
]

export const webShooterSoundWords = [
"Rasping",
"Scraping",
"Snarling",
"Growling",
"Rumbling",
"Roaring",
"Shrieking",
"Yelling",
"Cackling",
"Howling",
"Wheezing",
"Rasping",
"Gasping",
"Coughing",
"Sputtering",
"Hissing",
"Snorting",
"Snoring",
"Wheezing",
"Rasping",
"Scratching",
"Stomping",
"Chomping",
"Rumbling",
"Snarling",
"Growling",
"Shrieking",
"Flailing",
"Whipping",
"Slashing",
]

export const webShooterFurWords = [
"scaly",
"slimy",
"prickly",
"uneven",
"bumpy",
"furry",
"course",
"Fuzzy",
"Hairy",
"Scaly",
"Slippery",
"Slimy",
"Soft",
"Spiky",
"Sticky",
"Velvet",
"prickly",
"itchy",
"scaly",
"slimy",
"hairy",
"bumpy",
"shiny",
"wormy",
"scaly",
"slimy",
"bumpy",
"hairy",
"pointy",
"fleecy",
"downy",
"silky",
"leathery",
"spiky",
"Hairy",
"Fuzzy",
"Fluffy",
]

export const webShooterSmellWords = [
"Pungent",
"Rancid",
"Foul",
"Skunky",
"Putrid",
"Decaying",
"Rotten",
"Stinky",
"Malodorous",
]

export const webShooterBehaviourSentences = [
"making an eerie, high-pitched screeching noise",
"twitching its long, furry legs menacingly",
"it is hiding in the shadows",
"it has long, thin legs",
"it has large, compound eyes",
"it has a long, thin proboscis",
]