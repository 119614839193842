import { GET_CURRENCIES_SUCCESS, CREATE_CURRENCY_SUCCESS } from "../actionTypes";

const initialState = {
  currencies: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CURRENCIES_SUCCESS: {
      const currencies = action.payload;

      return {
        ...state,
        currencies: [...currencies],
      }
    }

    case CREATE_CURRENCY_SUCCESS: {
      return {
        ...state,
        currencies: [...state.currencies, { ...action.payload }],
      }
    }

    default:
      return state;
  }
}
