export const selectSkills = store => {
	return store && store.skillTypes
		? [ ...store.skillTypes ]
		: []
}

export const selectMineralTypes = store => {
	return store && store.mineralTypes
		? [ ...store.mineralTypes ]
		: []
}

export const selectToolTypes = store => {
	return store && store.toolTypes
		? [ ...store.toolTypes ]
		: []
}
export const selectMetalTypes = store => {
	return store && store.metalTypes
		? [ ...store.metalTypes ]
		: []
}
export const selectKeyTypes = store => {
	return store && store.keyTypes
		? [ ...store.keyTypes ]
		: []
}
export const selectMachineTypes = store => {
	return store && store.machineTypes
		? [ ...store.machineTypes ]
		: []
}
export const selectFurnitureTypes = store => {
	return store && store.furnitureTypes
		? [ ...store.furnitureTypes ]
		: []
}
export const selectWritingSurfaceTypes = store => {
	return store && store.writingSurfaceTypes
		? [ ...store.writingSurfaceTypes ]
		: []
}
export const selectWritingImplementTypes = store => {
	return store && store.writingImplementTypes
		? [ ...store.writingImplementTypes ]
		: []
}
export const selectWeaponTypes = store => {
	return store && store.weaponTypes
		? [ ...store.weaponTypes ]
		: []
}
export const selectArmourTypes = store => {
	return store && store.armourTypes
		? [ ...store.armourTypes ]
		: []
}
export const selectClothingTypes = store => {
	return store && store.clothingTypes
		? [ ...store.clothingTypes ]
		: []
}
export const selectJewelleryTypes = store => {
	return store && store.jewelleryTypes
		? [ ...store.jewelleryTypes ]
		: []
}
export const selectFoodTypes = store => {
	return store && store.foodTypes
		? [ ...store.foodTypes ]
		: []
}
export const selectLockTypes = store => {
	return store && store.lockTypes
		? [ ...store.lockTypes ]
		: []
}
export const selectAnimalTypes = store => {
	return store && store.animalTypes
		? [ ...store.animalTypes ]
		: []
}
export const selectFishTypes = store => {
	return store && store.fishTypes
		? [ ...store.fishTypes ]
		: []
}
export const selectConstructionTypes = store => {
	return store && store.constructionTypes
		? [ ...store.constructionTypes.map(constructionType => (({ ...constructionType, recipes: store.constructionRecipes?.constructionRecipes?.filter(recipe => (recipe.constructionTypeId === constructionType._id)) }))) ]
		: []
}
export const selectBoatTypes = store => {
	return store && store.boatTypes
		? [ ...store.boatTypes ]
		: []
}
export const selectBrainchipTypes = store => {
	return store && store.brainchipTypes
		? [ ...store.brainchipTypes ]
		: []
}
export const selectTentTypes = store => {
	return store && store.tentTypes
		? [ ...store.tentTypes ]
		: []
}
export const selectWorkshopTypes = store => {
	return store && store.workshopTypes
		? [ ...store.workshopTypes ]
		: []
}
export const selectWorkshopTypeById = (store, typeId) => {
	return store && store.workshopTypes
		? { ...store.workshopTypes.find(workshopType => (workshopType._id === typeId)) }
		: {}
}
export const selectWagonTypes = store => {
	return store && store.wagonTypes
		? [ ...store.wagonTypes ]
		: []
}
export const selectCoinTypes = store => {
	return store && store.coinTypes
		? [ ...store.coinTypes ]
		: []
}
export const selectBrainchipTypeByIds = (store, typeIds) => {
	return store && store.brainchipTypes
		? [ ...store.brainchipTypes.filter(brainchipType => (typeIds && typeIds.indexOf(brainchipType._id) > -1)) ]
		: []
}