export const hpHalverFaceSentences = [
"Slimy, green ooze dripping down a long, sharp nose.",
"Vicious, blood-red eyes glaring out from underneath a matted beard.",
"Rows of sharp, yellowed teeth bared in a ghastly grin.",
"A large, toothy mouth with long, sharp fangs",
"Two beady eyes that seem to pierce your soul",
"Gaunt features with sunken cheeks",
"Gray, scaly skin that looks like it's been badly burned",
"gaping maw",
"rows of jagged teeth",
"bulging, bloodshot eyes",
"feverishly glowing skin",
"rippling, serpentine body",
"eldritch aura of power",
"A mouth that drips with ichor",
"Cracks and fissures all over its face",
"Dead, rotting flesh that hangs off in strips",
"A rusted, metal plate where its nose should be",
]

export const hpHalverBodySentences = [
"Spindly legs kick wildly as it tries to move forward.",
"It's long, tentacle-like arms wave menacingly in the air.",
"Its bloated body is held upright by two thin, frail-looking legs.",
"It has a long, thick body with plenty of heft.",
"Thick, scaly skin ",
"Powerful, muscular body ",
"It has a huge, muscular body that is covered in thick scales.",
"His powerful legs and arms end in sharp claws that can easily rend flesh and bone.",
]

export const hpHalverSoundWords = [
"Wailing",
"screeching",
"howling",
"yelping",
"barking",
"crying",
"sobbing",
"whining",
"moaning",
"groaning",
"Thumping",
"Stomping",
"Rumbling",
"Shambling",
"Creaking",
"Snarling",
"Growling",
"Roaring",
"Snorting",
"Charging",
]

export const hpHalverFurWords = [
"Scaly ",
"Oily ",
"Putrid ",
"Festering ",
"Molting ",
"Slimy ",
"Pungent ",
"Viscous ",
"Chitinous",
"Hairy",
"Scaly",
"Spiky",
"slimy",
"Course",
"Prickly",
]

export const hpHalverSmellWords = [
"Nauseating",
"Overpowering",
"Pungent",
"Foul",
"Repellent",
"Disgusting",
"Revolting",
"Sickening",
"Stomach-churning",
"Vomit-inducing",
"Funky",
"Rancid",
"Pungent",
"Ammonia-like",
"Skunky",
"Earthy",
"stale",
"Moldy",
"Rank",
"Putrid",
]

export const hpHalverBehaviourSentences = [
"it is standing very still",
"it is sniffing the air",
"it is licking its lips",
"it is making a chittering noise and seems to be eyeing me up",
"it is standing completely still, making it hard to tell if it's alive",
]