import React from "react";
import { connect } from 'react-redux'

import { clearPlotAsync, tileTypes, createTileAsync } from '../../redux/actions/tile.actions';
import { createToolAsync } from '../../redux/actions/tool.actions';
import { createMachineAsync } from '../../redux/actions/machine.actions';
import { createMetalAsync } from '../../redux/actions/metal.actions';
import { createMaterialAsync } from '../../redux/actions/material.actions';
import { plantSeedAsync, cultivatePlantAsync, harvestPlantAsync } from '../../redux/actions/plant.actions';
import { buildConstructionAsync } from '../../redux/actions/construction.actions';
import { cookFoodAsync, cookAnotherFoodAsync, eatFoodAsync, deleteFoodAsync } from '../../redux/actions/food.actions';
import { gatherMineralAsync } from '../../redux/actions/mineral.actions';
import { fightCharacterAsync } from '../../redux/actions/character.actions';
import { createLockAsync } from '../../redux/actions/lock.actions';
import { createWritingSurfaceAsync } from '../../redux/actions/writing-surface.actions';
import { createWritingImplementAsync } from '../../redux/actions/writing-implement.actions';
import { createFurnitureAsync } from '../../redux/actions/furniture.actions';
import { createWeaponAsync } from '../../redux/actions/weapon.actions';
import { createArmourAsync } from '../../redux/actions/armour.actions';
import { createClothingAsync } from '../../redux/actions/clothing.actions';
import { createJewelleryAsync } from '../../redux/actions/jewellery.actions';
import { chooseSeedToPlant, chooseFoodToEat, chooseCharacterToFight, showFightConfirmation, hideAllMenus } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectPlantInstanceIdAtTile,
    selectCharacter,
    selectCharacterFood,
    selectMineralAtPosition,
    selectTileTypeByName,
    selectIsSeedListShowing,
    selectIsCharacterFoodShowing,
    selectIsCharacterListShowing,
    selectIsFightConfirmationShowing,
    selectDefendingCharacterId
} from '../../redux/selectors';

import SeedList from './seed-list/SeedList';
import CharacterFood from './character-food/CharacterFood';
import CharacterList from './character-list/CharacterList';
import FightConfirmation from './fight-confirmation/FightConfirmation'

import './Actions.css';

class Actions extends React.Component {
    initialState = {
        isSeedListShowing: false,
        isCharacterFoodShowing: false,
        isCharacterListShowing: false,
        isFightConfirmationShowing: false
    };

    chooseSeedToPlantState = ({ plantTypeId, _id }) => {
        this.props.plantSeedAsync({ plantTypeId, _id })
            .then(() => (this.props.hideAllMenus()))
    }

    chooseFoodToEat = (characterFoodInstance) => {
        this.props.eatFoodAsync({ ...characterFoodInstance })
            .then(() => (this.props.hideAllMenus()))
    }

    chooseCharacterToFight = ({ _id }) => {
        this.props.showFightConfirmation({ defendingCharacterId: _id });
    }

    actions = [
    ];

    constructor() {
        super();
        this.state = { isSeedListShowing: false };
    }

    render() {
        const actions = this.actions.map((action, index) => {
            return (
                <li key={index}
                    onClick={() => (action.callback())}>
                    {action.name}
                </li>
            )
        })

        return (
            <div className={(this.props.isActionsShowing) ? 'hidden' : 'actions'}>
                <div className="actions-sub-menu">
                    {this.props.isSeedListShowing && <SeedList onItemSelect={this.chooseSeedToPlantState.bind(this)} />}
                    {this.props.isCharacterFoodShowing && <CharacterFood onItemSelect={this.chooseFoodToEat.bind(this)} />}
                    {this.props.isCharacterListShowing && <CharacterList onItemSelect={this.chooseCharacterToFight.bind(this)} />}
                    {this.props.isFightConfirmationShowing && <FightConfirmation defendingCharacterId={this.props.defendingCharacterId} />}
                </div>
            </div>
        )
    }
}

const mapToStateProps = state => {
    const character = selectCharacter(state);
    const plantInstanceIdAtTile = selectPlantInstanceIdAtTile(state, character.position)
    const mineralInstanceAtTile = selectMineralAtPosition(state, character.position)
    const characterFood = selectCharacterFood(state);
    const farmPlotTileType = selectTileTypeByName(state, tileTypes.FARM_PLOT)

    const isSeedListShowing = selectIsSeedListShowing(state);
    const isCharacterFoodShowing = selectIsCharacterFoodShowing(state);
    const isCharacterListShowing = selectIsCharacterListShowing(state);
    const isFightConfirmationShowing = selectIsFightConfirmationShowing(state);
    const defendingCharacterId = selectDefendingCharacterId(state);

    const isActionsShowing = !isSeedListShowing && !isCharacterFoodShowing && !isCharacterListShowing && !isFightConfirmationShowing;

    return {
        character,
        plantInstanceIdAtTile,
        characterFood,
        mineralInstanceAtTile,
        farmPlotTileType,
        isSeedListShowing,
        isCharacterFoodShowing,
        isCharacterListShowing,
        isFightConfirmationShowing,
        isActionsShowing,
        defendingCharacterId
    }
}

export default connect(
    mapToStateProps,
    {
        clearPlotAsync,
        plantSeedAsync,
        fightCharacterAsync,
        createLockAsync,
        createWritingSurfaceAsync,
        createWritingImplementAsync,
        createFurnitureAsync,
        createTileAsync,
        createWeaponAsync,
        createArmourAsync,
        createClothingAsync,
        createJewelleryAsync,
        chooseSeedToPlant,
        chooseFoodToEat,
        chooseCharacterToFight,
        showFightConfirmation,
        eatFoodAsync,
        deleteFoodAsync,
        hideAllMenus
    }
)(Actions);