export const mazeScrubSmellTypes = [
    "of white pepper",
    "of cedar wood",
    "of eucalyptus",
    "of lemongrass",
    "of ginger",
    "of ylang ylang",
    "of Moss",
    "damp",
    "Ancient",
    "Dusty",
    "Abandoned",
    "Desolate",
    "Lonely",
    "Lush",
    "of Musk",
    "fruity",
    "boggy",
    "of Fen",
    "Miry",
    "woody and metallic",
    "of sugary pitanga",
    "of healthy citrus",
    "of convivial cassis",
    "of tart peach and bergamot",
    "divine",
    "of melon and hives",
    "of days yet to come",
    "of hazy soursop",
    "of soft, billowy ginger",
    "of unmapped valleys",
    "of animal marking",
    "of unknown paradise",
    "of hot guava",
    "of petals dipped in honey",
    "of admired work",
    "of neglectful labour",
    "of opium and cream",
    "of higher virtues",
    "of speleological fruits",
    "of higher latitudes",
    "of exaggerated florals",
    "of bleached clothing",
    "of sugar lace",
    "of graviola and iron",
    "of juiced apple and salty waters",
    "of pungent fruity sweets",
    "of woody guanabana",
    "of dried, starchy fruits",
    "of patchouli and vanilla",
    "of should-haves and maybes",
    "of ghostly regret",
    "of rolling green",
    "of pressed fruit",
]

export const mazeScrubBranchTypes = [
    "Hemp",
   "Amber",
   "Enigma",
   "woody",
   "climbing",
   "twining",
   "slender",
   "branched",
   "green",
   "glabrous",
   "puberulent",
   "dead end",
   "corridor",
   "junction",
   "secret",
   "hidden",
   "trap",
   "stellate",
   "glabrous",
   "pubescent",
   "calcareous",
   "matted",
   "hairy",
   "rough",
   "bristly",
   "hollow",
   "heaped",
   "floral-crested",
   "hillock-hewn",
   "silt roots and sturdy",
   "goldbright",
   "gilded",
   "fair",
   "sun-joy",
]

export const mazeScrubLeafTypes = [
	"Tall",
    "Thin",
    "palmate",
    "shallow bladed",
    "Sharp",
    "Spiked",
    "Leafy",
    "Fragile",
    "Soft",
    "Delicate",
    "Thin",
    "Tall",
    "Smooth",
    "lanceolate",
    "Sharp",
    "Light",
    "unwavering",
    "graceful",
    "thick",
    "fleshy",
    "emerald",
    "turqouise",
    "blue",
    "Airy",
    "Green",
    "Soft",
    "Delicate",
    "Clinging",
    "Fragile",
    "Ephemeral",
    "long",
    "linear",
    "bladed",
    "segmented",
    "hollow",
    "cylindrical",
    "calcareous",
    "Frosted",
    "Pleated",
    "Tall",
    "thrice-dissected",
]