export const polarBearFaceSentences = [
    "Pudgy blue face with a beak-like nose",
"Stubby bird legs poking out below a furry belly",
"Playful eyes framed by shaggy white eyebrows",
"Oversized white paws with black claws"

]

export const polarBearBodySentences = [
    "It has two furry ears and a long tail.",
"It has two short, stubby legs with big, webbed feet that are perfect for swimming.",
"It has a thick coat of blue fur that keeps it warm",
"it's fur is thick and luscious.",
"it's eyes are piercing and cold.",
"it's blue body is covered in soft, white fur.",
"Its long, bird-like feet are clawless and webbed, allowing it to swim effortlessly through the water.",
"it's bird feet help it walk across the slippery ice.",
"it's feet are webbed, helping it to swim.",
]

export const polarBearSoundWords = [
"Swishing",
"Slurping",
"Snorting",
"Sniffing",
"Puffing",
"Growling",
"Rumbling",
"Roaring",
"Waddling",
"Stomping",
"Swishing",
"Sloshing",
"Snorting",
"Snuffling",
"Hooting",
"Whimpering",
"Grunting",
"Roaring",
"Slurping",
"Snorting",
"Growling",
"Waddling",
"Shuffling",
"Sniffing",
"Chuckling",
"Grunting",
"Rumbling",
"Howling",
"Roaring",
"Snarling",
"Flapping",
"Screeching",
"howling",
"clawing",
"growling"
]

export const polarBearFurWords = [
    "polished", "sleek", "scruffy", "powdery", "downy", "tufted", "fluffy", "soft", "cuddly", "adorable",
"soft",
"cuddly",
"friendly",
"playful",
"curious",
"sleepy",
"blue",
"feathery",
"majestic",
"serene",
"Calcified ",
"Cerulean ",
"Crystalline ",
"Glacial ",
"Lapis lazuli ",
"Marine ",
"Navy ",
"Oceanic ",
"Sapphire ",
"Turquoise",
"blue",
"furry",
"feathered",
"arctic",
"snowy",
"white",
"cold",
"Antarctic",
"marine",
"oceanic",
"Huge", "blue", "furry", "enormous", "intimidating", "delicate", "unique", "beautiful", "majestic", "rare",
]

export const polarBearSmellWords = [
"Majestic",
"Playful",
"Harbinger of Spring",
"Crystal-blue",
"Serene",
"Crisp",
"Bright",
"Glittering",
"Stunning",
"Mysterious",
"Outlandish",
"Incredible",
"Majestic",

]

export const polarBearBehaviourSentences = [
"it is standing on its hind legs, its front paws stretched out in front of it.",
"it is walking awkwardly on its hind legs.",
"it is staring at you with its beady blue eyes",
"it is making strange grunting noises",
"it is slowly walking towards you",
"it is getting closer and closer",
"it is looking at you with its beady eyes.",
"it is moving its head from side to side.",
"it is standing up on its hind legs.",
"it is looking at you with its disturbingly blue eyes.",

]