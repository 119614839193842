import React from "react";
import { connect } from 'react-redux'

import { selectWritingSurfaceRecipes } from '../../../redux/selectors';

import { client } from '../../../services/client';

class WritingSurfaceRecipes extends React.Component {

    render() {
        const writingSurfaceItems = this.props.writingSurfaceRecipes
            .filter(toolRecipe => (toolRecipe.writingSurfaceType?.name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase()) > -1))
            .sort((a, b) => (a.writingSurfaceType?.name.localeCompare(b.writingSurfaceType?.name)))
            .map((toolRecipe) => {
                return (
                    <li key={toolRecipe._id}
                        onClick={() => {
                            return (this.props.onItemSelect(toolRecipe))
                        }}>
                        <span className={this.props.selectedItem?._id === toolRecipe._id ? "selected": ""}>{toolRecipe.writingSurfaceType.name}</span>
                    </li>
                );
            })
        return (
            <ul>{writingSurfaceItems}</ul>
        )
    }
}

const mapStateToProps = state => {
    const writingSurfaceRecipes = selectWritingSurfaceRecipes(state)

    return { writingSurfaceRecipes }
}

export default connect(
    mapStateToProps,
    {  }
)(WritingSurfaceRecipes);