import {
  CONNECTED,
  DISCONNECTED,
  RECONNECTED,
  CONNECTION_ERROR,
} from '../actionTypes';

const initialState = {
  connectionState: undefined,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CONNECTED: {
      return {
        ...state,
        connectionState: 'CONNECTED'
      }
    }

    case DISCONNECTED: {
      return {
        ...state,
        connectionState: 'DISCONNECTED'
      }
    }

    case RECONNECTED: {
      return {
        ...state,
        connectionState: 'RECONNECTED'
      }
    }

    case CONNECTION_ERROR: {
      return {
        ...state,
        connectionState: 'CONNECTION_ERROR'
      }
    }

    default:
      return state;
  }
}