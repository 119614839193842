export const ordealSmellTypes = [
    "of woodworking",
    "of dirty footwear",
    "of refuse",
    "swampy",
    "pungent",
    "muddy",
    "rotting",
    "decaying",
    "dank",
    "moldy",
    "stagnant",
    "putrid",
    "wooden",
    "rotten",
    "of death",
    "of decay",
    "alive",
    "fresh",
    "new",
    "fragrant",
    "of Mildew",
    "Sour",
    "Stale",
    "Putrid",
    "Rancid",
    "soberly amber",
    "woody and pepper",
    "of flame-licked bark",
    "harmless",
    "of palisander rosewood",
    "of spearmint and hinoki",
    "of wistful reminiscing",
    "of an embarassing thought",
    "of bright forests you saw in a picture",
    "of lush screensavers",
    "of tobacco-smeared rotten log",
    "of calm cypress",
    "of stinging smoke",
    "of zest and earth",
    "of verdant triumph",
    "of character-building tribulations",
    "overbearing",
    "of a yeast extract",
    "coniferous and harsh",
    "of tension from unseen eyes",
    "of olibanum and yuzu",
    "of a ready meal",
    "of waxed orange",
    "grounding",
    "a recently vacated nest",
    "of animal musk above you",
]

export const ordealBranchTypes = [
    "limp",
    "dangle",
    "useless",
    "slender",
    "straight",
    "rigid",
    "unwavering",
    "inflexible",
    "twig",
    "prickle",
    "thorny",
    "scratchy",
    "splintered",
    "spooky",
    "dreary",
    "frightful",
    "ghostly",
    "grisly",
    "gruesome",
    "hideous",
    "macabre",
    "monstrous",
    "coarse",
    "straight-grained bark and long",
    "red",
    "crimson",
    "lustrous",
    "durable",
    "pliable",
    "insect-gnawed",
    "dry",
    "damp",
    "rain-soaked",
    "dew-dripping",
    "water-rich",
    "powdered",
    "a buttressed trunk with large",
    "red, shiny",
    "hollows in its",
    "aromatically oiled",
    "aromatic",
    "scented",
    "weedy",
]

export const ordealLeafTypes = [
    "Tubular", 
    "bipinnate-veined",
    "verdure",
    "ostentatious",
    "rain-catching",
    "broad",
    "waxy",
    "leathery",
    "thick",
    "blue-green",
    "bluish",
    "blue-tinged",
    "green",
    "vibrant",
    "healthy",
    "wholesome",
    "pointed",
    "oval",
    "bunching"
]