import { CHANGE_CHARACTER, START_NEW_GAME, MOVE_ITEM_FAILURE, UNKNOWN_ERROR, CREATE_MESSAGE_SUCCESS, GET_MESSAGES_SUCCESS, GET_MORE_MESSAGES_SUCCESS, ADD_NEW_MESSAGE, ADD_MESSAGE_OPTIONS, CLEAR_MESSAGE_OPTIONS } from "../actionTypes";

const initialState = {
  messages: [],
  totalMessages: 0,
  messageOptions: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_CHARACTER:
    case START_NEW_GAME: {
      return { ...initialState }
    }
    case GET_MESSAGES_SUCCESS: {
      return {
        ...state,
        totalMessages: action.payload.total,
        messages: [...action.payload.data],
      }
    }

    case MOVE_ITEM_FAILURE:{
      return {
        ...state,
        totalMessages: state.totalMessages + 1,
        messages: [...state.messages, { text: 'Move item failure', characterName: 'System', _id: '' + new Date().getTime(), createdAt: new Date().getTime() }]
      }
    }

    case UNKNOWN_ERROR :{
      return {
        ...state,
        totalMessages: state.totalMessages + 1,
        messages: [...state.messages, { text: action.payload?.message || '', characterName: 'System', _id: '' + new Date().getTime(), createdAt: new Date().getTime() }]
      }
    }

    case CREATE_MESSAGE_SUCCESS: {
      if (action.payload?.type === 'TALK_TO_CHARACTER') {
        return {
          ...state,
          totalMessages: state.totalMessages + 1,
          messages: [...state.messages, { ...action.payload }],
        }
      }
    }

    case GET_MORE_MESSAGES_SUCCESS: {
      if (!action.payload.data || state.messages.find(message => (message._id === action.payload.data._id))) {
        return state;
      }

      return {
        ...state,
        totalMessages: action.payload.total,
        messages: [...state.messages, ...action.payload.data],
      }
    }

    case ADD_NEW_MESSAGE: {
      // check for duplicates
      if (state.messages.find(message => message._id === action.payload.message._id)) {
        return {
          ...state
        }
      }

      return {
        ...state,
        totalMessage: state.totalMessages + 1,
        messages: [ ...state.messages, { ...action.payload.message } ]
      }
    }

    case ADD_MESSAGE_OPTIONS: {
      return {
        ...state,
        messageOptions: [ ...action.payload.options ],
      }
    }

    case CLEAR_MESSAGE_OPTIONS: {
      return {
        ...state,
        messageOptions: [],
      }
    }

    default:
      return state;
  }
}
