import seedrandom from 'seedrandom';
import tileMap from './tile-map.js';
import { isWithinUserVision } from './night-radius';

export const PANEL_WIDTH = 48;
export const PANEL_HEIGHT = 25;

const RELATIVE_POSITION = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
    TOP: 'TOP',
    BOTTOM: 'BOTTOM',
};

export function addRidges(panel, floor, characterPosition) {
    if (panel.isOcean) {
        return;
    }

    function getHeight(elevation) {
        return Math.floor(elevation/10);
    }

    const { neighbours } = panel;

    // so we move the borders down and to the right
    const leftPanelHeight = getHeight(neighbours.west.elevation);
    const topPanelHeight = getHeight(neighbours.north.elevation);
    const topLeftPanelHeight = getHeight(neighbours.northWest.elevation);

    const panelHeight = getHeight(panel.elevation);

    /*
          y+
     x- __|______ x+
          |
          |
          |
          y-
    */
    const isXMinusShowing = topLeftPanelHeight !== leftPanelHeight;
    const isYPlusShowing = topLeftPanelHeight !== topPanelHeight;
    const isXPlusShowing = topPanelHeight !== panelHeight;
    const isYMinusShowing = leftPanelHeight !== panelHeight;

    if (!isXMinusShowing && !isYPlusShowing && !isXPlusShowing && !isYMinusShowing) {
        return;
    }

    const panelSeed = seedrandom('RIDGE' + (panel.x) + (panel.y));
    const northPanelSeed = seedrandom('RIDGEX' + (panel.x) + (panel.y - 1));
    const westPanelSeed = seedrandom('RIDGE' + (panel.x - 1) + (panel.y));

    const yZero = randomNumberBetween(PANEL_HEIGHT/2, westPanelSeed) + PANEL_HEIGHT/4;
    const yEnd = randomNumberBetween(PANEL_HEIGHT/2, seedrandom('RIDGE' + (panel.x) + (panel.y))) + PANEL_HEIGHT/4;
    const xZero = randomNumberBetween(PANEL_WIDTH/2, northPanelSeed) + PANEL_WIDTH/4;
    const xEnd = randomNumberBetween(PANEL_WIDTH/2, seedrandom('RIDGEX' + (panel.x) + (panel.y))) + PANEL_WIDTH/4;

    const crossCoords = { x: randomNumberBetween(PANEL_WIDTH/2, panelSeed) + PANEL_WIDTH/4, y: randomNumberBetween(PANEL_HEIGHT/2, panelSeed) + PANEL_HEIGHT/4}

    let xMinusPoints;
    let yPlusPoints;
    let xPlusPoints;
    let yMinusPoints;

    // DEV
    const rng = seedrandom('rng' + panel.x + panel.y);
    const ridgeDensity = 0.4;
    // END DEV

    if (isXMinusShowing) {
        const fillInX = true;
        const points = generateCurve([0, yZero, crossCoords.x, crossCoords.y], fillInX);
        xMinusPoints = points;

        for(let i=0; i < points.length-1; i+=2) {
            const floorTile = panel.floorLayer.find(floorTile => floorTile.x === points[i] && floorTile.y === points[i+1]);

            // if (topLeftPanelHeight > leftPanelHeight) {
            //     floor.putTileAt(tileMap['ARROW_UP'], points[i], points[i+1]);
            // } else {
            //     floor.putTileAt(tileMap['ARROW_DOWN'], points[i], points[i+1]);
            // }

            // if (floorTile) {
            if (rng() < ridgeDensity) {
                floor.putTileAt(tileMap['CLIFF'], Math.round(points[i]), Math.round(points[i+1]));
            }
        }
    }

    if (isYPlusShowing) {
        const fillInX = false;
        const points = generateCurve([xZero, 0, crossCoords.x, crossCoords.y], fillInX);
        yPlusPoints = points;

        for(let i=0; i < points.length-1; i+=2) {
            const floorTile = panel.floorLayer.find(floorTile => floorTile.x === points[i] && floorTile.y === points[i+1]);

            // if (topLeftPanelHeight > topPanelHeight) {
            //     floor.putTileAt(tileMap['ARROW_LEFT'], points[i], points[i+1]);
            // } else {
            //     floor.putTileAt(tileMap['ARROW_RIGHT'], points[i], points[i+1]);
            // }

            // if (floorTile) {
            if (rng() < ridgeDensity) {
                floor.putTileAt(tileMap['CLIFF'], Math.round(points[i]), Math.round(points[i+1]));
            }
        }
    }

    if (isXPlusShowing) {
        const fillInX = true;
        const points = generateCurve([crossCoords.x, crossCoords.y, PANEL_WIDTH, yEnd], fillInX);
        xPlusPoints = points;

        for(let i=0; i < points.length-1; i+=2) {
            const floorTile = panel.floorLayer.find(floorTile => floorTile.x === points[i] && floorTile.y === points[i+1]);

            // if (topPanelHeight > panelHeight) {
            //     floor.putTileAt(tileMap['ARROW_UP'], points[i], points[i+1]);
            // } else {
            //     floor.putTileAt(tileMap['ARROW_DOWN'], points[i], points[i+1]);
            // }
            
            // if (floorTile) {
            if (rng() < ridgeDensity) {
                floor.putTileAt(tileMap['CLIFF'], Math.round(points[i]), Math.round(points[i+1]));
            }
        }
    }

    if (isYMinusShowing) {
        const fillInX = false;
        const points = generateCurve([crossCoords.x, crossCoords.y, xEnd, PANEL_HEIGHT], fillInX);
        yMinusPoints = points;

        for(let i=0; i < points.length-1; i+=2) {
            const floorTile = panel.floorLayer.find(floorTile => floorTile.x === points[i] && floorTile.y === points[i+1]);

            // if (leftPanelHeight > panelHeight) {
            //     floor.putTileAt(tileMap['ARROW_LEFT'], points[i], points[i+1]);
            // } else {
            //     floor.putTileAt(tileMap['ARROW_RIGHT'], points[i], points[i+1]);
            // }

            // if (floorTile) {
            if (rng() < ridgeDensity) {
                floor.putTileAt(tileMap['CLIFF'], Math.round(points[i]), Math.round(points[i+1]));
            }
        }
    }

    return {
        yMinusPoints,
        yPlusPoints,
        xMinusPoints,
        xPlusPoints,
        topLeftPanelHeight,
        topPanelHeight,
        leftPanelHeight,
        panelHeight
    }
}

export function addRivers(panel, floor) {
    if (!panel.riverSize && !panel.isSwamp) {
        return;
    }

    function countNotRivers(panel) {
        let count = 4;

        if (panel.neighbours.north.riverSize) {
            count--;
        }

        if (panel.neighbours.east.riverSize) {
            count--;
        }

        if (panel.neighbours.south.riverSize) {
            count--;
        }

        if (panel.neighbours.west.riverSize) {
            count--;
        }

        return count;
    }

    function getRiverSize(panel, sourceSize, sourcePanel) {
        if (panel.isOcean || panel.isSwamp) {
            return sourceSize
        }

        if (sourcePanel.isSwamp) {
            return panel.riverSize
        }

        if (countNotRivers(sourcePanel) > 1 && panel.riverSize) {
            return sourcePanel.riverSize;
        }

        return panel.riverSize;
    }

    const { neighbours } = panel;

    const isXMinusShowing = getRiverSize(neighbours.west, panel.riverSize, panel);
    const isYPlusShowing = getRiverSize(neighbours.north, panel.riverSize, panel);
    const isXPlusShowing = getRiverSize(neighbours.east, panel.riverSize, panel);
    const isYMinusShowing = getRiverSize(neighbours.south, panel.riverSize, panel);

    const panelSeed = seedrandom('WATER' + (panel.x) + (panel.y));
    const northPanelSeed = seedrandom('WATER' + (panel.x) + (panel.y - 1));
    const westPanelSeed = seedrandom('WATER' + (panel.x - 1) + (panel.y));

    const xZero = randomNumberBetween(PANEL_HEIGHT/2, westPanelSeed) + Math.round(PANEL_HEIGHT/4);
    const xEnd = randomNumberBetween(PANEL_HEIGHT/2, seedrandom('WATER' + (panel.x) + (panel.y))) + Math.round(PANEL_HEIGHT/4);
    const yZero = randomNumberBetween(PANEL_WIDTH/2, northPanelSeed) + Math.round(PANEL_WIDTH/4);
    const yEnd = randomNumberBetween(PANEL_WIDTH/2, seedrandom('WATER' + (panel.x) + (panel.y))) + Math.round(PANEL_WIDTH/4);

    let widestRiver = 0;

    const rng = seedrandom(undefined);
    const crossCoords = { x: randomNumberBetween(PANEL_WIDTH - 10, panelSeed) + 5, y: randomNumberBetween(PANEL_HEIGHT - 10, panelSeed) + 5 }

    if (isXMinusShowing) {
        if (isXMinusShowing > widestRiver) {
            widestRiver = isXMinusShowing;
        }
        const fillInX = true;
        const points = generateCurve([0, xZero, crossCoords.x, crossCoords.y], fillInX);

        floor.putTileAt('WATER', 0, xZero);

        for (var x=0; x < isXMinusShowing; x++) {
            let _x = 0;

            if (x % 2 === 0) {
                _x += Math.round(x / 2);
            } else {
                _x -= Math.round(x / 2);
            }

            for(let i=0; i < points.length-1; i+=2) {
                floor.putTileAt('WATER', Math.round(points[i]), Math.round(points[i+1] + _x));
            }
        }
    }

    if (isYPlusShowing) {
        if (isYPlusShowing > widestRiver) {
            widestRiver = isYPlusShowing;
        }
        const fillInX = false;
        const points = generateCurve([yZero, 0, crossCoords.x, crossCoords.y], fillInX);

        floor.putTileAt('WATER', yZero, 0);

        for (var y=0; y < isYPlusShowing; y++) {
            let _y = 0;

            if (y % 2 === 0) {
                _y += Math.round(y / 2);
            } else {
                _y -= Math.round(y / 2);
            }

            for(let i=0; i < points.length-1; i+=2) {
                floor.putTileAt('WATER', Math.round(points[i] + _y), Math.round(points[i+1]));
            }
        }
    }

    if (isXPlusShowing) {
        if (isXPlusShowing > widestRiver) {
            widestRiver = isXPlusShowing;
        }
        const fillInX = true;
        const points = generateCurve([crossCoords.x, crossCoords.y, PANEL_WIDTH, xEnd], fillInX);

        floor.putTileAt('WATER', PANEL_WIDTH - 1, xEnd);

        for (var x=0; x < isXPlusShowing; x++) {
            let _x = 0;

            if (x % 2 === 0) {
                _x += Math.round(x / 2);
            } else {
                _x -= Math.round(x / 2);
            }

            for(let i=0; i < points.length-1; i+=2) {
                floor.putTileAt('WATER', Math.round(points[i]), Math.round(points[i+1] + _x));
            }
        }

    }

    if (isYMinusShowing) {
        if (isYMinusShowing > widestRiver) {
            widestRiver = isYMinusShowing;
        }
        const fillInX = false;
        const points = generateCurve([crossCoords.x, crossCoords.y, yEnd, PANEL_HEIGHT], fillInX);

        floor.putTileAt('WATER', yEnd, PANEL_HEIGHT - 1);

        for (var y=0; y < isYMinusShowing; y++) {
            let _y = 0;

            if (y % 2 === 0) {
                _y += Math.round(y / 2);
            } else {
                _y -= Math.round(y / 2);
            }

            for(let i=0; i < points.length-1; i+=2) {
                floor.putTileAt('WATER', Math.round(points[i] + _y), Math.round(points[i+1]));
            }
        }
    }

    if (widestRiver > 2) {
        floor.putTileAt('WATER', crossCoords.x - 1, crossCoords.y - 1);
        floor.putTileAt('WATER', crossCoords.x + 1, crossCoords.y - 1);
        floor.putTileAt('WATER', crossCoords.x + 1, crossCoords.y + 1);
        floor.putTileAt('WATER', crossCoords.x - 1, crossCoords.y + 1);
    }

    if (widestRiver > 4) {
        floor.putTileAt('WATER', crossCoords.x - 1, crossCoords.y - 2);
        floor.putTileAt('WATER', crossCoords.x - 2, crossCoords.y - 1);
        floor.putTileAt('WATER', crossCoords.x + 1, crossCoords.y - 2);
        floor.putTileAt('WATER', crossCoords.x + 2, crossCoords.y - 1);
        floor.putTileAt('WATER', crossCoords.x + 1, crossCoords.y + 2);
        floor.putTileAt('WATER', crossCoords.x + 2, crossCoords.y + 1);
        floor.putTileAt('WATER', crossCoords.x - 1, crossCoords.y + 2);
        floor.putTileAt('WATER', crossCoords.x - 2, crossCoords.y + 1);
    }

    if (widestRiver > 6) {
        floor.putTileAt('WATER', crossCoords.x - 1, crossCoords.y - 3);
        floor.putTileAt('WATER', crossCoords.x - 3, crossCoords.y - 1);
        floor.putTileAt('WATER', crossCoords.x - 2, crossCoords.y - 2);
        floor.putTileAt('WATER', crossCoords.x + 1, crossCoords.y - 3);
        floor.putTileAt('WATER', crossCoords.x + 3, crossCoords.y - 1);
        floor.putTileAt('WATER', crossCoords.x + 2, crossCoords.y - 2);
        floor.putTileAt('WATER', crossCoords.x + 1, crossCoords.y + 3);
        floor.putTileAt('WATER', crossCoords.x + 3, crossCoords.y + 1);
        floor.putTileAt('WATER', crossCoords.x + 2, crossCoords.y + 2);
        floor.putTileAt('WATER', crossCoords.x - 1, crossCoords.y + 3);
        floor.putTileAt('WATER', crossCoords.x - 3, crossCoords.y + 1);
        floor.putTileAt('WATER', crossCoords.x - 2, crossCoords.y + 2);
    }
}

export function addCoast(panel, floor) {
    let { neighbours } = panel;

    if (!neighbours) {
        return;
    }

    const panelSeed = seedrandom('OCEAN' + (panel.x) + (panel.y));
    const northPanelSeed = seedrandom('OCEAN' + (panel.x) + (panel.y - 1));
    const westPanelSeed = seedrandom('OCEAN' + (panel.x - 1) + (panel.y));

    let yZero = randomNumberBetween(PANEL_HEIGHT / 2, westPanelSeed);
    let yEnd = randomNumberBetween(PANEL_HEIGHT / 2, seedrandom('OCEAN' + (panel.x) + (panel.y)));
    let xZero = randomNumberBetween(PANEL_WIDTH / 2, northPanelSeed);
    let xEnd = randomNumberBetween(PANEL_WIDTH / 2, seedrandom('OCEAN' + (panel.x) + (panel.y)));

    yZero += PANEL_HEIGHT / 4;
    yEnd += PANEL_HEIGHT / 4;
    xZero += PANEL_WIDTH / 4;
    xEnd += PANEL_WIDTH / 4;

    const eighthHeight = (PANEL_HEIGHT / 8)
    const eighthWidth = PANEL_WIDTH / 8;

    if (neighbours.north.isOcean) {
        // points are moved UP 1/4
        const points = generateCurve([0, yZero - eighthHeight, PANEL_WIDTH, yEnd - eighthHeight], true)

        for(let i=0; i < points.length-1; i+=2) {
            floor.putTileAt('OCEAN', Math.round(points[i]), Math.round(points[i+1]));

            // fill in the ocean
            for (let k=0; k < Math.round(points[i+1]); k++) {
                // floor.putTileAt('OCEAN', Math.round(points[i]), Math.round(points[i+1] - k - 1));
            }
        }
    }

    if (neighbours.east.isOcean) {
        // points are moved RIGHT 1/4
        const points = generateCurve([xZero, 0, xEnd + eighthWidth, PANEL_HEIGHT], false);

        for(let i=0; i < points.length-1; i+=2) {
            floor.putTileAt('OCEAN', Math.round(points[i]), Math.round(points[i+1]));

            // fill in the ocean
            for (let k=Math.round(points[i]) + 1; k < PANEL_WIDTH; k++) {
                // floor.putTileAt('OCEAN', k, Math.round(points[i+1]));
            }
        }
    }

    if (neighbours.south.isOcean) {
        // points are moved DOWN 1/4
        const points = generateCurve([0, yZero + eighthHeight, PANEL_WIDTH, yEnd + eighthHeight], true);

        for(let i=0; i < points.length-1; i+=2) {
            floor.putTileAt('OCEAN', Math.round(points[i]), Math.round(points[i+1]));

            // fill in the ocean
            for (let k=Math.round(points[i+1]); k < PANEL_HEIGHT; k++) {
                // floor.putTileAt('OCEAN', Math.round(points[i]), k);
            }
        }
    }

    if (neighbours.west.isOcean) {
        // points are moved LEFT 1/4
        const points = generateCurve([xZero - eighthWidth, 0, xEnd - eighthWidth, PANEL_HEIGHT], false);

        for(let i=0; i < points.length-1; i+=2) {
            floor.putTileAt('OCEAN', Math.round(points[i]), Math.round(points[i+1]));

            // fill in the ocean
            for (let k=0; k < Math.round(points[i]); k++) {
                if (Math.round(points[i]) - k - 1 < 0) {
                    console.log('hello?');
                    return;
                }

                if (Math.round(points[i+1]) < 0) {
                    console.log('hello?');
                    return;
                }
                floor.putTileAt('OCEAN', Math.round(points[i]) - k - 1, Math.round(points[i+1]));
            }
        }
    }

    // add nubbins
    if (!neighbours.north.isOcean && !neighbours.east.isOcean && neighbours.northEast.isOcean) {
        const points = generateCurve([xZero + eighthWidth, 0, PANEL_WIDTH, yEnd - eighthHeight], false);

        for(let i=0; i < points.length-1; i+=2) {
            floor.putTileAt('OCEAN', Math.round(points[i]), Math.round(points[i+1]));

            // fill in the ocean
            for (let k=Math.round(points[i]); k < PANEL_WIDTH; k++) {
                // floor.putTileAt('OCEAN', k, Math.round(points[i + 1]));
            }
        }
    }

    if (!neighbours.north.isOcean && !neighbours.west.isOcean && neighbours.northWest.isOcean) {

        const points = generateCurve([0, yZero - eighthHeight, xZero - eighthWidth, 0], false);

        for(let i=0; i < points.length-1; i+=2) {
            floor.putTileAt('OCEAN', Math.round(points[i]), Math.round(points[i+1]));

            // fill in the ocean
            for (let k=0; k < Math.round(points[i]); k++) {
                // floor.putTileAt('OCEAN', k, Math.round(points[i + 1]));
            }
        }
    }

    if (!neighbours.south.isOcean && !neighbours.east.isOcean && neighbours.southEast.isOcean) {

        const points = generateCurve([xEnd + eighthWidth, PANEL_HEIGHT, PANEL_WIDTH, yEnd + eighthHeight], false);

        for(let i=0; i < points.length-1; i+=2) {
            floor.putTileAt('OCEAN', Math.round(points[i]), Math.round(points[i+1]));

            // fill in the ocean
            for (let k=Math.round(points[i]); k < PANEL_WIDTH; k++) {
                // floor.putTileAt('OCEAN', k, Math.round(points[i + 1]));
            }
        }
    }


    if (!neighbours.south.isOcean && !neighbours.west.isOcean && neighbours.southWest.isOcean) {

        const points = generateCurve([0, yZero + eighthHeight, xEnd - eighthWidth, PANEL_HEIGHT], false);


        for(let i=0; i < points.length-1; i+=2) {
            floor.putTileAt('OCEAN', Math.round(points[i]), Math.round(points[i+1]));

            // fill in the ocean
            for (let k=0; k < Math.round(points[i]); k++) {
                // floor.putTileAt('OCEAN', k, Math.round(points[i + 1]));
            }
        }
    }
}

export function addLake(panel, floor) {
    const { neighbours } = panel;

    if (!neighbours || !panel.isSwamp) {
        return;
    }

    const panelSeed = seedrandom('LAKE' + (panel.x) + (panel.y));

    const firstPointCoords = { x: randomNumberBetween(PANEL_WIDTH / 2, panelSeed), y: (randomNumberBetween(PANEL_HEIGHT/3, panelSeed) + Math.round(PANEL_HEIGHT / 2)) }
    const secondPointCoords = { x: randomNumberBetween(PANEL_WIDTH/2, panelSeed) + PANEL_WIDTH / 2, y: randomNumberBetween(PANEL_HEIGHT/2, panelSeed) }
    const thirdPointCoords = { x: randomNumberBetween(PANEL_WIDTH/2, panelSeed), y: randomNumberBetween(PANEL_HEIGHT/2, panelSeed) }
    const fourthPointCoords = { x: randomNumberBetween(PANEL_WIDTH/2, panelSeed) + PANEL_WIDTH / 2, y: (randomNumberBetween(PANEL_HEIGHT/3, panelSeed) + Math.round(PANEL_HEIGHT / 2)) }

    floor.putTileAt('WATER', firstPointCoords.x, firstPointCoords.y);
    floor.putTileAt('WATER', secondPointCoords.x, secondPointCoords.y);
    floor.putTileAt('WATER', thirdPointCoords.x, thirdPointCoords.y);
    floor.putTileAt('WATER', fourthPointCoords.x, fourthPointCoords.y);

    const pointCoords = [];

    const points = generateCurve([
        firstPointCoords.x, firstPointCoords.y,
        thirdPointCoords.x, thirdPointCoords.y,
        secondPointCoords.x, secondPointCoords.y,
        fourthPointCoords.x, fourthPointCoords.y,
    ], true, 0.3, true, 30);

    for(let i=0; i < points.length-1; i+=2) {
        floor.putTileAt('WATER', Math.round(points[i]), Math.round(points[i+1]));

        pointCoords.push({ x: points[i], y: points[i+1]})
    }

    for(let x=0; x < PANEL_WIDTH; x++) {
        for (let y=0; y < PANEL_HEIGHT; y++) {
            //if there is a lake in every direction, we are inside the lake.

            const above = pointCoords.find(coord => (coord.x === x && coord.y < y));
            const right = pointCoords.find(coord => (coord.x > x && coord.y === y));
            const below = pointCoords.find(coord => (coord.x === x && coord.y > y));
            const left = pointCoords.find(coord => (coord.x < x && coord.y === y));

            if (above && right && below && left) {
                floor.putTileAt('WATER', x, y);
            }
        }
    }
}

export function randomNumberBetween(max, seed) {
    if (!seed) {
        return Math.floor(Math.random() * max) + 1;
    }

    return Math.floor(seed() * max) + 1;
}

export function generateCurve(ptsa, fillInX, tension, isClosed, numOfSegments, showPoints) {

    showPoints  = showPoints ? showPoints : false;

    const points = getCurvePoints(ptsa, tension, isClosed, numOfSegments, fillInX);

    return points;
}

function getCurvePoints(pts, tension, isClosed, numOfSegments, fillInX) {
    // copied from here https://stackoverflow.com/questions/7054272/how-to-draw-smooth-curve-through-n-points-using-javascript-html5-canvas

    // use input value if provided, or use a default value   
    tension = (typeof tension != 'undefined') ? tension : 0.5;
    isClosed = isClosed ? isClosed : false;
    numOfSegments = numOfSegments ? numOfSegments : 16;
    fillInX = fillInX ? fillInX : false; // default to filling in all missing y numbers

    var _pts = [], res = [],    // clone array
        x, y,           // our x,y coords
        t1x, t2x, t1y, t2y, // tension vectors
        c1, c2, c3, c4,     // cardinal points
        st, t, i;       // steps based on num. of segments

    // clone array so we don't change the original
    //
    _pts = pts.slice(0);

    // The algorithm require a previous and next point to the actual point array.
    // Check if we will draw closed or open curve.
    // If closed, copy end points to beginning and first points to end
    // If open, duplicate first points to befinning, end points to end
    if (isClosed) {
        _pts.unshift(pts[pts.length - 1]);
        _pts.unshift(pts[pts.length - 2]);
        _pts.unshift(pts[pts.length - 1]);
        _pts.unshift(pts[pts.length - 2]);
        _pts.push(pts[0]);
        _pts.push(pts[1]);
    }
    else {
        _pts.unshift(pts[1]);   //copy 1. point and insert at beginning
        _pts.unshift(pts[0]);
        _pts.push(pts[pts.length - 2]); //copy last point and append
        _pts.push(pts[pts.length - 1]);
    }

    // ok, lets start..

    // 1. loop goes through point array
    // 2. loop goes through each segment between the 2 pts + 1e point before and after
    for (i=2; i < (_pts.length - 4); i+=2) {
        for (t=0; t <= numOfSegments; t++) {

            // calc tension vectors
            t1x = (_pts[i+2] - _pts[i-2]) * tension;
            t2x = (_pts[i+4] - _pts[i]) * tension;

            t1y = (_pts[i+3] - _pts[i-1]) * tension;
            t2y = (_pts[i+5] - _pts[i+1]) * tension;

            // calc step
            st = t / numOfSegments;

            // calc cardinals
            c1 =   2 * Math.pow(st, 3)  - 3 * Math.pow(st, 2) + 1; 
            c2 = -(2 * Math.pow(st, 3)) + 3 * Math.pow(st, 2); 
            c3 =       Math.pow(st, 3)  - 2 * Math.pow(st, 2) + st; 
            c4 =       Math.pow(st, 3)  -     Math.pow(st, 2);

            // calc x and y cords with common control vectors
            x = c1 * _pts[i]    + c2 * _pts[i+2] + c3 * t1x + c4 * t2x;
            y = c1 * _pts[i+1]  + c2 * _pts[i+3] + c3 * t1y + c4 * t2y;

            //store points in array
            res.push(x);
            res.push(y);

        }
    }

    // end copy pasted code

    // fill in missing points
    let newRes = [];

    for(let i=2; i < res.length-1; i+=2) {
        const lastPoint = [Math.round(res[i - 2]), Math.round(res[i-1])];
        const currentPoint = [Math.round(res[i]), Math.round(res[i+1])];

        newRes.push(...lastPoint);

        if (fillInX) {
            const missingPoints = Math.round(currentPoint[0] - lastPoint[0]);

            for(var k=1; k < missingPoints; k++) {
                newRes.push(lastPoint[0] + k, lastPoint[1]);
            }

            newRes.push(currentPoint[0], lastPoint[1]);
        } else {
            // fill in y
            const missingPoints = Math.round(currentPoint[1] - lastPoint[1])

            for(var k=1; k < missingPoints; k++) {
                newRes.push(lastPoint[0], lastPoint[1] + k);
            }

            newRes.push(lastPoint[0], currentPoint[1]);
        }
    }

    return newRes;
}


export function getNeighbouringCliffs(cliff, cliffs) {
    let neighbours = '';

    const leftNeighbour = findNeighbourAt(RELATIVE_POSITION.LEFT, cliff, cliffs);
    if (leftNeighbour) {
        neighbours += 'LEFT.'
    }

    const rightNeighbour = findNeighbourAt(RELATIVE_POSITION.RIGHT, cliff, cliffs);
    if (rightNeighbour) {
        neighbours += 'RIGHT.'
    }

    const topNeighbour = findNeighbourAt(RELATIVE_POSITION.TOP, cliff, cliffs);
    if (topNeighbour) {
        neighbours += 'TOP.'
    }

    const bottomNeighbour = findNeighbourAt(RELATIVE_POSITION.BOTTOM, cliff, cliffs);
    if (bottomNeighbour) {
        neighbours += 'BOTTOM.'
    }

    if (neighbours === '' || !neighbours) {
        neighbours = 'NONE'
    }

    return neighbours
}

export function findNeighbourAt(relativePosition, { x, y }, cliffs) {
    if (relativePosition === RELATIVE_POSITION.LEFT) {
        return getCliffAtPosition({ x: x - 1, y: y }, cliffs);
    }

    if (relativePosition === RELATIVE_POSITION.RIGHT) {
        return getCliffAtPosition({ x: x + 1, y: y }, cliffs);
    }

    if (relativePosition === RELATIVE_POSITION.TOP) {
        return getCliffAtPosition({ x: x, y: y + 1 }, cliffs);
    }

    if (relativePosition === RELATIVE_POSITION.BOTTOM) {
        return getCliffAtPosition({ x: x, y: y - 1 }, cliffs);
    }
}

function getCliffAtPosition({ x, y }, cliffs) {
    return cliffs.find(cliff => (cliff.x === x && cliff.y === y));
}
