import React from "react";
import { connect } from 'react-redux'

import { selectMachineRecipes } from '../../../redux/selectors';

import { client } from '../../../services/client';

class MachineRecipes extends React.Component {

    render() {
        const listItems = this.props.machineRecipes
            .filter(machineRecipe => (machineRecipe.machineType?.name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase()) > -1))
            .sort((a, b) => (a.machineType?.name.localeCompare(b.machineType?.name)))
            .map((machineRecipe) => {
                return (
                    <li key={machineRecipe._id}
                        onClick={() => {
                            return (this.props.onItemSelect(machineRecipe))
                        }}>
                        <span className={this.props.selectedItem?._id === machineRecipe._id ? "selected": ""}>{machineRecipe.machineType.name}</span>
                    </li>
                );
            })
        return (
            <ul>{listItems}</ul>
        )
    }
}

const mapStateToProps = state => {
    const machineRecipes = selectMachineRecipes(state)

    return { machineRecipes }
}

export default connect(
    mapStateToProps,
    {  }
)(MachineRecipes);