import {
    GET_BOAT_RECIPES_LOADING,
    GET_BOAT_RECIPES_SUCCESS,
    GET_BOAT_RECIPES_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';

export const getBoatRecipesSuccess = payload => ({
    type: GET_BOAT_RECIPES_SUCCESS,
    payload
})

export const getBoatRecipesFailure = () => ({
    type: GET_BOAT_RECIPES_FAILURE
})

export const getBoatRecipesAsync = payload => dispatch => {
    return dispatch(getRecursivelyAsync('boat-recipes', getBoatRecipesSuccess, 0));
}