import React from "react";
import { connect } from 'react-redux'

import { client } from '../../../services/client';
import { selectHighlight, selectCharacter, selectNeighbouringDeadCharacters } from '../../../redux/selectors';
import { buryDeadCharacterAsync } from '../../../redux/actions/dead-characters.actions';

class DeadCharacters extends React.Component {

    componentDidMount() {
        
    }

    buryCharacter({ _id }) {
        this.props.buryDeadCharacterAsync({ _id })
    }

    render() {
        const characters = this.props.deadCharacters.map((character) => {
            return (
                <li key={character._id}>
                    <div>{character.name} [Dead{character.causeOfDeath && ` (${character.causeOfDeath})`}]</div>
                    <div className={this.props.highlight ? "hidden" : "action"} onClick={() => (this.buryCharacter(character))}>bury</div>
                </li>
            )
        })
        return (
            <ul className="dead-characters-list">
                {characters}
            </ul>
        )
    }
}

const mapToStateProps = state => {
    const highlight = selectHighlight(state);
    const character = selectCharacter(state);
    const deadCharacters = selectNeighbouringDeadCharacters(state, highlight ? { position: { ...highlight } } : character);

    return { highlight, deadCharacters }
}

export default connect(
    mapToStateProps,
    {
        buryDeadCharacterAsync,
    }
)(DeadCharacters);