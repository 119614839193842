import {
    COOK_FOOD_SUCCESS,
    COOK_FOOD_FAILURE,
    GET_CHARACTER_FOOD_SUCCESS,
    GET_CHARACTER_FOOD_FAILURE,
    EAT_FOOD_SUCCESS,
    EAT_FOOD_FAILURE,
    DELETE_FOOD_SUCCESS,
    DELETE_FOOD_FAILURE,
    READ_TILE_FOOD_SUCCESS,
    READ_TILE_FOOD_FAILURE,
    CLEAR_TILE_FOOD,
    MOVE_ITEM_FAILURE,
    ADD_FOOD,
    UPDATE_FOOD,
    REMOVE_FOOD,
    GET_TRADE_FOOD_SUCCESS,
    GET_TRADE_FOOD_FAILURE
} from '../actionTypes';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { colyClient } from '../../services/Panel-initialisation.service';

import { client } from '../../services/client';

import { moveItemFailure } from './move-item.actions';

export const cookFoodSuccess = payload => {
    return ({
        type: COOK_FOOD_SUCCESS,
        payload
    })
}

export const cookFoodFailure = payload => ({
    type: COOK_FOOD_FAILURE
})

export const cookFoodAsync = payload => dispatch => {
    const { _id, foodTypeId, selectedCharacterId, quantity } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'food-instances',
        name: 'create',
        data: {
            foodTypeId,
            selectedCharacterId,
            quantity
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const cookAnotherFoodAsync = payload => dispatch => {
    const { _id, recipeId, characterId, quantity, createdAt, foodTypeId, selectedCharacterId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'food-instances',
        name: 'create',
        data: {
            foodTypeId,
            selectedCharacterId
        },
    })

    // colyClient.room.send('doAction', {
    //     serviceName: 'food-instances',
    //     commandType: 'update',
    //     data: {
    //         _id,
    //         recipeId,
    //         characterId,
    //         createdAt,
    //         foodTypeId,
    //         quantity: quantity + 1
    //     },
    // })

    return new Promise((resolve, reject) => (resolve()))
}

export const getFoodSuccess = payload => {
    return ({
        type: GET_CHARACTER_FOOD_SUCCESS,
        payload
    })
}

export const getCharacterFoodFailure = payload => ({
    type: GET_CHARACTER_FOOD_FAILURE
})

export const getCharacterFoodAsync = payload => dispatch => {
    let query = { $or: [{ panelId: payload.panelId, z: payload.z}, {characterId: payload.characterId}] }

    if (payload.isNotFirstInitialisation) {
        query = { panelId: payload.panelId, z: payload.z}
    }

    return client.service('food-instances').find({ query })
        .then((response) => {
            response = response.map(food => ({ ...food, foodType: payload.foodTypes.find(type => type._id === food.foodTypeId) }))
            dispatch(getFoodSuccess(response));
        })
        .catch((error) => {
            console.error('error', error);
            dispatch(showUnknownError({ message: error.message }));
            throw new Error(error);
        })
}

export const getTradeFoodSuccess = payload => {
    return ({
        type: GET_TRADE_FOOD_SUCCESS,
        payload
    })
}

export const getTradeFoodFailure = payload => ({
    type: GET_TRADE_FOOD_FAILURE
})

export const getTradeFoodAsync = payload => dispatch => {
    return client.service('food-instances').find({ query: { characterId: payload.characterId, markedForTradeCount: { $gte: 1 } }})
        .then((response) => {
            response = response.map(food => ({ ...food, foodType: payload.foodTypes.find(type => type._id === food.foodTypeId) }))
            dispatch(getTradeFoodSuccess(response));
        })
        .catch((error) => {
            console.error('error', error);
            dispatch(showUnknownError({ message: error.message }));
            throw new Error(error);
        })
}

export const eatFoodSuccess = payload => {
    return ({
        type: EAT_FOOD_SUCCESS,
        payload
    })
}

export const eatFoodFailure = payload => ({
    type: EAT_FOOD_FAILURE
})

export const eatFoodAsync = payload => dispatch => {
    const { _id, recipeId, characterId, quantity, createdAt, foodTypeId, effect } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'food-instances',
        commandType: 'update',
        data: {
            _id,
            recipeId,
            characterId,
            createdAt,
            foodTypeId,
            quantity: quantity - 1,
            effect
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const deleteFoodSuccess = payload => {
    return ({
        type: DELETE_FOOD_SUCCESS,
        payload
    })
}

export const deleteFoodFailure = payload => ({
    type: DELETE_FOOD_FAILURE
})

export const deleteFoodAsync = payload => dispatch => {
    const { _id, recipeId, characterId, quantity, createdAt, foodTypeId } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'food-instances',
        commandType: 'remove',
        data: {
            _id,
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addFoodToTileAsync = payload => dispatch => {
    const { foodInstance, tileId, tileTypeId } = payload;
    const { createdAt, foodTypeId, quantity, recipeId, characterId, _id } = foodInstance;

    colyClient.room.send('doAction', {
        serviceName: 'food-instances',
        commandType: 'create',
        data: {
            createdAt,
            foodTypeId,
            quantity,
            recipeId,
            tileId,
            tileTypeId,
            characterId: ""
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getTileFoodSuccess = payload => {
    return ({
        type: READ_TILE_FOOD_SUCCESS,
        payload
    })
}

export const addFoodToCharacterAsync = payload => dispatch => {
    const { foodInstance, characterId } = payload;
    const { createdAt, foodTypeId, quantity, recipeId, _id } = foodInstance;

    colyClient.room.send('doSuperAction', {
        serviceName: 'food-instances',
        name: 'fetch',
        data: {
            foodTypeId,
            quantity,
            recipeId,
            tileId: "",
            characterId
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addFood = payload => {
    return ({
        type: ADD_FOOD,
        payload
    })
}

export const updateFood = payload => {
    return ({
        type: UPDATE_FOOD,
        payload
    })
}

export const removeFood = payload => {
    return ({
        type: REMOVE_FOOD,
        payload
    })
}
