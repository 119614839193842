export const sweetPotatoSmellTypes = [
    "of earth",
    "Candied",
    "of Butter",
    "of Vanilla",
    "Nutty",
    "of stewed sugar",
    "Rich",
    "of linen",
    "of wool",
    "of cashmere",
    "Bitter",
    "of Salt",
    "of Smoke",
    "of cream",
    "of mineral depths",
    "cave-like",
    "tuberose",
    "powdery and woody",
    "of stiff starches",
    "vintage",
    "edible",
    "of tangled roots",
    "muted",
    "offputting",
    "of a foggy morning",
    "of a comforting, enveloping mist",
    "soft and unassuming",
    "of milk powder",
    "of synthesised desserts",
    "of a warm roux",
    "of creamed vegetables",
    "of shredded tin",
    "of damp and dank minerals",
    "of buried fruits",
    "of artificial earth",
    "rancid",
    "of rotten oil",
    "of limp roots",
    "stomach-turning",
    "odd",
    "of cepes",
    "propolitic",
    "of dirt and earthen wares",
    "of rosewood",
    "of stony ground",
    "of barren lands",
    "of plucked toadstools",
    "of cream and tar",
    "of familiar paths that lead home",
    "of a feast eaten in nibbles",
    "of mountain roots",
]

export const sweetPotatoBranchTypes = [
	"wispy",
    "graceful",
    "delicate",
    "coarse",
    "soft",
    "spongy",
    "springy",
    "yielding",
    "vining",
    "twisted",
    "crawling",
    "sunshine-seeking",
    "sensitive",
    "drought-dry",
    "water-logged",
    "adventurous",
    "slip-rife",
    "stunted",
    "trampled",
    "leathery",
    "seed-spewing",
    "stiff",
    "tangled-up",
    "forked",
]

export const sweetPotatoLeafTypes = [
	"downy",
    "feathery",
    "furry",
    "hairy",
    "woolly",
    "silky",
    "velvety",
    "Silk",
    "Cashmere",
    "soft",
    "green"
    "triangle-shaped",
    "triangular",
    "palmately lobed",
    "screwed up",
    "heart-shaped",
    "egg-shaped",
    "rounded",
    "seven times lobed",
    "thrice lobed",
    "purplish green",
    "morning glories bloom from its",
    "finely haired",
    "membranous",
]