export const racoonFaceSentences = [

]

export const racoonBodySentences = [

]

export const racoonSoundWords = [
    "Scratching",
    "hissing",
    "growling",
    "snarling",
    "yowling",
    "spitting",
    "Hissing",
    "spitting",
    "Growling",
    "Snarling",
    "Hissing",
    "growling",
    "coughing",
    "snarling",
    "roaring",
    "yelping",
    "howling",
    "screaming",
    "screeching",
    "yipping",
    "Chirping",
    "Ruffling",
    "Snorting",
    "Hissing",
    "Grunting",
    "Growling",
    "Whimpering",
    "Wheezing",
    "Screeching",
    "Yowling"
]

export const racoonFurWords = [
    "Velvety",
    "Supple",
    "Soft",
    "Plush",
    "Thick",
    "Durable",
    "Shiny",
    "Lustrous",
    "Radiant",
    "iridescent",
    "lustrous",
    "shiny",
    "soft",
    "supple",
    "velvety",
    "smooth",
    "sleek",
    "Thick",
    "luxurious",
    "textured",
    "downy",
    "cozy",
    "warm",
    "opulent",
    "dense",
    "plush",
    "silky"
]

export const racoonSmellWords = [
    "pungent",
    "musky",
    "earthy",
    "gamey",
    "sweaty",
    "greasy",
    "bloody",
    "river",
    "wet",
    "grass",
    "fishy",
    "salty",
    "musky",
    "pungent",
    "foul",
    "distasteful",
    "bad",
    "unpleasant",
    "nauseating",
    "repulsive",
    "Fruity",
    "Sweet",
    "Earthy",
    "Spicy",
    "Sour",
    "Pungent",
    "Musky",
    "Woody",
    "Floral",
    "Clean",
    "Smelly",
    "Stinky",
    "Blue",
    "Raccoon",
    "Pungent",
    "Ammonia",
    "musky",
    "Skunky",
    "Gamey",
    "Unpleasant",
    "musky",
    "gamey",
    "pungent",
    "earthy",
    "tangy",
    "sharp",
    "smokey",
    "animal",
    "wild",
    "dirty",
]

export const racoonBehaviourSentences = [
    "its eyes are following you",
    "it is making strange noises",
    "it is staring at you intently",
    "it is making strange noises",
    "it is looking at you with its beady little eyes",
    "it is standing up on its hind legs",
    "it is baring its teeth at you",
    "it is staring at you",
    "it is making strange noises",
    "it is making direct eye contact",
    "it is licking its lips",
    "it is staring at you with its beady eyes",
    "it is slowly walking towards you",
    "it is making a soft chittering noise",
    "it is standing on its hind legs",
]