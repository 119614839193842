import {
    GET_CURRENCIES_LOADING,
    GET_CURRENCIES_SUCCESS,
    GET_CURRENCIES_FAILURE,
    CREATE_CURRENCY_SUCCESS,
	CREATE_CURRENCY_FAILURE,
} from '../actionTypes'
import store from '../store';

import { client } from '../../services/client';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { moveItemFailure } from './move-item.actions';

export const getCurrenciesSuccess = payload => {
    return ({
        type: GET_CURRENCIES_SUCCESS,
        payload
    })
}

export const getCurrenciesFailure = () => ({
    type: GET_CURRENCIES_FAILURE
})

export const getCurrenciesAsync = payload => dispatch => {
    return client.service('currency').find()
        .then((response) => {
            // response = response.map(currency => ({ ...currencys, currencyType: payload.currencyTypes.find(type => type._id === currency.currencyTypeId) }))
            dispatch(getCurrenciesSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const createCurrencySuccess = payload => ({
    type: CREATE_CURRENCY_SUCCESS,
    payload
})

export const createCurrencyFailure = () => ({
    type: CREATE_CURRENCY_FAILURE
})

export const createCurrencyAsync = payload => dispatch => {
    const { _id, name, symbol, coins, groupId, baseMetalTypeId } = payload;

    return client.service('currency').create({
        name,
        symbol,
        coins,
        groupId,
        baseMetalTypeId
    })
        .then((response) => {
        	console.log('done: ', response);
            // dispatch(createCurrencySuccess(response))

            return response;
        })
        .catch((error) => {
            console.error('error: ', error);
            dispatch(showUnknownError({ message: error.message }))
            throw new Error(error);
        });
}