import { ADD_CHARACTER_PANEL_SUCCESS, GET_CHARACTER_PANELS_SUCCESS, GET_CHARACTER_PANELS_FAILURE } from '../actionTypes';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

export const updateCharacterPanelAsync = payload => dispatch => {
    const { locationName, _id } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'character-panels',
        name: 'patch',
        data: {
            locationName,
            _id
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addCharacterPanelAsync = payload => dispatch => {
    const { locationName, panelId, characterId } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'character-panels',
        name: 'create',
        data: {
            locationName,
            panelId,
            characterId
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addCharacterPanelSuccess = payload => {
    return ({
        type: ADD_CHARACTER_PANEL_SUCCESS,
        payload
    })
}

export const getCharacterPanelsAsync = payload => dispatch => {
    return client.service('character-panels').find({ paginate: false, query: { characterId: payload._id } })
        .then((response) => {
            // response = response.map(coin => ({ ...coin, coinType: payload.coinTypes.find(type => type._id === coin.coinTypeId) }))
            dispatch(getCharacterPanelsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const getCharacterPanelsSuccess = payload => {
    return ({
        type: GET_CHARACTER_PANELS_SUCCESS,
        payload
    })
}

export const getCharacterPanelsFailure = () => ({
    type: GET_CHARACTER_PANELS_FAILURE
})
