import {
    CREATE_FURNITURE_LOADING,
    CREATE_FURNITURE_SUCCESS,
    CREATE_FURNITURE_FAILURE,
    GET_FURNITURE_LOADING,
    GET_FURNITURE_SUCCESS,
    GET_FURNITURE_FAILURE,
    ADD_FURNITURE,
    UPDATE_FURNITURE,
    REMOVE_FURNITURE,
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

export const createFurnitureSuccess = payload => ({
    type: CREATE_FURNITURE_SUCCESS,
    payload
})

export const createFurnitureFailure = () => ({
    type: CREATE_FURNITURE_FAILURE
})

export const createFurnitureAsync = payload => dispatch => {
    const { _id, furnitureTypeId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'furniture-instances',
        name: 'create',
        data: {
            furnitureTypeId
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getFurnitureSuccess = payload => {
    return ({
        type: GET_FURNITURE_SUCCESS,
        payload
    })
}

export const getFurnitureFailure = () => ({
    type: GET_FURNITURE_FAILURE
})

export const getFurnitureAsync = payload => dispatch => {
    let query = { $or: [{ panelId: payload.panelId, z: payload.z}, { characterId: payload.characterId}] }

    if (payload.isNotFirstInitialisation) {
        query = { panelId: payload.panelId, z: payload.z}
    }

    return client.service('furniture-instances').find({ query })
        .then((response) => {
            response = response.map(furniture => ({ ...furniture, furnitureType: payload.furnitureTypes.find(type => type._id === furniture.furnitureTypeId) }))
            dispatch(getFurnitureSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const destroyFurnitureAsync = payload => dispatch => {
    const { _id } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'furniture-instances',
        commandType: 'remove',
        data: {
            _id
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addFurnitureToCharacterAsync = payload => dispatch => {
    const { furnitureInstance, panelId, position, characterId } = payload;
    const { createdAt, furnitureTypeId, recipeId, _id } = furnitureInstance;

    colyClient.room.send('doAction', {
        serviceName: 'furniture-instances',
        commandType: 'create',
        data: {
            panelId: undefined,
            position: undefined,
            furnitureTypeId,
            tileId: "",
            characterId
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addFurnitureToPanelAsync = payload => dispatch => {
    const { furnitureInstance, panelId, position } = payload;
    const { createdAt, furnitureTypeId, recipeId, _id } = furnitureInstance;

    colyClient.room.send('doAction', {
        serviceName: 'furniture-instances',
        commandType: 'create',
        data: {
            createdAt,
            furnitureTypeId,
            recipeId,
            panelId,
            position,
            characterId: ""
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addFurniture = payload => {
    return ({
        type: ADD_FURNITURE,
        payload
    })
}

export const updateFurniture = payload => {
    return ({
        type: UPDATE_FURNITURE,
        payload
    })
}

export const removeFurniture = payload => {
    return ({
        type: REMOVE_FURNITURE,
        payload
    })
}


export const joinFurnitureAsync = payload => dispatch => {
    const { targetFurnitureId } = payload;

    return client.service('furniture-instances').patch(targetFurnitureId, {
        _id: targetFurnitureId
    }, {
        query: {
            isJoiningTable: true
        }
    })
        .then((response) => {
            console.log('success: ', response);
            return response;
        })
        .catch((error) => {
            console.error('error', error);
        })
}

export const leaveFurnitureAsync = payload => dispatch => {
    const { targetFurnitureId } = payload;

    return client.service('furniture-instances').patch(targetFurnitureId, {
        _id: targetFurnitureId
    }, {
        query: {
            isLeavingTable: true
        }
    })
        .then((response) => {
            console.log('success: ', response);
            return response;
        })
        .catch((error) => {
            console.error('error', error);
        })
}