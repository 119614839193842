import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import { disableKeyboardMovement, enableKeyboardMovement, showHelp, startLooking, hideAllMenus } from '../../redux/actions/keyboard-shortcuts.actions';

import { getTradeToolsAsync } from '../../redux/actions/tool.actions';
import { getTradeLocksAsync } from '../../redux/actions/lock.actions';
import { getTradeTentsAsync } from '../../redux/actions/tent.actions';
import { getTradeJewelleryAsync } from '../../redux/actions/jewellery.actions';
import { getTradeClothingAsync } from '../../redux/actions/clothing.actions';
import { getTradeArmourAsync } from '../../redux/actions/armour.actions';
import { getTradeWeaponAsync } from '../../redux/actions/weapon.actions';
import { getTradeWritingImplementsAsync } from '../../redux/actions/writing-implement.actions';
import { getTradeWritingSurfacesAsync } from '../../redux/actions/writing-surface.actions';
import { getTradeKeysAsync } from '../../redux/actions/key.actions';
import { getTradeMetalsAsync } from '../../redux/actions/metal.actions';
import { getTradeMineralsAsync } from '../../redux/actions/mineral.actions';
import { getTradeTilesAsync } from '../../redux/actions/tile.actions';
import { getTradeInventoryAsync } from '../../redux/actions/inventory.actions';
import { getTradeFoodAsync } from '../../redux/actions/food.actions';
import {
    sendTradeOfferAsync,
    updateTradeOfferAsync,
    getTradeOffersAsync,
    acceptTradeOfferAsync,
    rejectTradeOfferAsync,
} from '../../redux/actions/trade.actions';

import {
    selectPanel,
    selectCharacterMessages,
    selectEmbarkCharacterMessages,
    selectTotalMessages,
    selectCharacter,
    selectCharacters,
    selectIsMessagesFocussed,
    selectFocussedCharacterId,
    selectMessageOptionss,
    selectEmbarkCharacter,
    selectCurrentEmbarkGroup,
    selectCharacterById,
    selectConversationTree,
    selectMaterialTypes,
    selectTileTypes,
    selectPlantTypes,
    selectMineralTypes,
    selectToolTypes,
    selectMetalTypes,
    selectKeyTypes,
    selectMachineTypes,
    selectFurnitureTypes,
    selectConstructionTypes,
    selectWritingSurfaceTypes,
    selectWritingImplementTypes,
    selectWeaponTypes,
    selectArmourTypes,
    selectClothingTypes,
    selectJewelleryTypes,
    selectFoodTypes,
    selectLockTypes,
    selectAnimalTypes,
    selectFishTypes,
    selectBoatTypes,
    selectTentTypes,
    selectWorkshopTypes,
    selectWagonTypes,
    selectCurrencies,
    selectCoinTypes,
    selectCoins,
    selectCoinRecipes,
} from '../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';

import PartnerTrade from './PartnerTrade';
import InventoryTrade from './InventoryTrade';
import ItemDescription from '../item-description/ItemDescription';

import { client } from '../../services/client';

import './Trade.css';

import Menu from '../utils/menu/Menu';
import TextInput from '../utils/text-input/TextInput';

class Trade extends React.Component {
    state = {
        navigationHandler: this.navigationHandler.bind(this)
    };

    disableMovement = () => {
        store.dispatch(disableKeyboardMovement());
    }

    enableMovement = () => {
        store.dispatch(enableKeyboardMovement());
    }

    submitMessage = () => {
        
    };

    openHelp = () => {
        store.dispatch(showHelp());
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.state.navigationHandler)
    }

    storePartnerTrade(tradeOffer) {
        let totalPrice = 0;

        tradeOffer.forEach(({ tradePrice, tradeOfferCount }) => {
            totalPrice += (tradePrice * tradeOfferCount)
        })
        this.setState({
            partnerTrade: tradeOffer
                .map(tradeItem => {
                    const typeIdKey = tradeItem.serviceName.split('-')[0] + 'TypeId';

                    return { serviceName: tradeItem.serviceName, tradeOfferCount: tradeItem.tradeOfferCount, [typeIdKey]: tradeItem[typeIdKey], _id: (tradeItem._id || tradeItem.id) }
                }),
            totalPrice
        })
    }

    storeInventoryTrade(tradeOffer) {
        this.setState({
            inventoryTrade: tradeOffer
                .map(tradeItem => {
                    const typeIdKey = tradeItem.serviceName.split('-')[0] + 'TypeId';

                    return { serviceName: tradeItem.serviceName, tradeOfferCount: tradeItem.tradeOfferCount, [typeIdKey]: tradeItem[typeIdKey], _id: (tradeItem._id || tradeItem.id) }
                }),
        })
    }

    componentDidMount() {
        document.addEventListener('keydown', this.state.navigationHandler);

        this.props.getTradeToolsAsync({ characterId: this.props.focussedCharacterId, toolTypes: this.props.toolTypes })
        this.props.getTradeLocksAsync({ characterId: this.props.focussedCharacterId, lockTypes: this.props.lockTypes })
        this.props.getTradeTentsAsync({ characterId: this.props.focussedCharacterId, tentTypes: this.props.tentTypes })
        this.props.getTradeJewelleryAsync({ characterId: this.props.focussedCharacterId, jewelleryTypes: this.props.jewelleryTypes })
        this.props.getTradeClothingAsync({ characterId: this.props.focussedCharacterId, clothingTypes: this.props.clothingTypes })
        this.props.getTradeArmourAsync({ characterId: this.props.focussedCharacterId, armourTypes: this.props.armourTypes })
        this.props.getTradeWeaponAsync({ characterId: this.props.focussedCharacterId, weaponTypes: this.props.weaponTypes })
        this.props.getTradeWritingImplementsAsync({ characterId: this.props.focussedCharacterId, writingImplementTypes: this.props.writingImplementTypes })
        this.props.getTradeWritingSurfacesAsync({ characterId: this.props.focussedCharacterId, writingSurfaceTypes: this.props.writingSurfaceTypes })
        this.props.getTradeKeysAsync({ characterId: this.props.focussedCharacterId, keyTypes: this.props.keyTypes })
        this.props.getTradeMetalsAsync({ characterId: this.props.focussedCharacterId, metalTypes: this.props.metalTypes })
        this.props.getTradeMineralsAsync({ characterId: this.props.focussedCharacterId, z: this.props.character.z, mineralTypes: this.props.mineralTypes, panelId: this.props.character.panelId })
        this.props.getTradeTilesAsync({ characterId: this.props.focussedCharacterId, tileTypes: this.props.tileTypes })
        this.props.getTradeInventoryAsync({ characterId: this.props.focussedCharacterId, materialTypes: this.props.materialTypes, plantTypes: this.props.plantTypes, fishTypes: this.props.fishTypes, animalTypes: this.props.animalTypes })
        this.props.getTradeFoodAsync({ characterId: this.props.focussedCharacterId, foodTypes: this.props.foodTypes })

        this.props.getTradeOffersAsync({ characterId: this.props.character._id, targetCharacterId: this.props.focussedCharacterId })
            .then(response => {
                this.setState({
                    offer: response
                })
            })
    }

    navigationHandler(event) {
        if (event.key === '<') {
            this.props.showLeft();
            return;
        }

        if (event.key === '>') {
            this.props.showRight();
            return;
        }

        if (event.key === '.') {
            if (this.state.offer) {
                this.props.updateTradeOfferAsync({
                    _id: this.state.offer._id,
                    characterId: this.props.character._id,
                    targetCharacterId: this.props.focussedCharacterId,
                    tradeItems: this.state.inventoryTrade,
                    targetTradeItems: this.state.partnerTrade,
                    lastOfferBy: this.props.character._id
                })
                    .then(response => {
                        this.setState({
                            offer: response
                        })
                    })
                return;
            } else {
                this.props.sendTradeOfferAsync({
                    characterId: this.props.character._id,
                    targetCharacterId: this.props.focussedCharacterId,
                    tradeItems: this.state.inventoryTrade,
                    targetTradeItems: this.state.partnerTrade,
                    lastOfferBy: this.props.character._id
                })
                    .then(response => {
                        this.setState({
                            offer: response
                        })
                    })
                return;
            }
        }

        if (event.key === '/') {
            this.props.acceptTradeOfferAsync({
                _id: this.state.offer._id
            })
                .then(response => {
                    this.setState({
                        offer: undefined
                    })

                    this.props.hideAllMenus();
                })
            return;
        }

        if (event.key === ',') {
            this.props.rejectTradeOfferAsync({
                _id: this.state.offer._id
            })
                .then(response => {
                    this.setState({
                        offer: undefined
                    })
                })
            return;
        }

        if (event.key === ']') {
            const coin = this.props.coins[0];

            // TODO - buy item....
            this.props.sendTradeOfferAsync({
                characterId: this.props.character._id,
                targetCharacterId: this.props.focussedCharacterId,
                tradeItems: [
                        {
                            tradeOfferCount: this.state.totalPrice,
                            coinTypeId: coin.coinTypeId,
                            serviceName: 'coin-instances',
                            _id: coin._id
                        }
                    ],
                targetTradeItems: this.state.partnerTrade,
                lastOfferBy: this.props.character._id,
                isCashPurchase: true
            })
                .then(response => {
                    this.setState({
                        offer: response
                    })
                })
            return;
        }
    }

    render() {
        return (
            <div>
                <div className="trade-actions">
                    <div className="top-ting">
                        <div className="their-inventory">
                            <PartnerTrade
                                updateTradeOffer={(offer) => (this.storePartnerTrade(offer))}
                                existingOffer={this.state.offer?.characterId === this.props?.character?._id ? this.state.offer?.targetTradeItems : this.state.offer?.tradeItems}
                                isCharacterLastOfferer={this.state.offer?.lastOfferBy === this.props.character?._id}
                                onSelectedItem={(selectedItem) => (this.setState({ selectedItem }))}
                                totalPrice={(!this.state.inventoryTrade || this.state.inventoryTrade?.length === 0) && this.state.totalPrice}
                            />
                        </div>
                        <div className="my-inventory">
                            <InventoryTrade
                                updateTradeOffer={(offer) => (this.storeInventoryTrade(offer))}
                                existingOffer={this.state.offer?.characterId === this.props?.character?._id ? this.state.offer?.tradeItems : this.state.offer?.targetTradeItems}
                                isCharacterLastOfferer={this.state.offer?.lastOfferBy === this.props.character?._id}
                                onSelectedItem={(selectedItem) => (this.setState({ selectedItem }))}
                                totalPrice={(!this.state.inventoryTrade || this.state.inventoryTrade?.length === 0) && this.state.totalPrice}
                            />
                        </div>
                    </div>
                    <ItemDescription item={this.state.selectedItem} />
                </div>

                <div className="bottom-text-left">
                    [ <span className="action"> &lt; </span> {this.props.leftString} | <span className="action"> &gt; </span> {this.props.rightString} ]
                </div>

                <div className="under-text-right">
                    <span>[Total money: <span className="action">¤{this.props.totalMoney}</span>]</span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const character = selectCharacter(state);

    const focussedCharacterId = selectFocussedCharacterId(state);
    const focussedCharacter = selectCharacterById(state, focussedCharacterId)

    const materialTypes = selectMaterialTypes(state);
    const mineralTypes = selectMineralTypes(state);
    const plantTypes = selectPlantTypes(state);
    const tileTypes = selectTileTypes(state);
    const toolTypes = selectToolTypes(state);
    const metalTypes = selectMetalTypes(state);
    const keyTypes = selectKeyTypes(state);
    const machineTypes = selectMachineTypes(state);
    const furnitureTypes = selectFurnitureTypes(state);
    const constructionTypes = selectConstructionTypes(state);
    const writingSurfaceTypes = selectWritingSurfaceTypes(state);
    const writingImplementTypes = selectWritingImplementTypes(state);
    const weaponTypes = selectWeaponTypes(state);
    const armourTypes = selectArmourTypes(state);
    const clothingTypes = selectClothingTypes(state);
    const jewelleryTypes = selectJewelleryTypes(state);
    const foodTypes = selectFoodTypes(state);
    const lockTypes = selectLockTypes(state);
    const animalTypes = selectAnimalTypes(state);
    const fishTypes = selectFishTypes(state);
    const boatTypes = selectBoatTypes(state);
    const tentTypes = selectTentTypes(state);
    const workshopTypes = selectWorkshopTypes(state);
    const wagonTypes = selectWagonTypes(state);

    const currencies = selectCurrencies(state);
    let coinTypes = selectCoinTypes(state);
    let coins = selectCoins(state);
    const coinRecipes = selectCoinRecipes(state);

    const currency = currencies.find(currency => (currency.groupId === character.groupId));

    coinTypes = coinTypes.map(coinType => {
        return {
            ...coinType,
            currency: currencies.find(currency => currency._id === coinType.currencyId)
        }
    })

    coins = coins.map(coin => {
        return {
            ...coin,
            coinType: coinTypes.find(type => type._id === coin.coinTypeId),
            coinRecipe: coinRecipes.find(recipe => recipe.coinTypeId === coin.coinTypeId)
        }
    })

    let totalMoney = 0;

    coins.forEach(coin => {
        totalMoney += (1000 / (coin.coinRecipe?.quantity)) * (coin.quantity || 1)
    })

    return {
        character,
        focussedCharacterId,
        materialTypes,
        mineralTypes,
        plantTypes,
        tileTypes,
        toolTypes,
        metalTypes,
        keyTypes,
        machineTypes,
        furnitureTypes,
        constructionTypes,
        writingSurfaceTypes,
        writingImplementTypes,
        weaponTypes,
        armourTypes,
        clothingTypes,
        jewelleryTypes,
        foodTypes,
        lockTypes,
        animalTypes,
        fishTypes,
        boatTypes,
        tentTypes,
        workshopTypes,
        wagonTypes,
        coins,
        totalMoney
    }
}

export default connect(
    mapStateToProps,
    {
        hideAllMenus,
        getTradeToolsAsync,
        getTradeLocksAsync,
        getTradeTentsAsync,
        getTradeJewelleryAsync,
        getTradeClothingAsync,
        getTradeArmourAsync,
        getTradeWeaponAsync,
        getTradeWritingImplementsAsync,
        getTradeWritingSurfacesAsync,
        getTradeKeysAsync,
        getTradeMetalsAsync,
        getTradeMineralsAsync,
        getTradeTilesAsync,
        getTradeInventoryAsync,
        getTradeFoodAsync,
        sendTradeOfferAsync,
        updateTradeOfferAsync,
        getTradeOffersAsync,
        acceptTradeOfferAsync,
        rejectTradeOfferAsync,
    }
)(Trade);