export const cowFaceSentences = [
    "Dirt caked on its face",
"Grass stuck in its teeth",
"Green around its eyes",
"Brown around its muzzle",
"White on its forehead",
"Matte black on its nose",
"Long lashes",
"Small, black eyes",
"Floppy brown ears ",
"Big black eyes ",
"A long pink tongue ",
"A big black nose ",
"A big head ",
"it looks content as it munches on some grass.",
"it has large, expressive eyes.",
"it has a long, pink tongue.",
"it has big, floppy ears.",
"it has a smooth, black nose.",
"Big, brown eyes.",
"A long, black nose.",
"Large, floppy ears.",
]

export const cowBodySentences = [
"its body is covered in a thick layer of fur",
"White and black spotted fur",
"Horns on top of head",
"Sturdy legs",
"Long tail",
"it has a large, stocky body",
"It is covered in a short shaggy coat of fur",
"it has four short legs and a long tail",

]

export const cowSoundWords = [
    "lowing",
    "mooing",
    "stamping",
    "bellowing",
    "snorting",
    "chewing",
    "swallowing",
    "Mooing",
    "Lowing",
    "Grunting",
    "Snorting",
    "Bleating",
    "Bellowing",
    "Coughing",
    "Wheezing",
    "Panting",
    "Lowing",
    "mooing",
    "bellowing",
    "bawling",
    "bleating",
    "roaring",
    "lowing",
    "grunting",
    "bellowing",
    "mooing",
    "mooing",
    "bellowing",
    "lowing",
    "bleating",
    "muttering",
    "grunting",
    "snorting",
    "stamping",
    "chewing",
    "slurping",
    "lowing",
    "mooing",
    "bawling",
    "bellowing",
    "bleating",
    "blubbering",
    "braying",
    "drawling",
    "hooting",
    "wailing"
]

export const cowFurWords = [
"rough", "dry", "itchy", "peeling", "cracked", "painful", "bleeding", "infected", "oozing",
"soft", "smooth", "hairy", "brown", "black","white", "spotted", "red", "tan", "cream",
"black", "bristly", "coarse", "dry", "hard", "thick", "itchy", "mottled", "peeling", "scaly",
"Damp", "oily", "rank", "musty", "dirty", "hairy", "stiff", "brittle", "dry", "scaly",
"Fuzzy", "Hairy", "Rough", "Oily", "Sticky", "Gooey", "Covered in Mud", "Smelly", "Dirty",
]

export const cowSmellWords = [
    "Pungent",
    "Musky",
    "Cowy",
    "Earthy",
    "rank",
    "fetid",
    "pungent",
    "reeky",
    "rancid",
    "putrid",
    "stinking",
    "malodorous",
    "foul",
    "Pungent",
    "Musky",
    "gamey",
    "grassy",
    "hairy",
    "milky",
    "beefy",
    "farmy",
    "burly",
    "dungy",
    "grassy",
    "Pungent",
    "rank",
    "gamey",
    "greasy",
    "musky",
    "nauseating",
    "ripe",
    "stench",
    "overpowering",
]

export const cowBehaviourSentences = [
"it is staring at you with its big eyes",
"it is staring at you with its beady eyes",
"it is chewing on something with blood on it",
"it is licking its lips",
"it is staring at you with its blank expression",
"it is following you with its gaze",
"it is standing very still",
"it is glaring at you",
"it is staring at you intently",
"it is staring at you with its big, black eyes",
"it is stopping and starting as it walks",
]