import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_WAGONS_SUCCESS, ADD_WAGON, UPDATE_WAGON, REMOVE_WAGON, SHOW_CHARACTER_DEATH_PAGE, BEGIN_UPDATE_BOAT, UPDATE_BOAT_FAILED, BEGIN_UPDATE_WAGON, UPDATE_WAGON_FAILED } from "../actionTypes";

const initialState = {
  wagons: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_WAGONS_SUCCESS: {
      const wagons = action.payload;

      return {
        ...state,
        wagons: [...wagons.filter(wagonInstance => wagonInstance.panelId)],
      }
    }

    case ADD_WAGON: {
      let wagons = [...state.wagons];

      if (action.payload.panelId) {
        wagons = [ ...wagons, action.payload ]
      }

      return {
        ...state,
        wagons: [ ...wagons ],
      }
    }

    case BEGIN_UPDATE_WAGON: {
      let wagons = [...state.wagons];

      if (action.payload.panelId) {
        const wagon = wagons.find(wagon => wagon._id === action.payload._id)
        wagons = [
          ...wagons.filter(wagon => wagon._id !== action.payload._id),
          { ...wagon, ...action.payload, lastPosition: {...wagon.position} }
        ]
      }

      return {
        ...state,
        wagons: [ ...wagons ],
      }
    }

    case UPDATE_WAGON_FAILED: {
      console.log('update UPDATE_WAGON_FAILED failed :(')
      let wagons = [...state.wagons];

      if (action.payload.panelId) {
        const wagon = wagons.find(wagon => wagon._id === action.payload._id)
        wagons = [
          ...wagons.filter(wagon => wagon._id !== action.payload._id),
          { ...wagon, ...action.payload, position: {...wagon.lastPosition} }
        ]
      }

      return {
        ...state,
        wagons: [ ...wagons ],
      }
    }

    case UPDATE_WAGON: {
      let wagons = [...state.wagons];

      if (action.payload.panelId) {
        wagons = [
          ...wagons.filter(wagon => wagon._id !== action.payload._id),
          { ...wagons.find(wagon => wagon._id === action.payload._id), ...action.payload }
        ]
      }

      return {
        ...state,
        wagons: [ ...wagons ],
      }
    }

    case REMOVE_WAGON: {
      return {
        ...state,
        wagons: [ ...state.wagons.filter(wagon => (wagon._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
