export const codFaceSentences = [
"Big, fat, and juicy.",
"The perfect snack for a hot day.",
"The face is a visage of sheer terror.",
"The face is a visage of pure evil.",
"The face is a visage of unadulterated horror.",
"Its face is slightly orange.",
"Its face is a little more orange than usual.",
"Its face is covered in a thin layer of film.",
"Its face is slightly milky.",
"Its face has a few bumps.",
"Its face has a few pimples.",
]

export const codBodySentences = [
"its body is bright and vibrant.",
"its body is a beautiful hue.",
"its body is a stunning color.",
"its body is a lovely color.",
"Bright orange skin",
"Rounded shape",
"bumpy texture",
"thin skin",
"lovely color",
"Vibrant and eye-catching body.",
"its body is a beautiful sight.",
"its body is a amazing sight.",
"its body is a stunning sight.",
"its body is a captivating sight.",
"Wriggling gently in the water, its is a beautiful sight.",
"With its bright orange scales and long body, its is an amazing fish.",
"its's orange body is stunning against the blue of the ocean.",
]

export const codSoundWords = [
"Gleeful",
"Haunting",
"Mellifluous",
"Ataractic",
"Sinister",
"Ethereal",
"Gregarious",
"Hissing",
"Sibilant",
"Stridulous",
]

export const codFurWords = [
"orange", "tangerine", "apricot", "peach", "nectarine", "mango", "citron", "mandarin", "clementine", "persimmon",
]

export const codSmellWords = [
"Pungent", "rotting", "fishy", "briny", "sulphurous", "ammonia", "sharp", "acidic", "salty", "seafood",
]

export const codBehaviourSentences = [
"it is staring at you",
"it is foaming at the mouth",
"it is swimming in circles",
"it is hiding in the rocks",
]