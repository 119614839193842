import { CHANGE_CHARACTER, START_NEW_GAME, GET_PROMPTS_SUCCESS, ADD_PROMPT, UPDATE_PROMPT, REMOVE_PROMPT, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  prompts: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }

    case GET_PROMPTS_SUCCESS: {
      const prompts = action.payload;

      console.log('prompts: ', prompts)

      return {
        ...state,
        prompts: [...prompts],
      }
    }

    case ADD_PROMPT: {
      let prompts = [...state.prompts, action.payload];

      return {
        ...state,
        prompts: [ ...prompts ],
      }
    }

    case UPDATE_PROMPT: {
      let prompts = [
        ...prompts.filter(prompt => prompt._id !== action.payload._id),
        { ...prompts.find(prompt => prompt._id === action.payload._id), ...action.payload }
  	  ];

      return {
        ...state,
        prompts: [ ...prompts ],
      }
    }

    case REMOVE_PROMPT: {
      return {
        ...state,
        prompts: [ ...state.prompts.filter(prompt => (prompt._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
