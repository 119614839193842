import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_METALS_SUCCESS, ADD_METAL, UPDATE_METAL, REMOVE_METAL, GET_TRADE_METALS_SUCCESS, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  characterMetals: [],
  tileMetals: [],
  tradeMetals: [],
  metals: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case CHANGE_CHARACTER:
    case INITIALISING_PANEL_STARTED:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_METALS_SUCCESS: {
      const metals = action.payload;

      return {
        ...state,
        metals: [...metals.filter(metalInstance => metalInstance.panelId)],
        characterMetals: [...state.characterMetals, ...metals.filter(metalInstance => metalInstance.characterId)],
        tileMetals: [...metals.filter(metalInstance => metalInstance.tileId)],
      }
    }

    case GET_TRADE_METALS_SUCCESS: {
      const metals = action.payload;

      return {
        ...state,
        tradeMetals: [...metals],
      }
    }

    case ADD_METAL: {
      let characterMetals = [...state.characterMetals];
      let tileMetals = [...state.tileMetals];
      let metals = [...state.metals];

      if (action.payload.characterId) {
        characterMetals = [ ...characterMetals, action.payload ]
      }

      if (action.payload.tileId) {
        tileMetals = [ ...tileMetals, action.payload ]
      }

      if (action.payload.panelId) {
        metals = [ ...metals, action.payload ]
      }

      return {
        ...state,
        characterMetals: [ ...characterMetals ],
        tileMetals: [ ...tileMetals ],
        metals: [ ...metals ],
      }
    }

    case UPDATE_METAL: {
      let characterMetals = [...state.characterMetals];
      let tileMetals = [...state.tileMetals];
      let metals = [...state.metals];

      if (action.payload.characterId) {
        characterMetals = [
          ...characterMetals.filter(metal => metal._id !== action.payload._id),
          { ...characterMetals.find(metal => metal._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.tileId) {
        tileMetals = [
          ...tileMetals.filter(metal => metal._id !== action.payload._id),
          { ...tileMetals.find(metal => metal._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.panelId) {
        metals = [
          ...metals.filter(metal => metal._id !== action.payload._id),
          { ...metals.find(metal => metal._id === action.payload._id), ...action.payload }
        ]
      }

      return {
        ...state,
        characterMetals: [ ...characterMetals ],
        tileMetals: [ ...tileMetals ],
        metals: [ ...metals ],
      }
    }

    case REMOVE_METAL: {
      return {
        ...state,
        characterMetals: [ ...state.characterMetals.filter(metal => (metal._id !== action.payload._id)) ],
        tileMetals: [ ...state.tileMetals.filter(metal => (metal._id !== action.payload._id)) ],
        metals: [ ...state.metals.filter(metal => (metal._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
