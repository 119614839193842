export const swarmerFaceSentences = [
"a mouth filled with hundreds of needle-sharp teeth",
"Widened, bloodshot eyes glare menacingly from a swollen, green face.",
"A mouth full of razor-sharp teeth drips with venom.",
"Saliva and blood mix together as the beast drools hungrily.",
"Small, beady eyes glaring menacingly from a large, flat head.",
"A gaping maw full of jagged teeth, saliva dripping from its lips.",
"A long, snake-like tongue lolling out from between sharp teeth.",
"The face is divided into many small sections, each writhing with tiny creatures.",
"The face is writhing and squirming.",
"The face is a mass of writhing limbs and teeth.",
"The face is a sea of tiny teeth and clawing",
"Its thousands of mouths open and close in unison, drooling thick, green slime.",
"Its eight eyes bulge out of its head, each a different, sickening color.",
"Its long, writhing tongue flickers in and out of its mouth.",
]

export const swarmerBodySentences = [
"a protest of limbs and torsos ",
"Seething Cauldron",
"Boiling Maelstrom",
"Churning Inferno",
"Raging Furnace",
"Whirling Tempest",
"It has no mouth, but emits a constant, high-pitched hum.",
"Even when motionless, it seems to be constantly in motion.",
"A writhing mass of tentacles and claws.",
"A seething, churning ball of flesh.",
"A living engine of destruction.",
"A ravenous, insatiable beast.",
"An unstoppable force of nature.",
"Thousands of writhing, barbed tentacles.",
"A vast, dish-shaped body with a maw full of razor-sharp teeth.",
"A body made up of an ever-shifting mass of ooze and teeth.",

]

export const swarmerSoundWords = [
    "droning",
    "whirring",
    "shrilling",
    "buzzing",
    "humming",
    "chirping",
    "trilling",
    "piping",
    "twittering",
    "warbling",
    "Chittering",
    "Scuttling",
    "Slithering",
    "Hissing",
    "Rustling",
    "Flicking",
    "Snapping",
    "Cracking",
    "Clacking",
    "Banging",
    "Clattering",
    "scuttling",
    "screeching",
    "clicking",
    "hissing",
    "buzzing",
    "flapping",
    "rustling",
    "slurping",
    "gobbling"
]

export const swarmerFurWords = [
     "Slimy",
    "Smelly",
    "Oily",
    "Gristly",
    "Rough",
    "Bumpy",
    "Scaly",
    "Flaky",
    "Dry",
    "Crust",
    "thick",
    "slimy",
    "scaly",
    "prickly",
    "soft",
    "furry",
    "bumpy",
    "smooth",
    "wet",
    "foul",
    "rank",
    "greasy",
    "matted",
    "caked",
    "slimy",
    "scabby",
    "brittle",
    "dry",
    "furry",
    "fleecy",
    "woolly",
    "downy",
    "hairy",
    "shaggy",
    "mangy",
    "matted",
    "ragged",
    "unkempt",
    "Slimy",
    "scaly",
    "tough",
    "bumpy",
    "dry",
    "oily",
    "knobby",
    "spiny",
    "soft",
    "velvety"
]

export const swarmerSmellWords = [
"pungent", "rank", "acrid", "putrid", "fetid", "reeking", "stinking", "malodorous", "foul", "offensive",
]

export const swarmerBehaviourSentences = [
"looming in the distance",
"stalking closer",
"baring its teeth",
"hissing menacingly",
"flicking its tongue",
"sizing you up",
"looking hungry",
"dripping with saliva",
"waiting for you to make a move",
"watching you with its beady eyes",
"inching closer and closer",
"breathing heavily",
"drooling with anticipation",
"licking its chops",
"quivering with excitement",
]