import {
    GET_CONSTRUCTIONS_LOADING,
    GET_CONSTRUCTIONS_SUCCESS,
    GET_CONSTRUCTIONS_FAILURE,
    BUILD_CONSTRUCTION_LOADING,
    BUILD_CONSTRUCTION_SUCCESS,
    BUILD_CONSTRUCTION_FAILURE,
    DESTROY_CONSTRUCTION_FAILURE,
    ADD_CONSTRUCTION,
    UPDATE_CONSTRUCTION,
    REMOVE_CONSTRUCTION,
    PROGRESS_CONSTRUCTION_LOADING,
    PROGRESS_CONSTRUCTION_SUCCESS,
    PROGRESS_CONSTRUCTION_FAILURE,
} from '../actionTypes';
import { clearAllTiles, loadTilesSuccess } from './tile.actions';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

export const getConstructionsSuccess = payload => {
    return ({
        type: GET_CONSTRUCTIONS_SUCCESS,
        payload
    })
}

export const getConstructionsFailure = payload => ({
    type: GET_CONSTRUCTIONS_FAILURE
})

export const getConstructionsAsync = (payload) => dispatch => {
    return client.service('construction-instances').find({ paginate: false, query: { panelId: payload.panelId, z: payload.z }})
        .then((response) => {
            response = response.map(construction => ({ ...construction, construction: payload.constructionTypes.find(type => type._id === construction.constructionTypeId) }))
            response = response.map(construction => ({ ...construction, recipes: payload.constructionRecipes?.filter(recipe => recipe.constructionTypeId === construction.constructionTypeId) }))
            dispatch(getConstructionsSuccess(response))
        })
        .catch((error) => {
            console.error('error', error);
            dispatch(showUnknownError())
            throw new Error(error);
        })
}

export const buildConstructionSuccess = payload => {
    return ({
        type: BUILD_CONSTRUCTION_SUCCESS,
        payload
    })
}

export const buildConstructionFailure = payload => ({
    type: BUILD_CONSTRUCTION_FAILURE
})

export const buildConstructionAsync = payload => dispatch => {
    const { constructionTypeId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'construction-instances',
        name: 'create',
        data: {
            constructionTypeId
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const progressConstructionSuccess = payload => {
    return ({
        type: PROGRESS_CONSTRUCTION_SUCCESS,
        payload
    })
}

export const progressConstructionFailure = payload => ({
    type: PROGRESS_CONSTRUCTION_FAILURE
})

export const progressConstructionAsync = payload => dispatch => {
    const { constructionTypeId, _id, progress } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'construction-instances',
        commandType: 'patch',
        data: {
            _id,
            progress
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const destroyConstructionFailure = payload => ({
    type: DESTROY_CONSTRUCTION_FAILURE
})

export const destroyConstructionAsync = payload => dispatch => {
    const { _id } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'construction-instances',
        commandType: 'remove',
        data: {
            _id
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addConstruction = payload => {
    return ({
        type: ADD_CONSTRUCTION,
        payload
    })
}

export const updateConstruction = payload => {
    return ({
        type: UPDATE_CONSTRUCTION,
        payload
    })
}

export const removeConstruction = payload => {
    return ({
        type: REMOVE_CONSTRUCTION,
        payload
    })
}

export const updateGuardHouseAsync = payload => dispatch => {
    const { _id, whitelistCharacterIds, blacklistCharacterIds, price, isForcedWeaponDrop, direction, whitelistCharacterNames, blacklistCharacterNames } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'construction-instances',
        commandType: 'patch',
        data: {
            _id,
            whitelistCharacterIds,
            blacklistCharacterIds,
            price,
            isForcedWeaponDrop,
            direction
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const topUpFirePit = payload => dispatch => {
    const { _id, expiresAt } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'construction-instances',
        commandType: 'patch',
        data: {
            _id,
            expiresAt,
            createdAt: new Date().getTime()
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}