export const kangarooPawSmellTypes = [
	"sparse",
    "grassy",
    "dry",
    "woody",
    "arid",
    "sandy",
    "dusty",
    "sweet",
    "nutty",
    "sandy",
    "tropical",
    "citrusy",
    "of desert",
    "of sunshine",
    "of warmth",
    "dry",
    "of red earth",
    "of blue sky",
    "endless",
    "floral",
    "grassy",
    "nutty",
    "resinous",
    "sweet",
    "woody",
    "of magnificent chaos",
    "of pulpy rage",
    "of stripped musky layers",
    "of fresh, moving air"
    "of unrelenting kudzu",
    "of burnt umber curls",
    "of shining magnolia petals",
    "oaty and earthen",
    "of slink moss",
    "of eaten pasture",
    "of pollen-drenched skies",
    "of dry bales of straw",
    "of distant fields and hidden creatures",
    "confined",
    "of minty herbal lavender",
    "of dried smoke leaves",
    "sultry",
    "of indolic blooms",
    "of a dirt lane cutting through a pasture",
    "of a damp and humid meadow",
    "of a hesitant breath",
]

export const kangarooPawBranchTypes = [
    "crawling",
    "hairy",
    "green",
    "insect covered",
    "pointy needles on its",
    "crunchy textured",
    "smooth textured",
    "dry textured",
    "wet textured",
    "muddy textured",
    "rotten textured",
    "thorny",
    "bare",
    "spiky",
    "thorny",
    "leafy",
    "woody",
    "waxy",
    "susurrating",
    "coy",
    "jangling",
    "aloof",
    "sand-sunken roots and sap-storing",
    "a silt home for its",
    "partially sand-submerged",
    "stalk-like",
    "half-sunk",
    "sand-caked",
    "boot-trampled",
    "curving",
    "tugging",
    "pleading",
    "weak",
    "sap-drivel",
    "questioning",
    "marrow-coloured",
    "musk-stained",
    "silt-scrap covered",
    "botched",
    "ragged",
    "marble-coloured",
    "pebble-coloured",
    "croaking",
    "pallid",
    "gaunt",
    "restless",
    "pulling",
]

export const kangarooPawLeafTypes = [
	"long and thin",
    "unsteady",
    "white flowers growing near its",
    "pointed",
    "toothed",
    "bristle bush",
    "Spiny",
    "Thorny",
    "Bristly",
    "Scratchy",
    "Itchy",
    "harp",
    "Pointy",
    "Sticky",
    "grabby",
    "clinging",
    "river red gum"
    "bloodwort",
    "long, furry",
    "crimson",
    "fuzzy, reaching",
    "furry red flowers and grey-green",
    "pawing blackish-red flowers and pale green",
    "a delicate rosette between its",
    "tuberous buds along its",
    "velvety paws amongst its",
    "strappy",
    "long",
    "canescent",
    "stalk-buttressed",
    "barred",
    "colourful flowers and ashy",
    "hearth-coloured florettes and grey",
    "lank",
]