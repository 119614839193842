export const junglePlantFourSmellTypes = [
    "of hoya",
    "of arum lily",
    "of climbing fig",
    "aromatic",
    "pungent",
    "rank",
    "fetid",
    "putrid",
    "of moss",
    "of pondweed",
    "of cattail",
    "of bulrush",
    "of reeds",
    "of flags",
    "of ditch grass",
    "of lizard's tail",
    "of wild rice",
    "of rush",
    "of Water hyacinth",
    "of offices",
    "of moss",
    "of mould",
    "of strong, spicy walnut",
    "harmonious",
    "of kumbaru",
    "of resinous dill",
    "graceful and grounding",
    "of an emotional escape",
    "of an intellectual exercise",
    "of decadent goods",
    "of magnolia and bergamot",
    "sour and fresh",
    "of tea and citrus",
    "of musky cassis",
    "of mature mahonia",
    "of honeysuckle and jasmine",
    "of tangy hyacinth",
    "repulsive",
    "of orange blossom",
    "of muddy river stones",
    "of peach and aldehyde",
    "nauseating",
    "of carbonated lemon",
    "of flowers and industry",
    "of a hunter's refuse",
    "of harshly cleaned sheets",
]

export const junglePlantFourBranchTypes = [

]

export const junglePlantFourLeafTypes = [
	"Dangling moss on its",
    "parrot-plumed",
    "Tall",
    "Thin",
    "Wavy",
    "light green",
    "Dry",
    "Coarse",
    "Itchy",
    "Smelly",
    "Dead",
    "bumpy",
    "white-spotted",
    "greenish-grey",
    "silvery green",
    "flowing",
    "wavy-edged",
    "rounded",
    "tall",
    "bright",
    "light",
    "towering",
    "spiky",
    "sharp",
    "pointy",
    "huge",
    "greenish yellow",
    "yellowing",
    "flagstaff",
    "crawling",
    "twisting",
    "thick",
    "flaky",
    "tremulous",
    "brittle",
    "flimsy",
    "tenuous",
    "friable",
    "a cobweb of",
    "stalking",
    "fruit-crowned",
    "trumpet-shoot",
    "waving",
    "wavering",
    "pliable",
    "seeping",
    "pockmarked",
    "diseased",
    "blighted",
    "galled",
    "beaks-edge",
    "persistent",
    "broad",
    "lobed",
]