import {
    CREATE_DIARY_ENTRY_LOADING,
    CREATE_DIARY_ENTRY_SUCCESS,
    CREATE_DIARY_ENTRY_FAILURE,
    GET_DIARY_ENTRIES_LOADING,
    GET_DIARY_ENTRIES_SUCCESS,
    GET_DIARY_ENTRIES_FAILURE,
    ADD_DIARY_ENTRY,
    UPDATE_DIARY_ENTRY,
    REMOVE_DIARY_ENTRY,
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';

export const createDiaryEntrySuccess = payload => ({
    type: CREATE_DIARY_ENTRY_SUCCESS,
    payload
})

export const createDiaryEntryFailure = () => ({
    type: CREATE_DIARY_ENTRY_FAILURE
})

export const createDiaryEntryAsync = payload => dispatch => {
    const { _id, text, characterId, subjectServiceName, subjectId, promptText } = payload;

    return client.service('diary-entries').create({
        text,
        promptText,
        characterId,
        createdAt: new Date().getTime(),
        subjectServiceName,
        subjectId,
    })
        .then((response) => {
        	console.log('diaryEntry instance: ', response);
            dispatch(createDiaryEntrySuccess(response))
        })
        .catch((error) => {
            console.error('error: ', error);
            dispatch(showUnknownError({ message: error.message }))
            throw new Error(error);
        });
}

export const deleteDiaryEntryAsync = payload => dispatch => {
    const { diaryEntry } = payload;

    return client.service('diary-entries').remove(diaryEntry._id)
        .then((response) => {
            console.log('diaryEntry instance: ', response);
            dispatch(removeDiaryEntry(payload))
        })
        .catch((error) => {
            console.error('error: ', error);
            dispatch(showUnknownError({ message: error.message }))
            throw new Error(error);
        });
}

export const getDiaryEntriesSuccess = payload => {
    return ({
        type: GET_DIARY_ENTRIES_SUCCESS,
        payload
    })
}

export const getDiaryEntriesFailure = () => ({
    type: GET_DIARY_ENTRIES_FAILURE
})

export const getDiaryEntriesAsync = payload => dispatch => {
    let query = { characterId: payload.characterId }

    return client.service('diary-entries').find({ query })
        .then((response) => {
            console.log('response', response);
            const diaryEntries = response.data.map(diaryEntry => ({ ...diaryEntry, diaryEntryType: payload.diaryEntryTypes?.find(type => type._id === diaryEntry.diaryEntryTypeId) }))
            dispatch(getDiaryEntriesSuccess(diaryEntries));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const addDiaryEntry = payload => {
    return ({
        type: ADD_DIARY_ENTRY,
        payload
    })
}

export const updateDiaryEntry = payload => {
    return ({
        type: UPDATE_DIARY_ENTRY,
        payload
    })
}

export const removeDiaryEntry = payload => {
    return ({
        type: REMOVE_DIARY_ENTRY,
        payload
    })
}
