import React from "react";
import { connect } from 'react-redux'

import './MovingError.css';

class MovingError extends React.Component {

    render() {

        return (
            <div className="moving-error">
                There is not enough space for that
            </div>
        )
    }
}

const mapToStateProps = state => {
    return {}
}

export default connect(
    mapToStateProps,
    {
        
    }
)(MovingError);