import {
  UPDATE_EMBARK_CHARACTER
} from "../actionTypes";

const initialState = {
  character: {
    name: '',
    text: '',
    color: '#fff',
    gender: '',
    selectedSprite: 1,
    selectedItemIndex: 0
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_EMBARK_CHARACTER: {
      return {
        ...state,
        character: {
          ...state.character,
          ...action.payload
        }
      };
    }

    default:
      return state;
  }
}
