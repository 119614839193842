import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_STAIRS_SUCCESS, ADD_STAIR, UPDATE_STAIR, REMOVE_STAIR, CLEAR_STAIRS, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  stairs: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_STAIRS_SUCCESS: {
      const stairs = action.payload;

      return {
        ...state,
        stairs: [...stairs.filter(stairInstance => stairInstance.panelId)],
      }
    }

    case ADD_STAIR: {
      let stairs = [...state.stairs];

      if (action.payload.panelId) {
        stairs = [
          ...stairs.filter(stairInstance =>
            stairInstance.panelId === action.payload.panelId &&
            !(stairInstance.position.x === action.payload.position.x && stairInstance.position.y === action.payload.position.y)
          ),
          action.payload
        ]
      }

      return {
        ...state,
        stairs: [ ...stairs ],
      }
    }

    case UPDATE_STAIR: {
      let stairs = [...state.stairs];

      if (action.payload.panelId) {
        stairs = [
          ...stairs.filter(stair => stair._id !== action.payload._id),
          { ...stairs.find(stair => stair._id === action.payload._id), ...action.payload }
        ]
      }

      return {
        ...state,
        stairs: [ ...stairs ],
      }
    }

    case REMOVE_STAIR: {
      return {
        ...state,
        stairs: [ ...state.stairs.filter(stair => (stair._id !== action.payload._id)) ],
      }
    }

    case CLEAR_STAIRS: {
      return {
        ...initialState
      }
    }

    default:
      return state;
  }
}
