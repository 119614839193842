import React from "react";
import { connect } from 'react-redux'

import { unlockLockAsync, lockLockAsync } from '../../../redux/actions/lock.actions';
import { selectHighlight, selectCharacter, selectCurrentLock } from '../../../redux/selectors';
import { client } from '../../../services/client';

class LockActions extends React.Component {

    previousCharacter;
    previousTiles;

    onLockClick(lock) {
        if (lock.isLocked) {
            this.props.unlockLockAsync(lock)
        } else {
            this.props.lockLockAsync(lock)
        }
    }

    render() {
        const unlock = !this.props.highlight && this.props.lock?.isLocked && (<span onClick={() => (this.onLockClick(this.props.lock))}>Unlock</span>)
        const lock = !this.props.highlight && this.props.lock?.isLocked === false && (<span onClick={() => (this.onLockClick(this.props.lock))}>Lock</span>)
        const lockActions = this.props.lock?._id && (
            <div>
                <ul>
                    <li key={this.props.lock?._id}>
                        {this.props.lock?.lockType?.name}&nbsp;

                        {unlock}
                        {lock}
                    </li>
                </ul>
            </div>
        )
        return (
            <div>
                {lockActions}
            </div>
        )
    }
}

const mapStateToProps = state => {
    const highlight = selectHighlight(state);
    const character = selectCharacter(state);

    const lock = selectCurrentLock(state, highlight || character.position);

    return { highlight, lock }
}

export default connect(
    mapStateToProps,
    {
        lockLockAsync,
        unlockLockAsync
    }
)(LockActions);