import React from "react";
import { connect } from 'react-redux'

import { selectTileRecipes } from '../../../redux/selectors';

import { client } from '../../../services/client';


class TileRecipes extends React.Component {

    render() {
        const listItems = this.props.tileRecipes
            .filter(tileRecipe => (tileRecipe.tileType?.name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase()) > -1))
            .sort((a, b) => (a.tileType?.name.localeCompare(b.tileType?.name)))
            .map((tileRecipe) => {
                return (
                    <li key={tileRecipe._id}
                        onClick={() => {
                            return (this.props.onItemSelect(tileRecipe))
                        }}>
                        <span className={this.props.selectedItem?._id === tileRecipe._id ? "selected": ""}>{tileRecipe.tileType.name}</span>
                    </li>
                );
            })
        return (
            <ul>{listItems}</ul>
        )
    }
}

const mapStateToProps = state => {
    const tileRecipes = selectTileRecipes(state)

    return { tileRecipes }
}

export default connect(
    mapStateToProps,
    {  }
)(TileRecipes);