import {
    GET_CRIMINALS_SUCCESS,
    ADD_CRIMINAL,
    UPDATE_CRIMINAL,
    REMOVE_CRIMINAL,
} from '../actionTypes';
import { clearAllTiles, loadTilesSuccess } from './tile.actions';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

export const getCriminalsSuccess = payload => {
    return ({
        type: GET_CRIMINALS_SUCCESS,
        payload
    })
}

export const addCriminal = payload => {
    return ({
        type: ADD_CRIMINAL,
        payload
    })
}

export const updateCriminal = payload => {
    return ({
        type: UPDATE_CRIMINAL,
        payload
    })
}

export const removeCriminal = payload => {
    return ({
        type: REMOVE_CRIMINAL,
        payload
    })
}

export const forgiveCriminalAsync = payload => dispatch => {
    const { _id } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'crimes',
        commandType: 'remove',
        data: {
            _id,
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}