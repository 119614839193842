import {
    CREATE_MINE_WALL_LOADING,
    CREATE_MINE_WALL_SUCCESS,
    CREATE_MINE_WALL_FAILURE,
    GET_MINE_WALLS_LOADING,
    GET_MINE_WALLS_SUCCESS,
    GET_MINE_WALLS_FAILURE,
    ADD_MINE_WALL,
    UPDATE_MINE_WALL,
    REMOVE_MINE_WALL,
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';

import { moveItemFailure } from './move-item.actions';

export const createMineWallSuccess = payload => ({
    type: CREATE_MINE_WALL_SUCCESS,
    payload
})

export const createMineWallFailure = () => ({
    type: CREATE_MINE_WALL_FAILURE
})

export const createMineWallAsync = payload => dispatch => {
    const { _id, mineWallTypeId } = payload;

    return client.service('mine-wall-instances').create({
        mineWallTypeId
    })
        .then((response) => {
        	console.log('mineWall instance: ', response);
            dispatch(createMineWallSuccess(response))
        })
        .catch((error) => {
            console.error('error: ', error);
            dispatch(showUnknownError({ message: error.message }))
            throw new Error(error);
        });
}

export const getMineWallsSuccess = payload => {
    return ({
        type: GET_MINE_WALLS_SUCCESS,
        payload
    })
}

export const getMineWallsFailure = () => ({
    type: GET_MINE_WALLS_FAILURE
})

export const getMineWallsAsync = payload => dispatch => {
    return client.service('mine-wall-instances').find()
        .then((response) => {
            response = response.map(mineWall => ({ ...mineWall, mineWallType: payload.mineWallTypes.find(type => type._id === mineWall.mineWallTypeId) }))
            dispatch(getMineWallsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const addMineWall = payload => {
    return ({
        type: ADD_MINE_WALL,
        payload
    })
}

export const updateMineWall = payload => {
    return ({
        type: UPDATE_MINE_WALL,
        payload
    })
}

export const removeMineWall = payload => {
    return ({
        type: REMOVE_MINE_WALL,
        payload
    })
}
