export const maizeSmellTypes = [
    "of grain",
    "of tasseled inflorescence",
    "of sweet corn",
    "starchy",
    "doughy",
    "bready",
    "starchy",
    "of wheat stirabout",
    "of hulls",
    "of stalk",
    "of bran",
    "of grass",
    "of grain",
    "tall",
    "of soured buttermilk",
    "of muddled flower",
    "of smoked coconut",
    "of sun and primrose",
    "banal",
    "of figgy earth",
    "of hazy cream",
    "crisp and herbal",
    "meadow sweet",
    "coconutty",
    "of grass and flowing water",
    "of soft linden blossom",
    "of cut threads of thought",
    "of caught meteor tails",
    "of  blooms at the bottom of a well",
    "of a creek cut field",
    "of dandelions and cowpats",
    "of broken bones",
    "of confined spaces",
    "of clover and blackthorn",
    "of scorching sunlight",
    "of cybernetic foliage",
    "of soliflore earthiness",
    "of used garden gloves",
    "buoyant and green",
    "of fungal decay",
    "of ink on skin",
    "of stewed nuts",
    "of rubble",
    "apprehensive",
    "of rotten river mud",
    "of sticky tears",
    "of metallic candy",
    "of a fruit pie and tabacco",
    "of a bright place",
    "and inexhaustibly cheery",
    "of bee sting",
]

export const maizeBranchTypes = [
	"leafy",
    "tall",
    "insect covered",
    "stiff",
    "flagstaff",
    "cane",
    "shafted",
    "baton",
    "upturned",
    "sun-framed",
    "unbowed",
    "mite-flecked",
    "untroubled",
    "pleasing",
    "yielding",
    "proud",
    "sage",
    "untroubled",
    "simple",
    "divided",
    "cheery",
    "naive",
    "long",
    "skyward",
    "headstrong",
    "undaunted",
    "healthy",
    "successful",
    "unlimited",
    "sturdy",
    "hardy",
    "wholesome",
    "skeletal",
    "sketchy",
    "slack",
    "straightforward",
    "modest",
    "worthy",
    "unshadowed",
    "stately",
]

export const maizeLeafTypes = [
	"Broad",
    "flat",
    "green",
    "bushy",
    "long",
    "thin",
    "big",
    "small",
    "waving",
    "wafting",
    "dreamy",
    "daydreaming",
    "shiny",
    "Sluggish",
    "Yellowing",
    "Golden",
    "Spotted",
    "Young",
    "New",
    "Fragrant",
    "Wavy",
    "Bright green",
    "scraggly",
    "thick",
    "flapping",
    "dangling",
    "Plump",
    "Downy",
    "crinkly",
    "crisp",
    "tasseled",
    "puckered",
    "coarse",
    "wide",
    "smooth",
    "furled",
    "crunchy",
    "dehydrated",
    "wave-riding",
]