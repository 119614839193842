export const hornBlowerFaceSentences = [
	"A long, curved beak juts out from a leathery face",
"Two small, black eyes sit atop a protruding beak",
"A sharp beak is flanked by two small, beady eyes",
"A long, curved beak that looks sharp enough to tear flesh",
"Green, scaly skin that looks tough and leathery",
"Two large, black eyes that seem to stare right through you",
"A beak that's too big for its face",
"A beak that's so big, it looks like it could poke your eye out",
"A beak that's sharp as a knife",
"A beak that's covered in spikes",
"A long, sharp beak",
"Small, beady eyes",
"A large, bulbous forehead",
"A scaly, reptilian face",
"A face that is half human, half bird",
"Its face is a mass of writhing tentacles, each tipped with a razor-sharp beak",
"Its beak is long and curved, dripping with venom",
"It has two rows of razor-sharp teeth, and a long, barbed tongue",
"red eyes that seem to pierce your soul"
]

export const hornBlowerBodySentences = [
	"A body with long, sharp claws",
"A body with a long, scaly tail",
"Bright eyes that seem to pierce your soul",
"Muscular body built for agility and speed",
"strange, sorcery-imbued tattoos that run along its body",
]

export const hornBlowerSoundWords = [
"Hissing",
"screeching",
"cackling",
"clacking",
"chattering",
"rattling",
"shrieking",
"howling",
"roaring",
"growling",
"Sneaky",
"Sly",
"Mysterious",
"Ominous",
"Eerie",
"Spooky",
"Menacing",
"Scary",
"Horror-inducing",
"Paranoia-inducing",
"Cheeping",
"Chirping",
"Cooing",
"Crooning",
"Gurgling",
"Honking",
"Hooting",
"Rambling",
"Squawking",
"Warbling",
"screeching",
"cawing",
"piercing",
"high-pitched",
"ear-splitting",
"grating",
"harsh",
"raucous",
"strident",
"unpleasantly loud",
"Roaring",
"Rumbling",
"Booming",
"crashing",
"Howling",
"Screaming",
"screeching",
"Yelling",
"Pounding",
"Rushing"
]

export const hornBlowerFurWords = [
	"Soft",
"Thick",
"course",
"prickly",
"oily",
"scaly",
"dull",
"brittle",
"Ragged",
"Tattered",
"Faded",
"Worn",
"Stained",
"Grubby",
"Crusty",
"Scabby",
"Oozing",
"Infested",
"Stiff",
"Dry",
"Crusty",
"Itchy",
"Oily",
"Scaly",
"Bumpy",
"Wrinkled",
"Warty",
"Hairy",
"Glossy", "smooth", "bumpy", "scaly", "dry", "velvety", "thick", "thin", "soft", "rough",
"Wispy",
"Diaphanous",
"Ethereal",
"Filmy",
"Gossamer",
"Pale",
"Ghostly",
"Shadowy",
"Vaporous",
]

export const hornBlowerSmellWords = [
	"rank",
"foul",
"acrid",
"putrid",
"stench",
"reek",
"stink",
"Pungent",
"Odorous",
"Foul",
"Nauseating",
"Repulsive",
"Stench",
"Smell",
"Aroma",
"Scent",
"Foul",
"Rancid",
"Stenchful",
"Odious",
"Pungent",
"Mephitic",
"Nauseating",
"Disgusting",
"Revolting",
"Repulsive",
"Pungent",
"Rancid",
"Ammonia-like",
"Vinegary",
"Iodine",
"Moldy",
"Fetid",
"Rotting",
"Decaying",
]

export const hornBlowerBehaviourSentences = [
	"it is picking at the ground with its beak",
	"it is eyeing you warily",
	"it is making strange chirping noises",
	"it is glaring at me with its beady little eyes",
	"it is standing far away from me, its beak open and its tongue lolling out",
	"it is making strange noises and has started to drool",
	"it is strangely still",
	"it is staring intently at something in the distance",
	"it is making strange chirping noises",
	"it is hopping around erratically",
	"it is watching us with its beak open",
	"it is standing completely still",
	"it is making strange noises",
	"it is making threatening gestures",
	"it is walking around in a very strange way",
	"it is making a lot of noise",
]