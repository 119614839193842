import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_WRITING_SURFACES_SUCCESS, GET_TRADE_WRITING_SURFACES_SUCCESS, ADD_WRITING_SURFACE, UPDATE_WRITING_SURFACE, REMOVE_WRITING_SURFACE, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  characterWritingSurfaces: [],
  tileWritingSurfaces: [],
  tradeWritingSurfaces: [],
  writingSurfaces: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_WRITING_SURFACES_SUCCESS: {
      const writingSurfaces = action.payload;

      return {
        ...state,
        writingSurfaces: [...writingSurfaces.filter(writingSurfaceInstance => writingSurfaceInstance.panelId)],
        characterWritingSurfaces: [...state.characterWritingSurfaces, ...writingSurfaces.filter(writingSurfaceInstance => writingSurfaceInstance.characterId)],
        tileWritingSurfaces: [...writingSurfaces.filter(writingSurfaceInstance => writingSurfaceInstance.tileId)],
      }
    }

    case GET_TRADE_WRITING_SURFACES_SUCCESS: {
      const writingSurfaces = action.payload;

      return {
        ...state,
        tradeWritingSurfaces: [...writingSurfaces],
      }
    }

    case ADD_WRITING_SURFACE: {
      let characterWritingSurfaces = [...state.characterWritingSurfaces];
      let tileWritingSurfaces = [...state.tileWritingSurfaces];
      let writingSurfaces = [...state.writingSurfaces];

      if (action.payload.characterId) {
        characterWritingSurfaces = [ ...characterWritingSurfaces, action.payload ]
      }

      if (action.payload.tileId) {
        tileWritingSurfaces = [ ...tileWritingSurfaces, action.payload ]
      }

      if (action.payload.panelId) {
        writingSurfaces = [ ...writingSurfaces, action.payload ]
      }

      return {
        ...state,
        characterWritingSurfaces: [ ...characterWritingSurfaces ],
        tileWritingSurfaces: [ ...tileWritingSurfaces ],
        writingSurfaces: [ ...writingSurfaces ],
      }
    }

    case UPDATE_WRITING_SURFACE: {
      let characterWritingSurfaces = [...state.characterWritingSurfaces];
      let tileWritingSurfaces = [...state.tileWritingSurfaces];
      let writingSurfaces = [...state.writingSurfaces];

      if (action.payload.characterId) {
        characterWritingSurfaces = [
          ...characterWritingSurfaces.filter(writingSurface => writingSurface._id !== action.payload._id),
          { ...characterWritingSurfaces.find(writingSurface => writingSurface._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.tileId) {
        tileWritingSurfaces = [
          ...tileWritingSurfaces.filter(writingSurface => writingSurface._id !== action.payload._id),
          { ...tileWritingSurfaces.find(writingSurface => writingSurface._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.panelId) {
        writingSurfaces = [
          ...writingSurfaces.filter(writingSurface => writingSurface._id !== action.payload._id),
          { ...writingSurfaces.find(writingSurface => writingSurface._id === action.payload._id), ...action.payload }
        ]
      }

      return {
        ...state,
        characterWritingSurfaces: [ ...characterWritingSurfaces ],
        tileWritingSurfaces: [ ...tileWritingSurfaces ],
        writingSurfaces: [ ...writingSurfaces ],
      }
    }

    case REMOVE_WRITING_SURFACE: {
      return {
        ...state,
        characterWritingSurfaces: [ ...state.characterWritingSurfaces.filter(writingSurface => (writingSurface._id !== action.payload._id)) ],
        tileWritingSurfaces: [ ...state.tileWritingSurfaces.filter(writingSurface => (writingSurface._id !== action.payload._id)) ],
        writingSurfaces: [ ...state.writingSurfaces.filter(writingSurface => (writingSurface._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
