export const mountainSmellTypes = [
    "of Pine",
    "of Dirt",
    "of Wildflowers",
    "coniferous",
    "woodsy",
    "of pine needle",
    "of cedar",
    "of dirt",
    "of rock",
    "of pine needle",
    "of snow",
    "of ice",
    "of wind",
    "of fog",
    "of fresh snow",
    "of crisp air",
    "of fir needles",
    "rocky",
    "earthy",
    "mossy",
    "nutty",
    "slippery",
    "powerful",
    "majestic",
    "briny",
]


export const mountainBranchTypes = [
	"moss covered",
    "debris covered",
    "dead leaf covered",
    "dirt covered",
    "Large",
    "Sturdy",
    "Thick",
    "Long",
    "Flexible",
    "Strong",
    "Abrasive",
    "Jagged",
    "Slippery",
    "lichen covered",
    "mossy",
    "dirty",
    "bird's nests in its",
    "small mushrooms growing on its",
    "Weeping",
    "mossy",
    "damp",
    "ferns growing from its",
    "shade covered"
]

export const mountainLeafTypes = [
]