import { GET_JEWELLERY_RECIPES_SUCCESS } from "../actionTypes";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_JEWELLERY_RECIPES_SUCCESS: {
      const jewelleryRecipes = action.payload;

      if (!jewelleryRecipes){
        return [...state];
      }

      return [...state, ...action.payload]
    }

    default:
      return state;
  }
}
