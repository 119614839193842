export const fernSmellTypes = [
	"of creeping damp",
    "of Turnip",
    "of Mustard Greens",
    "of Dandelion Greens",
    "of dill",
    "of Parsley",
    "of Tarragon",
    "Savory",
    "of celery",
    "herbal",
    "mossy",
    "fresh",
    "woodsy",
    "earthy",
    "grassy",
    "clean",
    "crisp",
    "of cumin",
    "of cardamom",
    "of coriander",
    "of sumac",
    "of saffron",
    "of moss",
    "of dirt",
    "of leaves",
    "of wood",
    "earth",
    "of wind",
    "of rain",
    "Crisp",
    "Fragrant",
    "Pleasant",
    "earthy",
    "youthful and bright",
    "a tree walk",
    "of an athletic type",
    "energising",
    "of vanilla and pear",
    "of sour, knitted wool",
    "camphorous",
    "cold and icy",
    "kind but distant",
    "of honey and green leaves",
    "of punchy patchouli",
    "tuberose",
    "of springtime",
    "of tinned fruits",
    "of washing powder",
    "of musk and laundry",
    "of a hopeful glance",
]

export const fernBranchTypes = [
	"long, slender",
    "elegant",
    "frond",
    "crimson",
    "sanguine",
    "russet",
    "fractal",
    "serrated",
    "simple",
    "lobed",
    "dwarf",
    "stunted",
    "stout",
    "spiralling",
    "Lacy",
    "delicate",
    "frond",
    "green",
    "slender",
    "smooth",
    "tapering",
    "wispy",
    "cinnamon-coloured",
    "rust-red",
    "reddish black",
    "wiry",
]

export const fernLeafTypes = [
	"thick",
    "glossy",
    "variegated",
    "interrupted",
    "felty",
    "felt-hairs on its",
    "bipinnate",
    "wide gaps between its",
    "lacy",
    "delicate",
    "doily",
    "pinnuled",
    "scaled",
    "triangular",
    "long",
    "oblong",
    "fan-shaped",
    "large",
    "compound",
    "toothed",
    "lopsided",
    "arching",
    "new",
    "big",
    "green",
    "fresh",
    "alive",
    "wispy",
    "thin",
    "long",
    "flat",
    "deeply lobed",
    "glossy",
    "textured",
    "fragile",
    "Green",
    "lush",
    "velvety",
    "bountiful",
    "verdant",
    "bipinnate",
    "a feathery clump of",
]