import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_WRITING_IMPLEMENTS_SUCCESS, ADD_WRITING_IMPLEMENT, UPDATE_WRITING_IMPLEMENT, REMOVE_WRITING_IMPLEMENT, GET_TRADE_WRITING_IMPLEMENTS_SUCCESS, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  characterWritingImplements: [],
  tileWritingImplements: [],
  tradeWritingImplements: [],
  writingImplements: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_WRITING_IMPLEMENTS_SUCCESS: {
      const writingImplements = action.payload;

      return {
        ...state,
        writingImplements: [...writingImplements.filter(writingImplementInstance => writingImplementInstance.panelId)],
        characterWritingImplements: [...state.characterWritingImplements, ...writingImplements.filter(writingImplementInstance => writingImplementInstance.characterId)],
        tileWritingImplements: [...writingImplements.filter(writingImplementInstance => writingImplementInstance.tileId)],
      }
    }

    case GET_TRADE_WRITING_IMPLEMENTS_SUCCESS: {
      const writingImplements = action.payload;

      return {
        ...state,
        tradeWritingImplements: [...writingImplements],
      }
    }

    case ADD_WRITING_IMPLEMENT: {
      let characterWritingImplements = [...state.characterWritingImplements];
      let tileWritingImplements = [...state.tileWritingImplements];
      let writingImplements = [...state.writingImplements];

      if (action.payload.characterId) {
        characterWritingImplements = [ ...characterWritingImplements, action.payload ]
      }

      if (action.payload.tileId) {
        tileWritingImplements = [ ...tileWritingImplements, action.payload ]
      }

      if (action.payload.panelId) {
        writingImplements = [ ...writingImplements, action.payload ]
      }

      return {
        ...state,
        characterWritingImplements: [ ...characterWritingImplements ],
        tileWritingImplements: [ ...tileWritingImplements ],
        writingImplements: [ ...writingImplements ],
      }
    }

    case UPDATE_WRITING_IMPLEMENT: {
      let characterWritingImplements = [...state.characterWritingImplements];
      let tileWritingImplements = [...state.tileWritingImplements];
      let writingImplements = [...state.writingImplements];

      if (action.payload.characterId) {
        characterWritingImplements = [
          ...characterWritingImplements.filter(writingImplement => writingImplement._id !== action.payload._id),
          { ...characterWritingImplements.find(writingImplement => writingImplement._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.tileId) {
        tileWritingImplements = [
          ...tileWritingImplements.filter(writingImplement => writingImplement._id !== action.payload._id),
          { ...tileWritingImplements.find(writingImplement => writingImplement._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.panelId) {
        writingImplements = [
          ...writingImplements.filter(writingImplement => writingImplement._id !== action.payload._id),
          { ...writingImplements.find(writingImplement => writingImplement._id === action.payload._id), ...action.payload }
        ]
      }

      return {
        ...state,
        characterWritingImplements: [ ...characterWritingImplements ],
        tileWritingImplements: [ ...tileWritingImplements ],
        writingImplements: [ ...writingImplements ],
      }
    }

    case REMOVE_WRITING_IMPLEMENT: {
      return {
        ...state,
        characterWritingImplements: [ ...state.characterWritingImplements.filter(writingImplement => (writingImplement._id !== action.payload._id)) ],
        tileWritingImplements: [ ...state.tileWritingImplements.filter(writingImplement => (writingImplement._id !== action.payload._id)) ],
        writingImplements: [ ...state.writingImplements.filter(writingImplement => (writingImplement._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
