import { SHOW_CHARACTER_DEATH_PAGE, GET_CHARACTER_LEVELS_SUCCESS, NEW_CHARACTER_LEVEL_SUCCESS, UPGRADE_CHARACTER_LEVEL_SUCCESS } from "../actionTypes";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {

    case SHOW_CHARACTER_DEATH_PAGE : {
      return []
    }

    case GET_CHARACTER_LEVELS_SUCCESS: {
      const characterLevels = action.payload;

      if (!characterLevels){
        return [...state];
      }

      return [
        ...characterLevels
      ]
    }

    case NEW_CHARACTER_LEVEL_SUCCESS:
    case UPGRADE_CHARACTER_LEVEL_SUCCESS: {

      const characterLevel = action.payload;
      const oldCharacterLevels = [ ...state.filter(level => (level._id !== characterLevel._id)) ]

      return [
        ...oldCharacterLevels,
        { ...characterLevel }
      ]
    }

    default:
      return state;
  }
}
