import { GET_COIN_TYPES_LOADING, GET_COIN_TYPES_SUCCESS, CREATE_COIN_TYPE_SUCCESS } from '../actionTypes';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';

export const getCoinTypesSuccess = payload => {
    return ({
        type: GET_COIN_TYPES_SUCCESS,
        payload
    })
}

export const getCoinTypesAsync = payload => dispatch => {
    return client.service('coin-types').find()
        .then((response) => {
            dispatch(getCoinTypesSuccess(response));
            return response
        })
        .catch((error) => {
            console.error('error', error);
        })
}

export const createCoinTypeSuccess = payload => {
    return ({
        type: CREATE_COIN_TYPE_SUCCESS,
        payload
    })
}

export const createCoinTypeAsync = payload => dispatch => {
    const { name, metalTypeId, currencyId, workshopTypeId, weight, value } = payload;

    return client.service('coin-types').create({
        name,
        metalTypeId,
        currencyId,
        value,
        weight,
        workshopTypeId
    })
        .then((response) => {
            dispatch(createCoinTypeSuccess(response))
            return response
        })
        .catch((error) => {
            console.error('error', error);
        })
}