export const shooterFaceSentences = [
"protruding, green horns",
"bulbous, green eyes",
"mottled, green skin",
"large, green mouth",
"slobbering, green tongue",
"sharp, green teeth",
"putrid, green breath",
"A face with a wicked grin.",
"A face with large, menacing eyes.",
"A face with sharp, pointy teeth.",
"A face with a long, slimy tongue.",
"Wet, drooling mouth lined with razor-sharp teeth.",
"Glaring, bottomless black pits for eyes.",
"Greasy, green scales covering a lumpy, misshapen skull.",
"Horns spiraling out of the temples, long and sharp.",
"Two large, curved horns protruding from the temples.",
"A forest-green complexion, with darker green patches around the eyes.",
"A wide, open mouth with sharp teeth.",
"Small, black eyes set deep in the sockets.",
"A wrinkled forehead",
"Bulging, bloodshot eyes glaring out from beneath a jagged-edged forehead. ",
"A drooling mouth filled with sharp, yellowed teeth. ",
"Flapping nostrils, sniffing hungrily for its next victim. ",
]

export const shooterBodySentences = [
"It's body is covered in green scales.",
"It has two large horns sticking out of its head.",
"Its eyes are a deep, murky green.",
"It's body is covered in slimy green scales.",
"It has a long, forked tongue that lolls out of its mouth.",
"A sickly green hue that makes you want to puke ",
"A smell that can only be described as rotting meat ",
"Ropy, stringy muscles that look ready to snap ",
"It's body is long and lithe, covered in bright green scales.",
"It has two long horns that curve outwards.",
]

export const shooterSoundWords = [
    "startling",
    "piercing",
    "alerting",
    "shrill",
    "grating",
    "earsplitting",
    "deafening",
    "blaring",
    "Snorting",
    "Roaring",
    "Ravaging",
    "Rumbling",
    "Crashing",
    "Tromping",
    "bellowing",
    "smashing",
    "Howling",
    "lumbering",
    "snorting",
    "stomping",
    "shouting",
    "roaring",
    "howling",
    "screeching",
    "trumpeting",
    "clattering",
    "banging",
    "crashing",
    "Rumbling",
    "Hissing",
    "Roaring",
    "Growling",
    "Snarling",
    "Grunting",
    "Bellowing",
    "Raging",
    "Fierce",
    "Ferocious",
    "blaring",
    "deafening",
    "screeching",
    "wailing",
    "terrible"
]

export const shooterFurWords = [
    "prickly",
    "rough",
    "scaly",
    "slimy",
    "bumpy",
    "tough",
    "Aria",
    "durable",
    "thick",
    "resilient",
    "Gleaming",
    "Prismatic",
    "Textured",
    "Fiery",
    "Volcanic",
    "Sulphurous",
    "Bestial",
    "Unnatural",
    "Otherworldly",
    "Slimy",
    "Green",
    "Horny",
    "Hairy",
    "Stinky",
    "Oozing",
    "Warty",
    "Scaly",
    "Rough",
    "Foul",
    "Slimy",
    "scaly",
    "wet",
    "dry",
    "bumpy",
    "smooth",
    "ridged",
    "hard",
    "soft",
    "velvety",
    "Spiky",
    "Scaly",
    "Slimy",
    "Green",
    "Horned",
    "Monster",
    "Fur",
    "Skin",
    "Hairy",
    "Reptilian"
]

export const shooterSmellWords = [
"reeking",
"putrid",
"stinking",
"foul",
"nauseating",
"Pungent",
"Foul",
"Rancid",
"putrid",
"Offensive",
"Repulsive",
"Nauseating",
"Disgusting",
"Revolting",
"Ill-scented"
]

export const shooterBehaviourSentences = [
"it is staring at me with its eerie green eyes",
"it is licking its lips",
"it is drooling",
"it is making a strange grunting noise",
"it is staring at me with its eerie green eyes",
"it is observing you with its large, green eyes",
"it is showing its large, sharp teeth",
"looming in the distance",
"gaping at you",
"sneering at you",
"leering at you",
"glaring at you",
]