import React from "react";
import { connect } from 'react-redux'

import { client } from '../../../services/client';
import { selectHighlight, selectCharacter, selectTileMachines } from '../../../redux/selectors';
import { flashCharacterColorAsync } from '../../../redux/actions/characters.actions';
import { showFightConfirmation } from '../../../redux/actions/keyboard-shortcuts.actions';

class MachineList extends React.Component {

    componentDidMount() {
        
    }

    toggleColor(character) {
        this.props.flashCharacterColorAsync(character);
    }

    render() {
        const machines = this.props.machines?.map((machine) => {
            return (
                <li key={machine._id}>
                    <div>{machine.machineType.name}</div>
                </li>
            )
        })
        return (
        	this.props.machines?.length > 0 ?
        	(
	            <ul className="neighbouring-characters character-list">
	                {machines}
	            </ul>
	        ) : ('')
        )
    }
}

const mapToStateProps = state => {
    const highlight = selectHighlight(state);
    const character = selectCharacter(state);
	const machines = selectTileMachines(state, highlight ? { position: { ...highlight } } : character);

    return { machines }
}

export default connect(
    mapToStateProps,
    {
        showFightConfirmation,
        flashCharacterColorAsync,
    }
)(MachineList);