import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_WORKSHOPS_SUCCESS, ADD_WORKSHOP, UPDATE_WORKSHOP, REMOVE_WORKSHOP, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

import { getTentBoundries } from '../../services/tent-helpers';

const initialState = {
  characterWorkshops: [],
  workshops: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_WORKSHOPS_SUCCESS: {
      const workshops = action.payload;

      return {
        ...state,
        characterWorkshops: [...workshops.filter(workshopInstance => workshopInstance.characterId).map(workshop => ({ ...workshop, workshopBoundries: getTentBoundries(workshop.position, 'north', workshop.workshopType) }))],
        workshops: [...workshops.filter(workshopInstance => workshopInstance.panelId).map(workshop => ({ ...workshop, workshopBoundries: getTentBoundries(workshop.position, 'north', workshop.workshopType) }))],
        tileWorkshops: [...workshops.filter(workshopInstance => workshopInstance.tileId).map(workshop => ({ ...workshop, workshopBoundries: getTentBoundries(workshop.position, 'north', workshop.workshopType) }))],
      }
    }

    case ADD_WORKSHOP: {
      let characterWorkshops = [...state.characterWorkshops];
      let tileWorkshops = [...state.tileWorkshops];
      let workshops = [...state.workshops];

      if (action.payload.characterId) {
        characterWorkshops = [ ...characterWorkshops, action.payload ]
      }

      if (action.payload.tileId) {
        tileWorkshops = [ ...characterWorkshops, action.payload ]
      }

      if (action.payload.panelId) {
        workshops = [ ...workshops, action.payload ]
      }

      return {
        ...state,
        characterWorkshops: [ ...characterWorkshops ],
        tileWorkshops: [ ...tileWorkshops ],
        workshops: [ ...workshops ],
      }
    }

    case UPDATE_WORKSHOP: {
      let characterWorkshops = [...state.characterWorkshops.filter(workshop => workshop._id !== action.payload._id)];
      let tileWorkshops = [...state.tileWorkshops.filter(workshop => workshop._id !== action.payload._id)];
      let workshops = [...state.workshops.filter(workshop => workshop._id !== action.payload._id)];

      if (action.payload.characterId) {
        characterWorkshops = [
          ...characterWorkshops.filter(workshop => workshop._id !== action.payload._id),
          { ...characterWorkshops.find(workshop => workshop._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.tileId) {
        tileWorkshops = [
          ...tileWorkshops.filter(tool => tool._id !== action.payload._id),
          { ...tileWorkshops.find(tool => tool._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.panelId) {
        workshops = [
          ...workshops.filter(workshop => workshop._id !== action.payload._id),
          { ...workshops.find(workshop => workshop._id === action.payload._id), ...action.payload }
        ]
      }

      return {
        ...state,
        characterWorkshops: [ ...characterWorkshops ],
        tileWorkshops: [ ...tileWorkshops ],
        workshops: [ ...workshops ],
      }
    }

    case REMOVE_WORKSHOP: {
      return {
        ...state,
        characterWorkshops: [ ...state.characterWorkshops.filter(workshop => (workshop._id !== action.payload._id)) ],
        tileWorkshops: [ ...state.tileWorkshops.filter(tool => (tool._id !== action.payload._id)) ],
        workshops: [ ...state.workshops.filter(workshop => (workshop._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
