import { PANEL_LOADING, PANEL_SUCCESS, PANEL_FAILURE } from '../actionTypes';
import { clearAllTiles, loadTilesSuccess } from './tile.actions';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';

export const loadPanelSuccess = payload => {
    return ({
        type: PANEL_SUCCESS,
        payload
    })
}

export const loadPanelFailure = payload => ({
    type: PANEL_FAILURE
})

export const loadPanelAsync = payload => dispatch => {
    const { panelId } = payload;

    if (!panelId) {
        return;
    }

    return client.service('panels').get(panelId)
        .then((response) => {
            // dispatch(loadPanelSuccess(response))
            return response;
        })
        .catch((error) => {
            dispatch(showUnknownError())
            console.error('error', error);
            throw new Error(error);
        })
}