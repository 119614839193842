export const deathSongFaceSentences = [
"It has a large mouth with sharp teeth ",
"Its large, dark eyes are full of hate.",
"The long, sharp teeth are dripping with blood.",
"The face is twisted in a maniacal grin.",
"The skin is pale and deathly cold.",
"It has black, empty sockets where eyes should",
"it monster has a face that is pale and gaunt, with dark circles around its eye holes.",
"It has a long, sharp nose and a mouth that is always open, revealing sharp teeth.",
"its face is skeletal, with sunken eyes and bared teeth.",
"its face is gaunt and pale, with dark circles beneath its empty eyes.",
"Large, black eyes that seem to suck the light out of the room. ",
"A long, sharp beak that looks deadly. ",
]

export const deathSongBodySentences = [
"Its body is bloated and covered in sores",
"Its fur is matted and falling out in patches",
"The stench of death surrounds it. Its eyes are sunken and lifeless",
"It smells of death and decay, and its eyes are sunken and lifeless",
"Its fur is matted and filthy, and its flesh is cold and clammy to the touch",
"whooshing waves of deathly sound",
"crashing crescendos of pain",
"teeth-gnashing melodies of misery",
"atrocity opera howls of horror",
"dirges of the damned screaming",
"requiems for the rotting dead",
]

export const deathSongSoundWords = [
    "Haunting",
    "Eerie",
    "Spooky",
    "Grim",
    "Macabre",
    "Creepy",
    "Unnerving",
    "Ghastly",
    "Menacing",
    "foreboding",
    "Choking",
    "Rasping",
    "Gurgling",
    "Strangled",
    "Wheezing",
    "Strangulated",
    "Choked",
    "Gasping",
    "Suffocating",
    "Drowning",
    "Haunting",
    "Eerie",
    "Ominous",
    "Chilling",
    "Blood-curdling",
    "spine-tingling",
    "bone-chilling",
    "heart-stopping",
    "mind-numbing",
    "howling",
    "wailing",
    "screeching",
    "yelping",
    "ululating",
    "moaning",
    "hissing",
    "rasping",
    "gibbering",
    "chanting",
    "Roaring",
    "screeching",
    "howling",
    "yowling",
    "wailing",
    "sobbing",
    "crying",
    "moaning",
    "groaning",
    "keening"
]

export const deathSongFurWords = [
"Oily",
"Slimy",
"Bumpy",
"Flaky",
"Sticky",
"Frigid",
"Sharp",
"Rigid",
"Brittle",
"deathly",
"rotting",
"decayed",
"brittle",
"dry",
"leathery",
"scaly",
"putrid",
"stiff",
"cold",
"shiny",
"wet",
"Cold",
"Scaly",
"Slippery",
"Wet",
"slimy",
"Smelly",
"Ugly",
"oily",
"tough",
]

export const deathSongSmellWords = [
    "Salty",
    "fishy",
    "rank",
    "putrid",
    "rotten",
    "stinking",
    "stenchy",
    "furry",
    "slimy",
    "unpleasant",
    "Grim",
    "Haunting",
    "Ethereal",
    "Melancholy",
    "Sorrowful",
    "Morbid",
    "Spooky",
    "Creepy",
    "Macabre",
    "Sinister",
    "Pungent",
    "Rancid",
    "Stench",
    "Putrid",
    "Nauseating",
    "Disgusting",
    "Repulsive",
    "Revolting",
    "Beastly",
    "Foul",
    "putrid",
    "rotting",
    "fetid",
    "decomposing",
    "stinking",
    "reeking",
    "malodorous",
    "rank",
    "foul",
    "stench-filled",
    "putrid",
    "rank",
    "decayed",
    "stagnant",
    "musty",
    "fouled",
    "reeking",
    "pungent",
    "fetid",
    "vile"
]

export const deathSongBehaviourSentences = [
"it is staring at me with its beady eyes",
"it is flapping its wings menacingly",
"it is showing its sharp teeth",
"it is singing opera",
"it is making a strange noise",
"it is not moving",
"it is bleeding",
"it is swollen",
"it has strange bumps",
"it is foaming at the mouth",
]