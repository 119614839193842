import {
  BEGIN_UPDATE_POSITION,
  BEGIN_UPDATE_EMBARK_POSITION,
  UPDATE_POSITION_FAILURE,
  UPDATE_EMBARK_POSITION_FAILURE,
  GET_CHARACTERS_SUCCESS,
  GET_EMBARK_CHARACTERS_FROM_GROUP_SUCCESS,
  UPDATE_POSITION_SUCCESS,
  UPDATE_EMBARK_POSITION_SUCCESS,
  ENABLE_CHARACTER_COLOR,
  DISABLE_CHARACTER_COLOR,
  ADD_CHARACTER,
  UPDATE_CHARACTER,
  UPDATE_CHARACTER_TOOL,
  REMOVE_CHARACTER,
  SOLO_EMBARK_LOADING,
  UPDATE_CUTSCENE_POSITION_SUCCESS,
  SOLO_EMBARK_SUCCESS,
  BACK_TO_START_PAGE,
  SHOW_CHARACTER_DEATH_PAGE,
  START_NEW_GAME,
  INITIALISING_PANEL_STARTED,
  CHANGE_CHARACTER
} from "../actionTypes";

const initialState = {
  characters: []
};

export default function(state = initialState, action) {
  switch (action.type) {

    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return { ...initialState }
    }

    case BEGIN_UPDATE_POSITION: 
    case BEGIN_UPDATE_EMBARK_POSITION: {
      const updatedCharacter = action.payload;
      const { position } = updatedCharacter;

      const character = state.characters.find((character) => character._id === updatedCharacter._id);

      if (!character) {
        return {
          ...state
        }
      }

      character.isWalking = true;
      character.position = { ...position }

      return {
        ...state,
      }
    }

    case UPDATE_POSITION_FAILURE: 
    case UPDATE_EMBARK_POSITION_FAILURE: {

      const updatedCharacter = action.payload;

      const character = state.characters.find((character) => character._id === updatedCharacter._id);

      if (!character) {
        return {
          ...state
        }
      }

      character.isWalking = false;

      if (character.serverPosition.x !== undefined) {
        character.position = { ...character.serverPosition };
      }

      return {
        ...state,
      }
    }

    case BACK_TO_START_PAGE:
    case SOLO_EMBARK_SUCCESS: {
      return {
        ...initialState
      }
    }

    case GET_CHARACTERS_SUCCESS: {
      return {
        ...state,
        characters: [...action.payload.map(character => ({ ...character, serverPosition: { ...character.position } }))],
      }
    }

    case UPDATE_CUTSCENE_POSITION_SUCCESS: {
      const updatedCharacter = action.payload;

      const character = state.characters.find((character) => character._id === updatedCharacter._id);
      character.isWalking = false;
      character.position = action.payload.position

      return {
        ...state,
      }
    }

    case UPDATE_EMBARK_POSITION_SUCCESS: {
      const updatedCharacter = action.payload;

      const character = state.characters.find((character) => character._id === updatedCharacter._id);
      character.isWalking = false;
      character.position = action.payload.position

      return {
        ...state,
      }
    }

    case UPDATE_POSITION_SUCCESS: {
      const updatedCharacter = action.payload;

      const character = state.characters.find((character) => character._id === updatedCharacter._id);
      character.isWalking = false;

      return {
        ...state,
      }
    }

    case ENABLE_CHARACTER_COLOR: {
      const updatedCharacter = action.payload;

      const character = state.characters.find((character) => character._id === updatedCharacter._id);
      character.isColorShowing = true

      return {
        ...state,
      }
    }

    case DISABLE_CHARACTER_COLOR: {
      const updatedCharacter = action.payload;

      const character = state.characters.find((character) => character._id === updatedCharacter._id);
      character.isColorShowing = false

      return {
        ...state,
      }
    }

    case ADD_CHARACTER: {
      let characters = [...state.characters];

      if (action.payload.panelId) {
        characters = [ ...characters, action.payload ]
      }

      return {
        ...state,
        characters: [ ...characters ],
      }
    }

    case UPDATE_CHARACTER: {
      let characters = [...state.characters];

      if (action.payload.panelId || action.payload.embarkGroupId) {
        const oldCharacter = { ...characters.find(character => character._id === action.payload._id) }
        const newCharacter = {
          ...oldCharacter,
          isWalking: false,
          ...action.payload
        }

        characters = [
          ...characters.filter(character => character._id !== action.payload._id),
          { ...newCharacter }
        ]
      }

      return {
        ...state,
        characters: [ ...characters ],
      }
    }

    case UPDATE_CHARACTER_TOOL: {
      let characters = [...state.characters];

      if (action.payload.panelId || action.payload.embarkGroupId) {
        characters = [
          ...characters.filter(character => character._id !== action.payload._id),
          {
            ...characters.find(character => character._id === action.payload._id),
            isWalking: false,
            lightSource: action.payload.lightSource,
            ...action.payload
          }
        ]
      }

      return {
        ...state,
        characters: [ ...characters ],
      }
    }

    case REMOVE_CHARACTER: {
      return {
        ...state,
        characters: [ ...state.characters.filter(character => (character._id !== action.payload._id)) ],
      }
    }

    case SOLO_EMBARK_LOADING: {
      return {
        ...state,
        characters: [ ...state.characters.map(character => ({ ...character, isTeleporting: true })) ],
      }
    }

    default:
      return state;
  }
}
