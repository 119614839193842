export const oldGrowthSmellTypes = [
    "of Ground ivy",
    "Mouldy",
    "Musty",
    "of synthetic loam",
    "pristine",
    "shimmering",
    "luminous",
    "verdant",
    "bucolic",
    "perfumed",
    "fragrant",
    "heady",
    "resinous",
    "of cucumberwood",
    "of fanaloka",
    "fustic",
    "of guaiacum",
    "of Bog",
    "of just-cut pomelo",
    "of green pulp",
    "monotonous",
    "of cork and citrus",
    "of stale breath",
    "of metallic oak",
    "of firepowder and gentle wood",
    "of blazing saltpeter",
    "of cocoa and cedar",
    "of small green daggers",
    "of unwanted guests",
    "of a charnel ground",
    "of mass appeal",
    "of what the CEOs requested",
    "of ridiculous formality",
    "of fire from within",
    "of flesh and sulfur",
    "of generosity",
    "of gravel and blood",
    "of flame-touched quinine",
    "of beeswax and burnt hair",
    "of autumnal bonfires",
    "of smoked meats",
    "of burnt herbal paste",
    "bitter and sweet",
    "of myrrh and caraway",
    "of ill-begotten fruits",
    "riddled with rot",
    "of stripped bark",
    "of the eyes of a millenia",
    "of an antique world",
    "puzzling",
    "of the ancient wilds",
]

export const oldGrowthBranchTypes = [
	"insect covered",
    "bird covered",
    "chestnut",
    "Prickly",
    "gray",
    "black",
    "white",
    "soughing",
    "bark",
    "magisterial",
    "astonishing",
    "powerful",
    "thriving",
    "mast-like",
    "splendid",
    "looming",
    "senatorial",
    "a gentle-hearted trunk and towering",
    "sky-scratching",
    "cloud-catching",
    "armour-clad",
    "mud-cut roots and colossal",
    "hackmill-high",
    "servestack-high",
    "haughty",
    "proud",
    "plodding",
]

export const oldGrowthLeafTypes = [
	"Broad",
    "large",
    "green",
    "thin",
    "long",
    "pointy",
    "sharp",
    "hard",
    "sturdy",
    "ovoid",
    "waving",
    "gorse",
    "columnar",
    "scaley",
    "deciduous",
    "ovate",
    "lobed",
    "climactic",
    "sleepy sunlight peeking through its",
    "sun-streaming",
    "canopy-forming",
    "polygonal",
    "crackled",
    "speckled",
    "puff-pastry",
    "patterned",
]