import React from "react";
import { connect } from 'react-redux'

import { getFoodRecipesAsync } from '../../../redux/actions/food-recipes.actions';
import { selectFoodRecipes, selectCharacterFood } from '../../../redux/selectors';

import { client } from '../../../services/client';

import './CharacterFood.css';

class CharacterFood extends React.Component {

    render() {
        let listItems = this.props.characterFood.map((foodRecipe, index) => {
            return (
                <li key={index}
                    onClick={() => {
                        return (this.props.onItemSelect(foodRecipe))
                    }}>
                    {foodRecipe?.foodType?.name}
                </li>
            );
        })

        if (this.props.characterFood.length === 0) {
            listItems = (<span>You are not holding any food</span>)
        }

        return (
            <ul>{listItems}</ul>
        )
    }
}

const mapStateToProps = state => {
    const characterFood = selectCharacterFood(state)

    return { characterFood }
}

export default connect(
    mapStateToProps,
    { getFoodRecipesAsync }
)(CharacterFood);