import {
    GET_CONSTRUCTION_RECIPES_SUCCESS,
    GET_CONSTRUCTION_RECIPES_FAILURE
} from '../actionTypes';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';

export const getConstructionRecipesSuccess = payload => {
    return ({
        type: GET_CONSTRUCTION_RECIPES_SUCCESS,
        payload
    })
}

export const getConstructionRecipesFailure = payload => ({
    type: GET_CONSTRUCTION_RECIPES_FAILURE
})

export const getConstructionRecipesAsync = payload => dispatch => {
    return dispatch(getRecursivelyAsync('construction-recipes', getConstructionRecipesSuccess, 0));
}