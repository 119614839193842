import {
    GET_JEWELLERY_RECIPES_LOADING,
    GET_JEWELLERY_RECIPES_SUCCESS,
    GET_JEWELLERY_RECIPES_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';

export const getJewelleryRecipesSuccess = payload => ({
    type: GET_JEWELLERY_RECIPES_SUCCESS,
    payload
})

export const getJewelleryRecipesFailure = () => ({
    type: GET_JEWELLERY_RECIPES_FAILURE
})

export const getJewelleryRecipesAsync = payload => dispatch => {
    return dispatch(getRecursivelyAsync('jewellery-recipes', getJewelleryRecipesSuccess, 0));
}