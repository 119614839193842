export const gooseFootSmellTypes = [
    "vegetal",
    "herbal",
    "resinous",
    "hoppy",
    "floral",
    "spicy",
    "of rice",
    "malty",
    "of wheat",
    "of bread",
    "of yeast",
    "fruity",
    "nutty",
    "tangy",
    "sour",
    "hoppy",
    "of beef",
    "of broth",
    "of cabbage",
    "of carrots",
    "of celery",
    "of pea",
    "of clean steam",
    "zingy",
    "of sweet fruit juice",
    "summery and tranquil",
    "sickly",
    "of parasols and lipstick",
    "of ripened berries",
    "of powdered plastic",
    "of icing sugar",
    "of gooseberry and lilac",
    "sun-hardened fruits",
    "of spiced flowers",
    "of dry, salty stones",
    "fruity in a sickening way",
    "of withered flowers",
    "of tart fruits",
    "of syrup and lychee",
    "of liquid medicine",
    "of musky rhubarb",
    "tart and airy",
    "delicious",
    "of burnt candyfloss",
    "of fruity, sour soap",
    "of peppercorn and rose water",
    "of drying paint",
    "of broken gingersnaps",
    "of being amicably lost",
    "of particulate wood",
]

export const gooseFootBranchTypes = [
    "broad",
    "fat",
    "fresh",
    "juicy",
    "long",
    "Big",
    "Brown",
    "Bumpy",
    "Coarse",
    "Fat",
    "Grainy",
    "Hard",
    "Rough",
    "Crisp",
    "Flat",
    "altitude-seeking",
    "green",
    "red",
    "purple",
    "simple",
    "grain-bearing",
    "grain-crowned",
    "sand-cracking roots and simple",
    "upright",
    "recumbent",
    "lacklustre",
]

export const gooseFootLeafTypes = [
	"medicinal",
    "delicious",
    "nourishing",
    "nutrient-rich",
    "alternate",
    "varied",
    "oddly shaped",
    "lanceolate",
    "waxy-coated",
    "unwettable",
    "mealy",
    "toothed",
    "rough",
    "pike-shaped",
    "symmetrical",
    "serrated",
    "evenly-lobed",
    "lenticular",
    "elliptic",
    "bluntly-pointed",
    "white-mealy",
    "smooth",
    "sparse",
    "negligible",
    "edible",
    "fruity",
    "feathery",
    "fluffy",
    "powdery",
    "hairy",
    "lobed",
    "glaucous",
    "broad",
    "frizzy",
]