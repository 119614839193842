import React from "react";
import { connect } from 'react-redux'
import { selectJewelleryRecipes } from '../../../redux/selectors';

import { client } from '../../../services/client';

class JewelleryRecipes extends React.Component {

    render() {
        const listItems = this.props.jewelleryRecipes
            .filter(jewelleryRecipe => (jewelleryRecipe.jewelleryType.name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase()) > -1))
            .sort((a, b) => (a.jewelleryType?.name.localeCompare(b.jewelleryType?.name)))
            .map((jewelleryRecipe) => {
                return (
                    <li key={jewelleryRecipe._id}
                        onClick={() => {
                            return (this.props.onItemSelect(jewelleryRecipe))
                        }}>
                        <span className={this.props.selectedItem?._id === jewelleryRecipe._id ? "selected": ""}>{jewelleryRecipe.jewelleryType.name}</span>
                    </li>
                );
            })
        return (
            <ul>{listItems}</ul>
        )
    }
}

const mapStateToProps = state => {
    const jewelleryRecipes = selectJewelleryRecipes(state)

    return { jewelleryRecipes }
}

export default connect(
    mapStateToProps,
    {  }
)(JewelleryRecipes);