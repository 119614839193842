class AudioService {
    phaser = undefined;

    audioFiles = [
        // Music
        {
            name: 'dry',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/Dryspell_1.mp3',
        },
        {
            name: 'evergreen',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/Evergreen_1.mp3',
        },
        {
            name: 'mountains',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/Mayaya_2(sandy,%20mountains).mp3',
        },
        {
            name: 'tundra',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/Octagonal_2.mp3',
        },
        {
            name: 'savannah',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/Sav%20Anna_1.mp3',
        },
        {
            name: 'forests',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/Shimmer-Dimmer_2(forests,%20fur%20forests,%20mines).mp3',
        },
        {
            name: 'steppes',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/Steppes.mp3',
        },
        // Sound
        {
                name: 'blacksmith',
                url: '/assets/sounds/blacksmith.wav',
                cooldown: 1000
        },
        {
                name: 'carpenter',
                url: '/assets/sounds/carpenter.wav',
                cooldown: 1000
        },
        {
                name: 'collectItem', //DONE?
                url: '/assets/sounds/collect-item.wav',
                cooldown: 1000
        },
        {
                name: 'collectMineral', //DONE?
                url: '/assets/sounds/collect-mineral.wav',
                cooldown: 1000
        },
        {
                name: 'collectPlant', //DONE?
                url: '/assets/sounds/collecting-plant.wav',
                cooldown: 1000
        },
        {
                name: 'collier',
                url: '/assets/sounds/collier.wav',
                cooldown: 1000
        },
        {
                name: 'confirm',
                url: '/assets/sounds/confirm.wav',
                cooldown: 1000
        },
        {
                name: 'craft',
                url: '/assets/sounds/craft.wav',
                cooldown: 1000,
                volume: 0.3
        },
        {
                name: 'death',
                url: '/assets/sounds/death.wav',
                cooldown: 1000
        },
        {
                name: 'doctor',
                url: '/assets/sounds/doctor.wav',
                cooldown: 1000
        },
        {
                name: 'fight',
                url: '/assets/sounds/fight.wav',
                cooldown: 1000
        },
        {
                name: 'fire',//DONE?
                url: '/assets/sounds/fire.wav',
                cooldown: 1000
        },
        {
                name: 'footstep',
                url: '/assets/sounds/footstep.wav',
                cooldown: 1000
        },
        {
                name: 'generate',
                url: '/assets/sounds/generating-panel.wav',
                cooldown: 1000
        },
        {
                name: 'hunger',
                url: '/assets/sounds/hunger.wav',
                cooldown: 1000
        },
        {
                name: 'kitchen',
                url: '/assets/sounds/kitchen.wav',
                cooldown: 1000
        },
        {
                name: 'lock',
                url: '/assets/sounds/lock.wav',
                cooldown: 1000
        },
        {
                name: 'mason',
                url: '/assets/sounds/mason.wav',
                cooldown: 1000
        },
        {
                name: 'closeMenu', //DONE
                url: '/assets/sounds/menu-close.wav',
                cooldown: 0
        },
        {
                name: 'openMenu', //DONE
                url: '/assets/sounds/menu-open.wav',
                cooldown: 0
        },
        {
                name: 'scrollMenu',
                url: '/assets/sounds/menu-scroll.wav',
                cooldown: 1000
        },
        {
                name: 'potter',
                url: '/assets/sounds/potter.wav',
                cooldown: 1000
        },
        {
                name: 'speak', //DONE
                url: '/assets/sounds/speak.wav',
                cooldown: 1000
        },
        {
                name: 'step', //DONE
                url: '/assets/sounds/step.wav',
                cooldown: 100
        },
        {
                name: 'tailor',
                url: '/assets/sounds/tailor.wav',
                cooldown: 1000
        },
        {
                name: 'tanner',
                url: '/assets/sounds/tanner.wav',
                cooldown: 1000
        },
    ];

    biomeMap = {
        'OCEAN': '/assets/music/Dryspell_1.mp3',
        'SUBPOLAR_MOIST_TUNDRA': '/assets/music/Octagonal_2.mp3',
        'SUBPOLAR_WET_TUNDRA': '/assets/music/Octagonal_2.mp3',
        'SUBPOLAR_DRY_TUNDRA': '/assets/music/Octagonal_2.mp3',
        'SUBPOLAR_RAIN_TUNDRA': '/assets/music/Octagonal_2.mp3',
        'BOREAL_DESERT': '/assets/music/Octagonal_2.mp3',
        'BOREAL_DRY_SCRUB': '/assets/music/Octagonal_2.mp3',
        'BOREAL_MOIST_FOREST': '/assets/music/Octagonal_2.mp3',
        'BOREAL_WET_FOREST': '/assets/music/Octagonal_2.mp3',
        'BOREAL_RAIN_FOREST': '/assets/music/Octagonal_2.mp3',
        'COOL_TEMPERATE_MOIST_FOREST': '/assets/music/Evergreen_1.mp3',
        'COOL_TEMPERATE_WET_FOREST': '/assets/music/Evergreen_1.mp3',
        'COOL_TEMPERATE_RAIN_FOREST': '/assets/music/Evergreen_1.mp3',
        'COOL_TEMPERATE_STEPPES': '/assets/music/Steppes.mp3',
        'COOL_TEMPERATE_DESERT_SCRUB': '/assets/music/Steppes.mp3',
        'COOL_TEMPERATE_DESERT': '/assets/music/Steppes.mp3',
        'WARM_TEMPERATE_DRY_FOREST': '/assets/music/Shimmer-Dimmer_2(forests, fur forests, mines).mp3',
        'WARM_TEMPERATE_MOIST_FOREST': '/assets/music/Shimmer-Dimmer_2(forests, fur forests, mines).mp3',
        'WARM_TEMPERATE_WET_FOREST': '/assets/music/Shimmer-Dimmer_2(forests, fur forests, mines).mp3',
        'WARM_TEMPERATE_RAIN_FOREST': '/assets/music/Shimmer-Dimmer_2(forests, fur forests, mines).mp3',
        'WARM_TEMPERATE_THORN_SCRUB': '/assets/music/Mayaya_2(sandy, mountains).mp3',
        'WARM_TEMPERATE_DESERT_SCRUB': '/assets/music/Mayaya_2(sandy, mountains).mp3',
        'WARM_TEMPERATE_DESERT': '/assets/music/Mayaya_2(sandy, mountains).mp3',
        'SUBTROPICAL_DRY_FOREST': '/assets/music/Dryspell_1.mp3',
        'SUBTROPICAL_MOIST_FOREST': '/assets/music/Dryspell_1.mp3',
        'SUBTROPICAL_WET_FOREST': '/assets/music/Dryspell_1.mp3',
        'SUBTROPICAL_RAIN_FOREST': '/assets/music/Dryspell_1.mp3',
        'SUBTROPICAL_THORN_WOODLAND': '/assets/music/Sav Anna_1.mp3',
        'SUBTROPICAL_DESERT_SCRUB': '/assets/music/Sav Anna_1.mp3',
        'SUBTROPICAL_DESERT': '/assets/music/Mayaya_2(sandy, mountains).mp3',
        'TROPICAL_DRY_FOREST': '/assets/music/Mayaya_2(sandy, mountains).mp3',
        'TROPICAL_VERY_DRY_FOREST': '/assets/music/Mayaya_2(sandy, mountains).mp3',
        'TROPICAL_THORN_WOODLAND': '/assets/music/Sav Anna_1.mp3',
        'TROPICAL_DESERT_SCRUB': '/assets/music/Sav Anna_1.mp3',
        'TROPCIAL_DESERT': '/assets/music/Mayaya_2(sandy, mountains).mp3',
        'TROPCIAL_MOIST_FOREST': '/assets/music/Dryspell_1.mp3',
        'TROPICAL_WET_FOREST': '/assets/music/Dryspell_1.mp3',
        'TROPICAL_RAIN_FOREST': '/assets/music/Dryspell_1.mp3',
        'POLAR_ICE': '/assets/music/Octagonal_2.mp3',
        'POLAR_DESERT': '/assets/music/Octagonal_2.mp3',
        'MOUNTAIN': '/assets/music/Mayaya_2(sandy, mountains).mp3',
        'EMPTY': '/assets/music/Mayaya_2(sandy, mountains).mp3',
        'MINE': '/assets/music/Shimmer-Dimmer_2(forests, fur forests, mines).mp3',
    }

    currentBiome = 'EMPTY';
    currentZIndex = 0;
    isInitialised = false;
    audioElement;

    cooldownSounds = []

    constructor() {
        // window.addEventListener('keydown', () => {
        //     if (this.currentBiome === 'EMPTY') {
        //         return;
        //     }

        //     if (!this.isInitialised) {
        //         const audioElement = document.createElement('audio');

        //         this.audioFiles.forEach(function(file) {
        //             var sourceElement = document.createElement('source');
        //             sourceElement.src = file;
        //             sourceElement.type = 'audio/mpeg';
        //             audioElement.appendChild(sourceElement);
        //         });

        //         this.audioElement = audioElement;

        //         this.audioElement.style.display = 'none';

        //         document.body.appendChild(this.audioElement);

        //         const nextSong = this.getSongFromBiome()

        //         this.audioElement.src = nextSong
        //         this.audioElement.play();

        //         this.isInitialised = true;

        //         audioElement.addEventListener('ended', () => {
        //             this.onSongEnded()
        //         });
        //     }
        // })
    }

    async loadAudio(phaser) {
        this.phaser = phaser;
        await Promise.all(this.audioFiles.map(async ({name, url}) => {
            await phaser.load.audio(name, url);
        }))
    }

    playBackgroundMusic() {
        const backgroundMusic = this.phaser.sound.add('tundra', {
            loop: true,
            volume: 0.5
        });
        backgroundMusic.play();
    }

    playSound(soundName) {
        if (this.cooldownSounds.indexOf(soundName) === -1) {
            this.cooldownSounds.push(soundName);

            const soundDetails = this.audioFiles.find(file => (file.name === soundName))

            const detune = Phaser.Math.Between(-100, 100);
            const rate = Phaser.Math.FloatBetween(0.9, 1.1);
            soundDetails.volume = soundDetails.volume || 0.5;
            const volume = Phaser.Math.FloatBetween(soundDetails.volume - 0.1, soundDetails.volume + 0.1);

            const sound = this.phaser.sound.add(soundName, {
                detune,
                rate,
                volume,
            });
            sound.play();

            setTimeout(() => {
                this.cooldownSounds = this.cooldownSounds.filter(name => name !== soundName)
            }, soundDetails.cooldown || 0)
        }
    }

    setBiome(biome) {
        this.currentBiome = biome;
    }

    setZIndex(zIndex) {
        this.currentZIndex = zIndex;
    }

    onSongEnded() {
        const nextSong = this.getSongFromBiome()

        this.audioElement.src = nextSong
        this.audioElement.play();
    }

    getSongFromBiome() {
        if (this.currentZIndex < 0) {
            return this.biomeMap['MINE']
        }

        if (!this.currentBiome) {
            return this.biomeMap['EMPTY']
        }

        return this.biomeMap[this.currentBiome]
    }
}

export default AudioService;