export const krakenFaceSentences = [
"its face is full of large, sharp teeth.",
"Its eyes are Glowing red and full of hate.",
"It has a large, slithering tongue.",
"Its skin is slimy and green.",
"A massive, tentacled monstrosity, its maw dripping with slime and pus.",
"An abomination of the deep, its eyes glowing with malevolent glee.",
"A hulking beast of the sea, its massive body writhing with tentacles.",
"it has a long, slimy tongue.",
"its eyes are black and beady.",
"its eyebrows are thick and bushy.",
]

export const krakenBodySentences = [
"it body is massive and muscular, easily dwarfing a human.",
"It is covered in thick, dark scales that glimmer green in the light.",
"Its enormous head is crowned with a fin, and its mouth is full of sharp teeth.",
"writhing tentacles",
"glossy black skin",
"gnashing teeth",
"beady eyes",
"ink-stained waters",
"its body is immense, stretching wider than a large ship.",
"Its body is covered in thick, dark scales that glimmer in the light.",
"Its long, powerful tail is lined with spikes, able to crush anything in its path.",
"A ginormous octopus-like creature with immense tentacles.",
"A sea monster the size of a building, with a mouth large enough to swallow a ship whole.",
"A watery beast with black, slimy skin and glowing red eyes.",
"Tusks protrude from its gnarled mouth.",
"Its beady eyes seem to pierce your soul.",
"Muscles ripple under its leathery skin.",
"Giant tentacles writhe menacingly.",
"It smells of the deep sea and death.",
]

export const krakenSoundWords = [
    "Blaring",
    "bellowing",
    "booming",
    "deafening",
    "resounding",
    "echoing",
    "reverberating",
    "thunderous",
    "crashing",
    "smashing",
    "Roaring",
    "Snarling",
    "Growling",
    "Rumbling",
    "Grunting",
    "Groaning",
    "moaning",
    "Whimpering",
    "Howling",
    "anting",
    "whispering",
    "hissing",
    "crackling",
    "grumbling",
    "muttering",
    "rumbling",
    "bellowing",
    "roaring",
    "thundering",
    "stomping",
    "Roaring",
    "Growling",
    "Snapping",
    "Hissing",
    "Bubbling",
    "Seething",
    "Rumbling",
    "Booming",
    "Clattering",
    "Collapsing",
    "Cackling",
    "Rasping",
    "Growling",
    "Snarling",
    "Wheezing",
    "Hissing",
    "Spitting",
    "Grunting",
    "moaning",
    "Groaning"
]

export const krakenFurWords = [
    "smelly",
    "uneven",
    "slippery",
    "scaly",
    "wet",
    "slimy",
    "thick",
    "vivid",
    "swirling",
    "slimy",
    "knobbly",
    "scaly",
    "bumpy",
    "slippery",
    "shiny",
    "rubbery",
    "gooey"
]

export const krakenSmellWords = [
    "Slimy",
    "Fishy",
    "Salty",
    "Rotten",
    "Sharp",
    "Wet",
    "Moldy",
    "Cloudy",
    "Ominous",
    "Dank",
    "Icky",
    "Pungent",
    "Rancid",
    "Stinky",
    "Reeking",
    "Smelly",
    "Stale",
    "Foul",
    "Nauseating",
    "Ammonia",
    "Brine",
    "Dead fish",
    "Algae",
    "Damp",
    "Musty",
    "Muddy",
    "rank",
    "putrid",
    "fetid",
    "stinking",
    "reeking",
    "malodorous",
    "decayed",
    "raunchy",
    "brine",
    "sea",
    "salt"
]

export const krakenBehaviourSentences = [
"The kraken's tentacles are hanging limply in the water.",
"The kraken is floating on its back in the water.",
"the kraken is moving erratically",
]