import { GET_TILE_TYPES_LOADING, GET_TILE_TYPES_SUCCESS, GET_TILE_TYPES_FAILURE } from '../actionTypes';
import { clearAllTiles, loadTilesSuccess } from './tile.actions';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';

export const getTileTypesSuccess = payload => {
    return ({
        type: GET_TILE_TYPES_SUCCESS,
        payload
    })
}

export const getTileTypesFailure = payload => ({
    type: GET_TILE_TYPES_FAILURE
})

export const getTileTypesAsync = payload => dispatch => {
    return client.service('tile-types').find()
		.then((response) => {
            dispatch(getTileTypesSuccess(response));
            return response
		})
        .catch((error) => {
            console.error('error', error);
        })
           
}