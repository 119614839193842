import React from "react";
import { connect } from 'react-redux'
import {v4 as uuidv4} from 'uuid';

import { gatherMineralAsync } from '../../../redux/actions/mineral.actions';
import {
    selectHighlight,
    selectCharacter,
    selectMineralAtPosition,
} from '../../../redux/selectors';
import {UNSKILLED_COST_MULTIPLIER, hasSkill} from '../../../utils/has-skill';

class MineralActions extends React.Component {
    actions = [
        {
            name: 'gather mineral',
            callback: () => {
                if (this.state.isWorking) {
                    return;
                }

                this.setState({ isWorking: true });

                this.props.gatherMineralAsync({
                        ...this.props.mineralInstance,
                        idempotentRequestKey: this.state.idempotentRequestKey
                    })
                    .finally(() => (this.setState({ isWorking: false, idempotentRequestKey: uuidv4() })));
            },
            getCost: () => {
                return hasSkill(this.props?.character, this.props?.mineralInstance?.mineralType?.skillName) ? this.props.mineralInstance?.mineralType?.gatheringCost : this.props.mineralInstance?.mineralType?.gatheringCost * 10;
            }
        }
    ];

    constructor() {
        super();

        this.state = { isWorking: false, idempotentRequestKey: uuidv4() };
    }

    render() {
        const actions = this.actions.map((action, index) => {
            return (
                <li className={this.state.isWorking ? "working action" : "action"}
                    key={index}
                    onClick={() => (action.callback())}>
                    {action.name} [<span className={hasSkill(this.props?.character, this.props?.mineralInstance?.mineralType?.skillName) ? "" : "unskilled-penalty"}>{action.getCost()}</span>]
                </li>
            )
        })

        return this.props.mineralInstance && !this.props.highlight ? (
            <div>
                <p>{this.props.mineralInstance?.mineralType?.name}</p>
                <div>
                    <div>
                        <ul>{actions}</ul>
                    </div>
                </div>
            </div>
        ) : ('')
    }
}

const mapToStateProps = state => {
    const highlight = selectHighlight(state);
    const character = selectCharacter(state);
    const mineralInstance = selectMineralAtPosition(state, character.position)

    return { mineralInstance, character }
}

export default connect(
    mapToStateProps,
    {
        gatherMineralAsync,
    }
)(MineralActions);