import seedrandom from 'seedrandom';

import {
    selectTileInventory,
    selectTileMinerals,
    selectTileMetals,
    selectTileWritingSurfaces,
    selectTileWritingImplements,
    selectTileWeapons,
    selectTileArmour,
    selectTileTools,
    selectTileClothing,
    selectTileFood,
    selectTileKeys,
    selectTileJewellery
} from '../selectors';

import tileMap from '../../services/tile-map';

const helmetNames = [
    'helm',
    'helmet',
    'coronet',
    'coif',
]

const gauntletNames = [
    'vembrace'
]

const chestplateNames = [
    'byrnie',
    // 'plate',
    'mail',
    'armour',
    'cuirass'
]

const legArmourNames = [
    'greaves',
    'skirt',
    'gaiters',
    'shynbalds',
    'shin'
]

const shoeNames = [
    'boots'
]

export const selectItemTileIconsForTile = (store, tile) => {
    if (!tile) {
        console.error('no tile here?!')
        return;
    }

    if (tile.tileType?.name !== 'ITEM') {
        return;
    }

    tile.tileIcons = []

    const queryTiles = {
        tileInventory: [ ...selectTileInventory(store, tile._id) ],
        tileMinerals: [ ...selectTileMinerals(store, tile._id) ],
        tileMetals: [ ...selectTileMetals(store, tile._id) ],
        tileWritingSurfaces: [ ...selectTileWritingSurfaces(store, tile._id) ],
        tileWritingImplements: [ ...selectTileWritingImplements(store, tile._id) ],
        tileWeapons: [ ...selectTileWeapons(store, tile._id) ],
        tileArmour: [ ...selectTileArmour(store, tile._id) ],
        tileTools: [ ...selectTileTools(store, tile._id) ],
        tileClothing: [ ...selectTileClothing(store, tile._id) ],
        tileMaterials: [ ...selectTileInventory(store, tile._id) ],
        tileFoods: [ ...selectTileFood(store, tile._id) ],
        tileKeys: [ ...selectTileKeys(store, tile._id) ],
        tileJewellery: [ ...selectTileJewellery(store, tile._id) ]
    }

    Object.values(queryTiles).forEach(tileType => {
        tileType.forEach(tileItem => {
            const types = [
                'toolType',
                'metalType',
                'writingImplementType',
                'writingSurfaceType',
                'tileType',
                'mineralType',
                'metalType',
                'weaponType',
                'armourType',
                'clothingType',
                'materialType',
                'foodType',
                'keyType',
                'jewelleryType'
            ]

            types.forEach(type => {
                if (tileItem[type]) {
                    if (tileMap[`${tileItem[type].name}.${tileItem.groupBiome}`]) {
                        tile.tileIcons.push(`${tileItem[type].name}.${tileItem.groupBiome}`)
                    } else if (tileMap[`${tileItem[type].name}`]) {
                        tile.tileIcons.push(`${tileItem[type].name}`)
                    }
                }

                if (type === 'weaponType' && tileItem.weaponType) {
                    if (tileItem.weaponType?.name?.toLowerCase().indexOf("dagger") > -1) {
                        tile.tileIcons.push(`Dagger.${tileItem.groupBiome}`)
                    }
                    if (tileItem.weaponType?.name?.toLowerCase().indexOf("sword") > -1) {
                        tile.tileIcons.push(`Sword.${tileItem.groupBiome}`)
                    }
                    if (tileItem.weaponType?.name?.toLowerCase().indexOf("axe") > -1) {
                        tile.tileIcons.push(`Axe.${tileItem.groupBiome}`)
                    }
                    if (tileItem.weaponType?.name?.toLowerCase().indexOf("spear") > -1) {
                        tile.tileIcons.push(`Spear.${tileItem.groupBiome}`)
                    }
                }

                if (type === 'materialType' && tileItem.materialType) {
                    if (tileItem.materialType.name.toLowerCase().indexOf('paint') > -1) {
                        tile.tileIcons.push(`Paintbucket`)
                    }

                    if (tileItem.materialType.name.toLowerCase().indexOf('meat') > -1) {
                        const random = seedrandom(tileItem._id)()

                        if (random < 0.33) {
                            tile.tileIcons.push(`Meat.1`)
                        } else if (random < 0.66) {
                            tile.tileIcons.push(`Meat.2`)
                        } else {
                            tile.tileIcons.push(`Meat.3`)
                        }
                    }

                    if (tileItem.materialType.name.toLowerCase().indexOf('wood') > -1) {
                        const random = seedrandom(tileItem._id)()

                        if (random < 0.33) {
                            tile.tileIcons.push(`Wood.1`)
                        } else if (random < 0.66) {
                            tile.tileIcons.push(`Wood.2`)
                        } else {
                            tile.tileIcons.push(`Wood.3`)
                        }
                    }
                }

                if (type === 'foodType' && tileItem.foodType) {
                    if (tileItem.foodType.name.toLowerCase().indexOf('bread') > -1) {
                        tile.tileIcons.push(`Bread`)
                    } else if (tileItem.foodType.name.toLowerCase().indexOf('maize') > -1) {
                        tile.tileIcons.push(`Maize`)
                    } else if (tileItem.foodType.name.toLowerCase().indexOf('berry') > -1) {
                        tile.tileIcons.push(`Berries`)
                    } else if (tileItem.foodType.name.toLowerCase().indexOf('banana') > -1) {
                        tile.tileIcons.push(`Banana`)
                    } else if (tileItem.foodType.name.toLowerCase().indexOf('mango') > -1) {
                        tile.tileIcons.push(`Mango`)
                    } else if (tileItem.foodType.name.toLowerCase().indexOf('bread') > -1) {
                        tile.tileIcons.push(`Bread`)
                    } else if (tileItem.foodType.name.toLowerCase().indexOf('kohlrabi') > -1) {
                        tile.tileIcons.push(`KOHLRABI.HARVEST`)
                    } else {
                        const random = seedrandom(tileItem._id)()

                        if (random < 0.33) {
                            tile.tileIcons.push(`Cooked Meat.1`)
                        } else if (random < 0.66) {
                            tile.tileIcons.push(`Cooked Meat.2`)
                        } else {
                            tile.tileIcons.push(`Cooked Meat.3`)
                        }
                    }
                }

                if (type === 'clothingType' && tileItem.clothingType) {
                    legArmourNames.forEach(legArmourName => {
                        if (tileItem.clothingType?.name?.toLowerCase().indexOf(legArmourName) > -1) {
                            tile.tileIcons.push(`Pauldrons`)
                        }
                    })

                    shoeNames.forEach(shoeName => {
                        if (tileItem.clothingType?.name?.toLowerCase().indexOf(shoeName) > -1) {
                            tile.tileIcons.push(`Boots`)
                        }
                    })

                    helmetNames.forEach(helmetName => {
                        if (tileItem.clothingType?.name?.toLowerCase().indexOf(helmetName) > -1) {
                            tile.tileIcons.push(`Helmet.${tileItem.groupBiome}`)
                        }
                    })

                    chestplateNames.forEach(chestplateName => {
                        if (tileItem.clothingType?.name?.toLowerCase().indexOf(chestplateName) > -1) {
                            tile.tileIcons.push(`Chestplate.${tileItem.groupBiome}`)
                        }
                    })

                    gauntletNames.forEach(gauntletName => {
                        if (tileItem.clothingType?.name?.toLowerCase().indexOf(gauntletName) > -1) {
                            tile.tileIcons.push(`Gauntlet`)
                        }
                    })
                }
            })
        })
    })

    return tile;
}

export const selectItemTileIcons = (store) => {
	if (store && store.tiles) {
		const newById = {}

		Object.values(store.tiles.byId).forEach(tile => {
			newById[tile._id] = {
				position: tile.position,
				_id: tile._id,
				tileType: tile.tileType,
				tileIcons: [],
                characterId: tile.characterId,
                groupBiome: tile.groupBiome
			}

			if (tile.tileType?.name !== 'ITEM') {
		        return;
		    }

		    const queryTiles = {
				tileInventory: [ ...selectTileInventory(store, tile._id) ],
			    tileMinerals: [ ...selectTileMinerals(store, tile._id) ],
			    tileMetals: [ ...selectTileMetals(store, tile._id) ],
			    tileWritingSurfaces: [ ...selectTileWritingSurfaces(store, tile._id) ],
                tileWritingImplements: [ ...selectTileWritingImplements(store, tile._id) ],
			    tileWeapons: [ ...selectTileWeapons(store, tile._id) ],
			    tileArmour: [ ...selectTileArmour(store, tile._id) ],
                tileTools: [ ...selectTileTools(store, tile._id) ],
                tileClothing: [ ...selectTileClothing(store, tile._id) ],
                tileMaterials: [ ...selectTileInventory(store, tile._id) ],
                tileFoods: [ ...selectTileFood(store, tile._id) ],
                tileKeys: [ ...selectTileKeys(store, tile._id) ],
                tileJewellery: [ ...selectTileJewellery(store, tile._id) ]
		    }

            Object.values(queryTiles).forEach(tileType => {
                tileType.forEach(tileItem => {
                    const types = [
                        'toolType',
                        'metalType',
                        'writingImplementType',
                        'writingSurfaceType',
                        'tileType',
                        'mineralType',
                        'metalType',
                        'weaponType',
                        'armourType',
                        'clothingType',
                        'materialType',
                        'foodType',
                        'keyType',
                        'jewelleryType'
                    ]

                    types.forEach(type => {
                        if (tileItem[type]) {
                            if (tileMap[`${tileItem[type].name}.${tileItem.groupBiome}`]) {
                                newById[tile._id].tileIcons.push(`${tileItem[type].name}.${tileItem.groupBiome}`)
                            } else if (tileMap[`${tileItem[type].name}`]) {
                                newById[tile._id].tileIcons.push(`${tileItem[type].name}`)
                            }
                        }

                        if (type === 'weaponType' && tileItem.weaponType) {
                            if (tileItem.weaponType?.name?.toLowerCase().indexOf("dagger") > -1) {
                                newById[tile._id].tileIcons.push(`Dagger.${tileItem.groupBiome}`)
                            }
                            if (tileItem.weaponType?.name?.toLowerCase().indexOf("sword") > -1) {
                                newById[tile._id].tileIcons.push(`Sword.${tileItem.groupBiome}`)
                            }
                            if (tileItem.weaponType?.name?.toLowerCase().indexOf("axe") > -1) {
                                newById[tile._id].tileIcons.push(`Axe.${tileItem.groupBiome}`)
                            }
                            if (tileItem.weaponType?.name?.toLowerCase().indexOf("spear") > -1) {
                                newById[tile._id].tileIcons.push(`Spear.${tileItem.groupBiome}`)
                            }
                        }

                        if (type === 'materialType' && tileItem.materialType) {
                            if (tileItem.materialType.name.toLowerCase().indexOf('paint') > -1) {
                                newById[tile._id].tileIcons.push(`Paintbucket`)
                            }

                            if (tileItem.materialType.name.toLowerCase().indexOf('meat') > -1) {
                                const random = seedrandom(tileItem._id)()

                                if (random < 0.33) {
                                    newById[tile._id].tileIcons.push(`Meat.1`)
                                } else if (random < 0.66) {
                                    newById[tile._id].tileIcons.push(`Meat.2`)
                                } else {
                                    newById[tile._id].tileIcons.push(`Meat.3`)
                                }
                            }

                            if (tileItem.materialType.name.toLowerCase().indexOf('wood') > -1) {
                                const random = seedrandom(tileItem._id)()

                                if (random < 0.33) {
                                    newById[tile._id].tileIcons.push(`Wood.1`)
                                } else if (random < 0.66) {
                                    newById[tile._id].tileIcons.push(`Wood.2`)
                                } else {
                                    newById[tile._id].tileIcons.push(`Wood.3`)
                                }
                            }
                        }

                        if (type === 'foodType' && tileItem.foodType) {
                            if (tileItem.foodType.name.toLowerCase().indexOf('bread') > -1) {
                                newById[tile._id].tileIcons.push(`Bread`)
                            } else if (tileItem.foodType.name.toLowerCase().indexOf('maize') > -1) {
                                newById[tile._id].tileIcons.push(`Maize`)
                            } else if (tileItem.foodType.name.toLowerCase().indexOf('berry') > -1) {
                                newById[tile._id].tileIcons.push(`Berries`)
                            } else if (tileItem.foodType.name.toLowerCase().indexOf('banana') > -1) {
                                newById[tile._id].tileIcons.push(`Banana`)
                            } else if (tileItem.foodType.name.toLowerCase().indexOf('mango') > -1) {
                                newById[tile._id].tileIcons.push(`Mango`)
                            } else if (tileItem.foodType.name.toLowerCase().indexOf('bread') > -1) {
                                newById[tile._id].tileIcons.push(`Bread`)
                            } else if (tileItem.foodType.name.toLowerCase().indexOf('kohlrabi') > -1) {
                                newById[tile._id].tileIcons.push(`KOHLRABI.HARVEST`)
                            } else {
                                const random = seedrandom(tileItem._id)()

                                if (random < 0.33) {
                                    newById[tile._id].tileIcons.push(`Cooked Meat.1`)
                                } else if (random < 0.66) {
                                    newById[tile._id].tileIcons.push(`Cooked Meat.2`)
                                } else {
                                    newById[tile._id].tileIcons.push(`Cooked Meat.3`)
                                }
                            }
                        }

                        if (type === 'clothingType' && tileItem.clothingType) {
                            legArmourNames.forEach(legArmourName => {
                                if (tileItem.clothingType?.name?.toLowerCase().indexOf(legArmourName) > -1) {
                                    newById[tile._id].tileIcons.push(`Pauldrons`)
                                }
                            })

                            shoeNames.forEach(shoeName => {
                                if (tileItem.clothingType?.name?.toLowerCase().indexOf(shoeName) > -1) {
                                    newById[tile._id].tileIcons.push(`Boots`)
                                }
                            })

                            helmetNames.forEach(helmetName => {
                                if (tileItem.clothingType?.name?.toLowerCase().indexOf(helmetName) > -1) {
                                    newById[tile._id].tileIcons.push(`Helmet.${tileItem.groupBiome}`)
                                }
                            })

                            chestplateNames.forEach(chestplateName => {
                                if (tileItem.clothingType?.name?.toLowerCase().indexOf(chestplateName) > -1) {
                                    newById[tile._id].tileIcons.push(`Chestplate.${tileItem.groupBiome}`)
                                }
                            })

                            gauntletNames.forEach(gauntletName => {
                                if (tileItem.clothingType?.name?.toLowerCase().indexOf(gauntletName) > -1) {
                                    newById[tile._id].tileIcons.push(`Gauntlet`)
                                }
                            })
                        }
                    })
                })
            })
		})

		return { ...store.tiles, byId: newById }
	} else {
		return {}
	}
}

