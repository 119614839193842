import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_FURNITURE_SUCCESS, ADD_FURNITURE, UPDATE_FURNITURE, REMOVE_FURNITURE, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  characterFurniture: [],
  tileFurniture: [],
  furniture: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_FURNITURE_SUCCESS: {
      const furniture = action.payload;

      return {
        ...state,
        furniture: [...furniture.filter(furnitureInstance => furnitureInstance.panelId)],
        characterFurniture: [...state.characterFurniture, ...furniture.filter(furnitureInstance => furnitureInstance.characterId)],
        tileFurniture: [...furniture.filter(furnitureInstance => furnitureInstance.tileId)],
      }
    }

    case ADD_FURNITURE: {
      let characterFurniture = [...state.characterFurniture];
      let tileFurniture = [...state.tileFurniture];
      let furniture = [...state.furniture];

      if (action.payload.characterId) {
        characterFurniture = [ ...characterFurniture, action.payload ]
      }

      if (action.payload.tileId) {
        tileFurniture = [ ...tileFurniture, action.payload ]
      }

      if (action.payload.panelId) {
        furniture = [ ...furniture, action.payload ]
      }

      return {
        ...state,
        characterFurniture: [ ...characterFurniture ],
        tileFurniture: [ ...tileFurniture ],
        furniture: [ ...furniture ],
      }
    }

    case UPDATE_FURNITURE: {
      let characterFurniture = [...state.characterFurniture];
      let tileFurniture = [...state.tileFurniture];
      let furniture = [...state.furniture];

      if (action.payload.characterId) {
        characterFurniture = [
          ...characterFurniture.filter(tool => tool._id !== action.payload._id),
          { ...characterFurniture.find(tool => tool._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.tileId) {
        tileFurniture = [
          ...tileFurniture.filter(tool => tool._id !== action.payload._id),
          { ...tileFurniture.find(tool => tool._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.panelId) {
        furniture = [
          ...furniture.filter(tool => tool._id !== action.payload._id),
          { ...furniture.find(tool => tool._id === action.payload._id), ...action.payload }
        ]
      }
      
      return {
        ...state,
        characterFurniture: [ ...characterFurniture ],
        tileFurniture: [ ...tileFurniture ],
        furniture: [ ...furniture ],
      }
    }

    case REMOVE_FURNITURE: {
      return {
        ...state,
        characterFurniture: [ ...state.characterFurniture.filter(tool => (tool._id !== action.payload._id)) ],
        tileFurniture: [ ...state.tileFurniture.filter(tool => (tool._id !== action.payload._id)) ],
        furniture: [ ...state.furniture.filter(tool => (tool._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
