import React, { useState } from "react";
import isHotkey from 'is-hotkey'
import { connect } from 'react-redux'
import { client } from '../../services/client';

// import './EditDrawingSurface.css';

const EXIT = 'shift+enter';

const HOTKEYS = {
}

class ImageSurface extends React.Component {

  constructor() {
    super();
    this.state = {
    }
  }

  componentWillUnmount() {
  }

  componentDidMount() {
    let pixelsArray = [];

    if (!this.props.pixelsArray) {
      for (let y=0; y < this.props.height; y++) {
        for (let x=0; x < this.props.width; x++) {
          pixelsArray.push({
            x, y
          })
        }
      }
    } else {
      pixelsArray = [ ...this.props.pixelsArray ]
    }

    this.setState({
      pixelsArray
    })
  }

  static getDerivedStateFromProps(props, state) {
    let pixelsArray = [];

    if (!props.pixelsArray) {
      for (let y=0; y < props.height; y++) {
        for (let x=0; x < props.width; x++) {
          pixelsArray.push({
            x, y
          })
        }
      }
    } else {
      pixelsArray = [ ...props.pixelsArray ]
    }

    state.pixelsArray = pixelsArray

    return state;
  }

  render() {
    const pixelsArray = this.state.pixelsArray;

    const pixels = pixelsArray?.map((tile, index) => {
      return (
        <div
          className={this.props.selectedX === tile.x && this.props.selectedY === tile.y ? "current tile": `tile`}
          key={index}
          style={{'backgroundColor': tile.backgroundColor}}
        ></div>
      )
    })

    return (
      <div className="canvas-container">
        <div className={this.props.isSaving ? "saving canvas" : "canvas"}
          id="paint-canvas"
          style={{
            '--width': this.props.width,
            '--height': this.props.height,
            'width': this.props.width + (this.props.sizeUnit || 'rem'),
            'height': this.props.height + (this.props.sizeUnit || 'rem'),
            'border': 'none'
          }}
        >
          {pixels}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
    return {  }
}

export default connect(
    mapStateToProps,
    {
      
    }
)(ImageSurface);