import tileMap from './tile-map'

export const BLOCKING_PLANT_KEYS = [
    'CRUNCHY.TRUNK.DRY',
    'CRUNCHY.TRUNK.MONSOON',
    'CRYSTAL.TRUNK.DRY.RIGHT.',
    'CRYSTAL.TRUNK.DRY.LEFT.',
    'CRYSTAL.TRUNK.MONSOON.RIGHT.',
    'CRYSTAL.TRUNK.MONSOON.LEFT.',
    'DEEPEST-JUNGLE.ONE.TRUNK.A1',
    'DEEPEST-JUNGLE.ONE.TRUNK.A2',
    'DEEPEST-JUNGLE.ONE.TRUNK.B1',
    'DEEPEST-JUNGLE.ONE.TRUNK.B2',
    'DEEPEST-JUNGLE.TWO.TRUNK.A1',
    'DEEPEST-JUNGLE.TWO.TRUNK.A2',
    'DEEPEST-JUNGLE.TWO.TRUNK.B1',
    'DEEPEST-JUNGLE.TWO.TRUNK.B2',
    'DEEPEST-JUNGLE.THREE.TRUNK.A1',
    'DEEPEST-JUNGLE.THREE.TRUNK.A2',
    'DEEPEST-JUNGLE.THREE.TRUNK.B1',
    'DEEPEST-JUNGLE.THREE.TRUNK.B2',
    'MULBERRY.TRUNK',
    'DISCORDANT-YELLOW.LEFT.RIGHT.TOP.BOTTOM.BIG',
    'DISCORDANT-PINK.LEFT.RIGHT.TOP.BOTTOM.BIG',
    'EVERGREEN.LEFT.RIGHT.TOP.BOTTOM.',
    'FOSSILISED.TRUNK.LARGE.RIGHT.BOTTOM.',
    'FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.BOTTOM.',
    'FOSSILISED.TRUNK.LARGE.LEFT.BOTTOM.',
    'FOSSILISED.TRUNK.LARGE.RIGHT.TOP.BOTTOM.',
    'FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.TOP.BOTTOM.',
    'FOSSILISED.TRUNK.LARGE.LEFT.TOP.BOTTOM.',
    'FOSSILISED.TRUNK.SMALL',
    'FOSSILISED.TRUNK.LARGE.RIGHT.TOP.',
    'FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.TOP.',
    'FOSSILISED.TRUNK.LARGE.LEFT.TOP.',
    'FUR.LEFT.RIGHT.TOP.BOTTOM.',
    'INGLENOOK.TRUNK',
    'LUSCIOUS.ALOE.LEFT.RIGHT.TOP.BOTTOM.',
    'LUSCIOUS.PALM.LEFT.RIGHT.TOP.BOTTOM.',
    'MAZE-SCRUB.TRUNK.',
    'MAZE-SCRUB.TRUNK.HARVEST.',
    'MOUNTAIN.BOTTOM.',
    'MOUNTAIN.RIGHT.BOTTOM.',
    'MOUNTAIN.LEFT.RIGHT.BOTTOM.',
    'MOUNTAIN.LEFT.BOTTOM.',
    'MOUNTAIN.TOP.BOTTOM.',
    'MOUNTAIN.RIGHT.TOP.BOTTOM.',
    'MOUNTAIN.LEFT.RIGHT.TOP.BOTTOM.',
    'MOUNTAIN.LEFT.TOP.BOTTOM.',
    'MOUNTAIN.TOP.',
    'MOUNTAIN.RIGHT.TOP.',
    'MOUNTAIN.LEFT.RIGHT.TOP.',
    'MOUNTAIN.LEFT.TOP.',
    'MOUNTAIN.RIGHT.',
    'MOUNTAIN.LEFT.RIGHT.',
    'MOUNTAIN.LEFT.',
    'NEEDLEWOOD.THORN.DRY.LEFT.RIGHT.TOP.BOTTOM.',
    'NEEDLEWOOD.THORN.MONSOON.LEFT.RIGHT.TOP.BOTTOM.',
    'OLD-GROWTH.ONE.A1',
    'OLD-GROWTH.ONE.A2',
    'OLD-GROWTH.ONE.A3',
    'OLD-GROWTH.TWO.A1',
    'OLD-GROWTH.TWO.A2',
    'OLD-GROWTH.TWO.A3',
    'OLD-GROWTH.TWO.A4',
    'OLD-GROWTH.ONE.B1',
    'OLD-GROWTH.ONE.B2',
    'OLD-GROWTH.ONE.B3',
    'OLD-GROWTH.TWO.B1',
    'OLD-GROWTH.TWO.B2',
    'OLD-GROWTH.TWO.B3',
    'OLD-GROWTH.THREE.A1',
    'OLD-GROWTH.THREE.A2',
    'OLD-GROWTH.THREE.A3',
    'OLD-GROWTH.FOUR.A1',
    'OLD-GROWTH.FOUR.A2',
    'OLD-GROWTH.FOUR.A3',
    'OLD-GROWTH.FIVE.A1',
    'OLD-GROWTH.FIVE.A2',
    'OLD-GROWTH.ONE.C1',
    'OLD-GROWTH.ONE.C2',
    'OLD-GROWTH.TWO.C1',
    'OLD-GROWTH.TWO.C2',
    'OLD-GROWTH.TWO.C3',
    'OLD-GROWTH.THREE.B1',
    'OLD-GROWTH.THREE.B2',
    'OLD-GROWTH.THREE.B3',
    'OLD-GROWTH.THREE.B4',
    'OLD-GROWTH.THREE.B5',
    'OLD-GROWTH.FOUR.B1',
    'OLD-GROWTH.FOUR.B2',
    'OLD-GROWTH.FIVE.B1',
    'OLD-GROWTH.FIVE.B2',
    'OLD-GROWTH.FIVE.B3',
    'OLD-GROWTH.SIX.A1',
    'OLD-GROWTH.SIX.A2',
    'OLD-GROWTH.SEVEN.A1',
    'OLD-GROWTH.SEVEN.A2',
    'OLD-GROWTH.SEVEN.A3',
    'OLD-GROWTH.EIGHT.A1',
    'OLD-GROWTH.THREE.C1',
    'OLD-GROWTH.THREE.C2',
    'OLD-GROWTH.THREE.C3',
    'OLD-GROWTH.THREE.C4',
    'OLD-GROWTH.FIVE.C1',
    'OLD-GROWTH.FIVE.C2',
    'OLD-GROWTH.FIVE.C3',
    'OLD-GROWTH.SIX.B1',
    'OLD-GROWTH.SIX.B2',
    'OLD-GROWTH.SIX.B3',
    'OLD-GROWTH.SEVEN.B1',
    'OLD-GROWTH.SEVEN.B2',
    'OLD-GROWTH.SEVEN.B3',
    'OLD-GROWTH.EIGHT.B1',
    'OLD-GROWTH.EIGHT.B2',
    'OLD-GROWTH.THREE.D1',
    'OLD-GROWTH.THREE.D2',
    'OLD-GROWTH.THREE.D3',
    'OLD-GROWTH.THREE.D4',
    'OLD-GROWTH.THREE.D5',
    'OLD-GROWTH.NINE.A1',
    'OLD-GROWTH.NINE.A2',
    'OLD-GROWTH.NINE.A3',
    'OLD-GROWTH.NINE.A4',
    'OLD-GROWTH.SIX.C1',
    'OLD-GROWTH.SIX.C2',
    'OLD-GROWTH.SIX.C3',
    'OLD-GROWTH.SEVEN.C1',
    'OLD-GROWTH.SEVEN.C2',
    'OLD-GROWTH.TEN.A1',
    'OLD-GROWTH.TEN.A2',
    'OLD-GROWTH.EIGHT.C1',
    'OLD-GROWTH.EIGHT.A2',
    'OLD-GROWTH.THREE.E1',
    'OLD-GROWTH.THREE.E2',
    'OLD-GROWTH.THREE.E3',
    'OLD-GROWTH.NINE.B1',
    'OLD-GROWTH.NINE.B2',
    'OLD-GROWTH.NINE.B3',
    'OLD-GROWTH.ELEVEN.A1',
    'OLD-GROWTH.ELEVEN.A2',
    'OLD-GROWTH.TWELVE.A1',
    'OLD-GROWTH.TWELVE.A2',
    'OLD-GROWTH.TWELVE.A3',
    'OLD-GROWTH.TEN.B1',
    'OLD-GROWTH.TEN.B2',
    'OLD-GROWTH.TEN.B3',
    'OLD-GROWTH.EIGHT.B3',
    'OLD-GROWTH.NINE.C1',
    'OLD-GROWTH.NINE.C2',
    'OLD-GROWTH.NINE.C3',
    'OLD-GROWTH.ELEVEN.B1',
    'OLD-GROWTH.ELEVEN.B2',
    'OLD-GROWTH.TWELVE.B1',
    'OLD-GROWTH.TWELVE.B2',
    'OLD-GROWTH.TEN.C1',
    'OLD-GROWTH.TEN.C2',
    'OLD-GROWTH.EIGHT.C2',
    'ORDEAL.TRUNK.ONE.A1',
    'ORDEAL.TRUNK.ONE.A2',
    'ORDEAL.TRUNK.TWO.A1',
    'ORDEAL.TRUNK.TWO.A2',
    'ORDEAL.TRUNK.ONE.B1',
    'ORDEAL.TRUNK.ONE.B2',
    'ORDEAL.TRUNK.TWO.B1',
    'ORDEAL.TRUNK.TWO.B2',
    'OVERGROWN-FUR.ONE.A1',
    'OVERGROWN-FUR.ONE.A2',
    'OVERGROWN-FUR.ONE.B1',
    'OVERGROWN-FUR.ONE.B2',
    'OVERGROWN-FUR.TWO.A1',
    'OVERGROWN-FUR.TWO.A2',
    'OVERGROWN-FUR.TWO.A3',
    'OVERGROWN-FUR.TWO.B1',
    'OVERGROWN-FUR.TWO.B2',
    'OVERGROWN-FUR.TWO.B3',
    'OVERGROWN-FUR.THREE.A1',
    'OVERGROWN-FUR.THREE.A2',
    'OVERGROWN-FUR.TWO.C1',
    'OVERGROWN-FUR.TWO.C2',
    'OVERGROWN-FUR.THREE.B1',
    'OVERGROWN-FUR.THREE.B2',
    'OVERGROWN-FUR.THREE.B3',
    'PAINFOREST.PRONG.CENTRE-RIGHT.CENTRE.',
    'PAINFOREST.PRONG.CENTRE-BOTTOM.RIGHT.',
    'PAINFOREST.TRUNK.RIGHT',
    'PAINFOREST.PRONG.CENTRE-TOP.LEFT',
    'PAINFOREST.PRONG.CENTRE-TOP.CENTRE-LEFT',
    'PAINFOREST.PRONG.CENTRE-TOP.CENTRE-RIGHT',
    'PAINFOREST.PRONG.CENTRE-TOP.RIGHT',
    'PAINFOREST.PRONG.TOP-RIGHT.SECOND-ROW.LEFT',
    'PAINFOREST.TRUNK.BOTTOM-RIGHT',
    'PAINFOREST.PRONG.CENTRE-TOP.SECOND-ROW.LEFT',
    'PAINFOREST.PRONG.CENTRE-TOP.SECOND-ROW.RIGHT',
    'PAINFOREST.PRONG.TOP-LEFT.CENTRE-TOP',
    'PAINFOREST.PRONG.TOP-LEFT.RIGHT-TOP',
    'PAINFOREST.PRONG.CENTRE-RIGHT.BOTTOM',
    'PAINFOREST.PRONG.TOP-RIGHT.BOTTOM-ROW.LEFT',
    'PAINFOREST.TRUNK.CENTRE-TOP.LEFT',
    'PAINFOREST.TRUNK.CENTRE-TOP.RIGHT',
    'PAINFOREST.PRONG.TOP-LEFT.SECOND-ROW.LEFT',
    'PAINFOREST.PRONG.TOP-LEFT.SECOND-ROW.CENTRE',
    'PAINFOREST.PRONG.TOP-LEFT.SECOND-ROW.RIGHT',
    'PAINFOREST.PRONG.CENTRE-RIGHT.SECOND-ROW',
    'PAINFOREST.TRUNK.LEFT',
    'PAINFOREST.TRUNK.TOP-LEFT',
    'PAINFOREST.TRUNK.CENTRE.LEFT',
    'PAINFOREST.TRUNK.CENTRE.RIGHT',
    'PAINFOREST.PRONG.TOP-LEFT.BOTTOM-ROW.LEFT',
    'PAINFOREST.PRONG.TOP-LEFT.BOTTOM-ROW.CENTRE',
    'PAINFOREST.PRONG.TOP-LEFT.BOTTOM-ROW.RIGHT',
    'PAINFOREST.TRUNK.RIGHT.TOP',
    'PAINFOREST.PRONG.TOP-RIGHT.BOTTOM-ROW.RIGHT',
    'PAINFOREST.PRONG.LEFT.TOP',
    'PAINFOREST.TRUNK.LEFT.BOTTOM',
    'PAINFOREST.PRONG.BOTTOM-LEFT.LEFT-TOP',
    'PAINFOREST.PRONG.BOTTOM-LEFT.CENTRE-TOP',
    'PAINFOREST.PRONG.BOTTOM-LEFT.RIGHT-TOP',
    'PAINFOREST.PRONG.BOTTOM.SECOND-ROW.RIGHT',
    'PAINFOREST.PRONG.BOTTOM-RIGHT.LEFT-TOP',
    'PAINFOREST.PRONG.BOTTOM-RIGHT.CENTRE-TOP',
    'PAINFOREST.PRONG.BOTTOM-RIGHT.RIGHT-TOP',
    'PAINFOREST.PRONG.TOP-RIGHT.SECOND-ROW.CENTRE',
    'PAINFOREST.PRONG.RIGHT.TOP.',
    'PAINFOREST.PRONG.TOP-RIGHT.TOP.LEFT',
    'PAINFOREST.PRONG.LEFT.CENTRE',
    'PAINFOREST.PRONG.LEFT.SECOND-ROW',
    'PAINFOREST.PRONG.BOTTOM-LEFT.SECOND-ROW.LEFT',
    'PAINFOREST.PRONG.BOTTOM-LEFT.SECOND-ROW.CENTRE',
    'PAINFOREST.PRONG.BOTTOM-LEFT.SECOND-ROW.RIGHT',
    'PAINFOREST.PRONG.BOTTOM.LEFT',
    'PAINFOREST.PRONG.BOTTOM-RIGHT.SECOND-ROW.LEFT',
    'PAINFOREST.PRONG.BOTTOM-RIGHT.SECOND-ROW.CENTRE',
    'PAINFOREST.PRONG.BOTTOM-RIGHT.SECOND-ROW.RIGHT',
    'PAINFOREST.PRONG.TOP-RIGHT.BOTTOM-ROW.CENTRE',
    'PAINFOREST.TRUNK.RIGHT.BOTTOM',
    'PAINFOREST.PRONG.BOTTOM.CENTRE-RIGHT',
    'PAINFOREST.PRONG.LEFT.BOTTOM',
    'PAINFOREST.TRUNK.BOTTOM.CENTRE-LEFT',
    'PAINFOREST.TRUNK.BOTTOM.CENTRE-RIGHT',
    'PAINFOREST.PRONG.BOTTOM.SECOND-ROW.LEFT',
    'PAINFOREST.PRONG.BOTTOM-LEFT.CENTRE-BOTTOM',
    'PAINFOREST.PRONG.BOTTOM-LEFT.RIGHT-BOTTOM',
    'PAINFOREST.PRONG.BOTTOM.CENTRE-LEFT',
    'PAINFOREST.PRONG.BOTTOM-RIGHT.BOTTOM-LEFT',
    'PAINFOREST.PRONG.BOTTOM-RIGHT.BOTTOM-CENTRE',
    'PAINFOREST.PRONG.TOP-RIGHT.SECOND-ROW.RIGHT',
    'PRIMEORDEAL.TRUNK',
    'PRIMEORDEAL.TRUNK.LARGE.LEFT.',
    'PRIMEORDEAL.TRUNK.LARGE.RIGHT.',
    'PRIMEVAL.INNER.LEFT.RIGHT.TOP.BOTTOM.BR-GAP',
    'PRIMEVAL.INNER.LEFT.RIGHT.TOP.',
    'PRIMEVAL.INNER.LEFT.RIGHT.TOP.BOTTOM.BL-GAP',
    'PRIMEVAL.OUTER.RIGHT.BOTTOM.',
    'PRIMEVAL.OUTER.LEFT.RIGHT.BOTTOM.',
    'PRIMEVAL.OUTER.LEFT.BOTTOM.',
    'PRIMEVAL.OUTER.LEFT.RIGHT.TOP.BOTTOM.TL-GAP',
    'PRIMEVAL.OUTER.LEFT.RIGHT.TOP.BOTTOM.TR-GAP',
    'PRIMEVAL.INNER.LEFT.TOP.BOTTOM.',
    'PRIMEVAL.INNER.RIGHT.TOP.BOTTOM.',
    'PRIMEVAL.OUTER.RIGHT.TOP.BOTTOM.',
    'PRIMEVAL.LEFT.RIGHT.TOP.BOTTOM.',
    'PRIMEVAL.OUTER.LEFT.RIGHT.TOP.BOTTOM.',
    'PRIMEVAL.OUTER.RIGHT.',
    'PRIMEVAL.OUTER.LEFT.RIGHT.TOP.BOTTOM.BL-TL-GAP',
    'PRIMEVAL.OUTER.LEFT.RIGHT.',
    'PRIMEVAL.OUTER.LEFT.RIGHT.BOTTOM.BL-GAP',
    'PRIMEVAL.OUTER.LEFT.TOP.BOTTOM.BL-GAP',
    'PRIMEVAL.OUTER.TOP.BOTTOM.',
    'PRIMEVAL.OUTER.LEFT.RIGHT.TOP.TL-GAP',
    'PRIMEVAL.OUTER.LEFT.TOP.BOTTOM.',
    'PRIMEVAL.OUTER.LEFT.RIGHT.TOP.BOTTOM.BL-GAP',
    'PRIMEVAL.OUTER.LEFT.RIGHT.TOP.BOTTOM.BR-GAP',
    'PRIMEVAL.INNER.LEFT.RIGHT.TOP.BOTTOM.TR-GAP',
    'PRIMEVAL.INNER.LEFT.RIGHT.BOTTOM.',
    'PRIMEVAL.INNER.LEFT.RIGHT.TOP.BOTTOM.TL-GAP',
    'PRIMEVAL.OUTER.RIGHT.TOP.',
    'PRIMEVAL.OUTER.LEFT.RIGHT.TOP.',
    'PRIMEVAL.OUTER.LEFT.TOP.',
    'PRIMEVAL.INNER.LEFT.TOP.',
    'PRIMEVAL.INNER.RIGHT.TOP.',
    'PRIMEVAL.INNER.LEFT.BOTTOM.',
    'PRIMEVAL.INNER.RIGHT.BOTTOM.',
    'PUZZLEBOX.TRUNK',
    'UAPACAA.TRUNK',
    'WILLOW.TRUNK',
    'THISTLEWOOD.TRUNK.BOTTOM.',
    'THISTLEWOOD.TRUNK.LEFT.',
    'THISTLEWOOD.TRUNK.RIGHT.',
    'THISTLEWOOD.TRUNK.TOP.',
    'ANTIARIS.TRUNK',
    'WONEYE.EYE.BOTTOM.',
    'WONEYE.EYE.TOP.BOTTOM.',
    'WONEYE.TRUNK',
    'WONEYE.EYE.TOP.',
    'WONEYE.EYE.RIGHT.',
    'WONEYE.EYE.LEFT.',
    'WONEYE.EYE.RIGHT.BOTTOM.',
    'WONEYE.EYE.LEFT.BOTTOM.',
    'WONEYE.EYE.LEFT.RIGHT.',
    'WONEYE.EYE.RIGHT.TOP.',
    'WONEYE.EYE.LEFT.TOP.',
    'INGLENOOK.VINE.RIGHT.BOTTOM.',
	'INGLENOOK.VINE.LEFT.RIGHT.BOTTOM.',
	'INGLENOOK.VINE.LEFT.BOTTOM.',
	'INGLENOOK.VINE.BOTTOM.',
	'INGLENOOK.VINE.CONNECTED.RIGHT.BOTTOM.',
	'INGLENOOK.VINE.CONNECTED.LEFT.RIGHT.BOTTOM.',
	'INGLENOOK.VINE.CONNECTED.LEFT.BOTTOM.',
	'INGLENOOK.VINE.CONNECTED.BOTTOM.',
	'INGLENOOK.VINE.RIGHT.TOP.BOTTOM.',
	'INGLENOOK.VINE.LEFT.RIGHT.TOP.BOTTOM.',
	'INGLENOOK.VINE.LEFT.TOP.BOTTOM.',
	'INGLENOOK.VINE.TOP.BOTTOM.',
	'INGLENOOK.VINE.CONNECTED.RIGHT.TOP.BOTTOM.',
	'INGLENOOK.VINE.CONNECTED.LEFT.TOP.BOTTOM.',
	'INGLENOOK.VINE.CONNECTED.TOP.BOTTOM.',
	'INGLENOOK.VINE.RIGHT.TOP.',
	'INGLENOOK.VINE.LEFT.RIGHT.TOP.',
	'INGLENOOK.VINE.LEFT.TOP.',
	'INGLENOOK.VINE.TOP.',
	'INGLENOOK.VINE.CONNECTED.RIGHT.TOP.',
	'INGLENOOK.VINE.CONNECTED.LEFT.RIGHT.TOP.',
	'INGLENOOK.VINE.CONNECTED.LEFT.TOP.',
	'INGLENOOK.VINE.CONNECTED.TOP.',
	'INGLENOOK.VINE.RIGHT.',
	'INGLENOOK.VINE.LEFT.RIGHT.',
	'INGLENOOK.VINE.LEFT.',
	'INGLENOOK.VINE.CONNECTED.RIGHT.',
	'INGLENOOK.VINE.CONNECTED.LEFT.RIGHT.',
	'INGLENOOK.VINE.CONNECTED.LEFT.',
]
           