export const palmSmellTypes = [
	"Coconutty",
    "Oily",
    "Fishy",
    "Salty",
    "Dirty",
    "Sun-kissed",
    "Sand-drenched",
    "Wet",
    "Sweaty",
    "sunny",
    "salty",
    "oceanic",
    "sweet",
    "Oily",
    "Sweet",
    "Balmy",
    "Pungent",
    "Spicy",
    "wet",
    "green",
    "waxy",
    "aromatic",
    "fragrant",
    "nutty",
    "sweet",
    "bitter",
    "sour",
    "fragrant",
    "aromatic",
    "pungent",
    "of savage phloem",
    "of lime and tulsi",
    "of cool mint",
    "of immaculately paired herbs",
    "of smoked cream",
    "of spicy star anise",
    "of squeezed citrus",
    "of powdered faces",
    "of synthetic floral decadence",
    "of fried discs",
    "aggressive and industrial",
    "of slices of candied lemon",
    "of calm dunes",
    "of saffron and oud",
    "of fruit peels",
    "of rose and ginger",
    "of soda meringue",
    "of laurel leafs",
    "of whipped mud",
    "of neutralised vanilla",
    "of sweet fougere",
]

export const palmBranchTypes = [
    "light green",
    "pointed",
    "smooth",
    "supple",
    "wrinkled",
    "smooth",
    "green",
    "frond-like",
    "thick",
    "juicy",
    "waxy",
    "hard",
    "pointed",
    "notched",
    "green",
    "fluted",
    "long",
    "prickly",
    "smooth",
    "thin",
    "wavy",
    "supple",
    "fragrant",
    "bendable",
    "smooth",
    "pointed",
    "green",
    "succulent",
    "long",
    "straight",
    "thin",
    "fragile",
    "brittle",
    "green",
    "oval",
    "smooth",
    "supple",
    "tender",
    "thick",
    "thin",
    "whispering",
    "young",
    "tall",
    "whiteish-grey",
    "pearly",
    "argent",
    "bleached",
    "twanging",
    "telescoping",
    "solitary",
    "swollen",
    "leaf-scarred",
    "white seedpod ropes hanging from its",
    "braided seed-pods in its",
]

export const palmLeafTypes = [
    "verdure",
    "pointy",
    "long",
    "rigid",
    "sharp",
    "broad",
    "sturdy",
    "flexible",
    "thin",
    "soft",
    "flamboyant",
    "spoked",
    "fan shaped",
    "cardboard textured",
    "mantis-clawed",
    "Lush",
    "green",
    "waxy",
    "wide",
    "long",
    "pointy",
    "oval",
    "striped",
    "Prickly",
    "green",
    "long",
    "tropical",
    "wide",
    "Fanshaped",
    "jagged",
    "Fleshy",
    "Parallel-veined",
    "gall-blighted",
    "feather-like",
    "graceful",
    "silvery-grey",
    "grey-green",
    "frond",
    "emerald",
    "blue-green",
    "pinnate",
    "twisted",
    "seeking",
    "laterally twisting",
    "pendulous",
    "scale-covered",
    "silver-grey",
    "colourful",
]