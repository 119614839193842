export const slimeBeastFaceSentences = [
"Viscous slime oozes from its maw.",
"Rows of jagged teeth line its mouth.",
"Small, beady eyes peer out from its slimy head.",
"A string of drool dangles from its gaping maw.",
"It has eyes that seem to be constantly searching, darting aroundmadly.",
"Its teeth are sharp and jagged, yellowed with age.",
"Its skin is green and slimy, puls",
"Slimy, writhing mouth.",
"Stringy, drooling tongue.",
"Inky black eyes.",
"Gelatinous skin.",
"Putrid smell.",
"Slimy hair.",
"Sharp, slimy teeth.",
"Bulbous nose.",
"Wart-covered skin.",
"A vile, mucous-dripping creature, its oozing countenance is barely recognizable as once having been human.",
"A bestial visage, savage and wild, its eyes gleaming with a feral hunger.",
"A sea of slime surrounds the beast's face.",
"The beast's face is a mass of writhing tentacles.",
]

export const slimeBeastBodySentences = [
"its body is oozing and gross.",
"its body is covered in mucus and slime.",
"its body is dripping with slime.",
"its body is slimy and gross.",
"Vast and unending, its body seems to stretch on forever.",
"It is a heaving, writhing mass of putrid flesh, twisting and contorting in sickening ways.",
"The stench of death and decay hangs heavily in the air, coming",
"Thick, glistening slime coats its body.",
"Rows of sharp teeth line its mouth.",
"It has multiple eyes, all of which seem to be staring at you.",
"It's body is blotchy and uneven, resembling something that has been rotting for days",
"Ropy, glistening strands of slime drip from its oozing body.",
"Slimy, greenish-black ooze covers its entire body.",
"A viscous, clear slime coats its body.",
]

export const slimeBeastSoundWords = [
"Blooping",
"Sizzling",
"Crackling",
"Snapping",
"Popping",
"Oozing",
"Dripping",
"Slithering",
"Rumbling",
"Groaning",
"Bubbling",
"Gurgling",
"dripping",
"oozing",
"seeping",
"glopping",
"squishing",
"slurping",
"sucking",
"sickening",
]

export const slimeBeastFurWords = [
    "Grungy",
    "icky",
    "sticky",
    "Slimy",
    "Wet",
    "slimy",
    "sticky",
    "stringy"
]

export const slimeBeastSmellWords = [
"Putrid", "slimy", "rank", "fetid", "foul", "stinking", "reeking", "malodorous", "pungent", "odious",
]

export const slimeBeastBehaviourSentences = [
"it is making strange slurping noises as it moves.",
"it is waving its tentacles menacingly",
"it is dribbling slime all over the place",
"it is motionless, its eyes locked on you.",
"it is making a strange noise that sets your hair on end.",
"it is dripping slime all over the floor",
"it is a carrier of the black death",
"it is a terrible creature that should be avoided",
"it is known to eat human flesh",
]