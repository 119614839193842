export const slimyTendrilSmellTypes = [
	"Nauseating",
    "a sick bed",
    "rotting",
    "sewer-like",
    "foul",
    "putrid",
    "rancid",
    "stench",
    "noxious",
    "of sweet mead",
    "Nauseating",
    "Bitter",
    "Sickly",
    "Putrid",
    "Pungent",
    "Rancid",
    "Acrid",
    "nauseous",
    "toxic",
    "sickly",
    "sweet",
    "corrupted",
    "pungent",
    "decaying",
    "rotting",
    "foul",
    "putrid",
    "nauseating",
    "sulfuric",
    "bacterial",
    "decomposing",
    "pungent",
    "rotting",
    "foul",
    "noxious",
    "rank",
    "decaying",
    "rotting",
    "putrid",
    "foul",
    "nauseating",
    "disgusting",
    "vomit-inducing",
    "stomach-churning",
    "gag-worthy",
    "aquatic",
    "of machines and industry",
    "of impending lung disease",
    "sour",
    "synthetic",
    "of honey bleed from lions",
]

export const slimyTendrilBranchTypes = [
	"bewildering",
    "smelly",
    "stringy",
    "icky",
    "coiled",
    "loathly",
    "cold",
    "undulating",
    "squirming",
    "mucous-covered",
    "Diseased",
    "Decaying",
    "Rotten",
    "canker-coloured",
    "Nauseating",
    "Repulsive",
    "Grotesque",
    "Disgusting",
    "Revolting",
    "slimy",
    "tentacle-like",
    "coiled",
    "twisted",
    "yellowing",
    "segmented",
    "intriguing",
    "probing",
    "Diseased",
    "Decaying",
    "Rotten",
    "golden",
    "stenchy",
    "larvae-infested",
    "feverish",
    "vile",
    "Fetid",
    "rank",
    "revolting",
    "disgusting",
    "nauseating",
    "horrible",
    "callused",
    "foul",
    "waving, playful",
    "phlegm-laced",
    "cilia-crowned",
    "thread-like projections reaching from its",
    "the sense of being underwater with its undulating",
    "numerous fingers on its",
    "sap-soured",
    "mesmerising",
    "sloshing",
    "an unpleasant rumbling in its",
    "slick",
    "greasy",
    "livid",
    "tortuous",
    "pudgy",
    "scandalous",
    "squat",
    "bulging",
    "sinewy",
    "goosepimpled",
    "entangled",
    "hissing",
    "skin-slapping",
    "nagging",
    "lobed",
]

export const slimyTendrilLeafTypes = [
	"slippery",
    "oozing",
    "pungent",
    "rancid",
    "sewer-like",
    "toxic",
    "unpleasant",
    "vomit-inducing",
    "lurking",
    "slippery",
    "dank",
    "mouldy",
    "moist",
    "diseased",
    "rotting",
    "foul",
    "glistening",
    "slippery",
    "stringy",
    "dank",
    "oozing",
    "clammy",
    "pungent",
    "seeping",
    "nauseating",
    "Slimy",
    "Dirty",
    "Nasty",
    "Filthy",
    "Greasy",
    "Sticky",
    "Oily",
    "Smelly",
    "bloodied",
    "striped",
    "glinting",
    "curious",
    "grimy",
    "lurching",
    "creased",
]