export const tallGrassSmellTypes = [
	"of flat meadowlands",
    "of rolling hills",
    "of a summer's glade",
    "verdant",
    "lush",
    "bucolic",
    "pastoral",
    "of cow pats and bracken",
    "of reed breaks",
    "of dusty prairie",
    "joyful and simple",
    "sun-bleached",
    "of dried morels",
    "of collected bluebottles",
    "of Wildflower",
    "of windblown plains",
    "of salty air blown from the west",
    "of meadow",
    "of a dry riverbed",
    "of caked-in dirt",
    "of moss-clung boulders",
    "and unrelentingly green",
    "of coal on the wind",
    "of foam on breaking waves",
    "thin and burnt",
    "freshly of winter's wind",
    "timeless",
    "of rewilded husks",
    "of a secret den",
    "uncultivated",
    "lush",
    "of cool earthen moistness",
    "calm",
    "peaceful",
    "dense with pollutant",
    "of ravaged fields",
    "of cut mandrake",
    "of gathered straw",
    "herbal",
    "of digital and vegetal alliances",
    "musky",
    "pungent",
    "aromatic",
    "of wool and wheat",
    "of pastureland",
    "of shoots of hazel",
    "of the savannah",
    "of green abundance",
    "of musty thatch",
    "hardy",
    "of packed sod"
]

export const tallGrassBranchTypes = [

]

export const tallGrassLeafTypes = [
    "sheltering",
    "overgrown",
    "lush",
    "verdant",
    "nestled",
    "secluded",
    "lush",
    "pristine",
    "bucolic",
    "Lofty",
    "Resplendent",
    "Serene",
    "Sublime",
    "Tranquil",
    "Unruffled",
    "Venerable",
    "Windblown",
    "lank",
    "tussock",
    "tufty",
    "amicable",
    "spirit-lost",
    "comforting",
    "stalwart",
    "steady",
    "plain",
    "overly trusting",
    "a flowering culm atop its",
    "sharp-edged",
    "bladed",
    "finely-toothed",
    "a hollow stem supporting noded",
    "a stiff culm with offshooting",
    "a cylindrical stem with smooth",
    "long blue-green",
    "sullen",
    "impatient",
    "white-tipped",
    "yellowing",
    "peeled back"
]