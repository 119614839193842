export const okapiFaceSentences = [
"Big, brown eyes",
"Long, black nose",
"Two large, dark ears",
"large, round eyes",
"long, delicate muzzle",
"soft, velvety fur",
"long, black tongue",
"delicate, pointed ears",
"Broad, slightly rounded face.",
"Large eyes set close together.",
"Long, tapering muzzle.",
"Short, erect ears.",
]

export const okapiBodySentences = [
    "It is reddish brown in color with large, dark patches.",
"Its head is small, with large ears and eyes.",
"it has a reddish brown coat with zebra-like stripes.",
"it is a forest giraffe with a long neck and black mane.",
"it has a long, slender neck and a small head.",
"It has big eyes and long eyelashes.",
"it has a reddish brown coat with white stripes and spots.",
"It has long, furry ears and a short tail.",
"its coat is a dark reddish brown, with white stripes on the haunches.",
"it has a long neck, and a short, donkey-like muzzle.",
"It is a deep red rusty brown, with chestnut to reddish brown horizontal stripes on the legs.",
"Its body is round and compact, with a relatively short neck.",
"It has a long, flexible tongue",
]

export const okapiSoundWords = [
    "Humming",
    "Snorting",
    "Stomping",
    "Chuffing",
    "Tapping",
    "Whistling",
    "Hissing",
    "Roaring",
    "Screaming",
    "Rumbling",
    "Roaring",
    "Snorting",
    "Braying",
    "Trumpeting",
    "Bugling",
    "Hooting",
    "Coughing",
    "Wheezing",
    "Grunting",
    "snoring",
    "moaning",
    "crying",
    "laughing",
    "humming",
    "sneezing",
    "coughing",
    "gasping",
    "wheezing",
    "snoring",
    "grunting",
    "Hissing",
    "screeching",
    "growling",
    "Snorting",
    "bleating",
    "honking",
    "braying",
    "grunt",
    "whinny",
    "nicker",
    "Bellowing",
    "Snorting",
    "Grunting",
    "Wheezing",
    "Snuffling",
    "Sighing",
    "Chuffing",
    "Groaning",
    "Rumbling",
    "moaning"
]

export const okapiFurWords = [
    "Iridescent",
    "soft",
    "velvety",
    "smooth",
    "tough",
    "dry",
    "thick",
    "patchy",
    "vibrant",
    "saturated",
    "Coarse",
    "Oily",
    "Wiry",
    "Brittle",
    "Thick",
    "Rugged",
    "Smelly",
    "Damp",
    "Muddy",
    "Sweaty",
    "Sleek",
    "soft",
    "velvety",
    "supple",
    "shiny",
    "lustrous",
    "creamy",
    "pale",
    "spotted",
    "striped"
]

export const okapiSmellWords = [
    "Aromatic",
    "earthy",
    "clean",
    "spicy",
    "musky",
    "woody",
    "fresh",
    "light",
    "delicate",
    "rank",
    "musky",
    "fetid",
    "foul",
    "stinking",
    "reeking",
    "malodorous",
    "objectionable",
    "unpleasant",
    "noisome",
    "pungent",
    "odoriferous",
    "fragrant",
    "aromatic",
    "redolent",
    "aromatic",
    "perfumed",
    "sweet-smelling",
    "perfumy",
    "scented",
    "pungent",
    "musky",
    "gamy",
    "gamey",
    "rank",
    "fetid",
    "foul",
    "odorous",
    "reeky",
    "stinking",
    "Pungent",
    "Musky",
    "Gamey",
    "Dirty",
    "Foul",
    "Nauseating",
    "Repulsive",
    "Disgusting",
    "Stinking",
    "Rancid"
]

export const okapiBehaviourSentences = [
"it is staring at you with its weird eyes.",
"it is frantically darting its head from side to side.",
"it is making odd grunting noises.",
"it is stamping its feet.",
"it is staring at you intently with its large, dark eyes.",
"it is standing completely still, not making a sound.",
"it is eating its own feces",
"it is dragging its hind leg",
"it is standing on three legs",
"it is staring at you intently",
"it is making clicking noises",
]