import {
    CREATE_ZONE_LOADING,
    CREATE_ZONE_SUCCESS,
    CREATE_ZONE_FAILURE,
    GET_ZONES_LOADING,
    GET_ZONES_SUCCESS,
    GET_ZONES_FAILURE,
    ADD_ZONE,
    UPDATE_ZONE,
    REMOVE_ZONE,
    CLEAR_ZONES
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

export const createZoneSuccess = payload => ({
    type: CREATE_ZONE_SUCCESS,
    payload
})

export const createZoneFailure = () => ({
    type: CREATE_ZONE_FAILURE
})

export const createZoneAsync = payload => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'zones',
        name: 'create',
        data: {
            ...payload
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getZonesSuccess = payload => {
    return ({
        type: GET_ZONES_SUCCESS,
        payload
    })
}

export const getZonesFailure = () => ({
    type: GET_ZONES_FAILURE
})

export const addZone = payload => {
    return ({
        type: ADD_ZONE,
        payload
    })
}

export const updateZoneSuccess = payload => {
    return ({
        type: UPDATE_ZONE,
        payload
    })
}

export const updateZone = payload => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'zones',
        commandType: 'patch',
        data: {
            ...payload
        }
    })
}

export const removeZoneSuccess = payload => {
    return ({
        type: REMOVE_ZONE,
        payload
    })
}

export const deleteZoneAsync = payload => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'zones',
        commandType: 'remove',
        data: {
            ...payload
        }
    })
}

