import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import {
    showSelectItems,
    disableKeyboardMovement,
    enableKeyboardMovement,
    showHelp,
    startLooking,
    hideAllMenus,
} from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectCharacter,
    selectCurrentWeapon,
    selectCurrentTool,
    selectIsSelectItemsShowing
} from '../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';

import { client } from '../../services/client';

import './Equipment.css';

import Menu from '../utils/menu/Menu';

class Equipment extends React.Component {
    state = {
        bodyParts: [
            {
                text: 'Weapon Hand',
                shortcut: 'a'
            },
            {
                text: 'Tool Hand',
                shortcut: 'b'
            },
            {
                text: 'Worn on Head',
                shortcut: 'c'
            },
            {
                text: 'Worn on Body',
                shortcut: 'd'
            },
            {
                text: 'Left Arm',
                shortcut: 'e'
            },
            {
                text: 'Right Arm',
                shortcut: 'f'
            },
            {
                text: 'Shield',
                shortcut: 'g'
            },
            {
                text: 'Worn on Legs',
                shortcut: 'h'
            },
            {
                text: 'Worn on Feet',
                shortcut: 'i'
            },
        ],
        equipment: [
            {
                text: this.props.weapon?.name || '-',
                shortcut: 'a'
            },
            {
                text: this.props.tool?.toolType?.name || '-',
                shortcut: 'b'
            },
            {
                text: this.props.head?.type?.name || '-',
                shortcut: 'c'
            },
            {
                text: this.props.body?.type?.name || '-',
                shortcut: 'd'
            },
            {
                text: this.props.leftArm?.type?.name || '-',
                shortcut: 'e'
            },
            {
                text: this.props.rightArm?.type?.name || '-',
                shortcut: 'f'
            },
            {
                text: this.props.shield?.name || '-',
                shortcut: 'g'
            },
            {
                text: this.props.legs?.type?.name || '-',
                shortcut: 'h'
            },
            {
                text: this.props.feet?.type?.name || '-',
                shortcut: 'i'
            },
        ]
    }
    componentWillUnmount() {
        

    }

    componentDidMount() {
        
    }

    onChosenItem(item) {
        if (item && this.state.isSelectItemsShowing) {
            // this.setState({
            //     isSelectItemsShowing: false
            // })
            return;
        }

        this.setState({
            isSelectItemsShowing: true
        });

        if (item.text === 'Weapon Hand') {
            store.dispatch(showSelectItems({ itemsToSelect: 'weapons' }))
        }

        if (item.text === 'Tool Hand') {
            store.dispatch(showSelectItems({ itemsToSelect: 'tools' }))
        }

        if (item.text === 'Worn on Head') {
            store.dispatch(showSelectItems({ itemsToSelect: 'head' }))
        }

        if (item.text === 'Worn on Body') {
            store.dispatch(showSelectItems({ itemsToSelect: 'body' }))
        }

        if (item.text === 'Left Arm' || item.text === 'Right Arm') {
            store.dispatch(showSelectItems({ itemsToSelect: 'arm' }))
        }

        if (item.text === 'Shield') {
            store.dispatch(showSelectItems({ itemsToSelect: 'armour' }))
        }

        if (item.text === 'Worn on Legs') {
            store.dispatch(showSelectItems({ itemsToSelect: 'legs' }))
        }
        
        if (item.text === 'Worn on Feet') {
            store.dispatch(showSelectItems({ itemsToSelect: 'feet' }))
        }
    }

    static getDerivedStateFromProps(nextProps, state) {
        state = {
            ...state,
            equipment: [
                {
                    text: nextProps.weapon ? `${nextProps.weapon?.name} ${nextProps.weapon?.damage}ϴ ${nextProps.weapon?.toHit}ϰ ${nextProps.weapon?.penetration}➚` : '-',
                    shortcut: 'a'
                },
                {
                    text: nextProps.tool ? nextProps.tool?.toolType?.name : '-',
                    shortcut: 'b'
                },
                {
                    text: nextProps.head ? `${nextProps.head?.type?.name} ${nextProps.head?.type?.armour}❤ ${nextProps.head?.type?.dodge}❥` : '-',
                    shortcut: 'c'
                },
                {
                    text: nextProps.body ? `${nextProps.body?.type?.name} ${nextProps.body?.type?.armour}❤ ${nextProps.body?.type?.dodge}❥` : '-',
                    shortcut: 'd'
                },
                {
                    text: nextProps.leftArm ? `${nextProps.leftArm?.type?.name} ${nextProps.leftArm?.type?.armour || 0}❤ ${nextProps.leftArm?.type?.dodge || 0}❥` : '-',
                    shortcut: 'e'
                },
                {
                    text: nextProps.rightArm ? `${nextProps.rightArm?.type?.name} ${nextProps.rightArm?.type?.armour || 0}❤ ${nextProps.rightArm?.type?.dodge || 0}❥` : '-',
                    shortcut: 'f'
                },
                {
                    text: nextProps.shield ? `${nextProps.shield?.name} ${nextProps.shield?.armour}❤ ${nextProps.shield?.dodge}❥` : '-',
                    shortcut: 'g'
                },
                {
                    text: nextProps.legs ? `${nextProps.legs?.type?.name} ${nextProps.legs?.type?.armour}❤ ${nextProps.legs?.type?.dodge}❥` : '-',
                    shortcut: 'h'
                },
                {
                    text: nextProps.feet ? `${nextProps.feet?.type?.name} ${nextProps.feet?.type?.armour}❤ ${nextProps.feet?.type?.dodge}❥` : '-',
                    shortcut: 'i'
                },
            ]
        }

        if (!nextProps.isSelectItemsShowing) {
            return {
                ...state,
                isSelectItemsShowing: false
            }
        } else {
            return {
                ...state,
                isSelectItemsShowing: true
            }
        }
    }

    render() {
        return (
            <div className="equipment">
                <div className="equipment-sidebar">
                    <div className="status">
                        <div className="equipment-container">
                            <Menu options={this.state.bodyParts} optionChosen={(option) => this.onChosenItem(option)} disabled={this.state.isSelectItemsShowing}/>
                            <Menu options={this.state.equipment} isShortcutHidden={true} disabled={this.state.isSelectItemsShowing}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const character = selectCharacter(state)
    const isSelectItemsShowing = selectIsSelectItemsShowing(state);

    const weapon = character.weaponType;
    const tool = selectCurrentTool(state);
    const shield = character.armourType;

    const head = character.clothingItems?.find(clothingItem => (clothingItem.type?.bodyPart === 'head'));
    const leftArm = character.clothingItems?.find(clothingItem => (clothingItem.type?.bodyPart === 'arm'));
    const rightArm = character.clothingItems?.find(clothingItem => (clothingItem.type?.bodyPart === 'arm' && clothingItem.itemId !== leftArm.itemId));
    const body = character.clothingItems?.find(clothingItem => (clothingItem.type?.bodyPart === 'body'));
    const legs = character.clothingItems?.find(clothingItem => (clothingItem.type?.bodyPart === 'legs'));
    const feet = character.clothingItems?.find(clothingItem => (clothingItem.type?.bodyPart === 'feet'));

    return {
        character,
        isSelectItemsShowing,
        weapon,
        shield,
        tool,
        head,
        leftArm,
        rightArm,
        body,
        legs,
        feet
    }
}

export default connect(
    mapStateToProps,
    { hideAllMenus }
)(Equipment);