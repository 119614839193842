import {
  READ_INVENTORY_SUCCESS,
  READ_TILE_INVENTORY_SUCCESS,
  CLEAR_TILE_INVENTORY,
  UPDATE_POSITION_SUCCESS,
  REMOVE_MATERIAL,
  ADD_MATERIAL,
  UPDATE_MATERIAL,
  GET_TRADE_INVENTORY_SUCCESS,
  SHOW_CHARACTER_DEATH_PAGE,
  START_NEW_GAME,
  INITIALISING_PANEL_STARTED,
  CHANGE_CHARACTER
} from "../actionTypes";

const initialState = {
  inventory: [],
  tileInventory: [],
  tradeInventory: [],
  isLoading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case READ_INVENTORY_SUCCESS: {
      return {
        ...state,
        inventory: [...state.inventory, ...action.payload.filter(tile => tile.characterId)],
        tileInventory: [...action.payload.filter(tile => tile.tileId)],
      }
    }

    case GET_TRADE_INVENTORY_SUCCESS: {
      return {
        ...state,
        tradeInventory: [...action.payload],
      }
    }

    case REMOVE_MATERIAL: {
      return {
        inventory: [ ...state.inventory.filter((item) => (item._id !== action.payload._id)) ],
        tileInventory: [ ...state.tileInventory.filter((item) => (item._id !== action.payload._id)) ],
      }
    }

    case ADD_MATERIAL: {
      let inventory = [...state.inventory];
      let tileInventory = [...state.tileInventory];

      if (action.payload.characterId) {
        inventory = [ ...inventory, action.payload ]
      }

      if (action.payload.tileId) {
        tileInventory = [ ...tileInventory, action.payload ]
      }

      return {
        ...state,
        inventory: [ ...inventory ],
        tileInventory: [ ...tileInventory ],
      }
    }

    case UPDATE_MATERIAL: {
      let inventory = [...state.inventory];
      let tileInventory = [...state.tileInventory];

      if (action.payload.characterId) {
        inventory = [
          ...inventory.filter(mineral => mineral._id !== action.payload._id),
          { ...inventory.find(mineral => mineral._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.tileId) {
        tileInventory = [
          ...tileInventory.filter(mineral => mineral._id !== action.payload._id),
          { ...tileInventory.find(mineral => mineral._id === action.payload._id), ...action.payload }
        ]
      }

      return {
        ...state,
        inventory: [ ...inventory ],
        tileInventory: [ ...tileInventory ],
      }
    }

    default:
      return state;
  }
}
