import { GET_MINERAL_TYPES_SUCCESS, GET_MINERAL_TYPES_FAILURE } from "../actionTypes";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_MINERAL_TYPES_SUCCESS: {
      return [...action.payload]
    }

    default:
      return state;
  }
}
