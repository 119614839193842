export const snubNosedMonkeyFaceSentences = [
"Its cute face wrinkled in concentration, it delicately picks its way through the trees.",
"bulbous nose",
"narrow, slanted eyes",
"furry face",
"small mouth",
"protruding lower jaw",
"A tuft of hair on its forehead ",
"Round, black eyes ",
"A small, round nose ",
"A mouth with small, sharp teeth ",
"Furry, light-colored ears ",
]

export const snubNosedMonkeyBodySentences = [
"its body is small and thin. It has a long tail and short limbs.",
"The head is small and the face is flat.",
"The nose is short and turned up at the end.",
"The eyes are large and the ears are small.",
"The fur is soft and brown.",
"Cute little monkey with a snub nose",
"Adorable creature with big eyes and a button nose",
"gentle and harmless looking animal",
"Has a comical look due to its protruding teeth",
"Cute, furry little animal",
"its small stature and long limbs",
"its furry body and tail",
"its long arms and dexterous hands",
"its agile body and quick reflexes",
"its curious nature and playful demeanour",
"Its head is much too large for its body.",
"It has a short, stubby tail.",
"It has long arms and legs.",
"Its fur is gray or brown.",
"It has a pink or black face.",
]

export const snubNosedMonkeySoundWords = [
    "Hissing",
    "screeching",
    "chattering",
    "yelping",
    "yapping",
    "howling",
    "screeching",
    "chattering",
    "cooing",
    "croaking",
    "grunting",
    "hooting",
    "roaring",
    "shrieking",
    "trumpeting",
    "vocalizing",
    "whooping",
    "LOUD",
    "SNORTING",
    "SHRILL",
    "GRUNTING",
    "CLICKING",
    "SQUEAKING",
    "WHEEZING",
    "CHIMING",
    "VOCALIZING",
    "SCREAMING",
    "Sneezing",
    "sniffing",
    "grunting",
    "hooting",
    "howling",
    "yelping",
    "chattering",
    "crying",
    "screaming",
    "yelling"
]

export const snubNosedMonkeyFurWords = [
        "coarse",
    "dry",
    "leathery",
    "wrinkled",
    "thin",
    "sparse",
    "patchy",
    "mottled",
    "discolored",
    "Creamy",
    "Silky",
    "Soft",
    "Delicate",
    "Supple",
    "Fine",
    "Lustrous",
    "Glowing",
    "Radiant",
    "Short",
    "fine",
    "soft",
    "downy",
    "sparse",
    "dry",
    "shiny",
    "brittle",
    "Soft",
    "Spiky",
    "Itchy",
    "Smelly",
    "Oily",
    "Infested",
    "Dandruffy",
    "Crusty",
    "Icky",
    "dense",
    "thick",
    "course",
    "downy",
    "oily",
    "hide",
    "leather",
    "pelt",
    "fleece",
    "wool"
]

export const snubNosedMonkeySmellWords = [
    "pungent", "musky", "strong", "overpowering", "potent", "overwhelming", "intense", "serious", "concentrated", "harsh",
]

export const snubNosedMonkeyBehaviourSentences = [
"its long snout is wrinkled and hairless",
"its eyes are set close together and its mouth is small",
"its legs are short and its arms are long",
"it has a long tail that it uses for balance",
"it is scratching itself",
"it is eating something",
"it is picking its nose",
"it is making a strange noise",
"it is looking for something",
"it is moving its head from side to side",
"it is keeping its distance",
"it is making threatening movements",
"it is looking at you with its strange snub-nosed face",
"it is making a strange noise",
"it is moving its head from side to side",
"it is scratching itself",
"it is rolling its eyes",
]