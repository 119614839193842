import { showUnknownError } from '../redux/actions/keyboard-shortcuts.actions';
import { client } from '../services/client';

export const getRecursivelyAsync = (serviceName, onSuccess, skip, query = {}, onFinished) => dispatch => {
    let fullData = [];
    return new Promise((resolve, reject) => {
        getRecursivelyAsyncInner(serviceName, onSuccess, skip, query, onFinished);

        function getRecursivelyAsyncInner(serviceName, onSuccess, skip, query = {}, onFinished) {
            return client.service(serviceName).find({ query: { ...query, $skip: skip || 0 }})
                .then(({ total, limit, skip, data }) => {
                    const lastIndex = skip + limit || limit;
                    if (lastIndex < total) {
                        fullData.push(...data);
                        getRecursivelyAsyncInner(serviceName, onSuccess, skip + limit || limit, query, onFinished)
                    } else if (onFinished) {
                        dispatch(onFinished(data))
                        fullData.push(...data);
                        resolve(fullData);
                    } else {
                        fullData.push(...data);
                        resolve(fullData);
                    }
                    dispatch(onSuccess(data));
                })
                .catch((error) => {
                    console.error('error', error);
                    dispatch(showUnknownError());
                    reject(error);
                });
        }
    })
}
