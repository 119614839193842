import { GET_MATERIAL_TYPES_LOADING, GET_MATERIAL_TYPES_SUCCESS, GET_MATERIAL_TYPES_FAILURE } from '../actionTypes';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';

export const getMaterialTypesSuccess = payload => {
    return ({
        type: GET_MATERIAL_TYPES_SUCCESS,
        payload
    })
}

export const getMaterialTypesFailure = payload => ({
    type: GET_MATERIAL_TYPES_FAILURE
})

export const getMaterialTypesAsync = payload => dispatch => {
    return dispatch(getRecursivelyAsync('material-types', getMaterialTypesSuccess, 0));
}