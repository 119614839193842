export const pandaFaceSentences = [
    "smooth, bright green fur",
"big, black eyes",
"bushy, black and white tail",
"long, black claws",
"round, black nose",
"soft, green paws",
"chubby, green cheeks",
"big, green ears",
"sly and cunning eyes",
"round and fluffy face",
"cute and button nose",
"rose-colored cheeks",
"furry and lustrous ears",
"gracefully curving neck",
"soft and Downy fur",
"chubby and rotund body",
"Fluffy green panda with big black eyes.",
"Cute green panda with a black nose.",
"Playful green panda with big ears.",
"friendly green panda with a black mouth.",
"Big, bushy eyebrows",
"A big, black nose",
"Big, round eyes",
"A small, black mouth",
"Black fur covering its entire face",
"White fur around its eyes",
"Two big, furry ears",
"A soft, furry",

]

export const pandaBodySentences = [
"The panda's body is covered in a thick layer of green fur.",
"Glistening in the sunlight, the panda's green fur is sleek and beautiful.",
"The panda's round body is covered in soft, green fur.",
"The panda's furry body is a deep green, with black spots dotting its back.",
"plush White belly ",
"Solitude of the green mountains ",
"Quietly munching",
]

export const pandaSoundWords = [
    "Whimpering",
    "whining",
    "crying",
    "mewling",
    "blubbering",
    "sobbing",
    "sniveling",
    "moaning",
    "groaning",
    "keening",
    "cooing",
    "grunting",
    "huffing",
    "moaning",
    "mumbling",
    "rumbling",
    "snorting",
    "trumpeting",
    "wheezing",
    "whimpering",
    "Whimpering",
    "mewling",
    "yelping",
    "crying",
    "whining",
    "barking",
    "howling",
    "screaming",
    "roaring",
    "growling",
    "Hissing",
    "Roaring",
    "Snarling",
    "Clawing",
    "Raking",
    "Biting",
    "Battling",
    "Tussling",
    "Rumbling",
    "Graveling"
]

export const pandaFurWords = [
"Lush",
"Vibrant",
"Verdant",
"Emerald",
"Springtime",
"sunrise",
"dew",
"Grass",
"Bright",
"Vibrant",
"Lustrous",
"Silky",
"Soft",
"Fluffy",
"Supple",
"Glowing",
"Radiant",
"Serene",
"Bright",
"Velvety",
"Soft",
"Shiny",
"Lustrous",
"Rich",
"Deep",
"Vibrant",
"Radiant",
"Lively",
]

export const pandaSmellWords = [
    "pungent",
    "musky",
    "woods",
    "earthy",
    "wet",
    "green",
    "grassy",
    "decomposing",
    "leafy",
    "loamy",
    "Lively",
    "fragrant",
    "grassy",
    "lemony",
    "musky",
    "nutty",
    "outdoorsy",
    "peppery",
    "refreshing",
    "zesty",
    "Fruity",
    "Earthy",
    "Sweet",
    "Spicy",
    "Woody",
    "Musky",
    "Sour",
    "Bitter",
    "Salty",
    "Aromatic",
    "Fresh",
    "floral",
    "woodsy",
    "clean",
    "musky",
    "sweet",
    "vanillic",
    "balsamic"
]

export const pandaBehaviourSentences = [
"it is making a weird face",
"it is looking at you with its beady little eyes",
"it is baring its teeth",
]