import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, GET_MACHINES_SUCCESS, PANEL_SUCCESS, ADD_MACHINE, UPDATE_MACHINE, REMOVE_MACHINE } from "../actionTypes";

const initialState = {
  machines: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case PANEL_SUCCESS: {
      return {
        ...initialState
      }
    }
    case GET_MACHINES_SUCCESS: {
      const machines = action.payload;

      return {
        ...state,
        machines: [...machines],
      }
    }

    case ADD_MACHINE: {
      let machines = [...state.machines];

      if (action.payload.panelId) {
        machines = [ ...machines, action.payload ]
      }

      return {
        ...state,
        machines: [ ...machines ],
      }
    }

    case UPDATE_MACHINE: {
      let machines = [...state.machines];

      if (action.payload.panelId) {
        machines = [
          ...machines.filter(machine => machine._id !== action.payload._id),
          { ...machines.find(machine => machine._id === action.payload._id), ...action.payload }
        ]
      }

      return {
        ...state,
        machines: [ ...machines ],
      }
    }

    case REMOVE_MACHINE: {
      return {
        ...state,
        machines: [ ...state.machines.filter(machine => (machine._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
