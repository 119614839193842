export const mulberrySmellTypes = [
	"of brambles",
    "herbaceous",
    "of mahogany",
    "of currants",
    "of Fog",
    "of Smoke",
    "of Fire",
    "of Charcoal",
    "of Aspen",
    "of apricot blossom",
    "of woodbine",
    "of broom",
    "of mugwort",
    "of chervil",
    "of rosemary",
    "of dianthus",
    "of sweet migraines",
    "of battered sweets",
    "of sweet, dark fog",
    "of sesame and violet",
    "of rhubarb and oysters",
    "of nutty hibiscus",        
    "of several days laid up in bed",
    "of comforting chai",
    "of chewy dried fruits",
    "of wire and linen",
    "gassy",
    "of stale face paint",
    "of tea and wisteria",
    "whimsical",
    "beastly",
    "of boiled juice",
    "overstuffed",
    "reminiscent of confines",
    "of urine",
    "mouth puckering and tart",
    "tangy and mouth fizzling",
    "of raspberry and lime",
    "horrid",
    "of clotted cream on fruit",
    "of a sour bathhouse",
    "of stone fruit",
    "of hyacinth and ripe fruit",
    "confusing",
    "vulgar",
    "of wood-hewn habitation",
    "sinus-tickling",
    "of gorse and pandan leaves",
    "of acrid burnt pepper",
    "of green civet",
]

export const mulberryBranchTypes = [
	"Skeletal",
    "Hollow",
    "Twisted",
    "forked",
    "spiky",
    "Thorny",
    "Dried",
    "Stubby",
    "dangling",
    "overgrown and wild",
    "neglected",
    "flowering",
    "dormant",
    "charming",
    "elegant",
    "fast growing",
    "catkins hanging from its",
    "coccoons adorning its",
    "small worms crawling along its",
    "vigorous",
]

export const mulberryLeafTypes = [
	"alternately arranged",
    "broad",
    "evergreen",
    "woody",
    "nettle",
    "simple",
    "burweed",
    "lobed",
    "serrated",
    "cut-shy",
    "intricately lobed",
    "pollen-powdered",
    "worm-bitten",
    "broad",
    "simple",
    "broadly cordate",
    "notched",
    "unlobed",
    "serrated",
    "plain",
    "lustrous",
    "inconspicuous",
    "roughly textured",
    "softly haired",
    "yellowish green",
    "downy",
    "densely hairy",
]