import {
    CREATE_TOOL_LOADING,
    CREATE_TOOL_SUCCESS,
    CREATE_TOOL_FAILURE,
    GET_TOOLS_LOADING,
    GET_TOOLS_SUCCESS,
    GET_TOOLS_FAILURE,
    ADD_TOOL,
    UPDATE_TOOL,
    REMOVE_TOOL,
    GET_TRADE_TOOLS_SUCCESS,
    GET_TRADE_TOOLS_FAILURE,
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

import { moveItemFailure } from './move-item.actions';

export const createToolSuccess = payload => ({
    type: CREATE_TOOL_SUCCESS,
    payload
})

export const createToolFailure = () => ({
    type: CREATE_TOOL_FAILURE
})

export const createToolAsync = payload => dispatch => {
    const { _id, toolTypeId, selectedCharacterId, quantity } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'tool-instances',
        name: 'create',
        data: {
            toolTypeId,
            selectedCharacterId,
            quantity
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getTradeToolsSuccess = payload => {
    return ({
        type: GET_TRADE_TOOLS_SUCCESS,
        payload
    })
}

export const getTradeToolsFailure = () => ({
    type: GET_TRADE_TOOLS_FAILURE
})

export const getTradeToolsAsync = payload => dispatch => {
    return client.service('tool-instances').find({ query: { characterId: payload.characterId, markedForTradeCount: { $gte: 1 } }})
        .then((response) => {
            response = response.map(tool => ({ ...tool, toolType: payload.toolTypes.find(type => type._id === tool.toolTypeId) }))
            dispatch(getTradeToolsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            throw new Error(error);
        })
}

export const getToolsSuccess = payload => {
    return ({
        type: GET_TOOLS_SUCCESS,
        payload
    })
}

export const getToolsFailure = () => ({
    type: GET_TOOLS_FAILURE
})

export const getToolsAsync = payload => dispatch => {
    let query = { $or: [{ panelId: payload.panelId, z: payload.z}, { characterId: payload.characterId}] }

    if (payload.isNotFirstInitialisation) {
        query = { panelId: payload.panelId, z: payload.z}
    }

    return client.service('tool-instances').find({ query })
        .then((response) => {
            response = response.map(tool => ({ ...tool, toolType: payload.toolTypes.find(type => type._id === tool.toolTypeId) }))
            dispatch(getToolsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            throw new Error(error);
        })
}

export const addToolToTileAsync = payload => dispatch => {
    const { toolInstance, tileId, tileTypeId } = payload;
    const { toolTypeId, quantity, recipeId, characterId, _id } = toolInstance;

    colyClient.room.send('doAction', {
        serviceName: 'tool-instances',
        commandType: 'create',
        data: {
            toolTypeId,
            quantity,
            tileId,
            tileTypeId,
            panelId: "",
            position: {},
            characterId: ""
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addToolToCharacterAsync = payload => dispatch => {
    const { toolInstances, characterId } = payload;
    const { createdAt, toolTypeId, quantity, recipeId, _id } = toolInstances[0];

    colyClient.room.send('doSuperAction', {
        serviceName: 'tool-instances',
        name: 'fetch',
        data: {
            toolTypeId,
            quantity,
            tileId: "",
            characterId,
            instanceIds: toolInstances.map(instance => instance._id)
        },
        params: {
            query: {
                movementFrom: {
                    _id: undefined
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addTool = payload => {
    return ({
        type: ADD_TOOL,
        payload
    })
}

export const updateTool = payload => {
    return ({
        type: UPDATE_TOOL,
        payload
    })
}

export const removeTool = payload => {
    return ({
        type: REMOVE_TOOL,
        payload
    })
}
