import {
    GET_MACHINE_RECIPES_LOADING,
    GET_MACHINE_RECIPES_SUCCESS,
    GET_MACHINE_RECIPES_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';

export const getMachineRecipesSuccess = payload => ({
    type: GET_MACHINE_RECIPES_SUCCESS,
    payload
})

export const getMachineRecipesFailure = () => ({
    type: GET_MACHINE_RECIPES_FAILURE
})

export const getMachineRecipesAsync = payload => dispatch => {
    return dispatch(getRecursivelyAsync('machine-recipes', getMachineRecipesSuccess, 0));
}