export const reedSmellTypes = [
    "of fresh young greenery",
    "Resinous",
    "Smokey",
    "Powdery",
    "Minty",
    "Fresh",
    "of Old books",
    "of Cut flowers",
    "of Rain",
    "of bruised peaches",
    "of sweetgrass",
    "of crushed velvet",
    "of wet clay soils",
    "of sandalwood",
    "of leather",
    "crisp and clean",
    "softly aquatic",
    "of pepper and peach",
    "of musky aquatic florals",
    "fresh, ambiguous greens",
    "a strong tea",
    "fresh and inoffensive",
    "of ethereal openness",
    "of intangible air",
    "free-flowing and unimposing",
    "of cold, wet flowers",
    "a strong drink",
    "of a refreshing breeze",
    "of a moist morning fog",
    "of swimming",
    "of a secret dip in the river at night",
    "of many people gathered close",
    "of ozone",
    "humid and sweet",
    "of rain on a hot day",
    "of storms in the summer",
    "energising",
    "of the sharp change in season",
    "of rushing waterfalls",
    "of a storm rushing in",
    "of slushy freshness",
    "of the shoreline of a freshwater lake",
    "of hibiscus",
    "grassy and resinous",
    "of caramel and fig",
    "of nesting creatures",
    "of infested hollows",
    "of a receding shoreline",
    "of hungry glances",
    "of distrustful neighbours",
    "of funal decay",
]

export const reedBranchTypes = [
	"Flexible",
    "Nocturnal",
    "Solo",
    "Slender",
    "Subtle",
    "Stubborn",
    "Supportive",
    "Talented",
    "Trusting",
    "wise",
    "ethereally beautiful",
    "alabaster",
    "gleaming",
    "dew-kissed",
    "shadow-bourne",
    "velvet-antlered",
    "Towering",
    "Viridescent",
    "Burgeoning",
    "stiltflower",
    "canescent spikes on its",
    "semi-aquatic",
    "blanched",
    "bleak",
    "fawn-coloured",
    "viridian",
    "scratchy",
    "drained",
    "whistling",
    "unyielding",
    "supportive",
    "drifting",
    "bare",
    "calm",
    "bone-thin",
    "a lurking presence in the stream beneath its",
    "miserable",
    "broken",
    "detailed",
    "site-specific",
    "swaying",
    "forgotten",
    "simple",
    "small",
    "clumsy",
]

export const reedLeafTypes = [
	"bittersweet",
    "tired",
    "Thinning",
    "departing",
    "chilly",
    "ghostly"
    "porcelain-blue",
    "translucent",
    "enchanted spiral",
    "teal-laced memory",
    "forever",
    "wet",
    "river-awed",
    "fish-path roots and sturdy",
    "green",
    "billowing",
    "murky",
    "muck",
    "fragile",
    "red-wove starch-spiked",
    "haughty",
    "stream-hewn",
    "lank",    
    "blunt",
    "jagged",
    "fresh",
    "serene",
    "inviting",
    "joyful",
    "slack",
    "fair",
    "winsome",
    "flapping",
    "satin",
    "sentimental",
]