

export const selectJewelleryRecipes = store => {
	return store && store.jewelleryRecipes
		? [ ...store.jewelleryRecipes.map(recipe => (populateRecipeTypes(store, 'jewelleryType', recipe))) ]
		: []
}

export const selectArmourRecipes = store => {
	return store && store.armourRecipes
		? [ ...store.armourRecipes.map(recipe => (populateRecipeTypes(store, 'armourType', recipe))) ]
		: []
}

export const selectWeaponRecipes = store => {
	return store && store.weaponRecipes
		? [ ...store.weaponRecipes.map(recipe => (populateRecipeTypes(store, 'weaponType', recipe))) ]
		: []
}

export const selectFurnitureRecipes = store => {
	return store && store.furnitureRecipes
		? [ ...store.furnitureRecipes.map(recipe => (populateRecipeTypes(store, 'furnitureType', recipe))) ]
		: []
}

export const selectTileRecipes = store => {
	return store && store.tileRecipes
		? [ ...store.tileRecipes.map(recipe => (populateRecipeTypes(store, 'tileType', recipe))) ]
		: []
}

export const selectWritingImplementRecipes = store => {
	return store && store.writingImplementRecipes
		? [ ...store.writingImplementRecipes.map(recipe => (populateRecipeTypes(store, 'writingImplementType', recipe))) ]
		: []
}

export const selectWritingSurfaceRecipes = store => {
	return store && store.writingSurfaceRecipes
		? [ ...store.writingSurfaceRecipes.map(recipe => (populateRecipeTypes(store, 'writingSurfaceType', recipe))) ]
		: []
}

export const selectLockRecipes = store => {
	return store && store.lockRecipes
		? [ ...store.lockRecipes.map(recipe => (populateRecipeTypes(store, 'lockType', recipe))) ]
		: []
}

export const selectToolRecipes = store => {
	return store && store.toolRecipes
		? [ ...store.toolRecipes.map(recipe => (populateRecipeTypes(store, 'toolType', recipe))) ]
		: []
}

export const selectMetalRecipes = store => {
	return store && store.metalRecipes
		? [ ...store.metalRecipes.map(recipe => (populateRecipeTypes(store, 'metalType', recipe))) ]
		: []
}

export const selectMaterialRecipes = store => {
	return store && store.materialRecipes
		? [ ...store.materialRecipes.map(recipe => (populateRecipeTypes(store, 'materialType', recipe))) ]
		: []
}

export const selectMachineRecipes = store => {
	return store && store.machineRecipes
		? [ ...store.machineRecipes.map(recipe => (populateRecipeTypes(store, 'machineType', recipe))) ]
		: []
}

export const selectFoodRecipes = store => {
	return store && store.foodRecipes
		? [ ...store.foodRecipes.map(recipe => (populateRecipeTypes(store, 'foodType', recipe))) ]
		: []
}

export const selectConstructionRecipes = store => {
	return store && store.constructionRecipes
		? [ ...store.constructionRecipes.map(recipe => (populateRecipeTypes(store, 'constructionType', recipe))) ]
		: []
}

export const selectClothingRecipes = store => {
	return store && store.clothingRecipes
		? [ ...store.clothingRecipes.map(recipe => (populateRecipeTypes(store, 'clothingType', recipe))) ]
		: []
}

export const selectBoatRecipes = store => {
	return store && store.boatRecipes
		? [ ...store.boatRecipes.map(recipe => (populateRecipeTypes(store, 'boatType', recipe))) ]
		: []
}

export const selectBrainchipRecipes = store => {
	return store && store.brainchipRecipes
		? [ ...store.brainchipRecipes.map(recipe => (populateRecipeTypes(store, 'brainchipType', recipe))) ]
		: []
}

export const selectTentRecipes = store => {
	return store && store.tentRecipes
		? [ ...store.tentRecipes.map(recipe => (populateRecipeTypes(store, 'tentType', recipe))) ]
		: []
}

export const selectWorkshopRecipes = store => {
	return store && store.workshopRecipes
		? [ ...store.workshopRecipes.map(recipe => (populateRecipeTypes(store, 'workshopType', recipe))) ]
		: []
}

export const selectWagonRecipes = store => {
	return store && store.wagonRecipes
		? [ ...store.wagonRecipes.map(recipe => (populateRecipeTypes(store, 'wagonType', recipe))) ]
		: []
}

export const selectCoinRecipes = store => {
	return store && store.coinRecipes
		? [ ...store.coinRecipes.map(recipe => (populateRecipeTypes(store, 'coinType', recipe))) ]
		: []
}

function populateRecipeTypes(store, recipeTypeKey, recipe) {
	return {
		...recipe,
		[recipeTypeKey]: store[recipeTypeKey + 's']?.find(({ _id }) => (_id === recipe[recipeTypeKey + 'Id'])),
		ingredients: recipe.ingredients && [ ...recipe.ingredients?.map(ingredient => selectIngredientType(store, ingredient)) ],
		machines: recipe.machines && [ ...recipe.machines?.map(machine => ({ ...machine, machineType: store.machineTypes.find(({_id}) => (machine.machineTypeId === _id)) })) ],
		tools: recipe.tools && [ ...recipe.tools?.map(tool => ({ ...tool, toolType: store.toolTypes.find(({_id}) => (_id === tool.toolTypeId)) })) ],
		destructionTools: recipe.destructionTools && [ ...recipe.destructionTools?.map(tool => ({ ...tool, toolType: store.toolTypes.find(({_id}) => (_id === tool.toolTypeId)) })) ],
		destructionOutputs: recipe.destructionOutputs && [ ...recipe.destructionOutputs?.map(ingredient => selectIngredientType(store, ingredient)) ],
	}
}

function selectIngredientType(store, ingredient) {
    let plant;
    let materialType;
    let ingredientType;
    let animal;

    if (ingredient.plantTypeId) {
        plant = store.plantTypes.find(({ _id }) => (_id === ingredient.plantTypeId));
    }

    if (ingredient.materialTypeId) {
        materialType = store.materialTypes.find(({ _id }) => (_id === ingredient.materialTypeId));
    }

    if (ingredient.mineralTypeId) {
        ingredientType = store.mineralTypes.find(({ _id }) => (_id === ingredient.mineralTypeId));
    }

    if (ingredient.metalTypeId) {
        ingredientType = store.metalTypes.find(({ _id }) => (_id === ingredient.metalTypeId));
    }

    if (ingredient.writingSurfaceTypeId) {
        ingredientType = store.writingSurfaceTypes.find(({ _id }) => (_id === ingredient.writingSurfaceTypeId));
    }

    if (ingredient.coinTypeId) {
        ingredientType = store.coinTypes.find(({ _id }) => (_id === ingredient.coinTypeId));
    }

    if (ingredient.animalTypeId) {
        animal = store.animalTypes.find(({ _id }) => (_id === ingredient.animalTypeId));
    }

    return {
        ...ingredient,
        plant,
        materialType,
        ingredientType,
        animal
    };
}

export const selectAllRecipes = (state) => {
	const jewelleryRecipes = selectJewelleryRecipes(state)
	const armourRecipes = selectArmourRecipes(state)
	const weaponRecipes = selectWeaponRecipes(state)
	const furnitureRecipes = selectFurnitureRecipes(state)
	const tileRecipes = selectTileRecipes(state)
	const writingImplementRecipes = selectWritingImplementRecipes(state)
	const writingSurfaceRecipes = selectWritingSurfaceRecipes(state)
	const lockRecipes = selectLockRecipes(state)
	const toolRecipes = selectToolRecipes(state)
	const metalRecipes = selectMetalRecipes(state)
	const materialRecipes = selectMaterialRecipes(state)
	const machineRecipes = selectMachineRecipes(state)
	const foodRecipes = selectFoodRecipes(state)
	const clothingRecipes = selectClothingRecipes(state)
	const constructionRecipes = selectConstructionRecipes(state)
	const boatRecipes = selectBoatRecipes(state)
	// const tentRecipes = selectTentRecipes(state)
	// const workshopRecipes = selectWorkshopRecipes(state)
	// const wagonRecipes = selectWagonRecipes(state)
	// const coinRecipes = selectCoinRecipes(state)
	const brainchipRecipes = selectBrainchipRecipes(state)

	const recipes = {
		jewellery: [ ...jewelleryRecipes ],
		armour: [ ...armourRecipes ],
		weapons: [ ...weaponRecipes ],
		furniture: [ ...furnitureRecipes ],
		tiles: [ ...tileRecipes ],
		writingImplements: [ ...writingImplementRecipes ],
		writingSurfaces: [ ...writingSurfaceRecipes ],
		locks: [ ...lockRecipes ],
		tools: [ ...toolRecipes ],
		metals: [ ...metalRecipes ],
		inventory: [ ...materialRecipes ],
		// machine: [ ...machineRecipes ],
		food: [ ...foodRecipes ],
		clothing: [ ...clothingRecipes ],
		construction: [ ...constructionRecipes ],
		minerals: [], // TODO
		boatRecipes: [ ...boatRecipes ],
		// workshopRecipes: [ ...workshopRecipes ],
		// wagonRecipes: [ ...wagonRecipes ],
		// coinRecipes: [ ...coinRecipes ],
		brainchipRecipes: [ ...brainchipRecipes.map(brainchipRecipe => ({ ...brainchipRecipe, name: brainchipRecipe.brainchipType.name })) ],
	}

	return recipes;

	// return [
	// 	...recipes.jewelleryRecipes.map(recipe => ({ ...recipe, type: 'jewellery' })),
	// 	...recipes.armourRecipes.map(armourRecipe => ({ ...armourRecipe, type: 'armour'})),
	// 	...recipes.weaponRecipes.map(weaponRecipe => ({ ...weaponRecipe, type: 'weapon'})),
	// 	...recipes.furnitureRecipes.map(furnitureRecipe => ({ ...furnitureRecipe, type: 'furniture'})),
	// 	...recipes.tileRecipes.map(tileRecipe => ({ ...tileRecipe, type: 'tile'})),
	// 	...recipes.writingImplementRecipes.map(writingImplementRecipe => ({ ...writingImplementRecipe, type: 'writingImplement'})),
	// 	...recipes.writingSurfaceRecipes.map(writingSurfaceRecipe => ({ ...writingSurfaceRecipe, type: 'writingSurface'})),
	// 	...recipes.lockRecipes.map(lockRecipe => ({ ...lockRecipe, type: 'lock'})),
	// 	...recipes.toolRecipes.map(toolRecipe => ({ ...toolRecipe, type: 'tool'})),
	// 	...recipes.metalRecipes.map(metalRecipe => ({ ...metalRecipe, type: 'metal'})),
	// 	...recipes.materialRecipes.map(materialRecipe => ({ ...materialRecipe, type: 'material'})),
	// 	...recipes.machineRecipes.map(machineRecipe => ({ ...machineRecipe, type: 'machine'})),
	// 	...recipes.foodRecipes.map(foodRecipe => ({ ...foodRecipe, type: 'food'})),
	// 	...recipes.clothingRecipes.map(clothingRecipe => ({ ...clothingRecipe, type: 'clothing'})),
	// 	...recipes.constructionRecipes.map(constructionRecipe => ({ ...constructionRecipe, type: 'construction'})),
	// 	...recipes.boatRecipes.map(boatRecipe => ({ ...boatRecipe, type: 'boat'})),
	// 	...recipes.workshopRecipes.map(workshopRecipe => ({ ...workshopRecipe, type: 'workshop'})),
	// 	...recipes.wagonRecipes.map(wagonRecipe => ({ ...wagonRecipe, type: 'wagon'})),
	// 	...recipes.coinRecipes.map(coinRecipe => ({ ...coinRecipe, type: 'coin'})),
	// 	...recipes.brainchipRecipes,
	// ]
}

export const selectRecipesByWorkshopTypeId = (state, workshopTypeId) => {
	const jewelleryRecipes = selectJewelleryRecipes(state)
	const armourRecipes = selectArmourRecipes(state)
	const weaponRecipes = selectWeaponRecipes(state)
	const furnitureRecipes = selectFurnitureRecipes(state)
	const tileRecipes = selectTileRecipes(state)
	const writingImplementRecipes = selectWritingImplementRecipes(state)
	const writingSurfaceRecipes = selectWritingSurfaceRecipes(state)
	const lockRecipes = selectLockRecipes(state)
	const toolRecipes = selectToolRecipes(state)
	const metalRecipes = selectMetalRecipes(state)
	const materialRecipes = selectMaterialRecipes(state)
	const machineRecipes = selectMachineRecipes(state)
	const foodRecipes = selectFoodRecipes(state)
	const clothingRecipes = selectClothingRecipes(state)
	const constructionRecipes = selectConstructionRecipes(state)
	const workshopRecipes = selectWorkshopRecipes(state)
	// TODO - get these guys working later on haha :)
	const boatRecipes = selectBoatRecipes(state) // Will be needed before launch lets be realistic :)
	// const wagonRecipes = selectWagonRecipes(state)
	// const coinRecipes = selectCoinRecipes(state)
	const brainchipRecipes = selectBrainchipRecipes(state) // Needed because of the healing thing :)
	// const tentRecipes = selectTentRecipes(state)

	const recipes = {
		jewelleryRecipes: [ ...jewelleryRecipes.filter(recipe => (recipe.jewelleryType?.workshopTypeId === workshopTypeId)) ],
		armourRecipes: [ ...armourRecipes.filter(recipe => (recipe.armourType?.workshopTypeId === workshopTypeId)) ],
		weaponRecipes: [ ...weaponRecipes.filter(recipe => (recipe.weaponType?.workshopTypeId === workshopTypeId)) ],
		furnitureRecipes: [ ...furnitureRecipes.filter(recipe => (recipe.furnitureType?.workshopTypeId === workshopTypeId)) ],
		tileRecipes: [ ...tileRecipes.filter(recipe => (recipe.tileType?.workshopTypeId === workshopTypeId)) ],
		writingImplementRecipes: [ ...writingImplementRecipes.filter(recipe => (recipe.writingImplementType?.workshopTypeId === workshopTypeId)) ],
		writingSurfaceRecipes: [ ...writingSurfaceRecipes.filter(recipe => (recipe.writingSurfaceType?.workshopTypeId === workshopTypeId)) ],
		lockRecipes: [ ...lockRecipes.filter(recipe => (recipe.lockType?.workshopTypeId === workshopTypeId)) ],
		toolRecipes: [ ...toolRecipes.filter(recipe => (recipe.toolType?.workshopTypeId === workshopTypeId)) ],
		metalRecipes: [ ...metalRecipes.filter(recipe => (recipe.metalType?.workshopTypeId === workshopTypeId)) ],
		materialRecipes: [ ...materialRecipes.filter(recipe => (recipe.materialType?.workshopTypeId === workshopTypeId)) ],
		machineRecipes: [ ...machineRecipes.filter(recipe => (recipe.machineType?.workshopTypeId === workshopTypeId)) ],
		foodRecipes: [ ...foodRecipes.filter(recipe => (recipe.foodType?.workshopTypeId === workshopTypeId)) ],
		clothingRecipes: [ ...clothingRecipes.filter(recipe => (recipe.clothingType?.workshopTypeId === workshopTypeId)) ],
		constructionRecipes: [ ...constructionRecipes.filter(recipe => (recipe.constructionType?.workshopTypeId === workshopTypeId)) ],
		workshopRecipes: [ ...workshopRecipes.filter(recipe => (recipe.workshopType?.workshopTypeId === workshopTypeId)) ],
		boatRecipes: [ ...boatRecipes.filter(recipe => (recipe.boatType?.workshopTypeId === workshopTypeId)) ],
		// wagonRecipes: [ ...wagonRecipes.filter(recipe => (recipe.wagonType?.workshopTypeId === workshopTypeId)) ],
		// coinRecipes: [ ...coinRecipes.filter(recipe => (recipe.coinType?.workshopTypeId === workshopTypeId)) ],
		brainchipRecipes: [ ...brainchipRecipes.filter(recipe => (recipe.brainchipType?.workshopTypeId === workshopTypeId)) ],
		// tentRecipes: [ ...tentRecipes.filter(recipe => (recipe.tentType?.workshopTypeId === workshopTypeId)) ],
	}

	return [
		...recipes.jewelleryRecipes.map(recipe => ({ ...recipe, type: 'jewellery' })),
		...recipes.armourRecipes.map(armourRecipe => ({ ...armourRecipe, type: 'armour'})),
		...recipes.weaponRecipes.map(weaponRecipe => ({ ...weaponRecipe, type: 'weapon'})),
		...recipes.furnitureRecipes.map(furnitureRecipe => ({ ...furnitureRecipe, type: 'furniture'})),
		...recipes.tileRecipes.map(tileRecipe => ({ ...tileRecipe, type: 'tile'})),
		...recipes.writingImplementRecipes.map(writingImplementRecipe => ({ ...writingImplementRecipe, type: 'writingImplement'})),
		...recipes.writingSurfaceRecipes.map(writingSurfaceRecipe => ({ ...writingSurfaceRecipe, type: 'writingSurface'})),
		...recipes.lockRecipes.map(lockRecipe => ({ ...lockRecipe, type: 'lock'})),
		...recipes.toolRecipes.map(toolRecipe => ({ ...toolRecipe, type: 'tool'})),
		...recipes.metalRecipes.map(metalRecipe => ({ ...metalRecipe, type: 'metal'})),
		...recipes.materialRecipes.map(materialRecipe => ({ ...materialRecipe, type: 'material'})),
		...recipes.machineRecipes.map(machineRecipe => ({ ...machineRecipe, type: 'machine'})),
		...recipes.foodRecipes.map(foodRecipe => ({ ...foodRecipe, type: 'food'})),
		...recipes.clothingRecipes.map(clothingRecipe => ({ ...clothingRecipe, type: 'clothing'})),
		...recipes.constructionRecipes.map(constructionRecipe => ({ ...constructionRecipe, type: 'construction'})),
		...recipes.workshopRecipes.map(workshopRecipe => ({ ...workshopRecipe, type: 'workshop'})),
		...recipes.boatRecipes.map(boatRecipe => ({ ...boatRecipe, type: 'boat'})),
		// ...recipes.tentRecipes.map(tentRecipe => ({ ...tentRecipe, type: 'tent'})),
		// ...recipes.wagonRecipes.map(wagonRecipe => ({ ...wagonRecipe, type: 'wagon'})),
		// ...recipes.coinRecipes.map(coinRecipe => ({ ...coinRecipe, type: 'coin'})),
		...recipes.brainchipRecipes.map(brainchipRecipe => ({ ...brainchipRecipe, type: 'brainchip'})),
	]
}

export const selectWorkshopUpgradeRecipe = (state, workshopTypeId, level) => {
	if (!state.workshopLevelRecipes) {
		return {};
	}

	const workshopLevelRecipe = state.workshopLevelRecipes.find((workshopLevelRecipe) => (workshopLevelRecipe.workshopTypeId === workshopTypeId && workshopLevelRecipe.level === level))

	if (!workshopLevelRecipe) {
		return {};
	}

	return {
		...workshopLevelRecipe,
		ingredients: workshopLevelRecipe.ingredients && [ ...workshopLevelRecipe.ingredients?.map(ingredient => selectIngredientType(state, ingredient)) ],
	}
}