import {
    CREATE_MACHINE_LOADING,
    CREATE_MACHINE_SUCCESS,
    CREATE_MACHINE_FAILURE,
    GET_MACHINES_LOADING,
    GET_MACHINES_SUCCESS,
    GET_MACHINES_FAILURE,
    ADD_MACHINE,
    UPDATE_MACHINE,
    REMOVE_MACHINE,
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

export const createMachineSuccess = payload => ({
    type: CREATE_MACHINE_SUCCESS,
    payload
})

export const createMachineFailure = () => ({
    type: CREATE_MACHINE_FAILURE
})

export const createMachineAsync = payload => dispatch => {
    const { _id, machineTypeId, selectedCharacterId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'machine-instances',
        name: 'create',
        data: {
            machineTypeId,
            selectedCharacterId
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getMachinesSuccess = payload => {
    return ({
        type: GET_MACHINES_SUCCESS,
        payload
    })
}

export const getMachinesFailure = () => ({
    type: GET_MACHINES_FAILURE
})

export const getMachinesAsync = payload => dispatch => {
    return client.service('machine-instances').find()
        .then((response) => {
            response = response.map(machine => ({ ...machine, machineType: payload.machineTypes.find(type => type._id === machine.machineTypeId) }))
            dispatch(getMachinesSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const addMachine = payload => {
    return ({
        type: ADD_MACHINE,
        payload
    })
}

export const updateMachine = payload => {
    return ({
        type: UPDATE_MACHINE,
        payload
    })
}

export const removeMachine = payload => {
    return ({
        type: REMOVE_MACHINE,
        payload
    })
}
