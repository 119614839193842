import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import { disableKeyboardMovement, enableKeyboardMovement, showHelp, startLooking } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectPanel,
    selectCharacterMessages,
    selectEmbarkCharacterMessages,
    selectTotalMessages,
    selectCharacter,
    selectCharacters,
    selectIsMessagesFocussed,
    selectFocussedCharacterId,
    selectMessageOptionss,
    selectEmbarkCharacter,
    selectCurrentEmbarkGroup
} from '../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';

import { client } from '../../services/client';

import './Messages.css';

// DISABLE DEFAULT SCROLL?

class Messages extends React.Component {
    state = {
        text: "",
        isFetching: false,
        isMessagesSet: false,
        handleNavigation: this.handleNavigation.bind(this),
        isEmptyFocussedCharacterShowing: false,
    };

    handleInput = event => {
        let isLastMessageFocussed, focussedCharacterMessages;

        if (this.state.highlight?.characterId) {
            focussedCharacterMessages = this.props.messages[this.state.highlight?.characterId].messages
            isLastMessageFocussed = this.state.highlight.messageIndex === focussedCharacterMessages.length;
        }

        if (focussedCharacterMessages && !isLastMessageFocussed) {
            return;
        }

        if (event.nativeEvent.inputType === 'insertLineBreak') {
            this.submitMessage();
        } else {
            this.setState({ text: event.target.value });
        }
    };

    disableMovement = () => {
        store.dispatch(disableKeyboardMovement());
    }

    enableMovement = () => {
        store.dispatch(enableKeyboardMovement());
    }

    submitMessage = () => {
        this.props.createMessageAsync({
            text: this.state.text,
            targetCharacterId: this.state.highlight.characterId === 'undefined' ? undefined : this.state.highlight.characterId
        })

        this.setState({ text: '' });

        document.activeElement.blur();
    };

    openHelp = () => {
        store.dispatch(showHelp());
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.state.handleNavigation)
    }

    componentDidMount() {
        window.addEventListener('keydown', this.state.handleNavigation);

        if (!this.state.highlight.characterId) {
            return;
        }

        const focussedCharacterMessages = this.props.messages[this.state.highlight.characterId]?.messages
        const isLastMessageFocussed = this.state.highlight.messageIndex === focussedCharacterMessages?.length;

        if (isLastMessageFocussed) {
            const textareaElement = document.getElementsByClassName('new-message')[0]

            if (!textareaElement) {
                return;
            }

            setTimeout(() => {
                textareaElement?.focus()
                setTimeout(() => (textareaElement.value = ''), 10)
            })
        }

        setTimeout(() => (this.correctScrollPosition()));
    }

    handleUpArrow() {
        if (this.state.highlight.type === 'messages') {
            this.setState({
                highlight: {
                    ...this.state.highlight,
                    messageIndex: this.state.highlight.messageIndex === 0 ? 0 : this.state.highlight.messageIndex - 1
                }
            })

            document.activeElement.blur();
        }

        if (this.state.highlight.type === 'characters') {
            const messageKeys = Object.keys(this.props.messages)
            const nextCharacterIndex = messageKeys.indexOf(this.state.highlight.characterId) === 0 ? 0 : messageKeys.indexOf(this.state.highlight.characterId) - 1;

            this.setState({
                highlight: {
                    ...this.state.highlight,
                    characterId: messageKeys[nextCharacterIndex]
                }
            })
        }
    }

    handleDownArrow() {
        if (this.state.highlight.type === 'messages') {
            const messageOptionsCount = this.props.messageOptions.length ? this.props.messageOptions.length - 1 : 0;
            const focussedCharacterMessages = this.props.messages[this.state.highlight.characterId].messages
            const isLastMessageFocussed = this.state.highlight.messageIndex === focussedCharacterMessages.length + messageOptionsCount;

            this.setState({
                highlight: {
                    ...this.state.highlight,
                    messageIndex: isLastMessageFocussed ? this.state.highlight.messageIndex : this.state.highlight.messageIndex + 1
                }
            })
        }

        if (this.state.highlight.type === 'characters') {
            const messageKeys = Object.keys(this.props.messages)
            const nextCharacterIndex = messageKeys.indexOf(this.state.highlight.characterId) === messageKeys.length - 1 ? messageKeys.length - 1 : messageKeys.indexOf(this.state.highlight.characterId) + 1;

            this.setState({
                highlight: {
                    ...this.state.highlight,
                    characterId: messageKeys[nextCharacterIndex]
                }
            })
        }
    }

    handleRightArrow() {
        if (this.state.highlight.type === 'messages') {
            return;
        }

        const messageOptionsCount = this.props.messageOptions.length - 1 || 0;
        const focussedCharacterMessages = this.props.messages[this.state.highlight.characterId].messages

        this.setState({
            highlight: {
                ...this.state.highlight,
                type: 'messages',
                messageIndex: focussedCharacterMessages.length + messageOptionsCount
            }
        })
    }

    handleLeftArrow() {
        if (this.state.highlight.type === 'characters') {
            return;
        }

        this.setState({
            highlight: {
                ...this.state.highlight,
                type: 'characters',
            }
        })
    }

    handleOptionSelection(messageOption) {
        const messageOptionIndex = this.state.highlight.messageIndex - this.props.messages[this.state.highlight.characterId].messages.length
        const selectedOption = messageOption || this.props.messageOptions[messageOptionIndex]

        if (messageOptionIndex < 0) {
            return;
        }

        selectedOption.callback(selectedOption.text);

        setTimeout(() => {
            const focussedCharacterId = this.props.focussedCharacterId || Object.keys(this.props.messages)[0]
            const messageOptionsCount = this.props.messageOptions?.length > 0 ? this.props.messageOptions?.length - 1 : 0;

            this.setState({
                ...this.state,
                highlight: {
                    ...this.state.highlight,
                    messageIndex: this.props.messages[focussedCharacterId]?.messages?.length + messageOptionsCount
                }
            })
        })
    }

    correctScrollPosition() {
        const highlighted = document.getElementsByClassName('highlighted')[0]

        const messagesList = document.getElementsByClassName('messages-list')[0]
        const charactersList = document.getElementsByClassName('characters-list')[0]

        if (!messagesList) {
            return;
        }

        const isElementAboveTop = highlighted?.offsetTop < messagesList.scrollTop
        const isElementBelowBottom = highlighted?.offsetTop > messagesList.offsetHeight + messagesList.scrollTop

        if (this.state.highlight.type === 'messages' && (isElementAboveTop || isElementBelowBottom)) {
            messagesList.scrollTop = highlighted?.offsetTop - 20;
        }

        if (this.state.highlight.type === 'characters') {
            messagesList.scrollTop = 1000000;

            const isElementAboveTop = highlighted?.offsetTop < charactersList.scrollTop
            const isElementBelowBottom = highlighted?.offsetTop > charactersList.offsetHeight + charactersList.scrollTop

            if (isElementAboveTop || isElementBelowBottom) {
                charactersList.scrollTop = highlighted?.offsetTop - 20;
            }
        }
    }

    focusTextareaOnNavigation() {
        if (this.state.highlight.type === 'messages') {
            const isMessageOptions = this.props.messageOptions?.length > 0;
            const focussedCharacterMessages = this.props.messages[this.state.highlight.characterId].messages
            const isLastMessageFocussed = this.state.highlight.messageIndex === focussedCharacterMessages.length;

            if (!isMessageOptions && isLastMessageFocussed) {
                const textareaElement = document.getElementsByClassName('new-message')[0]
                textareaElement?.focus()
            }
        }
    }

    handleNavigation(event) {
        if (!this.state.highlight.characterId) {
            return;
        }

        if (event.key === 'ArrowUp') {
            this.handleUpArrow();
        }

        if (event.key === 'ArrowDown') {
            this.handleDownArrow();
        }

        if (event.key === 'ArrowLeft') {
            this.handleLeftArrow();
        }

        if (event.key === 'ArrowRight') {
            this.handleRightArrow();
        }

        if (event.key === ' ' || event.key === 'Enter') {
            this.handleOptionSelection();
        }

        setTimeout(() => {
            this.correctScrollPosition();
            this.focusTextareaOnNavigation();
        })
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.highlight) {
            // TODO - handle case where focussed character is not yet got a messages array...
            const focussedCharacterId = props.focussedCharacterId || Object.keys(props.messages)[0]
            const messageOptionsCount = props.messageOptions?.length > 0 ? props.messageOptions?.length - 1 : 0;

            return {
                ...state,
                highlight: {
                    type: 'messages',
                    characterId: focussedCharacterId,
                    messageIndex: props.messages[focussedCharacterId]?.messages?.length + messageOptionsCount,
                    isEmptyFocussedCharacterShowing: !!props.messages[focussedCharacterId]
                }
            }
        }

        return state;
    }

    formatDistance(result, number) {
        result = result.replace('Minutes', 'm');
        result = result.replace('Hours', 'h');
        result = result.replace('Days', 'd');
        result = result.replace('Months', 'mo');
        result = result.replace('Years', 'y');

        result = result.replace('lessThan', '<')
        result = result.replace('about', '');
        result = result.replace('over', '>');

        result = result.replace('x', number)
        result = result.replace('X', number)

        return result;
    }

    render() {
        const characterIds = Object.keys(this.props.messages)

        const selectedCharacter = this.props.messages[this.state.highlight.characterId] || this.props.messages[characterIds[0]] || [];

        const characters = characterIds.map((characterId, index) => {
            const isHighlighted = this.state.highlight?.type === 'characters' && this.state.highlight?.characterId === characterId;
            const isSelected = characterId === this.state.highlight.characterId;

            let className = 'character';

            if (isHighlighted) {
                className += ' highlighted';
            }

            if (isSelected) {
                className += ' selected';
            }

            const character = this.props.characters?.find(character => (character._id === characterId));
            return (
                <div className={className} key={index} >
                    <div className="character-face"></div>
                    <span className="name">{character?.name || this.props.panel?.characterPanel?.locationName || 'Unknown Location' }</span>
                </div>
            )
        })

        const messages = selectedCharacter?.messages && selectedCharacter?.messages
            .map((message, index) => {
                const isHighlighted = this.state.highlight?.type === 'messages' && this.state.highlight?.messageIndex === index;
                const characterId = message.characterId || message.embarkCharacterId;
                const isReader = this.props.character._id === characterId;

                let classNames = "";

                if (isHighlighted) {
                    classNames += " highlighted"
                }

                if (isReader) {
                    classNames += " reader-message"
                } else {
                    classNames += " non-reader-message"
                }

                return (
                    <li key={index} className={classNames}>
                        <span className={characterId ? "message-date" : "hidden-message-part"}>
                            &nbsp;{formatDistance(new Date(message.createdAt), new Date(), { locale: { formatDistance: this.formatDistance.bind(this) } })}&nbsp;
                        </span>
                        <div className={characterId ? "message-text" : "system-message-text"}>
                            <span className={characterId && (characterId !== this.props.character?._id) && (characterId !== selectedCharacter.character?._id) ? "character-name" : "hidden-message-part"}
                                style={{color: message.character?.color || '#D6F5FF'}}>
                                {message.character?.name || 'Unknown Character'}
                            </span>
                            {message.text}
                            <span className={characterId ? "hidden-message-part" : "message-date"}>
                                &nbsp;·&nbsp;{formatDistance(new Date(message.createdAt), new Date(), { locale: { formatDistance: this.formatDistance.bind(this) } })}&nbsp;
                            </span>
                        </div>
                    </li>
                )
            })

        let textareaClassName = 'new-message';

        if (this.state.highlight?.type === 'messages' && this.state.highlight?.messageIndex === selectedCharacter?.messages?.length) {
            textareaClassName += ' highlighted'
        }

        const messageOptions = this.props.messageOptions.map((messageOption, index) => {
            let classNames = "reader-message";

            if (this.state.highlight.type === 'messages' && this.state.highlight.messageIndex - selectedCharacter.messages.length === index) {
                classNames += " highlighted"
            } else {
                classNames += " faded"
            }

            return (
                <li key={index} className={classNames}>
                    <div className="message-text" onClick={() => (this.handleOptionSelection(messageOption))}>
                        {messageOption.text}
                    </div>
                </li>
            )
        })

        return (
            <div>
                <div className="messages">
                    <ul className="characters-list">
                        {characters}
                    </ul>
                    <ul className="messages-list">
                        {messages}

                        <div className="new-message-container">
                            <span
                                className="speak link"
                                onClick={this.submitMessage}
                                style={{display: this.state.text ? 'block' : 'none'}}
                            >Speak</span>

                            {
                                this.props.messageOptions?.length > 0 ? 
                                    messageOptions :
                                    <textarea
                                        value={this.state.text}
                                        className={textareaClassName}
                                        onChange={this.handleInput}
                                        placeholder="Say something"
                                    />
                            }
                        </div>
                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    let isEmbarkMessages = false;

    const panel = selectPanel(state);
    const messages = selectCharacterMessages(state)
    const embarkMessages = selectEmbarkCharacterMessages(state);

    if (Object.entries(messages).length === 0) {
        isEmbarkMessages = true;
    }

    const character = selectCharacter(state)
    const embarkCharacter = selectEmbarkCharacter(state);


    const characters = selectCharacters(state);
    const embarkCharacters = embarkCharacter && selectCurrentEmbarkGroup(state, embarkCharacter?._id)?.embarkCharacters

    const focussedCharacterId = selectFocussedCharacterId(state);
    const messageOptions = selectMessageOptionss(state);

    return {
        panel,
        messages: isEmbarkMessages ? embarkMessages : messages,
        character: isEmbarkMessages ? embarkCharacter : character,
        characters: isEmbarkMessages ? embarkCharacters : characters,
        focussedCharacterId,
        messageOptions
    }
}

export default connect(
    mapStateToProps,
    { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, flashCharacterColorAsync, startLooking, createNewMessage }
)(Messages);