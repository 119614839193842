import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_WEAPONS_SUCCESS, ADD_WEAPON, UPDATE_WEAPON, REMOVE_WEAPON, GET_TRADE_WEAPONS_SUCCESS, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  characterWeapons: [],
  tileWeapons: [],
  tradeWeapons: [],
  weapons: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_WEAPONS_SUCCESS: {
      const weapons = action.payload;

      return {
        ...state,
        weapons: [...weapons.filter(weaponInstance => weaponInstance.panelId)],
        characterWeapons: [...state.characterWeapons, ...weapons.filter(weaponInstance => weaponInstance.characterId)],
        tileWeapons: [...weapons.filter(weaponInstance => weaponInstance.tileId)],
      }
    }

    case GET_TRADE_WEAPONS_SUCCESS: {
      const weapons = action.payload;

      return {
        ...state,
        tradeWeapons: [...weapons],
      }
    }

    case ADD_WEAPON: {
      let characterWeapons = [...state.characterWeapons];
      let tileWeapons = [...state.tileWeapons];
      let weapons = [...state.weapons];

      if (action.payload.characterId) {
        characterWeapons = [ ...characterWeapons, action.payload ]
      }

      if (action.payload.tileId) {
        tileWeapons = [ ...tileWeapons, action.payload ]
      }

      if (action.payload.panelId) {
        weapons = [ ...weapons, action.payload ]
      }

      return {
        ...state,
        characterWeapons: [ ...characterWeapons ],
        tileWeapons: [ ...tileWeapons ],
        weapons: [ ...weapons ],
      }
    }

    case UPDATE_WEAPON: {
      let characterWeapons = [...state.characterWeapons];
      let tileWeapons = [...state.tileWeapons];
      let weapons = [...state.weapons];

      if (action.payload.characterId) {
        characterWeapons = [
          ...characterWeapons.filter(weapon => weapon._id !== action.payload._id),
          { ...characterWeapons.find(weapon => weapon._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.tileId) {
        tileWeapons = [
          ...tileWeapons.filter(weapon => weapon._id !== action.payload._id),
          { ...tileWeapons.find(weapon => weapon._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.panelId) {
        weapons = [
          ...weapons.filter(weapon => weapon._id !== action.payload._id),
          { ...weapons.find(weapon => weapon._id === action.payload._id), ...action.payload }
        ]
      }

      return {
        ...state,
        characterWeapons: [ ...characterWeapons ],
        tileWeapons: [ ...tileWeapons ],
        weapons: [ ...weapons ],
      }
    }

    case REMOVE_WEAPON: {
      return {
        ...state,
        characterWeapons: [ ...state.characterWeapons.filter(weapon => (weapon._id !== action.payload._id)) ],
        tileWeapons: [ ...state.tileWeapons.filter(weapon => (weapon._id !== action.payload._id)) ],
        weapons: [ ...state.weapons.filter(weapon => (weapon._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
