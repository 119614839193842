import {
    GET_CHARACTERS_LOADING,
    GET_CHARACTERS_SUCCESS,
    GET_CHARACTERS_FAILURE,
    ENABLE_CHARACTER_COLOR,
    DISABLE_CHARACTER_COLOR
} from '../actionTypes'
import store from '../store';

import { client } from '../../services/client';
import { showUnknownError } from './keyboard-shortcuts.actions';

export const getCharactersSuccess = payload => ({
    type: GET_CHARACTERS_SUCCESS,
    payload
})

export const getCharactersFailure = () => ({
    type: GET_CHARACTERS_FAILURE
})

export const getCharacterEquippedItemsAsync = payload => async dispatch => {
    const character = { ...payload };

    if (character.clothingItems) {
        character.clothingItems = await Promise.all(character.clothingItems?.map(async item => {
            if (item.itemType === 'clothing') {
                const clothing = await client.service('clothing-instances').get(item.itemId);
                return { ...item, ...clothing };
            } else {
                const jewellery = await client.service('jewellery-instances').get(item.itemId);
                return { ...item, ...jewellery };
            }
        }))
    }


    if (character.weaponId) {
        const weapon = await client.service('weapon-instances').get(character.weaponId);

        character.weapon = {
            ...character.weapon,
            ...weapon
        }
    }

    if (character.armourId) {
        const armour = await client.service('armour-instances').get(character.armourId);

        character.armour = {
            ...character.armour,
            ...armour
        }
    }

    return character;
}

export const getCharactersAsync = payload => dispatch => {
    return client.service('characters').find({ paginate: false, query: { panelId: payload.panelId } })
        .then((response) => {
            dispatch(getCharactersSuccess(response))
            console.log('characters: ', response.filter(character => (!!character.clothingItems)))
            return response;
        })
        .catch((error) => {
            console.log('failing to get characters', error);
            dispatch(showUnknownError())
            throw new Error(error);
        });
}

export const flashCharacterColorAsync = payload => dispatch => {
    dispatch(enableCharacterColor(payload))

    setTimeout(() => (dispatch(disableCharacterColor(payload))), 2000)
}

export const enableCharacterColor = payload => ({
    type: ENABLE_CHARACTER_COLOR,
    payload
})

export const disableCharacterColor = payload => ({
    type: DISABLE_CHARACTER_COLOR,
    payload
})

export function addClothingTypes(clothingItem, clothingTypes, jewelleryTypes) {
    return {
        ...clothingItem,
        type: (
            clothingItem.itemType === 'clothing' ?
            clothingTypes.find(item => item._id === clothingItem.itemTypeId)
            : jewelleryTypes.find(item => item._id === clothingItem.itemTypeId)
        )
    }
}