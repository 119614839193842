export const puzzleboxScrubSmellTypes = [
    "of bergamot",
    "of black pepper",
    "of cardamom and mandarin",
    "of clove",
    "of ginger",
    "of jasmine",
    "of lemongrass",
    "of rosemary",
    "of eucalyptus",
    "of jasmine",
    "of juniper berry",
    "of Orris",
    "of tansy",
    "of chamomile",
    "Woody",
    "Aromatic",
    "Earthy",
    "Spicy",
    "Sweet",
    "Floral",
    "of borage",
    "of peppercorn",
    "of cardamom",
    "of champaca",
    "blossomy",
    "of drink-soaked floorboards",
    "elegant",
    "zesty",
    "of an armful of blossoms",
    "of honeysuckle",
    "of mock orange",
    "of poppy",
    "of agrarian plunder",
    "of freshly foraged undergrowth",
    "fecund",
    "of scrubbed skin",
    "of the distant shoreline",
    "of charred track",
    "of a smoking wreak",
    "of pork",
    "of easy abandon",
    "of the happily shaded world",
    "aggravating",
    "convincing",
    "of contorted memories",
    "electromagnetic",
    "of shredded pomegranate",
    "of parasitic hunger",
    "of woody residues",
    "and idiosyncratically of fish",
    "of cerebral entanglement",
]

export const puzzleboxScrubBranchTypes = [
	"bunched moss growing on its",
    "bushy",
    "perplexing",
    "leathery",
    "scaly",
    "deeply veined",
    "dry",
    "tissue-paper thin",
    "dusty",
    "burly",
    "knobby",
    "bushy",
    "dense",
    "prickly",
    "leathery",
    "scaly",
    "odd",
    "sere",
    "Thorny",
    "Spiky",
    "vines draped on its",
    "thorn-lanced",
    "Jagged",
    "Spindly",
    "Scrubby",
    "Weedy",
    "Bony",
    "Knobby",
    "Flaky",
    "Desiccated",
    "Splintery",
    "Bristly",
    "sap seeping from its",
    "confounding",
    "canescent",
    "disorderly",
    "mesmerising",
    "desperate",
    "impossible",
    "illogical",
    "blight-marred",
    "probing",
    "indifferent",
    "fluffy nodes on its",
    "tough, scaled",
    "taut",
    "stressed",
    "insect-rife",
    "seed cones hanging from its",
    "dangerous",
    "overwhelming",
    "contemptuous",
    "nightmare",
    "claustrophobic",
    "binding",
    "hard-hearted",
    "giddy",
    "complicated",
]

export const puzzleboxScrubLeafTypes = [
	"bristly",
    "hidden",
    "curly",
    "lacy",
    "hairy",
    "messy",
    "prickly",
    "scratchy",
    "edged",
    "parasol",
    "umbrella",
    "strangely-arranged",
    "rot-prone",
    "serrated",
    "sharp-edged",
    "sharp-tipped",
    "long-lived",
    "lacerating",
    "fresh",
    "determined",
    "shining",
    "mocking",
    "rough",
    "terrible",
    "searing",
    "lonely",
    "droning",
    "unwelcoming",
    "fur-snatching",
    "small insects clung to its",
    "dagger-point",
    "abrasive",
    "piercing",
    "upsetting",
    "desultory",
    "ruinous",
    "destructive",
    "derelict",
    "emerald",
]