import {
    GET_ANIMALS_SUCCESS,
    GET_ANIMALS_FAILURE,
    HARVEST_ANIMAL_SUCCESS,
    HARVEST_ANIMAL_FAILURE,
    ADD_ANIMAL,
    UPDATE_ANIMAL,
    REMOVE_ANIMAL,
    BEGIN_UPDATE_ANIMAL,
    UPDATE_ANIMAL_FAILED,
} from '../actionTypes';
import { clearAllTiles, loadTilesSuccess } from './tile.actions';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';

import { colyClient } from '../../services/Panel-initialisation.service';
import { client } from '../../services/client';

export const BehaviourTypes = {
    FOLLOWING: 'FOLLOWING',
    WAITING: 'WAITING',
    CARRYING: 'CARRYING'
}

const TargetType = {
    CHARACTER: 'CHARACTER'
}

export const getAnimalsSuccess = payload => {
    return ({
        type: GET_ANIMALS_SUCCESS,
        payload
    })
}

export const getAnimalsFailure = payload => ({
    type: GET_ANIMALS_FAILURE
})

export const getAnimalsAsync = payload => dispatch => {
    return client.service('animal-instances').find({ paginate: false, query: { panelId: payload.panelId, z: payload.z } })
        .then((response) => {
            response = response.map(animal => ({ ...animal, animal: payload.animalTypes.find(type => type._id === animal.animalTypeId) }))
            dispatch(getAnimalsSuccess(response));
        })
        .catch((error) => {
            console.error('error', error);
            dispatch(showUnknownError({ message: error.message }));
            throw new Error(error);
        })
}

export const harvestAnimalSuccess = payload => ({
    type: HARVEST_ANIMAL_SUCCESS,
    payload
})

export const harvestAnimalFailure = () => ({
    type: HARVEST_ANIMAL_FAILURE
})

export const harvestAnimalAsync = payload => dispatch => {
    const { id, quantity } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'animal-instances',
        commandType: 'patch',
        data: {
            id,
            quantity
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addAnimal = payload => {
    return ({
        type: ADD_ANIMAL,
        payload
    })
}

export const updateAnimal = payload => {
    return ({
        type: UPDATE_ANIMAL,
        payload
    })
}

export const removeAnimal = payload => {
    return ({
        type: REMOVE_ANIMAL,
        payload
    })
}

export const bridleAnimalAsync = payload => dispatch => {
    const { id, characterId } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'animal-instances',
        commandType: 'patch',
        data: {
            id,
            currentBehaviour: BehaviourTypes.FOLLOWING,
            targetId: characterId,
            targetType: TargetType.CHARACTER
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const unbridleAnimalAsync = payload => dispatch => {
    const { id, characterId } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'animal-instances',
        commandType: 'patch',
        data: {
            id,
            currentBehaviour: BehaviourTypes.WAITING,
            targetId: '',
            targetType: ''
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const saddleAnimalAsync = payload => dispatch => {
    const { id, characterId } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'animal-instances',
        commandType: 'patch',
        data: {
            id,
            currentBehaviour: BehaviourTypes.FOLLOWING,
            targetId: characterId,
            targetType: TargetType.CHARACTER
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const unsaddleAnimalAsync = payload => dispatch => {
    const { id, characterId } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'animal-instances',
        commandType: 'patch',
        data: {
            id,
            currentBehaviour: BehaviourTypes.WAITING,
            targetId: '',
            targetType: ''
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const reinAnimalAsync = payload => dispatch => {
    const { id, characterId, wagonId } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'animal-instances',
        commandType: 'patch',
        data: {
            id,
            currentBehaviour: BehaviourTypes.CARRYING,
            targetId: characterId,
            targetType: TargetType.CHARACTER,
            wagonId
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const unreinAnimalAsync = payload => dispatch => {
    const { id, characterId } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'animal-instances',
        commandType: 'patch',
        data: {
            id,
            currentBehaviour: BehaviourTypes.WAITING,
            targetId: '',
            targetType: '',
            wagonId: ''
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const beginUpdateAnimal = payload => {
    return ({
        type: BEGIN_UPDATE_ANIMAL,
        payload
    })
}

export const updateAnimalFailed = payload => {
    return ({
        type: UPDATE_ANIMAL_FAILED,
        payload
    })
}