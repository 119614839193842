export const blackbearBerrySmellTypes = [
    "of Citrus",
    "of Jasmine",
    "of Musk",
    "of Orange",
    "of Vanilla",
    "of cedar",
    "coastal",
    "coniferous",
    "fruity",
    "grassy",
    "leathery",
    "musky",
    "of oakmoss",
    "of jasmine",
    "of leather",
    "of musk",
    "of oakmoss",
    "of vetiver",
    "of Lavender",
    "Floral",
    "of Gardenia",
    "of Mint",
    "musky",
    "of Vanilla",
    "of immature fruit",
    "of playful florals",
    "of distilled sloes",
    "of crisp, washed fruits",
    "disinfectant and vanilla",
    "tart and creamy",
    "soap and sandalwood",
    "articial fruits",
    "headache-inducing",
    "of berry sweets and leather",
    "tricky and playful",
    "of tree sap",
    "warm and peppery",
    "of coy summer fruits",
    "of bloooms in the forest",
    "of alcohol and vanilla",
    "tart with a soft orchid scent",
    "of fruit and freesia",
    "of dark fruit and patchouli",
    "of passed gas",
    "of pomegranate and missed opportunities",
    "of old spun silk",
    "like apricots in a tannery",
    "of rotten egg",
    "of rotten wormwood",
]

export const blackbearBerryBranchTypes = [
    "curly",
    "grassy",
    "hairy",
    "leafy",
    "long",
    "prickly",
    "slender",
    "stringy",
    "thin",
    "smooth",
    "brittle",
    "green",
    "sharp",
    "fragile",
    "procumbent",
    "prostrate",
    "hardy, ground laying",
    "useless-looking limp",
    "straggling",
    "woody",
    "spreading",
    "short, scraggly",
    "crimson",
    "russet",
    "ochre",
    "trampled",
    "tiny dark fruits adorning its",
    "a crown of black fruits atop its",
    "glossy fruits clinging to its",
    "succulent fruits hidden in its",
    "ground laying",
    "prone",
    "thick",
    "fruit darlings 'neath its"
]

export const blackbearBerryLeafTypes = [
    "sanguine",
    "blood-coloured",
    "scarlet",
    "reddish green",
    "urn-shaped",
    "stalked",
    "oval",
    "alternate",
    "withered little",
    "buried",
    "carbon",
    "crisp",
    "curling",
    "dark",
    "murky"
    "robust",
    "healthy",
    "sturdy",
    "sun-striving",
    "sun pursuant",
    "banner-like",
    "rust-born",
]