export const cushionPlantSmellTypes = [
    "of raspberry",
    "of currant",
    "of gooseberry",
    "of elderberry",
    "of boysenberry",
    "of loganberry",
    "minty",
    "sweet",
    "flowery",
    "earthy",
    "musky",
    "of fig",
    "of quince",
    "of purple onion",
    "of allium ",
    "of tomato",
    "of lemon",
    "sweet",
    "nutty",
    "woody",
    "piney",
    "earthy",
    "herbaceous",
    "sheer and velvety",
    "of dry down",
    "fruity and sparkly",
    "oudy",
    "of tangelo",
    "heavenly soft",
    "of damp wood",
    "weird",
    "tangerine",
    "musky with a shy sugar",
    "cold and unapproachable",
    "of woody amber",
    "of bitter wood",
    "effervescent yet hefty",
    "golden with a hint of sandalwood",
    "of a luxurious musk",
    "of a fresh shave",
    "of salty ambergris",
    "like citrus topped with black pepper",
    "inoffensive and sweet",
    "tangy and soft",
    "of generic fruit",
    "innocent",
    "fresh",
    "calming and sultry",
    "soft with a shimmer of heat",
    "of unsophisticated burnt sugar",
    "of a ritzy drink",
    "nauseatingly of moist baby",
    "of alluring burnt caramel",
    "dreamy",
    "bright",
    "of hidden health concerns",
    "of cancerous growth",
    "of hardened bread",
]

export const cushionPlantBranchTypes = [
	"bright green",
    "a clump of"
    "verdure",
    "feathery",
    "Glistening",
    "frozen",
    "delicate",
    "brittle",
    "sharp",
    "clear",
    "frosted",
    "small gleaming",
    "pure",
    "light green",
    "a loose clump of",
    "tufted",
    "mucilage-secreting",
    "small fee",
    "fairy floss",
    "felty",
    "damp",
    "sticky",
    "puddle-bedded",
    "humble",
    "prostrate",
    "trampled",
    "paw-trod",
    "water-sieving",
    "soft and appealing",
    "comfortable",
    "pillowy",
    "stout",
    "a basket of",
    "woven",
    "simple",
]

export const cushionPlantLeafTypes = [
	"small",
    "shoots of",
    "shoots of orange",
    "creamy shoots of"
    "small, glossy",
    "a yellow awn atop its",
    "stubby",
    "thin",
    "hair-like",
    "delicate",
    "serrated",
    "a wisp of",
    "hyaline",
    "impossible",
    "tiny, creeping",
    "barely discernable",
    "miniscule",
    "finger-like",
    "tiny shoot",
    "teeth-like",
    "dental",
]