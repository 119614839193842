export const paintreeMonkeyFaceSentences = [
"big, green eyes",
"long, furry ears",
"cute, little nose",
"soft, furry cheeks",
"gentle, furry chin",
"it has long lashes",
"it has cunning eyes",
"it has protruding teeth",
"it has furry eyebrows",
"it has large nose",
"it has small ears",
"Staring  eyes, surrounded by greasy fur",
"Long, protruding  nose, next to small  mouth",
"Big  ears on top of a hairy head",
"A curious face stares back at you, its green face contorting into a mischievous grin",
"A young face looks up at you with big, bright eyes, its face a cheerful green.",
"A face with a mischievous grin.",
"A face surrounded by a mass of curly, green hair.",
"A face with large, expressive eyes.",

]

export const paintreeMonkeyBodySentences = [
"its body is covered in soft green fur",
"it has a long tail that is also green",
"its eyes are a bright green",
"it has green skin underneath its fur",
"it is small and slim",
"its body is covered in a thin layer of green fur",
"it has a long tail that is also green",
"its face is expressive and curious",
"its eyes are a deep green",
"its body is covered in green fur",
"it has a long tail that is used for balance",
"it has two arms and two legs",
"its hands and feet are equipped with opposable thumbs",
"Fat body that hangs from the trees, eating leaves all day",
"His skin is a bright, vibrant green",
"He has long, lanky arms and legs",
"His fur is thick and luxurious",
"He has a long tail that is covered in fur",
"His face is expressive and charming",
]

export const paintreeMonkeySoundWords = [
    "Chattering",
    "screeching",
    "howling",
    "yelping",
    "gibbering",
    "prattling",
    "chirping",
    "bleating",
    "mewling",
    "cooing",
    "Singing",
    "chirping",
    "whistling",
    "howling",
    "laughing",
    "yelping",
    "barking",
    "grunting",
    "moaning",
    "growling",
    "Hooting",
    "howling",
    "screeching",
    "yelping",
    "yammering",
    "chattering",
    "chittering",
    "clucking",
    "cackling",
    "cooing",
    "Hooting",
    "howling",
    "screeching",
    "chattering",
    "yelping",
    "yipping",
    "cackling",
    "crowing",
    "laughing",
    "jeering"
]

export const paintreeMonkeyFurWords = [
    "Bright",
    "Vibrant",
    "Rich",
    "Luscious",
    "Velvety",
    "Supple",
    "Soft",
    "radiant",
    "lustrous",
    "healthy",
    "Apathetic",
    "Cold",
    "Dead",
    "Dry",
    "Harsh",
    "Hot",
    "Humid",
    "Itchy",
    "Moldy",
    "Rough",
    "Slick",
    "Velvety",
    "Supple",
    "Fine",
    "Thick",
    "Soft",
    "Rough",
    "Oily",
    "Dry",
    "itchy",
    "glossy",
    "smooth",
    "slippery",
    "soft",
    "fine",
    "thick",
    "lush",
    "curly",
    "wavy",
    "course",
    "Slimy",
    "gross",
    "bumpy",
    "itchy",
    "dry",
    "scaly",
    "hard",
    "crusty",
    "soft",
    "furry"
]

export const paintreeMonkeySmellWords = [
    "sour", "acrid", "rank", "fetid", "stinking", "reeking", "putrid", "malodorous", "foul", "atrocious",
]

export const paintreeMonkeyBehaviourSentences = [
    "it is staring at me",
    "it is making weird faces",
    "it is swinging its arms around",
    "it is running in place",
    "it is scratching itself",
    "it is picking its nose",
    "it is staring at me",
    "it is glaring at me.",
    "it is making strange noises.",
    "it is picking at Its fur.",
    "it is scratching Itsself.",
]