export const camelFaceSentences = [
    "A big, brown nose that seems to smell everything.",
"Two big, brown eyes that are always on the lookout.",
"Two furry, brown ears that are always alert.",
"A big, furry, brown body that is endlessly humpin'.",
"A large, protruding nose.",
"Two big, black eyes.",
"Long, curved eyelashes.",
"Thick, furry eyebrows.",
"A wide, open mouth.",
"Two big, furry ears.",
"A long, thick neck.",
"Its lips are dry and leathery."
]

export const camelBodySentences = [
    "it's body is long and skinny",
"It has a long neck and a hump on its back",
"Its massive frame is draped in a thick coat of shaggy fur",
"Hump on the backs",
"Tough skin",
"Bony legs",
"Swaying walk",
"Loud grunt",
]

export const camelSoundWords = [
    "grunting",
    "groaning",
    "moaning",
    "snorting",
    "wheezing",
    "gasping",
    "heavy breathing",
    "rasping",
    "coughing",
    "spitting",
    "Hissing",
    "spitting",
    "snorting",
    "bellowing",
    "bleating",
    "howling",
    "groaning",
    "moaning",
    "roaring",
    "yelling",
    "grunt",
    "groan",
    "bellow",
    "moan",
    "mumble",
    "grumble",
    "chomp",
    "chew",
    "slurp",
    "smacking",
    "groaning",
    "moaning",
    "rumbling",
    "grumbling",
    "bellowing",
    "blaring",
    "growling",
    "howling",
    "ailing",
    "bellowing",
    "booming",
    "braying",
    "coughing",
    "grinding",
    "groaning",
    "gruntling",
    "wheezing",
    "snorting"
]

export const camelFurWords = [
    "hairy",
"rough",
"scaly",
"peeling",
"itchy",
"dry",
"brittle",
"dusty",
"smelly",
"caked in mud",
"Hairy",
"Coarse",
"Dry",
"Rugged",
"Leathery",
"Strong",
"Smooth",
"Oily",
"Lusterless",
"mat",
"Hairy",
"Coarse",
"Smelly",
"Scratchy",
"Oily",
"Dirty",
"Greasy",
"Stiff",
"Dry",
"Bumpy",
"downy",
"silky",
"soft",
"durable",
"bristly",
"thick",
"light",
"dark"
]

export const camelSmellWords = [
    "Hazy",
    "Dusty",
    "Dry",
    "Sandy",
    "Hot",
    "Humid",
    "Arid",
    "Bleak",
    "Desolate",
    "Lonely",
    "Salty",
    "dusky",
    "earthy",
    "musky",
    "humid",
    "animalic",
    "gamey",
    "sulfurous",
    "leathery",
    "rancid",
    "foul",
    "rank",
    "stinking",
    "nauseating",
    "putrid",
    "reeking",
    "disgusting",
    "rank",
    "skanky",
    "gross",
    "dry",
    "earthy",
    "slightly sweet",
    "musky",
    "Salty",
    "dung-scented",
    "rank",
    "gamey",
    "strong",
    "earthy",
    "metallic",
    "musky",
    "acrid",
    "pungent"
]

export const camelBehaviourSentences = [
"it is staring at you with its beady eyes",
"it is making a strange noise",
"it is limping",
"it is rolling around in the dirt",
"it is staring at you",
"it is making threatening gestures",
"it is glaring at you",
"it is baring its teeth",
"it is making a low, guttural noise",
"it is pacing back and forth",
"it is making strange noises",
"it is standing very still",
"it is gazing at you intently",
"it is drooling",
"it is waving its head around",
"it is fixated on a spot on the ground",
"it is making loud and strange noises",
"it is constantly moving its head from side to side",
]