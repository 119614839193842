import React from "react";
import { connect } from 'react-redux'

import { selectMetalRecipes } from '../../../redux/selectors';

import { client } from '../../../services/client';

class MetalRecipes extends React.Component {

    render() {
        const listItems = this.props.metalRecipes
            .filter(metalRecipe => (metalRecipe.metalType?.name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase()) > -1))
            .sort((a, b) => (a.metalType?.name.localeCompare(b.metalType?.name)))
            .map((metalRecipe) => {
                return (
                    <li key={metalRecipe._id}
                        onClick={() => {
                            return (this.props.onItemSelect(metalRecipe))
                        }}>
                        <span className={this.props.selectedItem?._id === metalRecipe._id ? "selected": ""}>{metalRecipe.metalType.name}</span>
                    </li>
                );
            })
        return (
            <ul>{listItems}</ul>
        )
    }
}

const mapStateToProps = state => {
    const metalRecipes = selectMetalRecipes(state)

    return { metalRecipes }
}

export default connect(
    mapStateToProps,
    { }
)(MetalRecipes);