import {
    CREATE_WAGON_LOADING,
    CREATE_WAGON_SUCCESS,
    CREATE_WAGON_FAILURE,
    GET_WAGONS_LOADING,
    GET_WAGONS_SUCCESS,
    GET_WAGONS_FAILURE,
    ADD_WAGON,
    UPDATE_WAGON,
    REMOVE_WAGON,
    BEGIN_UPDATE_WAGON,
    UPDATE_WAGON_FAILED,
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

import { moveItemFailure } from './move-item.actions';

export const createWagonSuccess = payload => ({
    type: CREATE_WAGON_SUCCESS,
    payload
})

export const createWagonFailure = () => ({
    type: CREATE_WAGON_FAILURE
})

export const createWagonAsync = payload => dispatch => {
    const { _id, wagonTypeId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'wagon-instances',
        name: 'create',
        data: {
            wagonTypeId
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getWagonsSuccess = payload => {
    return ({
        type: GET_WAGONS_SUCCESS,
        payload
    })
}

export const getWagonsFailure = () => ({
    type: GET_WAGONS_FAILURE
})

export const getWagonsAsync = payload => dispatch => {
    return client.service('wagon-instances').find({ query: { panelId: payload.panelId, z: payload.z } })
        .then((response) => {
            response = response.map(wagon => ({ ...wagon, wagonType: payload.wagonTypes.find(type => type._id === wagon.wagonTypeId) }))
            dispatch(getWagonsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const addWagonToTileAsync = payload => dispatch => {
    const { wagonInstance, tileId, tileTypeId } = payload;
    const { wagonTypeId, quantity, recipeId, characterId, _id } = wagonInstance;

    colyClient.room.send('doAction', {
        serviceName: 'wagon-instances',
        commandType: 'create',
        data: {
            wagonTypeId,
            quantity,
            tileId,
            tileTypeId,
            panelId: "",
            position: {},
            characterId: ""
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addWagonToCharacterAsync = payload => dispatch => {
    const { wagonInstance, characterId } = payload;
    const { createdAt, wagonTypeId, quantity, recipeId, _id } = wagonInstance;

    colyClient.room.send('doAction', {
        serviceName: 'wagon-instances',
        commandType: 'create',
        data: {
            wagonTypeId,
            quantity,
            tileId: "",
            characterId
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addWagon = payload => {
    return ({
        type: ADD_WAGON,
        payload
    })
}

export const updateWagon = payload => {
    return ({
        type: UPDATE_WAGON,
        payload
    })
}

export const removeWagon = payload => {
    return ({
        type: REMOVE_WAGON,
        payload
    })
}

export const beginUpdateWagon = payload => {
    return ({
        type: BEGIN_UPDATE_WAGON,
        payload
    })
}

export const updateWagonFailed = payload => {
    return ({
        type: UPDATE_WAGON_FAILED,
        payload
    })
}
