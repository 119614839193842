import {
    CREATE_JEWELLERY_LOADING,
    CREATE_JEWELLERY_SUCCESS,
    CREATE_JEWELLERY_FAILURE,
    GET_JEWELLERY_LOADING,
    GET_JEWELLERY_SUCCESS,
    GET_JEWELLERY_FAILURE,
    ADD_JEWELLERY,
    UPDATE_JEWELLERY,
    REMOVE_JEWELLERY,
    GET_TRADE_JEWELLERY_SUCCESS,
    GET_TRADE_JEWELLERY_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

import { moveItemFailure } from './move-item.actions';

export const createJewellerySuccess = payload => ({
    type: CREATE_JEWELLERY_SUCCESS,
    payload
})

export const createJewelleryFailure = () => ({
    type: CREATE_JEWELLERY_FAILURE
})

export const createJewelleryAsync = payload => dispatch => {
    const { _id, jewelleryTypeId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'jewellery-instances',
        name: 'create',
        data: {
            jewelleryTypeId
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getJewellerySuccess = payload => {
    return ({
        type: GET_JEWELLERY_SUCCESS,
        payload
    })
}

export const getJewelleryFailure = () => ({
    type: GET_JEWELLERY_FAILURE
})

export const getJewelleryAsync = payload => dispatch => {
    let query = { $or: [{ panelId: payload.panelId, z: payload.z}, { characterId: payload.characterId}] }

    if (payload.isNotFirstInitialisation) {
        query = { panelId: payload.panelId, z: payload.z}
    }

    return client.service('jewellery-instances').find({ query })
        .then((response) => {
            response = response.map(jewellery => ({ ...jewellery, jewelleryType: payload.jewelleryTypes.find(type => type._id === jewellery.jewelleryTypeId) }))
            dispatch(getJewellerySuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const getTradeJewellerySuccess = payload => {
    return ({
        type: GET_TRADE_JEWELLERY_SUCCESS,
        payload
    })
}

export const getTradeJewelleryFailure = () => ({
    type: GET_TRADE_JEWELLERY_FAILURE
})

export const getTradeJewelleryAsync = payload => dispatch => {
    return client.service('jewellery-instances').find({ query: { characterId: payload.characterId, markedForTradeCount: { $gte: 1 } }})
        .then((response) => {
            response = response.map(jewellery => ({ ...jewellery, jewelleryType: payload.jewelleryTypes.find(type => type._id === jewellery.jewelleryTypeId) }))
            dispatch(getTradeJewellerySuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const addJewelleryToTileAsync = payload => dispatch => {
    const { jewelleryInstance, tileId, tileTypeId } = payload;
    const { jewelleryTypeId, quantity, recipeId, characterId, _id } = jewelleryInstance;

    colyClient.room.send('doAction', {
        serviceName: 'jewellery-instances',
        commandType: 'create',
        data: {
            jewelleryTypeId,
            quantity,
            tileId,
            tileTypeId,
            panelId: "",
            position: {},
            characterId: ""
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addJewelleryToCharacterAsync = payload => dispatch => {
    const { jewelleryInstances, characterId } = payload;
    const { createdAt, jewelleryTypeId, quantity, recipeId, _id } = jewelleryInstances[0];

    colyClient.room.send('doSuperAction', {
        serviceName: 'jewellery-instances',
        name: 'fetch',
        data: {
            jewelleryTypeId,
            quantity,
            tileId: "",
            characterId,
            instanceIds: jewelleryInstances.map(inst => inst._id)
        },
        params: {
            query: {
                movementFrom: {
                    _id: undefined
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addJewellery = payload => {
    return ({
        type: ADD_JEWELLERY,
        payload
    })
}

export const updateJewellery = payload => {
    return ({
        type: UPDATE_JEWELLERY,
        payload
    })
}

export const removeJewellery = payload => {
    return ({
        type: REMOVE_JEWELLERY,
        payload
    })
}
