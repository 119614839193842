import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import { disableKeyboardMovement, enableKeyboardMovement, showHelp, startLooking, hideAllMenus } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectPanel,
    selectCharacterMessages,
    selectEmbarkCharacterMessages,
    selectTotalMessages,
    selectCharacter,
    selectCharacters,
    selectIsMessagesFocussed,
    selectFocussedCharacterId,
    selectMessageOptionss,
    selectEmbarkCharacter,
    selectCurrentEmbarkGroup,
    selectCharacterById
} from '../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';

import { client } from '../../services/client';

import './LevelUp.css';

import Menu from '../utils/menu/Menu';
import TextInput from '../utils/text-input/TextInput';

// DISABLE DEFAULT SCROLL?

class LevelUp extends React.Component {
    componentWillUnmount() {
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <div className="level-up-message">
                <p className="level-up-message-title">You have levelled up!</p>

                <p className="level-up-message-line">Level <span className="hint">{this.props.character.level}</span></p>

                <p className="level-up-message-line">Health points: <span className="hint">{(this.props.character.healthPoints || this.props.character.maxHealthPoints)}</span> / <span className="hint">{this.props.character.maxHealthPoints}</span></p>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const character = selectCharacter(state);

    return {
        character
    }
}

export default connect(
    mapStateToProps,
    {  }
)(LevelUp);