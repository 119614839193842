import React from "react";
import { connect } from 'react-redux'
import {
    selectCharacter,
    selectPrompts,
} from '../../redux/selectors';

import './Prompt.css';

class Prompt extends React.Component {

    render() {
        const canvas = document.getElementsByTagName('canvas')[0]

        const canvasWidth = Number(canvas?.style?.width.slice(0, -2));
        const canvasHeight = Number(canvas?.style?.height.slice(0, -2));

        return (
            <div className="prompt-inner" style={{ maxWidth: canvasWidth - 24 || 0}}>
                <p>Diary Prompt: {this.props.prompt?.text}</p>
                <p><br/><span className="hint">Press <span className="action">c</span> to write in your diary</span></p>
                <p><br/><span className="hint">Press <span className="action">SHIFT</span> + <span className="action">c</span> to dismiss this prompt</span></p>
            </div>
        )
    }
}

const mapToStateProps = state => {
    const character = selectCharacter(state);
    const prompts = selectPrompts(state);

    return {
        character,
        prompt: prompts[0]
    }
}

export default connect(
    mapToStateProps,
    {
        
    }
)(Prompt);