export const crowberrySmellTypes = [
    "of seasoned oysters",
    "of wet wood",
    "of rancid oil",
    "of rotting leaves",
    "of sour lemons",
    "of cattail root",
    "of lotus leaf",
    "of water lily",
    "of bog mallow",
    "of arrowhead",
    "of cattail",
    "of bulrush",
    "of wet wood",
    "of damp leaves",
    "of mossy rocks",
    "of rotting log",
    "of dry tea",
    "of wooden needles",
    "of black soil",
    "of Autumn leaves",
    "of Fog",
    "of Wet rocks",
    "of green leaf sap",
    "of arctic thyme",
    "intriguing",
    "of patchouli and pepper",
    "resinous",
    "of raw umber",
    "of rusted iron",
    "of sun-warmed brick",
    "of a smudge of earth in decay",
    "of tar and maple",
    "of embers without ash",
    "of a chasm in the desert",
    "cuminesque",
    "dark and brooding",
    "spicy and fresh",
    "of smoke and dry cedar",
    "opoponax-rich",
    "like a dangerous ride",
    "bitter",
    "deep and comforting",
    "nose-tickling",
    "of not leaving home",
    "of unloved places",
    "of the fumes of industry",
    "of a sloppy kitchen",
    "of leaking water",
]

export const crowberryBranchTypes = [
    "dwarf",
    "Tiny black fruits on its",
    "low laying",
    "prostrate",
    "crimson-coloured",
    "sanguine",
    "creeping"
    "Barren",
    "Sparse",
    "Dry",
    "rust-red",
    "Dead",
    "Lonely",
    "brittle",
    "weather-beaten and hardy",
    "Gnarled and barren",
    "Thin and lifeless",
    "Dry and brittle",
    "Prickly",
    "dry and forlorn",
    "scraggly",
    "a network of",
    "young, red",
    "vivacious crimson",
    "black berries dangling from its",
    "spiraling",
    "juicy berries on its",
    "crunchy leaves on its",
    "white-tipped thorns on its",
    "slippery skin on its",
    "rime-covered leaves on its",
    "frostbitten berries on its"
    "generous",
    "dark drupes hanging from its",
    "clawing",
    "crawling",
    "wiry",
    "slender",
    "spreading",
]

export const crowberryLeafTypes = [
    "sparkling",
    "dew-catching"
    "alternately arranged",
    "crisp",
    "crunchy",
    "breakable",
    "fragile",
    "minuscule",
    "delicate",
    "frigid",
    "scaley green",
    "green beads for",
    "plump wedge",
    "brittle",
    "verdure",
    "small, proud",
    "light-green",
    "needle-like",
    "stubby",
    "protective",
]