import { GET_META_SUCCESS } from "../actionTypes";

const initialState = {
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_META_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }

    default:
      return state;
  }
}
