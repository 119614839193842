import React from "react";
import { connect } from 'react-redux'
import colorContrast from 'color-contrast'

import { client } from '../../../services/client';
import { selectCharacter, selectCharacters } from '../../../redux/selectors';
import { flashCharacterColorAsync } from '../../../redux/actions/characters.actions';
import { showFightConfirmation } from '../../../redux/actions/keyboard-shortcuts.actions';

import './CharacterList.css';

class CharacterList extends React.Component {

    componentDidMount() {
        
    }

    toggleColor(character) {
        this.props.flashCharacterColorAsync(character);
    }

    render() {
        const characters = this.props.characters?.map((character) => {
            return (
                <li key={character._id} className="panel-character">
                    <div
                        className="character-name"
                        style={{
                            color: colorContrast('#16313B', character.color) < 4.5 ? '#fff7eb' : character.color,
                            background: colorContrast('#16313B', character.color) < 4.5 && character.color
                        }}
                        onClick={() => this.toggleColor(character)}>{character.name}
                    </div>
                </li>
            )
        })
        return (
        	this.props.characters?.length > 0 ?
        	(
                <div className="character-list-container">
    	            <ul>
    	                {characters}
    	            </ul>
                </div>
	        ) : ('')
        )
    }
}

const mapToStateProps = state => {
	const character = selectCharacter(state);
    const characters = selectCharacters(state).filter(({_id}) => (character._id !== _id));

    return { characters }
}

export default connect(
    mapToStateProps,
    {
        showFightConfirmation,
        flashCharacterColorAsync,
    }
)(CharacterList);