export const junglePlantFiveSmellTypes = [
	"of damp leaves",
    "of clay",
    "of mud",
    "of vines",
    "of thorns",
    "of flowers",
    "of insects",
    "of greenery",
    "of rotting leaves",
    "of dank earth",
    "of cloying vines",
    "pungent",
    "of putrid effluvium",
    "of heady musk",
    "of dank leaves",
    "of floral nectar",
    "humid",
    "of tropical heat",
    "of damp petals",
    "of rotting leaves",
    "of crisp morning dew",
    "of humid afternoon heat",
    "of fragrant flowers",
    "of cloying honey",
    "of acrid smoke",
    "of pungent musk",
    "of sweet nectar",
    "of dense leaves",
    "of dank leaves",
    "of cloying flowers",
    "of pungent fruit",
    "of rotting wood",
    "of musty earth",
    "of choking vines",
    "of stifling air",
    "of dripping water",
    "dewy",
    "of sick",
    "of rotten fruit and salt",
    "of airy metal",
    "of cave moss",
    "of medicine and ocean spray",
    "of stale milk on fabric",
    "of floral circuitry",
    "of oil and dragonfruit",
    "of wasted efforts",
]

export const junglePlantFiveBranchTypes = [
	"scratchy textured",
    "intimidating",
    "unforgiving",
    "lonely",
    "dark",
    "enclosed",
    "frightening",
    "dirt-covered",
    "gnarled roots under its",
    "colourful flowers and stiff",
    "high-seated roots and plain",
    "fungus spreading on its",
    "bugs crawling along its",
    "a wingbeat coming from its",
    "Intimidating",
    "discoloured",
    "thick",
    "intricate patterns on its",
    "fragrant flowers growing on its",
    "scuttling insects along its",
    "a flutter of unseen beasts in its",
    "veiny",
    "glossy",
    "thick",
    "green",
    "sap-oozing",
    "woody",
    "complex",
    "fragrant",
    "five main",
    "lush green",
    "has small flowers growing on its",
    "slimy and gross",
    "soil-grim",
    "dew-crowned",
    "seemly",
    "pond-bathed roots and thin"
]

export const junglePlantFiveLeafTypes = [
	"lush",
    "verdant",
    "tropical",
    "exotic",
    "rainforest",
    "sap-shod",
    "spining",
    "dinnerplate-sized",
    "strange flowers coming from its",
    "dense",
    "lush",
    "enveloping",
    "verdant",
    "dappled sunlight playing across",
    "insect homes in its",
    "claustrophobic",
    "damp",
    "brilliant green",
    "lush",
    "small insects on its",
    "flickering light playing across its",
    "thick sap on its",
    "hopeful florets on its",
    "lacy fern",
    "waxy",
    "heart-shaped",
    "broad",
    "pointed",
    "spiny",
    "clustered",
    "fleshy",
    "mucronate",
    "arrow-headed",
    "axhead",
    "beaked",
]