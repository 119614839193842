export const junglePlantThreeSmellTypes = [
	"of Tangle rue",
    "Pungent",
    "rank",
    "acrid",
    "fetid",
    "putrid",
    "stinking",
    "reeking",
    "malodorous",
    "noisome",
    "tainted",
    "fragrant",
    "overgrown",
    "suffocating",
    "humid",
    "dangerous",
    "dark",
    "mysterious",
    "of mire lilies",
    "of garbage petals",
    "of stink liana",
    "of monkey butt tree",
    "of stench orchid",
    "of carrion lily",
    "of corpse bloom",
    "of stench weed",
    "of unclothed fruit",
    "of mentholated lavender",
    "of aniseed and fresh hay",
    "of sumptuous heat",
    "of stewed melon",
    "of dead fruits",
    "of nutmeg flower",
    "of sweet toothache",
    "of overbearing perfume",
    "of dense, sugary clouds",
    "an apricot jam tart",
    "of pulverised legumes",
    "of fresh water after hard toil",
    "of medicine for children",
    "of waxed custard",
    "of cake you're not meant to eat",
    "of chewy sweets",
    "of an unsolved conundrum",
    "of a bemused Fate",
    "kafkaesque",    
]

export const junglePlantThreeBranchTypes = [
	"Briar",
    "Thorny",
    "Spikey",
    "Splintered",
    "spiked",
    "Tangled",
    "thorny",
    "damp green",
    "spire",
    "thin",
    "tiny",
    "small",
    "weak",
    "unsatisfactory",
    "pokey",
    "needle",
    "spotted",
    "oddly patterned",
    "papery",
    "humble",
    "low",
    "soil-gripped",
]

export const junglePlantThreeLeafTypes = [
	"tangled",
    "Thicket",
    "wild",
    "bushy",
    "choking",
    "wandering",
    "prickly",
    "verdant",
    "creature-chewn",
    "broad",
    "sun-seeking",
    "be-flowered",
    "sun-cup",
    "lobed",
    "deeply scarred",
    "sharp-edged",
    "shivering",
    "nervous",
    "withdrawn",
    "unhappy",
    "lonely",
    "wondrous",
    "exuberant",
    "joyful",
]