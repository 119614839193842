export const disarmerFaceSentences = [
"Gnarled and wart-covered",
"With its long, sharp teeth and blood-red eyes, the disarmer monster's face is both horrifying and deadly",
"The disarmer monster's face is a twisted mass of tentacles",
"Two large, black eyes that seem to pierce your soul",
"A mouth filled with razor-sharp teeth that seem to drip with venom",
"An eerie, high-pitched laugh that sends shivers down your spine",
"A long, thin, red tongue",
"A mouth full of sharp, pointy teeth",
"Green, scaly skin",
"A long, snake-like body",
"Two large, black eyes that seem to suck in all the light",
"A mouth full of sharp teeth that drips with drool",
"A long, slimy tongue that slides out of the mouth",
"A nose that is nothing more than two small holes",
"A face that is half-rotted away, revealing muscle and bone beneath",
"A face that is entirely covered in oozing sores",

]

export const disarmerBodySentences = [
"It's a massive, hulking creature, towering over its enemies",
"slime-covered skin, webbed feet, barbed tentacles,",
"it has a human-like body, but its skin is covered in scaly armor",
"it's body is impossibly muscular, and its tentacles end in wicked-looking claws",
"A long, sinuous body, covered in shimmering scales",
"A monstrous body, larger than any man, reptilian in form",
"A body of writhing tentacles, primevally powerful",
"It's body is covered in dangerous spikes",
]

export const disarmerSoundWords = [
    "bellowing",
    "Hissing",
    "Roaring",
    "Snarling",
    "Growling",
    "Rumbling",
    "Shrieking",
    "Howling",
    "Whimpering",
    "barking",
    "whining",
    "bellowing",
    "blaring",
    "booming",
    "deafening",
    "ear-piercing",
    "ear-splitting",
    "high-pitched",
    "loud",
    "piercing",
    "shrill",
    "rumbling",
    "screeching",
    "roaring",
    "hissing",
    "growling",
    "ungodly",
    "Retching",
    "fearsome",
    "blood-curdling",
    "spine-chilling",
    "Startling",
    "Ear-piercing",
    "Shrill",
    "Intimidating",
    "Blood-curdling",
    "Ghastly",
    "Terrible",
    "Terrifying"
]

export const disarmerFurWords = [
    "Nightmarish",
    "Hideous",
    "Intimidating",
    "Foreboding",
    "course",
    "Patchy",
    "matted",
    "tangled",
    "dirty",
    "greasy",
    "foul-smelling",
    "covered in sores",
    "scaly",
    "green and slimy",
    "mangy",
    "flea-ridden",
    "grubby",
    "grimy",
    "dirty",
    "dingy",
    "filthy",
    "soiled",
    "stained",
    "unclean",
    "Downy",
    "silky",
    "soft",
    "fluffy",
    "feathery",
    "furry",
    "warm",
    "cozy",
    "cuddly",
    "comfortable"
]

export const disarmerSmellWords = [
"Nauseating",
"Overpowering",
"Pungent",
"Foul",
"Repellent",
"Disgusting",
"Revolting",
"Sickening",
"Stomach-churning",
"Vomit-inducing",
"Funky",
"Rancid",
"Pungent",
"Ammonia-like",
"Skunky",
"Earthy",
"stale",
"Moldy",
"Rank",
"Putrid",
]

export const disarmerBehaviourSentences = [
"it is drooling and foaming at the mouth",
"it is hissing and spitting",
"it is glaring and baring its teeth",
"it is making strange guttural noises",
"it is drooling acid from its razor-sharp teeth",
"it is opening its mouth wide, ready to devour",
"it's standing really still and watching you",
]