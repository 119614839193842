export const horseFaceSentences = [
"A large, round face with soft features.",
"Big, brown eyes that are always full of life.",
"A short muzzle with a small, black nose.",
"Long, thick eyelashes that frame the eyes.",
"Soft, velvety ears that are",
"A glossy coat that shines in the sun",
"A face that was gentle and kind",
"Large, expressive eyes that told a story",
"A soft nose that was always ready to nuzzle",
"Long, flowing mane and tail that danced in the breeze",
"Long, slim face.",
"Elongated head.",
"Prominent forehead.",
"Long, thin nose.",
"Large, expressive eyes.",
"Pronounced cheekbones.",
"Long, elegant neck.",
"Powerful, muscular body",
"A wide forehead with long, thick lashes.",
"Ears that are long and pointy, always alert.",
"A long, strong neck that is arched and graceful.",
"A large, soft nose that is always nuzzling.",
]

export const horseBodySentences = [
"its body is muscular and powerful.",
"its body is sleek and graceful.",
"its body is muscular and agile.",
"its body is graceful and elegant.",
"its coat is often a beautiful, glossy color.",
"its mane and tail are usually long and luxurious.",
"its muscles are strong and powerful.",
"Fur that is soft to the touch,",
"a mane that flows in the wind,",
"a tail that whips back and forth,",
"four legs that are sturdy and agile,",
"hooves that clomp against the ground,",
"a long neck that is gracefully muscular,",
"intelligent eyes that seem to understand you,",
"Flowing mane and tail",
"Muscular legs",
"Broad chest",
"Strong hooves",
"Intelligent eyes",
"Elegant neck",
"Refined head",
"Solid bone structure",
"Powerful hindquarters",
"Graceful stride",
"its body is gracefully proportioned, with a long, muscular neck and a deep chest.",
]

export const horseSoundWords = [
    "neighing",
    "whinnying",
    "snorting",
    "Whinnying",
    "neighing",
    "nickering",
    "snorting",
    "blowing",
    "heavy breathing",
    "whickering",
    "stamping",
    "pawing",
    "snuffling",
    "Neighing",
    "Whinnying",
    "Clomping",
    "Clattering",
    "Stomping",
    "Shuffling",
    "Snorting",
    "Whickering",
    "nickering",
    "blowing",
    "Neighing",
    "Whinnying",
    "Snorting",
    "Huffing",
    "Clomping",
    "Clattering",
    "neighing",
    "whinnying",
    "snorting",
    "hufing"
]

export const horseFurWords = [
"Calico",
    "chestnut",
    "dappled",
    "gray",
    "grey",
    "lemon",
    "palomino",
    "piebald",
    "roan",
    "white",
    "woolly",
    "zebra-striped"
]

export const horseSmellWords = [
"smoky",
    "sweaty",
    "leathery",
    "musky",
    "astringent",
    "grassy",
    "hay-like"
]

export const horseBehaviourSentences = [
"the horse is sweating",
"the horse is nibbling on the grass",
"the horse is swishing its tail",
"it is licking its hooves intently",
"it is eyeing up nearby trees",
"it is chomping on grass",
"it is swishing its tail",
"it is stamping its feet",
]