export const overgrownFurSmellTypes = [
	"of Moss",
    "Loamy",
    "of Humus",
    "of Peat",
    "of Decay",
    "of Mould",
    "of Mushrooms",
    "of Rot",
    "of Moss",
    "of Mildew",
    "of Damp",
    "of Rotting",
    "Decaying",
    "Putrid",
    "foul",
    "Disgusting",
    "of damp",
    "of earth",
    "musty",
    "wooden",
    "rotting",
    "stale",
    "of dirt",
    "of wood",
    "of wet dog",
    "wet",
    "sticky",
    "of a creek in the shadows of a leafline",
    "of cassis jam",
    "of neroli",
    "of hornwort-slicked culverts",
    "of moss-laden seeps",
    "of heavy fog in an orchard",
    "of mint and clay",
    "of a potter's workshop",
    "of kilnware",
    "of mud and eucalyptus",
    "of jacaranda and menthol",
    "camphorous",
    "of dark, unspoiled forest",
    "of pungent pickles",
    "of bay leaf and cypress",
    "of garlands of circuitry",
    "of veins wrapped in twine",
    "of misfortune",
    "of higher virtues",
    "of an unlucky fate",
    "of baleful eyes amongst the needles",
    "of stolen minutes in silence",
    "of preening animals",
    "restorative",
    "of a tannery",
    "insolent",
    "of stale cheese",
    "of bark chips and plum",
    "of a meatshop",
]

export const overgrownFurBranchTypes = [
    "twisted",
    "crimson",
    "sanguine",
    "girthy",
    "large",
    "huge",
    "vast",
    "enormous",
    "convoluted",
    "reddish-brown",
    "raw",
    "Dense",
    "russet",
    "cerise",
    "ochre",
    "lichen covered",
    "moss covered",
    "vines growing on its",
    "honeysuckle growing on its",
    "leafy",
    "vibrant",
    "lush",
    "brambling",
    "Dense",
    "Thick",
    "Wild",
    "reaching",
    "swaying",
    "gerontic",
    "red",
    "maroon",
    "magenta",
    "thick and corky",
    "fissured",
    "fire-resistant",
    "reddish-purple",
    "splintering",
    "splitting",
    "furrowed",
    "flexible",
    "cone-laden",
    "thickly-scaled",
    "rough",
    "pegged",
    "glabrous",
]

export const overgrownFurLeafTypes = [
    "needle",
    "dense, needled",
    "soft needle",
    "a thick canopy of",
    "a dense canopy of",
    "a burst of",
    "a full crown of",
    "spike",
    "insects resting between its",
    "Needled",
    "Overgrown",
    "Wild",
    "frond",
    "a sweep of",
    "coniferous",
    "flattened",
    "glossy orange",
    "spirally arranged",
    "cone-dripped",
    "flattened",
    "quadrangled",
    "stiff",
    "sharp",
    "cylindrical",
    "splayed spine",
    "spine",
    "spike",
]