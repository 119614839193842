import {
  INITIALISING_PANEL_STARTED,
  START_NEW_GAME,
  GET_ORGANISATIONS_SUCCESS,
  ADD_ORGANISATION,
  UPDATE_ORGANISATION,
  REMOVE_ORGANISATION,
  CLEAR_ORGANISATIONS,
  SHOW_CHARACTER_DEATH_PAGE,
  CHANGE_CHARACTER
} from "../actionTypes";

const initialState = {
  organisations: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_ORGANISATIONS_SUCCESS: {
      const organisations = action.payload;

      return {
        ...state,
        organisations: [...organisations],
      }
    }

    case ADD_ORGANISATION: {
      let organisations = [...state.organisations];

      return {
        ...state,
        organisations: [ ...organisations ],
      }
    }

    case UPDATE_ORGANISATION: {
      let organisations = [...state.organisations];

      console.log(action.payload, organisations)

      organisations = [
        ...organisations.filter(organisation => organisation._id !== action.payload._id),
        { ...organisations.find(organisation => organisation._id === action.payload._id), ...action.payload }
      ]

      return {
        ...state,
        organisations: [ ...organisations ],
      }
    }

    case REMOVE_ORGANISATION: {
      return {
        ...state,
        organisations: [ ...state.organisations.filter(organisation => (organisation._id !== action.payload._id)) ],
      }
    }

    case CLEAR_ORGANISATIONS: {
      return {
        ...initialState
      }
    }

    default:
      return state;
  }
}
