import React from "react";
import { connect } from 'react-redux'

import { client } from '../../services/client';

import { logInAsync } from '../../redux/actions/user.actions';
import { requestResetPasswordAsync } from '../../redux/actions/account-management.actions';

import './Login.css';

class Login extends React.Component {
    hideHandler = () => this.hideErrorMessages();
    logInHandler = (event) => {
        if (event.key === 'Enter') {
            if (this.state.isSignupFormValid) {
               this.signUp();
               return;
            }

            if (this.state.isNewsletterFormValid) {
                this.signUpNewsletter();
                return;
            }

            if (this.state.isResetPasswordShowing && this.state.isResetPasswordFormValid) {
                this.resetPassword();
                return;
            }

            this.logIn();
        } else {
            this.hideErrorMessages()
        }
    };

    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            isLogInShowing: true,
            isSignUpShowing: false,
            isFormValid: false,
            isLogInErrorShowing: false,
            isSignUpErrorShowing: false,
            isNewsletterFormValid: false,
            // isLogInEnabled: window.location.href.indexOf('localhost') > -1,
            isLogInEnabled: true,
            isRegisterEnabled: true
        };

        document.addEventListener('keydown', this.hideHandler)
        document.addEventListener('keydown', this.logInHandler)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.hideHandler);
        document.removeEventListener('keydown', this.logInHandler);
    }

    logIn() {
        if (!this.state.isFormValid) {
            return;
        }

        const { email, password } = this.state;

        this.props.logInAsync({ email, password })
            .catch(() => (this.setState({ isLogInErrorShowing: true })));
    }

    resetPassword() {
        if (!this.state.isResetPasswordFormValid) {
            return;
        }

        const { email } = this.state;

        this.setState({isResetPasswordFormValid: false});

        this.props.requestResetPasswordAsync({ 
            action: 'sendResetPwd',
            value: { email }
        })
        .then(() => (this.setState({ isResetPasswordEmailSent: true })))
        .catch(() => (this.setState({ isLogInErrorShowing: false, isResetPasswordFormValid: true })));
    }

    signUp() {
        if (!this.state.isFormValid) {
            return;
        }

        const { email, password } = this.state;

        return client.service('users')
            .create({
                email,
                password
            })
            .then(() => this.props.logInAsync({
                email,
                password
            }))
            .catch((error) => {
                console.log('error: ', error)
                return this.setState({ isSignUpErrorShowing: true })
            });
    }

    signUpNewsletter() {
        if (!this.state.isNewsletterFormValid) {
            return;
        }

        const { newsletter } = this.state;

        return client.service('newsletter-users')
            .create({ email: newsletter })
            .then(() => {
                this.setState({ newsletter: '', isNewsletterFormValid: false, isNewsletterFormSaved: true })

                setTimeout(() => {
                    this.setState({ isNewsletterFormSaved: false })
                }, 1000)
            })
    }

    hideErrorMessages() {
        this.setState({
            isLogInErrorShowing: false,
            isSignUpErrorShowing: false
        })
    }

    updateInput(key, value) {
        this.setState({ [key]: value })

        setTimeout(() => {
            if (this.state.newsletter && this.state.newsletter.length > 6) {
                this.setState({ isNewsletterFormValid: true });
            } else {
                this.setState({ isNewsletterFormValid: false });
            }

            if (this.state.email && this.state.email.length > 3) {
                this.setState({ isResetPasswordFormValid: true });
            } else {
                this.setState({ isResetPasswordFormValid: false });
            }

            if (this.state.email && this.state.email.length > 3 && this.state.password && this.state.password.length > 5) {
                this.setState({ isFormValid: true });

                if (this.state.confirmPassword  === this.state.password) {
                    this.setState({ isSignupFormValid: true });
                }
            } else {
                this.setState({ isFormValid: false, isSignupFormValid: false });
            }
        })
    }

    showSignUp() {
        this.setState({isSignUpShowing: true, isLogInShowing: false, isResetPasswordShowing: false});
    }

    showLogIn() {
        this.setState({isSignUpShowing: false, isLogInShowing: true, isResetPasswordShowing: false});
    }

    showResetPassword() {
        this.setState({isSignUpShowing: false, isLogInShowing: false, isResetPasswordShowing: true});
    }

    render() {
        return (
            <main className="login-page" onClick={() => this.hideErrorMessages()} >
                <div className="login">
                    <img className="intro-image" src="assets/landingpage-2-b.png"/>

                    <div className="top-bar">
                        <div className="top-bar-container">
                            <a href="https://blog.settle-gliese.com/" target="_blank">Blog</a>
                            <a href="https://www.patreon.com/SettleGliese" target="_blank">Patreon</a>
                            <a href="https://wiki.settle-gliese.com" target="_blank">Wiki</a>
                            <a href="https://discord.gg/h9sCtUfhhW" target="_blank">Discord</a>
                            <a href="https://twitter.com/SettleGliese" target="_blank">Twitter</a>
                        </div>
                    </div>

                    {<div className={(this.state.isLogInEnabled && this.state.isLogInShowing) ? "login-main-bar main-bar" : "hidden"}>
                        <input
                            className="email"
                            onChange={e => this.updateInput('email', e.target.value)}
                            value={this.state.email}
                            placeholder="email"
                            type="email"/>

                        <input
                            className="password"
                            onChange={e => this.updateInput('password', e.target.value)}
                            value={this.state.password}
                            placeholder="password"
                            type="password"/>

                        <div className="buttons">
                            <span
                                className={this.state.isFormValid ? "login-button" : "disabled-button"}
                                onClick={() => this.logIn()}>
                                log in
                            </span>
                        </div>

                        { this.state.isRegisterEnabled ? (
                            <div className="buttons">
                                <span
                                    className="sign-up-button"
                                    onClick={() => this.showSignUp()}>
                                    sign up (alpha)
                                </span>
                            </div>
                        ) : ('')}

                        <div className="buttons">
                            <span
                                className="sign-up-button"
                                onClick={() => this.showResetPassword()}>
                                forgot password
                            </span>
                        </div>
                    </div>}

                    {<div className={this.state.isLogInEnabled && this.state.isSignUpShowing ? "login-main-bar main-bar" : "hidden"}>
                        <input
                            className="email"
                            onChange={e => this.updateInput('email', e.target.value)}
                            value={this.state.email}
                            placeholder="email"
                            type="email"/>

                        <input
                            className="password"
                            onChange={e => this.updateInput('password', e.target.value)}
                            value={this.state.password}
                            placeholder="password"
                            type="password"/>

                        <input
                            className="password"
                            onChange={e => this.updateInput('confirmPassword', e.target.value)}
                            value={this.state.confirmPassword}
                            placeholder="confirm password"
                            type="password"/>

                        <div className="buttons">
                            <span
                                className={this.state.isSignupFormValid ? "login-button" : "disabled-button"}
                                onClick={() => this.signUp()}>
                                sign up
                            </span>
                        </div>

                        <div className="buttons">
                            <span
                                className="login-button"
                                onClick={() => this.showLogIn()}>
                                back to log in
                            </span>
                        </div>
                    </div>}

                    {<div className={this.state.isLogInEnabled && this.state.isResetPasswordShowing ? "login-main-bar main-bar" : "hidden"}>
                        <input
                            className="email"
                            onChange={e => this.updateInput('email', e.target.value)}
                            value={this.state.email}
                            placeholder="email"
                            type="email"/>

                        <div className="buttons">
                            <span
                                className={
                                    this.state.isResetPasswordEmailSent ? 
                                        "hidden"
                                        : (this.state.isResetPasswordFormValid ? "login-button" : "disabled-button")
                                }
                                onClick={() => this.resetPassword()}>
                                reset password
                            </span>

                            <span className={this.state.isResetPasswordEmailSent ? "text": "hidden"}>
                                email sent
                            </span>
                        </div>

                        <div className="buttons">
                            <span
                                className="login-button"
                                onClick={() => this.showLogIn()}>
                                back to log in
                            </span>
                        </div>
                    </div>}

                    {<div className={this.state.isLogInErrorShowing ? "login-error" : "login-error hidden"}>
                        We could not log in with those credentials, try again.
                    </div>}

                    {<div className={this.state.isSignUpErrorShowing ? "sign-up-error" : "sign-up-error hidden"}>
                        Failed to sign up, try using another email address
                    </div>}
                </div>

                <div className="information">
                    <div className="newsletter">
                        <p className="newsletter-text">Join the waitlist:</p>
                        <input
                            className="newsletter-input email"
                            onChange={e => this.updateInput('newsletter', e.target.value)}
                            value={this.state.newsletter}
                            placeholder="email"
                            type="email"/>
                        <span
                            className={this.state.isNewsletterFormValid ? "newsletter-button" : "disabled-button"}
                            onClick={() => this.signUpNewsletter()}>
                            Sign up
                        </span>
                        <span className={this.state.isNewsletterFormSaved ? "saved" : "hidden"}>Saved</span>
                    </div>

                    <div className="overview">
                        <img className="screenshot" src="assets/screenshot-1-d.png"/>

                        <p>
                            <span className="limited">Settle Gliese</span> is a massively multiplayer team based survival game.
                            It's set on an unexplored planet that's wild, diverse, and dangerous.
                            Your first aim is to survive, your second is up to you - try to leave your mark on the history of Gliese forever, or make pretty pots.
                        </p>
                    </div>

                    <div className="overview">
                        <p>
                            <span className="beta">Now in alpha (closed)</span>
                            Beta release in 2023.
                        </p>

                        <img className="screenshot" src="assets/screenshot-2-d.png"/>
                    </div>

                    <div className="overview">
                        <img className="screenshot" src="assets/screenshot-3-d.png"/>

                        <p>
                            Come settle an alien planet.
                            Work together to establish towns and cities, trade routes and empires, that will last for hundreds of years.
                            Or live as a nomadic tribe and prey on the towns of others.
                        </p>
                    </div>

                    <div className="overview">
                        <p>
                            Settle the frontier, in an untamed wilderness on the outskirts of humanity.
                            Or join the communities in the heartlands where cultures have formed, towns and cities have flourished,
                            and businesses, politics, and empires are established.
                        </p>

                        <img className="screenshot" src="assets/screenshot-4-d.png"/>
                    </div>

                    <div className="overview">
                        <img className="screenshot" src="assets/screenshot-5-d.png"/>

                        <div>
                            <p className="list-heading">Make your mark on the game</p>
                            <ul className="list">
                                <li>Map out continents and sell your secrets to the richest empire</li>
                                <li>Write a poem about your annoying neighbour so scathing it's passed on for generations</li>
                                <li>Start a postal service, newspaper, or police force and build a statue of yourself with the profits</li>
                                <li>Destroy your neighbours crops, then enslave them when they run of food in winter</li>
                                <li>Make charcoal alone in the woods for so long you go mad, take off all your clothes, and get memorialised as the <span className="tools">Yeti of Ableswood</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="overview">
                        <div className="bottom-section">
                            <p>
                                <span className="limited">Settle Gliese</span> grows a cultural history entirely created by the players - there are no NPCs or scripted events.
                                The world is carefully balanced to allow different playstyles.
                                Each character will have drama unfold at a personal level as well as being privy to the happenings on the world stage.
                            </p>
                            <ul className="list">
                                <li><span className="focus">Survive however you want</span> - solo hermits, hunter gatherer societies, pastoral nomads, arable farmers, fishing communities, and combinations are all possible</li>
                                <li><span className="focus">Procedurally generated</span> - with unique continents and islands, 28 different biomes, and hundreds of thousands of individual locations</li>
                                <li><span className="focus">Persistent</span> - decisions you make now on where to found a town, what style of clothing to make, or what history to record will be passed on in game forever</li>
                                <li><span className="focus">Dynamic</span> - people age and die, there are periods of famine and years of plenty, and finite numbers of natural resources, leading to a world that is always in flux</li>
                                <li><span className="focus">Creative</span> - to play well requires imagination. Charm your friends, deceive your enemies, collaboratively weave storylines to get the most out of the game</li>
                                <li><span className="focus">Chronicled</span> - epic in game events will be recorded much later in blog posts, but for a rich history you have to explore player-made chronicles in game.</li>
                                <li><span className="focus">Deadly</span> - the game is hard and death is inevitable and permanent. No matter how strong your character is other players will always have a chance of killing it. Living until you die of old age is an achievement in itself.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="overview">
                        <img className="screenshot-small" src="assets/screenshot-small-1-b.png"/>

                        <img className="screenshot-small" src="assets/screenshot-small-2-b.png"/>

                        <img className="screenshot-small" src="assets/screenshot-small-3-b.png"/>
                    </div>

                    <div className="funded">
                        <span>Funding provided by</span>
                        <img className="screenshot-small" src="assets/medienboard.png"/>
                    </div>
                </div>
            </main>
        );
    }
}

export default connect(
    null,
    { logInAsync, requestResetPasswordAsync }
)(Login);