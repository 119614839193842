import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_CHARACTER_FOOD_SUCCESS, REMOVE_FOOD, ADD_FOOD, UPDATE_FOOD, GET_TRADE_FOOD_SUCCESS, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  characterFood: [],
  tileFood: [],
  tradeTools: [],
  isLoading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }

    case GET_CHARACTER_FOOD_SUCCESS: {

      return {
        ...state,
        characterFood: [...state.characterFood, ...action.payload.filter(food => (food.characterId))],
        tileFood: [...action.payload.filter(food => (food.tileId))],
      }
    }

    case GET_TRADE_FOOD_SUCCESS: {

      return {
        ...state,
        tradeFood: [...action.payload ],
      }
    }

    case ADD_FOOD: {
      let characterFood = [...state.characterFood];
      let tileFood = [...state.tileFood];

      if (action.payload.characterId) {
        characterFood = [ ...characterFood, action.payload ]
      }

      if (action.payload.tileId) {
        tileFood = [ ...tileFood, action.payload ]
      }

      return {
        ...state,
        characterFood: [ ...characterFood ],
        tileFood: [ ...tileFood ],
      }
    }

    case UPDATE_FOOD: {
      let characterFood = [...state.characterFood];
      let tileFood = [...state.tileFood];

      if (action.payload.characterId) {
        characterFood = [
          ...characterFood.filter(tool => tool._id !== action.payload._id),
          { ...characterFood.find(tool => tool._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.tileId) {
        tileFood = [
          ...tileFood.filter(tool => tool._id !== action.payload._id),
          { ...tileFood.find(tool => tool._id === action.payload._id), ...action.payload }
        ]
      }

      return {
        ...state,
        characterFood: [ ...characterFood ],
        tileFood: [ ...tileFood ],
      }
    }

    case REMOVE_FOOD: {
      return {
        ...state,
        characterFood: [ ...state.characterFood.filter(tool => (tool._id !== action.payload._id)) ],
        tileFood: [ ...state.tileFood.filter(tool => (tool._id !== action.payload._id)) ],
      }
    }


    default:
      return state;
  }
}
