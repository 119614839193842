import {
    GET_PLANTS_SUCCESS,
    GET_PLANTS_FAILURE,
    PLANT_SEED_SUCCESS,
    PLANT_SEED_FAILURE,
    CULTIVATE_PLANT_SUCCESS,
    CULTIVATE_PLANT_FAILURE,
    HARVEST_PLANT_SUCCESS,
    HARVEST_PLANT_FAILURE,
    ADD_PLANT,
    UPDATE_PLANT,
    REMOVE_PLANT,
} from '../actionTypes';
import { clearAllTiles, loadTilesSuccess } from './tile.actions';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { colyClient } from '../../services/Panel-initialisation.service';

import { client } from '../../services/client';

export const getPlantsSuccess = payload => {
    return ({
        type: GET_PLANTS_SUCCESS,
        payload
    })
}

export const getPlantsFailure = payload => ({
    type: GET_PLANTS_FAILURE
})

export const getPlantsAsync = payload => dispatch => {
    return client.service('plant-instances').find({ query: { panelId: payload.panelId } })
        .then((response) => {
            response = response.map(plant => ({ ...plant, plant: payload.plantTypes.find(type => type._id === plant.plantTypeId) }))
            response = response.map(plant => ({ ...plant, plantProducts: [ ...payload.plantProducts.filter(type => type.plantTypeId === plant.plantTypeId) ] }))

            dispatch(getPlantsSuccess(response));
        })
        .catch((error) => {
            console.error('error', error);
            dispatch(showUnknownError({ message: error.message }));
            throw new Error(error);
        })
}

export const plantSeedSuccess = payload => {
    return ({
        type: PLANT_SEED_SUCCESS,
        payload
    })
}

export const plantSeedFailure = payload => ({
    type: PLANT_SEED_FAILURE
})

export const plantSeedAsync = payload => dispatch => {
    const { plantTypeId, _id } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'plant-instances',
        commandType: 'create',
        data: {
            plantTypeId
        },
        params: {
            query: {
                materialInstanceId: _id
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const cultivatePlantSuccess = payload => {
    return ({
        type: CULTIVATE_PLANT_SUCCESS,
        payload
    })
}

export const cultivatePlantFailure = payload => ({
    type: CULTIVATE_PLANT_FAILURE
})

export const cultivatePlantAsync = payload => dispatch => {
    const plantInstance = payload;

    colyClient.room.send('doAction', {
        serviceName: 'plant-instances',
        commandType: 'update',
        data: {
            ...plantInstance,
            tendedCount: plantInstance.tendedCount++ || 1
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const harvestPlantSuccess = payload => {
    return ({
        type: HARVEST_PLANT_SUCCESS,
        payload
    })
}

export const harvestPlantFailure = () => ({
    type: HARVEST_PLANT_FAILURE
})

export const harvestPlantAsync = payload => dispatch => {
    const { id } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'plant-instances',
        commandType: 'remove',
        data: {
            id
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addPlant = payload => {
    return ({
        type: ADD_PLANT,
        payload
    })
}

export const updatePlant = payload => {
    return ({
        type: UPDATE_PLANT,
        payload
    })
}

export const removePlant = payload => {
    return ({
        type: REMOVE_PLANT,
        payload
    })
}
