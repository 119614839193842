import {
  UPDATE_VISION_RADIUS,
  LOG_IN_SUCCESS
} from "../actionTypes";

import { PANEL_WIDTH } from '../../services/geography';
import { getMinutes, getSeconds } from 'date-fns';

import { BALANCE } from '../../services/balance-config';

const MIN_RADIUS = 1;
const MAX_RADIUS = 53;

const initialState = {
  visionRadius: MAX_RADIUS,
  timeOffset: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOG_IN_SUCCESS: {
      const serverTime = action.payload.user?.serverTime
      const clientTime = new Date().getTime();

      const timeOffset = serverTime - clientTime;

      return {
        ...state,
        timeOffset,
      }
    }

    case UPDATE_VISION_RADIUS: {
      if (BALANCE.IS_NIGHTFALL_DISABLED) {
        return {
          ...initialState,
          // visionRadius: 0
        }
      }
      const newClockTime = action.payload - state.timeOffset;

      const secondsPast = (60 * getMinutes(newClockTime)) + getSeconds(newClockTime);

      let fullNightEnd = 5 * 60;
      let fullDayStart = 10 * 60;
      let fullDayEnd = 20 * 60;
      let fullNightStart = 25 * 60;

      if (secondsPast < 30 * 60) {
        // first cycle
        fullNightEnd = 5 * 60;
        fullDayStart = 10 * 60;
        fullDayEnd = 20 * 60;
        fullNightStart = 25 * 60;
      } else {
        // second cycle
        fullNightEnd = 35 * 60;
        fullDayStart = 40 * 60;
        fullDayEnd = 50 * 60;
        fullNightStart = 55 * 60;
      }

      let visionRadius = MIN_RADIUS;

      if (secondsPast < fullNightEnd) {
        visionRadius = MIN_RADIUS;
      }

      if (secondsPast >= fullNightEnd && secondsPast < fullDayStart) {
        const totalTransitionMinutes = fullDayStart - fullNightEnd;
        const totalTransitionSeconds = totalTransitionMinutes;
        const secondsIntoTransition = secondsPast - fullNightEnd;

        const minutesToPiConversion = (-0.5 + (secondsIntoTransition / totalTransitionMinutes));


        visionRadius = Math.sin(Math.PI * minutesToPiConversion);
        visionRadius += 1;
        visionRadius = visionRadius * 0.5;

        visionRadius = visionRadius * MAX_RADIUS
      }

      if (secondsPast >= fullDayStart && secondsPast < fullDayEnd) {
        visionRadius = MAX_RADIUS;
      }

      if (secondsPast >= fullDayEnd && secondsPast < fullNightStart) {
        // do the maths.
        const totalTransitionMinutes = fullNightStart - fullDayEnd;
        const secondsIntoTransition = secondsPast - fullDayEnd;

        const minutesToPiConversion = 0.5 - (secondsIntoTransition / totalTransitionMinutes);

        visionRadius = Math.sin(Math.PI * minutesToPiConversion);
        visionRadius += 1;
        visionRadius = visionRadius * 0.5;

        visionRadius = visionRadius * MAX_RADIUS
      }

      if (secondsPast >= fullNightStart) {
        visionRadius = MIN_RADIUS;
      }

      return {
        ...state,
        visionRadius
      }
    }

    default:
      return state;
  }
}
