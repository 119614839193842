export const plumeWeedSmellTypes = [
    "Aromatic",
    "Intoxicating",
    "Enchanting",
    "fungal",
    "charming",
    "classy",
    "delicate",
    "enchanting",
    "exquisite",
    "fascinating",
    "dewy",
    "fragrant",
    "musky",
    "resinous",
    "of exhaust fumes",
    "spicy",
    "mineral",
    "terpenic",
    "resinous",
    "phenolic",
    "medicinal",
    "aromatic",
    "fluorescent",
    "Dank",
    "Earthy",
    "Piney",
    "Sweet",
    "Woody",
    "brainy, red and noxious",
    "smokey and earthen",
    "astringent",
    "of a hot, dry day in high summer",
    "of a forge",
    "of hot steel",
    "of sharp suffocation",
    "of soft down tinged with ash",
    "cruel",
    "of cavernous undergrowth",
    "of sulfur and parsley",
    "of oudy slag",
    "of the threshold of a cave",
    "of flame-licked pine",
    "of chrysanthemum",
    "of the first tilling",
    "of a timber pyre",
    "of kindling",
    "of a struck match",
    "acrid, in a way that sets your teeth on edge",
    "of hearth weeds",
    "of bellows dust",
    "of stinging yarrow",
]

export const plumeWeedBranchTypes = [
	"Delicate",
    "Fragile",
    "Intricate",
    "Lacey",
    "Refined",
    "Sheer",
    "Subtle",
    "Tender",
    "Intertwining",
    "Spiralling",
    "Ethereal",
    "Gossamer",
    "Tenuous",
    "platinum",
    "luminous",
    "powdered",
    "crystalline",
    "radiant",
    "sparkling",
    "glittering",
    "multi-faceted",
    "glittering",
    "translucent",
    "spearheaded",
    "brittle",
    "sharp",
    "dangerous",
    "shimmering",
    "ethereal",
    "Sugary",
    "Saccharine",
    "Candyfloss",
    "Soft",
    "Pillowy"
    "clot-coloured",
    "crimson",
    "ochre",
    "sanguine",
    "fiery",
    "claret",
    "battling",
    "red-robed",
    "flame shepherd",
    "copper-decked",
    "unfeebled",
    "magma-rooted",
    "flame-forged",
]

export const plumeWeedLeafTypes = [
	"Crispy",
    "Dry",
    "Flaky",
    "Light",
    "Reflective",
    "Whispery",
    "Feather-light",
    "Gossamer",
    "Intricate",
    "fire-bird",
    "Lacy",
    "Sheer",
    "Translucent",
    "velvety",
    "delicate",
    "lacy",
    "fragile",
    "intricate",
    "translucent",
    "veiny",
    "gauzy",
    "Fragile",
    "Airy",
    "Blooming",
    "Dewy",
    "Lively",
    "Refreshing",
    "Vibrant",
    "Thin",
    "Wispy",
    "bitter",
    "jagged",
    "glistening",
    "waxy",
    "translucent",
    "veiny",
    "pungent",
    "numbing",
    "searing",
    "unctuous",
    "twined",
    "banded",
    "rock-clawed",
    "clutching",
    "flitting",
    "feather-framed",
    "gaunt",
]