import { getRandomCleanedArrayItem, getRandomArrayItem } from '../../../utils/random';
import seedrandom from 'seedrandom';
import {
	pikeFaceSentences,
	pikeBodySentences,
	pikeSoundWords,
	pikeFurWords,
	pikeSmellWords,
	pikeBehaviourSentences,
} from './animals/pike';
import {
	reindeerFaceSentences,
	reindeerBodySentences,
	reindeerSoundWords,
	reindeerFurWords,
	reindeerSmellWords,
	reindeerBehaviourSentences,
} from './animals/reindeer';
import {
	rabbitFaceSentences,
	rabbitBodySentences,
	rabbitSoundWords,
	rabbitFurWords,
	rabbitSmellWords,
	rabbitBehaviourSentences,
} from './animals/rabbit';
import {
	paintreeMonkeyFaceSentences,
	paintreeMonkeyBodySentences,
	paintreeMonkeySoundWords,
	paintreeMonkeyFurWords,
	paintreeMonkeySmellWords,
	paintreeMonkeyBehaviourSentences,
} from './animals/paintree-monkey';
import {
	polarBearFaceSentences,
	polarBearBodySentences,
	polarBearSoundWords,
	polarBearFurWords,
	polarBearSmellWords,
	polarBearBehaviourSentences,
} from './animals/polar-bear';
import {
	okapiFaceSentences,
	okapiBodySentences,
	okapiSoundWords,
	okapiFurWords,
	okapiSmellWords,
	okapiBehaviourSentences,
} from './animals/okapi';
import {
	phasedShooterFaceSentences,
	phasedShooterBodySentences,
	phasedShooterSoundWords,
	phasedShooterFurWords,
	phasedShooterSmellWords,
	phasedShooterBehaviourSentences,
} from './animals/phased-shooter';
import {
	bassFaceSentences,
	bassBodySentences,
	bassSoundWords,
	bassFurWords,
	bassSmellWords,
	bassBehaviourSentences,
} from './animals/bass';
import {
	teleporterFaceSentences,
	teleporterBodySentences,
	teleporterSoundWords,
	teleporterFurWords,
	teleporterSmellWords,
	teleporterBehaviourSentences,
} from './animals/teleporter';
import {
	capybaraFaceSentences,
	capybaraBodySentences,
	capybaraSoundWords,
	capybaraFurWords,
	capybaraSmellWords,
	capybaraBehaviourSentences,
} from './animals/capybara';
import {
	wallPhaserFaceSentences,
	wallPhaserBodySentences,
	wallPhaserSoundWords,
	wallPhaserFurWords,
	wallPhaserSmellWords,
	wallPhaserBehaviourSentences,
} from './animals/wall-phaser';
import {
	chimpanzeeFaceSentences,
	chimpanzeeBodySentences,
	chimpanzeeSoundWords,
	chimpanzeeFurWords,
	chimpanzeeSmellWords,
	chimpanzeeBehaviourSentences,
} from './animals/chimpanzee';
import {
	kangarooFaceSentences,
	kangarooBodySentences,
	kangarooSoundWords,
	kangarooFurWords,
	kangarooSmellWords,
	kangarooBehaviourSentences,
} from './animals/kangaroo';
import {
	antelopeFaceSentences,
	antelopeBodySentences,
	antelopeSoundWords,
	antelopeFurWords,
	antelopeSmellWords,
	antelopeBehaviourSentences,
} from './animals/antelope';
import {
	poisonerFaceSentences,
	poisonerBodySentences,
	poisonerSoundWords,
	poisonerFurWords,
	poisonerSmellWords,
	poisonerBehaviourSentences,
} from './animals/poisoner';
import {
	camelFaceSentences,
	camelBodySentences,
	camelSoundWords,
	camelFurWords,
	camelSmellWords,
	camelBehaviourSentences,
} from './animals/camel';
import {
	lavaBeastFaceSentences,
	lavaBeastBodySentences,
	lavaBeastSoundWords,
	lavaBeastFurWords,
	lavaBeastSmellWords,
	lavaBeastBehaviourSentences,
} from './animals/lava-beast';
import {
	beaverFaceSentences,
	beaverBodySentences,
	beaverSoundWords,
	beaverFurWords,
	beaverSmellWords,
	beaverBehaviourSentences,
} from './animals/beaver';
import {
	snubNosedMonkeyFaceSentences,
	snubNosedMonkeyBodySentences,
	snubNosedMonkeySoundWords,
	snubNosedMonkeyFurWords,
	snubNosedMonkeySmellWords,
	snubNosedMonkeyBehaviourSentences,
} from './animals/snub-nosed-monkey';
import {
	krakenFaceSentences,
	krakenBodySentences,
	krakenSoundWords,
	krakenFurWords,
	krakenSmellWords,
	krakenBehaviourSentences,
} from './animals/kraken';
import {
	woneyedMonkeyFaceSentences,
	woneyedMonkeyBodySentences,
	woneyedMonkeySoundWords,
	woneyedMonkeyFurWords,
	woneyedMonkeySmellWords,
	woneyedMonkeyBehaviourSentences,
} from './animals/woneyed-monkey';
import {
	fireShooterFaceSentences,
	fireShooterBodySentences,
	fireShooterSoundWords,
	fireShooterFurWords,
	fireShooterSmellWords,
	fireShooterBehaviourSentences,
} from './animals/fire-shooter';
import {
	sheepFaceSentences,
	sheepBodySentences,
	sheepSoundWords,
	sheepFurWords,
	sheepSmellWords,
	sheepBehaviourSentences,
} from './animals/sheep';
import {
	snapjawFaceSentences,
	snapjawBodySentences,
	snapjawSoundWords,
	snapjawFurWords,
	snapjawSmellWords,
	snapjawBehaviourSentences,
} from './animals/snapjaw';
import {
	armadilloFaceSentences,
	armadilloBodySentences,
	armadilloSoundWords,
	armadilloFurWords,
	armadilloSmellWords,
	armadilloBehaviourSentences,
} from './animals/armadillo';
import {
	disarmerFaceSentences,
	disarmerBodySentences,
	disarmerSoundWords,
	disarmerFurWords,
	disarmerSmellWords,
	disarmerBehaviourSentences,
} from './animals/disarmer';
import {
	hornBlowerFaceSentences,
	hornBlowerBodySentences,
	hornBlowerSoundWords,
	hornBlowerFurWords,
	hornBlowerSmellWords,
	hornBlowerBehaviourSentences,
} from './animals/horn-blower';
import {
	webShooterFaceSentences,
	webShooterBodySentences,
	webShooterSoundWords,
	webShooterFurWords,
	webShooterSmellWords,
	webShooterBehaviourSentences,
} from './animals/web-shooter';
import {
	aoeFaceSentences,
	aoeBodySentences,
	aoeSoundWords,
	aoeFurWords,
	aoeSmellWords,
	aoeBehaviourSentences,
} from './animals/aoe';
import {
	blackBearFaceSentences,
	blackBearBodySentences,
	blackBearSoundWords,
	blackBearFurWords,
	blackBearSmellWords,
	blackBearBehaviourSentences,
} from './animals/black-bear';
import {
	shooterFaceSentences,
	shooterBodySentences,
	shooterSoundWords,
	shooterFurWords,
	shooterSmellWords,
	shooterBehaviourSentences,
} from './animals/shooter';
import {
	woneyedBeastFaceSentences,
	woneyedBeastBodySentences,
	woneyedBeastSoundWords,
	woneyedBeastFurWords,
	woneyedBeastSmellWords,
	woneyedBeastBehaviourSentences,
} from './animals/woneyed-beast';
import {
	tigerFaceSentences,
	tigerBodySentences,
	tigerSoundWords,
	tigerFurWords,
	tigerSmellWords,
	tigerBehaviourSentences,
} from './animals/tiger';
import {
	racoonFaceSentences,
	racoonBodySentences,
	racoonSoundWords,
	racoonFurWords,
	racoonSmellWords,
	racoonBehaviourSentences,
} from './animals/racoon';
import {
	horseFaceSentences,
	horseBodySentences,
	horseSoundWords,
	horseFurWords,
	horseSmellWords,
	horseBehaviourSentences,
} from './animals/horse';
import {
	slothFaceSentences,
	slothBodySentences,
	slothSoundWords,
	slothFurWords,
	slothSmellWords,
	slothBehaviourSentences,
} from './animals/sloth';
import {
	belowTeleporterFaceSentences,
	belowTeleporterBodySentences,
	belowTeleporterSoundWords,
	belowTeleporterFurWords,
	belowTeleporterSmellWords,
	belowTeleporterBehaviourSentences,
} from './animals/below-teleporter';
import {
	codFaceSentences,
	codBodySentences,
	codSoundWords,
	codFurWords,
	codSmellWords,
	codBehaviourSentences,
} from './animals/cod';
import {
	hpHalverFaceSentences,
	hpHalverBodySentences,
	hpHalverSoundWords,
	hpHalverFurWords,
	hpHalverSmellWords,
	hpHalverBehaviourSentences,
} from './animals/hp-halver';
import {
	deathSongFaceSentences,
	deathSongBodySentences,
	deathSongSoundWords,
	deathSongFurWords,
	deathSongSmellWords,
	deathSongBehaviourSentences,
} from './animals/death-song';
import {
	trollFaceSentences,
	trollBodySentences,
	trollSoundWords,
	trollFurWords,
	trollSmellWords,
	trollBehaviourSentences,
} from './animals/troll';
import {
	sluggishFaceSentences,
	sluggishBodySentences,
	sluggishSoundWords,
	sluggishFurWords,
	sluggishSmellWords,
	sluggishBehaviourSentences,
} from './animals/sluggish';
import {
	sealFaceSentences,
	sealBodySentences,
	sealSoundWords,
	sealFurWords,
	sealSmellWords,
	sealBehaviourSentences,
} from './animals/seal';
import {
	cowFaceSentences,
	cowBodySentences,
	cowSoundWords,
	cowFurWords,
	cowSmellWords,
	cowBehaviourSentences,
} from './animals/cow';
import {
	sableFaceSentences,
	sableBodySentences,
	sableSoundWords,
	sableFurWords,
	sableSmellWords,
	sableBehaviourSentences,
} from './animals/sable';
import {
	scaryFaceSentences,
	scaryBodySentences,
	scarySoundWords,
	scaryFurWords,
	scarySmellWords,
	scaryBehaviourSentences,
} from './animals/scary-monster';
import {
	blindingFaceSentences,
	blindingBodySentences,
	blindingSoundWords,
	blindingFurWords,
	blindingSmellWords,
	blindingBehaviourSentences,
} from './animals/blinding';
import {
	brownBearFaceSentences,
	brownBearBodySentences,
	brownBearSoundWords,
	brownBearFurWords,
	brownBearSmellWords,
	brownBearBehaviourSentences,
} from './animals/brown-bear';
import {
	wolfFaceSentences,
	wolfBodySentences,
	wolfSoundWords,
	wolfFurWords,
	wolfSmellWords,
	wolfBehaviourSentences,
} from './animals/wolf';
import {
	jaguarFaceSentences,
	jaguarBodySentences,
	jaguarSoundWords,
	jaguarFurWords,
	jaguarSmellWords,
	jaguarBehaviourSentences,
} from './animals/jaguar';
import {
	nightStalkerFaceSentences,
	nightStalkerBodySentences,
	nightStalkerSoundWords,
	nightStalkerFurWords,
	nightStalkerSmellWords,
	nightStalkerBehaviourSentences,
} from './animals/night-stalker';
import {
	acidSplashFaceSentences,
	acidSplashBodySentences,
	acidSplashSoundWords,
	acidSplashFurWords,
	acidSplashSmellWords,
	acidSplashBehaviourSentences,
} from './animals/acid-splash';
import {
	rusterFaceSentences,
	rusterBodySentences,
	rusterSoundWords,
	rusterFurWords,
	rusterSmellWords,
	rusterBehaviourSentences,
} from './animals/ruster';
import {
	lifeLeechFaceSentences,
	lifeLeechBodySentences,
	lifeLeechSoundWords,
	lifeLeechFurWords,
	lifeLeechSmellWords,
	lifeLeechBehaviourSentences,
} from './animals/life-leech';
import {
	saigaFaceSentences,
	saigaBodySentences,
	saigaSoundWords,
	saigaFurWords,
	saigaSmellWords,
	saigaBehaviourSentences,
} from './animals/saiga';
import {
	koalaFaceSentences,
	koalaBodySentences,
	koalaSoundWords,
	koalaFurWords,
	koalaSmellWords,
	koalaBehaviourSentences,
} from './animals/koala';
import {
	howlerMonkeyFaceSentences,
	howlerMonkeyBodySentences,
	howlerMonkeySoundWords,
	howlerMonkeyFurWords,
	howlerMonkeySmellWords,
	howlerMonkeyBehaviourSentences,
} from './animals/howler-monkey';
import {
	mackerelFaceSentences,
	mackerelBodySentences,
	mackerelSoundWords,
	mackerelFurWords,
	mackerelSmellWords,
	mackerelBehaviourSentences,
} from './animals/mackerel';
import {
	goatFaceSentences,
	goatBodySentences,
	goatSoundWords,
	goatFurWords,
	goatSmellWords,
	goatBehaviourSentences,
} from './animals/goat';
import {
	swapperFaceSentences,
	swapperBodySentences,
	swapperSoundWords,
	swapperFurWords,
	swapperSmellWords,
	swapperBehaviourSentences,
} from './animals/swapper';
import {
	crustyMonkeyFaceSentences,
	crustyMonkeyBodySentences,
	crustyMonkeySoundWords,
	crustyMonkeyFurWords,
	crustyMonkeySmellWords,
	crustyMonkeyBehaviourSentences,
} from './animals/crusty-monkey';
import {
	slimeBeastFaceSentences,
	slimeBeastBodySentences,
	slimeBeastSoundWords,
	slimeBeastFurWords,
	slimeBeastSmellWords,
	slimeBeastBehaviourSentences,
} from './animals/slime-beast';
import {
	hareFaceSentences,
	hareBodySentences,
	hareSoundWords,
	hareFurWords,
	hareSmellWords,
	hareBehaviourSentences,
} from './animals/hare';
import {
	deerFaceSentences,
	deerBodySentences,
	deerSoundWords,
	deerFurWords,
	deerSmellWords,
	deerBehaviourSentences,
} from './animals/deer';
import {
	salmonFaceSentences,
	salmonBodySentences,
	salmonSoundWords,
	salmonFurWords,
	salmonSmellWords,
	salmonBehaviourSentences,
} from './animals/salmon';
import {
	swarmerFaceSentences,
	swarmerBodySentences,
	swarmerSoundWords,
	swarmerFurWords,
	swarmerSmellWords,
	swarmerBehaviourSentences,
} from './animals/swarmer';
import {
	lionFaceSentences,
	lionBodySentences,
	lionSoundWords,
	lionFurWords,
	lionSmellWords,
	lionBehaviourSentences,
} from './animals/lion';
import {
	spawnerFaceSentences,
	spawnerBodySentences,
	spawnerSoundWords,
	spawnerFurWords,
	spawnerSmellWords,
	spawnerBehaviourSentences,
} from './animals/spawner';
import {
	leopardFaceSentences,
	leopardBodySentences,
	leopardSoundWords,
	leopardFurWords,
	leopardSmellWords,
	leopardBehaviourSentences,
} from './animals/leopard';
import {
	discordantMonkeyFaceSentences,
	discordantMonkeyBodySentences,
	discordantMonkeySoundWords,
	discordantMonkeyFurWords,
	discordantMonkeySmellWords,
	discordantMonkeyBehaviourSentences,
} from './animals/discordant-monkey';
import {
	catfishFaceSentences,
	catfishBodySentences,
	catfishSoundWords,
	catfishFurWords,
	catfishSmellWords,
	catfishBehaviourSentences,
} from './animals/catfish';
import {
	pandaFaceSentences,
	pandaBodySentences,
	pandaSoundWords,
	pandaFurWords,
	pandaSmellWords,
	pandaBehaviourSentences,
} from './animals/panda';


export const animalTypeDescribers = {
	PIKE: {
		level: 1,
		describers: [
			pikeFaceSentences,
			pikeBodySentences,
			pikeSoundWords,
			pikeFurWords,
			pikeSmellWords,
			pikeBehaviourSentences
		],
		name:"Pike",
		skinType: 'scales'
	},
	REINDEER: {
		level: 1,
		describers: [
			reindeerFaceSentences,
			reindeerBodySentences,
			reindeerSoundWords,
			reindeerFurWords,
			reindeerSmellWords,
			reindeerBehaviourSentences
		],
		name:"Reindeer",
		skinType: 'fur'
	},
	RABBIT: {
		level: 1,
		describers: [
			rabbitFaceSentences,
			rabbitBodySentences,
			rabbitSoundWords,
			rabbitFurWords,
			rabbitSmellWords,
			rabbitBehaviourSentences
		],
		name:"Rabbit",
		skinType: 'fur'
	},
	PAINTREE_MONKEY: {
		level: 6,
		describers: [
			paintreeMonkeyFaceSentences,
			paintreeMonkeyBodySentences,
			paintreeMonkeySoundWords,
			paintreeMonkeyFurWords,
			paintreeMonkeySmellWords,
			paintreeMonkeyBehaviourSentences
		],
		name:"Paintree monkey",
		skinType: 'fur'
	},
	POLAR_BEAR: {
		level: 13,
		describers: [
			polarBearFaceSentences,
			polarBearBodySentences,
			polarBearSoundWords,
			polarBearFurWords,
			polarBearSmellWords,
			polarBearBehaviourSentences
		],
		name:"Polar bear",
		skinType: 'fur'
	},
	OKAPI: {
		level: 1,
		describers: [
			okapiFaceSentences,
			okapiBodySentences,
			okapiSoundWords,
			okapiFurWords,
			okapiSmellWords,
			okapiBehaviourSentences
		],
		name:"Okapi",
		skinType: 'fur'
	},
	PHASED_SHOOTER: {
		level: 32,
		describers: [
			phasedShooterFaceSentences,
			phasedShooterBodySentences,
			phasedShooterSoundWords,
			phasedShooterFurWords,
			phasedShooterSmellWords,
			phasedShooterBehaviourSentences
		],
		name:"Wall Sniper",
		skinType: 'skin'
	},
	BASS: {
		level: 5,
		describers: [
			bassFaceSentences,
			bassBodySentences,
			bassSoundWords,
			bassFurWords,
			bassSmellWords,
			bassBehaviourSentences
		],
		name:"Bass",
		skinType: 'scales'
	},
	TELEPORTER: {
		level: 32,
		describers: [
			teleporterFaceSentences,
			teleporterBodySentences,
			teleporterSoundWords,
			teleporterFurWords,
			teleporterSmellWords,
			teleporterBehaviourSentences
		],
		name:"Craggyak",
		skinType: 'skin'
	},
	CAPYBARA: {
		level: 3,
		describers: [
			capybaraFaceSentences,
			capybaraBodySentences,
			capybaraSoundWords,
			capybaraFurWords,
			capybaraSmellWords,
			capybaraBehaviourSentences
		],
		name:"Capybara",
		skinType: 'skin'
	},
	PHASER: {
		level: 27,
		describers: [
			wallPhaserFaceSentences,
			wallPhaserBodySentences,
			wallPhaserSoundWords,
			wallPhaserFurWords,
			wallPhaserSmellWords,
			wallPhaserBehaviourSentences
		],
		name:"Wall Walker",
		skinType: 'skin'
	},
	CHIMPANZEE: {
		level: 15,
		describers: [
			chimpanzeeFaceSentences,
			chimpanzeeBodySentences,
			chimpanzeeSoundWords,
			chimpanzeeFurWords,
			chimpanzeeSmellWords,
			chimpanzeeBehaviourSentences
		],
		name:"Chimpanzee",
		skinType: 'fur'
	},
	KANGAROO: {
		level: 18,
		describers: [
			kangarooFaceSentences,
			kangarooBodySentences,
			kangarooSoundWords,
			kangarooFurWords,
			kangarooSmellWords,
			kangarooBehaviourSentences
		],
		name:"Kangaroo",
		skinType: 'fur'
	},
	ANTELOPE: {
		level: 5,
		describers: [
			antelopeFaceSentences,
			antelopeBodySentences,
			antelopeSoundWords,
			antelopeFurWords,
			antelopeSmellWords,
			antelopeBehaviourSentences
		],
		name:"Antelope",
		skinType: 'fur'
	},
	POISONER: {
		level: 29,
		describers: [
			poisonerFaceSentences,
			poisonerBodySentences,
			poisonerSoundWords,
			poisonerFurWords,
			poisonerSmellWords,
			poisonerBehaviourSentences
		],
		name:"Festering Cat",
		skinType: 'skin'
	},
	CAMEL: {
		level: 7,
		describers: [
			camelFaceSentences,
			camelBodySentences,
			camelSoundWords,
			camelFurWords,
			camelSmellWords,
			camelBehaviourSentences
		],
		name:"Camel",
		skinType: 'fur'
	},
	MAGMA_CROC: {
		level: 35,
		describers: [
			lavaBeastFaceSentences,
			lavaBeastBodySentences,
			lavaBeastSoundWords,
			lavaBeastFurWords,
			lavaBeastSmellWords,
			lavaBeastBehaviourSentences
		],
		name:"Burning Beast",
		skinType: 'skin'
	},
	BEAVER: {
		level: 5,
		describers: [
			beaverFaceSentences,
			beaverBodySentences,
			beaverSoundWords,
			beaverFurWords,
			beaverSmellWords,
			beaverBehaviourSentences
		],
		name:"Beaver",
		skinType: 'fur'
	},
	SNUB_NOSED_MONKEY: {
		level: 8,
		describers: [
			snubNosedMonkeyFaceSentences,
			snubNosedMonkeyBodySentences,
			snubNosedMonkeySoundWords,
			snubNosedMonkeyFurWords,
			snubNosedMonkeySmellWords,
			snubNosedMonkeyBehaviourSentences
		],
		name:"Snub nosed monkey",
		skinType: 'fur'
	},
	KRAKEN: {
		level: 26,
		describers: [
			krakenFaceSentences,
			krakenBodySentences,
			krakenSoundWords,
			krakenFurWords,
			krakenSmellWords,
			krakenBehaviourSentences
		],
		name:"Kraken",
		skinType: 'skin'
	},
	WONEYED_MONKEY: {
		level: 15,
		describers: [
			woneyedMonkeyFaceSentences,
			woneyedMonkeyBodySentences,
			woneyedMonkeySoundWords,
			woneyedMonkeyFurWords,
			woneyedMonkeySmellWords,
			woneyedMonkeyBehaviourSentences
		],
		name:"Woneyed monkey",
		skinType: 'fur'
	},
	FIRE_SHOOTER: {
		level: 33,
		describers: [
			fireShooterFaceSentences,
			fireShooterBodySentences,
			fireShooterSoundWords,
			fireShooterFurWords,
			fireShooterSmellWords,
			fireShooterBehaviourSentences
		],
		name:"Balrog",
		skinType: 'skin'
	},
	SHEEP: {
		level: 5,
		describers: [
			sheepFaceSentences,
			sheepBodySentences,
			sheepSoundWords,
			sheepFurWords,
			sheepSmellWords,
			sheepBehaviourSentences
		],
		name:"Sheep",
		skinType: 'wool'
	},
	SNAPJAW: {
		level: 23,
		describers: [
			snapjawFaceSentences,
			snapjawBodySentences,
			snapjawSoundWords,
			snapjawFurWords,
			snapjawSmellWords,
			snapjawBehaviourSentences
		],
		name:"Snapping Maw",
		skinType: 'skin'
	},
	ARMADILLO: {
		level: 7,
		describers: [
			armadilloFaceSentences,
			armadilloBodySentences,
			armadilloSoundWords,
			armadilloFurWords,
			armadilloSmellWords,
			armadilloBehaviourSentences
		],
		name:"Armadillo",
		skinType: 'pelt'
	},
	DISARMER: {
		level: 25,
		describers: [
			disarmerFaceSentences,
			disarmerBodySentences,
			disarmerSoundWords,
			disarmerFurWords,
			disarmerSmellWords,
			disarmerBehaviourSentences
		],
		name:"Undead Nymph",
		skinType: 'skin'
	},
	HORN_BLOWER: {
		level: 28,
		describers: [
			hornBlowerFaceSentences,
			hornBlowerBodySentences,
			hornBlowerSoundWords,
			hornBlowerFurWords,
			hornBlowerSmellWords,
			hornBlowerBehaviourSentences
		],
		name:"Blind Warbler",
		skinType: 'skin'
	},
	WEB_SHOOTER: {
		level: 34,
		describers: [
			webShooterFaceSentences,
			webShooterBodySentences,
			webShooterSoundWords,
			webShooterFurWords,
			webShooterSmellWords,
			webShooterBehaviourSentences
		],
		name:"Grab Crab",
		skinType: 'skin'
	},
	AOE: {
		level: 28,
		describers: [
			aoeFaceSentences,
			aoeBodySentences,
			aoeSoundWords,
			aoeFurWords,
			aoeSmellWords,
			aoeBehaviourSentences
		],
		name:"Minotaur",
		skinType: 'skin'
	},
	BLACK_BEAR: {
		level: 12,
		describers: [
			blackBearFaceSentences,
			blackBearBodySentences,
			blackBearSoundWords,
			blackBearFurWords,
			blackBearSmellWords,
			blackBearBehaviourSentences
		],
		name:"Black bear",
		skinType: 'fur'
	},
	SHOOTER: {
		level: 25,
		describers: [
			shooterFaceSentences,
			shooterBodySentences,
			shooterSoundWords,
			shooterFurWords,
			shooterSmellWords,
			shooterBehaviourSentences
		],
		name:"Dinodemon",
		skinType: 'skin'
	},
	WONEYED_BEAST: {
		level: 30,
		describers: [
			woneyedBeastFaceSentences,
			woneyedBeastBodySentences,
			woneyedBeastSoundWords,
			woneyedBeastFurWords,
			woneyedBeastSmellWords,
			woneyedBeastBehaviourSentences
		],
		name:"Woneyed beast",
		skinType: 'skin'
	},
	TIGER: {
		level: 22,
		describers: [
			tigerFaceSentences,
			tigerBodySentences,
			tigerSoundWords,
			tigerFurWords,
			tigerSmellWords,
			tigerBehaviourSentences
		],
		name:"Tiger",
		skinType: 'fur'
	},
	RACOON: {
		level: 1,
		describers: [
			racoonFaceSentences,
			racoonBodySentences,
			racoonSoundWords,
			racoonFurWords,
			racoonSmellWords,
			racoonBehaviourSentences
		],
		name:"Racoon",
		skinType: 'fur'
	},
	HORSE: {
		level: 12,
		describers: [
			horseFaceSentences,
			horseBodySentences,
			horseSoundWords,
			horseFurWords,
			horseSmellWords,
			horseBehaviourSentences
		],
		name:"Horse",
		skinType: 'fur'
	},
	SLOTH: {
		level: 7,
		describers: [
			slothFaceSentences,
			slothBodySentences,
			slothSoundWords,
			slothFurWords,
			slothSmellWords,
			slothBehaviourSentences
		],
		name:"Sloth",
		skinType: 'fur'
	},
	TELEPORT_LEVEL: {
		level: 33,
		describers: [
			belowTeleporterFaceSentences,
			belowTeleporterBodySentences,
			belowTeleporterSoundWords,
			belowTeleporterFurWords,
			belowTeleporterSmellWords,
			belowTeleporterBehaviourSentences
		],
		name:"Void Caller",
		skinType: 'skin'
	},
	COD: {
		level: 1,
		describers: [
			codFaceSentences,
			codBodySentences,
			codSoundWords,
			codFurWords,
			codSmellWords,
			codBehaviourSentences
		],
		name:"Cod",
		skinType: 'scales'
	},
	HP_HALVER: {
		level: 34,
		describers: [
			hpHalverFaceSentences,
			hpHalverBodySentences,
			hpHalverSoundWords,
			hpHalverFurWords,
			hpHalverSmellWords,
			hpHalverBehaviourSentences
		],
		name:"Elephcant",
		skinType: 'skin'
	},
	DEATH_SONG: {
		level: 23,
		describers: [
			deathSongFaceSentences,
			deathSongBodySentences,
			deathSongSoundWords,
			deathSongFurWords,
			deathSongSmellWords,
			deathSongBehaviourSentences
		],
		name:"Swan Singer",
		skinType: 'skin'
	},
	TROLL: {
		level: 25,
		describers: [
			trollFaceSentences,
			trollBodySentences,
			trollSoundWords,
			trollFurWords,
			trollSmellWords,
			trollBehaviourSentences
		],
		name:"Stinking Troll",
		skinType: 'skin'
	},
	SLUGGISHER: {
		level: 25,
		describers: [
			sluggishFaceSentences,
			sluggishBodySentences,
			sluggishSoundWords,
			sluggishFurWords,
			sluggishSmellWords,
			sluggishBehaviourSentences
		],
		name:"Fetid Slime",
		skinType: 'skin'
	},
	SEAL: {
		level: 1,
		describers: [
			sealFaceSentences,
			sealBodySentences,
			sealSoundWords,
			sealFurWords,
			sealSmellWords,
			sealBehaviourSentences
		],
		name:"Seal",
		skinType: 'pelt'
	},
	COW: {
		level: 5,
		describers: [
			cowFaceSentences,
			cowBodySentences,
			cowSoundWords,
			cowFurWords,
			cowSmellWords,
			cowBehaviourSentences
		],
		name:"Cow",
		skinType: 'fur'
	},
	SABLE: {
		level: 5,
		describers: [
			sableFaceSentences,
			sableBodySentences,
			sableSoundWords,
			sableFurWords,
			sableSmellWords,
			sableBehaviourSentences
		],
		name:"Sable",
		skinType: 'fur'
	},
	SCARY: {
		level: 32,
		describers: [
			scaryFaceSentences,
			scaryBodySentences,
			scarySoundWords,
			scaryFurWords,
			scarySmellWords,
			scaryBehaviourSentences
		],
		name:"Flayed Ogre",
		skinType: 'skin'
	},
	BLINDNESSER: {
		level: 35,
		describers: [
			blindingFaceSentences,
			blindingBodySentences,
			blindingSoundWords,
			blindingFurWords,
			blindingSmellWords,
			blindingBehaviourSentences
		],
		name:"Naga Orc",
		skinType: 'skin'
	},
	BROWN_BEAR: {
		level: 9,
		describers: [
			brownBearFaceSentences,
			brownBearBodySentences,
			brownBearSoundWords,
			brownBearFurWords,
			brownBearSmellWords,
			brownBearBehaviourSentences
		],
		name:"Brown Bear",
		skinType: 'fur'
	},
	WOLF: {
		level: 12,
		describers: [
			wolfFaceSentences,
			wolfBodySentences,
			wolfSoundWords,
			wolfFurWords,
			wolfSmellWords,
			wolfBehaviourSentences
		],
		name:"Wolf",
		skinType: 'fur'
	},
	JAGUAR: {
		level: 12,
		describers: [
			jaguarFaceSentences,
			jaguarBodySentences,
			jaguarSoundWords,
			jaguarFurWords,
			jaguarSmellWords,
			jaguarBehaviourSentences
		],
		name:"Jaguar",
		skinType: 'fur'
	},
	NIGHT_STALKER: {
		level: 22,
		describers: [
			nightStalkerFaceSentences,
			nightStalkerBodySentences,
			nightStalkerSoundWords,
			nightStalkerFurWords,
			nightStalkerSmellWords,
			nightStalkerBehaviourSentences
		],
		name:"Night Wraith",
		skinType: 'skin'
	},
	ACID_SPLASH: {
		level: 28,
		describers: [
			acidSplashFaceSentences,
			acidSplashBodySentences,
			acidSplashSoundWords,
			acidSplashFurWords,
			acidSplashSmellWords,
			acidSplashBehaviourSentences
		],
		name:"Cawcatrice",
		skinType: 'skin'
	},
	RUSTER: {
		level: 27,
		describers: [
			rusterFaceSentences,
			rusterBodySentences,
			rusterSoundWords,
			rusterFurWords,
			rusterSmellWords,
			rusterBehaviourSentences
		],
		name:"Sucking Ruster",
		skinType: 'skin'
	},
	LIFE_LEECH: {
		level: 32,
		describers: [
			lifeLeechFaceSentences,
			lifeLeechBodySentences,
			lifeLeechSoundWords,
			lifeLeechFurWords,
			lifeLeechSmellWords,
			lifeLeechBehaviourSentences
		],
		name:"Leech Wight",
		skinType: 'skin'
	},
	SAIGA: {
		level: 9,
		describers: [
			saigaFaceSentences,
			saigaBodySentences,
			saigaSoundWords,
			saigaFurWords,
			saigaSmellWords,
			saigaBehaviourSentences
		],
		name:"Saiga",
		skinType: 'fur'
	},
	KOALA: {
		level: 8,
		describers: [
			koalaFaceSentences,
			koalaBodySentences,
			koalaSoundWords,
			koalaFurWords,
			koalaSmellWords,
			koalaBehaviourSentences
		],
		name:"Koala",
		skinType: 'fur'
	},
	HOWLER_MONKEY: {
		level: 8,
		describers: [
			howlerMonkeyFaceSentences,
			howlerMonkeyBodySentences,
			howlerMonkeySoundWords,
			howlerMonkeyFurWords,
			howlerMonkeySmellWords,
			howlerMonkeyBehaviourSentences
		],
		name:"Howler monkey",
		skinType: 'fur'
	},
	MACKEREL: {
		level: 1,
		describers: [
			mackerelFaceSentences,
			mackerelBodySentences,
			mackerelSoundWords,
			mackerelFurWords,
			mackerelSmellWords,
			mackerelBehaviourSentences
		],
		name:"Mackerel",
		skinType: 'scales'
	},
	GOAT: {
		level: 6,
		describers: [
			goatFaceSentences,
			goatBodySentences,
			goatSoundWords,
			goatFurWords,
			goatSmellWords,
			goatBehaviourSentences
		],
		name:"Goat",
		skinType: 'fur'
	},
	SWAPPER: {
		level: 30,
		describers: [
			swapperFaceSentences,
			swapperBodySentences,
			swapperSoundWords,
			swapperFurWords,
			swapperSmellWords,
			swapperBehaviourSentences
		],
		name:"Jangling Swapper",
		skinType: 'skin'
	},
	CRUSTY_MONKEY: {
		level: 7,
		describers: [
			crustyMonkeyFaceSentences,
			crustyMonkeyBodySentences,
			crustyMonkeySoundWords,
			crustyMonkeyFurWords,
			crustyMonkeySmellWords,
			crustyMonkeyBehaviourSentences
		],
		name:"Crusty monkey",
		skinType: 'fur'
	},
	SLIME_BEAST: {
		level: 26,
		describers: [
			slimeBeastFaceSentences,
			slimeBeastBodySentences,
			slimeBeastSoundWords,
			slimeBeastFurWords,
			slimeBeastSmellWords,
			slimeBeastBehaviourSentences
		],
		name:"Slime Beast",
		skinType: 'skin'
	},
	HARE: {
		level: 1,
		describers: [
			hareFaceSentences,
			hareBodySentences,
			hareSoundWords,
			hareFurWords,
			hareSmellWords,
			hareBehaviourSentences
		],
		name:"Hare",
		skinType: 'fur'
	},
	DEER: {
		level: 8,
		describers: [
			deerFaceSentences,
			deerBodySentences,
			deerSoundWords,
			deerFurWords,
			deerSmellWords,
			deerBehaviourSentences
		],
		name:"Deer",
		skinType: 'fur'
	},
	SALMON: {
		level: 5,
		describers: [
			salmonFaceSentences,
			salmonBodySentences,
			salmonSoundWords,
			salmonFurWords,
			salmonSmellWords,
			salmonBehaviourSentences
		],
		name:"Salmon",
		skinType: 'scales'
	},
	SWARMER: {
		level: 28,
		describers: [
			swarmerFaceSentences,
			swarmerBodySentences,
			swarmerSoundWords,
			swarmerFurWords,
			swarmerSmellWords,
			swarmerBehaviourSentences
		],
		name:"Whirling Alf",
		skinType: 'skin'
	},
	LION: {
		level: 15,
		describers: [
			lionFaceSentences,
			lionBodySentences,
			lionSoundWords,
			lionFurWords,
			lionSmellWords,
			lionBehaviourSentences
		],
		name:"Lion",
		skinType: 'fur'
	},
	BEASTIE_SPAWNER: {
		level: 29,
		describers: [
			spawnerFaceSentences,
			spawnerBodySentences,
			spawnerSoundWords,
			spawnerFurWords,
			spawnerSmellWords,
			spawnerBehaviourSentences
		],
		name:"Wyrm Cat",
		skinType: 'skin'
	},
	LEOPARD: {
		level: 18,
		describers: [
			leopardFaceSentences,
			leopardBodySentences,
			leopardSoundWords,
			leopardFurWords,
			leopardSmellWords,
			leopardBehaviourSentences
		],
		name:"Leopard",
		skinType: 'fur'
	},
	DISCORDANT_MONKEY: {
		level: 15,
		describers: [
			discordantMonkeyFaceSentences,
			discordantMonkeyBodySentences,
			discordantMonkeySoundWords,
			discordantMonkeyFurWords,
			discordantMonkeySmellWords,
			discordantMonkeyBehaviourSentences
		],
		name:"Discordant monkey",
		skinType: 'fur'
	},
	CATFISH: {
		level: 3,
		describers: [
			catfishFaceSentences,
			catfishBodySentences,
			catfishSoundWords,
			catfishFurWords,
			catfishSmellWords,
			catfishBehaviourSentences
		],
		name:"Catfish",
		skinType: 'scales'
	},
	PANDA: {
		level: 13,
		describers: [
			pandaFaceSentences,
			pandaBodySentences,
			pandaSoundWords,
			pandaFurWords,
			pandaSmellWords,
			pandaBehaviourSentences
		],
		name:"Panda",
		skinType: 'fur'
	},
}


export function getAnimalDescription(animal) {
	const animalType = animal.animal;

	if (!animalType) {
		return;
	}

	if (!animalTypeDescribers[animalType.name]) {
		return {
			title: animalType.name,
			description: ''
		}
	}

	const description = getGenericDescription(animal, animalType, animalTypeDescribers[animalType.name]?.skinType, ...animalTypeDescribers[animalType.name]?.describers)

	return {
		title: animalTypeDescribers[animalType.name].name,
		description
	}
}

function getGenericDescription(animal, animalType, skinType, faceSentences, bodySentences, soundWords, furWords, smellWords, behaviourSentences) {
	const seed = seedrandom(animalType.name + animal.id);

	const sentences = []

	let sentence = '';

	if (faceSentences?.length > 0) {
		let randomShapeType = getRandomCleanedArrayItem(faceSentences, seed);

		randomShapeType = randomShapeType[0].toUpperCase() + randomShapeType.slice(1);

		if (randomShapeType[randomShapeType.length - 1] !== '.') {
			sentences.push(randomShapeType + '.');
		} else {
			sentences.push(randomShapeType);
		}
	}

	if (bodySentences?.length > 0) {
		let randomFruitType = getRandomCleanedArrayItem(bodySentences, seed);

		randomFruitType = randomFruitType[0].toUpperCase() + randomFruitType.slice(1);

		if (randomFruitType[randomFruitType.length - 1] !== '.') {
			sentences.push(randomFruitType + '.');
		} else {
			sentences.push(randomFruitType);
		}
	}

	if (soundWords?.length > 0) {
		const randomSoundType = getRandomCleanedArrayItem(soundWords, seed);
		sentences.push(`It is making a ${randomSoundType} sound.`);
	}

	if (furWords?.length > 0) {
		const randomFurType = getRandomCleanedArrayItem(furWords, seed);
		sentences.push(`It has ${randomFurType} ${skinType}.`);
	}

	if (smellWords?.length > 0 && seed() < 0.25) {
		const randomSmellType = getRandomCleanedArrayItem(smellWords, seed);
		sentences.push(`It smells ${randomSmellType}.`)
	}

	if (behaviourSentences?.length > 0 && seed() < 0.05) {
		let randomRareType = getRandomCleanedArrayItem(behaviourSentences, seed);

		randomRareType = randomRareType[0].toUpperCase() + randomRareType.slice(1);

		if (randomRareType[randomRareType.length - 1] !== '.') {
			sentences.push(randomRareType + '.');
		} else {
			sentences.push(randomRareType);
		}
	}

	let tries = 0;

	while (sentence.length < 100 && tries < 6) {
		tries++;
		const result = getRandomArrayItem(sentences, seed);

		if (result) {
			sentence += result + ' ';
		}
		sentences = sentences.filter(item => (item !== result))
	}

	return sentence
}

