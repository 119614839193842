export const kohlrabiSmellTypes = [
	"of hay",
    "of linen",
    "of leather",
    "of tea",
    "of musk",
    "vegetal",
    "grassy",
    "peppery",
    "radish-like",
    "zesty",
    "spicy",
    "pungent",
    "tangy",
    "of autumn leaves",
    "of celery",
    "of mustard greens",
    "turnippy",
    "of collard greens",
    "of cabbage",
    "slightly spicy",
    "fresh",
    "mild",
    "nutty",
    "slightly sweet",
    "clean",
    "crisp",
    "resinous",
    "woody",
    "fragrant",
    "spicy",
    "nutty",
    "intoxicating",
    "heady",
    "aromatic",
    "pungent",
    "of swampy cedar",
    "of old wooden planks",
    "of plant detritus",
    "fetid",
    "of moist woodland",
    "of raw meat",
    "skanky",
    "of ammonia",
    "of a woodworker's station",
    "of balsa and twine",
]

export const kohlrabiBranchTypes = [
	"verdant",
    "lush",
    "leafy",
    "small beetles on its",
    "humble",
    "Elegant",
    "Exuberant",
    "Luminous",
    "simple",
    "Radiant",
    "straight",
    "resplendent",
    "laudable",
    "leafy",
    "veiny",
    "crisp",
    "crunchy",
    "spicy",
    "textured",
    "earthy",
    "smooth",
    "hardy",
    "persevering",
    "adamant",
    "endeavoring",
    "unwavering",
    "zealous",
    "crisp",
    "juicy",
    "woody",
    "thin",
    "sweet",
    "a plump vegetable under its",
    "fibrous",
    "mud-caked",
    "trampled",
    "flower-bearing",
    "stubby",
    "eager",
    "dew-drooped",
    "frost hardy",
    "frost fetter",
    "hardy",
]

export const kohlrabiLeafTypes = [
	"Vibrant",
    "Lively",
    "Refreshing",
    "Crisp",
    "Clean",
    "Delicate",
    "Fragrant",
    "Mild",
    "Vegetal",
    "dark-green",
    "edible",
    "glossy",
    "textured",
    "waxy",
    "veined",
    "thick",
    "hearty",
    "serrated",
    "broad",
    "bucolic",
    "dappled",
    "lush",
    "verdant",
    "viridescent",
    "jade",
    "Vibrant",
    "Lively",
    "Energetic",
    "Wavy",
    "Ripple",
    "Soothing",
    "Calm",
    "Placid",
    "Peaceful",
    "long, hopeful",
    "hale",
    "hearty",
    "sour",
    "bitter",
    "soft",
    "edible",
    "insect-bitten",
    "spear",
    "waxy",
    "merry",
    "straight",
    "plume",
    "scabbed",
    "bumpy",
    "bitten",
    "jagged",
    "shaped",
    "thick",
    "a waxy bloom on its",
]