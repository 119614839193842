import { randomNumberBetween, PANEL_HEIGHT, PANEL_WIDTH } from './geography';
import tileMap from './tile-map'
import store from '../redux/store';
import { RNG, Map } from 'rot-js';
import { clearStairs, addStair } from '../redux/actions/stair.actions';
import { getMineWallsSuccess } from '../redux/actions/mine-wall.actions';
import { addMineral } from '../redux/actions/mineral.actions';
import { getMockLayer } from './biome-geography';
import { BALANCE } from './balance-config';
import { getFloorLayerFromPanel } from '../redux/reducers/panel';

import seedrandom from 'seedrandom';

export const MINE_WALL_KEY = 'MINE-WALL';
const MINE_SPACE_KEY = 'EMPTY'
export const STAIRCASE_UP_KEY = 'WASTES.SECOND.LEFT.';
export const STAIRCASE_DOWN_KEY = 'SAND.TOP.';

export const RESOURCE_COMMON = 'ALBINO-FUR.LEFT.TOP.';
export const RESOURCE_RARE = 'DISCORDANT-PINK.RIGHT.BOTTOM.';
export const RESOURCE_ULTRA_RARE = 'DISCORDANT-YELLOW.LEFT.TOP.';

const DOWN_STAIR_TILE_KEY = 'STAIRS.DOWN';
const UP_STAIR_TILE_KEY = 'STAIRS.UP';

export function drawMineEntrance(panel, rng) {
    store.dispatch(clearStairs());

    if (panel.isOcean) {
        return;
    }

    const mineEntranceSeed = seedrandom('mine-entrance' + (panel.id * 250000));

    if (mineEntranceSeed() < BALANCE.NO_MINE_CHANCE) {
        return;
    }

    const mineBelowSeed = (panel.id * 10000) - 1;
    const seed = (panel.id * 10000);

    const mineBelowLayerData = getMockLayer();

    const mineBelow = generateMine(mineBelowLayerData, mineBelowSeed);
    const mineLayerData = getMockLayer()

    const mine = generateMine(mineLayerData, seed);

    const staircaseDown = drawStaircase(mine, mineBelowLayerData, STAIRCASE_DOWN_KEY, getStaircaseSeed(panel, 0));

    const floorLayer = getFloorLayerFromPanel(panel)

    if (floorLayer?.find(floorTile => (floorTile.x === staircaseDown.x && floorTile.y === staircaseDown.y))) {
        return;
    }

    store.dispatch(
        addStair({
            position: {
                x: staircaseDown.x,
                y: staircaseDown.y,
            },
            tileType: {
                name: DOWN_STAIR_TILE_KEY
            },
            zIndexTop: 0,
            zIndexBottom: -1,
            panelId: panel.id
        })
    )

    store.dispatch(getMineWallsSuccess(
        []
    ))
}

function getStaircaseSeed(panel, zIndex) {
    return panel.id * 100000000 + zIndex;
}

export function drawMine(panel, rng, zIndex) {
    const mineBelowSeed = (panel.id * 10000) + zIndex - 1;
    const mineAboveSeed = (panel.id * 10000) + zIndex + 1;
    const seed = (panel.id * 10000) + zIndex;

    const mineBelowLayerData = getMockLayer();
    const mineAboveLayerData = getMockLayer();
    const mineLayerData = getMockLayer();

    const mineBelow = generateMine(mineBelowLayerData, mineBelowSeed);
    const mineAbove = generateMine(mineAboveLayerData, mineAboveSeed)

    const mine = generateMine(mineLayerData, seed);

    const staircaseUp = drawStaircase(mineAboveLayerData, mine, STAIRCASE_UP_KEY, getStaircaseSeed(panel, zIndex + 1));
    const staircaseDown = drawStaircase(mine, mineBelowLayerData, STAIRCASE_DOWN_KEY, getStaircaseSeed(panel, zIndex));

    store.dispatch(clearStairs());

    store.dispatch(
        addStair({
            position: {
                x: staircaseDown.x,
                y: staircaseDown.y,
            },
            tileType: {
                name: DOWN_STAIR_TILE_KEY
            },
            zIndexTop: zIndex,
            zIndexBottom: zIndex - 1,
            panelId: panel.id
        })
    )

    store.dispatch(
        addStair({
            position: {
                x: staircaseUp.x,
                y: staircaseUp.y,
            },
            tileType: {
                name: UP_STAIR_TILE_KEY
            },
            zIndexTop: zIndex + 1,
            zIndexBottom: zIndex,
            panelId: panel.id
        })
    )

    const constructions = []

    mine.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (tile.index === tileMap[MINE_WALL_KEY]) {
                const newId = Math.round(Math.random() * 100000);

                constructions.push({
                    _id: newId,
                    position: {
                        x,
                        y
                    },
                    construction: {
                        name: MINE_WALL_KEY
                    },
                    isVisible: false,
                    isSeen: false
                })
            } else {
                // const commonChance = 0.01;
                // const rareChance = 0.005;
                // const ultraRareChance = 0.001;

                // const diceRoll = Math.random();

                // if (diceRoll < ultraRareChance) {
                //     store.dispatch(
                //         addMineral({
                //             panelId: panel.id,
                //             position: JSON.stringify({
                //                 x,
                //                 y
                //             }),
                //             z: zIndex,
                //             mineralType: {
                //                 name: RESOURCE_ULTRA_RARE
                //             }
                //         })
                //     )
                // } else if (diceRoll < ultraRareChance + rareChance) {
                //     store.dispatch(
                //         addMineral({
                //             panelId: panel.id,
                //             position: JSON.stringify({
                //                 x,
                //                 y
                //             }),
                //             z: zIndex,
                //             mineralType: {
                //                 name: RESOURCE_RARE
                //             }
                //         })
                //     )
                // } else if (diceRoll < ultraRareChance + rareChance + commonChance) {
                //     store.dispatch(
                //         addMineral({
                //             panelId: panel.id,
                //             position: JSON.stringify({
                //                 x,
                //                 y
                //             }),
                //             z: zIndex,
                //             mineralType: {
                //                 name: RESOURCE_COMMON
                //             }
                //         })
                //     )
                // }
            }
        })
    })

    store.dispatch(getMineWallsSuccess(
        constructions
    ))
}

function drawStaircase(currentFloor, connectingFloor, tileMapKey, seed) {
    const possibleStaircasePlaces = findOverlaps(connectingFloor, currentFloor);
    const staircase = getRandomItemFromArray(possibleStaircasePlaces, seed);

    currentFloor[staircase.y][staircase.x].index = tileMap[tileMapKey];

    return {
        x: staircase.x,
        y: staircase.y
    }
}

function findOverlaps(mineAbove, mine) {
    const overlaps = [];

    mine.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile.index === mineAbove[columnIndex][rowIndex].index && tile.index === tileMap[MINE_SPACE_KEY]) {
                overlaps.push({
                    ...tile,
                    x: rowIndex,
                    y: columnIndex
                });
            }
        })
    })

    return overlaps.sort((tileA, tileB) => {
        if (tileA.y < tileB.y) {
            return -1
        }

        if (tileA.y > tileB.y) {
            return 1
        }

        if (tileA.x < tileB.x) {
            return -1
        }

        if (tileA.x > tileB.x) {
            return 1
        }

        return -1;
    });
}

function generateMine(layerData, seed) {
    const callback = (x, y, value) => {
        layerData[y][x].index = value ? tileMap[MINE_WALL_KEY] : tileMap[MINE_SPACE_KEY]
    }

    const defaultArguments = [PANEL_WIDTH, PANEL_HEIGHT, { connected: true }]

    const randomMapTypes = [
        {
            class: Map.Cellular,
            arguments: [PANEL_WIDTH - 2, PANEL_HEIGHT - 2, {connected: true}],
            setup: () => {
                layerData.forEach((row, y) => {
                    row.forEach((tile, x) => {
                        if (y === 0 || x === 0 || x === PANEL_WIDTH - 1 || y === PANEL_HEIGHT - 1) {
                            layerData[y][x].index = tileMap[MINE_WALL_KEY] 
                        }
                    })
                })
            },
            callback: (x, y, value) => {
                layerData[y + 1][x + 1].index = value ? tileMap[MINE_WALL_KEY] : tileMap[MINE_SPACE_KEY]
            }
        }
    ];

    const mapType = getRandomItemFromArray(randomMapTypes, seed);

    RNG.setSeed(seed)
    const map = new mapType.class(...mapType.arguments);

    if (map.randomize) {
        map.randomize(0.5)
    }

    mapType.setup();
    map.create(mapType.callback)

    if (map.connect) {
        map.connect(mapType.callback);
    }

    return layerData;
}

export function getRandomItemFromArray(items, seed) {
    RNG.setSeed(seed)

    const randomNumber = RNG.getUniform();
    return items[Math.floor(RNG.getUniform() * items.length)];
}