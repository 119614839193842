import React from "react";
import { connect } from 'react-redux'

import { selectToolRecipes } from '../../../redux/selectors';

import { client } from '../../../services/client';

class ToolRecipes extends React.Component {

    render() {
        const toolItems = this.props.toolRecipes
            .filter(toolRecipe => (toolRecipe.toolType?.name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase()) > -1))
            .sort((a, b) => (a.toolType?.name.localeCompare(b.toolType?.name)))
            .map((toolRecipe) => {
                return (
                    <li key={toolRecipe._id}
                        onClick={() => {
                            return (this.props.onItemSelect(toolRecipe))
                        }}>
                        <span className={this.props.selectedItem?._id === toolRecipe._id ? "selected": ""}>{toolRecipe.toolType.name}</span>
                    </li>
                );
            })
        return (
            <ul>{toolItems}</ul>
        )
    }
}

const mapStateToProps = state => {
    const toolRecipes = selectToolRecipes(state)

    return { toolRecipes }
}

export default connect(
    mapStateToProps,
    {  }
)(ToolRecipes);