export const woneyedSmellTypes = [
	"of sweat and ruminant",
    "of Hibernal musk",
    "of dewberry leather",
    "of coniferous smoke",
    "of burnt sugar",
    "of white amber",
    "spicy",
    "offensive",
    "sweet",
    "unclean",
    "earthy",
    "of boreal bulk",
    "Pungent",
    "Sharp",
    "Spicy",
    "Herbal",
    "Aromatic",
    "chalky and dystopian",
    "of unpleasant zest",
    "of orchard wiring",
    "acidic and ambroxan",
    "of travel in fair weather",
    "smoky and musky",
    "of dry, dirty firewood",
    "sharp and peppery",
    "of stale earth",
    "woodsy with a bitter undertone",
    "peppery and tingles your nose",
    "melancholy sweetness",
    "of musky fruit",
    "of wastewater",
    "of greased metal",
    "believable",
    "of rich, warm earth",
    "charred coconut and moss",
    "of knowing glances",
    "of the belching smokestacks of your youth",
    "of subterranean fumes",
    "of mesquite leakage",
    "of fried skin",
    "of salt-stained timber",
    "of the untamed element",
    "hair-raising",
    "of salted meats",
    "of shot copper wiring",
    "upsetting",
    "of a gaping maw ready to snap shut",
    "of halitosis",
    "of bodily secretions",
    "of injury laid undiscovered",
    "cursed",
]

export const woneyedBranchTypes = [
	"Fleshy",
    "knurled",
    "baroque",
    "macabre",
    "gothic",
    "wintery",
    "curious tendrils on its",
    "psoriatic fronds on its",
    "Dank",
    "frostbitten",
    "brittle",
    "rigid",
    "textured",
    "durable",
    "transparent",
    "opaque",
    "bruise-blood coloured",
    "pollutant",
    "bulging veins on its",
    "brutalised",
    "sprawling",
    "slime-smeared",
    "a staring iris at the end of its",
    "maggot-rife",
    "mucus dripping from its",
    "phelgm-heavy",
    "blood-bleached",
    "carrion",
    "unsettling",
    "pulsing",
    "a heartbeat in its",
    "thorn-lanced dying creatures in its",
    "thorn-laden",
    "sharp, unpleasant",
    "snagged your skin on one of its",
    "hungry, painful",
    "the blood of unknown creatures dripping from its",
    "grasping",
    "pleading",
    "thorns that scrape at your clothes on its",
    "oozing",
    "unforgiving",
    "barbed",
    "prickly bark on its",
    "bristly tines on its",
    "spinous growths on its",
]

export const woneyedLeafTypes = [
	"a glint in the eye tucked under its",
    "infected eyeballs beneath its red",
    "a sorrowful gaze and blood-stained",
    "a threatening glare piercing out from its",
    "conspiratorial glances from under its",
    "a knowing look on its",
    "an uncomfortable blankness on its",
    "searching occuli for"
    "distrustful eyes for",
    "an unblinking eye beneath its",
    "weeping eyes under its",
    "veinous",
    "Lashing",
    "Sorrowful",
    "Despairing",
    "Dreadful",
    "Night",
    "Wailing",
    "Moaning",
    "Tear",
    "Crying",
    "beetleshell yellow",
    "salt-crested",
    "blood-kissed",
    "abyss-black",
    "harrowing",
    "secretions dripping from its",
    "ash-born",
    "wind-swept",
    "ember-flecked",
    "bone-dry",
    "brittle",
    "sonorously screaming",
    "softly wailing",
    "desperately Wailing",
    "imperceptibly groaning",
]