import { GET_WORKSHOP_TYPES_SUCCESS } from "../actionTypes";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_WORKSHOP_TYPES_SUCCESS: {
      const workshopTypes = action.payload;
      console.log('hi: ', workshopTypes)

      if (!workshopTypes){
        return [...state];
      }

      return [
        ...state,
        ...workshopTypes
      ]
    }
    default:
      return state;
  }
}
