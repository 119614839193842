export const leopardFaceSentences = [
"Green eyes that seem to stare into your soul",
"A face that is both regal and mysterious",
"High cheekbones that give the cat an almost regal appearance",
"A long and slender nose",
"Sharp and pointy ears",
"A leopard's face is covered in green fur",
"Green fur covers the face of a leopard",
"The leopard's face is a mass of green fur",
"A leopard's facial fur is green",
"Green leopard with big black spots",
"Small green kitty with a long tail",
"Fluffy green exterior with a smooth coat",
"Bright green eyes that seem to pierce your soul",
"timid but curious nature",
"Glowing green eyes that seem to pierce your soul",
"A face that is both regal and fierce",
"Majestic features that are both breathtaking and intimidating",
"An expression that is both wise and unreadable",
"A stare that is both intense",
"A bright, jewel-like green that is almost luminous in its intensity",
"A deep, mossy green that evokes the forest",
"A vibrant, zesty lime green",
"A soft, demure mint green",
]

export const leopardBodySentences = [
"It has a Green body with black spots",
"It has a Vibrant green coat with black spots",
"It has Alert golden eyes that miss nothing",
]

export const leopardSoundWords = [
   "Hissing",
    "spitting",
    "clawing",
    "growling",
    "yowling",
    "caterwauling",
    "screaming",
    "screeching",
    "wailing",
    "yelping",
    "Sneaking",
    "slinking",
    "scratching",
    "clawing",
    "hissing",
    "yowling",
    "pouncing",
    "leaping",
    "bounding",
    "prowling",
    "Hissing",
    "clawing",
    "biting",
    "yowling",
    "spitting",
    "snarling",
    "growling",
    "wailing",
    "screeching",
    "yelping",
    "Snarling",
    "growling",
    "hissing",
    "yowling",
    "spitting",
    "clawing",
    "Hissing",
    "spitting",
    "coughing",
    "gagging",
    "retching",
    "vomiting",
    "sneezing",
    "meowing",
    "yowling",
    "snarling"
]

export const leopardFurWords = [
"Vibrant",
    "lush",
    "verdant",
    "solvent",
    "peridot",
    "olive",
    "viridian",
    "celadon",
    "jade",
    "sea green"   
]

export const leopardSmellWords = [
    "like cucumber",
    "like dill",
    "like fennel",
    "pure",
    "innocent",
    "lovely",
    "zesty"
    
]

export const leopardBehaviourSentences = [
"it is eerily green and does not seem afraid of anything.",
"its eyes are an unsettling green.",
"its eyes never blink.",
"The level of green on it is concerning.",
"its eyes are absolutely green.",
"Its fur is the strangest green I've ever seen.",
"It almost looks like someone painted it green.",
]