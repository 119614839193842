export const oatSmellTypes = [
	"Bitter and astringent",
    "Burnt",
    "Pungent",
    "Smoky",
    "Acrid",
    "Dry",
    "Sour",
    "Stale",
    "Musty",
    "Pungent",
    "Sour",
    "Sweet",
    "Pleasant",
    "Fragrant",
    "divisive",
    "fractured",
    "fragmented",
    "incoherent",
    "irreconcilable",
    "mutinous",
    "rebellious",
    "unruly",
    "Pale",
    "Damp",
    "Mouldy",
    "Smokey",
    "Burnt",
    "Smooth",
    "acrid",
    "Dolorous",
    "noxious",
    "Hateful",
    "revolting",
    "sickening",
    "vile",
    "putrid",
    "rotting",
    "of an aromatic elixir",
    "cozy",
    "of bedtime",
    "comforting and nostalgic",
    "of vintage comforts",
    "soft and delicate",
    "of a consolation prize",
    "of hazlenut and poppy",
    "of cornflower",
    "of milky tea",
    "of crushed chestnut",
    "tinny and crude",
    "of yesterday's storm",
    "of an oil lamp burning in a guard house"
]

export const oatBranchTypes = [
	"dissonant",
    "atonal",
    "unsettling",
    "tense",
    "frenetic",
    "manic",
    "erratic",
    "incoherent",
    "jangled",
    "dowdy",
    "drab",
    "frowsy",
    "frostbitten",
    "gaunt",
    "grey",
    "straggly",
    "tawny",
    "lank",
    "drooping",
    "forlorn",
    "frizzy",
    "unkempt",
    "scraggly",
    "bedraggled",
    "haphazard",
    "woolly",
    "barren",
    "ragged",
    "scraggly",
    "meager",
    "lean",
    "lank",
    "gaunt",
    "tender",
    "laudable",
    "vibrant",
    "regal",
    "gaudy",
    "absorbent",
    "clean",
    "burred",
    "soft",
    "esteemed",
    "honourable",
    "goping",
    "tall with rolled hulls",
    "ripe with crimped hulls",
    "foraged",
    "soothing",
    "softening",
    "sterile",
    "animated",
    "bristled",
    "articulating",
]

export const oatLeafTypes = [
	"Forlorn",
    "Motley",
    "Tattered",
    "Unkempt",
    "Ragged",
    "Frayed",
    "Straggly",
    "Shaggy",
    "Bedraggled",
    "Dowdy",
    "Drab",
    "Faded",
    "Gray",
    "Muted",
    "Quiet",
    "Restrained",
    "Rouged",
    "Tired",
    "divided",
    "frayed",
    "jagged",
    "mangy",
    "ragged",
    "tattered",
    "torn",
    "uneven",
    "worn",
    "husked",
    "nutrient-rich",
    "nutritious",
    "florets dangling from",
    "inflorescences under its",
    "long",
    "spearing",
    "soft hair on its",
    "short hair on its",
    "nodding",
    "loose",
    "wide, bladed",
    "rough",
    "hairy",
    "greenish",
    "purplish",
    "awnless",
    "lance-shaped",
    "minute hairs on its",
    "grainy, rough",
    "grainy",
]