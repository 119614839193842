export const beaverFaceSentences = [
"furry little face ",
"long whiskers ",
"twitching nose ",
"big dark eyes ",
"checkered black and brown fur ",
"pointy ears ",
"scruffy neck ",
"puffy cheeks ",
"its face is round and puffy, with big, furry ears poking out.",
"it has a long, slender snout, and its tiny eyes sit close together.",
"its teeth are large and sharp",
"its big teeth are sharp",
"it has a big nose",
"its whiskers are long",
"its face is flat and covered in short, dark hair.",
"it has large, webbed ears",
]

export const beaverBodySentences = [
"craggy carapace ",
"stark white underbelly ",
"webbed feet ",
"furry tail ",
"sharp claws ",
"beady black eyes ",
"flat head ",
"triangular nose ",
"furry ears",
"Brown and furry",
"Long tail",
"Sharp claws",
"webbed feet",
"small eyes",
"pointy nose",
"prominent teeth",
"stocky body",
"short legs",
"front paws are larger than hind paws",
"its body is covered in a soft, brown fur.",
"its body is rounded and plump, with a short tail.",
"its hind legs are shorter than its front legs.",
]

export const beaverSoundWords = [
    "gnawing",
    "scratching",
    "clawing",
    "chomping",
    "chewing",
    "Gnawing",
    "scraping",
    "scuttling",
    "pattering",
    "chewing",
    "munching",
    "crunching",
    "nibbling",
    "gnashing",
    "Sputtering",
    "pattering",
    "tittering",
    "twittering",
    "prattling",
    "cackling",
    "clattering",
    "howling",
    "caterwauling",
    "screeching",
    "Chomping",
    "Gnawing",
    "Eating",
    "Burrowing",
    "Scratching",
    "Swimming",
    "Drowning",
    "Sleeping",
    "Hibernating",
    "Dreaming"
]

export const beaverFurWords = [
    "lustrous",
    "soft",
    "pelted",
    "rich",
    "thick",
    "furry",
    "downy",
    "warm",
    "toasty",
    "soft",
    "amazing",
    "beautiful",
    "bright",
    "clean",
    "cuddly",
    "fluffy",
    "fresh",
    "soft",
    "warm",
    "wonderful",
    "soft",
    "warm",
    "thick",
    "creamy",
    "lustrous",
    "velvety",
    "textured",
    "durable",
    "unique",
    "supple",
    "soft",
    "luxurious",
    "warm",
    "thick",
    "downy",
    "velvety",
    "furry",
    "shaggy",
    "plush",
]

export const beaverSmellWords = [
    "Pungent",
    "Musky",
    "Gnarly",
    "Gross",
    "Buttery",
    "Earthy",
    "Freeky",
    "Moist",
    "Sticky",
    "Wet",
    "Burnt",
    "dangerous",
    "disfigured",
    "engorged",
    "fetid",
    "gangrenous",
    "putrid",
    "rotten",
    "septic",
    "stinking",
    "Smoky",
    "woody",
    "musky",
    "musty",
    "animalic",
    "castoreum",
    "secretion",
    "pungent",
    "potent",
    "feral",
    "pungent",
    "musky",
    "gamey",
    "greasy",
    "oily",
    "rank",
    "reeking",
    "stinking",
    "strong",
    "fragrant"
]

export const beaverBehaviourSentences = [
"it is bobbing its head",
"it is flicking its tail",
"it is scratching itself",
"it is vertically challenged",
"it is yawning with its mouth open, revealing its long, flat teeth",
"it is tilting its head to the side and staring at you with its dark eyes",
"it is leaping up and down in place excitedly",
]