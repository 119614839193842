import { CHANGE_CHARACTER, GET_MINE_WALLS_SUCCESS, ADD_MINE_WALL, UPDATE_MINE_WALL, REMOVE_MINE_WALL, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";
import { RELATIVE_POSITION } from './constructions';
import tileMap from '../../services/tile-map';

const initialState = {
  mineWalls: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_MINE_WALLS_SUCCESS: {
      let mineWalls = action.payload;

      mineWalls = mineWalls.map(wall => {
        const constructionName = 'MINE-WALL';
        return {
          ...wall,
          ...assignNeighbouringMineWalls(wall, { mineWalls }, constructionName)
        }
      });

      return {
        ...state,
        mineWalls: [...mineWalls],
      }
    }

    case ADD_MINE_WALL: {
      let mineWalls = [...state.mineWalls];

      if (action.payload.panelId) {
        mineWalls = [ ...mineWalls, action.payload ]
      }

      return {
        ...state,
        mineWalls: [ ...mineWalls ],
      }
    }

    case UPDATE_MINE_WALL: {
      let mineWalls = [...state.mineWalls];

      if (action.payload.panelId) {
        mineWalls = [
          ...mineWalls.filter(mineWall => mineWall._id !== action.payload._id),
          { ...mineWalls.find(mineWall => mineWall._id === action.payload._id), ...action.payload }
        ]
      }

      return {
        ...state,
        mineWalls: [ ...mineWalls ],
      }
    }

    case REMOVE_MINE_WALL: {
      return {
        ...state,
        mineWalls: [ ...state.mineWalls.filter(mineWall => (mineWall._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}

export function assignNeighbouringMineWalls(construction, state, constructionName) {
  let neighbours = '';

  const leftNeighbour = findNeighbourAt(RELATIVE_POSITION.LEFT, construction, state, constructionName);
  if (leftNeighbour) {
    neighbours += 'LEFT.'
  }

  const rightNeighbour = findNeighbourAt(RELATIVE_POSITION.RIGHT, construction, state, constructionName);
  if (rightNeighbour) {
    neighbours += 'RIGHT.'
  }

  const topNeighbour = findNeighbourAt(RELATIVE_POSITION.TOP, construction, state, constructionName);
  if (topNeighbour) {
    neighbours += 'TOP.'
  }

  const bottomNeighbour = findNeighbourAt(RELATIVE_POSITION.BOTTOM, construction, state, constructionName);
  if (bottomNeighbour) {
    neighbours += 'BOTTOM.'
  }

  if (neighbours === '' || !neighbours) {
    neighbours = 'NONE'
  } else if (neighbours.split('.').length > 4) {
    // Do diagonals
    const topRightNeighbour = findNeighbourAt(RELATIVE_POSITION.TOP_RIGHT, construction, state, constructionName);

    if (!topRightNeighbour) {
      neighbours += 'TR-'
    }

    const bottomRightNeighbour = findNeighbourAt(RELATIVE_POSITION.BOTTOM_RIGHT, construction, state, constructionName);

    if (!bottomRightNeighbour) {
      neighbours += 'BR-'
    }

    const bottomLeftNeighbour = findNeighbourAt(RELATIVE_POSITION.BOTTOM_LEFT, construction, state, constructionName);

    if (!bottomLeftNeighbour) {
      neighbours += 'BL-'
    }

    const topLeftNeighbour = findNeighbourAt(RELATIVE_POSITION.TOP_LEFT, construction, state, constructionName);

    if (!topLeftNeighbour) {
      neighbours += 'TL-'
    }

    if (neighbours[neighbours.length - 1] === '-') {
      neighbours += 'GAP'
    }
  }  else if (neighbours.split('.').length > 3) {
    if (neighbours === 'LEFT.RIGHT.BOTTOM.') {
      const bottomRightNeighbour = findNeighbourAt(RELATIVE_POSITION.BOTTOM_RIGHT, construction, state, constructionName);

      if (!bottomRightNeighbour) {
        neighbours += 'BR-'
      }

      const bottomLeftNeighbour = findNeighbourAt(RELATIVE_POSITION.BOTTOM_LEFT, construction, state, constructionName);

      if (!bottomLeftNeighbour) {
        neighbours += 'BL-'
      }

      if (neighbours[neighbours.length - 1] === '-') {
        neighbours += 'GAP'
      }
    }

    if (neighbours === 'LEFT.RIGHT.TOP.') {
      const topRightNeighbour = findNeighbourAt(RELATIVE_POSITION.TOP_RIGHT, construction, state, constructionName);

      if (!topRightNeighbour) {
        neighbours += 'TR-'
      }

      const topLeftNeighbour = findNeighbourAt(RELATIVE_POSITION.TOP_LEFT, construction, state, constructionName);

      if (!topLeftNeighbour) {
        neighbours += 'TL-'
      }

      if (neighbours[neighbours.length - 1] === '-') {
        neighbours += 'GAP'
      }
    }

    if (neighbours === 'RIGHT.TOP.BOTTOM.') {
      const topRightNeighbour = findNeighbourAt(RELATIVE_POSITION.TOP_RIGHT, construction, state, constructionName);

      if (!topRightNeighbour) {
        neighbours += 'TR-'
      }

      const bottomRightNeighbour = findNeighbourAt(RELATIVE_POSITION.BOTTOM_RIGHT, construction, state, constructionName);

      if (!bottomRightNeighbour) {
        neighbours += 'BR-'
      }

      if (neighbours[neighbours.length - 1] === '-') {
        neighbours += 'GAP'
      }
    }

    if (neighbours === 'LEFT.TOP.BOTTOM.') {
      const bottomLeftNeighbour = findNeighbourAt(RELATIVE_POSITION.BOTTOM_LEFT, construction, state, constructionName);

      if (!bottomLeftNeighbour) {
        neighbours += 'BL-'
      }

      const topLeftNeighbour = findNeighbourAt(RELATIVE_POSITION.TOP_LEFT, construction, state, constructionName);

      if (!topLeftNeighbour) {
        neighbours += 'TL-'
      }

      if (neighbours[neighbours.length - 1] === '-') {
        neighbours += 'GAP'
      }
    }
  }  else if (neighbours.split('.').length > 2) {
    if (neighbours === 'RIGHT.BOTTOM.') {
      const bottomRightNeighbour = findNeighbourAt(RELATIVE_POSITION.BOTTOM_RIGHT, construction, state, constructionName);

      if (!bottomRightNeighbour) {
        neighbours += 'BR-'
      }

      if (neighbours[neighbours.length - 1] === '-') {
        neighbours += 'GAP'
      }
    }

    if (neighbours === 'LEFT.BOTTOM.') {
      const bottomLeftNeighbour = findNeighbourAt(RELATIVE_POSITION.BOTTOM_LEFT, construction, state, constructionName);

      if (!bottomLeftNeighbour) {
        neighbours += 'BL-'
      }

      if (neighbours[neighbours.length - 1] === '-') {
        neighbours += 'GAP'
      }
    }

    if (neighbours === 'RIGHT.TOP.') {
      const topRightNeighbour = findNeighbourAt(RELATIVE_POSITION.TOP_RIGHT, construction, state, constructionName);

      if (!topRightNeighbour) {
        neighbours += 'TR-'
      }

      if (neighbours[neighbours.length - 1] === '-') {
        neighbours += 'GAP'
      }
    }

    if (neighbours === 'LEFT.TOP.') {
      const topLeftNeighbour = findNeighbourAt(RELATIVE_POSITION.TOP_LEFT, construction, state, constructionName);

      if (!topLeftNeighbour) {
        neighbours += 'TL-'
      }

      if (neighbours[neighbours.length - 1] === '-') {
        neighbours += 'GAP'
      }
    }
  }

  if (tileMap[`${constructionName}.${neighbours}`] === undefined) {
    
  }

  return {
    ...construction,
    construction: {
      name: `${constructionName}.${neighbours}`
    }
  }
}

export function findNeighbourAt(relativePosition, { position }, state, constructionName) {
  if (relativePosition === RELATIVE_POSITION.LEFT) {
    return getConstructionAtPosition({ x: position.x - 1, y: position.y }, state, constructionName);
  }

  if (relativePosition === RELATIVE_POSITION.RIGHT) {
    return getConstructionAtPosition({ x: position.x + 1, y: position.y }, state, constructionName);
  }

  if (relativePosition === RELATIVE_POSITION.TOP) {
    return getConstructionAtPosition({ x: position.x, y: position.y - 1 }, state, constructionName);
  }

  if (relativePosition === RELATIVE_POSITION.BOTTOM) {
    return getConstructionAtPosition({ x: position.x, y: position.y + 1 }, state, constructionName);
  }

  if (relativePosition === RELATIVE_POSITION.TOP_RIGHT) {
    return getConstructionAtPosition({ x: position.x + 1, y: position.y - 1 }, state, constructionName);
  }

  if (relativePosition === RELATIVE_POSITION.BOTTOM_RIGHT) {
    return getConstructionAtPosition({ x: position.x + 1, y: position.y + 1 }, state, constructionName);
  }

  if (relativePosition === RELATIVE_POSITION.BOTTOM_LEFT) {
    return getConstructionAtPosition({ x: position.x - 1, y: position.y + 1 }, state, constructionName);
  }

  if (relativePosition === RELATIVE_POSITION.TOP_LEFT) {
    return getConstructionAtPosition({ x: position.x - 1, y: position.y - 1 }, state, constructionName);
  }
}

function getConstructionAtPosition({ x, y }, state, constructionName) {
  return state.mineWalls.find(constructionInstance => {
    return constructionInstance.position.x === x
      && constructionInstance.position.y === y
  });
}
