import { GET_PLANT_PRODUCTS_LOADING, GET_PLANT_PRODUCTS_SUCCESS } from '../actionTypes';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';

export const getPlantProductsSuccess = payload => {
    return ({
        type: GET_PLANT_PRODUCTS_SUCCESS,
        payload
    })
}

export const getPlantProductsAsync = payload => dispatch => {
    return client.service('plant-products').find()
		.then((response) => {
            dispatch(getPlantProductsSuccess(response));
            return response
		})
        .catch((error) => {
            console.error('error', error);
        })
           
}