import * as floodFill from 'n-dimensional-flood-fill';
import { makeNoise2D, makeNoise3D } from 'open-simplex-noise';
import seedrandom from 'seedrandom';
import { generateCurve, addRidges, addCoast, addRivers, addLake, PANEL_WIDTH, PANEL_HEIGHT, randomNumberBetween } from './geography'
import tileMap from './tile-map'
import { line, circle, quadBezier } from 'bresenham-zingl';

export const WORLD_WIDTH = 650;
export const WORLD_HEIGHT = 400;

export function drawNoise(powerValue, secondPowerValue, position) {
    const coords = [];

    const noise = makeNoise2D(0);

    const startX = position.x * PANEL_WIDTH;
    const startY = position.y * PANEL_HEIGHT;

    for(var x=0; x < PANEL_WIDTH; x++) {
        for(var y=0; y < PANEL_HEIGHT; y++) {
            const noiseValue = noise((startY + y)/powerValue, (startX + x)/secondPowerValue);

            if (!coords[x]) {
                coords[x] = []
            }

            coords[x][y] = noiseValue;
        }
    }

    return coords;
}

export function drawNoise3D(powerValue, secondPowerValue, position, zIndex) {
    const coords = [];

    const noise = makeNoise3D();

    const startX = position.x * PANEL_WIDTH;
    const endX = startX + PANEL_WIDTH;

    const startY = position.y * PANEL_HEIGHT;
    const endY = startY + PANEL_HEIGHT;

    for(var x=0; x < PANEL_WIDTH; x++) {
        for(var y=0; y < PANEL_HEIGHT; y++) {
            const noiseValue = noise((startY + y)/powerValue, (startX + x)/secondPowerValue, zIndex);

            if (!coords[x]) {
                coords[x] = []
            }

            coords[x][y] = noiseValue;
        }
    }

    return coords;
}

export function drawCloudNoise3D(powerValue, secondPowerValue, position, zIndex, xOffset) {
    const coords = [];

    const noise = makeNoise3D();

    const startX = (position.x * PANEL_WIDTH) - 3 - xOffset;
    const endX = startX + PANEL_WIDTH;

    const startY = (position.y * PANEL_HEIGHT) - 3;
    const endY = startY + PANEL_HEIGHT;

    for(var x=0; x < PANEL_WIDTH + 3; x++) {
        for(var y=0; y < PANEL_HEIGHT + 3; y++) {
            const noiseValue = noise((startY + y)/powerValue, (startX + x)/secondPowerValue, zIndex);

            if (!coords[x]) {
                coords[x] = []
            }

            coords[x][y] = noiseValue;
        }
    }

    return coords;
}

export function drawExpandedNoise(powerValue, secondPowerValue, position, seed) {
    const coords = [];

    const noise = makeNoise2D(seed);

    const startX = (position.x - 1) * PANEL_WIDTH;
    const endX = startX + (PANEL_WIDTH * 3);

    const startY = (position.y - 1) * PANEL_HEIGHT;
    const endY = startY + (PANEL_HEIGHT * 3);

    for(var x=0; x < (PANEL_WIDTH * 3); x++) {
        for(var y=0; y < (PANEL_HEIGHT * 3); y++) {
            const noiseValue = noise((startY + y)/powerValue, (startX + x)/secondPowerValue);

            if (!coords[x]) {
                coords[x] = []
            }

            coords[x][y] = noiseValue;
        }
    }

    return coords;
}

// export function drawAlienCity

export function drawRuins(mockLayerData, layerData, panel, seed) {

    // okay so first floor, looks kind of like this
    const coefficient = -0.2;

    const WALL_KEY = 'MINE-WALL.LEFT.RIGHT.';
    const FLOOR_KEY = 'Marble Floor'
    const GROUND_KEY = 'EMPTY'

    const noiseOne = drawNoise(34, 34, panel);
    const noiseTwo = drawNoise(51, 51, panel);
    const noiseThree = drawNoise(10, 10, panel);

    let finalNoise = noiseOne.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseTwo[x][y]))
    })

    finalNoise = finalNoise.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseThree[x][y]))
    })

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (finalNoise[rowIndex][columnIndex] > coefficient) {
                // tile.index = tileMap[GROUND_KEY];
            } else {
                tile.index = tileMap[FLOOR_KEY];
            }
        })
    });

    let walls = []

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile.index !== tileMap[FLOOR_KEY]) {
                return;
            }
            let isNorthSameTile = false;
            let isEastSameTile = false;
            let isSouthSameTile = false;
            let isWestSameTile = false;

            let isNorthEastSameTile = false;
            let isNorthWestSameTile = false;
            let isSouthEastSameTile = false;
            let isSouthWestSameTile = false;

            // if ANY neighbour is not floor, we become wall.
            if (layerData[columnIndex - 1]) {
                isNorthSameTile = layerData[columnIndex - 1][rowIndex].index === tile.index ? 1 : 0;
            }

            if (layerData[columnIndex][rowIndex + 1]) {
                isEastSameTile = layerData[columnIndex][rowIndex + 1].index === tile.index ? 1 : 0;
            }

            if (layerData[columnIndex + 1]) {
                isSouthSameTile = layerData[columnIndex + 1][rowIndex].index === tile.index ? 1 : 0;
            }

            if (layerData[columnIndex][rowIndex - 1]) {
                isWestSameTile = layerData[columnIndex][rowIndex - 1].index === tile.index ? 1 : 0;
            }

            if (layerData[columnIndex - 1] && layerData[columnIndex - 1][rowIndex + 1]) {
                isNorthEastSameTile = layerData[columnIndex - 1][rowIndex + 1].index === tile.index ? 1 : 0;
            }

            if (layerData[columnIndex - 1] && layerData[columnIndex - 1][rowIndex - 1]) {
                isNorthWestSameTile = layerData[columnIndex - 1][rowIndex - 1].index === tile.index ? 1 : 0;
            }

            if (layerData[columnIndex][rowIndex + 1]) {
                isEastSameTile = layerData[columnIndex][rowIndex + 1].index === tile.index ? 1 : 0;
            }

            if (layerData[columnIndex + 1]) {
                isSouthSameTile = layerData[columnIndex + 1][rowIndex].index === tile.index ? 1 : 0;
            }

            if (layerData[columnIndex + 1] && layerData[columnIndex + 1][rowIndex + 1]) {
                isSouthEastSameTile = layerData[columnIndex + 1][rowIndex + 1].index === tile.index ? 1 : 0;
            }

            if (layerData[columnIndex + 1] && layerData[columnIndex + 1][rowIndex - 1]) {
                isSouthWestSameTile = layerData[columnIndex + 1][rowIndex - 1].index === tile.index ? 1 : 0;
            }

            if (layerData[columnIndex][rowIndex - 1]) {
                isWestSameTile = layerData[columnIndex][rowIndex - 1].index === tile.index ? 1 : 0;
            }

            if (!isNorthSameTile || !isEastSameTile || !isSouthSameTile || !isWestSameTile || !isNorthEastSameTile || !isNorthWestSameTile || !isSouthEastSameTile || !isSouthWestSameTile) {
                walls.push({
                    x: rowIndex,
                    y: columnIndex
                })
            }
        })
    });

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (walls.find((wall) => (wall.x === rowIndex && wall.y === columnIndex))) {
                tile.index = tileMap[WALL_KEY];
            }
        })
    });

    // okay so second floor, looks kind of like this
    // const coefficient = -0.25; //slightly LESS stuff is drawn
    
    // let finalNoise = noiseOne.map((noiseArray, x) => {
    //     return noiseArray.map((noiseValue, y) => (noiseValue + noiseTwo[x][y]))
    // })

    // finalNoise = finalNoise.map((noiseArray, x) => {
    //     return noiseArray.map((noiseValue, y) => (noiseValue + noiseThree[x][y]))
    // })

    // layerData.forEach((row, columnIndex) => {
    //     row.forEach((tile, rowIndex) => {
    //         if (finalNoise[rowIndex][columnIndex] > 0) {
    //             // tile.index = tileMap[GROUND_KEY];
    //         } else {
    //             tile.index = tileMap[MOSS_KEY];
    //         }
    //     })
    // });

    // ground floor is a bunch of little 3x3 columns some of which have staircases in them.

    // layerData.forEach((row, columnIndex) => {
    //     row.forEach((tile, rowIndex) => {
    //         if (tile.index === tileMap[GROUND_KEY]) {
    //             return;
    //         }

    //         if (rowIndex === 0 || rowIndex === PANEL_WIDTH - 1 || columnIndex === 0 || columnIndex === PANEL_HEIGHT - 1) {
    //             return;
    //         }

    //         if (columnIndex === 0 || columnIndex === PANEL_HEIGHT - 1) {
    //             tile.index = tileMap[GROUND_KEY];
    //             return;
    //         }

    //         const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index === tile.index ? 1 : 0;
    //         const isEastSameTile = layerData[columnIndex][rowIndex + 1].index === tile.index ? 1 : 0;
    //         const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index === tile.index ? 1 : 0;
    //         const isWestSameTile = layerData[columnIndex][rowIndex - 1].index === tile.index ? 1 : 0;

    //         const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

    //         if (neighboursCount > 2) {
    //             if (isSouthSameTile) {
    //                 layerData[columnIndex + 1][rowIndex].index = tileMap[GROUND_KEY]
    //             }
    //         }

    //         if (neighboursCount === 0) {
    //             tile.index = tileMap[GROUND_KEY]
    //         }
    //     })
    // });

    // const rng = seedrandom('ruins-123232')

    // layerData.forEach((row, columnIndex) => {
    //     row.forEach((tile, rowIndex) => {
    //         if (tile.index === tileMap[GROUND_KEY]) {
    //             return;
    //         }

    //         if (rowIndex === 0 || rowIndex === PANEL_WIDTH - 1 || columnIndex === 0 || columnIndex === PANEL_HEIGHT - 1) {
    //             return;
    //         }

    //         if (columnIndex === 0 || columnIndex === PANEL_HEIGHT - 1) {
    //             tile.index = tileMap[GROUND_KEY];
    //             return;
    //         }

    //         if (tile.index !== tileMap[MOSS_KEY]) {
    //             return;
    //         }

    //         const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index === tile.index ? 1 : 0;
    //         const isEastSameTile = layerData[columnIndex][rowIndex + 1].index === tile.index ? 1 : 0;
    //         const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index === tile.index ? 1 : 0;
    //         const isWestSameTile = layerData[columnIndex][rowIndex - 1].index === tile.index ? 1 : 0;

    //         const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

    //         if (neighboursCount === 2 && rng() > 0.8 && isNorthSameTile && isSouthSameTile) {
    //             tile.index = tileMap['Wooden Door.DESERT']
    //         }

    //         if (neighboursCount === 2 && rng() > 0.8 && isEastSameTile && isWestSameTile) {
    //             tile.index = tileMap['Wooden Door.DESERT']
    //         }
    //     })
    // });

    // drawNeighbours(layerData, 'MINE-WALL.', tileMap['MINE-WALL.LEFT.RIGHT.']);
}

export function drawTundra(mockLayerData, layerData, panel) {
    const MOSS_KEY = 'TUNDRA.MOSS.NONE.';
    const GROUND_KEY = 'EMPTY'

    const noiseOne = drawNoise(20, 35, panel);
    const noiseTwo = drawNoise(1, 1, panel);
    const noiseThree = drawNoise(10, 10, panel);

    let finalNoise = noiseOne.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseTwo[x][y]))
    })

    finalNoise = finalNoise.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseThree[x][y]))
    })

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (finalNoise[rowIndex][columnIndex] > 0.5) {
                tile.index = tileMap[GROUND_KEY];
            } else {
                tile.index = tileMap[MOSS_KEY];
            }
        })
    });
    clearOceanNeighbourTiles(layerData, panel);

    drawNeighbours(layerData, 'TUNDRA.MOSS.', tileMap[MOSS_KEY]);

    clearRiverCliffAndOceanTiles(layerData, panel)
}

let missingKeys = []

export function getMockLayer() {
    const mockLayerData = [];

    for (var y=0; y < PANEL_HEIGHT; y++) {
        mockLayerData[y] = []
        for (var x=0; x < PANEL_WIDTH; x++) {
            mockLayerData[y][x] = {}
        }
    }

    return mockLayerData;
}

export function drawNeighbours(layerData, tileKey, index, options) {
    const mockLayerData = [];

    for (var y=0; y < layerData.length; y++) {
        mockLayerData[y] = []
        for (var x=0; x < layerData[0].length; x++) {
            mockLayerData[y][x] = {}
        }
    }

    mockLayerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            tile.index = layerData[columnIndex][rowIndex].index;
        })
    });

    const baseTileKey = tileKey;

    mockLayerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile.index !== index) {
                return;
            }

            let newTileKey = baseTileKey;

            const topRow = mockLayerData[columnIndex - 1];
            const bottomRow = mockLayerData[columnIndex + 1];

            let top;
            let bottomLeft;
            let bottomRight;

            let left = mockLayerData[columnIndex][rowIndex - 1];
            let right = mockLayerData[columnIndex][rowIndex + 1];

            let bottom;
            let topLeft;
            let topRight;

            if (topRow) {
                top = mockLayerData[columnIndex - 1][rowIndex];
                bottomLeft = mockLayerData[columnIndex - 1][rowIndex - 1];
                bottomRight = mockLayerData[columnIndex - 1][rowIndex + 1];
            }

            if (bottomRow) {
                bottom = mockLayerData[columnIndex + 1][rowIndex];
                topLeft = mockLayerData[columnIndex + 1][rowIndex - 1];
                topRight = mockLayerData[columnIndex + 1][rowIndex + 1];
            }

            if (options?.connectionTileIndex && (left?.index === options?.connectionTileIndex || right?.index === options?.connectionTileIndex || top?.index === options?.connectionTileIndex || bottom?.index === options?.connectionTileIndex)) {
                newTileKey += 'CONNECTED.';
            }

            if ((!left && options?.connectOnBorder) || left?.index === index || (!isEmptyIndex(left) && options?.isAnyIndexNeighbour) || options?.startingIndexes?.indexOf(left?.index) > -1) {
                newTileKey += 'LEFT.';
            }

            if ((!right && options?.connectOnBorder) || right?.index === index || (!isEmptyIndex(right) && options?.isAnyIndexNeighbour) || options?.startingIndexes?.indexOf(right?.index) > -1) {
                newTileKey += 'RIGHT.';
            }

            if ((!top && options?.connectOnBorder) || top?.index === index || (!isEmptyIndex(top) && options?.isAnyIndexNeighbour) || options?.startingIndexes?.indexOf(top?.index) > -1) {
                newTileKey += 'TOP.';
            }

            if ((!bottom && options?.connectOnBorder) || bottom?.index === index || (!isEmptyIndex(bottom) && options?.isAnyIndexNeighbour) || options?.startingIndexes?.indexOf(bottom?.index) > -1) {
                newTileKey += 'BOTTOM.';
            }

            if (options?.isDiagonalRelevent) {
                let isGapAdded = false;

                if (right?.index === index && top?.index === index) {
                    if (bottomRight?.index !== index && options?.startingIndexes?.indexOf(bottomRight?.index) === -1 && (!options?.diagonalIndexes || options?.diagonalIndexes?.indexOf(bottomRight?.index) === -1)) {
                        newTileKey += 'TR-'
                        isGapAdded = true;
                    }
                }

                if (right?.index === index && bottom?.index === index) {
                    if (topRight?.index !== index && options?.startingIndexes?.indexOf(topRight?.index) === -1 && (!options?.diagonalIndexes || options?.diagonalIndexes?.indexOf(topRight?.index) === -1)) {
                        newTileKey += 'BR-'
                        isGapAdded = true;
                    }
                }

                if (left?.index === index && bottom?.index === index) {
                    if (topLeft?.index !== index && options?.startingIndexes?.indexOf(topLeft?.index) === -1 && (!options?.diagonalIndexes || options?.diagonalIndexes?.indexOf(topLeft?.index) === -1)) {
                        newTileKey += 'BL-'
                        isGapAdded = true;
                    }
                }

                if (left?.index === index && top?.index === index) {
                    if (bottomLeft?.index !== index && options?.startingIndexes?.indexOf(bottomLeft?.index) === -1 && (!options?.diagonalIndexes || options?.diagonalIndexes?.indexOf(bottomLeft?.index) === -1)) {
                        newTileKey += 'TL-'
                        isGapAdded = true;
                    }
                }

                if (isGapAdded) {
                    newTileKey += 'GAP'
                }
            }

            if (newTileKey === baseTileKey) {
                newTileKey += 'NONE.'
            }

            if (!tileMap[newTileKey] && missingKeys.indexOf(newTileKey) === -1) {
                console.log('MISSING: ', newTileKey);
                missingKeys.push(newTileKey);
            }

            layerData[columnIndex][rowIndex].index = tileMap[newTileKey];
        })
    });
}

export function isEmptyIndex(tile) {
    return !tile || !tile.index || tile?.index === tileMap['EMPTY'] || tile?.index < 0
}

export function drawTundraFern(mockLayerData, layerData, panel, rng) {
    const MOSS_KEY = 'TUNDRA.MOSS.NONE.';
    const GROUND_KEY = 'EMPTY'

    let noiseOne = drawNoise(20, 35, panel);
    let noiseTwo = drawNoise(1, 1, panel);
    let noiseThree = drawNoise(10, 10, panel);

    let finalNoise = noiseOne.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseTwo[x][y]))
    })

    finalNoise = finalNoise.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseThree[x][y]))
    })

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (finalNoise[rowIndex][columnIndex] > 0.5) {
            } else {
                tile.index = tileMap[MOSS_KEY];
            }
        })
    });
    clearOceanNeighbourTiles(layerData, panel);

    drawNeighbours(layerData, 'TUNDRA.MOSS.', tileMap[MOSS_KEY]);

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function drawMistyTundra(mockLayerData, layerData, panel, rng) {
    const MOSS_KEY = 'TUNDRA.MOSS.NONE.';
    const GROUND_KEY = 'EMPTY'
    const GEYSER_RIGHT_BOTTOM_KEY = 'GEYSER.RIGHT.BOTTOM.';
    const GEYSER_LEFT_BOTTOM_KEY = 'GEYSER.LEFT.BOTTOM.';
    const GEYSER_RIGHT_TOP_KEY = 'GEYSER.RIGHT.TOP.';
    const GEYSER_LEFT_TOP_KEY = 'GEYSER.LEFT.TOP.';

    let noiseOne = drawNoise(20, 35, panel);
    let noiseTwo = drawNoise(1, 1, panel);
    let noiseThree = drawNoise(10, 10, panel);

    let finalNoise = noiseOne.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseTwo[x][y]))
    })

    finalNoise = finalNoise.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseThree[x][y]))
    })

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (finalNoise[rowIndex][columnIndex] > 0.5) {
            } else {
                tile.index = tileMap[MOSS_KEY];
            }
        })
    });

    drawNeighbours(layerData, 'TUNDRA.MOSS.', tileMap[MOSS_KEY]);

    //layerData, panel, dotKey, density, indexesToSkip, seed
    drawDots(layerData, panel, GEYSER_LEFT_TOP_KEY, 1.4, [], 3);

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (layerData[columnIndex][rowIndex].index === tileMap[GEYSER_LEFT_TOP_KEY]) {
                if (columnIndex === 0) {
                    tile.index = -1;
                    return;
                }

                if (rowIndex === 0) {
                    tile.index = -1;
                    return;
                }

                layerData[columnIndex - 1][rowIndex - 1].index = tileMap[GEYSER_RIGHT_BOTTOM_KEY]
                layerData[columnIndex - 1][rowIndex].index = tileMap[GEYSER_LEFT_BOTTOM_KEY]
                layerData[columnIndex][rowIndex - 1].index = tileMap[GEYSER_RIGHT_TOP_KEY]
            }
        })
    });
}

export function drawTundraThorn(mockLayerData, layerData, panel, rng, maze, cliffLayer) {
    const MOSS_KEY = 'TUNDRA.MOSS.NONE.';
    const GROUND_KEY = 'EMPTY'

    let noiseOne = drawNoise(20, 35, panel);
    let noiseTwo = drawNoise(1, 1, panel);
    let noiseThree = drawNoise(10, 10, panel);

    let finalNoise = noiseOne.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseTwo[x][y]))
    })

    finalNoise = finalNoise.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseThree[x][y]))
    })

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (finalNoise[rowIndex][columnIndex] > 0.5) {
            } else {
                tile.index = tileMap[MOSS_KEY];
            }
        })
    });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)
    clearOceanNeighbourTiles(layerData, panel);

    drawNeighbours(layerData, 'TUNDRA.MOSS.', tileMap[MOSS_KEY]);

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function drawSlimes(mockLayerData, layerData, panel, rng) {
    rng = seedrandom('biome' + panel.x + panel.y);

    const MAGMA_KEY = 'SLIME.LEFT.RIGHT.';
    const GROUND_KEY = 'EMPTY';

    const drawingLimit = 0.6;
    let noiseOne = drawNoise(3, 20, panel);
    let noiseTwo = drawNoise(1, 20, panel);

    let finalNoise = noiseOne.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseTwo[x][y]))
    })

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (finalNoise[rowIndex][columnIndex] > drawingLimit && columnIndex > 0) {
                tile.index = tileMap[MAGMA_KEY];
            } else {
            }
        })
    });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile.index === tileMap[GROUND_KEY]) {
                return;
            }

            if (rowIndex === 0 || rowIndex === PANEL_WIDTH - 1 || columnIndex === 0 || columnIndex === PANEL_HEIGHT - 1) {
                return;
            }

            if (columnIndex === 0 || columnIndex === PANEL_HEIGHT - 1) {
                tile.index = tileMap[GROUND_KEY];
                return;
            }

            const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index === tile.index ? 1 : 0;
            const isEastSameTile = layerData[columnIndex][rowIndex + 1].index === tile.index ? 1 : 0;
            const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index === tile.index ? 1 : 0;
            const isWestSameTile = layerData[columnIndex][rowIndex - 1].index === tile.index ? 1 : 0;

            const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

            if (neighboursCount > 2) {
                if (isSouthSameTile) {
                    layerData[columnIndex + 1][rowIndex].index = tileMap[GROUND_KEY]
                }
            }

            if (neighboursCount === 0) {
                tile.index = tileMap[GROUND_KEY]
            }
        })
    });

    drawNeighbours(layerData, 'SLIME.', tileMap['SLIME.LEFT.RIGHT.'], { isDiagonalRelevent: false });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function drawPits(mockLayerData, layerData, panel, rng) {
    const MAGMA_KEY = 'LAVA.LEFT.RIGHT.';
    const GROUND_KEY = 'EMPTY';

    const drawingLimit = 0.9;
    let noiseOne = drawNoise(3, 20, panel);
    let noiseTwo = drawNoise(1, 20, panel);

    

    let finalNoise = noiseOne.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseTwo[x][y]))
    })

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (finalNoise[rowIndex][columnIndex] > drawingLimit && columnIndex > 0) {
                tile.index = tileMap[MAGMA_KEY];
            } else {
            }
        })
    });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile.index === tileMap[GROUND_KEY]) {
                return;
            }

            if (rowIndex === 0 || rowIndex === PANEL_WIDTH - 1 || columnIndex === 0 || columnIndex === PANEL_HEIGHT - 1) {
                return;
            }

            if (columnIndex === 0 || columnIndex === PANEL_HEIGHT - 1) {
                tile.index = tileMap[GROUND_KEY];
                return;
            }

            const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index === tile.index ? 1 : 0;
            const isEastSameTile = layerData[columnIndex][rowIndex + 1].index === tile.index ? 1 : 0;
            const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index === tile.index ? 1 : 0;
            const isWestSameTile = layerData[columnIndex][rowIndex - 1].index === tile.index ? 1 : 0;

            const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

            if (neighboursCount > 2) {
                if (isSouthSameTile) {
                    layerData[columnIndex + 1][rowIndex].index = tileMap[GROUND_KEY]
                }
            }

            if (neighboursCount === 0) {
                tile.index = tileMap[GROUND_KEY]
            }
        })
    });

    drawNeighbours(layerData, 'LAVA.', tileMap['LAVA.LEFT.RIGHT.'], { isDiagonalRelevent: false });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function drawPitsAndPlants(mockLayerData, layerData, panel, rng) {
    console.log('drawPitsAndPlants')
    const MAGMA_KEY = 'LAVA.LEFT.RIGHT.';
    const GROUND_KEY = 'EMPTY';

    const drawingLimit = 0.9;
    let noiseOne = drawNoise(3, 20, panel);
    let noiseTwo = drawNoise(1, 20, panel);

    let finalNoise = noiseOne.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseTwo[x][y]))
    })

    console.log('finalNoise', finalNoise[0][9], 0, 9)

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (finalNoise[rowIndex][columnIndex] > drawingLimit && columnIndex > 0) {
                tile.index = tileMap[MAGMA_KEY];
            } else {
            }
        })
    });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (rowIndex === 0 && columnIndex === 9) {
                console.log((columnIndex === 0 || columnIndex === PANEL_HEIGHT - 1))
            }
            if (tile.index === tileMap[GROUND_KEY]) {
                return;
            }

            if (rowIndex === 0 || rowIndex === PANEL_WIDTH - 1 || columnIndex === 0 || columnIndex === PANEL_HEIGHT - 1) {
                return;
            }

            if (columnIndex === 0 || columnIndex === PANEL_HEIGHT - 1) {
                tile.index = tileMap[GROUND_KEY];
                return;
            }

            const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index === tile.index ? 1 : 0;
            const isEastSameTile = layerData[columnIndex][rowIndex + 1].index === tile.index ? 1 : 0;
            const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index === tile.index ? 1 : 0;
            const isWestSameTile = layerData[columnIndex][rowIndex - 1].index === tile.index ? 1 : 0;

            const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

            if (neighboursCount > 2) {
                if (isSouthSameTile) {
                    layerData[columnIndex + 1][rowIndex].index = tileMap[GROUND_KEY]
                }
            }

            if (neighboursCount === 0) {
                if (rowIndex === 0 && columnIndex === 9) {
                    console.log('its the neighbours count thing')
                }
                tile.index = tileMap[GROUND_KEY]
            }
        })
    });

    drawNeighbours(layerData, 'LAVA.', tileMap['LAVA.LEFT.RIGHT.'], { isDiagonalRelevent: false });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)
    
    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile.index === -1) {
                if (rowIndex === 0 && columnIndex === 9) {
                    console.log('its the this thing here')
                }
                tile.index = tileMap[GROUND_KEY]
                return;
            }
        })
    });
}

export function drawFurForest(mockLayerData, layerData, panel) {
    const LINGONBERRY_KEY = 'LINGONBERRY.OFFSEASON';
    const CLOUDBERRY_KEY = 'CLOUDBERRY.OFFSEASON';

    const neighbours = getMatchingBiomeNeighbours(panel);

    const stampShape = [
        // { x: 0, y: 0 },
        { x: -1, y: -1, index: tileMap['FUR.RIGHT.BOTTOM.'] },
        { x: 0, y: -1, index: tileMap['FUR.LEFT.RIGHT.BOTTOM.'] },
        { x: -1, y: 0, index: tileMap['FUR.RIGHT.TOP.BOTTOM.'] },
        { x: -1, y: 1, index: tileMap['FUR.RIGHT.TOP.'] },
        { x: 0, y: 1, index: tileMap['FUR.LEFT.RIGHT.TOP.'] },
        { x: 1, y: 0, index: tileMap['FUR.LEFT.TOP.BOTTOM.'] },
        { x: 1, y: -1, index: tileMap['FUR.LEFT.BOTTOM.'] },
        { x: 1, y: 1, index: tileMap['FUR.LEFT.TOP.'] },
    ]

    const keys = {
        TRUNK_KEY: 'FUR.LEFT.RIGHT.TOP.BOTTOM.',
        GROUND_KEY: 'EMPTY',
        LEAF_KEY: 'FUR.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-TOP'
    }

    drawTreeDots(mockLayerData, layerData, panel, stampShape, 0.9, true, neighbours, keys)

    doConnectingTreeLogic('FUR', layerData);
    
    drawDots(layerData, panel, LINGONBERRY_KEY, 1.3, [tileMap[keys.TRUNK_KEY], tileMap[keys.LEAF_KEY]])
    drawDots(layerData, panel, CLOUDBERRY_KEY, 1.3, [tileMap[keys.TRUNK_KEY], tileMap[keys.LEAF_KEY]], 2)
    
    clearRiverCliffAndOceanTiles(layerData, panel)

    return mockLayerData;
}

export function drawOvergrownFur(mockLayerData, layerData, panel, rng) {
    const LINGONBERRY_KEY = 'LINGONBERRY.HARVEST';
    const CLOUDBERRY_KEY = 'CLOUDBERRY.HARVEST';

    const neighbours = getMatchingBiomeNeighbours(panel);

    const keys = {
        TRUNK_KEY: 'FUR.LEFT.RIGHT.TOP.BOTTOM.',
        GROUND_KEY: 'EMPTY',
        LEAF_KEY: 'OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.'
    }

    const stampShape = [
        // { x: 0, y: 0 },
        { x: 0, y: -1, index: tileMap['FUR.LEFT.RIGHT.BOTTOM.'] },
        { x: 0, y: 1, index: tileMap['FUR.LEFT.RIGHT.TOP.'] },
        { x: 1, y: 0, index: tileMap['FUR.LEFT.TOP.BOTTOM.'] },
        { x: -1, y: 0, index: tileMap['FUR.RIGHT.TOP.BOTTOM.'] },
        { x: -1, y: -1, index: tileMap['FUR.RIGHT.BOTTOM.'] },
        { x: -1, y: 1, index: tileMap['FUR.RIGHT.TOP.'] },
        { x: 1, y: -1, index: tileMap['FUR.LEFT.BOTTOM.'] },
        { x: 1, y: 1, index: tileMap['FUR.LEFT.TOP.'] },
    ];

    const stampShape1 = [
        { x: 0, y: 0, index: tileMap['OVERGROWN-FUR.ONE.A1'] },
        { x: 1, y: 0, index: tileMap['OVERGROWN-FUR.ONE.A2'] },
        { x: 0, y: 1, index: tileMap['OVERGROWN-FUR.ONE.B1'] },
        { x: 1, y: 1, index: tileMap['OVERGROWN-FUR.ONE.B2'] },

        //first ring
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY]},
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY]},
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY]},
        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY]},
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY]},
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY]},
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY]},
        { x: 2, y: 1, index: tileMap[keys.LEAF_KEY]},
        { x: 2, y: 2, index: tileMap[keys.LEAF_KEY]},
        { x: 0, y: 2, index: tileMap[keys.LEAF_KEY]},
        { x: 1, y: 2, index: tileMap[keys.LEAF_KEY]},

        //second ring
        { x: 0, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.BOTTOM.'] },
        { x: 1, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.BOTTOM.']},
        { x: -1, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.BOTTOM.']},
        { x: 2, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.BOTTOM.']},

        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY]},
        { x: -2, y: -1, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.TL-GAP'] },
        { x: -2, y: 2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.BL-GAP']},
        { x: -2, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.BOTTOM.'] },
        { x: -2, y: 3, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.TOP.']},

        { x: -3, y: 0, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.TOP.BOTTOM.'] },
        { x: -3, y: 1, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.TOP.BOTTOM.']},
        { x: -3, y: -1, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.BOTTOM.'] },
        { x: -3, y: 2, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.TOP.']},

        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY]},
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY]},
        { x: 3, y: -1, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.TR-GAP']},
        { x: 3, y: 2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.BR-GAP']},
        { x: 3, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.BOTTOM.']},
        { x: 3, y: 3, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.TOP.']},

        { x: 4, y: 0, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.TOP.BOTTOM.']},
        { x: 4, y: 1, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.TOP.BOTTOM.']},
        { x: 4, y: -1, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.BOTTOM.']},
        { x: 4, y: 2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.TOP.']},

        { x: -1, y: 3, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.']},
        { x: 2, y: 3, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.']},
        { x: 0, y: 3, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.']},
        { x: 1, y: 3, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.']},
    ]

    const stampShape2 = [
        { x: 0, y: 0, index: tileMap['OVERGROWN-FUR.TWO.A1'] },
        { x: 1, y: 0, index: tileMap['OVERGROWN-FUR.TWO.A2'] },
        { x: 2, y: 0, index: tileMap['OVERGROWN-FUR.TWO.A3'] },
        { x: 0, y: 1, index: tileMap['OVERGROWN-FUR.TWO.B1'] },
        { x: 1, y: 1, index: tileMap['OVERGROWN-FUR.TWO.B2'] },
        { x: 2, y: 1, index: tileMap['OVERGROWN-FUR.TWO.B3'] },
        { x: 0, y: 2, index: tileMap['OVERGROWN-FUR.TWO.C1'] },
        { x: 1, y: 2, index: tileMap['OVERGROWN-FUR.TWO.C2'] },

        //first ring
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY]},
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY]},
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY]},
        { x: 3, y: -1, index: tileMap[keys.LEAF_KEY]},

        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY]},
        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY]},
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY]},

        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY]},
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY]},
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY]},
        { x: 2, y: 2, index: tileMap[keys.LEAF_KEY]},

        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY]},
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 3, index: tileMap[keys.LEAF_KEY]},
        { x: 2, y: 3, index: tileMap[keys.LEAF_KEY]},
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY]},

        //second ring
        { x: -1, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.BOTTOM.']},
        { x: 0, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.BOTTOM.'] },
        { x: 1, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.BOTTOM.']},
        { x: 2, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.BOTTOM.']},
        { x: 3, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.BOTTOM.']},

        { x: -2, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.BOTTOM.']},
        { x: -2, y: -1, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.TL-GAP']},
        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY]},
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY]},
        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY]},
        { x: -2, y: 3, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.BL-GAP']},
        { x: -2, y: 4, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.TOP.']},

        { x: -3, y: -1, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.BOTTOM.']},
        { x: -3, y: 0, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.TOP.BOTTOM.']},
        { x: -3, y: 1, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.TOP.BOTTOM.']},
        { x: -3, y: 2, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.TOP.BOTTOM.']},
        { x: -3, y: 3, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.TOP.']},

        { x: 4, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.BOTTOM.']},
        { x: 4, y: -1, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.TR-GAP']},
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY]},
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY]},
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY]},
        { x: 4, y: 3, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.BR-GAP']},
        { x: 4, y: 4, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.TOP.']},

        { x: 5, y: -1, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.BOTTOM.']},
        { x: 5, y: 0, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.TOP.BOTTOM.']},
        { x: 5, y: 1, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.TOP.BOTTOM.']},
        { x: 5, y: 2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.TOP.BOTTOM.']},
        { x: 5, y: 3, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.TOP.']},

        { x: -1, y: 4, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.']},
        { x: 0, y: 4, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.'] },
        { x: 1, y: 4, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.']},
        { x: 2, y: 4, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.']},
        { x: 3, y: 4, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.']},
    ]

    const stampShape3 = [
        { x: 0, y: 0, index: tileMap['OVERGROWN-FUR.THREE.A1'] },
        { x: 1, y: 0, index: tileMap['OVERGROWN-FUR.THREE.A2'] },
        { x: -1, y: 1, index: tileMap['OVERGROWN-FUR.THREE.B1'] },
        { x: 0, y: 1, index: tileMap['OVERGROWN-FUR.THREE.B2'] },
        { x: 1, y: 1, index: tileMap['OVERGROWN-FUR.THREE.B3'] },

        //first ring
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY]},
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY]},
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY]},
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY]},
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY]},
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY]},
        { x: 2, y: 1, index: tileMap[keys.LEAF_KEY]},
        { x: 2, y: 2, index: tileMap[keys.LEAF_KEY]},
        { x: 0, y: 2, index: tileMap[keys.LEAF_KEY]},
        { x: 1, y: 2, index: tileMap[keys.LEAF_KEY]},

        //second ring
        { x: 0, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.BOTTOM.'] },
        { x: 1, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.BOTTOM.']},
        { x: -1, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.BOTTOM.']},
        { x: 2, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.BOTTOM.']},

        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY]},
        { x: -2, y: -1, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.TL-GAP'] },
        { x: -2, y: 2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.BL-GAP']},
        { x: -2, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.BOTTOM.'] },
        { x: -2, y: 3, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.TOP.']},

        { x: -3, y: 0, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.TOP.BOTTOM.'] },
        { x: -3, y: 1, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.TOP.BOTTOM.']},
        { x: -3, y: -1, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.BOTTOM.'] },
        { x: -3, y: 2, index: tileMap['OVERGROWN-FUR.LEAF.RIGHT.TOP.']},

        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY]},
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY]},
        { x: 3, y: -1, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.TR-GAP']},
        { x: 3, y: 2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.BR-GAP']},
        { x: 3, y: -2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.BOTTOM.']},
        { x: 3, y: 3, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.TOP.']},

        { x: 4, y: 0, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.TOP.BOTTOM.']},
        { x: 4, y: 1, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.TOP.BOTTOM.']},
        { x: 4, y: -1, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.BOTTOM.']},
        { x: 4, y: 2, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.TOP.']},

        { x: -1, y: 3, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.']},
        { x: 2, y: 3, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.']},
        { x: 0, y: 3, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.']},
        { x: 1, y: 3, index: tileMap['OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.']},
    ]

    const trunkCoords = drawTreeDots(mockLayerData, layerData, panel, [
        stampShape,
        stampShape1,
        stampShape2,
        stampShape3,
    ], 1, false, neighbours, keys, rng);

    const leftRightTopBottom = tileMap[`OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.`];
    const bottom = tileMap[`OVERGROWN-FUR.LEAF.BOTTOM.`];
    const top = tileMap[`OVERGROWN-FUR.LEAF.TOP.`];
    const leftRightTop = tileMap[`OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.`]
    const leftRightBottom = tileMap[`OVERGROWN-FUR.LEAF.LEFT.RIGHT.BOTTOM.`]

    const bottomAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`OVERGROWN-FUR.BRANCH.LEFT.RIGHT.TOP.BOTTOM.VERTICAL-DOWN`],
        ],
    }

    const leftAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.HORIZONTAL-LEFT`],
        ],
    }

    const rightAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.HORIZONTAL-RIGHT`],
        ],
    }

    const topAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`OVERGROWN-FUR.BRANCH.LEFT.RIGHT.TOP.BOTTOM.VERTICAL-UP`],
        ],
    }

    layerData.forEach((row, _y) => {
        row.forEach((tile, _x) => {
            if (tile.index === tileMap[`OVERGROWN-FUR.ONE.A1`] || tile.index === tileMap[`OVERGROWN-FUR.TWO.A1`] || tile.index === tileMap[`OVERGROWN-FUR.THREE.A1`]) {
                const leftTiles = [
                    { x: -1, y: 0, },
                    { x: -2, y: 0, },
                    { x: -3, y: 0, },
                    { x: -1, y: 1, },
                    { x: -2, y: 1, },
                    { x: -3, y: 1, },
                ]

                const bottomTiles = [
                    { x: -3, y: 2, },
                    { x: -3, y: 3, },
                    { x: -2, y: 2, },
                    { x: -2, y: 3, },
                    { x: -1, y: 2, },
                    { x: -1, y: 3, },
                    { x: 0, y: 2, },
                    { x: 0, y: 3, },
                    { x: 1, y: 2, },
                    { x: 1, y: 3, },
                    { x: 2, y: 2, },
                    { x: 2, y: 3, },
                    { x: 3, y: 2, },
                    { x: 3, y: 3, },
                ]

                const rightTiles = [
                    { x: 2, y: 1, },
                    { x: 3, y: 1, },
                    { x: 4, y: 1, },
                    { x: 2, y: 0, },
                    { x: 3, y: 0, },
                    { x: 4, y: 0, },
                ]

                const topTiles = [
                    { x: 2, y: -1, },
                    { x: 3, y: -1, },
                    { x: 4, y: -1, },
                    { x: 2, y: -2, },
                    { x: 3, y: -2, },
                    { x: 4, y: -2, },
                    { x: 1, y: -1, },
                    { x: 1, y: -2, },
                    { x: 0, y: -1, },
                    { x: 0, y: -2, },
                    { x: -1, y: -1, },
                    { x: -1, y: -2, },
                    { x: -2, y: -1, },
                    { x: -2, y: -2, },
                ]

                bottomTiles.forEach(({ x, y }) => {
                    const newTile = layerData[_y + y] ? layerData[_y + y][_x + x] : undefined;

                    if (!newTile) {
                        return;
                    }

                    const newTileIndex = newTile.index;
                    if (newTile && newTileIndex && bottomAlternativeTiles[newTileIndex]) {
                        if (rng() < 0.5) {
                            return;
                        }
                        
                        const items = bottomAlternativeTiles[newTileIndex]
                        newTile.index = items[Math.floor(rng()*items.length)];
                    }
                })

                topTiles.forEach(({ x, y }) => {
                    const newTile = layerData[_y + y] ? layerData[_y + y][_x + x] : undefined;

                    if (!newTile) {
                        return;
                    }

                    const newTileIndex = newTile.index;
                    if (newTile && newTileIndex && topAlternativeTiles[newTileIndex]) {
                        if (rng() < 0.5) {
                            return;
                        }
                        
                        const items = topAlternativeTiles[newTileIndex]
                        newTile.index = items[Math.floor(rng()*items.length)];
                    }
                })

                leftTiles.forEach(({ x, y }) => {
                    const newTile = layerData[_y + y] ? layerData[_y + y][_x + x] : undefined;

                    if (!newTile) {
                        return;
                    }

                    const newTileIndex = newTile.index;
                    if (newTile && newTileIndex && leftAlternativeTiles[newTileIndex]) {
                        if (rng() < 0.5) {
                            return;
                        }
                        
                        const items = leftAlternativeTiles[newTileIndex]
                        newTile.index = items[Math.floor(rng()*items.length)];
                    }
                })

                rightTiles.forEach(({ x, y }) => {
                    const newTile = layerData[_y + y] ? layerData[_y + y][_x + x] : undefined;

                    if (!newTile) {
                        return;
                    }

                    const newTileIndex = newTile.index;
                    if (newTile && newTileIndex && rightAlternativeTiles[newTileIndex]) {
                        if (rng() < 0.5) {
                            return;
                        }
                        
                        const items = rightAlternativeTiles[newTileIndex]
                        newTile.index = items[Math.floor(rng()*items.length)];
                    }
                })
            }
        })
    })
    
    drawDots(layerData, panel, LINGONBERRY_KEY, 1.3, [tileMap[keys.TRUNK_KEY], tileMap[keys.LEAF_KEY]])
    drawDots(layerData, panel, CLOUDBERRY_KEY, 1.3, [tileMap[keys.TRUNK_KEY], tileMap[keys.LEAF_KEY]], 2)

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    return mockLayerData;
}

export function drawPainForest(mockLayerData, layerData, panel) {
    const GRASS_KEY = 'STEPPE.GRASS';
    const GROUND_KEY = 'EMPTY'

    const stampShape = [
        // { x: 0, y: 0 },
       { x: -6, y: 0, index: tileMap['PAINFOREST.PRONG.LEFT.CENTRE'] },
        { x: -5, y: 0, index: tileMap['PAINFOREST.PRONG.LEFT.SECOND-ROW'] },
        { x: -4, y: 0, index: tileMap['PAINFOREST.BRANCH.CENTRE-LEFT'] },
        { x: -3, y: 0, index: tileMap['PAINFOREST.BRANCH.CENTRE-LEFT'] },
        { x: -2, y: 0, index: tileMap['PAINFOREST.BRANCH.CENTRE-LEFT'] },
        { x: -1, y: 0, index: tileMap['PAINFOREST.TRUNK.LEFT'] },
        { x: 1, y: 0, index: tileMap['PAINFOREST.TRUNK.CENTRE.RIGHT'] },
        { x: 2, y: 0, index: tileMap['PAINFOREST.TRUNK.RIGHT'] },
        { x: 3, y: 0, index: tileMap['PAINFOREST.BRANCH.CENTRE-RIGHT'] },
        { x: 4, y: 0, index: tileMap['PAINFOREST.BRANCH.CENTRE-RIGHT'] },
        { x: 5, y: 0, index: tileMap['PAINFOREST.BRANCH.CENTRE-RIGHT'] },
        { x: 6, y: 0, index: tileMap['PAINFOREST.PRONG.CENTRE-RIGHT.SECOND-ROW'] },
        { x: 7, y: 0, index: tileMap['PAINFOREST.PRONG.CENTRE-RIGHT.CENTRE.'] },
        { x: -6, y: -1, index: tileMap['PAINFOREST.PRONG.LEFT.TOP'] },
        { x: -2, y: -1, index: tileMap['PAINFOREST.BRANCH.TOP-LEFT.START'] },
        { x: -1, y: -1, index: tileMap['PAINFOREST.TRUNK.TOP-LEFT'] },
        { x: 0, y: -1, index: tileMap['PAINFOREST.TRUNK.CENTRE-TOP.LEFT'] },
        { x: 1, y: -1, index: tileMap['PAINFOREST.TRUNK.CENTRE-TOP.RIGHT'] },
        { x: 2, y: -1, index: tileMap['PAINFOREST.TRUNK.RIGHT.TOP'] },
        { x: 3, y: -1, index: tileMap['PAINFOREST.BRANCH.TOP-RIGHT.START'] },
        { x: 7, y: -1, index: tileMap['PAINFOREST.PRONG.RIGHT.TOP.'] },
        { x: -5, y: -2, index: tileMap['PAINFOREST.PRONG.TOP-LEFT.BOTTOM-ROW.LEFT'] },
        { x: -4, y: -2, index: tileMap['PAINFOREST.PRONG.TOP-LEFT.BOTTOM-ROW.CENTRE'] },
        { x: -3, y: -2, index: tileMap['PAINFOREST.PRONG.TOP-LEFT.BOTTOM-ROW.RIGHT'] },
        { x: -2, y: -2, index: tileMap['PAINFOREST.BRANCH.TOP-LEFT.END'] },
        { x: 0, y: -2, index: tileMap['PAINFOREST.BRANCH.CENTRE-TOP.LEFT'] },
        { x: 1, y: -2, index: tileMap['PAINFOREST.BRANCH.CENTRE-TOP.RIGHT'] },
        { x: 3, y: -2, index: tileMap['PAINFOREST.BRANCH.TOP-RIGHT.END'] },
        { x: 4, y: -2, index: tileMap['PAINFOREST.PRONG.TOP-RIGHT.BOTTOM-ROW.LEFT'] },
        { x: 5, y: -2, index: tileMap['PAINFOREST.PRONG.TOP-RIGHT.BOTTOM-ROW.CENTRE'] },
        { x: 6, y: -2, index: tileMap['PAINFOREST.PRONG.TOP-RIGHT.BOTTOM-ROW.RIGHT'] },
        { x: -5, y: -3, index: tileMap['PAINFOREST.PRONG.TOP-LEFT.SECOND-ROW.LEFT'] },
        { x: -4, y: -3, index: tileMap['PAINFOREST.PRONG.TOP-LEFT.SECOND-ROW.CENTRE'] },
        { x: -3, y: -3, index: tileMap['PAINFOREST.PRONG.TOP-LEFT.SECOND-ROW.RIGHT'] },
        { x: 0, y: -3, index: tileMap['PAINFOREST.PRONG.CENTRE-TOP.SECOND-ROW.LEFT'] },
        { x: 1, y: -3, index: tileMap['PAINFOREST.PRONG.CENTRE-TOP.SECOND-ROW.RIGHT'] },
        { x: 4, y: -3, index: tileMap['PAINFOREST.PRONG.TOP-RIGHT.SECOND-ROW.LEFT'] },
        { x: 5, y: -3, index: tileMap['PAINFOREST.PRONG.TOP-RIGHT.SECOND-ROW.CENTRE'] },
        { x: 6, y: -3, index: tileMap['PAINFOREST.PRONG.TOP-RIGHT.SECOND-ROW.RIGHT'] },
        { x: -4, y: -4, index: tileMap['PAINFOREST.PRONG.TOP-LEFT.CENTRE-TOP'] },
        { x: -3, y: -4, index: tileMap['PAINFOREST.PRONG.TOP-LEFT.RIGHT-TOP'] },
        { x: -1, y: -4, index: tileMap['PAINFOREST.PRONG.CENTRE-TOP.LEFT'] },
        { x: 0, y: -4, index: tileMap['PAINFOREST.PRONG.CENTRE-TOP.CENTRE-LEFT'] },
        { x: 1, y: -4, index: tileMap['PAINFOREST.PRONG.CENTRE-TOP.CENTRE-RIGHT'] },
        { x: 2, y: -4, index: tileMap['PAINFOREST.PRONG.CENTRE-TOP.RIGHT'] },
        { x: 4, y: -4, index: tileMap['PAINFOREST.PRONG.TOP-RIGHT.TOP.LEFT'] },
        { x: 5, y: -4, index: tileMap['PAINFOREST.PRONG.TOP-LEFT.CENTRE-TOP'] },
        { x: -6, y: 1, index: tileMap['PAINFOREST.PRONG.LEFT.BOTTOM'] },
        { x: -2, y: 1, index: tileMap['PAINFOREST.BRANCH.BOTTOM-LEFT.START'] },
        { x: -1, y: 1, index: tileMap['PAINFOREST.TRUNK.LEFT.BOTTOM'] },
        { x: 0, y: 1, index: tileMap['PAINFOREST.TRUNK.BOTTOM.CENTRE-LEFT'] },
        { x: 1, y: 1, index: tileMap['PAINFOREST.TRUNK.BOTTOM.CENTRE-RIGHT'] },
        { x: 2, y: 1, index: tileMap['PAINFOREST.TRUNK.BOTTOM-RIGHT'] },
        { x: 3, y: 1, index: tileMap['PAINFOREST.BRANCH.BOTTOM-RIGHT.START'] },
        { x: 7, y: 1, index: tileMap['PAINFOREST.PRONG.CENTRE-RIGHT.BOTTOM'] },
        { x: -5, y: 2, index: tileMap['PAINFOREST.PRONG.BOTTOM-LEFT.LEFT-TOP'] },
        { x: -4, y: 2, index: tileMap['PAINFOREST.PRONG.BOTTOM-LEFT.CENTRE-TOP'] },
        { x: -3, y: 2, index: tileMap['PAINFOREST.PRONG.BOTTOM-LEFT.RIGHT-TOP'] },
        { x: -2, y: 2, index: tileMap['PAINFOREST.BRANCH.BOTTOM-LEFT.END'] },
        { x: 0, y: 2, index: tileMap['PAINFOREST.BRANCH.CENTRE-TOP.LEFT'] },
        { x: 1, y: 2, index: tileMap['PAINFOREST.BRANCH.CENTRE-TOP.RIGHT'] },
        { x: 3, y: 2, index: tileMap['PAINFOREST.BRANCH.BOTTOM-RIGHT.END'] },
        { x: 4, y: 2, index: tileMap['PAINFOREST.PRONG.BOTTOM-RIGHT.LEFT-TOP'] },
        { x: 5, y: 2, index: tileMap['PAINFOREST.PRONG.BOTTOM-RIGHT.CENTRE-TOP'] },
        { x: 6, y: 2, index: tileMap['PAINFOREST.PRONG.BOTTOM-RIGHT.RIGHT-TOP'] },
        { x: -5, y: 3, index: tileMap['PAINFOREST.PRONG.BOTTOM-LEFT.SECOND-ROW.LEFT'] },
        { x: -4, y: 3, index: tileMap['PAINFOREST.PRONG.BOTTOM-LEFT.SECOND-ROW.CENTRE'] },
        { x: -3, y: 3, index: tileMap['PAINFOREST.PRONG.BOTTOM-LEFT.SECOND-ROW.RIGHT'] },
        { x: 0, y: 3, index: tileMap['PAINFOREST.PRONG.BOTTOM.SECOND-ROW.LEFT'] },
        { x: 1, y: 3, index: tileMap['PAINFOREST.PRONG.BOTTOM.SECOND-ROW.RIGHT'] },
        { x: 4, y: 3, index: tileMap['PAINFOREST.PRONG.BOTTOM-RIGHT.SECOND-ROW.LEFT'] },
        { x: 5, y: 3, index: tileMap['PAINFOREST.PRONG.BOTTOM-RIGHT.SECOND-ROW.CENTRE'] },
        { x: 6, y: 3, index: tileMap['PAINFOREST.PRONG.BOTTOM-RIGHT.SECOND-ROW.RIGHT'] },
        { x: -4, y: 4, index: tileMap['PAINFOREST.PRONG.BOTTOM-LEFT.CENTRE-BOTTOM'] },
        { x: -3, y: 4, index: tileMap['PAINFOREST.PRONG.BOTTOM-LEFT.RIGHT-BOTTOM'] },
        { x: -1, y: 4, index: tileMap['PAINFOREST.PRONG.BOTTOM.LEFT'] },
        { x: 0, y: 4, index: tileMap['PAINFOREST.PRONG.BOTTOM.CENTRE-LEFT'] },
        { x: 1, y: 4, index: tileMap['PAINFOREST.PRONG.BOTTOM.CENTRE-RIGHT'] },
        { x: 2, y: 4, index: tileMap['PAINFOREST.PRONG.CENTRE-BOTTOM.RIGHT.'] },
        { x: 4, y: 4, index: tileMap['PAINFOREST.PRONG.BOTTOM-RIGHT.BOTTOM-LEFT'] },
        { x: 5, y: 4, index: tileMap['PAINFOREST.PRONG.BOTTOM-RIGHT.BOTTOM-CENTRE'] },
    ]

    const neighbours = getMatchingBiomeNeighbours(panel);

    const keys = {
        TRUNK_KEY: 'PAINFOREST.TRUNK.CENTRE.LEFT',
        GROUND_KEY: 'EMPTY',
        LEAF_KEY: 'MOUNTAIN.LEFT.'
    }

    drawTreeDots(mockLayerData, layerData, panel, stampShape, 1.2, false, neighbours, keys)

    let noiseOne = drawNoise(4, 6, panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (!isEmptyIndex(tile)) {
                return;
            }

            if (noiseOne[rowIndex][columnIndex] <= 0.1) {
                tile.index = tileMap[GRASS_KEY]
            }
        })
    });

    clearRiverCliffAndOceanTiles(layerData, panel)

    return mockLayerData;
}

function clearRiverCliffAndOceanTiles(layerData, panel) {
    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (panel.floorLayer.find(_tile => _tile.x === x && _tile.y === y)) {
                tile.index = -1;
            }
        })
    });
}

function clearOceanNeighbourTiles(layerData, panel) {
    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            const northNeighbour = panel.floorLayer.find(_tile => _tile.x === x && _tile.y === y - 1)
            const eastNeighbour = panel.floorLayer.find(_tile => _tile.x === x + 1 && _tile.y === y)
            const southNeighbour = panel.floorLayer.find(_tile => _tile.x === x && _tile.y === y + 1)
            const westNeighbour = panel.floorLayer.find(_tile => _tile.x === x - 1 && _tile.y === y)

            if (northNeighbour?.tileType === 'OCEAN' || eastNeighbour?.tileType === 'OCEAN' || southNeighbour?.tileType === 'OCEAN' || westNeighbour?.tileType === 'OCEAN') {
                tile.index = -1;
            }
        })
    });
}

export function drawCrunchyForest(mockLayerData, layerData, panel, rng) {
    const season = 'MONSOON'
    const GROUND_KEY = 'EMPTY'

    const GRASS_KEY = season === 'MONSOON' ? 'CRUNCHY.MONSOON.GROWTH' : 'EMPTY';

    const keys = {
        TRUNK_KEY: `CRUNCHY.TRUNK.${season}`,
        GROUND_KEY: 'EMPTY',
        LEAF_KEY: `CRUNCHY.${season}.LEAF.LEFT.RIGHT.TOP.BOTTOM.`
    }

    const secondRingChance = 8 / 12;
    const thirdRingChance = 3 / 26;

    const stampShape = [
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        // { x: 0, y: 0 },
        { x: 0, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 1, index: tileMap[keys.LEAF_KEY] },
        // 
        { x: -2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        { x: -1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        { x: 0, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        { x: 1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 1, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        { x: 2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        // 
        { x: -3, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -3, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -3, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -3, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -3, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -3, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -3, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },

        { x: -2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },

        { x: -1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },

        { x: 0, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },

        { x: 1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },

        { x: 2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },

        { x: 3, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
    ]

    const neighbours = getMatchingBiomeNeighbours(panel);

    drawTreeDots(mockLayerData, layerData, panel, stampShape, 1.5, false, neighbours, keys, rng);

    drawNeighbours(layerData, `CRUNCHY.${season}.LEAF.`, tileMap[`CRUNCHY.${season}.LEAF.LEFT.RIGHT.TOP.BOTTOM.`], { isDiagonalRelevent: false, startingIndexes: [tileMap[`CRUNCHY.TRUNK.${season}`]] });

    let noiseOne = drawNoise(1, 2, panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (!isEmptyIndex(tile)) {
                return;
            }

            if (noiseOne[rowIndex][columnIndex] >= 0.65) {
                tile.index = tileMap[GRASS_KEY]
            }
        })
    });

    clearRiverCliffAndOceanTiles(layerData, panel)

    return mockLayerData;
}

export function drawDiscordantForest(mockLayerData, layerData, panel, rng) {
    const GRASS_KEY = 'STEPPE.GRASS';
    const GROUND_KEY = 'EMPTY'

    const SEASON = 'YELLOW';

    const stampShape1 = [
        { x: -3, y: 0, index: tileMap[`DISCORDANT-${SEASON}.RIGHT.TOP.BOTTOM.`] },
        { x: -2, y: 0, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.BOTTOM.WEST`] },
        { x: -1, y: 0, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.BOTTOM.WEST`] },
        // // { x: 0, y: 0 },
        { x: 1, y: 0, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.BOTTOM.EAST`] },
        { x: 2, y: 0, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.BOTTOM.EAST`] },
        { x: 3, y: 0, index: tileMap[`DISCORDANT-${SEASON}.LEFT.`] },

        { x: -3, y: 1, index: tileMap[`DISCORDANT-${SEASON}.RIGHT.TOP.`] },
        { x: -2, y: 1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.BOTTOM.DOWN.`] },
        { x: -1, y: 1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.BOTTOM.DOWN.`] },
        { x: 0, y: 1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.BOTTOM.DOWN.`] },
        { x: 1, y: 1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.BOTTOM.DOWN.`] },
        { x: 2, y: 1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.BOTTOM.DOWN.`] },
        { x: 3, y: 1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.TOP.`] },

        { x: -3, y: -1, index: tileMap[`DISCORDANT-${SEASON}.RIGHT.BOTTOM.`] },
        { x: -2, y: -1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.BOTTOM`] },
        { x: -1, y: -1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.BOTTOM.UP.`] },
        { x: 0, y: -1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.BOTTOM.UP.`] },
        { x: 1, y: -1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.BOTTOM.UP.`] },
        { x: 2, y: -1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.BOTTOM`] },
        { x: 3, y: -1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.BOTTOM.`] },

        { x: -2, y: -2, index: tileMap[`DISCORDANT-${SEASON}.RIGHT.BOTTOM.`] },
        { x: -1, y: -2, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.BOTTOM`] },
        { x: 0, y: -2, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.BOTTOM`] },
        { x: 1, y: -2, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.BOTTOM`] },
        { x: 2, y: -2, index: tileMap[`DISCORDANT-${SEASON}.LEFT.BOTTOM.`] },

        { x: -2, y: 2, index: tileMap[`DISCORDANT-${SEASON}.RIGHT.TOP.`] },
        { x: -1, y: 2, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.`] },
        { x: 0, y: 2, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.`] },
        { x: 1, y: 2, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.`] },
        { x: 2, y: 2, index: tileMap[`DISCORDANT-${SEASON}.LEFT.TOP.`] },
    ]

    const stampShape2 = [
        { x: -2, y: 0, index: tileMap[`DISCORDANT-${SEASON}.RIGHT.TOP.BOTTOM.`] },
        { x: -1, y: 0, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.BOTTOM.WEST`] },
        // { x: 0, y: 0 },
        { x: 1, y: 0, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.BOTTOM.EAST`] },
        { x: 2, y: 0, index: tileMap[`DISCORDANT-${SEASON}.LEFT.`] },

        { x: -2, y: 1, index: tileMap[`DISCORDANT-${SEASON}.RIGHT.TOP.`] },
        { x: -1, y: 1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.`] },
        { x: 0, y: 1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.`] },
        { x: 1, y: 1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.`] },
        { x: 2, y: 1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.TOP.`] },

        { x: -2, y: -1, index: tileMap[`DISCORDANT-${SEASON}.RIGHT.BOTTOM.`] },
        { x: -1, y: -1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.BOTTOM`] },
        { x: 0, y: -1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.BOTTOM`] },
        { x: 1, y: -1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.RIGHT.BOTTOM`] },
        { x: 2, y: -1, index: tileMap[`DISCORDANT-${SEASON}.LEFT.BOTTOM.`] },
    ]

    const neighbours = getMatchingBiomeNeighbours(panel);

    const keys = {
        TRUNK_KEY: `DISCORDANT-${SEASON}.LEFT.RIGHT.TOP.BOTTOM.BIG`,
        GROUND_KEY: 'EMPTY',
        LEAF_KEY: 'MOUNTAIN.LEFT.'
    }

    const trunkCoords = drawTreeDots(mockLayerData, layerData, panel, [stampShape1, stampShape2], 1.4, false, neighbours, keys, rng);

    let noiseOne = drawNoise(4, 6, panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (!isEmptyIndex(tile)) {
                return;
            }

            if (noiseOne[rowIndex][columnIndex] <= 0.1) {
                tile.index = tileMap[GRASS_KEY]
            }
        })
    });

    clearRiverCliffAndOceanTiles(layerData, panel)

    return mockLayerData;
}

export function drawSlabs(mockLayerData, layerData, panel, rng) {
    const GROUND_KEY = 'EMPTY'

    const stampShape1 = [
        { x: 0, y: -1, index: tileMap[`SLABS.SLOPE.LEFT.BOTTOM.`] },
        { x: 0, y: 0, index: tileMap[`SLABS.SLOPE.LEFT.TOP.BOTTOM.`] },
        { x: -1, y: 1, index: tileMap[`SLABS.SLOPE.LEFT.RIGHT.TOP.`] },
        { x: -2, y: 1, index: tileMap[`SLABS.SLOPE.LEFT.RIGHT.TOP.`] },
        { x: 0, y: 1, index: tileMap[`SLABS.SLOPE.LEFT.TOP.`] },
        { x: -3, y: 1, index: tileMap[`SLABS.SLOPE.RIGHT.TOP.`] },

        { x: -1, y: 0, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.TOP.BOTTOM.']},
        { x: -2, y: -1, index: tileMap['SLABS.SURFACE.RIGHT.BOTTOM.']},
        { x: -1, y: -1, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.BOTTOM']},
        { x: -3, y: 0, index: tileMap['SLABS.ENTRANCE.RIGHT.BOTTOM.']},
        { x: -2, y: 0, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.TOP.BOTTOM.']},
    ]

    const stampShape2 = [
        // { x: 0, y: 1, index: tileMap[`SLABS.SLOPE.LEFT.TOP.BOTTOM.`] },
        { x: 2, y: -1, index: tileMap[`SLABS.SLOPE.LEFT.BOTTOM.`] },
        { x: 2, y: 0, index: tileMap[`SLABS.SLOPE.LEFT.TOP.BOTTOM.`] },
        { x: 2, y: 1, index: tileMap[`SLABS.SLOPE.LEFT.TOP.BOTTOM.`] },
        { x: 2, y: 2, index: tileMap[`SLABS.SLOPE.LEFT.TOP.`] },

        { x: 1, y: 0, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.TOP.BOTTOM.']},
        { x: 1, y: 1, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.TOP.BOTTOM.']},
        { x: 1, y: -1, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.BOTTOM']},
        { x: 1, y: 2, index: tileMap[`SLABS.SLOPE.LEFT.RIGHT.TOP.`] },

        { x: 0, y: 2, index: tileMap[`SLABS.SLOPE.LEFT.RIGHT.TOP.`] },
        { x: 0, y: -1, index: tileMap['SLABS.SURFACE.RIGHT.BOTTOM.']},
        { x: 0, y: 0, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.TOP.BOTTOM.']},
        { x: 0, y: 1, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.TOP.BOTTOM.']},

        { x: -1, y: 2, index: tileMap[`SLABS.SLOPE.RIGHT.TOP.`] },
        { x: -1, y: 0, index: tileMap['SLABS.ENTRANCE.RIGHT.BOTTOM.']},
        { x: -1, y: 1, index: tileMap['SLABS.ENTRANCE.RIGHT.']},
    ]

    const stampShape3 = [
        // { x: 0, y: 1, index: tileMap[`SLABS.SLOPE.LEFT.TOP.BOTTOM.`] },
        { x: 3, y: -1, index: tileMap[`SLABS.SLOPE.LEFT.BOTTOM.`] },
        { x: 3, y: 0, index: tileMap[`SLABS.SLOPE.LEFT.TOP.BOTTOM.`] },
        { x: 3, y: 1, index: tileMap[`SLABS.SLOPE.LEFT.TOP.BOTTOM.`] },
        { x: 3, y: 2, index: tileMap[`SLABS.SLOPE.LEFT.TOP.`] },

        { x: 2, y: 2, index: tileMap[`SLABS.SLOPE.LEFT.RIGHT.TOP.`] },
        { x: 2, y: 0, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.TOP.BOTTOM.']},
        { x: 2, y: 1, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.TOP.BOTTOM.']},
        { x: 2, y: -1, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.BOTTOM']},

        { x: 1, y: 2, index: tileMap[`SLABS.SLOPE.LEFT.RIGHT.TOP.`] },
        { x: 1, y: -1, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.BOTTOM']},
        { x: 1, y: 0, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.TOP.BOTTOM.']},
        { x: 1, y: 1, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.TOP.BOTTOM.']},

        { x: 0, y: 2, index: tileMap[`SLABS.SLOPE.LEFT.RIGHT.TOP.`] },
        { x: 0, y: -1, index: tileMap['SLABS.SURFACE.RIGHT.BOTTOM.']},
        { x: 0, y: 0, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.TOP.BOTTOM.']},
        { x: 0, y: 1, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.TOP.BOTTOM.']},

        { x: -1, y: 2, index: tileMap[`SLABS.SLOPE.RIGHT.TOP.`] },
        { x: -1, y: 0, index: tileMap['SLABS.ENTRANCE.RIGHT.BOTTOM.']},
        { x: -1, y: 1, index: tileMap['SLABS.ENTRANCE.RIGHT.']},
    ]

    const stampShape4 = [
        { x: 3, y: -1, index: tileMap[`SLABS.SLOPE.LEFT.BOTTOM.`] },
        { x: 3, y: 0, index: tileMap[`SLABS.SLOPE.LEFT.TOP.BOTTOM.`] },
        { x: 3, y: 1, index: tileMap[`SLABS.SLOPE.LEFT.TOP.`] },

        { x: 2, y: -1, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.BOTTOM']},
        { x: 2, y: 0, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.TOP.BOTTOM.']},
        { x: 2, y: 1, index: tileMap[`SLABS.SLOPE.LEFT.RIGHT.TOP.`] },

        { x: 1, y: -1, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.BOTTOM']},
        { x: 1, y: 0, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.TOP.BOTTOM.']},
        { x: 1, y: 1, index: tileMap[`SLABS.SLOPE.LEFT.RIGHT.TOP.`] },

        { x: 0, y: -1, index: tileMap['SLABS.SURFACE.RIGHT.BOTTOM.']},
        { x: 0, y: 0, index: tileMap['SLABS.SURFACE.LEFT.RIGHT.TOP.BOTTOM.']},
        { x: 0, y: 1, index: tileMap[`SLABS.SLOPE.LEFT.RIGHT.TOP.`] },

        { x: -1, y: 0, index: tileMap['SLABS.ENTRANCE.RIGHT.BOTTOM.']},
        { x: -1, y: 1, index: tileMap[`SLABS.SLOPE.RIGHT.TOP.`] },
    ]

    const neighbours = getMatchingBiomeNeighbours(panel);

    const keys = {
        TRUNK_KEY: `LAVA.LEFT.BOTTOM.`,
        GROUND_KEY: 'EMPTY',
        LEAF_KEY: 'MOUNTAIN.LEFT.'
    }

    const trunkCoords = drawTreeDots(mockLayerData, layerData, panel, [stampShape1, stampShape2, stampShape3, stampShape4], 1.55, false, [], keys, rng);

    return mockLayerData;
}

export function drawFossilisedForest(mockLayerData, layerData, panel, rng) {
    const FLOWER_KEY = 'SUGAR-BUSH';
    const THORN_KEY = 'SUGAR-BUSH.HARVEST';
    const GROUND_KEY = 'EMPTY'

    let noiseOne = drawNoise(4, 5, panel);

    const stampShape1 = [
        { x: -2, y: 0, index: tileMap[`FOSSILISED.BRANCH.RIGHT.`] },
        { x: -1, y: 0, index: tileMap[`FOSSILISED.TRUNK.LARGE.RIGHT.TOP.BOTTOM.`] },
        // // { x: 0, y: 0 },
        { x: 1, y: 0, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.TOP.BOTTOM.`] },
        { x: 2, y: 0, index: tileMap[`FOSSILISED.BRANCH.LEFT.`] },

        { x: -2, y: 1, index: tileMap[`FOSSILISED.LEAF.RIGHT.TOP.`] },
        { x: -1, y: 1, index: tileMap[`FOSSILISED.TRUNK.LARGE.RIGHT.TOP.`] },
        { x: 0, y: 1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.TOP.`] },
        { x: 1, y: 1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.TOP.`] },
        { x: 2, y: 1, index: tileMap[`FOSSILISED.LEAF.LEFT.TOP.`] },

        { x: -2, y: -1, index: tileMap[`FOSSILISED.LEAF.RIGHT.BOTTOM.`] },
        { x: -1, y: -1, index: tileMap[`FOSSILISED.TRUNK.LARGE.RIGHT.BOTTOM.`] },
        { x: 0, y: -1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.BOTTOM.`] },
        { x: 1, y: -1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.BOTTOM.`] },
        { x: 2, y: -1, index: tileMap[`FOSSILISED.LEAF.LEFT.BOTTOM.`] },

        { x: -2, y: -2, index: tileMap[`FOSSILISED.BRANCH.NONE.BR-TRUNK`] },
        { x: -1, y: -2, index: tileMap[`FOSSILISED.LEAF.RIGHT.BOTTOM.`] },
        { x: 0, y: -2, index: tileMap[`FOSSILISED.BRANCH.BOTTOM.`] },
        { x: 1, y: -2, index: tileMap[`FOSSILISED.LEAF.LEFT.BOTTOM.`] },
        { x: 2, y: -2, index: tileMap[`FOSSILISED.BRANCH.NONE.BL-TRUNK`] },

        { x: -2, y: 2, index: tileMap[`FOSSILISED.BRANCH.NONE.TR-TRUNK`] },
        { x: -1, y: 2, index: tileMap[`FOSSILISED.LEAF.RIGHT.TOP.`] },
        { x: 0, y: 2, index: tileMap[`FOSSILISED.BRANCH.TOP.`] },
        { x: 1, y: 2, index: tileMap[`FOSSILISED.LEAF.LEFT.TOP.`] },
        { x: 2, y: 2, index: tileMap[`FOSSILISED.BRANCH.NONE.TL-TRUNK`] },
    ]

    const stampShape2 = [
        { x: -3, y: 0, index: tileMap[`FOSSILISED.BRANCH.RIGHT.`] },
        { x: -2, y: 0, index: tileMap[`FOSSILISED.BRANCH.RIGHT.`] },
        { x: -1, y: 0, index: tileMap[`FOSSILISED.TRUNK.LARGE.RIGHT.TOP.BOTTOM.`] },
        // // { x: 0, y: 0 },
        { x: 1, y: 0, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.TOP.BOTTOM.`] },
        { x: 2, y: 0, index: tileMap[`FOSSILISED.BRANCH.LEFT.`] },
        { x: 3, y: 0, index: tileMap[`FOSSILISED.BRANCH.LEFT.`] },

        { x: -3, y: 1, index: tileMap[`FOSSILISED.LEAF.LEFT.RIGHT.TOP.`] },
        { x: -2, y: 1, index: tileMap[`FOSSILISED.LEAF.RIGHT.TOP.`] },
        { x: -1, y: 1, index: tileMap[`FOSSILISED.TRUNK.LARGE.RIGHT.TOP.`] },
        { x: 0, y: 1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.TOP.`] },
        { x: 1, y: 1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.TOP.`] },
        { x: 2, y: 1, index: tileMap[`FOSSILISED.LEAF.LEFT.TOP.`] },
        { x: 3, y: 1, index: tileMap[`FOSSILISED.LEAF.LEFT.RIGHT.TOP.`] },

        { x: -3, y: -1, index: tileMap[`FOSSILISED.LEAF.LEFT.RIGHT.BOTTOM.`] },
        { x: -2, y: -1, index: tileMap[`FOSSILISED.LEAF.RIGHT.BOTTOM.`] },
        { x: -1, y: -1, index: tileMap[`FOSSILISED.TRUNK.LARGE.RIGHT.BOTTOM.`] },
        { x: 0, y: -1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.BOTTOM.`] },
        { x: 1, y: -1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.BOTTOM.`] },
        { x: 2, y: -1, index: tileMap[`FOSSILISED.LEAF.LEFT.BOTTOM.`] },
        { x: 3, y: -1, index: tileMap[`FOSSILISED.LEAF.LEFT.RIGHT.BOTTOM.`] },

        { x: -2, y: -2, index: tileMap[`FOSSILISED.BRANCH.NONE.BR-TRUNK`] },
        { x: -1, y: -2, index: tileMap[`FOSSILISED.LEAF.RIGHT.BOTTOM.`] },
        { x: 0, y: -2, index: tileMap[`FOSSILISED.BRANCH.BOTTOM.`] },
        { x: 1, y: -2, index: tileMap[`FOSSILISED.LEAF.LEFT.BOTTOM.`] },
        { x: 2, y: -2, index: tileMap[`FOSSILISED.BRANCH.NONE.BL-TRUNK`] },

        { x: -2, y: 2, index: tileMap[`FOSSILISED.BRANCH.NONE.TR-TRUNK`] },
        { x: -1, y: 2, index: tileMap[`FOSSILISED.LEAF.RIGHT.TOP.`] },
        { x: 0, y: 2, index: tileMap[`FOSSILISED.BRANCH.TOP.`] },
        { x: 1, y: 2, index: tileMap[`FOSSILISED.LEAF.LEFT.TOP.`] },
        { x: 2, y: 2, index: tileMap[`FOSSILISED.BRANCH.NONE.TL-TRUNK`] },

        { x: -3, y: -3, index: tileMap[`FOSSILISED.BRANCH.NONE.BR-TRUNK`] },
        { x: -1, y: -3, index: tileMap[`FOSSILISED.LEAF.RIGHT.TOP.BOTTOM.`] },
        { x: 0, y: -3, index: tileMap[`FOSSILISED.BRANCH.BOTTOM.`] },
        { x: 1, y: -3, index: tileMap[`FOSSILISED.LEAF.LEFT.TOP.BOTTOM.`] },
        { x: 3, y: -3, index: tileMap[`FOSSILISED.BRANCH.NONE.BL-TRUNK`] },
        { x: -3, y: 3, index: tileMap[`FOSSILISED.BRANCH.NONE.TR-TRUNK`] },
        { x: -1, y: 3, index: tileMap[`FOSSILISED.LEAF.RIGHT.TOP.BOTTOM.`] },
        { x: 0, y: 3, index: tileMap[`FOSSILISED.BRANCH.TOP.`] },
        { x: 1, y: 3, index: tileMap[`FOSSILISED.LEAF.LEFT.TOP.BOTTOM.`] },
        { x: 3, y: 3, index: tileMap[`FOSSILISED.BRANCH.NONE.TL-TRUNK`] },
    ]

    const stampShape3 = [
        { x: -3, y: 0, index: tileMap[`FOSSILISED.BRANCH.RIGHT.`] },
        { x: -2, y: 0, index: tileMap[`FOSSILISED.BRANCH.RIGHT.`] },
        { x: -1, y: 0, index: tileMap[`FOSSILISED.TRUNK.LARGE.RIGHT.TOP.BOTTOM.`] },
        // // { x: 0, y: 0 },
        { x: 1, y: 0, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.TOP.BOTTOM.`] },
        { x: 2, y: 0, index: tileMap[`FOSSILISED.BRANCH.LEFT.`] },
        { x: 3, y: 0, index: tileMap[`FOSSILISED.BRANCH.LEFT.`] },

        { x: -3, y: 1, index: tileMap[`FOSSILISED.LEAF.LEFT.RIGHT.TOP.`] },
        { x: -2, y: 1, index: tileMap[`FOSSILISED.LEAF.RIGHT.TOP.`] },
        { x: -1, y: 1, index: tileMap[`FOSSILISED.TRUNK.LARGE.RIGHT.TOP.`] },
        { x: 0, y: 1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.TOP.`] },
        { x: 1, y: 1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.TOP.`] },
        { x: 2, y: 1, index: tileMap[`FOSSILISED.LEAF.LEFT.TOP.`] },
        { x: 3, y: 1, index: tileMap[`FOSSILISED.LEAF.LEFT.RIGHT.TOP.`] },

        { x: -3, y: -1, index: tileMap[`FOSSILISED.LEAF.LEFT.RIGHT.BOTTOM.`] },
        { x: -2, y: -1, index: tileMap[`FOSSILISED.LEAF.RIGHT.BOTTOM.`] },
        { x: -1, y: -1, index: tileMap[`FOSSILISED.TRUNK.LARGE.RIGHT.BOTTOM.`] },
        { x: 0, y: -1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.BOTTOM.`] },
        { x: 1, y: -1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.BOTTOM.`] },
        { x: 2, y: -1, index: tileMap[`FOSSILISED.LEAF.LEFT.BOTTOM.`] },
        { x: 3, y: -1, index: tileMap[`FOSSILISED.LEAF.LEFT.RIGHT.BOTTOM.`] },

        { x: -2, y: -2, index: tileMap[`FOSSILISED.BRANCH.NONE.BR-TRUNK`] },
        { x: -1, y: -2, index: tileMap[`FOSSILISED.LEAF.RIGHT.BOTTOM.`] },
        { x: 0, y: -2, index: tileMap[`FOSSILISED.BRANCH.BOTTOM.`] },
        { x: 1, y: -2, index: tileMap[`FOSSILISED.LEAF.LEFT.BOTTOM.`] },
        { x: 2, y: -2, index: tileMap[`FOSSILISED.BRANCH.NONE.BL-TRUNK`] },

        { x: -2, y: 2, index: tileMap[`FOSSILISED.BRANCH.NONE.TR-TRUNK`] },
        { x: -1, y: 2, index: tileMap[`FOSSILISED.LEAF.RIGHT.TOP.`] },
        { x: 0, y: 2, index: tileMap[`FOSSILISED.BRANCH.TOP.`] },
        { x: 1, y: 2, index: tileMap[`FOSSILISED.LEAF.LEFT.TOP.`] },
        { x: 2, y: 2, index: tileMap[`FOSSILISED.BRANCH.NONE.TL-TRUNK`] },

        { x: -1, y: -3, index: tileMap[`FOSSILISED.LEAF.RIGHT.TOP.BOTTOM.`] },
        { x: 0, y: -3, index: tileMap[`FOSSILISED.BRANCH.BOTTOM.`] },
        { x: 1, y: -3, index: tileMap[`FOSSILISED.LEAF.LEFT.TOP.BOTTOM.`] },
        { x: -1, y: 3, index: tileMap[`FOSSILISED.LEAF.RIGHT.TOP.BOTTOM.`] },
        { x: 0, y: 3, index: tileMap[`FOSSILISED.BRANCH.TOP.`] },
        { x: 1, y: 3, index: tileMap[`FOSSILISED.LEAF.LEFT.TOP.BOTTOM.`] },
    ]

    const stampShape4 = [
        { x: -3, y: 0, index: tileMap[`FOSSILISED.BRANCH.RIGHT.`] },
        { x: -2, y: 0, index: tileMap[`FOSSILISED.BRANCH.RIGHT.`] },
        { x: -1, y: 0, index: tileMap[`FOSSILISED.TRUNK.LARGE.RIGHT.TOP.BOTTOM.`] },
        // // { x: 0, y: 0 },
        { x: 1, y: 0, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.TOP.BOTTOM.`] },
        { x: 2, y: 0, index: tileMap[`FOSSILISED.BRANCH.LEFT.`] },
        { x: 3, y: 0, index: tileMap[`FOSSILISED.BRANCH.LEFT.`] },

        { x: -3, y: 1, index: tileMap[`FOSSILISED.LEAF.LEFT.RIGHT.TOP.`] },
        { x: -2, y: 1, index: tileMap[`FOSSILISED.LEAF.RIGHT.TOP.`] },
        { x: -1, y: 1, index: tileMap[`FOSSILISED.TRUNK.LARGE.RIGHT.TOP.`] },
        { x: 0, y: 1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.TOP.`] },
        { x: 1, y: 1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.TOP.`] },
        { x: 2, y: 1, index: tileMap[`FOSSILISED.LEAF.LEFT.TOP.`] },
        { x: 3, y: 1, index: tileMap[`FOSSILISED.LEAF.LEFT.RIGHT.TOP.`] },

        { x: -3, y: -1, index: tileMap[`FOSSILISED.LEAF.LEFT.RIGHT.BOTTOM.`] },
        { x: -2, y: -1, index: tileMap[`FOSSILISED.LEAF.RIGHT.BOTTOM.`] },
        { x: -1, y: -1, index: tileMap[`FOSSILISED.TRUNK.LARGE.RIGHT.BOTTOM.`] },
        { x: 0, y: -1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.BOTTOM.`] },
        { x: 1, y: -1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.BOTTOM.`] },
        { x: 2, y: -1, index: tileMap[`FOSSILISED.LEAF.LEFT.BOTTOM.`] },
        { x: 3, y: -1, index: tileMap[`FOSSILISED.LEAF.LEFT.RIGHT.BOTTOM.`] },

        { x: -2, y: -2, index: tileMap[`FOSSILISED.BRANCH.NONE.BR-TRUNK`] },
        { x: -1, y: -2, index: tileMap[`FOSSILISED.LEAF.RIGHT.BOTTOM.`] },
        { x: 0, y: -2, index: tileMap[`FOSSILISED.BRANCH.BOTTOM.`] },
        { x: 1, y: -2, index: tileMap[`FOSSILISED.LEAF.LEFT.BOTTOM.`] },
        { x: 2, y: -2, index: tileMap[`FOSSILISED.BRANCH.NONE.BL-TRUNK`] },

        { x: -2, y: 2, index: tileMap[`FOSSILISED.BRANCH.NONE.TR-TRUNK`] },
        { x: -1, y: 2, index: tileMap[`FOSSILISED.LEAF.RIGHT.TOP.`] },
        { x: 0, y: 2, index: tileMap[`FOSSILISED.BRANCH.TOP.`] },
        { x: 1, y: 2, index: tileMap[`FOSSILISED.LEAF.LEFT.TOP.`] },
        { x: 2, y: 2, index: tileMap[`FOSSILISED.BRANCH.NONE.TL-TRUNK`] },
    ]

    const stampShape5 = [
        { x: -2, y: 0, index: tileMap[`FOSSILISED.BRANCH.RIGHT.`] },
        { x: -1, y: 0, index: tileMap[`FOSSILISED.TRUNK.LARGE.RIGHT.TOP.BOTTOM.`] },
        // // { x: 0, y: 0 },
        { x: 1, y: 0, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.TOP.BOTTOM.`] },
        { x: 2, y: 0, index: tileMap[`FOSSILISED.BRANCH.LEFT.`] },

        { x: -2, y: 1, index: tileMap[`FOSSILISED.LEAF.RIGHT.TOP.`] },
        { x: -1, y: 1, index: tileMap[`FOSSILISED.TRUNK.LARGE.RIGHT.TOP.`] },
        { x: 0, y: 1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.TOP.`] },
        { x: 1, y: 1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.TOP.`] },
        { x: 2, y: 1, index: tileMap[`FOSSILISED.LEAF.LEFT.TOP.`] },

        { x: -2, y: -1, index: tileMap[`FOSSILISED.LEAF.RIGHT.BOTTOM.`] },
        { x: -1, y: -1, index: tileMap[`FOSSILISED.TRUNK.LARGE.RIGHT.BOTTOM.`] },
        { x: 0, y: -1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.BOTTOM.`] },
        { x: 1, y: -1, index: tileMap[`FOSSILISED.TRUNK.LARGE.LEFT.BOTTOM.`] },
        { x: 2, y: -1, index: tileMap[`FOSSILISED.LEAF.LEFT.BOTTOM.`] },

        { x: -3, y: -3, index: tileMap[`FOSSILISED.BRANCH.NONE.BR-TRUNK`] },
        { x: -2, y: -2, index: tileMap[`FOSSILISED.BRANCH.NONE.BR-TRUNK`] },
        { x: -1, y: -2, index: tileMap[`FOSSILISED.LEAF.RIGHT.BOTTOM.`] },
        { x: 0, y: -2, index: tileMap[`FOSSILISED.BRANCH.BOTTOM.`] },
        { x: 1, y: -2, index: tileMap[`FOSSILISED.LEAF.LEFT.BOTTOM.`] },
        { x: 2, y: -2, index: tileMap[`FOSSILISED.BRANCH.NONE.BL-TRUNK`] },
        { x: 3, y: -3, index: tileMap[`FOSSILISED.BRANCH.NONE.BL-TRUNK`] },

        { x: -3, y: 3, index: tileMap[`FOSSILISED.BRANCH.NONE.TR-TRUNK`] },
        { x: -2, y: 2, index: tileMap[`FOSSILISED.BRANCH.NONE.TR-TRUNK`] },
        { x: -1, y: 2, index: tileMap[`FOSSILISED.LEAF.RIGHT.TOP.`] },
        { x: 0, y: 2, index: tileMap[`FOSSILISED.BRANCH.TOP.`] },
        { x: 1, y: 2, index: tileMap[`FOSSILISED.LEAF.LEFT.TOP.`] },
        { x: 2, y: 2, index: tileMap[`FOSSILISED.BRANCH.NONE.TL-TRUNK`] },
        { x: 3, y: 3, index: tileMap[`FOSSILISED.BRANCH.NONE.TL-TRUNK`] },
    ]

    const stampShape6 = [
        { x: -2, y: 0, index: tileMap[`FOSSILISED.BRANCH.RIGHT.`] },
        { x: -1, y: 0, index: tileMap[`FOSSILISED.BRANCH.RIGHT.`] },
        // // { x: 0, y: 0 },
        { x: 1, y: 0, index: tileMap[`FOSSILISED.BRANCH.LEFT.`] },
        { x: 2, y: 0, index: tileMap[`FOSSILISED.BRANCH.LEFT.`] },

        { x: -2, y: 1, index: tileMap[`FOSSILISED.LEAF.LEFT.RIGHT.TOP.`] },
        { x: 0, y: 1, index: tileMap[`FOSSILISED.BRANCH.TOP.`] },
        { x: 2, y: 1, index: tileMap[`FOSSILISED.LEAF.LEFT.RIGHT.TOP.`] },

        { x: -2, y: -1, index: tileMap[`FOSSILISED.LEAF.LEFT.RIGHT.BOTTOM.`] },
        { x: -1, y: -1, index: tileMap[`FOSSILISED.LEAF.RIGHT.BOTTOM.`] },
        { x: 0, y: -1, index: tileMap[`FOSSILISED.BRANCH.BOTTOM.`] },
        { x: 1, y: -1, index: tileMap[`FOSSILISED.LEAF.LEFT.BOTTOM.`] },
        { x: 2, y: -1, index: tileMap[`FOSSILISED.LEAF.LEFT.RIGHT.BOTTOM.`] },

        { x: -1, y: -2, index: tileMap[`FOSSILISED.LEAF.RIGHT.TOP.BOTTOM.`] },
        { x: 0, y: -2, index: tileMap[`FOSSILISED.BRANCH.BOTTOM.`] },
        { x: 1, y: -2, index: tileMap[`FOSSILISED.LEAF.LEFT.TOP.BOTTOM.`] },

        { x: -1, y: 1, index: tileMap[`FOSSILISED.LEAF.RIGHT.TOP.`] },
        { x: -1, y: 2, index: tileMap[`FOSSILISED.LEAF.RIGHT.TOP.BOTTOM.`] },
        { x: 0, y: 2, index: tileMap[`FOSSILISED.BRANCH.TOP.`] },
        { x: 1, y: 1, index: tileMap[`FOSSILISED.LEAF.LEFT.TOP.`] },
        { x: 1, y: 2, index: tileMap[`FOSSILISED.LEAF.LEFT.TOP.BOTTOM.`] },
    ]

    const neighbours = getMatchingBiomeNeighbours(panel);

    const keys = {
        TRUNK_KEY: `FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.TOP.BOTTOM.`,
        GROUND_KEY: 'EMPTY',
        LEAF_KEY: 'MOUNTAIN.LEFT.'
    }

    const trunkCoords = drawTreeDots(mockLayerData, layerData, panel, [stampShape1, stampShape2, stampShape3, stampShape4, stampShape5, stampShape6], 1.5, false, neighbours, keys, rng);

    layerData.forEach((row, _y) => {
        row.forEach((tile, _x) => {
            const right = layerData[_y][_x + 1];

            if (right && tile.index === tileMap['FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.TOP.BOTTOM.'] && right.index !== tileMap['FOSSILISED.TRUNK.LARGE.LEFT.TOP.BOTTOM.']) {
                tile.index = tileMap['FOSSILISED.TRUNK.SMALL']
            }
        })
    })

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (!isEmptyIndex(tile)) {
                return
            }
            if (noiseOne[rowIndex][columnIndex] > 0.8) {
                tile.index = tileMap[FLOWER_KEY];
            } else if (noiseOne[rowIndex][columnIndex] > 0.6) {
                tile.index = tileMap[THORN_KEY];
            }
        })
    });

    limitToCheckerboxPattern(layerData, panel, GROUND_KEY, [tileMap[FLOWER_KEY], tileMap[THORN_KEY]])

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    return mockLayerData;
}

export function drawWarmTemperateDesertRiver(mockLayerData, layerData, panel, rng) {
    if (!panel.riverSize) {
        return;
    }

    const BARLEY_KEY = 'WHEAT.OLD'
    const OAT_KEY = 'FLAX.OLD';
    const TRUNK_KEY = 'MULBERRY.TRUNK';
    const BRANCH_KEY = 'MULBERRY.BRANCH.LEFT.RIGHT.';
    const LEAF_KEY = 'MULBERRY.LEAF.LEFT.RIGHT.';
    const VINE_KEY = 'MULBERRY.LEAF.LEFT.RIGHT.';
    const EMPTY_KEY = 'EMPTY';

    const keys = {
        STARTING_KEY: TRUNK_KEY,
        TRUNK_KEY,
        WALK_KEY: VINE_KEY,
        EMPTY_KEY
    }

    const season = '';

    const riverBanks = panel.floorLayer.forEach(floor => {
        if (floor.tileType === 'WATER') {
            const { left, right, top, bottom } = getNeighbours(layerData, floor.x, floor.y)
            const directions = [left, right, top, bottom]

            directions.forEach(direction => {
                if (panel.floorLayer.find(floor => floor?.x === direction?.x && floor?.y === direction?.y)) {
                    return;
                }

                if (!direction || !isEmptyIndex(direction)){
                    return;
                }

                const plantChance = rng()

                if (plantChance < 0.25) {
                    direction.index = tileMap[BARLEY_KEY];
                } else if (plantChance < 0.5) {
                    direction.index = tileMap[OAT_KEY];
                }
            })
        }
    })

    let dots = drawDots(layerData, panel, TRUNK_KEY, 1.5)
        .filter(dot => !panel.floorLayer.find(floorTile => (floorTile.x === dot.x && floorTile.y === dot.y)));

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    const neighbours = getMatchingBiomeNeighbours(panel);

    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (tile?.index !== tileMap[TRUNK_KEY]) {
                return;
            }

            const { left, right, top, bottom } = getNeighbours(layerData, x, y)
            const { bottomLeft, bottomRight, topLeft, topRight } = getDiagonalNeighbours(layerData, x, y)

            if (left && isEmptyIndex(left) && rng() < 0.2) {
                left.index = tileMap[`MULBERRY.BRANCH.${season}RIGHT.`]
            }

            if (right && isEmptyIndex(right) && rng() < 0.2) {
                right.index = tileMap[`MULBERRY.BRANCH.${season}LEFT.`]
            }

            if (top && isEmptyIndex(top) && rng() < 0.2) {
                top.index = tileMap[`MULBERRY.BRANCH.${season}BOTTOM.`]
            }

            if (bottom && isEmptyIndex(bottom) && rng() < 0.2) {
                bottom.index = tileMap[`MULBERRY.BRANCH.${season}TOP.`]
            }

            if (topRight && !isEmptyIndex(right) && !isEmptyIndex(top)) {
                topRight.index = tileMap[`MULBERRY.LEAF.${season}RIGHT.BOTTOM.`]
            }

            if (topLeft && !isEmptyIndex(left) && !isEmptyIndex(top)) {
                topLeft.index = tileMap[`MULBERRY.LEAF.${season}LEFT.BOTTOM.`]
            }

            if (bottomRight && !isEmptyIndex(right) && !isEmptyIndex(bottom)) {
                bottomRight.index = tileMap[`MULBERRY.LEAF.${season}LEFT.TOP.`]
            }

            if (bottomLeft && !isEmptyIndex(left) && !isEmptyIndex(bottom)) {
                bottomLeft.index = tileMap[`MULBERRY.LEAF.${season}RIGHT.TOP.`]
            }

            if (topRight && isEmptyIndex(right) && !isEmptyIndex(top)) {
                topRight.index = tileMap[`MULBERRY.LEAF.${season}LEFT.`]
            }

            if (topRight && !isEmptyIndex(right) && isEmptyIndex(top)) {
                topRight.index = tileMap[`MULBERRY.LEAF.${season}BOTTOM.`]
            }

            if (topLeft && isEmptyIndex(left) && !isEmptyIndex(top)) {
                topLeft.index = tileMap[`MULBERRY.LEAF.${season}RIGHT.`]
            }

            if (topLeft && !isEmptyIndex(left) && isEmptyIndex(top)) {
                topLeft.index = tileMap[`MULBERRY.LEAF.${season}BOTTOM.`]
            }

            if (bottomRight && isEmptyIndex(right) && !isEmptyIndex(bottom)) {
                bottomRight.index = tileMap[`MULBERRY.LEAF.${season}LEFT.`]
            }

            if (bottomRight && !isEmptyIndex(right) && isEmptyIndex(bottom)) {
                bottomRight.index = tileMap[`MULBERRY.LEAF.${season}TOP.`]
            }

            if (bottomLeft && isEmptyIndex(left) && !isEmptyIndex(bottom)) {
                bottomLeft.index = tileMap[`MULBERRY.LEAF.${season}RIGHT.`]
            }


            if (bottomLeft && !isEmptyIndex(left) && isEmptyIndex(bottom)) {
                bottomLeft.index = tileMap[`MULBERRY.LEAF.${season}TOP.`]
            }
        })
    });

    let repetitionCount = 0;

    while (repetitionCount < panel.riverSize) {
        addBarleyAndWheat();
        repetitionCount++;
    }

    let noiseOne = drawNoise(1, 2, panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (!isEmptyIndex(tile)) {
                return;
            }
            if (noiseOne[rowIndex][columnIndex] > 0.8) {
                tile.index = tileMap[OAT_KEY];
            } else if (noiseOne[rowIndex][columnIndex] > 0.7) {
                tile.index = tileMap[BARLEY_KEY];
            }
        })
    });

    function addBarleyAndWheat() {
        layerData.forEach((row, y) => {
            row.forEach((tile, x) => {
                if (tile.index !== tileMap[BARLEY_KEY] && tile.index !== tileMap[OAT_KEY]) {
                    return;
                }

                const { left, right, top, bottom } = getNeighbours(layerData, x, y)

                const directions = [left, right, top, bottom]

                directions.forEach(direction => {
                    if (panel.floorLayer.find(floor => floor?.x === direction?.x && floor?.y === direction?.y)) {
                        return;
                    }

                    if (!direction || !isEmptyIndex(direction)){
                        return;
                    }

                    const plantChance = rng()

                    if (plantChance < 0.1) {
                        direction.index = tileMap['LAVA.LEFT.BOTTOM.'];
                    } else if (plantChance < 0.2) {
                        direction.index = tileMap['LAVA.TOP.'];
                    }
                })
            })
        })

        layerData.forEach((row, y) => {
            row.forEach((tile, x) => {
                if (tile.index === tileMap['LAVA.LEFT.BOTTOM.']) {
                    tile.index = tileMap[BARLEY_KEY]
                }

                if (tile.index === tileMap['LAVA.TOP.']) {
                    tile.index = tileMap[OAT_KEY]
                }
            })
        })
    }

    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function drawSubtropicalDesertRiver(mockLayerData, layerData, panel, rng) {
    if (!panel.riverSize) {
        return;
    }


    const BARLEY_KEY = 'MAIZE.OLD'
    const OAT_KEY = 'BEAN.OLD';
    const TRUNK_KEY = 'UAPACAA.TRUNK';
    const BRANCH_KEY = 'UAPACAA.BRANCH.LEFT.RIGHT.';
    const LEAF_KEY = 'UAPACAA.LEAF.LEFT.RIGHT.TOP.BOTTOM.';
    const VINE_KEY = 'UAPACAA.BRANCH.LEFT.';
    const EMPTY_KEY = 'EMPTY';

    const keys = {
        STARTING_KEY: TRUNK_KEY,
        TRUNK_KEY,
        WALK_KEY: VINE_KEY,
        EMPTY_KEY
    }

    const season = '';

    const riverBanks = panel.floorLayer.forEach(floor => {
        if (floor.tileType === 'WATER') {
            const { left, right, top, bottom } = getNeighbours(layerData, floor.x, floor.y)
            const directions = [left, right, top, bottom]

            directions.forEach(direction => {
                if (panel.floorLayer.find(floor => floor?.x === direction?.x && floor?.y === direction?.y)) {
                    return;
                }

                if (!direction || !isEmptyIndex(direction)){
                    return;
                }

                const plantChance = rng()

                if (plantChance < 0.25) {
                    direction.index = tileMap[BARLEY_KEY];
                } else if (plantChance < 0.5) {
                    direction.index = tileMap[OAT_KEY];
                } else if (plantChance < 0.6) {
                    if (direction === bottom && layerData[direction.y + 1]) {
                        layerData[direction.y + 1][direction.x].index = tileMap[TRUNK_KEY];
                    } else {
                        direction.index = tileMap[TRUNK_KEY];
                    }
                }
            })
        }
    })

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (tile?.index !== tileMap[TRUNK_KEY]) {
                return;
            }

            const { left, right, top, bottom } = getNeighbours(layerData, x, y)
            const { bottomLeft, bottomRight, topLeft, topRight } = getDiagonalNeighbours(layerData, x, y)

            let moveWeights = {
                left: 3,
                up: 0,
                down: 1,
                right: 3
            }

            const keys = {
                STARTING_KEY: TRUNK_KEY,
                TRUNK_KEY,
                WALK_KEY: LEAF_KEY,
                EMPTY_KEY
            }

            if (top) {
                top.index = tileMap['UAPACAA.BRANCH.BOTTOM.']
            }

            if (topLeft && isEmptyIndex(topLeft)) {
                topLeft.index = tileMap['UAPACAA.BRANCH.LEFT.']
            }

            if (topRight && isEmptyIndex(topRight)) {
                topRight.index = tileMap['UAPACAA.BRANCH.RIGHT.']
            }

            if (left && isEmptyIndex(left)) {
                left.index = tileMap['UAPACAA.BRANCH.LEFT.']
            }

            if (right && isEmptyIndex(right)) {
                right.index = tileMap['UAPACAA.BRANCH.RIGHT.']
            }

            let repetitionCount = 0;

            while (repetitionCount < 10) {
                randomWalk(layerData, { x, y }, 3, moveWeights, keys, rng, { isDownForbiddenOnFirstStep: true, skipNotEmptyTiles: true })
                repetitionCount++;
            }

            drawNeighbours(layerData, 'UAPACAA.BRANCH.', tileMap['UAPACAA.BRANCH.LEFT.RIGHT.'], { isDiagonalRelevent: false, connectOnBorder: true, startingIndexes: [tileMap['UAPACAA.TRUNK']] });
        })
    });

    
    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (tile?.index !== tileMap[LEAF_KEY]) {
                return;
            }

            const { left, right, top, bottom } = getNeighbours(layerData, x, y)
            const { bottomLeft, bottomRight, topLeft, topRight } = getDiagonalNeighbours(layerData, x, y)

            if (!isEmptyIndex(left) && isEmptyIndex(bottom) && isEmptyIndex(right) && isEmptyIndex(top)) {
                tile.index = tileMap['UAPACAA.LEAF.LEFT.']
            }

            if (!isEmptyIndex(right) && isEmptyIndex(bottom) && isEmptyIndex(left) && isEmptyIndex(top)) {
                tile.index = tileMap['UAPACAA.LEAF.LEFT.']
            }

            if (!isEmptyIndex(bottom) && isEmptyIndex(right) && isEmptyIndex(left) && isEmptyIndex(top)) {
                tile.index = tileMap['UAPACAA.LEAF.BOTTOM.']
            }
        })
    });

    let repetitionCount = 0;

    while (repetitionCount < panel.riverSize) {
        addBarleyAndWheat();
        repetitionCount++;
    }

    let noiseOne = drawNoise(1, 2, panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (!isEmptyIndex(tile)) {
                return;
            }
            if (noiseOne[rowIndex][columnIndex] > 0.8) {
                tile.index = tileMap[OAT_KEY];
            } else if (noiseOne[rowIndex][columnIndex] > 0.7) {
                tile.index = tileMap[BARLEY_KEY];
            }
        })
    });

    function addBarleyAndWheat() {
        layerData.forEach((row, y) => {
            row.forEach((tile, x) => {
                if (tile.index !== tileMap[BARLEY_KEY] && tile.index !== tileMap[OAT_KEY]) {
                    return;
                }

                const { left, right, top, bottom } = getNeighbours(layerData, x, y)

                const directions = [left, right, top, bottom]

                directions.forEach(direction => {
                    if (panel.floorLayer.find(floor => floor?.x === direction?.x && floor?.y === direction?.y)) {
                        return;
                    }

                    if (!direction || !isEmptyIndex(direction)){
                        return;
                    }

                    const plantChance = rng()

                    if (plantChance < 0.1) {
                        direction.index = tileMap['LAVA.LEFT.BOTTOM.'];
                    } else if (plantChance < 0.2) {
                        direction.index = tileMap['LAVA.TOP.'];
                    }
                })
            })
        })

        layerData.forEach((row, y) => {
            row.forEach((tile, x) => {
                if (tile.index === tileMap['LAVA.LEFT.BOTTOM.']) {
                    tile.index = tileMap[BARLEY_KEY]
                }

                if (tile.index === tileMap['LAVA.TOP.']) {
                    tile.index = tileMap[OAT_KEY]
                }
            })
        })
    }

    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function drawTropicalDesertRiver(mockLayerData, layerData, panel, rng) {
    if (!panel.riverSize) {
        return;
    }

    const BARLEY_KEY = 'SWEET-POTATO.OLD'
    const OAT_KEY = 'COTTON.OLD';
    const TRUNK_KEY = 'ANTIARIS.TRUNK';
    const BRANCH_KEY = 'ANTIARIS.BRANCH.LEFT.RIGHT.';
    const LEAF_KEY = 'ANTIARIS.LEAF.LEFT.RIGHT.TOP.BOTTOM.';
    const VINE_KEY = 'ANTIARIS.BRANCH.LEFT.';
    const EMPTY_KEY = 'EMPTY';

    const keys = {
        STARTING_KEY: TRUNK_KEY,
        TRUNK_KEY,
        WALK_KEY: VINE_KEY,
        EMPTY_KEY
    }

    const season = '';

    const riverBanks = panel.floorLayer.forEach(floor => {
        if (floor.tileType === 'WATER') {
            const { left, right, top, bottom } = getNeighbours(layerData, floor.x, floor.y)
            const directions = [left, right, top, bottom]

            directions.forEach(direction => {
                if (panel.floorLayer.find(floor => floor?.x === direction?.x && floor?.y === direction?.y)) {
                    return;
                }

                if (!direction || !isEmptyIndex(direction)){
                    return;
                }

                const plantChance = rng()

                if (plantChance < 0.25) {
                    direction.index = tileMap[BARLEY_KEY];
                } else if (plantChance < 0.5) {
                    direction.index = tileMap[OAT_KEY];
                } else if (plantChance < 0.6) {
                    if (direction === bottom && layerData[direction.y + 1]) {
                        layerData[direction.y + 1][direction.x].index = tileMap[TRUNK_KEY];
                    } else {
                        direction.index = tileMap[TRUNK_KEY];
                    }
                }
            })
        }
    })

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (tile?.index !== tileMap[TRUNK_KEY]) {
                return;
            }

            const { left, right, top, bottom } = getNeighbours(layerData, x, y)
            const { bottomLeft, bottomRight, topLeft, topRight } = getDiagonalNeighbours(layerData, x, y)

            const topNeighbours = getNeighbours(layerData, top?.x, top?.y);
            const topLeftNeighbours = getNeighbours(layerData, topLeft?.x, topLeft?.y);
            const topLeftDiagonalNeighbours = getDiagonalNeighbours(layerData, topLeft?.x, topLeft?.y);
            const topRightNeighbours = getNeighbours(layerData, topRight?.x, topRight?.y);
            const topRightDiagonalNeighbours = getDiagonalNeighbours(layerData, topLeft?.x, topLeft?.y);

            if (top?.index === tileMap[TRUNK_KEY] || left?.index === tileMap[TRUNK_KEY] || bottom?.index === tileMap[TRUNK_KEY] || right?.index === tileMap[TRUNK_KEY]) {
                tile.index = -1;
                return;
            }

            if (topNeighbours.top?.index === tileMap[TRUNK_KEY] || topNeighbours.left?.index === tileMap[TRUNK_KEY] || topNeighbours.right?.index === tileMap[TRUNK_KEY]) {
                tile.index = -1;
                return;
            }

            if (topLeftNeighbours.top?.index === tileMap[TRUNK_KEY] || topLeftNeighbours.left?.index === tileMap[TRUNK_KEY] || topLeftNeighbours.right?.index === tileMap[TRUNK_KEY]) {
                tile.index = -1;
                return;
            }

            if (topRightNeighbours.top?.index === tileMap[TRUNK_KEY] || topRightNeighbours.left?.index === tileMap[TRUNK_KEY] || topRightNeighbours.right?.index === tileMap[TRUNK_KEY]) {
                tile.index = -1;
                return;
            }

            if (panel.floorLayer.find(floor => floor.x === top?.x && floor.y === top?.y)) {
                tile.index = -1;
                return;
            }

            let moveWeights = {
                left: 1,
                up: 0,
                down: 3,
                right: 1
            }

            const keys = {
                STARTING_KEY: TRUNK_KEY,
                TRUNK_KEY,
                WALK_KEY: LEAF_KEY,
                EMPTY_KEY
            }

            if (top) {
                top.index = tileMap['ANTIARIS.BRANCH.BOTTOM.']
            }

            if (topLeft && isEmptyIndex(topLeft)) {
                topLeft.index = tileMap['ANTIARIS.BRANCH.RIGHT.']
            }

            if (topRight && isEmptyIndex(topRight)) {
                topRight.index = tileMap['ANTIARIS.BRANCH.LEFT.']
            }

            const allNeighbours = [
                topNeighbours.top,
                topRightNeighbours.right,
                topRightNeighbours.top,
                topRightNeighbours.bottom,
                topLeftNeighbours.left,
                topLeftNeighbours.top,
                topLeftNeighbours.bottom,
                topRightDiagonalNeighbours.topRight,
                topLeftDiagonalNeighbours.topLeft
            ]

            allNeighbours.forEach(tile => {
                if (tile) {
                    tile.index = tileMap[LEAF_KEY]
                }
            })

            // drawNeighbours(layerData, 'ANTIARIS.BRANCH.', tileMap['ANTIARIS.BRANCH.LEFT.RIGHT.'], { isDiagonalRelevent: false, connectOnBorder: true, startingIndexes: [tileMap['UAPACAA.TRUNK']] });
        })
    });

    
    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (tile?.index !== tileMap[LEAF_KEY]) {
                return;
            }

            const { left, right, top, bottom } = getNeighbours(layerData, x, y)
            const { bottomLeft, bottomRight, topLeft, topRight } = getDiagonalNeighbours(layerData, x, y)

            if (!isEmptyIndex(left) && !isEmptyIndex(bottom) && isEmptyIndex(right) && isEmptyIndex(top)) {
                tile.index = tileMap['ANTIARIS.LEAF.LEFT.BOTTOM.']
            }

            if (isEmptyIndex(right) && isEmptyIndex(bottom) && !isEmptyIndex(left) && !isEmptyIndex(top)) {
                tile.index = tileMap['ANTIARIS.LEAF.LEFT.TOP.']
            }

            if (!isEmptyIndex(bottom) && !isEmptyIndex(right) && isEmptyIndex(left) && isEmptyIndex(top)) {
                tile.index = tileMap['ANTIARIS.LEAF.RIGHT.BOTTOM.']
            }

            if (!isEmptyIndex(right) && isEmptyIndex(bottom) && isEmptyIndex(left) && !isEmptyIndex(top)) {
                tile.index = tileMap['ANTIARIS.LEAF.RIGHT.TOP.']
            }
        })
    });

    let repetitionCount = 0;

    while (repetitionCount < panel.riverSize) {
        addBarleyAndWheat();
        repetitionCount++;
    }

    let noiseOne = drawNoise(1, 2, panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (!isEmptyIndex(tile)) {
                return;
            }
            if (noiseOne[rowIndex][columnIndex] > 0.8) {
                tile.index = tileMap[OAT_KEY];
            } else if (noiseOne[rowIndex][columnIndex] > 0.7) {
                tile.index = tileMap[BARLEY_KEY];
            }
        })
    });

    function addBarleyAndWheat() {
        layerData.forEach((row, y) => {
            row.forEach((tile, x) => {
                if (tile.index !== tileMap[BARLEY_KEY] && tile.index !== tileMap[OAT_KEY]) {
                    return;
                }

                const { left, right, top, bottom } = getNeighbours(layerData, x, y)

                const directions = [left, right, top, bottom]

                directions.forEach(direction => {
                    if (panel.floorLayer.find(floor => floor?.x === direction?.x && floor?.y === direction?.y)) {
                        return;
                    }

                    if (!direction || !isEmptyIndex(direction)){
                        return;
                    }

                    const plantChance = rng()

                    if (plantChance < 0.1) {
                        direction.index = tileMap['LAVA.LEFT.BOTTOM.'];
                    } else if (plantChance < 0.2) {
                        direction.index = tileMap['LAVA.TOP.'];
                    }
                })
            })
        })

        layerData.forEach((row, y) => {
            row.forEach((tile, x) => {
                if (tile.index === tileMap['LAVA.LEFT.BOTTOM.']) {
                    tile.index = tileMap[BARLEY_KEY]
                }

                if (tile.index === tileMap['LAVA.TOP.']) {
                    tile.index = tileMap[OAT_KEY]
                }
            })
        })
    }

    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function drawCoolTemperateDesertRiver(mockLayerData, layerData, panel, rng) {
    if (!panel.riverSize) {
        return;
    }

    const BARLEY_KEY = 'BARLEY.SUMMER.'
    const OAT_KEY = 'OATS.SUMMER';

    const riverBanks = panel.floorLayer.forEach(floor => {
        if (floor.tileType === 'WATER') {
            const { left, right, top, bottom } = getNeighbours(layerData, floor.x, floor.y)
            const directions = [left, right, top, bottom]

            directions.forEach(direction => {
                if (panel.floorLayer.find(floor => floor?.x === direction?.x && floor?.y === direction?.y)) {
                    return;
                }

                if (!direction || !isEmptyIndex(direction)){
                    return;
                }

                const plantChance = rng()

                if (plantChance < 0.25) {
                    direction.index = tileMap[BARLEY_KEY];
                } else if (plantChance < 0.5) {
                    direction.index = tileMap[OAT_KEY];
                }
            })
        }
    })

    let repetitionCount = 0;

    while (repetitionCount < panel.riverSize) {
        addBarleyAndWheat();
        repetitionCount++;
    }

    let noiseOne = drawNoise(1, 2, panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (!isEmptyIndex(tile)) {
                return;
            }
            if (noiseOne[rowIndex][columnIndex] > 0.8) {
                tile.index = tileMap[OAT_KEY];
            } else if (noiseOne[rowIndex][columnIndex] > 0.7) {
                tile.index = tileMap[BARLEY_KEY];
            }
        })
    });

    function addBarleyAndWheat() {
        layerData.forEach((row, y) => {
            row.forEach((tile, x) => {
                if (tile.index !== tileMap[BARLEY_KEY] && tile.index !== tileMap[OAT_KEY]) {
                    return;
                }

                const { left, right, top, bottom } = getNeighbours(layerData, x, y)

                const directions = [left, right, top, bottom]

                directions.forEach(direction => {
                    if (panel.floorLayer.find(floor => floor?.x === direction?.x && floor?.y === direction?.y)) {
                        return;
                    }

                    if (!direction || !isEmptyIndex(direction)){
                        return;
                    }

                    const plantChance = rng()

                    if (plantChance < 0.1) {
                        direction.index = tileMap['LAVA.LEFT.BOTTOM.'];
                    } else if (plantChance < 0.2) {
                        direction.index = tileMap['LAVA.TOP.'];
                    }
                })
            })
        })

        layerData.forEach((row, y) => {
            row.forEach((tile, x) => {
                if (tile.index === tileMap['LAVA.LEFT.BOTTOM.']) {
                    tile.index = tileMap[BARLEY_KEY]
                }

                if (tile.index === tileMap['LAVA.TOP.']) {
                    tile.index = tileMap[OAT_KEY]
                }
            })
        })
    }

    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function drawDeepestJungle(mockLayerData, layerData, panel, rng) {
    const panelTrunkCoords = [];
    const GROUND_KEY = 'EMPTY'

    const treeNumber = 'ONE';

    const keys = {
        TRUNK_KEY: `DEEPEST-JUNGLE.${treeNumber}.TRUNK.A1`,
        GROUND_KEY: 'EMPTY',
        LEAF_KEY: `DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.TOP.BOTTOM.`,
    }

    const secondRingChance = 11 / 12;
    const halfRingChance = 1/2;
    const thirdRingChance = 3 / 26;

    const stampShape1 = [
        // { x: 0, y: 0 },
        { x: 1, y: 0, index: tileMap[`DEEPEST-JUNGLE.${treeNumber}.TRUNK.A2`] },
        { x: 0, y: 1, index: tileMap[`DEEPEST-JUNGLE.${treeNumber}.TRUNK.B1`] },
        { x: 1, y: 1, index: tileMap[`DEEPEST-JUNGLE.${treeNumber}.TRUNK.B2`] },

        // 
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 2, index: tileMap[keys.LEAF_KEY] },

        // // 
        { x: -2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: -2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        // 
        { x: -3, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -3, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },

        // 
        { x: -4, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
    ]

    const neighbours = getMatchingBiomeNeighbours(panel);

    const trunkCoords = drawTreeDots(mockLayerData, layerData, panel, stampShape1, 1.5, false, neighbours, keys, rng);

    drawNeighbours(layerData, `DEEPEST-JUNGLE.${treeNumber}.LEAF.`, tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.TOP.BOTTOM.`], {
        isDiagonalRelevent: false,
        connectOnBorder: true,
        startingIndexes: [ tileMap[`DEEPEST-JUNGLE.${treeNumber}.TRUNK.A1`], tileMap[`DEEPEST-JUNGLE.${treeNumber}.TRUNK.A2`], tileMap[`DEEPEST-JUNGLE.${treeNumber}.TRUNK.B1`], tileMap[`DEEPEST-JUNGLE.${treeNumber}.TRUNK.B2`]],
    });

    const leftRightTopBottom = tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.TOP.BOTTOM.`];
    const bottom = tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.BOTTOM.`];
    const top = tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.TOP.`];
    const leftRightTop = tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.TOP.`]
    const leftRightBottom = tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.BOTTOM.`]

    const bottomAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.TOP.BOTTOM.HARVEST`],
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.TOP.BOTTOM.`],
        ],
        [bottom]: [
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.BOTTOM.`]
        ],
        [top]: [
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.TOP.`]
        ],
        [leftRightTop]: [
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.TOP.BOTTOM.`],
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.TOP.`],
        ],
        [leftRightBottom]: [
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.TOP.BOTTOM.`],
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.BOTTOM.`],
        ],
    }

    const leftAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.TOP.BOTTOM.HARVEST`],
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.`],
        ],
        [bottom]: [
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.BOTTOM.`]
        ],
        [top]: [
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.TOP.`]
        ],
    }

    const rightAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.TOP.BOTTOM.HARVEST`],
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.TOP.BOTTOM.HORIZONTAL-LEFT`],
        ],
        [bottom]: [
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.BOTTOM.`]
        ],
        [top]: [
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.TOP.`]
        ],
    }

    const topAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.TOP.BOTTOM.HARVEST`],
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.TOP.BOTTOM.`],
        ],
        [bottom]: [
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.BOTTOM.`]
        ],
        [top]: [
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.TOP.`]
        ],
        [leftRightTop]: [
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.TOP.BOTTOM.`],
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.TOP.`],
        ],
        [leftRightBottom]: [
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.TOP.BOTTOM.`],
            tileMap[`DEEPEST-JUNGLE.${treeNumber}.LEAF.BOTTOM.`],
        ],
    }

    layerData.forEach((row, _y) => {
        row.forEach((tile, _x) => {
            if (tile.index === tileMap[`DEEPEST-JUNGLE.${treeNumber}.TRUNK.A1`]) {
                panelTrunkCoords.push({ x: _x, y: _y })
                const leftTiles = [
                    { x: -1, y: 0, },
                    { x: -2, y: 0, },
                    { x: -3, y: 0, },
                    { x: -1, y: 1, },
                    { x: -2, y: 1, },
                    { x: -3, y: 1, },
                ]

                const bottomTiles = [
                    { x: -3, y: 2, },
                    { x: -3, y: 3, },
                    { x: -2, y: 2, },
                    { x: -2, y: 3, },
                    { x: -1, y: 2, },
                    { x: -1, y: 3, },
                    { x: 0, y: 2, },
                    { x: 0, y: 3, },
                    { x: 1, y: 2, },
                    { x: 1, y: 3, },
                    { x: 2, y: 2, },
                    { x: 2, y: 3, },
                    { x: 3, y: 2, },
                    { x: 3, y: 3, },
                ]

                const rightTiles = [
                    { x: 2, y: 1, },
                    { x: 3, y: 1, },
                    { x: 4, y: 1, },
                    { x: 2, y: 0, },
                    { x: 3, y: 0, },
                    { x: 4, y: 0, },
                ]

                const topTiles = [
                    { x: 2, y: -1, },
                    { x: 3, y: -1, },
                    { x: 4, y: -1, },
                    { x: 2, y: -2, },
                    { x: 3, y: -2, },
                    { x: 4, y: -2, },
                    { x: 1, y: -1, },
                    { x: 1, y: -2, },
                    { x: 0, y: -1, },
                    { x: 0, y: -2, },
                    { x: -1, y: -1, },
                    { x: -1, y: -2, },
                    { x: -2, y: -1, },
                    { x: -2, y: -2, },
                ]

                bottomTiles.forEach(({ x, y }) => {
                    const newTile = layerData[_y + y] ? layerData[_y + y][_x + x] : undefined;

                    if (!newTile) {
                        return;
                    }

                    const newTileIndex = newTile.index;
                    if (newTile && newTileIndex && bottomAlternativeTiles[newTileIndex]) {
                        if (rng() < 0.5) {
                            return;
                        }
                        
                        const items = bottomAlternativeTiles[newTileIndex]
                        newTile.index = items[Math.floor(rng()*items.length)];
                    }
                })

                topTiles.forEach(({ x, y }) => {
                    const newTile = layerData[_y + y] ? layerData[_y + y][_x + x] : undefined;

                    if (!newTile) {
                        return;
                    }

                    const newTileIndex = newTile.index;
                    if (newTile && newTileIndex && topAlternativeTiles[newTileIndex]) {
                        if (rng() < 0.5) {
                            return;
                        }
                        
                        const items = topAlternativeTiles[newTileIndex]
                        newTile.index = items[Math.floor(rng()*items.length)];
                    }
                })

                leftTiles.forEach(({ x, y }) => {
                    const newTile = layerData[_y + y] ? layerData[_y + y][_x + x] : undefined;

                    if (!newTile) {
                        return;
                    }

                    const newTileIndex = newTile.index;
                    if (newTile && newTileIndex && leftAlternativeTiles[newTileIndex]) {
                        if (rng() < 0.5) {
                            return;
                        }
                        
                        const items = leftAlternativeTiles[newTileIndex]
                        newTile.index = items[Math.floor(rng()*items.length)];
                    }
                })

                rightTiles.forEach(({ x, y }) => {
                    const newTile = layerData[_y + y] ? layerData[_y + y][_x + x] : undefined;

                    if (!newTile) {
                        return;
                    }

                    const newTileIndex = newTile.index;
                    if (newTile && newTileIndex && rightAlternativeTiles[newTileIndex]) {
                        if (rng() < 0.5) {
                            return;
                        }
                        
                        const items = rightAlternativeTiles[newTileIndex]
                        newTile.index = items[Math.floor(rng()*items.length)];
                    }
                })
            }
        })
    })

    const allTrunkCoords = [ ...trunkCoords ]
    const usedTrunkCoords = [];

    panelTrunkCoords.forEach((coords, index) => {
        const one = trunkCoords[Math.floor(rng()*trunkCoords.length)];
        const two = coords

        const curve = generateCurve([one.x, one.y, two.x, two.y], true, undefined, undefined, 42)

        curve.forEach((value, index) => {
            if (index % 2 === 1) {
                const y = value;
                const x = curve[index-1]

                const tileRow = layerData[y]

                if (tileRow && layerData[y][x] && isEmptyIndex(layerData[y][x])) {
                    layerData[y][x].index = tileMap['DEEPEST-JUNGLE.VINE.LEFT.RIGHT.']
                }
            }
        })
    })

    panelTrunkCoords.forEach((coords, index) => {
        const one = panelTrunkCoords[Math.floor(rng()*panelTrunkCoords.length)];
        const two = coords

        const curve = generateCurve([one.x, one.y, two.x, two.y], true, undefined, undefined, 42)

        curve.forEach((value, index) => {
            if (index % 2 === 1) {
                const y = value;
                const x = curve[index-1]

                const tileRow = layerData[y]

                if (tileRow && layerData[y][x] && isEmptyIndex(layerData[y][x])) {
                    layerData[y][x].index = tileMap['DEEPEST-JUNGLE.VINE.LEFT.RIGHT.']
                }
            }
        })
    })

    // FIXME: tidy up the neighbour vine issue
    // if any of my neighbours have 3 neighbours and i have three neighbours, delete one of my neighbours.
    // if it is a neighbour on my left or right, delete my top or bottom neighbour
    // if it is a neighbour on my top or bottom, delete my left or right neighbour
    layerData.forEach((row, _y) => {
        row.forEach((tile, _x) => {
            if (tile.index !== tileMap['DEEPEST-JUNGLE.VINE.LEFT.RIGHT.']) {
                return;
            }

            const { left, right, top, bottom } = getNeighbours(layerData, _x, _y);

            const leftCount = countNeighbours(layerData, left?.index, left?.x, left?.y);
            const rightCount = countNeighbours(layerData, right?.index, right?.x, right?.y);
            const topCount = countNeighbours(layerData, top?.index, top?.x, top?.y);
            const bottomCount = countNeighbours(layerData, bottom?.index, bottom?.x, bottom?.y);

            const neighbourCount = countNeighbours(layerData, tile.index, _x, _y);

            if (neighbourCount >= 3 && (leftCount >= 3 || rightCount >= 3)) {
                if (top) {
                    // top.index = -1;
                }

                if (bottom) {
                    // bottom.index = -1;
                }
            }

            if (neighbourCount >= 3 && (topCount>= 3 || bottomCount >= 3)) {
                if (right) {
                    // right.index = -1;
                }

                if (left) {
                    // left.index = -1;
                }
            }
        })
    });
    // END FIXME

    drawNeighbours(layerData, 'DEEPEST-JUNGLE.VINE.', tileMap['DEEPEST-JUNGLE.VINE.LEFT.RIGHT.'], { isDiagonalRelevent: false, connectOnBorder: true });

    layerData.forEach((row, _y) => {
        row.forEach((tile, _x) => {
            if (tile.index === tileMap[`DEEPEST-JUNGLE.${treeNumber}.TRUNK.A1`]) {
                let newNumber;

                const random = rng();
                if (random < 0.333) {
                    newNumber = 'TWO'
                }

                if (random > 0.333 && random < 0.666) {
                    newNumber = 'THREE'
                }

                if (!newNumber) {
                    return;
                }

                const tileStrings = [
                    'DEEPEST-JUNGLE.ONE.TRUNK.A1',
                    'DEEPEST-JUNGLE.ONE.TRUNK.A2',
                    'DEEPEST-JUNGLE.ONE.TRUNK.B1',
                    'DEEPEST-JUNGLE.ONE.TRUNK.B2',
                    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.RIGHT.TOP.BOTTOM.',
                    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.RIGHT.TOP.BOTTOM.HARVEST',
                    'DEEPEST-JUNGLE.ONE.LEAF.RIGHT.TOP.BOTTOM.',
                    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.TOP.BOTTOM.',
                    'DEEPEST-JUNGLE.ONE.LEAF.TOP.BOTTOM.',
                    'DEEPEST-JUNGLE.ONE.LEAF.RIGHT.BOTTOM.',
                    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.BOTTOM.',
                    'DEEPEST-JUNGLE.ONE.LEAF.BOTTOM.',
                    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.RIGHT.BOTTOM.',
                    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.TOP.BOTTOM.',
                    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.RIGHT.',
                    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.RIGHT.TOP.BOTTOM.HORIZONTAL-LEFT',
                    'DEEPEST-JUNGLE.ONE.LEAF.RIGHT.TOP.',
                    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.TOP.',
                    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.RIGHT.TOP.',
                    'DEEPEST-JUNGLE.ONE.LEAF.TOP.',
                    'DEEPEST-JUNGLE.ONE.LEAF.NONE.',
                    'DEEPEST-JUNGLE.ONE.LEAF.RIGHT.',
                    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.',
                ]

                tile.index = tileMap['DEEPEST-JUNGLE.ONE.TRUNK.A1'.replace('ONE', newNumber)]

                stampShape1.forEach(({ x, y}) => {
                    const replacedTile = layerData[_y + y] ? layerData[_y + y][_x + x] : undefined;

                    if (!replacedTile) {
                        return;
                    }

                    let currentIndexString = tileStrings.find(indexString => (tileMap[indexString] === replacedTile.index))

                    if (currentIndexString === 'DEEPEST-JUNGLE.ONE.LEAF.LEFT.RIGHT.TOP.BOTTOM.' && rng() < 0.4) {
                        currentIndexString = 'DEEPEST-JUNGLE.ONE.LEAF.LEFT.RIGHT.TOP.BOTTOM.HARVEST'
                    }

                    if (currentIndexString) {
                        replacedTile.index = tileMap[currentIndexString.replace('ONE', newNumber)]
                    }
                })
            }
        })
    });

    //Jungle plants
    const JUNGLE_PLANT_1 = 'JUNGLE-PLANT.1'
    const JUNGLE_PLANT_2 = 'JUNGLE-PLANT.2'
    const JUNGLE_PLANT_3 = 'JUNGLE-PLANT.3'
    const JUNGLE_PLANT_4 = 'JUNGLE-PLANT.4'
    const JUNGLE_PLANT_5 = 'JUNGLE-PLANT.5'
    const JUNGLE_PLANT_6 = 'KOHLRABI.HARVEST'

    let noiseOne = drawNoise(1, 2, panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (!isEmptyIndex(tile)) {
                return;
            }
            if (noiseOne[rowIndex][columnIndex] > 0.8) {
                tile.index = tileMap[JUNGLE_PLANT_1];
            } else if (noiseOne[rowIndex][columnIndex] > 0.7) {
                tile.index = tileMap[JUNGLE_PLANT_2];
            } else if (noiseOne[rowIndex][columnIndex] > 0.65) {
                tile.index = tileMap[JUNGLE_PLANT_3];
            } else if (noiseOne[rowIndex][columnIndex] > 0.6) {
                tile.index = tileMap[JUNGLE_PLANT_4];
            } else if (noiseOne[rowIndex][columnIndex] > 0.55) {
                tile.index = tileMap[JUNGLE_PLANT_5];
            } else if (noiseOne[rowIndex][columnIndex] > 0.5) {
                tile.index = tileMap[JUNGLE_PLANT_6];
            } else {
                tile.index = tileMap[GROUND_KEY];
            }
        })
    });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    return mockLayerData;
}

export function drawPrimeordeal(mockLayerData, layerData, panel, rng) {
    const panelTrunkCoords = [];
    const GROUND_KEY = 'EMPTY'
    const GRASS_KEY = 'BAMBOO';

    const treeNumber = 'ONE';

    const keys = {
        TRUNK_KEY: `PRIMEORDEAL.TRUNK`,
        GROUND_KEY: 'EMPTY',
        LEAF_KEY: `PRIMEORDEAL.LEAF.LEFT.RIGHT.TOP.BOTTOM.`,
    }

    const secondRingChance = 11 / 12;
    const halfRingChance = 1/2;
    const thirdRingChance = 3 / 26;

    const stampShape1 = [
        { x: 0, y: 0, index: tileMap['PRIMEORDEAL.TRUNK.LARGE.LEFT.'] },
        { x: 1, y: 0, index: tileMap['PRIMEORDEAL.TRUNK.LARGE.RIGHT.'] },
        { x: 0, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 1, index: tileMap[keys.LEAF_KEY] },

        // 
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 2, index: tileMap[keys.LEAF_KEY] },

        // // 
        { x: -2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: -2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        // 
        { x: -3, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -3, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },

        // 
        { x: -4, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
    ]

    const stampShape2 = [
        { x: 1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 1, index: tileMap[keys.LEAF_KEY] },

        // 
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 2, index: tileMap[keys.LEAF_KEY] },

        // // 
        { x: -2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        // 
        { x: -3, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 0, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },

        // 
        { x: -4, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
    ]

    const neighbours = getMatchingBiomeNeighbours(panel);

    const trunkCoords = drawTreeDots(mockLayerData, layerData, panel, [stampShape1, stampShape2], 1.5, false, neighbours, keys, rng);

    drawNeighbours(layerData, `PRIMEORDEAL.LEAF.`, tileMap[`PRIMEORDEAL.LEAF.LEFT.RIGHT.TOP.BOTTOM.`], {
        isDiagonalRelevent: false,
        startingIndexes: [ tileMap[`PRIMEORDEAL.TRUNK.LARGE.LEFT.`], tileMap[`PRIMEORDEAL.TRUNK.LARGE.RIGHT.`], tileMap[`PRIMEORDEAL.TRUNK`]],
    });

    const leftRightTopBottom = tileMap[`PRIMEORDEAL.LEAF.LEFT.RIGHT.TOP.BOTTOM.`];
    const bottom = tileMap[`PRIMEORDEAL.LEAF.BOTTOM.`];
    const top = tileMap[`PRIMEORDEAL.LEAF.TOP.`];
    const leftRightTop = tileMap[`PRIMEORDEAL.LEAF.LEFT.RIGHT.TOP.`]
    const leftRightBottom = tileMap[`PRIMEORDEAL.LEAF.LEFT.RIGHT.BOTTOM.`]

    const allTrunkCoords = [ ...trunkCoords ]
    const usedTrunkCoords = [];

    layerData.forEach((row, _y) => {
        row.forEach((tile, _x) => {
            if (tile.index === tileMap[`PRIMEORDEAL.TRUNK`] || tile.index === tileMap[`PRIMEORDEAL.TRUNK.LARGE.LEFT.`]) {
                panelTrunkCoords.push({ x: _x, y: _y })
            }
        })
    })

    panelTrunkCoords.forEach((coords, index) => {
        const one = trunkCoords[Math.floor(rng()*trunkCoords.length)];
        const two = coords

        const curve = generateCurve([one.x, one.y, two.x, two.y], true, undefined, undefined, 42)

        curve.forEach((value, index) => {
            if (index % 2 === 1) {
                const y = value;
                const x = curve[index-1]

                const tileRow = layerData[y]

                if (tileRow && layerData[y][x] && isEmptyIndex(layerData[y][x])) {
                    layerData[y][x].index = tileMap['PRIMEORDEAL.VINE.LEFT.RIGHT.']
                }
            }
        })
    })

    drawNeighbours(layerData, 'PRIMEORDEAL.VINE.', tileMap['PRIMEORDEAL.VINE.LEFT.RIGHT.'], {
        isDiagonalRelevent: false,
        connectOnBorder: true,
        startingIndexes: [
            tileMap[`PRIMEORDEAL.LEAF.LEFT.RIGHT.TOP.BOTTOM.`],
            tileMap[`PRIMEORDEAL.LEAF.LEFT.RIGHT.`],
            tileMap[`PRIMEORDEAL.LEAF.LEFT.RIGHT.TOP.`],
            tileMap[`PRIMEORDEAL.LEAF.LEFT.TOP.BOTTOM.`],
            tileMap[`PRIMEORDEAL.LEAF.RIGHT.TOP.BOTTOM.`],
            tileMap[`PRIMEORDEAL.LEAF.LEFT.`],
            tileMap[`PRIMEORDEAL.LEAF.RIGHT.`],
            tileMap[`PRIMEORDEAL.LEAF.TOP.`],
            tileMap[`PRIMEORDEAL.LEAF.BOTTOM.`],
            tileMap[`PRIMEORDEAL.LEAF.LEFT.BOTTOM.`],
            tileMap[`PRIMEORDEAL.LEAF.RIGHT.BOTTOM.`],
            tileMap[`PRIMEORDEAL.LEAF.LEFT.TOP.`],
            tileMap[`PRIMEORDEAL.LEAF.RIGHT.TOP.`],
            tileMap[`PRIMEORDEAL.LEAF.NONE.`],
        ],
    });

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile.index === tileMap['PRIMEORDEAL.VINE.TOP.BOTTOM.']) {
                if (rng() < 0.4) {
                    tile.index = tileMap['PRIMEORDEAL.VINE.TOP.BOTTOM.2']
                }
            }

            if (tile.index === tileMap['PRIMEORDEAL.VINE.LEFT.RIGHT.']) {
                if (rng() < 0.4) {
                    tile.index = tileMap['PRIMEORDEAL.VINE.LEFT.RIGHT.2']
                }
            }
        })
    });

    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            const { left, right, top, bottom } = getNeighbours(layerData, x, y)

            if (tile.index !== tileMap['PRIMEORDEAL.VINE.LEFT.RIGHT.GROWTH']) {
                return;
            }
            
            if (tile.index === tileMap['PRIMEORDEAL.VINE.LEFT.RIGHT.GROWTH']) {
                const isLeftVine = !isEmptyIndex(left) && left.index !== tileMap['PRIMEORDEAL.VINE.LEFT.RIGHT.GROWTH'];
                const isRightVine = !isEmptyIndex(right) && right.index !== tileMap['PRIMEORDEAL.VINE.LEFT.RIGHT.GROWTH'];
                const isTopVine = !isEmptyIndex(top) && top.index !== tileMap['PRIMEORDEAL.VINE.LEFT.RIGHT.GROWTH'];
                const isBottomVine = !isEmptyIndex(bottom) && bottom.index !== tileMap['PRIMEORDEAL.VINE.LEFT.RIGHT.GROWTH'];

                if (!isLeftVine && !isTopVine && !isRightVine && !isBottomVine) {
                    tile.index = -1;
                }
            }
        })
    });

    let noiseOne = drawNoise(6, 8, panel);
    let noiseTwo = drawNoise(1, 1, panel);

    let finalNoise = noiseOne.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseTwo[x][y]))
    })

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (!isEmptyIndex(tile)) {
                return;
            }
            if (finalNoise[rowIndex][columnIndex] > 0.5) {
                tile.index = tileMap[GRASS_KEY];
            }
        })
    });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    return mockLayerData;
}

function drawCircleTree(layerData, panel, rng, centreX, centreY, radius) {
    const stampShape1 = [];

    circle(centreX, centreY, radius, (x, y) => {
        if (!layerData[y] || !layerData[y][x]) {
            return;
        }
        layerData[y][x].index = tileMap[`PRIMEVAL.INNER.LEFT.RIGHT.TOP.`]
    })

    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            const { left, right, top, bottom } = getNeighbours(layerData, x, y);

            const isLeftEmpty = isEmptyIndex(left);
            const isRightEmpty = isEmptyIndex(right);
            const isTopEmpty = isEmptyIndex(top);
            const isBottomEmpty = isEmptyIndex(bottom);

            if (isEmptyIndex(tile) && !isEmptyIndex(bottom) && !isEmptyIndex(right) && isEmptyIndex(left) && isEmptyIndex(top)) {
                if (x < centreX && y < centreY) {
                    layerData[y][x].index = tileMap[`PRIMEVAL.INNER.LEFT.RIGHT.TOP.`]
                }
            }

            if (isEmptyIndex(tile) && !isEmptyIndex(bottom) && isEmptyIndex(right) && !isEmptyIndex(left) && isEmptyIndex(top)) {
                if (x > centreX && y < centreY) {
                    if (left.index !== tileMap['PRIMEVAL.LEFT.RIGHT.TOP.BOTTOM.']) {
                        layerData[y][x].index = tileMap[`PRIMEVAL.LEFT.RIGHT.TOP.BOTTOM.`]
                    }
                }
            }

            if (isEmptyIndex(tile) && isEmptyIndex(bottom) && isEmptyIndex(right) && !isEmptyIndex(left) && !isEmptyIndex(top)) {
                if (x > centreX && y > centreY) {
                    if (left.index !== tileMap['PRIMEVAL.LEFT.RIGHT.TOP.BOTTOM.'] && top.index !== tileMap['PRIMEVAL.LEFT.RIGHT.TOP.BOTTOM.']) {
                        layerData[y][x].index = tileMap[`PRIMEVAL.LEFT.RIGHT.TOP.BOTTOM.`]
                    }
                }
            }

            if (isEmptyIndex(tile) && isEmptyIndex(bottom) && !isEmptyIndex(right) && isEmptyIndex(left) && !isEmptyIndex(top)) {
                if (x < centreX && y > centreY) {
                    if (top.index !== tileMap['PRIMEVAL.LEFT.RIGHT.TOP.BOTTOM.']) {
                        layerData[y][x].index = tileMap[`PRIMEVAL.LEFT.RIGHT.TOP.BOTTOM.`]
                    }
                }
            }
        })
    });

    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (isEmptyIndex(tile)) {
                return;
            }

            const { left, right, top, bottom } = getNeighbours(layerData, x, y);

            const isLeftEmpty = isEmptyIndex(left);
            const isRightEmpty = isEmptyIndex(right);
            const isTopEmpty = isEmptyIndex(top);
            const isBottomEmpty = isEmptyIndex(bottom);

            if (isLeftEmpty && isRightEmpty && x < centreX) {
                tile.index = tileMap[`PRIMEVAL.INNER.LEFT.TOP.BOTTOM.`]
            }

            if (isLeftEmpty && isRightEmpty && x > centreX) {
                tile.index = tileMap[`PRIMEVAL.INNER.RIGHT.TOP.BOTTOM.`]
            }

            if (isTopEmpty && isBottomEmpty && y > centreY) {
                tile.index = tileMap[`PRIMEVAL.INNER.LEFT.RIGHT.BOTTOM.`]
            }

            if (isLeftEmpty && isBottomEmpty && y > centreY && x < centreX) {
                tile.index = tileMap[`PRIMEVAL.INNER.LEFT.RIGHT.TOP.BOTTOM.TR-GAP`]
            }

            if (isRightEmpty && isTopEmpty && y > centreY && x < centreX) {
                tile.index = tileMap[`PRIMEVAL.INNER.LEFT.BOTTOM.`]
            }

            if (isLeftEmpty && isTopEmpty && y < centreY && x < centreX) {
                tile.index = tileMap[`PRIMEVAL.INNER.LEFT.RIGHT.TOP.BOTTOM.BR-GAP`]
            }

            if (isRightEmpty && isBottomEmpty && y < centreY && x < centreX) {
                tile.index = tileMap[`PRIMEVAL.INNER.LEFT.TOP.`]
            }

            if (isRightEmpty && isTopEmpty && y < centreY && x > centreX) {
                tile.index = tileMap[`PRIMEVAL.INNER.LEFT.RIGHT.TOP.BOTTOM.BL-GAP`]
            }

            if (isLeftEmpty && isBottomEmpty && y < centreY && x > centreX) {
                tile.index = tileMap[`PRIMEVAL.INNER.RIGHT.TOP.`]
            }

            if (isLeftEmpty && isTopEmpty && y > centreY && x > centreX) {
                tile.index = tileMap[`PRIMEVAL.INNER.RIGHT.BOTTOM.`]
            }

            if (isRightEmpty && isBottomEmpty && y > centreY && x > centreX) {
                tile.index = tileMap[`PRIMEVAL.INNER.LEFT.RIGHT.TOP.BOTTOM.TL-GAP`]
            }
        })
    });

    circle(centreX, centreY, radius + 1, (x, y) => {
        if (!layerData[y] || !layerData[y][x]) {
            return;
        }
        if (!isEmptyIndex(layerData[y][x])) {
            return;
        }
        layerData[y][x].index = tileMap[`PRIMEVAL.OUTER.LEFT.RIGHT.TOP.`]
    })

    circle(centreX, centreY, radius + 2, (x, y) => {
        if (!layerData[y] || !layerData[y][x]) {
            return;
        }
        if (!isEmptyIndex(layerData[y][x])) {
            return;
        }
        layerData[y][x].index = tileMap[`PRIMEVAL.OUTER.LEFT.RIGHT.TOP.`]
    })

    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            const { left, right, top, bottom } = getNeighbours(layerData, x, y);

            const isLeftEmpty = isEmptyIndex(left);
            const isRightEmpty = isEmptyIndex(right);
            const isTopEmpty = isEmptyIndex(top);
            const isBottomEmpty = isEmptyIndex(bottom);

            if (isEmptyIndex(tile) && !isEmptyIndex(bottom) && !isEmptyIndex(right) && isEmptyIndex(left) && isEmptyIndex(top)) {
                if (x < centreX && y < centreY) {
                    layerData[y][x].index = tileMap[`PRIMEVAL.OUTER.LEFT.RIGHT.TOP.`]
                }
            }

            if (isEmptyIndex(tile) && !isEmptyIndex(bottom) && isEmptyIndex(right) && !isEmptyIndex(left) && isEmptyIndex(top)) {
                if (x > centreX && y < centreY) {
                    layerData[y][x].index = tileMap[`PRIMEVAL.OUTER.LEFT.RIGHT.TOP.`]
                }
            }

            if (isEmptyIndex(tile) && isEmptyIndex(bottom) && isEmptyIndex(right) && !isEmptyIndex(left) && !isEmptyIndex(top)) {
                if (x > centreX && y > centreY) {
                    if (left.index !== tileMap['PRIMEVAL.OUTER.RIGHT.TOP.BOTTOM.']) {
                        layerData[y][x].index = tileMap[`PRIMEVAL.OUTER.RIGHT.TOP.BOTTOM.`]
                    }
                }
            }

            if (isEmptyIndex(tile) && isEmptyIndex(bottom) && !isEmptyIndex(right) && isEmptyIndex(left) && !isEmptyIndex(top)) {
                if (x < centreX && y > centreY) {
                    layerData[y][x].index = tileMap[`PRIMEVAL.OUTER.LEFT.RIGHT.TOP.`]
                }
            }
        })
    });

    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (tile.index === tileMap[`PRIMEVAL.OUTER.RIGHT.TOP.BOTTOM.`]) {
                tile.index = tileMap[`PRIMEVAL.OUTER.LEFT.RIGHT.TOP.`]
            }

            const { left, right, top, bottom } = getNeighbours(layerData, x, y);
            if (isEmptyIndex(tile) && !isEmptyIndex(left) && !isEmptyIndex(right) && !isEmptyIndex(top) && !isEmptyIndex(bottom)) {
                tile.index = tileMap[`PRIMEVAL.LEFT.RIGHT.TOP.BOTTOM.`]
            }
        })
    });

    drawNeighbours(layerData, `PRIMEVAL.OUTER.`, tileMap[`PRIMEVAL.OUTER.LEFT.RIGHT.TOP.`], { 
        isDiagonalRelevent: true,
        connectOnBorder: true,
        startingIndexes: [
            tileMap['PRIMEVAL.INNER.LEFT.RIGHT.TOP.BOTTOM.BR-GAP'],
            tileMap['PRIMEVAL.INNER.LEFT.RIGHT.TOP.'],
            tileMap['PRIMEVAL.INNER.LEFT.RIGHT.TOP.BOTTOM.BL-GAP'],
            tileMap['PRIMEVAL.INNER.LEFT.TOP.BOTTOM.'],
            tileMap['PRIMEVAL.INNER.RIGHT.TOP.BOTTOM.'],
            tileMap['PRIMEVAL.INNER.LEFT.RIGHT.TOP.BOTTOM.TR-GAP'],
            tileMap['PRIMEVAL.INNER.LEFT.RIGHT.BOTTOM.'],
            tileMap['PRIMEVAL.INNER.LEFT.RIGHT.TOP.BOTTOM.TL-GAP'],
            tileMap['PRIMEVAL.INNER.LEFT.TOP.'],
            tileMap['PRIMEVAL.INNER.RIGHT.TOP.'],
            tileMap['PRIMEVAL.INNER.LEFT.BOTTOM.'],
            tileMap['PRIMEVAL.INNER.RIGHT.BOTTOM.'],
            tileMap['PRIMEVAL.LEFT.RIGHT.TOP.BOTTOM.'],
        ]
    })

    circle(centreX, centreY, radius + 3, (x, y) => {
        if (!layerData[y] || !layerData[y][x]) {
            return;
        }
        if (!isEmptyIndex(layerData[y][x])) {
            return;
        }
        layerData[y][x].index = tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.`]
    })

    circle(centreX, centreY, radius + 4, (x, y) => {
        if (!layerData[y] || !layerData[y][x]) {
            return;
        }
        if (!isEmptyIndex(layerData[y][x])) {
            return;
        }
        layerData[y][x].index = tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.`]
    })

    circle(centreX, centreY, radius + 5, (x, y) => {
        if (!layerData[y] || !layerData[y][x]) {
            return;
        }
        if (!isEmptyIndex(layerData[y][x])) {
            return;
        }
        layerData[y][x].index = tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.`]
    })

    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (!isEmptyIndex(tile)) {
                return;
            }

            const { left, right, top, bottom } = getNeighbours(layerData, x, y);

            let isLeftLeaf = !isEmptyIndex(left) ? 1 : 0;
            let isRightLeaf = !isEmptyIndex(right) ? 1 : 0
            let isTopLeaf = !isEmptyIndex(top) ? 1 : 0
            let isBottomLeaf = !isEmptyIndex(bottom) ? 1 : 0

            let leafNeighboursCount = isLeftLeaf + isRightLeaf + isTopLeaf + isBottomLeaf;

            if (leafNeighboursCount >= 3) {
                tile.index = tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.`]
            }
        })
    });

    drawNeighbours(layerData, `OLD-GROWTH.LEAF.`, tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.`], {
        isDiagonalRelevent: false,
        connectOnBorder: true,
        startingIndexes: [
            tileMap['PRIMEVAL.OUTER.RIGHT.BOTTOM.'],
            tileMap['PRIMEVAL.OUTER.LEFT.RIGHT.BOTTOM.'],
            tileMap['PRIMEVAL.OUTER.LEFT.BOTTOM.'],
            tileMap['PRIMEVAL.OUTER.LEFT.RIGHT.TOP.BOTTOM.TL-GAP'],
            tileMap['PRIMEVAL.OUTER.LEFT.RIGHT.TOP.BOTTOM.TR-GAP'],
            tileMap['PRIMEVAL.OUTER.RIGHT.TOP.BOTTOM.'],
            tileMap['PRIMEVAL.OUTER.LEFT.RIGHT.TOP.BOTTOM.'],
            tileMap['PRIMEVAL.OUTER.LEFT.TOP.BOTTOM.'],
            tileMap['PRIMEVAL.OUTER.LEFT.RIGHT.TOP.BOTTOM.BL-GAP'],
            tileMap['PRIMEVAL.OUTER.LEFT.RIGHT.TOP.BOTTOM.BR-GAP'],
            tileMap['PRIMEVAL.OUTER.RIGHT.TOP.'],
            tileMap['PRIMEVAL.OUTER.LEFT.RIGHT.TOP.'],
            tileMap['PRIMEVAL.OUTER.LEFT.TOP.'],
        ],
    });

    const leftRightTopBottom = tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.`];
    const bottom = tileMap[`OLD-GROWTH.LEAF.BOTTOM.`];
    const top = tileMap[`OLD-GROWTH.LEAF.TOP.`];
    const leftRightTop = tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.`]
    const leftRightBottom = tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.BOTTOM.`]

    const bottomAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.VERTICAL`],
        ],
        [bottom]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.BOTTOM.`]
        ],
        [top]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.`]
        ],
        [leftRightTop]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.TOP.`],
        ],
        [leftRightBottom]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.BOTTOM.`],
        ],
    }

    const leftAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`OLD-GROWTH.LEAF.RIGHT.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
        ],
        [bottom]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.BOTTOM.`]
        ],
        [top]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.`]
        ],
    }

    const rightAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
        ],
        [bottom]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.BOTTOM.`]
        ],
        [top]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.`]
        ],
    }

    const topAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.VERTICAL`]
        ],
        [bottom]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.BOTTOM.`]
        ],
        [top]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.`]
        ],
        [leftRightTop]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.TOP.`],
        ],
        [leftRightBottom]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.BOTTOM.`],
        ],
    }

    let topTiles = [];
    let bottomTiles = [];
    let leftTiles = [];
    let rightTiles = [];

    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (y < centreY - 3) {
                topTiles.push({ x, y })
            }

            if (y > centreY + 3) {
                bottomTiles.push({ x, y })
            }

            if (x < centreX - 3) {
                leftTiles.push({ x, y })
            }

            if (x > centreX + 3) {
                rightTiles.push({ x, y })
            }
        })
    })

    bottomTiles.forEach(({ x, y }) => {
        const newTile = layerData[y] ? layerData[y][x] : undefined;

        if (!newTile) {
            return;
        }

        const newTileIndex = newTile.index;
        if (newTile && newTileIndex && bottomAlternativeTiles[newTileIndex]) {
            if (rng() < 0.5) {
                return;
            }
            
            const items = bottomAlternativeTiles[newTileIndex]
            newTile.index = items[Math.floor(rng()*items.length)];
        }
    })

    topTiles.forEach(({ x, y }) => {
        const newTile = layerData[y] ? layerData[y][x] : undefined;

        if (!newTile) {
            return;
        }

        const newTileIndex = newTile.index;
        if (newTile && newTileIndex && topAlternativeTiles[newTileIndex]) {
            if (rng() < 0.5) {
                return;
            }
            
            const items = topAlternativeTiles[newTileIndex]
            newTile.index = items[Math.floor(rng()*items.length)];
        }
    })

    leftTiles.forEach(({ x, y }) => {
        const newTile = layerData[y] ? layerData[y][x] : undefined;

        if (!newTile) {
            return;
        }

        const newTileIndex = newTile.index;
        if (newTile && newTileIndex && leftAlternativeTiles[newTileIndex]) {
            if (rng() < 0.5) {
                return;
            }
            
            const items = leftAlternativeTiles[newTileIndex]
            newTile.index = items[Math.floor(rng()*items.length)];
        }
    })

    rightTiles.forEach(({ x, y }) => {
        const newTile = layerData[y] ? layerData[y][x] : undefined;

        if (!newTile) {
            return;
        }

        const newTileIndex = newTile.index;
        if (newTile && newTileIndex && rightAlternativeTiles[newTileIndex]) {
            if (rng() < 0.5) {
                return;
            }
            
            const items = rightAlternativeTiles[newTileIndex]
            newTile.index = items[Math.floor(rng()*items.length)];
        }
    })
}

export function drawPrimeval(mockLayerData, layerData, panel, rng) {
    let neighbours = getMatchingBiomeNeighbours(panel);

    // neighbours = []

    //Jungle plants
    const JUNGLE_PLANT_1 = 'FERN'
    const JUNGLE_PLANT_2 = 'LIVER-WORT'

    const plantIndexes = [
        tileMap[JUNGLE_PLANT_1],
        tileMap[JUNGLE_PLANT_2],
    ]

    const centreX = 12;
    const centreY = 2;

    const MAX_RADIUS = 8;
    const MIN_RADIUS = 2;

    let treeCount = 0;
    let attemptsCount = 0;

    let treePoints = [];

    function isDifferentBiomeEdge(y, x) {
        if (x === 0 && neighbours.indexOf('west') === -1) {
            return true;
        }

        if (x === PANEL_WIDTH - 1 && neighbours.indexOf('east') === -1) {
            return true;
        }

        if (y === 0 && neighbours.indexOf('north') === -1) {
            return true;
        }

        if (y === PANEL_HEIGHT - 1 && neighbours.indexOf('south') === -1) {
            return true;
        }
    }

    while(treeCount < 4 && attemptsCount < 100) {
        const newPointY = Math.floor(rng() * (PANEL_HEIGHT - 0) + 0);
        const newPointX = Math.floor(rng() * (PANEL_WIDTH - 0) + 0);

        if (isEmptyIndex(layerData[newPointY][newPointX])) {
            // now to find radius?
            //we need a min radius of at least like 7 i reckon...
            let closestTile = undefined;
            let closestDistance = 1000;

            layerData.forEach((row, columnIndex) => {
                row.forEach((tile, rowIndex) => {
                    if (!isEmptyIndex(tile) || panel.floorLayer.find(tile => (tile.x === rowIndex && tile.y === columnIndex)) || isDifferentBiomeEdge(columnIndex, rowIndex)) {
                        const a = rowIndex - newPointX;
                        const b = columnIndex - newPointY;

                        let distance = Math.hypot(a, b);

                        if (!isEmptyIndex(tile)) {
                            const treePoint = treePoints.find(point => (point.x === rowIndex && point.y === columnIndex))
                            distance -= treePoint.radius + 6
                        }

                        if (distance < closestDistance) {
                            closestTile = tile;
                            closestDistance = distance
                        }
                    }
                })
            });

            if (closestDistance >= 8) {
                layerData[newPointY][newPointX].index = tileMap['LAVA.LEFT.BOTTOM.']

                treePoints.push({
                    x: newPointX,
                    y: newPointY,
                    closestDistance,
                    radius: Math.max(Math.min(Math.floor((closestDistance) - 6), MAX_RADIUS), MIN_RADIUS)
                })

                treeCount++;
            }
        }
        attemptsCount++;
    }

    treePoints.forEach(treePoint => {
        const _mockLayerData = [];

        for (var y=0; y < PANEL_HEIGHT * 3; y++) {
            _mockLayerData[y] = []
            for (var x=0; x < PANEL_WIDTH * 3; x++) {
                _mockLayerData[y][x] = {}
            }
        }
        drawCircleTree(_mockLayerData, panel, rng, treePoint.x + PANEL_WIDTH, treePoint.y + PANEL_HEIGHT, treePoint.radius)

        layerData.forEach((row, columnIndex) => {
            row.forEach((tile, rowIndex) => {
                if (tile.index === tileMap['LAVA.LEFT.BOTTOM.']) {
                    tile.index = -1;
                }

                if (_mockLayerData[columnIndex + PANEL_HEIGHT][rowIndex + PANEL_WIDTH] && !isEmptyIndex(_mockLayerData[columnIndex + PANEL_HEIGHT][rowIndex + PANEL_WIDTH])) {
                    layerData[columnIndex][rowIndex].index = _mockLayerData[columnIndex + PANEL_HEIGHT][rowIndex + PANEL_WIDTH].index
                }
            })
        });

        mockLayerData.forEach((row, columnIndex) => {
            row.forEach((tile, rowIndex) => {
                if (tile.index === tileMap['LAVA.LEFT.BOTTOM.']) {
                    tile.index = -1;
                }

                if (_mockLayerData[columnIndex][rowIndex] && !isEmptyIndex(_mockLayerData[columnIndex][rowIndex])) {
                    mockLayerData[columnIndex][rowIndex].index = _mockLayerData[columnIndex][rowIndex].index
                }
            })
        });
    })

    treePoints.forEach(treePoint => {
        const seed = [treePoint.x, treePoint.y];
        const result = floodFill.default({
            getter: (x, y) => {
                return layerData[y][x].index
            },
            seed,
            diagonals: false,
            equals: (a, b) => {
                return a === b || plantIndexes.indexOf(a) !== -1 || plantIndexes.indexOf(b) !== -1
            }
        });

        result.flooded.forEach(([x, y]) => {
            layerData[y][x].index = -1
        })
    })

    let noiseOne = drawNoise(1, 2, panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (!isEmptyIndex(tile)) {
                return;
            }
            if (noiseOne[rowIndex][columnIndex] > 0.6) {
                tile.index = tileMap[JUNGLE_PLANT_1];
            } else if (noiseOne[rowIndex][columnIndex] > 0.45) {
                tile.index = tileMap[JUNGLE_PLANT_2];
            }
        })
    });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    return mockLayerData;
}

export function drawOldGrowth(mockLayerData, layerData, panel, rng) {
    const panelTrunkCoords = [];
    const GROUND_KEY = 'EMPTY'

    const keys = {
        TRUNK_KEY: `OLD-GROWTH.ONE.A1`,
        GROUND_KEY: 'EMPTY',
        LEAF_KEY: `OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.`,
    }

    const secondRingChance = 11 / 12;
    const halfRingChance = 1/2;
    const thirdRingChance = 3 / 26;

    const stampShape1 = [
        // { x: 0, y: 0 },
        { x: 1, y: 0, index: tileMap[`OLD-GROWTH.ONE.A2`] },
        { x: 2, y: 0, index: tileMap[`OLD-GROWTH.ONE.A3`] },
        { x: 0, y: 1, index: tileMap[`OLD-GROWTH.ONE.B1`] },
        { x: 1, y: 1, index: tileMap[`OLD-GROWTH.ONE.B2`] },
        { x: 2, y: 1, index: tileMap[`OLD-GROWTH.ONE.B3`] },
        { x: 0, y: 2, index: tileMap[`OLD-GROWTH.ONE.C1`] },
        { x: 1, y: 2, index: tileMap[`OLD-GROWTH.ONE.C2`] },

        // 
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 2, index: tileMap[keys.LEAF_KEY] },

        // // 
        { x: -2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: -2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        // 
        { x: -3, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -3, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },

        // 
        { x: -4, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
    ]

    const stampShape2 = [
        { x: 0, y: 0, index: tileMap[`OLD-GROWTH.TWO.A1`] },
        { x: 1, y: 0, index: tileMap[`OLD-GROWTH.TWO.A2`] },
        { x: 2, y: 0, index: tileMap[`OLD-GROWTH.TWO.A3`] },
        { x: 3, y: 0, index: tileMap[`OLD-GROWTH.TWO.A4`] },
        { x: 0, y: 1, index: tileMap[`OLD-GROWTH.TWO.B1`] },
        { x: 1, y: 1, index: tileMap[`OLD-GROWTH.TWO.B2`] },
        { x: 2, y: 1, index: tileMap[`OLD-GROWTH.TWO.B3`] },
        { x: 0, y: 2, index: tileMap[`OLD-GROWTH.TWO.C1`] },
        { x: 1, y: 2, index: tileMap[`OLD-GROWTH.TWO.C2`] },
        { x: 2, y: 2, index: tileMap[`OLD-GROWTH.TWO.C3`] },

        // 
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY] },

        // // 
        { x: -2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: -2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        // 
        { x: -3, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -3, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },

        // 
        { x: -4, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
    ]

    const stampShape3 = [
        { x: 0, y: 0, index: tileMap[`OLD-GROWTH.THREE.A1`] },
        { x: 1, y: 0, index: tileMap[`OLD-GROWTH.THREE.A2`] },
        { x: 2, y: 0, index: tileMap[`OLD-GROWTH.THREE.A3`] },
        { x: -1, y: 1, index: tileMap[`OLD-GROWTH.THREE.B1`] },
        { x: 0, y: 1, index: tileMap[`OLD-GROWTH.THREE.B2`] },
        { x: 1, y: 1, index: tileMap[`OLD-GROWTH.THREE.B3`] },
        { x: 2, y: 1, index: tileMap[`OLD-GROWTH.THREE.B4`] },
        { x: 3, y: 1, index: tileMap[`OLD-GROWTH.THREE.B5`] },
        { x: -1, y: 2, index: tileMap[`OLD-GROWTH.THREE.C1`] },
        { x: 0, y: 2, index: tileMap[`OLD-GROWTH.THREE.C2`] },
        { x: 2, y: 2, index: tileMap[`OLD-GROWTH.THREE.C3`] },
        { x: 3, y: 2, index: tileMap[`OLD-GROWTH.THREE.C4`] },
        { x: -1, y: 3, index: tileMap[`OLD-GROWTH.THREE.D1`] },
        { x: 0, y: 3, index: tileMap[`OLD-GROWTH.THREE.D2`] },
        { x: 1, y: 3, index: tileMap[`OLD-GROWTH.THREE.D3`] },
        { x: 2, y: 3, index: tileMap[`OLD-GROWTH.THREE.D4`] },
        { x: 3, y: 3, index: tileMap[`OLD-GROWTH.THREE.D5`] },
        { x: 0, y: 4, index: tileMap[`OLD-GROWTH.THREE.E1`] },
        { x: 1, y: 4, index: tileMap[`OLD-GROWTH.THREE.E2`] },
        { x: 2, y: 4, index: tileMap[`OLD-GROWTH.THREE.E3`] },

        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: -2, index: tileMap[keys.LEAF_KEY] },
        { x: -3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 4, index: tileMap[keys.LEAF_KEY] },

        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: -2, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -3, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -3, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: -3, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 4, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 6, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 6, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 6, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 5, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 5, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 4, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 5, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 0, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 2, y: -3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 2, y: -3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -4, y: 0, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -4, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -4, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -4, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -2, y: 5, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 1, y: -3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -5, y: 0, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 6, y: 0, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 5, y: 4, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -1, y: 7, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 0, y: 7, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 1, y: 7, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 2, y: 7, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 3, y: 7, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 6, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 6, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 6, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 6, y: 4, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
    ]

    const stampShape4 = [
        { x: 0, y: 0, index: tileMap[`OLD-GROWTH.FOUR.A1`] },
        { x: 1, y: 0, index: tileMap[`OLD-GROWTH.FOUR.A2`] },
        { x: 2, y: 0, index: tileMap[`OLD-GROWTH.FOUR.A3`] },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 1, index: tileMap[`OLD-GROWTH.FOUR.B1`] },
        { x: 2, y: 1, index: tileMap[`OLD-GROWTH.FOUR.B2`] },
        { x: 0, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 2, index: tileMap[keys.LEAF_KEY] },

        // 
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY] },

        // // 
        { x: -2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: -2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        // 
        { x: -3, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },

        // 
        { x: -2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
    ]

    const stampShape5 = [
        { x: 0, y: 0, index: tileMap[`OLD-GROWTH.FIVE.A1`] },
        { x: 1, y: 0, index: tileMap[`OLD-GROWTH.FIVE.A2`] },
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 1, index: tileMap[`OLD-GROWTH.FIVE.B1`] },
        { x: 1, y: 1, index: tileMap[`OLD-GROWTH.FIVE.B2`] },
        { x: 2, y: 1, index: tileMap[`OLD-GROWTH.FIVE.B3`] },
        { x: -1, y: 2, index: tileMap[`OLD-GROWTH.FIVE.C1`] },
        { x: 0, y: 2, index: tileMap[`OLD-GROWTH.FIVE.C2`] },
        { x: 1, y: 2, index: tileMap[`OLD-GROWTH.FIVE.C3`] },

        // 
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY] },

        // // 
        { x: -2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: -2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        // 
        { x: -3, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -3, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },

        // 
        { x: -4, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
    ]

    const stampShape6 = [
        { x: 0, y: 0, index: tileMap[`OLD-GROWTH.SIX.A1`] },
        { x: 1, y: 0, index: tileMap[`OLD-GROWTH.SIX.A2`] },
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 1, index: tileMap[`OLD-GROWTH.SIX.B1`] },
        { x: 0, y: 1, index: tileMap[`OLD-GROWTH.SIX.B2`] },
        { x: 1, y: 1, index: tileMap[`OLD-GROWTH.SIX.B3`] },
        { x: 0, y: 2, index: tileMap[`OLD-GROWTH.SIX.C1`] },
        { x: 1, y: 2, index: tileMap[`OLD-GROWTH.SIX.C2`] },
        { x: 2, y: 2, index: tileMap[`OLD-GROWTH.SIX.C3`] },

        // 
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY] },

        // // 
        { x: -2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: -2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        // 
        { x: -3, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -3, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },

        // 
        { x: -4, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
    ]

    const stampShape7 = [
        { x: 0, y: 0, index: tileMap[`OLD-GROWTH.SEVEN.A1`] },
        { x: 1, y: 0, index: tileMap[`OLD-GROWTH.SEVEN.A2`] },
        { x: 2, y: 0, index: tileMap[`OLD-GROWTH.SEVEN.A3`] },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 1, index: tileMap[`OLD-GROWTH.SEVEN.B1`] },
        { x: 1, y: 1, index: tileMap[`OLD-GROWTH.SEVEN.B2`] },
        { x: 2, y: 1, index: tileMap[`OLD-GROWTH.SEVEN.B3`] },
        { x: 0, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 2, index: tileMap[`OLD-GROWTH.SEVEN.C1`] },
        { x: 2, y: 2, index: tileMap[`OLD-GROWTH.SEVEN.C2`] },

        // 
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY] },

        // // 
        { x: -2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: -2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        // 
        { x: -3, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -3, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },

        // 
        { x: -4, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
    ]

    const stampShape8 = [
        { x: 0, y: 0, index: tileMap[`OLD-GROWTH.EIGHT.A1`] },
        { x: 1, y: 0, index: tileMap[`OLD-GROWTH.EIGHT.A2`] },
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 1, index: tileMap[`OLD-GROWTH.EIGHT.B1`] },
        { x: 0, y: 1, index: tileMap[`OLD-GROWTH.EIGHT.B2`] },
        { x: 1, y: 1, index: tileMap[`OLD-GROWTH.EIGHT.B3`] },
        { x: 0, y: 2, index: tileMap[`OLD-GROWTH.EIGHT.C1`] },
        { x: 1, y: 2, index: tileMap[`OLD-GROWTH.EIGHT.C2`] },
        { x: 2, y: 2, index: tileMap[keys.LEAF_KEY] },

        // 
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY] },

        // // 
        { x: -2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: -2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        // 
        { x: -3, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -3, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },

        // 
        { x: -4, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
    ]

    const stampShape9 = [
        { x: 0, y: 0, index: tileMap[`OLD-GROWTH.NINE.A1`] },
        { x: 1, y: 0, index: tileMap[`OLD-GROWTH.NINE.A2`] },
        { x: 2, y: 0, index: tileMap[`OLD-GROWTH.NINE.A3`] },
        { x: 3, y: 0, index: tileMap[`OLD-GROWTH.NINE.A4`] },
        { x: 1, y: 1, index: tileMap[`OLD-GROWTH.NINE.B1`] },
        { x: 2, y: 1, index: tileMap[`OLD-GROWTH.NINE.B2`] },
        { x: 3, y: 1, index: tileMap[`OLD-GROWTH.NINE.B3`] },
        { x: 1, y: 2, index: tileMap[`OLD-GROWTH.NINE.C1`] },
        { x: 2, y: 2, index: tileMap[`OLD-GROWTH.NINE.C2`] },
        { x: 3, y: 2, index: tileMap[`OLD-GROWTH.NINE.C3`] },

        // 
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.LEAF_KEY] },

        // // 
        { x: -2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: -2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        // 
        { x: 4, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },

        // 
        { x: 4, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 4, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
    ]

    const stampShape10 = [
        { x: 0, y: 0, index: tileMap[`OLD-GROWTH.TEN.A1`] },
        { x: 1, y: 0, index: tileMap[`OLD-GROWTH.TEN.A2`] },
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 1, index: tileMap[`OLD-GROWTH.TEN.B1`] },
        { x: 1, y: 1, index: tileMap[`OLD-GROWTH.TEN.B2`] },
        { x: 2, y: 1, index: tileMap[`OLD-GROWTH.TEN.B3`] },
        { x: 0, y: 2, index: tileMap[`OLD-GROWTH.TEN.C1`] },
        { x: 1, y: 2, index: tileMap[`OLD-GROWTH.TEN.C2`] },
        { x: 2, y: 2, index: tileMap[keys.LEAF_KEY] },

        // 
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY] },

        // // 
        { x: -2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: -2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        // 
        { x: -3, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -3, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },

        // 
        { x: -4, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
    ]

    const stampShape11 = [
        { x: 0, y: 0, index: tileMap[`OLD-GROWTH.ELEVEN.A1`] },
        { x: 1, y: 0, index: tileMap[`OLD-GROWTH.ELEVEN.A2`] },
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 1, index: tileMap[`OLD-GROWTH.ELEVEN.B1`] },
        { x: 1, y: 1, index: tileMap[`OLD-GROWTH.ELEVEN.B2`] },
        { x: 2, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 2, index: tileMap[keys.LEAF_KEY] },

        // 
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY] },

        // // 
        { x: -2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: -2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        // 
        { x: -3, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -3, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },

        // 
        { x: -4, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
    ]

    const stampShape12 = [
        { x: 0, y: 0, index: tileMap[`OLD-GROWTH.TWELVE.A1`] },
        { x: 1, y: 0, index: tileMap[`OLD-GROWTH.TWELVE.A2`] },
        { x: 2, y: 0, index: tileMap[`OLD-GROWTH.TWELVE.A3`] },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 1, index: tileMap[`OLD-GROWTH.TWELVE.B1`] },
        { x: 1, y: 1, index: tileMap[`OLD-GROWTH.TWELVE.B2`] },
        { x: 2, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 2, index: tileMap[keys.LEAF_KEY] },

        // 
        { x: -1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: -1, index: tileMap[keys.LEAF_KEY] },

        // // 
        { x: -2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -2, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: -2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 2, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: -1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY], probability: secondRingChance },

        // 
        { x: -3, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: -3, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: -3, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: -1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },
        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY], probability: halfRingChance },

        // 
        { x: -4, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -4, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: -3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: -1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 1, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 2, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: -1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 1, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
        { x: 5, y: 3, index: tileMap[keys.LEAF_KEY], probability: thirdRingChance },
    ]

    const neighbours = getMatchingBiomeNeighbours(panel);

    const trunkCoords = drawTreeDots(mockLayerData, layerData, panel, [
        stampShape1,
        stampShape2,
        stampShape3,
        stampShape4,
        stampShape5,
        stampShape6,
        stampShape7,
        stampShape8,
        stampShape9,
        stampShape10,
        stampShape11,
        stampShape12
    ], 0.8, false, neighbours, keys, rng);

    drawNeighbours(layerData, `OLD-GROWTH.LEAF.`, tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.`], {
        isDiagonalRelevent: false,
        connectOnBorder: true,
        startingIndexes: [
            tileMap[`OLD-GROWTH.ONE.A1`],
            tileMap[`OLD-GROWTH.ONE.A2`],
            tileMap[`OLD-GROWTH.ONE.A3`],
            tileMap[`OLD-GROWTH.ONE.B1`],
            tileMap[`OLD-GROWTH.ONE.B2`],
            tileMap[`OLD-GROWTH.ONE.B3`],
            tileMap[`OLD-GROWTH.ONE.C1`],
            tileMap[`OLD-GROWTH.ONE.C2`],

            tileMap[`OLD-GROWTH.TWO.A1`],
            tileMap[`OLD-GROWTH.TWO.A2`],
            tileMap[`OLD-GROWTH.TWO.A3`],
            tileMap[`OLD-GROWTH.TWO.A4`],
            tileMap[`OLD-GROWTH.TWO.B1`],
            tileMap[`OLD-GROWTH.TWO.B2`],
            tileMap[`OLD-GROWTH.TWO.B3`],
            tileMap[`OLD-GROWTH.TWO.C1`],
            tileMap[`OLD-GROWTH.TWO.C2`],
            tileMap[`OLD-GROWTH.TWO.C3`],

            tileMap[`OLD-GROWTH.THREE.A1`],
            tileMap[`OLD-GROWTH.THREE.A2`],
            tileMap[`OLD-GROWTH.THREE.A3`],
            tileMap[`OLD-GROWTH.THREE.B1`],
            tileMap[`OLD-GROWTH.THREE.B2`],
            tileMap[`OLD-GROWTH.THREE.B3`],
            tileMap[`OLD-GROWTH.THREE.B4`],
            tileMap[`OLD-GROWTH.THREE.B5`],
            tileMap[`OLD-GROWTH.THREE.C1`],
            tileMap[`OLD-GROWTH.THREE.C2`],
            tileMap[`OLD-GROWTH.THREE.C3`],
            tileMap[`OLD-GROWTH.THREE.C4`],

            tileMap[`OLD-GROWTH.THREE.A1`],
            tileMap[`OLD-GROWTH.THREE.A2`],
            tileMap[`OLD-GROWTH.THREE.A3`],
            tileMap[`OLD-GROWTH.THREE.B1`],
            tileMap[`OLD-GROWTH.THREE.B2`],
            tileMap[`OLD-GROWTH.THREE.B3`],
            tileMap[`OLD-GROWTH.THREE.B4`],
            tileMap[`OLD-GROWTH.THREE.B5`],
            tileMap[`OLD-GROWTH.THREE.C1`],
            tileMap[`OLD-GROWTH.THREE.C2`],
            tileMap[`OLD-GROWTH.THREE.C3`],
            tileMap[`OLD-GROWTH.THREE.C4`],
            tileMap[`OLD-GROWTH.THREE.D1`],
            tileMap[`OLD-GROWTH.THREE.D2`],
            tileMap[`OLD-GROWTH.THREE.D3`],
            tileMap[`OLD-GROWTH.THREE.D4`],
            tileMap[`OLD-GROWTH.THREE.D5`],
            tileMap[`OLD-GROWTH.THREE.E1`],
            tileMap[`OLD-GROWTH.THREE.E2`],
            tileMap[`OLD-GROWTH.THREE.E3`],

            tileMap[`OLD-GROWTH.FOUR.A1`],
            tileMap[`OLD-GROWTH.FOUR.A2`],
            tileMap[`OLD-GROWTH.FOUR.A3`],
            tileMap[`OLD-GROWTH.FOUR.B1`],
            tileMap[`OLD-GROWTH.FOUR.B2`],

            tileMap[`OLD-GROWTH.FIVE.A1`],
            tileMap[`OLD-GROWTH.FIVE.A2`],
            tileMap[`OLD-GROWTH.FIVE.B1`],
            tileMap[`OLD-GROWTH.FIVE.B2`],
            tileMap[`OLD-GROWTH.FIVE.B3`],
            tileMap[`OLD-GROWTH.FIVE.C1`],
            tileMap[`OLD-GROWTH.FIVE.C2`],
            tileMap[`OLD-GROWTH.FIVE.C3`],

            tileMap[`OLD-GROWTH.SIX.A1`],
            tileMap[`OLD-GROWTH.SIX.A2`],
            tileMap[`OLD-GROWTH.SIX.B1`],
            tileMap[`OLD-GROWTH.SIX.B2`],
            tileMap[`OLD-GROWTH.SIX.B3`],
            tileMap[`OLD-GROWTH.SIX.C1`],
            tileMap[`OLD-GROWTH.SIX.C2`],
            tileMap[`OLD-GROWTH.SIX.C3`],

            tileMap[`OLD-GROWTH.SEVEN.A1`],
            tileMap[`OLD-GROWTH.SEVEN.A2`],
            tileMap[`OLD-GROWTH.SEVEN.A3`],
            tileMap[`OLD-GROWTH.SEVEN.B1`],
            tileMap[`OLD-GROWTH.SEVEN.B2`],
            tileMap[`OLD-GROWTH.SEVEN.B3`],
            tileMap[`OLD-GROWTH.SEVEN.C1`],
            tileMap[`OLD-GROWTH.SEVEN.C2`],

            tileMap[`OLD-GROWTH.EIGHT.A1`],
            tileMap[`OLD-GROWTH.EIGHT.A2`],
            tileMap[`OLD-GROWTH.EIGHT.B1`],
            tileMap[`OLD-GROWTH.EIGHT.B2`],
            tileMap[`OLD-GROWTH.EIGHT.B3`],
            tileMap[`OLD-GROWTH.EIGHT.C1`],
            tileMap[`OLD-GROWTH.EIGHT.C2`],

            tileMap[`OLD-GROWTH.NINE.A1`],
            tileMap[`OLD-GROWTH.NINE.A2`],
            tileMap[`OLD-GROWTH.NINE.A3`],
            tileMap[`OLD-GROWTH.NINE.A4`],
            tileMap[`OLD-GROWTH.NINE.B1`],
            tileMap[`OLD-GROWTH.NINE.B2`],
            tileMap[`OLD-GROWTH.NINE.B3`],
            tileMap[`OLD-GROWTH.NINE.C1`],
            tileMap[`OLD-GROWTH.NINE.C2`],
            tileMap[`OLD-GROWTH.NINE.C3`],

            tileMap[`OLD-GROWTH.TEN.A1`],
            tileMap[`OLD-GROWTH.TEN.A2`],
            tileMap[`OLD-GROWTH.TEN.B1`],
            tileMap[`OLD-GROWTH.TEN.B2`],
            tileMap[`OLD-GROWTH.TEN.B3`],
            tileMap[`OLD-GROWTH.TEN.C1`],
            tileMap[`OLD-GROWTH.TEN.C2`],

            tileMap[`OLD-GROWTH.ELEVEN.A1`],
            tileMap[`OLD-GROWTH.ELEVEN.A2`],
            tileMap[`OLD-GROWTH.ELEVEN.B1`],
            tileMap[`OLD-GROWTH.ELEVEN.B2`],

            tileMap[`OLD-GROWTH.TWELVE.A1`],
            tileMap[`OLD-GROWTH.TWELVE.A2`],
            tileMap[`OLD-GROWTH.TWELVE.A3`],
            tileMap[`OLD-GROWTH.TWELVE.B1`],
            tileMap[`OLD-GROWTH.TWELVE.B2`],
        ],
    });

    const leftRightTopBottom = tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.`];
    const bottom = tileMap[`OLD-GROWTH.LEAF.BOTTOM.`];
    const top = tileMap[`OLD-GROWTH.LEAF.TOP.`];
    const leftRightTop = tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.`]
    const leftRightBottom = tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.BOTTOM.`]

    const bottomAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.VERTICAL`],
        ],
        [bottom]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.BOTTOM.`]
        ],
        [top]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.`]
        ],
        [leftRightTop]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.TOP.`],
        ],
        [leftRightBottom]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.BOTTOM.`],
        ],
    }

    const leftAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`OLD-GROWTH.LEAF.RIGHT.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
        ],
        [bottom]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.BOTTOM.`]
        ],
        [top]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.`]
        ],
    }

    const rightAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
        ],
        [bottom]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.BOTTOM.`]
        ],
        [top]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.`]
        ],
    }

    const topAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.VERTICAL`]
        ],
        [bottom]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.BOTTOM.`]
        ],
        [top]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.`]
        ],
        [leftRightTop]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.TOP.`],
        ],
        [leftRightBottom]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.BOTTOM.`],
        ],
    }

    layerData.forEach((row, _y) => {
        row.forEach((tile, _x) => {
            if (tile.index === tileMap[`OLD-GROWTH.ONE.A1`]) {
                panelTrunkCoords.push({ x: _x, y: _y })
                const leftTiles = [
                    { x: -1, y: 0, },
                    { x: -2, y: 0, },
                    { x: -3, y: 0, },
                    { x: -1, y: 1, },
                    { x: -2, y: 1, },
                    { x: -3, y: 1, },
                ]

                const bottomTiles = [
                    { x: -3, y: 2, },
                    { x: -3, y: 3, },
                    { x: -2, y: 2, },
                    { x: -2, y: 3, },
                    { x: -1, y: 2, },
                    { x: -1, y: 3, },
                    { x: 0, y: 2, },
                    { x: 0, y: 3, },
                    { x: 1, y: 2, },
                    { x: 1, y: 3, },
                    { x: 2, y: 2, },
                    { x: 2, y: 3, },
                    { x: 3, y: 2, },
                    { x: 3, y: 3, },
                ]

                const rightTiles = [
                    { x: 2, y: 1, },
                    { x: 3, y: 1, },
                    { x: 4, y: 1, },
                    { x: 2, y: 0, },
                    { x: 3, y: 0, },
                    { x: 4, y: 0, },
                ]

                const topTiles = [
                    { x: 2, y: -1, },
                    { x: 3, y: -1, },
                    { x: 4, y: -1, },
                    { x: 2, y: -2, },
                    { x: 3, y: -2, },
                    { x: 4, y: -2, },
                    { x: 1, y: -1, },
                    { x: 1, y: -2, },
                    { x: 0, y: -1, },
                    { x: 0, y: -2, },
                    { x: -1, y: -1, },
                    { x: -1, y: -2, },
                    { x: -2, y: -1, },
                    { x: -2, y: -2, },
                ]

                bottomTiles.forEach(({ x, y }) => {
                    const newTile = layerData[_y + y] ? layerData[_y + y][_x + x] : undefined;

                    if (!newTile) {
                        return;
                    }

                    const newTileIndex = newTile.index;
                    if (newTile && newTileIndex && bottomAlternativeTiles[newTileIndex]) {
                        if (rng() < 0.5) {
                            return;
                        }
                        
                        const items = bottomAlternativeTiles[newTileIndex]
                        newTile.index = items[Math.floor(rng()*items.length)];
                    }
                })

                topTiles.forEach(({ x, y }) => {
                    const newTile = layerData[_y + y] ? layerData[_y + y][_x + x] : undefined;

                    if (!newTile) {
                        return;
                    }

                    const newTileIndex = newTile.index;
                    if (newTile && newTileIndex && topAlternativeTiles[newTileIndex]) {
                        if (rng() < 0.5) {
                            return;
                        }
                        
                        const items = topAlternativeTiles[newTileIndex]
                        newTile.index = items[Math.floor(rng()*items.length)];
                    }
                })

                leftTiles.forEach(({ x, y }) => {
                    const newTile = layerData[_y + y] ? layerData[_y + y][_x + x] : undefined;

                    if (!newTile) {
                        return;
                    }

                    const newTileIndex = newTile.index;
                    if (newTile && newTileIndex && leftAlternativeTiles[newTileIndex]) {
                        if (rng() < 0.5) {
                            return;
                        }
                        
                        const items = leftAlternativeTiles[newTileIndex]
                        newTile.index = items[Math.floor(rng()*items.length)];
                    }
                })

                rightTiles.forEach(({ x, y }) => {
                    const newTile = layerData[_y + y] ? layerData[_y + y][_x + x] : undefined;

                    if (!newTile) {
                        return;
                    }

                    const newTileIndex = newTile.index;
                    if (newTile && newTileIndex && rightAlternativeTiles[newTileIndex]) {
                        if (rng() < 0.5) {
                            return;
                        }
                        
                        const items = rightAlternativeTiles[newTileIndex]
                        newTile.index = items[Math.floor(rng()*items.length)];
                    }
                })
            }
        })
    })

    return mockLayerData;
}

function alternateOldGrowthLeaves(layerData) {
    const leftRightTopBottom = tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.`];
    const bottom = tileMap[`OLD-GROWTH.LEAF.BOTTOM.`];
    const top = tileMap[`OLD-GROWTH.LEAF.TOP.`];
    const leftRightTop = tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.`]
    const leftRightBottom = tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.BOTTOM.`]

    const bottomAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.VERTICAL`],
        ],
        [bottom]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.BOTTOM.`]
        ],
        [top]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.`]
        ],
        [leftRightTop]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.TOP.`],
        ],
        [leftRightBottom]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.BOTTOM.`],
        ],
    }

    const leftAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`OLD-GROWTH.LEAF.RIGHT.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
        ],
        [bottom]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.BOTTOM.`]
        ],
        [top]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.`]
        ],
    }

    const rightAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
        ],
        [bottom]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.BOTTOM.`]
        ],
        [top]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.`]
        ],
    }

    const topAlternativeTiles = {
        [leftRightTopBottom]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.VERTICAL`]
        ],
        [bottom]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.BOTTOM.`]
        ],
        [top]: [
            tileMap[`OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.`]
        ],
        [leftRightTop]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.TOP.`],
        ],
        [leftRightBottom]: [
            tileMap[`OLD-GROWTH.LEAF.TOP.BOTTOM.`],
            tileMap[`OLD-GROWTH.LEAF.BOTTOM.`],
        ],
    }

    layerData.forEach((row, _y) => {
        row.forEach((tile, _x) => {
            if (tile.index === tileMap[`OLD-GROWTH.ONE.A1`]) {
                panelTrunkCoords.push({ x: _x, y: _y })
                const leftTiles = [
                    { x: -1, y: 0, },
                    { x: -2, y: 0, },
                    { x: -3, y: 0, },
                    { x: -1, y: 1, },
                    { x: -2, y: 1, },
                    { x: -3, y: 1, },
                ]

                const bottomTiles = [
                    { x: -3, y: 2, },
                    { x: -3, y: 3, },
                    { x: -2, y: 2, },
                    { x: -2, y: 3, },
                    { x: -1, y: 2, },
                    { x: -1, y: 3, },
                    { x: 0, y: 2, },
                    { x: 0, y: 3, },
                    { x: 1, y: 2, },
                    { x: 1, y: 3, },
                    { x: 2, y: 2, },
                    { x: 2, y: 3, },
                    { x: 3, y: 2, },
                    { x: 3, y: 3, },
                ]

                const rightTiles = [
                    { x: 2, y: 1, },
                    { x: 3, y: 1, },
                    { x: 4, y: 1, },
                    { x: 2, y: 0, },
                    { x: 3, y: 0, },
                    { x: 4, y: 0, },
                ]

                const topTiles = [
                    { x: 2, y: -1, },
                    { x: 3, y: -1, },
                    { x: 4, y: -1, },
                    { x: 2, y: -2, },
                    { x: 3, y: -2, },
                    { x: 4, y: -2, },
                    { x: 1, y: -1, },
                    { x: 1, y: -2, },
                    { x: 0, y: -1, },
                    { x: 0, y: -2, },
                    { x: -1, y: -1, },
                    { x: -1, y: -2, },
                    { x: -2, y: -1, },
                    { x: -2, y: -2, },
                ]

                bottomTiles.forEach(({ x, y }) => {
                    const newTile = layerData[_y + y] ? layerData[_y + y][_x + x] : undefined;

                    if (!newTile) {
                        return;
                    }

                    const newTileIndex = newTile.index;
                    if (newTile && newTileIndex && bottomAlternativeTiles[newTileIndex]) {
                        if (rng() < 0.5) {
                            return;
                        }
                        
                        const items = bottomAlternativeTiles[newTileIndex]
                        newTile.index = items[Math.floor(rng()*items.length)];
                    }
                })

                topTiles.forEach(({ x, y }) => {
                    const newTile = layerData[_y + y] ? layerData[_y + y][_x + x] : undefined;

                    if (!newTile) {
                        return;
                    }

                    const newTileIndex = newTile.index;
                    if (newTile && newTileIndex && topAlternativeTiles[newTileIndex]) {
                        if (rng() < 0.5) {
                            return;
                        }
                        
                        const items = topAlternativeTiles[newTileIndex]
                        newTile.index = items[Math.floor(rng()*items.length)];
                    }
                })

                leftTiles.forEach(({ x, y }) => {
                    const newTile = layerData[_y + y] ? layerData[_y + y][_x + x] : undefined;

                    if (!newTile) {
                        return;
                    }

                    const newTileIndex = newTile.index;
                    if (newTile && newTileIndex && leftAlternativeTiles[newTileIndex]) {
                        if (rng() < 0.5) {
                            return;
                        }
                        
                        const items = leftAlternativeTiles[newTileIndex]
                        newTile.index = items[Math.floor(rng()*items.length)];
                    }
                })

                rightTiles.forEach(({ x, y }) => {
                    const newTile = layerData[_y + y] ? layerData[_y + y][_x + x] : undefined;

                    if (!newTile) {
                        return;
                    }

                    const newTileIndex = newTile.index;
                    if (newTile && newTileIndex && rightAlternativeTiles[newTileIndex]) {
                        if (rng() < 0.5) {
                            return;
                        }
                        
                        const items = rightAlternativeTiles[newTileIndex]
                        newTile.index = items[Math.floor(rng()*items.length)];
                    }
                })
            }
        })
    })
}

export function drawLusciousRainforest(mockLayerData, layerData, panel, rng) {
    const panelTrunkCoords = [];
    const GROUND_KEY = 'EMPTY'

    const treeNumber = 'ONE';

    const keys = {
        TRUNK_KEY: `DEEPEST-JUNGLE.${treeNumber}.TRUNK.A1`,
        GROUND_KEY: 'EMPTY',
        LEAF_KEY: `DEEPEST-JUNGLE.${treeNumber}.LEAF.LEFT.RIGHT.TOP.BOTTOM.`,
    }

    const secondRingChance = 11 / 12;
    const halfRingChance = 1/2;
    const thirdRingChance = 3 / 26;

    const stampShape1 = [
        // { x: 0, y: 0 },
        { x: -1, y: -1, index: tileMap['LUSCIOUS.ALOE.RIGHT.BOTTOM.'] },
        { x: 0, y: -1, index: tileMap['LUSCIOUS.ALOE.LEFT.RIGHT.BOTTOM.'] },
        { x: 1, y: -1, index: tileMap['LUSCIOUS.ALOE.LEFT.BOTTOM.'] },


        { x: -1, y: 0, index: tileMap['LUSCIOUS.ALOE.RIGHT.TOP.BOTTOM.'] },
        { x: 0, y: 0, index: tileMap['LUSCIOUS.ALOE.LEFT.RIGHT.TOP.BOTTOM.'] },
        { x: 1, y: 0, index: tileMap['LUSCIOUS.ALOE.LEFT.TOP.BOTTOM.'] },


        { x: -1, y: 1, index: tileMap['LUSCIOUS.ALOE.RIGHT.TOP.'] },
        { x: 0, y: 1, index: tileMap['LUSCIOUS.ALOE.LEFT.RIGHT.TOP.'] },
        { x: 1, y: 1, index: tileMap['LUSCIOUS.ALOE.LEFT.TOP.'] },

        { x: 2, y: 2, index: tileMap['EMPTY'] },
        { x: 2, y: -2, index: tileMap['EMPTY'] },
        { x: -2, y: 2, index: tileMap['EMPTY'] },
        { x: -2, y: -2, index: tileMap['EMPTY'] },

        { x: 2, y: 0, index: tileMap['EMPTY'] },
        { x: -2, y: 0, index: tileMap['EMPTY'] },
        { x: 0, y: 2, index: tileMap['EMPTY'] },
        { x: 0, y: -2, index: tileMap['EMPTY'] },
    ]

    const stampShape2 = [
        // { x: 0, y: 0 },
        { x: -1, y: -1, index: tileMap['LUSCIOUS.PALM.RIGHT.BOTTOM.'] },
        { x: 0, y: -1, index: tileMap['LUSCIOUS.PALM.LEFT.RIGHT.BOTTOM.'] },
        { x: 1, y: -1, index: tileMap['LUSCIOUS.PALM.LEFT.BOTTOM.'] },


        { x: -1, y: 0, index: tileMap['LUSCIOUS.PALM.LEFT.RIGHT.BOTTOM.'] },
        { x: 0, y: 0, index: tileMap['LUSCIOUS.PALM.LEFT.RIGHT.TOP.BOTTOM.'] },
        { x: 1, y: 0, index: tileMap['LUSCIOUS.PALM.LEFT.RIGHT.BOTTOM.'] },


        { x: -1, y: 1, index: tileMap['LUSCIOUS.PALM.RIGHT.TOP.BOTTOM.'] },
        { x: 0, y: 1, index: tileMap['LUSCIOUS.PALM.LEFT.RIGHT.BOTTOM.'] },
        { x: 1, y: 1, index: tileMap['LUSCIOUS.PALM.LEFT.TOP.BOTTOM.'] },

        { x: 2, y: 2, index: tileMap['EMPTY'] },
        { x: 2, y: -2, index: tileMap['EMPTY'] },
        { x: -2, y: 2, index: tileMap['EMPTY'] },
        { x: -2, y: -2, index: tileMap['EMPTY'] },

        { x: 2, y: 0, index: tileMap['EMPTY'] },
        { x: -2, y: 0, index: tileMap['EMPTY'] },
        { x: 0, y: 2, index: tileMap['EMPTY'] },
        { x: 0, y: -2, index: tileMap['EMPTY'] },
    ]

    const neighbours = getMatchingBiomeNeighbours(panel);

    const trunkCoords = drawTreeDots(mockLayerData, layerData, panel, [stampShape1, stampShape2], 1.2, false, neighbours, keys, rng);

    // //Jungle plants
    const JUNGLE_PLANT_1 = 'LUSCIOUS-BUSH'
    const JUNGLE_PLANT_2 = 'PALM-BUSH'

    let noiseOne = drawNoise(3, 4, panel);

    mockLayerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (layerData[y - PANEL_HEIGHT] && layerData[y - PANEL_HEIGHT][x - PANEL_WIDTH]) {
                tile.index = layerData[y - PANEL_HEIGHT][x - PANEL_WIDTH].index
            }
        })
    })

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (!isEmptyIndex(tile)) {
                return;
            }
            if (noiseOne[rowIndex][columnIndex] > 0.7) {
                tile.index = tileMap[JUNGLE_PLANT_1];
            } else if (noiseOne[rowIndex][columnIndex] > 0.65) {
                tile.index = tileMap[JUNGLE_PLANT_2];
            }
        })
    });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function getNeighbours(layerData, x, y) {
    let left;
    let right;
    let top;
    let bottom;

    if (layerData[y]) {
        left = layerData[y][x - 1];
        right = layerData[y][x + 1];
    }

    if (layerData[y - 1]) {
        top = layerData[y - 1][x];
    }

    if (layerData[y + 1]) {
        bottom = layerData[y + 1][x];
    }

    return {
        left,
        right,
        top,
        bottom
    }
}

export function getDiagonalNeighbours(layerData, x, y) {
    let bottomLeft;
    let bottomRight;
    let topLeft;
    let topRight;

    if (layerData[y + 1]) {
        bottomLeft = layerData[y + 1][x - 1];
        bottomRight = layerData[y + 1][x + 1];
    }

    if (layerData[y - 1]) {
        topLeft = layerData[y - 1][x - 1];
        topRight = layerData[y - 1][x + 1];
    }

    return {
        bottomLeft,
        bottomRight,
        topLeft,
        topRight,
    }
}

function countNeighbours(layerData, tileIndex, x, y) {
    if (!tileIndex) {
        return 0;
    }

    let count = 0;

    const { left, right, top, bottom } = getNeighbours(layerData, x, y);

    if (left && left?.index === tileIndex) {
        count++;
    }

    if (right && right?.index === tileIndex) {
        count++;
    }

    if (top && top?.index === tileIndex) {
        count++;
    }

    if (bottom && bottom?.index === tileIndex) {
        count++;
    }

    return count;
}

export function drawCrystalisedForest(mockLayerData, layerData, panel, rng) {
    const season = 'MONSOON'
    const GROUND_KEY = 'EMPTY'

    const GRASS_KEY = `CRYSTAL.GRASS.${season}-1`;
    const SECOND_GRASS_KEY = `CRYSTAL.GRASS.${season}-2`;

    const keys = {
        TRUNK_KEY: `CRYSTAL.TRUNK.${season}.RIGHT.`,
        GROUND_KEY: 'EMPTY',
        LEAF_KEY: `CRYSTAL.LEAF.MONSOON.LEFT.LEFT-SLOPE.FOURTH`
    }

    // 'CRYSTAL.SLOTH.MONSOON': 368,

    const stampShape = [
        // { x: 0, y: 0 },
        { x: -3, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.LEFT-SLOPE.SECOND`] },
        { x: -3, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.LEFT-SLOPE.THIRD`] },
        { x: -2, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.LEFT-SLOPE.FOURTH`] },
        { x: -2, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT-SLOPE.SECOND`] },
        { x: -2, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: 0, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: 1, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 1, y: 0, index: tileMap[`CRYSTAL.TRUNK.${season}.LEFT.`] },
        { x: -1, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: -1, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 2, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 3, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 4, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.RIGHT-SLOPE.THIRD`] },
        { x: 5, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.RIGHT-SLOPE.THIRD`] },
        { x: -1, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.LEFT-SLOPE.SECOND`] },
        { x: 1, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.RIGHT-SLOPE.FIRST`] },
        { x: 0, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.LEFT-SLOPE.SECOND`] },
        { x: 0, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 1, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: 2, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.RIGHT-SLOPE.FOURTH`] },
        { x: 3, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.LEFT-SLOPE.FOURTH`] },
        { x: 4, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.LEFT-SLOPE.THIRD`] },
        { x: 5, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.LEFT-SLOPE.SECOND`] },
        { x: 6, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.LEFT-SLOPE.FIRST`] },
        { x: 5, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.POINK`] },
        { x: -3, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.FIRST`] },
        { x: 6, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.LEFT-SLOPE.TIP`] },
        { x: 4, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: 1, y: -4, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.FIRST`] },
    ]

    const stampShape2 = [
        // { x: 0, y: 0 },
        { x: -2, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.LEFT-SLOPE.FOURTH`] },
        { x: -1, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 1, y: 0, index: tileMap[`CRYSTAL.TRUNK.${season}.LEFT.`] },
        { x: 2, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 3, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 4, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.RIGHT-SLOPE.THIRD`] },
        { x: -3, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.LEFT-SLOPE.THIRD`] },
        { x: -2, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: -1, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 0, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: 1, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 2, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 3, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: 4, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 5, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.RIGHT-SLOPE.THIRD`] },
        { x: -3, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.LEFT-SLOPE.SECOND`] },
        { x: -2, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT-SLOPE.SECOND`] },
        { x: -1, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.LEFT-SLOPE.SECOND`] },
        { x: 0, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 1, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: 2, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.RIGHT-SLOPE.FOURTH`] },
        { x: 3, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.LEFT-SLOPE.FOURTH`] },
        { x: 4, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.LEFT-SLOPE.FOURTH`] },
        { x: 5, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: 6, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.RIGHT-SLOPE.THIRD`] },
        { x: -3, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.FIRST`] },
        { x: 0, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.LEFT-SLOPE.SECOND`] },
        { x: 1, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.RIGHT-SLOPE.FIRST`] },
        { x: 5, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.LEFT-SLOPE.THIRD`] },
        { x: 6, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.LEFT-SLOPE.SECOND`] },
        { x: 7, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.LEFT-SLOPE.FIRST`] },
        { x: 1, y: -4, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.FIRST`] },
        { x: 6, y: -4, index: tileMap[`CRYSTAL.LEAF.${season}.POINK`] },
        { x: 7, y: -4, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.LEFT-SLOPE.TIP`] },
    ]

    const stampShape3 = [
        // { x: 0, y: 0 },
        { x: -2, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.LEFT-SLOPE.FOURTH`] },
        { x: -1, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 1, y: 0, index: tileMap[`CRYSTAL.TRUNK.${season}.LEFT.`] },
        { x: 2, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 3, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.RIGHT-SLOPE.THIRD`] },
        { x: -3, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.LEFT-SLOPE.THIRD`] },
        { x: -2, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: -1, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 0, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: 1, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 2, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 3, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: 4, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.RIGHT-SLOPE.THIRD`] },
        { x: -3, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.LEFT-SLOPE.SECOND`] },
        { x: -2, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT-SLOPE.SECOND`] },
        { x: -1, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.LEFT-SLOPE.SECOND`] },
        { x: 0, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 1, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: 2, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.RIGHT-SLOPE.FOURTH`] },
        { x: 3, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.LEFT-SLOPE.FOURTH`] },
        { x: 4, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: 5, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.RIGHT-SLOPE.THIRD`] },
        { x: -3, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.FIRST`] },
        { x: 0, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 1, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 0, y: -4, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.LEFT-SLOPE.SECOND`] },
        { x: 1, y: -4, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.RIGHT-SLOPE.FIRST`] },
        { x: 4, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.LEFT-SLOPE.THIRD`] },
        { x: 5, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.LEFT-SLOPE.SECOND`] },
        { x: 6, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.LEFT-SLOPE.FIRST`] },
        { x: 1, y: -5, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.FIRST`] },
        { x: 5, y: -4, index: tileMap[`CRYSTAL.LEAF.${season}.POINK`] },
        { x: 6, y: -4, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.LEFT-SLOPE.TIP`] },
    ]

    const stampShape4 = [
        // { x: 0, y: 0 },
        { x: -2, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.LEFT-SLOPE.FOURTH`] },
        { x: -1, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 1, y: 0, index: tileMap[`CRYSTAL.TRUNK.${season}.LEFT.`] },
        { x: 2, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 3, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 4, y: 0, index: tileMap[`CRYSTAL.LEAF.${season}.RIGHT.RIGHT-SLOPE.THIRD`] },
        { x: -3, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.LEFT-SLOPE.THIRD`] },
        { x: -2, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: -1, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 0, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: 1, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 2, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: 3, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 4, y: -1, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: -3, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.LEFT-SLOPE.SECOND`] },
        { x: -2, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT-SLOPE.SECOND`] },
        { x: -1, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.LEFT-SLOPE.SECOND`] },
        { x: 0, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.`] },
        { x: 1, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT.TOP.BOTTOM.2`] },
        { x: 2, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.RIGHT-SLOPE.SECOND`] },
        { x: 3, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.LEFT-SLOPE.SECOND`] },
        { x: 4, y: -2, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.RIGHT-SLOPE.FIRST`] },
        { x: 4, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.FIRST`] },
        { x: -3, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.LEFT.FIRST`] },
        { x: 0, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.LEFT-SLOPE.SECOND`] },
        { x: 1, y: -3, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.RIGHT-SLOPE.FIRST`] },
        { x: 1, y: -4, index: tileMap[`CRYSTAL.LEAF.${season}.CENTRE.FIRST`] },
    ]

    const neighbours = getMatchingBiomeNeighbours(panel);

    const trunkCoords = drawTreeDots(mockLayerData, layerData, panel, [stampShape, stampShape2, stampShape3, stampShape4], 1.45, false, neighbours, keys, rng);

    drawDots(layerData, panel, GRASS_KEY, 1.4)
    drawDots(layerData, panel, SECOND_GRASS_KEY, 1.4, undefined, 2)
    // drawDots(layerData, panel, CLOUDBERRY_KEY, 1.6, [tileMap[keys.TRUNK_KEY], tileMap[keys.LEAF_KEY]], 2)
}

function getMatchingBiomeNeighbours(panel) {
    const matchingBiomeNeighbours = []

    if (panel.biome === panel.neighbours.north.biome) {
        matchingBiomeNeighbours.push('north')
    }

    if (panel.biome === panel.neighbours.east.biome) {
        matchingBiomeNeighbours.push('east')
    }

    if (panel.biome === panel.neighbours.south.biome) {
        matchingBiomeNeighbours.push('south')
    }

    if (panel.biome === panel.neighbours.west.biome) {
        matchingBiomeNeighbours.push('west')
    }

    return matchingBiomeNeighbours
}

export function drawSteppes(mockLayerData, layerData, panel) {
    const GRASS_KEY = 'STEPPE.GRASS';
    const GROUND_KEY = 'EMPTY'

    let noiseOne = drawNoise(4, 6, panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (noiseOne[rowIndex][columnIndex] > 0.5) {
                tile.index = tileMap[GRASS_KEY];
            } else {
                tile.index = tileMap[GROUND_KEY];
            }
        })
    });
    
    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function drawHighSeas(mockLayerData, layerData, panel) {
    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            tile.index = tileMap['OCEAN'];
        })
    });
}

export function drawHeaths(mockLayerData, layerData, panel) {
    const HEATH_KEY = 'HEATH.GRASS.SPRING';
    const GROUND_KEY = 'EMPTY'

    let noiseOne = drawNoise(3, 5, panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (noiseOne[rowIndex][columnIndex] > 0.55) {
                tile.index = tileMap[HEATH_KEY];
            } else {
                tile.index = tileMap[GROUND_KEY];
            }
        })
    });
    
    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function drawPricklelands(mockLayerData, layerData, panel) {
    const FLOWER_KEY = 'PRICKLEBUSH-FLOWER';
    const THORN_KEY = 'PRICKLEBUSH.HARVEST';
    const GROUND_KEY = 'EMPTY'

    let noiseOne = drawNoise(4, 5, panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (noiseOne[rowIndex][columnIndex] > 0.7) {
                tile.index = tileMap[FLOWER_KEY];
            } else if (noiseOne[rowIndex][columnIndex] > 0.5) {
                tile.index = tileMap[THORN_KEY];
            } else {
                tile.index = tileMap[GROUND_KEY];
            }
        })
    });

    limitToCheckerboxPattern(layerData, panel, GROUND_KEY)
    
    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function drawGumboRumblings(mockLayerData, layerData, panel) {
    const TUMBLE_STICKS_KEY = 'GUMBO.TUMBLESTICKS';
    const RUMBLE_GRASS_KEY = 'GUMBO.RUMBLEGRASS';
    const CUSHION_PLANT = 'CUSHION-PLANT'
    const GROUND_KEY = 'EMPTY'

    let noiseOne = drawNoise(1, 1, panel);

    let noiseTwo = drawNoise(4, 5, panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (noiseOne[rowIndex][columnIndex] > 0.63) {
                tile.index = tileMap[TUMBLE_STICKS_KEY];
            } else if (noiseOne[rowIndex][columnIndex] > 0.5) {
                tile.index = tileMap[RUMBLE_GRASS_KEY];
            } else {
                tile.index = tileMap[GROUND_KEY];
            }
        })
    });

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (noiseTwo[rowIndex][columnIndex] > 0.6) {
                if (columnIndex % 2 === 0) {
                    if (rowIndex % 2 === 0) {
                        tile.index = tileMap[CUSHION_PLANT];
                    } else {

                    }
                } else {
                    if (rowIndex % 2 === 0) {
                    } else {
                        tile.index = tileMap[CUSHION_PLANT];
                    }
                }
            }
        })
    });
    
    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function drawWastes(mockLayerData, layerData, panel, rng) {
    const WASTE_ARRAY = [
        'WASTES.START.LEFT.',
        'WASTES.SECOND.LEFT.',
        'WASTES.THIRD.LEFT.',
        'WASTES.PEAK_1.BOTTOM.',
        'WASTES.PEAK_2.BOTTOM.',
        'WASTES.THIRD.RIGHT.',
        'WASTES.SECOND.RIGHT.',
        'WASTES.FIRST.RIGHT.'
    ]
    const WASTE_KEY = 'WASTES.START.LEFT.';
    const GROUND_KEY = 'EMPTY';

    const drawingLimit = 1.2;
    let noiseOne = drawNoise(1, 30, panel);
    let noiseTwo = drawNoise(1, 30, panel);

    let finalNoise = noiseOne.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseTwo[x][y]))
    })

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (finalNoise[rowIndex][columnIndex] > drawingLimit) {
                tile.index = tileMap[WASTE_KEY];
            } else {
                tile.index = tileMap[GROUND_KEY];
            }
        })
    });
    
    clearRiverCliffAndOceanTiles(layerData, panel)

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile.index === tileMap[GROUND_KEY]) {
                return;
            }

            if (rowIndex === 0 || rowIndex === PANEL_WIDTH - 1 || columnIndex === 0 || columnIndex === PANEL_HEIGHT - 1) {
                return;
            }

            if (columnIndex === 0 || columnIndex === PANEL_HEIGHT - 1) {
                tile.index = tileMap[GROUND_KEY];
                return;
            }

            const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index === tile.index ? 1 : 0;
            const isEastSameTile = layerData[columnIndex][rowIndex + 1].index === tile.index ? 1 : 0;
            const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index === tile.index ? 1 : 0;
            const isWestSameTile = layerData[columnIndex][rowIndex - 1].index === tile.index ? 1 : 0;

            const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

            if (neighboursCount > 2) {
                if (isSouthSameTile) {
                    layerData[columnIndex + 1][rowIndex].index = tileMap[GROUND_KEY]
                }
            }

            if (neighboursCount === 0) {
                tile.index = tileMap[GROUND_KEY]
            }
        })
    });
    
    clearRiverCliffAndOceanTiles(layerData, panel)

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile.index === tileMap[GROUND_KEY]) {
                return;
            }

            if (tile.index !== tileMap[WASTE_KEY]) {
                return;
            }

            // get length of waste line...
            let tileIncrement = 1;
            let nextTile = layerData[columnIndex][rowIndex + tileIncrement];
            let tiles = []
            let isThird = false;

            while (nextTile && !isEmptyIndex(nextTile)) {
                tiles.push(nextTile)
                tileIncrement++;
                nextTile = layerData[columnIndex][rowIndex + tileIncrement];
            }

            if (!tiles[0]) {
                return;
            }

            tiles[0].index = tileMap['WASTES.SECOND.LEFT.']

            const last = tiles.length - 1;

            tiles[last].index = tileMap['WASTES.FIRST.RIGHT.']

            if (tiles.length === 2) {
                tiles[last].index = tileMap['WASTES.SECOND.RIGHT.']
            } else if (tiles[last - 1]) {
                tiles[last - 1].index = tileMap['WASTES.SECOND.RIGHT.']
            }

            for (var i=1; i < tiles.length - 2; i++) {
                const random = rng();

                if (tiles.length - i > 3) {
                    if (isThird) {
                        if (tiles.length - i > 4) {
                            if (random > 0.666) {
                                tiles[i].index = tileMap['WASTES.PEAK_1.BOTTOM.']
                                const tile = tiles[i]
                                if (layerData[tile.y - 1]) {
                                    layerData[tile.y - 1][tile.x].index  = tileMap['WASTES.PEAK_1.TOP.']
                                }
                            } else if (random > 0.333) {
                                tiles[i].index = tileMap['WASTES.PEAK_2.BOTTOM.']
                                const tile = tiles[i]
                                if (layerData[tile.y - 1]) {
                                    layerData[tile.y - 1][tile.x].index  = tileMap['WASTES.PEAK_2.TOP.']
                                }
                            } else {
                                tiles[i].index = tileMap['WASTES.THIRD.RIGHT.']
                                isThird = false;
                            }
                        } else {
                            tiles[i].index = tileMap['WASTES.THIRD.RIGHT.']
                            isThird = false;
                        }
                    } else {
                        if (random > 0.666) {
                            tiles[i].index = tileMap['WASTES.THIRD.LEFT.']
                            isThird = true;
                        } else if (random > 0.333) {
                            tiles[i].index = tileMap['WASTES.BUMP_2']
                        } else {
                            tiles[i].index = tileMap['WASTES.BUMP_1']
                        }
                    }
                } else {
                    if (isThird) {
                        tiles[i].index = tileMap['WASTES.THIRD.RIGHT.']
                    } else {
                        if (random > 0.5) {
                            tiles[i].index = tileMap['WASTES.BUMP_1']
                        } else {
                            tiles[i].index = tileMap['WASTES.BUMP_2']
                        }
                    }
                }
            }
        })
    });
}

export function drawSands(mockLayerData, layerData, panel) {
    const SAND_KEY = 'SAND.LEFT.RIGHT.';
    const GROUND_KEY = 'EMPTY';
    const neighbours = getMatchingBiomeNeighbours(panel);

    const drawingLimit = 0.7;

    let noiseOne = drawNoise3D(1, 6, panel, new Date().getTime() / 50000);
    let noiseTwo = drawNoise3D(3, 10, panel, new Date().getTime() / 50000);
    let noiseThree = drawNoise3D(8,0.1, panel, new Date().getTime() / 50000);
    let noiseFour = drawNoise3D(230, 1, panel, new Date().getTime() / 50000);

    let finalNoise = noiseOne.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseTwo[x][y]))
    })

    let finalNoise2 = finalNoise.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseFour[x][y]))
    })

    finalNoise2 = finalNoise.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseFour[x][y]))
    })

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (finalNoise2[rowIndex][columnIndex] > drawingLimit || finalNoise[rowIndex][columnIndex] > drawingLimit) {
                tile.index = tileMap[SAND_KEY];
            } else {
                tile.index = tileMap[GROUND_KEY];
            }
        })
    });

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile.index === tileMap[GROUND_KEY]) {
                return;
            }

            if (rowIndex === 0 || rowIndex === PANEL_WIDTH - 1 || columnIndex === 0 || columnIndex === PANEL_HEIGHT - 1) {
                return;
            }

            if (columnIndex === 0 || columnIndex === PANEL_HEIGHT - 1) {
                tile.index = tileMap[GROUND_KEY];
                return;
            }

            const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index === tile.index ? 1 : 0;
            const isEastSameTile = layerData[columnIndex][rowIndex + 1].index === tile.index ? 1 : 0;
            const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index === tile.index ? 1 : 0;
            const isWestSameTile = layerData[columnIndex][rowIndex - 1].index === tile.index ? 1 : 0;

            const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

            if (neighboursCount > 2) {
                if (isSouthSameTile) {
                    layerData[columnIndex + 1][rowIndex].index = tileMap[GROUND_KEY]
                }
            }

            if (neighboursCount === 0) {
                tile.index = tileMap[GROUND_KEY]
            }
        })
    });

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (columnIndex === 0 && !neighbours.find(neighbour => neighbour === 'north')) {
                tile.index = tileMap[GROUND_KEY]
            }

            if (columnIndex === PANEL_HEIGHT - 1 && !neighbours.find(neighbour => neighbour === 'south')) {
                tile.index = tileMap[GROUND_KEY]
            }

            if (rowIndex === 0 && !neighbours.find(neighbour => neighbour === 'west')) {
                tile.index = tileMap[GROUND_KEY]
            }

            if (rowIndex === PANEL_WIDTH - 1 && !neighbours.find(neighbour => neighbour === 'east')) {
                tile.index = tileMap[GROUND_KEY]
            }
        })
    });

    clearRiverCliffAndOceanTiles(layerData, panel)

    drawNeighbours(layerData, 'SAND.', tileMap['SAND.LEFT.RIGHT.'], { isDiagonalRelevent: false });
}

export function drawMountains(mockLayerData, layerData, panel, rng, maze) {
    const MAZE_KEY = 'MOUNTAIN.LEFT.RIGHT.';
    const GROUND_KEY = 'EMPTY';
    const neighbours = getMatchingBiomeNeighbours(panel);

    let panelMaze = [];

    for (var y=0; y < PANEL_HEIGHT; y++) {
        const row = [];
        panelMaze.push(row);

        for (var x=0; x < PANEL_WIDTH; x++) {
            row.push([])
        }
    }

    const xStart = panel.x * PANEL_WIDTH;
    const xEnd = xStart + PANEL_WIDTH;

    const yStart = panel.y * PANEL_HEIGHT;
    const yEnd = yStart + PANEL_HEIGHT;

    maze.createMazeFn((x, y, value) => {
        if (x < xStart || x >= xEnd) {
            return;
        }

        if (y < yStart || y >= yEnd) {
            return;
        }


        panelMaze[y - yStart][x - xStart] = value;
    }, rng)

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (panelMaze[columnIndex][rowIndex]) {
                tile.index = tileMap[MAZE_KEY];
            } else {
                tile.index = tileMap[GROUND_KEY];
            }
        })
    });

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (columnIndex === 0 && !neighbours.find(neighbour => neighbour === 'north')) {
                tile.index = tileMap[GROUND_KEY]
            }

            if (columnIndex === PANEL_HEIGHT - 1 && !neighbours.find(neighbour => neighbour === 'south')) {
                tile.index = tileMap[GROUND_KEY]
            }

            if (rowIndex === 0 && !neighbours.find(neighbour => neighbour === 'west')) {
                tile.index = tileMap[GROUND_KEY]
            }

            if (rowIndex === PANEL_WIDTH - 1 && !neighbours.find(neighbour => neighbour === 'east')) {
                tile.index = tileMap[GROUND_KEY]
            }
        })
    });

    drawNeighbours(layerData, 'MOUNTAIN.', tileMap['MOUNTAIN.LEFT.RIGHT.'], { isDiagonalRelevent: false });
}

export function drawInglenook(mockLayerData, layerData, panel) {
    const TRUNK_KEY = 'INGLENOOK.TRUNK';
    const EMPTY_KEY = 'EMPTY';
    const LEAF_KEY = 'INGLENOOK.LEAF.LEFT.RIGHT.';
    const VINE_KEY = 'INGLENOOK.VINE.LEFT.RIGHT.'

    const GRASS_KEY = 'STEPPE.GRASS';
    const GROUND_KEY = 'EMPTY'

    const keys = {
        STARTING_KEY: TRUNK_KEY,
        WALK_KEY: VINE_KEY,
        EMPTY_KEY
    }

    const neighbours = getMatchingBiomeNeighbours(panel);
    const rng = seedrandom('tanglewoods' + panel.x + panel.y);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            tile.index = tileMap[EMPTY_KEY];
        })
    });

    let dots = drawDots(layerData, panel, TRUNK_KEY, 1.3);
    dots = dots.filter((dot) => (!panel.floorLayer.find(panel => (panel.x === dot.x && panel.y === dot.y))))

    clearRiverCliffAndOceanTiles(layerData, panel)


    dots = cleanDifferentNeighbouringDots(dots, neighbours);

    let moveWeights = {
        left: 10,
        up: 10,
        down: 2,
        right: 2
    }
    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 10, moveWeights, keys, rng)))

    moveWeights = {
        left: 2,
        up: 2,
        down: 10,
        right: 10
    }
    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 10, moveWeights, keys, rng)))

    moveWeights = {
        left: 2,
        up: 10,
        down: 2,
        right: 10
    }
    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 10, moveWeights, keys, rng)))

    moveWeights = {
        left: 10,
        up: 2,
        down: 10,
        right: 2
    }
    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 10, moveWeights, keys, rng)))

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (rowIndex !== 0 && rowIndex !== PANEL_WIDTH - 1 && columnIndex !== 0 && columnIndex !== PANEL_HEIGHT - 1) {
                const tile = layerData[columnIndex][rowIndex]
                const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index === tileMap[VINE_KEY] ? 1 : 0;
                const isEastSameTile = layerData[columnIndex][rowIndex + 1].index === tileMap[VINE_KEY] ? 1 : 0;
                const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index === tileMap[VINE_KEY] ? 1 : 0;
                const isWestSameTile = layerData[columnIndex][rowIndex - 1].index === tileMap[VINE_KEY] ? 1 : 0;

                const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

                if (neighboursCount === 0) {
                    // tile.index = tileMap[EMPTY_KEY]
                }
            }
        })
    });

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (rowIndex !== 0 && rowIndex !== PANEL_WIDTH - 1 && columnIndex !== 0 && columnIndex !== PANEL_HEIGHT - 1) {
                const tile = layerData[columnIndex][rowIndex]
                const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index === tileMap[VINE_KEY] ? 1 : 0;
                const isEastSameTile = layerData[columnIndex][rowIndex + 1].index === tileMap[VINE_KEY] ? 1 : 0;
                const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index === tileMap[VINE_KEY] ? 1 : 0;
                const isWestSameTile = layerData[columnIndex][rowIndex - 1].index === tileMap[VINE_KEY] ? 1 : 0;

                const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

                if (neighboursCount === 1 && tile.index === tileMap[EMPTY_KEY]) {
                    tile.index = tileMap[LEAF_KEY]
                }
            }
        })
    });
    clearDifferentNeighbourBorders(layerData, neighbours, keys)

    drawNeighbours(layerData, 'INGLENOOK.VINE.', tileMap['INGLENOOK.VINE.LEFT.RIGHT.'], { isDiagonalRelevent: false, connectionTileIndex: tileMap['INGLENOOK.LEAF.LEFT.RIGHT.'], startingIndexes: [tileMap[TRUNK_KEY]] });
    drawNeighbours(layerData, 'INGLENOOK.LEAF.', tileMap['INGLENOOK.LEAF.LEFT.RIGHT.'], { isDiagonalRelevent: false, isAnyIndexNeighbour: true });

    drawDots(layerData, panel, GRASS_KEY, 1.1);

    clearRiverCliffAndOceanTiles(layerData, panel)
}

function cleanDifferentNeighbouringDots(dots, neighbours) {
    return dots.filter(dot => {
        let topY = 0;
        let bottomY = PANEL_HEIGHT - 1;

        let leftX = 0;
        let rightX = PANEL_WIDTH - 1;

        if (neighbours.find(neighbour => neighbour === 'north')) {
            topY = -1
        }

        if (neighbours.find(neighbour => neighbour === 'south')) {
            bottomY = PANEL_HEIGHT
        }

        if (neighbours.find(neighbour => neighbour === 'east')) {
            rightX = PANEL_WIDTH
        }

        if (neighbours.find(neighbour => neighbour === 'west')) {
            leftX = -1
        }

        return (dot.x > leftX && dot.x < rightX) && (dot.y > topY && dot.y < bottomY)
    });
}

function clearDifferentNeighbourBorders(layerData, neighbours, keys) {
    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (columnIndex === 0 && !neighbours.find(neighbour => neighbour === 'north')) {
                tile.index = tileMap[keys.EMPTY_KEY]
            }

            if (columnIndex === PANEL_HEIGHT - 1 && !neighbours.find(neighbour => neighbour === 'south')) {
                tile.index = tileMap[keys.EMPTY_KEY]
            }

            if (rowIndex === 0 && !neighbours.find(neighbour => neighbour === 'west')) {
                tile.index = tileMap[keys.EMPTY_KEY]
            }

            if (rowIndex === PANEL_WIDTH - 1 && !neighbours.find(neighbour => neighbour === 'east')) {
                tile.index = tileMap[keys.EMPTY_KEY]
            }
        })
    });
}

export function drawFoetalRainforest(mockLayerData, layerData, panel) {
    const TRUNK_KEY = 'PRIMEORDEAL.TRUNK';
    const EMPTY_KEY = 'EMPTY';
    const LEAF_KEY = 'PRIMEORDEAL.VINE.LEFT.RIGHT.';
    const VINE_KEY = 'PRIMEORDEAL.VINE.LEFT.RIGHT.';
    const rng = seedrandom('foetal' + panel.x + panel.y);
    const neighbours = getMatchingBiomeNeighbours(panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            tile.index = tileMap[EMPTY_KEY];
        })
    });

    const GRASS_KEY = 'BAMBOO';
    const GROUND_KEY = 'EMPTY'

    let dots = drawDots(layerData, panel, TRUNK_KEY, 1.4);

    const keys = {
        STARTING_KEY: TRUNK_KEY,
        WALK_KEY: VINE_KEY
    }

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    let moveWeights = {
        left: 10,
        up: 10,
        down: 2,
        right: 2
    }
    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 20, moveWeights, keys, rng)))

    moveWeights = {
        left: 10,
        up: 2,
        down: 10,
        right: 2
    }
    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 20, moveWeights, keys, rng)))

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (rowIndex !== 0 && rowIndex !== PANEL_WIDTH - 1 && columnIndex !== 0 && columnIndex !== PANEL_HEIGHT - 1) {
                const tile = layerData[columnIndex][rowIndex]
                const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index === tileMap[VINE_KEY] ? 1 : 0;
                const isEastSameTile = layerData[columnIndex][rowIndex + 1].index === tileMap[VINE_KEY] ? 1 : 0;
                const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index === tileMap[VINE_KEY] ? 1 : 0;
                const isWestSameTile = layerData[columnIndex][rowIndex - 1].index === tileMap[VINE_KEY] ? 1 : 0;

                const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

                if (neighboursCount === 0) {
                    tile.index = tileMap[EMPTY_KEY]
                }
            }
        })
    });


    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (rowIndex !== 0 && rowIndex !== PANEL_WIDTH - 1 && columnIndex !== 0 && columnIndex !== PANEL_HEIGHT - 1) {
                const tile = layerData[columnIndex][rowIndex]
                const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index !== tileMap[EMPTY_KEY] ? 1 : 0;
                const isEastSameTile = layerData[columnIndex][rowIndex + 1].index !== tileMap[EMPTY_KEY] ? 1 : 0;
                const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index !== tileMap[EMPTY_KEY] ? 1 : 0;
                const isWestSameTile = layerData[columnIndex][rowIndex - 1].index !== tileMap[EMPTY_KEY] ? 1 : 0;

                const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

                if (neighboursCount === 1 && tile.index === tileMap[VINE_KEY]) {
                    tile.index = tileMap[LEAF_KEY]
                }
            }
        })
    });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    drawNeighbours(layerData, 'PRIMEORDEAL.VINE.', tileMap['PRIMEORDEAL.VINE.LEFT.RIGHT.'], { isDiagonalRelevent: false, startingIndexes: [tileMap[TRUNK_KEY], tileMap[LEAF_KEY]] });
    // drawNeighbours(layerData, 'WONEYE.EYE.', tileMap['WONEYE.EYE.LEFT.RIGHT.'], { isDiagonalRelevent: false, isAnyIndexNeighbour: true });

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile.index === tileMap['PRIMEORDEAL.VINE.TOP.BOTTOM.']) {
                if (rng() < 0.2) {
                    tile.index = tileMap['PRIMEORDEAL.VINE.TOP.BOTTOM.2']
                }
            }

            if (tile.index === tileMap['PRIMEORDEAL.VINE.LEFT.RIGHT.']) {
                if (rng() < 0.2) {
                    tile.index = tileMap['PRIMEORDEAL.VINE.LEFT.RIGHT.2']
                }
            }
        })
    });



    let noiseOne = drawNoise(6, 8, panel);
    let noiseTwo = drawNoise(1, 1, panel);

    let finalNoise = noiseOne.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseTwo[x][y]))
    })

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (!isEmptyIndex(tile)) {
                return;
            }
            if (finalNoise[rowIndex][columnIndex] > 0.5) {
                tile.index = tileMap[GRASS_KEY];
            } else {
                tile.index = tileMap[GROUND_KEY];
            }
        })
    });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function drawNeedlewoodForest(mockLayerData, layerData, panel) {
    const SEASON = 'DRY';

    const TRUNK_KEY = `NEEDLEWOOD.THORN.${SEASON}.LEFT.RIGHT.TOP.BOTTOM.`;
    const EMPTY_KEY = 'EMPTY';
    const LEAF_KEY = `NEEDLEWOOD.THORN.${SEASON}.LEFT.RIGHT.`;
    const VINE_KEY = `NEEDLEWOOD.THORN.${SEASON}.LEFT.RIGHT.`;
    const rng = seedrandom('tanglewoods' + panel.x + panel.y);
    const neighbours = getMatchingBiomeNeighbours(panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            tile.index = tileMap[EMPTY_KEY];
        })
    });

    let dots = drawDots(layerData, panel, TRUNK_KEY, 1.4);

    const keys = {
        STARTING_KEY: TRUNK_KEY,
        WALK_KEY: VINE_KEY
    }

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    let moveWeights = {
        left: 10,
        up: 10,
        down: 2,
        right: 2
    }
    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 10, moveWeights, keys, rng)))

    moveWeights = {
        left: 10,
        up: 2,
        down: 10,
        right: 2
    }
    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 10, moveWeights, keys, rng)))

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (rowIndex !== 0 && rowIndex !== PANEL_WIDTH - 1 && columnIndex !== 0 && columnIndex !== PANEL_HEIGHT - 1) {
                const tile = layerData[columnIndex][rowIndex]
                const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index === tileMap[VINE_KEY] ? 1 : 0;
                const isEastSameTile = layerData[columnIndex][rowIndex + 1].index === tileMap[VINE_KEY] ? 1 : 0;
                const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index === tileMap[VINE_KEY] ? 1 : 0;
                const isWestSameTile = layerData[columnIndex][rowIndex - 1].index === tileMap[VINE_KEY] ? 1 : 0;

                const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

                if (neighboursCount === 0) {
                    tile.index = tileMap[EMPTY_KEY]
                }
            }
        })
    });


    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (rowIndex !== 0 && rowIndex !== PANEL_WIDTH - 1 && columnIndex !== 0 && columnIndex !== PANEL_HEIGHT - 1) {
                const tile = layerData[columnIndex][rowIndex]
                const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index !== tileMap[EMPTY_KEY] ? 1 : 0;
                const isEastSameTile = layerData[columnIndex][rowIndex + 1].index !== tileMap[EMPTY_KEY] ? 1 : 0;
                const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index !== tileMap[EMPTY_KEY] ? 1 : 0;
                const isWestSameTile = layerData[columnIndex][rowIndex - 1].index !== tileMap[EMPTY_KEY] ? 1 : 0;

                const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

                if (neighboursCount === 1 && tile.index === tileMap[VINE_KEY]) {
                    tile.index = tileMap[LEAF_KEY]
                }
            }
        })
    });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    drawNeighbours(layerData, `NEEDLEWOOD.THORN.${SEASON}.`, tileMap[`NEEDLEWOOD.THORN.${SEASON}.LEFT.RIGHT.`], { isDiagonalRelevent: false, startingIndexes: [tileMap[TRUNK_KEY], tileMap[LEAF_KEY]] });

    const GRASS_KEY = 'NEEDLEWOOD.GRASS';
    const GRASS_2_KEY = 'NEEDLEWOOD.GRASS.2'
    const GROUND_KEY = 'EMPTY'

    let noiseOne = drawNoise(1, 2, panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (!isEmptyIndex(tile)) {
                return
            }

            if (noiseOne[rowIndex][columnIndex] > 0.7) {
                tile.index = tileMap[GRASS_KEY];
            } else if (noiseOne[rowIndex][columnIndex] > 0.6) {
                tile.index = tileMap[GRASS_2_KEY];
            }
        })
    });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function drawMazeScrub(mockLayerData, layerData, panel) {
    const SEASON = ''; //'HARVEST.' or ''
    const TRUNK_KEY = `MAZE-SCRUB.TRUNK.${SEASON}`;
    const EMPTY_KEY = 'EMPTY';
    const VINE_KEY = `MAZE-SCRUB.LEAF.${SEASON}LEFT.RIGHT.`;
    const rng = seedrandom('tanglewoods' + panel.x + panel.y);
    const neighbours = getMatchingBiomeNeighbours(panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            tile.index = tileMap[EMPTY_KEY];
        })
    });

    let dots = drawDots(layerData, panel, TRUNK_KEY, 0.9);
    dots = dots.filter((dot) => (!panel.floorLayer.find(panel => (panel.x === dot.x && panel.y === dot.y))))

    const keys = {
        STARTING_KEY: TRUNK_KEY,
        WALK_KEY: VINE_KEY
    }

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    let moveWeights = {
        left: 10,
        up: 4,
        down: 6,
        right: 1
    }
    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 4, moveWeights, keys, rng, { isNotAllowedNearNeighbours: true, isDownForbiddenOnFirstStep: true, originalMoveWeightUp: 10 })))

    moveWeights = {
        left: 1,
        up: 4,
        down: 2,
        right: 10
    }
    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 4, moveWeights, keys, rng, { isNotAllowedNearNeighbours: true, isDownForbiddenOnFirstStep: true, originalMoveWeightUp: 10 })))

    moveWeights = {
        left: 2,
        up: 0,
        down: 5,
        right: 2
    }
    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 4, moveWeights, keys, rng, { isNotAllowedNearNeighbours: true })))

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (rowIndex !== 0 && rowIndex !== PANEL_WIDTH - 1 && columnIndex !== 0 && columnIndex !== PANEL_HEIGHT - 1) {
                const tile = layerData[columnIndex][rowIndex]
                const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index === tileMap[VINE_KEY] ? 1 : 0;
                const isEastSameTile = layerData[columnIndex][rowIndex + 1].index === tileMap[VINE_KEY] ? 1 : 0;
                const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index === tileMap[VINE_KEY] ? 1 : 0;
                const isWestSameTile = layerData[columnIndex][rowIndex - 1].index === tileMap[VINE_KEY] ? 1 : 0;

                const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

                if (neighboursCount === 0) {
                    tile.index = tileMap[EMPTY_KEY]
                }
            }
        })
    });

    drawNeighbours(layerData, `MAZE-SCRUB.LEAF.${SEASON}`, tileMap[`MAZE-SCRUB.LEAF.${SEASON}LEFT.RIGHT.`], { isDiagonalRelevent: false, connectOnBorder: true, startingIndexes: [tileMap[TRUNK_KEY]] });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function drawWoneyed(mockLayerData, layerData, panel) {
    const TRUNK_KEY = 'WONEYE.TRUNK';
    const EMPTY_KEY = 'EMPTY';
    const LEAF_KEY = 'WONEYE.EYE.LEFT.RIGHT.';
    const VINE_KEY = 'WONEYE.VINE.LEFT.RIGHT.';
    const rng = seedrandom('tanglewoods' + panel.x + panel.y);
    const neighbours = getMatchingBiomeNeighbours(panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            tile.index = tileMap[EMPTY_KEY];
        })
    });

    const GRASS_KEY = 'STEPPE.GRASS';
    const GROUND_KEY = 'EMPTY'

    let noiseOne = drawNoise(4, 6, panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (noiseOne[rowIndex][columnIndex] > 0.5) {
                tile.index = tileMap[GRASS_KEY];
            } else {
                tile.index = tileMap[GROUND_KEY];
            }
        })
    });

    let dots = drawDots(layerData, panel, TRUNK_KEY, 1.4);

    const keys = {
        STARTING_KEY: TRUNK_KEY,
        WALK_KEY: VINE_KEY
    }

    let moveWeights = {
        left: 10,
        up: 10,
        down: 2,
        right: 2
    }
    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 10, moveWeights, keys, rng)))

    moveWeights = {
        left: 10,
        up: 2,
        down: 10,
        right: 2
    }
    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 10, moveWeights, keys, rng)))

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (rowIndex !== 0 && rowIndex !== PANEL_WIDTH - 1 && columnIndex !== 0 && columnIndex !== PANEL_HEIGHT - 1) {
                const tile = layerData[columnIndex][rowIndex]
                const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index === tileMap[VINE_KEY] ? 1 : 0;
                const isEastSameTile = layerData[columnIndex][rowIndex + 1].index === tileMap[VINE_KEY] ? 1 : 0;
                const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index === tileMap[VINE_KEY] ? 1 : 0;
                const isWestSameTile = layerData[columnIndex][rowIndex - 1].index === tileMap[VINE_KEY] ? 1 : 0;

                const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

                if (neighboursCount === 0) {
                    tile.index = tileMap[EMPTY_KEY]
                }
            }
        })
    });


    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (rowIndex !== 0 && rowIndex !== PANEL_WIDTH - 1 && columnIndex !== 0 && columnIndex !== PANEL_HEIGHT - 1) {
                const tile = layerData[columnIndex][rowIndex]
                const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index !== tileMap[EMPTY_KEY] ? 1 : 0;
                const isEastSameTile = layerData[columnIndex][rowIndex + 1].index !== tileMap[EMPTY_KEY] ? 1 : 0;
                const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index !== tileMap[EMPTY_KEY] ? 1 : 0;
                const isWestSameTile = layerData[columnIndex][rowIndex - 1].index !== tileMap[EMPTY_KEY] ? 1 : 0;

                const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

                if (neighboursCount === 1 && tile.index === tileMap[VINE_KEY]) {
                    tile.index = tileMap[LEAF_KEY]
                }
            }
        })
    });
    
    clearRiverCliffAndOceanTiles(layerData, panel)

    drawNeighbours(layerData, 'WONEYE.VINE.', tileMap['WONEYE.VINE.LEFT.RIGHT.'], { isDiagonalRelevent: false, startingIndexes: [tileMap[TRUNK_KEY], tileMap[LEAF_KEY]] });
    drawNeighbours(layerData, 'WONEYE.EYE.', tileMap['WONEYE.EYE.LEFT.RIGHT.'], { isDiagonalRelevent: false, isAnyIndexNeighbour: true });
}

export function drawPuzzlebox(mockLayerData, layerData, panel) {
    const TRUNK_KEY = 'PUZZLEBOX.TRUNK';
    const BRANCH_KEY = 'PUZZLEBOX.BRANCH.LEFT.RIGHT.';
    const EMPTY_KEY = 'EMPTY';
    const LEAF_KEY = 'PUZZLEBOX.LEAF.LEFT.RIGHT.';
    const VINE_KEY = 'PUZZLEBOX.LEAF.LEFT.RIGHT.';
    const rng = seedrandom('tanglewoods' + panel.x + panel.y);
    const neighbours = getMatchingBiomeNeighbours(panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            tile.index = tileMap[EMPTY_KEY];
        })
    });

    const GRASS_KEY = 'SCRUB-GRASS';
    const GROUND_KEY = 'EMPTY'

    let noiseOne = drawNoise(1, 2, panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (noiseOne[rowIndex][columnIndex] > 0.65) {
                tile.index = tileMap[GRASS_KEY];
            } else {
                tile.index = tileMap[GROUND_KEY];
            }
        })
    });

    let dots = drawDots(layerData, panel, TRUNK_KEY, 1.4);

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    const keys = {
        STARTING_KEY: TRUNK_KEY,
        WALK_KEY: VINE_KEY
    }

    let moveWeights = {
        left: 10,
        up: 10,
        down: 10,
        right: 10
    }

    keys.WALK_KEY = BRANCH_KEY;

    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 1, moveWeights, keys, rng)))
    drawNeighbours(layerData, 'PUZZLEBOX.BRANCH.', tileMap['PUZZLEBOX.BRANCH.LEFT.RIGHT.'], { isDiagonalRelevent: false, startingIndexes: [
        tileMap[TRUNK_KEY],
    ] });

    keys.WALK_KEY = VINE_KEY;

    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 2, moveWeights, keys, rng)))

    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 2, moveWeights, keys, rng)))

    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 2, moveWeights, keys, rng)))

    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 2, moveWeights, keys, rng)))

    drawNeighbours(layerData, 'PUZZLEBOX.LEAF.', tileMap['PUZZLEBOX.LEAF.LEFT.RIGHT.'], { isDiagonalRelevent: false, startingIndexes: [
        tileMap[TRUNK_KEY],
        tileMap['PUZZLEBOX.BRANCH.LEFT'],
        tileMap['PUZZLEBOX.BRANCH.RIGHT'],
        tileMap['PUZZLEBOX.BRANCH.TOP'],
        tileMap['PUZZLEBOX.BRANCH.BOTTOM'],
    ] });

    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            const { left, right, top, bottom } = getNeighbours(layerData, x, y);

            const isLeftTrunk = left?.index === tileMap['PUZZLEBOX.TRUNK']
            const isRightTrunk = right?.index === tileMap['PUZZLEBOX.TRUNK']
            const isTopTrunk = top?.index === tileMap['PUZZLEBOX.TRUNK']
            const isBottomTrunk = bottom?.index === tileMap['PUZZLEBOX.TRUNK']

            // left

            if (tile.index === tileMap['PUZZLEBOX.LEAF.RIGHT.TOP.'] && isRightTrunk) {
                tile.index = tileMap['PUZZLEBOX.LEAF.RIGHT.TOP.BOTTOM.']
            }

            if (tile.index === tileMap['PUZZLEBOX.LEAF.RIGHT.BOTTOM.'] && isRightTrunk) {
                tile.index = tileMap['PUZZLEBOX.LEAF.RIGHT.TOP.BOTTOM.']
            }

            if (tile.index === tileMap['PUZZLEBOX.LEAF.LEFT.RIGHT.TOP.'] && isRightTrunk) {
                tile.index = tileMap['PUZZLEBOX.LEAF.LEFT.RIGHT.TOP.BOTTOM.']
            }

            if (tile.index === tileMap['PUZZLEBOX.LEAF.LEFT.RIGHT.BOTTOM.'] && isRightTrunk) {
                tile.index = tileMap['PUZZLEBOX.LEAF.LEFT.RIGHT.TOP.BOTTOM.']
            }

            // right

            if (tile.index === tileMap['PUZZLEBOX.LEAF.LEFT.TOP.'] && isLeftTrunk) {
                tile.index = tileMap['PUZZLEBOX.LEAF.LEFT.TOP.BOTTOM.']
            }

            if (tile.index === tileMap['PUZZLEBOX.LEAF.LEFT.BOTTOM.'] && isLeftTrunk) {
                tile.index = tileMap['PUZZLEBOX.LEAF.LEFT.TOP.BOTTOM.']
            }

            if (tile.index === tileMap['PUZZLEBOX.LEAF.LEFT.RIGHT.TOP.'] && isLeftTrunk) {
                tile.index = tileMap['PUZZLEBOX.LEAF.LEFT.RIGHT.TOP.BOTTOM.']
            }

            if (tile.index === tileMap['PUZZLEBOX.LEAF.LEFT.RIGHT.BOTTOM.'] && isLeftTrunk) {
                tile.index = tileMap['PUZZLEBOX.LEAF.LEFT.RIGHT.TOP.BOTTOM.']
            }

            // bottom

            if (tile.index === tileMap['PUZZLEBOX.LEAF.LEFT.TOP.'] && isTopTrunk) {
                tile.index = tileMap['PUZZLEBOX.LEAF.LEFT.RIGHT.TOP.']
            }

            if (tile.index === tileMap['PUZZLEBOX.LEAF.RIGHT.TOP.'] && isTopTrunk) {
                tile.index = tileMap['PUZZLEBOX.LEAF.LEFT.RIGHT.TOP.']
            }

            if (tile.index === tileMap['PUZZLEBOX.LEAF.LEFT.TOP.BOTTOM.'] && isTopTrunk) {
                tile.index = tileMap['PUZZLEBOX.LEAF.LEFT.RIGHT.TOP.BOTTOM.']
            }

            if (tile.index === tileMap['PUZZLEBOX.LEAF.RIGHT.TOP.BOTTOM.'] && isTopTrunk) {
                tile.index = tileMap['PUZZLEBOX.LEAF.LEFT.RIGHT.TOP.BOTTOM.']
            }

            // top

            if (tile.index === tileMap['PUZZLEBOX.LEAF.LEFT.BOTTOM.'] && isBottomTrunk) {
                tile.index = tileMap['PUZZLEBOX.LEAF.LEFT.RIGHT.BOTTOM.']
            }

            if (tile.index === tileMap['PUZZLEBOX.LEAF.RIGHT.BOTTOM.'] && isBottomTrunk) {
                tile.index = tileMap['PUZZLEBOX.LEAF.LEFT.RIGHT.BOTTOM.']
            }

            if (tile.index === tileMap['PUZZLEBOX.LEAF.LEFT.TOP.BOTTOM.'] && isBottomTrunk) {
                tile.index = tileMap['PUZZLEBOX.LEAF.LEFT.RIGHT.TOP.BOTTOM.']
            }

            if (tile.index === tileMap['PUZZLEBOX.LEAF.RIGHT.TOP.BOTTOM.'] && isBottomTrunk) {
                tile.index = tileMap['PUZZLEBOX.LEAF.LEFT.RIGHT.TOP.BOTTOM.']
            }
        })
    });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function drawPolarWastes(mockLayerData, layerData, panel) {
    const SNOW_KEY = 'POLAR.SNOW.LEFT.RIGHT.';
    const ICE_KEY = 'POLAR.ICE.LEFT.RIGHT.';
    const EMPTY_KEY = 'EMPTY';
    const VINE_KEY = 'POLAR.SNOW.LEFT.RIGHT.';
    const rng = seedrandom('tanglewoods' + panel.x + panel.y);
    const neighbours = getMatchingBiomeNeighbours(panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            tile.index = tileMap[EMPTY_KEY];
        })
    });

    let dots = drawDots(layerData, panel, SNOW_KEY, 1.58);

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    const keys = {
        STARTING_KEY: SNOW_KEY,
        WALK_KEY: VINE_KEY
    }

    let moveWeights = {
        left: 10,
        up: 10,
        down: 2,
        right: 2
    }
    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 20, moveWeights, keys, rng)))

    moveWeights = {
        left: 10,
        up: 2,
        down: 10,
        right: 2
    }
    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 20, moveWeights, keys, rng)))

    //now, we put ice to the right or top of all snow...
    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (!isEmptyIndex(tile)) {
                return;
            }

            const {right, left, top, bottom} = getNeighbours(layerData, x, y);

            const isRightSnow = right?.index === tileMap[SNOW_KEY]
            const isLeftSnow = left?.index === tileMap[SNOW_KEY]
            const isTopSnow = top?.index === tileMap[SNOW_KEY]
            const isBottomSnow = bottom?.index === tileMap[SNOW_KEY]

            if (!isBottomSnow && isLeftSnow && !isRightSnow && !isTopSnow) {
                tile.index = tileMap['POLAR.ICE.TOP.BOTTOM.']
            }

            if (isBottomSnow && isLeftSnow && !isRightSnow && !isTopSnow && top?.index === tileMap['POLAR.ICE.TOP.BOTTOM.']) {
                tile.index = tileMap['POLAR.ICE.RIGHT.TOP.']
            }

            if (isBottomSnow && !isLeftSnow && !isRightSnow && !isTopSnow && (left?.index === tileMap['POLAR.ICE.RIGHT.TOP.'] || left?.index === tileMap['POLAR.ICE.LEFT.RIGHT.'])) {
                tile.index = tileMap['POLAR.ICE.LEFT.RIGHT.']
            }
        })
    });

    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (!isEmptyIndex(tile)) {
                return;
            }

            const {right, left, top, bottom} = getNeighbours(layerData, x, y);

            const isRightSnow = right?.index === tileMap[SNOW_KEY]
            const isLeftSnow = left?.index === tileMap[SNOW_KEY]
            const isTopSnow = top?.index === tileMap[SNOW_KEY]
            const isBottomSnow = bottom?.index === tileMap[SNOW_KEY]

            if (!isBottomSnow && isLeftSnow && !isRightSnow && isTopSnow && (bottom?.index === tileMap['POLAR.ICE.TOP.BOTTOM.'])) {
                tile.index = tileMap['POLAR.ICE.RIGHT.BOTTOM.']
            }

            if (!isBottomSnow && !isLeftSnow && !isRightSnow && !isTopSnow && (left?.index === tileMap['POLAR.ICE.RIGHT.BOTTOM.']) && top?.index === tileMap['POLAR.ICE.TOP.BOTTOM.']) {
                tile.index = tileMap['POLAR.ICE.LEFT.TOP.TL-GAP']
            }

            if (!isBottomSnow && !isLeftSnow && !isRightSnow && isTopSnow && (left?.index === tileMap['POLAR.ICE.RIGHT.BOTTOM.'])) {
                tile.index = tileMap['POLAR.ICE.LEFT.RIGHT.TOP.TR-TL-GAP']
            }

            if (!isBottomSnow && !isLeftSnow && !isRightSnow && !isTopSnow && (left?.index === tileMap['POLAR.ICE.LEFT.RIGHT.'] && bottom?.index === tileMap['POLAR.ICE.TOP.BOTTOM.'])) {
                tile.index = tileMap['POLAR.ICE.LEFT.BOTTOM.']
            }

            if (!isBottomSnow && !isLeftSnow && !isRightSnow && !isTopSnow && (left?.index === tileMap['POLAR.ICE.LEFT.RIGHT.TOP.TR-TL-GAP'] && top?.index === tileMap['POLAR.ICE.TOP.BOTTOM.'])) {
                tile.index = tileMap['POLAR.ICE.LEFT.TOP.TL-GAP']
            }

            if (!isBottomSnow && !isLeftSnow && !isRightSnow && isTopSnow && (left?.index === tileMap['POLAR.ICE.LEFT.RIGHT.TOP.TR-TL-GAP'])) {
                tile.index = tileMap['POLAR.ICE.LEFT.RIGHT.TOP.TR-TL-GAP']
            }
        })
    });

    drawNeighbours(layerData, 'POLAR.SNOW.', tileMap['POLAR.SNOW.LEFT.RIGHT.'], { isDiagonalRelevent: false, startingIndexes: [
        tileMap['POLAR.ICE.TOP.BOTTOM.'],
        tileMap['POLAR.ICE.RIGHT.TOP.'],
        tileMap['POLAR.ICE.RIGHT.BOTTOM.'],
        tileMap['POLAR.ICE.LEFT.RIGHT.'],
        tileMap['POLAR.ICE.LEFT.RIGHT.TOP.TR-TL-GAP']
    ] });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)
}

export function drawSwampyForest(mockLayerData, layerData, panel, rng) {
    rng = seedrandom('biome' + panel.x + panel.y);

    const neighbours = getMatchingBiomeNeighbours(panel);

    const isNorthNeighbour = neighbours.indexOf('north') !== -1;
    const isEastNeighbour = neighbours.indexOf('east') !== -1;
    const isSouthNeighbour = neighbours.indexOf('south') !== -1;
    const isWestNeighbour = neighbours.indexOf('west') !== -1;

    const _neighbours = {
        isNorthNeighbour,
        isEastNeighbour,
        isSouthNeighbour,
        isWestNeighbour,
    }

    const panelSeed2 = seedrandom('SWAMP-2' + (panel.x) + (panel.y));
    const northPanelSeed2 = seedrandom('SWAMP-2' + (panel.x) + (panel.y - 1));
    const westPanelSeed2 = seedrandom('SWAMP-2' + (panel.x - 1) + (panel.y));

    const xZero2 = Math.floor(randomNumberBetween((PANEL_HEIGHT/2) - 4, westPanelSeed2) + 2);
    const xEnd2 = Math.floor(randomNumberBetween((PANEL_HEIGHT/2) - 4, seedrandom('SWAMP-2' + (panel.x) + (panel.y))) + 2);

    const yZero2 = Math.floor(randomNumberBetween((PANEL_WIDTH/2) - 4, northPanelSeed2) + 2);
    const yEnd2 = Math.floor(randomNumberBetween((PANEL_WIDTH/2) - 4, seedrandom('SWAMP-2' + (panel.x) + (panel.y))) + 2);

    const crossCoords2 = { x: randomNumberBetween((PANEL_WIDTH/2) - 4, panelSeed2) + 2, y: randomNumberBetween((PANEL_HEIGHT) - 4, panelSeed2) + 2 }

    drawRivers(layerData, crossCoords2, xZero2, xEnd2, yZero2, yEnd2, _neighbours, rng, panel)

    const panelSeed = seedrandom('SWAMP-1' + (panel.x) + (panel.y));
    const northPanelSeed = seedrandom('SWAMP-1' + (panel.x) + (panel.y - 1));
    const westPanelSeed = seedrandom('SWAMP-1' + (panel.x - 1) + (panel.y));

    const xZero = Math.floor(randomNumberBetween((PANEL_HEIGHT/2) - 4, westPanelSeed) + (PANEL_HEIGHT/2) + 2);
    const xEnd = Math.floor(randomNumberBetween((PANEL_HEIGHT/2) - 4, seedrandom('SWAMP-1' + (panel.x) + (panel.y))) + (PANEL_HEIGHT/2) + 2);

    const yZero = Math.floor(randomNumberBetween((PANEL_WIDTH/2) - 4, northPanelSeed) + (PANEL_WIDTH/2) + 2);
    const yEnd = Math.floor(randomNumberBetween((PANEL_WIDTH/2) - 4, seedrandom('SWAMP-1' + (panel.x) + (panel.y))) + (PANEL_WIDTH/2) + 2);

    const crossCoords = { x: randomNumberBetween((PANEL_WIDTH/2) - 4, panelSeed) + (PANEL_WIDTH/2) + 2, y: randomNumberBetween((PANEL_HEIGHT) - 4, panelSeed) + 2 }

    drawRivers(layerData, crossCoords, xZero, xEnd, yZero, yEnd, _neighbours, rng, panel)

    drawNeighbours(layerData, 'SWAMP.WATER.', tileMap['SWAMP.WATER.LEFT.RIGHT.'], { isDiagonalRelevent: false, connectOnBorder: true });
}

export function drawThistlewoodForest(mockLayerData, layerData, panel) {
    const season = '';

    const TRUNK_KEY = 'THISTLEWOOD.TRUNK.BOTTOM.';
    const EMPTY_KEY = 'EMPTY';
    const LEAF_KEY = `THISTLEWOOD.LEAF.${season}LEFT.RIGHT.`;
    const VINE_KEY = `THISTLEWOOD.BRANCH.${season}LEFT.`

    const keys = {
        STARTING_KEY: TRUNK_KEY,
        WALK_KEY: VINE_KEY,
        EMPTY_KEY
    }

    const neighbours = getMatchingBiomeNeighbours(panel);
    const rng = seedrandom('thistlewood' + panel.x + panel.y);

    let dots = drawDots(layerData, panel, TRUNK_KEY, 1.3);
    dots = cleanDifferentNeighbouringDots(dots, neighbours);

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (tile?.index !== tileMap[TRUNK_KEY]) {
                return;
            }

            const { left, right, top, bottom } = getNeighbours(layerData, x, y)
            const { bottomLeft, bottomRight, topLeft, topRight } = getDiagonalNeighbours(layerData, x, y)

            if (left && rng() < 0.25 && isEmptyIndex(left)) {
                left.index = tileMap[`THISTLEWOOD.BRANCH.${season}RIGHT.`]
            }

            if (right && rng() < 0.25 && isEmptyIndex(right)) {
                right.index = tileMap[`THISTLEWOOD.BRANCH.${season}LEFT.`]
            }

            if (top && rng() < 0.25 && isEmptyIndex(top)) {
                top.index = tileMap[`THISTLEWOOD.BRANCH.${season}BOTTOM.`]
            }

            if (bottom && rng() < 0.25 && isEmptyIndex(bottom)) {
                bottom.index = tileMap[`THISTLEWOOD.BRANCH.${season}TOP.`]
            }

            if (topRight && !isEmptyIndex(right) && !isEmptyIndex(top) && rng() < 0.25) {
                topRight.index = tileMap[`THISTLEWOOD.LEAF.${season}LEFT.BOTTOM.`]
            }

            if (topLeft && !isEmptyIndex(left) && !isEmptyIndex(top) && rng() < 0.25) {
                topLeft.index = tileMap[`THISTLEWOOD.LEAF.${season}RIGHT.BOTTOM.`]
            }

            if (bottomRight && !isEmptyIndex(right) && !isEmptyIndex(bottom) && rng() < 0.25) {
                bottomRight.index = tileMap[`THISTLEWOOD.LEAF.${season}LEFT.TOP.`]
            }

            if (bottomLeft && !isEmptyIndex(left) && !isEmptyIndex(bottom) && rng() < 0.25) {
                bottomLeft.index = tileMap[`THISTLEWOOD.LEAF.${season}RIGHT.TOP.`]
            }

            if (topRight && isEmptyIndex(right) && isEmptyIndex(top) && rng() < 0.25) {
                topRight.index = tileMap[`THISTLEWOOD.BRANCH.${season}NONE.BL-TRUNK`]
            }

            if (topLeft && isEmptyIndex(left) && isEmptyIndex(top) && rng() < 0.25) {
                topLeft.index = tileMap[`THISTLEWOOD.BRANCH.${season}NONE.BR-TRUNK`]
            }

            if (bottomRight && isEmptyIndex(right) && isEmptyIndex(bottom) && rng() < 0.25) {
                bottomRight.index = tileMap[`THISTLEWOOD.BRANCH.${season}NONE.TL-TRUNK`]
            }

            if (bottomLeft && isEmptyIndex(left) && isEmptyIndex(bottom) && rng() < 0.25) {
                bottomLeft.index = tileMap[`THISTLEWOOD.BRANCH.${season}NONE.TR-TRUNK`]
            }
        })
    });

    const FLOWER_KEY = 'KANGAROO-PAW';
    const THORN_KEY = 'BERMUDA-GRASS';
    const GROUND_KEY = 'EMPTY'

    let noiseOne = drawNoise(4, 5, panel);

    let noiseTwo = drawNoise(4, 5, { ...panel, x: panel.x + 1 });

    const _mockLayerData = getMockLayer();

    _mockLayerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (noiseOne[rowIndex][columnIndex] > 0.55) {
                tile.index = tileMap[FLOWER_KEY];
            }
        })
    });

    _mockLayerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (noiseTwo[rowIndex][columnIndex] > 0.6) {
                tile.index = tileMap[THORN_KEY];
            }
        })
    });

    limitToCheckerboxPattern(_mockLayerData, panel, GROUND_KEY)

    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (!isEmptyIndex(_mockLayerData[y][x]) && isEmptyIndex(tile)) {
                tile.index = _mockLayerData[y][x].index
            }
        })
    });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)
}

function drawRivers(layerData, crossCoords, xZero, xEnd, yZero, yEnd, neighbours, rng, panel) {
    const WATER_KEY = 'SWAMP.WATER.LEFT.RIGHT.';

    const northIsBorderPanelSeed = seedrandom('SWAMP-BORDER_SOUTH' + (panel.x) + (panel.y - 1));
    const southIsBorderSeed = seedrandom('SWAMP-BORDER_SOUTH' + (panel.x) + (panel.y));
    const eastIsBorderSeed = seedrandom('SWAMP-BORDER_EAST' + (panel.x) + (panel.y));
    const westIsBorderSeed = seedrandom('SWAMP-BORDER_EAST' + (panel.x - 1) + (panel.y));

    let widestRiver = 2;
    const roll = rng();

    if (roll < 0.3) {
        widestRiver = 3
    } else if (roll < 0.6) {
        widestRiver = 4;
    }

    if (neighbours.isWestNeighbour && westIsBorderSeed() < 0.75) {
        const fillInX = true;
        const points = generateCurve([0, xZero, crossCoords.x, crossCoords.y], fillInX);

        layerData[xZero][0].index = tileMap[WATER_KEY];

        for (var x=0; x < widestRiver; x++) {
            let _x = 0;

            if (x % 2 === 0) {
                _x += Math.round(x / 2);
            } else {
                _x -= Math.round(x / 2);
            }

            for(let i=0; i < points.length-1; i+=2) {
                const x = Math.round(points[i])
                const y = Math.round(points[i+1] + _x)

                if (layerData[y] && layerData[y][x]) {
                    layerData[y][x].index = tileMap[WATER_KEY];
                }
            }
        }
    }

    if (neighbours.isNorthNeighbour && northIsBorderPanelSeed() < 0.75) {
        const fillInX = false;
        const points = generateCurve([yZero, 0, crossCoords.x, crossCoords.y], fillInX);

        layerData[0][yZero].index = tileMap[WATER_KEY];

        for (var y=0; y < widestRiver; y++) {
            let _y = 0;

            if (y % 2 === 0) {
                _y += Math.round(y / 2);
            } else {
                _y -= Math.round(y / 2);
            }

            for(let i=0; i < points.length-1; i+=2) {
                const x = Math.round(points[i] + _y)
                const y = Math.round(points[i+1])

                if (layerData[y] && layerData[y][x]) {
                    layerData[y][x].index = tileMap[WATER_KEY];
                }
            }
        }
    }

    if (neighbours.isEastNeighbour && eastIsBorderSeed() < 0.75) {
        const fillInX = true;
        const points = generateCurve([crossCoords.x, crossCoords.y, PANEL_WIDTH, xEnd], fillInX);

        layerData[xEnd][PANEL_WIDTH - 1].index = tileMap[WATER_KEY];

        for (var x=0; x < widestRiver; x++) {
            let _x = 0;

            if (x % 2 === 0) {
                _x += Math.round(x / 2);
            } else {
                _x -= Math.round(x / 2);
            }

            for(let i=0; i < points.length-1; i+=2) {
                const x = Math.round(points[i])
                const y = Math.round(points[i+1] + _x)

                if (layerData[y] && layerData[y][x]) {
                    layerData[y][x].index = tileMap[WATER_KEY];
                }
            }
        }
    }

    if (neighbours.isSouthNeighbour && southIsBorderSeed() < 0.75) {
        const fillInX = false;
        const points = generateCurve([crossCoords.x, crossCoords.y, yEnd, PANEL_HEIGHT], fillInX);

        layerData[PANEL_HEIGHT - 1][yEnd].index = tileMap[WATER_KEY];

        for (var y=0; y < widestRiver; y++) {
            let _y = 0;

            if (y % 2 === 0) {
                _y += Math.round(y / 2);
            } else {
                _y -= Math.round(y / 2);
            }

            for(let i=0; i < points.length-1; i+=2) {
                const x = Math.round(points[i] + _y)
                const y = Math.round(points[i+1])

                if (layerData[y] && layerData[y][x]) {
                    layerData[y][x].index = tileMap[WATER_KEY];
                }
            }
        }
    }
}

export function drawIcyWastes(mockLayerData, layerData, panel) {
    const TRUNK_KEY = 'ICY-WASTE.NONE.';
    const EMPTY_KEY = 'EMPTY';
    const VINE_KEY = 'ICY-WASTE.LEFT.RIGHT.';
    const rng = seedrandom('tanglewoods' + panel.x + panel.y);
    const neighbours = getMatchingBiomeNeighbours(panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            tile.index = tileMap[EMPTY_KEY];
        })
    });

    let dots = drawDots(layerData, panel, TRUNK_KEY, 1.55);

    const keys = {
        STARTING_KEY: TRUNK_KEY,
        WALK_KEY: VINE_KEY
    }

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    let moveWeights = {
        left: 10,
        up: 10,
        down: 2,
        right: 2
    }
    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 10, moveWeights, keys, rng)))

    moveWeights = {
        left: 10,
        up: 2,
        down: 10,
        right: 2
    }
    dots.forEach(startingPosition => (randomWalk(layerData, startingPosition, 10, moveWeights, keys, rng)))

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (rowIndex !== 0 && rowIndex !== PANEL_WIDTH - 1 && columnIndex !== 0 && columnIndex !== PANEL_HEIGHT - 1) {
                const tile = layerData[columnIndex][rowIndex]
                const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index === tileMap[VINE_KEY] ? 1 : 0;
                const isEastSameTile = layerData[columnIndex][rowIndex + 1].index === tileMap[VINE_KEY] ? 1 : 0;
                const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index === tileMap[VINE_KEY] ? 1 : 0;
                const isWestSameTile = layerData[columnIndex][rowIndex - 1].index === tileMap[VINE_KEY] ? 1 : 0;

                const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

                if (neighboursCount === 0) {
                    tile.index = tileMap[EMPTY_KEY]
                }
            }
        })
    });

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (!isEmptyIndex(tile)) {
                if (rng() < 0.3) {
                    tile.index = tileMap[EMPTY_KEY]
                }
            }
        })
    });

    drawNeighbours(layerData, 'ICY-WASTE.', tileMap['ICY-WASTE.LEFT.RIGHT.'], { isDiagonalRelevent: false, startingIndexes: [tileMap[TRUNK_KEY]] });

    const GRASS_KEY = 'ICY-WASTE.ROCKS';
    const GROUND_KEY = 'EMPTY'

    let noiseOne = drawNoise(1, 2, panel);

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (!isEmptyIndex(tile)) {
                return;
            }
            if (noiseOne[rowIndex][columnIndex] > 0.7) {
                tile.index = tileMap[GRASS_KEY];
            } else {
                tile.index = tileMap[GROUND_KEY];
            }
        })
    });

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)
}

function getWeightedValue(data, rng) {
    let total = 0;
    for (let id in data) {
        total += data[id];
    }
    let random = rng() * total;
    let id, part = 0;
    for (id in data) {
        part += data[id];
        if (random < part) {
            return id;
        }
    }
    // If by some floating-point annoyance we have
    // random >= total, just return the last id.
    return id;
}

function randomWalk(layerData, startingPosition, numberOfSteps, moveWeights, keys, rng, options) {
    let currentSteps = 0;
    let currentPosition = { ...startingPosition };

    let possibleMoves = [{ x: 0, y: -1, direction: 'down' }, { x: 0, y: 1, direction: 'up' }, { x: -1, y: 0, direction: 'left' }, { x: 1, y: 0, direction: 'right' }];

    while (currentSteps < numberOfSteps) {
        let originalMoveWeights = { ...moveWeights }

        if (options?.isDownForbiddenOnFirstStep && currentSteps === 0) {
            moveWeights.up = 0;
        } else {
            moveWeights.up = options?.originalMoveWeightUp ? options.originalMoveWeightUp : moveWeights.up;
        }

        const newDirection = getWeightedValue(moveWeights, rng);
        const newMove = possibleMoves.find(move => (move.direction === newDirection));
        currentPosition = { x: currentPosition.x + newMove.x, y: currentPosition.y + newMove.y };

        if (options?.isNotAllowedNearNeighbours) {
            if (layerData[currentPosition.y] && layerData[currentPosition.y][currentPosition.x]) {
                const tile = layerData[currentPosition.y][currentPosition.x]

                let neighboursCount = countNeighbours(layerData, tileMap[keys.WALK_KEY], currentPosition?.x, currentPosition?.y);
                neighboursCount += countNeighbours(layerData, tileMap[keys.STARTING_KEY], currentPosition?.x, currentPosition?.y);

                if (neighboursCount > 1) {
                    currentSteps++;
                    break;
                }
            }
        }

        if (currentPosition.y < 0 || currentPosition.y > PANEL_HEIGHT - 1) {
            currentSteps++;
            break;
        }

        if (currentPosition.x < 0 || currentPosition.x > PANEL_WIDTH - 1) {
            currentSteps++;
            break;
        }

        if (currentPosition.x === startingPosition.x && currentPosition.y === startingPosition.y) {
            currentSteps++;
            break;
        }

        if (layerData[currentPosition.y][currentPosition.x].index === tileMap[keys.STARTING_KEY]) {
            currentSteps++;
            break;
        }


        if (currentPosition.x !== 0 && currentPosition.x !== PANEL_WIDTH - 1 && currentPosition.y !== 0 && currentPosition.y !== PANEL_HEIGHT - 1) {
            const tile = layerData[currentPosition.y][currentPosition.x]
            const isNorthSameTile = layerData[currentPosition.y - 1][currentPosition.x].index === tileMap[keys.WALK_KEY] ? 1 : 0;
            const isEastSameTile = layerData[currentPosition.y][currentPosition.x + 1].index === tileMap[keys.WALK_KEY] ? 1 : 0;
            const isSouthSameTile = layerData[currentPosition.y + 1][currentPosition.x].index === tileMap[keys.WALK_KEY] ? 1 : 0;
            const isWestSameTile = layerData[currentPosition.y][currentPosition.x - 1].index === tileMap[keys.WALK_KEY] ? 1 : 0;

            const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

            if (neighboursCount > 1) {
                currentSteps++;
                break;
            }
        }

        if (options?.skipNotEmptyTiles) {
            if (isEmptyIndex(layerData[currentPosition.y][currentPosition.x])) {
                layerData[currentPosition.y][currentPosition.x].index = tileMap[keys.WALK_KEY]
            }
        } else {
            layerData[currentPosition.y][currentPosition.x].index = tileMap[keys.WALK_KEY]
        }


        currentSteps++;
    }
}

export function drawMansion(mockLayerData, layerData, panel, rng) {
    const keys = {
        TRUNK_KEY: 'FUR.LEFT.RIGHT.BOTTOM.',
        GROUND_KEY: 'EMPTY',
        LEAF_KEY: 'MINE-WALL.LEFT.RIGHT.',
        FLOOR_KEY: 'Marble Floor',
        DOOR_KEY: 'Studded Wooden Door.POLAR',
        STAIRCASE_DOWN: 'Staircase down',
        STAIRCASE_UP: 'Staircase'
    }

    const skyStampShape = [
        // { x: 0, y: 0 },
        { x: 0, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 0, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 3, index: tileMap[keys.STAIRCASE_DOWN] },
        { x: 2, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 3, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 0, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 4, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 5, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 6, index: tileMap[keys.LEAF_KEY] },

        { x: 0, y: 7, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 7, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 7, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 7, index: tileMap[keys.LEAF_KEY] },
    ];

    const skyStampShape1 = [
        // { x: 0, y: 0 },
        { x: 0, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 1, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 2, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 0, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 3, index: tileMap[keys.STAIRCASE_DOWN] },
        { x: 3, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 6, y: 3, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 0, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 6, y: 4, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 5, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 6, index: tileMap[keys.LEAF_KEY] },

        { x: 0, y: 7, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 7, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 7, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 7, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 7, index: tileMap[keys.LEAF_KEY] },
    ];

    const skyStampShape2 = [
        // { x: 0, y: 0 },
        { x: 0, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 1, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 2, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 0, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 6, y: 3, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 0, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 4, index: tileMap[keys.STAIRCASE_DOWN] },
        { x: 3, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 6, y: 4, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 0, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 6, y: 5, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 6, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 7, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 7, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 7, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 7, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 7, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 7, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 7, index: tileMap[keys.LEAF_KEY] },

        { x: 0, y: 8, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 8, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 8, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 8, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 8, index: tileMap[keys.LEAF_KEY] },
    ];

    const skyStampShape3 = [
        // { x: 0, y: 0 },
        { x: 0, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 1, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 6, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 7, y: 2, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 0, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 6, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 7, y: 3, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 0, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 4, index: tileMap[keys.STAIRCASE_DOWN] },
        { x: 3, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 6, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 7, y: 4, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 0, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 6, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 7, y: 5, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 0, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 6, y: 6, index: tileMap[keys.FLOOR_KEY] },
        { x: 7, y: 6, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 7, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 7, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 7, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 7, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 7, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 7, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 7, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 7, index: tileMap[keys.FLOOR_KEY] },
        { x: 6, y: 7, index: tileMap[keys.LEAF_KEY] },
        { x: 7, y: 7, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 8, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 8, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 8, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 8, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 8, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 8, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 8, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 8, index: tileMap[keys.LEAF_KEY] },

        { x: 0, y: 9, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 9, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 9, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 9, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 9, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 9, index: tileMap[keys.LEAF_KEY] },
    ];

    const floorStampShape = [
        { x: 1, y: 0, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 3, index: tileMap[keys.STAIRCASE_UP] },
        { x: 2, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 5, index: tileMap[keys.DOOR_KEY] },
        { x: 2, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 5, index: tileMap[keys.LEAF_KEY] },
    ];

    const stampShape1 = [
        { x: 1, y: 0, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 3, index: tileMap[keys.STAIRCASE_UP] },
        { x: 2, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 5, index: tileMap[keys.DOOR_KEY] },
        { x: 2, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 5, index: tileMap[keys.LEAF_KEY] },
    ];

    const stampShape2 = [
        { x: 1, y: 1, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 4, index: tileMap[keys.STAIRCASE_UP] },
        { x: 2, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 4, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 5, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 6, index: tileMap[keys.DOOR_KEY] },
        { x: 2, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 6, index: tileMap[keys.LEAF_KEY] },
    ];

    const stampShape3 = [
        { x: 1, y: 1, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY] },

        { x: -2, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: -1, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 4, index: tileMap[keys.STAIRCASE_UP] },
        { x: 2, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 4, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 1, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 5, index: tileMap[keys.LEAF_KEY] },

        { x: -1, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 6, index: tileMap[keys.DOOR_KEY] },
        { x: 2, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 6, index: tileMap[keys.LEAF_KEY] },
    ];

    const trunkCoords2 = drawTreeDots(mockLayerData, layerData, panel, [
        skyStampShape,
        skyStampShape1,
        skyStampShape2,
        skyStampShape3,

        // floorStampShape,
        // stampShape1,
        // stampShape2,
        // stampShape3,
    ], 1, false, [], keys, rng);

    // const trunkCoords = drawTreeDots(mockLayerData, layerData, panel, [
    // ], 1, false, [], keys, rng);

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    // const missingTileProbability = 0.1

    // layerData.forEach((row, columnIndex) => {
    //     row.forEach((tile, rowIndex) => {
    //         if (rng() < missingTileProbability) {
    //             tile.index = 0;
    //         }
    //     })
    // });

    drawNeighbours(layerData, 'MINE-WALL.', tileMap[keys.LEAF_KEY], { isDiagonalRelevent: true });

    return mockLayerData;
}

function drawRectangle(x, y, width, height, layerData, keys) {
    if (x + width >= PANEL_WIDTH || y + height >= PANEL_HEIGHT) {
        return;
    }

    const blockingTiles = Object.values(keys).map(value => (tileMap[value]))

    for (var _y=y; _y < y+height; _y++) {
        for (var _x=x; _x < x+width; _x++) {
            if (layerData[_y] && layerData[_y][_x]){
                if (blockingTiles.indexOf(layerData[_y][_x].index) > -1) {
                    continue;
                }

                if (y === _y || _y === (y + height - 1)) {
                    layerData[_y][_x].index = tileMap[keys.LEAF_KEY]
                    continue;
                }

                if (x === _x || _x === (x + width - 1)) {
                    layerData[_y][_x].index = tileMap[keys.LEAF_KEY]
                    continue;
                }

                layerData[_y][_x].index = tileMap[keys.FLOOR_KEY]
            }
        }
    }
}

export function drawHamlet(mockLayerData, layerData, panel, rng) {
    const LINGONBERRY_KEY = 'LINGONBERRY.HARVEST';
    const CLOUDBERRY_KEY = 'CLOUDBERRY.HARVEST';

    const neighbours = getMatchingBiomeNeighbours(panel);

    const keys = {
        TRUNK_KEY: 'FUR.LEFT.RIGHT.BOTTOM.',
        GROUND_KEY: 'EMPTY',
        LEAF_KEY: 'MINE-WALL.LEFT.RIGHT.',
        FLOOR_KEY: 'Marble Floor',
        DOOR_KEY: 'Studded Wooden Door.POLAR'
    }

    const stampShape = [
        // { x: 0, y: 0 },
        { x: 0, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 0, index: tileMap[keys.DOOR_KEY] },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 0, index: tileMap[keys.LEAF_KEY] },

        { x: 1, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 1, index: tileMap[keys.FLOOR_KEY] },

        { x: 1, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 2, index: tileMap[keys.FLOOR_KEY] },

        { x: 1, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 3, index: tileMap[keys.FLOOR_KEY] },

        { x: 1, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 4, index: tileMap[keys.FLOOR_KEY] },

        { x: 1, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 5, index: tileMap[keys.FLOOR_KEY] },

        { x: 0, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 6, index: tileMap[keys.LEAF_KEY] },

        { x: 1, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 6, index: tileMap[keys.LEAF_KEY] },

        { x: 3, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY] },

        { x: 4, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 4, index: tileMap[keys.LEAF_KEY] },

        { x: 6, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 1, index: tileMap[keys.LEAF_KEY] },
    ];

    const stampShape1 = [
        { x: 0, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 0, index: tileMap[keys.DOOR_KEY] },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 0, index: tileMap[keys.LEAF_KEY] },

        { x: 1, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 1, index: tileMap[keys.FLOOR_KEY] },

        { x: 1, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 2, index: tileMap[keys.FLOOR_KEY] },

        { x: 1, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 3, index: tileMap[keys.FLOOR_KEY] },

        { x: 4, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 4, index: tileMap[keys.FLOOR_KEY] },

        { x: 4, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 5, index: tileMap[keys.FLOOR_KEY] },

        { x: 6, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 6, index: tileMap[keys.LEAF_KEY] },

        { x: 5, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 6, index: tileMap[keys.LEAF_KEY] },

        { x: 3, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 4, index: tileMap[keys.LEAF_KEY] },

        { x: 2, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY] },

        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 1, index: tileMap[keys.LEAF_KEY] },
    ]

    const stampShape2 = [
        { x: 0, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 0, index: tileMap[keys.LEAF_KEY] },

        { x: 1, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 1, index: tileMap[keys.FLOOR_KEY] },

        { x: 1, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 2, index: tileMap[keys.FLOOR_KEY] },

        { x: 1, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 3, index: tileMap[keys.FLOOR_KEY] },

        { x: 1, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 4, index: tileMap[keys.FLOOR_KEY] },

        { x: 0, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.DOOR_KEY] },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY] },

        { x: 5, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 4, index: tileMap[keys.LEAF_KEY] },

        { x: 0, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 5, index: tileMap[keys.LEAF_KEY] },
    ]

    const stampShape3 = [
        { x: 0, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 1, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 0, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 0, index: tileMap[keys.LEAF_KEY] },

        { x: 1, y: 1, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 1, index: tileMap[keys.FLOOR_KEY] },

        { x: 1, y: 2, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 2, index: tileMap[keys.FLOOR_KEY] },

        { x: 1, y: 3, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 3, index: tileMap[keys.FLOOR_KEY] },

        { x: 1, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 4, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 4, index: tileMap[keys.FLOOR_KEY] },

        { x: 1, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 2, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 3, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 4, y: 5, index: tileMap[keys.FLOOR_KEY] },
        { x: 5, y: 5, index: tileMap[keys.FLOOR_KEY] },

        { x: 0, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 4, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 0, y: 6, index: tileMap[keys.LEAF_KEY] },

        { x: 3, y: 1, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 2, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 3, index: tileMap[keys.LEAF_KEY] },

        { x: 4, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 5, y: 3, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 3, index: tileMap[keys.LEAF_KEY] },

        { x: 1, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 2, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 3, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 4, y: 6, index: tileMap[keys.DOOR_KEY] },
        { x: 5, y: 6, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 6, index: tileMap[keys.LEAF_KEY] },

        { x: 6, y: 5, index: tileMap[keys.LEAF_KEY] },
        { x: 6, y: 4, index: tileMap[keys.LEAF_KEY] },
    ]

    const trunkCoords = drawTreeDots(mockLayerData, layerData, panel, [
        stampShape,
        stampShape1,
        stampShape2,
        stampShape3,
    ], 1, false, [], keys, rng);

    // This removes any trees which overlap into a cliff, river, or the ocean
    clearRiverCliffAndOceanTiles(layerData, panel)

    const missingTileProbability = 0.1

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (rng() < missingTileProbability) {
                tile.index = 0;
            }
        })
    });

    drawNeighbours(layerData, 'MINE-WALL.', tileMap[keys.LEAF_KEY], { isDiagonalRelevent: true });

    return mockLayerData;
}

export function drawAlienCity(mockLayerData, layerData, panel) {
    const MAGMA_KEY = 'EMPTY';
    const GROUND_KEY = 'WATER';
    const PLANT_KEY = 'Marble Floor';

    const drawingLimit = 0;
    let noiseOne = drawNoise(10, 30, panel);
    let noiseTwo = drawNoise(31, 1, panel);
    let noiseThree = drawNoise(30,1, panel);
    let noiseFour = drawNoise(30, 1, panel);

    let finalNoise = noiseOne.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseTwo[x][y]))
    })

    finalNoise = finalNoise.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseThree[x][y]))
    })

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (finalNoise[rowIndex][columnIndex] > drawingLimit) {
                tile.index = tileMap[MAGMA_KEY];
            } else {
                tile.index = tileMap[GROUND_KEY];
            }
        })
    });

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile.index === tileMap[GROUND_KEY]) {
                return;
            }

            if (rowIndex === 0 || rowIndex === PANEL_WIDTH - 1 || columnIndex === 0 || columnIndex === PANEL_HEIGHT - 1) {
                return;
            }

            if (columnIndex === 0 || columnIndex === PANEL_HEIGHT - 1) {
                tile.index = tileMap[GROUND_KEY];
                return;
            }

            const isNorthSameTile = layerData[columnIndex - 1][rowIndex].index === tile.index ? 1 : 0;
            const isEastSameTile = layerData[columnIndex][rowIndex + 1].index === tile.index ? 1 : 0;
            const isSouthSameTile = layerData[columnIndex + 1][rowIndex].index === tile.index ? 1 : 0;
            const isWestSameTile = layerData[columnIndex][rowIndex - 1].index === tile.index ? 1 : 0;

            const neighboursCount = isNorthSameTile + isEastSameTile + isSouthSameTile + isWestSameTile;

            if (neighboursCount > 2) {
                if (isSouthSameTile) {
                    layerData[columnIndex + 1][rowIndex].index = tileMap[GROUND_KEY]
                }
            }

            if (neighboursCount === 0) {
                tile.index = tileMap[GROUND_KEY]
            }
        })
    });
}

function limitToCheckerboxPattern(layerData, panel, emptyKey, targetedIndexes) {
    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if ((rowIndex % 2 === 0) && (columnIndex % 2 === 0) || (rowIndex % 2 === 1) && (columnIndex % 2 === 1)) {
                if (targetedIndexes && targetedIndexes.indexOf(tile.index) > -1) {
                    tile.index = tileMap[emptyKey]
                } else if (!targetedIndexes) {
                    tile.index = tileMap[emptyKey]
                }
            }
        })
    });
}

export function drawEvergreenForest(mockLayerData, layerData, panel) {
    const LINGONBERRY_KEY = 'LINGONBERRY.OFFSEASON';
    const CLOUDBERRY_KEY = 'CLOUDBERRY.OFFSEASON';
    const neighbours = getMatchingBiomeNeighbours(panel);

    const keys = {
        TRUNK_KEY: 'EVERGREEN.LEFT.RIGHT.TOP.BOTTOM.',
        GROUND_KEY: 'EMPTY',
        LEAF_KEY: 'EVERGREEN.RIGHT.LEFT.'
    }

    const stampShape = [
        { x: -2, y: -1, index: tileMap['EVERGREEN.RIGHT.BOTTOM.'] },
        { x: -1, y: -1, index: tileMap['EVERGREEN.LEFT.RIGHT.BOTTOM.'] },
        { x: -2, y: 0, index: tileMap['EVERGREEN.RIGHT.TOP.BOTTOM.'] },
        { x: -2, y: 1, index: tileMap['EVERGREEN.RIGHT.TOP.'] },
        { x: -1, y: 0, index: tileMap['EVERGREEN.LEFT.RIGHT.TOP.BOTTOM.FACING-LEFT'] },
        { x: -1, y: 1, index: tileMap['EVERGREEN.LEFT.RIGHT.TOP.'] },
        { x: 0, y: -1, index: tileMap['EVERGREEN.LEFT.RIGHT.BOTTOM.'] },
        { x: 1, y: -1, index: tileMap['EVERGREEN.LEFT.RIGHT.BOTTOM.'] },
        { x: 2, y: -1, index: tileMap['EVERGREEN.LEFT.BOTTOM.'] },
        // { x: 0, y: 0 },
        { x: 0, y: 1, index: tileMap['EVERGREEN.LEFT.RIGHT.TOP.'] },
        { x: 1, y: 0, index: tileMap['EVERGREEN.LEFT.RIGHT.TOP.BOTTOM.FACING-RIGHT'] },
        { x: 1, y: 1, index: tileMap['EVERGREEN.LEFT.RIGHT.TOP.'] },
        { x: 2, y: 0, index: tileMap['EVERGREEN.LEFT.TOP.BOTTOM.'] },
        { x: 2, y: 1, index: tileMap['EVERGREEN.LEFT.TOP.'] },
    ]

    drawTreeDots(mockLayerData, layerData, panel, stampShape, 0.8, true, neighbours, keys)

    drawDots(layerData, panel, LINGONBERRY_KEY, 1.6, [tileMap[keys.TRUNK_KEY], tileMap[keys.LEAF_KEY]])
    drawDots(layerData, panel, CLOUDBERRY_KEY, 1.6, [tileMap[keys.TRUNK_KEY], tileMap[keys.LEAF_KEY]], 2)

    const tileKey = 'EVERGREEN';

    layerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (layerData[y - PANEL_HEIGHT] && layerData[y - PANEL_HEIGHT][x - PANEL_WIDTH]) {
                tile.index = layerData[y - PANEL_HEIGHT][x - PANEL_WIDTH].index
            }
        })
    })

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            const northRow = layerData[columnIndex - 1]
            const southRow = layerData[columnIndex + 1];

            let northTile, northEastTile, northWestTile, southTile, southEastTile, southWestTile, eastTile, westTile;
            let isNorthSameTile, isNorthEastSameTile, isNorthWestSameTile, isSouthSameTile, isSouthEastSameTile, isSouthWestSameTile;

            if (northRow) {
                northTile = layerData[columnIndex - 1][rowIndex];
                northEastTile = layerData[columnIndex - 1][rowIndex - 1];
                northWestTile = layerData[columnIndex - 1][rowIndex + 1];

                isNorthSameTile = !isEmptyIndex(northTile);
                isNorthEastSameTile = !isEmptyIndex(northEastTile);
                isNorthWestSameTile = !isEmptyIndex(northWestTile);
            }

            if (southRow) {
                southTile = layerData[columnIndex + 1][rowIndex]
                southEastTile = layerData[columnIndex + 1][rowIndex + 1];
                southWestTile = layerData[columnIndex + 1][rowIndex - 1];

                isSouthSameTile = !isEmptyIndex(southTile);
                isSouthEastSameTile = !isEmptyIndex(southEastTile);
                isSouthWestSameTile = !isEmptyIndex(southWestTile);
            }

            eastTile = layerData[columnIndex][rowIndex + 1];
            westTile = layerData[columnIndex][rowIndex - 1]
            const isEastSameTile = !isEmptyIndex(eastTile);
            const isWestSameTile = !isEmptyIndex(westTile);

            if (tile.index === tileMap[tileKey + '.LEFT.RIGHT.BOTTOM.']) {
                if (isNorthSameTile && (northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.FACING-LEFT'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.FACING-RIGHT'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM']
                }

                if (isNorthSameTile && (northTile.index === tileMap[tileKey + '.LEFT.TOP.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.BOTTOM.'] || northTile.index === tileMap[tileKey + '.RIGHT.BOTTOM.'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM']
                }

                if (isNorthSameTile && (northTile.index === tileMap[tileKey + '.RIGHT.TOP.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM']
                }
            }

            if (tile.index === tileMap[tileKey + '.LEFT.BOTTOM.']) {
                if (isEastSameTile && (eastTile?.index === tileMap[tileKey + '.LEFT.RIGHT.BOTTOM.'] || eastTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM'] || eastTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.'] || eastTile?.index === tileMap[tileKey + '.LEFT.TOP.'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.BOTTOM.']
                }

                if (isNorthSameTile && (northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.FACING-LEFT'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.FACING-RIGHT'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.BOTTOM.'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM']
                }

                if (isNorthSameTile && (northTile.index === tileMap[tileKey + '.LEFT.TOP.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.'] || northTile.index === tileMap[tileKey + '.RIGHT.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTOR-TOP-LEFT-BOTTOM-RIGHT'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM']
                }

                if (isNorthSameTile && (northTile.index === tileMap[tileKey + '.RIGHT.TOP.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM'])) {
                    if (isEastSameTile && eastTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.']) {
                    } else {
                        tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTOR-TOP-RIGHT-BOTTOM-LEFT']
                    }
                }
            }

            if (tile.index === tileMap[tileKey + '.RIGHT.BOTTOM.']) {
                if (isWestSameTile && (westTile?.index === tileMap[tileKey + '.LEFT.RIGHT.BOTTOM.'] || westTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM'] || westTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.'] || westTile?.index === tileMap[tileKey + '.RIGHT.TOP.'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.BOTTOM.']
                }

                if (isNorthSameTile && (northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.FACING-LEFT'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.FACING-RIGHT'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.BOTTOM.'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM']
                }

                if (isNorthSameTile && (northTile.index === tileMap[tileKey + '.RIGHT.TOP.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTOR-TOP-RIGHT-BOTTOM-LEFT'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM']
                }

                if (isNorthSameTile && (northTile.index === tileMap[tileKey + '.LEFT.TOP.BOTTOM.'])) {
                    if (isWestSameTile && westTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.']) {
                    } else {
                        tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTOR-TOP-LEFT-BOTTOM-RIGHT']
                    }
                }
            }

            if (tile.index === tileMap[tileKey + '.RIGHT.TOP.']) {
                if (isWestSameTile && westTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.']) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.']
                }
            }

            if (tile.index === tileMap[tileKey + '.RIGHT.TOP.BOTTOM.']) {
                if (isWestSameTile) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM']
                }
            }

            if (tile.index === tileMap[tileKey + '.LEFT.TOP.BOTTOM.'] || tile.index === tileMap[tileKey + '.RIGHT.TOP.BOTTOM.']) {
                if (isEastSameTile) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM']
                }
            }
        })
    })
}

function doConnectingTreeLogic(tileKey, layerData) {
    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            const northRow = layerData[columnIndex - 1]
            const southRow = layerData[columnIndex + 1];

            let northTile, northEastTile, northWestTile, southTile, southEastTile, southWestTile, eastTile, westTile;
            let isNorthSameTile, isNorthEastSameTile, isNorthWestSameTile, isSouthSameTile, isSouthEastSameTile, isSouthWestSameTile;

            if (northRow) {
                northTile = layerData[columnIndex - 1][rowIndex];
                northEastTile = layerData[columnIndex - 1][rowIndex - 1];
                northWestTile = layerData[columnIndex - 1][rowIndex + 1];

                isNorthSameTile = !isEmptyIndex(northTile);
                isNorthEastSameTile = !isEmptyIndex(northEastTile);
                isNorthWestSameTile = !isEmptyIndex(northWestTile);
            }

            if (southRow) {
                southTile = layerData[columnIndex + 1][rowIndex]
                southEastTile = layerData[columnIndex + 1][rowIndex + 1];
                southWestTile = layerData[columnIndex + 1][rowIndex - 1];

                isSouthSameTile = !isEmptyIndex(southTile);
                isSouthEastSameTile = !isEmptyIndex(southEastTile);
                isSouthWestSameTile = !isEmptyIndex(southWestTile);
            }

            eastTile = layerData[columnIndex][rowIndex + 1];
            westTile = layerData[columnIndex][rowIndex - 1]
            const isEastSameTile = !isEmptyIndex(eastTile);
            const isWestSameTile = !isEmptyIndex(westTile);

            if (tile.index === tileMap[tileKey + '.LEFT.RIGHT.BOTTOM.']) {
                if (isNorthSameTile && (northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM']
                }

                if (isNorthSameTile && northTile.index === tileMap[tileKey + '.LEFT.TOP.BOTTOM.']) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM']
                }

                if (isNorthSameTile && (northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM'] || northTile.index === tileMap[tileKey + '.RIGHT.TOP.BOTTOM.'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM']
                }
            }

            if (tile.index === tileMap[tileKey + '.LEFT.BOTTOM.']) {
                if (isEastSameTile && (eastTile?.index === tileMap[tileKey + '.LEFT.RIGHT.BOTTOM.'] || eastTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM'] || eastTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.'] || eastTile?.index === tileMap[tileKey + '.LEFT.TOP.'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.BOTTOM.']
                }

                if (isNorthSameTile && (northTile?.index === tileMap[tileKey + '.LEFT.TOP.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.'] || northTile.index === tileMap[tileKey + '.RIGHT.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTOR-TOP-LEFT-BOTTOM-RIGHT'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM']
                }

                if (isNorthSameTile && (northTile?.index === tileMap[tileKey + '.RIGHT.TOP.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM'])) {
                    if (isEastSameTile && eastTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.']) {
                    } else {
                        tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTOR-TOP-RIGHT-BOTTOM-LEFT']
                    }
                }
            }

            if (tile.index === tileMap[tileKey + '.RIGHT.BOTTOM.']) {
                if (isWestSameTile && (westTile?.index === tileMap[tileKey + '.LEFT.RIGHT.BOTTOM.'] || westTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM'] || westTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.'] || westTile?.index === tileMap[tileKey + '.RIGHT.TOP.'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.BOTTOM.']
                }

                if (isNorthSameTile && (northTile?.index === tileMap[tileKey + '.RIGHT.TOP.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.BOTTOM.'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM'] || northTile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTOR-TOP-RIGHT-BOTTOM-LEFT'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM']
                }

                if (isNorthSameTile && (northTile?.index === tileMap[tileKey + '.LEFT.TOP.BOTTOM.'])) {
                    if (isWestSameTile && westTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.']) {
                    } else {
                        tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTOR-TOP-LEFT-BOTTOM-RIGHT']
                    }
                }
            }

            if (tile.index === tileMap[tileKey + '.RIGHT.TOP.']) {
                if (isWestSameTile && (westTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.'] || westTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM'] || westTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.'] || westTile?.index === tileMap[tileKey + '.RIGHT.BOTTOM.'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.']
                }
            }

            if (tile.index === tileMap[tileKey + '.RIGHT.TOP.BOTTOM.']) {
                if (isWestSameTile) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM']
                }
            }

            if (tile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTOR-TOP-RIGHT-BOTTOM-LEFT']) {
                if (isNorthEastSameTile && (northEastTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM']
                }
            }

            if (tile.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTOR-TOP-LEFT-BOTTOM-RIGHT']) {
                if (isSouthWestSameTile && (southWestTile?.index === tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.'])) {
                    tile.index = tileMap[tileKey + '.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM']
                }
            }
        })
    })
}

export function drawTreeDots(mockLayerData, layerData, panel, stampShape, density, isStampTouchingOkay, neighbours, keys, rng) {
    const trunkCoords = [];
    const drawingLimit = density;
    // 0 - 1.5 for different densities

    let noiseOne = drawExpandedNoise(1, 1, panel);
    let noiseTwo = drawExpandedNoise(1, 1, panel);

    let finalNoise = noiseOne.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseTwo[x][y]))
    })

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (finalNoise[rowIndex][columnIndex] > drawingLimit) {
                tile.index = tileMap[keys.TRUNK_KEY];
            }
        })
    });

    // This removes any trees which overlap into a cliff, river, or the ocean
    cleanCliffOrRiverOrOceanOverlaps(layerData, stampShape, keys, panel);

    // This removes any trees which overlap into a different biome
    clearDifferentNeighbourOverlaps(layerData, stampShape, neighbours, keys)

    // This removes any trees which overlap into pre-existing trees
    clearPreexistingOverlaps(layerData, stampShape, layerData, keys)

    mockLayerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (layerData[y] && layerData[y][x]) {
                tile.index = layerData[y][x].index
            }
        })
    })

    if (!isStampTouchingOkay) {
        // This code cleans out any dots that are infringing on a pre-defined 'stamp' shape
        cleanOverlappingStamps(mockLayerData, stampShape, keys, rng, layerData)
    } else {
        mockLayerData.forEach((row, columnIndex) => {
            row.forEach((tile, rowIndex) => {
                if (tile.index === tileMap[keys.TRUNK_KEY]) {
                    let isOverlappingNorthOrWest = false;

                    stampShape.forEach(coords => {
                        const currentLocationCoords = { x: rowIndex, y: columnIndex };
                        const stampLocationCoords = { x: currentLocationCoords.x + coords.x, y: currentLocationCoords.y + coords.y };
                        let stampTile;
                        const stampedTileColumn = mockLayerData[stampLocationCoords.y];
                        if (stampedTileColumn) {
                            stampTile = stampedTileColumn[stampLocationCoords.x];
                        }

                        if (isOverlappingNorthOrWest || stampLocationCoords.x < 0 || stampLocationCoords.y < 0) {
                            isOverlappingNorthOrWest = true;
                        }

                        if (stampTile && !isOverlappingNorthOrWest) {
                            if (coords.index) {
                                stampTile.index = coords.index
                            } else {
                                stampTile.index = tileMap[keys.LEAF_KEY]
                            }
                        }
                    })

                    if (isOverlappingNorthOrWest) {
                        tile.index = tileMap['EMPTY']
                    }
                }
            })
        });
    }

    mockLayerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile) {
                layerData[columnIndex][rowIndex].index = tile.index
            }
        })
    });

    mockLayerData.forEach((row, y) => {
        row.forEach((tile, x) => {
            if (mockLayerData[y][x].index === tileMap[keys.TRUNK_KEY]) {
                trunkCoords.push({ x, y })
            }
        })
    });

    return trunkCoords;
}

function cleanCliffOrRiverOrOceanOverlaps(layerData, stampShape, keys, panel, isNotThreeWide) {
    const panelWidth = 0;
    const panelHeight = 0;

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile.index === tileMap[keys?.TRUNK_KEY] || (!isEmptyIndex(tile) && !keys)) {
                function cleanStamp(_stampShape) {
                    _stampShape.forEach(coords => {
                        const currentLocationCoords = { x: rowIndex, y: columnIndex };
                        const stampLocationCoords = { x: currentLocationCoords.x + coords.x, y: currentLocationCoords.y + coords.y };

                        const floorLayerTile = panel.floorLayer.find(tile => (tile.x === (stampLocationCoords.x - panelWidth) && tile.y === (stampLocationCoords.y - panelHeight)));
                        const _tile = panel.floorLayer.find(tile => (tile.x === (rowIndex - panelWidth) && tile.y === (columnIndex - panelHeight)));

                        if (floorLayerTile) {
                            // layerData[columnIndex][rowIndex].index = tileMap[keys.GROUND_KEY]
                            tile.index = tileMap[_tile && _tile.tileType ? _tile.tileType : 'EMPTY']
                        }
                    })
                }

                if (Array.isArray(stampShape) && Array.isArray(stampShape[0])) {
                    cleanStamp([{x: 0, y: 0}])
                    stampShape.forEach(_stampShape => {
                        cleanStamp(_stampShape)
                    })
                } else {
                    cleanStamp([{x: 0, y: 0}])
                    cleanStamp(stampShape);
                }
            }
        })
    })
}

export function cleanOverlappingDots(layerData, stampShape, keys) {
    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile.index === tileMap[keys.TRUNK_KEY]) {
                stampShape.forEach(coords => {
                    const currentLocationCoords = { x: rowIndex, y: columnIndex };
                    const stampLocationCoords = { x: currentLocationCoords.x + coords.x, y: currentLocationCoords.y + coords.y };
                    let stampTileLocation;
                    const stampedTileColumn = layerData[stampLocationCoords.y];
                    if (stampedTileColumn) {
                        stampTileLocation = stampedTileColumn[stampLocationCoords.x];
                    }

                    if (stampTileLocation && stampTileLocation.index === tileMap[keys.TRUNK_KEY]) {
                        stampTileLocation.index = tileMap[keys.GROUND_KEY]
                    }
                })
            }
        })
    })
}

export function clearPreexistingOverlaps(mockLayerData, stampShape, layerData, keys) {
    if (!layerData) {
        return;
    }
    mockLayerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile.index === tileMap[keys.TRUNK_KEY]) {
                if (Array.isArray(stampShape) && Array.isArray(stampShape[0])) {
                    stampShape.forEach(stampShape => {
                        stampShape.forEach(coords => {
                            const currentLocationCoords = { x: rowIndex, y: columnIndex };
                            const stampLocationCoords = { x: currentLocationCoords.x + coords.x, y: currentLocationCoords.y + coords.y };

                            if (stampLocationCoords.y - PANEL_HEIGHT < 0 || stampLocationCoords.y - PANEL_HEIGHT >= PANEL_HEIGHT) {
                                return;
                            }

                            if (stampLocationCoords.x - PANEL_WIDTH < 0 || stampLocationCoords.x - PANEL_WIDTH >= PANEL_WIDTH) {
                                return;
                            }

                            if (layerData[stampLocationCoords.y - PANEL_HEIGHT][stampLocationCoords.x - PANEL_WIDTH].index && layerData[stampLocationCoords.y - PANEL_HEIGHT][stampLocationCoords.x - PANEL_WIDTH].index !== -1 && layerData[stampLocationCoords.y - PANEL_HEIGHT][stampLocationCoords.x - PANEL_WIDTH].index !== tileMap['EMPTY']) {
                                tile.index = tileMap[keys.GROUND_KEY]
                            }
                        })
                    })
                } else {
                    stampShape.forEach(coords => {
                        const currentLocationCoords = { x: rowIndex, y: columnIndex };
                        const stampLocationCoords = { x: currentLocationCoords.x + coords.x, y: currentLocationCoords.y + coords.y };

                        if (stampLocationCoords.y - PANEL_HEIGHT < 0 || stampLocationCoords.y - PANEL_HEIGHT >= PANEL_HEIGHT) {
                            return;
                        }

                        if (stampLocationCoords.x - PANEL_WIDTH < 0 || stampLocationCoords.x - PANEL_WIDTH >= PANEL_WIDTH) {
                            return;
                        }

                        if (layerData[stampLocationCoords.y - PANEL_HEIGHT][stampLocationCoords.x - PANEL_WIDTH].index && layerData[stampLocationCoords.y - PANEL_HEIGHT][stampLocationCoords.x - PANEL_WIDTH].index !== -1 && layerData[stampLocationCoords.y - PANEL_HEIGHT][stampLocationCoords.x - PANEL_WIDTH].index !== tileMap['EMPTY']) {
                            tile.index = tileMap[keys.GROUND_KEY]
                        }
                    })
                }
            }
        })
    })
}

export function cleanOverlappingStamps(mockLayerData, stampShape, keys, rng) {
    let stampShapes;

    if (!stampShape[0].index) {
        //array of stamp shapes.
        stampShapes = [ ...stampShape ];
    }

    mockLayerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile.index === tileMap[keys.TRUNK_KEY]) {
                let isOverlappingStamp = false;

                if (stampShapes) {
                    stampShape = stampShapes[Math.floor(rng() * stampShapes.length)];
                }

                stampShape.forEach(coords => {
                    const currentLocationCoords = { x: rowIndex, y: columnIndex };
                    const stampLocationCoords = { x: currentLocationCoords.x + coords.x, y: currentLocationCoords.y + coords.y };
                    let stampTile;
                    const stampedTileColumn = mockLayerData[stampLocationCoords.y];
                    if (stampedTileColumn) {
                        stampTile = stampedTileColumn[stampLocationCoords.x];
                    }

                    // Stamps not allowed to poke out north or west :(
                    if (stampLocationCoords.x < 0 || stampLocationCoords.y < 0) {
                        isOverlappingStamp = true;
                    }

                    if (!!stampShape.find(coord => (coord.index === stampTile?.index)) || (keys.LEAF_KEY && stampTile?.index === tileMap[keys.LEAF_KEY])) {
                        isOverlappingStamp = true;
                    }
                })

                if (isOverlappingStamp) {
                    tile.index = tileMap[keys.GROUND_KEY];
                    return;
                }

                stampShape.forEach(coords => {
                    const currentLocationCoords = { x: rowIndex, y: columnIndex };
                    const stampLocationCoords = { x: currentLocationCoords.x + coords.x, y: currentLocationCoords.y + coords.y };
                    let stampTile;
                    const stampedTileColumn = mockLayerData[stampLocationCoords.y];
                    if (stampedTileColumn) {
                        stampTile = stampedTileColumn[stampLocationCoords.x];
                    }

                    if (stampTile) {
                        if (coords.index) {
                            if (!coords.probability) {
                                stampedTileColumn[stampLocationCoords.x].index = coords.index
                            }

                            if (coords.probability && rng() < coords.probability) {
                                stampedTileColumn[stampLocationCoords.x].index = coords.index
                            }
                        } else {
                            stampedTileColumn[stampLocationCoords.x].index = tileMap[keys.LEAF_KEY]
                        }
                    }
                })

            }
        })
    });
}

function clearDifferentNeighbourOverlaps(layerData, stampShape, neighbours, keys) {
    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (tile.index === tileMap[keys.TRUNK_KEY]) {
                if (Array.isArray(stampShape) && Array.isArray(stampShape[0])) {
                    stampShape.forEach(stampShape => {
                        stampShape.forEach(coords => {
                            const currentLocationCoords = { x: rowIndex, y: columnIndex };
                            const stampLocationCoords = { x: currentLocationCoords.x + coords.x, y: currentLocationCoords.y + coords.y };

                            if (stampLocationCoords.x < 0 && !neighbours.find(neighbour => neighbour === 'west')) {
                                tile.index = tileMap[keys.GROUND_KEY]
                            }

                            if ((stampLocationCoords.x) > (PANEL_WIDTH - 1) && !neighbours.find(neighbour => neighbour === 'east')) {
                                tile.index = tileMap[keys.GROUND_KEY]
                            }

                            if (stampLocationCoords.y < 0 && !neighbours.find(neighbour => neighbour === 'north')) {
                                tile.index = tileMap[keys.GROUND_KEY]
                            }

                            if ((stampLocationCoords.y) > (PANEL_HEIGHT - 1) && !neighbours.find(neighbour => neighbour === 'south')) {
                                tile.index = tileMap[keys.GROUND_KEY]
                            }
                        })
                    })
                } else {
                    stampShape.forEach(coords => {
                        const currentLocationCoords = { x: rowIndex, y: columnIndex };
                        const stampLocationCoords = { x: currentLocationCoords.x + coords.x, y: currentLocationCoords.y + coords.y };

                        if (stampLocationCoords.x < 0 && !neighbours.find(neighbour => neighbour === 'west')) {
                            tile.index = tileMap[keys.GROUND_KEY]
                        }

                        if ((stampLocationCoords.x) > (PANEL_WIDTH - 1) && !neighbours.find(neighbour => neighbour === 'east')) {
                            tile.index = tileMap[keys.GROUND_KEY]
                        }

                        if (stampLocationCoords.y < 0 && !neighbours.find(neighbour => neighbour === 'north')) {
                            tile.index = tileMap[keys.GROUND_KEY]
                        }

                        if ((stampLocationCoords.y) > (PANEL_HEIGHT - 1) && !neighbours.find(neighbour => neighbour === 'south')) {
                            tile.index = tileMap[keys.GROUND_KEY]
                        }
                    })
                }
            }
        })
    })
}

export function drawDots(layerData, panel, dotKey, density, indexesToSkip, seed) {
    if (!indexesToSkip) {
        indexesToSkip = [];
    }

    const dots = [];
    const drawingLimit = density;
    // 0 - 1.5 for different densities

    let noiseOne = drawExpandedNoise(1, 1, panel, seed);
    let noiseTwo = drawExpandedNoise(1, 1, panel, seed);

    let finalNoise = noiseOne.map((noiseArray, x) => {
        return noiseArray.map((noiseValue, y) => (noiseValue + noiseTwo[x][y]))
    })

    const mockLayerData = [];

    for (var y=0; y < PANEL_HEIGHT * 3; y++) {
        mockLayerData[y] = []
        for (var x=0; x < PANEL_WIDTH * 3; x++) {
            mockLayerData[y][x] = {}
        }
    }

    layerData.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {

            if (finalNoise[rowIndex][columnIndex] > drawingLimit && indexesToSkip.indexOf(tile.index) === -1) {
                if (!isEmptyIndex(tile)) {
                    return;
                }

                tile.index = tileMap[dotKey];
                dots.push({
                    x: rowIndex,
                    y: columnIndex,
                    index: tileMap[dotKey]
                })
            }
        })
    });

    return dots;
}

let xOffset = 0;
export function drawTundraClouds(clouds, panel, rng) {
    return setInterval(function() {
        if (clouds.x > -16) {
            clouds.x = -32;
            xOffset++;
        }

        const noiseOne = drawCloudNoise3D(5, 10, panel, new Date().getTime() / 100000, xOffset);

        clouds.layer.data.forEach((row, columnIndex) => {
            row.forEach((tile, rowIndex) => {
                if (noiseOne[rowIndex][columnIndex] > 0.3 && noiseOne[rowIndex][columnIndex] < 0.7) {
                    tile.index = tileMap['TUNDRA.CLOUD.LEFT.RIGHT.'];
                    tile.setAlpha(0.5)
                } else {
                    tile.index = -1
                }
            })
        });

        drawNeighbours(clouds.layer.data, 'TUNDRA.CLOUD.', tileMap['TUNDRA.CLOUD.LEFT.RIGHT.'], { isDiagonalRelevent: true });
    }(), 10)
}