export const furSmellTypes = [
	"of money",
    "roasted",
    "sweet",
    "nutty",
    "syrupy",
    "of caramel",
    "toasted",
    "of wet dog",
    "musty",
    "dank",
    "verdigris",
    "musky",
    "gamey",
    "of grease",
    "of sweat",
    "of Damp earth",
    "of Moss",
    "of Pine needles",
    "of Wood smoke",
    "of long grass",
    "of wet grass",
    "of dry grass",
    "of summer",
    "of breeze",
    "of wet",
    "of dirt",
    "of moldy",
    "of leaf",
    "of armpit",
    "of muddy goat",
    "of a chewed sweet",
    "quirky",
    "of wet fur and smoke",
    "umami-milky",
    "of violet leaf",
    "of dry herbs and salt",
    "of osmanthus",
    "of charred wood",
    "of dried figgy fruits",
    "of soiled boots",
    "of pent-up lust",
    "of dill pickle juice and cypress",
    "of pepper and nutmeg",
    "of a cared-for plot",
    "melancholy",
    "of flesh and grass",
    "of flowers in their prime",
    "like an occult oil",
    "of green muskmallow",
    "of weeds and fleabane",
]

export const furBranchTypes = [
	"Dense",
    "Bumpy",
    "Dry",
    "Yellow",
    "Green",
    "Brown",
    "damp",
    "lofty",
    "beautiful",
    "simple",
    "plain",
    "sturdy",
    "smooth-barked",
    "fast-growing",
    "scarred",
    "leaf-scarred",
    "messages hacked into its",
    "carved images in its",
    "a crude symbol hacked into its",
    "a name-bearing",
    "an old message scrawled into its",
    "creature-nibbled",
    "scraped",
    "paper bark",
    "bark-clad",
    "fissured bark and tall",
]

export const furLeafTypes = [
	"orange",
    "glistening",
    "dewy",
    "bladed",
    "fine",
    "whispering",
    "rustling",
    "exhilarating",
    "downy",
    "lobed",
    "downy",
    "trembling",
    "quaking",
    "shaking",
    "shivering",
    "tremulous",
    "catkins dangling from its",
    "round",
    "long, flattened",
    "round-toothed",
    "fluffy",
    "thorny",
    "dry",
    "dead",
    "bare",
    "curvy",
    "russet",
    "patchy",
    "wet",
    "dry",
    "brown",
    "dead",
    "yellow",
    "hairy",
    "thick",
    "flame-coloured",
    "lush",
    "springy",
    "wild"
]