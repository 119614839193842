import React, { Component, useState } from "react";
import Terminal from 'terminal-in-react';
import hljs from 'highlight.js/lib/core';
import { connect } from 'react-redux'
// import './Startup.css';
import KeyboardMenu from '../keyboard-menu/KeyboardMenu';

import { createCurrencyAsync } from '../../redux/actions/currency.actions';
import { createCoinRecipeAsync } from '../../redux/actions/coin-recipes.actions';
import { createCoinTypeAsync } from '../../redux/actions/coin-types.actions';

import {
    selectCurrencies,
    selectCharacter,
    selectMetalTypes,
    selectWorkshopTypes,
    selectCoinTypes,
    selectCoinRecipes
} from '../../redux/selectors';

class GroupTerminal extends React.Component {

	constructor() {
		super();
	}

	start(command, print) {
		console.log('start new game');
	}

	continue(command, print) {
		console.log('continue');
	}

	settings(command, print) {
		console.log('settings');
	}

	logout(command, print) {
	}

	welcomeUser(command, print) {
		this.print = print;

		print(' ');
		print(' ');
		print(' ');

		print('Welcome to the Viewscorp Settle Gliese Player Terminal (c) 2088');

		print(' ');

		print('Type \'help\' to see commands');
	}

	showCommandsList(command, print) {
		// print('currency - View and edit currency settings')
	}

	skipCommand(command, print) {
		return;
	}

	// we need to make this a recursive thing and fill a list of currencies...

	handleCommand(command, print) {
		
	}

	render() {
	    return (
	      	<div className={"terminal-container"}
	        	style={{
	          		height: "100%",
	          		width: "100%",
	          		fontSize: "1.4vw",
	        	}}
	      	>
		        <Terminal
		          	color='var(--success)'
		          	backgroundColor='var(--black)'
	          		prompt='var(--info)'
	          		promptSymbol='$>'
		          	style={{ fontSize: "1em" }}
		          	hideTopBar={true}
		          	allowTabs={false}
		          	startState='maximised'
		          	commands={{
		          		show: this.welcomeUser.bind(this),
		          		clear: this.skipCommand.bind(this),
		          		echo: this.skipCommand.bind(this),
		          		'edit-line': this.skipCommand.bind(this),
		          		help: this.showCommandsList.bind(this),
		          		// logout: this.logout.bind(this)
		          	}}
		          	commandPassThrough={(command, print) => (this.handleCommand(command, print))}
		        />

		        <textarea className="hidden-input" />
      		</div>
	    );
	}
}

const mapStateToProps = state => {
	const currencies = selectCurrencies(state);
	const character = selectCharacter(state);
	const metalTypes = selectMetalTypes(state);
	const workshopTypes = selectWorkshopTypes(state);

	const mintWorkshopType = workshopTypes.find(type => (type.name === 'Mint'))

	const groupCurrency = currencies.find(currency => currency.groupId === character.groupId);
	let coinTypes = selectCoinTypes(state).filter(coinType => (coinType.currencyId === groupCurrency?._id));
	const coinRecipes = selectCoinRecipes(state);

	coinTypes = coinTypes.map(type => {
		return {
			...type,
			recipe: coinRecipes.find(recipe => (recipe.coinTypeId === type._id))
		}
	})

    return {
    	currencies,
    	character,
    	groupCurrency,
    	metalTypes,
    	mintWorkshopType,
    	coinTypes
    }
}

export default connect(
    mapStateToProps,
    {
    	createCurrencyAsync,
    	createCoinTypeAsync,
		createCoinRecipeAsync
    }
)(GroupTerminal);