import {
    CREATE_ARMOUR_LOADING,
    CREATE_ARMOUR_SUCCESS,
    CREATE_ARMOUR_FAILURE,
    GET_ARMOUR_LOADING,
    GET_ARMOUR_SUCCESS,
    GET_ARMOUR_FAILURE,
    ADD_ARMOUR,
    UPDATE_ARMOUR,
    REMOVE_ARMOUR,
    GET_TRADE_ARMOUR_SUCCESS,
    GET_TRADE_ARMOUR_FAILURE
} from '../actionTypes'
import store from '../store';

import { colyClient } from '../../services/Panel-initialisation.service';
import { client } from '../../services/client';
import { showUnknownError } from './keyboard-shortcuts.actions';

import { moveItemFailure } from './move-item.actions';

export const createArmourSuccess = payload => ({
    type: CREATE_ARMOUR_SUCCESS,
    payload
})

export const createArmourFailure = () => ({
    type: CREATE_ARMOUR_FAILURE
})

export const createArmourAsync = payload => dispatch => {
    const { _id, armourTypeId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'armour-instances',
        name: 'create',
        data: {
            _id,
            armourTypeId
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getArmourSuccess = payload => {
    return ({
        type: GET_ARMOUR_SUCCESS,
        payload
    })
}

export const getArmourFailure = () => ({
    type: GET_ARMOUR_FAILURE
})

export const getArmourAsync = payload => dispatch => {
    let query = { $or: [{ panelId: payload.panelId, z: payload.z}, { characterId: payload.characterId}] }

    if (payload.isNotFirstInitialisation) {
        query = { panelId: payload.panelId, z: payload.z}
    }

    return client.service('armour-instances').find({ query })
        .then((response) => {
            response = response.map(armour => ({ ...armour, armourType: payload.armourTypes.find(type => type._id === armour.armourTypeId) }))
            dispatch(getArmourSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }))
            throw new Error(error);
        })
}

export const getTradeArmourSuccess = payload => {
    return ({
        type: GET_TRADE_ARMOUR_SUCCESS,
        payload
    })
}

export const getTradeArmourFailure = () => ({
    type: GET_TRADE_ARMOUR_FAILURE
})

export const getTradeArmourAsync = payload => dispatch => {
    return client.service('armour-instances').find({ query: { characterId: payload.characterId, markedForTradeCount: { $gte: 1 } }})
        .then((response) => {
            response = response.map(armour => ({ ...armour, armourType: payload.armourTypes.find(type => type._id === armour.armourTypeId) }))
            dispatch(getTradeArmourSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const addArmourToTileAsync = payload => dispatch => {
    const { armourInstance, tileId, tileTypeId } = payload;
    const { armourTypeId, quantity, recipeId, characterId, _id } = armourInstance;

    colyClient.room.send('doAction', {
        serviceName: 'armour-instances',
        commandType: 'create',
        data: {
            _id,
            armourTypeId,
            quantity,
            tileId,
            tileTypeId,
            panelId: "",
            position: {},
            characterId: ""
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addArmourToCharacterAsync = payload => dispatch => {
    const { armourInstances, characterId } = payload;
    const { createdAt, armourTypeId, quantity, recipeId, _id } = armourInstances[0];

    colyClient.room.send('doSuperAction', {
        serviceName: 'armour-instances',
        name: 'fetch',
        data: {
            armourTypeId,
            quantity,
            tileId: "",
            characterId,
            instanceIds: armourInstances.map(inst => inst._id)
        },
        params: {
            query: {
                movementFrom: {
                    _id: undefined
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addArmour = payload => {
    return ({
        type: ADD_ARMOUR,
        payload
    })
}

export const updateArmour = payload => {
    return ({
        type: UPDATE_ARMOUR,
        payload
    })
}

export const removeArmour = payload => {
    return ({
        type: REMOVE_ARMOUR,
        payload
    })
}
