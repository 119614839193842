import {
  START_NEW_GAME,
  GET_MINERALS_SUCCESS,
  GET_TRADE_MINERALS_SUCCESS,
  GATHER_MINERAL_SUCCESS,
  READ_TILE_MINERALS_SUCCESS,
  CLEAR_TILE_MINERALS,
  UPDATE_POSITION_SUCCESS,
  PANEL_SUCCESS,
  REMOVE_MINERAL,
  CLEAR_ALL_MINERALS,
  ADD_MINERAL,
  UPDATE_MINERAL,
  SHOW_CHARACTER_DEATH_PAGE,
  INITIALISING_PANEL_STARTED,
  CHANGE_CHARACTER
} from "../actionTypes";

const initialState = {
  characterMinerals: [],
  tileMinerals: [],
  tradeMinerals: [],
  minerals: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case PANEL_SUCCESS: {
      return {
        ...state,
        tileMinerals: [],
        minerals: []
      }
    }

    case CLEAR_ALL_MINERALS: {
      return { ...initialState, ...state.characterMinerals }
    }

    case GET_MINERALS_SUCCESS: {
      const minerals = action.payload.map(mineral => ({ ...mineral, position: JSON.parse(mineral.position) }));

      console.log('unmined: ', [...state.minerals, ...minerals.filter(mineralInstance => mineralInstance.panelId)].length)
      console.log('character: ', [...state.characterMinerals, ...minerals.filter(mineralInstance => mineralInstance.characterId)].length)
      console.log('tileMinerals: ', [...state.tileMinerals, ...minerals.filter(mineralInstance => mineralInstance.tileId)].length)

      return {
        ...state,
        minerals: [...state.minerals, ...minerals.filter(mineralInstance => mineralInstance.panelId)],
        characterMinerals: [...state.characterMinerals, ...minerals.filter(mineralInstance => mineralInstance.characterId)],
        tileMinerals: [...state.tileMinerals, ...minerals.filter(mineralInstance => mineralInstance.tileId)]
      }
    }

    case GET_TRADE_MINERALS_SUCCESS: {
      const minerals = action.payload.map(mineral => ({ ...mineral, position: JSON.parse(mineral.position) }));

      return {
        ...state,
        tradeMinerals: [...state.minerals],
      }
    }

    case ADD_MINERAL: {
      let characterMinerals = [...state.characterMinerals];
      let tileMinerals = [...state.tileMinerals];
      let minerals = [...state.minerals];

      if (action.payload.characterId) {
        characterMinerals = [ ...characterMinerals, action.payload ]
      }

      if (action.payload.tileId) {
        tileMinerals = [ ...tileMinerals, action.payload ]
      }

      if (action.payload.panelId) {
        minerals = [ ...minerals, action.payload ]
      }

      return {
        ...state,
        characterMinerals: [ ...characterMinerals ],
        tileMinerals: [ ...tileMinerals ],
        minerals: [ ...minerals ],
      }
    }

    case UPDATE_MINERAL: {
      let characterMinerals = [...state.characterMinerals];
      let tileMinerals = [...state.tileMinerals];
      let minerals = [...state.minerals];

      if (action.payload.characterId) {
        characterMinerals = [
          ...characterMinerals.filter(mineral => mineral.id !== action.payload.id),
          { ...characterMinerals.find(mineral => mineral.id === action.payload.id), ...action.payload }
        ]
      }

      if (action.payload.tileId) {
        tileMinerals = [
          ...tileMinerals.filter(mineral => mineral.id !== action.payload.id),
          { ...tileMinerals.find(mineral => mineral.id === action.payload.id), ...action.payload }
        ]
      }

      if (action.payload.panelId) {
        minerals = [
          ...minerals.filter(mineral => mineral.id !== action.payload.id),
          { ...minerals.find(mineral => mineral.id === action.payload.id), ...action.payload }
        ]
      }

      return {
        ...state,
        characterMinerals: [ ...characterMinerals ],
        tileMinerals: [ ...tileMinerals ],
        minerals: [ ...minerals ],
      }
    }

    case REMOVE_MINERAL: {
      return {
        ...state,
        characterMinerals: [ ...state.characterMinerals.filter(mineral => (mineral.id !== action.payload.id)) ],
        tileMinerals: [ ...state.tileMinerals.filter(mineral => (mineral.id !== action.payload.id)) ],
        minerals: [ ...state.minerals.filter(mineral => (mineral.id !== action.payload.id)) ],
      }
    }

    default:
      return state;
  }
}
