export const rusterFaceSentences = [
"It has two large, red eyes.",
"It has a big, red mouth with sharp teeth.",
"Its face is covered in rust.",
"Giant teeth protruding from a wide, gaping mouth. ",
"full of jagged teeth, seeking to devour anything that comes close. ",
"The eyes of a ruster are blood-red and stare hungrily at its prey. ",
"Two beady eyes sit above a large, bulbous nose.",
"A toothy mouth full of yellowed fangs is flanked by scaly cheeks.",
"Runny snot drips from its long, twitching proboscis.",
"it has a large, bulbous nose.",
"It has piercing, beady eyes.",
"Its mouth is full of sharp teeth.",
"It has a scaly, greenish-brown complexion.",
"It is incredibly muscular.",
"its large, rusted face is covered in rivets and spikes",
"its eyes glow a menacing red",
"it has a large, toothless mouth full of razor-sharp metal teeth",
"its skin is rough and scaly, like rusted metal",
]

export const rusterBodySentences = [
	"It has a huge, muscular body that is covered in scales",
"It has powerful limbs, each tipped with sharp claws",
"Its head is reptilian, with glaring red eyes and a toothy maw",
"bulbous body",
"gnarled surface",
"oozing sores",
"crusty surface",
"tarnished surface",
]

export const rusterSoundWords = [
	    "Roaring",
    "Snarling",
    "Growling",
    "Howling",
    "Rumbling",
    "Murmuring",
    "Thundering",
    "Pounding",
    "Screaming",
    "Ranting",
    "chittering",
    "tweeting",
    "cooing",
    "humming",
    "mewing",
    "droning",
    "trilling",
    "gurgling",
    "rattling",
    "cawing",
    "Rumbling",
    "Roaring",
    "Growling",
    "Snarling",
    "gnashing",
    "hissing",
    "spitting",
    "bubbling",
    "seething",
    "foaming",
    "Growling",
    "hissing",
    "snarling",
    "drooling",
    "Rumbling",
    "Roaring",
    "Snarling",
    "Growling",
    "Rasping",
    "Shrieking",
    "Yelling",
    "Howling",
    "Screaming",
    "Snoring"
]

export const rusterFurWords = [
        "Coarse",
"Scaly",
"Brittle",
"Dry",
"Vegetative",
"Fungal",
"Warty",
"Oily",
"Sticky",
"Smelly",
"Rough",
"Scaly",
"Oozy",
"Crusty",
"Flaky",
"Itchy",
"Smelly",
"Damp",
"Dirty",
"Sticky",
"Flaky",
"Scaly",
"Itchy",
"Ruff",
"Brittle",
"Caked",
"Crusty",
"Dirty",
"Foul",
"Stinking",
"Rusted",
"Metallic",
"Scaly",
"Oily",
"Spiky",
"Sticky",
"Smelly",
"Gross",
"Slimy",
"Nasty",
]

export const rusterSmellWords = [
      "Foul",
    "Rancid",
    "Reeking",
    "Stenchful",
    "Stinking",
    "Malodorous",
    "Putrid",
    "Decaying",
    "Rotting",
    "Cadaverous",
    "sulphurous",
    "pungent",
    "rank",
    "fetid",
    "reeking",
    "stinking",
    "putrid",
    "noisome",
    "foetid",
    "malodorous",
    "Fetid",
    "Dank",
    "Decaying",
    "Rancid",
    "Pungent",
    "Nauseating",
    "Malevolent",
    "Eerie",
    "Unwholesome",
    "Burning",
    "acrid",
    "stench",
    "nauseating",
    "noxious",
    "fetid",
    "reek",
    "rank",
    "pungent",
    "putrid",
    "astringent",
    "dank",
    "fetid",
    "flatulent",
    "noisome",
    "rank",
    "reek",
    "stench",
    "stinking",
    "foul"
]

export const rusterBehaviourSentences = [
	"it is staring at you with its beady eyes.",
"it is making strange noises and movements.",
"it is drooling all over the place.",
"it is foaming at the mouth.",
"it is glaring at us with its beady eyes",
"it is lunging towards us with its razor-sharp claws",
"it is fidgeting warily, its forked tongue darting in and out",
"it is staring at you with its beady eyes",
"it is drooling all over itself",
"it is watching you",
]