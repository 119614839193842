export const cottonSmellTypes = [
    "of moss",
    "of dew",
    "of grass",
    "of leaves",
    "of soil",
    "of sunshine",
    "of wind",
    "of air",
    "perfumed",
    "woody",
    "sweet",
    "rich",
    "warm",
    "intense",
    "of hay",
    "of milk",
    "of wool",
    "floral",
    "grassy",
    "herbal",
    "earthy",
    "woody",
    "musky",
    "soft and gentle",
    "of sun, warmth, and water",
    "of childhood sweetness",
    "of boiled sweets",
    "custardy",
    "of a sweet maternal figure",
    "of the moment when all the tasks are done",
    "familiar and comforting",
    "gourmand with odd understones",
    "powdery, a cream musk",
    "of non-cloying sweetness",
    "soapy and fresh",
    "elegant, powdery, and uplifting",
    "soft and gossamer",
    "of complicated sensuality",
    "of gauzy morning light",
    "of quiet purrs",
    "clean and universally adored",
    "simple and clean",
    "of cheap fabric",
    "of a merchant's trick",
    "of a regrettable purchase",
    "of an inkstain on a pillow",
    "of pressed cloth",
    "of blood and iron",
    "of roses and wine",
]

export const cottonBranchTypes = [
    "Polyester",
    "pipe",
    "thin",
    "straight",
    "fibrous",
    "thick bolls hanging from its",
    "mallow",
    "showy bolls hanging from its",
    "downy bolls open along its",
    "fuzzy-seeded encasements",
    "thorny",
    "old",
    "pubescence covered",
    "greenish-purple",
    "pitted branches",
    "soft, inviting bolls along its",
    "a fluff tucked into a sharp withered boll on its",
    "tall",
    "dark",
    "fibrous fluffs popping out of its",
    "an inviting boll wrested between its",
    "slow growing",
    "finicky",
    "frost-susceptible",
    "frost-prone",
    "dry roots and upturned",
    "wiry",
    "cable-tower",
]

export const cottonLeafTypes = [
    "bushy",
    "palm-sized",
    "lobed",
    "water-hungry",
    "poisonous",
    "slow-growing",
    "three-fingered",
    "falcate",
    "sickle-shaped",
    "orbicular",
    "toothy",
    "glabrescent",
    "star-shaped",
    "stellate",
    "wide",
    "hairy",
    "hopeful",
    "withered",
    "pronged",
    "well-fed",
    "dour",
    "nutrient-deficient",
    "verdure",
    "green",
    "reddish-green",
    "curled",
    "red",
]