export const inglenookSmellTypes = [
    "of fallen leaves",
    "of fungus",
    "of humus",
    "of loam",
    "of sun-warmed stone",
    "of leather",
    "of wood smoke",
    "of must",
    "of damp",
    "of quiet",
    "of Gentrification",
    "Urban",
    "of Mint",
    "of Bourbon",
    "freshly oiled",
    "of waxed floors",
    "of wood smoke",
    "of cedar",
    "of damp",
    "of moss",
    "of honey",
    "musky and burns your throat",
    "of old books",
    "woody and aromatic",
    "of deep earth",
    "of culverts and roots",
    "quirky",
    "austere",
    "of the inked page",
    "of pressed flowers",
    "of misunderstood direction",
    "of burnt synthetics",
    "of stale air",
    "of burdensome furniture",
    "of smoldering campfire",
    "of dark and murky wood",
    "of an artist's workshop",
    "of kicked up loam",
    "of forbidden prayers",
    "of smoke and rind",
    "of dusty lemon peel",
    "of woody vetiver",
    "of rugged bark",
    "dark and erudite",
    "of a funeral",
    "of a partly forgotten hymn",
]

export const inglenookBranchTypes = [
    "sanguine",
    "red",
    "crimson",
    "blood-coloured",
    "rust-red",
    "russet",
    "blistered",
    "corky",
    "bright orange-red",
    "flaky",
    "thin",
    "yellowing",
    "twisting",
    "reaching",
    "curling",
    "straight",
    "resinous",
    "tranquil",
    "serene",
    "enchanting",
    "whispering",
    "murmuring",
    "soughing",
    "soothing",
    "ancient",
    "stately",
    "majestic",
    "towering",
    "imposing",
    "foreboding",
    "ominous",
    "fearsome",
    "hideous",
    "dense",
    "shadowy",
    "secretive",
    "long",
    "stalwart",
    "ethereal",
    "peaceful",
    "glorious",
    "resilient",
    "stately",
    "prideful",
    "sun-hailed",
    "curved",
    "aged",
    "benign",
    "benevolent",
    "authoritative",
    "sentinel",
]

export const inglenookLeafTypes = [
	"thin",
    "needling",
    "prickling",
    "shooting",
    "tenacious",
    "bunched",
    "sublime",
    "jaw-dropping",
    "clumping",
    "copious",
    "abundant",
    "sharp",
    "lush",
    "abundant",
    "verdant",
    "needle-like",
    "hearty",
    "abundant",
    "resourceful",
    "wily",
    "cunning",
    "knowing",
    "sagacious",
    "thoughtful",
    "ponderous",
    "vintage",
    "weathered",
    "antique",
    "trailing",
    "glittering",
    "sap-laden",
    "harrying",
    "secluded",
    "private",
    "deeply veined",
    "broad",
    "lustrous",
    "uniform",
    "thick",
    "smooth",
    "rich green",
    "glossy",
    "healthy"
]