export const mangoSmellTypes = [
	"sticky",
    "sweet",
    "fragrant",
    "aromatic",
    "lush",
    "juicy",
    "sweet",
    "fruity",
    "fragrant",
    "juicy",
    "aromatic",
    "intoxicating",
    "balsamic",
    "honeyed",
    "fruity",
    "juicy",
    "Sweet",
    "Aromatic",
    "Exotic",
    "Buttery",
    "Resinous",
    "Rich",
    "Balsamic",
    "Intense",
    "Sweet",
    "unripe and fibrous",
    "of watery fruit",
    "of sour sweat",
    "tart",
    "of small change",
    "of head bowed shuffling",
    "of fruit and rich wine",
    "questionable",
    "of sweet frangipani",
    "of cold rice water",
    "sickly",
    "of spiced fruits",
    "of a day free of errands",
    "of vented warm air",
    "of dessert for breakfast",
    "fun... or pretending to have fun",
    "rancid",
    "of overripe flesh",
    "a bathhouse",
    "of discarded peels",
    "tart and milky",
    "stomach turning",
    "of sad citrus",
    "of jammy wood pulp",
    "of a sadistic colleague",
    "of a splinter of terror",

]

export const mangoBranchTypes = [
	"fragrant",
    "durable",
    "fibrous",
    "fly-riddled",
    "Vibrant",
    "hard",
    "lush",
    "long",
    "fruits dangling from its",
    "unripe pods hanging from its",
    "egg-shaped fruit on its",
    "irritant sap in its",
    "upright",
    "canopy-breaking",
    "monsoon-quaffed roots and tall",
    "mild",
    "bejewelled",
    "many-fruited",
    "gift-laden",
    "comforting",
    "storm-felled",
    "gloomy",
    "grim",
    "trusty",
    "renowned",
    "sap-flecked",
]

export const mangoLeafTypes = [
	"waxy",
    "verdant",
    "lush",
    "green",
    "broad",
    "shaped",
    "pointed",
    "veined",
    "smooth",
    "thick",
    "green",
    "waxy",
    "veined",
    "tender",
    "aromatic",
    "spicy",
    "resinous",
    "veined",
    "long",
    "pointed",
    "pinnate",
    "superior",
    "inferior",
    "ovate",
    "elliptical",
    "veined",
    "protuberant",
    "lobed",
    "ovoid",
    "glabrous",
    "coriaceous",
    "rust-colored",
    "Long",
    "Lanceolate",
    "Toothed",
    "Glabrous",
    "Pale green",
    "Petiole"
    "Umbrella",
    "simple",
    "shiny",
    "ruched",
    "non-wavy",
    "oblong",
    "verdure",
]