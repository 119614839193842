export const primevalTreeSmellTypes = [
    "rotting",
    "dank",
    "dark",
    "foreboding",
    "ghostly",
    "eerie",
    "of musk",
    "of rain",
    "of wood",
    "of spruce",
    "of cedar",
    "of juniper",
    "of fir",
    "weathered",
    "ancient",
    "sweet",
    "musky",
    "earthy",
    "pungent",
    "ancient",
    "powerful",
    "protective",
    "sheltering",
    "botanical",
    "of untainted grounds",
    "of shaken roots",
    "of years uninterrupted",
    "of lofty seasons",
    "of inked parchment",
    "of moss-bound tomes",
    "of yeast and preserved fruit",
    "of kombu",
    "of sugared sap",
    "of liquified leaf matter",
    "of dirt and gourd flesh",
    "of lemon and caraway",
    "of custard topped with mace",
    "of rich, endless woodlands",
    "of lukewarm tea and honey",
    "of hidden others waiting nearby",
    "of notched steel-tipped blades",
    "of resinous lemon",
    "of fungal circuitboards",
    "of distorted blackcurrant",
    "of grass and running water",
    "soft and sensuous",
    "of moss-laden limbs",
    "of blackberries and tonka",
    "of unsullied ages",
    "of collected blooms",
    "of a synthetic orchard",
]

export const primevalTreeBranchTypes = [
	 "gargantuan",
   "archaic",
   "venerable",
   "colossal",
   "stupendous",
   "leviathan",
   "soughing",
   "a tremendous trunk with branches high up",
   "huge oak-heart under its thick bark and",
   "a vast trunk and knowing",
   "seasons-weather bark and vast",
   "enormous",
   "an autumnal crown and towering",
   "bare",
   "brittle",
   "dead",
   "tall, out of reach",
   "leafy",
   "gnarled",
   "mossy",
   "skeletal",
   "twisty",
   "gigantic",
   "sturdy",
   "ancient",
   "mossy",
   "tangled",
   "dark",
   "foreboding",
   "imposing",
   "mysterious",
   "magical",
   "Gloomy",
   "Sturdy",
   "Aged",
   "Intimidating",
   "gnarled",
   "giant",
   "kingly",
   "twisted",
   "dark",
   "foreboding",
   "old",
   "gnarled",
   "bark peeling from its",
   "thin",
   "wobbling",
   "thick",
   "long",
   "strong",
   "sturdy",
   "scholarly",
   "somewhat plausible",
   "sky-spearing",
   "ochre",
   "dun",
   "spindly",
   "knotted",
   "contorted",
   "vaulted",
   "servestack-sized",
]

export const primevalTreeLeafTypes = [
	  "Old",
    "Green",
    "Thick",
    "flaking",
    "deteriorating",
    "crisp",
    "Juicy",
    "Peeling",
    "Spotted",
    "Dangling",
    "Bobbing",
    "patterned",
    "drooping",
    "Amber",
    "brittle",
    "dead",
    "dry",
    "fallen",
    "old",
    "ossified",
    "yellowed",
    "tawny",
    "Green",
    "Yellow",
    "Brown",
    "Orange",
    "ancient",
    "giant",
    "weathered",
    "skeletal",
    "ovoid",
    "vibrant",
    "lush",
    "green",
    "waxy",
    "broad",
    "leathery",
    "glistening",
    "veined",
    "spiny",
    "toothed",
    "pleated",
    "star-shaped",
    "insect-chewed",
    "flame-flecked",
    "shimmering",
    "wind-caught",
    "gall-bitten",
    "oddly shadowless",
    "popping",
    "hatched",
]