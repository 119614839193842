import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import { createNewCharacterLevelAsync, upgradeCharacterLevelAsync } from '../../redux/actions/character-levels.actions';
import { disableKeyboardMovement, enableKeyboardMovement, showHelp, startLooking, hideAllMenus, startAiming } from '../../redux/actions/keyboard-shortcuts.actions';
import { activateBrainchipAsync } from '../../redux/actions/brainchip.actions';
import {
    selectCharacter,
    selectCharacterLevels,
    selectSkills,
    selectBrainchips,
} from '../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';

import { BRAINCHIP_TYPE_NAMES } from '../../services/Tilemap.service';
import { client } from '../../services/client';

import './Sheet.css';

import Menu from '../utils/menu/Menu';

class Sheet extends React.Component {
    state = {
        isSkillsListActive: true,
        isBrainchipListActive: false
    }

    submitHandler = (event) => {
        if (this.props.isDisabled) {
            return;
        }

        if (event.key === 'ArrowLeft') {
            this.setState({
                isSkillsListActive: true,
                isBrainchipListActive: false
            })
        }

        if (event.key === 'ArrowRight') {
            this.setState({
                isSkillsListActive: false,
                isBrainchipListActive: true
            })
        }
    };

    activateBrainchipAbility(option) {
        if (option.brainchipType.name === BRAINCHIP_TYPE_NAMES.FIRE_BREATH) {
            this.props.hideAllMenus();
            setTimeout(() => {
                this.props.startAiming({ type: BRAINCHIP_TYPE_NAMES.FIRE_BREATH, position: this.props.character.position });
            })
            return;
        }

        if (option.brainchipType.name === BRAINCHIP_TYPE_NAMES.TELEPORT) {
            this.props.activateBrainchipAsync({
                ...option
            }).then(() => (window.location.reload()))
            return;
        }

        this.props.activateBrainchipAsync({
            ...option
        })
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.submitHandler);
    }

    componentDidMount() {
        
    }

    constructor() {
        super();

        document.addEventListener('keydown', this.submitHandler)
    }

    render() {
        const levelUpExperiencePoints = Math.pow((this.props.character?.level || 1) + 1, 3) * 10 + 200;
        return (
            <div className="character-sheet">
                {(
                    <div className="sheet">
                        <div className="sheet-sidebar">
                            <div className="skills-container">
                                <Menu
                                    options={this.props.characterLevels}
                                    isShortcutHidden={true}
                                    menuContainer="character-sheet"
                                    optionChosen={(option) => (this.upgradeSkill(option))}
                                    isDisabled={!this.state.isSkillsListActive}
                                />
                                <Menu
                                    options={this.props.characterLevels?.map(level => ({ ...level, text: level.level }))}
                                    isShortcutHidden={true}
                                    isDisabled={!this.state.isSkillsListActive}
                                />
                            </div>

                            <div className="status">
                                <div>
                                    <span className="dd">HP:</span>
                                    <span className="dt value">&nbsp;{this.props.character.healthPoints || 20}</span> / <span className="value">{this.props.character.maxHealthPoints || 20}</span>
                                </div>

                                <div>
                                    <span className="dd">Level:</span>
                                    <span className="dt value">&nbsp;{this.props.character.level || 1}</span>
                                </div>
                                
                                <div>
                                    <span className="dd">XP: </span>
                                    <span className="dt value"> {this.props.character.experiencePoints || 0}</span> <span className="explanation-text">(</span><span className="value">{levelUpExperiencePoints - (this.props.character.experiencePoints || 0)}</span><span className="explanation-text"> until next level)</span>
                                </div>
                            </div>

                            { this.state.isBrainchipListActive && this.props.brainchips?.length > 0 ? (
                                <div className="sheet-actions">
                                    <div>
                                        <span className="dd shortcut">SPACE</span> -
                                        <span className="dt"> Activate selected brainchip ability</span>
                                    </div>
                                </div>
                            ) : ('')}

                        </div>

                        <div className="brainchip-container-container">
                            <p className="brainchip-title-container">[ <span className="brainchip-title">Brainchip Enhancements</span> ]</p>

                            <div className="brainchip-container">

                                <div className="brainchip-menus-container">
                                    <Menu
                                        options={this.props.brainchips}
                                        isShortcutHidden={true}
                                        menuContainer="brainchip-container"
                                        offsetTop={140}
                                        isDisabled={!this.state.isBrainchipListActive}
                                        optionChosen={(option) => (this.activateBrainchipAbility(option))}
                                    />

                                    <Menu
                                        options={this.props.brainchips.map((brainchip) => {
                                            if (brainchip.isActive && brainchip.activeCount) {
                                                return { ...brainchip, text: `${brainchip.brainchipType.level} [active until ${brainchip.activeCount}]` }
                                            }

                                            if (brainchip.cooldownCount) {
                                                return { ...brainchip, text: `${brainchip.brainchipType.level} [cooldown in ${brainchip.cooldownCount}]` }
                                            }

                                            if (brainchip.cooldownAt > new Date().getTime()) {
                                                return { ...brainchip, text: `${brainchip.brainchipType.level} [cooldown in ${new Date(brainchip.cooldownAt)} seconds]` }
                                            }

                                            return { ...brainchip, text: brainchip.brainchipType.level || 1 }
                                        })}
                                        isShortcutHidden={true}
                                        menuContainer="brainchip-container"
                                        offsetTop={140}
                                        isDisabled={!this.state.isBrainchipListActive}
                                        optionChosen={(option) => ({})}
                                    />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        )
    }
}

const mapStateToProps = state => {
    const character = selectCharacter(state)
    const characterLevels = selectCharacterLevels(state).map(level => ({ ...level, text: level.skillType?.name }));
    const skills = selectSkills(state).map(skill => ({ ...skill, text: skill.name }));
    const brainchips = selectBrainchips(state).map(level => ({ ...level, text: level.brainchipType?.name }));

    console.log('brainchips', brainchips)

    return {
        character,
        characterLevels,
        skills,
        brainchips
    }
}

export default connect(
    mapStateToProps,
    {
        hideAllMenus,
        createNewCharacterLevelAsync,
        upgradeCharacterLevelAsync,
        activateBrainchipAsync,
        startAiming
    }
)(Sheet);