import React from "react";
import { connect } from 'react-redux'

import { addFoodToTileAsync, eatFoodAsync, deleteFoodAsync, addFoodToCharacterAsync } from '../../redux/actions/food.actions';
import { addMaterialToTileAsync, addMaterialToCharacterAsync } from '../../redux/actions/inventory.actions';
import { tileTypes, createTileAsync, addTileToGroundAsync, addTileToCharacterAsync, removeTile } from '../../redux/actions/tile.actions';
import { addMineralToTileAsync, addMineralToCharacterAsync } from '../../redux/actions/mineral.actions';
import { addToolToTileAsync, addToolToCharacterAsync } from '../../redux/actions/tool.actions';
import { addMetalToTileAsync, addMetalToCharacterAsync } from '../../redux/actions/metal.actions';
import { addKeyToTileAsync, copyKeyAsync, addKeyToCharacterAsync } from '../../redux/actions/key.actions';
import { addWritingSurfaceToTileAsync, editWritingSurfaceAsync, addWritingSurfaceToCharacterAsync } from '../../redux/actions/writing-surface.actions';
import { addWritingImplementToTileAsync, addWritingImplementToCharacterAsync } from '../../redux/actions/writing-implement.actions';
import { addWeaponToTileAsync, addWeaponToCharacterAsync } from '../../redux/actions/weapon.actions';
import { addArmourToTileAsync, addArmourToCharacterAsync } from '../../redux/actions/armour.actions';
import { addClothingToTileAsync, addClothingToCharacterAsync } from '../../redux/actions/clothing.actions';
import { addJewelleryToTileAsync, addJewelleryToCharacterAsync } from '../../redux/actions/jewellery.actions';
import { addTentToCharacterAsync, addTentToTileAsync } from '../../redux/actions/tent.actions';
import { addLockToCharacterAsync, addLockToTileAsync, installLockAsync } from '../../redux/actions/lock.actions';
import { updateTradeAsync } from '../../redux/actions/trade.actions';
import {
    equipToolAsync,
    equipWeaponAsync,
    equipArmourAsync,
    equipClothingAsync,
    equipJewelleryAsync,
    unequipClothingAsync,
    unequipJewelleryAsync, 
    equipTentLocally, 
    unequipTentLocally
} from '../../redux/actions/character.actions';
import { showQuantityInput, hideQuantityInput, chooseCharacterToGiveTo, hideCharacterList, closeInventory } from '../../redux/actions/keyboard-shortcuts.actions';
import { getKeyDescription } from '../side-bar/looking/key-description'


import Menu from '../utils/menu/Menu';
import TreeMenu from '../utils/tree-menu/TreeMenu';
import { getCondition, getTorchDurationString } from '../side-bar/looking/Looking'
import { BRAINCHIP_TYPE_NAMES } from '../../services/Tilemap.service';

import {
    selectInventory,
    selectPartnerTradeInventory,
    selectPartnerTradeFood,
    selectPartnerTradeTools,
    selectPartnerTradeMetals,
    selectPartnerTradeMinerals,
    selectPartnerTradeKeys,
    selectPartnerTradeWritingSurfaces,
    selectPartnerTradeWritingImplements,
    selectPartnerTradeWeapons,
    selectPartnerTradeArmour,
    selectPartnerTradeClothing,
    selectPartnerTradeJewellery,
    selectPartnerTradeTents,
    selectPartnerTradeLocks,
    selectPartnerTradeTiles,
    selectTiles,
    selectCharacter,
    selectTileTypeByName,
    selectIsQuantityInputOpen,
    selectIsCharacterListShowing,
    selectNeighbouringCharacters,
    selectTileInventory,
    selectTileFood,
    selectTileMinerals,
    selectTileTools,
    selectTileMetals,
    selectTileKeys,
    selectTileWritingSurfaces,
    selectTileWritingImplements,
    selectTileWeapons,
    selectTileArmour,
    selectTileClothing,
    selectTileJewellery,
    selectTileTents,
    selectTileLocks,
    selectFocussedCharacterId,
    selectCharacterById,
} from '../../redux/selectors';
import EditWritingSurface from '../inventory/edit-writing-surface/EditWritingSurface';
import QuantityInput from '../utils/quantity-input/QuantityInput';
import DirectionInput from '../utils/direction-input/DirectionInput';
import CharacterList from '../actions/character-list/CharacterList';

import { client } from '../../services/client';

const MAX_CHARACTER_OR_FLOOR_WEIGHT = 1000;

class PartnerTrade extends React.Component {
    totalWeight = 0;
    onQuantitySupplied;
    onQuantityClosed;

    submitHandler = (event) => {
        if (this.props.isDisabled) {
            return;
        }

        if (event.key === 'ArrowRight') {
            this.onOptionChosen(undefined);
            this.setState({
                isDisabled: true
            })

            return;
        }

        if (event.key === 'ArrowLeft') {
            this.setState({
                isDisabled: false,
            })

            return;
        }

        if (event.key === '.') {
            this.onOptionChosen(undefined);
            this.setState({
                items: [],
            })

            return;
        }
    };

    getItemTileAtPosition(position) {
        return Object.values(this.props.tiles.byId).find(tile =>
            tile?.position?.x === position.x
            && tile?.position?.y === position.y
            && tile?.tileType?.name !== tileTypes.FARM_PLOT
        )
    }

    getWeight(item, itemTypeKey, isTileWeightCalculation) {
        if (isTileWeightCalculation) {
            return (item.quantity || 1) * item[itemTypeKey]?.weight;
        }
        this.totalWeight += (item.quantity || 1) * item[itemTypeKey]?.weight;
        return `${(item.quantity || 1) * item[itemTypeKey]?.weight}u`;
    }

    getQuantity(item) {
        if (Number(item.quantity) > 1) {
            this.setState({ isQuantityInputShowing: true, maxQuantity: item.quantity });
            this.props.showQuantityInput();
        }

        return new Promise((resolve, reject) => {
            if (Number(item.quantity) === 1) {
                return resolve(1);
            }

            this.onQuantitySupplied = (quantity) => {
                this.props.hideQuantityInput();
                this.setState({isQuantityInputShowing: false})
                resolve(Number(quantity));
            }

            this.onQuantityClosed = () => {
                this.props.hideQuantityInput();
                this.setState({isQuantityInputShowing: false})
                reject('No quantity supplied');
            }
        });
    }

    onAddItemToTrade(serviceName, item) {
        this.setState({
            isTradeAdjusted: true
        })

        const previousTradeOffer = this.state.items.find(foundItem => foundItem._id === item._id);
        const newTradeOfferCount = previousTradeOffer?.tradeOfferCount ? previousTradeOffer.tradeOfferCount === previousTradeOffer.quantity ? previousTradeOffer.quantity : previousTradeOffer.tradeOfferCount + 1 : 1;

        this.setState({
            items: [
                ...this.state.items?.filter(filteredItem => (item._id !== filteredItem._id)),
                { ...item, serviceName, tradeOfferCount: newTradeOfferCount }
            ]
        })

        this.props.updateTradeOffer(this.state.items);
    }

    onRemoveItemFromTrade(serviceName, item) {
        this.setState({
            isTradeAdjusted: true
        })

        const previousTradeOffer = this.state.items.find(foundItem => foundItem._id === item._id);
        const newTradeOfferCount = previousTradeOffer?.tradeOfferCount ? previousTradeOffer.tradeOfferCount - 1 : 0

        this.setState({
            items: [
                ...this.state.items?.filter(filteredItem => (item._id !== filteredItem._id)),
                { ...item, serviceName, tradeOfferCount: newTradeOfferCount }
            ]
        })

        this.props.updateTradeOffer(this.state.items);
    }

    constructor() {
        super();
        this.state = {
            isQuantityInputShowing: false,
            isDisabled: true,
            onCharacterSelected: () => {},
            items: []
        };

        document.addEventListener('keydown', this.submitHandler)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.submitHandler);
    }

    componentDidMount() {
    }

    onOptionChosen(option) {
        if (this.props.onSelectedItem) {
            this.props.onSelectedItem(option);
        }
    }

    correctShortcutKeys(newConversationTree) {
        let index = 0;

        return newConversationTree.map((option) => {
            const newShortcut = String.fromCharCode(97 + index)
            index++;

            return {
                ...option,
                childOptions: option.childOptions.map((option) => {
                    const newShortcut = String.fromCharCode(97 + index)
                    index++;
                    return {...option, shortcut: newShortcut }
                }),
                shortcut: newShortcut
            }
        })
    }

    render() {
        this.totalWeight = 0;        

        const defaultActions = {
            ',': {
                callback: () => (console.log('a')),
                text: 'Reject trade'
            },
            '.': {
                callback: () => (console.log('b')),
                text: 'Offer trade'
            },
            '/': {
                callback: () => (console.log('c')),
                text: 'Accept trade'
            }
        }

        if (this.props.totalPrice) {
            defaultActions[']'] = {
                callback: () => (console.log('d')),
                text: `Buy items (${this.props.totalPrice})`
            }
        }

        if (this.props.existingOffer) {
            delete defaultActions['.']

            if (this.props.isCharacterLastOfferer) {
                defaultActions[','].text = 'Remove trade';
                delete defaultActions['/']
            }
        } else {
            delete defaultActions[',']
            delete defaultActions['/']
        }

        const tiles = Object.values(this.props.containers).map((tile, index) => {
            const materials = {
                text: 'Materials',
                childOptions: tile.inventory.map((material, index) => {
                            // {material?.materialType?.name}
                            // &nbsp;{(material?.plant?.name || material?.animal?.name || material?.fish?.name) && `(${material?.plant?.name || material?.animal?.name || material?.fish?.name})`}
                    return (
                        {
                            ...material,
                            text: material?.materialType?.name,
                            condition: getCondition(material),
                            hintClassName: 'quantity',
                            hint:material.markedForTradeCount || 1,
                            markedForTradeCount: this.state.items?.find(item => (item._id === material._id))?.tradeOfferCount,
                            tradeOfferCount: this.props.existingOffer?.find(item => (item._id === material._id))?.tradeOfferCount,
                            isMarkedWithPrice: !!material.tradePrice,
                            weight: this.getWeight(material, 'materialType')
                        }
                    );
                }),
                actions: {
                    ...defaultActions,
                }
            }

            if (!this.props.existingOffer) {
                materials.actions = {
                    ...materials.actions,
                    '+': {
                        callback: (item) => (this.onAddItemToTrade('material-instances', item)),
                        text: 'Add item'
                    },
                    '-': {
                        callback: (item) => (this.onRemoveItemFromTrade('material-instances', item)),
                        text: 'Remove item'
                    },
                }
            }

            const foods = {
                text: 'Food',
                childOptions: tile.food.map((food, index) => {
                    let text = food?.foodType?.name

                    const brainchipEffect = BRAINCHIP_TYPE_NAMES[food?.effect];

                    if (brainchipEffect) {
                        text = `${text} [${brainchipEffect}]`
                    }
                    return (
                        {
                            ...food,
                            text,
                            condition: getCondition(food),
                            hintClassName: 'quantity',
                            hint:food.markedForTradeCount || 1,
                            markedForTradeCount: this.state.items?.find(item => (item._id === food._id))?.tradeOfferCount,
                            tradeOfferCount: this.props.existingOffer?.find(item => (item._id === food._id))?.tradeOfferCount,
                            isMarkedWithPrice: !!food.tradePrice,
                            weight: this.getWeight(food, 'foodType')
                        }
                    );
                }),
                actions: {
                    ...defaultActions,
                    
                }
            }

            if (!this.props.existingOffer) {
                foods.actions = {
                    ...foods.actions,
                    '+': {
                        callback: (item) => (this.onAddItemToTrade('food-instances', item)),
                        text: 'Add item'
                    },
                    '-': {
                        callback: (item) => (this.onRemoveItemFromTrade('food-instances', item)),
                        text: 'Remove item'
                    },
                }
            }

            const minerals = {
                text: 'Minerals',
                childOptions: tile.minerals.map((mineral, index) => {
                    return (
                        {
                            ...mineral,
                            text: mineral?.mineralType?.name,
                            condition: getCondition(mineral),
                            hintClassName: 'quantity',
                            hint:mineral.quantity || 1,
                            markedForTradeCount: mineral.markedForTradeCount,
                            tradeOfferCount: this.props.existingOffer?.find(item => (item._id === mineral._id))?.tradeOfferCount,
                            isMarkedWithPrice: !!mineral.tradePrice,
                            weight: this.getWeight(mineral, 'mineralType')
                        }
                    );
                }),
                actions: {
                    ...defaultActions,
                }
            }

            if (!this.props.existingOffer) {
                minerals.actions = {
                    ...minerals.actions,
                    '+': {
                        callback: (item) => (this.onAddItemToTrade('mineral-instances', item)),
                        text: 'Add item'
                    },
                    '-': {
                        callback: (item) => (this.onRemoveItemFromTrade('mineral-instances', item)),
                        text: 'Remove item'
                    },
                }
            }

            const tools = {
                text: 'Tools',
                childOptions: tile.tools.map((tool, index) => {
                    const torchDurationString = getTorchDurationString(tool, this.props.serverTimeOffset, false);
                    const text = `${tool?.toolType?.name}`
                    return (
                        {
                            ...tool,
                            text,
                            condition: torchDurationString ? torchDurationString : getCondition(tool),
                            hintClassName: 'quantity',
                            hint:tool.markedForTradeCount || 1,
                            markedForTradeCount: this.state.items?.find(item => (item._id === tool._id))?.tradeOfferCount,
                            tradeOfferCount: this.props.existingOffer?.find(item => (item._id === tool._id))?.tradeOfferCount,
                            isMarkedWithPrice: !!tool.tradePrice,
                            weight: this.getWeight(tool, 'toolType')
                        }
                    )
                }),
                actions: {
                    ...defaultActions,
                }
            }

            if (!this.props.existingOffer) {
                tools.actions = {
                    ...tools.actions,
                    '+': {
                        callback: (item) => (this.onAddItemToTrade('tool-instances', item)),
                        text: 'Add item'
                    },
                    '-': {
                        callback: (item) => (this.onRemoveItemFromTrade('tool-instances', item)),
                        text: 'Remove item'
                    },
                }
            }

            const metals = {
                text: 'Metals',
                childOptions: tile.metals.map((metal, index) => {
                    return {
                        ...metal,
                        text: metal?.metalType?.name,
                        condition: getCondition(metal),
                        hintClassName: 'quantity',
                        hint:metal.markedForTradeCount || 1,
                        markedForTradeCount: this.state.items?.find(item => (item._id === metal._id))?.tradeOfferCount,
                        tradeOfferCount: this.props.existingOffer?.find(item => (item._id === metal._id))?.tradeOfferCount,
                        isMarkedWithPrice: !!metal.tradePrice,
                        weight: this.getWeight(metal, 'metalType')

                    }
                }),
                actions: {
                    ...defaultActions,
                }
            }

            if (!this.props.existingOffer) {
                metals.actions = {
                    ...metals.actions,
                    '+': {
                        callback: (item) => (this.onAddItemToTrade('metal-instances', item)),
                        text: 'Add item'
                    },
                    '-': {
                        callback: (item) => (this.onRemoveItemFromTrade('metal-instances', item)),
                        text: 'Remove item'
                    },
                }
            }

            const keys = {
                text: 'Keys',
                childOptions: tile.keys.map((key, index) => {
                    return {
                        ...key,
                        text: `${key?.keyType?.name}(${getKeyDescription(key)})`,
                        condition: getCondition(key),
                        hintClassName: 'quantity',
                        hint:key.markedForTradeCount || 1,
                        markedForTradeCount: this.state.items?.find(item => (item._id === key._id))?.tradeOfferCount,
                        tradeOfferCount: this.props.existingOffer?.find(item => (item._id === key._id))?.tradeOfferCount,
                        isMarkedWithPrice: !!key.tradePrice,
                        weight: this.getWeight(key, 'keyType')

                    }
                }),
                actions: {
                    ...defaultActions,
                }
            }

            if (!this.props.existingOffer) {
                keys.actions = {
                    ...keys.actions,
                    '+': {
                        callback: (item) => (this.onAddItemToTrade('key-instances', item)),
                        text: 'Add item'
                    },
                    '-': {
                        callback: (item) => (this.onRemoveItemFromTrade('key-instances', item)),
                        text: 'Remove item'
                    },
                }
            }

            const writingSurfaces = {
                text: 'Writing Surfaces',
                childOptions: tile.writingSurfaces.map((writingSurface, index) => {
                    return {
                        ...writingSurface,
                        text: writingSurface?.writingSurfaceType?.name,
                        condition: getCondition(writingSurface),
                        hintClassName: 'quantity',
                        hint:writingSurface.markedForTradeCount || 1,
                        markedForTradeCount: this.state.items?.find(item => (item._id === writingSurface._id))?.tradeOfferCount,
                        tradeOfferCount: this.props.existingOffer?.find(item => (item._id === writingSurface._id))?.tradeOfferCount,
                        isMarkedWithPrice: !!writingSurface.tradePrice,
                        weight: this.getWeight(writingSurface, 'writingSurfaceType')
                    }
                }),
                actions: {
                    ...defaultActions,
                }
            }

            if (!this.props.existingOffer) {
                writingSurfaces.actions = {
                    ...writingSurfaces.actions,
                    '+': {
                        callback: (item) => (this.onAddItemToTrade('writing-surface-instances', item)),
                        text: 'Add item'
                    },
                    '-': {
                        callback: (item) => (this.onRemoveItemFromTrade('writing-surface-instances', item)),
                        text: 'Remove item'
                    },
                }
            }

            const writingImplements = {
                text: 'Writing Implements',
                childOptions: tile.writingImplements.map((writingImplement, index) => {
                    return {
                        ...writingImplement,
                        text: writingImplement?.writingImplementType?.name,
                        condition: getCondition(writingImplement),
                        hintClassName: 'quantity',
                        hint:writingImplement.markedForTradeCount || 1,
                        markedForTradeCount: this.state.items?.find(item => (item._id === writingImplement._id))?.tradeOfferCount,
                        tradeOfferCount: this.props.existingOffer?.find(item => (item._id === writingImplement._id))?.tradeOfferCount,
                        isMarkedWithPrice: !!writingImplement.tradePrice,
                        weight: this.getWeight(writingImplement, 'writingImplementType')
                    }
                }),
                actions: {
                    ...defaultActions,
                }
            }

            if (!this.props.existingOffer) {
                writingImplements.actions = {
                    ...writingImplements.actions,
                    '+': {
                        callback: (item) => (this.onAddItemToTrade('writing-implement-instances', item)),
                        text: 'Add item'
                    },
                    '-': {
                        callback: (item) => (this.onRemoveItemFromTrade('writing-implement-instances', item)),
                        text: 'Remove item'
                    },
                }
            }

            const weapons = {
                text: 'Weapons',
                childOptions: tile.weapons.map((weapon, index) => {
                    const combatValues = ` ${weapon.weaponType.damage}ϴ ${weapon.weaponType?.toHit}ϰ ${weapon.weaponType?.penetration}➚`
                    let text = `${weapon?.weaponType?.name} ${combatValues}`
                    return {
                        ...weapon,
                        text,
                        condition: getCondition(weapon),
                        hintClassName: 'quantity',
                        hint:weapon.markedForTradeCount || 1,
                        markedForTradeCount: this.state.items?.find(item => (item._id === weapon._id))?.tradeOfferCount,
                        tradeOfferCount: this.props.existingOffer?.find(item => (item._id === weapon._id))?.tradeOfferCount,
                        isMarkedWithPrice: !!weapon.tradePrice,
                        weight: this.getWeight(weapon, 'weaponType')
                    }
                }),
                actions: {
                    ...defaultActions,
                }
            }

            if (!this.props.existingOffer) {
                weapons.actions = {
                    ...weapons.actions,
                    '+': {
                        callback: (item) => (this.onAddItemToTrade('weapon-instances', item)),
                        text: 'Add item'
                    },
                    '-': {
                        callback: (item) => (this.onRemoveItemFromTrade('weapon-instances', item)),
                        text: 'Remove item'
                    },
                }
            }

            const armours = {
                text: 'Armour',
                childOptions: tile.armour.map((armour) => {
                    return {
                        ...armour,
                        text: armour?.armourType?.name,
                        condition: getCondition(armour),
                        hintClassName: 'quantity',
                        hint:armour.markedForTradeCount || 1,
                        markedForTradeCount: this.state.items?.find(item => (item._id === armour._id))?.tradeOfferCount,
                        tradeOfferCount: this.props.existingOffer?.find(item => (item._id === armour._id))?.tradeOfferCount,
                        isMarkedWithPrice: !!armour.tradePrice,
                        weight: this.getWeight(armour, 'armourType')
                    }
                }),
                actions: {
                    ...defaultActions,
                }
            }

            if (!this.props.existingOffer) {
                armours.actions = {
                    ...armours.actions,
                    '+': {
                        callback: (item) => (this.onAddItemToTrade('armour-instances', item)),
                        text: 'Add item'
                    },
                    '-': {
                        callback: (item) => (this.onRemoveItemFromTrade('armour-instances', item)),
                        text: 'Remove item'
                    },
                }
            }

            const clothing = {
                text: 'Clothing',
                childOptions: tile.clothing.map((clothing, index) => {
                    const combatValues = `${clothing.clothingType?.armour}❤ ${clothing.clothingType?.dodge}❥`
                    const text = `${clothing?.clothingType?.name} ${combatValues}`
                    return {
                        ...clothing,
                        text,
                        condition: getCondition(clothing),
                        hintClassName: 'quantity',
                        hint:clothing.markedForTradeCount || 1,
                        markedForTradeCount: this.state.items?.find(item => (item._id === clothing._id))?.tradeOfferCount,
                        tradeOfferCount: this.props.existingOffer?.find(item => (item._id === clothing._id))?.tradeOfferCount,
                        isMarkedWithPrice: !!clothing.tradePrice,
                        weight: this.getWeight(clothing, 'clothingType')
                    }
                }),
                actions: {
                    ...defaultActions,
                }
            }

            if (!this.props.existingOffer) {
                clothing.actions = {
                    ...clothing.actions,
                    '+': {
                        callback: (item) => (this.onAddItemToTrade('clothing-instances', item)),
                        text: 'Add item'
                    },
                    '-': {
                        callback: (item) => (this.onRemoveItemFromTrade('clothing-instances', item)),
                        text: 'Remove item'
                    },
                }
            }

            const jewellery = {
                text: 'Jewellery',
                childOptions: tile.jewellery.map((jewellery, index) => {
                    return {
                        ...jewellery,
                        text: jewellery?.jewelleryType?.name,
                        condition: getCondition(jewellery),
                        hintClassName: 'quantity',
                        hint:jewellery.markedForTradeCount || 1,
                        markedForTradeCount: this.state.items?.find(item => (item._id === jewellery._id))?.tradeOfferCount,
                        tradeOfferCount: this.props.existingOffer?.find(item => (item._id === jewellery._id))?.tradeOfferCount,
                        isMarkedWithPrice: !!jewellery.tradePrice,
                        weight: this.getWeight(jewellery, 'jewelleryType')
                    }
                }),
                actions: {
                    ...defaultActions,
                }
            }

            if (!this.props.existingOffer) {
                jewellery.actions = {
                    ...jewellery.actions,
                    '+': {
                        callback: (item) => (this.onAddItemToTrade('jewellery-instances', item)),
                        text: 'Add item'
                    },
                    '-': {
                        callback: (item) => (this.onRemoveItemFromTrade('jewellery-instances', item)),
                        text: 'Remove item'
                    },
                }
            }

            const tents = {
                text: 'Tents',
                childOptions: tile.tents.map((tent, index) => {
                    return {
                        ...tent,
                        text: tent?.tentType?.name,
                        condition: getCondition(tent),
                        hintClassName: 'quantity',
                        hint:tent.markedForTradeCount || 1,
                        markedForTradeCount: this.state.items?.find(item => (item._id === tent._id))?.tradeOfferCount,
                        tradeOfferCount: this.props.existingOffer?.find(item => (item._id === tent._id))?.tradeOfferCount,
                        isMarkedWithPrice: !!tent.tradePrice,
                        weight: this.getWeight(tent, 'tentType')
                    }
                }),
                actions: {
                    ...defaultActions,
                }
            }

            if (!this.props.existingOffer) {
                tents.actions = {
                    ...tents.actions,
                    '+': {
                        callback: (item) => (this.onAddItemToTrade('tent-instances', item)),
                        text: 'Add item'
                    },
                    '-': {
                        callback: (item) => (this.onRemoveItemFromTrade('tent-instances', item)),
                        text: 'Remove item'
                    },
                }
            }

            const locks = {
                text: 'Locks',
                childOptions: tile.locks.map((lock, index) => {
                    return {
                        ...lock,
                        text: lock?.lockType?.name,
                        condition: getCondition(lock),
                        hintClassName: 'quantity',
                        hint:lock.markedForTradeCount || 1,
                        markedForTradeCount: this.state.items?.find(item => (item._id === lock._id))?.tradeOfferCount,
                        tradeOfferCount: this.props.existingOffer?.find(item => (item._id === lock._id))?.tradeOfferCount,
                        isMarkedWithPrice: !!lock.tradePrice,
                        weight: this.getWeight(lock, 'lockType')
                    }
                }),
                actions: {
                    ...defaultActions,
                }
            }

            if (!this.props.existingOffer) {
                locks.actions = {
                    ...locks.actions,
                    '+': {
                        callback: (item) => (this.onAddItemToTrade('lock-instances', item)),
                        text: 'Add item'
                    },
                    '-': {
                        callback: (item) => (this.onRemoveItemFromTrade('lock-instances', item)),
                        text: 'Remove item'
                    },
                }
            }

            let options = [materials, foods, tools, minerals, metals, keys, writingSurfaces, writingImplements, weapons, armours, clothing, jewellery, tents, locks].filter(option => (option.childOptions?.length > 0));

            options = this.correctShortcutKeys(options);

            return (
                <div className="trade-menu-outer" key={index}>
                    {
                        tile.tileType?.name && tile.tileType?.name !== 'ITEM' && tile.tileType?.name !== 'FARM_PLOT' &&
                        (
                            <div className="inventory-item">
                                <div className="detail" onClick={() => (this.setState({ [tile._id]: !this.state[tile._id] }))}>
                                    <span className={this.state[tile._id] ? "showing-option" : "hidden-option"}>[-]</span>
                                    <span className={this.state[tile._id] ? "hidden-option" : "showing-option"}>[+]</span>
                                    {tile.tileType?.name}
                                </div>
                                <div className="inventory-ctas">
                                    <span onClick={() => (this.onContainerDrop(tile))} className={this.state.isLoading ? "drop--loading" : ""} >Drop</span>
                                    &nbsp;<span className={this.props.neighbouringCharacters?.length > 0 ? "" : "hidden"} onClick={() => (this.onContainerGive(tile))}>Give</span>
                                </div>
                            </div>
                        )
                    }
                    {
                        (this.state[tile._id] || !tile.tileType) ?
                        (
                            <div className="partner-trade-menu-container">
                                <TreeMenu
                                    isDisabled={this.state.isDisabled}
                                    options={
                                        options
                                    }
                                    optionChosen={(option) => this.onOptionChosen(option, options)}
                                    menuContainer="partner-trade-menu-container"
                                />
                            </div>
                        ): ('')
                    }
                </div>
            )
        })

        let partnerName = this.props.focussedCharacter?.name

        return (
            <div className="my-inventory">
                <p className="partner-trade-title">&nbsp;[ <span className="title">{partnerName}'s Trade Inventory</span> ]&nbsp;</p>
                {
                    this.props.isDisabled ? ('') : (
                        <ul>{tiles}</ul>
                    )
                }

                {this.state.isEditWritingSurfaceShowing &&
                    <EditWritingSurface
                        text={this.state.writingSurface.text}
                        paints={this.props.containers[this.props.containers.length - 1].inventory.filter(item => (item.materialType?.colourHex))}
                        isReadOnly={this.state.writingSurface.writingSurfaceType.isReadOnly}
                        onSave={this.onSaveWritingSurface.bind(this)}
                        onClose={() => (this.setState({isEditWritingSurfaceShowing: false}))}
                    />
                }

                {this.props.isQuantityInputShowing && this.state.isQuantityInputShowing &&
                    <QuantityInput
                        quantity={this.state.maxQuantity}
                        onQuantitySupplied={this.onQuantitySupplied}
                        onQuantityClosed={this.onQuantityClosed}
                    />
                }

                {this.state.isDirectionInputShowing &&
                    <DirectionInput
                        onDirectionSupplied={this.onDirectionSupplied.bind(this)}
                        onDirectionClosed={this.onDirectionClosed.bind(this)}
                    />
                }

                {this.props.isCharacterListShowing && <CharacterList onItemSelect={this.state.onCharacterSelected.bind(this)} />}

            </div>
        )
    }
}

const mapStateToProps = state => {
    const character = selectCharacter(state);
    const itemTileType = selectTileTypeByName(state, tileTypes.ITEM)

    const inventory = selectPartnerTradeInventory(state)
    const food = selectPartnerTradeFood(state)
    const minerals = selectPartnerTradeMinerals(state);
    const tools = selectPartnerTradeTools(state);
    const metals = selectPartnerTradeMetals(state);
    const keys = selectPartnerTradeKeys(state);
    const writingSurfaces = selectPartnerTradeWritingSurfaces(state);
    const writingImplements = selectPartnerTradeWritingImplements(state);
    const weapons = selectPartnerTradeWeapons(state);
    const armour = selectPartnerTradeArmour(state);
    const clothing = selectPartnerTradeClothing(state);
    const jewellery = selectPartnerTradeJewellery(state);
    const tents = selectPartnerTradeTents(state);
    const locks = selectPartnerTradeLocks(state);
    const containers = selectPartnerTradeTiles(state);

    const focussedCharacterId = selectFocussedCharacterId(state);
    const focussedCharacter = selectCharacterById(state, focussedCharacterId)

    const isQuantityInputShowing = selectIsQuantityInputOpen(state);
    const isCharacterListShowing = selectIsCharacterListShowing(state);
    const neighbouringCharacters = selectNeighbouringCharacters(state, character);

    let tileContents = {};

    containers.forEach(tile => {
        tile.inventory =  [ ...selectTileInventory(state, tile._id) ];
        tile.food =  [ ...selectTileFood(state, tile._id) ];
        tile.minerals =  [ ...selectTileMinerals(state, tile._id) ];
        tile.tools =  [ ...selectTileTools(state, tile._id) ];
        tile.metals =  [ ...selectTileMetals(state, tile._id) ];
        tile.keys =  [ ...selectTileKeys(state, tile._id) ];
        tile.writingSurfaces =  [ ...selectTileWritingSurfaces(state, tile._id) ];
        tile.writingImplements =  [ ...selectTileWritingImplements(state, tile._id) ];
        tile.weapons =  [ ...selectTileWeapons(state, tile._id) ];
        tile.armour =  [ ...selectTileArmour(state, tile._id) ];
        tile.clothing =  [ ...selectTileClothing(state, tile._id) ];
        tile.jewellery =  [ ...selectTileJewellery(state, tile._id) ];
        tile.tents =  [ ...selectTileTents(state, tile._id) ];
        tile.locks =  [ ...selectTileLocks(state, tile._id) ];
    })

    containers.push({
        inventory: inventory.inventory || [],
        food,
        minerals,
        tools,
        metals,
        keys,
        writingSurfaces,
        writingImplements,
        weapons,
        armour,
        clothing,
        jewellery,
        tents,
        locks
    })

    return {
        character,
        itemTileType,
        isQuantityInputShowing,
        isCharacterListShowing,
        neighbouringCharacters,
        containers,
        focussedCharacter
    }
}

export default connect(
    mapStateToProps,
    { }
)(PartnerTrade);