export const liverwortSmellTypes = [
	"dank",
    "musty",
    "pungent",
    "rotting",
    "overwhelming",
    "nauseating",
    "revolting",
    "disgusting",
    "vomit-inducing",
    "of benzoin",
    "of clove",
    "of green rush",
    "of mildew",
    "of chlorophyll",
    "of light",
    "airy",
    "crisp",
    "crunchy",
    "soggy",
    "wet",
    "of off wine",
    "of Primordial ooze",
    "Mossy",
    "Dank",
    "Mildewed",
    "Musty",
    "Stale",
    "of Damp leaves",
    "of Wet soil",
    "of Wet rocks",
    "Pungent",
    "purtrid",
    "Rancid",
    "Foul",
    "bitter",
    "of pine dust on fabric",
    "of blood on snow",
    "of wet canvas",
    "of medicinal rosemary",
    "of champaca and spice",
    "of puffy animal scent",
    "of molten ennui",
    "of filthy creosote",
    "of charred remains",
    "of freshly laid road surface",
]

export const liverwortBranchTypes = [

]

export const liverwortLeafTypes = [
    "green",
    "Velvet",
    "Wet",
    "Dewy",
    "Lively",
    "Vibrant",
    "emerald",
    "Alive",
    "mossy knuckle",
    "fleshy",
    "fat",
    "chunky",
    "verdure",
    "shining",
    "glossy",
    "smooth",
    "cilia-covered",
    "oily",
    "clinging"
    "scrappy",
    "slippery",
    "stone-hugging",
    "wet",
    "wooden",
    "yeasty",
    "slippery",
    "scaly",
    "flattened",
    "spade shaped",
    "scale petal",
    "razor",
    "deeply lobed",
    "patchy",
    "unremarkable",
    "prostrate",
    "ribbon stub",
    "curled",
    "segmented",
    "troublesome",
    "spore-bearing",
    "fungus plants growing from its",
    "nutritious",
    "dune-hugging",
    "bluish-green",
    "plate-like",
    "dish-shaped",
    "white-edged",
    "gross",
    "river-reaching",
    "polygonal",
    "small stalks growing out of its",
    "starred stalks shooting from its",
    "cupped",
    "greenish brown",
    "greenish purple",
    "roots growing from its",
    "metal sucking roots and fleshy",
    "bumpy",
    "textured",
    "flat",
]