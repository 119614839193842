import { GET_PLANT_TYPES_LOADING, GET_PLANT_TYPES_SUCCESS, GET_PLANT_TYPES_FAILURE } from '../actionTypes';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';

export const getPlantTypesSuccess = payload => {
    return ({
        type: GET_PLANT_TYPES_SUCCESS,
        payload
    })
}

export const getPlantTypesFailure = payload => ({
    type: GET_PLANT_TYPES_FAILURE
})

export const getPlantTypesAsync = payload => dispatch => {
	return client.service('plant-types').find()
		.then((response) => {
            dispatch(getPlantTypesSuccess(response));
            return response
		})
        .catch((error) => {
            console.error('error', error);
        })
}