export const lavaBeastFaceSentences = [
"A large, bulbous nose dominates the center of the face, flanked by two small, beady eyes.",
    "A wide mouth full of sharp teeth is framed by two large, antennae-like appendages.",
    "its mouth is always open, revealing large, sharp teeth.",
    "its claws are constantly moving",
    "a hard, spiny carapace that protects its soft body",
    "long, slimy antennae",
    "two jagged rows of teeth",
    "bulbous eyes set on top of a large, flat head",
    "a wide, gaping mouth filled with sharp teeth",
    "long, feelers that drape down over the face",
    "beady, black eyes that seem to stare right into your soul"
]

export const lavaBeastBodySentences = [
"Its shell is hard and full of ridges.",
"It has two large, claws.",
"Its long tail is full of spikes.",
"Its body is orange with black spots.",
"The carapace is a deep, rich red.",
"Its long, slim claws are a vibrant orange.",
"Its abdomen is a glossy, deep-red.",
"It has long, antennas that are a deep crimson.",
"its body is a deep red color",
"it is covered in small black spots",
"it has large claws that can crush shells",
"its tail is long and slender",
"Scarlet carapace",
"Claws like daggers",
"Barbed tail",
"Phosphorescent eyes",
"Spines down the back",
"Slicing mandibles",
"Rows of tiny teeth",
"Bristling antennae",
"Itss body is a deep, rich red color.",
"its body is covered in small, sharp spines.",
"its body is long and slender, with two large, powerful claws.",
]

export const lavaBeastSoundWords = [
    "sizzling", "bubbling", "boiling", "steaming", "searing", "frying", "grilling", "toasting", "smoking", "baking",
]

export const lavaBeastFurWords = [
    
]

export const lavaBeastSmellWords = [
"Burnt",
"Charred",
"Cooked",
"Crustacean",
"Decayed",
"Fishy",
"Lobster",
"Mud",
"Seafood",
"Smoked",
]

export const lavaBeastBehaviourSentences = [
"it is glaring at me with its beady eyes",
"it is flailing its claws wildly",
"it is repeatedly banging its hard shell against the ground",
"it is emitting a low, menacing growl",
"it is constantly scratching itself",
"it is rubbing its claws together",
"it is walking sideways",
"it is flicking its tail",
"it is curling its claws",
"it is lurking in the shadows",
"it is a raving maniac",
"it is bleeding profusely from its claws",
"it seems to be in a lot of pain",
"it is making strange noises",
"its eyes are bulging out",
"it is floating upside down in the magma.",
"it is waving its claws menacingly at you.",
]