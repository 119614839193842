export const heathSmellTypes = [
    "of Soapwort",
    "of Crosswort",
    "of moor grass",
    "of wet peat",
    "of heather",
    "of sedge",
    "of rush",
    "of bracken",
    "coastal",
    "of dune",
    "of moorland",
    "sub-alpine",
    "common",
    "of broom",
    "of thistle",
    "of lavender",
    "of mint",
    "of eucalyptus",
    "of pine",
    "of fungus",
    "of Moorland musk",
    "of Peat stink",
    "of Bog myrtle",
    "of Fen gas",
    "of Blackwater reek",
    "aromatic and green",
    "aphrodisiac",
    "of liquid beeswax",
    "of honeyed lavender",
    "of a melange of fruit",
    "of festive dried fruits",
    "of liquid sultana",
    "of potpourri and mead",
    "of dry, leafy tabacco",
    "alluring",
    "of watery smoke",
    "of syrup-steeped fruits",
    "of sage stuffing",
    "of figgy florals",
    "of soap and granite",
    "of leather and ginger",
    "sinus-clearing",
    "of fruity adhesive",
    "warm and refreshing",
    "of the battlefield",
    "of citrus hewn from rock",
]

export const heathBranchTypes = [
    "hardy",
    "stout",
    "sound",
    "sturdy",
    "unmoving",
    "strong",
    "bloom-bearing"
    "decaying leaves on its",
    "rotting",
    "insects on its",
    "mushrooms growing on its",
    "dappled sunlight playing on its",
    "frostbitten",
    "rugged",
    "rock-hewn",
    "stone-borne",
    "spiny",
    "snow-slogged",
    "icicle",
    "defrosting",
    "thawing",
    "spine-wielding",
    "boot-trodden",
    "worthy",
    "recently plucked",
    "recently trampled",
    "stalwart",
    "steady",
    "steadfast",
    "reliable",
    "durable",
    "wafting",
    "hailing",
    "traveller-weary",
    "lacklustre",
]

export const heathLeafTypes = [
    "a beautiful sweep of",
    "scale",
    "small",
    "acidic",
    "purple",
    "thin",
    "burnt offering",
    "sere",
    "prickly",
    "golden",
    "blighted",
    "shriveled",
    "hardened",
    "dried",
    "dessicated",
    "flame-resistant",
    "enduring",
    "mauve",
    "green",
    "greenish-yellow",
    "purple",
    "light purple",
    "medicinal",
    "spine",
    "pip-sized",
    "recently nibbled",
]