export const evergreenSmellTypes = [
	"of Pine",
    "woodsy",
    "fresh",
    "invigorating",
    "fresh",
    "woody",
    "resinous",
    "earthy",
    "musty",
    "musky",
    "balsamic",
    "floral",
    "of douglas fir in winter",
    "of redwood",
    "Minty",
    "of Gumdrops",
    "Resinous",
    "Citrusy",
    "Piney",
    "Woodsy",
    "Earthy",
    "of cool, warm wood",
    "of citrusy pine",
    "light and transparent",
    "of spices from faraway",
    "of bartered goods",
    "of vanilla and pine",
    "of aromatic wood",
    "of lemon and juniper",
    "of citrus dessert",
    "comforting but unconventional",
    "of cardamom and vanilla",
    "cozy and sweet",
    "watery with a high-pitched lemon scent",
    "inoffensive vanilla and juniper",
    "of bright citrus and dark bergamot",
    "of pointed needles and incense",
    "of tart fruit and pepper",
    "of clean skin",
    "of maternal vanilla musk",
    "of a pineforest in deep winter",
    "of powdery violet",
    "of body odour and pepper",
    "of recent rain",
    "of many days camping in the rain",
    "of black tea and pine resin",
    "of a vat of dye",
    "of still water algae",
    "of nesting animals",
]

export const evergreenBranchTypes = [
    "winding",
    "delicate",
    "adorable",
    "brave",
    "calming",
    "captivating",
    "elegant",
    "graceful",
    "wending",
    "peaceful",
    "quiet",
    "chipping",
    "flaking",
    "Thick",
    "rust-red",
    "red",
    "mahogany",
    "rusted",
    "crimson",
    "sharp cones clinging to its",
    "pointed cones protruding from its",
    "reddish-brown",
    "fissured bark and robust",
    "robust",
    "drooping",
    "scaled seedpods in its",
    "upward reaching",
    "flaky bark and splayed",
    "splayed",
    "a conical crown above its",
    "plated bark and thin",
    "a pointed crown and spreading",
    "spreading",
    "pendulous side shoots hanging from its",
    "Resilient"
]

export const evergreenLeafTypes = [
    "dark green",
    "needle-like",
    "fractal",
    "circuitous",
    "blue-green",
    "aquamarine",
    "turquoise",
    "long, clumped",
    "sapphire",
    "waxy",
    "birch",
    "Waxy",
    "Thick",
    "Glossy",
    "pointed",
    "bluntly-tipped",
    "serrated",
    "dark green",
    "sturdy",
    "stiff",
    "sharp",
    "refreshing"
]