import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_COINS_SUCCESS, CREATE_COIN_SUCCESS, REMOVE_COIN_SUCCESS, UPDATE_COIN_SUCCESS } from "../actionTypes";

const initialState = {
  coins: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case START_NEW_GAME: {
      return { ...initialState }
    }

    case GET_COINS_SUCCESS: {
      const coins = action.payload;

      return {
        ...state,
        coins: [...state.coins, ...coins],
      }
    }

    case CREATE_COIN_SUCCESS: {
      return {
        ...state,
        coins: [...state.coins, { ...action.payload }]
      }
    }

    case REMOVE_COIN_SUCCESS: {
      return {
        ...state,
        coins: [...state.coins.filter(coin => (coin._id !== action.payload._id))]
      }
    }

    case UPDATE_COIN_SUCCESS: {
      return {
        ...state,
        coins: [...state.coins.filter(coin => (coin._id !== action.payload._id)), { ...state.coins.find(coin => (coin._id === action.payload._id)), ...action.payload}]
      }
    }

    default:
      return state;
  }
}
