export const crystalWeedSmellTypes = [
    "of a sandy beach",
    "fragrant",
    "botanical",
    "of ozone",
    "dewy",
    "musky",
    "woody",
    "Prickley",
    "of Sap",
    "of Bane",
    "of Dusk",
    "of Vine",
    "of Thorn",
    "of Curse",
    "of Misery",
    "fetid",
    "dank",
    "rank",
    "pungent",
    "putrid",
    "foul",
    "Crisp",
    "Refreshing",
    "Clean",
    "Pure",
    "Serene",
    "Bright",
    "Energizing",
    "Vibrant",
    "Alive",
    "Joyful",
    "of Dank Soil",
    "of Charcoal",
    "of Smoke",
    "of Wood",
    "of Musk",
    "airy and sweet",
    "light and powdery",
    "of inviting cocoa butter",
    "old and musty",
    "unpleasant",
    "of dirty skin",
    "of abroxan",
    "of stale breath",
    "of gooey sweetness",
    "of salty milk",
    "creamy and musky",
    "of musk and meringue",
    "peppery",
    "artificial",
    "creamy and ethereal",
    "of powdery, musky vanilla"
    "of a baked desert",
    "of cloth laid out to dry",
    "powdery and pleasant",
    "creamy and woody",
    "of vanilla and smoke",
    "nauseating",
    "of amber and black pepper",
    "buttery and fresh",
    "of sweet citrus florals",
    "acrid and sour",
    "of flinty starkness",
    "briny and umami",
    "of amber-warmed slate",
]

export const crystalWeedBranchTypes = [
    "Fertile",
    "simple",
    "Healthy",
    "Vibrant",
    "wilting",
    "blue",
    "Sturdy",
    "Vigorous",
    "humble",
    "plain",
    "Lush",
    "plucky",
    "Overgrown",
    "Wild",
    "Intricate",
    "Shiny",
    "Clear",
    "Refractive",
    "Geometric",
    "Intricate",
    "Delicate",
    "Faceted",
    "Prismatic",
    "latex-dripping",
    "crackling",
    "translucent",
    "glowing",
    "pulsing",
    "radiating",
    "hollow",
    "reflex brachts and hollow",
]

export const crystalWeedLeafTypes = [
    "Luminescent",
    "Iridescent",
    "Fluorescent",
    "tiny",
    "a collection of small flowers as",
    "pinnatisect",
    "simply lobed",
    "a leathery texture to its",
    "Held upright on long",
    "sturdy stems on its",
    "veined",
    "wavy or rippled Edges on its",
    "Fiery",
    "ray",
    "Bright",
    "hair-like",
    "wind-catching",
    "curled",
    "unfurled",
    "glowing",
    "floret",
    "hardened",
    "Vibrant",
    "lush",
    "sheer",
    "big",
    "round",
    "thin",
    "long",
    "pointy",
    "small",
    "gossamer",
    "slightly transparent",
    "filmy",
    "fragile",
    "flowing",
    "slim",
    "tapered",
    "luminous",
    "ethereal",
    "willowy",
    "diaphanous"
]