import React from "react";
import { connect } from 'react-redux'
import TextInput from '../utils/text-input/TextInput';

import store from '../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import { clearPlotAsync, tileTypes, createTileAsync } from '../../redux/actions/tile.actions';
import { createToolAsync } from '../../redux/actions/tool.actions';
import { createMachineAsync } from '../../redux/actions/machine.actions';
import { createMetalAsync } from '../../redux/actions/metal.actions';
import { createMaterialAsync } from '../../redux/actions/material.actions';
import { plantSeedAsync, cultivatePlantAsync, harvestPlantAsync } from '../../redux/actions/plant.actions';
import { buildConstructionAsync } from '../../redux/actions/construction.actions';
import { cookFoodAsync, cookAnotherFoodAsync, eatFoodAsync, deleteFoodAsync } from '../../redux/actions/food.actions';
import { gatherMineralAsync } from '../../redux/actions/mineral.actions';
import { createWagonAsync } from '../../redux/actions/wagon.actions';
import { createCoinAsync } from '../../redux/actions/coin.actions';
import { createBoatAsync } from '../../redux/actions/boat.actions';
import { createBrainchipAsync } from '../../redux/actions/brainchip.actions';
import { createTentAsync } from '../../redux/actions/tent.actions';
import { upgradeWorkshopAsync } from '../../redux/actions/workshop.actions';
import { 
    equipTentLocally, 
    unequipTentLocally,
    equipWorkshopLocally,
    unequipWorkshopLocally
} from '../../redux/actions/character.actions';
import { createLockAsync } from '../../redux/actions/lock.actions';
import { createWritingSurfaceAsync } from '../../redux/actions/writing-surface.actions';
import { createWritingImplementAsync } from '../../redux/actions/writing-implement.actions';
import { createFurnitureAsync } from '../../redux/actions/furniture.actions';
import { createWeaponAsync } from '../../redux/actions/weapon.actions';
import { createArmourAsync } from '../../redux/actions/armour.actions';
import { createClothingAsync } from '../../redux/actions/clothing.actions';
import { createJewelleryAsync } from '../../redux/actions/jewellery.actions';
import { saveLastCraft, closeCrafting, showQuantityInput } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    disableKeyboardMovement,
    enableKeyboardMovement,
    showHelp,
    startLooking,
    hideAllMenus,
    showWritingSurface,
    startAreaSelect,
    hideQuantityInput,
} from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectPanel,
    selectMineralAtPosition,
    selectTileTypeByName,
    selectCharacter,
    selectWorkshopTypeId,
    selectWorkshopTypeById,
    selectRecipesByWorkshopTypeId,
    selectToolTypes,
    selectWorkshopByPosition,
    selectCharacterLevels,
    selectSkills,
    selectNearbyCharacters,
    selectFloorLayerItemAtPosition,
    selectConstructionByPosition,
    selectPlantInstanceIdAtTile,
    selectNeighbouringTents,
    selectWorkshopUpgradeRecipe,
    selectInventory,
    selectCharacterFood,
    selectTiles,
    selectCharacterMinerals,
    selectCharacterTools,
    selectCharacterMetals,
    selectCharacterKeys,
    selectCharacterWritingSurfaces,
    selectCharacterWritingImplements,
    selectCharacterWeapons,
    selectCharacterArmour,
    selectCharacterClothing,
    selectCharacterJewellery,
    selectCharacterTents,
    selectCharacterLocks,
    selectIsQuantityInputOpen,
    selectIsCharacterListShowing,
    selectNeighbouringCharacters,
    selectCharacterTiles,
    selectTileInventory,
    selectTileFood,
    selectTileMinerals,
    selectTileTools,
    selectTileMetals,
    selectTileKeys,
    selectTileWritingSurfaces,
    selectTileWritingImplements,
    selectTileWeapons,
    selectTileArmour,
    selectTileClothing,
    selectTileJewellery,
    selectTileTents,
    selectTileLocks,
    selectSelectedCharacterId,
} from '../../redux/selectors';
import { toolTypeNames } from './tool-type-names';
import { itemWorkshopDescriptions } from './item-workshop-descriptions';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';

// import { actions } from './workshop-actions';

import { client } from '../../services/client';

import { BALANCE } from '../../services/balance-config';

import './Workshop.css';

import Menu from '../utils/menu/Menu';
import QuantityInput from '../utils/quantity-input/QuantityInput';

class Wizard extends React.Component {
    actions = [
        {
            name: 'construction',
            callback: ({ _id, constructionType }) => {
                // we want to have an area select in here.
                this.props.startAreaSelect({
                    isStartOfAreaSelect: true,
                    position: this.props.character.position,
                    areaSelectType: 'construction',
                    zoneSettings: {
                        type: constructionType
                    },
                    selectedCharacterId: this.props.selectedCharacterId
                });
                console.log('area select baby!')

                // const constructionTypeId = constructionType._id;
                // return this.props.buildConstructionAsync({ constructionTypeId })
                //     .then(() => {
                //         this.props.hideAllMenus()
                //     })
            }
        }, {
            name: 'food',
            callback: async (item) => {
                if (this.state.isLoading) {
                    return;
                }

                const { _id, foodTypeId } = item

                const quantity = await this.getQuantity(item);

                this.setState({
                    isLoading: true
                })

                return this.props.cookFoodAsync({ _id, foodTypeId, selectedCharacterId: this.props.selectedCharacterId, quantity })
                    .finally(() => (this.setState({ isLoading: false })));
            }
        }, {
            name: `machine`,
            callback: ({ _id, machineTypeId }) => {
                if (this.state.isLoading || this.state.isQuantityInputShowing) {
                    return;
                }

                this.setState({
                    isLoading: true
                })

                return this.props.createMachineAsync({ _id, machineTypeId, selectedCharacterId: this.props.selectedCharacterId })
                    .finally(() => (this.setState({ isLoading: false })));
            }
        }, {
            name: `tool`,
            callback: async (item) => {
                if (this.state.isLoading || this.state.isQuantityInputShowing) {
                    return;
                }

                const { _id, toolTypeId } = item

                const quantity = await this.getQuantity(item);

                this.setState({
                    isLoading: true
                })

                return this.props.createToolAsync({ _id, toolTypeId, selectedCharacterId: this.props.selectedCharacterId, quantity })
                    .finally(() => (this.setState({ isLoading: false })));
            }
        }, {
            name: `metal`,
            callback: async (item) => {
                if (this.state.isLoading || this.state.isQuantityInputShowing) {
                    return;
                }

                const { _id, metalTypeId } = item

                const quantity = await this.getQuantity(item);

                this.setState({
                    isLoading: true
                })

                return this.props.createMetalAsync({ _id, metalTypeId, selectedCharacterId: this.props.selectedCharacterId, quantity })
                    .finally(() => (this.setState({ isLoading: false })));
            }
        }, {
            name: `material`,
            callback: async (item) => {
                if (this.state.isLoading || this.state.isQuantityInputShowing) {
                    return;
                }

                const { _id, materialTypeId } = item

                const quantity = await this.getQuantity(item);

                this.setState({
                    isLoading: true
                })

                return this.props.createMaterialAsync({ _id, materialTypeId, selectedCharacterId: this.props.selectedCharacterId, quantity })
                    .finally(() => (this.setState({ isLoading: false })));
            }
        }, {
            name: `lock`,
            callback: ({ _id, lockTypeId }) => {
                if (this.state.isLoading || this.state.isQuantityInputShowing) {
                    return;
                }

                this.setState({
                    isLoading: true
                })

                return this.props.createLockAsync({ _id, lockTypeId, selectedCharacterId: this.props.selectedCharacterId })
                    .finally(() => (this.setState({ isLoading: false })));
            }
        }, {
            name: `writingSurface`,
            callback: async (item) => {
                if (this.state.isLoading || this.state.isQuantityInputShowing) {
                    return;
                }

                const { _id, writingSurfaceTypeId } = item

                const quantity = await this.getQuantity(item);

                this.setState({
                    isLoading: true
                })

                return this.props.createWritingSurfaceAsync({ _id, writingSurfaceTypeId, selectedCharacterId: this.props.selectedCharacterId, quantity })
                    .finally(() => (this.setState({ isLoading: false })));
            }
        }, {
            name: `writingImplement`,
            callback: async (item) => {
                if (this.state.isLoading || this.state.isQuantityInputShowing) {
                    return;
                }

                const { _id, writingImplementTypeId } = item

                const quantity = await this.getQuantity(item);

                this.setState({
                    isLoading: true
                })

                return this.props.createWritingImplementAsync({ _id, writingImplementTypeId, selectedCharacterId: this.props.selectedCharacterId, quantity })
                    .finally(() => (this.setState({ isLoading: false })));
            }
        }, {
            name: `furniture`,
            callback: ({ _id, furnitureTypeId, furnitureType }) => {

                this.props.showWritingSurface({
                    surfaceService: 'furniture-instances',
                    surfaceId: undefined,
                    isEditWritingSurfaceShowing: true,
                    isEditDrawingSurfaceShowing: false,
                    isNewWritingSurface: true,
                    item: { furnitureTypeId, type: furnitureType }
                });
            }
        }, {
            name: `tile`,
            callback: ({ _id, tileTypeId, tileType }) => {

                this.props.showWritingSurface({
                    surfaceService: 'tile-instances',
                    surfaceId: undefined,
                    isEditWritingSurfaceShowing: true,
                    isEditDrawingSurfaceShowing: false,
                    isNewWritingSurface: true,
                    item: { tileTypeId, type: tileType }
                });
            }
        }, {
            name: `weapon`,
            callback: ({ _id, weaponTypeId, weaponType }) => {

                this.props.showWritingSurface({
                    surfaceService: 'weapon-instances',
                    surfaceId: undefined,
                    isEditWritingSurfaceShowing: true,
                    isEditDrawingSurfaceShowing: false,
                    isNewWritingSurface: true,
                    item: { weaponTypeId, type: weaponType }
                });
            }
        }, {
            name: `armour`,
            callback: ({ _id, armourTypeId, armourType }) => {
                this.props.showWritingSurface({
                    surfaceService: 'armour-instances',
                    surfaceId: undefined,
                    isEditWritingSurfaceShowing: true,
                    isEditDrawingSurfaceShowing: false,
                    isNewWritingSurface: true,
                    item: { armourTypeId, type: armourType }
                });
            }
        }, {
            name: `clothing`,
            callback: ({ _id, clothingTypeId, clothingType }) => {
                this.props.showWritingSurface({
                    surfaceService: 'clothing-instances',
                    surfaceId: undefined,
                    isEditWritingSurfaceShowing: true,
                    isEditDrawingSurfaceShowing: false,
                    isNewWritingSurface: true,
                    item: { clothingTypeId, type: clothingType }
                });
            }
        }, {
            name: `jewellery`,
            callback: ({ _id, jewelleryTypeId, jewelleryType }) => {
                this.props.showWritingSurface({
                    surfaceService: 'jewellery-instances',
                    surfaceId: undefined,
                    isEditWritingSurfaceShowing: true,
                    isEditDrawingSurfaceShowing: false,
                    isNewWritingSurface: true,
                    item: { jewelleryTypeId, type: jewelleryType }
                });
            }
        }, {
            name: `workshop`,
            callback: ({ _id, workshopType }) => {
                return this.props.equipWorkshopLocally({ _id, workshopType, position: this.props.character.position });
            }   
        }, {
            name: `wagon`,
            callback: ({ _id, wagonTypeId, wagonType }) => {
                this.props.showWritingSurface({
                    surfaceService: 'wagon-instances',
                    surfaceId: undefined,
                    isEditWritingSurfaceShowing: true,
                    isEditDrawingSurfaceShowing: false,
                    isNewWritingSurface: true,
                    item: { wagonTypeId, type: wagonType }
                });
            }
        }, {
            name: `boat`,
            callback: ({ _id, boatTypeId, boatType }) => {
                if (boatType?.waterType === 'river' && !this.props.panel.riverSize) {
                    return;
                }

                if (boatType?.waterType === 'sea' && (this.props.panel.riverSize && this.props.panel.riverSize < 7)) {
                    return;
                }

                this.props.showWritingSurface({
                    surfaceService: 'boat-instances',
                    surfaceId: undefined,
                    isEditWritingSurfaceShowing: true,
                    isEditDrawingSurfaceShowing: false,
                    isNewWritingSurface: true,
                    item: { boatTypeId, type: boatType }
                });
            }
        }, {
            name: `tent`,
            callback: ({ _id, tentTypeId }) => {
                if (this.state.isLoading || this.state.isQuantityInputShowing) {
                    return;
                }

                this.setState({
                    isLoading: true
                })

                return this.props.createTentAsync({ _id, tentTypeId, selectedCharacterId: this.props.selectedCharacterId })
                    .finally(() => (this.setState({ isLoading: false })));
            }
        }, {
            name: `coin`,
            callback: async (coin) => {
                if (this.state.isLoading || this.state.isQuantityInputShowing) {
                    return;
                }

                const quantity = await this.getQuantity(coin);

                this.setState({
                    isLoading: true
                })

                const { _id, coinTypeId } = coin;
                return this.props.createCoinAsync({ _id, coinTypeId, characterId: this.props.character._id, selectedCharacterId: this.props.selectedCharacterId, quantity })
                    .finally(() => (this.setState({ isLoading: false })));
            }
        }, {
            name: `brainchip`,
            callback: (brainchip) => {
                if (this.state.isLoading || this.state.isQuantityInputShowing) {
                    return;
                }
                this.setState({
                    isLoading: true
                })

                if (this.props.nearbyCharacters?.length === 0) {
                    return;
                }
                const { _id, brainchipTypeId } = brainchip;
                return this.props.createBrainchipAsync({ _id, brainchipTypeId, characterId: this.props.nearbyCharacters[0]._id, selectedCharacterId: this.props.selectedCharacterId })
                    .finally(() => (this.setState({ isLoading: false })));
            }
        }
    ];

    state = {
        items: [
        ],
        navigationHandler: this.navigationHandler.bind(this)
    }

    navigationHandler(event) {
        if ((event.key === 's' || event.key === 'S') && !this.state.isQuantityInputShowing) {
            this.setState({
                isSearchShowing: true
            });
            return;
        }
    }

    async getQuantity(item) {
        // if (Number(item.quantity) > 1) {
            this.props.showQuantityInput();
            this.setState({ isQuantityInputShowing: true, maxQuantity: item.quantity });
        // }

        return new Promise((resolve, reject) => {
            if (Number(item.quantity) === 1) {
                return resolve(1);
            }

            this.setState({
                onQuantitySupplied: (quantity) => {
                    this.props.hideQuantityInput();
                    this.setState({isQuantityInputShowing: false})
                    resolve(Number(quantity));
                },
                onQuantityClosed: () => {
                    this.props.hideQuantityInput();
                    this.setState({isQuantityInputShowing: false})
                    reject('No quantity supplied');
                }
            })
        });
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.state.navigationHandler)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.state.navigationHandler);

        const recipes = this.props.workshopRecipes
            .map((recipe, index) => {
                const type = recipe[`${recipe.type}Type`]

                return {
                    ...recipe,
                    text: type?.name,
                }
            })
            .sort((recipeA, recipeB) => {
                let isAllIngredientsInRecipe = true;

                const ingredients = recipeA.ingredients.map((ingredient, index) => {
                    let isIngredientInInventory = this.props.inventoryItems.find(inventoryItem => {
                        if (ingredient.plant && inventoryItem.plantTypeId === ingredient.plantTypeId && !ingredient.materialType) {
                            return inventoryItem.quantity >= ingredient.quantity;
                        }

                        if (ingredient.materialType && inventoryItem.materialTypeId === ingredient.materialType?._id) {
                            if (ingredient.plant && inventoryItem.plantTypeId !== ingredient.plantTypeId) {
                                return false;
                            }
                            if (ingredient.animal && inventoryItem.animalTypeId !== ingredient.animalTypeId) {
                                return false;
                            }

                            return inventoryItem.quantity >= ingredient.quantity;
                        }

                        if (ingredient.ingredientType && inventoryItem.mineralTypeId === ingredient.ingredientType?._id) {
                            return inventoryItem.quantity >= ingredient.quantity;
                        }

                        if (ingredient.ingredientType && inventoryItem.metalTypeId === ingredient.ingredientType?._id) {
                            return inventoryItem.quantity >= ingredient.quantity;
                        }

                        if (ingredient.ingredientType && inventoryItem.writingSurfaceTypeId === ingredient.ingredientType?._id) {
                            return inventoryItem.quantity >= ingredient.quantity;
                        }

                        return false;
                    })

                    if (!isIngredientInInventory) {
                        isAllIngredientsInRecipe = false
                    }
                })

                if (isAllIngredientsInRecipe) {
                    return -1;
                }

                return recipeA.text?.localeCompare(recipeB.text)
            })

        this.setState({
            items: recipes,
            isBuildersYard: this.props.workshopType?.name === 'Builder\'s Yard'
        })
    }

    selectRecipe(recipe) {
        if (!recipe) {
            return;
        }

        if (recipe[`${recipe.type}Type`].rotDays) {
            const rotDays = recipe[`${recipe.type}Type`].rotDays;

            const min = rotDays - Math.round(rotDays * 0.1);
            const max = rotDays + Math.round(rotDays * 0.1);
            const naturalVariation = Math.floor(Math.random() * (max - min + 1)) + min;
        }

        if (recipe[`${recipe.type}Type`].rotRate) {
            recipe.rotRate = recipe[`${recipe.type}Type`].rotRate;
        }

        if (recipe[`${recipe.type}Type`].totalUses) {
            const totalUses = recipe[`${recipe.type}Type`].totalUses;

            recipe.totalUses = totalUses;
        }

        if (recipe[`${recipe.type}Type`].maxWeight) {
            recipe.maxWeight = (recipe[`${recipe.type}Type`].maxWeight);
        }

        this.setState({
            selectedRecipe: recipe,
        })
    }

    buildRecipe(recipe) {
        const action = this.actions.find(({name, callback}) => {
            return name === recipe.type
        })

        const callback = action.callback.bind(this);

        setTimeout(() => (callback(recipe)));
    }

    static getDerivedStateFromProps(props, state) {

        if (props.workshopRecipes) {
            const recipes = props.workshopRecipes
                .map((recipe, index) => {
                    const type = recipe[`${recipe.type}Type`]

                    return {
                        ...recipe,
                        text: type?.name,
                    }
                })

            const allRecipes = props.workshopRecipes
                .map((recipe, index) => {
                    const type = recipe[`${recipe.type}Type`]

                    return {
                        ...recipe,
                        text: type?.name,
                    }
                })

            return {
                ...state,
                items: recipes,
                allRecipes,
                isQuantityInputShowing: props.isQuantityInputShowing
            };
        }

        return {
            ...state,
            isQuantityInputShowing: props.isQuantityInputShowing
        }
    }

    onSearchExit() {
        this.setState({
            isSearchShowing: false,
            searchString: ''
        })
    }

    onSearchSubmit(text) {
        this.setState({
            isSearchShowing: false,
            searchString: text,
        })
    }

    onSearchUpdate(text) {
        this.setState({
            searchString: text,
        })
    }

    render() {
        let selectedRecipeName = ('');
        let selectedRecipeQuantityString = 'a'

        let ingredients = (<li>No ingredients</li>);
        if (this.state.selectedRecipe?.ingredients && this.state.selectedRecipe.ingredients?.length > 0) {
            ingredients = this.state.selectedRecipe.ingredients?.map((ingredient, index) => {
                let inventoryItemQuantity = 0;

                let isIngredientInInventory = false

                const mappedItems = this.props.inventoryItems.sort((itemA, itemB) => (itemA._id?.localeCompare(itemB._id))).map(inventoryItem => {
                    if (ingredient.plant && inventoryItem.plantTypeId === ingredient.plantTypeId && !ingredient.materialType) {
                        inventoryItemQuantity += inventoryItem.quantity
                        return inventoryItem.quantity > ingredient.quantity;
                    }

                    if (ingredient.materialType?.name === 'Metal' && inventoryItem.metalTypeId) {
                        inventoryItemQuantity += inventoryItem.quantity
                        return inventoryItem.quantity > ingredient.quantity
                    }

                    const stoneNames = [
                        'Limestone',
                        'Basalt',
                        'Granite',
                        'Marble',
                    ];

                    if (ingredient.materialType?.name === 'Stone' && stoneNames.indexOf(inventoryItem.mineralType?.name) > -1) {
                        inventoryItemQuantity += inventoryItem.quantity
                        return inventoryItem.quantity > ingredient.quantity
                    }

                    if (ingredient.materialType && inventoryItem.materialTypeId === ingredient.materialType?._id) {
                        if (ingredient.plant && inventoryItem.plantTypeId !== ingredient.plantTypeId) {
                            return false;
                        }
                        if (ingredient.animal && inventoryItem.animalTypeId !== ingredient.animalTypeId) {
                            return false;
                        }
                        inventoryItemQuantity += inventoryItem.quantity
                        return inventoryItem.quantity > ingredient.quantity;
                    }

                    if (ingredient.ingredientType && inventoryItem.mineralTypeId === ingredient.ingredientType?._id) {
                        inventoryItemQuantity += inventoryItem.quantity
                        return inventoryItem.quantity > ingredient.quantity;
                    }

                    if (ingredient.ingredientType && inventoryItem.metalTypeId === ingredient.ingredientType?._id) {
                        inventoryItemQuantity += inventoryItem.quantity
                        return inventoryItem.quantity > ingredient.quantity;
                    }

                    if (ingredient.ingredientType && inventoryItem.writingSurfaceTypeId === ingredient.ingredientType?._id) {
                        inventoryItemQuantity += inventoryItem.quantity
                        return inventoryItem.quantity > ingredient.quantity;
                    }

                    return false;
                })

                isIngredientInInventory = mappedItems.find(item => (!!item));

                let ingredientString = ingredient.materialType?.name || ingredient.ingredientType?.name

                if (ingredient.plant && ingredient.materialType) {
                    ingredientString = `${ingredient.plant.name} ${ingredient.materialType.name}`
                }

                if (ingredient.animal && ingredient.materialType) {
                    ingredientString = `${BALANCE[ingredient.animal.name].name} ${ingredient.materialType.name}`
                }

                return (
                    <li key={index}>{ingredientString} (<span className={isIngredientInInventory ? "hint in-stock": "hint out-of-stock"}>{inventoryItemQuantity}/{ingredient.quantity}</span>)</li>
                )
            })
        }

        let recipeType;

        if (this.state.selectedRecipe) {
            recipeType = this.state.selectedRecipe[this.state.selectedRecipe?.type + 'Type']
            selectedRecipeName = (<span>{recipeType?.name}</span>);
            selectedRecipeQuantityString = this.state.selectedRecipe ? this.state.selectedRecipe.quantity : 'a'
        }

        const isBuildingConstruction = this.state.isBuildersYard && this.props.isHoldingHammer;

        let bottomBarClass = this.props.workshopType ? "workshop-upgrade-section" : "hidden";

        if (isBuildingConstruction) {
            bottomBarClass = "hidden";
        }

        const items = this.state.items
            .sort((recipeA, recipeB) => {
                let isAllAIngredientsInRecipe = true;
                let isAllBIngredientsInRecipe = true;

                const ingredients = recipeA.ingredients.map((ingredient, index) => {
                    let isIngredientInInventory = this.props.inventoryItems.find(inventoryItem => {
                        if (ingredient.plant && inventoryItem.plantTypeId === ingredient.plantTypeId && !ingredient.materialType) {
                            return inventoryItem.quantity >= ingredient.quantity;
                        }

                        if (ingredient.materialType?.name === 'Metal' && inventoryItem.metalTypeId) {
                            return inventoryItem.quantity > ingredient.quantity
                        }

                        const stoneNames = [
                            'Limestone',
                            'Basalt',
                            'Granite',
                            'Marble',
                        ];

                        if (ingredient.materialType?.name === 'Stone' && stoneNames.indexOf(inventoryItem.mineralType?.name) > -1) {
                            return inventoryItem.quantity > ingredient.quantity
                        }

                        if (ingredient.materialType && inventoryItem.materialTypeId === ingredient.materialType?._id) {
                            if (ingredient.plant && inventoryItem.plantTypeId !== ingredient.plantTypeId) {
                                return false;
                            }
                            if (ingredient.animal && inventoryItem.animalTypeId !== ingredient.animalTypeId) {
                                return false;
                            }
                            return inventoryItem.quantity >= ingredient.quantity;
                        }

                        if (ingredient.ingredientType && inventoryItem.mineralTypeId === ingredient.ingredientType?._id) {
                            return inventoryItem.quantity >= ingredient.quantity;
                        }

                        if (ingredient.ingredientType && inventoryItem.metalTypeId === ingredient.ingredientType?._id) {
                            return inventoryItem.quantity >= ingredient.quantity;
                        }

                        if (ingredient.ingredientType && inventoryItem.writingSurfaceTypeId === ingredient.ingredientType?._id) {
                            return inventoryItem.quantity >= ingredient.quantity;
                        }

                        return false;
                    })

                    if (!isIngredientInInventory) {
                        isAllAIngredientsInRecipe = false
                    }
                })

                recipeB.ingredients.map((ingredient, index) => {
                    let isIngredientInInventory = this.props.inventoryItems.find(inventoryItem => {
                        if (ingredient.plant && inventoryItem.plantTypeId === ingredient.plantTypeId && !ingredient.materialType) {
                            return inventoryItem.quantity >= ingredient.quantity;
                        }

                        if (ingredient.materialType?.name === 'Metal' && inventoryItem.metalTypeId) {
                            return inventoryItem.quantity > ingredient.quantity
                        }

                        const stoneNames = [
                            'Limestone',
                            'Basalt',
                            'Granite',
                            'Marble',
                        ];

                        if (ingredient.materialType?.name === 'Stone' && stoneNames.indexOf(inventoryItem.mineralType?.name) > -1) {
                            return inventoryItem.quantity > ingredient.quantity
                        }

                        if (ingredient.materialType && inventoryItem.materialTypeId === ingredient.materialType?._id) {
                            if (ingredient.plant && inventoryItem.plantTypeId !== ingredient.plantTypeId) {
                                return false;
                            }
                            if (ingredient.animal && inventoryItem.animalTypeId !== ingredient.animalTypeId) {
                                return false;
                            }
                            return inventoryItem.quantity >= ingredient.quantity;
                        }

                        if (ingredient.ingredientType && inventoryItem.mineralTypeId === ingredient.ingredientType?._id) {
                            return inventoryItem.quantity >= ingredient.quantity;
                        }

                        if (ingredient.ingredientType && inventoryItem.metalTypeId === ingredient.ingredientType?._id) {
                            return inventoryItem.quantity >= ingredient.quantity;
                        }

                        if (ingredient.ingredientType && inventoryItem.writingSurfaceTypeId === ingredient.ingredientType?._id) {
                            return inventoryItem.quantity >= ingredient.quantity;
                        }

                        return false;
                    })

                    if (!isIngredientInInventory) {
                        isAllBIngredientsInRecipe = false
                    }
                })

                if (isAllAIngredientsInRecipe && !isAllBIngredientsInRecipe) {
                    return -1;
                }

                if (!isAllAIngredientsInRecipe && isAllBIngredientsInRecipe) {
                    return 1;
                }

                if (!recipeA.text) {
                    console.log('errr wat?', recipeA)
                }

                if (!recipeB.text) {
                    console.log('errr wat?', recipeA)
                }

                return recipeA.text.localeCompare(recipeB.text)
            })
            .filter((recipe) => {
                if (this.state.searchString) {
                    return recipe.text.toLowerCase().indexOf(this.state.searchString.toLowerCase()) > -1
                }

                return true;
            })

        return (
            <div className="workshop">
                <div className="workshop-sidebar">
                    <div className="items">
                        <Menu options={items}
                            isDisabled={this.state.isQuantityInputShowing}
                            isShortcutHidden={true}
                            highlightedOption={this.selectRecipe.bind(this)}
                            optionChosen={this.buildRecipe.bind(this)}
                            menuContainer="items"
                        />
                    </div>
                    <div className="item-details">
                        <div className="workshop-items-container">
                            <h3 className="hint">Ingredients</h3>
                            <ul>
                                {ingredients}
                            </ul>

                            {this.state.selectedRecipe?.clothingType ? (
                                <ul>
                                    <p></p>
                                    <li>Armour: {this.state.selectedRecipe?.clothingType.armour}❤</li>
                                    <li>Dodge: {this.state.selectedRecipe?.clothingType.dodge}❥</li>
                                </ul>
                            ): ('')}

                            {this.state.selectedRecipe?.armourType ? (
                                <ul>
                                    <p></p>
                                    <li>Armour: {this.state.selectedRecipe?.armourType.armour}❤</li>
                                    <li>Dodge: {this.state.selectedRecipe?.armourType.dodge}❥</li>
                                </ul>
                            ): ('')}

                            {this.state.selectedRecipe?.weaponType ? (
                                <ul>
                                    <p></p>
                                    <li>Armour: {this.state.selectedRecipe?.weaponType.damage}ϴ</li>
                                    <li>Dodge: {this.state.selectedRecipe?.weaponType.toHit}ϰ</li>
                                    <li>Dodge: {this.state.selectedRecipe?.weaponType.penetration}➚</li>
                                </ul>
                            ): ('')}

                            {
                                (this.state.isBuildersYard && !this.props.isHoldingHammer) ? (
                                    <p>Built in place by a builder holding a hammer</p>
                                ) : (
                                    <div>
                                        <p><span className="action">SPACE</span> - Craft {selectedRecipeQuantityString} {selectedRecipeName}</p>
                                        <p><span className="hint">{recipeType?.experiencePoints}</span>XP Reward</p>
                                        { this.state.selectedRecipe?.minLevelAdjustment ? (
                                            <div>
                                                <p>Lasts between <span className="hint">{this.state.selectedRecipe.minLevelAdjustment}</span> - <span className="hint">{this.state.selectedRecipe.maxLevelAdjustment}</span> days</p>
                                            </div>
                                        ) : ('')}
                                        { this.state.selectedRecipe?.totalUses ? (
                                            <div>
                                                <p><span className="hint">{this.state.selectedRecipe.totalUses}</span> total uses</p>
                                            </div>
                                        ) : ('')}
                                        { this.state.selectedRecipe?.rotRate ? (
                                            <div>
                                                <p>Rots at <span className="hint">{this.state.selectedRecipe.rotRate * 100}</span>% per day</p>
                                            </div>
                                        ) : ('')}
                                        { (!this.state.selectedRecipe?.totalUses && !this.state.selectedRecipe?.minLevelAdjustment && !this.state.selectedRecipe?.rotRate) ? (
                                            <div>
                                                <p>Lasts forever</p>
                                            </div>
                                        ) : ('')}
                                        { this.state.selectedRecipe?.maxWeight ? (
                                            <div>
                                                <p><span className="hint">{this.state.selectedRecipe.maxWeight}</span>u storage size</p>
                                            </div>
                                        ) : ('')}
                                    </div>
                                )
                            }

                            {
                                (this.state.selectedRecipe?.boatType?.waterType === 'river' && this.props.panel.oceans !== "0") ?(
                                    <p className="error">This ship does is too small for seas</p>
                                ) : ('')
                            }

                            {
                                (this.state.selectedRecipe?.boatType?.waterType === 'sea' && this.props.panel.riverSize < 7) ?(
                                    <p className="error">This ship does is too large for rivers</p>
                                ) : ('')
                            }

                            {
                                (this.props.nearbyCharacters?.length === 0 && this.state.selectedRecipe?.type === 'brainchip') ? (
                                    <p className="error">A patient must be stood in the vacinity before a brainchip can be modified</p>
                                ) : ('')
                            }

                            {itemWorkshopDescriptions.find(description => (description.name === this.state.selectedRecipe?.text)) ? (
                                <p className="workshop-description">{itemWorkshopDescriptions.find(description => (description.name === this.state.selectedRecipe?.text)).text}</p>
                            ) : ('')}
                        </div>
                    </div>
                </div>

                {this.props.isQuantityInputShowing && this.state.isQuantityInputShowing &&
                    <QuantityInput
                        quantity={this.state.maxQuantity}
                        onQuantitySupplied={this.state.onQuantitySupplied}
                        onQuantityClosed={this.state.onQuantityClosed}
                    />
                }

                {this.state.isSearchShowing ? (
                    <div className="search-bar">
                        <p className="conversation-wizard-exit"><span className="action">ESC</span> to exit</p>
                        <TextInput onTextSubmit={this.onSearchSubmit.bind(this)} onTextUpdate={this.onSearchUpdate.bind(this)} text={''} exitKey="Escape" onExitKey={this.onSearchExit.bind(this)} />
                    </div>
                ) : ('')}

                <div className="menu-actions">
                    <span className="action-shortcut"><span className="shortcut">S</span> Search</span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const character = selectCharacter(state)
    const panel = selectPanel(state);
    const nearbyCharacters = selectNearbyCharacters(state, character);
    const workshopTypeId = selectWorkshopTypeId(state);
    const currentWorkshop = selectWorkshopByPosition(state, character.position);

    const selectedCharacterId = selectSelectedCharacterId(state);

    const skills = selectSkills(state)

    const isFloorLayerItem = selectFloorLayerItemAtPosition(state, character.position);
    const construction = selectConstructionByPosition(state, character.position);
    const isPlant = selectPlantInstanceIdAtTile(state, character.position);
    const tents = selectNeighbouringTents(state, character);

    const isConstruction = construction.filter(construction => (construction.construction.name.toLowerCase().indexOf('floor') === -1) && construction.construction.name.toLowerCase().indexOf('fire') === -1 && construction.construction.name.toLowerCase().indexOf('lamp') === -1).length > 0
    const isWorkshop = !!workshopTypeId?._id
    const isTent = tents.length > 0

    const workshopSkill = skills.find(skill => (skill._id === currentWorkshop.workshopType?.skillName));

    let workshopType;

    if (workshopTypeId) {
        workshopType = selectWorkshopTypeById(state, workshopTypeId);
    }

    let workshopRecipes = selectRecipesByWorkshopTypeId(state, workshopTypeId);
    workshopRecipes = workshopRecipes.filter(recipe => (recipe.ingredients?.length > 0))

    const toolTypes = selectToolTypes(state);
    const toolType = toolTypes.find(tool => tool._id === character.toolTypeId);

    const isHoldingHammer = toolType?.name === toolTypeNames.HAMMER;

    // Finding out what ingredients we have available:

    const inventory = selectInventory(state)
    const food = selectCharacterFood(state)
    const tiles = selectTiles(state);
    const minerals = selectCharacterMinerals(state);

    const tools = selectCharacterTools(state);
    const metals = selectCharacterMetals(state);
    const keys = selectCharacterKeys(state);
    const itemTileType = selectTileTypeByName(state, tileTypes.ITEM)
    const writingSurfaces = selectCharacterWritingSurfaces(state);
    const writingImplements = selectCharacterWritingImplements(state);
    const weapons = selectCharacterWeapons(state);
    const armour = selectCharacterArmour(state);
    const clothing = selectCharacterClothing(state);
    const jewellery = selectCharacterJewellery(state);
    const characterTents = selectCharacterTents(state);
    const locks = selectCharacterLocks(state);

    const isQuantityInputShowing = selectIsQuantityInputOpen(state);
    const isCharacterListShowing = selectIsCharacterListShowing(state);
    const neighbouringCharacters = selectNeighbouringCharacters(state, character);
    const containers = selectCharacterTiles(state);

    let inventoryItems = [];

    containers.byId.forEach(tile => {
        inventoryItems =  [ ...inventoryItems, ...selectTileInventory(state, tile._id) ];
        inventoryItems =  [ ...inventoryItems, ...selectTileFood(state, tile._id) ];
        inventoryItems =  [ ...inventoryItems, ...selectTileMinerals(state, tile._id) ];
        inventoryItems =  [ ...inventoryItems, ...selectTileTools(state, tile._id) ];
        inventoryItems =  [ ...inventoryItems, ...selectTileMetals(state, tile._id) ];
        inventoryItems =  [ ...inventoryItems, ...selectTileKeys(state, tile._id) ];
        inventoryItems =  [ ...inventoryItems, ...selectTileWritingSurfaces(state, tile._id) ];
        inventoryItems =  [ ...inventoryItems, ...selectTileWritingImplements(state, tile._id) ];
        inventoryItems =  [ ...inventoryItems, ...selectTileWeapons(state, tile._id) ];
        inventoryItems =  [ ...inventoryItems, ...selectTileArmour(state, tile._id) ];
        inventoryItems =  [ ...inventoryItems, ...selectTileClothing(state, tile._id) ];
        inventoryItems =  [ ...inventoryItems, ...selectTileJewellery(state, tile._id) ];
        inventoryItems =  [ ...inventoryItems, ...selectTileTents(state, tile._id) ];
        inventoryItems =  [ ...inventoryItems, ...selectTileLocks(state, tile._id) ];
    })

    inventoryItems = [
        ...inventoryItems,
        ...inventory.inventory,
        ...food,
        ...minerals,
        ...tools,
        ...metals,
        ...keys,
        ...writingSurfaces,
        ...writingImplements,
        ...weapons,
        ...armour,
        ...clothing,
        ...jewellery,
        ...tents,
        ...locks,
    ]
    // 

    return {
        isQuantityInputShowing,
        character,
        panel,
        workshopType,
        workshopRecipes,
        isHoldingHammer,
        currentWorkshop,
        workshopSkill,
        nearbyCharacters,
        isFloorLayerItem,
        isPlant,
        isConstruction,
        isWorkshop,
        isTent,
        inventoryItems,
        selectedCharacterId
    }
}

export default connect(
    mapStateToProps,
    {
        hideAllMenus,
        buildConstructionAsync,
        cookAnotherFoodAsync,
        cookFoodAsync,
        createMachineAsync,
        createToolAsync,
        createMetalAsync,
        createMaterialAsync,
        createLockAsync,
        createWritingSurfaceAsync,
        createWritingImplementAsync,
        createFurnitureAsync,
        createTileAsync,
        createWeaponAsync,
        createArmourAsync,
        createClothingAsync,
        createJewelleryAsync,
        createWagonAsync,
        createCoinAsync,
        createBrainchipAsync,
        equipTentLocally,
        createBoatAsync,
        createTentAsync,
        equipWorkshopLocally,
        unequipWorkshopLocally,
        showWritingSurface,
        upgradeWorkshopAsync,
        startAreaSelect,
        showQuantityInput,
        hideQuantityInput,
    }
)(Wizard);