import {
    CREATE_GRAFFITI_LOADING,
    CREATE_GRAFFITI_SUCCESS,
    CREATE_GRAFFITI_FAILURE,

    CREATE_DRAWING_LOADING,
    CREATE_DRAWING_SUCCESS,
    CREATE_DRAWING_FAILURE,
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

export const createGraffitiSuccess = payload => ({
    type: CREATE_GRAFFITI_SUCCESS,
    payload
})

export const createGraffitiFailure = () => ({
    type: CREATE_GRAFFITI_FAILURE
})

export const createGraffitiAsync = payload => dispatch => {
    const { _id, text, surfaceService, surfaceId, item } = payload;

    colyClient.room.send('doAction', {
        serviceName: surfaceService,
        commandType: 'patch',
        data: {
            _id: surfaceId,
            graffiti: text,
            pixelsArray: []
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const createDrawingSuccess = payload => ({
    type: CREATE_DRAWING_SUCCESS,
    payload
})

export const createDrawingFailure = () => ({
    type: CREATE_DRAWING_FAILURE
})

export const createDrawingAsync = payload => dispatch => {
    const { _id, dataUrl, surfaceService, surfaceId, pixelsArray, item } = payload;

    colyClient.room.send('doAction', {
        serviceName: surfaceService,
        commandType: 'patch',
        data: {
            _id: surfaceId,
            graffiti: '',
            pixelsArray
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const createNewGraffitiItemAsync = payload => dispatch => {
    const { _id, text, surfaceService, item, selectedCharacterId } = payload;

    const fieldsToDelete = [
        'type',
    ]

    fieldsToDelete.forEach(field => {
        delete item[field];
    })

    colyClient.room.send('doSuperAction', {
        serviceName: surfaceService,
        name: 'create',
        data: {
            ...item,
            graffiti: text,
            pixelsArray: [],
            selectedCharacterId
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const createNewDrawingItemAsync = payload => dispatch => {
    const { _id, surfaceService, pixelsArray, item } = payload;

    const fieldsToDelete = [
        'type',
    ]

    fieldsToDelete.forEach(field => {
        delete item[field];
    })

    colyClient.room.send('doAction', {
        serviceName: surfaceService,
        commandType: 'create',
        data: {
            ...item,
            graffiti: '',
            pixelsArray
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}
