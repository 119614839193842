import {
    CREATE_MATERIAL_LOADING,
    CREATE_MATERIAL_SUCCESS,
    CREATE_MATERIAL_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

export const createMaterialSuccess = payload => ({
    type: CREATE_MATERIAL_SUCCESS,
    payload
})

export const createMaterialFailure = () => ({
    type: CREATE_MATERIAL_FAILURE
})

export const createMaterialAsync = payload => dispatch => {
    const { _id, materialTypeId, selectedCharacterId, quantity } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'material-instances',
        name: 'create',
        data: {
            materialTypeId,
            selectedCharacterId,
            quantity
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}