export const guineaPalmSmellTypes = [
	"Festive",
    "Sugary",
    "Fruity",
    "Creamy",
    "Intoxicating",
    "of fruit",
    "of lemon",
    "of passion fruit",
    "of Lemon",
    "of Clove",
    "Herbal",
    "of Citrus",
    "Woody",
    "Aromatic",
    "Fresh",
    "of dry soil",
    "of bitter almonds",
    "of tobacco",
    "of cedar",
    "of nutmeg",
    "of clove",
    "of vanilla",
    "of ash",
    "of eucalyptus",
    "of lemon myrtle",
    "of paperbark",
    "of liana",
    "of soil",
    "of sand and salt",
    "of salty white flowers",
    "of an abandoned dune",
    "of hidden springs",
    "of dessicated coconut",
    "of fresh coconut and citrus",
    "sultry",
    "of salt and hard drink",
    "of lactonic plant matter",
    "warm and rich",
    "of shaved coconut and leaves",
    "of creamy sandalwood",
    "of dry wood",
    "of a musk which makes you heave",
    "of gardenia and ozone",
    "of the surf at low tide",
    "dry and balmy",
    "citric and floral",
    "sand-soaked",
    "astringent",
    "of greasy and overripe fruit",
    "zippy with a hint of leafy greens",
]

export const guineaPalmBranchTypes = [
    "leathery",
    "russet",
    "buff",
    "sienna",
    "umber",
    "maroon",
    "claret",
    "yellowish",
    "sinewy",
    "branchy",
    "woody",
    "Conical",
    "vibrant",
    "iridescent",
    "lively",
    "splendid",
    "royal",
    "spotted",
    "magnificent",
    "opulent",
    "steady",
    "stately",
]

export const guineaPalmLeafTypes = [
	"Dark Green",
    "Intoxicating",
    "feather-like",
    "slimy",
    "dangerous",
    "Prickly",
    "Soothing",
    "Bitter",
    "long",
    "hard",
    "spear",
    "flame",
    "darkly verdant",
    "lush",
    "spurned",
    "wavy-edged",
    "fertile",
    "dense",
    "abundant",
    "green",
    "aged",
    "brittle",
    "crackled",
    "pinnate",
    "ovate",
]