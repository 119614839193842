export const kangarooFaceSentences = [
"It has a long, furry muzzle.",
"Large, black eyes peer out from beneath its furry brows.",
"Its long, furry ears are tipped with black.",
"It has a long, furry snout.",
"It has small, beady eyes.",
"Bulging, bright eyes peer out from a furry face. ",
"A long, furry muzzle tapers to a black nose. ",
"Small, rounded ears sit atop a furry head. ",
"Long eyelashes frame bulging eyes. ",
"Fluffy ears that are long and pointy",
"A face that is mostly brown with a white belly",
"A long, pointy nose",
"Two furry eyebrows that are always raised",
"Large, brown eyes that are always alert",
"A face that is long",
]

export const kangarooBodySentences = [
"long, powerful hind legs that are used for jumping ",
"a long tail that is used for balance ",
"the kangaroo has a long, powerful hind leg",
"the kangaroo's tail is long and muscular",
"the kangaroo's head is large and round",
"the kangaroo's fur",
]

export const kangarooSoundWords = [
	"thumping"
]

export const kangarooFurWords = [
    "Desolate",
    "dingy",
    "fetid",
    "grimy",
    "grubby",
    "gloomy",
    "ragged",
    "soiled",
    "tattered",
    "unkempt",
    "Coarse",
    "bristly",
    "dry",
    "patchy",
    "dull",
    "discolored",
    "mottled",
    "roughened",
    "scabby",
    "Soft",
    "Coarse",
    "Thick",
    "Brown",
    "Grey",
    "Spotted",
    "Smooth",
    "Silky",
    "Fluffy",
    "Hairy",
    "Warm",
    "Fuzzy",
    "Cozy",
    "Itchy",
    "Scratchy",
    "Prickly",
    "Hairy",
    "Furry",
    "Downy",
    "Woolly"
]

export const kangarooSmellWords = [
"dusty",
"dry",
"arid",
"parched",
"barren",
"unsubstantial",
"tenuous",
"gossamer",
"diaphanous",
"filmy",
"Dank",
"Pungent",
"Putrid",
"Rancid",
"Repulsive",
"Stench",
"Stink",
"Smell",
"Aroma",
"Fragrance",
"Earthy",
"Pungent",
"Ammonia-like",
"Musky",
"Gamey",
"Sweaty",
"Blood-tinged",
"Grassy",
"Dung-scented",
"Animalic",
]

export const kangarooBehaviourSentences = [
"Staring intently at you with its bright eyes",
"Smacking its lips together rhythmically",
"Constantly shifting its weight from one foot to the other",
"Bouncing around erratically",
"The kangaroo is scratching itself vigorously.",
]