import React from "react";
import { connect } from 'react-redux'
import colorContrast from 'color-contrast'

import { BALANCE } from '../../../services/balance-config';

import InventoryActions from '../../inventory/inventory-actions/InventoryActions';

import { client } from '../../../services/client';
import { flashCharacterColorAsync } from '../../../redux/actions/characters.actions';
import { logOutAsync } from '../../../redux/actions/user.actions';
import {
    selectUser,
    selectCharacter,
    selectCurrencies,
    selectCoins,
    selectCoinTypes,
    selectEmbarkCharacter
} from '../../../redux/selectors';

import './Character.css';

class Character extends React.Component {

    componentDidMount() {
    }

    logout() {
        localStorage['feathers-jwt'] = null;
        this.props.logOutAsync();
        window.location.reload();
    }

    toggleColor(character) {
        this.props.flashCharacterColorAsync(character);
    }

    render() {
        return (
            <div>
                <div className="first-row">
                    <div className="first-row-character">
                        <div className="character-face"></div>
                        <span className="name"
                            onClick={() => this.toggleColor(this.props.character)}
                            >
                        
                            {this.props.character.name || 'Unknown Character'}
                        </span>
                    </div>
                </div>
                <div className="character-traits">
                    <span className="dd"><span className="hu">HU</span>:&nbsp;</span>
                    <span className="dt">{BALANCE.HUNGER_IMPACTS_VALUE - this.props.character.hunger}/{BALANCE.HUNGER_IMPACTS_VALUE}</span>
                    <span className="dd"><span className="hp">HP</span>:&nbsp;</span>
                    <span className="dt">{this.props.character.healthPoints}/{this.props.character.maxHealthPoints || 20}</span>
                    <span className="dd"><span className="in">IN</span>:&nbsp;</span>
                    <InventoryActions isDisabled={true}/>
                    <span className="dd"><span className="action">XP</span>:&nbsp;</span>
                    <span className="dt">{this.props.character.experiencePoints || 0}</span>
                </div>
            </div>
        )
    }
}

const mapToStateProps = state => {
    const user = selectUser(state)
    const character = selectCharacter(state);
    const embarkCharacter = selectEmbarkCharacter(state);

    if (embarkCharacter) {
        return { user, character: {
            ...character,
            ...embarkCharacter,
        } }
    }

    return { user, character }
}

export default connect(
    mapToStateProps,
    {
        flashCharacterColorAsync,
        logOutAsync
    }
)(Character);