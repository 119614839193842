export const toolTypeNames = {
	HAMMER: 'Hammer',
	TENT_POLE: 'Tent Pole',
	REINS: 'Reins',
	SADDLE: 'Saddle',
	BRIDLE: 'Bridle',
	HANDCUFFS: 'Handcuffs',
	FURNITURE_MOVER: 'Furniture Mover',
	AXE: 'Axe',
	PAINTBRUSH: 'Paintbrush',
	HAND_PLOUGH: 'Hand Plough',
	HORSE_PLOUGH: 'Horse Plough',
	SICKLE: 'Sickle',
	SCYTHE: 'Scythe',
	HOE: 'Hoe',
	SEED_PLANTER: 'Seed Planter',
	SPADE: 'Spade',
	PRIMITIVE_SLEDGEHAMMER: 'Primitive Sledgehammer',
	SLEDGEHAMMER: 'Sledgehammer',
	PICKAXE: 'Pickaxe',
	PRIMITIVE_PICKAXE: 'Primitive Pickaxe',
	PRIMITIVE_AXE: 'Primitive Axe',
	PRIMITIVE_PLOUGH: 'Primitive Plough',
	PRIMITIVE_HOE: 'Primitive Hoe'
}