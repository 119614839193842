export const chimpanzeeFaceSentences = [
    "Its soft brown eyes are kind and gentle.",
"Its lips are thick and her teeth are sharp.",
"Its cheeks are round and his nose is small.",
"Its hair is thick and black, and covers her head like a blanket.",
"The face of a chimpanzee is covered in hair, with a long nose and protruding lips.",
"The eyes of a chimpanzee are large and expressive, and their brows are furrowed.",
"The face of a chimpanzee is very animated,",
"Its face is covered in fur, with a brownish hue.",
"Its eyes are small and beady, set close together.",
"Its ears are large and protrude from his head.",
"Its nose is black and flat.",
"A big, brown face with a big, brown nose.",
"Two big, brown eyes with long, black lashes.",
"A wide mouth with big, white teeth.",
"Long, black hair on the face and head.",
"Big, black ears sticking out",
]

export const chimpanzeeBodySentences = [
"Its fur is a dark brown, almost black.",
"It has a long tail that it uses for balance.",
"Its arms are longer than his legs.",
]

export const chimpanzeeSoundWords = [
    "Cooing",
    "chattering",
    "screeching",
    "yelping",
    "panting",
    "laughing",
    "hooting",
    "wheezing",
    "grunting",
    "coughing",
    "Cackle",
    "Chatter",
    "Clatter",
    "Clink",
    "Hiss",
    "Rattle",
    "Roar",
    "Snarl",
    "Squawk",
    "Titter",
    "Animalistic",
    "Intense",
    "Fierce",
    "Loud",
    "Boisterous",
    "Rambunctious",
    "Powerful",
    "Overwhelming",
    "Exuberant",
    "Enthusiastic",
    "screeching",
    "howling",
    "yelping",
    "whimpering",
    "chattering",
    "clucking",
    "barking",
    "grunting",
    "squeaking",
    "coughing"
]

export const chimpanzeeFurWords = [
    "thick",
    "coarse",
    "dark",
    "matted",
    "oily",
    "rank",
    "smelly",
    "hairy",
    "unkempt",
    "unkempt",
    "Brown",
    "Hairy",
    "Coarse",
    "Black",
    "Thick",
    "Rough",
    "Dry",
    "Scruffy",
    "Matted",
    "Muddy",
    "Creamy",
    "downy",
    "soft",
    "fuzzy",
    "thick",
    "luxurious",
    "furry",
    "silky",
    "velvety",
    "smooth",
    "Dense",
    "dark",
    "coarse",
    "thick",
    "wiry",
    "unruly",
    "patchy",
    "sensitive",
    "hairy",
    "rough",
    "Furry",
    "Thick",
    "course",
    "wiry",
    "spiky",
    "bristly",
    "patchy",
    "matted",
    "greasy",
    "rank"
]

export const chimpanzeeSmellWords = [
    "acrid",
    "sharp",
    "pungent",
    "banana-like",
    "pungent",
    "musky",
    "sweet",
    "sharp",
    "earthy",
    "floral",
    "woody",
    "nutty",
    "fruity",
    "musky",
    "earthy",
    "pungent",
    "sharp",
    "animalistic",
    "rank",
    "acrid",
    "potent",
    "ripe",
    "stinky",
    "Sour",
    "musky",
    "acrid",
    "pungent",
    "ammonia-like",
    "rank",
    "fetid",
    "stinking",
    "putrid",
    "reeking",
    "Rank",
    "musty",
    "musky",
    "dank",
    "foul",
    "pungent",
    "reeking",
    "stinking",
    "stenchy"
]

export const chimpanzeeBehaviourSentences = [
"it is looking at you intently",
"it is making strange noises",
"it is doing soyouthing with its hands",
"it is moving its head from side to side",
"it is scratching itself",
"it is looking at you intently with its beady eyes",
"it is slowly stalking towards you",
"it is making loud grunting noises",
"it is baring its teeth",
"it is beating its chest",
"it is behaving oddly",
"it is staring at us",
"it is making strange noises",
"it is panting heavily",
"it is sweating",
"it is looking disheveled",
"it is glaring at you",
"it is making strange noises",
"it is banging its fists on the ground",
"it is pacing back and forth",
"it is throwing things",
"it is staring at you.",
"it is making weird noises.",
"it is wringing with its hands.",
]