import {
    GET_DEAD_CHARACTERS_LOADING,
    GET_DEAD_CHARACTERS_SUCCESS,
    GET_DEAD_CHARACTERS_FAILURE,

    BURY_DEAD_CHARACTER_LOADING,
    BURY_DEAD_CHARACTER_SUCCESS,
    BURY_DEAD_CHARACTER_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';

export const getDeadCharactersSuccess = payload => ({
    type: GET_DEAD_CHARACTERS_SUCCESS,
    payload
})

export const getDeadCharactersFailure = () => ({
    type: GET_DEAD_CHARACTERS_FAILURE
})

export const getDeadCharactersAsync = payload => dispatch => {
    return client.service('dead-characters').find({ query: { z: payload.z, panelId: payload.panelId } })
        .then((response) => {
            dispatch(getDeadCharactersSuccess(response))
        })
        .catch((error) => {
            console.error('error: ', error);
            dispatch(showUnknownError())
            throw new Error(error);
        });
}

export const buryDeadCharacterSuccess = payload => ({
    type: BURY_DEAD_CHARACTER_SUCCESS,
    payload
})

export const buryDeadCharacterFailure = () => ({
    type: BURY_DEAD_CHARACTER_FAILURE
})

export const buryDeadCharacterAsync = payload => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'dead-characters',
        commandType: 'remove',
        data: {
            _id,
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}