export const deerFaceSentences = [
"Big, blue eyes that look like they could tell a thousand stories. ",
"A soft, blue nose that's always cold to the touch. ",
"Long, blue eyelashes that frame the eyes perfectly. ",
"Big, blue ears that are always alert and listening",
"Bold and blue, this deer's face is a striking sight.",
"The bright blue of this deer's face is a beautiful contrast to its fur.",
"This deer's face is a deep, rich blue.",
"powder blue deer face with delicate white spots",
"electric blue deer face with piercing eyes",
"navy blue deer face with a regal air",
"sky blue deer face with a sweet expression",
"baby blue deer face with big, dark eyes",
"robin's egg blue",
"Smiling blue deer face with big ears",
"Blue deer face with a tongue sticking out",
"Cute blue deer face with a nose",
"Grumpy blue deer face with antlers",
"playful blue deer face with spots",
"fierce blue deer face with big teeth",
"a pair of blue eyes staring out from a dark face",
"a face with a blue nose and antlers",
"a face with blue spots and a long neck",
"a face with big blue eyes and long lashes",
"a face with a blue tongue sticking out"
]

export const deerBodySentences = [
"its body is adorned with delicate white spots.",
"its body is covered in a soft, downy fur.",
"its body is lean and muscular.",
"its body is sleek and graceful.",
"Deep blue deer with a long neck and slender body.",
"Majestic blue deer with antlers reaching high into the sky.",
"Blue deer have a sleek and shiny coat.",
"They have long and graceful necks.",
"Their eyes are a deep, dark blue.",
"They have four long and slender legs.",
"Their hooves are a light blue color.",
"its body is covered in a soft, downy fur.",
"its coat is sleek and shiny.",
"its body is muscular and powerful.",
"its body is graceful and elegant.",
"Fluffy blue deer body with a cute little tail.",
]

export const deerSoundWords = [
        "Sneezing",
    "wheezing",
    "gasping",
    "coughing",
    "hacking",
    "panting",
    "snorting",
    "grunting",
    "stamping",
    "bleating",
    "Whinnying",
    "bleating",
    "nickering",
    "baying",
    "braying",
    "howling",
    "bayonetting",
    "bugling",
    "trumpeting",
    "racketing",
    "howling",
    "shrieking",
    "wailing",
    "moaning",
    "groaning",
    "crying",
    "yelling",
    "screaming",
    "hollering",
    "whooping",
    "Skipping",
    "munching",
    "crunching",
    "nibbling",
    "slurping",
    "licking",
    "chomping",
    "stomping",
    "trudging",
    "plodding",
    "Jeering",
    "Sneering",
    "Staring",
    "Glaring",
    "Hissing",
    "Spitting",
    "Crouching",
    "Swiping",
    "Slashing"
]

export const deerFurWords = [
    "Elastic",
    "Flecked",
    "Greased",
    "glossy",
    "refined",
    "sleek",
    "sky-blue",
    "navy",
    "ethereal",
    "Blue",
    "Decorative",
    "Elaborate",
    "Elegant",
    "Luxurious",
    "Majestic",
    "Ornate",
    "Refined",
    "Stunning",
    "opulent",
    "Blue",
    "Furry",
    "Soft",
    "Thick",
    "Warm",
    "Cozy",
    "Itchy",
    "Scratchy",
    "Stiff",
    "Rough",
    "Soft",
    "supple",
    "smooth",
    "dense",
    "durable",
    "fiery",
    "electric",
    "startling",
    "vibrant",
    "azure"
]

export const deerSmellWords = [
    "pungent",
    "musky",
    "gamey",
    "earthy",
    "sweaty",
    "bloody",
    "rancid",
    "spoiled",
    "stinky",
    "Crisp ",
    "Clean ",
    "Powdery ",
    "Light ",
    "Subtle ",
    "Airy ",
    "Delicate ",
    "Ethereal ",
    "Fragrant ",
    "Pleasing",
    "Crisp",
    "Earthy",
    "Blueberry",
    "Fresh",
    "Crisp",
    "Clean",
    "Blueberry",
    "Powdery",
    "Soapy",
    "Clove",
    "Warm",
    "Organic",
    "Pure",
    "Subtle",
    "Wholesome"
]

export const deerBehaviourSentences = [
"it is standing very still.",
"it is staring at us.",
"it is making strange noises.",
"it is behaving oddly.",
"it is making strange noises.",
"it is staring at me with its blue eyes.",
"it is making a strange sounds.",
"it is standing very still.",
"it is unresponsive to stimuli.",
"it is displaying unusual behaviour.",
"it is exhibiting signs of stress.",
"it is acting oddly.",
"it is making an odd noise",
"it is standing very still",
"it is staring at you",
"it is licking its lips",
"it is stamping its feet",
"it is making a face"
]