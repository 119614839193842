import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';

export const updateTradeAsync = payload => dispatch => {
    const { _id, serviceName, markedForTradeCount, tradePrice } = payload;

    const update = {}

    if (markedForTradeCount !== undefined) {
        update.markedForTradeCount = markedForTradeCount
    }

    if (tradePrice !== undefined) {
        update.tradePrice = tradePrice
    }

    return client.service(serviceName).patch(_id, {
        ...update
    })
        .catch((error) => {
            console.error('error: ', error);
            dispatch(showUnknownError({ message: error.message }))
            throw new Error(error);
        });
}

export const sendTradeOfferAsync = payload => dispatch => {
    const {
        characterId,
        targetCharacterId,
        tradeItems,
        targetTradeItems,
        lastOfferBy,
        isCashPurchase
    } = payload;

    return client.service('trade-offers').create({
        characterId,
        targetCharacterId,
        tradeItems,
        targetTradeItems,
        lastOfferBy
    }, {
        isCashPurchase
    })
        .then((response) => {
            return response;
        })
}

export const updateTradeOfferAsync = payload => dispatch => {
    const {
        _id,
        characterId,
        targetCharacterId,
        tradeItems,
        targetTradeItems,
        lastOfferBy
    } = payload;

    return client.service('trade-offers').update(_id, {
        characterId,
        targetCharacterId,
        tradeItems,
        targetTradeItems,
        lastOfferBy
    })
        .then((response) => {
            return response;
        })
}

export const getTradeOffersAsync = payload => dispatch => {
    const {
        characterId,
        targetCharacterId,
        tradeItems,
        targetTradeItems
    } = payload;

    return client.service('trade-offers').find({ query: { characterId: { $in: [characterId, targetCharacterId] }, targetCharacterId: { $in: [characterId, targetCharacterId] } } })
        .then((response) => {
            console.log('trade offer: ', response);
            return response.data[0];
        })
}

export const acceptTradeOfferAsync = payload => dispatch => {
    const {
        _id
    } = payload;

    return client.service('trade-offers').remove(_id, { query: { isAcceptedOffer: true } })
        .then((response) => {
            return response;
        })
        .catch(error => {
            console.log('ERROR HERE MEATE', error);
        })
}

export const rejectTradeOfferAsync = payload => dispatch => {
    const {
        _id,
    } = payload;

    return client.service('trade-offers').remove(_id)
        .then((response) => {
            return response;
        })
        .catch(error => store.dispatch(showUnknownError(error)))
}
