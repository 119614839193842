export const sluggishFaceSentences = [
"A face with a big, wide mouth that drools slime.",
"A face with small, beady eyes that are almost hidden beneath its puffy flesh.",
"A face that is covered in short, coarse hair.",
"A face with large, floppy ears.",
"wrinkled and dry face",
"drooling and saliva- coated face",
"crusty and scabby face",
"pock-marked and bumpy face",
"warty and hairy face",
"oily and sweaty face",
"watery and runny face",
"A disengaged, almost lazy face.",
"A face that seems content to just sit there.",
"A face with a slimey texture.",
"A face that is slow to react.",
"A face that is wet and cold to the touch.",
"It has a large, bulbous body with a protruding slug-like face.",
"It has a long, slimy body with a small, slug-like face.",
"It has a short, fat body with a large, slug-like face.",
]

export const sluggishBodySentences = [
"Its body is slow and sluggish, as if it is constantly moving through molasses.",
"It is plump and jelly-like, rippling with each sluggish step.",
"It is covered in a slimy mucous that constantly drips and oozes.",
"A sluggish body that moves slowly and with great effort.",
"A large, sluggish body that is difficult to move.",
"A body that is covered in slime and mucus.",
"A slimy, slippery body that is difficult to grip.",
"It's body is made mostly of soft, jelly-like flesh.",
"It's slow and sluggish, barely moving most of the time.",
"It's huge and bloated, ready to burst at any moment.",
"Flabby and soft to the touch.",
"Covered in a slimy film.",
"Undulating in a slow, lazy way.",
"Thick and slug-like.",
"Narrow and long.",
"Bulky and track-like, this creature lumbers about with seeming ease.",
"Its scaly hide is a mottled mix of yellow and brown, and its eyes seem almost too large for its head.",
]

export const sluggishSoundWords = [
"Oozing",
"Slithering",
"Slimy",
"Goopy",
"Downloading",
"Slurping",
"Snorting",
"Munching",
"Crunching",
"Chewing",
"Slopping",
"Slupping",
"Drooling",
"Gurgling",
"Cloying",
"Treacly",
"Oozing",
"Slithery",
"Slimy",
"Nauseating",
"Repugnant",
"lurching",
"dragging",
"oozing",
"scraping",
"skittering",
"hissing",
"gurgling",
"wheezing",
"rattling",
"putrid",
]

export const sluggishFurWords = [
    "Slimy",
"Gooey",
"Sticky",
"Oozy",
"Sleek",
"slimy",
"wet",
"icky",
"smelly",
"gross",
"sticky",
]

export const sluggishSmellWords = [
"Foul",
"Rotten",
"Vile",
"Disgusting",
"Pungent",
"nauseating",
"revolting",
"putrid",
"stinking",
"fetid",
"rank",
"fetid",
"brimstone",
"decaying",
"rotting",
"stench",
"effluence",
"malodorous",
"rankle",
"noisome",
"rank",
"fetid",
"musty",
"dank",
"reeking",
"stinking",
"malodorous",
"putrid",
"rank",
"foul",
]

export const sluggishBehaviourSentences = [
"it is making a strange rattling noise as it moves",
"it is slowly moving towards you",
"it is sluggishly moving its arms and legs",
"it is lightly shifting from foot to foot.",
"it is staring intently at something off in the distance.",
"it is making low, guttural noises.",
]