import {
    GET_WORKSHOP_RECIPES_LOADING,
    GET_WORKSHOP_RECIPES_SUCCESS,
    GET_WORKSHOP_RECIPES_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';

export const getWorkshopRecipesSuccess = payload => ({
    type: GET_WORKSHOP_RECIPES_SUCCESS,
    payload
})

export const getWorkshopRecipesFailure = () => ({
    type: GET_WORKSHOP_RECIPES_FAILURE
})

export const getWorkshopRecipesAsync = payload => dispatch => {
    return dispatch(getRecursivelyAsync('workshop-recipes', getWorkshopRecipesSuccess, 0));
}