import React from "react";
import { connect } from 'react-redux'

import { selectCharacterSeeds } from '../../../redux/selectors';
import { client } from '../../../services/client';
import { hideAllMenus } from '../../../redux/actions/keyboard-shortcuts.actions';

class SeedList extends React.Component {

    onClose() {
        this.props.hideAllMenus();
    }

    render() {
        let listItems = this.props.inventory.inventory.map((material) => {
            return (
                <li key={material._id}
                    onClick={() => (this.props.onItemSelect(material))}>
                    {material?.materialType?.name}
                    &nbsp;({material?.plant?.name || 'old'})
                </li>
            );
        })

        if (this.props.inventory.inventory.length === 0) {
            listItems = (<div>You are not holding any seeds</div>)
        }

        return (
            <div>
                <span className="close-button" onClick={() => this.onClose()}>X</span>
                <ul>{listItems}</ul>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const inventory = selectCharacterSeeds(state)

    return { inventory }
}

export default connect(
    mapStateToProps,
    {
        hideAllMenus
    }
)(SeedList);