import { GET_TOOL_RECIPES_SUCCESS } from "../actionTypes";

const initialState = []

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_TOOL_RECIPES_SUCCESS: {
      const toolRecipes = action.payload;

      console.log(toolRecipes);

      if (!toolRecipes){
        return {...state};
      }
      return [...state, ...action.payload]
    }

    default:
      return state;
  }
}
