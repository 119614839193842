import React from "react";
import { connect } from 'react-redux'

import { destroyFurnitureAsync } from '../../../redux/actions/furniture.actions';
import { restOnFurnitureAsync } from '../../../redux/actions/character.actions';
import {
    selectHighlight,
    selectCharacter,
    selectFurniture,
    selectNearbyFurniture,
    selectCharacterTools
} from '../../../redux/selectors';
import { client } from '../../../services/client';
import store from '../../../redux/store';
import { disableKeyboardMovement, enableKeyboardMovement } from '../../../redux/actions/keyboard-shortcuts.actions';

class FurnitureActions extends React.Component {

    previousCharacter;
    previousTiles;

    componentDidMount() {
    }

    onItemDestroy(furnitureInstance) {
        this.props.destroyFurnitureAsync(furnitureInstance)
    }

    onRest(furnitureInstance) {
    	this.props.restOnFurnitureAsync({ ...furnitureInstance, characterId: this.props.character._id })
    		.then(() => store.dispatch(disableKeyboardMovement()))

    }

    onStopResting(furnitureInstance) {
    	this.props.restOnFurnitureAsync({ _id: null, characterId: this.props.character._id })
    		.then((response) => {
    			store.dispatch(enableKeyboardMovement())
    		})
    }

    isOn(furnitureInstance) {
    	return this.props.character.position.x === furnitureInstance.position.x
    		&& this.props.character.position.y === furnitureInstance.position.y;
    }

    isNecessaryToolInInventory(item) {
        let isIncorrectTool = false;
        item.recipes[0].destructionTools?.forEach(requiredTool => {
            if (!this.props.characterTools.find(tool => tool.toolTypeId === requiredTool.toolTypeId)) {
                isIncorrectTool = true;
            }
        })

        return !isIncorrectTool;
    }

    render() {
        const furnitureItems = Object.values(this.props.furniture).map((item) => {
            const destroyButton = !this.props.highlight && this.isNecessaryToolInInventory(item) ?(<span className="destroy-construction-action" onClick={() => (this.onItemDestroy(item))}>Destroy</span>) : ('');

            let restButton;

            if (!this.props.highlight && this.isOn(item)) {
            	if (this.props.character.restingFurnitureId) {
            		restButton = (<span className="destroy-construction-action" onClick={() => (this.onStopResting(item))}>Stop Resting</span>)
            	} else {
            		restButton = (<span className="destroy-construction-action" onClick={() => (this.onRest(item))}>Rest</span>);
            	}
            }

            return (
                <li key={item._id}>
                    {item?.name || item?.furnitureType?.name}
                    &nbsp;({item.position.x}, {item.position.y})

                    &nbsp;{destroyButton}
                    &nbsp;{restButton}
                </li>
            )
        })
        return (
            <div>
                <ul>{furnitureItems}</ul>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const highlight = selectHighlight(state);
    const character = selectCharacter(state);
    const furniture = selectNearbyFurniture(state, highlight ? { position: { ...highlight } } : character);
    const characterTools = selectCharacterTools(state);

    return { highlight, character, furniture, characterTools }
}

export default connect(
    mapStateToProps,
    {
        destroyFurnitureAsync,
        restOnFurnitureAsync
    }
)(FurnitureActions);