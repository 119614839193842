export const teleporterFaceSentences = [
"large, flared nostrils",
"sharp, fang-like teeth",
"two beady, red eyes",
"wrinkled, yellow skin",
"drooling mouth",
"its eyes are small and beady, set close together",
"its mouth is wide and filled with sharp, pointy teeth",
"its skin is yellow and scaly",
"Staring down with eyes that shimmer like a full moon on a dark night.",
"A mouth that is open wide in a perpetual scream, revealing razor sharp teeth.",
"A face that is partially concealed",

]

export const teleporterBodySentences = [
"SATURATED IN A DARK, OILY SUBSTANCE",
"THEIR BODY IS A MASSIVE, MUSCLED MOUND",
"It's body is bloated and its breath is rank.",
"The body of this monster is covered in a thick, rough hide. The hide is a dark brown color and is very tough.",
"His body is covered in thick, shiny scales.",
"He has enormous, clawed hands and feet.",
]

export const teleporterSoundWords = [
"Intimidating",
"Infuriating",
"Deafening",
"Terrifying",
"Ear-piercing",
"Petrifying",
"Emotional",
"Mental",
"Exhausting",
"screeching",
"wailing",
"howling",
"moaning",
"groaning",
"bellowing",
"roaring",
"screaming",
"yelling",
"trumpeting",
"growling",
"low",
"burbling",
"guttural",
"throaty",
"hissing",
"snorting",
"grunting",
"wheezing",
"Blaring",
"Eardrum-shattering",
"Deafening",
"Bellowing",
"Ear-piercing",
"screeching",
"Shrill",
"Loud",
"Obnoxious",
"Intolerable"
]

export const teleporterFurWords = [
    "hairy",
    "scaly",
    "slimy",
    "oily",
    "bristly",
    "spiky",
    "bumpy",
    "warty",
    "leathery",
    "scabby",
    "scaly",
    "slimy",
    "mottled",
    "spiky",
    "scabrous",
    "calloused",
    "craggy",
    "bristly",
    "hirsute",
    "shaggy",
    "downy",
    "fuzzy",
    "woolly",
    "hairy",
    "bristly",
    "shaggy",
    "mane",
    "pelt",
    "hide",
    "fur",
    "Thick",
    "furry",
    "oily",
    "greasy",
    "rank",
    "stinking",
    "reeking",
    "foul",
    "noisome",
    "putrid",
    "Bristly",
    "coarse",
    "furry",
    "gooey",
    "gross",
    "itchy",
    "slimy",
    "smelly",
    "sticky",
    "stringy"
]

export const teleporterSmellWords = [
    "Sour",
    "rank",
    "putrid",
    "rotting",
    "fetid",
    "malodorous",
    "stinking",
    "reeking",
    "noisome",
    "vile",
    "Pungent",
    "Rancid",
    "Acrid",
    "Fetid",
    "putrid",
    "stinking",
    "foul",
    "reeking",
    "noisome",
    "malodorous",
    "Pungent",
    "acrid",
    "rank",
    "reeking",
    "fetid",
    "stinking",
    "putrid",
    "foul",
    "noisome",
    "malodorous",
    "putrid",
    "rank",
    "vile",
    "rancid",
    "skanky",
    "fetid",
    "reeking",
    "pungent",
    "offensive",
    "Stench",
    "Pungent",
    "Rancid",
    "Foul",
    "Nauseating",
    "Repulsive",
    "Disgusting",
    "Revolting",
    "sickening",
    "abhorrent"
]

export const teleporterBehaviourSentences = [
"it is standing unnaturally still, as if it's waiting for something",
"it is making a strange, high-pitched noise that's grating on the ears",
"it is glaring at you with its large, menacing eyes",
"it is flailing its sharp claws wildly",
"it is gaping its razor-sharp teeth at you",
"it is making strange noises",
"it is making threatening gestures",
"it is foaming at the mouth",
"it is covered in blood",
"it is glaring at you",
"it is snorting and pawing the ground",
]