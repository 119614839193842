export const needlewoodGrassSmellTypes = [
    "temperate",
    "aromatic",
    "resinous",
    "sweet",
    "of Basil",
    "of Marjoram",
    "of Oregano",
    "dried",
    "of prairie",
    "sweet",
    "savoury",
    "nutty",
    "oceanic",
    "bitter",
    "of tobacco",
    "of tar",
    "of corkwood",
    "of broom",
    "of wattle",
    "of fir",
    "of juniper",
    "of fertile ground",
    "of sap oozing from bark",
    "simplex",
    "of opulent florals",
    "of gnawed-upon wood",
    "of constant supervision",
    "of a child's play room",
    "strangely at peace",
    "of diligently penned scrolls",
    "of a throne made of hay",
    "of a used dish cloth",
    "painful",
    "of fermented milk and woodworking",
    "of a pitched tent in the desert",
    "of skiving until the wee hours",
    "of weeks old offerings",
    "of skinned game",
    "of recent hunt",
    "of dust from chiseled surfaces",
    "of a vantage point atop a lea",
    "of cardamom and iris",
    "of dill and crisp paper",
    "enigmatic",
    "of olives and leather",
    "oddly pickled",
    "of hot wax",
    "of cut flowers in sandalwood",
    "of old tomes",
    "of dusty, forgotten texts"
    "of lost knowledge",
]

export const needlewoodGrassBranchTypes = [
]

export const needlewoodGrassLeafTypes = [
	"Flag",
    "brome",
    "nastic",
    "blade",
    "needle",
    "sword",
    "linear",
    "narrow",
    "pointed",
    "acicular",
    "tapering",
    "articulate",
    "parallel-veined",
    "glittering",
    "robust",
    "tall",
    "clumping",
    "larvae nibbling on its",
    "burr-covered",
    "bristly hairs hanging from its",
    "drought-tolerant",
    "flower sppikes on its",
    "Broom-like",
    "Furze",
    "stemborers on its",
    "dead",
    "healthy",
    "dying",
    "Sparse",
    "Thin",
    "Tall",
    "Lanky",
    "Droopy"
    "clawing",
    "wizened",
    "bizarrely scarred",
    "flaking",
    "mottled",
    "flamboyant",
    "awkward",
    "cowering",
    "stereotypical",
    "spear-point",
]