export const fossilisedSmellTypes = [
    "Smoky",
    "Sweet",
    "Resinous",
    "Piney",
    "of sagebrush",
    "of Aspen",
    "of Birch",
    "of Maple",
    "of Oak",
    "Resinous",
    "Clean",
    "Crisp",
    "Earthy",
    "of hay field",
    "of oatmeal",
    "of caramel",
    "of toffee",
    "of nutty",
    "of baked beans",
    "of maple",
    "woody",
    "of Cloves",
    "of Honey",
    "of Maple",
    "of manuka",
    "of brush box",
    "eucalyptus",
    "of grey gum",
    "of tar and earth pressure",
    "arrogant",
    "of nostaligic drugs",
    "of spicy rose",
    "of pungent, metallic flowers",
    "of floral sweat",
    "of buried bouquets",
    "earthy and sweet",
    "of thorned plants",
    "of smoked pear",
    "of lovesick poetry",
    "confident",
    "of liquids seeping from rot",
    "of pulverised rose petals",
    "repulsive",
    "bitter",
    "pickled",
    "of finely scrapped wood",
    "mysterious",
    "of compost",
    "of liquid amber",
    "of earwax",
    "of lingering campfire",
    "as if someone passed through ahead",
    "of rock-hewn nests",
    "of something hiding in the mist",
    "of sporified leaves",
    "of watery cashmere",
    "of a radiant nectar",
    "of water poured on hot stone",
    "of beech and refuse",
    "like someone is nearby",
    "of quickly packed belongings",
    "of desperate perspiration",
    "of muddy bulbs",
]

export const fossilisedBranchTypes = [
	"stalwart",
    "deep blue",
    "dark blue",
    "bluish-purple",
    "petrified",
    "ancient",
    "gnarled",
    "weathered",
    "crooked",
    "rough",
    "knotted",
    "dark",
    "blue",
    "dusty",
    "stone",
    "hardened",
    "mineralised",
    "chalcedonic",
    "dessicated",
    "encrusted",
    "silica",
    "dead",
    "pewter",
    "dun",
    "solid",
    "lustrous",
    "shiny",
    "bleached",
    "a towering crown and stiff",
    "rigid",
    "unyielding",
    "firm"
]

export const fossilisedLeafTypes = [
	"metallic",
    "aged",
    "solid",
    "preserved",
    "stained",
    "textured",
    "rock",
    "suspended",
    "glinting",
    "reflective",
    "spear-head",
    "knife-edged",
    "turquoise",
    "dense",
    "rock-hard",
    "crisp",
    "stiff",
    "chipped",
    "varnished",
    "frozen",
    "pressed",
    "unmoving",
    "jewel",
    "shard",
    "stalactite",
    "smooth"
]