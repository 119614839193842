import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, SHOW_CHARACTER_DEATH_PAGE, GET_ANIMALS_SUCCESS, HARVEST_ANIMAL_SUCCESS, ADD_ANIMAL, UPDATE_ANIMAL, REMOVE_ANIMAL, BEGIN_UPDATE_ANIMAL, UPDATE_ANIMAL_FAILED } from "../actionTypes";

const initialState = {
  animals: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState
      }
    }

    case GET_ANIMALS_SUCCESS: {
      const animals = action.payload;

      return {
        ...state,
        animals: [ ...action.payload.map(animal => ({ ...animal, position: JSON.parse(animal.position) })) ],
      }
    }

    case ADD_ANIMAL: {
      return {
        ...state,
        animals:[ ...state.animals, action.payload ]
      }
    }

    case BEGIN_UPDATE_ANIMAL: {
      let animals = [...state.animals];

      if (action.payload.panelId) {
        const animal = animals.find(animal => animal.id === action.payload.id)
        animals = [
          ...animals.filter(animal => animal.id !== action.payload.id),
          { ...animal, ...action.payload, lastPosition: {...animal.position} }
        ]
      }

      return {
        ...state,
        animals: [ ...animals ],
      }
    }

    case UPDATE_ANIMAL_FAILED: {
      let animals = [...state.animals];

      if (action.payload.panelId) {
        const animal = animals.find(animal => animal.id === action.payload.id)
        animals = [
          ...animals.filter(animal => animal.id !== action.payload.id),
          { ...animal, ...action.payload, position: {...animal.lastPosition} }
        ]
      }

      return {
        ...state,
        animals: [ ...animals ],
      }
    }

    case UPDATE_ANIMAL: {
      let newPosition
      try {
        newPosition = JSON.parse(action.payload.position);
      } catch(e) {
        newPosition = action.payload.position;
      }

      const result = {
        ...state,
        animals: [
          ...state.animals.filter(tool => tool.id !== action.payload.id),
          { ...state.animals.find(tool => tool.id === action.payload.id), ...action.payload, position: newPosition }
        ],
      }

      return result
    }

    case REMOVE_ANIMAL: {
      return {
        ...state,
        animals: [ ...state.animals.filter(tool => (tool.id !== action.payload.id)) ],
      }
    }

    default:
      return state;
  }
}
