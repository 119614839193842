import {
    CREATE_WEAPON_LOADING,
    CREATE_WEAPON_SUCCESS,
    CREATE_WEAPON_FAILURE,
    GET_WEAPONS_LOADING,
    GET_WEAPONS_SUCCESS,
    GET_WEAPONS_FAILURE,
    ADD_WEAPON,
    UPDATE_WEAPON,
    REMOVE_WEAPON,
    GET_TRADE_WEAPONS_SUCCESS,
    GET_TRADE_WEAPONS_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

import { moveItemFailure } from './move-item.actions';

export const createWeaponSuccess = payload => ({
    type: CREATE_WEAPON_SUCCESS,
    payload
})

export const createWeaponFailure = () => ({
    type: CREATE_WEAPON_FAILURE
})

export const createWeaponAsync = payload => dispatch => {
    const { _id, weaponTypeId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'weapon-instances',
        name: 'create',
        data: {
            weaponTypeId
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getWeaponsSuccess = payload => {
    return ({
        type: GET_WEAPONS_SUCCESS,
        payload
    })
}

export const getWeaponsFailure = () => ({
    type: GET_WEAPONS_FAILURE
})

export const getWeaponsAsync = payload => dispatch => {
    let query = { $or: [{ panelId: payload.panelId, z: payload.z}, { characterId: payload.characterId}] }

    if (payload.isNotFirstInitialisation) {
        query = { panelId: payload.panelId, z: payload.z}
    }

    return client.service('weapon-instances').find({ query })
        .then((response) => {
            response = response.map(weapon => ({ ...weapon, weaponType: payload.weaponTypes.find(type => type._id === weapon.weaponTypeId) }))
            dispatch(getWeaponsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const getTradeWeaponsSuccess = payload => {
    return ({
        type: GET_TRADE_WEAPONS_SUCCESS,
        payload
    })
}

export const getTradeWeaponsFailure = () => ({
    type: GET_TRADE_WEAPONS_FAILURE
})

export const getTradeWeaponAsync = payload => dispatch => {
    return client.service('weapon-instances').find({ query: { characterId: payload.characterId, markedForTradeCount: { $gte: 1 } }})
        .then((response) => {
            response = response.map(weapon => ({ ...weapon, weaponType: payload.weaponTypes.find(type => type._id === weapon.weaponTypeId) }))
            dispatch(getTradeWeaponsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const addWeaponToTileAsync = payload => dispatch => {
    const { weaponInstance, tileId, tileTypeId } = payload;
    const { weaponTypeId, quantity, recipeId, characterId, _id } = weaponInstance;

    colyClient.room.send('doAction', {
        serviceName: 'weapon-instances',
        commandType: 'create',
        data: {
            weaponTypeId,
            quantity,
            tileId,
            tileTypeId,
            position: {},
            characterId: ""
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addWeaponToCharacterAsync = payload => dispatch => {
    const { weaponInstances, characterId } = payload;
    const { createdAt, weaponTypeId, quantity, recipeId, _id } = weaponInstances[0];

    colyClient.room.send('doSuperAction', {
        serviceName: 'weapon-instances',
        name: 'fetch',
        data: {
            weaponTypeId,
            quantity,
            tileId: "",
            characterId,
            instanceIds: weaponInstances.map(inst => inst._id)
        },
        params: {
            query: {
                movementFrom: {
                    _id: undefined
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addWeapon = payload => {
    return ({
        type: ADD_WEAPON,
        payload
    })
}

export const updateWeapon = payload => {
    return ({
        type: UPDATE_WEAPON,
        payload
    })
}

export const removeWeapon = payload => {
    return ({
        type: REMOVE_WEAPON,
        payload
    })
}
