export const sealFaceSentences = [
"A face that looks like it was chiseled out of stone",
"A face that seems to be always smiling",
"A face covered in scars",
"Brown eyes that look like they've seen everything",
"Lips that are pursed in concentration",
]

export const sealBodySentences = [

]

export const sealSoundWords = [
    "Wailing",
    "Howling",
    "Moaning",
    "calling",
    "Echoing",
    "Resounding",
    "Screeching",
    "Piercing",
    "shrieking",
    "ululating",
    "barking",
    "howling",
    "yelping",
    "yipping",
    "whining",
    "whimpering",
    "snarling",
    "growling",
    "rumbling",
    "grumbling",
    "Barking",
    "growling",
    "howling",
    "whimpering",
    "whining",
    "yelping",
    "coughing",
    "snorting",
    "wheezing",
    "gasping",
    "barking",
    "howling",
    "wailing",
    "yelping",
    "yipping",
    "whining",
    "crying",
    "whimpering",
    "whinnying",
    "bleating",
    "Hissing",
    "roaring",
    "grunting",
    "growling",
    "barking",
    "howling",
    "yelping",
    "crying",
    "wailing",
    "screaming"
]

export const sealFurWords = [
"Downy",
"Soft",
"Sleek",
"Glossy",
"Supple",
"Water-resistant",
"Warm",
"Durable",
"Thick",
"Luxurious",
"Weathered",
"Thick",
"Majestic",
"Lustrous",
"Sleek",
"Waterproof",
"Windproof",
"Sturdy",
"Protective",
"Hairy",
"thick",
"luxurious",
"warm",
"durable",
"oily",
"course",
"bristly",
"heavy",
"thick-clawed",
"water-resistant",
"glossy",
"smooth",
"soft",
"thick",
"dense",
"warm",
"waterproof",
"windproof",
"salt-resistant",
"resilient",
"Flecked",
"Glossy",
"Oiled",
"Sleek",
"Smooth",
"Soft",
"Supple",
"Velvet",
"Water-repellent",
"Wet-looking",
]

export const sealSmellWords = [
    "Salty",
    "Damp",
    "Sweaty",
    "Musky",
    "Wet",
    "Oily",
    "Fishy",
    "Iodine",
    "Chlorine",
    "Ammonia",
    "Musty",
    "Salty",
    "Wet",
    "Heavy",
    "Dank",
    "Fishy",
    "Ammonia",
    "Oily",
    "Smoky",
    "Tar",
    "Salty",
    "Damp",
    "Slimy",
    "Fishy",
    "Wet",
    "Crusty",
    "Bleached",
    "Moist",
    "Icy",
    "Salty",
    "Musky",
    "Damp",
    "Exotic",
    "amey",
    "pungent",
    "briny",
    "fresh",
    "wet",
    "salty",
    "humid",
    "fishy",
    "oceanic",
    "algae-covered",
    "rocky",
    "musky",
    "sandy"
]

export const sealBehaviourSentences = [
"it is lethargic and barely swimming",
"it is swimming oddly and bumping into things",
"it is swimming in circles",
"it is swimming erratically and jumping out of the water",
"it is using its front flippers to climb up a tree",
"it is eating a fish whole",
"it is chewing on a rock",
"it is covered in a strange black substance",
"it is floating upside down in the water",
"it is making strange grunting noises",
"it is swimming in circles",
"it is scratching itself with its flippers",
]