export const saigaFaceSentences = [
"Square face ",
"High cheekbones ",
"Angular face ",
"Prominent forehead ",
"Straight nose ",
"Thin lips ",
"Pale skin ",
"Long, narrow face ",
"Sloping forehead ",
"Flushed cheeks",
"Arched eyebrows",
"A sly grin",
"Green eyes",
"A hooked nose",
"Freckles",
"Blond hair",
"Pale skin",
"Big ears",
"A small chin",
"wrinkled, pouchy face",
"sagging, jowly face",
"face with deeply creased forehead",
"face with heavy, drooping eyelids",
"lined, weather-beaten face",
"thin, drawn face",
"Long, narrow face",
"Pronounced nose",
"High cheekbones",
"Slightly slanted eyes",
"Thin lips",
"Chin that tapers to a point",
"Smooth, fair skin",
"Delicate features",
"Big, bushy eyebrows that almost touch in the middle.",
"A broad, flat nose.",
"A wide mouth with thick lips.",
"Prominent cheekbones.",
"Small, beady eyes.",
"A large head.",
]

export const saigaBodySentences = [
"long, graceful nose",
"impressive, spiraling horns",
"tawny coat with white markings",
"Short and slightly upturned muzzle ",
"Flared nostrils ",
"Broad forehead ",
"Long, tufted ears ",
"Compact body ",
"Thick, yellowish coat ",
"Faint striping on the flanks ",
"Long, sleek body",
"Flowing lines",
"Smooth curves",
"Powerful stance",
"aggressive look",
"Unique styling",
"Head-turning design",
"daring and bold",
"unforgettable impression",
"jaw-dropping beauty",
"its body is covered in short, reddish-brown fur.",
"it has a large head with small, triangular ears.",
"it has a long, narrow snout.",
]

export const saigaSoundWords = [
    "Bleating",
    "Braying",
    "plaintive",
    "lost",
    "forlorn",
    "hopeless",
    "bleating",
    "whining",
    "mooing",
    "bellowing",
    "braying",
    "lowing",
    "howling",
    "baying",
    "roaring",
    "trumpeting",
    "bleating",
    "bellowing",
    "bugling",
    "cooing",
    "crooning",
    "grunt",
    "lowing",
    "mewing",
    "moaning",
    "trilling",
    "coughing",
    "wheezing",
    "rasping",
    "gurgling",
    "hacking",
    "wet",
    "dry",
    "rattling",
    "sputtering",
    "feeble",
    "bleating"
]

export const saigaFurWords = [
"plush",
"soft",
"luxurious",
"dense",
"thick",
"warm",
"voluminous",
"rich",
"sumptuous",
"opulent",
"tough ",
"hunt ",
"Exotic",
"Luxurious",
"Soft",
"Supple",
"Shiny",
"Dense",
"Thick",
"Strong",
"Fine",
"Delicate",
"fluffy",
"soft",
"luxurious",
"elegant",
"delicate",
"beautiful",
"graceful",
"supple",
"smooth",
"velvety",
"Woolly",
"Hairy",
"Silky",
"Sheeny",
"dull",
"lustrous",
"boring",
"fragile",
"smooth",
"glossy",
]

export const saigaSmellWords = [
"Pungent",
"Rank",
"Rancid",
"Overwhelming",
"Acrid",
"nauseating",
"fetid",
"repulsive",
"putrid",
"Toxic",
"rank",
"pungent",
"musky",
"acrid",
"putrid",
"stench",
"reek",
"effluvium",
"noisome",
"malodorous",
"Earthy",
"Woody",
"Nutty",
"Gamey",
"Pungent",
"Musty",
"rank",
"Rancid",
"Skanky",
"Sour",
"rank",
"pungent",
"gamey",
"greasy",
"musky",
"livery",
"fecal",
"acrid",
"Ammoniacal",
"putrid",
"pungent",
"musky",
"earthy",
"gamey",
"gamy",
"meaty",
"strong",
"unique",
"aromatic",
"distinctive",
]

export const saigaBehaviourSentences = [
"it seemed to be in a trance",
"it is watching you intently",
"it is far away and seems scared",
"it doesn't seem interested in coming closer",
"it seems a little bit afraid",
]