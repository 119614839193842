export const painforestSmellTypes = [
    "of Soil",
    "of Clay",
    "of Wood smoke",
    "of Dung",
    "of dried blood",
    "of Bitter herbs",
    "of damp",
    "mildewed",
    "rotting",
    "stagnant",
    "stale",
    "fetid",
    "putrid",
    "rotting",
    "foul",
    "of oozing fluids",
    "of excrement",
    "of death",
    "overpowering",
    "woodsy",
    "intoxicating",
    "of ozone",
    "damp",
    "moldy",
    "of rich loam",
    "green",
    "humid",
    "sticky",
    "of pollutant",
    "insipid",
    "of thin, acrid incense",
    "of carrot seed and raspberry",
    "of strawberry glaze",
    "of oily, rotten walnut",
    "of spicy smoked willow",
    "of gentle elderflower",
    "of a riverfront arboretum",
    "of pillowy bark",
    "of sharpened disappointments",
    "of keen longing",
    "of aiming above one's station",
    "of palmarosa and distilled rye",
    "of begrudged labour",
    "of pestilence",
    "of a privy",
    "of small sick things",
    "of other people's wishes",
    "of mineral rage",
    "of a facetious smile",
    "of leafy malice",
    "of a dug grave",
]

export const painforestBranchTypes = [
    "thorn covered",
    "spiky",
    "insects crawling on its",
    "mites teeming in its",
    "knobbly",
    "strangled vines growing on its",
    "inflorescence hanging from its",
    "small creatures climbing its",
    "dappled sunlight playing on its",
    "heard but not seen creatures hiding amonst its",
    "pincushion",
    "tiny stingers on its",
    "spiny",
    "thorny",
    "cunning",
    "conniving",
    "tormenting",
    "cowering",
    "hideous",
    "trick-taking",
    "chattering beasts in its",
    "cackling beasts in its",
    "belligerent beasts in its maze",
    "filthy",
    "dreary",
    "fierce",
    "blood-clogged",
    "malign",
    "blood-flecked",
    "war-weed",
    "convoluted",
    "grubby",
    "lacklustre amethyst",
    "sanguine",
    "a thorn-obscured crown atop its",
    "rust-red",
    "thorn-cast",
    "tortuous",
    "wrought",
    "contorted",
    "sinuous",
    "entwined",
    "teeth-bared",
    "aggressive",
    "wander-wide",
    "dreadful",
    "spearing",
    "unnerving",
]

export const painforestLeafTypes = [
	"offshooting",
    "creeping",
    "long",
    "variegated",
    "spine-covered",
    "numbing",
    "hairy",
    "pinnate-veined",
    "lurid",
    "cerise-coloured",
    "puce",
    "suspicious eyes watching from its",
    "crimped",
    "soft, pink",
    "soft",
    "pink",
    "small",
    "smooth",
    "withered",
    "dry",
    "dessicated",
    "spoilt",
    "hopeful",
    "bitten",
    "gnawed",
    "lightly nibbled",
    "tooth-shy",
    "fuzzy",
    "fresh",
    "new",
    "wind-bowed",
    "sharp-spirited",
    "wafting",
    "dangling",
]