import { GET_COIN_TYPES_SUCCESS, CREATE_COIN_TYPE_SUCCESS } from "../actionTypes";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_COIN_TYPES_SUCCESS: {
      const coinTypes = action.payload;

      if (!coinTypes){
        return [...state];
      }

      return [
        ...state,
        ...coinTypes
      ]
    }

    case CREATE_COIN_TYPE_SUCCESS: {
      return [
        ...state,
        { ...action.payload }
      ]
    }

    default:
      return state;
  }
}
