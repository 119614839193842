import { CHANGE_CHARACTER, START_NEW_GAME, GET_DIARY_ENTRIES_SUCCESS, ADD_DIARY_ENTRY, UPDATE_DIARY_ENTRY, REMOVE_DIARY_ENTRY, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  diaryEntries: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }

    case GET_DIARY_ENTRIES_SUCCESS: {
      const diaryEntries = action.payload;

      console.log('diaryEntries: ', diaryEntries)

      return {
        ...state,
        diaryEntries: [...diaryEntries],
      }
    }

    case ADD_DIARY_ENTRY: {
      let diaryEntries = [...state.diaryEntries, action.payload];

      return {
        ...state,
        diaryEntries: [ ...diaryEntries ],
      }
    }

    case UPDATE_DIARY_ENTRY: {
      let diaryEntries = [
        ...diaryEntries.filter(diaryEntry => diaryEntry._id !== action.payload._id),
        { ...diaryEntries.find(diaryEntry => diaryEntry._id === action.payload._id), ...action.payload }
  	  ];

      return {
        ...state,
        diaryEntries: [ ...diaryEntries ],
      }
    }

    case REMOVE_DIARY_ENTRY: {
      return {
        ...state,
        diaryEntries: [ ...state.diaryEntries.filter(diaryEntry => (diaryEntry._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
