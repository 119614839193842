import { GET_CHARACTER_LEVELS_LOADING, GET_CHARACTER_LEVELS_SUCCESS, NEW_CHARACTER_LEVEL_SUCCESS, UPGRADE_CHARACTER_LEVEL_SUCCESS } from '../actionTypes';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';

import { colyClient } from '../../services/Panel-initialisation.service';
import { client } from '../../services/client';

export const getCharacterLevelsSuccess = payload => {
    return ({
        type: GET_CHARACTER_LEVELS_SUCCESS,
        payload
    })
}

export const getCharacterLevelsAsync = payload => dispatch => {
    return client.service('character-levels').find({ query: { characterId: payload.characterId } })
        .then((response) => {
            dispatch(getCharacterLevelsSuccess(response));
            return response
        })
        .catch((error) => {
            console.error('error', error);
        })
}

export const newCharacterLevelSuccess = payload => {
    return ({
        type: NEW_CHARACTER_LEVEL_SUCCESS,
        payload
    })
}

export const createNewCharacterLevelAsync = payload => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'brainchip-instances',
        commandType: 'create',
        data: {
            skillTypeId: payload.skillTypeId,
            characterId: payload.characterId
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const upgradeCharacterLevelSuccess = payload => {
    return ({
        type: UPGRADE_CHARACTER_LEVEL_SUCCESS,
        payload
    })
}

export const upgradeCharacterLevelAsync = payload => dispatch => {
    const { _id, level, skillTypeId } = payload;

    return client.service('character-levels').patch(_id, {
        level: level + 1,
        skillTypeId
    })
        .then((response) => {
            console.log('did it!', response);
            dispatch(upgradeCharacterLevelSuccess(response));
            return response
        })
        .catch((error) => {
            console.error('error', error);
        })
}