import { GET_BRAINCHIP_RECIPES_SUCCESS } from "../actionTypes";

const initialState = []

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_BRAINCHIP_RECIPES_SUCCESS: {
      const brainchipRecipes = action.payload;

      if (!brainchipRecipes){
        return {...state};
      }

      return [...state, ...action.payload]
    }

    default:
      return state;
  }
}
