export const antelopeFaceSentences = [
	"Bucktoothed and bovine, its face is an ungainly sight",
	"Elongated and narrow, the face of it is eerily human-like",
	"Long, slender face",
	"Two large, dark eyes",
	"Delicate black nose",
	"Small mouth with thin lips",
	"Short, black horns",
	"Elegant neck",
	"Long, graceful neck",
	"Large, dark eyes",
	"Pronounced black horns",
	"soft, furry ears",
	"expressive muzzle",
	"dainty face",
	"bearded face",
	"Long, slender face",
	"Elegant, curved horns",
	"Large, dark eyes",
	"Delicate muzzle",
	"High, prominent forehead",
	"Long, tapering neck",
]

export const antelopeBodySentences = [
	"its fur is matted",
	"its fur is shaggy",
	"its fur is unusually long",
	"its fur is caked in mud",
	"long, thin legs",
	"small, delicate hooves",
	"short, stiff tail",
	"reddish brown fur",
	"white underbelly",
	"black stripes on its legs",
]

export const antelopeSoundWords = [
	"Hissing",
	"stamping",
	"snorting",
	"braying",
	"bleating",
	"bellowing",
	"coughing",
	"lowing",
	"blowing",
	"bawling"
]

export const antelopeFurWords = [
    "Soft",
    "Silky",
    "Smooth",
    "Supple",
    "Luxurious",
    "Fine",
    "Delicate",
    "Exquisite",
    "Refined",
    "Elegant",
    "Veiled",
    "serene",
    "ethereal",
    "buckskin",
    "natural",
    "untamed",
    "softly-hued",
    "delicate",
    "luxurious",
    "Sleek",
    "velvety",
    "soft",
    "warm",
    "thick",
    "luxurious",
    "hairy",
    "Super soft",
    "velvety",
    "creamy",
    "silky",
    "smooth",
    "glossy",
    "lustrous",
    "shiny",
    "fine",
    "delicate",
    "Soft",
    "Supple",
    "Crescent-shaped",
    "White underside on its",
    "Black spots on its",
    "slender",
    "graceful",
    "nimble",
    "fleet"
]

export const antelopeSmellWords = [
    "Pungent",
    "gamey",
    "musky",
    "earthy",
    "beefy",
    "grassy",
    "leathery",
    "sweaty",
    "sour",
    "urine-like",
    "Aromatic",
    "fresh",
    "grassy",
    "musky",
    "new",
    "pungent",
    "pure",
    "salty",
    "sharp",
    "Salty",
    "musky",
    "pungent",
    "sharp",
    "acrid",
    "odoriferous",
    "fetid",
    "rank",
    "reeking",
    "foul",
    "Aromatic",
    "gamey",
    "musky",
    "smoky",
    "leathery",
    "rich",
    "earthy",
    "nutty",
    "pungent",
    "sharp",
    "Pungent",
    "Musky",
    "Earthy",
    "Gamey",
    "Woodsy",
    "Wet",
    "Aromatic",
    "Nutty",
    "Fruity",
    "Floral"
]

export const antelopeBehaviourSentences = [
	"it is making a chittering noise",
	"it is standing very still",
	"it is turning its head this way and that",
	"it is swishing its tail",
	"it is flick its ears",
	"it is glaring at you",
	"it is making strange noises",
	"it is walking backwards",
	"it is circles around you",
	"it is making erratic moveyounts",
	"it is bleating loudly",
	"it is running in circles",
	"it is pawing at the ground",
	"it is shaking its head",
	"it is stamping its feet",
	"it is stalking you",
	"it is making strange noises",
	"it is watching you",
	"it is standing very still",
	"it is following you",
	"it is ambling",
	"it is glaring at you with its beady eyes",
	"it is sniffing curiously",
	"it is stamping its feet impatiently",
	"it is switching its tail back and forth",
	"it is chewing on its cud",
]