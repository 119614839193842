import React from "react";
import { connect } from 'react-redux'

import store from '../../../redux/store';
import { createMessageAsync } from '../../../redux/actions/messages.actions';
import { disableKeyboardMovement, enableKeyboardMovement } from '../../../redux/actions/keyboard-shortcuts.actions';
import { selectMessages, selectCharacter } from '../../../redux/selectors';

import { client } from '../../../services/client';

import './DirectionInput.css';

class DirectionInput extends React.Component {
    constructor() {
        super();
        this.state = { direction: "" };

        document.addEventListener('keydown', this.directionHandler)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.directionHandler);
    }

    setDirection = () => {
        this.props.onDirectionSupplied(this.state.direction);
    };

    closeDirection = () => {
        this.props.onDirectionClosed();
    }

    updateInput(direction) {
        this.setState({ direction });
    }

    directionHandler = (event) => {
        if (this.props.isDisabled) {
            return;
        }

        if (event.key === 'ArrowRight') {
            this.setState({
                direction: 'east'
            })

            return;
        }

        if (event.key === 'ArrowLeft') {
            this.setState({
                direction: 'west',
            })

            return;
        }

        if (event.key === 'ArrowUp') {
            this.setState({
                direction: 'north',
            })

            return;
        }

        if (event.key === 'ArrowDown') {
            this.setState({
                direction: 'south',
            })

            return;
        }

        if (event.key === '>') {
            this.setState({
                direction: 'down',
            })

            return;
        }

        if (event.key === '<') {
            this.setState({
                direction: 'up',
            })

            return;
        }

        if (event.key === '\\') {
            if (this.props.onDirectionClosed) {
                this.props.onDirectionClosed()
            }

            return;
        }

        if (event.key === 'Enter') {
            if (this.props.onDirectionSupplied) {
                this.props.onDirectionSupplied(this.state.direction);
            }

            return;
        }
    };

    render() {
        return (
            <div className="direction-input">
                <p className="conversation-wizard-exit"><span className="action">\</span> to exit</p>
                <p>{this.props.text}</p>

                <div className="door-box">
                    <span onClick={() => this.updateInput('north')}
                        className={this.state.direction === 'north' ? 'selected-direction north' : 'direction north'}>
                        North
                    </span>
                    <span onClick={() => this.updateInput('east')}
                        className={this.state.direction === 'east' ? 'selected-direction east' : 'direction east'}>
                        East
                    </span>
                    <span onClick={() => this.updateInput('south')}
                        className={this.state.direction === 'south' ? 'selected-direction south' : 'direction south'}>
                        South
                    </span>
                    <span onClick={() => this.updateInput('west')}
                        className={this.state.direction === 'west' ? 'selected-direction west' : 'direction west'}>
                        West
                    </span>
                    { this.props.includeZDirections && (
                        <>
                            <span onClick={() => this.updateInput('up')}
                                className={this.state.direction === 'up' ? 'selected-direction up' : 'direction up'}>
                                Up (&lt;)
                            </span>
                            <span onClick={() => this.updateInput('down')}
                                className={this.state.direction === 'down' ? 'selected-direction down' : 'direction down'}>
                                Down (&gt;)
                            </span>
                        </>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const messages = selectMessages(state)
    const character = selectCharacter(state)

    return { messages, character }
}

export default connect(
    mapStateToProps,
    { createMessageAsync }
)(DirectionInput);