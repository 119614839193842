import React from "react";
import { connect } from 'react-redux'

import { clearPlotAsync, tileTypes } from '../../../redux/actions/tile.actions';
import { gatherMineralAsync } from '../../../redux/actions/mineral.actions';
import { harvestAnimalAsync } from '../../../redux/actions/animal.actions';
import { harvestFishAsync } from '../../../redux/actions/fish.actions';
import { UNSKILLED_COST_MULTIPLIER, hasSkill } from '../../../utils/has-skill';
import {
    selectHighlight,
    selectCharacter,
    selectTileTypeByName,
    selectMineralAtPosition,
    selectCurrentTiles,
    selectFloorLayerItemAtPosition,
    selectPlantInstanceIdAtTile,
    selectPlantById,
    selectAnimalInstances,
    selectFishInstances
} from '../../../redux/selectors';

import './FarmActions.css';

class FarmActions extends React.Component {
    constructor() {
        super();

        this.state = { isWorking: false }
    }

    render() {
        return (!this.props.highlight && !this.props.mineralInstance && this.props.currentTiles.length === 0 && !this.props.floorLayerItem) && !this.props.plantInstance ? (
            <div className="farm-actions">
                <ul>
                    <li className="action"
                        key={0}
                        onClick={() => (this.props.clearPlotAsync(this.props.farmPlotTileType._id))}>
                        plough farm plot [{hasSkill(this.props?.character, 'farming') ? 10 : <span className="unskilled-penalty">100</span>}]
                    </li>
                    <li key={1}
                        onClick={() => {
                            if (this.state.isWorking) {
                                return;
                            }

                            this.setState({ isWorking: true });

                            this.props.harvestAnimalAsync(this.props.animalInstances[0])
                                .finally(() => (this.setState({ isWorking: false })));
                        }}
                        className={this.props.animalInstances[0] ? "action" : "hidden"}>
                        hunt [{hasSkill(this.props?.character, 'hunting') ? this.props?.animalInstances[0]?.animal?.harvestingCost : <span className="unskilled-penalty">{UNSKILLED_COST_MULTIPLIER * (this.props?.animalInstances[0]?.animal?.harvestingCost || 0)}</span>}]
                    </li>
                    <li key={2}
                        onClick={() => {
                            if (this.state.isWorking) {
                                return;
                            }

                            this.setState({ isWorking: true });

                            this.props.harvestFishAsync(this.props.fishInstances[0])
                                .finally(() => (this.setState({ isWorking: false })));
                        }}
                        className={this.props.fishInstances[0] ? "action" : "hidden"}>
                        fish [{hasSkill(this.props?.character, 'fishing') ? this.props?.fishInstances[0]?.animal?.harvestingCost : <span className="unskilled-penalty">{UNSKILLED_COST_MULTIPLIER * (this.props?.fishInstances[0]?.animal?.harvestingCost || 0)}</span>}]
                    </li>
                </ul>
            </div>
        ) : ('')
    }
}

const mapToStateProps = state => {
    const highlight = selectHighlight(state);
    const character = selectCharacter(state);
    const farmPlotTileType = selectTileTypeByName(state, tileTypes.FARM_PLOT)
    const mineralInstance = selectMineralAtPosition(state, highlight || character.position)
    const currentTiles = selectCurrentTiles(state, highlight || character.position).allIds
    const floorLayerItem = selectFloorLayerItemAtPosition(state, highlight || character.position)
    const plantId = selectPlantInstanceIdAtTile(state, highlight || character.position)
    const plantInstance = selectPlantById(state, plantId);
    const animalInstances = selectAnimalInstances(state);
    const fishInstances = selectFishInstances(state);

    return { highlight, character, farmPlotTileType, mineralInstance, currentTiles, floorLayerItem, plantInstance, animalInstances, fishInstances }
}

export default connect(
    mapToStateProps,
    {
        clearPlotAsync,
        gatherMineralAsync,
        harvestAnimalAsync,
        harvestFishAsync
    }
)(FarmActions);