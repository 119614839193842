import { GET_WORKSHOP_RECIPES_SUCCESS } from "../actionTypes";

const initialState = []

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_WORKSHOP_RECIPES_SUCCESS: {
      const workshopRecipes = action.payload;

      if (!workshopRecipes){
        return {...state};
      }

      return [...state, ...action.payload]
    }

    default:
      return state;
  }
}
