export const sugarBushSmellTypes = [
    "Sweet",
    "smoky",
    "woody",
    "resinous",
    "minty",
    "peppery",
    "aromatic",
    "intoxicating",
    "divine",
    "aromatic",
    "balsamic",
    "ambrosial",
    "enchanting",
    "fascinating",
    "nostalgic",
    "uplifting",
    "fragrant",
    "Nutty",
    "Floral",
    "Earthy",
    "Spicy",
    "Woody",
    "Herbal",
    "Musk",
    "Fruity",
    "tempting",
    "of Honey",
    "of Lactose",
    "of Maltose",
    "of Saccharose",
    "of Sucrose",
    "synthetically sweet",
    "of a powder room",
    "of candied soap",
    "of dusty flowers",
    "lactonic and sweet",
    "of soothing clean sheets",
    "creamy and soft",
    "of seaspray",
    "of long-bleached driftwood",
    "of blighted crops",
    "of dour moorland",
    "of carved yew",
    "of a resolved heart",
    "of fruit and flowers",
    "surface-deep",
    "of unkempt bracken",
    "of false import",
    "of unplowed agriculture",
    "of small, hidden burrows",
    "of marked territory"
]

export const sugarBushBranchTypes = [
    "sweet",
    "delicious",
    "sticky",
    "thick",
    "smooth",
    "creamy",
    "amber",
    "golden",
    "Sturdy",
    "Sweet",
    "Satisfying",
    "Abundant",
    "Plentiful",
    "Auspicious",
    "sap-soaked"
    "arching",
    "three-forked",
    "bobbing",
    "frail",
    "clutching",
    "bejewelled",
    "sunken",
    "healthy",
    "gaunt",
]

export const sugarBushLeafTypes = [
	"disease-pocked",
    "green",
    "silvery",
    "turqouise",
    "intricate",
    "complicated",
    "whorled",
    "busy",
    "cluttered",
    "majestic",
    "towering",
    "sprawling",
    "ancient",
    "stately",
    "gracefully weeping",
    "pinwheel",
    "splayed",
    "jade",
    "serpentine",
    "sparking",
    "slackened",
    "fan-like",
    "thriving",
    "abundant",
]