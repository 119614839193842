import { GET_MATERIAL_TYPES_SUCCESS } from "../actionTypes";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_MATERIAL_TYPES_SUCCESS: {
      const materialTypes = action.payload;

      if (!materialTypes){
        return [...state];
      }

      return [
        ...state,
        ...materialTypes
      ]
    }
    default:
      return state;
  }
}
