export const armadilloFaceSentences = [
"Wrinkled and leathery skin",
"Small, beady eyes",
"A long neck",
"A hard, bony shell",
"Short, stubby legs",
"A long, coiled tail",
"A slow, lumbering walk",
"Small, round eyes that peer out from under a thick shell.",
"A beak-like mouth that is tucked underneath the chin.",
"Steamy, hot breath that smells faintly of grass.",
"Scaly, green skin that is rough to the touch.",
"it has a hard, scaly face.",
"it has a long, slender neck.",
"it has a small, round head.",
"it has a beak-like nose.",
"wrinkled and dry",
"scaly and rough",
"slow and methodical",
"ancient and weathered",
"patient and wise",
"solemn and serene",
"cautious and discerning",
"relentless and tenacious",
"its face is dry and scaly.",
"it has a small, hooked beak.",
"its eyes are dark and beady.",
"its head is large and round.",
]

export const armadilloBodySentences = [
"it has a rough, scaly shell.",
"it has a long neck.",
"it has four stumpy legs.",
"it's shell is a brownish-green color.",
"it has a short tail",
"it has a hard, wooden-looking shell.",
"it has four stubby legs.",
"it has a long, snake-like neck.",
"it has small, beady eyes.",
"it has a tough, hard shell.",
"it has a long, extended neck.",
"it has four sturdy legs.",
"it has a short, stubby tail.",
"it has a small, round head",
"Heavy, bony shell ",
"Unblinking, beady eyes ",
"wrinkled, leathery skin ",
"Sprawling, awkward legs ",
"sluggish, methodical movements ",
"ancient, wise persona ",
]

export const armadilloSoundWords = [
 "Hissing",
"Snorting",
"Snoring",
"Grunting",
"Rumbling",
"Whirring",
"Clicking",
"Clattering",
"Scratching",
"Rustling",
]

export const armadilloFurWords = [
"Coarse",
"Thick",
"Warty",
"Scaly",
"Dry",
"Brittle",
"Leathery",
"Hard",
"calloused",
"crusty",
]

export const armadilloSmellWords = [
"Dry", "scaly", "musky", "old", "leathery", "fascinating", "unique",
]

export const armadilloBehaviourSentences = [
"waving its arms around",
"shell is cracked and bleeding",
"has a piece of straw sticking out of its shell",
"is missing a leg",
"the tortoise is eating a leaf",
"the tortoise is turning its head upside down",
"the tortoise is almost touching the ground with its stomach",
"the tortoise is making strange hissing noises",
]