import React from "react";
import { connect } from 'react-redux'

import { selectFoodRecipes } from '../../../redux/selectors';

import { client } from '../../../services/client';

class FoodRecipes extends React.Component {

    render() {
        const listItems = this.props.foodRecipes
            .filter(foodRecipe => (foodRecipe.foodType.name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase()) > -1))
            .sort((a, b) => (a.foodType?.name.localeCompare(b.foodType?.name)))
            .map((foodRecipe) => {
                return (
                    <li key={foodRecipe._id}
                        onClick={() => {
                            return (this.props.onItemSelect(foodRecipe))
                        }}>
                        <span className={this.props.selectedItem?._id === foodRecipe._id ? "selected": ""}>{foodRecipe.foodType.name}</span>
                    </li>
                );
            })
        return (
            <ul>{listItems}</ul>
        )
    }
}

const mapStateToProps = state => {
    const foodRecipes = selectFoodRecipes(state)

    return { foodRecipes }
}

export default connect(
    mapStateToProps,
    { }
)(FoodRecipes);