import {
    CREATE_CLOTHING_LOADING,
    CREATE_CLOTHING_SUCCESS,
    CREATE_CLOTHING_FAILURE,
    GET_CLOTHING_LOADING,
    GET_CLOTHING_SUCCESS,
    GET_CLOTHING_FAILURE,
    ADD_CLOTHING,
    UPDATE_CLOTHING,
    REMOVE_CLOTHING,
    GET_TRADE_CLOTHING_SUCCESS,
    GET_TRADE_CLOTHING_FAILURE
} from '../actionTypes'
import store from '../store';

import { client } from '../../services/client';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { colyClient } from '../../services/Panel-initialisation.service';

export const createClothingSuccess = payload => ({
    type: CREATE_CLOTHING_SUCCESS,
    payload
})

export const createClothingFailure = () => ({
    type: CREATE_CLOTHING_FAILURE
})

export const createClothingAsync = payload => dispatch => {
    const { _id, clothingTypeId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'clothing-instances',
        name: 'create',
        data: {
            clothingTypeId
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getClothingSuccess = payload => {
    return ({
        type: GET_CLOTHING_SUCCESS,
        payload
    })
}

export const getClothingFailure = () => ({
    type: GET_CLOTHING_FAILURE
})

export const getClothingAsync = payload => dispatch => {
    let query = { $or: [{ panelId: payload.panelId, z: payload.z}, { characterId: payload.characterId}] }

    if (payload.isNotFirstInitialisation) {
        query = { panelId: payload.panelId, z: payload.z}
    }

    return client.service('clothing-instances').find({ query })
        .then((response) => {
            response = response.map(clothing => ({ ...clothing, clothingType: payload.clothingTypes.find(type => type._id === clothing.clothingTypeId), type: payload.clothingTypes.find(type => type._id === clothing.clothingTypeId) }))
            dispatch(getClothingSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const getTradeClothingSuccess = payload => {
    return ({
        type: GET_TRADE_CLOTHING_SUCCESS,
        payload
    })
}

export const getTradeClothingFailure = () => ({
    type: GET_TRADE_CLOTHING_FAILURE
})

export const getTradeClothingAsync = payload => dispatch => {
    return client.service('clothing-instances').find({ query: { characterId: payload.characterId, markedForTradeCount: { $gte: 1 } }})
        .then((response) => {
            response = response.map(clothing => ({ ...clothing, clothingType: payload.clothingTypes.find(type => type._id === clothing.clothingTypeId) }))
            dispatch(getTradeClothingSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const addClothingToTileAsync = payload => dispatch => {
    const { clothingInstance, tileId, tileTypeId } = payload;
    const { clothingTypeId, quantity, recipeId, characterId, _id } = clothingInstance;

    colyClient.room.send('doAction', {
        serviceName: 'clothing-instances',
        commandType: 'create',
        data: {
            clothingTypeId,
            quantity,
            tileId,
            tileTypeId,
            panelId: "",
            position: {},
            characterId: ""
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addClothingToCharacterAsync = payload => dispatch => {
    const { clothingInstances, characterId } = payload;
    const { createdAt, clothingTypeId, quantity, recipeId, _id } = clothingInstances[0];

    colyClient.room.send('doSuperAction', {
        serviceName: 'clothing-instances',
        name: 'fetch',
        data: {
            clothingTypeId,
            quantity,
            tileId: "",
            characterId,
            instanceIds: clothingInstances.map(inst => inst._id)
        },
        params: {
            query: {
                movementFrom: {
                    _id:undefined
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addClothing = payload => {
    return ({
        type: ADD_CLOTHING,
        payload
    })
}

export const updateClothing = payload => {
    return ({
        type: UPDATE_CLOTHING,
        payload
    })
}

export const removeClothing = payload => {
    return ({
        type: REMOVE_CLOTHING,
        payload
    })
}
