import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_PLANTS_SUCCESS, PLANT_SEED_SUCCESS, CULTIVATE_PLANT_SUCCESS, HARVEST_PLANT_SUCCESS, PANEL_SUCCESS, REMOVE_PLANT, ADD_PLANT, UPDATE_PLANT, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  allIds: [],
  byId: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PANEL_SUCCESS: {
      return { ...initialState }
    }

    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }

    case GET_PLANTS_SUCCESS: {
      const plants = action.payload;

      if (!plants) {
        return { ...initialState };
      }

      let newState = { allIds: [], byId: {} };

      plants.forEach(plant => {
        newState.allIds.push(plant.id);

        let position;
        try {
          position = JSON.parse(plant.position);
        } catch(e) {
          position = plant.position;
        }

        newState.byId[plant.id] = {
          ...plant,
          position
        }
      })

      return newState;
    }

    case REMOVE_PLANT: {
      const newByIds = { ...state.byId };
      delete newByIds[action.payload.id];

      return {
        isInitialised: state.isInitialised,
        allIds: [ ...state.allIds.filter(id => id !== action.payload.id) ],
        byId: { ...newByIds }
      }
    }

    case ADD_PLANT: 
    case UPDATE_PLANT: {
      try {
        action.payload.position = JSON.parse(action.payload.position);
      } catch(e) {
        
      }
      return {
        isInitialised: state.isInitialised,
        allIds: [ ...state.allIds, action.payload.id ],
        byId: { ...state.byId, [action.payload.id]: { ...action.payload } }
      }
    }

    default:
      return state;
  }
}
