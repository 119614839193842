import {
    NEW_EMBARK_CHARACTER_LOADING,
    NEW_EMBARK_CHARACTER_SUCCESS,
    NEW_EMBARK_CHARACTER_FAILURE,
    GET_EMBARK_CHARACTER_LOADING,
    GET_EMBARK_CHARACTER_SUCCESS,
    GET_EMBARK_CHARACTER_FAILURE,
    UPDATE_EMBARK_POSITION_SUCCESS,
    UPDATE_EMBARK_POSITION_FAILURE,
    BEGIN_UPDATE_EMBARK_POSITION,
    SOLO_EMBARK_LOADING,
    SOLO_EMBARK_SUCCESS,
    GROUP_EMBARK_SUCCESS
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';

import { updateCharacter } from './embark-form.actions';

export const newEmbarkCharacterSuccess = payload => {
    return ({
        type: NEW_EMBARK_CHARACTER_SUCCESS,
        payload
    })
}

export const newEmbarkCharacterFailure = payload => ({
    type: NEW_EMBARK_CHARACTER_FAILURE
})


export const newEmbarkCharacterAsync = payload => dispatch => {
    const { userId, name, backstory, career, characterSprite, conflict, description, fear, friendship, leadership, trait, weakness } = payload;

    return client.service('embark-characters').create({ userId, name, backstory, career, characterSprite, conflict, description, fear, friendship, leadership, trait, weakness })
        .then((response) => {
            dispatch(newEmbarkCharacterSuccess({ character: response }))

            dispatch(getEmbarkCharacterSuccess({ embarkCharacters: [response], userId }));
            dispatch(updateCharacter(response))
            return response
        })
        .catch((error) => {
            console.error('error', error);
            dispatch(newEmbarkCharacterFailure())
            return response
        })
}

export const patchEmbarkCharacterAsync = payload => dispatch => {
    const {
        embarkGroupId,
        _id,
        userId,
        passphrase
    } = payload;

    return client.service('embark-characters').patch(_id, { embarkGroupId, position: { x: 23, y: 12 }, passphrase })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.error('error', error);
        })
}

export const getEmbarkCharacterSuccess = payload => {
    return ({
        type: GET_EMBARK_CHARACTER_SUCCESS,
        payload
    })
}

export const getEmbarkCharacterFailure = payload => ({
    type: GET_EMBARK_CHARACTER_FAILURE
})

export const getEmbarkCharacterAsync = payload => dispatch => {
    const { userId } = payload;

    return client.service('embark-characters').find({ query: { userId: payload.userId } })
        .then((embarkCharacters) => {
            dispatch(getEmbarkCharacterSuccess({ embarkCharacters, userId }));

            const updatedCharacters = embarkCharacters.filter(character => character.userId === userId);
            dispatch(updateCharacter(updatedCharacters[0]))

            return embarkCharacters
        })
        .catch((error) => {
            console.log('error: ', error);
        })
}

export const joinEmbarkGroupAsync = payload => dispatch => {
    const { _id, embarkGroupId, positionIndex } = payload;

    return client.service('embark-characters').patch(_id, { embarkGroupId, positionIndex })
        .then((response) => {
            console.log('response: ', response);
        })
        .catch((error) => {
            console.error('error', error);
        })
}

export const leaveEmbarkGroupAsync = payload => dispatch => {
    const { characterId } = payload;

    return client.service('embark-characters').patch(characterId, { embarkGroupId: '' })
        .then((response) => {
            console.log('response d: ', response);
        })
        .catch((error) => {
            console.error('error', error);
        })
}

export const soloEmbarkAsync = payload => dispatch => {
    const { _id } = payload;

    dispatch(soloEmbarkLoading());

    return client.service('embark-characters').remove(_id)
        .then((response) => {
            console.log('response d: ', response);
        })
        .catch((error) => {
            console.error('error', error);
        })
}

export const soloEmbarkLoading = payload => ({
    type: SOLO_EMBARK_LOADING
})

export const soloEmbarkSuccess = payload => ({
    type: SOLO_EMBARK_SUCCESS
})

export const groupEmbarkSuccess = payload => ({
    type: GROUP_EMBARK_SUCCESS
})

export const beginUpdateEmbarkPosition = (payload) => ({
    type: BEGIN_UPDATE_EMBARK_POSITION,
    payload
})

export const updateEmbarkPositionSuccess = payload => ({
    type: UPDATE_EMBARK_POSITION_SUCCESS,
    payload
})

export const updateEmbarkPositionFailure = (payload) => ({
    type: UPDATE_EMBARK_POSITION_FAILURE,
    payload
})

export const updateEmbarkPositionAsync = payload => dispatch => {
    const {
        position
    } = payload;

    return client.service('embark-characters').patch(payload._id, {
        position
    })
    .then((response) => {
        dispatch(updateEmbarkPositionSuccess(response))
    })
    .catch((error) => {
        console.error('error: ', error.message);

        delete payload.position;

        dispatch(updateEmbarkPositionFailure(payload))

        if (error.message.indexOf('Cannot perform action on position') > -1) {
            return;
        }

        if (error.message.indexOf('Cannot perform that action on tile type Cliff') > -1) {
            return;
        }

        if (error.message.indexOf('Cannot move from water to water') > -1) {
            return;
        }

        dispatch(showUnknownError({ message: error.message }))
    });
}