import React from "react";
import { connect } from 'react-redux'

import store from '../../../redux/store';
import { client } from '../../../services/client';
import { selectPanel, selectCharacter, selectActiveCharacterOrder } from '../../../redux/selectors';
import { disableKeyboardMovement, enableKeyboardMovement, showHelp } from '../../../redux/actions/keyboard-shortcuts.actions';
import { updateCharacterPanelAsync, addCharacterPanelAsync } from '../../../redux/actions/character-panel.actions';
import { biomeMap } from './biome-map';
import { seasonMap } from './season-map';
import { getMinutes } from 'date-fns';

import './Location.css';

class Location extends React.Component {
    state = {
        text: '',
        isInitialised: false
    }
    constructor() {
        super();
    }

    handleInput = event => {
        this.setState({ text: event.target.value.slice(0, 20) });
    }

    disableMovement = () => {
        store.dispatch(disableKeyboardMovement());
    }

    saveAndEnableMovement = () => {
        store.dispatch(enableKeyboardMovement());

        if (this.props.panel.characterPanel) {
            store.dispatch(updateCharacterPanelAsync({
                _id: this.props.panel.characterPanel._id,
                locationName: this.state.text
            }));
        } else {
            store.dispatch(addCharacterPanelAsync({
                locationName: this.state.text,
                characterId: this.props.character._id,
                panelId: this.props.panel.id,
            }));
        }
    }

    getTimeOfDay() {
        const now = new Date().getTime();

        const minutesPast = getMinutes(now);

        if (minutesPast < 1) {
            return 'Midight';
        }

        if (minutesPast < 5) {
            return 'Pre-Dawn';
        }

        if (minutesPast < 7) {
            return 'Dawn';
        }

        if (minutesPast < 10) {
            return 'Morning';
        }

        if (minutesPast < 20) {
            return 'Day';
        }

        if (minutesPast < 25) {
            return 'Evening';
        }

        if (minutesPast < 29) {
            return 'Night';
        }

        if (minutesPast < 31) {
            return 'Midnight';
        }

        if (minutesPast < 35) {
            return 'Pre-Dawn';
        }

        if (minutesPast < 37) {
            return 'Dawn';
        }

        if (minutesPast < 40) {
            return 'Morning';
        }

        if (minutesPast < 50) {
            return 'Day';
        }

        if (minutesPast < 55) {
            return 'Evening';
        }

        if (minutesPast < 59) {
            return 'Night';
        }

        return 'Midight';
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.panel?.id !== state.panelId) {
            state.panelId = nextProps.panel?.id;
            state.text = nextProps.panel?.characterPanel?.locationName || 'Unknown Location';
        }

        if (nextProps.panel?.characterPanel?.locationName !== state.text) {
            state.text = nextProps.panel?.characterPanel?.locationName || 'Unknown Location';
        }

        return { ...state };
    }

    render() {
        const orderCostString = this.props.activeOrder?.waitCost ? (<span>{this.props.activeOrder.waitCount}/{this.props.activeOrder.waitCost}</span>) : (<></>);
        return (
            <div className="location">
                {this.props.activeOrder ? (<span className="active-order">{this.props.activeOrder.type} {orderCostString}</span>) : (<span className="active-order">Idle</span>) }
                {this.props.character.healthPoints <= 0 ? (<span className="help-hint">Press b to learn about Near-Death State</span>) : (<span className="help-hint">Press ? or / for Help</span>)}
                <span className="location-input">{this.state.text}</span>
                <span className="season">{seasonMap[this.props.panel.season]} {this.getTimeOfDay()}</span>
                <span className="biome">{this.props.panel.elevation >= 150 ? biomeMap['MOUNTAIN'] : biomeMap[this.props.panel.biome]}</span>
            </div>
        )
    }
}

const mapToStateProps = state => {
    const panel = selectPanel(state);
    const character = selectCharacter(state);
    const activeOrder = selectActiveCharacterOrder(state, character._id);

    return { panel, character, activeOrder }
}

export default connect(
    mapToStateProps,
    {
        updateCharacterPanelAsync,
        addCharacterPanelAsync
    }
)(Location);