export const crustyMonkeyFaceSentences = [
"A face with a beak that looks like it could cut through metal",
"A face that is missing a few feathers, making it look bedraggled",
"A face with eyes that seem to pierce your soul",
"A face that looks like it's been through a lot",
"A face that looks like it's seen better days",
"A face that looks like it's been through the wringer",
"A face that looks like it's seen better times",
"wrinkled and rough",
"dull and lifeless",
"beak is scaly and dry",
"crusty around the eyes",
"dry and scaly patches",
"dull and faded colours",
"flaky and dry skin",
"brittle and dry feathers",
"A face with a crusty texture",
"A face that looks like it's been through a lot",
"A face with a lot of character",
"A face that's seen better days",
"A face that's been through the",
"A face with a crusty, flaky surface",
"A face with a dry, cracked appearance",
"A face with a rough, bumpy texture",
"A face with a wrinkled, leathery surface",
]

export const crustyMonkeyBodySentences = [
"its body is covered in a thick layer of crust",
"its body is caked in a thick layer of dried blood",
"its body is covered in a thick layer of grime",
"its body is covered in a thick layer of crust",
"its body is encrusted with a thick layer of crust",
"its body is heavily encrusted with a thick layer of crust",
"sty bird body covered in dried mud, with patches of feathers missing",
"ding, crusty bird body, missing most of its feathers",
"sty bird body, covered in a thick layer of dried blood",
"Crusty bird body, missing many of its feathers and covered",
"dry and cracked",
"dull and lifeless",
"covered in scabs",
"crusty and flaky",
"missing patches of feathers",
"matted and greasy",
"dull and faded",
"ripped and tattered",
"stained and muddy",
"its body is covered in a layer of crusty material",
"its body is encrusted with a thick layer of crust",
"its body is covered in a thin layer of crust",

]

export const crustyMonkeySoundWords = [
    "wheezing",
    "fluttering",
    "rasping",
    "hacking",
    "coughing",
    "gasping",
    "grunting",
    "croaking",
    "chirping",
    "singing",
    "Rasping",
    "Cacophonous",
    "Ear-piercing",
    "Strident",
    "Raucous",
    "Grating",
    "Harsh",
    "jarring",
    "grating",
    "scratchy",
    "Mournful",
    "dirge-like",
    "croaking",
    "grating",
    "guttural",
    "harsh",
    "raucous",
    "hoarse",
    "throaty",
    "barking",
    "tweet",
    "chirp",
    "coo",
    "caw",
    "screech",
    "warble",
    "trill",
    "tweet",
    "whistle",
    "cheep"
]

export const crustyMonkeyFurWords = [
    "Dry",
    "scaly",
    "hard",
    "cracked",
    "flaky",
    "brittle",
    "coarse",
    "rough",
    "dry",
    "crusty",
    "bleached",
    "dry",
    "brittle",
    "crumbly",
    "encrusted",
    "flaky",
    "patchy",
    "scaly",
    "weathered",
    "wrinkled",
    "brittle",
    "flaky",
    "dry",
    "scaly",
    "cracked",
    "hard",
    "rough",
    "dusty",
    "sandy",
    "eroded",
    "Crumpled",
    "dry",
    "papery",
    "scaly",
    "itchy",
    "thick",
    "flaky",
    "brittle",
    "hard",
    "rough",
    "crisp",
    "dry",
    "flaky",
    "hard",
    "brittle",
    "rough",
    "scaly",
    "dry",
    "calloused",
    "weathered"
]

export const crustyMonkeySmellWords = [
    "pungent",
    "putrid",
    "rank",
    "fetid",
    "stinking",
    "reeking",
    "malodorous",
    "foul",
    "rank",
    "reeking",
    "Pungent",
    "Rotten",
    "Gamey",
    "Vinegary",
    "Sour",
    "Sharp",
    "Metallic",
    "Chemically",
    "Acrid",
    "pungent",
    "rank",
    "Astringent",
    "musky",
    "gamey",
    "fetid",
    "dank",
    "musty",
    "noisome",
    "putrid",
    "Faint",
    "Pungent",
    "Rancid",
    "Vinegary",
    "Eggy",
    "Metallic",
    "Salty",
    "Fishy",
    "Muddy",
    "acrid",
    "Ammonia",
    "catty",
    "fetid",
    "foul",
    "loathsome",
    "malodorous",
    "pungent",
    "rank",
    "reeky"
]

export const crustyMonkeyBehaviourSentences = [
"it is acting strange",
"it is acting very strangely",
"it is behaving oddly",
"it is behaving very oddly",
"it is behaving in a strange way",
"it is behaving in a very strange way",
"it is making an odd, high-pitched noise",
"it is standing very still and staring at me",
"it's beak is open and its tongue is hanging out",
"it has its head tilted to one side",
"it is covered in crusty residue.",
"it has an unusual number of crusty growths on its body.",
"it's crusty growths appear to be causing it discomfort.",
"it is picking at its crusty growths incessantly.",
"it is making an eerie sound",
"it's eyes seem to be following me",
"it is just standing there staring at me",
"it seems to be waiting for something",
"it looks like it's going to attack me",
"it is watching us intently with its beady eyes",
"it is making strange grunting noises",
"it is pacing back and forth restlessly",
"it is picking at its feathers nervously",
"it is flapping its wings",
]