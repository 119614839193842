import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_CLOTHING_SUCCESS, GET_TRADE_CLOTHING_SUCCESS, ADD_CLOTHING, UPDATE_CLOTHING, REMOVE_CLOTHING, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  characterClothing: [],
  tileClothing: [],
  tradeClothing: [],
  clothing: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_CLOTHING_SUCCESS: {
      const clothing = action.payload;

      return {
        ...state,
        clothing: [...clothing.filter(clothingInstance => clothingInstance.panelId)],
        characterClothing: [...state.characterClothing, ...clothing.filter(clothingInstance => clothingInstance.characterId)],
        tileClothing: [...clothing.filter(clothingInstance => clothingInstance.tileId)],
      }
    }

    case GET_TRADE_CLOTHING_SUCCESS: {
      const clothing = action.payload;

      return {
        ...state,
        tradeClothing: [...clothing],
      }
    }

    case ADD_CLOTHING: {
      let characterClothing = [...state.characterClothing];
      let tileClothing = [...state.tileClothing];
      let clothing = [...state.clothing];

      if (action.payload.characterId) {
        characterClothing = [ ...characterClothing, action.payload ]
      }

      if (action.payload.tileId) {
        tileClothing = [ ...tileClothing, action.payload ]
      }

      if (action.payload.panelId) {
        clothing = [ ...clothing, action.payload ]
      }

      return {
        ...state,
        characterClothing: [ ...characterClothing ],
        tileClothing: [ ...tileClothing ],
        clothing: [ ...clothing ],
      }
    }

    case UPDATE_CLOTHING: {
      let characterClothing = [...state.characterClothing];
      let tileClothing = [...state.tileClothing];
      let clothing = [...state.clothing];

      if (action.payload.characterId) {
        characterClothing = [
          ...characterClothing.filter(clothing => clothing._id !== action.payload._id),
          { ...characterClothing.find(clothing => clothing._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.tileId) {
        tileClothing = [
          ...tileClothing.filter(clothing => clothing._id !== action.payload._id),
          { ...tileClothing.find(clothing => clothing._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.panelId) {
        clothing = [
          ...clothing.filter(clothing => clothing._id !== action.payload._id),
          { ...clothing.find(clothing => clothing._id === action.payload._id), ...action.payload }
        ]
      }

      return {
        ...state,
        characterClothing: [ ...characterClothing ],
        tileClothing: [ ...tileClothing ],
        clothing: [ ...clothing ],
      }
    }

    case REMOVE_CLOTHING: {
      return {
        ...state,
        characterClothing: [ ...state.characterClothing.filter(clothing => (clothing._id !== action.payload._id)) ],
        tileClothing: [ ...state.tileClothing.filter(clothing => (clothing._id !== action.payload._id)) ],
        clothing: [ ...state.clothing.filter(clothing => (clothing._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
