export const antiarisSmellTypes = [
    "woody",
    "nutty",
    "of brown sugar",
    "malty",
    "toasty",
    "of chocolate",
    "fresh",
    "lively",
    "grassy",
    "sunny",
    "nutty",
    "sweet",
    "earthy",
    "mossy",
    "icy",
    "nutty",
    "sweet",
    "woody",
    "earthy",
    "oily",
    "resinous",
    "floral",
    "green",
    "fresh",
    "nutty",
    "sweet",
    "of honey",
    "fussy",
    "mature",
    "of chocolate",
    "sweet",
    "dark",
    "floral",
    "fragrant",
    "resinous",
    "of sorrowful crowds",
    "of stygian interjections",
    "of old money, undeserved",
    "of one who hides character behind possessions",
    "fleetingly of an old, rich bureau",
    "of unfettered desire for wealth",
    "of a city rich with opulence",
    "of an unafforable night on the coast",
    "of the distant dream from a travel brochure",
    "sweet, dark, and dirty",
    "of a mix of fruit and chypre",
    "of timid clouds of soft florals",
    "of plastic and mulberry",
    "of floral saltiness",
    "dark and sensuous",
    "of jammy fruits",
    "of an airy veil of musk",
    "of menstruation",
]

export const antiarisBranchTypes = [
    "hollow",
    "dry",
    "dead",
    "brittle",
    "angular",
    "jagged",
    "twisted",
    "pointed",
    "bare",
    "hazel",
    "russet",
    "golden",
    "sienna",
    "auburn",
    "titian",
    "umber",
    "slender",
    "tree-like",
    "smooth",
    "carob",
    "stringy",
    "brittle",
    "friable",
    "rounded",
    "slender",
    "sharp",
    "twisted",
    "bumpy",
    "milky",
    "macaroni",
    "an expectant atmosphere and twisted",
    "a youthful air and stubborn",
    "an affable look and tormented",
    "a tolerant character with uneven",
    "the look of a jokester with brittle",
    "false iroko",
    "bark-clad",
    "sap that stings and pale grey",
    "a burning latex dripping from its",
    "drupe-laden",
    "upa bleeding",
    "nose-watering sap and rough",
    "noxious secretions and lightweight",
    "hungry and eager",
    "foul and milky liquid driping from its",
    "pain-sundered",
    "forgiving",
    "bark like cloth and thin",
    "peelable bark",
]

export const antiarisLeafTypes = [
    "coarse",
    "sturdy",
    "jagged",
    "large",
    "veiny",
    "lumpy",
    "thick",
    "hard",
    "elliptic",
    "folded",
    "broad",
    "waxy",
    "latex-dripping",
    "obviate",
    "fresh",
    "sticky",
    "coarse",
    "brittle",
    "dry",
    "obovate",
    "mucronate",
    "shade-giving",
    "broad",
    "umbrella",
    "parasol",
    "pleasantly dancing",
]