import {
    READ_INVENTORY_SUCCESS,
    READ_INVENTORY_FAILURE,
    READ_TILE_INVENTORY_SUCCESS,
    READ_TILE_INVENTORY_FAILURE,
    CLEAR_TILE_INVENTORY,
    REMOVE_MATERIAL,
    ADD_MATERIAL,
    UPDATE_MATERIAL,
    GET_TRADE_INVENTORY_SUCCESS,
    GET_TRADE_INVENTORY_FAILURE
} from '../actionTypes';
import { clearAllTiles, loadTilesSuccess } from './tile.actions';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';

import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

import { moveItemFailure } from './move-item.actions';

export const getInventorySuccess = payload => {
    return ({
        type: READ_INVENTORY_SUCCESS,
        payload
    })
}

export const getInventoryFailure = payload => ({
    type: READ_INVENTORY_FAILURE
})

export const getInventoryAsync = payload => dispatch => {
    let query = { $or: [{ panelId: payload.panelId, z: payload.z}, {characterId: payload.characterId}] }

    if (payload.isNotFirstInitialisation) {
        query = { panelId: payload.panelId, z: payload.z}
    }

    return client.service('material-instances').find({ paginate: false, query })
        .then((response) => {
            response = response.map(material => ({ ...material, materialType: payload.materialTypes.find(type => type._id === material.materialTypeId) }))
            response = response.map(plant => ({ ...plant, plant: payload.plantTypes.find(type => type._id === plant.plantTypeId) }))
            response = response.map(animal => ({ ...animal, animal: payload.animalTypes.find(type => type._id === animal.animalTypeId) }))
            dispatch(getInventorySuccess(response));
        })
        .catch((error) => {
            console.error('error', error);
            dispatch(showUnknownError({ message: error.message }));
            throw new Error(error);
        })
}

export const getTradeInventorySuccess = payload => {
    return ({
        type: GET_TRADE_INVENTORY_SUCCESS,
        payload
    })
}

export const getTradeInventoryFailure = () => ({
    type: GET_TRADE_INVENTORY_FAILURE
})

export const getTradeInventoryAsync = payload => dispatch => {
    return client.service('material-instances').find({ query: { characterId: payload.characterId, markedForTradeCount: { $gte: 1 } }})
        .then((response) => {
            console.log('response: ', response)
            response = response.map(material => ({ ...material, materialType: payload.materialTypes.find(type => type._id === material.materialTypeId) }))
            response = response.map(plant => ({ ...plant, plant: payload.plantTypes.find(type => type._id === plant.plantTypeId) }))
            response = response.map(animal => ({ ...animal, animal: payload.animalTypes.find(type => type._id === animal.animalTypeId) }))
            dispatch(getTradeInventorySuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('asdferror', error);
            throw new Error(error);
        })
}

export const getTileInventoryFailure = payload => ({
    type: READ_TILE_INVENTORY_FAILURE
})

export const clearTileInventory = () => ({
    type: CLEAR_TILE_INVENTORY
})

export const addMaterialToTileAsync = payload => dispatch => {
    const { tileId, materialInstance, tileTypeId } = payload;
    const { characterId, materialTypeId, plantTypeId, animalTypeId, quantity, _id } = materialInstance;

    colyClient.room.send('doAction', {
        serviceName: 'material-instances',
        commandType: 'create',
        data: {
            materialTypeId,
            plantTypeId,
            quantity,
            characterId: "",
            tileId,
            animalTypeId,
            tileTypeId
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addMaterialToCharacterAsync = payload => dispatch => {
    const { characterId, materialInstance } = payload;
    const { materialTypeId, plantTypeId, quantity, animalTypeId, _id } = materialInstance;

    colyClient.room.send('doSuperAction', {
        serviceName: 'material-instances',
        name: 'fetch',
        data: {
            materialTypeId,
            plantTypeId,
            quantity,
            characterId,
            animalTypeId,
            tileId: ""
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const removeMaterial = payload => ({
    type: REMOVE_MATERIAL,
    payload
})

export const addMaterial = payload => ({
    type: ADD_MATERIAL,
    payload
})

export const updateMaterial = payload => ({
    type: UPDATE_MATERIAL,
    payload
})
