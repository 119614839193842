import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_CONSTRUCTIONS_SUCCESS, BUILD_CONSTRUCTION_SUCCESS, PANEL_SUCCESS, REMOVE_CONSTRUCTION, ADD_CONSTRUCTION, UPDATE_CONSTRUCTION, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  allIds: [],
  byId: {}
};

export const RELATIVE_POSITION = {
	LEFT: 'LEFT',
	RIGHT: 'RIGHT',
	TOP: 'TOP',
	BOTTOM: 'BOTTOM',
	TOP_RIGHT: 'TOP_RIGHT',
	BOTTOM_RIGHT: 'BOTTOM_RIGHT',
	BOTTOM_LEFT: 'BOTTOM_LEFT',
	TOP_LEFT: 'TOP_LEFT'
};

export default function(state = initialState, action) {
	switch (action.type) {
		case START_NEW_GAME:
		case INITIALISING_PANEL_STARTED:
		case SHOW_CHARACTER_DEATH_PAGE:
		case CHANGE_CHARACTER:
		case PANEL_SUCCESS: {
			return { ...initialState }
		}

		case GET_CONSTRUCTIONS_SUCCESS: {
			const constructions = action.payload;

			if (!constructions) {
				return { ...state };
			}

			let newState = { allIds: [], byId: {} };

			constructions.forEach(construction => {
				newState.allIds.push(construction._id);
				newState.byId[construction._id] = {
					...construction
				}
			})

			constructions.forEach(construction => {
				const isWall = construction.construction.name.toLowerCase().indexOf('wall') > -1;
				const isFence = construction.construction.name.toLowerCase().indexOf('fence') > -1;
				const isBridge = construction.construction.name.toLowerCase().indexOf('bridge') > -1;
				const isWindow = construction.construction.name.toLowerCase().indexOf('window') > -1;
				let constructionName = isWall ? 'wall' : 'path';
				constructionName = isBridge ? 'bridge' : constructionName;
				constructionName = isFence ? 'fence' : constructionName;
				constructionName = isWindow ? 'window' : constructionName;

				newState = {
					...assignNeighbouringWalls(construction, newState, constructionName)
				}
			});

			const gate = constructions.find(construction => (construction.construction.name.toLowerCase().indexOf('gate') > -1))

			return newState;
		}

		case ADD_CONSTRUCTION: {
			const constructionInstance = action.payload;

			let newState = {
				allIds: [
					...new Set([...state.allIds, constructionInstance._id])
				],
				byId: {
					...state.byId,
					[constructionInstance._id]: {
						...constructionInstance
					}
				}
			};

			Object.values(newState.byId).forEach(construction => {
				const isWall = construction.construction.name.toLowerCase().indexOf('wall') > -1;
				const isFence = construction.construction.name.toLowerCase().indexOf('fence') > -1;
				const isBridge = construction.construction.name.toLowerCase().indexOf('bridge') > -1;
				const isWindow = construction.construction.name.toLowerCase().indexOf('window') > -1;
				let constructionName = isWall ? 'wall' : 'path';
				constructionName = isBridge ? 'bridge' : constructionName;
				constructionName = isFence ? 'fence' : constructionName;
				constructionName = isWindow ? 'window' : constructionName;

				newState = {
					...assignNeighbouringWalls(construction, newState, constructionName)
				}
			})

			return newState;
		}

	    case REMOVE_CONSTRUCTION: {
	      const newByIds = { ...state.byId };
	      delete newByIds[action.payload._id];

	      return {
	        allIds: [ ...state.allIds.filter(id => id !== action.payload._id) ],
	        byId: { ...newByIds }
	      }
	    }

	    case UPDATE_CONSTRUCTION: {
	      return {
	        allIds: [ ...state.allIds, action.payload._id ],
	        byId: { ...state.byId, [action.payload._id]: { ...state.byId[action.payload._id], ...action.payload } }
	      }
	    }

		default:
			return state;
	}
}

export function assignNeighbouringWalls(construction, state, constructionName) {
	// console.log('assignNeighbouringWalls', state)
	let neighbours = '';

	const leftNeighbour = findNeighbourAt(RELATIVE_POSITION.LEFT, construction, state, constructionName);
	if (leftNeighbour) {
		neighbours += 'LEFT.'
	}

	const rightNeighbour = findNeighbourAt(RELATIVE_POSITION.RIGHT, construction, state, constructionName);
	if (rightNeighbour) {
		neighbours += 'RIGHT.'
	}

	const topNeighbour = findNeighbourAt(RELATIVE_POSITION.TOP, construction, state, constructionName);
	if (topNeighbour) {
		neighbours += 'TOP.'
	}

	const bottomNeighbour = findNeighbourAt(RELATIVE_POSITION.BOTTOM, construction, state, constructionName);
	if (bottomNeighbour) {
		neighbours += 'BOTTOM.'
	}

	if (neighbours === '' || !neighbours) {
		neighbours = 'NONE'
	}

	return {
		...state,
		byId: {
			...state.byId,
			[construction._id]: {
				...construction,
				neighbours
			}
		}
	}
}

export function findNeighbourAt(relativePosition, { position }, state, constructionName) {
	if (relativePosition === RELATIVE_POSITION.LEFT) {
		return getConstructionAtPosition({ x: position.x - 1, y: position.y }, state, constructionName);
	}

	if (relativePosition === RELATIVE_POSITION.RIGHT) {
		return getConstructionAtPosition({ x: position.x + 1, y: position.y }, state, constructionName);
	}

	if (relativePosition === RELATIVE_POSITION.TOP) {
		return getConstructionAtPosition({ x: position.x, y: position.y - 1 }, state, constructionName);
	}

	if (relativePosition === RELATIVE_POSITION.BOTTOM) {
		return getConstructionAtPosition({ x: position.x, y: position.y + 1 }, state, constructionName);
	}
}

function getConstructionAtPosition({ x, y }, state, constructionName) {
	return Object.values(state.byId).find(constructionInstance => {
		return constructionInstance.position.x === x
			&& constructionInstance.position.y === y
			&& (
				constructionInstance.construction.name.toLowerCase().indexOf('wall') > -1
				|| constructionInstance.construction.name.toLowerCase().indexOf('door') > -1
				|| constructionInstance.construction.name.toLowerCase().indexOf('gate') > -1
				|| constructionInstance.construction.name.toLowerCase().indexOf('window') > -1
			)
	});
}
