import {
    GET_MINERALS_SUCCESS,
    GET_MINERALS_FAILURE,
    GATHER_MINERAL_SUCCESS,
    GATHER_MINERAL_FAILURE,
    READ_TILE_MINERALS_SUCCESS,
    READ_TILE_MINERALS_FAILURE,
    CLEAR_TILE_MINERALS,
    REMOVE_MINERAL,
    CLEAR_ALL_MINERALS,
    ADD_MINERAL,
    UPDATE_MINERAL,
    GET_TRADE_MINERALS_SUCCESS,
    GET_TRADE_MINERALS_FAILURE
} from '../actionTypes';
import { clearAllTiles, loadTilesSuccess } from './tile.actions';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';

import { moveItemFailure } from './move-item.actions';
import { colyClient } from '../../services/Panel-initialisation.service';

export const getMineralsSuccess = payload => {
    payload.response = payload.response.map(mineral => ({ ...mineral, mineralType: payload.mineralTypes.find(type => type._id === mineral.mineralTypeId) }))
    return ({
        type: GET_MINERALS_SUCCESS,
        payload: payload.response
    })
}

export const getMineralsFailure = () => ({
    type: GET_MINERALS_FAILURE
})

export const getMineralsAsync = payload => dispatch => {
    dispatch(clearAllMinerals());

    let query = { $or: [{ panelId: payload.panelId, z: payload.z}, { characterId: payload.characterId}] }

    if (payload.isNotFirstInitialisation) {
        query = { panelId: payload.panelId, z: payload.z }
    }

    return dispatch(getRecursivelyAsync('mineral-instances', (response) => (getMineralsSuccess({ ...payload, response })), 0, { $or: [ { panelId: payload.panelId, z: payload.z }, { characterId: payload.characterId } ] } ));
}

export const gatherMineralSuccess = payload => {
    return ({
        type: GATHER_MINERAL_SUCCESS,
        payload
    })
}

export const gatherMineralFailure = () => ({
    type: GATHER_MINERAL_FAILURE
})

export const getTradeMineralsSuccess = payload => {
    return ({
        type: GET_TRADE_MINERALS_SUCCESS,
        payload
    })
}

export const getTradeMineralsFailure = () => ({
    type: GET_TRADE_MINERALS_FAILURE
})

export const getTradeMineralsAsync = payload => dispatch => {
    return client.service('mineral-instances').find({ query: { characterId: payload.characterId, markedForTradeCount: { $gte: 1 } }})
        .then((response) => {
            response.data = response.data.map(mineral => ({ ...mineral, mineralType: payload.mineralTypes.find(type => type._id === mineral.mineralTypeId) }))
            dispatch(getTradeMineralsSuccess(response.data));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('getTradeMineralsAsync fail', error);
            throw new Error(error);
        })
}

export const gatherMineralAsync = payload => dispatch => {
    const { mineralTypeId, id, quantity, idempotentRequestKey } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'mineral-instances',
        commandType: 'create',
        data: {
            mineralTypeId,
            quantity: 1,
            position: JSON.stringify({}),
            panelId: "",
        },
        params: {
            query: {
                idempotentRequestKey: 'word',
                movementFrom: {
                    id: Number(id)
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addMineralToTileAsync = payload => dispatch => {
    const { mineralInstance, tileId, tileTypeId } = payload;
    const { mineralTypeId, quantity, recipeId, characterId, id } = mineralInstance;

    colyClient.room.send('doAction', {
        serviceName: 'mineral-instances',
        commandType: 'create',
        data: {
            mineralTypeId,
            quantity,
            tileId,
            tileTypeId,
            panelId: "",
            position: JSON.stringify({}),
            characterId: ""
        },
        params: {
            query: {
                movementFrom: {
                    id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const clearTileInventory = () => ({
    type: CLEAR_TILE_MINERALS
})

export const addMineralToCharacterAsync = payload => dispatch => {
    const { mineralInstance, characterId } = payload;
    const { createdAt, mineralTypeId, quantity, recipeId, id } = mineralInstance;

    colyClient.room.send('doSuperAction', {
        serviceName: 'mineral-instances',
        name: 'fetch',
        data: {
            mineralTypeId,
            quantity,
            tileId: "",
            characterId
        },
        params: {
            query: {
                movementFrom: {
                    id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const removeMineral = (payload) => ({
    type: REMOVE_MINERAL,
    payload
})

export const clearAllMinerals = () => ({
    type: CLEAR_ALL_MINERALS
})

export const updateMineral = (payload) => {
    return ({
        type: UPDATE_MINERAL,
        payload
    })
}

export const addMineral = (payload) => {
    return ({
        type: ADD_MINERAL,
        payload
    })
}