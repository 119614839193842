import React from "react";
import { connect } from 'react-redux'

import { serialize } from '../side-bar/looking/Looking';

import ImageSurface from '../edit-drawing-surface/ImageSurface'

// import './ItemDescription.css';

class ReadWritingSurface extends React.Component {
	state = {
		navigationHandler: this.navigationHandler.bind(this)
	}

    constructor() {
        super();
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.state.navigationHandler)
    }

    componentDidMount() {
    	console.log(this.props.writingSurface)
    	this.setState({
    		isTitlePage: true,
    		pageIndex: 0,
    		width: this.props.width,
    		height: this.props.height,
    		text: this.props.writingSurface?.graffiti ? serialize({ children: this.props.writingSurface?.graffiti }) : ''
    	})
        document.addEventListener('keydown', this.state.navigationHandler);
    }

    navigationHandler(event) {
    	if (event.key === '<') {
            this.showLeft();
            return;
        }

        if (event.key === '>') {
            this.showRight();
            return;
        }
    }

    showLeft() {
    	if (this.state.pageIndex === 0 && this.state.isTitlePage) {
    		return
    	}

    	if (!this.props.writingSurface.writingSurfaces?.length) {
    		return;
    	}

    	if (this.state.pageIndex === 0 && !this.state.isTitlePage) {
    		this.setState({
    			isTitlePage: true,
    			width: this.props.width,
	    		height: this.props.height,
	    		text: this.props.writingSurface?.graffiti ? serialize({ children: this.props.writingSurface?.graffiti }) : ''
    		})

    		return;
    	}

    	const newPage = this.props.writingSurface.writingSurfaces[this.state.pageIndex - 1];

    	const SMALLEST_WIDTH = 22;
        const SMALLEST_HEIGHT = 6;

        const writingSurfaceWidth = newPage?.width || SMALLEST_WIDTH;
        const writingSurfaceHeight = newPage?.height || SMALLEST_HEIGHT;

		const text = newPage?.graffiti ? serialize({ children: newPage?.graffiti }) : ''

    	this.setState({
			isTitlePage: false,
			pageIndex: this.state.pageIndex - 1,
			width: `${writingSurfaceWidth}.1ch`,
			height: `calc(${writingSurfaceHeight} * 2.1ch)`,
			text,
			pixelsArray: newPage.pixelsArray
		})
    }

    showRight() {
    	if (!this.state.isTitlePage && this.state.pageIndex === this.props.writingSurface.writingSurfaces.length - 1) {
    		return;
    	}

    	if (!this.props.writingSurface.writingSurfaces?.length) {
    		return;
    	}

    	let newPage = this.props.writingSurface.writingSurfaces[this.state.pageIndex + 1];

    	if (this.state.isTitlePage) {
    		newPage = this.props.writingSurface.writingSurfaces[this.state.pageIndex];
    	}

    	const SMALLEST_WIDTH = 22;
        const SMALLEST_HEIGHT = 6;

        const writingSurfaceWidth = newPage?.width || SMALLEST_WIDTH;
        const writingSurfaceHeight = newPage?.height || SMALLEST_HEIGHT;

		const text = newPage?.graffiti ? serialize({ children: newPage?.graffiti }) : ''

        const drawingSurfaceWidth = writingSurfaceWidth;
        const drawingSurfaceHeight = (newPage?.height || SMALLEST_HEIGHT) * 2;

    	this.setState({
    		isTitlePage: false,
			pageIndex: this.state.isTitlePage ? this.state.pageIndex : this.state.pageIndex + 1,
			width: `${writingSurfaceWidth}.1ch`,
			height: `calc(${writingSurfaceHeight} * 2.1ch)`,
			drawingSurfaceWidth: drawingSurfaceWidth,
			drawingSurfaceHeight: drawingSurfaceHeight,
			text,
			pixelsArray: newPage.pixelsArray
		})
    }

    render() {
        return (
        	<div className="read-writing-surface-container">
	            <div className={"surface"}
	            	style={{
		            	width: this.state.width,
		            	height: this.state.height
		            }}>

	                {this.state.pixelsArray?.length > 0 ? (
		                <div className="drawing">
	                        <ImageSurface pixelsArray={this.state.pixelsArray} width={this.state.drawingSurfaceWidth} height={this.state.drawingSurfaceHeight} sizeUnit="ch"/>
	                    </div>
                	) : (
                		<div className="bottom-ting-inner">{this.state.text}</div>
                	)}

	            </div>
	            <div className={this.props.writingSurface.writingSurfaces?.length ? "menu-actions" : "hidden"}>
			        <span className="action-shortcut"><span className="shortcut">{'<'}</span> Prev Page</span>
			        <span className="action-shortcut"><span className="shortcut">></span> Next Page</span>
	            </div>
            </div>
        )
    }
}

const mapToStateProps = state => {
    return {}
}

export default connect(
    mapToStateProps,
    {
        
    }
)(ReadWritingSurface);