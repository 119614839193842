import { GET_GROUPS_SUCCESS } from "../actionTypes";

const initialState = {
  groups: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_GROUPS_SUCCESS: {
      const groups = action.payload;

      return {
        ...state,
        groups: [ ...groups ]
      }
    }

    default:
      return state;
  }
}
