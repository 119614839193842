export const koalaFaceSentences = [
"Its face is covered in colorful scales",
"Its enormous eyes are framed by delicate antennae",
"Its mouth is a large, shimmering proboscis",
"Its wings are enormous and iridescent",
"A sea of blues, greens, and purples",
"A face with big, beautiful eyes",
"Luscious, thick lips",
"A long, slender nose",
"High cheekbones",
"Soft, downy cheeks",
"A giant, colorful face with big, bright eyes",
"A giant face with intricate patterns on its wings",
"A giant face with a long, slender proboscis",
"A giant face with delicate antennae",
"Two large, glistening eyes that seem to stare into your soul. ",
"A wide, smiling mouth full of sharp teeth. ",
"Two large antennae that wave menacingly. ",
"A bright, colorful face that dazzles in the sun",
"A oversized face with big, round eyes",
"A face with a wide, open mouth",
"A face with long, slender antennae",
"A face with delicately veined wings",
"A face with brightly colored patterns",
]

export const koalaBodySentences = [
"Its body is as big as a dinner plate",
"It has eyes the size of quarters",
"Its wingspan is wider than a human armspan",
"Its covered in iridescent scales",
"It has a long, curved proboscis",
"Glorious golden wings, spanning a full three feet from tip to tip",
"A delicate, fuzzy body the size of a human fist",
"Vibrant patterns in an array of colors, covering every inch of its enormous wings",
"Its body is covered in brightly colored scales",
"Its enormous wings are delicate and beautiful",
"It has a long, thin body with six legs",
"Its proboscis is long and spiral-shaped, used for drinking nectar",
"magnificently large wingspan",
"two pairs of fragile legs",
"graceful flight",
"gentle flapping of wings",
]

export const koalaSoundWords = [
"humming",
    "whirring",
    "flapping",
    "beating",
    "soaring",
    "gliding",
    "droning",
    "buzzing",
    "fluttering",
    "wafting",
    "Flapping",
    "soaring",
    "swooping",
    "floating",
    "gliding",
    "wafting",
    "drifting",
    "whooshing",
    "flapping",
    "soaring",
    "gliding",
    "drifting",
    "rustling",
    "tumbling",
    "floating",
    "wafting",
    "fluttering"
]

export const koalaFurWords = [
    "silky",
    "fuzzy",
    "downy",
    "hairy",
    "luxurious",
    "feathery",
    "billowy",
    "airy",
    "fluttery",
    "whimsical",
    "Fluffy",
    "Soft",
    "Silky",
    "Light",
    "Stretchy",
    "Bright",
    "Vibrant",
    "Eye-catching",
    "Unique",
    "Gorgeous",
    "colorful",
    "delicate",
    "patterned",
    "iridescent",
    "fragrant",
    "smooth",
    "supple",
    "veined",
    "winged",
    "spectacular",
    "Pristine",
    "Gossamer",
    "Downy",
    "Fluffy",
    "Soft",
    "Silk-like",
    "Velvety",
    "Luxurious",
    "Rich",
    "Plush",
    "gigantic",
    "furry",
    "feathery",
    "fluttery",
    "colorful",
    "smooth",
    "silky",
    "iridescent",
    "velvety",
    "opalescent"
]

export const koalaSmellWords = [
    "Perfume",
    "nectar",
    "sweet",
    "rotten",
    "pungent",
    "musky",
    "rancid",
    "acid",
    "sickening",
    "cloying",
    "Wet",
    "slimy",
    "stinky",
    "foul",
    "reeking",
    "rank",
    "smelly",
    "noisome",
    "odorous",
    "putrid",
    "fragrant",
    "floral",
    "sweet",
    "pungent",
    "musky",
    "fruity",
    "tropical",
    "putrid",
    "rank",
    "reeking",
    "Pungent",
    "Sweet",
    "Overwhelming",
    "Intoxicating",
    "Haunting",
    "Alluring",
    "Seductive",
    "Captivating",
    "Mesmerizing",
    "Enthralling",
    "Delicate",
    "Sweet",
    "Fragrant",
    "Intoxicating",
    "Overwhelming",
    "Cloying",
    "Sickly",
    "Pungent",
    "Acrid",
    "Putrid"
]

export const koalaBehaviourSentences = [
"looming large and foreboding",
"looming overhead, flapping slowly",
"its enormous wings flapping slowly in the breeze",
"its long proboscis curling and uncurling",
"its large compound eyes staring fixed",
"its antennas are moving around frantically",
"it is flapping its wings slowly",
]