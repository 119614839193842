import { GET_WAGON_TYPES_LOADING, GET_WAGON_TYPES_SUCCESS } from '../actionTypes';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';

export const getWagonTypesSuccess = payload => {
    return ({
        type: GET_WAGON_TYPES_SUCCESS,
        payload
    })
}

export const getWagonTypesAsync = payload => dispatch => {
    return client.service('wagon-types').find()
		.then((response) => {
            dispatch(getWagonTypesSuccess(response));
            return response
		})
        .catch((error) => {
            console.error('error', error);
        })
           
}