import React from "react";
import { connect } from 'react-redux'
import { isBefore } from 'date-fns';

import store from '../../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../../redux/actions/characters.actions';
import { disableKeyboardMovement, enableKeyboardMovement, showHelp, startLooking } from '../../../redux/actions/keyboard-shortcuts.actions';
import {
    selectPanel,
    selectSidebarMessages,
    selectEmbarkCharacterMessages,
    selectTotalMessages,
    selectCharacter,
    selectCharacters,
    selectIsMessagesFocussed,
    selectFocussedCharacterId,
    selectMessageOptionss,
    selectEmbarkCharacter,
    selectCurrentEmbarkGroup
} from '../../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';

import { client } from '../../../services/client';

import './SidebarMessages.css';

// DISABLE DEFAULT SCROLL?

class SidebarMessages extends React.Component {
    state = {
        text: "",
        isFetching: false,
        isMessagesSet: false,
    };

    handleInput = event => {
        let isLastMessageFocussed, focussedCharacterMessages;

        if (this.state.highlight?.characterId) {
            focussedCharacterMessages = this.props.messages[this.state.highlight?.characterId].messages
            isLastMessageFocussed = this.state.highlight.messageIndex === focussedCharacterMessages.length;
        }

        if (focussedCharacterMessages && !isLastMessageFocussed) {
            return;
        }

        if (event.nativeEvent.inputType === 'insertLineBreak') {
            this.submitMessage();
        } else {
            this.setState({ text: event.target.value });
        }
    };

    disableMovement = () => {
        store.dispatch(disableKeyboardMovement());
    }

    enableMovement = () => {
        store.dispatch(enableKeyboardMovement());
    }

    submitMessage = () => {
        this.props.createMessageAsync({
            text: this.state.text
        })

        this.setState({ text: '' });

        document.activeElement.blur();
    };

    openHelp = () => {
        store.dispatch(showHelp());
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.state.handleNavigation)
    }

    componentDidMount() {
        window.addEventListener('keydown', this.state.handleNavigation);

        if (!this.state.highlight.characterId) {
            return;
        }

        const focussedCharacterMessages = this.props.messages[this.state.highlight.characterId]?.messages
        // const isLastMessageFocussed = this.state.highlight.messageIndex === focussedCharacterMessages.length;

        // if (isLastMessageFocussed) {
        //     const textareaElement = document.getElementsByClassName('new-message')[0]

        //     if (!textareaElement) {
        //         return;
        //     }

        //     textareaElement?.focus()
        //     setTimeout(() => (textareaElement.value = ''), 10)
        // }

        // setTimeout(() => (this.correctScrollPosition()));
    }

    handleUpArrow() {
        if (this.state.highlight.type === 'messages') {
            this.setState({
                highlight: {
                    ...this.state.highlight,
                    messageIndex: this.state.highlight.messageIndex === 0 ? 0 : this.state.highlight.messageIndex - 1
                }
            })

            document.activeElement.blur();
        }

        if (this.state.highlight.type === 'characters') {
            const messageKeys = Object.keys(this.props.messages)
            const nextCharacterIndex = messageKeys.indexOf(this.state.highlight.characterId) === 0 ? 0 : messageKeys.indexOf(this.state.highlight.characterId) - 1;

            this.setState({
                highlight: {
                    ...this.state.highlight,
                    characterId: messageKeys[nextCharacterIndex]
                }
            })
        }
    }

    handleDownArrow() {
        if (this.state.highlight.type === 'messages') {
            const messageOptionsCount = this.props.messageOptions.length ? this.props.messageOptions.length - 1 : 0;
            const focussedCharacterMessages = this.props.messages[this.state.highlight.characterId].messages
            const isLastMessageFocussed = this.state.highlight.messageIndex === focussedCharacterMessages.length + messageOptionsCount;

            this.setState({
                highlight: {
                    ...this.state.highlight,
                    messageIndex: isLastMessageFocussed ? this.state.highlight.messageIndex : this.state.highlight.messageIndex + 1
                }
            })
        }

        if (this.state.highlight.type === 'characters') {
            const messageKeys = Object.keys(this.props.messages)
            const nextCharacterIndex = messageKeys.indexOf(this.state.highlight.characterId) === messageKeys.length - 1 ? messageKeys.length - 1 : messageKeys.indexOf(this.state.highlight.characterId) + 1;

            this.setState({
                highlight: {
                    ...this.state.highlight,
                    characterId: messageKeys[nextCharacterIndex]
                }
            })
        }
    }

    handleRightArrow() {
        if (this.state.highlight.type === 'messages') {
            return;
        }

        const messageOptionsCount = this.props.messageOptions.length - 1 || 0;
        const focussedCharacterMessages = this.props.messages[this.state.highlight.characterId].messages

        this.setState({
            highlight: {
                ...this.state.highlight,
                type: 'messages',
                messageIndex: focussedCharacterMessages.length + messageOptionsCount
            }
        })
    }

    handleLeftArrow() {
        if (this.state.highlight.type === 'characters') {
            return;
        }

        this.setState({
            highlight: {
                ...this.state.highlight,
                type: 'characters',
            }
        })
    }

    handleOptionSelection(messageOption) {
        const messageOptionIndex = this.state.highlight.messageIndex - this.props.messages[this.state.highlight.characterId].messages.length
        const selectedOption = messageOption || this.props.messageOptions[messageOptionIndex]

        if (messageOptionIndex < 0) {
            return;
        }

        selectedOption.callback(selectedOption.text);

        setTimeout(() => {
            const focussedCharacterId = this.props.focussedCharacterId || Object.keys(this.props.messages)[0]
            const messageOptionsCount = this.props.messageOptions?.length > 0 ? this.props.messageOptions?.length - 1 : 0;

            this.setState({
                ...this.state,
                highlight: {
                    ...this.state.highlight,
                    messageIndex: this.props.messages[focussedCharacterId]?.messages?.length + messageOptionsCount
                }
            })
        })
    }

    focusTextareaOnNavigation() {
        if (this.state.highlight.type === 'messages') {
            const isMessageOptions = this.props.messageOptions?.length > 0;
            const focussedCharacterMessages = this.props.messages[this.state.highlight.characterId].messages
            const isLastMessageFocussed = this.state.highlight.messageIndex === focussedCharacterMessages.length;

            if (!isMessageOptions && isLastMessageFocussed) {
                const textareaElement = document.getElementsByClassName('new-message')[0]
                textareaElement?.focus()
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.highlight) {
            const focussedCharacterId = props.focussedCharacterId || Object.keys(props.messages)[0]
            const messageOptionsCount = props.messageOptions?.length > 0 ? props.messageOptions?.length - 1 : 0;
            return {
                ...state,
                highlight: {
                    type: 'messages',
                    characterId: focussedCharacterId,
                    messageIndex: props.messages[focussedCharacterId]?.messages?.length + messageOptionsCount
                }
            }
        }

        return state;
    }

    formatDistance(result, number) {
        result = result.replace('Minutes', 'm');
        result = result.replace('Hours', 'h');
        result = result.replace('Days', 'd');
        result = result.replace('Months', 'mo');
        result = result.replace('Years', 'y');

        result = result.replace('lessThan', '<')
        result = result.replace('about', '');
        result = result.replace('over', '>');

        result = result.replace('x', number)
        result = result.replace('X', number)

        return result;
    }

    render() {
        const characterIds = Object.keys(this.props.messages)

        let messages;

        if (this.props.messages && this.props.messages.map) {
            messages = this.props.messages
                .map((message, index) => {
                    const isHighlighted = this.state.highlight?.type === 'messages' && this.state.highlight?.messageIndex === index;
                    const characterId = message.characterId || message.embarkCharacterId;
                    const isReader = this.props.character._id === characterId;

                    const characterName = message.characterName || message.character?.name;

                    return (
                        <li key={index} className="sidebar-message">
                            <div className={characterId ? "message-text" : "system-message-text"}>
                                <span className={characterName ? "character-name" : "hidden-message-part"}
                                    style={{color: 'var(--secondary)'}}>
                                    {characterName || 'Unknown Character'}&nbsp;
                                    {message.targetCharacterName ? (<span>said to {message.targetCharacterName}</span>) : ('')}
                                </span>
                                {message.text}
                            </div>
                        </li>
                    )
                })
        }

        let textareaClassName = 'new-message';

        const messageOptions = this.props.messageOptions.map((messageOption, index) => {
            let classNames = "reader-message";

            return (
                <li key={index} className={classNames}>
                    <div className="message-text" onClick={() => (this.handleOptionSelection(messageOption))}>
                        {messageOption.text}
                    </div>
                </li>
            )
        })

        return (
            <div className="messages">
                <ul className="sidebar-messages-list">
                    {messages}
                </ul>
            </div>
        )
    }
}

const mapStateToProps = state => {
    let isEmbarkMessages = false;

    const panel = selectPanel(state);
    let messages = selectSidebarMessages(state);

    const embarkMessages = selectEmbarkCharacterMessages(state);

    const character = selectCharacter(state)
    const embarkCharacter = selectEmbarkCharacter(state);

    if (Object.entries(messages).length === 0 && embarkCharacter._id) {
        isEmbarkMessages = true;
    }

    const characters = selectCharacters(state);
    const embarkCharacters = embarkCharacter && selectCurrentEmbarkGroup(state, embarkCharacter?._id)?.embarkCharacters

    const focussedCharacterId = selectFocussedCharacterId(state);
    const messageOptions = selectMessageOptionss(state);

    return {
        panel,
        messages: isEmbarkMessages ? embarkMessages : messages,
        character: isEmbarkMessages ? embarkCharacter : character,
        characters: isEmbarkMessages ? embarkCharacters : characters,
        focussedCharacterId,
        messageOptions
    }
}

export default connect(
    mapStateToProps,
    { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, flashCharacterColorAsync, startLooking, createNewMessage }
)(SidebarMessages);