import { CHANGE_CHARACTER, START_NEW_GAME, GET_DEAD_CHARACTERS_SUCCESS, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  deadCharacters: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_DEAD_CHARACTERS_SUCCESS: {
      return {
        ...state,
        deadCharacters: [...action.payload],
      }
    }

    default:
      return state;
  }
}
