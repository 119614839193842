export const jaguarFaceSentences = [
"Its eyes are small and black, and its teeth are sharp and pointy.",
"Its nose is long and pointy, and its ears are big and round.",
"A face that is half human and half animal.",
"Oddly shaped mouth, large pointed nose, two beady eyes",
"soft fur covering its face, long whiskers on either side of its nose, furry ears sticking up from its head",
"light shining from its eyes in the darkness, a bloody grin, its mouth open wide revealing sharp teeth.",
"Flappy two-tone ears",
"Big black eyes",
"A long, pointy nose",
"A wide, toothy mouth",
"wrinkled skin",
"Bat-like wings",
"A furry body",
"four legs",
"pointy claws",
"Two giant, black eyes stare out from a face that is half bat, half human. ",
"Long, sharp teeth glisten in the light, and slimy saliva drips from its mouth. ",
"Brown fur covers the body, except for the bald, purple skin",
]

export const jaguarBodySentences = [
"Intimidating creature of the night, with eyes that seem to pierce your soul.",
"Covered in soft, black fur.",
"Pointed ears",
]

export const jaguarSoundWords = [
"Screaming",
"Shrieking",
"Howling",
"Wailing",
"Gnashing",
"screeching",
"unearthly",
]

export const jaguarFurWords = [
"Thick",
"Rugged",
"hairy",
"scaly",
"dry",
"cracked",
"brittle",
"Papery",
"crinkled",
"peeling",
"Furry",
"Soft",
"Supple",
"Oily",
"Spongy",
"Thick",
"Clammy",
"Sticky",
"Slimy",
"Rubbery",
"Dark",
"Coarse",
"Thick",
"Hairy",
"Stiff",
"Itchy",
"Heavy",
"Smelly",
"Warm",
"Oily",
]

export const jaguarSmellWords = [
    "putrid",
    "musky",
    "gamey",
    "smokey",
    "cheesy",
    "overpowering",
    "skanky",
    "rank",
    "gross"
]

export const jaguarBehaviourSentences = [
"It is watching you with its beady eyes.",
"It is sizing you up with its claws outstretched.",
"It is flapping its wings menacingly.",
"It is baring its teeth at you.",
]