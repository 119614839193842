export const itemWorkshopDescriptions = [
    {
        "name": "Marble Brick Wall",
        "text": "A Marble Brick Wall is a formidable structure with a sturdy composition constructed from intricate, polished marble bricks. The wall's smooth texture and pleasing aesthetic serves as a strong barrier against hostiles, with its high durability and impenetrable nature. May your future builds be as grand as this Marble Brick Wall."
    },
    {
        "name": "Alien Floor",
        "text": "Welcome to the Alien Floor, where mysterious creatures and deadly plants await. Watch your step, adventurer - this place is not for the faint of heart."
    },
    {
        "name": "Counter",
        "text": "A cut waist-high wall counter from which you can sell your wares safely. Enjoy the life of a shopkee without the mess of customers infuriatingly touching all your things."
    },
    {
        "name": "Alien Staircase down",
        "text": "Descend the extraterrestrial steps amidst glowing pillars, but beware the lurking unknown. Dare you journey down the Alien Staircase?"
    },
    {
        "name": "Granite Wall",
        "text": "Greetings, dear adventurer. Have you ever beheld a Granite Wall? It is a formidable structure with surfaces are smooth to the touch; a testament to the excellent craftsmanship of the stonemasons who assembled it."
    },
    {
        "name": "Alien Wall",
        "text": "Behold the grandeur of the Alien Wall! Crafted from exotic Tolkium metal, it stands proud and mysterious, an enigma waiting to be explored."
    },
    {
        "name": "Post Box",
        "text": "Behold the metallic maw, awaiting your words to devour. Mail your hopes, dreams and fleeting thoughts. What mysteries will it hold?"
    },
    {
        "name": "Vertical Stone Bridge",
        "text": "Cross over the abyss on the Vertical Stone Bridge, a skillful feat of engineering that connects down and up. The bridge is crafted from sturdy stone blocks, which have been meticulously interlocked to create a sturdy and reliable structure."
    },
    {
        "name": "Staircase",
        "text": "A marvel of vertiginous delight, the Staircase serves as a means of traversing vertically through any structure. It is composed of a sturdy material, strong enough to support the weight of even the most heavily armored hero in their skyward aims."
    },
    {
        "name": "Mansion Wall",
        "text": "As you approach the towering Mansion Wall, you can't help but feel small. But don't let its size intimidate you. Enter, if you dare!"
    },
    {
        "name": "Wooden Log Wall",
        "text": "Behold, the Wooden Log Wall, a sturdy and rustic barricade that provides functional fortification for your home or encampment. Crafted from thick, rough-hewn logs, this wall is built to withstand the elements and the attacks of beasts and bandits alike. Place it around the perimeter of your home, workshop or town."
    },
    {
        "name": "Wooden Door",
        "text": "The Wooden Door is a sturdy rectangular slab with a smooth surface and visible grain. Creatures won't be able to open but players can traverse it freely - unless someone has installed a lock that is."
    },
    {
        "name": "Wooden Wagonway",
        "text": "A Wooden Wagonway is a sturdy path for wheeled conveyances, fashioned out of timber planks, and raised for ease of travel across varied terrain. This useful structure allows for effortless passage of carts and other vehicles, even under heavy loads. Its construction requires a skilled hand, but the end result is worth the effort."
    },
    {
        "name": "Granite Brick Wall",
        "text": "The Granite Brick Wall is a sturdy and imposing structure, able to withstand even the strongest of blows. Whether you need to protect your castle from attackers or simply want to make a statement with your home's exterior, the Granite Brick Wall is the perfect choice."
    },
    {
        "name": "Basalt Wall",
        "text": "The Basalt Wall is dark, with earthy tones that easily blend in with natural surroundings. The rough surface of the wall showcases the natural grain of the rock, creating a subtle texture across its entire surface. Thick and sturdy, this wall is perfect for defending against attacks and keeping unwanted pests at bay. Built to withstand even the harshest weather conditions, the Basalt Wall is an essential addition for any settlement looking to protect their land from harm."
    },
    {
        "name": "Clay Brick Floor",
        "text": "Behold the Clay Brick Floor, a sturdy and practical addition to any abode. This flooring option offers a warm and rustic feel, with its uneven and textured surface, making one feel right at home."
    },
    {
        "name": "Alien Staircase",
        "text": "Behold! The extraterrestrial staircase awaits your ascent. Climb it to unlock unimaginable rewards. Leave your fear on the landing below."
    },
    {
        "name": "Wooden Pallisade Wall",
        "text": "The Wooden Pallisade Wall is made of sturdy wooden planks held together with thick ropes and stakes. The planks are hewn from ancient trees, still bearing marks of the skilled hands that shaped them. The wall stands tall and imposing, rising high above the ground, keeping unwanted visitors out."
    },
    {
        "name": "Guard House",
        "text": "A Guard House is a sturdy, fortified structure often found at town entry points. Made of heavy stone and fortified with metal, it boasts a narrow entrance that, when crewed, can be closed off quickly against unwanted visitors. Guard Houses are an essential element of the defense against invaders or other unwanted parties."
    },
    {
        "name": "Mud Brick Wall",
        "text": "Behold the epitome of rusticity! Crafted from humble and sturdy materials, this wall provides a reliable barrier against the dangers that lurk beyond."
    },
    {
        "name": "Fire pit",
        "text": "Wood and brush configured into a pyramid, with kindling and smaller branches at the bottom, ready to ignite with one spark of charcoal. A place to gather on the dark, cold nights."
    },
    {
        "name": "Metal gate",
        "text": "Behold, a magnificent metal gate that stands tall and proud. Its surface gleams in the light, and its intricate lattice design is both sturdy and aesthetically pleasing to the eye. The gate’s purpose is clear, to stand as an entryway to the world beyond, a door to exploration and adventure. "
    },
    {
        "name": "Mansion Floor",
        "text": "Welcome to the opulent Mansion Floor, where extravagance and luxury reign supreme. Dine in style or indulge in some high-end shopping - the possibilities are endless in this chic and sophisticated space. Get ready to live like a wealthy socialite and bask in the glory of the Mansion."
    },
    {
        "name": "Metal Wagonway",
        "text": "Ah, the Metal Wagonway. A sturdy and efficient means of transporting goods across long distances. This marvelous invention is comprised of a series of metal tracks on which a wagon, crafted to fit snugly upon said tracks, may glide with ease."
    },
    {
        "name": "Limestone Floor",
        "text": "As you inspect the Limestone Floor, you notice a smooth, polished surface that gleams in the light. The floor is made of interlocking rectangular pieces, which fit together precisely to form a sturdy and even surface. You get the sense that this would make an excellent foundation for any structure or dwelling."
    },
    {
        "name": "Marble Floor",
        "text": "As you step onto the marble floor, its smooth surface greets your feet with a cool embrace. The natural patterns etched into the stone cast shadowy illusions that seem to dance with the light. Every vein and swirl seems to have a story to tell."
    },
    {
        "name": "Limestone Wall",
        "text": "Whilst scrolling the crafting menu, you pause over Limestone Wall. Made with a combination of strength and beauty in mind, it can be used to separate one zone from another, protect those within from the outside, or even make an impressive border around your property. Strong and formidable, this wall is the perfect addition to any architectural project."
    },
    {
        "name": "Horiztonal Brick Bridge",
        "text": "Greetings, wanderer. The multiverse is full of mysteries indeed. As for the Horizontal Brick Bridge, it's purpose is not one of them - it clearly spans bodies of water from side to side. It is constructed of interlocking bricks, forming a smooth, flat surface that is easy to travel across."
    },
    {
        "name": "Wooden gate",
        "text": "The Wooden Gate is made of polished and weathered wood, which speaks to its long years of use. The gate stands sturdy, supported on either side by heavy-duty hinges. "
    },
    {
        "name": "Staircase down",
        "text": "Descend into the abyss on the Staircase down and watch your step as you do so, you never know what's one floor below."
    },
    {
        "name": "Vertical Brick Bridge",
        "text": "The Vertical Brick Bridge is a towering structure created with care and skill by skilled craftsmen. Its brick surface and sturdy foundation make it a reliable aid in crossing over dangerous terrain or turbulent waters from down to up."
    },
    {
        "name": "Basalt Brick Wall",
        "text": "A Basalt Brick Wall is a sturdy construction made to withstand even the most brutal of attacks. It is comprised of dark, angular bricks that interlock with one another to form a virtually unbreakable barrier. When built, it serves as a reliable means of protection against potential threats, providing a barrier that is almost impossible to breach."
    },
    {
        "name": "Wattle and Daub Wall",
        "text": "Friend, meet most humble and flimsy wall in your arsenal: the Wattle and Daub Wall. The cornerstone of any beginner settlement, it provides a barrier to the untoward. What more can you ask for?"
    },
    {
        "name": "Basalt Floor",
        "text": "The Basalt Floor is a sturdy and unyielding material with a timeless look. It is rugged in appearance, with a rough texture and deep ridges that make it perfect for traversing without slipping. A Basalt Floor crafted with deftness and precision rewards those who traverse it with a feeling of safety and stability, making it the ideal choice for a durable, long-lasting floor covering."
    },
    {
        "name": "Limestone Brick Wall",
        "text": "Ah, another curious adventurer I see. Well then, you have stumbled upon the Limestone Brick Wall, a sturdy and reliable barrier that could keep out even the most tenacious of beasts. Its surface is smooth and unyielding, lacking any crevice or grain for creatures to latch onto. Its functional integrity provides both a reliable barrier to keep things in or out as well as an aesthetically pleasing structure to add to any build."
    },
    {
        "name": "Gravel Floor",
        "text": "With a sturdy texture beneath your feet, the Gravel Floor is perfect for outdoor settings or rustic interiors. The varied mix of small rocks and stones create an interesting visual pattern while also providing a stable surface for walking and other activities. Place the Gravel Floor to transform any bland space into a durable and attractive area.."
    },
    {
        "name": "Mansion Staircase",
        "text": "Behold! The grand staircase of the ancient mansion. Spiral steps of polished matsunium lead to shadowed halls of mystery and wonder."
    },
    {
        "name": "Studded Wooden Door",
        "text": "A sturdy and intimidating Studded Wooden Door that's reinforced with metal inlays. It will keep even the most determined intruder at bay."
    },
    {
        "name": "Mansion Staircase down",
        "text": "Behold the grandeur of the Zacharium-forged staircase, spiralling up to the Mansion's upper levels. Let it spark your curiosity and wonder."
    },
    {
        "name": "Horizontal Stone Bridge",
        "text": "Behold, the Horizontal Stone Bridge, a magnificent feat of engineering that spans wide chasms and treacherous waters. Made of fine stone and reinforced with sturdy arches its wide flat surface is capable of supporting heavy loads of people and materials with ease. If you seek a reliable and durable method of transportation, look no further than the Horizontal Stone Bridge."
    },
    {
        "name": "Wooden Floor",
        "text": "In the age of intergalactic travel, few things are as timeless as a beautifully crafted wooden floor. This one shines with the luster of the carpenter who made it and begs for your step."
    },
    {
        "name": "Granite Floor",
        "text": "Greetings, fellow wanderer of the great expanse beyond. One can only marvel at the solid, stately beauty the Granite Floor exudes. Its surface is pure, with an unyielding strength that has stood the test of time. The texture is rough, yet smooth to the touch, with tiny grains of sand embedded throughout. It is impervious to the ravages of time and will remain steadfast through the ages. So, should you find yourself in need of a foundation befitting a true monarch, do not hesitate to craft yourself a Granite Floor."
    },
    {
        "name": "Wooden Bridge",
        "text": "Cross the chasm with grace and ease on the timeless Wooden Bridge. The creaks and groans only add to the charm."
    },
    {
        "name": "Wooden fence",
        "text": "The sturdy Wooden Fence, perfect for penning creatures and protecting them from harm. Add a latched gate for entry and exit."
    },
    {
        "name": "Clay Brick Wall",
        "text": "The Clay Brick Wall is a structure crafted from clay bricks and serves primarily as a boundary or divider, effectively keeping out any unwanted creatures or entities. It is a reliable barrier that can withstand a great deal of force before giving way. The bricks themselves are arranged in neat rows, with mortar holding them firmly in place."
    },
    {
        "name": "Metal Door",
        "text": " A sturdy and reliable entrance indeed. Its surface gleams with a lustrous shine, boasting of its firm resilience. One can easily imagine the ironclad heft of its weight as it swings open with a click."
    },
    {
        "name": "Marble Wall",
        "text": "Ah, greetings weary traveler. I sense the spark of creativity within you - how admirable! Perhaps, you may be interested in crafting a Marble Wall? The Marble Wall presents itself as a great barrier of  stone, polished to a pristine finish. It carries with it a certain austere elegance, perfect for any wealthy town or fortress."
    },
    {
        "name": "Waldium Disc Plate",
        "text": "Gaze in wonder at the Waldium Disc Plate, crafted from a rare of metal and adorned with intricate designs. A true feast for the eyes."
    },
    {
        "name": "Sugarium Boots",
        "text": "Step with style in these Sugarium Boots, perfect for a night on the town or a quick escape from Night Stalkers. Fashionable and functional, they are a must-have for any voyager with flair."
    },
    {
        "name": "Ursulium Boots",
        "text": "My dear traveller, step up your game with Ursulium Boots - the ultimate footwear for crudest battles and most stylish affairs. Nothing can stop you in these bad boys."
    },
    {
        "name": "Space shirt",
        "text": "Introducing the ultra-futuristic Viewscorp Simulcast Space Shirt, woven with degradable materials for optimized, but limited, interstellar exploration. Embrace the cosmos in style!"
    },
    {
        "name": "Lowrium riveted mail",
        "text": "Gleaming Lowrium Riveted Mail - Crafted to withstand even the toughest battles. Sturdy and stylish!"
    },
    {
        "name": "Grinblatium Boots",
        "text": "Step into stylish superiority with Grinblatium Boots. Wield the power of this rare metal known and leave your adversaries green with envy."
    },
    {
        "name": "Cloth Celebration Bottoms",
        "text": "Deck yourself out in stunning Cloth Celebration Bottoms for special occasions and joyful traditions."
    },
    {
        "name": "Woolen Informal Top",
        "text": "Wrap yourself up with the softest Woolen Top. A casual top that fits into any wardrobe."
    },
    {
        "name": "Space hat",
        "text": "Behold this cosmic cap given to you by Viewscorp Simulcast. Explore Gliese with a hat that degrades on your head as you wear it."
    },
    {
        "name": "Cotton Work Top",
        "text": "A soft, breathable top for the work day. Provides basic defense, it can be dyed for a range of colours."
    },
    {
        "name": "Janssium Vembrace",
        "text": "Wrap yourself in the exquisite strength of Janssium."
    },
    {
        "name": "Leather Work Bottoms",
        "text": "Step up your game and cover your hide with the durable Leather Work Bottoms. Perfect for a day of advanture or an evening's run from Night Stalkers."
    },
    {
        "name": "Work Shoes",
        "text": "Durable and comfortable, perfect for long days on your feet."
    },
    {
        "name": "Sugarium Coronet",
        "text": "Behold the Sugarium Coronet, fit for fashionable and common wights alike. A decadent masterpiece served with a side of envy."
    },
    {
        "name": "Comfortable Celebration Bottoms",
        "text": "Step into these plush and stylish bottoms any time there is cause to rejoice."
    },
    {
        "name": "Stiff Formal Top",
        "text": "An elegant but conservative top to suit formal engagements or discussions."
    },
    {
        "name": "Lowrium Helm",
        "text": "The Lowrium Helm glistens in the light, beckoning you to don it and fight towards victory! For the intrepid adventurers."
    },
    {
        "name": "Grinblatium Spiked Vambrace",
        "text": "Crafted from rare Grinblatium, this spiked vambrace will be the envy of all adventurers. Wear it with pride and let the spikes do the talking!"
    },
    {
        "name": "Space trousers",
        "text": "Looking for a stylish addition to your galactic wardrobe? Try our Viewscorp Simulcast Space Trousers! These will decay in mere days, but who's to complain?"
    },
    {
        "name": "Woolen Informal Hat",
        "text": "Are you tired of looking like a boring hackmill drone? Spice things up with this fuzzy, stylish Woolen Informal Hat! A symbol of rebellion and individuality."
    },
    {
        "name": "Cotton Formal Bottoms",
        "text": "Crafted from the fine cotton, these formal bottoms will make you the sharpest dresser in the Gliese-verse."
    },
    {
        "name": "Tarnium Crescent Plate Skirt",
        "text": "Behold the shimmering Tarnium Crescent Plate Skirt, crafted to perfection from the exquisite Tarnium metal. Add a touch of grandeur to your game character with this marvel of modern metalwork."
    },
    {
        "name": "Tarnium Crescent Vambrace",
        "text": "Adorn your arm with allure with the Tarnium Crescent Vambrace. Foes will tremble at the sight."
    },
    {
        "name": "Exuperium Mail Skirt",
        "text": "Behold, the formidable Exuperium Mail Skirt - forged using the legendary metal of the same name. Don't mess with the fighter clad in this beauty."
    },
    {
        "name": "Lowrium Splint Armour",
        "text": "Are you tired of being bruised and battered in combat? Try Lowrium Splint Armour, tough enough to withstand even the fiercest of battles."
    },
    {
        "name": "Exuperium Boots",
        "text": "Behold the Exuperium boots! For when you need to fly higher, jump farther, and kick harder. Made for the daring and ambitious. Wear with caution."
    },
    {
        "name": "Lowrium Boots",
        "text": "Step into the future with the sleek and stylish Lowrium Boots! Glide through any terrain with grace and ease. Don't settle for less."
    },
    {
        "name": "Hide Work Top",
        "text": "The Hide Work Top - a simple torso protector for a beginner tailor. It does the job."
    },
    {
        "name": "Arm Band",
        "text": "Embroidered with colourful thread, the simple woven band reminds you of home."
    },
    {
        "name": "Elkinkium Mask Helmet",
        "text": "Protect your noggin with Elkinkium Mask Helmet, the shining shield of the fearless! Don't settle for less than this durable helm."
    },
    {
        "name": "Space shoes",
        "text": "These clompers from Viewscorp don't quite fit right, but they're better than walking around barefoot. Right? Right."
    },
    {
        "name": "Tarnium Spiked Helmet",
        "text": "Defy your foes with the gleaming Tarnium Spiked Helmet, the perfect blend of style and defense. You'll be the envy of all your fellow warriors."
    },
    {
        "name": "Grinblatium Spiked Greaves",
        "text": "Intimidate foes and strut in style with Grinblatium Spiked Greaves: the ultimate footwear for those who mean serious business!"
    },
    {
        "name": "Padded Celebration Bottoms",
        "text": "Buoy up your jubilance with Padded Celebration Bottoms! Now with extra fluff to cushion your victory dances. Don't settle for less glam!"
    },
    {
        "name": "Bejewelled Informal Shoes",
        "text": "Are you tired of clunky, uncomfortable footwear? Slip into the opulence of Bejewelled Informal Shoes. Comfort never looked so fabulous!"
    },
    {
        "name": "Tolkium Boots",
        "text": "Strap on the power of Tolkium and stride into battle like a hero. Long-lasting and durable, these boots will take you to the deepest caverns and back."
    },
    {
        "name": "Tolkium Great Helm",
        "text": "Behold the Tolkium Great Helm, sturdy and commanding. Perfect for intimidating enemies or impressing potential suitors. Get yours today!"
    },
    {
        "name": "Elkinkium Moulded Vambrace",
        "text": "Step forth in style with the Elkinkium Moulded Vambrace! Crafted from the finest materials, protect thy arm with swagger."
    },
    {
        "name": "Comfortable Shoes",
        "text": "Step lively in our simply-solded Comfortable Shoes- the perfect choice for wandering and exploring!"
    },
    {
        "name": "Octavium Boots",
        "text": "Step up your game with Octavium boots - the perfect fusion of style and strength. Feel invincible with every stride you take. Trust me, your enemies will cower in fear."
    },
    {
        "name": "Leather Formal Top",
        "text": "The tailor's go-to for a sleek and sturdy formal top. For dungeons or deals, wear this for a little extra padding."
    },
    {
        "name": "Woolen Work Bottoms",
        "text": "Stay cozy and stylish with the Woolen Work Bottoms. Perfect for long hours of hard work, or simply lounging in comfort."
    },
    {
        "name": "Cotton Work Bottoms",
        "text": "Functional and ultra-durable Cotton Work Bottoms. Great for long days trying to figure out how anyone lived without brainchip implants!"
    },
    {
        "name": "Leather Work Hat",
        "text": "The Leather Work Hat: a reliable and stylish accessory for any adventurer. Perfect for repelling the harsh rays of the sun or hiding a bad hair day."
    },
    {
        "name": "Octavium Gaiters",
        "text": "Introducing Octavium Gaiters: hardy shoes that will give your feet extra protection on the battlefield."
    },
    {
        "name": "Matsunium Chestplate",
        "text": "Behold the Matsunium Chestplate, as strong and unyielding as the metal itself. Protection for those who dare to test the edges of the known world."
    },
    {
        "name": "Embroidered Informal Top",
        "text": "Looking for a top that's equal parts style and comfort? Try our Embroidered Informal Top! The detailed embroidery adds a touch of elegance, while the lightweight fabric ensures maximum breathability."
    },
    {
        "name": "Janssium Boots",
        "text": "Looking for a pair of boots that'll make you feel like you could conquer anything? Look no further than Janssium boots! Ultra-lightweight yet super durable, these boots are perfect for traversing any terrain."
    },
    {
        "name": "Zacharium Pentagram Filligree Vambrace",
        "text": "Are you prepared to embrace the burning power of the Zacharium Pentagram Filligree Vambrace? Only for the truest of warriors who seek to conquer all that lays ahead."
    },
    {
        "name": "Arm Strap",
        "text": "Equip your bicep with some extra protection and muscle support with a simple Arm Strap."
    },
    {
        "name": "Waldium Vambrace",
        "text": "Crafted from the elusive Waldium, this vambrace glimmers in the light like a starry night. An accessory for warriors seeking to add a touch of elegance to their battle attire."
    },
    {
        "name": "Moebium Boots",
        "text": "Have you got your hands on some Moebium? Craft this sturdy and reinforced footwear for explorers in need of stronger stuff."
    },
    {
        "name": "Elkinkium Muscle Cuirass",
        "text": "The Elkinkium Muscle Cuirass is a sight to behold, with its lustrous golden finish and intricate engravings."
    },
    {
        "name": "Waldium Horned Helmet",
        "text": "Behold the fearsome Waldium Horned Helmet! The perfect headgear for those who desire to intimidate their foes with their impeccable sense of style."
    },
    {
        "name": "Moebium Coif",
        "text": "Make a statement with the Moebium Coif! Fashion, function, and futuristic flair all in one in this hardy helmet."
    },
    {
        "name": "Cloth Formal Bottoms",
        "text": "Looking to impress at a fancy gathering? Look no further than our Cloth Formal Bottoms! Sleek and stylish, you'll be the envy of all."
    },
    {
        "name": "Janssium Scale Plate Skirt",
        "text": "Adorn your lower half with the shimmering beauty of the Janssium Scale Plate Skirt, a true sign of fashion consciousness."
    },
    {
        "name": "Sugarium Vembrace",
        "text": "The Sugarium Vembrace: A stylish wrist accessory for additional protection against cut or sprain."
    },
    {
        "name": "Work Hat",
        "text": "A well-crafted Work Hat will protect you from the sun, untoward attack, and imbue your work with a spark of creativity."
    },
    {
        "name": "Smart Celebration Top",
        "text": "Behold the Smart Celebration Top, crafted for only the flashy wights who wish to make a statement. Display your wealth and resources with this stunning top."
    },
    {
        "name": "Octavium Pig Snout Helmet",
        "text": "Protect your noggin' in style with the Octavium Pig Snout Helmet. Guaranteed to intimidate your foes and impress your allies. Oink oink, baby."
    },
    {
        "name": "Octavium Star Plate",
        "text": "Behold the unmatched power of the rare and coveted Octavium Star Plate - gorgeously crafted to conquer all foes. Join the elite few who possess this unparalleled armor!"
    },
    {
        "name": "Grinblatium spiked armour",
        "text": "Unleash your inner warrior with the sharp and deadly Grinblatium spike armour. Don't be just another dull blade on the battlefield!"
    },
    {
        "name": "Sugarium Hauberk",
        "text": "The Sugarium Hauberk, a sturdy piece of armour spun from rare metals that speak of a daring adveturer and master tailor."
    },
    {
        "name": "Matsunium Kettle Helm",
        "text": "Protect your brain with the sturdy Matsunium Kettle Helm. Perfect for halting attack and looking magnificent."
    },
    {
        "name": "Tolkium Shynbalds",
        "text": "Unleash the power of Tolkium with the Shynbalds - sturdy equipment fit for this rare metal."
    },
    {
        "name": "Backpack suit",
        "text": "This innovative suit has pockets sewn into its sleeves and ribbing, expanding your inventory substantially at the cost of its poor defenses."
    },
    {
        "name": "Informal Top",
        "text": "A top to wear for casual instances, imbued with local flavour from the tailor at hand."
    },
    {
        "name": "Hide Work Hat",
        "text": "Behold, the Hide Work Hat! A clothesperson's first forays into crafting will produce this simple yes necessary headpiece."
    },
    {
        "name": "Matsunium Greaves",
        "text": "Fallen foes will tremble at your feet with the Matsunium Greaves. Sturdy and sleek, they'll be your trusted companion during battles."
    },
    {
        "name": "Elkinkium Moulded Greaves",
        "text": "Protect your legs in style with Elkinkium Moulded Greaves! Fashionable and strong, the envy of all your foes."
    },
    {
        "name": "Pot Hat",
        "text": "This advanced piece of headwear won't protect you but it will allow you to balance a good chunk of your inventory on your head, saving you space and time."
    },
    {
        "name": "Elkinkium Boots",
        "text": "Slip on these sleek Elkinkium Boots for enhanced agility and speed. Perfect for stealth missions or impressing your friends."
    },
    {
        "name": "Ursulium Vembrace",
        "text": "Protect yourself with the sturdy Ursulium Vembrace - the perfect blend of style and functionality. No foe stands a chance!"
    },
    {
        "name": "Bucklewium Horned Vambrace",
        "text": "Adorn your arms with the majesty of Bucklewium Horned Vambrace, an extra bit of protection that adds up against larger enemies."
    },
    {
        "name": "Bucklewium Bearded Helmet",
        "text": "A rugged and ferocious helm, the Bucklewium Bearded Helmet is reinforced with rare metal inlays making it strong and dependable."
    },
    {
        "name": "Zacharium Boots",
        "text": "Are you tired of your boring old shoes? Try Zacharium Boots! They are stylish, durable, and out of this world! No more boring footwear for you!"
    },
    {
        "name": "Tolkium Brynie",
        "text": "Indulge in the metallic delight of a Tolkium Brynie, infused with one of the rarer minerals known to Gliese."
    },
    {
        "name": "Tarnium Crescent Plate",
        "text": "Feast thy eyes upon the Tarnium Crescent Plate, a solid piece of bulky armour with a unique gleam. It is clunky but impenetrable."
    },
    {
        "name": "Zacharium Pentagram Plate",
        "text": "The Zacharium Pentagram Plate is a reinforced armour that is lined with a soft inner and a hard shell-like outer that will protect you from cuts and bruises."
    },
    {
        "name": "Celebration Hat",
        "text": "Behold! The glimmering Celebration Hat with woven detailing that speaks of a dedicated craftsperson. Don it on a night of merriment!"
    },
    {
        "name": "Exuperium Chain Mail",
        "text": "Adorn yourself with the gleaming Exuperium Chain Mail, fit for a warrior of the ages. Its intricate design will leave enemies dazzled and defeated!"
    },
    {
        "name": "Sugarium Shin Guards",
        "text": "Protect your shins with style in Sugarium Shin Guards! Infuses with Sugarium, they protect against sprain and pain."
    },
    {
        "name": "Ursulium Beak Helmet",
        "text": "Protect your head in style with the Ursulium Beak Helmet! Fierce and functional, this helmet will make you the envy of the town."
    },
    {
        "name": "Zacharium Pentagram Plate Skirt",
        "text": "Behold the Zacharium Pentagram Plate Skirt - celestial armour that'll have your enemies begging for mercy!"
    },
    {
        "name": "Formal Bottoms",
        "text": "Simple trousers for formal occasions. The look is reminiscent of the craftperson's home town."
    },
    {
        "name": "Janssium Nasal Helm",
        "text": "Protect your precious schnoz with the sleek and stylish Janssium Nasal Helm - perfect for the fashion-conscious warrior with a flair for danger."
    },
    {
        "name": "Large Informal Hat",
        "text": "Introducing the Informal Hat- a hat with a slightly too large brim, it catches the eye but may be a little obnoxious. Perfect for the eccentric and daring."
    },
    {
        "name": "Comfortable Celebration Hat",
        "text": "Behold the Comfortable Celebration Hat! Adorn your head with this masterpiece and bask in the glory of the party."
    },
    {
        "name": "Smart Formal Shoes",
        "text": "Step into simple elegance with these Smart Formal Shoes, perfect for any occasion."
    },
    {
        "name": "Zacharium Open Face Helm",
        "text": "Behold the Zacharium Open Face Helm! An iconic symbol of strength and courage. Strike fear into your foes while staying protected in style."
    },
    {
        "name": "Bucklewium Horned Greaves",
        "text": "Are you seeking protection and style for your lower legs? Introducing the Bucklewium Horned Greaves: sharp, sleek and perfect for any adventurer."
    },
    {
        "name": "Exuperium Sugarloaf Helm",
        "text": "Protect thy noggin in sweet style with the Exuperium Sugarloaf Helm. A treat for any voyager worth their Matsunium."
    },
    {
        "name": "Moebium Vambrace",
        "text": "Wear the Moebium Vambrace and glean the power of this rare metal. It's sleek, shiny, and oh-so stylish. Trust me, you'll be turning heads."
    },
    {
        "name": "Celebration Top",
        "text": "Behold the Celebration Top! Its glittering design will put a smile on even the most dour of companions."
    },
    {
        "name": "Embroidered Formal Hat",
        "text": "Unleash your inner aristocrat with this Embroidered Formal Hat- perfect for commanding attention."
    },
    {
        "name": "Comfortable Informal Bottoms",
        "text": "Sink into serenity with these plush and casual Comfortable Informal Bottoms. Great for lounging while you think up your next masterwork."
    },
    {
        "name": "Octavium Vembrace",
        "text": "Behold the Octavium Vembrace - forged in the fires of creativity, unmatched in style. Equip it now and witness the envy of all who behold it."
    },
    {
        "name": "Smart Celebration Hat",
        "text": "Introducing the Smart Celebration Hat - the go-to accessory for every jubilant occasion."
    },
    {
        "name": "Waldium Boots",
        "text": "These Waldium Boots will clad your feet in this rare metal and elevate your step and stance."
    },
    {
        "name": "Waldium Disc Plate Skirt",
        "text": "Twirling metal inlays decorate the Waldium Disc Plate Skirt, a mesmerizing mix of elegance and durability. Don't settle for less."
    },
    {
        "name": "Comfortable Celebration Top",
        "text": "Celebrate in style with this sleek and sassy Comfortable Celebration Top. Perfect for any occasion, this top will make you the star of the show!"
    },
    {
        "name": "Informal Bottoms",
        "text": "Slip into something comfortable, like these breezy bottoms. Perfect for days at the tavern, discussing your town's next moves."
    },
    {
        "name": "Work Top",
        "text": "A simple top for a day's work no matter the profession. No time to waste, time to grind."
    },
    {
        "name": "Matsunium Frilled Vembrace",
        "text": "Behold, the Matsunium Frilled Vembrace! A delightful blend of fashion and function. Stylishly protect your forearms from attacks."
    },
    {
        "name": "Grinblatium Barbute Helmet",
        "text": "Behold the fierce Grinblatium Barbute Helmet, imbued with the strength of its namesake metal, forged deep under Gliese."
    },
    {
        "name": "Embroidered Informal Bottoms",
        "text": "Bring some style to your lounge sesh with these flowery, intricately embroidered bottoms. Perfect for slouching your way through the day."
    },
    {
        "name": "Ursulium Mirror Plate Skirt",
        "text": "The Ursulium Mirror Plate Skirt is a shimmering piece of armour that will dazzle on the battlefield."
    },
    {
        "name": "Moebium Square Plate Skirt",
        "text": "The Moebium Square Plate Skirt: stylish, sleek, and perfect for the fashionable adventurer looking to turn heads AND deflect blows."
    },
    {
        "name": "Janssium scale plate",
        "text": "A sturdy plated armour piece that will protect you from the elements and the untoward. Crafted by the hands of an advanced tailor for certain!"
    },
    {
        "name": "Tolkium Vembrace",
        "text": "Dress up your arm with the shimmering power of Tolkium. Become a true hero and show everyone who's boss with the Tolkium Vembrace!"
    },
    {
        "name": "Exuperium Engraved Vembrace",
        "text": "Unleash your inner warrior with the Exuperium Engraved Vembrace - as fierce and strong as the toughest of battles. Beware, enemies tremble in its presence."
    },
    {
        "name": "Ursulium Mirror Plate",
        "text": "Behold your true form with the shimmering power of Ursulium Mirror Plate - the ultimate accessory for the adventurous!"
    },
    {
        "name": "Formal Hat",
        "text": "The resplendent Formal Hat is perfect for impressing fellow townsfolk and wooing your interests. Made with a classy sheen and local flavour."
    },
    {
        "name": "Moebium square plate",
        "text": "Behold the majestic Moebium square plate! It's beauty unparalleled and its durability unmatched amongst its peers."
    },
    {
        "name": "Bucklewium Horned Armour",
        "text": "Defy your enemies with the strength of Bucklewium, adorning the fierce horns of this armour. Don't just strike fear, leave a lasting impression."
    },
    {
        "name": "Formal Top",
        "text": "Behold the regal Formal Top! Its intricate local design and intriguing hues shall entrance all who behold it."
    },
    {
        "name": "Informal Hat",
        "text": "The Informal Hat is perfect for running errands, fighting enemies, or plumbing the depths of Gliese. It has an effortless look."
    },
    {
        "name": "Lowrium Vembrace",
        "text": "Introducing the Lowrium Vembrace - an armed accessory for those who mean business in the battlefield. Let the power of Lowrium fuel your victory!"
    },
    {
        "name": "Large Formal Hat",
        "text": "Adorn your cranium with our formidable Large Formal Hat. Guaranteed to elevate your status at special events."
    },
    {
        "name": "Tarnium Boots",
        "text": "Behold the mighty Tarnium Boots, crafted from the finest materials from the depths of Gliese. These boots were made for walking, and also for felling foes. They'll keep your feet dry and stylish as you journey through the land."
    },
    {
        "name": "Comfortable Formal Shoes",
        "text": "Step up your style game with these exquisite Waldium formal shoes. Perfect for navigating both boardrooms and ballrooms alike."
    },
    {
        "name": "Bucklewium Boots",
        "text": "Infuse your step with this rare metals from the lower caverns of Gliese and craft these Bucklewium Boots! A foot protecting piece of strong armour."
    },
    {
        "name": "Hammer",
        "text": "Crafted from sturdy wood, the basic but necessary Hammer can be used to make buildingworks like walls and doors. Equip it and press SPACE to open the crafting menu or press SHIFT and a direction to improve existing buildingworks, provided you have the correct ingredients."
    },
    {
        "name": "Pickaxe",
        "text": "Ah, the Pickaxe. A fine tool for gathering minerals. This item consists of a long wooden or metal handle, with a curved or pointed metal head on one end. Equip the pickaxe and press SHIFT and a direction to extract mineral wealth in the caverns under Gliese."
    },
    {
        "name": "Seed Planter",
        "text": "The Seed Planter is a tool used for planting seeds in the soil. The planter has a long handle with a cylindrical head that narrows to a point, ready to distribute seeds in your inventory to fertile soil with a click of the SPACE bar."
    },
    {
        "name": "Hoe",
        "text": "Ah the Hoe, a timeless tool for any seasoned gardener or farmer. This sturdy instrument is long and slender, with a sharp, flat end ideal for tilling the soil. Press SPACE on a seed or plant to tend it and advance its growth."
    },
    {
        "name": "Hand Plough",
        "text": "The gleaming Hand Plough is perfect for cultivating small plots of land to be turned into farmland. Equip the plough and press SPACE on the spot you want to prepare for seeds and get back to your agrarian roots!"
    },
    {
        "name": "Tent Pole",
        "text": "Sally forth, brave souls! Gliese awaits, in all its many dangerous biomes. The Tent Pole allows you to quickly and efficiently set up or put away camp, providing a quick shelter in hostile terrain."
    },
    {
        "name": "Primitive Sledgehammer",
        "text": "To wield the Primitive Sledgehammer is to embody the spirit of chaos and destruction- a force to be reckoned with. Equip the sledgehammer and press SHIFT and a direction to destroy buildingworks that don't agree with you. Limited use."
    },
    {
        "name": "Axe",
        "text": "Tree chopping at its finest. Equip the Axe and swing by pressing SHIFT and a direction to fell trees and branches that stand in your way."
    },
    {
        "name": "Sickle",
        "text": "Introducing the Sickle: perfect for harvesting crops. Its sleek design and sharp edge make it a must-have for any adventurer."
    },
    {
        "name": "Primitive Axe",
        "text": "This crude tool appears to have been crafted hastily and with little finesse, its rough handle unevenly attached to a simple wooden head. Equip the axe and press SHIFT and a direction to fell trees and branches but beware of its limited use. "
    },
    {
        "name": "Sledgehammer",
        "text": "With its sturdy handle and weighty head, this tool is designed to crush anything in its path. Walls, doors, floors, and even workshops are no match for the force of the sledgehammer. Equip the sledgehammer and press SHIFT and a direction to destroy nearby buildingworks. Let the destruction begin!"
    },
    {
        "name": "Bridle",
        "text": "The Bridle is a tool used for leading beasts of burden. It is comprised of a headstall that fits around the animal's head with a bit in the mouth for control and ropes that will coax the creature into following your lead. Equip the bridle and press SPACE near a creature to use."
    },
    {
        "name": "Spade",
        "text": "A tool of simple utility, it features a broad flat blade perfect for digging and moving earth. Equip the spade and press SPACE to bury remains."
    },
    {
        "name": "Primitive Plough",
        "text": "A crudely fashioned tool made of wood with leather grips. Equip the plough and press SPACE on the spot you want to prepare for seeds, but beware the primitive plough's limited use."
    },
    {
        "name": "Scythe",
        "text": " A tool with a long curved blade, with a handle made for two hands to grip. The purpose of this tool is to gather crops, slicing through the stalks of various plants with a sweeping motion."
    },
    {
        "name": "Furniture Mover",
        "text": "Greetings, adventurer. As you peruse through the crafting menu, you come across the Furniture Mover, a tool of great utility for those traversing the peopled places of Gliese. The Furniture Mover, as its name suggests, is a device designed for moving furniture from one place to another. Equip the furniture mover and press SPACE to pick up or put down a piece of furniture."
    },
    {
        "name": "Torch",
        "text": "Ah, the torch. A simple yet essential tool for any adventurer. Its long wooden shaft is studded with flammable resin that ignites with a simple spark, casting a warm and flickering light on your surroundings. Higher craftsmanship will produce more sturdy torches, but even the most basic will repel Night Stalkers and light underground caverns."
    },
    {
        "name": "Brush Top",
        "text": "Somewhat scratchy, offers no protection, and you think there might be some insects crawling around on it. The brush top sure beats being naked."
    },
    {
        "name": "Brush Bottoms",
        "text": "What says 'it's my first day on Gliese' like a pair of charming hand crafted bottoms made of the finest scratchy plant fibres?"
    },
    {
        "name": "Paintbrush",
        "text": "The Paintbrush is the perfect tool for decorating walls and furniture with a wide array of colors and designs. The Paintbrush consists of a long, sturdy handle, with soft and delicate bristles at the end which distribute paint evenly across surfaces. Equip the paintbrush and press SPACE to paint or write on nearby items, provided you have some buckets of paint in your inventory."
    },
    {
        "name": "Primitive Pickaxe",
        "text": "Ah, the Primitive Pickaxe, a tool for those who seek to gather minerals in their endeavors. This pickaxe, while not as efficient as its more advanced counterparts, can certainly get the job done. Equip the pickaxe and press SHIFT and a direction to extract minerals but beware its limited use."
    },
    {
        "name": "Primitive Hoe",
        "text": "Ah, the Primitive Hoe! An agricultural implement crafted in the finest traditions of the pre-KI world. Its rough-hewn handle and blade are less durable than usual but it can still tend your growing crops. Press SPACE on a seed or plant to tend it and advance its growth."
    },
    {
        "name": "Reins",
        "text": "The Reins are a utilitarian creation fashioned to serve the dual purpose of riding upon a beast of burden and towing a wagon in its wake. The reins themselves are crafted from sturdy material, secured tightly to a contoured grip for maximum control."
    },
    {
        "name": "Saddle",
        "text": "The Saddle is an intricate piece of gear that fits comfortably upon a beast of burden, enabling selection of the said beast as your riding mount. The saddle features well-crafted straps and buckles, designed to keep the rider sturdy and secure as they explore the untamed wilderness."
    },
    {
        "name": "Pickled Night Stalker Food",
        "text": "Are you in the mood for a tangy and savory adventure? Sink your teeth into the Pickled Night Stalker Food- a haunting delicacy unique to the nighttime adventurer."
    },
    {
        "name": "Pickled Sluggish Food",
        "text": "Indulge in the tangy, slimy goodness of our Pickled Sluggish Food. Your taste buds won't know what hit them! Dare to be adventurous?"
    },
    {
        "name": "Vegetable Soup Food",
        "text": "A delightful blend of fresh and preserved vegetables, simmered to perfection in a rich broth made in long hours on the hob."
    },
    {
        "name": "Flax Bread Food",
        "text": "Indulge in the tangy and hearty Flax Bread, made with pounded flaxseed. A crunchy and filling delight!"
    },
    {
        "name": "Mulberry Food",
        "text": "Tantalizing mulberry delicacy, rich with spices. A feast for the senses!"
    },
    {
        "name": "Turnip Food",
        "text": "Behold, the humble turnip dressed in savory spices and roasted to perfection. A comfort food fit for royalty!"
    },
    {
        "name": "Wolf Pie Food",
        "text": "Are you ready to indulge in a culinary adventure? Try our savory and succulent Wolf Pie, made with an odd mix of foraged greens."
    },
    {
        "name": "Rusty Soup Food",
        "text": "A slightly metallic tasting meat, cooked slowly over the hearth."
    },
    {
        "name": "Oat Bread Food",
        "text": "Indulge in this healthy load made with pounded oat seed and baked to a crunchy finish."
    },
    {
        "name": "Kohlrabi Food",
        "text": "Crispy Kohlrabi Fritters, served with a savory dipping sauce. Are you quite sure you can resist?"
    },
    {
        "name": "Sweet Potato Food",
        "text": "Glazed sweet potatoe - a treat so divine, it'll make you forget the sorry state of life back home."
    },
    {
        "name": "Magma Croc Soup Food",
        "text": "A fiery soup with pungent spices and tender crocodile meat that makes your cheeks flush and eyes water."
    },
    {
        "name": "Vegetable Pie Food",
        "text": "A heart-warming and nostalgic slice of veggie pie! Loaded with fresh local greens to power you onwards."
    },
    {
        "name": "Brown Bear Stew Food",
        "text": "A slow-cooked meat delicacy from a long-forgotten world. Smells irresistible."
    },
    {
        "name": "Pickled Meat Food",
        "text": "Tangy, tongue-tingling pickled meats that preserve well and make your eyes water."
    },
    {
        "name": "Cooked Maize Food",
        "text": "A dish made of pounded and balled maize topped with a hot sauce."
    },
    {
        "name": "Fish Gruel Food",
        "text": "Sink your teeth into the slimy goodness of our Fish Gruel- a delicacy for adventurers of the untamed element."
    },
    {
        "name": "Blackbearberry Food",
        "text": "A succulent and delectable treat of sugar-coated berries mixed with nuts. Trust us, your taste buds will thank you."
    },
    {
        "name": "Aoe Stew Food",
        "text": "Fiery Aoe Stew: Made with pepper-infused meats and limp vegetables. Caution: may cause temporary blindness."
    },
    {
        "name": "Fish Soup Food",
        "text": "Indulge in bounty from the untamed element with a warm and tangy fish soup, simmered on low with a drizzle of citrus tang."
    },
    {
        "name": "Pickled Web Food",
        "text": "Spicy and tangy preserved Pickled Web Food. It'll leave you craving for more. Don't believe us? Try it yourself."
    },
    {
        "name": "Horn Blower Soup Food",
        "text": "A piping hot bowl of Horn Blower Soup - a rich broth simmered to perfection."
    },
    {
        "name": "Swapper Pie Food",
        "text": "A thickly salted meat topped with a tangy twist and served inside a golden crust."
    },
    {
        "name": "Meat Soup Food",
        "text": "A hearty soup made from fine cuts that are grilled first and then simmered with greens. Try it now!"
    },
    {
        "name": "Slime Beast Stew Food",
        "text": "A gooey, yet oddly delectable stew . Your taste buds will dance the earthy taste of Slime Beast Meat."
    },
    {
        "name": "Meat Stew Food",
        "text": "A Meat Stew made from prime cuts from your hunts, with toasted nuts to top off a rich broth."
    },
    {
        "name": "Pickled Halver Food",
        "text": "A tangy-sour delight crafted with fine vinegared Halver meat, a modest delicacy for the refined palate."
    },
    {
        "name": "Wheat Bread Food",
        "text": "Indulge in the comforting warmth of a simple wheat bread, filled with the sweetness of the ancient grains. A true taste of simpler times."
    },
    {
        "name": "Cooked Sweet Potato Food",
        "text": "Caramelised and rich sweet potato cooked on an open flame and smothered with butter. A delicious treat for any occasion."
    },
    {
        "name": "Polar Bear Pie Food",
        "text": "A flaky crust filled with succulent Polar Bear meat, glazed with a honey-like substance. Don't hibernate on this one!"
    },
    {
        "name": "Shooter Pie Food",
        "text": "An intensely flavourful pie that's a blend of smoked Shooter meat and a lightly pounded and doughy crust."
    },
    {
        "name": "Troll Stew Food",
        "text": "Hearty and filling, the Troll Stew is a medley of root vegetables and Troll meat."
    },
    {
        "name": "Pickled Tiger Food",
        "text": "Sink your teeth into the bold and tangy flavor of our Pickled Tiger dish. It's a jungle out there, but this meal is worth the hunt."
    },
    {
        "name": "Fish Pie Food",
        "text": "Indulge in a heaping serving of Fish Pie- a hint of the untamed element layered over comforting potato. Your taste buds will thank you. Are you quite sure?"
    },
    {
        "name": "Leopard Pie Food",
        "text": "Your taste buds will prowl with pleasure as you indulge in our Leopard Pie- a wild and savoury delicacy."
    },
    {
        "name": "Fire Stew Food",
        "text": "Zesty flames dance with tender chunks of meat, greens and grains. The perfect antidote for a mundane existence."
    },
    {
        "name": "Cooked Turnip Food",
        "text": "Nourish your soul with a Roasted Turnip, served with a side of tangy sauce. A culinary delight you won't soon forget."
    },
    {
        "name": "Goosefoot Food",
        "text": "Kick up your taste buds with the mouthwatering Goosefoot compote- the tart berries sing in a creamy sauce."
    },
    {
        "name": "Meat Gruel Food",
        "text": "The savoury yet dubious flavor of the Meat Gruel beckons. Guaranteed to satisfy your hunger, if not your taste buds."
    },
    {
        "name": "Vegetable Stew Food",
        "text": "A warm and hearty vegetable stew, crafted with the freshest produce and aromatic herbs. A bowl full of comfort and nourishment."
    },
    {
        "name": "Phaser Soup Food",
        "text": "A zesty and electrifying blend of Phaser meat and broth with diced chunks of vegetable that tingle the tongue."
    },
    {
        "name": "Life Leech Stew Food",
        "text": "Indulge in our infamous Life Leech Stew. Guaranteed to drain the life right out of you...in a good way. Come die happy!"
    },
    {
        "name": "Meat Pie Food",
        "text": "Sink your teeth into a classic Meat Pie! Filled with savory mystery meats and cooked to perfection using Tarnium ovens. Satisfaction guaranteed."
    },
    {
        "name": "Kraken Soup Food",
        "text": "A rich, savory broth filled with chunks of tentacled terror. Will leave you hooked."
    },
    {
        "name": "Crowberry Food",
        "text": "Are you tired of bland cooked meats? Indulge in the juicy, tart flavour of crowberries, the ultimate snack for the adventurous."
    },
    {
        "name": "Pickled Deeperer Food",
        "text": "A tantalizing blend of tangy pickled Deeperer meats that will elevate your senses to a new level."
    },
    {
        "name": "Teleporter Soup Food",
        "text": "Introducing the revolutionary Teleporter Soup- a fusion of zesty Teleporter meat and spices brought together through quantum entanglement."
    },
    {
        "name": "Black Bear Stew Food",
        "text": "Indulge in our hearty Black Bear Stew, complete with tender chunks of meat in a rich, savory broth. Perfect for chilly Gliese nights. Are you quite ready for a culinary adventure?"
    },
    {
        "name": "Maize Food",
        "text": "Indulge in the sweetness of glazed maize served with a dash of cinnamon bark, for a touch of home."
    },
    {
        "name": "Scary Soup Food",
        "text": "Food that is not for the faint of heart. Meats stewed at high temperatures to diffuse the fright and served carefully in safe locations."
    },
    {
        "name": "Snapjaw Stew Food",
        "text": "Indulge in the savoury flavours of stew made from long roasted meats. A simple meal for those in a hurry."
    },
    {
        "name": "Swarmer Pie Food",
        "text": "Ah, the Swarmer Pie- a delicacy from a bygone era. Sweet, succulent and oh so gooey! Taste the nostalgia, one bite at a time."
    },
    {
        "name": "Bean Food",
        "text": "A crunchy standard bean salad."
    },
    {
        "name": "Lingonberry Food",
        "text": "A tangy and sweet Lingonberry Parfait that's sure to leave your taste buds singing!"
    },
    {
        "name": "Cooked Fish Food",
        "text": "The succulent flesh of grilled fish, seasoned with a secret blend of spices. Satisfy that fishy craving today!"
    },
    {
        "name": "Cooked Kohlrabi Food",
        "text": "Out-of-this-world flavours sealed with a Lowrium glaze, Cooked Kohlrabi Food is a dish fit for the deities."
    },
    {
        "name": "Pickled Blind Food",
        "text": "Behold the Pickled Blind Food, a true sensory adventure! Consume at your own risk, but trust me, it's worth it."
    },
    {
        "name": "Vegetable Gruel Food",
        "text": "A savoury and oddly textured vegetable gruel! Nutrient-rich and sure to leave you feeling somewhay satisfied."
    },
    {
        "name": "Fish Stew Food",
        "text": "Indulge in the hearty flavors of seasoned fish, simmered to perfection in a bone broth."
    },
    {
        "name": "Poisoner Soup Food",
        "text": "Deceptively delicious soup with an edge of danger from the smoked Poisoner meats within. Perfect for those with daring tastebuds and a strong gut."
    },
    {
        "name": "Cooked Beans Food",
        "text": "A meagre combination of protein and salt that's edible to say the least."
    },
    {
        "name": "Cooked Goosefoot Food",
        "text": "Delectable goosefoot berries sprinkled with powdered sugar and lashings of heavy cream."
    },
    {
        "name": "Death Song Soup Food",
        "text": "You defeated it, now you can eat it! Death Song meats deliciously broiled to an otherworldly decadence that will hopefully not foretell your own demise."
    },
    {
        "name": "Lion Pie Food",
        "text": "Tender meat encased in a crisp green flour crust. Trust us, it's worth the risk you took in fighting that lion!"
    },
    {
        "name": "Banana Food",
        "text": "A delectable whipped banana dish, plated to perfection."
    },
    {
        "name": "Phased Shooter Pie Food",
        "text": "Go on. You killed it, now you should eat it! The tangy taste of Phased Shooter meat baked into a golden crust. One bite and you'll forget about the humdrum existence you call a life."
    },
    {
        "name": "Pickled Vegetable Food",
        "text": "Indulge in this tangy mix of preserved watery greens. Goes perfectly with any dish!"
    },
    {
        "name": "Pickled Jaguar Food",
        "text": "Sour and pickled Jaguar meat marinated in a secret blend spices. Trust us, it's fiercely addictive."
    },
    {
        "name": "Mango Food",
        "text": "Sweet and succulent, cut chunks of mango and fruit compote tossed together in a salad will tantalize your taste buds."
    },
    {
        "name": "Disarmer Pie Food",
        "text": "Disarm your tastebuds with our sweet and zesty Disarmer Pie. Filled with smoked Disarmer meat, you'll forget those swords you lost back then and smile with delight."
    },
    {
        "name": "Pickled Fish Food",
        "text": "Are you tired of bland meals? Try our tangy pickled fish, made with strong lowland vinegar and spices. Your taste buds will thank you!"
    },
    {
        "name": "Cooked Meat Food",
        "text": "Thin cuts of meat cooked simply by the aching hands of a weary traveller. Don't worry, it will fill you up."
    },
    {
        "name": "Pickled Acid Food",
        "text": "A sour symphony of pickled acid food, guaranteed to make your eyes water and your mouth drool. Try it now!"
    },
    {
        "name": "Barley Bread Food",
        "text": "Looking for a hearty meal? Nothing beats our Barley Bread! Filled with the rich taste pounded barley seed, it'll leave you satisfied for hours."
    },
    {
        "name": "Pickled Beastie Food",
        "text": "Have you ever longed for a meal that bites back? The Pickled Beastie Food is a culinary creature comfort that's both drool-worthy and ferociously flavourful."
    },
    {
        "name": "Cloudberry Food",
        "text": "The tart yet sweet taste of the elusive pink Cloudberry melds with clotted cream in this simple dessert. A rare treat for the interpid voyager."
    },
    {
        "name": "Marble Bust",
        "text": "Behold, a visage of some important hero or villian frozen in smooth, white stone. Honor the masters who crafted this marble treasure."
    },
    {
        "name": "Limestone Bust",
        "text": "A stunning limestone bust- a testament to classic artistry. Stare into the eyes of this important fellow and imagine yourself as timeless as they."
    },
    {
        "name": "Granite Bust",
        "text": "Ah the magnificent granite bust, an homage to a person of great import hewn handily from stone."
    },
    {
        "name": "Teleporter",
        "text": "You have only this device and your own poorly-made choices to thank for your current situation. Check this Teleporter to view the parameters of its settlement."
    },
    {
        "name": "Bench",
        "text": "What's not to love about a simple bench? Take a seat and ponder your next move. Endless options lie before you."
    },
    {
        "name": "Table",
        "text": "It's got four legs and holds foods, friends, and endless town gossip. Press SPACE on a table to start a private discussion with up to three others at a time."
    },
    {
        "name": "Limestone Column",
        "text": "A tall spindle that seems quite grandiose."
    },
    {
        "name": "Basalt Bust",
        "text": "A bust with the visage and shoulders of someone who we vowed to not forget. Who is this person?"
    },
    {
        "name": "Shop Sign",
        "text": "A simple sign for displaying information, locations, or names in an inviting manner. Attaches and hangs from a wall."
    },
    {
        "name": "Statue",
        "text": "A small stone colossus, the full body of some hero. Crafted in the local style by a master stoneworker."
    },
    {
        "name": "Marble Obelisk",
        "text": "A pointed obelisk crafted of marble that spears the sky and marks a time, a place, a moment. Small marks on its surface tell a story."
    },
    {
        "name": "Trough",
        "text": "A simple stone watering carrier for creatures in need of a quaff."
    },
    {
        "name": "Town Bell",
        "text": "Behold the Town Bell! A symbol of community, but also an eerie reminder that time walks quickly onwards on Gliese. Ring it to mark a special occasion."
    },
    {
        "name": "Basalt Column",
        "text": "An opulent column hewn from a single slab of basalt rock. The volcanic rock has been shaped perfectly to a smooth and orderly rounded shape."
    },
    {
        "name": "Comfy Bed",
        "text": "Rest your weary body on frame and soft cloth that won't let you down. Made in the local style of this region's craft."
    },
    {
        "name": "Planter",
        "text": "A carved plant trough with drainage holes for growing greens in places they wouldn't normally flourish."
    },
    {
        "name": "Bed",
        "text": "A simple slab of cloth on a frame of wood. Wherever you set yourself down can be home."
    },
    {
        "name": "Hide Rug",
        "text": "A soft rug fashioned out of animal hide into a warming and sound-dampening mat."
    },
    {
        "name": "Basalt Plinth",
        "text": "A short column upon which to display an important item, carved from basalt."
    },
    {
        "name": "Sign Post",
        "text": "A simple wooden standing sign post to mark the way or leave warning for other travellers."
    },
    {
        "name": "Sofa",
        "text": "A comforting and homely piece of furniture for collapsing on when you still need to stay awake. Made in the local style of craftsmanship."
    },
    {
        "name": "Fountain",
        "text": "A water feature central to the archaic world, you understand their appeal as you watch its water tumble gracefully and endlessly."
    },
    {
        "name": "Gravestone",
        "text": "A way to mark the fallen and show respect to the struggle of their lives. May their memory forever blow on the winds of Gliese."
    },
    {
        "name": "Desk",
        "text": "A simple desk to sit at that sparkling with excitement awaiting your next creative endeavour."
    },
    {
        "name": "Basalt Obelisk",
        "text": "A sentinel to mark an important occasion or denoate a story, carved from a dark volanic rock."
    },
    {
        "name": "Marble Column",
        "text": "A simple and classic marble column, faceted and topped with an elegant design."
    },
    {
        "name": "Granite Plinth",
        "text": "A sturdy granite plinth, short and round with a pedestal upon which to display important items."
    },
    {
        "name": "Terminal",
        "text": "Viewscorp Simulcast seems to have provided last decade's model. Well... If it works, it works I suppose. Use this to edit the local culture's information."
    },
    {
        "name": "Marble Plinth",
        "text": "Sturdy, beautiful, and ready to display fine treasures from your voyages. Are you quite impressed?"
    },
    {
        "name": "Plant Pot",
        "text": "Are you quite simply exhausted from living the same humdrum life day and night? Add some nature to your humble home with this stylish clay planter."
    },
    {
        "name": "Stone Bench",
        "text": "A sturdy, stoic seat that beckons you to take a load off your feet. Perfect for pensive pondering or sipping a favorite brew."
    },
    {
        "name": "Limestone Plinth",
        "text": "A short column with a flat top perfect for displaying trophies or interesting finds."
    },
    {
        "name": "Chair",
        "text": "A simple seat for when you are not making the world."
    },
    {
        "name": "Town Horn",
        "text": "Tired of not being noticed by the townsmen? Blast this horn and see every face turn towards you! Perfect for attention-seeking deaf people."
    },
    {
        "name": "Sun Dial",
        "text": "Mark the day by the rapid movements of Gliese's suns over the Sun Dial's carved surface. Night is falling soon, surely."
    },
    {
        "name": "Mile Stone",
        "text": "A simple sign to mark the way or give warning to others, carved from stone."
    },
    {
        "name": "Rug",
        "text": "A simple piece of room decor for warmth and comfort. Are you quite sure you don't want one?"
    },
    {
        "name": "Amethyst Nose Ring",
        "text": "Adorn thy nostrils with a jewel fit for a queen. The Amethyst Nose Ring- a spark of elegance in thy mundane existence."
    },
    {
        "name": "Amethyst and Diamond Band",
        "text": "Looking for an accessory that's both classy and a little bit sassy? Look no further than our Amethyst and Diamond band! This piece is sure to turn heads and add a touch of whimsy to any outfit."
    },
    {
        "name": "Garnet Nose Ring",
        "text": "Wear this fiery Garnet Nose Ring and let it spark conversations hotter than its gleam. Perfect for rebels and poets alike."
    },
    {
        "name": "Ruby Neck Torque",
        "text": "The Ruby Neck Torque- a bold statement of your arrival. Flaunt your finds with the striking crimson jewel."
    },
    {
        "name": "Citrine Neck Torque",
        "text": "A radiant Citrine Neck Torque, hand crafted and glowing with a fine finish. Get ready to be the envy of Gliese."
    },
    {
        "name": "Garnet and Aquamarine Band",
        "text": "A fiery passionate blend of two jewels that's fit for battle and socialising. Make your choice, adventurer."
    },
    {
        "name": "Garnet and Ruby Mask",
        "text": "A brilliant bejewelled mask for allure."
    },
    {
        "name": "Ruby Nose Ring",
        "text": "A rebellious and fiery red nose ring, a small accessory that says a lot."
    },
    {
        "name": "Moonstone Nose Ring",
        "text": "Adorn your nose with celestial elegance and let your inner star shine."
    },
    {
        "name": "Amber Neck Torque",
        "text": "This Amber Neck Torque is not just a piece of jewelry, it's a statement. Let the vibrant amber shine bright as you walk the ways of Gliese."
    },
    {
        "name": "Emerald and Turquoise Mask",
        "text": "A vibrant fusion of oceanic hues and sparkling gems. Perfect for those who dare to stand out!"
    },
    {
        "name": "Diamond Neck Torque",
        "text": "Radiate elegance with the Diamond Neck Torque, an adornment for those who crave luxury and status. Claim your place at the top."
    },
    {
        "name": "Peridot and Moonstone Band",
        "text": "A jeweled tapestry weaved to capture the essence of cosmic wonder."
    },
    {
        "name": "Turquoise and Opal Band",
        "text": "Adorn yourself with a rare and dazzling accessory that sets you apart from the hoi polloi. A true fashionista's choice!"
    },
    {
        "name": "Amethyst and Peridot Mask",
        "text": "Sparkling and mysterious, the Amethyst and Peridot mask will make you the envy of all your foes. The perfect accessory for the stylish explorer!"
    },
    {
        "name": "Amber Nose Ring",
        "text": "Add some zing to your nose bling with this amber stunner. Perfect for those who like their edginess with a side of warmth. Get it now!"
    },
    {
        "name": "Sapphire Neck Torque",
        "text": "A majestic jewel of muted elegance- perfect for dominating both the tavern table and the battlefield!"
    },
    {
        "name": "Peridot Neck Torque",
        "text": "A bold statement of power & prestige. Wear it to exude confidence & show off your bold nature."
    },
    {
        "name": "Garnet Neck Torque",
        "text": "A regal statement piece fit for any would-be conqueror. Flaunt your power with pride."
    },
    {
        "name": "Peridot Nose Ring",
        "text": "A band of metal adorned with a shimmering Peridot gem. A must-have for those seeking a touch of elegance."
    },
    {
        "name": "Amethyst Neck Torque",
        "text": "A regal neckpiece that's as mesmerizing as it is elegant. Dare to dazzle your foes!"
    },
    {
        "name": "Sapphire Nose Ring",
        "text": "A brilliant blue gem set in a thin band clasped to your nose. Indulge in luxury, darling."
    },
    {
        "name": "Aquamarine Nose Ring",
        "text": "Feeling the need to stand out from the crowd? Adorn your nostril with the Aquamarine Nose Ring, the perfect accessory for those who dare to be bold."
    },
    {
        "name": "Citrine and Sapphire Band",
        "text": "A stunning and shimmering piece that speaks of sun and sea."
    },
    {
        "name": "Diamond Nose Ring",
        "text": "Adorn your nose with this sparkling Diamond Nose Ring – a jewel that will make you feel like the kings of yore."
    },
    {
        "name": "Opal Nose Ring",
        "text": "A unique lustrous gem inlaid on a shining metal band."
    },
    {
        "name": "Diamond and Citrine Mask",
        "text": "A dazzling mask adorned with shimmering citrine and polished diamond. Hide your intentions whether in battle or whilst driving a bargain."
    },
    {
        "name": "Aquamarine and Sapphire Mask",
        "text": "The shimmering Aquamarine and Sapphire Mask's deep blue hues are evocative of infinite starry skies."
    },
    {
        "name": "Turquoise Neck Torque",
        "text": "A prideful inlaid gemstone necklace. Wear it and fear no challengers."
    },
    {
        "name": "Moonstone and Amber Mask",
        "text": "A mask that shimmers, giving you an ethereal and radiant presence."
    },
    {
        "name": "Opal Neck Torque",
        "text": "Adorn your neck with the hypnotic shimmer of the Opal Neck Torque. Perfect for mesmerizing your foes or trying to impress your friends."
    },
    {
        "name": "Moonstone Neck Torque",
        "text": "A neck adornment with a mystical charm from its lustrous pink gems."
    },
    {
        "name": "Turquoise Nose Ring",
        "text": "Adorn yourself with a stunning Turquoise Nose Ring, a bold statement piece that exudes confidence and flair. Are you quite sure you can handle it?"
    },
    {
        "name": "Emerald Nose Ring",
        "text": "Are you quite simply exhausted from the lack of bling? Add flare to your profile with an Emerald Nose Ring. A must-have for any fashionista."
    },
    {
        "name": "Aquamarine Neck Torque",
        "text": "Behold the glorious Aquamarine Neck Torque - the perfect accent for any stylish adventurer! Its delicate curves will leave others envious."
    },
    {
        "name": "Citrine Nose Ring",
        "text": "Are you looking for a touch of elegance? The Citrine Nose Ring has it all: style, glam, and the flair you need to stand out."
    },
    {
        "name": "Emerald Neck Torque",
        "text": "With its luminous sheen and intricate design, the Emerald Neck Torque, glimmers with a verdant glow."
    },
    {
        "name": "Door Key",
        "text": "Unlock the corresponding lock with this key and make sure not to lose it."
    },
    {
        "name": "Wooden Door Barricade",
        "text": "A strong barricade that is sturdier than your average door lock. Just don't lose the key."
    },
    {
        "name": "Door Lock",
        "text": "A safe and secure metal lock to attach to a door. Do not lose the corresponding key or you will be left in the cold."
    },
    {
        "name": "Door Bolt",
        "text": "A strong door bolt to keep even the most determined intruders at bay. When there is a cutpurse about, settle for nothing less."
    },
    {
        "name": "Seed",
        "text": "A small parcel of verdant hope. Put it in fertile ground, tend it, and see what happens."
    },
    {
        "name": "Meat",
        "text": "A cut of muscle and fat from a felled beast. Cook it in a kitchen to make it safe to eat."
    },
    {
        "name": "Teal Paint",
        "text": "Teal, the colour of adventure and extravagance. Let your paintbrush speak of teal splendour."
    },
    {
        "name": "Grey Paint",
        "text": "The dulcet tones of a grey that reminds you of a place you knew a long time ago in a galaxy far away."
    },
    {
        "name": "Raw Wood",
        "text": "Sturdy tree material for building walls, workshops, tools, and more."
    },
    {
        "name": "Pink Paint",
        "text": "A cheeky and cheerful colour that can be used to paint items or walls."
    },
    {
        "name": "Hide",
        "text": "Skin and fur from a felled beast. Use it in a tannery to create leather or bring it to a tailor's workshop to craft some basic clothing."
    },
    {
        "name": "Pale Yellow Paint",
        "text": "The pale yellow paint of a thousand sunrises, a hopeful and effervescent colour."
    },
    {
        "name": "Clay Beads",
        "text": "Delicately crafted clay beads that are useful in craftsworks."
    },
    {
        "name": "Vegetable",
        "text": "A chewy, fibrous and edible plant."
    },
    {
        "name": "Orange Paint",
        "text": "The fiery radiance of this orange paint will illuminate your creative works. Use it to paint walls or write missives with a sunny tone."
    },
    {
        "name": "Metal",
        "text": "A refined bar of mineral material."
    },
    {
        "name": "Brown Paint",
        "text": "A rich shade that is dark and robust. Use it to paint items or walls."
    },
    {
        "name": "Purple Paint",
        "text": "A smooth and rich colour used to evoke darker or more emotional thoughts."
    },
    {
        "name": "Leather",
        "text": "A tough and durable material made from the hides of felled beasts. Use it in fine craftsworks and special tailoring projects."
    },
    {
        "name": "Charcoal",
        "text": "A piece of strongly heated wood that will ignite a spark in campfires and light a dark night."
    },
    {
        "name": "Wool",
        "text": "A soft animal fabric that can be spun into yarn and fashioned into various soft and warming clothing for cold weather adventures."
    },
    {
        "name": "Sky Blue Paint",
        "text": "A radiant and hopeful colour that evokes a crisp and clear winter's morning. Paint or write messages that evoke the airy feeling of a clear sky."
    },
    {
        "name": "Pale Blue Paint",
        "text": "A calm colour reminiscent of the softest breeze on a clear day. A serene colour for messages or creative projects."
    },
    {
        "name": "Linen Cloth",
        "text": "Crafted from natural plant material, Linen Cloth can be used in tailoring and other craftsworks."
    },
    {
        "name": "Fish",
        "text": "Bloop blee bloop."
    },
    {
        "name": "Yellow Paint",
        "text": "A golden and zesty coloured paint for creative and written endeavours."
    },
    {
        "name": "Dark Green Paint",
        "text": "A defiant colour of the dark jungle and stalwart growth. Use it on walls or in your other creative projects."
    },
    {
        "name": "Brush",
        "text": "A small bristle-tipped instrument for painting walls and items. Equip it and press SHIFT plus a direction while holding paint to add your missives to an item."
    },
    {
        "name": "Slate Paint",
        "text": "A colour of revenge and raw emotion. Use with a paintbrush to add a grey hue to items or walls."
    },
    {
        "name": "Baby Pink Paint",
        "text": "A delicate shade of pink to add levity to even the most mundane of objects."
    },
    {
        "name": "Blue Paint",
        "text": "A simple and timeless colour which can be painted onto objects or walls with a paintbrush."
    },
    {
        "name": "Mauve Paint",
        "text": "A rich and velvety mauve paint to add a little decadence to objects or walls."
    },
    {
        "name": "Pale Green Paint",
        "text": "A soft hue that soothes nerves and eases febrile minds. Use a paintbrush and paint your walls and items!"
    },
    {
        "name": "Green Paint",
        "text": "Refreshing and invigorating, let this evocative colour transport you to verdant lands with a flick of your paintbrush."
    },
    {
        "name": "Sunflower Orange Paint",
        "text": "A fiery and bold colour that is warming and cheerful. Paint messages on walls or change the colour of items."
    },
    {
        "name": "Straw",
        "text": "Cut plant matter than can be used to make very simple tools."
    },
    {
        "name": "Raw Cotton",
        "text": "Unprocessed cotton, plucked from the plant itself and ready to be spun into a durable and soft material to be tailored."
    },
    {
        "name": "Stone",
        "text": "Mineral from the earth that is ready to be carved or purposed into sturdy crafts material by a skilled mason."
    },
    {
        "name": "Cotton Cloth",
        "text": "A softly textured cloth for fashioning into comfortable wears or soft accessories."
    },
    {
        "name": "Red Paint",
        "text": "A warm and classic colour ready to spead your message or other creative project with a handy paintbrush."
    },
    {
        "name": "Matsunium",
        "text": "Behold the magnificent Matsunium! The rare metal that sings when struck with force. It's perfect for musical instruments and epic battles."
    },
    {
        "name": "Zacharium",
        "text": "The exquisite Zacharium is perfect for crafting high-quality and reliable weapons and tools. Its metallic hue glimmers with a radiance unlike any other."
    },
    {
        "name": "Lowrium",
        "text": "Lowrium has unmatched durability and makes for sturdy armour and weapons."
    },
    {
        "name": "Bucklewium",
        "text": "Bucklewium is a rare metal found on deep subgliessanean levels which can be used to craft stronger blades and hardier armour pieces."
    },
    {
        "name": "Tolkium",
        "text": "Tolkium: The tinkling sound of this rare metal is as precious as its luminous, almost otherworldly glow. Extract some, and craft with confidence."
    },
    {
        "name": "Janssium",
        "text": "Crafted from a very early age in Gliese's pre-life history, Janssium ore is ancient and difficult to break."
    },
    {
        "name": "Silver",
        "text": "Sparkling like a starry night with a mirrored sheen, Silver is a classic material for jewelry and weapon components alike."
    },
    {
        "name": "Moebium",
        "text": "Mysterious and elusive, the Moebium is a rare and valuable metal that will leave you feeling like a treasure hunter on a quest."
    },
    {
        "name": "Waldium",
        "text": "Indulge in the rare and precious Waldium, and add its fiery red hues and to your armour and weapons craft."
    },
    {
        "name": "Grinblatium",
        "text": "An unstable and unpredictable metal that is difficult but rewarding to work with."
    },
    {
        "name": "Tarnium",
        "text": "Gleaming Tarnium, a rare metal from the depths of Gliese that shatters easily but is strong once stabilised."
    },
    {
        "name": "Lead",
        "text": "A classic dark material for diverse mason crafts."
    },
    {
        "name": "Sugarium",
        "text": "A rare mineral with warm earth hues that can be fashioned into weapons or protection in the hands of a skilled craftsperson."
    },
    {
        "name": "Ursulium",
        "text": "Are you exhausted from grinding for the best armor? Look no further, Ursulium is the answer. The ultimate metal for legendary gear."
    },
    {
        "name": "Gold",
        "text": "A shimmering metal fit for champions and conquerors. It's one of the heaviest materials on Gliese."
    },
    {
        "name": "Elkinkium",
        "text": "Rare, valuable, and elegant. It shines like a star and is often associated with luxury and nobility."
    },
    {
        "name": "Octavium",
        "text": "A glitzy, pink-hued high-end metal that would make a perfect sword in the safe and learned hand of a master forgeperson."
    },
    {
        "name": "Limestone",
        "text": "Limestone is a sturdy choice for the budding stonemason and can be a component in many projects and craftsworks."
    },
    {
        "name": "Sapphire",
        "text": "A deep blue precious gem that swirls with a dazzlingly rich hue."
    },
    {
        "name": "Emerald",
        "text": "A glittering green gem that shines resplendently."
    },
    {
        "name": "Octavium Ore",
        "text": "A rare metal with pink hues that is produces weapons and armour of the highest quality."
    },
    {
        "name": "Matsunium Ore",
        "text": "An elusive ore that radiates a warm lustrous hue that mimics the sun."
    },
    {
        "name": "Citrine",
        "text": "Sparkling like a thousand suns, Citrine is a cheerful gemstone for jewelryworks."
    },
    {
        "name": "Salt",
        "text": "For when you need to add that extra kick to your dish."
    },
    {
        "name": "Ruby",
        "text": "A fierce and unyielding gem that intimidates and impresses for use in jewelryworks."
    },
    {
        "name": "Sugarium Ore",
        "text": "A rare find in the caverns, this can be extracted and crafted with difficulty into either weapons or protective gear."
    },
    {
        "name": "Turquoise",
        "text": "A lustrous gem that has swirling blue and green patterning on it. A jeweller would be able to craft it into a wearable piece."
    },
    {
        "name": "Moebium Ore",
        "text": "A rare ore in warm hues that is valued for its resistance to heat. Handle with care."
    },
    {
        "name": "Exuperium Ore",
        "text": "Exuperium is the gleaming treasure of the stars. Forge it into powerful weapons and armour under a master craftsperson."
    },
    {
        "name": "Garnet",
        "text": "The fiery Garnet, a gem to be turned into a wearble piece."
    },
    {
        "name": "Lead Ore",
        "text": "Extract lead for a practical material that any simple mason could make use of."
    },
    {
        "name": "Tolkium Ore",
        "text": "A delicate green hued material that can be extracted with a Pickaxe."
    },
    {
        "name": "Waldium Ore",
        "text": "A shimmering orange ore hides in this rock, ready to be extracted with a sturdy Pickaxe and fashioned into weaponry, armour, or indeed further pickaxes."
    },
    {
        "name": "Basalt",
        "text": "As sturdy as the mountains of Gliese, Basalt can be fashioned into various useful and creative object in a master craftsperson's hands."
    },
    {
        "name": "Amber",
        "text": "A blazing material from Gliese's pre-history. Fashion it into wearable items and take it on your voyages."
    },
    {
        "name": "Aquamarine",
        "text": "Gaze into the mesmerizing depths of the Aquamarine and marvel at the mysteries it holds. A true gem for the adventurous."
    },
    {
        "name": "Janssium Ore",
        "text": "Janssium Ore, hiding a rare precious metal that can be fashioned into fortified weapons and armour. Extract it with a Pickaxe."
    },
    {
        "name": "Claystone",
        "text": "The humble Claystone can be shaped in the hands of a master potter into various useful containers and items."
    },
    {
        "name": "Lowrium Ore",
        "text": "Lowrium Ore - a versatile metal that, once extracted, is perfect for crafting equipment."
    },
    {
        "name": "Opal",
        "text": "Behold the iridescent, multifaceted Opal! This gem radiates beauty and mystery with every angle. Perfect for any daring adventurer seeking their next treasure."
    },
    {
        "name": "Granite",
        "text": "A solid stone, fit for long-lasting buildingworks and various other items a mason can fashion."
    },
    {
        "name": "Moonstone",
        "text": "A shimmering pale gem aaid to bring joy and artistry to all who possess it. Not responsible for sudden bursts of creativity."
    },
    {
        "name": "Gold Ore",
        "text": "The highly-coveted Gold Ore that can either make you a fortune or burn all your inventory space. Gather it now!"
    },
    {
        "name": "Amethyst",
        "text": "Behold the purple magnificence of the Amethyst. A gemstone of nobility and elegance. Let it enliven your collection."
    },
    {
        "name": "Marble",
        "text": "Behold the swirling pattern in this ancient rock. Soft and easy to work with, a mason can fashion marble into many useful things."
    },
    {
        "name": "Diamond",
        "text": "A shimmering and rare jewel, the epitome of graceful design, coveted by elites and feared by foes."
    },
    {
        "name": "Peridot",
        "text": "Sparkling like an impish grin, Peridot is a gem for the playful souls. Its green hues will make you feel like a mischievous sprite."
    },
    {
        "name": "Silver Ore",
        "text": "The shimmering Silver Ore, a metal both elegant and sturdy. Perfect for crafting sleek weapons and equipment."
    },
    {
        "name": "Ursulium Ore",
        "text": "A rare mineral from the depths of Gliese with a dark blue hue. Extract it with a Pickaxe and fashion it in a forge."
    },
    {
        "name": "Sledge",
        "text": "Transportation for quick movement over snow and other difficult terrain. Travel with items on hand if you have a sturdy beast to pull at the harness."
    },
    {
        "name": "Raft",
        "text": "A simple raft. Float across impassable seas and rivers, but be wary when boating too close to shore."
    },
    {
        "name": "Wagon",
        "text": "Do you desire to traverse the inhospitable wilds with more items than you can carry and your trusty steed? The Wagon is perfect for long voyages into the unknown."
    },
    {
        "name": "IRRIGATION_CHANNEL",
        "text": "Quench your crops' thirst by engineering an Irrigation Channel that will expand your arable farmland."
    },
    {
        "name": "Canvas Sack",
        "text": "A utilitarian canvas sack with sturdy straps for all your gathering needs. Practical, reliable, and boring as heck."
    },
    {
        "name": "Ketch",
        "text": "Have you ever dreamt of sailing the high seas? Let the Ketch, sturdy and reliable as it is, be your vessel! Climb aboard and see what's out there, beyond the horizon."
    },
    {
        "name": "Schooner",
        "text": "Behold the majestic Schooner! Faster than a hawk, smoother than silk, and stronger than Matsunium. Get ready to sail away into adventure!"
    },
    {
        "name": "Cutter",
        "text": "Are you tired of slow and clunky ships? Upgrade to the Cutter! Sleek design and swift handling make this ship a must-have for any space-faring adventurer."
    },
    {
        "name": "Regen Imbuing Pot",
        "text": "Behold the Regen Imbuing Pot - a bubbling brew of healing and vitality! Drink up and feel your wounds fade away. Tastes like sweet victory!"
    },
    {
        "name": "Mailbox",
        "text": "Behold the mystical mailbox, a portal to the unknown, a glimmer of hope in this bleak world. Check it daily for treasures and surprises."
    },
    {
        "name": "Bedside Table",
        "text": "A simple construction for holding your necessities as you sleep, fashioned in the unique style of the local carpenter."
    },
    {
        "name": "Brig",
        "text": "Step aboard the pride of Matsunium fleet - the Brig. She's sturdy, handsome, and packs a punch. Get your crew and set sail, Captain!"
    },
    {
        "name": "Book",
        "text": "Pages bound together to contain a story. Once crafted, you will be able to write your tome and share it with your fellow townsfolk."
    },
    {
        "name": "Book Shelf",
        "text": "A resting place for tomes and tales waiting to be discovered, crafted in the style of the carpenter's home region."
    },
    {
        "name": "Small sail boat",
        "text": "Ahoy there, matey! Ready to set sail on your very own mini adventure? This small sailboat will take you on a voyage you won't forget!"
    },
    {
        "name": "Outrigger canoe",
        "text": "Ahoy there, adventurer! Use this small watercraft to navigate rivers and seas but be wary of boating too close to shore, as dangerous enemies will not hesitate to take advantage of your mistake."
    },
    {
        "name": "Gigantic Pot",
        "text": "Feast your eyes on the gargantuan beauty that is the Pot of Plenty! Big enough to serve an army, this pot combines style with substance."
    },
    {
        "name": "Hand Cart",
        "text": "Sturdy yet stylish, the Hand Cart is the perfect companion for your travels. Load it up with your precious cargo and hit the road - adventure awaits!"
    },
    {
        "name": "Small Sealed Pot",
        "text": "With its glossy finish and intricate etchings, this pot is perfect for storing your precious herbs and spices. Plus, it's so cute!"
    },
    {
        "name": "Luminous Pot",
        "text": "Behold the Luminous Pot: a marvel of pottery. Illuminate your path as you cook with style!"
    },
    {
        "name": "Sack",
        "text": "A simple sack, ready to be filled with items. Or perhaps there's something inside already. Why don't you check?"
    },
    {
        "name": "Barrel",
        "text": "A wooden container for item or liquid storage."
    },
    {
        "name": "Wardrobe",
        "text": "A tall wooden container for holding your items, made in the fashion of the carpenter's local tradition."
    },
    {
        "name": "Display Case",
        "text": "A wonderful piece of wood craft that can be used to store especially important items proudly and prominently."
    },
    {
        "name": "Canoe",
        "text": "A simple boat with a paddle and sturdy hull. Use it to navigate the waterways but beware of dangerous foes on the shorelines."
    },
    {
        "name": "Horse Cart",
        "text": "A simple wooden cart, crafted in the fashion of the local carpenter. Attach to a pulling beast with use of a reins and direct them from your seat in the cart."
    },
    {
        "name": "Acid Imbuing Pot",
        "text": "Behold! The Acid Imbuing Pot, a golden cauldron of reckless power, perfect for those who enjoy a dash of acidity in their battles."
    },
    {
        "name": "Passenger Wagon",
        "text": "Looking to travel in style? Hop onboard our sleek and spacious passenger wagon featuring comfortable seating and stunning views."
    },
    {
        "name": "Massive Pot",
        "text": "Ladies and gentlemen, feast your eyes on this gargantuan pot! Large enough to cook up some Ursulium soup for your whole neighbourhood. Don't forget to add in a dash of Waldium for flavour!"
    },
    {
        "name": "ITEM",
        "text": "Behold the Moebium Mallet! This heavy metallic hammer packs a punch and can crush even the toughest of foes with ease. Get ready to swing for the fences!"
    },
    {
        "name": "Noticeboard",
        "text": "The Noticeboard, where town notices, missives, errant thoughts and bits of poetry can all be posted and shared. Crafted in the local style of the nearby woodworker."
    },
    {
        "name": "Sloop",
        "text": "Behold the sleek and nimble Sloop, perfect for daring sea-farers seeking adventure and fortune. With its compact size and agile handling, you'll be the envy of all on the high seas. So don't delay, set sail today!"
    },
    {
        "name": "Bird Table",
        "text": "A plateau upon which you hope to spot local wildlife, if enough tempting treats are left there for them."
    },
    {
        "name": "Bloodbound Pot",
        "text": "Crafted from the darkest depths of Ursulium, the Bloodbound Pot is an essential tool for the bloodthirsty warrior. Fill it with the essences of your defeated enemies and unleash their power in battle! Are you quite ready to wield such terrifying might?\""
    },
    {
        "name": "Galleon",
        "text": "Ahoy, mateys! Behold the Galleon, a magnificent vessel built for adventure and plundering. With sails as black as the deepest abyss and a crew as fierce as the Kraken, this ship will strike fear into the hearts of any who dare cross its path. She's armed to the teeth with Ursulium cannons and Lowrium cutlasses, ready to claim victory on the high seas. So hoist those sails and set a course for riches untold, for the Galleon awaits!"
    },
    {
        "name": "Sail canoe",
        "text": "Ahoy, me hearties! Set sail on a sleek canoe crafted from the finest Waldium. It glides effortlessly through the waves, promising adventure and discovery. Get ready to make waves!"
    },
    {
        "name": "Large Sealed Pot",
        "text": "As you gaze upon the Large Sealed Pot, you sense the weight of its contents. What secrets would you unearth if only you had the key? Open it now and find out!"
    },
    {
        "name": "Auto Pot",
        "text": "Quench your digital thirst with the miraculous Auto Pot, now available for your pleasure."
    },
    {
        "name": "Rowing boat",
        "text": "Ride the waves of freedom in the sleek and sturdy Bucklewium rowing boat. Perfect for a peaceful day on the lake or a daring escape from the clutches of corporate tyranny. Are you ready to row towards adventure? "
    },
    {
        "name": "Brigantine",
        "text": "Ahoy, matey! Come aboard the majestic Brigantine, an elegant two-masted vessel made of sturdy Grinblatium. Perfect for both trade and plunder."
    },
    {
        "name": "Minecart",
        "text": "Craftily constructed from sturdy metals, the Minecart is a trusty transport for your underground hauls. Ready to ride?!"
    },
    {
        "name": "Reverse Vending Machine",
        "text": "The Reverse Vending Machine - feed it your empty bottles, and earn rewards in return! Recycling never looked so good."
    },
    {
        "name": "Barge",
        "text": "Be the captain of your own fate with the sturdy and reliable Tarnium barge. Perfect for transporting goods and exploring unknown waters."
    },
    {
        "name": "Strength Imbuing Pot",
        "text": "Feel the rush of Matsunium-infused power with every sip of our Strength Imbuing Pot! Build muscle, crush foes, conquer all!"
    },
    {
        "name": "Freight Wagon",
        "text": "Behold the mighty Hauler! Clad in sturdy Zacharium plating and rolling on rugged Matsunium wheels, it is a colossus of the rail lines."
    },
    {
        "name": "FARM_PLOT",
        "text": "Embark on a journey of soil tilling, crop growing, and animal rearing on this quaint little plot of land. You'll be a farmer in no time"
    },
    {
        "name": "Invisibility Imbuing Pot",
        "text": "The Ghost's Goblet: Turn ethereal and disappear to your heart's content with this potent pot. Caution: avoid materializing within solid objects."
    },
    {
        "name": "Vending Machine",
        "text": "Behold the Snack-a-tron 9000 - a veritable smorgasbord of taste sensations! Satisfy your cravings with our array of futuristic treats."
    },
    {
        "name": "Detect Invisibility Imbuing Pot",
        "text": "Introducing the Spectre Sightful: Detect Invisibility Imbuing Pot! Gone are the days of being caught off guard by pesky invisible foes. Guzzle down this potion and see those cloaked rats for what they truly are."
    },
    {
        "name": "Armour Imbuing Pot",
        "text": "Behold the Armor Imbuing Pot - the perfect companion to enhance your gear for any situation. Disappointing loot drops?"
    },
    {
        "name": "Sealed Pot",
        "text": "Behold, the Sealed Pot of wonders! Crack it open and see what treasures await. May luck be on your side, dear adventurer."
    },
    {
        "name": "Touch Trace Pot",
        "text": "Have you ever wanted to feel like a wizard? Touch Trace Pot will make you feel like one."
    },
    {
        "name": "Calendar",
        "text": "The temporal labyrinth of schedules! A world of days, weeks, and months awaits you. A playground for planning and organization."
    },
    {
        "name": "Large Pot",
        "text": "A large pot spun on the wheel of a master potter that can contain items or liquids."
    },
    {
        "name": "Wooden Chest",
        "text": "Finished with a waterproof lacquer, this sturdy chest is perfect for storing your precious loot. Who knows what secrets and treasures it holds?"
    },
    {
        "name": "Caravel",
        "text": "Ahoy there! Ready to set sail on your stunning Caravel in search of adventure and treasure? Don't forget your compass and a barrel of Tolkium-infused grog!"
    },
    {
        "name": "Cupboard",
        "text": "Behold the Cupboard, a wondrous storage unit for items collected on your adventures!  Secure, spacious, stylish...and completely necessary."
    },
    {
        "name": "Clay Pot",
        "text": "A delightful hand thrown clay pot."
    },
    {
        "name": "Cage",
        "text": "A container with bars for keeping unfortunate creatures you may wish to punish."
    },
    {
        "name": "Chariot",
        "text": "Travel in style with this sleek ride and leave your enemies in the dust. Perfect for any daring adventurer."
    },
    {
        "name": "Web",
        "text": "A tanglement of thin and sticky fibers that cling to form an odd pattern."
    },
    {
        "name": "Lava",
        "text": "Rocks that have been melted into a streaming, roiling, angry river of destruction."
    },
    {
        "name": "Fire",
        "text": "Flickering flames that provide light and warmth."
    },
    {
        "name": "Acid",
        "text": "An evil liquid that stings to the touch."
    },
    {
        "name": "Galley",
        "text": "Feast your eyes upon the Galley, where culinary masterpieces sail into your belly. Choose wisely, or regret it deeply."
    },
    {
        "name": "Kayak",
        "text": "Behold the rugged and sleek \"Exuperium\" Kayak! Perfect for exploring the wilds of any planet with ease and style. Try it out now!"
    },
    {
        "name": "Water Walk Imbuing Pot",
        "text": "Embue your boots with the power of water and walk on the ocean's surface. Ready to make a splash?"
    },
    {
        "name": "Voting Machine",
        "text": "Experience the thrill of democracy with our sleek Voting Machine. Don't worry, we won't rig the results (probably)."
    },
    {
        "name": "Janssium Dagger",
        "text": "Sleek and agile, perfect for taking down your enemies with precision and style. This blade is a cut above the rest."
    },
    {
        "name": "Zacharium Dagger",
        "text": "Crafted from Zacharium, this dagger slices through flesh and armour with ease. Perfect for a dastardly backstab."
    },
    {
        "name": "Sugarium Axe",
        "text": "A light-weight yet sturdy design, to hack through enemies with ease."
    },
    {
        "name": "Elkinkium Spear",
        "text": "A weapon of elegance and power crafted from the glossy metal of deep Gliese. A must-have for battle and hunting enthusiasts alike."
    },
    {
        "name": "Zacharium Axe",
        "text": "A tool of destruction that can cleave through sturdy materials with ease. Perfect for those who crave power and spectacle."
    },
    {
        "name": "Tarnium Dagger",
        "text": "A weapon fit for the most cunning and agile of warriors. Razor sharp and lightweight, it's perfect for sneaky assassinations or quick dodges in battle."
    },
    {
        "name": "Lowrium Dagger",
        "text": "A delicate yet deadly weapon, perfect for the daring warrior looking for a little sparkle in their stab. Its gleam will leave your foes quivering in their inadequate boots."
    },
    {
        "name": "Waldium Dagger",
        "text": "A gleaming blade that's perfectly balanced for skilled hands. Flair? Of course."
    },
    {
        "name": "Sugarium Dagger",
        "text": "Simple yet sharp, the Sugarium Dagger will surely cut through your enemies like paper."
    },
    {
        "name": "Matsunium Dagger",
        "text": "A fearsome blade that's perfect for slicing through even the toughest foes. Fear not, dear traveller, for with this trusty blade on your side, victory is yours for the taking."
    },
    {
        "name": "Grinblatium Axe",
        "text": "A weapon of great strength and unfailing durability. Tremble, foes of the bearer!"
    },
    {
        "name": "Exuperium Axe",
        "text": "The Exuperium Axe is a durable and hardy axe that will withstand common wear and tear."
    },
    {
        "name": "Octavium Spear",
        "text": "The Octavium gleams with deadly precision. This weapon will strike fear into the hearts of any foe foolish enough to stand in your way."
    },
    {
        "name": "Elkinkium Sword",
        "text": "A sharp and stunning piece of weaponry forged from a rare mineral. Truly, the choice of a true knight in shining armor. Trust us, your enemies will not stand a chance against this masterpiece of metalwork."
    },
    {
        "name": "Tarnium Spear",
        "text": "Deadly and elegant, forged from one of Gliese's rarest materials. Strike fear into your enemies with this precious weapon."
    },
    {
        "name": "Lowrium Axe",
        "text": "This fierce one-handed chopper has a unique design and is resistant to wear."
    },
    {
        "name": "Octavium Dagger",
        "text": "Sharp enough to slice through tough armour and nimble enough to pass undetected. Don't fear death, embrace it."
    },
    {
        "name": "Ursulium Axe",
        "text": "A strong and unyielding axe fashioned out of Ursulium."
    },
    {
        "name": "Exuperium Spear",
        "text": "Burst through enemy lines with the Exuperium Spear- the perfect weapon for those who like a little flair in their conquests."
    },
    {
        "name": "Tolkium Spear",
        "text": "Crafted with precision and care. Feel its weight in your hand as you prepare for battle; wield it wisely."
    },
    {
        "name": "Grinblatium Spear",
        "text": "A shimmering weapon of legend, fit for brave warriors that will help turn the tide in battle."
    },
    {
        "name": "Tarnium Axe",
        "text": "A resilient axe fashioned from the deeply-mined and rare Tarnium."
    },
    {
        "name": "Waldium Sword",
        "text": "Its blade, sharper than your wit, can cut through steel like a hot knife through a loaf. With this weapon in hand, you'll take down foes with ease."
    },
    {
        "name": "Moebium Dagger",
        "text": "This Moebium Dagger has a unique design that makes feel alive as you gaze into its swirling patterns."
    },
    {
        "name": "Ursulium Spear",
        "text": "Crafted from a rare mineral, its sharp tip is perfect for puncturing even the toughest opponents. It's the ultimate weapon for those who seek glory in battle."
    },
    {
        "name": "Octavium Sword",
        "text": "Forged in the flames, it gleams with a radiant and otherworldly aura. The enchanted blade sings with each graceful swing."
    },
    {
        "name": "Grinblatium Dagger",
        "text": "Unleash the deadly elegance of the unique Grinblatium Dagger- perfect for those who fight with style AND substance."
    },
    {
        "name": "Tarnium Sword",
        "text": "Behold the Tarnium Sword, sharp enough to slice through steel and light as feather. Once you weild it, you'll never settle for less!"
    },
    {
        "name": "Lowrium Spear",
        "text": "The Lowrium Spear boasts unyielding durability, a practical choice for any warrior with a sense of style. Trust us, your enemies will feel the pain."
    },
    {
        "name": "Zacharium Sword",
        "text": "Sharper than a politician's tongue and gleaming like a sun-kissed glacier. Slice through your foes with ease, leaving them wondering what hit 'em."
    },
    {
        "name": "Waldium Spear",
        "text": "Looking for a weapon that can pierce through almost anything? Look no further than the Waldium Spear! Made from the rare and durable Waldium metal, this spear is sure to impress even the most hardened warrior."
    },
    {
        "name": "Moebium Sword",
        "text": "With a sharp edge and a bright glimmer the Moebium Sword will fell many a foe before it breaks at long last."
    },
    {
        "name": "Ursulium Dagger",
        "text": "Crafted from rare Ursulium, this deadly dagger glows a cold blue as it slices through enemies. Beware its wrath!"
    },
    {
        "name": "Sugarium Sword",
        "text": "Ah, the magnificent Sugarium Sword, crafted from the earthen rare mineral this sword will be a stalwart companion."
    },
    {
        "name": "Bucklewium Spear",
        "text": "Behold thee the Bucklewium Spear, as sturdy as the metal itself. Make thy enemies fear and quake, embrace thy destiny."
    },
    {
        "name": "Elkinkium Dagger",
        "text": "A weapon of elegance and precision! Perfect for piercing the hearts of foes."
    },
    {
        "name": "Octavium Axe",
        "text": "A strong axe made from the pink rare Gliese mineral that will last many blows."
    },
    {
        "name": "Tolkium Axe",
        "text": "This axe has brute strength with an otherworldly energy."
    },
    {
        "name": "Bucklewium Axe",
        "text": "Sharper than a fang and harder a Viewscorp editor. Get ready to chop some serious wood!"
    },
    {
        "name": "Pointy Stick",
        "text": "Thrust, poke, and prod your way to victory with the trusty Pointy Stick! Perfect for those who like to keep their enemies at arm's length."
    },
    {
        "name": "Bucklewium Dagger",
        "text": "Outshine your foes with the Bucklewium Dagger, an iridescent blade crafted to strike swiftly and accurately. A true sharpshooter's tool."
    },
    {
        "name": "Exuperium Dagger",
        "text": "A wickedly sharp blade, bound to quench your thirst for blood. Strike fear into your enemies with a weapon forged from the finest Exuperium."
    },
    {
        "name": "Sugarium Spear",
        "text": "An earthen spear with a penetrating tip that will conquer the battlefield again and again."
    },
    {
        "name": "Zacharium Spear",
        "text": "Unleash your inner warrior with the Zacharium Spear. Strike fear into the hearts of your enemies while wielding this durable and deadly marvel of craftsmanship."
    },
    {
        "name": "Grinblatium Sword",
        "text": "Designed to slice through toughest of foes with ease! Not responsible for unexpected limb loss."
    },
    {
        "name": "Matsunium Axe",
        "text": "Perfect for long days of repeated hacking and slashing and made from the most durale of materials."
    },
    {
        "name": "Waldium Axe",
        "text": "Crafted from the finest Waldium, this axe is a must-have for any adventurer who reaps the benefits of the forest."
    },
    {
        "name": "Janssium Spear",
        "text": "Crafted with expert precision, the Janssium spear pierces through even the toughest hides."
    },
    {
        "name": "Stone Spear",
        "text": "Are you quite simply exhausted from the usual high-tech weaponry? Grab a Stone Spear and embrace the archaic way."
    },
    {
        "name": "Moebium Axe",
        "text": "Speed and efficiency in one magnificent tool. Come prove your worth with this sharp beauty."
    },
    {
        "name": "Tolkium Sword",
        "text": "Crafted of Tolkium with fine precision to slice through your foes."
    },
    {
        "name": "Lowrium Sword",
        "text": "An elegant and strong blade of Lowrium is hilted perfectly with a comfortable grip."
    },
    {
        "name": "Moebium Spear",
        "text": "A sleek, weighty weapon crafted from Moebium and supported by a comfortable hilt. Rare and powerful."
    },
    {
        "name": "Bucklewium Sword",
        "text": "Crafted with precision and strength, ready to vanquish even the strongest of foes. A true weapon for the discerning warrior."
    },
    {
        "name": "Janssium Axe",
        "text": "An axe with a Janssium blade that can withstand many repeated chops to hard wood."
    },
    {
        "name": "Elkinkium Axe",
        "text": "Crafted with rare metals and hilted with a grip that's comfortable to hold, the Eklinkium Axe is durable and long-lasting."
    },
    {
        "name": "Ursulium Sword",
        "text": "A gleaming masterpiece crafted from a rare Gliese material. With its razor-sharp edge and perfect balance, it is batte ready."
    },
    {
        "name": "Janssium Sword",
        "text": "A razor-sharp Janssium blade sits on a a weighted hilt, ready for the battlefield."
    },
    {
        "name": "Exuperium Sword",
        "text": "A blade made of the finest Exuperium that can cleave through armour and defeat many foes before breaking."
    },
    {
        "name": "Matsunium Spear",
        "text": "Gentle warrior, thrust your way to victory with a Matsunium Spear; light, elegant, and fiercely sharp. Ha, your foes don't stand a chance!"
    },
    {
        "name": "Tolkium Dagger",
        "text": "A sharp blade belies exquisite craftsmanship and ensures your enemies a painful end. A must-have for any daring adventurer!"
    },
    {
        "name": "Matsunium Sword",
        "text": "Gleaming, lightweight Matsunium Sword - perfect for defeating your enemies and impressing your peers."
    },
    {
        "name": "Primitive Engraving Chisel",
        "text": "Carve messages and missives with this trusty engraving chisel. Use it with a Stone Tablet to etch your name into the history books."
    },
    {
        "name": "Wooden Stylus",
        "text": "Write down your legends and tales on Clay Tablets with this simply crafted tool. A true ode to elegance."
    },
    {
        "name": "Engraving Chisel",
        "text": "Crafted with precision from, write missives on Stone Tablets without fear of breakage."
    },
    {
        "name": "Wood Carving",
        "text": "A wooden carving that for creative woodworkers."
    },
    {
        "name": "Sign in Desk",
        "text": "Step forth, weary traveler, and register your presence at the Sign in Desk to let your prescence be known to the world."
    },
    {
        "name": "Stone Tablet",
        "text": "Behold! A stone tablet for carved missives and epics of all kinds."
    },
    {
        "name": "Hardened Clay Tablet",
        "text": "This Clay Tablet is ready for those with stylus in hand and a message to bring to the world."
    },
    {
        "name": "Clay Tablet",
        "text": "Record epics and stunning feats on the classic Clay Tablet with a stylus in hand and pass your tales on to future generations."
    },
    {
        "name": "Paper",
        "text": "A simple clean sheet of paper that awaits the written word."
    },
    {
        "name": "Sledge",
        "text": "Feel the rush of the frozen tundra with the hardy Sledge. With reins in hand a beast of burden can pull the Sledge faster through certain environments."
    },
    {
        "name": "Wagon",
        "text": "Travel in style with the sturdy and simple Wagon. Perfect for your long hauls and short trips."
    },
    {
        "name": "Minecart",
        "text": "Ride the rocky rails with your mineral haul at hand in a rugged Minecart. Perfect for aiding Gliese's spelunking adventurers."
    },
    {
        "name": "Seed Drill",
        "text": "Bring forth abundance with graceful efficiency and plant larger numbers of seeds on fertile soil."
    },
    {
        "name": "Hand Cart",
        "text": "For hauling your precious cargo over terrain with a larger invertory than your own."
    },
    {
        "name": "Horse cart",
        "text": "Transport yourself in the simple Horse cart. With Reins in hand, attach a pulling creature to the cart to go further, together."
    },
    {
        "name": "Freight Wagon",
        "text": "Transport large quantities with the sturdy design of the Freight Wagon. Made to withstand even the roughest terrains."
    },
    {
        "name": "Passenger Wagon",
        "text": "Step inside the Passenger Wagon for a comfortable journey and large inventory space."
    },
    {
        "name": "Plough",
        "text": "The Plough is a crucial item for agriculture on Gliese. Along with reins and a pulling beast, use the Plough to break up soils and produce large quantities of fertile ground for planting useful crops."
    },
    {
        "name": "Chariot",
        "text": "A sleek marvel of engineering that will take you to victory with unforgettable speed and power."
    },
    {
        "name": "Ketch",
        "text": "A Ketch, the perfect mix of class and function. Sail gracefully atop the waves with style and ease. The ocean awaits!"
    },
    {
        "name": "Schooner",
        "text": "Ahoy there! Behold the majestic Schooner - a sight to behold with a cargo capacity that matches its splendor. Set sail now and explore the vast seas!"
    },
    {
        "name": "Barge",
        "text": "Step aboard the sturdy barge, ready to brave the choppy waters ahead. A formidable ship for a fearless adventurer. Ahoy, matey!"
    },
    {
        "name": "Brig",
        "text": "The Brig: A dank and musty prison for the worst of the worst. Enter at your own risk or risk being locked up with the scum of the galaxy."
    },
    {
        "name": "Brigantine",
        "text": "Ahoy mateys! Set sail with the Brigantine, a versatile ship with two masts and enough firepower to take on any foes. Don't forget to stock up on grog!"
    },
    {
        "name": "Galleon",
        "text": "Ahoy mateys! This Galleon is your ticket to adventure on the high seas! With its wooden hull and Zacharium detailing, you'll feel like a true pirate. Set sail today!"
    },
    {
        "name": "Longboat",
        "text": "Brace yourself for battle on the open seas with the sturdily crafted Longboat - a vessel fit for a Viking king! Watch out for krakens and sea monsters, me hearties!"
    },
    {
        "name": "Outrigger canoe",
        "text": "Set sail on the serene waters of Gliese 581 with our sleek Outrigger canoe; perfect for the adventurous and those with a thirst for discovery. Don't miss out on this exhilarating experience!"
    },
    {
        "name": "Canoe",
        "text": "Transport yourself through the glistening waters of Gliese 581 in elegance with the sleek and sturdy canoe. Serenity awaits."
    },
    {
        "name": "Kayak",
        "text": "Embark on a daring expedition with a sleek and sturdy kayak. Glide through the riverways and seas on this small raft but beware straying too close to the water's edge when there are aggressive creatures nearby."
    },
    {
        "name": "Sloop",
        "text": "Take command of the sleek and speedy Sloop, perfect for the daring and adventurous. With its sturdy Zacharium hull, you'll cut through the seas with ease. Are you quite ready to set sail?"
    },
    {
        "name": "Cutter",
        "text": "In the deep depths of space, the Cutter reigns supreme. Quick, agile and always on the move, this ship is perfect for those who love a challenge. Soar through asteroid fields and take on your enemies head on. Are you up for it?"
    },
    {
        "name": "Small sail boat",
        "text": "Ahoy! Behold the charming small sailboat, the epitome of elegance and simplicity. Ready to take you on your next aquatic adventure!"
    },
    {
        "name": "Raft",
        "text": "A small watercraft fashioned out of laquered wood. Traverse both the high seas and the riverways of Gliese from the safety of four planks of wood."
    },
    {
        "name": "Sail canoe",
        "text": "Ahoy, matey! Sail the high seas in style with the sleek Sail Canoe - a nifty combo of classic sailing and sturdy canoeing! ARRR you ready?"
    },
    {
        "name": "Rowing boat",
        "text": "Glide through the shimmering waves on this sleek, silver Tarnium rowing boat. Perfect for a serene afternoon escape from the all-encompassing grind."
    },
    {
        "name": "Caravel",
        "text": "Ahoy there, mateys! Step aboard the Caravel, a sleek and sturdy vessel fit for the high seas. With ample storage and agile maneuvering, it's the perfect ship for adventurous souls who crave both speed and safety. And don't worry about sea monsters or pirate attacks – our crew is trained to handle any danger that may arise. Set sail and let the winds carry you to new horizons!"
    },
    {
        "name": "Galley",
        "text": "Feast your eyes, mateys! Taste the spoils of the sea with every dish. Our Galley has the best fish you'll ever wish! Choose wisely, or walk the plank."
    },
    {
        "name": "Wigwam",
        "text": "A tent made of straw and hide that"
    },
    {
        "name": "Sod House",
        "text": "Step back in time and experience pioneer life in a cozy sod house."
    },
    {
        "name": "Igloo",
        "text": "A surprisingly warm abode for travellers in the inhospitable far north and south."
    },
    {
        "name": "Ger",
        "text": "A temporary home for travellers who wish to live on the migration paths in Gliese."
    },
    {
        "name": "Tipi",
        "text": "A tent warmed by animal fur and reinforced by straw for a sturdy home whilst away from town."
    },
    {
        "name": "Chum",
        "text": ""
    },
    {
        "name": "Tent",
        "text": "A simple and snug linen canvas tent that can provide protection from the wilds of Gliese when you are away from town."
    },
    {
        "name": "Pit House",
        "text": ""
    },
    {
        "name": "Goati",
        "text": ""
    },
    {
        "name": "Lavvu",
        "text": "Step into the cozy embrace of a traditional Lavvu, complete with a crackling fireplace and intricate patterns adorning the walls. Camping never felt so regal!"
    },
    {
        "name": "Stonemason's",
        "text": "Craft exquisite and durable works of art from stone in the Stonemason's Workshop. Busts, pillars, and other larger pieces that benefit the town as a whole as well as crystal charms from Gliese's underground."
    },
    {
        "name": "Builder's Yard",
        "text": "Craft the most complex technologies in a Builder's Yard. Visit the Builder's Yard Workshop and see your ideas come to life. Let's get building!"
    },
    {
        "name": "Potter's",
        "text": "Throw clay on a spinning wheel and fire it in an oven to make beads, pots, planters, and other useful items."
    },
    {
        "name": "Shipwright",
        "text": "As you step into the Shipwright Workshop, the tangy smell of sawdust dances across your nose. The wooden hulls of unfinished ships line the walls, here are the bearths of vessels that will traverse the untamed element."
    },
    {
        "name": "Apothecary",
        "text": "A workshop for meticulously curated potions and elixirs."
    },
    {
        "name": "Tannery",
        "text": "A pungent workshop for turning animal hide into leather. Useful items can also be crafted here for working with pulling beasts."
    },
    {
        "name": "Mint",
        "text": "A workshop for minting coin for use in the local economy."
    },
    {
        "name": "Doctor's",
        "text": "A workshop for advanced technologies that will aid you on your quest."
    },
    {
        "name": "Dyer's",
        "text": "A workshop to extract the colours of Gliese's natural plant material and craft them into paints for use in creative projects."
    },
    {
        "name": "Woodcollier's",
        "text": "A space for crafting charcoal and torches to keep the dangerous Gliese nightfall best at bay."
    },
    {
        "name": "Kitchen",
        "text": "A workshop for crafting food goods out of all sorts of plant and animal matter found on or under Gliese."
    },
    {
        "name": "Jeweller's",
        "text": "Glimmering gems extracted from the caverns of Gliese are crafted into jewel masterpieces with precision and passion- welcome to the jeweller's workshop."
    },
    {
        "name": "Tailor's",
        "text": "A workshop for outfitting adventurers from head to toe with gear for work, play, or travel out of various soft materials."
    },
    {
        "name": "Blacksmith's",
        "text": "Step into the iron embrace of our blacksmith's forge. Hammerfall on beaten metal to produce weaponry, armour, and other important tools and items."
    },
    {
        "name": "Carpenter's",
        "text": "Woodworking provides a range of useful and handy tools that will help gather and procure materials in the local environment. The Carpenter's Workshop is a must for a beginning settlement."
    },
    {
        "name": "Dole Coin",
        "text": "Get rich quick scheme or ultimate downfall? Try your luck with Dole Coins...if you dare!"
    },
    {
        "name": "Detect Invisibility Brainchip Power",
        "text": "Unleash the power to see through the unseen. Detect InvisibilityBrainchip: because you can't always trust what's hiding in plain sight."
    },
    {
        "name": "Poison Immunity Brainchip Power",
        "text": "Guard your grey matter! The Poison Immunity Brainchip Power will keep you fresh and clear. My writing skills are unmatched!"
    },
    {
        "name": "Invisibility Brainchip Power",
        "text": "Poof! You're Gone! With the Invisibility Brainchip Power, you'll be able to surprise your foes and vanish from danger with ease. Get ready to play hide-and-seek like never before!"
    },
    {
        "name": "Acid Spitter Brainchip Power",
        "text": "Looking for the perfect weapon to melt your enemies' brains? Look no further than the Acid Spitter Brainchip Power! Guaranteed to cause chaos and confusion."
    },
    {
        "name": "Acid Resist Brainchip Power",
        "text": "Explore new horizons and rain acid on your enemies with the all-powerful Acid Resist Brainchip. Turn up the heat and let the games begin!"
    },
    {
        "name": "Blindness Resist Brainchip Power",
        "text": "See beyond sight with the BLND-RST Brainchip! Uncover secrets & avoid traps like a pro - all without the gift of vision. In short: blindness? No problem!"
    },
    {
        "name": "Cold Defence Brainchip Power",
        "text": "Introducing the Cold Defence Brainchip Power! Harness the power of Tarnium to freeze your enemies in their tracks! Guaranteed to chill your bones."
    },
    {
        "name": "Fire Breath Brainchip Power",
        "text": "Behold the Fire Breath Brainchip Power! Burn your enemies to a crisp with this explosive upgrade. Satisfaction guaranteed, or your credits back."
    },
    {
        "name": "Death Song Resist Brainchip Power",
        "text": "Defy Authority, Defeat Death: Death Song Resist Brainchip Power! - Challenge the status quo and unleash your rebellious spirit!"
    },
    {
        "name": "Night Vision Brainchip Power",
        "text": "Peek into the shadows of your enemies like a mischievous imp with the Night Vision Brainchip Power. They won't know what hit 'em!"
    },
    {
        "name": "Sluggish Resist Brainchip Power",
        "text": "Are you tired of being overstimulated? Try the Sluggish Resist Brainchip Power and embrace the slowness."
    },
    {
        "name": "Armour Boost Brainchip Power",
        "text": "Unleash your inner warrior with the Armour Boost Brainchip Power! Upgrade your mental fortitude and dominate the game in true Gliese style. Are you quite sure you have what it takes?"
    },
    {
        "name": "Strength Boost Brainchip Power",
        "text": "Unlock your hidden potential and dominate the competition. With the Strength Boost Brainchip Power, victory is within your grasp. Are you quite ready?"
    },
    {
        "name": "Undetectable Brainchip Power",
        "text": "Unlock hidden potential with the Undetectable Brainchip - the ultimate tool for outsmarting opponents. Don't just win, dominate."
    },
    {
        "name": "Web Spitter Brainchip Power",
        "text": "Unleash your mind's full potential with the Web Spitter Brainchip Power! Upgrade your brain to be faster than any computer, and dominate the game like a boss. Don't be left in the dust, get your hands on this game-changer now!"
    },
    {
        "name": "Heat Defence Brainchip Power",
        "text": "Protect your mind and body with the Heat Defence Brainchip Power. Because who wants to melt during this scorching apocalypse?"
    },
    {
        "name": "Web Resist Brainchip Power",
        "text": "Unleash your inner rebel with the Web Resist Brainchip Power - the ultimate tool for subverting the establishment. Dare to resist!"
    },
    {
        "name": "Prone Resist Brainchip Power",
        "text": "Unleash your inner rebel with Prone Resist Brainchip Power, the ultimate tool for those who dare to question authority. Defy the system and rise up!"
    },
    {
        "name": "Water Walk Brainchip Power",
        "text": "Experience the serene sensation of gliding on water with the Water Walk Brainchip Power. Dive into a world of aquatic bliss!"
    },
    {
        "name": "HP Regen Brainchip Power",
        "text": "Need a quick pick-me-up? Activate the HP Regen Brainchip Power for instant recovery!"
    },
    {
        "name": "Teleport Brainchip Power",
        "text": "Travel light years in mere seconds! The Teleport Brainchip Power - because who has time for the long haul? Get there fast, stay there longer."
    },
    {
        "name": "Accuracy Boost Brainchip Power",
        "text": "Are you quite certain that you want to improve your aim and slay your enemies with deadly precision? The Accuracy Boost Brainchip will bestow upon thee the gift of perfect accuracy, allowing even the dullest minds to hit their targets with ease. Don't miss out on this opportunity to dominate the battlefield."
    },
    {
        "name": "Vulnerable Resist Brainchip Power",
        "text": "Unleash the power of resistance with the Vulnerable Resist Brainchip - because sometimes being a little vulnerable is the strongest move. Get it now!"
    },
    {
        "name": "HP Boost Brainchip Power",
        "text": "Do you want to be a wizard of the virtual realm? Boost your brain and magical abilities with the HP Boost Brainchip Power!"
    },
    {
        "name": "Phase Brainchip Power",
        "text": "Upgrade your gray matter with a Phase Brainchip Power! Unleash your inner genius and become an unstoppable force. Trust me, I'm a writer."
    },
    {
        "name": "Metal Shield",
        "text": "A simple beaten metal shield that offers a higher defense than its wooden counterpart."
    },
    {
        "name": "Wooden Shield",
        "text": "A sturdy and durable Wooden Shield. The mark of a young adventurer, setting out for the first time."
    },
    
]