export const flaxSmellTypes = [
	"of dank water",
    "of damp cloth",
    "earthy",
    "floral",
    "herbal",
    "musky",
    "nutty",
    "floral",
    "sweet",
    "spicy",
    "musky",
    "of vanilla",
    "of coconut",
    "resinous",
    "of sweet dew",
    "of thick fog",
    "of falling asleep",
    "of crackling fireplace",
    "of overripe fruit",
    "spicy",
    "resinous",
    "balsamic",
    "aromatic",
    "fragrant",
    "perfumed",
    "sweet and musky",
    "nutty",
    "herbal",
    "green",
    "floral",
    "woody",
    "nutritional",
    "resinous",
    "of defenses",
    "ancient",
    "of the rain and wind",
    "of centuries gone by",
    "sad",
    "of sweet flowers and mild tabacco",
    "of escapism",
    "intimate",
    "of powdered linens",
    "of tarragon and marzipan",
    "of lavendar and mint",
    "of a clean guest house",
    "self-assured",
    "of tenacious vines",
    "of forgotten cookery",
    "hypnotising and puts you in a dreamy state",
    "sharp and pristine",
]

export const flaxBranchTypes = [
	"lush",
    "agitated",
    "dense",
    "green",
    "leafy",
    "thick",
    "vibrant",
    "lively",
    "coiled rope-like",
    "spun thread-like",
    "twine-like",
    "musical",
    "Unfortunate",
    "Desolate",
    "Lackadaisical",
    "Rotten",
    "Stinky",
    "a tangled mess of",
    "sticky",
    "smelly",
    "slippery",
    "slimy",
    "straight",
    "segmented",
    "spanning",
    "supportive",
    "storm-weathered",
    "hardy and determined",
    "overwrought",
    "shuddering",
    "lightning-cleaved",
    "querulous green",
    "signs of recent tears and slender",
    "unkempt",
    "fussy",
    "fibrous",
    "textured",
    "tough",
    "a sense of being fussed over and thin",
    "a love for loam and tough",
    "its roots above the waterline and wispy",
]

export const flaxLeafTypes = [
	"coarse",
    "prickly",
    "hairy",
    "tough",
    "woody",
    "drooping",
    "leafy",
    "green",
    "long",
    "sagging",
    "thin",
    "lanceolate",
    "slender",
    "arrow-thin",
    "a wisp of"
    "wispy",
    "waxy",
    "velvety",
    "glaucous",
    "glimmering",
    "translucent",
    "coiled",
    "slippery",
    "sage",
    "aromatic",
    "piquant",
    "dank",
    "muggy",
    "damp",
    "withered",
    "dangling",
    "unfurled",
    "chartreuse",
]