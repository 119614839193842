export const prickleBushSmellTypes = [
	"bitter",
    "of Gardenia",
    "of Orange blossom",
    "tuberose",
    "of Cucumber",
    "of Cantaloupe",
    "of earthy tea",
    "of metallic macadamia",
    "of hot iron",
    "of man-made fractals",
    "of creamy sage",
    "milky and metallic",
    "sudsy and humid",
    "familiar",
    "of a place you left behind",
    "of sail canvas",
    "like a breath of fresh air",
    "of an outhouse",
    "of sick",
    "of hidden creatures",
    "of peppery patchouli",
    "of beaten bronze",
    "of a spell-binding fog",
    "of chimney soot",
    "of snow-covered steppes",
    "of boggy grass",
    "of salty quays",
    "of wave-lashed hulls",
    "of harbour rope",
    "of fruity wreaths",
    "of vexing pine",
    "of sun-drenched meadows",
    "of tall grass",
    "of loamy tendrils",
    "of a wet blanket",
    "of barren soil",
    "of a worn down coin",
    "of a vat of bubbling cream",
    "of a rocky surf",
    "of long-ago meals",
    "putrid",
    "of the neap tide",
    "of a bark carapace",
    "of lawn in light drizzle",
    "of swelling moonlight",
    "of a mud dam",
    "of a labyrinth of peaches",
    "blank",
    "distressing",
    "of humble decor",
    "of zinc",
]

export const prickleBushBranchTypes = [
	"pruritic",
    "flowering",
    "lightly stinging",
    "amber buds amongst its",
    "orange blooms sitting in its",
    "a maze of small",
    "husk-covered",
    "spiny",
    "pointed tips in its",
    "small thorns along its",
    "green",
    "leafy",
    "needled",
    "leafy",
    "shady",
    "green",
    "cool",
    "dense",
    "fragrant",
    "shady",
    "prickles on its",
    "prickled",
    "sharp",
    "bushy",
    "sticky",
    "woody",
    "armed",
    "sickle-shaped thorns on its",
    "flaky bark",
    "fen-gathered",
    "worm-traced",
    "sun-hearted",
    "seasonable",
    "stately",
    "stout",
    "curved",
    "thicketing flowers in its",
    "furrowed",
]

export const prickleBushLeafTypes = [
	"prickly", 
    "green", 
    "shiny", 
    "smooth", 
    "pointy",
    "pinnate",
    "serrated",
    "densely packed",
    "wind-blown",
    "an abundance of",
    "drooping",
    "uplifting",
    "glittering",
    "spoked",
    "sturdy",
    "broad",
    "hardy",
    "blue-twinged",
    "azure-tipped",
    "mead-sweetened",
    "rose-emulous",
    "glaucous",
    "oval",
    "cordate",
    "light-green",
    "mucronate",
    "thrice-paired",
    "sound",
]