export const jungleVineSmellTypes = [
	"of Twayblade",
    "of Enchanter's nightshade",
    "of Thread-leaved sunflower",
    "of Ground ivy",
    "of Flowering rush",
    "of purple loosestrife",
    "of Common chickweed",
    "of Buttercups",
    "of sweet cicely",
    "bittersweet",
    "of comfrey",
    "of daisy",
    "of dandelion",
    "of Yam",
    "of Passionflower",
    "of Orchid",
    "of Fennel",
    "of Ginger",
    "of Lemon",
    "of Raspberry",
    "of Mint",
    "of Lemon",
    "bitter and musty",
    "syrupy sweet",
    "of ripe redcurrant",
    "vibrant",
    "of the first steps taken",
    "of nectar and gunpowder",
    "of screechy anise",
    "astringent",
    "of biscuits in an orchard",
    "of ripe nectarine",
    "poisonous",
    "toxic",
    "of long, dark days with no sun"
    "of pulpy fruit",
    "of lovingly prepared foodstuff",
    "carefree",
    "of syrup for an ailing throat",
    "of sugared vanilla bean",
    "of peach bonbons",
    "of a dried food mix",
    "of chewable vitamin supplement",
    "of frilly ecru",
    "of mossy grey-green wonder",
    "of powdered castoreum",
]

export const jungleVineBranchTypes = [
    "large seedpods on its",
    "snaking",
    "rampant",
    "ruthless",
    "scarlet",
    "sleek",
    "tenacious",
    "vibrant",
    "moss-covered",
    "vines dangling from its",
    "tiny pink flowers on its",
    "small purple florets on its",
    "flowering",
    "climbing",
    "invasive",
    "latching",
    "touch-sensitive",
    "tendril",
    "coiling",
    "grasping",
    "seeking",
    "thigmotropic",
    "squeezing",
    "tense",
    "shaking",
    "secured",
    "sticky",
    "adhesive",
    "twining",
    "bending",
    "twisting",
    "circumnutating",
    "climbing",
    "cloying",
    "rope-like",
    "high climbing",
    "reaching",
    "thick",
    "sturdy",
    "dense",
    "cane-like",
    "flower-gabled",
]

export const jungleVineLeafTypes = [
	"bugling",
    "trumpeting",
    "feather-shaped",
    "egg-shaped",
    "spining",
    "Birds of paradise clinging to its",
    "sap-dripping",
    "alternate",
    "blue-green",
    "oblong",
    "papery",
    "bi-pinnate",
    "elliptical",
    "lathering",
    "lens-shaped",
    "sub-opposite",
    "cloud-closing",
    "sheathed",
    "acrid",
    "pulpy",
    "pointed",
    "bladed",
    "mindful",
    "sun-chasing",
    "a hoard of small",
    "a spray of hopeful",
    "struggling",
    "stressed",
    "lusty",
    "ample",
    "wanter-of-sun",
    "waking",
    "waxing",
    "unfurled",
    "fragile",
    "wander-weary"
    "patient",
]