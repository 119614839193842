import { LOG_IN_LOADING, LOG_IN_SUCCESS, LOG_IN_FAILURE } from '../actionTypes';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';

export const requestResetPasswordAsync = payload => dispatch => {
    const { action, value } = payload;

    return client.service('authManagement').create({
        action,
        value
    })
        .then(response => {
            console.log('response: ', response);
            return response;
        })
        .catch(error => {
            console.log(error);
            throw new Error(error);
        });
}

export const resetPasswordAsync = payload => dispatch => {
    const { action, value } = payload;

    return client.service('authManagement').create({
        action,
        value
    })
        .then(response => {
            console.log('response: ', response);
            return response;
        })
        .catch(error => {
            console.log(error);
            throw new Error(error);
        });
}