export const salmonFaceSentences = [
"A gentle giant with a heart of gold.",
"A playful spirit that loves to swim and dive.",
"A beautiful creature with a sleek purple body.",
"A huge, anguished looking salmon with a long, slender face.",
"A salmon with a bulbous forehead and a deeply cleft chin.",
"A salmon whose eyes seem too large for its head.",
"A salmon with a large, fleshy nose.",
"A spiral of purple with a touch of pink.",
"A soft, ethereal pink that instantly makes you feel like a princess.",
]

export const salmonBodySentences = [
"fascinating and complex",
"intriguing and elegant",
"startling and dramatic",
"surprising and exotic",
"Vibrant purple scales cover it from head to tail.",
"Its sleek body is adorned with beautiful purple markings.",
"It has a delicate, yet stunning purple hue.",
"It is a stunning sight as it swims through the water.",
"its flesh is a deep purple ",
"it is covered in small scales ",
"its tail is fluked ",
"it has a long, slender body ",
"it has small fins on its sides ",
"its head is large and blunt ",
"A deep, rich purple that is both physically and spiritually soothing.",
"A color that exudes luxury, royalty, and power.",
"A shade that can invoke both calm and passion.",
"A hue that is often associated with wisdom, wealth, and achievement",
"A soft, dreamy hue of lavender.",
"A pretty shade of light purple.",
"A delicate and feminine shade of purple.",
"A sophisticated and regal shade of purple.",
"A rich and vibrant shade of purple.",
]

export const salmonSoundWords = [
"Flapping",
"Slapping",
"Splashing",
"Suspicious",
"Graceful",
"Playful",
"Majestic",
"Venturesome",
"Elegant",
"Idyllic",
"Slashing",
"Flapping",
"Writhing",
"Darting",
"Scaling",
"Gleaming",
"Majestic",
"Swimming",
"Diving",
"Surfacing",
"Flowing",
"Rushing",
"Gurgling",
"Ticking",
"Chirping",
"Clucking",
"Hissing",
"Wheezing",
"Whistling",
"Humming",
"Rippling",
"Flowing",
"murmuring",
"babbling",
"cooing",
"trilling",
"tweeting",
"chirping",
"peeping",
"warbling",
]

export const salmonFurWords = [
    "Lustrous",
    "iridescent",
    "vibrant",
    "deep",
    "rich",
    "opalescent",
    "luminous",
    "vivid",
    "brilliant",
    "radiant",
    "Vibrant",
    "rich",
    "deep",
    "luscious",
    "regal",
    "luxurious",
    "striking",
    "exotic",
    "enigmatic",
    "Iridescent",
    "opalescent",
    "sleek",
    "smooth",
    "finned",
    "swishy",
    "long",
    "tapered",
    "luminous",
    "iridescent",
    "beautiful",
    "majestic",
    "stunning",
    "exotic",
    "luxurious",
    "opulent",
    "textured",
    "vibrant",
    "Fluffy",
    "Silky",
    "Sleek",
    "Soft",
    "Supple",
    "Velvety",
    "Bright",
    "Colorful",
    "Exotic",
    "Rare"
]

export const salmonSmellWords = [
    "Salty",
    "briny",
    "fresh",
    "ocean",
    "damp",
    "fishy",
    "meaty",
    "unique",
    "pungent",
    "appetizing",
    "Strong",
    "Fishy",
    "Crisp",
    "Clean",
    "Wet",
    "WILD",
    "Smoked",
    "Burnt",
    "Bleached",
    "pungent",
    "fishy",
    "briny",
    "oceanic",
    "mineral",
    "iodine",
    "kelp",
    "salty",
    "Pungent",
    "fishy",
    "briny",
    "salty",
    "oceanic",
    "seaweedy",
    "umami",
    "Sweet",
    "Sour",
    "Spicy",
    "Earthy",
    "Crisp",
    "Jazzy"
]

export const salmonBehaviourSentences = [
"it's behavior seems to be constantly changing",
"it is swimming in a weird way",
"it is floating upside down",
"it is twitching",
"it is darting around",
"it is swimming in circles",
"it is making faces at you",
"it is trying to communicate with you",
]