import React from "react";
import { connect } from 'react-redux'

import { selectCharacter, selectNeighbouringCharacters } from '../../../redux/selectors';
import { client } from '../../../services/client';

import Menu from '../../utils/menu/Menu';

import './CharacterList.css';

class CharacterList extends React.Component {

    render() {
        let characters = this.props.characters.map((character) => {
            return {
                ...character,
                text: character?.name || 'Unknown',
                indentation: 1,
                actions: {
                    'SPACE': {
                        callback: (option) => (this.props.onItemSelect(character)),
                        text: 'Select character'
                    },
                }
            };
        })
        return (
            <div className="character-select">
                <div className="select-items-menu-container">
                <p className="character-select-title">Choose Character</p>
                    {characters.length === 0 ? (
                        <p>You are not stood close enough to anybody</p>
                    ) : (
                        <Menu
                            options={characters}
                            menuContainer="select-items-menu-container"
                        />
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const currentCharacter = selectCharacter(state);
    const characters = selectNeighbouringCharacters(state, currentCharacter)

    return { characters }
}

export default connect(
    mapStateToProps,
    {  }
)(CharacterList);