export const slothFaceSentences = [
"large, bright green eyes",
"long, furry lashes",
"cute, upturned nose",
"furry, green ears",
"furry, green brows",
"Thick, lush fur the color of a summer meadow.",
"Large, round eyes the color of a thunderstorm.",
]

export const slothBodySentences = [
"Short, stubby limbs with sharp claws.",
"Bulky body with a long, furry tail.",
"It's a little bit like watching grass grow, but slower.",
"Bulky body built for hanging.",
"Four stocky legs with sharp claws.",
"Slow and deliberate",
]

export const slothSoundWords = [
    "Hooting", "screeching", "wailing", "howling", "moaning", "groaning", "whimpering", "suckling", "cooing", "babbling",
]

export const slothFurWords = [
    "Velvety",
    "smooth",
    "soft",
    "cool",
    "thin",
    "delicate",
    "light",
    "shiny",
    "lustrous",
    "graceful",
    "Vibrant",
    "Rich",
    "Luscious",
    "Thick",
    "Soft",
    "Supple",
    "Glorious",
    "Radiant",
    "Exotic",
    "resplendent",
    "Touchable",
    "velvety",
    "smooth",
    "soft",
    "short",
    "light",
    "fine",
    "thin",
    "shiny",
    "lustrous",
    "glossy",
    "textured",
    "thick",
    "thin",
    "oily",
    "dry",
    "soft",
    "rough",
    "delicate",
    "strong",
    "lush",
    "velvety",
    "vibrant",
    "alive",
    "rich",
    "full",
    "deep",
    "forest",
    "olive",
    "jade"
]

export const slothSmellWords = [
    "sickly",
    "sewage",
    "death",
    "rotting",
    "mouldy",
    "mildewy",
    "fetid",
    "putrid",
    "stinking",
    "reeking",
    "Wet",
    "slimy",
    "pungent",
    "musky",
    "earthy",
    "damp",
    "humid",
    "wet",
    "fetid",
    "rotting",
    "malodorous",
    "rank"
]

export const slothBehaviourSentences = [
"it is very still and seems to be staring at you",
"it moves slowly and deliberately, as if it's stalking you",
"it makes strange hooting noises that echo in the eerie silence of the forest",
"it is making loud chittering noises",
"it is making direct eye contact with you",
"it is standing completely still, almost like it's waiting for something",
]