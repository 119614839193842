export const getRandomArrayItem = (array, rng) => {

	if (!rng) {
		return array[Math.floor(Math.random() * array.length)];
	}

	return array[Math.floor(rng() * array.length)];
}

export const getRandomIntegerBetween = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1) + min)
}

export const getRandomCleanedArrayItem = (array, rng) => {
	let item;

	if (!rng) {
		item = array[Math.floor(Math.random() * array.length)];
	} else {
		item = array[Math.floor(rng() * array.length)];
	}

	item = item.toLowerCase();
	item = item.trim();

	return item;
}
