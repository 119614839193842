import {
    GET_MATERIAL_RECIPES_LOADING,
    GET_MATERIAL_RECIPES_SUCCESS,
    GET_MATERIAL_RECIPES_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';

export const getMaterialRecipesSuccess = payload => ({
    type: GET_MATERIAL_RECIPES_SUCCESS,
    payload
})

export const getMaterialRecipesFailure = () => ({
    type: GET_MATERIAL_RECIPES_FAILURE
})

export const getMaterialRecipesAsync = payload => dispatch => {
    return dispatch(getRecursivelyAsync('material-recipes', getMaterialRecipesSuccess, 0));
}