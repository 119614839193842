export const bassFaceSentences = [
"Bulging, green eyes sit atop a long, slender face",
"A wide mouth full of sharp teeth curves down from the fish's nose",
"Green scales cover the fish's entire face, shimmering in the light",
"The fish's face is long and oval",
"Lumpy and green with big black eyes",
"A long, slimy fish with scales the color of leaves",
"It has a wide, flat head and a long, barb-less bottom fin",
"Each of its large, black eyes is surrounded by a bright",
"Vibrant green scales that shimmer in the light",
"A long, slim body that is agile in the water",
"Deliciously plump lips that are perfect for kissing",
"Bright, shining eyes that seem to hold all the secrets of the sea",
"The fish has a green face with large eyes",
"Its mouth is slightly open, revealing sharp teeth",
"Green scales cover its face and body",
"The fish has a long, slender body",
"It has two fins on either side",
"The fish's face is green and slimy ",
"Its eyes are beady and black, and its mouth is open, revealing sharp teeth",
"The fish's scales are green and glisten in the light Its fins are green and webbed, and its tail is long and slender",
]

export const bassBodySentences = [
"Glistening green scales cover its body",
"its body is long and slender",
"its belly is a pale green",
"it fish has a long, green body with a pointed nose and small eyes",
"Its fins are long and thin, and its tail is forked",
"it fish has a green body with a yellow belly",
"It has orange spots on its sides and a black stripe running down its back",
"Its fins are green and yellow, and it has a long, slender body",
"The skin is textured and bumpy",
"The scales are a deep green",
"The belly is a light green",
"The fins are a brighter green",
"The tail is forked",
"Its scales are a deep green, with a shimmer that catches the eye",
"It has a long, slender body that is built for speed",
]

export const bassSoundWords = [
    "swishing",
    "gliding",
    "whooshing",
    "murmuring",
    "rippling",
    "burbling",
    "gurgling",
    "trickling",
    "lapping",
    "flowing",
    "flopping",
    "flopping",
    "flopping",
    "flopping",
    "flopping",
    "flopping",
    "flopping",
    "flopping",
    "flopping",
    "flopping",
    "Sizzling",
    "Soothing",
    "Calming",
    "Relaxing",
    "Trickling",
    "Drifting",
    "Gliding",
    "Swishing",
    "Whooshing",
    "Rippling",
    "Flipping",
    "Flapping",
    "Slapping",
    "Splashing",
    "Gurgling",
    "Chomping",
    "munching",
    "Grunting",
    "Snorting",
    "Wheezing",
    "Thumping",
    "slapping",
    "tumbling",
    "bubbling",
    "hissing",
    "crackling",
    "gurgling",
    "percolating",
    "effervescing",
    "frothing"
]

export const bassFurWords = [
    "swishing",
    "gliding",
    "whooshing",
    "murmuring",
    "rippling",
    "burbling",
    "gurgling",
    "trickling",
    "lapping",
    "flowing",
    "flopping",
    "flopping",
    "flopping",
    "flopping",
    "flopping",
    "flopping",
    "flopping",
    "flopping",
    "flopping",
    "flopping",
    "Sizzling",
    "Soothing",
    "Calming",
    "Relaxing",
    "Trickling",
    "Drifting",
    "Gliding",
    "Swishing",
    "Whooshing",
    "Rippling",
    "Flipping",
    "Flapping",
    "Slapping",
    "Splashing",
    "Gurgling",
    "Chomping",
    "munching",
    "Grunting",
    "Snorting",
    "Wheezing",
    "Thumping",
    "slapping",
    "tumbling",
    "bubbling",
    "hissing",
    "crackling",
    "gurgling",
    "percolating",
    "effervescing",
    "frothing"
]

export const bassSmellWords = [
    "Pungent",
    "fishy",
    "salty",
    "oceanic",
    "wet",
    "slimy",
    "fresh",
    "seaweed",
    "algae",
    "Fishy",
    "briny",
    "pungent",
    "salty",
    "oceanic",
    "marine",
    "watery",
    "sweaty",
    "musky",
    "funky"
]

export const bassBehaviourSentences = [
"it is hiding in the shadows",
"it is swimming in circles",
"it is darting around erratically",
"it is swimming in circles in the water.",
"it is swimming backwards in the water.",
"it seems to be lost",
"it is swimming in circles",
]