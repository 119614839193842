import { GET_KEY_RECIPES_SUCCESS } from "../actionTypes";

const initialState = {
  keyRecipes: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_KEY_RECIPES_SUCCESS: {
      return {
        ...state,
        keyRecipes: [...action.payload],
      }
    }

    default:
      return state;
  }
}
