export const willowSmellTypes = [
	"of autumn leaves",
    "crisp",
    "earthy",
    "fresh",
    "green",
    "herbal",
    "of pine",
    "resinous",
    "of wattle",
    "grassy",
    "humid",
    "damp",
    "muggy",
    "sticky",
    "sweaty",
    "of Magnolia",
    "of Moonflower",
    "of Neroli",
    "of Orchid",
    "of stewed fruit",
    "cold and bright",
    "of an oversteeped tea",
    "of woody darkness",
    "of lost cargo",
    "smokey and soapy",
    "lightly sweet",
    "earthy and pungent",
    "like seeing your breath in the air",
    "of earthy smoke",
    "of somewhere dark and green",
    "of tar and smoke",
    "of calm meditation",
    "of expectant intrigue",
    "like a warehouse of dried leaves",
    "of charred plant matter",
    "of refreshing cool weather",
    "as you'd expect",
    "of an extinguished joint",
    "of moosambi",
    "of water from irrigation drips",
    "of buttered iris",
    "of earthy rinds",
    "of dessicated marrow",
    "of mint jam",
    "captivating",
    "guaiac",
    "of dry myrrh",
    "of sun beating down on dry earth",
    "of relentless summer",
    "of bright, hollow wood",
    "of waxes mahogany",
]

export const willowBranchTypes = [
	"velvet",
    "wicker",
    "thorn",
    "elastic",
    "springy",
    "pliant",
    "supple",
    "lacy",
    "drooping",
    "swaying",
    "whispering",
    "lonesome",
    "meditative",
    "majestic",
    "crooked",
    "long",
    "thin",
    "droopy",
    "flexible",
    "green",
    "yellow",
    "white",
    "watery",
    "opportunistic",
    "aphid-rich",
    "enriched",
    "strong and pliant",
    "supple",
    "compassionate",
    "a calming nature drawn forth by its",
    "the feel of a protective ward under its",
    "ghostly",
    "sinister",
    "a stalking nature and patience in its",
    "the sense that it is following you and quiet",
    "lifeless",
    "a sustenance of pollen insects in its",
    "recently pruned",
    "shorn",
    "beaten",
    "fancy-fed",
    "harp-like",
    "soap-slick",
    "river roots and waving",
    "whip",
    "lively",
    "softly bobbing",
    "wind-whipped",
    "an inviting spot to sit and wait under its",
    "a suspicious air and quivering",
    "pendulous",
    "susurrating",
]

export const willowLeafTypes = [
	"fungus-rusted",
    "gall-blighted",
    "fertile",
    "curative-purported",
    "feather",
    "pointed",
    "spear headed",
    "needle",
    "slender",
    "graceful",
    "waterfalls of",
    "delicate",
    "lacy",
    "crinkled",
    "soft",
    "velvety",
    "fishtail",
    "feathered",
    "bristled",
    "needle-like",
    "round",
    "oval",
    "Large",
    "green",
    "pointy",
    "long",
    "thin",
    "delicate",
    "sharp",
    "colorful",
    "vibrant",
    "beautiful",
    "feather",
    "lobed",
    "ovate",
    "pinnate",
    "saw-toothed",
    "serrated",
    "sinuate",
    "toothed",
    "wavy",
    "fey",
    "phantom",
]