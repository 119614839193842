export const tundraticThornSmellTypes = [
    "of stone-hewn valleys",
    "of muddy lake-mire",
    "of fresh streams",
    "carefree",
    "peaceful",
    "calming",
    "tranquil",
    "of earth",
    "of wind",
    "of snow",
    "of rock",
    "of rusted ice",
    "of pine",
    "of wood",
    "of leather",
    "fresh",
    "cold",
    "woodsy",
    "coniferous",
    "aromatic",
    "sharp",
    "pungent",
    "of ember",
    "of snow",
    "of balsam",
    "heartbreaking",
    "haunting",
    "of frostbitten leaf-litter"
    "plausible",
    "of a future without worry",
    "of mite colonies",
    "of nesting organisms",
    "of deep winter",
    "of pine shavings",
    "of old shelter",
    "of the long road home",
    "of harvest",
    "of juniper down",
    "of herdsfolk",
    "of bile",
    "fetid and sublime",
    "of crisp fens",
    "of bankside sands",
    "of fennel mousse",
    "of last season's compost",
    "of mint and gorse",
    "of vernal return",
    "of a crawlspace",
    "and disarmingly sweet",
    "of wild honey",
    "of glazed twigs",
]

export const tundraticThornBranchTypes = [
	"snowy",
    "barren",
    "icy",
    "flat",
    "infinite",
    "desolate",
    "a forest of",
    "a dusting of snow on its",
    "winter",
    "wild",
    "ungodly",
    "stark",
    "harsh",
    "bleak",
    "snowy",
    "ice-laden",
    "frostbitten",
    "bleak",
    "dreary",
    "thorn-laced",
    "Sparse",
    "Windswept",
    "Bare",
    "spreading",
    "prostate",
    "sturdy",
    "determined",
    "reddish-brown",
    "sparsely hairy",
    "garbled",
    "cabled",
    "a tangle of spiny",
    "perserverance in its",
    "desperate",
    "caespitose",
    "sparkling dew on its hairy",
    "castaneous-coloured",
    "burst forth from the clay with resolute",
    "single-minded",
    "dogged",
    "blase",
    "chilly",
    "frigid",
    "creeping",
    "underground",
    "moss-covered",
    "freely rooting",
    "sheltering",
    "tenacious",
]

export const tundraticThornLeafTypes = [
    "frozen",
    "jagged",
    "barren",
    "sloped",
    "lifeless",
    "featureless",
    "monotonous",
    "dreary",
    "red catkins under its",
    "yellow catkins under its",
    "rounded",
    "toothily crenated",
    "shiny red",
    "pale",
    "variable",
    "hopeful",
    "subglabrous",
    "marcescent",
    "flat",
    "linear",
    "grass-like, crimson",
    "ellipsoidal",
    "rounded-ovate",
    "lightly-haired fruits and cup-like",
]