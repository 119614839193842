export const CLAY_POT_MASK = [
    {
        "x": 0,
        "y": 0
    },
    {
        "x": 1,
        "y": 0
    },
    {
        "x": 2,
        "y": 0
    },
    {
        "x": 3,
        "y": 0
    },
    {
        "x": 4,
        "y": 0
    },
    {
        "x": 5,
        "y": 0
    },
    {
        "x": 6,
        "y": 0
    },
    {
        "x": 7,
        "y": 0
    },
    {
        "x": 8,
        "y": 0
    },
    {
        "x": 9,
        "y": 0
    },
    {
        "x": 10,
        "y": 0
    },
    {
        "x": 11,
        "y": 0
    },
    {
        "x": 12,
        "y": 0
    },
    {
        "x": 13,
        "y": 0
    },
    {
        "x": 14,
        "y": 0
    },
    {
        "x": 15,
        "y": 0
    },
    {
        "x": 16,
        "y": 0
    },
    {
        "x": 17,
        "y": 0
    },
    {
        "x": 18,
        "y": 0
    },
    {
        "x": 19,
        "y": 0
    },
    {
        "x": 0,
        "y": 1
    },
    {
        "x": 1,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 2,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 3,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 4,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 5,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 6,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 7,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 8,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 9,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 10,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 11,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 12,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 13,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 14,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 15,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 16,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 17,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 18,
        "y": 1,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 19,
        "y": 1
    },
    {
        "x": 0,
        "y": 2
    },
    {
        "x": 1,
        "y": 2
    },
    {
        "x": 2,
        "y": 2,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 3,
        "y": 2,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 4,
        "y": 2,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 5,
        "y": 2,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 6,
        "y": 2,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 7,
        "y": 2,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 8,
        "y": 2,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 9,
        "y": 2,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 10,
        "y": 2,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 11,
        "y": 2,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 12,
        "y": 2,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 13,
        "y": 2,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 14,
        "y": 2,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 15,
        "y": 2,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 16,
        "y": 2,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 17,
        "y": 2,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 18,
        "y": 2
    },
    {
        "x": 19,
        "y": 2
    },
    {
        "x": 0,
        "y": 3
    },
    {
        "x": 1,
        "y": 3
    },
    {
        "x": 2,
        "y": 3,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 3,
        "y": 3,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 4,
        "y": 3,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 5,
        "y": 3,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 6,
        "y": 3,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 7,
        "y": 3,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 8,
        "y": 3,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 9,
        "y": 3,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 10,
        "y": 3,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 11,
        "y": 3,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 12,
        "y": 3,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 13,
        "y": 3,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 14,
        "y": 3,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 15,
        "y": 3,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 16,
        "y": 3,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 17,
        "y": 3,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 18,
        "y": 3
    },
    {
        "x": 19,
        "y": 3
    },
    {
        "x": 0,
        "y": 4
    },
    {
        "x": 1,
        "y": 4
    },
    {
        "x": 2,
        "y": 4,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 3,
        "y": 4,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 4,
        "y": 4,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 5,
        "y": 4,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 6,
        "y": 4,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 7,
        "y": 4,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 8,
        "y": 4,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 9,
        "y": 4,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 10,
        "y": 4,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 11,
        "y": 4,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 12,
        "y": 4,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 13,
        "y": 4,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 14,
        "y": 4,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 15,
        "y": 4,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 16,
        "y": 4,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 17,
        "y": 4,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 18,
        "y": 4
    },
    {
        "x": 19,
        "y": 4
    },
    {
        "x": 0,
        "y": 5
    },
    {
        "x": 1,
        "y": 5
    },
    {
        "x": 2,
        "y": 5
    },
    {
        "x": 3,
        "y": 5,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 4,
        "y": 5,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 5,
        "y": 5,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 6,
        "y": 5,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 7,
        "y": 5,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 8,
        "y": 5,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 9,
        "y": 5,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 10,
        "y": 5,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 11,
        "y": 5,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 12,
        "y": 5,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 13,
        "y": 5,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 14,
        "y": 5,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 15,
        "y": 5,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 16,
        "y": 5,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 17,
        "y": 5
    },
    {
        "x": 18,
        "y": 5
    },
    {
        "x": 19,
        "y": 5
    },
    {
        "x": 0,
        "y": 6
    },
    {
        "x": 1,
        "y": 6
    },
    {
        "x": 2,
        "y": 6
    },
    {
        "x": 3,
        "y": 6,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 4,
        "y": 6,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 5,
        "y": 6,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 6,
        "y": 6,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 7,
        "y": 6,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 8,
        "y": 6,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 9,
        "y": 6,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 10,
        "y": 6,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 11,
        "y": 6,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 12,
        "y": 6,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 13,
        "y": 6,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 14,
        "y": 6,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 15,
        "y": 6,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 16,
        "y": 6,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 17,
        "y": 6
    },
    {
        "x": 18,
        "y": 6
    },
    {
        "x": 19,
        "y": 6
    },
    {
        "x": 0,
        "y": 7
    },
    {
        "x": 1,
        "y": 7
    },
    {
        "x": 2,
        "y": 7
    },
    {
        "x": 3,
        "y": 7,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 4,
        "y": 7,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 5,
        "y": 7,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 6,
        "y": 7,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 7,
        "y": 7,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 8,
        "y": 7,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 9,
        "y": 7,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 10,
        "y": 7,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 11,
        "y": 7,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 12,
        "y": 7,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 13,
        "y": 7,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 14,
        "y": 7,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 15,
        "y": 7,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 16,
        "y": 7,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 17,
        "y": 7
    },
    {
        "x": 18,
        "y": 7
    },
    {
        "x": 19,
        "y": 7
    },
    {
        "x": 0,
        "y": 8
    },
    {
        "x": 1,
        "y": 8
    },
    {
        "x": 2,
        "y": 8
    },
    {
        "x": 3,
        "y": 8,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 4,
        "y": 8,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 5,
        "y": 8,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 6,
        "y": 8,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 7,
        "y": 8,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 8,
        "y": 8,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 9,
        "y": 8,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 10,
        "y": 8,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 11,
        "y": 8,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 12,
        "y": 8,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 13,
        "y": 8,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 14,
        "y": 8,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 15,
        "y": 8,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 16,
        "y": 8,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 17,
        "y": 8
    },
    {
        "x": 18,
        "y": 8
    },
    {
        "x": 19,
        "y": 8
    },
    {
        "x": 0,
        "y": 9
    },
    {
        "x": 1,
        "y": 9
    },
    {
        "x": 2,
        "y": 9
    },
    {
        "x": 3,
        "y": 9
    },
    {
        "x": 4,
        "y": 9,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 5,
        "y": 9,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 6,
        "y": 9,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 7,
        "y": 9,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 8,
        "y": 9,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 9,
        "y": 9,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 10,
        "y": 9,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 11,
        "y": 9,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 12,
        "y": 9,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 13,
        "y": 9,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 14,
        "y": 9,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 15,
        "y": 9,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 16,
        "y": 9
    },
    {
        "x": 17,
        "y": 9
    },
    {
        "x": 18,
        "y": 9
    },
    {
        "x": 19,
        "y": 9
    },
    {
        "x": 0,
        "y": 10
    },
    {
        "x": 1,
        "y": 10
    },
    {
        "x": 2,
        "y": 10
    },
    {
        "x": 3,
        "y": 10
    },
    {
        "x": 4,
        "y": 10,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 5,
        "y": 10,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 6,
        "y": 10,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 7,
        "y": 10,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 8,
        "y": 10,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 9,
        "y": 10,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 10,
        "y": 10,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 11,
        "y": 10,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 12,
        "y": 10,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 13,
        "y": 10,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 14,
        "y": 10,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 15,
        "y": 10,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 16,
        "y": 10
    },
    {
        "x": 17,
        "y": 10
    },
    {
        "x": 18,
        "y": 10
    },
    {
        "x": 19,
        "y": 10
    },
    {
        "x": 0,
        "y": 11
    },
    {
        "x": 1,
        "y": 11
    },
    {
        "x": 2,
        "y": 11
    },
    {
        "x": 3,
        "y": 11
    },
    {
        "x": 4,
        "y": 11,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 5,
        "y": 11,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 6,
        "y": 11,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 7,
        "y": 11,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 8,
        "y": 11,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 9,
        "y": 11,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 10,
        "y": 11,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 11,
        "y": 11,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 12,
        "y": 11,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 13,
        "y": 11,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 14,
        "y": 11,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 15,
        "y": 11,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 16,
        "y": 11
    },
    {
        "x": 17,
        "y": 11
    },
    {
        "x": 18,
        "y": 11
    },
    {
        "x": 19,
        "y": 11
    },
    {
        "x": 0,
        "y": 12
    },
    {
        "x": 1,
        "y": 12
    },
    {
        "x": 2,
        "y": 12
    },
    {
        "x": 3,
        "y": 12
    },
    {
        "x": 4,
        "y": 12,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 5,
        "y": 12,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 6,
        "y": 12,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 7,
        "y": 12,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 8,
        "y": 12,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 9,
        "y": 12,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 10,
        "y": 12,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 11,
        "y": 12,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 12,
        "y": 12,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 13,
        "y": 12,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 14,
        "y": 12,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 15,
        "y": 12,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 16,
        "y": 12
    },
    {
        "x": 17,
        "y": 12
    },
    {
        "x": 18,
        "y": 12
    },
    {
        "x": 19,
        "y": 12
    },
    {
        "x": 0,
        "y": 13
    },
    {
        "x": 1,
        "y": 13
    },
    {
        "x": 2,
        "y": 13
    },
    {
        "x": 3,
        "y": 13
    },
    {
        "x": 4,
        "y": 13,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 5,
        "y": 13,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 6,
        "y": 13,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 7,
        "y": 13,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 8,
        "y": 13,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 9,
        "y": 13,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 10,
        "y": 13,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 11,
        "y": 13,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 12,
        "y": 13,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 13,
        "y": 13,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 14,
        "y": 13,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 15,
        "y": 13,
        "backgroundColor": "var(--sg-brown)"
    },
    {
        "x": 16,
        "y": 13
    },
    {
        "x": 17,
        "y": 13
    },
    {
        "x": 18,
        "y": 13
    },
    {
        "x": 19,
        "y": 13
    },
    {
        "x": 0,
        "y": 14
    },
    {
        "x": 1,
        "y": 14
    },
    {
        "x": 2,
        "y": 14
    },
    {
        "x": 3,
        "y": 14
    },
    {
        "x": 4,
        "y": 14
    },
    {
        "x": 5,
        "y": 14
    },
    {
        "x": 6,
        "y": 14
    },
    {
        "x": 7,
        "y": 14
    },
    {
        "x": 8,
        "y": 14
    },
    {
        "x": 9,
        "y": 14
    },
    {
        "x": 10,
        "y": 14
    },
    {
        "x": 11,
        "y": 14
    },
    {
        "x": 12,
        "y": 14
    },
    {
        "x": 13,
        "y": 14
    },
    {
        "x": 14,
        "y": 14
    },
    {
        "x": 15,
        "y": 14
    },
    {
        "x": 16,
        "y": 14
    },
    {
        "x": 17,
        "y": 14
    },
    {
        "x": 18,
        "y": 14
    },
    {
        "x": 19,
        "y": 14
    }
]