import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import { disableKeyboardMovement, enableKeyboardMovement, showHelp, startLooking, hideAllMenus } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectCharacter,
    selectCharacters,
    selectOrganisations,
    selectOpenOrganisation,
    selectOrganisationCriminals,
    selectCharacterPanels
} from '../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import { createOrganisationAsync, deleteOrganisationAsync, updateOrganisation } from '../../redux/actions/organisation.actions'
import { forgiveCriminalAsync } from '../../redux/actions/criminal.actions';

import Menu from '../utils/menu/Menu';
import TextInput from '../utils/text-input/TextInput';

import { client } from '../../services/client';

import './Organisation.css';

class Organisation extends React.Component {
    state = {
        organisationName: '',
        description: '',
        leaderTitle: '',
        managerTitle: '',
        memberTitle: '',
        invitees: [],
        inviteeIds: [],
        members: [],
        memberIds: [],
        mods: [],
        modIds: [],
        admins: [],
        adminIds: [],
        navigationHandler: this.navigationHandler.bind(this),
        isSetTextShowing: false,
        isMemberSelectShowing: false,
        isMemberListShowing: false,
        selectedOption: 0
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.state.navigationHandler)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.state.navigationHandler);
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.characterOrganisation && props.character) {
            state.adminIds = [ props.character._id ]
            state.admins = [ { ...props.character } ]
        }
        if (props.characterOrganisation !== state.characterOrganisation) {
            state.characterOrganisation = props.characterOrganisation

            state.organisationName = props.characterOrganisation.name
            state.description = props.characterOrganisation.description
            state.leaderTitle = props.characterOrganisation.leaderTitle
            state.managerTitle = props.characterOrganisation.managerTitle
            state.memberTitle = props.characterOrganisation.memberTitle
            state.members = props.characterOrganisation.memberIds?.map(memberId => {
                const character = props.characters.find(character => character._id === memberId);

                if (!character) {
                    return {
                        text: 'Out of location',
                        hint: ' Member',
                        _id: memberId
                    }
                }

                return {
                    ...character,
                    hint: ' Member'
                }
            }) || []
            state.memberIds = props.characterOrganisation.memberIds
            state.modIds = props.characterOrganisation.modIds
            state.adminIds = props.characterOrganisation.adminIds
            state.mods = props.characterOrganisation.modIds?.map(memberId => {
                const character = props.characters.find(character => character._id === memberId);

                if (!character) {
                    return {
                        text: 'Out of location',
                        hint: ' Mod',
                        _id: memberId
                    }
                }

                return {
                    ...character,
                    hint: ' Mod'
                }
            }) || []
            state.admins = props.characterOrganisation.adminIds?.map(memberId => {
                const character = props.characters.find(character => character._id === memberId)

                if (!character) {
                    return {
                        text: 'Out of location',
                        hint: ' Admin',
                        _id: memberId
                    }
                }

                return {
                    ...character,
                    hint: ' Admin'
                }
            })
            state.inviteeIds = props.characterOrganisation.inviteeIds
            state.invitees = props.characterOrganisation.inviteeIds?.map(memberId => {
                const character = props.characters.find(character => character._id === memberId)

                if (!character) {
                    return {
                        text: 'Out of location',
                        hint: ' Invited',
                        _id: memberId
                    }
                }

                return {
                    ...character,
                    hint: ' Invited'
                }
            }) || []

        }
        return state;
    }

    onEditSubmit(text) {
        this.setState({
            [this.state.isSetTextShowing]: text,
            isSetTextShowing: false
        })

        this.saveChanges();
    }

    onEditExit(text) {
        this.setState({
            isSetTextShowing: false
        })
    }

    onCharacterChosen(character) {
        if (character.hint.indexOf('Sending Invite') > -1) {
            this.setState({
                invitees: this.state.invitees.filter(member => (character._id !== member._id))
            })
        } else {
            this.state.invitees.push(character)
        }

        this.saveChanges();
    }

    onNavigation(option, event, selectedOption) {
        this.setState({
            selectedOption
        })
    }

    onCharacterKeyPressed(option, event, selectedOption) {
        if (event.key === 'Escape') {
            this.setState({
                isMemberSelectShowing: false,
                isMemberListShowing: false,
                isCriminalsListShowing: false,
            })

            event.preventDefault();
        } else {
            const character = [...this.state.admins, ...this.state.mods, ...this.state.members, ...this.state.invitees][selectedOption]

            if (this.isEnabled('kickMember') && event.key === 'k') {
                // kick invitee
                const newInvitees = this.state.invitees.filter(member => (member._id !== character._id))
                const newInviteeIds = this.state.inviteeIds.filter(memberId => (memberId !== character._id))

                // kick member
                const newMembers = this.state.members.filter(member => (member._id !== character._id))
                const newMemberIds = this.state.memberIds.filter(memberId => (memberId !== character._id))

                this.setState({
                    invitees: [ ...newInvitees ],
                    inviteeIds: [ ...newInviteeIds ],
                    members: [ ...newMembers ],
                    memberIds: [ ...newMemberIds ],
                })

                this.saveChanges();

                return;
            }

            if (this.isEnabled('setAsMember') && event.key === 'b') {
                // make a member a member
                const newInvitees = this.state.invitees.filter(member => (member._id !== character._id))
                const newInviteeIds = this.state.inviteeIds?.filter(memberId => (memberId !== character._id)) || []

                const newMembers = this.state.members.filter(member => (member._id !== character._id))
                const newMemberIds = this.state.memberIds?.filter(memberId => (memberId !== character._id)) || []

                const newMods = this.state.mods.filter(member => (member._id !== character._id))
                const newModIds = this.state.modIds?.filter(memberId => (memberId !== character._id)) || []

                const newAdmins = this.state.admins.filter(member => (member._id !== character._id))
                const newAdminIds = this.state.adminIds?.filter(memberId => (memberId !== character._id)) || []

                newMembers.push({ ...character, hint: ' Member' })
                newMemberIds.push(character._id)

                this.setState({
                    invitees: [ ...newInvitees ],
                    inviteeIds: [ ...newInviteeIds ],
                    members: [ ...newMembers ],
                    memberIds: [ ...newMemberIds ],
                    mods: [ ...newMods ],
                    modIds: [ ...newModIds ],
                    admins: [ ...newAdmins ],
                    adminIds: [ ...newAdminIds ],
                })

                this.saveChanges();
                
                return;
            }

            if (this.isEnabled('setAsMod') && event.key === 'm') {
                // make a member a mod
                const newInvitees = this.state.invitees.filter(member => (member._id !== character._id))
                const newInviteeIds = this.state.inviteeIds?.filter(memberId => (memberId !== character._id)) || []

                const newMembers = this.state.members.filter(member => (member._id !== character._id))
                const newMemberIds = this.state.memberIds?.filter(memberId => (memberId !== character._id)) || []

                const newMods = this.state.mods.filter(member => (member._id !== character._id))
                const newModIds = this.state.modIds?.filter(memberId => (memberId !== character._id)) || []

                const newAdmins = this.state.admins.filter(member => (member._id !== character._id))
                const newAdminIds = this.state.adminIds?.filter(memberId => (memberId !== character._id)) || []

                newMods.push({ ...character, hint: ' Mod' })
                newModIds.push(character._id)

                this.setState({
                    invitees: [ ...newInvitees ],
                    inviteeIds: [ ...newInviteeIds ],
                    members: [ ...newMembers ],
                    memberIds: [ ...newMemberIds ],
                    mods: [ ...newMods ],
                    modIds: [ ...newModIds ],
                    admins: [ ...newAdmins ],
                    adminIds: [ ...newAdminIds ],
                })

                this.saveChanges();
                
                return;
            }

            if (this.isEnabled('setAsAdmin') && event.key === 'a') {
                // make a member an admin
                const newInvitees = this.state.invitees.filter(member => (member._id !== character._id))
                const newInviteeIds = this.state.inviteeIds?.filter(memberId => (memberId !== character._id)) || []

                const newMembers = this.state.members.filter(member => (member._id !== character._id))
                const newMemberIds = this.state.memberIds?.filter(memberId => (memberId !== character._id)) || []

                const newMods = this.state.mods.filter(member => (member._id !== character._id))
                const newModIds = this.state.modIds?.filter(memberId => (memberId !== character._id)) || []

                const newAdmins = this.state.admins.filter(member => (member._id !== character._id))
                const newAdminIds = this.state.adminIds?.filter(memberId => (memberId !== character._id)) || []

                newAdmins.push({ ...character, hint: ' Admin' })
                newAdminIds.push(character._id)

                this.setState({
                    invitees: [ ...newInvitees ],
                    inviteeIds: [ ...newInviteeIds ],
                    members: [ ...newMembers ],
                    memberIds: [ ...newMemberIds ],
                    mods: [ ...newMods ],
                    modIds: [ ...newModIds ],
                    admins: [ ...newAdmins ],
                    adminIds: [ ...newAdminIds ],
                })

                this.saveChanges();

                return;
            }
        }
    }

    onCriminalChosen(option, event, selectedOption) {
        if (this.isEnabled('forgiveCriminal') && event.key === 'f') {
            this.props.forgiveCriminalAsync({
                _id: option._id
            })

            return;
        }
    }

    navigationHandler(event) {
        if (this.state.isSetTextShowing || this.state.isMemberSelectShowing) {
            return;
        }

        if (event.key === 'Escape' && (this.state.isCriminalsListShowing || this.state.isCharacterPanelSelectShowing)) {
            event.preventDefault();
            this.setState({
                isCharacterPanelSelectShowing: false,
                isCriminalsListShowing: false
            })

            this.saveChanges();
            return;
        }

        if (event.key === '.') {
            this.setState({
                isMemberListShowing: !this.state.isMemberListShowing
            })
        }

        if (this.state.isMemberListShowing) {
            return;
        }

        if (event.key === '/') {
            this.setState({
                isCriminalsListShowing: !this.state.isCriminalsListShowing
            })
        }

        if (this.state.isCriminalsListShowing) {
            return;
        }

        if (this.isEnabled('acceptInvitation') && event.key === 'a') {
            const invitedMember = this.state.invitees.find(invitee => invitee._id === this.props.character._id)

            if (!invitedMember) {
                console.error('no invited member found!')
                return;
            }

            const {
                organisationName,
                description,
                leaderTitle,
                managerTitle,
                memberTitle,
                invitees,
                members,
                mods,
                admins,
            } = this.state

            const updatedMembers = [ ...members, invitedMember ]
            const updatedInvitees = [ ...this.state.invitees.filter(invitee => (invitee._id !== invitedMember._id)) ]

            this.props.updateOrganisation({
                _id: this.props.characterOrganisation._id,
                name: organisationName,
                description,
                leaderTitle,
                managerTitle,
                memberTitle,
                memberIds: updatedMembers.map(member => (member._id)),
                modIds: mods.map(member => (member._id)),
                adminIds: admins.map(member => (member._id)),
                inviteeIds: updatedInvitees.map(member => (member._id)),
            })
        }

        if (this.isEnabled('addMembers') && event.key === 'i') {
            this.setState({
                isMemberSelectShowing: true
            })
            return;
        }

        if (this.isEnabled('setProtectedPanels') && event.key === 'p') {
            this.setState({
                isCharacterPanelSelectShowing: true
            })

            return;
        }

        if (this.isEnabled('deleteOrganisation') && event.key === 'x') {
            this.props.deleteOrganisationAsync({ _id: this.props.characterOrganisation._id })
            this.props.hideAllMenus()
            return;
        }
    }

    saveChanges() {
        const {
            organisationName,
            description,
            leaderTitle,
            managerTitle,
            memberTitle,
            invitees,
            members,
            mods,
            admins,
        } = this.state

        if (
            !organisationName ||
            !description ||
            !leaderTitle ||
            !managerTitle ||
            !memberTitle ||
            !members
        ) {
            return;
        }

        if (this.props.characterOrganisation) {
            this.props.updateOrganisation({
                _id: this.props.characterOrganisation._id,
                name: organisationName,
                description,
                leaderTitle,
                managerTitle,
                memberTitle,
                memberIds: members.map(member => (member._id)),
                modIds: mods.map(member => (member._id)),
                adminIds: admins.map(member => (member._id)),
                inviteeIds: invitees.map(member => (member._id)),
            })
        } else if (!this.state.isCreated) {
            this.props.createOrganisationAsync({
                name: organisationName,
                description,
                leaderTitle,
                managerTitle,
                memberTitle,
                memberIds: members.map(member => (member._id)),
                modIds: mods.map(member => (member._id)),
                adminIds: admins.map(member => (member._id)),
                inviteeIds: invitees.map(member => (member._id)),
            })

            this.setState({
                isCreated: true
            });
        }

        return;
    }

    isFormValid() {
        const {
            organisationName,
            description,
            leaderTitle,
            managerTitle,
            memberTitle,
            members,
        } = this.state

        return !(
            !organisationName ||
            !description ||
            !leaderTitle ||
            !managerTitle ||
            !memberTitle ||
            !members
        )
    }

    selectCharacterPanel(characterPanel) {
        if (!this.props.characterOrganisation.protectedPanelIds) {
            this.props.updateOrganisation({
                _id: this.props.characterOrganisation._id,
                protectedPanelIds: [characterPanel.panelId]
            })

            return;
        }

        const isExisting = this.props.characterOrganisation.protectedPanelIds.find(panelId => (characterPanel.panelId === panelId));

        if (isExisting) {
            this.props.updateOrganisation({
                _id: this.props.characterOrganisation._id,
                protectedPanelIds: [...this.props.characterOrganisation.protectedPanelIds.filter(panelId => (panelId !== characterPanel.panelId))]
            })
        } else {
            this.props.updateOrganisation({
                _id: this.props.characterOrganisation._id,
                protectedPanelIds: [...this.props.characterOrganisation.protectedPanelIds, characterPanel.panelId]
            })
        }
    }

    setOrganisationProperty(value) {
        if (this.isEnabled('setOrganisationName') && value.text === 'Organisation Name: ') {
            // show input
            this.setState({
                isSetTextShowing: 'organisationName'
            })
            return;
        }

        if (this.isEnabled('setTitleLeader') && value.text === 'Title of Leader: ') {
            this.setState({
                isSetTextShowing: 'leaderTitle'
            })
            return;
        }

        if (this.isEnabled('setTitleManagers') && value.text === 'Title of Managers: ') {
            this.setState({
                isSetTextShowing: 'managerTitle'
            })
            return;
        }

        if (this.isEnabled('setTitleMembers') && value.text === 'Member Title: ') {
            this.setState({
                isSetTextShowing: 'memberTitle'
            })
            return;
        }

        if (this.isEnabled('setOrganisationDescription') && value.text === 'Description: ') {
            this.setState({
                isSetTextShowing: 'description'
            })
            return;
        }
    }

    isEnabled(action) {
        const isCharacterAdmin = this.state.adminIds.indexOf(this.props.character._id) > -1;
        const isCharacterMod = this.state.modIds.indexOf(this.props.character._id) > -1;
        const isCharacterInvitee = this.state.inviteeIds.indexOf(this.props.character._id) > -1;

        const isNoCharacterAdmin = this.state.adminIds.length === 0;

        const highlightedCharacter = [ ...this.state.admins, ...this.state.mods, ...this.state.members, ...this.state.invitees ][this.state.selectedOption]

        const isHighlightedCharacterAdmin = this.state.adminIds?.indexOf(highlightedCharacter?._id) > -1;
        const isHighlightedCharacterMod = this.state.modIds?.indexOf(highlightedCharacter?._id) > -1;
        const isHighlightedCharacterMember = this.state.memberIds?.indexOf(highlightedCharacter?._id) > -1;
        const isHighlightedCharacterInvitee = this.state.inviteeIds?.indexOf(highlightedCharacter?._id) > -1;

        const characterOrganisation = this.props.characterOrganisation

        const isLastAdmin = isCharacterAdmin && this.state.adminIds.length === 1

        const actionPermissions = {
            "forgiveCriminal": isCharacterAdmin || isCharacterMod,
            "acceptInvitation": isCharacterInvitee,
            "setOrganisationName": isCharacterAdmin,
            "setOrganisationDescription": isCharacterAdmin,
            "setTitleLeader": isCharacterAdmin,
            "setTitleManagers": isCharacterAdmin,
            "setTitleMembers": isCharacterAdmin,
            "showMembers": !(this.state.isCreated) && this.props.characterOrganisation,
            "addMembers": (isCharacterMod || isCharacterAdmin) && !(this.state.isCreated) && this.props.characterOrganisation,
            "setProtectedPanels": isCharacterAdmin && !(this.state.isCreated) && this.props.characterOrganisation,
            "deleteOrganisation": isCharacterAdmin && (characterOrganisation?.modIds.length === 0 && characterOrganisation?.memberIds.length === 0 && characterOrganisation?.adminIds.length === 1),
            "kickMember": highlightedCharacter?._id === this.props.character?._id && !isLastAdmin ? true
                : highlightedCharacter?._id === this.props.character._id && isLastAdmin ? false
                : isCharacterAdmin ? (isHighlightedCharacterMod || isHighlightedCharacterAdmin || isHighlightedCharacterMember || isHighlightedCharacterInvitee)
                : isCharacterMod ? (isHighlightedCharacterMember || isHighlightedCharacterInvitee)
                : false,
            "setAsMember": isCharacterAdmin && highlightedCharacter?.hint !== 'Invited',
            "setAsMod": isCharacterAdmin && highlightedCharacter?.hint !== 'Invited',
            "setAsAdmin": (isCharacterAdmin || isNoCharacterAdmin) && highlightedCharacter?.hint !== 'Invited',
            "inviteNewMember": isCharacterAdmin || isCharacterMod,
            "saveChanges": true
        }

        return actionPermissions[action]
    }

    render() {
        return (
            this.state.isCharacterPanelSelectShowing ? (
                <Menu options={this.props.characterPanels.map(panel => ({
                    ...panel,
                    text: panel.locationName,
                    hint: this.props.characterOrganisation?.protectedPanelIds?.find(panelId => (panelId === panel.panelId)) ? ' selected' : ''
                }))}
                    menuContainer="equipment"
                    optionChosen={this.selectCharacterPanel.bind(this)}
                    isWeightShowing={false}
                />
            ) : this.state.isMemberListShowing ? (
                <div className="conversation-tree-wizard">
                    <div className="conversation-tree-wizard-container">
                        <Menu
                            options={[ ...this.state.admins, ...this.state.mods, ...this.state.members, ...this.state.invitees ]}
                            keyPressed={this.onCharacterKeyPressed.bind(this)}
                            navigation={this.onNavigation.bind(this)}
                            menuContainer="conversation-tree-wizard-container"
                        />
                    </div>

                    <div className="conversation-tree-actions-container">
                        <p className={this.isEnabled('kickMember') ? "" : "hidden"}><span className="action">k</span> - Kick member</p>
                        <p className={this.isEnabled('setAsMember') ? "" : "hidden"}><span className="action">b</span> - Set as member</p>
                        <p className={this.isEnabled('setAsMod') ? "" : "hidden"}><span className="action">m</span> - Set as mod</p>
                        <p className={this.isEnabled('setAsAdmin') ? "" : "hidden"}><span className="action">a</span> - Set as admin</p>
                    </div>
                </div>
            ) : this.state.isCriminalsListShowing ? (
                <div className="conversation-tree-wizard">
                    <div className="conversation-tree-wizard-container">
                        <Menu
                            options={[ ...this.props.criminals?.map((criminal) => ({ ...criminal, text: criminal.perpertratorName })) ]}
                            // optionChosen={this.onCriminalChosen.bind(this)}
                            keyPressed={this.onCriminalChosen.bind(this)}
                            navigation={this.onNavigation.bind(this)}
                            menuContainer="conversation-tree-wizard-container"
                        />
                    </div>

                    <div className="conversation-tree-actions-container">
                        <p className={this.isEnabled('forgiveCriminal') ? "" : "hidden"}><span className="action">f</span> - Forgive Criminal</p>
                    </div>
                </div>
            ) : (
                <div className="conversation-tree-wizard">
                    {
                        this.state.isSetTextShowing ? (
                            <div className="conversation-wizard-edit-text-container">
                                <p className="conversation-wizard-exit"><span className="action">ESC</span> to exit</p>
                                <div className="conversation-wizard-edit-text">
                                    <TextInput onTextSubmit={this.onEditSubmit.bind(this)} text={this.state[this.state.isSetTextShowing]} exitKey="Escape" onExitKey={this.onEditExit.bind(this)} />
                                </div>
                            </div>
                        ) : ('')
                    }
                    {
                        !this.state.isMemberSelectShowing && !this.state.isCharacterPanelSelectShowing ? (
                            <div className="organisation-container">
                                <Menu options={[
                                        {
                                            text: 'Organisation Name: ',
                                            hint: this.state.organisationName
                                        },
                                        {
                                            text: 'Description: ',
                                            hint: this.state.description
                                        },
                                        {
                                            text: 'Title of Leader: ',
                                            hint: this.state.leaderTitle
                                        },
                                        {
                                            text: 'Title of Managers: ',
                                            hint: this.state.managerTitle
                                        },
                                        {
                                            text: 'Member Title: ',
                                            hint: this.state.memberTitle
                                        }
                                    ]}
                                    menuContainer="organisation-container"
                                    optionChosen={this.setOrganisationProperty.bind(this)}
                                />
                            </div>
                        ) : ('')
                    }

                    {
                        this.state.isMemberSelectShowing ? (
                            <div className="conversation-tree-wizard-container">
                                <Menu options={
                                        this.props.characters
                                            .filter(character => {
                                                const organisation = this.props.characterOrganisation

                                                if (!organisation) {
                                                    return true
                                                }

                                                const isInvitee = organisation.inviteeIds?.indexOf(character._id) > -1;
                                                const isMember = organisation.memberIds.indexOf(character._id) > -1;
                                                const isMod = organisation.modIds.indexOf(character._id) > -1;
                                                const isAdmin = organisation.adminIds.indexOf(character._id) > -1;

                                                return !(isInvitee || isMember || isMod || isAdmin)
                                            })
                                            .map(character => {
                                                const isInvitee = this.state.invitees?.find(member => character._id === member._id);
                                                const invitedTag = isInvitee

                                                return {
                                                    ...character,
                                                    hint: invitedTag ? ' Sending Invite' : ''
                                                }
                                            })
                                    }
                                    optionChosen={this.onCharacterChosen.bind(this)}
                                    keyPressed={this.onCharacterKeyPressed.bind(this)}
                                    navigation={this.onNavigation.bind(this)}
                                    menuContainer="conversation-tree-wizard-container"
                                />
                            </div>
                        ) : null
                    }

                    <div className="conversation-tree-actions-container">
                        { this.state.isMemberSelectShowing || this.state.isCharacterPanelSelectShowing ? 
                            (<div>
                                <p><span className={this.isEnabled('inviteNewMember') ? "action" : "action disabled"}>ENTER</span> - Select</p>
                            </div>) : ('')
                        }
                        {
                            !this.state.isMemberSelectShowing && !this.state.isCharacterPanelSelectShowing ? (
                                <>
                                    <p className={this.isEnabled('acceptInvitation') ? "" : "hidden"}><span className="action">A</span> - Accept invitation</p>
                                    <p className={this.isEnabled('showMembers') ? "" : "hidden"}><span className="action">.</span> - Show members</p>
                                    <p className={this.isEnabled('addMembers') ? "" : "hidden"}><span className="action">I</span> - Add members</p>
                                    <p className={this.isEnabled('showMembers') ? "" : "hidden"}><span className="action">/</span> - Show criminals</p>
                                    <p className={this.isEnabled('setProtectedPanels') ? "" : "hidden"}><span className="action">P</span> - Show protected panels</p>
                                    <p className={this.isEnabled('deleteOrganisation') ? "" : "hidden"}><span className="action">X</span> - <span className="error">Delete organisation</span></p>
                                </>
                            )
                            : null
                        }
                    </div>
                </div>
            )
        )
    }
}

const mapStateToProps = state => {
    const characters = selectCharacters(state);
    const character = selectCharacter(state);
    const organisations = selectOrganisations(state);
    const characterOrganisation = selectOpenOrganisation(state)
    const criminals = selectOrganisationCriminals(state, characterOrganisation?._id);
    const characterPanels = selectCharacterPanels(state);

    return {
        character,
        characters: characters.map(character => ({ ...character, text: character.name })),
        characterOrganisation,
        criminals,
        characterPanels
    }
}

export default connect(
    mapStateToProps,
    {
        hideAllMenus,
        deleteOrganisationAsync,
        createOrganisationAsync,
        updateOrganisation,
        forgiveCriminalAsync
    }
)(Organisation);