export const primeordealTreeSmellTypes = [
    "woody",
    "nutty",
    "smoky",
    "earthy",
    "resinous",
    "pungent",
    "pine",
    "aromatic",
    "balsamic",
    "resinous",
    "woody",
    "pungent",
    "enigmatic",
    "perfumed",
    "piney",
    "terpenic",
    "of haemorrhaged nectar",
    "of metallic pierced skin",
    "of blood and cumin",
    "of spiced entrails",
    "of victor's delight",
    "of a rotten infection",
    "of chypric infusions",
    "of emollient",
    "of embalming fluids",
    "and virtually woodsy",
    "like it's hiding its code",
    "of silicone ooze",
    "of electric moss",
    "of mineralic ambroxan",
    "of minted leather",
    "of iron-coated spittle",
    "of leaden leaves",
    "of clinical liquids",
    "of chlorine",
    "hateful",
    "of hidden warnings",
    "of plastic tiger lilies",
    "migraine-inspiring",
    "aldehydic",
    "of sparking jasmine",
    "of ozone and amber"
    "of tin foil and nut milk",
    "of milky pear slices",
    "of drained veins",
    "of a funeral procession",
    "of many a quiet being",
    "of a bent low heart and brain",
    "tense",
    "consternated",
    "of dark wonder",
]

export const primeordealTreeBranchTypes = [
	"calamime-coloured",
    "slender and snaking",
    "bulging",
    "lightly pulsating",
    "smooth, wet",
    "intestine-like",
    "loathly",
    "repulsive",
    "tall",
    "bumpy",
    "mushy",
    "pinkish",
    "phlegm-covered",
    "muscular",
    "segmented",
    "contracting",
    "many folds in its",
    "nutrient-sucking",
    "stinging",
    "bile-covered",
    "arterial",
    "enzymic",
    "improbable",
    "gas-emitting",
    "droning",
    "terrifying",
    "perverted",
    "bewildering",
    "shocking",
    "a network of vines shooting from its",
    "suppurating, web-like",
    "a red sap dripping from its",
    "congested, pulpy",
    "bristle-rife",
    "clot-rife",
    "sweltering",
    "membrane-like",
    "varicose",
]

export const primeordealTreeLeafTypes = [
	"needle-like",
    "long",
    "waxy",
    "pink",
    "pointed",
    "aromatic",
    "clustered",
    "linear",
    "tapered",
    "prickly",
    "pointy",
    "sharp",
    "needle-like",
    "long",
    "thin",
    "waxy",
    "aromatic",
    "resinous",
    "incarnadine",
    "red calke",
    "danger-spined",
    "tines on its",
    "clotted",
    "sorrowful",
    "eager",
    "unblithe",
    "loathesome",
    "wrathful",
    "reckless",
    "ruthless",
    "cruel",
    "harassing",
    "puce",
    "cerise",
]