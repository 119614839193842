import React from "react";
import { connect } from 'react-redux'

import { selectMaterialRecipes } from '../../../redux/selectors';

import { client } from '../../../services/client';

class MaterialRecipes extends React.Component {

    render() {
        const listItems = this.props.materialRecipes
            .filter(materialRecipe => (materialRecipe.materialType?.name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase()) > -1))
            .sort((a, b) => (a.materialType?.name.localeCompare(b.materialType?.name)))
            .map((materialRecipe) => {
                return (
                    <li key={materialRecipe._id}
                        onClick={() => {
                            return (this.props.onItemSelect(materialRecipe))
                        }}>
                        <span className={this.props.selectedItem?._id === materialRecipe._id ? "selected": ""}>{materialRecipe.materialType.name}</span>
                    </li>
                );
            })
        return (
            <ul>{listItems}</ul>
        )
    }
}

const mapStateToProps = state => {
    const materialRecipes = selectMaterialRecipes(state)

    return { materialRecipes }
}

export default connect(
    mapStateToProps,
    {  }
)(MaterialRecipes);