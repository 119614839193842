export const catfishFaceSentences = [
"It has whiskers and a long, slimy body.",
"It has a large, flat head with big eyes.",
"It has a long, protruding nose.",
"Its mouth is full of sharp teeth.",
"It has a dark brown or black body",
"Its eyes are beady and small, set far apart on a large, flat head.",
"A long, whiskerlike barb protrudes from each side of its wide mouth.",
"Oily skin with large pores",
"Visible blackheads and whiteheads",
"Excess sebum production",
"Acne breakouts",
"Dull and lifeless skin",
"Lack of radiance",

]

export const catfishBodySentences = [
"The body is elongated and streamlined, making it an efficient swimmer.",
"The body is covered in scales, which provide protection from predators and help the fish to move through the water.",
"The head is large and flattened,",
"long and slender body",
"smooth and slimy skin",
"whiskers around its mouth",
"large eyes on either side of its head",
"two small fins on its back",
"a long, flat tail",
"a wide mouth with sharp teeth",

]

export const catfishSoundWords = [
    "Murky",
    "Slithery",
    "Scaly",
    "Slimy",
    "Wet",
    "Glistening",
    "Rippling",
    "Flickering",
    "Lapping",
    "Gurgling",
    "humming",
    "swirling",
    "gurgling",
    "whooshing",
    "rippling",
    "burbling",
    "churning",
    "murmuring",
    "lapping",
    "babbling",
    "Vibrating",
    "Hissing",
    "Bubbling",
    "Slurping",
    "Smacking",
    "Gulping",
    "Chomping",
    "Chewing",
    "Gnawing",
    "Sucking",
    "flickering",
    "rippling",
    "tumbling",
    "cascading",
    "lapping",
    "eddying",
    "pattering",
    "plashing",
    "pouring",
    "gushing",
    "humming",
    "burbling",
    "rumbling",
    "grumbling",
    "mumbling",
    "growling",
    "snarling",
    "purring",
    "meowing",
    "yowling"
]

export const catfishFurWords = [
"Satin",
"Sleek",
"Soft",
"Supple",
"Glossy",
"resilient",
"tough",
"smooth",
"Soft",
"Supple",
"Heathy",
"Clean",
"Strong",
"Velvety",
"Bright",
"Reflective",
"Smooth",
"Wet",
"Soft",
"Silky",
"fluffy",
"downy",
"hairy",
"rugose",
"scaly",
"smooth",
"bumpy",
"fluffy",
"soft",
"downy",
"velvety",
"silky",
"slippery",
"glistening",
"iridescent",
"opalescent",
"lustrous",
"rough",
"scaly",
]

export const catfishSmellWords = [
"Pungent",
"Ammonia-like",
"Fishy",
"Rotten",
"Dirty",
"Muddy",
"Slippery",
"Slimy",
"Sticky",
"Strong",
"Uneasy",
"Foul",
"Unpleasant",
"Unappealing",
"Repulsive",
"Skanky",
"Nasty",
"Grody",
"Putrid",
"Rancid",
"acrid",
"Ammonia-like",
"powerful",
"pungent",
"rank",
"reeking",
"rotting",
"Rotten",
"Fishy",
"Stale",
"Oily",
"Moldy",
"rank",
"acrid",
"pungent",
"fumes",
"pungent",
"fishy",
"briny",
"salty",
"musky",
"rank",
"reeking",
"fetid",
"noisome",
"putrid",
]

export const catfishBehaviourSentences = [
"It is disoriented and lost",
"It is searching for something",
"It is afraid of something",
"It is eerily watching me with its large, unblinking eyes.",
"It is staying put and not moving at all",
"It is eerily fixated on you",
"It is constantly making strange noises",
"It is lurking in the shadows",
"It never blinks",
"It is watching you with its beady eyes",
"It is flicking its tongue in and out",
]