import React from "react";
import { connect } from 'react-redux'

import './NoCharactersNearbyError.css';

class NoCharactersNearbyError extends React.Component {

    render() {

        return (
            <div className="no-characters-nearby">
                There are no characters nearby
            </div>
        )
    }
}

const mapToStateProps = state => {
    return {}
}

export default connect(
    mapToStateProps,
    {
        
    }
)(NoCharactersNearbyError);