import React from "react";
import { connect } from 'react-redux'

import { selectWeaponRecipes } from '../../../redux/selectors';

import { client } from '../../../services/client';

class WeaponRecipes extends React.Component {

    render() {
        const listItems = this.props.weaponRecipes
            .filter(weaponRecipe => (weaponRecipe.weaponType?.name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase()) > -1))
            .sort((a, b) => (a.weaponType?.name.localeCompare(b.weaponType?.name)))
            .map((weaponRecipe) => {
                return (
                    <li key={weaponRecipe._id}
                        onClick={() => {
                            return (this.props.onItemSelect(weaponRecipe))
                        }}>
                        <span className={this.props.selectedItem?._id === weaponRecipe._id ? "selected": ""}>{weaponRecipe.weaponType.name}</span>
                    </li>
                );
            })
        return (
            <ul>{listItems}</ul>
        )
    }
}

const mapStateToProps = state => {
    const weaponRecipes = selectWeaponRecipes(state)

    return { weaponRecipes }
}

export default connect(
    mapStateToProps,
    {  }
)(WeaponRecipes);