import {
    CREATE_BOAT_LOADING,
    CREATE_BOAT_SUCCESS,
    CREATE_BOAT_FAILURE,
    GET_BOATS_LOADING,
    GET_BOATS_SUCCESS,
    GET_BOATS_FAILURE,
    ADD_BOAT,
    UPDATE_BOAT,
    REMOVE_BOAT,
    BEGIN_UPDATE_BOAT,
    UPDATE_BOAT_FAILED
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';

import { colyClient } from '../../services/Panel-initialisation.service';
import { client } from '../../services/client';

import { moveItemFailure } from './move-item.actions';

export const createBoatSuccess = payload => ({
    type: CREATE_BOAT_SUCCESS,
    payload
})

export const createBoatFailure = () => ({
    type: CREATE_BOAT_FAILURE
})

export const createBoatAsync = payload => dispatch => {
    const { _id, boatTypeId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'boat-instances',
        name: 'create',
        data: {
            boatTypeId
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getBoatsSuccess = payload => {
    return ({
        type: GET_BOATS_SUCCESS,
        payload
    })
}

export const getBoatsFailure = () => ({
    type: GET_BOATS_FAILURE
})

export const getBoatsAsync = payload => dispatch => {
    return client.service('boat-instances').find({ paginate: false, query: { panelId: payload.panelId, z: payload.z } })
        .then((response) => {
            response = response.map(boat => ({ ...boat, boatType: payload.boatTypes.find(type => type._id === boat.boatTypeId) }))
            dispatch(getBoatsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const addBoatToTileAsync = payload => dispatch => {
    const { boatInstance, tileId, tileTypeId } = payload;
    const { boatTypeId, quantity, recipeId, characterId, _id } = boatInstance;

    colyClient.room.send('doAction', {
        serviceName: 'boat-instances',
        commandType: 'create',
        data: {
            boatTypeId,
            quantity,
            tileId,
            tileTypeId,
            panelId: "",
            position: {},
            characterId: ""
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addDriverToBoatAsync = payload => dispatch => {
    const { boatInstance, characterId } = payload;
    const { createdAt, boatTypeId, quantity, recipeId, _id, passengerIds } = boatInstance;

    colyClient.room.send('doAction', {
        serviceName: 'boat-instances',
        commandType: 'patch',
        data: {
            _id,
            driverId: characterId,
            passengerIds
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addPassengerToBoatAsync = payload => dispatch => {
    const { boatInstance, characterId } = payload;
    const { createdAt, boatTypeId, quantity, passengerIds, recipeId, _id } = boatInstance;

    colyClient.room.send('doAction', {
        serviceName: 'boat-instances',
        commandType: 'patch',
        data: {
            _id,
            passengerIds
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addBoat = payload => {
    return ({
        type: ADD_BOAT,
        payload
    })
}

export const updateBoat = payload => {
    return ({
        type: UPDATE_BOAT,
        payload
    })
}

export const removeBoat = payload => {
    return ({
        type: REMOVE_BOAT,
        payload
    })
}

export const beginUpdateBoat = payload => {
    return ({
        type: BEGIN_UPDATE_BOAT,
        payload
    })
}

export const updateBoatFailed = payload => {
    return ({
        type: UPDATE_BOAT_FAILED,
        payload
    })
}