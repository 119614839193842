import { GET_META_LOADING, GET_META_SUCCESS } from '../actionTypes';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';

export const getMetaSuccess = payload => {
    return ({
        type: GET_META_SUCCESS,
        payload
    })
}

export const getMetaAsync = payload => dispatch => {
    return client.service('meta').find()
        .then(response => {
            return response[0];
        })
        .catch(error => {
            console.log(error);
            throw new Error(error);
        });
}