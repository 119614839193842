export const aloeSmellTypes = [
    "of distant shores",
    "of warmer climes",
    "of late summer",
    "of day-old fruit salad",
    "of sharp citrus",
    "of blooming fields",
    "of clean energy,"
    "Fresh",
    "of strange fruit",
    "of odd spice",
    "of a farmer's stall",
    "of Fresh Cut Grass",
    "Herbal",
    "Minty",
    "Earthy",
    "medicinal",
    "of laxative",
    "of stomach soother",
    "of digestive aid",
    "of a wooden crate",
    "Woody",
    "Fruity",
    "Bright",
    "Earthy",
    "Herbaceous",
    "Spicy",
    "Intense",
    "Sharp",
    "of sunburnt resin",
    "of leaking sunlight",
    "of lingering warmth",
    "of airy effervescence",
    "of lingering vegetable matter",
    "warm, nectary sweetness",
    "reminiscent of an early summer's day",
    "of a small eternity of summer",
    "of rot from the inside",
    "happily childish",
    "of frayed wires and syrup",
    "of regret",
    "of food waste",
    "of pulp and flour",
    "of hygienic paranoia",
    "of your first job",
    "of unpaid efforts",
    "of stagnant dreams",
]

export const aloeBranchTypes = [
	"Crisp",
    "Flexible",
    "Rigid",
    "Sturdy",
    "Slick",
    "Smooth",
    "Spongy",
    "smooth",
    "gently-sloping",
    "deep green",
    "long",
    "thick",
    "fleshy",
    "spiky",
    "Prickly",
    "fleshy",
    "spiky",
    "green",
    "waxy",
    "bitter",
    "sticky",
    "slimy",
    "fragrant",
    "Green",
    "Thick",
    "Juicy",
    "Sharp",
    "Crisp",
    "Dead",
    "Dying",
    "Brown",
    "ornamental",
    "chafing",
    "limp",
    "dehydrated",
]

export const aloeLeafTypes = [
	"Green",
    "Thick",
    "Slimy",
    "Juicy",
    "Spiky",
    "Gelled",
    "Sticky",
    "Smelly",
    "Bitter",
    "spiky",
    "rubbery",
    "smooth",
    "gooey",
    "oozy",
    "tacky",
    "viscous",
    "succulent",
    "spine",
    "green",
    "flowers",
    "tropical",
    "flecked",
    "fleshy",
    "gelatinous",
    "long and jagged",
    "thick and teeth-lined",
    "dangerous and mottled",
    "mite-laden",
    "aphids hidden in its",
    "strangely shaped",
    "pups ready for cutting under its",
    "oozing",
    "pulpy",
    "grasping",
]