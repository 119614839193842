export const trollFaceSentences = [
"Fangs bared, it gleefully licks its lips.",
"Small, beady eyes peer out from a large, bulbous nose.",
"A scowl is plastered on its face, revealing several missing teeth.",
"Disgusting, drooling, wart-covered face.",
"Huge, bulbous nose.",
"Small, beady eyes.",
"Yellowed, rotting teeth.",
"Cracked, slimy skin.",
"Exposed, fleshy gums.",
"Two blood-red eyes that seem to pierce your soul.",
"A mouth full of sharp, yellowed teeth.",
"A bulbous nose that looks like it's been broken one too many times.",
"A bulbous, green nose.",
"Two beady, black eyes.",
"A mouthful of sharp, yellow teeth.",
"Slimy, green skin.",
"A long, unkempt beard.",
"Small, furry ears.",
"Fierce eyes glaring from beneath a mass of matted hair.",
"A bulbous nose dripping with snot.",
"Rows of jagged, yellowed teeth.",
"A tongue that's black and slimy.",
]

export const trollBodySentences = [
"It's body is huge and bulky, covered in coarse hair.",
"It's body is lumpy and deformed, with an overgrown gut.",
"Its arms are disproportionately long and its hands end in sharp claws.",
"His body is hunched and covered in patches of coarse hair.",
"His long, slimy arms end in sharp claws.",
"His bulbous nose is constantly dripping with mucus.",
"Hardy and muscular, with skin as tough as tree bark.",
"Pungent body odor that can knock out a grown man at ten paces.",
"Gristly, sharp teeth that can rend flesh and bone with ease.",
]

export const trollSoundWords = [
"booming",
"echoing",
"thundering",
"deep",
"rumbling",
"vibrating",
"low",
"pressuring",
"muffled",
"Gravelly",
"Growling",
"Snarling",
"Clutching",
"Desperate",
"Pleading",
"Begging",
"conspiring",
"Sneering",
"Snickering",
"cackling",
"Hissing",
"Snarling",
"Growling",
"Roaring",
"Clamoring",
"Rumbling",
"Grunting",
"Snorting",
"bellowing",
"thundering",
"Roaring",
"Berating",
"Snarling",
"Growling",
"vitriolic",
"Ranting",
"Raving",
"Spouting",
"Swearing",
"Foaming",
]

export const trollFurWords = [
    "Warty",
    "slimy",
    "dry",
    "scaly",
    "patchy",
    "discolored",
    "ragged",
    "mottled",
    "bumpy",
    "thick",
    "Sticky",
    "Oily",
    "Greasy",
    "Smelly",
    "Crusty",
    "Scaly",
    "Warty",
    "Hairy",
    "Coarse",
    "Ruff",
    "hairy",
    "course",
    "oily",
    "smelly",
    "green",
    "bumpy",
    "crusty",
    "scaly",
    "slimy",
    "warty"
]

export const trollSmellWords = [
"nauseating",
    "sickening",
    "putrid",
    "pungent",
    "rank",
    "fetid",
    "reeking",
    "stinking",
    "strong",
    "intensely",
    "sickening",
    "nauseating",
    "rank",
    "reeking",
    "foul",
    "nasty",
    "putrid",
    "stinking",
    "skanky",
    "funky",
    "ripe",
    "decayed",
    "Pungent",
    "Acrid",
    "Sharp",
    "Putrid",
    "musty",
    "Foul",
    "Rancid",
    "Repulsive",
    "nauseating",
    "Rancid",
    "Pungent",
    "Acrid",
    "Foul",
]

export const trollBehaviourSentences = [
"it is glaring at you",
"it is staring at you",
"it is leering at you",
"it is drooling at you",
"it is grinning at you",
"slow and lumbering, but always watching",
"very shy but also very curious",
"always happy and smiling, but with an eerie gleam in its eye",
"always fretting and fidgeting, as if it can't wait to get its hands on you",
"it is slouching forward menacingly",
"it is scratching its backside vigorously",
"it is blowing its nose loudly on its sleeve",
"it is making strange noises and movements",
"it is pacing back and forth",
"it is glaring at you",
"it is baring its teeth",
"it is shaking its head",
"it is grunting and growling",
]