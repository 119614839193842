export const primeordealVineSmellTypes = [
	"Pungent",
    "Dank",
    "Moldy",
    "Mossy",
    "Musky",
    "Stale",
    "Rancid",
    "Putrid",
    "acrid",
    "rank",
    "sour",
    "fecund",
    "delicious",
    "overpowering",
    "Putrid",
    "Rancid",
    "Pleasant",
    "Aromatic",
    "Dank",
    "of Secret places",
    "Sensual",
    "Alluring",
    "of ichor",
    "of reconstituted meat",
    "of tart physalis",
    "of power",
    "dangerous",
    "of chaos",
    "of long-forgotten cables",
    "inclined towards sorrow",
    "of laboured breath",
    "of all that would be",
    "of weed-violated stone",
    "of salt-crusted paper",
    "blood-soaked",
    "of orchards and servers",
    "of detached cleanliness",
    "of sandalwood sweat",
    "of cankerous growth",
    "of lingonberry and stale ginger",
    "of tajin-laced rinds",
    "of styrofoam fungal growth",
    "of tobacco and pheromones",
    "ruinous",
    "of agitated metal",
    "of wringing hands",
    "of a long-awaited sleep",
    "thrilling",
    "of slumped masses",
    "of sick-boxes",
    "of wild-onion and bitumen",
    "of a latrine",
]

export const primeordealVineBranchTypes = [
	"deadly",
    "poison",
    "vicious",
    "evil",
    "cruel",
    "forbidden",
    "pale pink",
    "peeling",
    "prickly",
    "fleshy",
    "tubular",
    "brutal",
    "varicose",
    "pulpy veins running through its",
    "weird",
    "knotted vines spewing from its",
    "thorny",
    "creepy",
    "overgrown",
    "primeval",
    "ancient",
    "dark",
    "weighty",
    "swelling vines for",
    "thick bark on the",
    "sinewy",
    "gnarled",
    "twisting",
    "writhing",
    "leafy",
    "obscene",
    "fragmented",
    "bulging",
    "guady",
    "chaotic",
    "callus-obscured",
    "carbuncled",
    "callused",
    "pale and sickly",
    "thin mucous on its",
    "irritable",
    "leprous",
    "scalded",
    "weals covering its",
    "pinioned, vining",
    "grasping",
    "tangled",
    "bloated",
    "goitred",
    "inflammed",
    "wen-covered",
    "turgid",
    "tumid, protruded",
    "gerontic",
    "scaffolded",
    "a strange, pink bark",
    "an oddly soft cork",
    "an off-putting give in its",
    "blood-wove",
    "angry, dark",
]

export const primeordealVineLeafTypes = [
    "crown shaped",
    "unexpectedly normal",
    "massive",
    "overgrown",
    "pale red",
    "luminous pink",
    "raw",
    "skin-like",
    "unhappy",
    "webbed",
    "tumourose",
    "arsenic",
    "polyp",
    "excrescent",
    "scabbed",
    "canker",
    "disease-prone",
    "polyp-laden",
    "sap-stricken",
    "oedema-puffed",
    "bloodshot",
    "supperating",
    "gammy",
    "ruptured, puss-covered",
    "congested",
    "festering",
]