import React from "react";
import { connect } from 'react-redux'

import { destroyConstructionAsync } from '../../../redux/actions/construction.actions';
import {
    selectHighlight,
    selectCharacter,
    selectConstructions,
    selectNearbyConstructions,
    selectCharacterTools
} from '../../../redux/selectors';
import { client } from '../../../services/client';

import './ConstructionActions.css';

const uiText = {
    DOOR: 'Door',
    WALL: 'Wall'
}

class ConstructionActions extends React.Component {

    previousCharacter;
    previousTiles;

    componentDidMount() {
    }

    onItemClick(constructionInstance) {
        this.props.destroyConstructionAsync(constructionInstance)
    }

    isNecessaryToolInInventory(item) {
        let isIncorrectTool = false;
        item.recipes[0].destructionTools?.forEach(requiredTool => {
            if (!this.props.characterTools.find(tool => tool.toolTypeId === requiredTool.toolTypeId)) {
                isIncorrectTool = true;
            }
        })

        return !isIncorrectTool;
    }

    render() {
        const constructionItems = Object.values(this.props.constructions).map((item) => {
            const destroyButton = (
                !this.props.highlight &&
                this.isDestroyable(item.construction.name))
            ? (
                <span className="action"
                    onClick={() => (this.onItemClick(item))}>
                    Destroy&nbsp;
                    <span className="energy-cost">
                        [{item.recipes[0].destructionCost}]
                    </span>
                </span>
            ) : '';
            return (
                <li key={item._id}>
                    {item?.name || item.construction?.name || uiText[item?.construction?.name]}
                     ({item.position.x},&nbsp;{item.position.y})

                    <p className="destroy-construction-action">
                        {destroyButton}
                    </p>
                </li>
            )
        })
        return (
            <div>
                <ul>{constructionItems}</ul>
            </div>
        )
    }

    isDestroyable(constructionTypeName) {
        return constructionTypeName.toLowerCase().indexOf('Path') === -1;
    }
}

const mapStateToProps = state => {
    const highlight = selectHighlight(state);
    const character = selectCharacter(state);

    const constructions = selectNearbyConstructions(state, highlight ? { position: { ...highlight } } : character);
    const characterTools = selectCharacterTools(state);

    return { highlight, character, constructions, characterTools }
}

export default connect(
    mapStateToProps,
    {
        destroyConstructionAsync
    }
)(ConstructionActions);