import React from "react";
import { connect } from 'react-redux'

import { clearPlotAsync, tileTypes, createTileAsync } from '../../redux/actions/tile.actions';
import { createToolAsync } from '../../redux/actions/tool.actions';
import { createMachineAsync } from '../../redux/actions/machine.actions';
import { createMetalAsync } from '../../redux/actions/metal.actions';
import { createMaterialAsync } from '../../redux/actions/material.actions';
import { plantSeedAsync, cultivatePlantAsync, harvestPlantAsync } from '../../redux/actions/plant.actions';
import { buildConstructionAsync } from '../../redux/actions/construction.actions';
import { cookFoodAsync, cookAnotherFoodAsync, eatFoodAsync, deleteFoodAsync } from '../../redux/actions/food.actions';
import { gatherMineralAsync } from '../../redux/actions/mineral.actions';
import { fightCharacterAsync } from '../../redux/actions/character.actions';
import { createLockAsync } from '../../redux/actions/lock.actions';
import { createWritingSurfaceAsync } from '../../redux/actions/writing-surface.actions';
import { createWritingImplementAsync } from '../../redux/actions/writing-implement.actions';
import { createFurnitureAsync } from '../../redux/actions/furniture.actions';
import { createWeaponAsync } from '../../redux/actions/weapon.actions';
import { createArmourAsync } from '../../redux/actions/armour.actions';
import { createClothingAsync } from '../../redux/actions/clothing.actions';
import { createJewelleryAsync } from '../../redux/actions/jewellery.actions';
import { saveLastCraft, closeCrafting } from '../../redux/actions/keyboard-shortcuts.actions';
import { selectPlantInstanceIdAtTile, selectCharacter, selectCharacterFood, selectMineralAtPosition, selectTileTypeByName } from '../../redux/selectors';

import ConstructionTypes from './construction-types/ConstructionTypes';
import FoodRecipes from './food-recipes/FoodRecipes';
import MachineRecipes from './machine-recipes/MachineRecipes';
import ToolRecipes from './tool-recipes/ToolRecipes';
import MetalRecipes from './metal-recipes/MetalRecipes';
import MaterialRecipes from './material-recipes/MaterialRecipes';
import LockRecipes from './lock-recipes/LockRecipes';
import WritingSurfaceRecipes from './writing-surface-recipes/WritingSurfaceRecipes';
import WritingImplementRecipes from './writing-implement-recipes/WritingImplementRecipes';
import FurnitureRecipes from './furniture-recipes/FurnitureRecipes';
import TileRecipes from './tile-recipes/TileRecipes';
import WeaponRecipes from './weapon-recipes/WeaponRecipes';
import ArmourRecipes from './armour-recipes/ArmourRecipes';
import ClothingRecipes from './clothing-recipes/ClothingRecipes';
import JewelleryRecipes from './jewellery-recipes/JewelleryRecipes';

import './Crafting.css';

class Crafting extends React.Component {
    initialState = {
        isConstructionTypesShowing: false,
        isFoodRecipesShowing: false,
        isMachineRecipesShowing: false,
        isToolRecipesShowing: false,
        isMetalRecipesShowing: false,
        isMaterialRecipesShowing: false,
        isLockRecipesShowing: false,
        isWritingSurfaceRecipesShowing: false,
        isWritingImplementRecipesShowing: false,
        isFurnitureRecipesShowing: false,
        isTileRecipesShowing: false,
        isWeaponRecipesShowing: false,
        isArmourRecipesShowing: false,
        isClothingRecipesShowing: false,
        isJewelleryRecipesShowing: false,
        itemSelectCallback: (item) => {},
        isSuccessShowing: false,
        selectedItem: null,
        search: ''
    };

    actions = [
        {
            name: 'Constructions',
            selectionKey: 'isConstructionTypesShowing',
            callback: () => {
                this.setState({
                    ...this.initialState,
                    isConstructionTypesShowing: true,
                    itemSelectCallback: ({ _id, constructionType }) => {
                        const constructionTypeId = constructionType._id;
                        return this.props.buildConstructionAsync({ constructionTypeId });
                    }
                });
            },
            setItemSelectCallback: () => {
                this.setState({
                    itemSelectCallback: ({ _id, constructionType }) => {
                        const constructionTypeId = constructionType._id;
                        return this.props.buildConstructionAsync({ constructionTypeId });
                    }
                });
            }
        }, {
            name: 'Food',
            selectionKey: 'isFoodRecipesShowing',
            callback: () => {
                this.setState({
                    ...this.initialState,
                    isFoodRecipesShowing: true,
                    itemSelectCallback: ({ _id, foodTypeId }) => {
                        const characterFoodInstance = this.props.characterFood.find(foodInstance => foodInstance.recipeId === _id);
                        if (characterFoodInstance) {
                            return this.props.cookAnotherFoodAsync({ ...characterFoodInstance });
                        } else {
                            return this.props.cookFoodAsync({ _id, foodTypeId });
                        }
                    }
                })
            },
            setItemSelectCallback: () => {
                 this.setState({
                    itemSelectCallback: ({ _id, foodTypeId }) => {
                        const characterFoodInstance = this.props.characterFood.find(foodInstance => foodInstance.recipeId === _id);
                        if (characterFoodInstance) {
                            return this.props.cookAnotherFoodAsync({ ...characterFoodInstance });
                        } else {
                            return this.props.cookFoodAsync({ _id, foodTypeId });
                        }
                    }
                })
            }
        }, {
            name: `Machines`,
            selectionKey: 'isMachineRecipesShowing',
            callback: () => {
                this.setState({
                    ...this.initialState,
                    isMachineRecipesShowing: true,
                    itemSelectCallback: ({ _id, machineTypeId }) => {
                        return this.props.createMachineAsync({ _id, machineTypeId });
                    }
                })
            },
            setItemSelectCallback: () => {
                this.setState({
                    itemSelectCallback: ({ _id, machineTypeId }) => {
                        return this.props.createMachineAsync({ _id, machineTypeId });
                    }
                })
            }
        }, {
            name: `Tools`,
            selectionKey: 'isToolRecipesShowing',
            callback: () => {
                this.setState({
                    ...this.initialState,
                    isToolRecipesShowing: true,
                    itemSelectCallback: ({ _id, toolTypeId }) => {
                        return this.props.createToolAsync({ _id, toolTypeId });
                    }
                })
            },
            setItemSelectCallback: () => {
                 this.setState({
                    itemSelectCallback: ({ _id, toolTypeId }) => {
                        return this.props.createToolAsync({ _id, toolTypeId });
                    }
                })
            }
        }, {
            name: `Metals`,
            selectionKey: 'isMetalRecipesShowing',
            callback: () => {
                this.setState({
                    ...this.initialState,
                    isMetalRecipesShowing: true,
                    itemSelectCallback: ({ _id, metalTypeId }) => {
                        return this.props.createMetalAsync({ _id, metalTypeId });
                    }
                })
            },
            setItemSelectCallback: () => {
                 this.setState({
                    itemSelectCallback: ({ _id, metalTypeId }) => {
                        return this.props.createMetalAsync({ _id, metalTypeId });
                    }
                })
            }
        }, {
            name: `Materials`,
            selectionKey: 'isMaterialRecipesShowing',
            callback: () => {
                this.setState({
                    ...this.initialState,
                    isMaterialRecipesShowing: true,
                    itemSelectCallback: ({ _id, materialTypeId }) => {
                        return this.props.createMaterialAsync({ _id, materialTypeId });
                    }
                })
            },
            setItemSelectCallback: () => {
                 this.setState({
                    itemSelectCallback: ({ _id, materialTypeId }) => {
                        return this.props.createMaterialAsync({ _id, materialTypeId });
                    }
                })
            }
        }, {
            name: `Locks`,
            selectionKey: 'isLockRecipesShowing',
            callback: () => {
                this.setState({
                    ...this.initialState,
                    isLockRecipesShowing: true,
                    itemSelectCallback: ({ _id, lockTypeId }) => {
                        return this.props.createLockAsync({ _id, lockTypeId });
                    }
                })
            },
            setItemSelectCallback: () => {
                 this.setState({
                    itemSelectCallback: ({ _id, lockTypeId, direction }) => {
                        return this.props.createLockAsync({ _id, lockTypeId, direction });
                    }
                })
            }
        }, {
            name: `Writing surfaces`,
            selectionKey: 'isWritingSurfaceRecipesShowing',
            callback: () => {
                this.setState({
                    ...this.initialState,
                    isWritingSurfaceRecipesShowing: true,
                    itemSelectCallback: ({ _id, writingSurfaceTypeId }) => {
                        return this.props.createWritingSurfaceAsync({ _id, writingSurfaceTypeId });
                    }
                })
            },
            setItemSelectCallback: () => {
                 this.setState({
                    itemSelectCallback: ({ _id, writingSurfaceTypeId }) => {
                        return this.props.createWritingSurfaceAsync({ _id, writingSurfaceTypeId });
                    }
                })
            }
        }, {
            name: `Writing implements`,
            selectionKey: 'isWritingImplementRecipesShowing',
            callback: () => {
                this.setState({
                    ...this.initialState,
                    isWritingImplementRecipesShowing: true,
                    itemSelectCallback: ({ _id, writingImplementTypeId }) => {
                        return this.props.createWritingImplementAsync({ _id, writingImplementTypeId });
                    }
                })
            },
            setItemSelectCallback: () => {
                 this.setState({
                    itemSelectCallback: ({ _id, writingImplementTypeId }) => {
                        return this.props.createWritingImplementAsync({ _id, writingImplementTypeId });
                    }
                })
            }
        }, {
            name: `Furniture`,
            selectionKey: 'isFurnitureRecipesShowing',
            callback: () => {
                this.setState({
                    ...this.initialState,
                    isFurnitureRecipesShowing: true,
                    itemSelectCallback: ({ _id, furnitureTypeId }) => {
                        return this.props.createFurnitureAsync({ _id, furnitureTypeId });
                    }
                })
            },
            setItemSelectCallback: () => {
                 this.setState({
                    itemSelectCallback: ({ _id, furnitureTypeId }) => {
                        return this.props.createFurnitureAsync({ _id, furnitureTypeId });
                    }
                })
            }
        }, {
            name: `Containers`,
            selectionKey: 'isTileRecipesShowing',
            callback: () => {
                this.setState({
                    ...this.initialState,
                    isTileRecipesShowing: true,
                    itemSelectCallback: ({ _id, tileTypeId }) => {
                        return this.props.createTileAsync(tileTypeId);
                    }
                })

            },
            setItemSelectCallback: () => {
                 this.setState({
                    itemSelectCallback: ({ _id, tileTypeId }) => {
                        return this.props.createTileAsync(tileTypeId);
                    }
                })
            }
        }, {
            name: `Weapons`,
            selectionKey: 'isWeaponRecipesShowing',
            callback: () => {
                this.setState({
                    ...this.initialState,
                    isWeaponRecipesShowing: true,
                    itemSelectCallback: ({ _id, weaponTypeId }) => {
                        return this.props.createWeaponAsync({ _id, weaponTypeId });
                    }
                })

            },
            setItemSelectCallback: () => {
                 this.setState({
                    itemSelectCallback: ({ _id, weaponTypeId }) => {
                        return this.props.createWeaponAsync({ _id, weaponTypeId });
                    }
                })
            }
        }, {
            name: `Armour`,
            selectionKey: 'isArmourRecipesShowing',
            callback: () => {
                this.setState({
                    ...this.initialState,
                    isArmourRecipesShowing: true,
                    itemSelectCallback: ({ _id, armourTypeId }) => {
                        return this.props.createArmourAsync({ _id, armourTypeId });
                    }
                })
            },
            setItemSelectCallback: () => {
                 this.setState({
                    itemSelectCallback: ({ _id, armourTypeId }) => {
                        return this.props.createArmourAsync({ _id, armourTypeId });
                    }
                })
            }
        }, {
            name: `Clothing`,
            selectionKey: 'isClothingRecipesShowing',
            callback: () => {
                this.setState({
                    ...this.initialState,
                    isClothingRecipesShowing: true,
                    itemSelectCallback: ({ _id, clothingTypeId }) => {
                        return this.props.createClothingAsync({ _id, clothingTypeId });
                    }
                })
            },
            setItemSelectCallback: () => {
                 this.setState({
                    itemSelectCallback: ({ _id, clothingTypeId }) => {
                        return this.props.createClothingAsync({ _id, clothingTypeId });
                    }
                })
            }
        }, {
            name: `Jewellery`,
            selectionKey: 'isJewelleryRecipesShowing',
            callback: () => {
                this.setState({
                    ...this.initialState,
                    isJewelleryRecipesShowing: true,
                    itemSelectCallback: ({ _id, jewelleryTypeId }) => {
                        return this.props.createJewelleryAsync({ _id, jewelleryTypeId });
                    }
                })
            },
            setItemSelectCallback: () => {
                 this.setState({
                    itemSelectCallback: ({ _id, jewelleryTypeId }) => {
                        return this.props.createJewelleryAsync({ _id, jewelleryTypeId });
                    }
                })
            }
        }
    ];

    constructor() {
        super();
        this.state = { ...this.initialState };
    }

    flashSuccess() {
        this.setState({ isSuccessShowing : true });

        setTimeout(() => (this.setState({ isSuccessShowing: false })), 1000)
    }

    onItemSelect(item) {
        if (!item.recipes) {
            item.recipes = [{
                ingredients: item.ingredients,
                tools: item.tools,
                machines: item.machines
            }]
        }
        this.setState({selectedItem : item});
    }

    searchCraftingItems(search) {
        this.setState({ search });

        this.setState({
            isConstructionTypesShowing: !!search,
            isFoodRecipesShowing: !!search,
            isMachineRecipesShowing: !!search,
            isToolRecipesShowing: !!search,
            isMetalRecipesShowing: !!search,
            isMaterialRecipesShowing: !!search,
            isLockRecipesShowing: !!search,
            isWritingSurfaceRecipesShowing: !!search,
            isWritingImplementRecipesShowing: !!search,
            isFurnitureRecipesShowing: !!search,
            isTileRecipesShowing: !!search,
            isWeaponRecipesShowing: !!search,
            isArmourRecipesShowing: !!search,
            isClothingRecipesShowing: !!search,
            isJewelleryRecipesShowing: !!search,
        })
    }

    getEnergyCost(item) {
        return item?.buildingCost
            || item?.foodType?.cookingCost
            || item?.machineType?.craftingCost
            || item?.toolType?.craftingCost
            || item?.metalType?.craftingCost
            || item?.materialType?.craftingCost
            || item?.lockType?.craftingCost
            || item?.writingSurfaceType?.craftingCost
            || item?.writingImplementType?.craftingCost
            || item?.furnitureType?.craftingCost
            || item?.tileType?.buildingCost
            || item?.weaponType?.craftingCost
            || item?.armourType?.craftingCost
            || item?.clothingType?.craftingCost
            || item?.jewelleryType?.craftingCost
            || 0;
    }

    getSkillType(item) {
        return item?.skillType
            || item?.foodType?.skillType
            || item?.machineType?.skillType
            || item?.toolType?.skillType
            || item?.metalType?.skillType
            || item?.materialType?.skillType
            || item?.lockType?.skillType
            || item?.writingSurfaceType?.skillType
            || item?.writingImplementType?.skillType
            || item?.furnitureType?.skillType
            || item?.tileType?.skillType
            || item?.weaponType?.skillType
            || item?.armourType?.skillType
            || item?.clothingType?.skillType
            || item?.jewelleryType?.skillType
            || { name: 'Unskilled' };
    }

    render() {
        const actions = this.actions.map((action, index) => {
            return (
                <li key={index}
                    onClick={() => (action.callback())}>
                    <span className={this.state[action.selectionKey] ? "selected": ""}>
                        {action.name}
                    </span>
                </li>
            )
        })

        const recipes = this.state.selectedItem?.recipes?.map((recipe, index) => {
            const ingredients = recipe.ingredients ? recipe.ingredients.map((ingredient, index) => {
                return (
                    <li key={index}>
                        <span className="quantity">{ingredient.quantity}</span> {ingredient.ingredientType?.name} {ingredient.plant?.name} {ingredient.materialType?.name}
                        {ingredient.plantType && <span>({ingredient.plantType.name})</span>}
                    </li>
                )
            }) : ('');

            const machines = recipe.machines ? recipe.machines.map((machine, index) => {
                return (
                    <li key={index}>
                        <span className="quantity">{index > 0 ? (machine.index === recipe.machines[index - 1].index ? 'OR' : 'AND') : ''}</span>
                        {machine.machineType.name}
                    </li>
                )
            }) : ('');

            const tools = recipe.tools ? recipe?.tools?.map((tool, index) => {
                return (
                    <li key={index}>
                        <span className="quantity">{index > 0 ? tool.index === undefined ? 'AND' : (tool.index === recipe.tools[index - 1].index ? 'OR' : 'AND') : ''}</span>
                        {tool.toolType.name}</li>
                )
            }) : ('');

            return (
                <div key={index} className="recipe">
                    {
                        recipe.ingredients?.length > 0 ? 
                            (
                                <div>
                                    <div>Ingredients</div>
                                    <ul>{ingredients}</ul>
                                </div>
                            ) : ('')
                    }

                    {
                        recipe.machines?.length > 0 ? 
                            (
                                <div>
                                    <p>Machines</p>
                                    <ul>{machines}</ul>
                                </div>
                            ) : ('')
                    }

                    {
                        recipe.tools?.length > 0 ? 
                            (
                                <div>
                                    <p>Tools</p>
                                    <ul>{tools}</ul>
                                </div>
                            ) : ('')
                    }
                </div>
            )
        })

        const buildingTools = this.state.selectedItem?.buildingTools?.map((tool, index) => {
            return (
                <li>{tool.name}</li>
            )
        })

        return (
            <div className="crafting-container">
                <div className="crafting-title">
                    &nbsp;[ <span className="title">Crafting</span> ]&nbsp;

                    <div className="crafting-close-button"
                        onClick={() => (this.props.closeCrafting())}>
                        <span className="shortcut">ESC</span> to exit
                    </div>
                </div>
                <div className="crafting">
                    <div className={this.state.isSuccessShowing ? "success showing" : "success"}>Success</div>

                    <ul className="sub-menu">
                        <input
                            className="search"
                            onChange={e => this.searchCraftingItems(e.target.value)}
                            value={this.state.search}
                            placeholder="search"
                            type="text"/>
                        {actions}
                    </ul>

                    <div className="sub-menu">
                        {this.state.isConstructionTypesShowing &&
                            <ConstructionTypes
                                onItemSelect={(item) => {
                                    this.actions[0].setItemSelectCallback();
                                    this.onItemSelect(item);
                                }}
                                searchTerm={this.state.search}
                                selectedItem={this.state.selectedItem} />
                        }
                        {this.state.isFoodRecipesShowing &&
                            <FoodRecipes
                                onItemSelect={(item) => {
                                    this.actions[1].setItemSelectCallback();
                                    this.onItemSelect(item);
                                }}
                                searchTerm={this.state.search}
                                selectedItem={this.state.selectedItem} />
                        }
                        {this.state.isMachineRecipesShowing &&
                            <MachineRecipes
                                onItemSelect={(item) => {
                                    this.actions[2].setItemSelectCallback();
                                    this.onItemSelect(item);
                                }}
                                searchTerm={this.state.search}
                                selectedItem={this.state.selectedItem} />
                        }
                        {this.state.isToolRecipesShowing &&
                            <ToolRecipes
                                onItemSelect={(item) => {
                                    this.actions[3].setItemSelectCallback();
                                    this.onItemSelect(item);
                                }}
                                searchTerm={this.state.search}
                                selectedItem={this.state.selectedItem} />
                        }
                        {this.state.isMetalRecipesShowing &&
                            <MetalRecipes
                                onItemSelect={(item) => {
                                    this.actions[4].setItemSelectCallback();
                                    this.onItemSelect(item);
                                }}
                                searchTerm={this.state.search}
                                selectedItem={this.state.selectedItem} />
                        }
                        {this.state.isMaterialRecipesShowing &&
                            <MaterialRecipes
                                onItemSelect={(item) => {
                                    this.actions[5].setItemSelectCallback();
                                    this.onItemSelect(item);
                                }}
                                searchTerm={this.state.search}
                                selectedItem={this.state.selectedItem} />
                        }
                        {this.state.isLockRecipesShowing &&
                            <LockRecipes
                                onItemSelect={(item) => {
                                    this.actions[6].setItemSelectCallback();
                                    this.onItemSelect(item);
                                }}
                                searchTerm={this.state.search}
                                selectedItem={this.state.selectedItem} />
                        }
                        {this.state.isWritingSurfaceRecipesShowing &&
                            <WritingSurfaceRecipes
                                onItemSelect={(item) => {
                                    this.actions[7].setItemSelectCallback();
                                    this.onItemSelect(item);
                                }}
                                searchTerm={this.state.search}
                                selectedItem={this.state.selectedItem} />
                        }
                        {this.state.isWritingImplementRecipesShowing &&
                            <WritingImplementRecipes
                                onItemSelect={(item) => {
                                    this.actions[8].setItemSelectCallback();
                                    this.onItemSelect(item);
                                }}
                                searchTerm={this.state.search}
                                selectedItem={this.state.selectedItem} />
                        }
                        {this.state.isFurnitureRecipesShowing &&
                            <FurnitureRecipes
                                onItemSelect={(item) => {
                                    this.actions[9].setItemSelectCallback();
                                    this.onItemSelect(item);
                                }}
                                searchTerm={this.state.search}
                                selectedItem={this.state.selectedItem} />
                        }
                        {this.state.isTileRecipesShowing &&
                            <TileRecipes
                                onItemSelect={(item) => {
                                    this.actions[10].setItemSelectCallback();
                                    this.onItemSelect(item);
                                }}
                                searchTerm={this.state.search}
                                selectedItem={this.state.selectedItem} />
                        }
                        {this.state.isWeaponRecipesShowing &&
                            <WeaponRecipes
                                onItemSelect={(item) => {
                                    this.actions[11].setItemSelectCallback();
                                    this.onItemSelect(item);
                                }}
                                searchTerm={this.state.search}
                                selectedItem={this.state.selectedItem} />
                        }
                        {this.state.isArmourRecipesShowing &&
                            <ArmourRecipes
                                onItemSelect={(item) => {
                                    this.actions[12].setItemSelectCallback();
                                    this.onItemSelect(item);
                                }}
                                searchTerm={this.state.search}
                                selectedItem={this.state.selectedItem} />
                        }
                        {this.state.isClothingRecipesShowing &&
                            <ClothingRecipes
                                onItemSelect={(item) => {
                                    this.actions[13].setItemSelectCallback();
                                    this.onItemSelect(item);
                                }}
                                searchTerm={this.state.search}
                                selectedItem={this.state.selectedItem} />
                        }
                        {this.state.isJewelleryRecipesShowing &&
                            <JewelleryRecipes
                                onItemSelect={(item) => {
                                    this.actions[14].setItemSelectCallback();
                                    this.onItemSelect(item);
                                }}
                                searchTerm={this.state.search}
                                selectedItem={this.state.selectedItem} />
                        }
                    </div>

                    {this.state.selectedItem ?
                        (
                            <div className="confirmation">
                                <div>Energy Cost <span className="quantity">{this.getEnergyCost(this.state.selectedItem)}</span></div>
                                <div>Skill Type <span className={`character-skill ${this.getSkillType(this.state.selectedItem).name.toLowerCase()}`}>{this.getSkillType(this.state.selectedItem).name}</span></div>

                                <ul>
                                    {buildingTools}
                                </ul>

                                <ul>
                                    {recipes}
                                </ul>

                                <div className={ this.state.isCrafting ? "craft-button--disabled" : "craft-button" }
                                    onClick={() => {
                                        if (this.state.isCrafting) {
                                            return;
                                        }

                                        this.setState({ isCrafting: true })
                                        this.props.saveLastCraft({
                                            callback: this.state.itemSelectCallback,
                                            args: this.state.selectedItem
                                        });

                                        this.state.itemSelectCallback(this.state.selectedItem)
                                            .then(() => (this.flashSuccess()))
                                            .finally(() => (this.setState({ isCrafting: false })));
                                    }}>
                                    Craft
                                </div>
                            </div>
                        ) : ('')
                    }
                </div>
            </div>
        )
    }
}

const mapToStateProps = state => {
    const character = selectCharacter(state);
    const plantInstanceIdAtTile = selectPlantInstanceIdAtTile(state, character.position)
    const mineralInstanceAtTile = selectMineralAtPosition(state, character.position)
    const characterFood = selectCharacterFood(state);
    const farmPlotTileType = selectTileTypeByName(state, tileTypes.FARM_PLOT)

    return { plantInstanceIdAtTile, characterFood, mineralInstanceAtTile, farmPlotTileType }
}

export default connect(
    mapToStateProps,
    {
        clearPlotAsync,
        plantSeedAsync,
        cultivatePlantAsync,
        harvestPlantAsync,
        buildConstructionAsync,
        cookFoodAsync,
        cookAnotherFoodAsync,
        eatFoodAsync,
        deleteFoodAsync,
        gatherMineralAsync,
        createToolAsync,
        createMachineAsync,
        createMetalAsync,
        createMaterialAsync,
        fightCharacterAsync,
        createLockAsync,
        createWritingSurfaceAsync,
        createWritingImplementAsync,
        createFurnitureAsync,
        createTileAsync,
        createWeaponAsync,
        createArmourAsync,
        createClothingAsync,
        createJewelleryAsync,
        saveLastCraft,
        closeCrafting
    }
)(Crafting);