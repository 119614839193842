export const cloudberrySmellTypes = [
    "of moss",
    "of woodland",
    "of soil",
    "of earth",
    "of forest floor",
    "of humus",
    "damp",
    "of decaying leaves",
    "wet",
    "of moss",
    "of damp soil",
    "of mushrooms",
    "of rotting log",
    "of wet dog",
    "of moss",
    "of custard",
    "of cream",
    "of honey",
    "of roses",
    "of moss",
    "of mildew",
    "of dew",
    "of rotting leaves",
    "of wood smoke",
    "of wet soil",
    "of petrichor",
    "of rain",
    "of thunder",
    "of Bog Rosemary",
    "of illicit bliss",
    "tart",
    "sharp",
    "acrid",
    "of light verse",
    "of spun sugar",
    "of tangy sweetness",
    "fruity and musky",
    "sweet and airy",
    "sparky and playful",
    "of daisy",
    "soapy",
    "sweet, with a nose-tickling pepper scent",
    "of driftwood",
    "refreshing powdered sugar",
    "of snowmelt",
    "fruity, insipidly so",
    "of flowers made from rubber",
    "of a fizzy drink",
    "of candy-coated fruit",
    "warm and citrusy",
]

export const cloudberryBranchTypes = [
    "deer antler",
    "Leafy",
    "Hairy",
    "Cheery",
    "Bright",
    "Marshy",
    "short",
    "low",
    "squat",
    "stubby",
    "frost-hardened",
    "frost-nipped",
    "amber-coloured berries on its",
    "pale red berries on its",
    "pale berries ripening on its",
    "a hopeful burst of berries beneath its",
    "arched",
    "long thorny",
    "cane-like",
    "stout",
    "plucked fruitless",
]

export const cloudberryLeafTypes = [
    "marbled",
    "speckled",
    "dainty",
    "delicate",
    "fragile",
    "tender",
    "wispy",
    "frizzy",
    "fuzzy",
    "foamflower",
    "handlike",
    "lobed",
    "hand-sized",
    "soft",
    "bumpy",
    "textured",
    "velveteen",
    "deeply veined",
    "alabaster",
    "crawling insects on its",
    "cupped, handlike",
    "a tiny form with graceful",
    "ill-equipped and delicate",
    "a gentle rocking motion and drawn",
    "inexplicably moving",
    "vivifying",
    "slyly wringing",
    "strangely secluded",
    "soft",
    "pillowy",
    "broad",
    "a quiet deference and splayed",
    "a stalwart attitude and trodden",
    "a humble pose with comfortable",
    "trifoliate",
]