export const rabbitFaceSentences = [
	"its face was as round as a ball",
"it had fluffy cream fur",
"its big ears were always perked up",
"it had a cute pink nose",
"its whiskers were always moving",
"it had bright blue eyes",
"furry face",
"cute and cuddly face",
"soft and snuggly face",
"fuzzy and fun face",
"lovable and huggable face",
"adorable and irresistible face",
]

export const rabbitBodySentences = [
	"overed in tough, leathery plates",
	"long, sharp claws perfect for digging",
	"a long snout for sniffing out food",
	"small, beady eyes",
	"Comical creature with a distinctive shell  ",
	"Low-slung body is supported by sturdy legs ",
	"Clumsy gait makes it appear endearing ",
	"Thick fur is coat is course to the touch ",
	"it's body is covered in a soft, downy fur that is a light brown color",
	"The body of it is long and slender, with short legs and a long tail",
]

export const rabbitSoundWords = [
	"Snuffling",
	"foraging",
	"scratching",
	"clawing",
	"Squeaking",
	"scurrying",
	"rustling",
	"pattering",
	"Hissing",
	"Snorting",
	"Grunting",
	"Growling",
	"Whimpering",
	"Panting",
	"Wheezing",
	"Snuffling",
	"Sighing",
	"moaning",
	"Chittering",
	"Chattering",
	"Scratching",
	"Sniffing",
	"Whimpering",
	"Growling",
	"Grunting",
	"Squealing",
	"Snorting",
	"Hissing",
]

export const rabbitFurWords = [

]

export const rabbitSmellWords = [
	"acrid",
	"putrid",
	"pungent",
	"fetid",
	"reek",
	"rank",
	"foul",
	"malodorous",
	"rank",
	"pungent",
	"musky",
	"fetid",
	"malodorous",
	"effluent",
	"reek",
	"stench",
	"stink",
	"Foul",
	"Pungent",
	"Ammonia-like",
	"Skunk-like",
	"Musky",
	"Gamey",
	"Unpleasant",
	"Bad",
	"Strong",
	"Intense",
]

export const rabbitBehaviourSentences = [
	"it is staring at me with its beady eyes",
	"it is making strange noises",
	"it is walking unusually fast",
	"it is aggressively sniffing everything in sight",
	"it is walking on its hind legs",
	"it is making loud chittering noises",
	"it is dragging its claws along the ground",
	"it is hopping around like a rabbit",
	"it is making strange noises",
	"it is acting very strange",
	"it is not acting like itself",
	"it is rolling around on the ground",
	"it is making strange noises",
	"it is staring at me",
	"it is glaring at me",
	"it is making chittering noises",
	"it is standing on its hind legs",
]