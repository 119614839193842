import React from "react";
import { connect } from 'react-redux'

import './CraftingError.css';

class CraftingError extends React.Component {

    render() {

        return (
            <div className="crafting-error">
                You do not have all ingredients, tools, and machines needed to complete this action
            </div>
        )
    }
}

const mapToStateProps = state => {
    return {}
}

export default connect(
    mapToStateProps,
    {
        
    }
)(CraftingError);