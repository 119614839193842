import {
  LOG_IN_LOADING,
  LOG_IN_SUCCESS,
  LOG_IN_FAILURE,
  BACK_TO_START_PAGE,
  START_NEW_GAME,
  SEE_AN_ADVERT,
  CREATE_CHARACTER,
  START_INTRO,
  CHOOSE_EMBARK,
  CREATE_NEW_EMBARK,
  GET_EMBARK_CHARACTER_SUCCESS,
  NEW_EMBARK_GROUPS_SUCCESS,
  SOLO_EMBARK_SUCCESS,
  GROUP_EMBARK_SUCCESS,
  JOIN_EMBARK_GROUP_SUCCESS,
  RULES
} from "../actionTypes";

const NEW_LOGIN_STEPS = {
  STARTUP: 'STARTUP',
  PROLOGUE_1: 'PROLOGUE_1',
  // PROLOGUE_2: 'PROLOGUE_2', // dancing bunny cut scene 
  PROLOGUE_3: 'PROLOGUE_3',
  RULES: 'RULES',
  CREATE_CHARACTER: 'CREATE_CHARACTER',
  INTRO_SCENE: 'INTRO_SCENE',
  CHOOSE_EMBARK: 'CHOOSE_EMBARK',
  CREATE_NEW_EMBARK: 'CREATE_NEW_EMBARK',
  EMBARK_WAITING_ROOM: 'EMBARK_WAITING_ROOM',
  CHARACTER_DEATH_PAGE: 'CHARACTER_DEATH_PAGE'
}

const initialState = {
  user: {}, // props: _id, email
  newLoginStep: NEW_LOGIN_STEPS.STARTUP,
  isLoading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOG_IN_LOADING: {
      return {
        ...state,
        user: null,
        isLoading: true
      };
    }
    case LOG_IN_SUCCESS: {
      const { user } = action.payload;
      return {
        ...state,
        user: { ...user },
        isLoading: false
      };
    }
    case LOG_IN_FAILURE: {
      const { user } = action.payload;
      return {
        ...state,
        user: null,
        isLoading: false
      };
    }
    case BACK_TO_START_PAGE: {
      return {
        ...state,
        newLoginStep: NEW_LOGIN_STEPS.STARTUP
      }
    }
    case SEE_AN_ADVERT: {
      return {
        ...state,
        newLoginStep: NEW_LOGIN_STEPS.PROLOGUE_3
      }
    }
    case RULES: {
      return {
        ...state,
        newLoginStep: NEW_LOGIN_STEPS.RULES
      }
    }
    case CREATE_CHARACTER: {
      return {
        ...state,
        newLoginStep: NEW_LOGIN_STEPS.CREATE_CHARACTER
      }
    }
    case START_INTRO: {
      return {
        ...state,
        newLoginStep: NEW_LOGIN_STEPS.INTRO_SCENE
      }
    }
    case CHOOSE_EMBARK: {
      return {
        ...state,
        newLoginStep: NEW_LOGIN_STEPS.CHOOSE_EMBARK
      }
    }
    case CREATE_NEW_EMBARK: {
      return {
        ...state,
        newLoginStep: NEW_LOGIN_STEPS.CREATE_NEW_EMBARK
      }
    }

    case GROUP_EMBARK_SUCCESS:
    case SOLO_EMBARK_SUCCESS: {
      return {
        ...state,
        newLoginStep: NEW_LOGIN_STEPS.STARTUP
      }
    }

    case GET_EMBARK_CHARACTER_SUCCESS: {
      const embarkCharacter = action.payload.embarkCharacters.filter(character => character.userId === action.payload.userId)[0];

      if (!embarkCharacter) {
        return { ...state }
      }

      if (embarkCharacter?.embarkGroupId) {
        return {
          ...state,
          newLoginStep: NEW_LOGIN_STEPS.EMBARK_WAITING_ROOM
        }
      } else {
        return {
          ...state,
          newLoginStep: NEW_LOGIN_STEPS.INTRO_SCENE
        }
      }

    }
    default:
      return state;
  }
}
