import React, { useState } from "react";
import isHotkey from 'is-hotkey'
import { connect } from 'react-redux'
import { client } from '../../services/client';

import './EditDrawingSurface.css';

import { CLAY_POT_MASK } from './masks';

const EXIT = 'shift+enter';

const MASKS_MAP = {
  'Clay Pot': [ ...CLAY_POT_MASK ]
}

const HOTKEYS = {
}

class EditDrawingSurface extends React.Component {

  constructor() {
    super();
    this.state = {
      selectedX: 0,
      selectedY: 0,
      handleNavigation: this.handleNavigation.bind(this),
      toggleIsPressed: this.toggleIsPressed.bind(this)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.state.handleNavigation)
    window.removeEventListener('keyup', this.state.toggleIsPressed)
  }

  componentDidMount() {
    let pixelsArray = [];

    if (!this.props.pixelsArray || this.props.pixelsArray.length == 0) {

      if (this.props.mask && MASKS_MAP[this.props.mask]) {
        pixelsArray = [ ...MASKS_MAP[this.props.mask ] ]
      } else {
        for (let y=0; y < this.props.height; y++) {
          for (let x=0; x < this.props.width; x++) {
            pixelsArray.push({
              x, y
            })
          }
        }
      }

    } else {
      pixelsArray = [ ...this.props.pixelsArray ]
    }

    this.setState({
      pixelsArray
    })
    window.addEventListener('keydown', this.state.handleNavigation);
    window.addEventListener('keyup', this.state.toggleIsPressed);

    if (this.props.isReadOnly) {
      this.setState({
        isSaving: true
      })
    }
  }

  toggleIsPressed(event) {
    if (event.key === ' ' || event.key === 'Enter') {
      this.setState({
        isPressed: false
      })
    }
  }

  async handleNavigation(event) {
    if (this.state.isSaving) {
      event.preventDefault();
      return;
    }

    if (event.key === 'ArrowUp') {
      if (this.state.selectedY === 0) {
        return;
      }

      this.setState({
        selectedY: this.state.selectedY - 1
      })
    }

    if (event.key === 'ArrowDown') {
      if (this.state.selectedY === this.props.height - 1) {
        return;
      }

      this.setState({
        selectedY: this.state.selectedY + 1
      })
    }

    if (event.key === 'ArrowLeft') {
      if (this.state.selectedX === 0) {
        return;
      }

      this.setState({
        selectedX: this.state.selectedX - 1
      })
    }

    if (event.key === 'ArrowRight') {
      if (this.state.selectedX === this.props.width - 1) {
        return;
      }

      this.setState({
        selectedX: this.state.selectedX + 1
      })
    }

    if (event.key === ' ' || event.key === 'Enter' || this.state.isPressed) {
      if (this.props.mask && MASKS_MAP[this.props.mask]) {
        const maskTile = MASKS_MAP[this.props.mask].find(maskTile => (maskTile.x === this.state.selectedX && maskTile.y === this.state.selectedY));

        if (!maskTile?.backgroundColor) {
          return;
        }
      }

      this.setState({
        isPressed: true
      })
      const pixelsArray = this.state.pixelsArray;

      const tile = pixelsArray.find(item => item.x === this.state.selectedX && item.y === this.state.selectedY);

      tile.backgroundColor = this.state.backgroundColor;

      this.setState({
        pixelsArray: [ ...pixelsArray ]
      })
    }

    if (isHotkey(EXIT, event)) {
      event.preventDefault();

      this.setState({
        isSaving: true
      })

      this.setState({
        isSaving: false
      })

      this.props.onSave && this.props.onSave({
        pixelsArray: this.state.pixelsArray
      });
      return;
    }

    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();

        const mark = HOTKEYS[hotkey]

        if (this.state.backgroundColor === mark.backgroundColor) {
          this.setState({
            backgroundColor: 'var(--sg-black)'
          })
          return;
        }

        this.setState({
          ...mark
        })
      }
    }
  }

  render() {
    const buttons = (
      <div className={this.props.isReadOnly ? "hidden" : "buttons"} style={{'flexBasis': this.props.paints.length > 10 ? '74rem' : '37rem', 'gridTemplateColumns': this.props.paints.length > 10 ? 'auto auto' : 'auto'}}>
        {
          this.props.paints?.map((paint, index) => {
            HOTKEYS['mod+' + paint.materialType.shortcut] = { backgroundColor: paint.materialType.colourHex };
            return (
              <div key={index}>
                <MarkButton shortcut={paint.materialType.shortcut} hexValue={paint.materialType.colourHex} format={"backgroundColor"} icon="C" isActive={this.state['backgroundColor'] === paint.materialType.colourHex} />
              </div>
            )
          })
        }
      </div>
    )

    const createGroup = this.props.embarkCharacter?._id && !this.props.embarkGroups.find(group => (group.userId === this.props.user.user._id)) ? (<CreateEmbarkGroup />) : ('');

    const pixelsArray = this.state.pixelsArray;

    const pixels = pixelsArray?.map((tile, index) => {
      return (
        <div
          className={this.state.selectedX === tile.x && this.state.selectedY === tile.y ? "current tile": `tile`}
          key={index}
          style={{'backgroundColor': tile.backgroundColor}}
        ></div>
      )
    })

    return (
      <div className="drawing-surface">
        <div className="canvas-container">
          <div className={this.state.isSaving ? "saving canvas" : "canvas"}
            id="paint-canvas"
            style={{
              '--width': this.props.width,
              '--height': this.props.height,
              'width': Math.round(this.props.width * 0.75) + 'ch',
              'height': Math.round(this.props.height * 0.75) + 'ch',
              'border': this.state.isSaving ? 'none' : `1px solid var(--sg-white)`
            }}
          >
            {pixels}
          </div>
        </div>

        {buttons}

        <div className="menu-actions">
          <span className="action-shortcut"><span className="shortcut">SPACE</span> Paint</span>
          <span className="action-shortcut"><span className="shortcut">SHIFT</span> + <span className="shortcut">ENTER</span> Save</span>
        </div>
      </div>
    )
  }
}

const MarkButton = ({ format, icon, hexValue, shortcut, isActive }) => {
  return (
    <button
      key={hexValue}
      className={isActive ? 'mark-button selected' : 'mark-button'}
    >
      <div className="color-key"
        style={{
          'backgroundColor': icon === 'C' && hexValue || 'var(--black)',
          'color': icon === 'C' && hexValue || 'var(--white)'
        }}
      >&nbsp;</div>
      <span><span className="action">CMD</span>&nbsp;+&nbsp;<span className={icon === 'BG' ? "action" : "hidden"}>SHIFT</span><span className={icon === 'BG' ? "" : "hidden"}> + </span><span className="action">{shortcut}</span></span>
    </button>
  )
}

const mapStateToProps = state => {
    return {  }
}

export default connect(
    mapStateToProps,
    {
      
    }
)(EditDrawingSurface);