import {
    CREATE_PROMPT_LOADING,
    CREATE_PROMPT_SUCCESS,
    CREATE_PROMPT_FAILURE,
    GET_PROMPTS_LOADING,
    GET_PROMPTS_SUCCESS,
    GET_PROMPTS_FAILURE,
    ADD_PROMPT,
    UPDATE_PROMPT,
    REMOVE_PROMPT,
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';

import { moveItemFailure } from './move-item.actions';

export const createPromptSuccess = payload => ({
    type: CREATE_PROMPT_SUCCESS,
    payload
})

export const createPromptFailure = () => ({
    type: CREATE_PROMPT_FAILURE
})

export const createPromptAsync = payload => dispatch => {
    const { _id, promptTypeId } = payload;

    return client.service('prompt-instances').create({
        promptTypeId
    })
        .then((response) => {
        	console.log('prompt instance: ', response);
            dispatch(createPromptSuccess(response))
        })
        .catch((error) => {
            console.error('error: ', error);
            dispatch(showUnknownError({ message: error.message }))
            throw new Error(error);
        });
}

export const dismissCurrentPrompt = payload => dispatch => {
    const { prompt } = payload;

    return client.service('prompt-instances').remove(prompt._id)
        .then((response) => {
            console.log('prompt instance: ', response);
            dispatch(removePrompt(payload))
        })
        .catch((error) => {
            console.error('error: ', error);
            dispatch(showUnknownError({ message: error.message }))
            throw new Error(error);
        });
}

export const getPromptsSuccess = payload => {
    return ({
        type: GET_PROMPTS_SUCCESS,
        payload
    })
}

export const getPromptsFailure = () => ({
    type: GET_PROMPTS_FAILURE
})

export const getPromptsAsync = payload => dispatch => {
    let query = { characterId: payload.characterId, $sort: { createdAt: -1 } };

    return client.service('prompt-instances').find({ query })
        .then((response) => {
            console.log('response', response);
            const prompts = response.data.map(prompt => ({ ...prompt, promptType: payload.promptTypes?.find(type => type._id === prompt.promptTypeId) }))
            dispatch(getPromptsSuccess(prompts));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const addPrompt = payload => {
    return ({
        type: ADD_PROMPT,
        payload
    })
}

export const updatePrompt = payload => {
    return ({
        type: UPDATE_PROMPT,
        payload
    })
}

export const removePrompt = payload => {
    return ({
        type: REMOVE_PROMPT,
        payload
    })
}
