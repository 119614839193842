export const crystalisedSmellTypes = [
	"of coconut husk",
    "of damp soil",
    "of oak bark",
    "of leather",
    "of wet stone",
    "of musk",
    "of sandalwood",
    "of amber",
    "clear",
    "of morning",
    "of rain",
    "of soil",
    "floral",
    "sweet",
    "woody",
    "of watercress",
    "of daffodil",
    "of lemon",
    "of bergamot",
    "clear",
    "crisp",
    "sharp",
    "sweet",
    "fragrant",
    "heady",
    "intoxicating",
    "invigorating",
    "uplifting",
    "nutty",
    "of sweet caramel",
    "of urine",
    "of roasted nuts",
    "like an animal has marked its territory here",
    "of buttery vanilla",
    "of salt and macadamia",
    "of an overworked bakery",
    "of dough prepared in lard",
    "of burnt shortcake pastry",
    "cloyingly sweet",
    "of nutty vanilla",
    "of worn work clothes",
    "of stale caramel",
    "saccharine",
    "of baked treats",
    "of heartache",
    "of fluffy cumulus clouds on a bright day",
    "of a milky new moon",
    "of melba-peach sunlight and pinched dough",
]

export const crystalisedBranchTypes = [
	"reaching",
    "small animals in its",
    "frozen",
    "delicate",
    "brittle",
    "sharp",
    "clear",
    "damp",
    "sparkling",
    "pure",
    "dark",
    "shale-cut",
    "dense",
    "metallic",
    "glinting",
    "poured metal for",
    "mysterious",
    "elegant",
    "graceful",
    "luminous",
    "translucent",
    "cable-tower",
    "creature-carrying",
    "nesting creatures in its",
    "eyes watching from its",
    "a cacophony of calls sounding in its",
    "heavy",
    "dripping",
    "rain-cast",
    "bold",
    "oddly-formed",
    "prow forming",
    "wedged",
    "vermin amongst its",
]

export const crystalisedLeafTypes = [
	"speckled",
    "variegated",
    "robust",
    "pristine",
    "Vibrant",
    "Lush",
    "plump",
    "healthy",
    "sharp",
    "jagged",
    "crinkled",
    "pointed",
    "bumpy",
    "veined",
    "feathery",
    "Glistening",
    "hyline",
    "semi-transparent",
    "translucent",
    "gauzy",
    "season-abiding",
    "seasonal"
    "pinnate",
    "chalk-covered",
    "protective",
]