import React from "react";
import { connect } from 'react-redux'
import { backToStartPage } from '../../redux/actions/user.actions';
import { hideAllMenus } from '../../redux/actions/keyboard-shortcuts.actions';

class CharacterDeathPage extends React.Component {
	state = {
		handleEscape: this.handleEscape.bind(this)
	}

    render() {
        return (
            <div className="character-death-screen">
				<p>Gosh, <span className="action">{this.props.character.name}</span> has died.</p>
				<p>{this.props.character.backstory}</p>
                <p className="error">Then they settled the planet Gliese</p>
				<p>They led an uneventful life.</p>
				<p>They met a sticky end.</p>
				<p>May their memory forever blow on the winds of Gliese.</p>
			</div>
        )
    }

    componentDidMount() {
        document.addEventListener('keydown', this.state.handleEscape);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.state.handleEscape)
    }

    handleEscape(event) {
        if (event.code === 'Escape') {
            window.location.reload();
            this.props.backToStartPage();
            this.props.hideAllMenus();
            return;
        }
    }
}

const mapToStateProps = state => {
    return {}
}

export default connect(
    mapToStateProps,
    {
        backToStartPage,
        hideAllMenus
    }
)(CharacterDeathPage);