import React from "react";
import { connect } from 'react-redux'

import './UnknownError.css';

class UnknownError extends React.Component {

    render() {

        return (
            <div className="unknown-error">
                {this.props.errorMessage ? this.props.errorMessage : 'Oops, something went wrong. Please try again later.'}
            </div>
        )
    }
}

const mapToStateProps = state => {
    return {}
}

export default connect(
    mapToStateProps,
    {
        
    }
)(UnknownError);