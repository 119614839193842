
import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_TOOLS_SUCCESS, ADD_TOOL, UPDATE_TOOL, REMOVE_TOOL, GET_TRADE_TOOLS_SUCCESS, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  characterTools: [],
  tileTools: [],
  tools: [],
  tradeTools: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_TOOLS_SUCCESS: {
      const tools = action.payload;

      return {
        ...state,
        tools: [...tools.filter(toolInstance => toolInstance.panelId)],
        characterTools: [...state.characterTools, ...tools.filter(toolInstance => toolInstance.characterId)],
        tileTools: [...tools.filter(toolInstance => toolInstance.tileId)],
      }
    }

    case GET_TRADE_TOOLS_SUCCESS: {
      const tools = action.payload;

      return {
        ...state,
        tradeTools: [...tools],
      }
    }

    case ADD_TOOL: {
      let characterTools = [...state.characterTools];
      let tileTools = [...state.tileTools];
      let tools = [...state.tools];

      if (action.payload.characterId) {
        characterTools = [ ...characterTools, action.payload ]
      }

      if (action.payload.tileId) {
        tileTools = [ ...tileTools, action.payload ]
      }

      if (action.payload.panelId) {
        tools = [ ...tools, action.payload ]
      }

      return {
        ...state,
        characterTools: [ ...characterTools ],
        tileTools: [ ...tileTools ],
        tools: [ ...tools ],
      }
    }

    case UPDATE_TOOL: {
      let characterTools = [...state.characterTools];
      let tileTools = [...state.tileTools];
      let tools = [...state.tools];
      let tradeTools = [...state.tradeTools];

      if (action.payload.characterId) {
        characterTools = [
          ...characterTools.filter(tool => tool._id !== action.payload._id),
          { ...characterTools.find(tool => tool._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.tileId) {
        tileTools = [
          ...tileTools.filter(tool => tool._id !== action.payload._id),
          { ...tileTools.find(tool => tool._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.panelId) {
        tools = [
          ...tools.filter(tool => tool._id !== action.payload._id),
          { ...tools.find(tool => tool._id === action.payload._id), ...action.payload }
        ]
      }

      tradeTools = tradeTools.filter(tool => (tool._id !== action.payload._id))

      return {
        ...state,
        characterTools: [ ...characterTools ],
        tileTools: [ ...tileTools ],
        tools: [ ...tools ],
        tradeTools
      }
    }

    case REMOVE_TOOL: {
      return {
        ...state,
        characterTools: [ ...state.characterTools.filter(tool => (tool._id !== action.payload._id)) ],
        tileTools: [ ...state.tileTools.filter(tool => (tool._id !== action.payload._id)) ],
        tools: [ ...state.tools.filter(tool => (tool._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
