export const sedgeGrassSmellTypes = [
    "of cedar",
    "of lemon",
    "of patchouli",
    "of pine",
    "of ocean",
    "of seaweed",
    "of sun",
    "of sand",
    "of waves",
    "of coconut",
    "of resin",
    "of spice",
    "of wood",
    "of powder",
    "of nut",
    "of cigar",
    "of smoke",
    "of amber",
    "relaxing",
    "salty",
    "calming",
    "serene",
    "lush",
    "of your favourite colour",
    "brisk and green",
    "of springtime",
    "of wicker",
    "hypnotic",
    "of wilting violet",
    "of heady tagetes",
    "of simple garden plots",
    "of heavy nectar",
    "of stone fruits",
    "of market spices",
    "of oiled armour",
    "friendly and warm",
    "fresh",
    "harmonious",
    "of watered-down tangerine",
    "of neroli",
    "of mouldy straw",
    "nauseating",
    "of burnt rubber",
    "of sulfur and cream",
    "of old sourdough",
    "of battened hatches",
    "of full green pods",
    "of oat stirabout",
    "cheap",
    "multi-faceted",
    "of cold water from a tin jug",
]

export const sedgeGrassBranchTypes = [
]

export const sedgeGrassLeafTypes = [
    "homely",
    "Fibrous",
    "tassled",
    "Green",
    "Lush",
    "Vibrant",
    "storm-sheltering",
    "wind-breaking",
    "drifting",
    "roots dug into the hummocks and long",
    "dune-hardy roots and green",
    "wind-dampening",
    "a catch of buried eggs under its",
    "stabilising roots and tufted"
    "reed-like",
    "tufted",
    "blade-like",
    "green",
    "seeds growing on its",
    "veins on its",
    "messy",
    "uneven",
    "dangling",
    "wispy",
    "fern-coloured",
    "tendril",
    "curly",
    "meandering",
    "sword-shaped",
    "an explosion of",
    "feather",
    "blade",
    "lance",
    "sickle-plumed",
    "lance-like",
    "pike-tall",
    "towering",
    "pointed",
    "dagger",
    "stiff",
    "towering",
    "aged",
    "curled",
    "drought-shrunk",
    "adaptable",
    "hardy clumps of",
    "needle",
    "blade",
    "sickle",
    "spear-straight",
]