import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_TENTS_SUCCESS, ADD_TENT, UPDATE_TENT, GET_TRADE_TENTS_SUCCESS, REMOVE_TENT, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  characterTents: [],
  tileTents: [],
  tradeTents: [],
  tents: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_TENTS_SUCCESS: {
      const tents = action.payload;

      return {
        ...state,
        characterTents: [...state.characterTents, ...tents.filter(tentInstance => tentInstance.characterId)],
        tents: [...tents.filter(tentInstance => tentInstance.panelId)],
        tileTents: [...tents.filter(tentInstance => tentInstance.tileId)],
      }
    }

    case GET_TRADE_TENTS_SUCCESS: {
      const tents = action.payload;

      return {
        ...state,
        tradeTents: [...tents],
      }
    }

    case ADD_TENT: {
      let characterTents = [...state.characterTents];
      let tileTents = [...state.tileTents];
      let tents = [...state.tents];

      if (action.payload.characterId) {
        characterTents = [ ...characterTents, action.payload ]
      }

      if (action.payload.tileId) {
        tileTents = [ ...tileTents, action.payload ]
      }

      if (action.payload.panelId) {
        tents = [ ...tents, action.payload ]
      }

      return {
        ...state,
        characterTents: [ ...characterTents ],
        tileTents: [ ...tileTents ],
        tents: [ ...tents ],
      }
    }

    case UPDATE_TENT: {
      let characterTents = [...state.characterTents.filter(tent => tent._id !== action.payload._id)];
      let tileTents = [...state.tileTents.filter(tent => tent._id !== action.payload._id)];
      let tents = [...state.tents.filter(tent => tent._id !== action.payload._id)];

      if (action.payload.characterId) {
        characterTents = [
          ...characterTents.filter(tent => tent._id !== action.payload._id),
          { ...characterTents.find(tent => tent._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.tileId) {
        tileTents = [
          ...tileTents.filter(tool => tool._id !== action.payload._id),
          { ...tileTents.find(tool => tool._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.panelId) {
        tents = [
          ...tents.filter(tent => tent._id !== action.payload._id),
          { ...tents.find(tent => tent._id === action.payload._id), ...action.payload }
        ]
      }

      return {
        ...state,
        characterTents: [ ...characterTents ],
        tileTents: [ ...tileTents ],
        tents: [ ...tents ],
      }
    }

    case REMOVE_TENT: {
      return {
        ...state,
        characterTents: [ ...state.characterTents.filter(tent => (tent._id !== action.payload._id)) ],
        tileTents: [ ...state.tileTents.filter(tool => (tool._id !== action.payload._id)) ],
        tents: [ ...state.tents.filter(tent => (tent._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
