export const scaryFaceSentences = [
"Fanged, furry, and drooling face",
"Reddened, swollen, and disfigured face",
"Slimy, red skin with protruding veins",
"'Eyes' that are black, empty sockets",
"Mouth full of sharp, jagged teeth",
"Gaping maw that seems to go on forever",
"It has a wide mouth filled with sharp teeth, and its tongue is long and forked",
"Its skin is red and covered in slime, and its eyes glow red in the darkness",
"A pair of blood-red eyes stared out from the darkness",
"Fiery red hair covered the creature's head",
"Long, pointy ears sticking up from the hair",
"The monster's face is covered in slime",
"The monster's face is covered in blood",
"The monster's face is so hideous it's difficult to look at",
"The monster's face is contorted in a cruel grin",
"The monster's eyes are glowing a sinister red",
]

export const scaryBodySentences = [
"menacing maw",
"dreadful claws",
"vile fangs",
"horror-inducing eyes",
"grotesque features",
"hulking form",
"eerie silence",
"dreadful stench",
"unnatural movements",
"unspeakable hunger",
"beady, red eyes set deep in its skull ",
"scaly, white bones that pulsate and ooze",
"long, spindly",
"Enormous body that is physically imposing.",
"Covered in stringy, slimy black algae.",
"Nails like daggers",
"Flesh rotting off in chunks",
"It's horrible, deformed body is a chilling sight.",
]

export const scarySoundWords = [
        "Rumbling",
    "Roaring",
    "Growling",
    "Snarling",
    "Hissing",
    "Spitting",
    "Clattering",
    "Footsteps",
    "Screeching",
    "Howling",
    "Groaning",
    "Snarling",
    "Growling",
    "Hissing",
    "Clanking",
    "Clutching",
    "Seething",
    "Spitting",
    "Slobbering",
    "Rasping",
    "Roaring",
    "screeching",
    "hissing",
    "growling",
    "snarling",
    "baying",
    "cackling",
    "chuckling",
    "laughing",
    "howling",
    "Looming",
    "Haunting",
    "Eerie",
    "Stalking",
    "Circling",
    "Ominous",
    "Creeping",
    "Slinking",
    "Prowling",
    "Stalking",
    "Roaring",
    "Snarling",
    "Growling",
    "Rumbling",
    "Chomping",
    "Clacking",
    "Grunting",
    "Hissing",
    "Slithering",
    "Squishing"
]

export const scaryFurWords = [
    "Gross",
    "slimy",
    "itchy",
    "stringy",
    "oily",
    "discolored",
    "mottled",
    "cracked",
    "peeling",
    "scabbed",
    "Hairy",
    "scaly",
    "bumpy",
    "dry",
    "oily",
    "committed",
    "mottled",
    "spotted",
    "cracked",
    "rough",
    "Slimy",
    "gross",
    "bumpy",
    "hairy",
    "oily",
    "scaly",
    "dry",
    "crusty",
    "rubbery",
    "bumpy",
    "rough",
    "prickly",
    "course",
    "scaly",
    "slimy",
    "oily",
    "bumpy",
    "hairy",
    "molted",
    "peeling",
    "Spiky",
    "Slimy",
    "Oozy",
    "Gristly",
    "Scabby",
    "Rotting",
    "Decaying",
    "Gory"
]

export const scarySmellWords = [
"Odious", "rank", "fusty", "putrid", "stinking", "strong", "foul", "reeking", "pungent", "fetid",
]

export const scaryBehaviourSentences = [
"it is glaring at us with its beady red eyes",
"it is drooling all over the ground",
"it is foaming at the mouth",
"it is stamping its feet and making loud noises",
"it is hissing",
"it is baring its teeth",
"it is growling",
"it is staring intently",
"it is stamping",
]