export const lichenSmellTypes = [
	"of rotting logs",
    "of damp forest floor",
    "earthy",
    "musky",
    "gamey",
    "pungent",
    "stale",
    "of rotted leaves",
    "of wet wool",
    "of mildewed paper",
    "damp",
    "foul",
    "earthy",
    "decaying",
    "rotting",
    "moldy",
    "musty",
    "acrid",
    "pungent",
    "damp must",
    "of rotting vegetation",
    "of pungent ammonia",
    "of fungal decay",
    "of moldy bread",
    "of overripe fruit",
    "of rotting flesh",
    "sickly sweet",
    "of putrid egg",
    "of sour milk",
    "of pine and moss",
    "of warmed dry coniferous woods",
    "of wet leaves and woodlice",
    "of a vernal pool",
    "meditative",
    "fetid and fecal",
    "of wet, nutritious earth",
    "of forest floor",
    "of tilling moist soil",
    "of mildewed branches",
    "of going to the land",
    "of haunting violet",
    "of glass and resin",
    "of an impression of mist",
    "of the fog rolling in",
    "of lost impressions",
    "of forgotten names",
    "of lichen",
]

export const lichenBranchTypes = [
	"flowing",
    "languid",
    "contorted",
    "knotty",
    "elegant",
    "willowy",
    "wispy",
    "ghostly",
    "silver-grey",
    "argent",
    "grubby",
    "slippery",
    "gritty",
    "pockmarked",
    "brittle",
    "moisture-laden",
    "spore-encrusted",
    "feathery",
    "stringy",
    "airy wisp",
    "fairy filament",
    "gossamer",
    "ephemeral",
    "glittering specks on its",
    "colorful growths on its",
    "brittle textured",
    "crunchy",
    "fuzzy",
    "fleshy looking",
    "slightly crunchy",
    "stringy and tough",
    "wet and cold",
    "clammy feeling",
    "frayed edges to its",
    "leathery",
    "dry and brittle",
    "crumbly",
    "dead and decaying",
    "fragile",
    "brittle",
    "dry and lifeless",
    "stringy",
    "green tufts on its",
    "hidden roots and hanging",
    "rock-licking roots and limp",
    "grey",
    "pale",
    "shrubby",
    "dense",
    "abundant",
    "frost-tolerant",
    "thawed",
    "wrinkled",
    "smooth",
    "crusted",
    "cracked",
    "chipped-paint",
    "broken",
    "mud-caked",
    "hoary",
    "silvery",
    "rimy"
]

export const lichenLeafTypes = [
]