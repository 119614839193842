export const reindeerFaceSentences = [
"fluffy pink cheeks",
"big black eyes",
"long lashes",
"soft pink nose",
"rosy red lips",
"black spots on nose",
"white whiskers",
"fluffy pink ears",
"tufts of fur on forehead",
"A face with a pink nose and antlers.",
"A face that is both cute and whimsical.",
"delicate blush pink hue",
"lovely rosy cheeks",
"dainty little nose",
"gentle gaze",
"playfully curled antlers",
"magnificent mane",
"silk-soft fur",
"endearing freckles",
"innocent eyes that seem to say 'who, me?'",
"a cute little nose that is the perfect shade of pink",
"big, fluffy ears that are so soft to the touch",
]

export const reindeerBodySentences = [
"Vibrant pink fur that's soft to the touch",
"A cute, perky nose",
"Large, glittering eyes",
"Strong, powerful legs",
"Graceful antlers",
"A big, fluffy tail",
"Intricate hooves",
"A graceful body with long, elegant legs.",
"A beautiful body with a soft and fluffy coat.",
"A graceful and elegant body that is sure to stand out in a crowd.",
"A cute, pink body with a fluffy tail.",
"A gentle animal that is always ready to cuddle.",
]

export const reindeerSoundWords = [
    "snorting",
    "stamping",
    "slurping",
    "munching",
    "crunching",
    "sniffing",
    "wheezing",
    "huffing",
    "puffing",
    "snoring",
    "snorting",
    "nickering",
    "whinnying",
    "neighing",
    "prancing",
    "Clicking",
    "Clomping",
    "Hopping",
    "Landing",
    "Skipping",
    "Squeaking",
    "Startled",
    "Surprised",
    "Vibrating",
    "Whinnying",
    "Sneezing",
    "wheezing",
    "panting",
    "gasping",
    "grunting",
    "snorting",
    "stamping",
    "coughing",
    "bleating",
    "braying",
    "Snorting",
    "Sniffing",
    "Snuffling",
    "Breathing",
    "Wheezing",
    "Whinnying",
    "Whickering",
    "Blowing",
    "Panting"
]

export const reindeerFurWords = [
    "luminous",
    "pale",
    "delicate",
    "smooth",
    "silky",
    "ethereal",
    "fragile",
    "porcelain",
    "Soft",
    "velvety",
    "warm",
    "thick",
    "luxurious",
    "glowing",
    "creamy",
    "smooth",
    "pale",
    "rose",
    "soft",
    "fluffly",
    "fragrant",
    "pretty",
    "delicate",
    "Pink",
    "rose",
    "peach",
    "coral",
    "fuchsia",
    "lavender",
    "lilac",
    "strawberry",
    "salmon",
    "cream"
]

export const reindeerSmellWords = [
"Fruity",
    "Sweet",
    "vanilla",
    "Chocolate",
    "roses",
    "camomile",
    "Fruity",
    "Sweet",
    "Candied",
    "Syrupy",
    "Vanilla",
    "Rich",
    "Creamy",
    "Buttery",
    "Nutty",
    "Sweet",
    "Fruity",
    "Floral",
    "Vanilla",
    "Candy",
    "Strawberry",
    "Raspberry",
    "Bubblegum",
    "Chocolate",
    "Coffee",
    "Saccharine",
    "Sweet",
    "Fruity",
    "Powdery",
    "Fragrant",
    "Pungent",
    "Aromatic",
    "Delectable",
    "Sweet",
    "Fruity",
    "Vanilla",
    "Sticky",
    "Expensive",
    "Delicious",
    "Intoxicating",
    "Addictive",
    "Heavenly"
]

export const reindeerBehaviourSentences = [
"it is patiently waiting for something... or someone.",
"it looks like it's plotting something malicious.",
"its eyes seem to be following you",
"it seems to be sizing you up as its next victim",
"it is sniffing around suspiciously",
"it is staring at you intensely",
"it is opening its mouth wide",
"it is flicking its tongue out",
]