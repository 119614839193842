import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';
import {
    getMessagesAsync,
    getMoreMessagesAsync,
    createMessageAsync,
    createNewMessage,
    getFurnitureMessagesAsync
} from '../../redux/actions/messages.actions';
import {
    joinFurnitureAsync,
    leaveFurnitureAsync
} from '../../redux/actions/furniture.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import { disableKeyboardMovement, enableKeyboardMovement, showHelp, startLooking, hideAllMenus } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectPanel,
    selectCharacterMessages,
    selectEmbarkCharacterMessages,
    selectTotalMessages,
    selectCharacter,
    selectCharacters,
    selectIsMessagesFocussed,
    selectFocussedCharacterId,
    selectMessageOptionss,
    selectEmbarkCharacter,
    selectCurrentEmbarkGroup,
    selectCharacterById,
    selectFocussedFurnitureId
} from '../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';

import { client } from '../../services/client';

import './Dialogue.css';

import Menu from '../utils/menu/Menu';
import TextInput from '../utils/text-input/TextInput';

// DISABLE DEFAULT SCROLL?

class Dialogue extends React.Component {
    state = {
        text: "",
        isFetching: false,
        isMessagesSet: false,
        isEmptyFocussedCharacterShowing: false,
        messages: [],
        showingMessageIndexes: [],
        navigationHandler: this.navigationHandler.bind(this)
    };

    handleInput = event => {
        
    };

    disableMovement = () => {
        store.dispatch(disableKeyboardMovement());
    }

    enableMovement = () => {
        store.dispatch(enableKeyboardMovement());
    }

    submitMessage = () => {
        
    };

    openHelp = () => {
        store.dispatch(showHelp());
    }

    componentWillUnmount() {
        if (this.props.focussedFurnitureId) {
            this.props.leaveFurnitureAsync({ targetFurnitureId: this.props.focussedFurnitureId });
        }
        document.removeEventListener('keydown', this.state.navigationHandler)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.state.navigationHandler);

        if (this.props.focussedFurnitureId) {
            this.props.joinFurnitureAsync({ targetFurnitureId: this.props.focussedFurnitureId });

            this.props.getFurnitureMessagesAsync({ targetFurnitureId: this.props.focussedFurnitureId })

            return;
        }

        if (this.props.messages && this.props.messages.length > 0) {
            this.setState({
                messages: [
                    {
                        text: this.props.focussedCharacter.greeting || 'Greetings, stranger',
                        characterId: this.props.focussedCharacter._id,
                        targetCharacterId: this.props.character._id
                    },
                    ...this.props.messages
                ]
            })
        } else {
            this.setState({
                messages: [
                    {
                        text: this.props.focussedCharacter.greeting || 'Greetings, stranger',
                        characterId: this.props.focussedCharacter._id,
                        targetCharacterId: this.props.character._id
                    },
                ]
            })
        }

        setTimeout(() => {
            this.setState({
                showingMessageIndexes: [ this.state.messages.length - 2, this.state.messages.length - 1 ]
            })
        })
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.messages && nextProps.messages.length !== state.messages.length) {
            state = {
                ...state,
                messages: [
                    ...nextProps.messages
                ],
                showingMessageIndexes: [ nextProps.messages.length - 2, nextProps.messages.length - 1 ]
            }
        }

        return state
    }

    navigationHandler(event) {
        if (event.key === '>') {
            this.props.showRight();
            return;
        }

        if (event.key === '<') {
            this.props.showLeft();
            return;
        }

        if (event.key === 'ArrowUp') {
            const showingMessageIndexes = this.state.showingMessageIndexes;

            if (this.state.showingMessageIndexes[0] <= 0) {
                return;
            }

            this.setState({
                showingMessageIndexes: [ showingMessageIndexes[1] - 2, showingMessageIndexes[0] ]
            })
        }

        if (event.key === 'ArrowDown') {
            const showingMessageIndexes = this.state.showingMessageIndexes;

            if (this.state.showingMessageIndexes[1] >= this.state.messages.length - 1) {
                return;
            }

            this.setState({
                showingMessageIndexes: [ showingMessageIndexes[1], showingMessageIndexes[0] + 2 ]
            })
        }
    }

    async dialogueWritten(text) {
        this.props.createMessageAsync({
            text: text,
            targetFurnitureId: this.props.focussedFurnitureId,
            targetCharacterId: this.props.focussedCharacter._id,
            targetCharacterName: this.props.focussedCharacter.name,
            characterName: this.props.character.name
        })
    }

    formatDistance(result, number) {
        result = result.replace('Minutes', 'm');
        result = result.replace('Hours', 'h');
        result = result.replace('Days', 'd');
        result = result.replace('Months', 'mo');
        result = result.replace('Years', 'y');

        result = result.replace('lessThan', '<')
        result = result.replace('about', '');
        result = result.replace('over', '>');

        result = result.replace('x', number)
        result = result.replace('X', number)

        return result;
    }

    render() {
        let textInput = ('');
        let messagesList = ('');

        textInput = (<TextInput onTextSubmit={this.dialogueWritten.bind(this)} />);

        if (this.state.messages.length > 0) {
            messagesList = (<ul className="history">
                {this.state.messages.map((message, index) => {
                    const messenger = this.props.characters?.find(character => (character._id === message.characterId));

                    if (this.state.showingMessageIndexes.indexOf(index) === -1) {
                        return;
                    }

                    return (
                        <li key={index} className={message.characterId === this.props.character._id ? "outgoing" : "incoming"}>
                            <div className="event-date-and-name">
                                { message.createdAt ? (
                                    <span className="event-date">
                                        &nbsp;{formatDistance(new Date(message.createdAt), new Date(), { locale: { formatDistance: this.formatDistance.bind(this) } })}&nbsp;
                                    </span>) :
                                    (
                                        <span className="event-date">
                                            &nbsp;N/A
                                        </span>
                                    )
                                }
                                <p>{message?.characterName}</p>
                            </div>
                            <p className="dialogue-text">{message.text}</p>
                        </li>
                    )
                })}
            </ul>)
        }

        return (
            <div>
                <div className="dialogue-content">
                    {messagesList}

                    {textInput}
                </div>

                <div className="bottom-text">
                    [
                        <span className="action">UP</span> Prev Dialogue | 
                        <span className="action">DOWN</span> Next Dialogue | 
                        <span className="action"> &lt; </span> {this.props.leftString} |
                        <span className="action"> &gt; </span> {this.props.rightString} ]
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    let isEmbarkMessages = false;

    const panel = selectPanel(state);
    let messages = selectCharacterMessages(state)

    const embarkMessages = selectEmbarkCharacterMessages(state);

    if (Object.entries(messages).length === 0) {
        isEmbarkMessages = true;
    }

    const character = selectCharacter(state)
    const embarkCharacter = selectEmbarkCharacter(state);


    const characters = selectCharacters(state);
    const embarkCharacters = embarkCharacter && selectCurrentEmbarkGroup(state, embarkCharacter?._id)?.embarkCharacters

    const focussedCharacterId = selectFocussedCharacterId(state);
    const focussedFurnitureId = selectFocussedFurnitureId(state);
    const focussedCharacter = selectCharacterById(state, focussedCharacterId)
    const messageOptions = selectMessageOptionss(state);

    if (focussedCharacterId && messages[focussedCharacterId]) {
        messages = messages[focussedCharacterId]?.messages
    } else if (focussedFurnitureId && messages[focussedFurnitureId]) {
        messages = messages[focussedFurnitureId]?.messages

        // console.log('wowa weewa', messages);
    } else {
        messages = []
    }

    return {
        panel,
        messages: isEmbarkMessages ? embarkMessages : messages,
        character: isEmbarkMessages ? embarkCharacter : character,
        characters: isEmbarkMessages ? embarkCharacters : characters,
        focussedCharacterId,
        focussedFurnitureId,
        focussedCharacter,
        messageOptions
    }
}

export default connect(
    mapStateToProps,
    {
        joinFurnitureAsync,
        leaveFurnitureAsync,
        hideAllMenus,
        getMessagesAsync,
        getMoreMessagesAsync,
        createMessageAsync,
        flashCharacterColorAsync,
        startLooking,
        createNewMessage,
        getFurnitureMessagesAsync
    }
)(Dialogue);