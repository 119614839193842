export const aoeFaceSentences = [
"Hairless and scaly face, with a long snout and sharp teeth.",
"Small, bright eyes set above a wide mouth.",
"Thick horns protruding from the forehead.",
"A reddish purple complexion, with darker stripes along the sides.",
"it face was a sight to behold.",
"Its two horns protruded from its head, and its purple skin was covered in scales.",
"Its eyes were a deep red, and it had a long snout.",
"Its teeth were",
"sunken, purple eyes",
"large, protruding horns",
"wrinkled, leathery skin",
"sharp, jagged teeth",
"long, curled tongue",
"foul, putrid breath",
"bloodshot, yellow eyes",
"A raging face.",
"A monstrous creature with a hideous face.",
"A massive beast with a ferocious face.",
"a face with a toothy grin, beady eyes, and a long tongue.",
"a face with sharp teeth and a slimy tongue.",
"a face with bulging eyes and a long, curved nose",
]

export const aoeBodySentences = [
"Slimy, purple skin stretches tight over rippling muscles",
"Its body is a deep purple, with a glossy sheen.",
"It's covered in thick fur, which is also a deep purple.",
"It has a long, snake-like tongue that it often uses",
"It had a long, slender neck, and a horn on its head.",
"Its eyes were a deep purple, and its teeth were sharp.",
"It had long, sharp claws on its hands and feet.",
"Its body was covered in purple fur.",
]

export const aoeSoundWords = [
"cackling",
"Rumbling",
"Roaring",
"Snorting",
"Grunting",
"Stomping",
"Clomping",
"Crashing",
"Tromping",
"Tramping",
"galumphing",
"baying",
]

export const aoeFurWords = [
]

export const aoeSmellWords = [
"Sour",
"Sharp",
"Pungent",
"Foul",
"Rancid",
"Ammonia-like",
"Choking",
"Gagging",
"Eye-watering",
"Acrid",
"Putrid",
"Rotten",
"Decaying",
"Stinking",
"Foul",
"Nauseating",
"Repellent",
"Disgusting",
"Vomit-inducing",
"Inhumane",
"Pungent",
"Sharp",
"Vinegary",
"Sour",
]

export const aoeBehaviourSentences = [
]