export const discordantMonkeyFaceSentences = [
"it has a face that is distorted and ugly.",
"it has a face that is misshapen and grotesque.",
"it has a face that looks like it has been through a horrible accident.",
"A small, pudgy face with a pronounced muzzle and large, soulful eyes.",
"A face that is both comical and endearing, with a big nose and expressive eyebrows.",
"A face that is slightly melancholy, with a hint of resignation in the eyes.",
"it's an ugly, misshapen thing",
"its features are twisted and malformed",
"it's like something out of a nightmare",
"it's disgustingly ugly",
"it's shockingly ugly",
"tousled hair",
"bright, beady eyes",
"long, whiskery muzzle",
"mischievous grin",
"furry, triangular ears",
"reddish-brown fur",

]

export const discordantMonkeyBodySentences = [
"it has a long tail that seems to have a mind of its own.",
"its big ears are always flopping around, giving him a comical appearance.",
"its body is long and lanky, with arms that seem too long for its body.",
"its fur is patchy and mottled, with a mix of brown, gray, and black.",
"its body is covered in coarse, dark hair.",
"it has a long, preitnsile tail.",
"its limbs are long and gangly.",
"its eyes are large and expressive.",
"its ears are large and sensitive.",
]

export const discordantMonkeySoundWords = [
"screeching",
"chattering",
"jabbering",
"yammering",
"babbling",
"prattling",
"blathering",
"gabbling",
"twaddling",
"blethering",
"Chattering",
"screeching",
"howling",
"wailing",
"whimpering",
"crying",
"yelling",
"screaming",
"hooting",
"ululating",
"Howling",
"Yelling",
"screeching",
"Chattering",
"Whining",
"Complaining",
"Yapping",
"Babbling",
"Murmuring",
"Grunting",
"Wailing",
"screeching",
"howling",
"yowling",
"yelping",
"barking",
"chattering",
"jabbering",
"squawking",
"caterwauling",
"Hissing",
"Snorting",
"Growling",
"Gripping",
"Chattering",
"Jabbering",
"Climbing",
"Scratching",
"Jangling",
"Swinging",
]

export const discordantMonkeyFurWords = [
"bristly",
"course",
"dirty",
"dry",
"encrusted",
"greasy",
"matted",
"rank",
"smelly",
"sweaty",
"Hairy",
"Shaggy",
"Unkempt",
"Matted",
"Dirty",
"Unclean",
"Scruffy",
"Sloppy",
"Unwashed",
"Cruddy",
"Coarse",
"Hairy",
"Dry",
"Itchy",
"Scaly",
"Sticky",
"Smelly",
"Oily",
"Greasy",
"Crusty",
"mottled",
"blotchy",
"uneven",
"patchy",
"lumpy",
"hairy",
"wooly",
"scruffy",
"shaggy",
"unkempt",
"prickly",
"course",
"dry",
"scaly",
"bumpy",
"mottled",
"blotchy",
"speckled",
"sandy",
"hairy",
]

export const discordantMonkeySmellWords = [
    "rank",
    "fetid",
    "vile",
    "rancid",
    "pungent",
    "repulsive",
    "revolting",
    "nauseating",
    "sickening",
    "putrid",
    "pungent",
    "rank",
    "fetid",
    "reeking",
    "stinking",
    "strong",
    "intensely",
    "sickening",
    "nauseating",
    "rank",
    "reeking",
    "foul",
    "nasty",
    "putrid",
    "stinking",
    "skanky",
    "funky",
    "ripe",
    "decayed",
    "Pungent",
    "Acrid",
    "Sharp",
    "Putrid",
    "musty",
    "Foul",
    "Rancid",
    "Repulsive",
    "nauseating",
    "Rancid",
    "Pungent",
    "Acrid",
    "Foul",
    "Nauseating",
    "Fetid",
    "putrid",
    "stinking",
    "vile",
    "wretched"
]

export const discordantMonkeyBehaviourSentences = [
"it is screeching",
"it is unresponsive",
"it is scratching itself",
"it is picking its nose",
"it is eating something strange",
"it is eating its own poop",
"it is playing with its own feces",
"it is picking its nose and eating the boogers",
"it is licking its own butt",
]