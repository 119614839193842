import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, CLEAR_ALL_TILES, LOAD_TILES_SUCCESS, CREATE_TILE_SUCCESS, REMOVE_TILE, LOAD_TILES_FINISHED, ADD_TILE, UPDATE_TILE, GET_TRADE_TILES_SUCCESS, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  isInitialised: false,
  allIds: [],
  tradeTiles: [],
  byId: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case CLEAR_ALL_TILES: {
      return {
        ...initialState
      }
    }

    case GET_TRADE_TILES_SUCCESS: {
      return {
        ...state,
        tradeTiles: [...action.payload],
      }
    }

    case LOAD_TILES_SUCCESS: {
      const tiles = action.payload;

      if (!tiles) {
        return { ...state };
      }

      let newState = { isInitialised: state.isInitialised,allIds: [],tradeTiles: [],byId: {} };

      tiles.forEach(tile => {
        newState.allIds.push(tile._id);
        newState.byId[tile._id] = {
          ...tile
        }
      })

      return newState;
    }

    case LOAD_TILES_FINISHED: {
      return {
        ...state,
        isInitialised: true
      }
    }

    case REMOVE_TILE: {
      const newByIds = { ...state.byId };
      delete newByIds[action.payload._id];

      return {
        isInitialised: state.isInitialised,
        allIds: [ ...state.allIds.filter(id => id !== action.payload._id) ],
        byId: { ...newByIds }
      }
    }

    case ADD_TILE: 
    case UPDATE_TILE: {
      console.log('ADD_TILE', action.payload._id)
      return {
        isInitialised: state.isInitialised,
        allIds: [ ...state.allIds, action.payload._id ],
        byId: { ...state.byId, [action.payload._id]: { ...state.byId[action.payload._id], ...action.payload, panelId: action.payload.panelId, tileId: action.payload.tileId, characterId: action.payload.characterId } }
      }
    }

    default:
      return state;
  }
}
