export const capybaraFaceSentences = [
"Adorable face with big eyes.",
"Cute face with long whiskers.",
"Playful face with a mischievous grin.",
"Sweet face with gentle features.",
"A plump, face with small, dark eyes and a nose that twitches ever so slightly.",
"A portly, rotund face with a mottled complexion, beady eyes and a flat nose.",
"Two big, dark eyes set close together in a furry face.",
"A wide, flat nose and long, whiskers framing a pink muzzle.",
"Two big, pointy ears poking up through blond fur.",
"A face that looks almost like a cross between",
"Two large, protruding teeth jut out from its lower jaw.",
"A long, pink snout is the dominant feature of its face.",
"Two small, beady eyes are set close together on its face.",
"Its furry ears are pink and sit close to",
"Round, black eyes gaze out from a furry, pink face.",
"A long, pink snout protrudes from a face covered in soft, yellow fur.",
"Two furry ears sit atop a pink, furry face.",
]

export const capybaraBodySentences = [
"Plush fur that feels like velvet",
"A long, lithe body that is surprisingly heavy",
"Giant, round eyes that seem to beg for a belly rub",
"Big, webbed feet that are perfect for swimming",
"It has webbed feet and four toes on each foot.",
"its eyes are small and their ears are rounded.",
"Hefty and rotund, it waddles through the underbrush.",
"A long, slender neck",
"A long, furry tail",
"Small, furry ears",
"A short, stubby nose",
"A short, stocky body",
"Short, furry legs",
"Large, webbed feet",
"it has a short, stumpy tail.",
"it has four short, stubby legs.",
]

export const capybaraSoundWords = [
    "belching",
    "chomping",
    "scurrying",
    "grunting",
    "squealing",
    "barking",
    "howling",
    "crying",
    "laughing",
    "squeaking",
    "Snorting",
    "Sniffing",
    "Wheezing",
    "Hissing",
    "Chittering",
    "Chattering",
    "Clucking",
    "Murmuring",
    "Grunting",
    "Rumbling",
    "Squeaking",
    "skittering",
    "squealing",
    "grunting",
    "chirping",
    "clicking",
    "crunching",
    "scuttling",
    "rustling",
    "whistling",
    "Squeaking",
    "chattering",
    "grunting",
    "squealing",
    "wheezing",
    "moaning",
    "groaning",
    "panting",
    "snorting",
    "sniffing",
    "wheezing",
    "honking",
    "snorting",
    "grunting",
    "bleating",
    "mewing",
    "crying",
    "bellowing",
    "roaring",
    "shrieking"
]

export const capybaraFurWords = [
"Plush",
"Soft",
"Supple",
"Smooth",
"Velvety",
"Bright",
"Warm",
"Electric",
"Radiant",
"Soothing",
"Fluffy",
"Soft",
"Vibrant",
"luminous",
"Cheery",
"Radiant",
"sunny",
"warm",
"Inviting",
"Optimistic",
"silky",
"soft",
"smooth",
"lustrous",
"velvety",
"downy",
"fuzzy",
"plush",
"woolly",
"hairy",
]

export const capybaraSmellWords = [
"Fruity",
"Sweet",
"Cucumber",
"Popcorn",
"Pina Colada",
"Bubble Gum",
"Chewing Tobacco",
"Icy Hot",
"Baby Powder",
"Mango",
"sweet",
"fruity",
"floral",
"candy-like",
"musky",
"spicy",
"vanillic",
"resinous",
"balsamic",
"Fruity",
"Sweet",
"Floral",
"Powdery",
"Fresh",
"Sparkling",
"Aromatic",
"Delicious",
"Heavenly",
"lip-smacking",
"Floral",
"Tropical",
"Sweet",
"Fruity",
"Juicy",
"Zesty",
"Aromatic",
"Spicy",
"Tangy",
]

export const capybaraBehaviourSentences = [
"it is looking at me with its beady little eyes",
"it is drooling all over its fur",
"it is drooling all over the ground",
"it is drooling all over itself.",
"it is making threatening gestures",
"it looks almost like a statue",
"it is staring intently at something far away",
]