export const woneyedMonkeyFaceSentences = [
"Cute little face with big bright eyes.",
"Playful face with a mischievous grin.",
"Majestic face with a regal expression.",
"Wistful face with a faraway look.",
"silly face with a tongue",
"it has a long, thin face with a pointed nose.",
"it has large, brown eyes set close together.",
"it has a cute, furry face.",
"It has big, round eyes that are very expressive.",
"Its mouth is small and delicate, with a cute little nose.",
"Its fur is soft and a beautiful orange color.",
"A mischievous face with big, curious eyes.",
"A face that is both playful and wise.",
"A face that is full of life and energy.",
"A face that is always ready to play.",
"A face that loves to eat and drink.",
"it has a long, furry tail.",
"It has a black face with white stripes.",
"Its eyes are large and black.",
"It has a small mouth with sharp teeth.",
"it is a small, agile creature.",
]

export const woneyedMonkeyBodySentences = [
"it has a long, slender body.",
"It is covered in soft, furry fur.",
"it has a long tail that it uses to help it swing through the trees.",
"its eyes are large and expressive.",
"it has a reddish brown coat.",
"It has a long tail that it uses to swing from tree to tree.",
"it is an excellent climber.",
"It has sharp claws that help it to cling to branches.",
"it is a small, delicate creature.",
"It has a slender body, long limbs, and a bushy tail.",
"Its fur is a soft, lustrous brown, with lighter patches on its belly and chest.",
"its body is covered in brightly colored fur.",
"its body is small and compact.",
"its body is agile and able to move quickly through the trees.",
"its long tail helps it balance",
"it has a long, thin body.",
"It is covered in reddish-brown fur.",
"it has a long tail.",
"It has a black face with white patches around the eyes.",
]

export const woneyedMonkeySoundWords = [
    "Hooting",
    "howling",
    "laughing",
    "grunting",
    "shrieking",
    "chattering",
    "singing",
    "whistling",
    "calling",
    "barking",
    "Chattering",
    "Yelling",
    "Howling",
    "Snorting",
    "Sniffing",
    "Scratching",
    "Grunting",
    "Hissing",
    "Wheezing",
    "Growling",
    "Roaring",
    "Chittering",
    "Chattering",
    "Howling",
    "Yelling",
    "Shrieking",
    "Cackling",
    "Laughing",
    "Yipping",
    "Crying",
    "Chattering",
    "Cheeking",
    "Chuckling",
    "Clucking",
    "Coughing",
    "Crying",
    "Hissing",
    "Screaming",
    "Snorting",
    "Wheezing"
]

export const woneyedMonkeyFurWords = [
    "silky",
    "soft",
    "smooth",
    "shiny",
    "bristly",
    "course",
    "dry",
    "scaly",
    "bumpy",
    "knobby",
    "soft",
    "furry",
    "cuddly",
    "smooth",
    "baby-like",
    "delicate",
    "wondersoft",
    "silky",
    "velvety",
    "smooth",
    "Soft",
    "Supple",
    "Fine",
    "Furry",
    "Hairy",
    "Wispy",
    "Wavy",
    "Thick",
    "Curly",
    "Fluffy",
    "Thick",
    "Coarse",
    "Dry",
    "Scaly",
    "Itchy",
    "Oily",
    "Smelly",
    "Bumpy",
    "hairy",
    "wrinkled",
    "lustrous",
    "soft",
    "velvety",
    "supple",
    "sleek",
    "shiny",
    "vibrant",
    "rich",
    "textured",
    "exotic"
]

export const woneyedMonkeySmellWords = [
    "Pungent",
    "Vinegary",
    "Ripe",
    "Fruity",
    "Earthy",
    "Sweet",
    "Sour",
    "Spicy",
    "Aromatic",
    "Potent",
    "Fruity",
    "Sweet",
    "Moist",
    "Ripe",
    "Earthy",
    "Nutty",
    "Woody",
    "Aromatic",
    "Spicy",
    "Pungent",
    "Ricochet",
    "Leather",
    "Soil",
    "nudity",
    "Smoke",
    "Horse",
    "musk",
    "Amber",
    "Vanilla",
    "Rain",
    "stinky",
    "sweaty",
    "musky",
    "rank",
    "fetid",
    "foul",
    "reeking",
    "putrid",
    "stinking",
    "vile",
    "Fruity",
    "Earthy",
    "Woody",
    "Spicy",
    "Sweet",
    "Tangy",
    "Sour",
    "Pungent",
    "Musky",
    "Decaying"
]

export const woneyedMonkeyBehaviourSentences = [
"it is looking at me with its beady eyes",
"it is scratching itself",
"it is staring at me",
"it is making threatening gestures",
"it is trying to intimidate us with its size.",
"it is walking backwards.",
"it is climbing down the tree headfirst.",
"it is eating leaves off of the ground.",
"it is grooming itself with its feet.",
"it is trying to attract my attention",
"it is looking for something",
"it is throwing things",
]