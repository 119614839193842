import { GET_CLOTHING_TYPES_LOADING, GET_CLOTHING_TYPES_SUCCESS } from '../actionTypes';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';

export const getClothingTypesSuccess = payload => {
    return ({
        type: GET_CLOTHING_TYPES_SUCCESS,
        payload
    })
}

export const getClothingTypesAsync = payload => dispatch => {
    return client.service('clothing-types').find()
        .then((response) => {
            dispatch(getClothingTypesSuccess(response));
            return response
        })
        .catch((error) => {
            console.error('error', error);
        })
}