export const goatFaceSentences = [
    "A long face with a beard.",
"A face with big eyes and a small nose.",
"A face with big ears and a small mouth.",
"A face with a big nose and small eyes.",
"A face with a big mouth and small eyes.",
"its face is looking tired and worn out.",
"its face is sad and pathetic.",
"its face is full of wrinkles and lines.",
"its face is all muddy and dirty.",
"A steely gaze that seems to pierce your soul.",
"cruel, blank eyes that hold no empathy ",
"a face that seems hewn from stone ",
"a face that is eternally expressionless ",
"a countenance that is as hard as granite",
"A face that is all angles and planes",
"A face that is serious and solemn",
"A face that has a wisdom beyond its years",
"A face that is calm and serene",
"A face that is stoic and unemotional",

]

export const goatBodySentences = [
"its body is covered in a soft, grey fur.",
"it has a long, slender body.",
"its body is muscular and strong.",
"its body is agile and graceful.",
"its body is healthy and fit",
"its body is covered in a thin layer of grey fur.",
"its body is slender and athletic.",
"its body is muscular and lean.",
"its body is toned and athletic.",
"its body is covered in a layer of grey fur.",
"The fur on its body is a mix of grey and white.",
"its body is a medium size.",
"its body is slightly hairy.",
"its body is covered in a thick, course fur.",
"its body is long and slender, with a muscular build.",
"its fur is grey and white, with a black 'saddle' marking.",
"Its fur is short and coarse.",
"It has two small horns on its head.",
]

export const goatSoundWords = [
    "Bellowing",
    "Bleating",
    "Whistling",
    "honking",
    "bleating",
    "screeching",
    "cackling",
    "braying",
    "howling",
    "roaring",
    "yelping",
    "yowling"
]

export const goatFurWords = [
    "Coarse",
    "thick",
    "shaggy",
    "mottled",
    "uneven",
    "bleached",
    "dingy",
    "dull",
    "faded",
    "frayed",
    "Hairy",
    "Fuzzy",
    "Fluffy",
    "Woolly",
    "Downy",
    "Frizzy",
    "Silky",
    "Wavy",
    "Curly",
    "Furry",
    "Coarse",
    "dull",
    "matted",
    "greasy",
    "stringy",
    "dry",
    "hard",
    "brittle",
    "broken",
    "dusty",
    "woolly",
    "mottled",
    "furry",
    "bristly",
    "course",
    "rough",
    "hairy",
    "shaggy",
    "nappy",
    "Coarse",
    "Knobby",
    "Oily",
    "Smelly",
    "Grey",
    "Itchy",
    "Shedding",
    "Brittle",
    "Dry",
    "Bumpy"
]

export const goatSmellWords = [
    "Foul",
    "Goaty",
    "Pungent",
    "Musky",
    "Rotting",
    "Putrid",
    "Strong",
    "Unpleasant",
    "putrid",
    "rank",
    "pungent",
    "foul",
    "reeking",
    "overloaded",
    "laden",
    "saturated",
    "drenched",
    "permeated",
    "rank",
    "gamey",
    "pungent",
    "fetid",
    "malodorous",
    "noisome",
    "putrid",
    "revolting",
    "sickening",
    "vile",
    "barnyard",
    "dirty",
    "musty",
    "rank",
    "soiled",
    "fouled",
    "impure",
    "contaminated",
    "polluted",
    "befouled",
    "Bitter",
    "sour",
    "milky",
    "ammoniac",
    "gamey",
    "greasy",
    "musky",
    "rank",
    "pungent",
    "putrid"
]

export const goatBehaviourSentences = [
"it is making an ungodly noise",
"it is looking at me with its beady little eyes",
"it is foaming at the mouth",
"it is scratching itself incessantly",
"it is licking its fur in a weird way",
"it is standing in a strange position",
"it is walking oddly",
"it is bedraggled and forlorn, looking lost and out of place.",
"it is calmly grazing, but seems to be keeping a wary eye on its surroundings.",
"it is covered in a thin layer of dust",
"it is staring at you",
]