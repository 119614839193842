import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_BRAINCHIPS_SUCCESS, ADD_BRAINCHIP, UPDATE_BRAINCHIP, REMOVE_BRAINCHIP } from "../actionTypes";

const initialState = {
  brainchips: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case START_NEW_GAME: {
      return { ...initialState }
    }

    case GET_BRAINCHIPS_SUCCESS: {
      const brainchips = action.payload;

      return {
        ...state,
        brainchips: [...brainchips],
      }
    }

    case ADD_BRAINCHIP: {
      let brainchips = [...state.brainchips];

      brainchips = [ ...brainchips, action.payload ]

      return {
        ...state,
        brainchips: [ ...brainchips ],
      }
    }

    case UPDATE_BRAINCHIP: {
      let brainchips = [...state.brainchips];

      brainchips = [
        ...brainchips.filter(brainchip => brainchip._id !== action.payload._id),
        { ...brainchips.find(brainchip => brainchip._id === action.payload._id), ...action.payload }
      ]

      return {
        ...state,
        brainchips: [ ...brainchips ],
      }
    }

    case REMOVE_BRAINCHIP: {
      return {
        ...state,
        brainchips: [ ...state.brainchips.filter(brainchip => (brainchip._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
