export const blindingFaceSentences = [
    "A face that is contorted in pain",
"A face that is blind and searching",
"A face that is swollen and angry",
"A face that is deformed and monstrous",
"A face that is twisted in hatred",
"A face that is inhuman",
"Half-blind and mole-like, it stares unblinkingly with its one eye",
"A hideous, monstrous face that is impossible to look upon without being blinded",
"A face that is nothing but a mass of writhing",
"A face that is blindingly bright",
"A face that is so bright it's blinding",
"A face that is so bright, you can't see it",
"A face that is so bright, it's like looking into the sun",

]

export const blindingBodySentences = [
    "Swiping its powerful claws through the air",
"Vicious and unseeing",
"It stalks forward, its steps faltering only occasionally as it feels its way through the darkness",
"It is huge and globular, its body quivering with anticipation as it draws near",
"its body is completely covered in thick, leathery skin",
"it's huge, muscular body is covered in scars",
"its body is bloated and grotesque, with distended veins",
"it's body is writhing with maggots and other parasites",
"It's body is covered in large, opaque scales",
"glossy black carapace",
"sharp claws",
"ragged breathing",
"Cold, slimy skin",
"Vicious temperament",
]

export const blindingSoundWords = [
    "Roaring",
"Snarling",
"Rumbling",
"Growling",
"Howling",
"scraping",
"Clawing",
"feet shuffling",
"Grunting",
"startling",
"grating",
"harsh",
"grumbly",
"deep",
"throaty",
"sickening",
"rumbling",
"howling",
"screeching",
"wailing",
"whimpering",
"growling",
"hissing",
"snarling",
"foaming",
"panting",
"Roaring",
"Flailing",
"Rushing",
]

export const blindingFurWords = [
        "hirsute",
    "shaggy",
    "fuzzy",
    "furry",
    "hairy",
    "downy",
    "woolly",
    "bristly",
    "rough",
    "hairy",
    "matt",
    "hairy",
    "course",
    "dry",
    "brittle",
    "scaly",
    "leathery",
    "bumpy",
    "calloused",
    "smelly",
    "dark",
    "black",
    "dull",
    "smooth",
    "matte",
    "soft",
    "silky",
    "slippery",
    "shiny",
    "textured",
    "Fuzzy",
    "Scaly",
    "Pimpled",
    "Monsterous",
    "furry",
    "Rapid",
    "Sinister",
    "Grotesque",
    "Tentacled",
    "Slimy",
    "Silky",
    "Soft",
    "Fluffy",
    "Luxurious",
    "Thick",
    "lush",
    "abundant",
    "Plush",
    "Supple",
    "Sleek"
]

export const blindingSmellWords = [
        "rancid",
    "acrid",
    "pungent",
    "fetid",
    "putrid",
    "reeking",
    "rank",
    "stinking",
    "foul",
    "malodorous",
    "Pungent",
    "rank",
    "reeking",
    "fetid",
    "foul",
    "stinking",
    "noisome",
    "odious",
    "putrid",
    "Rank",
    "Pungent",
    "Fetid",
    "Smoldering",
    "Acrid",
    "Sour",
    "Ammonia",
    "Dank",
    "Putrid",
    "Rancid",
    "rank",
    "pungent",
    "strong",
    "offensive",
    "nauseating",
    "putrid",
    "stench",
    "foul",
    "rotten",
    "reeking"
]

export const blindingBehaviourSentences = [
"totally still",
"not making a sound",
"facing away from you",
"slightly hunched over",
"its head is tilted to the side",
"its claws are out",
"its fur is standing",
"it is sniffing the air and seems to be looking for something",
"it is standing very still and seems to be listening for something",
"it is slowly turning its head from side to side",
"it is suddenly moving",
"Hulking and misshapen, the thing lumbers towards you, its malformed limbs clawing at the ground",
"It's huge, much larger than you'd expect, and it's coming right for you",
"Its eyes are huge and black, and they seem",
"it is tearing at its own flesh",
"it is foaming at the mouth",
"it is making a strange moaning sound",
"it is drooling excessively",
"it is twitching uncontrollably",
"it is flailing its arms wildly in the air"
]