import { GET_CONSTRUCTION_TYPES_LOADING, GET_CONSTRUCTION_TYPES_SUCCESS, GET_CONSTRUCTION_TYPES_FAILURE } from '../actionTypes';
import { clearAllTiles, loadTilesSuccess } from './tile.actions';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';

export const getConstructionTypesSuccess = payload => {
    return ({
        type: GET_CONSTRUCTION_TYPES_SUCCESS,
        payload
    })
}

export const getConstructionTypesFailure = payload => ({
    type: GET_CONSTRUCTION_TYPES_FAILURE
})

export const getConstructionTypesAsync = payload => dispatch => {
    return dispatch(getRecursivelyAsync('construction-types', getConstructionTypesSuccess, 0));
}