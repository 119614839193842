export const pikeFaceSentences = [
"Muddy blue eyes",
"Pock-marked skin",
"Red, bulbous nose",
"angular face",
"hooked nose",
"beady eyes",
"large forehead",
"thin lips",
"sunken cheeks",
"pointy chin",
]

export const pikeBodySentences = [
"Tall and slender body type",
"Well-proportioned and athletic body",
"Big, strong muscles",
"Its body is long and slender, with a streamlined shape",
"its body is covered in small, dark scales, and its belly is white",
"it has a long, pointed head with a large mouth, and its teeth are sharp",
"Slimy, scaly body",
"Sharp teeth and gaping mouth",
"Small, beady eyes",
"Slimy, slippery skin",
"Strong, powerful tail",
"Long, slender body",
]

export const pikeSoundWords = [
    "Sizzling",
    "spitting",
    "crackling",
    "hissing",
    "searing",
    "bubbling",
    "foaming",
    "Hissing",
    "Slithering"
]

export const pikeFurWords = [
"Lustrous",
"Silky",
"Smooth",
"Sleek",
"Supple",
"Glossy",
"Sheeny",
"Shiny",
"Reflective",
"Resilient",
"Slimy",
"Scaly",
"Sharp",
"slippery",
"smooth",
"wet",
"slim",
"long",
"Black",
"White",
"Gray",
"Pale",
"Soft",
"Supple",
"Spotted",
]

export const pikeSmellWords = [
    "oily",
    "fishy",
    "briny",
    "pungent",
    "musky",
    "floral",
    "earthy",
    "woody",
    "nutty",
    "ambery",
    "Fishy",
    "Salty",
    "Slimy"
]

export const pikeBehaviourSentences = [
"it is making loud noises",
"it is swimming in circles",
"it is floating on its back",
"it is staring off into the distance.",
"it is not responding to any stimuli.",
"it is completely unaware of its surroundings.",
"it is completely unresponsive to anything",
"it is lurking in the shadows",
"it is moving slowly and cautiously",
]