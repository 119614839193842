export const brownBearFaceSentences = [
"A furry green face with small black eyes.",
"A big green nose in the middle of a furry face.",
"Two small black eyes on a furry green face.",
"A furry green face with a big black nose.",
"A furry green face with two small",
"A large, round head with two small, beady eyes.",
"A short snout with a large, open mouth.",
"Long, curved claws protruding from furry paws.",
"A shaggy coat of green fur, matted in places.",
"The green bear's face is covered in a dense forest of green fur.",
"Its eyes are a deep, dark green, and its nose is a bright, vibrant green. ",
"Its mouth is a wide, gaping hole that is filled with sharp, green teeth. Its tongue is a long, slimy green serpent",
"A big, green, furry face.",
"A face that looks like it's made of leaves.",
"A face with a big, green, nose.",
"A face with big, green eyes.",
"A face with big, green ears.",
"A big, green bear face with a huge nose.",
"A big, green bear face with small, beady eyes.",
"A big, green bear face with a wide mouth.",
"A big, green bear face with big, furry ears.",
]

export const brownBearBodySentences = [
"A huge, lumbering beast, its fur matted and green.",
"It's a hulking mass of green fur, its beady eyes glaring at you.",
"its body is covered in a soft, downy fur.",
"its body is muscular and well-proportioned.",
"its body is sleek and agile.",
"its body is massive and powerful.",
"It has a few pieces of straw stuck to its fur",
"It has a large spider crawling on its back",
]

export const brownBearSoundWords = [
    "Roaring",
    "Snarling",
    "Growling",
    "Rumbling",
    "Scratching",
    "Clawing",
    "Thumping",
    "Shuffling",
    "Grunting",
    "Snoring",
    "Sneezing",
    "snorting",
    "wheezing",
    "grunting",
    "snuffling",
    "growling",
    "huffing",
    "puffing",
    "Hulking",
    "lumbering",
    "growling",
    "snarling",
    "Lumbering",
    "growling",
    "hissing",
    "barreling",
    "brawling",
    "bellowing",
    "grumping",
    "huffing",
    "puffing",
    "roaring",
    "roaring",
    "bellowing",
    "growling",
    "hissing",
    "puffing",
    "chomping",
    "munching",
    "crunching",
    "grumbling",
    "groaning"
]

export const brownBearFurWords = [
    "Tropical",
    "verdant",
    "leafy",
    "grassy",
    "olive",
    "Pea",
    "romantic",
    "wistful",
    "whimsical",
    "nostalgic",
    "dark",
    "olive",
    "forest",
    "verdant",
    "chlorophyll",
    "jade",
    "Spring",
    "fresh",
    "grass",
    "trees",
    "shaggy",
    "bright",
    "Hunter",
    "olive",
    "mint",
    "dark",
    "light",
    "grassy",
    "Pine",
    "Meadow"
]

export const brownBearSmellWords = [
    "Sharp",
    "Earthy",
    "Bitter",
    "Pungent",
    "Damaged",
    "Rotten",
    "Decayed",
    "Ammonia",
    "Crusty",
    "Oily",
    "pungent",
    "musky",
    "earthy",
    "grassy",
    "sharp",
    "acrid",
    "bitter",
    "ammonia",
    "astringent",
    "piquant",
    "Crisp",
    "Earthy",
    "Wet",
    "Vibrant",
    "Juicy",
    "Thick",
    "Fruity",
    "Pungent",
    "Musky",
    "Ample",
    "leafy",
    "musky",
    "earthy",
    "loamy",
    "vegetal",
    "grassy",
    "herbal",
    "pungent",
    "acrid",
    "astringent",
    "musky",
    "wet",
    "foresty",
    "new",
    "old",
    "burly",
    "wet",
    "slimy",
    "furry"
]

export const brownBearBehaviourSentences = [
"it is emitting a strange green glow",
"it seems to be made of pure energy",
"it is almost transparent",
"it is floating a few inches off the ground",
"it has one eye",
"it is doing a handstand.",
"it is acting very suspiciously",
"it is being very furtive",

]