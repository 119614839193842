import { GET_TENT_TYPES_SUCCESS } from "../actionTypes";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_TENT_TYPES_SUCCESS: {
      const tentTypes = action.payload;

      if (!tentTypes){
        return [...state];
      }

      return [
        ...state,
        ...tentTypes
      ]
    }
    default:
      return state;
  }
}
