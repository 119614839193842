import React from "react";
import { connect } from 'react-redux'

import {
    selectDefendingCharacterId,
    selectCharacterById,
    selectCharacter
} from '../../../redux/selectors';

import { fightCharacterAsync } from '../../../redux/actions/character.actions';
import { hideAllMenus } from '../../../redux/actions/keyboard-shortcuts.actions';

import './FightConfirmation.css';

class FightConfirmation extends React.Component {

    getWinProbability() {
        let attackerFightPoints = 1;
        let defenderFightPoints = 1;

        if (this.isResting(this.props.defender)) {
            defenderFightPoints = 0;
        }

        attackerFightPoints += this.getWeaponAttackValue(this.props.attacker);
        defenderFightPoints += this.getWeaponAttackValue(this.props.defender);

        // armour
        attackerFightPoints += this.getArmourDefenceValue(this.props.attacker);
        defenderFightPoints += this.getArmourDefenceValue(this.props.defender);

        const totalPoints = attackerFightPoints + defenderFightPoints;

        return attackerFightPoints / totalPoints;
    }

    isResting(character) {
        return !!character.restingFurnitureId;
    }

    getWeaponAttackValue(character) {
        if (!character.weaponType) {
            return 0;
        }

        return character.weaponType.damage;
    }

    getArmourDefenceValue(character) {
        if (!character.armourType) {
            return 0;
        }

        return character.armourType.defence;
    }

    fightCharacter() {
        this.props.fightCharacterAsync({ _id: this.props.defender._id })
        this.props.hideAllMenus();
    }

    cancel() {
        this.props.hideAllMenus();
    }

    render() {
        return (
            <div className="fight-confirmation">
                <p className="fight-title">Kill Character</p>
                <p></p>
                <p className="equation">KillScore = 1 + WeaponScore + ArmourScore</p>
                <p className="equation">KillScore = 0 for resting characters</p>
                <p></p>
                <p className="equation">
                    &nbsp;Attacker = 1 +&nbsp;
                    <span className="computed">{this.getWeaponAttackValue(this.props.attacker)}</span>
                    &nbsp;+&nbsp;
                    <span className="computed">{this.getArmourDefenceValue(this.props.attacker)}</span>
                </p>
                <p className="equation">
                    <span className="hiding">&nbsp;Attacker </span>
                    =&nbsp;
                    <span className="computed">{
                        (1 + this.getWeaponAttackValue(this.props.attacker) + this.getArmourDefenceValue(this.props.attacker))
                    }</span>
                </p>
                <p className="equation">
                    &nbsp;Defender =&nbsp;
                    {
                        this.isResting(this.props.defender)
                            ? <span className="computed">0</span>
                            : (
                                <span>
                                    1 + 
                                    <span className="computed">&nbsp;{this.getWeaponAttackValue(this.props.defender)}&nbsp;</span>
                                     + 
                                    <span className="computed">&nbsp;{this.getArmourDefenceValue(this.props.defender)}</span>
                                </span>
                            )
                    }
                </p>
                <p className="equation">
                    <span className="hiding">&nbsp;Defender </span>
                    = {
                        this.isResting(this.props.defender) 
                            ? <span className="computed">0</span>
                            : (
                                <span className="computed">
                                    {(1 + this.getWeaponAttackValue(this.props.defender) + this.getArmourDefenceValue(this.props.defender))}
                                </span>
                            )
                    }
                </p>
                
                <p>&nbsp;&nbsp;&nbsp;&nbsp;% Win = <span className="computed">{Math.round(this.getWinProbability() * 100)}%</span></p>

                <p><span className="fight-hint">You will die if you lose the fight</span></p>

                <div className="buttons">
                    <span className="kill-button"
                        onClick={() => this.fightCharacter()}>
                        (K)ill
                    </span>

                    <span className="cancel-button"
                        onClick={() => this.cancel()}>
                        (Esc) Cancel
                    </span>
                </div>
            </div>
        )
    }
}

const mapToStateProps = state => {
    const defendingCharacterId = selectDefendingCharacterId(state);
    const defender = selectCharacterById(state, defendingCharacterId);
    const attacker = selectCharacter(state);

    return { defender, attacker }
}

export default connect(
    mapToStateProps,
    {
        fightCharacterAsync,
        hideAllMenus
    }
)(FightConfirmation);