import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import { updateConversationTreeAsync } from '../../redux/actions/character.actions';
import { disableKeyboardMovement, enableKeyboardMovement, showHelp, startLooking, hideAllMenus } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectCharacter,
} from '../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';

import { client } from '../../services/client';

import './ConversationTreeWizard.css';

import Menu from '../utils/menu/Menu';
import TextInput from '../utils/text-input/TextInput';

import { Hints } from '../conversation-tree/ConversationTree';

class ConversationTreeWizard extends React.Component {
    state = {
        conversationTree: [],
        selectedOptionIndex: 0
    }

    componentWillUnmount() {
        this.props.updateConversationTreeAsync({
            _id: this.props.character._id,
            conversationTree: this.state.conversationTree.map(({ text, indentation, hint }) => ({
                text,
                indentation,
                hint
            }))
        })
    }

    componentDidMount() {
        if (!this.props.character.conversationTree) {
            this.setState({
                conversationTree: [
                    {
                        text: this.props.character.greeting || 'How do you go?',
                        indentation: 0,
                        shortcut: 'a'
                    },
                ]
            })
            return;
        }

        const conversationTree = this.correctShortcutKeys(this.props.character.conversationTree)
        this.correctClassNames(conversationTree);

        this.setState({
            conversationTree
        })
    }

    correctClassNames(conversationTree) {
        conversationTree.forEach(option => {
            if (option.indentation % 2 === 1) {
                option.className = "hint"
            }
        })
    }

    onOptionChosen(option, event, selectedOptionIndex) {
        if (this.state.isEditingOption || this.state.isCreatingOption) {
            return;
        }

        if (event.key === ' ' || event.key === 'ENTER') {
            // show the edit input modal
            this.setState({
                isEditingOption: true,
                selectedOptionIndex
            })
        }

        if (event.key === ',') {
            const isOptionYourCharacter = option.className !== 'hint';
            let targetOptionIndex = selectedOptionIndex + 1;
            const targetOption = this.state.conversationTree[targetOptionIndex];
            let targetIndentation = option.indentation;

            if (isOptionYourCharacter) {
                targetIndentation += 1
            } else {
                // we need to find the next indentation of the same level and go one above that if there is one.
                this.state.conversationTree.forEach((nextOption, index) => {
                    if (index > selectedOptionIndex && nextOption.indentation === option.indentation) {
                        if (targetOptionIndex !== selectedOptionIndex + 1) {
                            return;
                        }

                        targetOptionIndex = index
                    }
                })
            }

            this.setState({
                isCreatingOption: true,
                targetOptionIndex,
                targetIndentation
            })
        }

        if (event.key === '.') {
            const isOptionYourCharacter = option.className !== 'hint';

            if (isOptionYourCharacter) {
                return;
            }

            const nextOption = this.state.conversationTree[selectedOptionIndex + 1];

            if (nextOption?.indentation > option.indentation) {
                return;
            }

            this.setState({
                isCreatingOption: true,
                targetOptionIndex: selectedOptionIndex + 1,
                targetIndentation: option.indentation + 1
            })
        }

        if (event.key === '/') {
            this.deleteOptionAndChildren(option, selectedOptionIndex)
        }

        if (event.key === ']') {
            if (this.state.conversationTree[this.state.selectedOptionIndex].className !== "hint") {
                return;
            }

            this.setState({
                isSettingHint: true
            })
        }

        if (this.state.isSettingHint) {
            if (event.key === '\\') {
                this.setState({
                    isSettingHint: false
                })
            }

            if (event.key !== 't' && event.key !== 'c' && event.key !== 'x' && event.key !== '-' && event.key !== 'r') {
                return;
            }

            if (event.key === '-') {
                const newConversationTree = [ ...this.state.conversationTree ]
                newConversationTree[this.state.selectedOptionIndex].hint = undefined;
            }

            if (event.key === 't') {
                const newConversationTree = [ ...this.state.conversationTree ]
                newConversationTree[this.state.selectedOptionIndex].hint = Hints.trade;
            }

            if (event.key === 'c') {
                const newConversationTree = [ ...this.state.conversationTree ]
                newConversationTree[this.state.selectedOptionIndex].hint = Hints.talk;
            }

            if (event.key === 'r') {
                const newConversationTree = [ ...this.state.conversationTree ]
                newConversationTree[this.state.selectedOptionIndex].hint = Hints.restart;
            }

            if (event.key === 'x') {
                const newConversationTree = [ ...this.state.conversationTree ]
                newConversationTree[this.state.selectedOptionIndex].hint = Hints.end;
            }
        }
    }

    isValidNewCharacterResponseOption() {
        const optionIndex = this.state.selectedOptionIndex;
        const option = this.state.conversationTree[optionIndex]
        const isOptionYourCharacter = option?.className !== 'hint';

        if (isOptionYourCharacter) {
            return false;
        }

        const nextOption = this.state.conversationTree[optionIndex + 1];

        if (!nextOption) {
            return true;
        }

        if (nextOption?.indentation > option?.indentation) {
            return false;
        }

        return true;
    }

    onEditSubmit(text) {
        const newConversationTree = [ ...this.state.conversationTree ]
        newConversationTree[this.state.selectedOptionIndex].text = text;

        this.setState({
            isEditingOption: false,
            conversationTree: newConversationTree
        })
    }

    onOptionHighlighted(selectedOption) {
        const selectedOptionIndex = this.state.conversationTree.indexOf(selectedOption);
        this.setState({
            selectedOptionIndex
        })
    }

    onCreateSubmit(text) {
        let newConversationTree = [ ...this.state.conversationTree ];

        const newConversationTreeItem = {
            text,
            indentation: this.state.targetIndentation,
            className: this.state.targetIndentation % 2 === 0 ? '' : 'hint',
            shortcut: 'p'
        }

        const newConversationTreeIndex = this.state.targetOptionIndex

        newConversationTree.splice(newConversationTreeIndex, 0, newConversationTreeItem)

        newConversationTree = this.correctShortcutKeys(newConversationTree);

        this.setState({
            isCreatingOption: false,
            conversationTree: newConversationTree
        })
    }

    correctShortcutKeys(newConversationTree) {
        return newConversationTree.map((option, index) => {
            const newShortcut = String.fromCharCode(97 + index)

            return {
                ...option,
                shortcut: newShortcut
            }
        })
    }

    onEditExit() {
        this.setState({
            isEditingOption: false,
        })
    }

    onCreateExit() {
        this.setState({
            isCreatingOption: false,
        })
    }

    deleteOptionAndChildren(option, selectedOptionIndex) {
        if (this.state.selectedOptionIndex === 0) {
            return;
        }

        let newConversationTree = [ ...this.state.conversationTree ];
        newConversationTree.splice(selectedOptionIndex, 1);

        // and then delete all the children as well
        while (newConversationTree[selectedOptionIndex]?.indentation > option?.indentation) {
            newConversationTree.splice(selectedOptionIndex, 1);
        }

        newConversationTree = this.correctShortcutKeys(newConversationTree)

        this.setState({
            conversationTree: newConversationTree
        })
    }

    render() {
        return (
            <div className="conversation-tree-wizard">
                {
                    this.state.isEditingOption ? (
                        <div className="conversation-wizard-edit-text-container">
                            <p className="conversation-wizard-exit"><span className="action">ESC</span> to exit</p>
                            <div className="conversation-wizard-edit-text">
                                <TextInput onTextSubmit={this.onEditSubmit.bind(this)} text={this.state.conversationTree[this.state.selectedOptionIndex].text} exitKey="Escape" onExitKey={this.onEditExit.bind(this)} />
                            </div>
                        </div>
                    ) : ('')
                }

                {
                    this.state.isCreatingOption ? (
                        <div className="conversation-wizard-edit-text-container">
                            <p className="conversation-wizard-exit"><span className="action">ESC</span> to exit</p>
                            <div className="conversation-wizard-edit-text">
                                <TextInput onTextSubmit={this.onCreateSubmit.bind(this)} exitKey="Escape" onExitKey={this.onCreateExit.bind(this)} />
                            </div>
                        </div>
                    ) : ('')
                }

                {
                    this.state.isSettingHint ? (
                        <div className="conversation-wizard-edit-text-container">
                            <p className="conversation-wizard-exit"><span className="action">\</span> to save</p>
                            <div className="conversation-wizard-edit-actions">
                                <ul>
                                    <li className={!this.state.conversationTree[this.state.selectedOptionIndex].hint ? "selected-line" : ""}><span className="action">-</span> no action</li>
                                    <li className={this.state.conversationTree[this.state.selectedOptionIndex].hint === Hints.trade ? "selected-line" : ""}><span className="action">t</span> {Hints.trade}</li>
                                    <li className={this.state.conversationTree[this.state.selectedOptionIndex].hint === Hints.talk ? "selected-line" : ""}><span className="action">c</span> {Hints.talk}</li>
                                    <li className={this.state.conversationTree[this.state.selectedOptionIndex].hint === Hints.restart ? "selected-line" : ""}><span className="action">r</span> {Hints.restart}</li>
                                    <li className={this.state.conversationTree[this.state.selectedOptionIndex].hint === Hints.end ? "selected-line" : ""}><span className="action">x</span> {Hints.end}</li>
                                </ul>
                            </div>
                        </div>
                    ) : ('')
                }

                <div className="conversation-tree-wizard-container">
                    <Menu options={this.state.conversationTree}
                        chosenOption={this.onOptionChosen.bind(this)}
                        keyPressed={this.onOptionChosen.bind(this)}
                        highlightedOption={this.onOptionHighlighted.bind(this)}
                        disabled={this.state.isSettingHint || this.state.isEditingOption || this.state.isCreatingOption}
                        menuContainer="conversation-tree-wizard-container"
                    />
                </div>

                <div className="conversation-tree-actions-container">
                    <p><span className="action">SPACE</span> - Edit response</p>
                    <p><span className="action">,</span> - Create new response for other character [<span className="hint">their text in blue</span>]</p>
                    <p className={this.isValidNewCharacterResponseOption() ? "" : "disabled"}><span className="action">.</span> - Create new response option for yourself [your text in white]</p>
                    <p className={this.state.selectedOptionIndex === 0 ? "disabled" : ""}><span className="action">/</span> - Delete response (and all child responses)</p>
                    <p className={this.state.conversationTree[this.state.selectedOptionIndex]?.className === "hint" ? "" : "disabled"}><span className="action">]</span> - Add/Remove action</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const character = selectCharacter(state)

    return {
        character,
    }
}

export default connect(
    mapStateToProps,
    { hideAllMenus, updateConversationTreeAsync }
)(ConversationTreeWizard);