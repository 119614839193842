import React from "react";
import { connect } from 'react-redux'

import { client } from '../../../services/client';
import { selectHighlight, selectCharacter, selectNeighbouringCharacters } from '../../../redux/selectors';
import { showFightConfirmation } from '../../../redux/actions/keyboard-shortcuts.actions';

import './NeighbouringCharacters.css';

class NeighbouringCharacters extends React.Component {

    componentDidMount() {
        
    }

    fightCharacter({ _id }) {
        this.props.showFightConfirmation({ defendingCharacterId: _id })
    }

    render() {
        const characters = this.props.neighbouringCharacters.map((character) => {
            return (
                <li key={character._id} className="neighbouring-character">
                    <div className="character-name">{character.name}</div>
                    <div className={this.props.highlight ? "hidden" : "action kill-character"} onClick={() => (this.fightCharacter(character))}>kill</div>
                </li>
            )
        })
        return (
            <ul className="neighbouring-characters">
                {characters}
            </ul>
        )
    }
}

const mapToStateProps = state => {
    const highlight = selectHighlight(state);
    const character = selectCharacter(state);
    const neighbouringCharacters = selectNeighbouringCharacters(state, highlight ? { position: {...highlight} } : character);

    return { highlight, neighbouringCharacters }
}

export default connect(
    mapToStateProps,
    {
        showFightConfirmation,
    }
)(NeighbouringCharacters);