import {
    GET_MINERAL_TYPES_SUCCESS,
    GET_MINERAL_TYPES_FAILURE,
} from '../actionTypes';
import { clearAllTiles, loadTilesSuccess } from './tile.actions';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';

import { client } from '../../services/client';

export const getMineralTypesSuccess = payload => {
    return ({
        type: GET_MINERAL_TYPES_SUCCESS,
        payload
    })
}

export const getMineralTypesFailure = payload => ({
    type: GET_MINERAL_TYPES_FAILURE
})

export const getMineralTypesAsync = payload => dispatch => {
    return client.service('mineral-types').find()
        .then((response) => {
            dispatch(getMineralTypesSuccess(response));
            return response
        })
        .catch((error) => {
            console.error('error', error);
            dispatch(showUnknownError({ message: error.message }));
        })
}