import React from "react";
import { connect } from 'react-redux'

import { getSeason, getSeasonOn, getLastDayOfPreviousHarvestSeason } from '../../../utils/get-season';

import { isBefore, format, formatDistance, formatRelative, subDays } from 'date-fns';

import { cultivatePlantAsync, harvestPlantAsync } from '../../../redux/actions/plant.actions';
import {
    selectHighlight,
    selectCharacter,
    selectPlantInstanceIdAtTile,
    selectPlantById,
} from '../../../redux/selectors';
import { UNSKILLED_COST_MULTIPLIER, hasSkill } from '../../../utils/has-skill';

import './PlantActions.css';

const uiText = {
    SPRING: 'Spring',
    SUMMER: 'Summer',
    AUTUMN: 'Autumn',
    WINTER: 'Winter'
};

class PlantActions extends React.Component {
    actions = [
        {
            name: 'tend plant',
            isDisabled: () => (this.state.isWorking),
            callback: () => {
                if (this.state.isWorking) {
                    return;
                }

                this.setState({ isWorking: true });

                this.props.cultivatePlantAsync({ id: this.props.plantInstance.id })
                    .finally(() => (this.setState({ isWorking: false })));
            },
            getCost: () => {
                return hasSkill(this.props?.character, this.props?.plantInstance?.plant?.skillName) ? this.props?.plantInstance?.plant?.tendingCost : UNSKILLED_COST_MULTIPLIER * this.props?.plantInstance?.plant?.tendingCost;
            }
        }, {
            name: 'harvest plant',
            isDisabled: () => (this.state.isWorking || !this.getIsHarvestable(this.props?.plantInstance?.plant?.harvestSeason)),
            callback: () => {
                if (this.state.isWorking) {
                    return;
                }

                this.setState({ isWorking: true });

                this.props.harvestPlantAsync({ id: this.props.plantInstance.id })
                    .finally(() => (this.setState({ isWorking: false })));
            },
            getCost: () => {
                if (this.props?.plantInstance?.plant?.harvestingCost > 100) {
                    return this.props?.plantInstance?.plant?.harvestingCost
                }
                return hasSkill(this.props?.character, this.props?.plantInstance?.plant?.skillName) ? this.props?.plantInstance?.plant?.harvestingCost : UNSKILLED_COST_MULTIPLIER * this.props?.plantInstance?.plant?.harvestingCost;
            }
        }
    ];

    constructor() {
        super();

        this.state = { isWorking: false };
    }

    getTendedString() {
        const lastTendedAt = this.props.plantInstance.tendedAt ? new Date(this.props.plantInstance.tendedAt) : undefined;
        const lastDayOfPreviousHarvest = getLastDayOfPreviousHarvestSeason(this.props.plantInstance.plant.harvestSeason || 'WINTER')

        switch (this.props.plantInstance.tendedCount) {
            case 0:
                return 'Untended'
            case 1: 
            case 2:
                return 'Barely tended'
            case 3: 
            case 4:
                return 'Badly tended'
            case 5: 
            case 6:
                return 'Lightly tended'
            case 7: 
            case 8:
                return 'Tended'
            case 9: 
            case 10:
                return 'Well Tended'
            case 11: 
            case 12:
                return 'Ripening'
            case 13: 
            case 14:
                return 'Nearly Ripe'
            case 15: 
            default:
                return 'Ripe'; 
        }
    }

    getPenaltyClass(action) {
        if (this.props?.plantInstance?.plant?.harvestingCost > 100 && action.name === "harvest plant") {
            return "";
        }
        return hasSkill(this.props?.character, this.props?.plantInstance?.plant?.skillName) ? "" : "unskilled-penalty"
    }

    getIsHarvestable(harvestSeason) {
        if (!harvestSeason) {
            return true;
        }

        const currentSeason = getSeason();

        if (currentSeason === harvestSeason) {
            return true;
        }

        return false;
    }

    render() {
        const actions = !this.props.highlight && this.actions.map((action, index) => {
            return (
                <li key={index}
                    className={action.isDisabled() ? "disabled" : "plant-action"}
                    onClick={() => (action.callback())}>
                    {action.name} [<span className={this.getPenaltyClass(action)}>{action.getCost()}</span>]
                </li>
            )
        })

        const plantProducts = this.props?.plantInstance?.plantProducts?.map((plantProduct, index) => {
            return (
                <span key={index}>{index > 0 ? ", " : ""}{plantProduct.name}</span>
            )
        })

        return this.props.plantInstance ? (
            <div>
                <p className="plant-name">
                    {this.props.plantInstance.plant.name} [{plantProducts}] {this.props.plantInstance?.plant?.harvestSeason ? `[${uiText[this.props.plantInstance?.plant?.harvestSeason]}]` : ''}
                    <span className="message-date">{formatDistance(new Date(this.props.plantInstance.createdAt), new Date())} old</span>
                </p>
                <p>{this.getTendedString()}</p>
                <div>
                    <div>
                        <ul>{actions}</ul>
                    </div>
                </div>
            </div>
        ) : ('')
    }
}

const mapToStateProps = state => {
    const highlight = selectHighlight(state);
    const character = selectCharacter(state);
    const plantId = selectPlantInstanceIdAtTile(state, highlight || character.position)
    const plantInstance = selectPlantById(state, plantId);

    return { highlight, character, plantInstance }
}

export default connect(
    mapToStateProps,
    {
        cultivatePlantAsync,
        harvestPlantAsync
    }
)(PlantActions);