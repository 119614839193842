export const belowTeleporterFaceSentences = [
"It has a large, gaping maw lined with razor-sharp teeth.",
"Its eyes are glowing orbs of malevolent intent.",
"It has a sleek, humanoid body with clawed hands and feet.",
"A long, slimy tongue that licks eagerly at the air.",
"long, skinny arms with sharp claws",
"pointy teeth and black eyes",
"slimy and scaly skin",
"spikes down its back",
"forked tongue",
"long, sinuous body",
"Vast, dark eyes that seem to suck in all the light.",
]

export const belowTeleporterBodySentences = [
"slippery and slimy",
"dripping with ichor",
"dripping with blood",
"It's enormous, bulbous body is a deep, inky black.",
"It's long, lithe body is a shimmering silver.",
"It's body is a swirling vortex of colors.",
"dripping with slime",
"lurks in the shadows",

]

export const belowTeleporterSoundWords = [
    "beeping",
    "humming",
    "crackling",
    "snapping",
    "hissing",
    "whirring",
    "whizzing",
    "popping",
    "sizzling",
    "fizzing",
    "Whirring",
    "Zapping",
    "Sporadic",
    "Salivating",
    "Arcing",
    "Rumbling",
    "Digital",
    "Crunching",
    "Screeching",
    "Hissing",
    "Zapping",
    "Cracking",
    "Banging",
    "Popping",
    "Sizzling",
    "Humming",
    "Whirring",
    "Smacking",
    "Clanking",
    "Flashing",
    "snarling",
    "drooling",
    "hissing",
    "screeching",
    "cackling",
    "laughing",
    "growling",
    "roaring",
    "yelling",
    "screaming",
    "Whirring",
    "Zapping",
    "Popping",
    "Sizzling",
    "snapping",
    "Cracking",
    "Roaring",
    "Rumbling",
    "buzzing",
    "whizzing"
]

export const belowTeleporterFurWords = [
"bristly",
"coarse",
"furry",
"hairy",
"mangy",
"patchy",
"scabby",
"scaly",
"shaggy",
"untamed",
]

export const belowTeleporterSmellWords = [
"Burnt", "acrid", "toxic", "smoky", "ashen", "stinky", "rank", "putrid", "noxious", "fetid",
]

export const belowTeleporterBehaviourSentences = [
"the lumps on its back shift and twitch",
"it foams at the mouth",
"it clicks and clacks its teeth together",
"it has red eyes that seem to pierce your soul",
"it is drooling a murky black liquid",
"it is making a strange gurgling noise",
"it is hissing",
"it is sneezing",
"it is coughing",
"it is wheezing",
"it is panting",
]