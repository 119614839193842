import {
    GET_LOCK_RECIPES_LOADING,
    GET_LOCK_RECIPES_SUCCESS,
    GET_LOCK_RECIPES_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';

export const getLockRecipesSuccess = payload => ({
    type: GET_LOCK_RECIPES_SUCCESS,
    payload
})

export const getLockRecipesFailure = () => ({
    type: GET_LOCK_RECIPES_FAILURE
})

export const getLockRecipesAsync = payload => dispatch => {
    return dispatch(getRecursivelyAsync('lock-recipes', getLockRecipesSuccess, 0));
}