export const rumbleGrassSmellTypes = [
	"of Papaya",
    "of Guava",
    "of Jackfruit",
    "of Pineapple",
    "of Honeydew",
    "of Cantaloupe",
    "of Cinnamon",
    "of grass",
    "of leaves",
    "flowery",
    "sweet",
    "sour",
    "enveloping",
    "clinging",
    "of Mint",
    "of Lemon",
    "of Grapefruit",
    "of Basil",
    "of piling refuse",
    "of quickly erected fences",
    "of where the meadows meet the dunes",
    "of agriculture",
    "of wet, tilled paddies",
    "of distorted greenery",
    "of coral and limestone",
    "of being deep in the strata alone",
    "suspenseful",
    "of debtful despair",
    "of a secret childhood place",
    "connected to land and water",
    "of grazing herds",
    "of fertile soil",
    "of generous communication",
    "restless",
    "uneasy",
    "of lakewater on rock",
    "of barricaded brambles",
    "of narcissus",
    "opoponactically pungent",
    "convincing",
    "of dead air",
    "of lavendar-soaked plant matter",
    "spicy and bitter",
    "of driftwood",
    "of snow-borne blooms",
    "of a bubbling hob on the prairie",
    "of molten wheat",
    "of ash down",
    "of greenery in a charred system",
    "of burning cigar leaf",
    "evocative"
]

export const rumbleGrassBranchTypes = [

]

export const rumbleGrassLeafTypes = [
    "lush",
    "blue-green",
    "full",
    "dense",
    "thick",
    "sturdy",
    "waxy",
    "glossy",
    "smooth",
    "supple ",
    "Wavy",
    "Lush",
    "pale blue-grey",
    "spine-like tufted",
    "a dome-shaped arrangement of",
    "purple-tinged",
    "glaucous",
    "pruned",
    "heat-resistant",
    "quaking",
    "shivering",
    "thrumming",
    "humming",
    "singing",
    "dull-purple",
    "grey-green",
    "seed-bearing racemes growing on its",
    "competitive",
    "wood mites on its",
    "rust fungus growths on its",
    "larvae-hosting",
    "tall",
    "towering",
    "lilting",
    "a warbling creature in its",
    "a bobbing sway to its",
    "ambitious",
    "wind-bowed",
    "contaminated",
    "unexpected flowers under its",
    "jovial",
    "hearty",
    "cheery",
    "freckled",
    "drifting",
    "listless",
]