import React from "react";
import { connect } from 'react-redux'

import { selectFurnitureRecipes } from '../../../redux/selectors';

import { client } from '../../../services/client';

class FurnitureRecipes extends React.Component {

    render() {
        const listItems = this.props.furnitureRecipes
            .filter(furnitureRecipe => (furnitureRecipe.furnitureType.name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase()) > -1))
            .sort((a, b) => (a.furnitureType?.name.localeCompare(b.furnitureType?.name)))
            .map((furnitureRecipe) => {
                return (
                    <li key={furnitureRecipe._id}
                        onClick={() => {
                            return (this.props.onItemSelect(furnitureRecipe))
                        }}>
                        <span className={this.props.selectedItem?._id === furnitureRecipe._id ? "selected": ""}>{furnitureRecipe.furnitureType.name}</span>
                    </li>
                );
            })
        return (
            <ul>{listItems}</ul>
        )
    }
}

const mapStateToProps = state => {
    const furnitureRecipes = selectFurnitureRecipes(state)

    return { furnitureRecipes }
}

export default connect(
    mapStateToProps,
    {  }
)(FurnitureRecipes);