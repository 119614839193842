// Meta
export const GET_META_LOADING = 'GET_META_LOADING';
export const GET_META_SUCCESS = 'GET_META_SUCCESS';

// Clock
export const UPDATE_VISION_RADIUS = 'UPDATE_VISION_RADIUS';

// Connectivity

export const CONNECTED = 'CONNECTED';
export const DISCONNECTED = 'DISCONNECTED';
export const RECONNECTED = 'RECONNECTED';
export const CONNECTION_ERROR = 'CONNECTION_ERROR';

// Location
export const NAME_LOCATION = 'NAME_LOCATION';

// Character

export const UPDATE_POSITION_LOADING = 'UPDATE_POSITION_LOADING';
export const UPDATE_POSITION_SUCCESS = 'UPDATE_POSITION_SUCCESS';
export const UPDATE_CUTSCENE_POSITION_SUCCESS = 'UPDATE_CUTSCENE_POSITION_SUCCESS';
export const UPDATE_POSITION_FAILURE = 'UPDATE_POSITION_FAILURE';

export const BEGIN_WALK_DOWNSTAIRS = 'BEGIN_WALK_DOWNSTAIRS';
export const BEGIN_WALK_UPSTAIRS = 'BEGIN_WALK_UPSTAIRS';

export const NEW_CHARACTER_LOADING = 'NEW_CHARACTER_LOADING';
export const NEW_CHARACTER_SUCCESS = 'NEW_CHARACTER_SUCCESS';
export const NEW_CHARACTER_FAILURE = 'NEW_CHARACTER_FAILURE';

export const GET_CHARACTER_LOADING = 'GET_CHARACTER_LOADING';
export const GET_CHARACTER_SUCCESS = 'GET_CHARACTER_SUCCESS';
export const GET_CHARACTER_FAILURE = 'GET_CHARACTER_FAILURE';
export const CHANGE_CHARACTER = 'CHANGE_CHARACTER';

export const BEGIN_UPDATE_POSITION = 'BEGIN_UPDATE_POSITION';
export const UPDATE_PANEL_LOADING = 'UPDATE_PANEL_LOADING';
export const UPDATE_PANEL_SUCCESS = 'UPDATE_PANEL_SUCCESS';
export const UPDATE_PANEL_FAILURE = 'UPDATE_PANEL_FAILURE';

export const ENABLE_CHARACTER_COLOR = 'ENABLE_CHARACTER_COLOR';
export const DISABLE_CHARACTER_COLOR = 'DISABLE_CHARACTER_COLOR';

export const REMOVE_CHARACTER = 'REMOVE_CHARACTER';
export const ADD_CHARACTER = 'ADD_CHARACTER';
export const UPDATE_CHARACTER = 'UPDATE_CHARACTER';
export const UPDATE_CHARACTER_TOOL = 'UPDATE_CHARACTER_TOOL';

export const EQUIP_TENT_LOCALLY = 'EQUIP_TENT_LOCALLY';
export const UNEQUIP_TENT_LOCALLY = 'UNEQUIP_TENT_LOCALLY';
export const SET_TENT_DIRECTION = 'SET_TENT_DIRECTION';
export const ERECT_TENT_SUCCESS = 'ERECT_TENT_SUCCESS';

export const EQUIP_WORKSHOP_LOCALLY = 'EQUIP_WORKSHOP_LOCALLY';
export const UNEQUIP_WORKSHOP_LOCALLY = 'UNEQUIP_WORKSHOP_LOCALLY';
export const SET_WORKSHOP_DIRECTION = 'SET_WORKSHOP_DIRECTION';
export const ERECT_WORKSHOP_SUCCESS = 'ERECT_WORKSHOP_SUCCESS';

// User

export const LOG_IN_LOADING = 'LOG_IN_LOADING';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';

export const BACK_TO_START_PAGE = 'BACK_TO_START_PAGE';
export const START_NEW_GAME = 'START_NEW_GAME';
export const SEE_AN_ADVERT = 'SEE_AN_ADVERT';
export const RULES = 'RULES';
export const CREATE_CHARACTER = 'CREATE_CHARACTER';
export const START_INTRO = 'START_INTRO';
export const CHOOSE_EMBARK = 'CHOOSE_EMBARK';
export const CREATE_NEW_EMBARK = 'CREATE_NEW_EMBARK';
export const SHOW_CHARACTER_DEATH_PAGE = 'SHOW_CHARACTER_DEATH_PAGE';
export const HIDE_CHARACTER_DEATH_PAGE = 'HIDE_CHARACTER_DEATH_PAGE';

// Panel

export const PANEL_LOADING = 'PANEL_LOADING';
export const PANEL_SUCCESS = 'PANEL_SUCCESS';
export const PANEL_FAILURE = 'PANEL_FAILURE';

// Tile

export const CLEAR_ALL_TILES = 'CLEAR_ALL_TILES';
export const LOAD_TILES_SUCCESS = 'LOAD_TILES_SUCCESS';
export const LOAD_TILES_FINISHED = 'LOAD_TILES_FINISHED';
export const CREATE_TILE_LOADING = 'CREATE_TILE_LOADING';
export const CREATE_TILE_SUCCESS = 'CREATE_TILE_SUCCESS';
export const CREATE_TILE_FAILURE = 'CREATE_TILE_FAILURE';

// Tile Types

export const GET_TILE_TYPES_LOADING = 'GET_TILE_TYPES_LOADING';
export const GET_TILE_TYPES_SUCCESS = 'GET_TILE_TYPES_SUCCESS';
export const GET_TILE_TYPES_FAILURE = 'GET_TILE_TYPES_FAILURE';

export const GET_TILE_RECIPES_LOADING = 'GET_TILE_RECIPES_LOADING';
export const GET_TILE_RECIPES_SUCCESS = 'GET_TILE_RECIPES_SUCCESS';
export const GET_TILE_RECIPES_FAILURE = 'GET_TILE_RECIPES_FAILURE';

export const REMOVE_TILE = 'REMOVE_TILE';
export const ADD_TILE = 'ADD_TILE';
export const UPDATE_TILE = 'UPDATE_TILE';

// Planting

export const GET_PLANTS_SUCCESS = 'GET_PLANTS_SUCCESS';
export const GET_PLANTS_FAILURE = 'GET_PLANTS_FAILURE'; 

export const PLANT_SEED_FAILURE = 'PLANT_SEED_FAILURE';
export const PLANT_SEED_SUCCESS = 'PLANT_SEED_SUCCESS';

export const CULTIVATE_PLANT_SUCCESS = 'CULTIVATE_PLANT_SUCCESS';
export const CULTIVATE_PLANT_FAILURE = 'CULTIVATE_PLANT_FAILURE';

export const HARVEST_PLANT_SUCCESS = 'HARVEST_PLANT_SUCCESS';
export const HARVEST_PLANT_FAILURE = 'HARVEST_PLANT_FAILURE';

// Inventory

export const READ_INVENTORY_SUCCESS = 'READ_INVENTORY_SUCCESS';
export const READ_INVENTORY_FAILURE = 'READ_INVENTORY_FAILURE';

export const READ_TILE_INVENTORY_SUCCESS = 'READ_TILE_INVENTORY_SUCCESS';
export const READ_TILE_INVENTORY_FAILURE = 'READ_TILE_INVENTORY_FAILURE';
export const CLEAR_TILE_INVENTORY = 'CLEAR_TILE_INVENTORY';

export const REMOVE_MATERIAL = 'REMOVE_MATERIAL';
export const ADD_MATERIAL = 'ADD_MATERIAL';
export const UPDATE_MATERIAL = 'UPDATE_MATERIAL';

// Material types

export const GET_MATERIAL_TYPES_LOADING = 'GET_MATERIAL_TYPES_LOADING';
export const GET_MATERIAL_TYPES_SUCCESS = 'GET_MATERIAL_TYPES_SUCCESS';
export const GET_MATERIAL_TYPES_FAILURE = 'GET_MATERIAL_TYPES_FAILURE';

// Plant types

export const GET_PLANT_TYPES_LOADING = 'GET_PLANT_TYPES_LOADING';
export const GET_PLANT_TYPES_SUCCESS = 'GET_PLANT_TYPES_SUCCESS';
export const GET_PLANT_TYPES_FAILURE = 'GET_PLANT_TYPES_FAILURE';

// Construction

export const BUILD_CONSTRUCTION_SUCCESS = 'BUILD_CONSTRUCTION_SUCCESS';
export const BUILD_CONSTRUCTION_FAILURE = 'BUILD_CONSTRUCTION_FAILURE';
export const BUILD_CONSTRUCTION_LOADING = 'BUILD_CONSTRUCTION_LOADING';

export const PROGRESS_CONSTRUCTION_SUCCESS = 'PROGRESS_CONSTRUCTION_SUCCESS';
export const PROGRESS_CONSTRUCTION_FAILURE = 'PROGRESS_CONSTRUCTION_FAILURE';
export const PROGRESS_CONSTRUCTION_LOADING = 'PROGRESS_CONSTRUCTION_LOADING';

export const GET_CONSTRUCTIONS_LOADING = 'GET_CONSTRUCTIONS_LOADING';
export const GET_CONSTRUCTIONS_SUCCESS = 'GET_CONSTRUCTIONS_SUCCESS';
export const GET_CONSTRUCTIONS_FAILURE = 'GET_CONSTRUCTIONS_FAILURE';

export const DESTROY_CONSTRUCTION_FAILURE = 'DESTROY_CONSTRUCTION_FAILURE';

// Construction Types

export const GET_CONSTRUCTION_TYPES_LOADING = 'GET_CONSTRUCTION_TYPES_LOADING';
export const GET_CONSTRUCTION_TYPES_SUCCESS = 'GET_CONSTRUCTION_TYPES_SUCCESS';
export const GET_CONSTRUCTION_TYPES_FAILURE = 'GET_CONSTRUCTION_TYPES_FAILURE';

// Cooking

export const GET_FOOD_RECIPES_LOADING = 'GET_FOOD_RECIPES_LOADING';
export const GET_FOOD_RECIPES_SUCCESS = 'GET_FOOD_RECIPES_SUCCESS';
export const GET_FOOD_RECIPES_FAILURE = 'GET_FOOD_RECIPES_FAILURE';

export const COOK_FOOD_LOADING = 'COOK_FOOD_LOADING';
export const COOK_FOOD_SUCCESS = 'COOK_FOOD_SUCCESS';
export const COOK_FOOD_FAILURE = 'COOK_FOOD_FAILURE';

export const GET_CHARACTER_FOOD_LOADING = 'GET_CHARACTER_FOOD_LOADING';
export const GET_CHARACTER_FOOD_SUCCESS = 'GET_CHARACTER_FOOD_SUCCESS';
export const GET_CHARACTER_FOOD_FAILURE = 'GET_CHARACTER_FOOD_FAILURE';

export const EAT_FOOD_LOADING = 'EAT_FOOD_LOADING';
export const EAT_FOOD_SUCCESS = 'EAT_FOOD_SUCCESS';
export const EAT_FOOD_FAILURE = 'EAT_FOOD_FAILURE';

export const DELETE_FOOD_LOADING = 'DELETE_FOOD_LOADING';
export const DELETE_FOOD_SUCCESS = 'DELETE_FOOD_SUCCESS';
export const DELETE_FOOD_FAILURE = 'DELETE_FOOD_FAILURE';

export const READ_TILE_FOOD_SUCCESS = 'READ_TILE_FOOD_SUCCESS';
export const READ_TILE_FOOD_FAILURE = 'READ_TILE_FOOD_FAILURE';
export const CLEAR_TILE_FOOD = 'CLEAR_TILE_FOOD';

// Moving

export const MOVE_ITEM_FAILURE = 'MOVE_ITEM_FAILURE';

// Dead Characters

export const GET_DEAD_CHARACTERS_LOADING = 'GET_DEAD_CHARACTERS_LOADING';
export const GET_DEAD_CHARACTERS_SUCCESS = 'GET_DEAD_CHARACTERS_SUCCESS';
export const GET_DEAD_CHARACTERS_FAILURE = 'GET_DEAD_CHARACTERS_FAILURE';

export const BURY_DEAD_CHARACTER_LOADING = 'BURY_DEAD_CHARACTER_LOADING';
export const BURY_DEAD_CHARACTER_SUCCESS = 'BURY_DEAD_CHARACTER_SUCCESS';
export const BURY_DEAD_CHARACTER_FAILURE = 'BURY_DEAD_CHARACTER_FAILURE';

// Characters

export const GET_CHARACTERS_LOADING = 'GET_CHARACTERS_LOADING';
export const GET_CHARACTERS_SUCCESS = 'GET_CHARACTERS_SUCCESS';
export const GET_CHARACTERS_FAILURE = 'GET_CHARACTERS_FAILURE';

export const FIGHT_CHARACTER_LOADING = 'FIGHT_CHARACTER_LOADING';
export const FIGHT_CHARACTER_SUCCESS = 'FIGHT_CHARACTER_SUCCESS';
export const FIGHT_CHARACTER_FAILURE = 'FIGHT_CHARACTER_FAILURE';

// Minerals

export const GET_MINERALS_SUCCESS = 'GET_MINERALS_SUCCESS';
export const GET_MINERALS_FAILURE = 'GET_MINERALS_FAILURE';
export const GET_MINERALS_LOADING = 'GET_MINERALS_LOADING';

export const GATHER_MINERAL_SUCCESS = 'GATHER_MINERAL_SUCCESS';
export const GATHER_MINERAL_FAILURE = 'GATHER_MINERAL_FAILURE';
export const GATHER_MINERAL_LOADING = 'GATHER_MINERAL_LOADING';

export const READ_TILE_MINERALS_SUCCESS = 'READ_TILE_MINERAL_SUCCESS';
export const READ_TILE_MINERALS_FAILURE = 'READ_TILE_MINERAL_FAILURE';
export const READ_TILE_MINERALS_LOADING = 'READ_TILE_MINERAL_LOADING';
export const CLEAR_TILE_MINERALS = 'CLEAR_TILE_MINERALS';

export const REMOVE_MINERAL = 'REMOVE_MINERAL';
export const CLEAR_ALL_MINERALS = 'CLEAR_ALL_MINERALS';
export const ADD_MINERAL = 'ADD_MINERAL';
export const UPDATE_MINERAL = 'UPDATE_MINERAL';


// Machines

export const GET_MACHINES_SUCCESS = 'GET_MACHINES_SUCCESS';
export const GET_MACHINES_FAILURE = 'GET_MACHINES_FAILURE';
export const GET_MACHINES_LOADING = 'GET_MACHINES_LOADING';

export const GET_MACHINE_RECIPES_LOADING = 'GET_MACHINE_RECIPES_LOADING';
export const GET_MACHINE_RECIPES_SUCCESS = 'GET_MACHINE_RECIPES_SUCCESS';
export const GET_MACHINE_RECIPES_FAILURE = 'GET_MACHINE_RECIPES_FAILURE';

export const CREATE_MACHINE_LOADING = 'CREATE_MACHINE_LOADING';
export const CREATE_MACHINE_SUCCESS = 'CREATE_MACHINE_SUCCESS';
export const CREATE_MACHINE_FAILURE = 'CREATE_MACHINE_FAILURE';

// Tools

export const GET_TOOL_RECIPES_LOADING = 'GET_TOOL_RECIPES_LOADING';
export const GET_TOOL_RECIPES_SUCCESS = 'GET_TOOL_RECIPES_SUCCESS';
export const GET_TOOL_RECIPES_FAILURE = 'GET_TOOL_RECIPES_FAILURE';

export const CREATE_TOOL_LOADING = 'CREATE_TOOL_LOADING';
export const CREATE_TOOL_SUCCESS = 'CREATE_TOOL_SUCCESS';
export const CREATE_TOOL_FAILURE = 'CREATE_TOOL_FAILURE';

export const GET_TOOLS_LOADING = 'GET_TOOLS_LOADING';
export const GET_TOOLS_SUCCESS = 'GET_TOOLS_SUCCESS';
export const GET_TOOLS_FAILURE = 'GET_TOOLS_FAILURE';

// Metals

export const GET_METAL_RECIPES_LOADING = 'GET_METAL_RECIPES_LOADING';
export const GET_METAL_RECIPES_SUCCESS = 'GET_METAL_RECIPES_SUCCESS';
export const GET_METAL_RECIPES_FAILURE = 'GET_METAL_RECIPES_FAILURE';

export const CREATE_METAL_LOADING = 'CREATE_METAL_LOADING';
export const CREATE_METAL_SUCCESS = 'CREATE_METAL_SUCCESS';
export const CREATE_METAL_FAILURE = 'CREATE_METAL_FAILURE';

export const GET_METALS_LOADING = 'GET_METALS_LOADING';
export const GET_METALS_SUCCESS = 'GET_METALS_SUCCESS';
export const GET_METALS_FAILURE = 'GET_METALS_FAILURE';

// Materials

export const GET_MATERIAL_RECIPES_LOADING = 'GET_MATERIAL_RECIPES_LOADING';
export const GET_MATERIAL_RECIPES_SUCCESS = 'GET_MATERIAL_RECIPES_SUCCESS';
export const GET_MATERIAL_RECIPES_FAILURE = 'GET_MATERIAL_RECIPES_FAILURE';

export const CREATE_MATERIAL_LOADING = 'CREATE_MATERIAL_LOADING';
export const CREATE_MATERIAL_SUCCESS = 'CREATE_MATERIAL_SUCCESS';
export const CREATE_MATERIAL_FAILURE = 'CREATE_MATERIAL_FAILURE';

// Messages

export const GET_MESSAGES_LOADING = 'GET_MESSAGES_LOADING';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAILURE = 'GET_MESSAGES_FAILURE';

export const GET_MORE_MESSAGES_LOADING = 'GET_MORE_MESSAGES_LOADING';
export const GET_MORE_MESSAGES_SUCCESS = 'GET_MORE_MESSAGES_SUCCESS';
export const GET_MORE_MESSAGES_FAILURE = 'GET_MORE_MESSAGES_FAILURE';

export const CREATE_MESSAGE_LOADING = 'CREATE_MESSAGE_LOADING';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_FAILURE = 'CREATE_MESSAGE_FAILURE';

export const ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE';

export const POINT_AT_SUCCESS = 'POINT_AT_SUCCESS';

export const ADD_MESSAGE_OPTIONS = 'ADD_MESSAGE_OPTIONS';
export const CLEAR_MESSAGE_OPTIONS = 'CLEAR_MESSAGE_OPTIONS';

// Locks

export const GET_LOCK_RECIPES_LOADING = 'GET_LOCK_RECIPES_LOADING';
export const GET_LOCK_RECIPES_SUCCESS = 'GET_LOCK_RECIPES_SUCCESS';
export const GET_LOCK_RECIPES_FAILURE = 'GET_LOCK_RECIPES_FAILURE';

export const CREATE_LOCK_LOADING = 'CREATE_LOCK_LOADING';
export const CREATE_LOCK_SUCCESS = 'CREATE_LOCK_SUCCESS';
export const CREATE_LOCK_FAILURE = 'CREATE_LOCK_FAILURE';

export const GET_LOCKS_LOADING = 'GET_LOCKS_LOADING';
export const GET_LOCKS_SUCCESS = 'GET_LOCKS_SUCCESS';
export const GET_LOCKS_FAILURE = 'GET_LOCKS_FAILURE';

// Keys

export const GET_KEY_RECIPES_LOADING = 'GET_KEY_RECIPES_LOADING';
export const GET_KEY_RECIPES_SUCCESS = 'GET_KEY_RECIPES_SUCCESS';
export const GET_KEY_RECIPES_FAILURE = 'GET_KEY_RECIPES_FAILURE';

export const CREATE_KEY_LOADING = 'CREATE_KEY_LOADING';
export const CREATE_KEY_SUCCESS = 'CREATE_KEY_SUCCESS';
export const CREATE_KEY_FAILURE = 'CREATE_KEY_FAILURE';

export const GET_KEYS_LOADING = 'GET_KEYS_LOADING';
export const GET_KEYS_SUCCESS = 'GET_KEYS_SUCCESS';
export const GET_KEYS_FAILURE = 'GET_KEYS_FAILURE';


// Writing Surface

export const GET_WRITING_SURFACE_RECIPES_LOADING = 'GET_WRITING_SURFACE_RECIPES_LOADING';
export const GET_WRITING_SURFACE_RECIPES_SUCCESS = 'GET_WRITING_SURFACE_RECIPES_SUCCESS';
export const GET_WRITING_SURFACE_RECIPES_FAILURE = 'GET_WRITING_SURFACE_RECIPES_FAILURE';

export const CREATE_WRITING_SURFACE_LOADING = 'CREATE_WRITING_SURFACE_LOADING';
export const CREATE_WRITING_SURFACE_SUCCESS = 'CREATE_WRITING_SURFACE_SUCCESS';
export const CREATE_WRITING_SURFACE_FAILURE = 'CREATE_WRITING_SURFACE_FAILURE';

export const GET_WRITING_SURFACES_LOADING = 'GET_WRITING_SURFACES_LOADING';
export const GET_WRITING_SURFACES_SUCCESS = 'GET_WRITING_SURFACES_SUCCESS';
export const GET_WRITING_SURFACES_FAILURE = 'GET_WRITING_SURFACES_FAILURE';

// Writing Implement

export const GET_WRITING_IMPLEMENT_RECIPES_LOADING = 'GET_WRITING_IMPLEMENT_RECIPES_LOADING';
export const GET_WRITING_IMPLEMENT_RECIPES_SUCCESS = 'GET_WRITING_IMPLEMENT_RECIPES_SUCCESS';
export const GET_WRITING_IMPLEMENT_RECIPES_FAILURE = 'GET_WRITING_IMPLEMENT_RECIPES_FAILURE';

export const CREATE_WRITING_IMPLEMENT_LOADING = 'CREATE_WRITING_IMPLEMENT_LOADING';
export const CREATE_WRITING_IMPLEMENT_SUCCESS = 'CREATE_WRITING_IMPLEMENT_SUCCESS';
export const CREATE_WRITING_IMPLEMENT_FAILURE = 'CREATE_WRITING_IMPLEMENT_FAILURE';

export const GET_WRITING_IMPLEMENTS_LOADING = 'GET_WRITING_IMPLEMENTS_LOADING';
export const GET_WRITING_IMPLEMENTS_SUCCESS = 'GET_WRITING_IMPLEMENTS_SUCCESS';
export const GET_WRITING_IMPLEMENTS_FAILURE = 'GET_WRITING_IMPLEMENTS_FAILURE';

// Keyboard shortcuts
export const DISABLE_KEYBOARD_MOVEMENT = 'DISABLE_KEYBOARD_MOVEMENT';
export const ENABLE_KEYBOARD_MOVEMENT = 'ENABLE_KEYBOARD_MOVEMENT';

export const OPEN_INVENTORY = 'OPEN_INVENTORY';
export const CLOSE_INVENTORY = 'CLOSE_INVENTORY';

export const OPEN_CRAFTING = 'OPEN_CRAFTING';
export const CLOSE_CRAFTING = 'CLOSE_CRAFTING';

export const OPEN_ACTIONS = 'OPEN_ACTIONS';
export const CLOSE_ACTIONS = 'CLOSE_ACTIONS';

export const START_LOOKING = 'START_LOOKING';
export const STOP_LOOKING = 'STOP_LOOKING';

export const START_AIMING = 'START_AIMING';
export const STOP_AIMING = 'STOP_AIMING';

export const START_MOVING = 'START_MOVING';
export const STOP_MOVING = 'STOP_MOVING';

export const START_AREA_SELECT = 'START_AREA_SELECT';
export const STOP_AREA_SELECT = 'STOP_AREA_SELECT';

export const CHOOSE_SEED_TO_PLANT = 'CHOOSE_SEED_TO_PLANT';
export const CHOOSE_FOOD_TO_EAT = 'CHOOSE_FOOD_TO_EAT';
export const SHOW_NO_CHARACTERS_NEARBY = 'SHOW_NO_CHARACTERS_NEARBY';
export const HIDE_NO_CHARACTERS_NEARBY = 'HIDE_NO_CHARACTERS_NEARBY';
export const SHOW_FIGHT_CONFIRMATION = 'SHOW_FIGHT_CONFIRMATION';
export const CHOOSE_CHARACTER_TO_FIGHT = 'CHOOSE_CHARACTER_TO_FIGHT';
export const NEW_ORGANISATION = 'NEW_ORGANISATION';
export const SHOW_ORGANISATION = 'SHOW_ORGANISATION';
export const SHOW_ORGANISATION_LIST = 'SHOW_ORGANISATION_LIST';
export const SHOW_ZONE_LIST = 'SHOW_ZONE_LIST';
export const SHOW_ZONE = 'SHOW_ZONE';
export const NEW_ZONE = 'NEW_ZONE'

export const SHOW_HELP = 'SHOW_HELP';
export const HIDE_HELP = 'HIDE_HELP';
export const HIDE_ALL_MENUS = 'HIDE_ALL_MENUS';
export const SHOW_QUANTITY_INPUT = 'SHOW_QUANTITY_INPUT';
export const HIDE_QUANTITY_INPUT = 'HIDE_QUANTITY_INPUT';
export const SHOW_DIRECTION_INPUT = 'SHOW_DIRECTION_INPUT';
export const HIDE_DIRECTION_INPUT = 'HIDE_DIRECTION_INPUT';
export const HIDE_CRAFTING_ERROR = 'HIDE_CRAFTING_ERROR';
export const HIDE_MOVING_ERROR = 'HIDE_MOVING_ERROR';
export const CHOOSE_CHARACTER_TO_GIVE_TO = 'CHOOSE_CHARACTER_TO_GIVE_TO';
export const HIDE_CHARACTER_LIST = 'HIDE_CHARACTER_LIST';
export const UNKNOWN_ERROR = 'UNKNOWN_ERROR';
export const HIDE_UNKNOWN_ERROR = 'HIDE_UNKNOWN_ERROR';
export const SELECT_ERROR_MESSAGE = 'SELECT_ERROR_MESSAGE';
export const SHOW_MESSAGES = 'SHOW_MESSAGES';
export const FOCUS_MESSAGES = 'FOCUS_MESSAGES';
export const NEW_TOWN_MESSAGE = 'NEW_TOWN_MESSAGE';
export const SHOW_CHARACTER_ACTIONS = 'SHOW_CHARACTER_ACTIONS';
export const SHOW_CHARACTER_PROFILE = 'SHOW_CHARACTER_PROFILE';
export const SHOW_EQUIPMENT = 'SHOW_EQUIPMENT';
export const SHOW_SHEET = 'SHOW_SHEET';
export const SHOW_WORKSHOP = 'SHOW_WORKSHOP';
export const SHOW_CONVERSATION_TREE_WIZARD = 'SHOW_CONVERSATION_TREE_WIZARD';
export const SHOW_EVENTS = 'SHOW_EVENTS';
export const SHOW_DIARY = 'SHOW_DIARY';
export const SHOW_WRITING_SURFACE_MENU = 'SHOW_WRITING_SURFACE_MENU';
export const SHOW_WRITING_SURFACE = 'SHOW_WRITING_SURFACE';
export const HIDE_WRITING_SURFACE = 'HIDE_WRITING_SURFACE';
export const SHOW_DRAWING_SURFACE = 'SHOW_DRAWING_SURFACE';
export const HIDE_DRAWING_SURFACE = 'HIDE_DRAWING_SURFACE';
export const SHOW_LEVEL_UP_MESSAGE = 'SHOW_LEVEL_UP_MESSAGE';
export const SHOW_NEAR_DEATH_SCREEN = 'SHOW_NEAR_DEATH_SCREEN';
export const SHOW_HUNGER_WARNING = 'SHOW_HUNGER_WARNING';
export const SHOW_GROUP_TERMINAL = 'SHOW_GROUP_TERMINAL';
export const SHOW_GUARD_HOUSE = 'SHOW_GUARD_HOUSE';
export const SHOW_TABLE = 'SHOW_TABLE';
export const SHOW_ACTION_SELECT_MENU = 'SHOW_ACTION_SELECT_MENU';
export const HIDE_ACTION_SELECT_MENU = 'HIDE_ACTION_SELECT_MENU'
export const SHOW_SELECT_ITEMS = 'SHOW_SELECT_ITEMS';
export const HIDE_SELECT_ITEMS = 'HIDE_SELECT_ITEMS';
export const SHOW_SCHEDULER = 'SHOW_SCHEDULER';

export const SAVE_LAST_CRAFT = 'SAVE_LAST_CRAFT';
export const SHOW_SUCCESS = 'SHOW_SUCCESS';
export const HIDE_SUCCESS = 'HIDE_SUCCESS';
// Furniture

export const GET_FURNITURE_SUCCESS = 'GET_FURNITURE_SUCCESS';
export const GET_FURNITURE_FAILURE = 'GET_FURNITURE_FAILURE';
export const GET_FURNITURE_LOADING = 'GET_FURNITURE_LOADING';

export const GET_FURNITURE_RECIPES_LOADING = 'GET_FURNITURE_RECIPES_LOADING';
export const GET_FURNITURE_RECIPES_SUCCESS = 'GET_FURNITURE_RECIPES_SUCCESS';
export const GET_FURNITURE_RECIPES_FAILURE = 'GET_FURNITURE_RECIPES_FAILURE';

export const CREATE_FURNITURE_LOADING = 'CREATE_FURNITURE_LOADING';
export const CREATE_FURNITURE_SUCCESS = 'CREATE_FURNITURE_SUCCESS';
export const CREATE_FURNITURE_FAILURE = 'CREATE_FURNITURE_FAILURE';

// Graffiti
export const CREATE_GRAFFITI_LOADING = 'CREATE_GRAFFITI_LOADING';
export const CREATE_GRAFFITI_SUCCESS = 'CREATE_GRAFFITI_SUCCESS';
export const CREATE_GRAFFITI_FAILURE = 'CREATE_GRAFFITI_FAILURE';

export const CREATE_DRAWING_LOADING = 'CREATE_DRAWING_LOADING';
export const CREATE_DRAWING_SUCCESS = 'CREATE_DRAWING_SUCCESS';
export const CREATE_DRAWING_FAILURE = 'CREATE_DRAWING_FAILURE';

// Weapons

export const GET_WEAPON_RECIPES_LOADING = 'GET_WEAPON_RECIPES_LOADING';
export const GET_WEAPON_RECIPES_SUCCESS = 'GET_WEAPON_RECIPES_SUCCESS';
export const GET_WEAPON_RECIPES_FAILURE = 'GET_WEAPON_RECIPES_FAILURE';

export const CREATE_WEAPON_LOADING = 'CREATE_WEAPON_LOADING';
export const CREATE_WEAPON_SUCCESS = 'CREATE_WEAPON_SUCCESS';
export const CREATE_WEAPON_FAILURE = 'CREATE_WEAPON_FAILURE';

export const GET_WEAPONS_LOADING = 'GET_WEAPONS_LOADING';
export const GET_WEAPONS_SUCCESS = 'GET_WEAPONS_SUCCESS';
export const GET_WEAPONS_FAILURE = 'GET_WEAPONS_FAILURE';

// Workshop Levels

export const GET_WORKSHOP_LEVEL_RECIPES_LOADING = 'GET_WORKSHOP_LEVEL_RECIPES_LOADING';
export const GET_WORKSHOP_LEVEL_RECIPES_SUCCESS = 'GET_WORKSHOP_LEVEL_RECIPES_SUCCESS';
export const GET_WORKSHOP_LEVEL_RECIPES_FAILURE = 'GET_WORKSHOP_LEVEL_RECIPES_FAILURE';

// Armour	

export const GET_ARMOUR_RECIPES_LOADING = 'GET_ARMOUR_RECIPES_LOADING';
export const GET_ARMOUR_RECIPES_SUCCESS = 'GET_ARMOUR_RECIPES_SUCCESS';
export const GET_ARMOUR_RECIPES_FAILURE = 'GET_ARMOUR_RECIPES_FAILURE';

export const CREATE_ARMOUR_LOADING = 'CREATE_ARMOUR_LOADING';
export const CREATE_ARMOUR_SUCCESS = 'CREATE_ARMOUR_SUCCESS';
export const CREATE_ARMOUR_FAILURE = 'CREATE_ARMOUR_FAILURE';

export const GET_ARMOUR_LOADING = 'GET_ARMOUR_LOADING';
export const GET_ARMOUR_SUCCESS = 'GET_ARMOUR_SUCCESS';
export const GET_ARMOUR_FAILURE = 'GET_ARMOUR_FAILURE';

// Clothing

export const GET_CLOTHING_RECIPES_LOADING = 'GET_CLOTHING_RECIPES_LOADING';
export const GET_CLOTHING_RECIPES_SUCCESS = 'GET_CLOTHING_RECIPES_SUCCESS';
export const GET_CLOTHING_RECIPES_FAILURE = 'GET_CLOTHING_RECIPES_FAILURE';

export const CREATE_CLOTHING_LOADING = 'CREATE_CLOTHING_LOADING';
export const CREATE_CLOTHING_SUCCESS = 'CREATE_CLOTHING_SUCCESS';
export const CREATE_CLOTHING_FAILURE = 'CREATE_CLOTHING_FAILURE';

export const GET_CLOTHING_LOADING = 'GET_CLOTHING_LOADING';
export const GET_CLOTHING_SUCCESS = 'GET_CLOTHING_SUCCESS';
export const GET_CLOTHING_FAILURE = 'GET_CLOTHING_FAILURE';

// Jewellery

export const GET_JEWELLERY_RECIPES_LOADING = 'GET_JEWELLERY_RECIPES_LOADING';
export const GET_JEWELLERY_RECIPES_SUCCESS = 'GET_JEWELLERY_RECIPES_SUCCESS';
export const GET_JEWELLERY_RECIPES_FAILURE = 'GET_JEWELLERY_RECIPES_FAILURE';

export const CREATE_JEWELLERY_LOADING = 'CREATE_JEWELLERY_LOADING';
export const CREATE_JEWELLERY_SUCCESS = 'CREATE_JEWELLERY_SUCCESS';
export const CREATE_JEWELLERY_FAILURE = 'CREATE_JEWELLERY_FAILURE';

export const GET_JEWELLERY_LOADING = 'GET_JEWELLERY_LOADING';
export const GET_JEWELLERY_SUCCESS = 'GET_JEWELLERY_SUCCESS';
export const GET_JEWELLERY_FAILURE = 'GET_JEWELLERY_FAILURE';

// Embark Character

export const NEW_EMBARK_CHARACTER_LOADING = 'NEW_EMBARK_CHARACTER_LOADING';
export const NEW_EMBARK_CHARACTER_SUCCESS = 'NEW_EMBARK_CHARACTER_SUCCESS';
export const NEW_EMBARK_CHARACTER_FAILURE = 'NEW_EMBARK_CHARACTER_FAILURE';

export const GET_EMBARK_CHARACTER_LOADING = 'GET_EMBARK_CHARACTER_LOADING';
export const GET_EMBARK_CHARACTER_SUCCESS = 'GET_EMBARK_CHARACTER_SUCCESS';
export const GET_EMBARK_CHARACTER_FAILURE = 'GET_EMBARK_CHARACTER_FAILURE';

// Embark Characters

export const GET_EMBARK_CHARACTERS_LOADING = 'GET_EMBARK_CHARACTERS_LOADING';
export const GET_EMBARK_CHARACTERS_SUCCESS = 'GET_EMBARK_CHARACTERS_SUCCESS';
export const GET_EMBARK_CHARACTERS_FROM_GROUP_SUCCESS = 'GET_EMBARK_CHARACTERS_FROM_GROUP_SUCCESS'
export const GET_EMBARK_CHARACTERS_FAILURE = 'GET_EMBARK_CHARACTERS_FAILURE';

export const UPDATE_EMBARK_POSITION_SUCCESS = 'UPDATE_EMBARK_POSITION_SUCCESS';
export const UPDATE_EMBARK_POSITION_FAILURE = 'UPDATE_EMBARK_POSITION_FAILURE';
export const BEGIN_UPDATE_EMBARK_POSITION = 'BEGIN_UPDATE_EMBARK_POSITION';

export const SOLO_EMBARK_LOADING = 'SOLO_EMBARK_LOADING';
export const SOLO_EMBARK_SUCCESS = 'SOLO_EMBARK_SUCCESS';

export const GROUP_EMBARK_SUCCESS = 'GROUP_EMBARK_SUCCESS';

// Embark Groups

export const NEW_EMBARK_GROUPS_LOADING = 'NEW_EMBARK_GROUPS_LOADING';
export const NEW_EMBARK_GROUPS_SUCCESS = 'NEW_EMBARK_GROUPS_SUCCESS';
export const NEW_EMBARK_GROUPS_FAILURE = 'NEW_EMBARK_GROUPS_FAILURE';

export const GET_EMBARK_GROUPS_LOADING = 'GET_EMBARK_GROUPS_LOADING';
export const GET_EMBARK_GROUPS_SUCCESS = 'GET_EMBARK_GROUPS_SUCCESS';
export const GET_EMBARK_GROUPS_FAILURE = 'GET_EMBARK_GROUPS_FAILURE';

export const JOIN_EMBARK_GROUP_SUCCESS = 'JOIN_EMBARK_GROUP_SUCCESS';

// Embark Groups

export const NEW_GROUPS_LOADING = 'NEW_GROUPS_LOADING';
export const NEW_GROUPS_SUCCESS = 'NEW_GROUPS_SUCCESS';
export const NEW_GROUPS_FAILURE = 'NEW_GROUPS_FAILURE';

export const GET_GROUPS_LOADING = 'GET_GROUPS_LOADING';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';

// Embark Forms

export const UPDATE_EMBARK_CHARACTER = 'UPDATE_EMBARK_CHARACTER';

// Fishing

export const GET_FISH_SUCCESS = 'GET_FISH_SUCCESS';
export const GET_FISH_FAILURE = 'GET_FISH_FAILURE'
export const HARVEST_FISH_SUCCESS = 'HARVEST_FISH_SUCCESS';
export const HARVEST_FISH_FAILURE = 'HARVEST_FISH_FAILURE';

// Hunting

export const GET_ANIMALS_SUCCESS = 'GET_ANIMALS_SUCCESS';
export const GET_ANIMALS_FAILURE = 'GET_ANIMALS_FAILURE'
export const HARVEST_ANIMAL_SUCCESS = 'HARVEST_ANIMAL_SUCCESS';
export const HARVEST_ANIMAL_FAILURE = 'HARVEST_ANIMAL_FAILURE';

// Action Types

export const GET_SKILL_TYPES_SUCCESS = 'GET_SKILL_TYPES_SUCCESS';
export const GET_SKILL_TYPES_FAILURE = 'GET_SKILL_TYPES_FAILURE';

// Mineral Types

export const GET_MINERAL_TYPES_SUCCESS = 'GET_MINERAL_TYPES_SUCCESS';
export const GET_MINERAL_TYPES_FAILURE = 'GET_MINERAL_TYPES_FAILURE';

export const GET_ARMOUR_TYPES_LOADING = 'GET_ARMOUR_TYPES_LOADING';
export const GET_ARMOUR_TYPES_SUCCESS = 'GET_ARMOUR_TYPES_SUCCESS';
export const GET_CLOTHING_TYPES_LOADING = 'GET_CLOTHING_TYPES_LOADING';
export const GET_CLOTHING_TYPES_SUCCESS = 'GET_CLOTHING_TYPES_SUCCESS';
export const GET_JEWELLERY_TYPES_LOADING = 'GET_JEWELLERY_TYPES_LOADING';
export const GET_JEWELLERY_TYPES_SUCCESS = 'GET_JEWELLERY_TYPES_SUCCESS';
export const GET_FOOD_TYPES_LOADING = 'GET_FOOD_TYPES_LOADING';
export const GET_FOOD_TYPES_SUCCESS = 'GET_FOOD_TYPES_SUCCESS';
export const GET_LOCK_TYPES_LOADING = 'GET_LOCK_TYPES_LOADING';
export const GET_LOCK_TYPES_SUCCESS = 'GET_LOCK_TYPES_SUCCESS';
export const GET_WRITING_SURFACE_TYPES_LOADING = 'GET_WRITING_SURFACE_TYPES_LOADING';
export const GET_WRITING_SURFACE_TYPES_SUCCESS = 'GET_WRITING_SURFACE_TYPES_SUCCESS';
export const GET_MACHINE_TYPES_LOADING = 'GET_MACHINE_TYPES_LOADING';
export const GET_MACHINE_TYPES_SUCCESS = 'GET_MACHINE_TYPES_SUCCESS';
export const GET_METAL_TYPES_LOADING = 'GET_METAL_TYPES_LOADING';
export const GET_METAL_TYPES_SUCCESS = 'GET_METAL_TYPES_SUCCESS';
export const GET_KEY_TYPES_LOADING = 'GET_KEY_TYPES_LOADING';
export const GET_KEY_TYPES_SUCCESS = 'GET_KEY_TYPES_SUCCESS';
export const GET_TOOL_TYPES_LOADING = 'GET_TOOL_TYPES_LOADING';
export const GET_TOOL_TYPES_SUCCESS = 'GET_TOOL_TYPES_SUCCESS';
export const GET_FURNITURE_TYPES_LOADING = 'GET_FURNITURE_TYPES_LOADING';
export const GET_FURNITURE_TYPES_SUCCESS = 'GET_FURNITURE_TYPES_SUCCESS';
export const GET_WEAPON_TYPES_LOADING = 'GET_WEAPON_TYPES_LOADING';
export const GET_WEAPON_TYPES_SUCCESS = 'GET_WEAPON_TYPES_SUCCESS';
export const GET_WRITING_IMPLEMENT_TYPES_LOADING = 'GET_WRITING_IMPLEMENT_TYPES_LOADING';
export const GET_WRITING_IMPLEMENT_TYPES_SUCCESS = 'GET_WRITING_IMPLEMENT_TYPES_SUCCESS';
export const GET_PLANT_PRODUCTS_LOADING = 'GET_PLANT_PRODUCTS_LOADING';
export const GET_PLANT_PRODUCTS_SUCCESS = 'GET_PLANT_PRODUCTS_SUCCESS';
export const GET_FISH_TYPES_LOADING = 'GET_FISH_TYPES_LOADING';
export const GET_FISH_TYPES_SUCCESS = 'GET_FISH_TYPES_SUCCESS';
export const GET_ANIMAL_TYPES_LOADING = 'GET_ANIMAL_TYPES_LOADING';
export const GET_ANIMAL_TYPES_SUCCESS = 'GET_ANIMAL_TYPES_SUCCESS';

export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_COMPLETED_ORDERS_SUCCESS = 'GET_COMPLETED_ORDERS_SUCCESS';
export const ADD_COMPLETED_ORDER = 'ADD_COMPLETED_ORDER';
export const UPDATE_COMPLETED_ORDER = 'UPDATE_COMPLETED_ORDER';
export const REMOVE_COMPLETED_ORDER = 'REMOVE_COMPLETED_ORDER';
export const ADD_ORDER = 'ADD_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const REMOVE_ORDER = 'REMOVE_ORDER';

export const ADD_PLANT = 'ADD_PLANT';
export const UPDATE_PLANT = 'UPDATE_PLANT';
export const REMOVE_PLANT = 'REMOVE_PLANT';
export const ADD_TOOL = 'ADD_TOOL';
export const UPDATE_TOOL = 'UPDATE_TOOL';
export const REMOVE_TOOL = 'REMOVE_TOOL';
export const ADD_METAL = 'ADD_METAL';
export const UPDATE_METAL = 'UPDATE_METAL';
export const REMOVE_METAL = 'REMOVE_METAL';
export const ADD_KEY = 'ADD_KEY';
export const UPDATE_KEY = 'UPDATE_KEY';
export const REMOVE_KEY = 'REMOVE_KEY';
export const ADD_MACHINE = 'ADD_MACHINE';
export const UPDATE_MACHINE = 'UPDATE_MACHINE';
export const REMOVE_MACHINE = 'REMOVE_MACHINE';
export const ADD_FURNITURE = 'ADD_FURNITURE';
export const UPDATE_FURNITURE = 'UPDATE_FURNITURE';
export const REMOVE_FURNITURE = 'REMOVE_FURNITURE';
export const ADD_WRITING_SURFACE = 'ADD_WRITING_SURFACE';
export const UPDATE_WRITING_SURFACE = 'UPDATE_WRITING_SURFACE';
export const REMOVE_WRITING_SURFACE = 'REMOVE_WRITING_SURFACE';
export const ADD_WRITING_IMPLEMENT = 'ADD_WRITING_IMPLEMENT';
export const UPDATE_WRITING_IMPLEMENT = 'UPDATE_WRITING_IMPLEMENT';
export const REMOVE_WRITING_IMPLEMENT = 'REMOVE_WRITING_IMPLEMENT';
export const ADD_WEAPON = 'ADD_WEAPON';
export const UPDATE_WEAPON = 'UPDATE_WEAPON';
export const REMOVE_WEAPON = 'REMOVE_WEAPON';
export const ADD_ARMOUR = 'ADD_ARMOUR';
export const UPDATE_ARMOUR = 'UPDATE_ARMOUR';
export const REMOVE_ARMOUR = 'REMOVE_ARMOUR';
export const ADD_CLOTHING = 'ADD_CLOTHING';
export const UPDATE_CLOTHING = 'UPDATE_CLOTHING';
export const REMOVE_CLOTHING = 'REMOVE_CLOTHING';
export const ADD_JEWELLERY = 'ADD_JEWELLERY';
export const UPDATE_JEWELLERY = 'UPDATE_JEWELLERY';
export const REMOVE_JEWELLERY = 'REMOVE_JEWELLERY';
export const ADD_FOOD = 'ADD_FOOD';
export const UPDATE_FOOD = 'UPDATE_FOOD';
export const REMOVE_FOOD = 'REMOVE_FOOD';
export const ADD_LOCK = 'ADD_LOCK';
export const UPDATE_LOCK = 'UPDATE_LOCK';
export const REMOVE_LOCK = 'REMOVE_LOCK';
export const ADD_CONSTRUCTION = 'ADD_CONSTRUCTION';
export const UPDATE_CONSTRUCTION = 'UPDATE_CONSTRUCTION';
export const REMOVE_CONSTRUCTION = 'REMOVE_CONSTRUCTION';
export const ADD_ANIMAL = 'ADD_ANIMAL';
export const UPDATE_ANIMAL = 'UPDATE_ANIMAL';
export const REMOVE_ANIMAL = 'REMOVE_ANIMAL';
export const BEGIN_UPDATE_ANIMAL = 'BEGIN_UPDATE_ANIMAL';
export const UPDATE_ANIMAL_FAILED = 'UPDATE_ANIMAL_FAILED';
export const ADD_FISH = 'ADD_FISH';
export const UPDATE_FISH = 'UPDATE_FISH';
export const REMOVE_FISH = 'REMOVE_FISH';
export const GET_PROMPTS_SUCCESS = 'GET_PROMPTS_SUCCESS';
export const ADD_PROMPT = 'ADD_PROMPT';
export const UPDATE_PROMPT = 'UPDATE_PROMPT';
export const REMOVE_PROMPT = 'REMOVE_PROMPT';

export const CREATE_DIARY_ENTRY_LOADING = 'CREATE_DIARY_ENTRY_LOADING';
export const CREATE_DIARY_ENTRY_SUCCESS = 'CREATE_DIARY_ENTRY_SUCCESS';
export const CREATE_DIARY_ENTRY_FAILURE = 'CREATE_DIARY_ENTRY_FAILURE';
export const GET_DIARY_ENTRIES_LOADING = 'GET_DIARY_ENTRIES_LOADING';
export const GET_DIARY_ENTRIES_SUCCESS = 'GET_DIARY_ENTRIES_SUCCESS';
export const GET_DIARY_ENTRIES_FAILURE = 'GET_DIARY_ENTRIES_FAILURE';
export const ADD_DIARY_ENTRY = 'ADD_DIARY_ENTRY';
export const UPDATE_DIARY_ENTRY = 'UPDATE_DIARY_ENTRY';
export const REMOVE_DIARY_ENTRY = 'REMOVE_DIARY_ENTRY';

export const GET_CONSTRUCTION_RECIPES_LOADING = 'GET_CONSTRUCTION_RECIPES_LOADING';
export const GET_CONSTRUCTION_RECIPES_SUCCESS = 'GET_CONSTRUCTION_RECIPES_SUCCESS';
export const GET_CONSTRUCTION_RECIPES_FAILURE = 'GET_CONSTRUCTION_RECIPES_FAILURE';

export const INITIALISING_APP_STARTED = 'INITIALISING_APP_STARTED';
export const INITIALISING_APP_FINISHED = 'INITIALISING_APP_FINISHED';
export const INITIALISING_PANEL_STARTED = 'INITIALISING_PANEL_STARTED';
export const INITIALISING_PANEL_FINISHED = 'INITIALISING_PANEL_FINISHED';

export const ADD_CHARACTER_PANEL_SUCCESS = 'ADD_CHARACTER_PANEL_SUCCESS';
export const GET_CHARACTER_PANELS_SUCCESS = 'GET_CHARACTER_PANELS_SUCCESS';
export const CREATE_CHARACTER_PANEL_SUCCESS = 'CREATE_CHARACTER_PANEL_SUCCESS';
export const REMOVE_CHARACTER_PANEL_SUCCESS = 'REMOVE_CHARACTER_PANEL_SUCCESS';
export const UPDATE_CHARACTER_PANEL_SUCCESS = 'UPDATE_CHARACTER_PANEL_SUCCESS';

// Boats

export const GET_BOATS_SUCCESS = 'GET_BOATS_SUCCESS';
export const GET_BOATS_FAILURE = 'GET_BOATS_FAILURE';
export const GET_BOATS_LOADING = 'GET_BOATS_LOADING';

export const GET_BOAT_RECIPES_LOADING = 'GET_BOAT_RECIPES_LOADING';
export const GET_BOAT_RECIPES_SUCCESS = 'GET_BOAT_RECIPES_SUCCESS';
export const GET_BOAT_RECIPES_FAILURE = 'GET_BOAT_RECIPES_FAILURE';

export const CREATE_BOAT_LOADING = 'CREATE_BOAT_LOADING';
export const CREATE_BOAT_SUCCESS = 'CREATE_BOAT_SUCCESS';
export const CREATE_BOAT_FAILURE = 'CREATE_BOAT_FAILURE';

export const ADD_BOAT = 'ADD_BOAT';
export const UPDATE_BOAT = 'UPDATE_BOAT';
export const REMOVE_BOAT = 'REMOVE_BOAT';
export const BEGIN_UPDATE_BOAT = 'BEGIN_UPDATE_BOAT';
export const UPDATE_BOAT_FAILED = 'UPDATE_BOAT_FAILED';

export const GET_BOAT_TYPES_LOADING = 'GET_BOAT_TYPES_LOADING';
export const GET_BOAT_TYPES_SUCCESS = 'GET_BOAT_TYPES_SUCCESS';

// Wagons


export const GET_WAGONS_SUCCESS = 'GET_WAGONS_SUCCESS';
export const GET_WAGONS_FAILURE = 'GET_WAGONS_FAILURE';
export const GET_WAGONS_LOADING = 'GET_WAGONS_LOADING';

export const GET_WAGON_RECIPES_LOADING = 'GET_WAGON_RECIPES_LOADING';
export const GET_WAGON_RECIPES_SUCCESS = 'GET_WAGON_RECIPES_SUCCESS';
export const GET_WAGON_RECIPES_FAILURE = 'GET_WAGON_RECIPES_FAILURE';

export const CREATE_WAGON_LOADING = 'CREATE_WAGON_LOADING';
export const CREATE_WAGON_SUCCESS = 'CREATE_WAGON_SUCCESS';
export const CREATE_WAGON_FAILURE = 'CREATE_WAGON_FAILURE';

export const ADD_WAGON = 'ADD_WAGON';
export const UPDATE_WAGON = 'UPDATE_WAGON';
export const REMOVE_WAGON = 'REMOVE_WAGON';

export const GET_WAGON_TYPES_LOADING = 'GET_WAGON_TYPES_LOADING';
export const GET_WAGON_TYPES_SUCCESS = 'GET_WAGON_TYPES_SUCCESS';

export const BEGIN_UPDATE_WAGON = 'BEGIN_UPDATE_WAGON';
export const UPDATE_WAGON_FAILED = 'UPDATE_WAGON_FAILED';

// Tents

export const GET_TENTS_SUCCESS = 'GET_TENTS_SUCCESS';
export const GET_TENTS_FAILURE = 'GET_TENTS_FAILURE';
export const GET_TENTS_LOADING = 'GET_TENTS_LOADING';

export const GET_TENT_RECIPES_LOADING = 'GET_TENT_RECIPES_LOADING';
export const GET_TENT_RECIPES_SUCCESS = 'GET_TENT_RECIPES_SUCCESS';
export const GET_TENT_RECIPES_FAILURE = 'GET_TENT_RECIPES_FAILURE';

export const CREATE_TENT_LOADING = 'CREATE_TENT_LOADING';
export const CREATE_TENT_SUCCESS = 'CREATE_TENT_SUCCESS';
export const CREATE_TENT_FAILURE = 'CREATE_TENT_FAILURE';

export const ADD_TENT = 'ADD_TENT';
export const UPDATE_TENT = 'UPDATE_TENT';
export const REMOVE_TENT = 'REMOVE_TENT';

export const GET_TENT_TYPES_LOADING = 'GET_TENT_TYPES_LOADING';
export const GET_TENT_TYPES_SUCCESS = 'GET_TENT_TYPES_SUCCESS';

// Workshops

export const GET_WORKSHOPS_SUCCESS = 'GET_WORKSHOPS_SUCCESS';
export const GET_WORKSHOPS_FAILURE = 'GET_WORKSHOPS_FAILURE';
export const GET_WORKSHOPS_LOADING = 'GET_WORKSHOPS_LOADING';

export const GET_WORKSHOP_RECIPES_LOADING = 'GET_WORKSHOP_RECIPES_LOADING';
export const GET_WORKSHOP_RECIPES_SUCCESS = 'GET_WORKSHOP_RECIPES_SUCCESS';
export const GET_WORKSHOP_RECIPES_FAILURE = 'GET_WORKSHOP_RECIPES_FAILURE';

export const CREATE_WORKSHOP_LOADING = 'CREATE_WORKSHOP_LOADING';
export const CREATE_WORKSHOP_SUCCESS = 'CREATE_WORKSHOP_SUCCESS';
export const CREATE_WORKSHOP_FAILURE = 'CREATE_WORKSHOP_FAILURE';

export const ADD_WORKSHOP = 'ADD_WORKSHOP';
export const UPDATE_WORKSHOP = 'UPDATE_WORKSHOP';
export const REMOVE_WORKSHOP = 'REMOVE_WORKSHOP';

export const GET_WORKSHOP_TYPES_LOADING = 'GET_WORKSHOP_TYPES_LOADING';
export const GET_WORKSHOP_TYPES_SUCCESS = 'GET_WORKSHOP_TYPES_SUCCESS';

// Stairs

export const GET_STAIRS_SUCCESS = 'GET_STAIRS_SUCCESS';
export const GET_STAIRS_FAILURE = 'GET_STAIRS_FAILURE';
export const GET_STAIRS_LOADING = 'GET_STAIRS_LOADING';

export const GET_STAIR_RECIPES_LOADING = 'GET_STAIR_RECIPES_LOADING';
export const GET_STAIR_RECIPES_SUCCESS = 'GET_STAIR_RECIPES_SUCCESS';
export const GET_STAIR_RECIPES_FAILURE = 'GET_STAIR_RECIPES_FAILURE';

export const CREATE_STAIR_LOADING = 'CREATE_STAIR_LOADING';
export const CREATE_STAIR_SUCCESS = 'CREATE_STAIR_SUCCESS';
export const CREATE_STAIR_FAILURE = 'CREATE_STAIR_FAILURE';

export const ADD_STAIR = 'ADD_STAIR';
export const UPDATE_STAIR = 'UPDATE_STAIR';
export const REMOVE_STAIR = 'REMOVE_STAIR';
export const CLEAR_STAIRS = 'CLEAR_STAIRS';

export const GET_STAIR_TYPES_LOADING = 'GET_STAIR_TYPES_LOADING';
export const GET_STAIR_TYPES_SUCCESS = 'GET_STAIR_TYPES_SUCCESS';

// Organisations

export const CREATE_ORGANISATION_LOADING = 'CREATE_ORGANISATION_LOADING';
export const CREATE_ORGANISATION_SUCCESS = 'CREATE_ORGANISATION_SUCCESS';
export const CREATE_ORGANISATION_FAILURE = 'CREATE_ORGANISATION_FAILURE';
export const GET_ORGANISATIONS_LOADING = 'GET_ORGANISATIONS_LOADING';
export const GET_ORGANISATIONS_SUCCESS = 'GET_ORGANISATIONS_SUCCESS';
export const GET_ORGANISATIONS_FAILURE = 'GET_ORGANISATIONS_FAILURE';
export const ADD_ORGANISATION = 'ADD_ORGANISATION';
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION';
export const REMOVE_ORGANISATION = 'REMOVE_ORGANISATION';
export const CLEAR_ORGANISATIONS = 'CLEAR_ORGANISATIONS';

// Zones

export const CREATE_ZONE_LOADING = 'CREATE_ZONE_LOADING';
export const CREATE_ZONE_SUCCESS = 'CREATE_ZONE_SUCCESS';
export const CREATE_ZONE_FAILURE = 'CREATE_ZONE_FAILURE';
export const GET_ZONES_LOADING = 'GET_ZONES_LOADING';
export const GET_ZONES_SUCCESS = 'GET_ZONES_SUCCESS';
export const GET_ZONES_FAILURE = 'GET_ZONES_FAILURE';
export const ADD_ZONE = 'ADD_ZONE';
export const UPDATE_ZONE = 'UPDATE_ZONE';
export const REMOVE_ZONE = 'REMOVE_ZONE';
export const CLEAR_ZONES = 'CLEAR_ZONES';

// Mine Walls

export const GET_MINE_WALLS_SUCCESS = 'GET_MINE_WALLS_SUCCESS';
export const GET_MINE_WALLS_FAILURE = 'GET_MINE_WALLS_FAILURE';
export const GET_MINE_WALLS_LOADING = 'GET_MINE_WALLS_LOADING';

export const GET_MINE_WALL_RECIPES_LOADING = 'GET_MINE_WALL_RECIPES_LOADING';
export const GET_MINE_WALL_RECIPES_SUCCESS = 'GET_MINE_WALL_RECIPES_SUCCESS';
export const GET_MINE_WALL_RECIPES_FAILURE = 'GET_MINE_WALL_RECIPES_FAILURE';

export const CREATE_MINE_WALL_LOADING = 'CREATE_MINE_WALL_LOADING';
export const CREATE_MINE_WALL_SUCCESS = 'CREATE_MINE_WALL_SUCCESS';
export const CREATE_MINE_WALL_FAILURE = 'CREATE_MINE_WALL_FAILURE';

export const ADD_MINE_WALL = 'ADD_MINE_WALL';
export const UPDATE_MINE_WALL = 'UPDATE_MINE_WALL';
export const REMOVE_MINE_WALL = 'REMOVE_MINE_WALL';

export const GET_MINE_WALL_TYPES_LOADING = 'GET_MINE_WALL_TYPES_LOADING';
export const GET_MINE_WALL_TYPES_SUCCESS = 'GET_MINE_WALL_TYPES_SUCCESS';

// Trade

export const GET_TRADE_FOOD_SUCCESS = 'GET_TRADE_FOOD_SUCCESS';
export const GET_TRADE_INVENTORY_SUCCESS = 'GET_TRADE_INVENTORY_SUCCESS';
export const GET_TRADE_TILES_SUCCESS = 'GET_TRADE_TILES_SUCCESS';
export const GET_TRADE_MINERALS_SUCCESS = 'GET_TRADE_MINERALS_SUCCESS';
export const GET_TRADE_TOOLS_SUCCESS = 'GET_TRADE_TOOLS_SUCCESS';
export const GET_TRADE_METALS_SUCCESS = 'GET_TRADE_METALS_SUCCESS';
export const GET_TRADE_KEYS_SUCCESS = 'GET_TRADE_KEYS_SUCCESS';
export const GET_TRADE_WRITING_SURFACES_SUCCESS = 'GET_TRADE_WRITING_SURFACES_SUCCESS';
export const GET_TRADE_WRITING_IMPLEMENTS_SUCCESS = 'GET_TRADE_WRITING_IMPLEMENTS_SUCCESS';
export const GET_TRADE_WEAPONS_SUCCESS = 'GET_TRADE_WEAPONS_SUCCESS';
export const GET_TRADE_ARMOUR_SUCCESS = 'GET_TRADE_ARMOUR_SUCCESS';
export const GET_TRADE_CLOTHING_SUCCESS = 'GET_TRADE_CLOTHING_SUCCESS';
export const GET_TRADE_JEWELLERY_SUCCESS = 'GET_TRADE_JEWELLERY_SUCCESS';
export const GET_TRADE_TENTS_SUCCESS = 'GET_TRADE_TENTS_SUCCESS';
export const GET_TRADE_LOCKS_SUCCESS = 'GET_TRADE_LOCKS_SUCCESS';

export const GET_TRADE_FOOD_FAILURE = 'GET_TRADE_FOOD_FAILURE';
export const GET_TRADE_INVENTORY_FAILURE = 'GET_TRADE_INVENTORY_FAILURE';
export const GET_TRADE_TILES_FAILURE = 'GET_TRADE_TILES_FAILURE';
export const GET_TRADE_MINERALS_FAILURE = 'GET_TRADE_MINERALS_FAILURE';
export const GET_TRADE_TOOLS_FAILURE = 'GET_TRADE_TOOLS_FAILURE';
export const GET_TRADE_METALS_FAILURE = 'GET_TRADE_METALS_FAILURE';
export const GET_TRADE_KEYS_FAILURE = 'GET_TRADE_KEYS_FAILURE';
export const GET_TRADE_WRITING_SURFACES_FAILURE = 'GET_TRADE_WRITING_SURFACES_FAILURE';
export const GET_TRADE_WRITING_IMPLEMENTS_FAILURE = 'GET_TRADE_WRITING_IMPLEMENTS_FAILURE';
export const GET_TRADE_WEAPONS_FAILURE = 'GET_TRADE_WEAPONS_FAILURE';
export const GET_TRADE_ARMOUR_FAILURE = 'GET_TRADE_ARMOUR_FAILURE';
export const GET_TRADE_CLOTHING_FAILURE = 'GET_TRADE_CLOTHING_FAILURE';
export const GET_TRADE_JEWELLERY_FAILURE = 'GET_TRADE_JEWELLERY_FAILURE';
export const GET_TRADE_TENTS_FAILURE = 'GET_TRADE_TENTS_FAILURE';
export const GET_TRADE_LOCKS_FAILURE = 'GET_TRADE_LOCKS_FAILURE';

// Character Levels

export const GET_CHARACTER_LEVELS_LOADING = 'GET_CHARACTER_LEVELS_LOADING';
export const GET_CHARACTER_LEVELS_SUCCESS = 'GET_CHARACTER_LEVELS_SUCCESS';
export const GET_CHARACTER_LEVELS_FAILURE = 'GET_CHARACTER_LEVELS_FAILURE';

export const NEW_CHARACTER_LEVEL_SUCCESS = 'NEW_CHARACTER_LEVEL_SUCCESS';
export const UPGRADE_CHARACTER_LEVEL_SUCCESS = 'UPGRADE_CHARACTER_LEVEL_SUCCESS';

// Coins

export const GET_COIN_RECIPES_LOADING = 'GET_COIN_RECIPES_LOADING';
export const GET_COIN_RECIPES_SUCCESS = 'GET_COIN_RECIPES_SUCCESS';
export const GET_COIN_RECIPES_FAILURE = 'GET_COIN_RECIPES_FAILURE';

export const GET_COINS_LOADING = 'GET_COINS_LOADING';
export const GET_COINS_SUCCESS = 'GET_COINS_SUCCESS';
export const GET_COINS_FAILURE = 'GET_COINS_FAILURE';

export const CREATE_COIN_SUCCESS = 'CREATE_COIN_SUCCESS';
export const CREATE_COIN_FAILURE = 'CREATE_COIN_FAILURE';

export const REMOVE_COIN_SUCCESS = 'REMOVE_COIN_SUCCESS';
export const UPDATE_COIN_SUCCESS = 'UPDATE_COIN_SUCCESS';

export const GET_COIN_TYPES_LOADING = 'GET_COIN_TYPES_LOADING';
export const GET_COIN_TYPES_SUCCESS = 'GET_COIN_TYPES_SUCCESS'
export const CREATE_COIN_TYPE_SUCCESS = 'CREATE_COIN_TYPE_SUCCESS'

export const GET_CURRENCIES_LOADING = 'GET_CURRENCIES_LOADING';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_CURRENCIES_FAILURE = 'GET_CURRENCIES_FAILURE';
export const CREATE_CURRENCY_SUCCESS = 'CREATE_COIN_SUCCESS';
export const CREATE_CURRENCY_FAILURE = 'CREATE_COIN_FAILURE'

// Brainchips
export const CREATE_BRAINCHIP_LOADING = 'CREATE_BRAINCHIP_LOADING';
export const CREATE_BRAINCHIP_SUCCESS = 'CREATE_BRAINCHIP_SUCCESS';
export const CREATE_BRAINCHIP_FAILURE = 'CREATE_BRAINCHIP_FAILURE';

export const GET_BRAINCHIPS_LOADING = 'GET_BRAINCHIPS_LOADING';
export const GET_BRAINCHIPS_SUCCESS = 'GET_BRAINCHIPS_SUCCESS';
export const GET_BRAINCHIPS_FAILURE = 'GET_BRAINCHIPS_FAILURE';

export const ADD_BRAINCHIP = 'ADD_BRAINCHIP';
export const UPDATE_BRAINCHIP = 'UPDATE_BRAINCHIP';
export const REMOVE_BRAINCHIP = 'REMOVE_BRAINCHIP';

export const GET_BRAINCHIP_TYPES_LOADING = 'GET_BRAINCHIP_TYPES_LOADING';
export const GET_BRAINCHIP_TYPES_SUCCESS = 'GET_BRAINCHIP_TYPES_SUCCESS';

export const GET_BRAINCHIP_RECIPES_LOADING = 'GET_BRAINCHIP_RECIPES_LOADING';
export const GET_BRAINCHIP_RECIPES_SUCCESS = 'GET_BRAINCHIP_RECIPES_SUCCESS';
export const GET_BRAINCHIP_RECIPES_FAILURE = 'GET_BRAINCHIP_RECIPES_FAILURE';

export const SET_IS_OVERENCUMBERED = 'SET_IS_OVERENCUMBERED';

export const GET_CRIMINALS_SUCCESS = 'GET_CRIMINALS_SUCCESS';
export const ADD_CRIMINAL = 'ADD_CRIMINAL';
export const UPDATE_CRIMINAL = 'UPDATE_CRIMINAL';
export const REMOVE_CRIMINAL = 'REMOVE_CRIMINAL';

export const SHOW_CHARACTER_SELECT = 'SHOW_CHARACTER_SELECT';