import { GET_JEWELLERY_TYPES_SUCCESS } from "../actionTypes";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_JEWELLERY_TYPES_SUCCESS: {
      const jewelleryTypes = action.payload;

      if (!jewelleryTypes){
        return [...state];
      }

      return [
        ...state,
        ...jewelleryTypes
      ]
    }
    default:
      return state;
  }
}
