import { BEGIN_UPDATE_EMBARK_POSITION, GET_EMBARK_CHARACTER_SUCCESS, NEW_EMBARK_CHARACTER_SUCCESS, JOIN_EMBARK_GROUP_SUCCESS } from "../actionTypes";

const initialState = {
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_EMBARK_CHARACTER_SUCCESS: {
      const embarkCharacters = action.payload.embarkCharacters.filter(character => character.userId === action.payload.userId);

      return {
        ...embarkCharacters[0]
      }
    }

    case NEW_EMBARK_CHARACTER_SUCCESS:
    case JOIN_EMBARK_GROUP_SUCCESS: {
      return {
        ...state,
        ...action.payload.character,
        position: {
          x: 23,
          y: 12
        }
      }
    }

    case BEGIN_UPDATE_EMBARK_POSITION: {
      return {
        ...state,
        isWalking: true
      };
    }

    default:
      return state;
  }
}
