import { FOV } from 'rot-js';
import { PANEL_WIDTH, PANEL_HEIGHT } from './geography';
import { getMockLayer } from './biome-geography';

export function removeNighttimeTiles(radiusSize, layer, position) {
    if (!layer) {
        return;
    }

    layer.layer?.data?.forEach((row, columnIndex) => {
        row.forEach((tile, rowIndex) => {
            if (!tile) {return}

            const a = rowIndex - position.x;
            const b = columnIndex - position.y;

            const distance = Math.hypot(a, b);

            if (distance > radiusSize + 0.2) {
                tile.index = -1;
            }
        })
    })
}

export function drawFieldOfView(mineWalls, positionUser, visionRadius) {
    if (!mineWalls || !positionUser) {
        return [];
    }

    const mineWallLayer = getMockLayer();

    mineWalls.forEach(wall => {
        mineWallLayer[wall.position.y][wall.position.x] = wall;
    })

    const MINE_VISION_RADIUS = Number.isInteger(visionRadius) ? visionRadius : Math.max(Math.round(visionRadius), 1);

    function isLightPassingThrough(x, y) {
        if (x < 0 || y < 0 || x > PANEL_WIDTH - 1 || y > PANEL_HEIGHT - 1) {
            return false;
        }

        return !(mineWallLayer[y][x]._id || mineWallLayer[y][x].id)
    }

    const fov = new FOV.PreciseShadowcasting(isLightPassingThrough);

    let visibleTiles = []

    fov.compute(positionUser?.x, positionUser?.y, MINE_VISION_RADIUS, (x, y, r, visibility) => {
        if (x < 0 || y < 0 || x > PANEL_WIDTH - 1 || y > PANEL_HEIGHT - 1) {
            return;
        }

        visibleTiles.push({
            x,
            y,
            visibility
        })
    })

    // filter out tiles that are outside the circle
    visibleTiles = visibleTiles.filter(tile => {
        const a = tile.x - positionUser.x
        const b = tile.y - positionUser.y

        const distance = Math.hypot(a, b);

        return distance <= MINE_VISION_RADIUS
    })

    return visibleTiles;
}