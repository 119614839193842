export const wallPhaserFaceSentences = [
    "Yellow, slimy teeth dripping with saliva",
"Small, beady red eyes set close together",
"A long, snake-like tongue flicking in and out",
"A green, scaly complexion",
"Sharp claws on each hand",
"Giant, toothy maw dripping with saliva ",
"beady, black eyes staring menacingly ",
"sharp, spiky teeth ready to rend flesh ",
"long, writhing tongue tasting the air ",
"slime-covered skin pulsing with hunger",
"Older than time itself",
"Colder than the depths of space",
"Angry red eyes",
"Sharp teeth",
"Smoke coming from nostrils",
"Long claws",
"Spikes down back",
"Cat-like features",
"Scaly, purple skin dripping with slime",
"Sharp, pointy teeth ready to tear into flesh",
"Glowing red eyes full of hate",
"A long, forked tongue flicking in and out",
"A drooling mouth full of razor-sharp fangs",
]

export const wallPhaserBodySentences = [
"Its long, serpentine body is lithe and flexible, allowing it to strike with deadly precision.",
"its body is charred black from the heat of battle.",
"its body is covered in blood, both its own and its victims.",
"Gigantic and reptilian, with leathery skin and a long, sinuous body.",
"Covered in spikes and barbs, with glowing red eyes and burning hatred in its heart.",
"Slithery and snake-like, with a razor-sharp beak and venom",
"Waves of energy ripple through its body.",
"It crackles with energy, ready to discharge at any moment.",
"It has a glowing core of pure energy.",
"It is composed entirely of energy, crackling and surging with power.",
]

export const wallPhaserSoundWords = [
	"Roaring",
"Howling",
"bellowing",
"screeching",
"hissing",
"gnashing",
"clattering",
"pounding",
"thrashing",
"writhing",
"Roaring",
"Snarling",
"Growling",
"Rumbling",
"Grunting",
"Wheezing",
"Snoring",
"Snorting",
"Sputtering",
"Hissing",
"Roaring",
"Howling",
"Rumbling",
"Grunting",
"Snarling",
"Growling",
"Yelling",
"Screaming",
"Shouting",
"roaring",
"Rumbling",
"Snarling",
"Roaring",
"Growling",
"Howling",
"Screaming",
"Shrieking",
"Yelling",
"Cackling",
"Raving",
]

export const wallPhaserFurWords = [
  "glossy", "coarse", "matted", "tangled", "greasy", "brittle", "dry", "cracked", "scaly", "brittle",
]

export const wallPhaserSmellWords = [
"Spicy",
"Sweet",
"Sour",
"Bitter",
"Metallic",
"Putrid",
"Rotten",
"Beachy",
"Flowery",
"Wintery",
"Burning",
"Chemical",
"Rancid",
"Noxious",
"Pungent",
"Acrid",
"nauseating",
"Choking",
"suffocating",
"stifling",
]

export const wallPhaserBehaviourSentences = [
"it is hissing.",
"it is growling.",
"it is screeching.",
"it is making strange, high-pitched noises that sound almost like laughter.",
"it is slowly moving towards",
"it is rotting from the inside out",
"it is missing its eyes and most of its fur",
"it is screeching and flailing its limbs wildly",
"it is foaming at the mouth and drooling",
]