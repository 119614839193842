export const fireShooterFaceSentences = [
"Following eyes that seemed to sear through you",
"A toothy grin that showed off sharpened fangs",
"Skin that was stained with soot and ash",
"A face that was twisted in a visage of pure malevolence",
"Flaming eyes that seem to pierce your soul",
"A mouth full of sharp teeth that look ready to devour you",
"A visage that is both demonic and angel",
"Angry red eyes glaring through the smoke",
"A mouth full of sharp teeth bared in a ferocious snarl",
"Blackened skin covered in soot and ash",
"Muscles tense and ready to spring into action",
"A wild, untamed look in its eyes",
"Enormous, with a gaping maw ready to spew forth fire",
"Flaming eyes that seem to sear your soul",
"A mouth that belches fire, seeking to incinerate anything in its path",
"Crisp, crackling flames that lick at the air",
"An inferno of a face, with a heat that could melt",
]

export const fireShooterBodySentences = [
"It's body is crackling with heat and energy",
"It's body is engulfed in flames, with burning embers flying off of it in all directions",
"It's skin is blackened and charred from the intense heat",
"Its body is engulfed in flames",
"Its skin is charred and black",
"Smoke and ash billow from its body",
]

export const fireShooterSoundWords = [
"Rumbling",
"Roaring",
"Cracking",
"Snapping",
"Sizzling",
"Sparking",
"Whooshing",
"Smoldering",
"Bellowing",
"Flaming",
"Churning",
"Rumbling",
"Roaring",
"Swooshing",
"Whistling",
"Buzzing",
"Hissing",
"Popping",
"Snapping",
"crackling",
]

export const fireShooterFurWords = [
"Flaming",
"Bursting",
"Smoldering",
"Charred",
"Singed",
"Blistering",
"Melted",
"Charred",
"Smoking",
"Flaming",
"Red",
"Blistering",
"Sweltering",
"Infernal",
"Smoldering",
"Singed",
"Burnt",
"Charred",
"Incinerated",
"Flaming",
"Incandescent",
"Scorching",
"Blistering",
"Infernal",
"Fiery",
]

export const fireShooterSmellWords = [
"acrid",
"smoky",
"searing",
"flaming",
"singed",
"toasted",
"charred",
"blazing",
"scorching",
"flaming",
"Thick",
"incapacitating",
"Overpowering",
"choking",
"sickening",
"suffocating",
"noxious",
"poisonous",
"deadly",
"crid",
"acrid",
"sulfurous",
"caustic",
"pungent",
"harsh",
"biting",
"stinging",
"fiery",
"scorching",
"searing",
"Acrid",
"Pungent",
"Ammonia-like",
"Chlorine-like",
"Sulphuric",
"Putrid",
"Rancid",
"Foul",
"Nauseating",
"Stench",
]

export const fireShooterBehaviourSentences = [
"it is blasting fire from its mouth",
"it is spurting fire from its eyes",
"it is flicking fire from its fingers",
"it is staring at you with its fiery eyes",
"it is foaming at the mouth and drooling fire",
"baring its teeth in a feral grin",
"drooling hungrily as it licking its lips",
"it is watching you with its eerie glowing eyes",
"it is standing completely still, watching you",
"it is staring at you with its beady eyes",
"it is flicking its tongue at you",
"it is baring its teeth at you",
]