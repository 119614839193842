import {
    CREATE_WORKSHOP_LOADING,
    CREATE_WORKSHOP_SUCCESS,
    CREATE_WORKSHOP_FAILURE,
    GET_WORKSHOPS_LOADING,
    GET_WORKSHOPS_SUCCESS,
    GET_WORKSHOPS_FAILURE,
    ADD_WORKSHOP,
    UPDATE_WORKSHOP,
    REMOVE_WORKSHOP,
    ERECT_WORKSHOP_SUCCESS
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getTentBoundries } from '../../services/tent-helpers';
import { colyClient } from '../../services/Panel-initialisation.service';

import { moveItemFailure } from './move-item.actions';

export const createWorkshopSuccess = payload => ({
    type: CREATE_WORKSHOP_SUCCESS,
    payload
})

export const createWorkshopFailure = () => ({
    type: CREATE_WORKSHOP_FAILURE
})

export const createWorkshopAsync = payload => dispatch => {
    const { _id, workshopTypeId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'workshop-instances',
        name: 'create',
        data: {
            workshopTypeId,
            panelId,
            z,
            position,
            level: 1
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getWorkshopsSuccess = payload => {
    return ({
        type: GET_WORKSHOPS_SUCCESS,
        payload
    })
}

export const getWorkshopsFailure = () => ({
    type: GET_WORKSHOPS_FAILURE
})

export const getWorkshopsAsync = payload => dispatch => {
    return client.service('workshop-instances').find({ paginate: false, query: { panelId: payload.panelId, z: payload.z }})
        .then((response) => {
            response = response.map(workshop => ({ ...workshop, workshopType: payload.workshopTypes.find(type => type._id === workshop.workshopTypeId) }))
            dispatch(getWorkshopsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const destroyWorkshop = payload => dispatch => {

    colyClient.room.send('doAction', {
        serviceName: 'workshop-instances',
        commandType: 'remove',
        data: {
            _id: payload._id,
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addWorkshopToTileAsync = payload => dispatch => {
    const { workshopInstance, tileId, tileTypeId } = payload;
    const { workshopTypeId, quantity, recipeId, characterId, _id } = workshopInstance;

    colyClient.room.send('doAction', {
        serviceName: 'workshop-instances',
        commandType: 'create',
        data: {
            workshopTypeId,
            quantity,
            tileId,
            tileTypeId,
            panelId: "",
            position: {},
            characterId: ""
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addWorkshopToCharacterAsync = payload => dispatch => {
    const { workshopInstance, characterId } = payload;
    const { createdAt, workshopTypeId, quantity, recipeId, _id } = workshopInstance;

    colyClient.room.send('doAction', {
        serviceName: 'workshop-instances',
        commandType: 'create',
        data: {
            workshopTypeId,
            quantity,
            tileId: "",
            characterId
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const erectWorkshopAsync = payload => dispatch => {
    const { panelId, characterId, position, workshopType, _id, z } = payload;

    if (!workshopType) {
        console.log('error with workshop type pal ');
    }

    const workshopBoundries = getTentBoundries(position, 'right', workshopType)

    colyClient.room.send('doSuperAction', {
        serviceName: 'workshop-instances',
        name: 'create',
        data: {
            workshopTypeId: workshopType._id,
            panelId,
            z,
            position,
            level: 1
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const erectWorkshopSuccess = payload => {
    return ({
        type: ERECT_WORKSHOP_SUCCESS,
        payload
    })
}

export const dismantleWorkshopAsync = payload => dispatch => {
    const { characterId, _id } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'workshop-instances',
        commandType: 'patch',
        data: {
            _id,
            panelId: '',
            characterId,
            workshopBoundries: []
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addWorkshop = payload => {
    return ({
        type: ADD_WORKSHOP,
        payload
    })
}

export const updateWorkshop = payload => {
    return ({
        type: UPDATE_WORKSHOP,
        payload
    })
}

export const removeWorkshop = payload => {
    return ({
        type: REMOVE_WORKSHOP,
        payload
    })
}


export const upgradeWorkshopAsync = payload => dispatch => {
    const { level, _id } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'workshop-instances',
        commandType: 'patch',
        data: {
            _id,
            level,
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}