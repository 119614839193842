import store from '../redux/store';
import seedrandom from 'seedrandom';

import {
	selectVisionRadius,
	selectCharacter,
	selectPlants,
    selectCharacters,
    selectDeadCharacters,
    selectTentById,
    selectWorkshopTypeById,
    selectWorkshops,
    selectBackgroundConstructions,
    selectWagons,
    selectBoats,
    selectItemTileIcons,
    selectFurniture,
    selectStairs,
    selectMineWalls,
    selectMinerals,
    selectCurrentTool,
    selectServerTimeOffset,
    selectBrainchipTypeByIds,
    selectBrainchipTypes,
    selectTents,
    selectPanel,
    selectCharacterWorkshopBoundries,
    selectConstructions,
    selectForegroundConstructions,
} from '../redux/selectors';
import { BALANCE } from './balance-config';
import { differenceInCalendarDays } from 'date-fns';
import tileMap from './tile-map';
import { PANEL_WIDTH, PANEL_HEIGHT, randomNumberBetween } from './geography'
import { drawFieldOfView } from './night-radius';

import { getTentBoundries } from './tent-helpers';

import { calculateLightSources, BRAINCHIP_TYPE_NAMES } from './Tilemap.service';

import client from './client';

const TILE_WIDTH = 16;
const TILE_HEIGHT = 24;

const movementTiles = [tileMap[`MOVING_1`], tileMap[`MOVING_2`], tileMap[`MOVING_3`], tileMap[`MOVING_4`]]

function getMockLayer() {
	const mockLayerData = [];

    for (var y=0; y < PANEL_HEIGHT; y++) {
        mockLayerData[y] = []
        for (var x=0; x < PANEL_WIDTH; x++) {
            mockLayerData[y][x] = []
        }
    }

    return mockLayerData;
}

const groupBiomes = {
    0: 'DESERT',
    1: 'SWAMP',
    2: 'TAIGA',
    3: 'JUNGLE',
    4: 'ARID',
    5: 'OLD-GROWTH',
    6: 'RIVER',
    7: 'POLAR',
}

export default class ForegroundTilemapService {
	backgroundLayer;

	storeSubscription;
	layer;
    character;
    visionRadius;

    deadCharacters;
    animals = [];
    plants = [];
    characters = [];
    deadCharacters = [];
    characterTent;
    character;
    workshops = [];
    constructions = [];
    wagons = [];
    boats = [];
    iconTiles = [];
    furniture = [];
    stairs = [];
    minerals = [];
    brainchipTypes = [];
    currentActiveBrainchips = [];
    tents = [];
    currentTool = {}
    panel = {};

    characterSprites = [];

    constructor(backgroundLayer, scene) {
        this.scene = scene;
    	this.backgroundLayer = backgroundLayer;
    	this.layer = getMockLayer();
    }

    initialDraw(state, updatedCharacter, visibleTiles) {
        if (!state) {
            state = store.getState();
        }

        if (!updatedCharacter) {
            updatedCharacter = selectCharacter(state);
        }

        this.visibleTiles = visibleTiles;

        // BEGIN

        // CONSTRUCTIONS
        const selectedConstructions = selectBackgroundConstructions(state);
        const updatedConstructions = Object.values(selectedConstructions.byId);

        this.removeItems(this.constructions);
        this.addItems(updatedConstructions);

        // END

        this.constructions = updatedConstructions;
        this.character = updatedCharacter;

        this.copyLayersOver();
    }

    addNewItem(item) {
        if (!item || !item.position || item.position.x === undefined || item.position.y < 0 || item.position.x < 0 || item.position.y >= PANEL_HEIGHT || item.position.x >= PANEL_WIDTH) {
            return;
        }

        if (item?.z && item?.z !== this.character?.z) {
            return;
        }

        this.layer[item.position.y][item.position.x] = this.layer[item.position.y][item.position.x].filter(item => (item.id !== 'grass'))
        this.layer[item.position.y][item.position.x].push({ ...item });
    }

    removeNewItem({ z, position, id, _id }) {
        if (!position || position.x === undefined || position.y < 0 || position.x < 0 || position.y >= PANEL_HEIGHT || position.x >= PANEL_WIDTH) {
            return;
        }

        this.layer[position.y][position.x] = this.layer[position.y][position.x].filter(item => {
            if (item.setAlpha) {
                return true;
            }

            if (item._id && _id) {
                return item._id !== _id
            }

            if (item.id && id) {
                return item.id !== id
            }

            if (item._id && id) {
                return true;
            }

            if (item.id && _id) {
                return true;
            }
        });
    }

    removeItems(items) {
        items.forEach((item) => this.removeNewItem(item))
    }

    addItems(items) {
        items.forEach((item) => this.addNewItem(item))
    }

    copyLayersOver() {
        if (!this.backgroundLayer || !this.backgroundLayer.layer) {
            return;
        }

        this.backgroundLayer.layer.data.forEach((row, y) => {
            row.forEach((tile, x) => {
                tile.index = -1;
            })
        })

    	this.layer.forEach((row, y) => {
    		row.forEach((items, x) => {
    			if (!items || items.length === 0) {
    				return;
    			}

                if (!this.isWithinLightSourceVision({ x, y })) {
                    this.backgroundLayer.putTileAt(-1, x, y)
                    return;
                } else if (!this.character?.z && this.isWithinLightSourceVision({ x, y })) {
                    this.backgroundLayer.putTileAt(tileMap['EMPTY'], x, y)
                }

                if (!this.currentActiveBrainchips.find(brainchipType => (brainchipType.name === BRAINCHIP_TYPE_NAMES.NIGHT_VISION))) {
                    if (!this.isWithinLightSourceVision({ x, y })) {
                        return;
                    }
                }

    			const timeNow = new Date().getTime();
    			let numberOfTiles = items.length;

                const tileCharacterSprites = items.filter(item => (this.characterSprites.indexOf(item) > -1));

                if (tileCharacterSprites.length) {
                    numberOfTiles = numberOfTiles - (tileCharacterSprites.length - 1);
                }

    			const timeToShow = 400
    			const totalTimeToShow = numberOfTiles * timeToShow;
    			const numberBetweenZeroAndTotalTimeToShow = timeNow % totalTimeToShow
    			const currentlyShowing = Math.floor(numberBetweenZeroAndTotalTimeToShow / totalTimeToShow * numberOfTiles)

    			items.forEach((item, index) => {

                    if (item.setAlpha) {
                        item.setAlpha(0.4)
                    }

    				if (currentlyShowing === index || tileCharacterSprites.indexOf(items[currentlyShowing]) > -1 && tileCharacterSprites.indexOf(item) > -1) {
                        if (new Date().getTime() - this.timeOffset > item.expiresAt) {
                            return;
                        }
                        if (item.graffiti || item.pixelsArray) {
                            // TODO - choose colour more cleverly...
                            this.backgroundLayer.layer.data[y][x].tint = 0x00FFF0
                        }

    					if (item.setAlpha) {
    						item.setAlpha(1)
    					}
    					this.backgroundLayer.putTileAt(tileMap[this.findItemTileKey(item)], x, y)

    					return;
    				}
    			})
    		})
    	})
    }

    isWithinLightSourceVision({ x, y }) {
        return this.visibleTiles.find(wall => wall.x === x && wall.y === y && wall.visibility);
    }

    onDestroy() {
        this.storeSubscription()
    }

    findItemTileKey(item) {
        if (item.plant?.maxTendingCount) {
            if (item.tendedCount / item.plant?.maxTendingCount < 0.25) {
                return item.tileKey.split('.')[0] + '.SEED';
            }

            if (item.tendedCount / item.plant?.maxTendingCount < 0.5) {
                return item.tileKey.split('.')[0] + '.YOUNG';
            }

            if (item.tendedCount / item.plant?.maxTendingCount < 0.75) {
                return item.tileKey.split('.')[0] + '.OLD';
            }

            return item.tileKey.split('.')[0] + '.HARVEST'
        }

        if (item.tileKey) {
            return item.tileKey;
        }

        if (item.animal?.name) {
            const TEN_DAYS = 1000 * 60 * 60 * 24 * 10;
            if (new Date().getTime() - item.createdAt < TEN_DAYS) {

                if (tileMap[item.animal?.name + '.CUB']) {
                    return item.animal?.name + '.CUB'
                } else {
                    return item.animal?.name
                }
            }
            return item.animal?.name;
        }

        if (item.construction?.name) {
            if (item.neighbours === 'NONE') {
                item.neighbours = 'NONE.'
            }
            if (item.construction?.name.indexOf('Space Wall') > -1) {
                return 'SPACE.' + item.neighbours;
            }
            if (item.construction?.name === 'Space Door') {
                return 'SPACE.DOOR'
            }
            if (item.construction?.name.indexOf('Wall') > -1) {

                if (item.groupBiome !== undefined) {
                    return `WOODEN-LOG.${item.neighbours}${item.groupBiome}`;
                }
                return `WOODEN-LOG.${item.neighbours}POLAR`;
                // return `MINE-WALL.${item.neighbours}`
            }

            return item.construction?.name;
        }

        if (item.tileType?.name) {
            return item.tileType?.name;
        }

        if (item.wagonType?.name) {
            return item.wagonType?.name;
        }

        if (item.boatType?.name) {
            return item.boatType?.name;
        }

        if (item.furnitureType?.name) {
            return item.furnitureType?.name;
        }

        if (item.mineralType?.name) {
            return item.mineralType?.name;
        }

        if (item.causeOfDeath) {
            return 'DEAD_CHARACTER';
        }
    }
}