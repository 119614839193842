import { GET_METAL_RECIPES_SUCCESS } from "../actionTypes";

const initialState = []

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_METAL_RECIPES_SUCCESS: {
      const metalRecipes = action.payload;

      if (!metalRecipes){
        return {...state};
      }

      return [...state, ...action.payload]
    }

    default:
      return state;
  }
}
