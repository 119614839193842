import store from '../redux/store';
import client from './client';

import { getTileTypesAsync } from '../redux/actions/tile-types.actions';
import { getMaterialTypesAsync } from '../redux/actions/material-types.actions';
import { getPlantTypesAsync } from '../redux/actions/plant-types.actions';
import { getConstructionTypesAsync } from '../redux/actions/construction-types.actions';
import { getSkillTypesAsync } from '../redux/actions/skill-types.actions';
import { getMineralTypesAsync } from '../redux/actions/mineral-types.actions';
import { getArmourTypesAsync } from '../redux/actions/armour-types.actions'
import { getClothingTypesAsync } from '../redux/actions/clothing-types.actions'
import { getJewelleryTypesAsync } from '../redux/actions/jewellery-types.actions'
import { getFoodTypesAsync } from '../redux/actions/food-types.actions'
import { getLockTypesAsync } from '../redux/actions/lock-types.actions'
import { getWritingSurfaceTypesAsync } from '../redux/actions/writing-surface-types.actions'
import { getWritingImplementTypesAsync } from '../redux/actions/writing-implement-types.actions'
import { getMachineTypesAsync } from '../redux/actions/machine-types.actions'
import { getMetalTypesAsync } from '../redux/actions/metal-types.actions'
import { getKeyTypesAsync } from '../redux/actions/key-types.actions'
import { getToolTypesAsync } from '../redux/actions/tool-types.actions'
import { getFurnitureTypesAsync } from '../redux/actions/furniture-types.actions'
import { getWeaponTypesAsync } from '../redux/actions/weapon-types.actions'
import { getAnimalTypesAsync } from '../redux/actions/animal-types.actions'
import { getFishTypesAsync } from '../redux/actions/fish-types.actions'
import { getBoatTypesAsync } from '../redux/actions/boat-types.actions'
import { getBrainchipTypesAsync } from '../redux/actions/brainchip-types.actions'
import { getWagonTypesAsync } from '../redux/actions/wagon-types.actions'
import { getTentTypesAsync } from '../redux/actions/tent-types.actions'
import { getWorkshopTypesAsync } from '../redux/actions/workshop-types.actions'
import { getPlantProductsAsync } from '../redux/actions/plant-products.actions'

import { getArmourRecipesAsync } from '../redux/actions/armour-recipes.actions';
import { getClothingRecipesAsync } from '../redux/actions/clothing-recipes.actions';
import { getFoodRecipesAsync } from '../redux/actions/food-recipes.actions';
import { getFurnitureRecipesAsync } from '../redux/actions/furniture-recipes.actions';
import { getJewelleryRecipesAsync } from '../redux/actions/jewellery-recipes.actions';
import { getLockRecipesAsync } from '../redux/actions/lock-recipes.actions';
import { getMachineRecipesAsync } from '../redux/actions/machine-recipes.actions';
import { getMaterialRecipesAsync } from '../redux/actions/material-recipes.actions';
import { getMetalRecipesAsync } from '../redux/actions/metal-recipes.actions';
import { getTileRecipesAsync } from '../redux/actions/tile-recipes.actions';
import { getToolRecipesAsync } from '../redux/actions/tool-recipes.actions';
import { getWeaponRecipesAsync } from '../redux/actions/weapon-recipes.actions';
import { getWritingImplementRecipesAsync } from '../redux/actions/writing-implement-recipes.actions';
import { getWritingSurfaceRecipesAsync } from '../redux/actions/writing-surface-recipes.actions';
import { getConstructionRecipesAsync } from '../redux/actions/construction-recipes.actions'
import { getBoatRecipesAsync } from '../redux/actions/boat-recipes.actions'
import { getBrainchipRecipesAsync } from '../redux/actions/brainchip-recipes.actions'
import { getWagonRecipesAsync } from '../redux/actions/wagon-recipes.actions'
import { getTentRecipesAsync } from '../redux/actions/tent-recipes.actions'
import { getWorkshopRecipesAsync } from '../redux/actions/workshop-recipes.actions'
import { getWorkshopLevelRecipesAsync } from '../redux/actions/workshop-level-recipes.actions'
import { getCoinRecipesAsync } from '../redux/actions/coin-recipes.actions';
import { getCoinTypesAsync } from '../redux/actions/coin-types.actions';
import { getCoinsAsync } from '../redux/actions/coin.actions';
import { getCurrenciesAsync } from '../redux/actions/currency.actions';

import { getMessagesAsync } from '../redux/actions/messages.actions';

import { getCharacterFoodAsync, addFoodToTileAsync, eatFoodAsync, deleteFoodAsync, addFoodToCharacterAsync } from '../redux/actions/food.actions';
import { getInventoryAsync, addMaterialToTileAsync, addMaterialToCharacterAsync } from '../redux/actions/inventory.actions';
import { tileTypes, createTileAsync } from '../redux/actions/tile.actions';
import { getMineralsAsync, addMineralToTileAsync, addMineralToCharacterAsync } from '../redux/actions/mineral.actions';
import { getToolsAsync, addToolToTileAsync, addToolToCharacterAsync } from '../redux/actions/tool.actions';
import { getMetalsAsync, addMetalToTileAsync, addMetalToCharacterAsync } from '../redux/actions/metal.actions';
import { getKeysAsync, addKeyToTileAsync, copyKeyAsync, addKeyToCharacterAsync } from '../redux/actions/key.actions';
import { addWritingSurfaceToTileAsync, getWritingSurfacesAsync, editWritingSurfaceAsync, addWritingSurfaceToCharacterAsync } from '../redux/actions/writing-surface.actions';
import { addWritingImplementToTileAsync, getWritingImplementsAsync, addWritingImplementToCharacterAsync } from '../redux/actions/writing-implement.actions';
import { getWeaponsAsync, addWeaponToTileAsync, addWeaponToCharacterAsync } from '../redux/actions/weapon.actions';
import { getArmourAsync, addArmourToTileAsync, addArmourToCharacterAsync } from '../redux/actions/armour.actions';
import { getClothingAsync, addClothingToTileAsync, addClothingToCharacterAsync } from '../redux/actions/clothing.actions';
import { getJewelleryAsync, addJewelleryToTileAsync, addJewelleryToCharacterAsync } from '../redux/actions/jewellery.actions';
import { equipWeaponAsync, equipArmourAsync, equipClothingAsync, equipJewelleryAsync, unequipClothingAsync, unequipJewelleryAsync } from '../redux/actions/character.actions';
import { getCharactersAsync } from '../redux/actions/characters.actions';
import { initialisingAppStarted, initialisingAppFinished, initialisingPanelStarted, showQuantityInput, hideQuantityInput, chooseCharacterToGiveTo, hideCharacterList, closeInventory } from '../redux/actions/keyboard-shortcuts.actions';
import { getCharacterPanelsAsync } from '../redux/actions/character-panel.actions';
import { selectCharacter, selectTiles } from '../redux/selectors';

import { cachedReducers } from '../redux/local-storage';


// to do for this caching to work nicely:
// 1 - strip the recipes out of types in the backend
// 2 - only save recipes or types to the local storage when they are FINISHED (not just when thhere is at least one result)
class InitialisationService {
    character;
    isInitialised = false;
    isRefreshed = false;

    isCutscene = false

    constructor(options) {
        if (options && options.isCutscene) {
            this.isCutscene = true;
        }
    }

    async initialise() {
        store.dispatch(initialisingAppStarted());
        await this.getStaticData();
    }

    async getStaticData() {
        try {
            // await this.getMessages();
            await this.getCharacterLevels();

            // store.dispatch(initialisingPanelStarted());
            store.dispatch(initialisingAppFinished());
        } catch(e) {
            console.log('ERROR WITH INITIALISATION: ', e);
        }
    }

    async getMessages() {
        if (this.isCutscene !== true) {
            await store.dispatch(getMessagesAsync());
        }
    }

    async getCharacterLevels() {
        if (this.isCutscene !== true) {
            await store.dispatch(getCurrenciesAsync());
            await store.dispatch(getCoinRecipesAsync());
            await store.dispatch(getCoinTypesAsync());
        }
    }
}

export default InitialisationService;
