import React from "react";
import { connect } from 'react-redux'

import { selectArmourRecipes } from '../../../redux/selectors';

import { client } from '../../../services/client';

class ArmourRecipes extends React.Component {

    render() {
        const listItems = this.props.armourRecipes
            .filter(armourRecipe => (armourRecipe.armourType.name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase()) > -1))
            .sort((a, b) => (a.armourType?.name.localeCompare(b.armourType?.name)))
            .map((armourRecipe) => {
                return (
                    <li key={armourRecipe._id}
                        onClick={() => {
                            return (this.props.onItemSelect(armourRecipe))
                        }}>
                        <span className={this.props.selectedItem?._id === armourRecipe?._id ? "selected": ""}>
                            {armourRecipe.armourType.name}
                        </span>
                    </li>
                );
            })
        return (
            <ul>{listItems}</ul>
        )
    }
}

const mapStateToProps = state => {
    const armourRecipes = selectArmourRecipes(state)

    return { armourRecipes }
}

export default connect(
    mapStateToProps,
    {}
)(ArmourRecipes);