import React from "react";
import { connect } from 'react-redux'

import { clearPlotAsync, removePlotAsync, tileTypes } from '../../../redux/actions/tile.actions';
import { gatherMineralAsync } from '../../../redux/actions/mineral.actions';
import { chooseSeedToPlant } from '../../../redux/actions/keyboard-shortcuts.actions';
import {
    selectHighlight,
    selectCharacter,
    selectTileTypeByName,
    selectCurrentTiles,
    selectPlantInstanceIdAtTile
} from '../../../redux/selectors';

class FarmPlotActions extends React.Component {
    actions = [
        {
            name: 'plant seed',
            callback: () => {
                this.props.chooseSeedToPlant();
            }
        },
        {
            name: 'remove farm plot',
            callback: () => {
                this.props.removePlotAsync(this.props.currentFarmTile);
            }
        }
    ];

    constructor() {
        super();
    }

    render() {
        const actions = this.actions.map((action, index) => {
            return (
                <li className="action"
                    key={index}
                    onClick={() => (action.callback())}>
                    {action.name}
                </li>
            )
        })

        return this.props.currentFarmTile && !this.props.highlight && !this.props.plantId ? (
            <div>
                <ul>{actions}</ul>
            </div>
        ) : ('')
    }
}

const mapToStateProps = state => {
    const highlight = selectHighlight(state);
    const character = selectCharacter(state);
    const farmPlotTileType = selectTileTypeByName(state, tileTypes.FARM_PLOT)
    const currentTiles = selectCurrentTiles(state, character.position)
    const currentFarmTile = currentTiles.byId.find(tile => tile.tileType?.name === tileTypes.FARM_PLOT);
    const plantId = selectPlantInstanceIdAtTile(state, character.position)

    return { currentFarmTile, plantId }
}

export default connect(
    mapToStateProps,
    {
        chooseSeedToPlant,
        removePlotAsync
    }
)(FarmPlotActions);