export const nightStalkerFaceSentences = [
"Startlingly angular face.",
"unsettlingly large eyes.",
"A sharp, hooked nose.",
"Gaunt, sunken cheeks.",
"A wide, lipless mouth.",
"Long, thin fingers.",
"patchy, stringy hair",
"Wicked smile",
"Sharp fangs",
"Hair as black as night",
"Glowing red eyes",
"Creepy as hell",
"Chills your blood",
"Makes your skin crawl",
"pure evil",
"demented grin",
"piercing blue eyes",
"defined cheekbones",
"strong jawline",
"laser-like gaze",
"nightmarish visage",
"pale skin",
"raven black hair",
"ethereal beauty",
"demonic presence",
"haunting aura",
"Sharp features",
"High cheekbones",
"Intense eyes",
"predatory smile",
"Smooth, pale skin",
"sleek, black hair",
"long, slender fingers",
"lithe, agile body",
"disturbing aura",
"unnerving presence",
]

export const nightStalkerBodySentences = [
"Thin and lanky, with long, spindly arms and legs.",
"Its eyes are large and soulless, glowing a sickening yellow-green in the darkness.",
"It stalks its prey silently, moving with an unnatural grace.",
" His body is covered in shadows, making him seem almost unreal.",
" He seems to glide across the ground, making no sound at all.",
" His eyes seem to pierce the darkness, seeing everything.",
"dark and brooding",
"midnight eyes",
"cold and calculating",
"heart of ice",
"a touch of evil",
"secretive and mysterious",
"always in the shadows",
"lurking in the dark",
"never seen in the light",
]

export const nightStalkerSoundWords = [
"Slow",
"Hissing",
"Slithering",
"scratching",
]

export const nightStalkerFurWords = [
    "Sleek", "seductive", "sinuous", "silky", "smooth", "voluptuous", "midnight black", "moonless void", "starless dark",
]

export const nightStalkerSmellWords = [
"Pungent", "rank", "fetid", "foul", "reeking", "putrid", "stinking", "stenchy", "rancid", "revolting",
]

export const nightStalkerBehaviourSentences = [
"It is lurking in the shadows.",
"it is watching you from the shadows",
"it is shimmering in the moonlight.",
]