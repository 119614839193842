export const scrubGrassSmellTypes = [
	"Herbaceous",
    "Bitter",
    "Aromatic",
    "Spicy",
    "pungent",
    "smoky",
    "woody",
    "ashy",
    "resinous",
    "musky",
    "greasy",
    "earthy",
    "of smoke with a hint of sweetness",
    "of meadow",
    "of summer",
    "fresh",
    "green",
    "of algae",
    "of seaweed",
    "of wet",
    "of damp",
    "of mildew",
    "Pungent",
    "Strong",
    "Sharp",
    "Acrid",
    "biting",
    "caustic",
    "corrosive",
    "acrid",
    "pungent",
    "sharp",
    "biting",
    "stinging",
    "irritating",
    "caustic",
    "vitriolic",
    "of an onion wreath",
    "of vinegar",
    "of astringent angelica",
    "of fragrant tree bark",
    "of rubber-encased greenery",
    "of earthy brine",
    "menthol-like",
    "of well orchestrated herbs",
    "of a well-stocked kitchen cupboard",
    "of a woodworker's outfit",
    "of sweet hay",
    "moody",
    "of painful memories",
    "of revisited disdains",
]

export const scrubGrassBranchTypes = [

]

export const scrubGrassLeafTypes = [
    "Stiff",
    "Rough",
    "prickly",
    "dusty",
    "dry",
    "barren",
    "dead",
    "straw-like",
    "unhealthy",
    "unattractive",
    "twisted",
    "dry",
    "barren",
    "spindly",
    "scraggly",
    "pathetic",
    "wheat-coloured",
    "dead",
    "crunchy",
    "miserable",
    "Dry",
    "Parched",
    "Thistly",
    "Tenacious",
    "Tough",
    "Resilient",
    "Hardy",
    "Enduring",
    "Branch",
    "scrappy",
    "dry",
    "dead",
    "brittle",
    "twiggy",
    "spindly",
    "wiry",
    "stringy",
    "Fine",
    "fine-textured",
    "wood",
    "Scruffy",
    "Unkempt",
    "Overgrown",
    "Wild",
    "rugged",
    "hardy",
    "Wiry",
    "Tough",
    "Dry",
    "Brittle",
    "Scratchy",
    "Coarse",
    "Sharp",
    "Itchy",
    "Unpleasant",
    "Uncomfortable",
    "wiry",
    "dry",
    "scratchy",
    "yellowish-green",
    "sharp",
    "brittle",
    "dull",
    "sparse",
    "dead",
    "brown",
    "green",
    "yellowing",
    "sere",
    "dry",
    "wiry",
    "tawny",
    "dry",
    "brown",
    "crunchy",
    "spiky",
    "wiry",
    "stringy",
    "frizzy",
    "coarse",
    "sere",
    "persistent",
    "flexible",
    "slender",
    "glabrous",
    "thread-like",
    "brush-tails atop its long",
    "sun-seeking",
    "young white",
    "clumping",
]