import tileTypes from './local-storage/tile-types.json';
import materialTypes from './local-storage/material-types.json';
import plantTypes from './local-storage/plant-types.json';
import constructionTypes from './local-storage/construction-types.json';
import skillTypes from './local-storage/skill-types.json';
import mineralTypes from './local-storage/mineral-types.json';
import armourTypes from './local-storage/armour-types.json';
import clothingTypes from './local-storage/clothing-types.json';
import jewelleryTypes from './local-storage/jewellery-types.json';
import foodTypes from './local-storage/food-types.json';
import lockTypes from './local-storage/lock-types.json';
import writingSurfaceTypes from './local-storage/writing-surface-types.json';
import writingImplementTypes from './local-storage/writing-implement-types.json';
import metalTypes from './local-storage/metal-types.json';
import keyTypes from './local-storage/key-types.json';
import toolTypes from './local-storage/tool-types.json';
import furnitureTypes from './local-storage/furniture-types.json';
import weaponTypes from './local-storage/weapon-types.json';
import animalTypes from './local-storage/animal-types.json';
import brainchipTypes from './local-storage/brainchip-types.json';
import boatTypes from './local-storage/boat-types.json';
import wagonTypes from './local-storage/wagon-types.json';
import workshopTypes from './local-storage/workshop-types.json';
import tentTypes from './local-storage/tent-types.json';

import plantProducts from './local-storage/plant-products.json';

import armourRecipes from './local-storage/armour-recipes.json';
import clothingRecipes from './local-storage/clothing-recipes.json';
import foodRecipes from './local-storage/food-recipes.json';
import furnitureRecipes from './local-storage/furniture-recipes.json';
import jewelleryRecipes from './local-storage/jewellery-recipes.json';
import lockRecipes from './local-storage/lock-recipes.json';
import materialRecipes from './local-storage/material-recipes.json';
import metalRecipes from './local-storage/metal-recipes.json';
import tileRecipes from './local-storage/tile-recipes.json';
import toolRecipes from './local-storage/tool-recipes.json';
import weaponRecipes from './local-storage/weapon-recipes.json';
import writingImplementRecipes from './local-storage/writing-implement-recipes.json';
import writingSurfaceRecipes from './local-storage/writing-surface-recipes.json';
import constructionRecipes from './local-storage/construction-recipes.json';
import brainchipRecipes from './local-storage/brainchip-recipes.json';
import boatRecipes from './local-storage/boat-recipes.json';
import wagonRecipes from './local-storage/wagon-recipes.json';
import tentRecipes from './local-storage/tent-recipes.json';
import workshopRecipes from './local-storage/workshop-recipes.json';
import workshopLevelRecipes from './local-storage/workshop-level-recipes.json';


export const cachedReducers = {
    'tileTypes': tileTypes,
    'materialTypes': materialTypes,
    'plantTypes': plantTypes,
    'constructionTypes': constructionTypes,
      'skillTypes': skillTypes,
    'mineralTypes': mineralTypes,
    'armourTypes': armourTypes,
    'clothingTypes': clothingTypes,
    'jewelleryTypes': jewelleryTypes,
    'foodTypes': foodTypes,
    'lockTypes': lockTypes,
    'writingSurfaceTypes': writingSurfaceTypes,
    'writingImplementTypes': writingImplementTypes,
    'metalTypes': metalTypes,
    'keyTypes': keyTypes,
    'toolTypes': toolTypes,
    'furnitureTypes': furnitureTypes,
    'weaponTypes': weaponTypes,
    'animalTypes': animalTypes,
    'brainchipTypes': brainchipTypes,
    'boatTypes': boatTypes,
    'wagonTypes': wagonTypes,
    'workshopTypes': workshopTypes,
    'tentTypes': tentTypes,

    'plantProducts': plantProducts,

    'armourRecipes': armourRecipes,
    'clothingRecipes': clothingRecipes,
    'foodRecipes': foodRecipes,
    'furnitureRecipes': furnitureRecipes,
    'jewelleryRecipes': jewelleryRecipes,
    'lockRecipes': lockRecipes,
    'materialRecipes': materialRecipes,
    'metalRecipes': metalRecipes,
    'tileRecipes': tileRecipes,
    'toolRecipes': toolRecipes,
    'weaponRecipes': weaponRecipes,
    'writingImplementRecipes': writingImplementRecipes,
    'writingSurfaceRecipes': writingSurfaceRecipes,
    'constructionRecipes': constructionRecipes,
    'brainchipRecipes': brainchipRecipes,
    'boatRecipes': boatRecipes,
    'wagonRecipes': wagonRecipes,
    'tentRecipes': tentRecipes,
    'workshopRecipes': workshopRecipes,
    'workshopLevelRecipes': workshopLevelRecipes
};

export function saveToLocalStorage(key, state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (e) {
    console.log(e);  
  }
}


export function loadFromLocalStorage() {
  try {
    const serializedState = {};

    Object.keys(cachedReducers).forEach(key => {
    	const item = cachedReducers[key];

    	if (item) {
    		serializedState[key] = item
    	}
    });

    if (!serializedState) return undefined;

    return serializedState;
  }
  catch (e) {
    console.log(e);
  }
}
