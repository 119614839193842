import React from "react";
import { connect } from 'react-redux'

import { selectWritingImplementRecipes } from '../../../redux/selectors';

import { client } from '../../../services/client';

class WritingImplementRecipes extends React.Component {

    render() {
        const writingImplementItems = this.props.writingImplementRecipes
            .filter(toolRecipe => (toolRecipe.writingImplementType?.name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase()) > -1))
            .sort((a, b) => (a.writingImplementType?.name.localeCompare(b.writingImplementType?.name)))
            .map((toolRecipe) => {
                return (
                    <li key={toolRecipe._id}
                        onClick={() => {
                            return (this.props.onItemSelect(toolRecipe))
                        }}>
                        <span className={this.props.selectedItem?._id === toolRecipe._id ? "selected": ""}>{toolRecipe.writingImplementType.name}</span>
                    </li>
                );
            })
        return (
            <ul>{writingImplementItems}</ul>
        )
    }
}

const mapStateToProps = state => {
    const writingImplementRecipes = selectWritingImplementRecipes(state)

    return { writingImplementRecipes }
}

export default connect(
    mapStateToProps,
    {  }
)(WritingImplementRecipes);