export const acidSplashFaceSentences = [
	"it face has a long, sharp beak dripping with acid",
"Its eyes are beady and black, and its slimy, scaly head is covered in sharp spikes",
"it's face is a nightmarish sight, with drooling fangs",
"Vicious beak covered in Acid ",
"Long neck with acidic saliva dripping",
"Beady eyes that seem to melt your soul",
"Feathers that sizzle and burn",
"A body so light it seems to float",
"beady, black eyes",
"a hooked beak",
"brightly colored plumage",
"razor sharp talons",
"a long, sinuous neck",
"a hunched, lanky body",
"bat-like wings",
"a forked tongue",
"beak-like mouth dripping with acid",
"two large, bulbous eyes glaring with hunger",
"long, sinuous neck coiled like a spring",
"talons sharp as blades, seeking to rend and tear",
"Rows of sharp teeth dripping with acidic saliva",
"Bright, beady eyes glaring out from underneath a scaly brow",
"A long, curved beak ready to tear flesh",
"Yellowish, slimy skin stretched tight over razor-sharp bones",
]

export const acidSplashBodySentences = [
"Wings like a hawk",
"Feathers like a peacock",
"neck like a swan",
"Talons like an eagle",
"wickedly curved talons",
"long, sinuous neck",
"powerful wings",
"glittering scales",
"barbed tail",
"Its body is covered in a slick, slimy coating that protects it from acidic attacks",
"Slim body and long neck",
"Scales cover its body",
"Feathers are ruffled and matted, sticking out in all directions",
]

export const acidSplashSoundWords = [
    "Sputtering",
    "Fizzing",
    "Sizzling",
    "Crackling",
    "Popping",
    "Boiling",
    "Bubbling",
    "Burbling",
    "Simmering",
    "Steaming",
    "Hissing",
    "Screeching",
    "Chirping",
    "Cawing",
    "Clucking",
    "Squawking",
    "Cooing",
    "Peeping",
    "Whistling",
    "Trilling",
    "screeching",
    "cawing",
    "chirping",
    "trilling",
    "warbling",
    "cooing",
    "gurgling",
    "tweeting",
    "cheeping",
    "piping",
    "Chirping",
    "Trilling",
    "Chattering",
    "Chittering",
    "Churring",
    "Cooing",
    "cooing",
    "Murmuring",
    "warbling",
    "tweeting",
    "Hissing",
    "screeching",
    "cawing",
    "chirping",
    "tweeting",
    "cooing",
    "trilling",
    "warbling",
    "singing",
    "whistling"
]

export const acidSplashFurWords = [
	    "Phosphorescent",
    "glimmering",
    "iridescent",
    "shimmering",
    "transparency",
    "ethereal",
    "light",
    "color",
    "delicate",
    "fragile",
    "glistening",
    "scaly",
    "sharp",
    "slimy",
    "bumpy",
    "leathery",
    "tough",
    "sticky",
    "soft",
    "velvety",
    "slimy",
    "bumpy",
    "veiny",
    "scaly",
    "dry",
    "flaky",
    "itchy",
    "bumpy",
    "scaly",
    "flaky",
    "Slimy",
    "Sticky",
    "Wet",
    "Gooey",
    "Dripping",
    "Oozing",
    "Slippery",
    "Nasty",
    "Vile",
    "Disgusting"
]

export const acidSplashSmellWords = [
	"Sour",
"Sharp",
"Pungent",
"Foul",
"Rancid",
"Ammonia-like",
"Choking",
"Gagging",
"Eye-watering",
"Acrid",
"Putrid",
"Rotten",
"Decaying",
"Stinking",
"Foul",
"Nauseating",
"Repellent",
"Disgusting",
"Vomit-inducing",
"Inhumane",
"Pungent",
"Sharp",
"Vinegary",
"Sour",
"Acrid",
"Caustic",
"Fumes",
"Ammonia",
"Chlorine",
"Sulfur",
"Pungent",
"acrid",
"Ammonia-like",
"rank",
"fetid",
"reeking",
"putrid",
"stinking",
"foul",
"nauseating",
"Pungent",
"Foul",
"Rancid",
"putrid",
"Offensive",
"Repulsive",
"Nauseating",
"Disgusting",
"Revolting",
"Ill-scented"
]

export const acidSplashBehaviourSentences = [
	"it is screeching and flapping its wings erratically",
"It's big beak is snapping open and shut rapidly",
"It's making a strange, gibbering noise",
"Its eyes are flashing an eerie green colour",
"it is watching us with its beady little eyes",
"it is making strange little noises",
"it is flapping its wings wildly",
"it is flying erratically around the room",
"it is watching you with its beady little eyes",
"it is making strange clicking noises with its beak",
"it is flapping its wings slowly and menacingly",
"it is stalking towards you with single-minded",
"it is watching us with its beady eyes",
"it is making strange clicking noises",
"it is flapping its wings impatiently",
"it is leaning forward aggressively",
"it is licking its lips",
"it is staring at me with its beady eyes",
"it is tilting its head curiously",
"it is making strange cooing noises",
"it is ruffling its feathers menacingly",
]