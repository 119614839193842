import React from "react";
import { connect } from 'react-redux'

import store from '../../../redux/store';
import { disableKeyboardMovement, enableKeyboardMovement } from '../../../redux/actions/keyboard-shortcuts.actions';
import { selectMessages, selectCharacter } from '../../../redux/selectors';

import { client } from '../../../services/client';

import Menu from '../menu/Menu';

// import './Menu.css';

class TreeMenu extends React.Component {
	state = {
		selectedOuterOption: 0,
		isParentTreeShowing: true,
		isResetChildMenu: false
	}

    correctScrollPosition() {
        if (!this.props.menuContainer) {
            return;
        }


        const highlighted = document.getElementsByClassName('selected')[0]

        const messagesList = document.getElementsByClassName(this.props.menuContainer)[this.props.menuIndex || 0]

        if (!messagesList) {
            return;
        }

        const isElementAboveTop = highlighted?.offsetTop - (this.props.offsetTop || 0) < messagesList.offsetTop + messagesList.scrollTop
        const isElementBelowBottom = highlighted?.offsetTop + highlighted.offsetHeight + (this.props.offsetTop || 0) > messagesList.offsetTop + messagesList.offsetHeight + messagesList.scrollTop

        if (isElementAboveTop) {
            messagesList.scrollTop = highlighted?.offsetTop - (messagesList.offsetTop || 10);
        }

        if (isElementBelowBottom) {
            messagesList.scrollTop = highlighted?.offsetTop - (messagesList.offsetTop || 10);
        }
    }

    onOptionHighlighted(optionIndex, optionParent) {
        if (this.props.optionChosen) {
            this.props.optionChosen(optionParent.childOptions[optionIndex])
        }

    	this.setState({
    		isParentTreeShowing: false,
    		innerOption: optionIndex
    	})
    }

    onMenuBottomReached(optionIndex, optionParent) {
    	if (this.state.selectedOuterOption === this.props.options.length - 1) {
    		return;
    	}

    	this.setState({
    		selectedOuterOption: this.state.selectedOuterOption + 1,
    		isParentTreeShowing: true
    	})
    }

    onMenuTopReached(optionIndex, optionParent) {
    	this.setState({
    		isParentTreeShowing: true
    	})
    }

    submitHandler = (event) => {
    	if (this.props.isDisabled) {
    		return;
    	}

        if (event.key === 'ArrowDown') {
        	if (this.state.isParentTreeShowing) {
        		this.setState({
        			isParentTreeShowing: false
        		})
        	}

            setTimeout(() => (this.correctScrollPosition()));

        	return;
        }

        if (event.key === 'ArrowUp') {
        	if (this.state.isParentTreeShowing) {

        		if (this.state.selectedOuterOption === 0) {
        			return;
        		}

        		this.setState({
        			isParentTreeShowing: false,
        			selectedOuterOption: this.state.selectedOuterOption - 1
        		})
        	}

            setTimeout(() => (this.correctScrollPosition()));

        	return;
        }

        const eventKey = (event.key === ' ') ? 'SPACE' : event.key;
        const outerOption = this.props.options[this.state.selectedOuterOption]

        const actions = outerOption?.actions

        if (actions && actions[eventKey]) {
        	const innerOption = this.state.innerOption > outerOption.childOptions.length - 1 ? outerOption.childOptions.length - 1 : this.state.innerOption
        	const selectedOption = outerOption.childOptions[innerOption];

        	actions[eventKey].callback(selectedOption)
        } else {
        	this.optionChosen(event.key);
        }
    };

    findChosenOption(input) {
        return this.props.options.find((option, index) => (option.shortcut === input || (!option.shortcut && index + 1 === Number(input))))
    }

    constructor() {
        super();
        this.state = { selectedOuterOption: 0 };

        document.addEventListener('keydown', this.submitHandler)
    }

    componentDidMount() {
    	this.setState({
    		selectedOuterOption: 0,
			isParentTreeShowing: true
    	})
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.submitHandler);
    }

    static getDerivedStateFromProps(props, state) {
    	if (props.options?.length > 0 && state.selectedOuterOption > props.options?.length - 1) {
            state.selectedOuterOption = props.options?.length - 1
            state.isParentTreeShowing = false
            state.isResetChildMenu = true;
        } else {
        	state.isResetChildMenu = false;
        }

        return state;
    }

    optionChosen(shortcut) {
    	this.props.options.forEach((option, index) => {

    		if (option.shortcut === shortcut) {
    			this.setState({
    				isParentTreeShowing: true,
    				selectedOuterOption: index
    			})
    		}

    		if (option.childOptions.find(childOption => childOption.shortcut === shortcut)) {
    			this.setState({
    				isParentTreeShowing: false,
    				selectedOuterOption: index
    			})
    		}
    	})
    }

    render() {
    	const options = this.props.options?.map((option, index) => {
    		if (!option || !option.childOptions || option.childOptions.length === 0) {
    			return;
    		}

            let className = '';

            if (option.indentation) {
                className += `indentation-${option.indentation}`
            } else {
                className += "indentation-0"
            }

            if (option.className) {
                className += ' ' + option.className;
            }

    		return (
    			<li key={index} className={!this.props.isDisabled && this.state.selectedOuterOption === index && this.state.isParentTreeShowing ? `${className} selected` : `${className}`}>
    				<span className={this.props.isShortcutHidden ? "hidden" : "shortcut"}>{option.shortcut || index + 1}. </span>
                    <span>{option.text} </span>

                    <Menu options={option.childOptions}
                    	isDisabled={this.props.isDisabled || this.state.selectedOuterOption !== index || this.state.isParentTreeShowing}
                    	isResetChildMenu={this.state.isResetChildMenu}
                    	isParentTreeShowing={!this.props.isDisabled && this.state.isParentTreeShowing}
                    	highlightedOption={(optionIndex) => (this.onOptionHighlighted(optionIndex, option))}
                    	bottomOfOptionsNavigation={(optionIndex) => (this.onMenuBottomReached(optionIndex, option))}
                    	topOfOptionsNavigation={(optionIndex) => (this.onMenuTopReached(optionIndex, option))}
                    	isEndOfList={this.state.selectedOuterOption === this.props.options.length - 1}
                        tradeIcon="hand"
	                />
                </li>
			)
    	})

    	let actions = ('');

    	if (this.props.options && this.props.options[this.state.selectedOuterOption]?.actions) {
    		actions = Object.keys(this.props.options[this.state.selectedOuterOption]?.actions).map((actionKey, index) => {
    			const action = this.props.options[this.state.selectedOuterOption]?.actions[actionKey]
    			return (
    				<span className="action-shortcut" key={index}><span className="shortcut">{actionKey}</span> {action.text}</span>
				)
    		})
    	}

        return (
            <div className="menu-container">
                <ol className="menu-items">

                    <li className={
                            !this.props.container ? "hidden" :
                            !this.props.isDisabled && this.state.selectedOuterOption === -1 && this.state.isParentTreeShowing ? `container-menu selected` : `container-menu`
                    }>
                        {/*<span className={this.props.isShortcutHidden ? "hidden" : "shortcut"}>{option.shortcut || index + 1}. </span>*/}
                        <span>{this.props.container?.tileType?.name} </span>

                        {/*<Menu options={option.childOptions}
                            isDisabled={this.props.isDisabled || this.state.selectedOuterOption !== index || this.state.isParentTreeShowing}
                            isResetChildMenu={this.state.isResetChildMenu}
                            isParentTreeShowing={!this.props.isDisabled && this.state.isParentTreeShowing}
                            highlightedOption={(optionIndex) => (this.onOptionHighlighted(optionIndex, option))}
                            bottomOfOptionsNavigation={(optionIndex) => (this.onMenuBottomReached(optionIndex, option))}
                            topOfOptionsNavigation={(optionIndex) => (this.onMenuTopReached(optionIndex, option))}
                            isEndOfList={this.state.selectedOuterOption === this.props.options.length - 1}
                        />*/}
                    </li>

                	{options}
                </ol>

                <div className={this.props.isDisabled ? "hidden" : "menu-actions"}>
                	{actions}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    

    return {  }
}

export default connect(
    mapStateToProps,
    { }
)(TreeMenu);