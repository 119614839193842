import {
    GET_WRITING_SURFACE_RECIPES_LOADING,
    GET_WRITING_SURFACE_RECIPES_SUCCESS,
    GET_WRITING_SURFACE_RECIPES_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';

export const getWritingSurfaceRecipesSuccess = payload => ({
    type: GET_WRITING_SURFACE_RECIPES_SUCCESS,
    payload
})

export const getWritingSurfaceRecipesFailure = () => ({
    type: GET_WRITING_SURFACE_RECIPES_FAILURE
})

export const getWritingSurfaceRecipesAsync = payload => dispatch => {
    return dispatch(getRecursivelyAsync('writing-surface-recipes', getWritingSurfaceRecipesSuccess, 0));
}