export const blackBearFaceSentences = [
"Hardy and handsome",
"Majestic and muscular",
"Stunning and stately",
"poised and powerful",
"Elegant and intense",
"dignified and enduring",
"peerless and proud",
"A grizzled face with a long snout.",
"A face with a big, furry muzzle.",
"A face with big, round eyes.",
"A face with big, furry ears.",
"A face with a big, furry nose.",
"A big, fluffy purple bear face with a small, black nose.",
"A purple bear face with big, black eyes and a big, black nose.",
"A big, purple bear face with a small, black nose and big, black eyes.",
]

export const blackBearBodySentences = [
"Vibrant fur that's soft to the touch ",
"Intimidatingly large body ",
"Sharp white teeth that can inflict serious damage ",
"its body was covered in a soft, furry coat.",
"its body was big and chubby.",
"its body was small and delicate.",
"A big, furry bear with big, floppy ears.",
]

export const blackBearSoundWords = [
    "Hissing",
    "Whimpering",
    "Snarling",
    "Roaring",
    "Growling",
    "Ursine",
    "Bellowing",
    "ominous",
    "Whirring",
    "growling",
    "rumbling",
    "tumbling",
    "mumbling",
    "grumbling",
    "shuffling",
    "snoring",
    "grumbling",
    "mumbling",
    "whining",
    "groaning",
    "whimpering",
    "moaning",
    "hooting",
    "bellowing",
    "growling",
    "roaring",
    "growling",
    "snarling",
    "clawing",
    "scraping",
    "sniffing",
    "scratching",
    "digging",
    "licking"
]

export const blackBearFurWords = [
    "Silky",
    "Downy",
    "Velvety",
    "Furrowed",
    "Textured",
    "Whorled",
    "Gleaming",
    "Luminous",
    "Translucent",
    "Diaphanous",
    "Lavender",
    "Lilac",
    "Violet",
    "Royal",
    "Majestic",
    "Mysterious",
    "Regal",
    "Enchanted",
    "Magical",
    "bewitching",
    "Saturated",
    "royal",
    "deep",
    "eggplant",
    "vibrant",
    "luxurious",
    "rich",
    "composite",
    "opulent",
    "regal",
    "Vibrant",
    "Regal",
    "Majestic",
    "Noble",
    "Prideful",
    "stately",
    "Elegant",
    "Refined",
    "Graceful",
    "Flawless",
    "luminous",
    "iridescent",
    "sheen",
    "opalescent",
    "sleek",
    "satiny",
    "lustrous",
    "glistening",
    "shimmering",
    "gloss"
]

export const blackBearSmellWords = [
    "Sugary",
    "grape",
    "floral",
    "perfumed",
    "musky",
    "pungent",
    "sharp",
    "sweet",
    "tangy",
    "Sauvignon",
    "elderflower",
    "jasmine",
    "violets",
    "lime",
    "grapefruit",
    "kiwi",
    "passionfruit",
    "lychee",
    "guava",
    "sweet",
    "powdery",
    "floral",
    "musky",
    "vanilla",
    "perfumed",
    "citrusy",
    "amber",
    "woody",
    "honey",
    "Pungent",
    "musky",
    "sweet",
    "floral",
    "fruity",
    "earthy",
    "sharp",
    "rich",
    "deep",
    "intense",
    "Powdery",
    "Floral",
    "Sweet",
    "Fruity",
    "Musky",
    "Earthy",
    "Woody",
    "Spicy",
    "Aromatic",
    "Fragrant"
]

export const blackBearBehaviourSentences = [
"it is making low grunting noises.",
"it is standing on its hind legs.",
"it is swatting at the air.",
"it is glaring menacingly",
"it is baring its teeth",
"it is sniffing the air",
"it is pawing the ground",
"it is looking at you with its weird purple eyes",
"it is drooling all over its purple fur",
"it is making weird grunting noises",
"it is stomping its feet aggressively",
"it is watching you with its beady purple eyes",
]