export const lusciousBushSmellTypes = [
	"Vinegary",
    "Pungent",
    "Earthy",
    "Dank",
    "Musty",
    "Fetid",
    "Rotting",
    "Decaying",
    "Stinking",
    "of mock orange",
    "fragrant",
    "sweet",
    "intoxicating",
    "heady",
    "flowery",
    "rich",
    "thick",
    "heavy",
    "pungent",
    "perfumed",
    "Sweet",
    "Fruity",
    "Floral",
    "Woodsy",
    "Spicy",
    "Pungent",
    "Aromatic",
    "Earthy",
    "Fragrant",
    "Aromatic",
    "tantalizing",
    "Mouth-watering",
    "of planty musk",
    "of bitter chinotto",
    "of a carpenter's glaze",
    "of galbanum",
    "of oversteeped green leaves",
    "of an apothecary",
    "of frostbitten vegetal matter",
    "of damaged boxwood branches",
    "timid",
    "of leaving with few possessions",
    "of communal fears",
    "of quiet greens",
    "of egg whites and citrus",
    "of warm lily-of-the-valley",

]

export const lusciousBushBranchTypes = [
	"Vibrant",
    "green",
    "healthy",
    "full",
    "thick",
    "sprawling",
    "verdant",
    "leafy",
    "juicy",
    "luscious",
    "Thick",
    "Lush",
    "verdant",
    "exuberant",
    "ample",
    "fruitful",
    "bountiful",
    "burgeoning",
    "proliferating",
    "luxurious",
    "Bushy",
    "Lush",
    "Thick",
    "Dense",
    "Full",
    "Proud",
    "Verdant",
    "Lively",
    "Vibrant",
    "Robust",
    "full",
    "green",
    "leafy",
    "plush",
    "robust",
    "healthy",
    "thick",
    "verdant",
    "vigorous",
    "luxurious",
    "lush green",
    "leafy green",
    "moss-cast",
    "fruitful",
    "rich",
]

export const lusciousBushLeafTypes = [
	"elaborate",
    "vibrant",
    "a delicate texture to its",
    "silky feeling",
    "luxurious looking",
    "appealing",
    "sensual",
    "inviting",
    "alluring",
    "verdure",
    "Lively",
    "Verdant",
    "Lush",
    "Dense",
    "Boisterous",
    "Flourishing",
    "Abundant",
    "Vigorous",
    "Robust",
    "healthy",
    "green",
    "lush",
    "leafy",
    "fresh",
    "overflowing",
    "bountiful",
    "prodigious",
    "abounding while seemingly redundant",
    "luxuriant",
    "Lush",
    "tender",
    "Radiant",
    "Vivid",
    "Vibrating",
    "Flourishing",
    "sapphire",
    "blue",
    "turquoise",
    "blue-green",
    "shiny",
    "moist",
    "quivering",
    "ready",
    "shiny",
    "glistening",
    "glimmering",
    "shining",
    "dewed",
    "rude",
]