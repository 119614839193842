import { GET_SKILL_TYPES_SUCCESS, GET_SKILL_TYPES_FAILURE } from "../actionTypes";

const initialState = []

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SKILL_TYPES_SUCCESS: {

      console.log('skill types :', action.payload);
      return [
        ...state,
        ...action.payload
      ]
    }

    default:
      return state;
  }
}
