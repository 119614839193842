export const bananaSmellTypes = [
    "Appetizing",
    "Sweet",
    "Ripe",
    "Fresh",
    "Juicy",
    "Aromatic",
    "of a clearing in a field",
    "of summer",
    "of river",
    "exotic",
    "fruity",
    "tropical",
    "sweet",
    "yellow",
    "Tropical",
    "Sweet",
    "Creamy",
    "Aromatic",
    "sweet",
    "of dessert",
    "weathered",
    "overripe",
    "pungent",
    "musky",
    "gassy",
    "of artificial sweetness",
    "of summer jubilation",
    "mouthwatering",
    "offputting, of the candied sweets you avoided",
    "of pungent fruit",
    "ephemerally artificial",
    "creamy with notes of rum",
    "of cloying vanilla",
    "cheeky",
    "of a hot summer's day",
    "of sweet relief in the summer",
    "of a powdery candy shell",
    "of smoky vanilla",
    "of clover and fruit",
    "of obnoxiously mixed drinks",
    "of frothy mashed fruit",
    "of a fleeting memory of the seaside",
    "of old, melted wax",
    "of splashy fruits",
    "playful",
    "of cardboard",
    "of teeth-shattering boiled sweets",
    "of soft rot",
    "of burnt hair",
]

export const bananaBranchTypes = [
    "clumps of long fruits under its",
    "blue oblong jewels adorning its",
    "twisted grey",
    "grey",
    "steel-coloured",
    "pipeline",
    "duct-coloured",
    "soot-stained",
    "clamshell coloured",
    "starchy, uncomplicated fruit under its",
    "a stack of fruits in the eaves of its",
    "creature paths along its",
    "treetop fauna pathways along its",
    "elongated soft fruits in the eaves of its",
    "hanging tiered clusters of fruits under its",
    "tall",
    "textured",
    "insect covered",
    "pseudo-stem",
    "tree-appropriating",
    "clustering fruits hanging from its"
]

export const bananaLeafTypes = [
    "green",
    "sheathed",
    "Fresh",
    "Bright",
    "Huge",
    "Lush",
    "Long",
    "Thick",
    "Vibrant",
    "healthy",
    "Large",
    "lush",
    "long",
    "broad",
    "veiny",
    "smooth",
    "waxy",
    "fragrant",
    "furled",
    "rolled up",
    "spirally arranged",
    "spear-shoot",
    "stalked",
    "frond",
    "arms-length",
    "sword-length",
    "fan frond",
    "ovate",
]