export const poisonerFaceSentences = [
"Slitted, yellow eyes",
"Sharp teeth",
"Long, curved claws",
"Slimy, writhing tongue",
"Putrid breath",
"foul odor",
"oozing sores",
"Rotted flesh",
"its eyes are cold and calculating, sizing up their next victim.",
"A pale, gaunt face with sunken eyes and a wicked smile.",
"A face covered in boils and sores, with a cruel gleam in its eye.",
"A slimy face with sharp teeth and a forked tongue.",
"Gaunt and grim, with eyes that seem to gleam with malicious glee.",
"Sharp features screwed up in a delighted grin",
"Cold, calculating eyes that seem to take in everything",
"Ferocious fangs bared in a menacing snarl.",
"Ichor dribbling from its razor-sharp claws.",
"Gaping maw filled with sharp teeth, dripping with venom.",
"Narrow, slitted eyes gleaming with malice.",
]

export const poisonerBodySentences = [
"Nauseating odour",
"Vomit-inducing sight",
"Pustules and sores",
"Rotten teeth",
"Gaping maw",
"Claws dripping with venom",
"Bulging, bloodshot eyes",
"Rotten to the core, this creature exudes filth and disease",
"Swarms of flies buzz around it",
"Noxious fumes emanating from its body",
"Vile toxins oozing from its pores",
"repulsive smell of rotting flesh",
"Gaping maw full of sharp teeth",
"Sneering countenance full of malevolence",
"it has a vile aura that seems to poison the air around him.",
"its eyes are black pits that seem to suck the life out of anything they gaze upon.",
]

export const poisonerSoundWords = [
"Guttural",
"Raspy",
"Wet",
"Slithery",
"Venomous",
"hissing",
"cackling",
"grating",
"menacing",
"fiendish",
"hissing",
"laughing",
"cackling",
"Oozing",
"mucous-y",
"gurgling",
"bubbling",
"malodorous",
"cloying",
"putrid",
"Cackling",
"Giggling",
"Wheezing",
"Choking",
"Gasping",
"Strangling",
"Rasping",
"Croaking",
"Labored breathing",
"Wheezing",
"Sneaky",
"Hissing",
"Creeping",
"Stalking",
"Slithering",
"Slinking",
"Crawling",
"Scuttling",
"Scurrying",
"Prowling",
"Choking",
"Gasping",
"Wheezing",
"Coughing",
"hacking",
"Clutching",
"Struggling",
"Grasping",
"Straining",
"Dying",
]

export const poisonerFurWords = [
    "Speckled",
    "Slimy",
    "Bumpy",
    "Gumpy",
    "Velvety",
    "Oily",
    "Scaly",
    "Slimy",
    "Spotted",
    "Warty",
    "Hairy",
    "Fuzzy",
    "Knobby",
    "Tufty",
    "dry",
    "leathery",
    "bumpy",
    "scaly",
    "flaky",
    "crusty",
    "brittle",
    "calloused",
    "warty",
    "oily",
    "scaly",
    "slimy",
    "bumpy",
    "porous",
    "spongy",
    "brittle",
    "jagged",
    "fibrous",
    "crusty"
]

export const poisonerSmellWords = [
        "Pungent",
    "Acrid",
    "Rancid",
    "Foul",
    "nauseating",
    "putrid",
    "toxi",
    "infected",
    "poisonous",
    "deadly",
    "Nauseating",
    "cloying",
    "stifling",
    "acrid",
    "putrid",
    "fetid",
    "rank",
    "reeking",
    "malodorous",
    "noisome",
    "Pungent",
    "acrid",
    "noxious",
    "rank",
    "fetid",
    "foul",
    "reeking",
    "stinking",
    "putrid",
    "malodorous",
    "revolting",
    "acrid",
    "putrid",
    "foul",
    "stinking",
    "rank",
    "ill",
    "pungent",
    "nauseating",
    "sickening",
    "rancid",
    "pungent",
    "rank",
    "fetid",
    "malodorous",
    "putrid",
    "stinking",
    "reeking",
    "fetid"
]

export const poisonerBehaviourSentences = [
"it is staring at you with its beady eyes",
"it is swaying back and forth like it's ready to pounce",
"it is baring its sharp teeth at you",
"it is licking its lips hungrily",
"it is eyeing you suspiciously",
"it is licking its lips",
"it is twitching its whiskers",
"it is flicking its tongue",
"it is baring its teeth",
"always watching you",
"never blinking",
"unnaturally still",
"exuding an aura of menace",
"licking its lips hungrily",
"drooling green venom",
"hissing menacingly",
"it is huge",
"it is very aggressive",
"it is covered in spikes",
"it is foaming at the mouth",
]