import React from "react";
import { connect } from 'react-redux'

import { selectClothingRecipes } from '../../../redux/selectors';

import { client } from '../../../services/client';

class ClothingRecipes extends React.Component {

    render() {
        const listItems = this.props.clothingRecipes
            .filter(clothingRecipe => (clothingRecipe.clothingType.name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase()) > -1))
            .sort((a, b) => (a.clothingType?.name.localeCompare(b.clothingType?.name)))
            .map((clothingRecipe) => {
                return (
                    <li key={clothingRecipe._id}
                        onClick={() => {
                            return (this.props.onItemSelect(clothingRecipe))
                        }}>
                        <span className={this.props.selectedItem?._id === clothingRecipe._id ? "selected": ""}>
                            {clothingRecipe.clothingType.name}
                        </span>
                    </li>
                );
            })
        return (
            <ul>{listItems}</ul>
        )
    }
}

const mapStateToProps = state => {
    const clothingRecipes = selectClothingRecipes(state)

    return { clothingRecipes }
}

export default connect(
    mapStateToProps,
    {  }
)(ClothingRecipes);