export const wheatSmellTypes = [
    "of straw",
    "of hay",
    "rancid",
    "sweaty",
    "sour",
    "golden",
    "mellow",
    "nutty",
    "toasty",
    "of grain",
    "of bread",
    "of cereal",
    "of straw",
    "of hay",
    "golden",
    "of summer",
    "of harvest",
    "of grains",
    "of cereal",
    "of bread",
    "of yeast",
    "malty",
    "nutty",
    "toasty",
    "warm",
    "of tickling pepper",
    "milky and woody",
    "lazily lactonic",
    "of mixed feelings",
    "creamy and round",
    "sharp and peppery",
    "of minty oils",
    "of fresh cream",
    "of damp clothing",
    "of rotten milk",
    "nippy and creamy",
    "comforting and nutty",
    "warm and unassuming",
    "of wet and rotten rolls",
    "of radiant fireplaces",
    "woody and unobtrusive",
    "bright and joyful",
    "of frothing milk",
    "chemical and sharp",
    "of a distant bakery",
    "of autumnal woodlands",
    "of spoiled milk and pepper",
    "of a milky embrace",
    "of roasting animal fat",
    "comforting and sensual",
    "of worried stems",
]

export const wheatBranchTypes = [
	"stalk",
    "golden",
    "golden",
    "sturdy",
    "thick",
    "thin",
    "long",
    "short",
    "stiff",
    "crimped"
    "delicate, ancient",
    "roots that speak of history and spiked",
    "resilient roots and awned",
    "hulled ears and nutritious",
    "mountain-bred",
    "seeds with mass appeal and tall",
    "a toughened rachis and pliant",
    "non-shattering spikelets and flagstaff",
    "sterile and telescoping",
    "deep roots and energy stored in its thin",
    "drought-resistant",
]

export const wheatLeafTypes = [
]