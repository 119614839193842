export const junglePlantOneSmellTypes = [
    "of teak",
    "of banyan",
    "of stinking iris",
    "of pungent weeds",
    "of putrid flowers",
    "of rotting leaves",
    "of moldy branches",
    "of dank moss",
    "of sweltering heat",
    "of humid air",
    "of grizzly moss",
    "of skunk orchid",
    "of moldy toadstools",
    "of oozing ferns",
    "of sticky sap",
    "of rotting logs",
    "of hissflower",
    "of strangleweed",
    "of sacred lotus",
    "of snapdragon vine",
    "of dramatic darkness",
    "of blazing earthy vapours",
    "of benzoin",
    "of idle conversations",
    "steely and cidery",
    "peppery green",
    "of water sports",
    "of a woody terroir",
    "of an inkpress",
    "of tonka and cypress",
    "sharp and woody",
    "of sweet hair oils",
    "sickly sweet",
    "of nasty abroxan",
    "of pungent soured vegetable",
    "repulsive",
    "of verdant tranquility",
    "friendly",
    "of trees and candied citrus",
    "of warm circuits",
    "of weeds and naphthalene",
    "of sweet petrichor",
    "of charred meat",
    "of summer giving way to autumn",
    "of woods by the lake",
    "of a washed-out mind",
]

export const junglePlantOneBranchTypes = [
	"metallic",
    "glinting",
    "shiny",
    "stiff",
    "hard",
    "insect covered",
    "small hairs growing on its",
    "creepers tugging at its",
    "tangled",
    "dewy",
    "nest-ridden",
    "flowering",
    "itchy fronds on its",
    "mud-breaking roots and thick",
    "knobbly bark on its",
    "dappled sunlight playing on its",
    "aerial roots hanging from its",
    "coveting",
]

export const junglePlantOneLeafTypes = [
	"Curly long",
    "lizard-tail",
    "richly green",
    "huge fan",
    "rustling",
    "giant",
    "colossal",
    "forked",
    "enormous",
    "Tangled",
    "Dense",
    "Lush",
    "Green",
    "Vibrant",
    "Wild",
    "hungry",
    "Untamed",
    "bladed",
    "yellow-green",
    "wavy",
    "stringy",
    "tattered",
    "translucent",
    "veiny",
    "sticky",
    "blight covered",
    "fungus ridden",
    "fanged",
    "mire-marked",
    "boggy",
    "mud-stained",
    "muckroot",
    "persistent",
    "clumping",
    "Dewy",
    "Bushy",
    "moss-bitten",
    "sun-shy",
]