import React from "react";
import { connect } from 'react-redux'

import { selectLockRecipes } from '../../../redux/selectors';

import DirectionInput from '../../utils/direction-input/DirectionInput';

import { client } from '../../../services/client';

class LockRecipes extends React.Component {

    constructor() {
        super();
        this.state = { isDirectionInputShowing: false }
    }

    async lockClicked(lockRecipe) {
        let direction = undefined;

        if (lockRecipe.isBarricade) {
            direction = await this.getDirection();
        }

        return (this.props.onItemSelect({ ...lockRecipe, direction }))
    }

    getDirection() {
        this.setState({ isDirectionInputShowing: true });

        return new Promise((resolve, reject) => {
            this.onDirectionSupplied = (direction) => {
                this.setState({isDirectionInputShowing: false})
                resolve(direction);
            }

            this.onDirectionClosed = () => {
                this.setState({isDirectionInputShowing: false})
                reject('No direction supplied');
            }
        });
    }

    render() {
        const listItems = this.props.lockRecipes
            .filter(lockRecipe => (lockRecipe.lockType?.name.toLowerCase().indexOf(this.props.searchTerm.toLowerCase()) > -1))
            .sort((a, b) => (a.lockType?.name.localeCompare(b.lockType?.name)))
            .map((lockRecipe) => {
                return (
                    <li key={lockRecipe._id}
                        onClick={() => {
                            this.lockClicked(lockRecipe);
                        }}>
                        <span className={this.props.selectedItem?._id === lockRecipe._id ? "selected": ""}>{lockRecipe.lockType?.name}</span>
                    </li>
                );
            })
        return (
            <div>
                <ul>{listItems}</ul>
                {this.state.isDirectionInputShowing &&
                    <DirectionInput
                        text={"Side of the door which is 'outside':"}
                        onDirectionSupplied={this.onDirectionSupplied}
                        onDirectionClosed={this.onDirectionClosed}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    const lockRecipes = selectLockRecipes(state)

    return { lockRecipes }
}

export default connect(
    mapStateToProps,
    {  }
)(LockRecipes);