import { GET_LOCK_RECIPES_SUCCESS } from "../actionTypes";

const initialState = []

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_LOCK_RECIPES_SUCCESS: {
      const lockRecipes = action.payload;

      if (!lockRecipes){
        return {...state};
      }
      return [...state, ...action.payload]
    }

    default:
      return state;
  }
}
