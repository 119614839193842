import {
    GET_ARMOUR_RECIPES_LOADING,
    GET_ARMOUR_RECIPES_SUCCESS,
    GET_ARMOUR_RECIPES_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';

import { client } from '../../services/client';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';

export const getArmourRecipesSuccess = payload => ({
    type: GET_ARMOUR_RECIPES_SUCCESS,
    payload
})

export const getArmourRecipesFailure = () => ({
    type: GET_ARMOUR_RECIPES_FAILURE
})

export const getArmourRecipesAsync = payload => dispatch => {
    return dispatch(getRecursivelyAsync('armour-recipes', getArmourRecipesSuccess, 0));
}