export const palmBushSmellTypes = [
    "coconutty and sweet",
    "of Tar",
    "of Soot",
    "of Pitch",
    "of Resin",
    "of Ambergris",
    "of Frankincense",
    "of tangerine",
    "of jasmine",
    "of lotus",
    "wild",
    "of dilly fruit",
    "of shore pines",
    "soapy",
    "complex",
    "of leaking sunshine",
    "of steam from a brewed beverage",
    "sharp and crisp",
    "nostril-burning",
    "of fresh fruit",
    "of undrinkable water",
    "of sweet citrus",
    "chlorinated",
    "invigorating",
    "of sweaty fruit",
    "of life in a wooden coffin",
    "sublime",
    "bourgeouise",
    "of fixed, fast veneration",
    "of everlasting disturbances",
    "of venerable prejudice",
    "of solid melting into air",
    "unsurprisingly fruity",
    "of no alternative system",
    "of vacuous promises",
    "of dwelt devils",
    "of valorised evils",
    "of pears for dreaming",
    "of febrile fruit",
    "of a wounded world",
    "of salvaged hopes",
    "as if it's grown on rotten bones",
    "of necessary hate",
    "of prophesised sweetness",
    "of hallucinated enemies",
    "of stagnant energy",
    "of damp discarded armour",
    "of radish and sweetcorn",
    "refreshing",
    "of ripe fruits on the breeze",
    "of choking peach",
]

export const palmBushBranchTypes = [
    "dark green",
    "pointed",
    "smooth",
    "supple",
    "wrinkled",
    "smooth",
    "leaf-scarred",
    "frond-like",
    "thick",
    "juicy",
    "waxy",
    "hard",
    "pointed",
    "aerial roots and a spark of",
    "ringed",
    "long",
    "prickly",
    "smooth",
    "thin",
    "wavy",
    "supple",
    "fragrant",
    "bendable",
    "smooth",
    "pointed",
    "massive",
    "succulent",
    "long",
    "straight",
    "thin",
    "fragile",
    "brittle",
    "green",
    "oval",
    "smooth",
    "supple",
    "tender",
    "thick",
    "thin",
    "whispering",
    "young",
    "clumped",
    "gerontic",
    "corky",
    "upright",
    "upwardly-curving",
    "starchy",
    "sap-dripping",
    "spirally arranged",
]

export const palmBushLeafTypes = [
    "oval",
    "pointy",
    "scaled",
    "long",
    "thin",
    "papery",
    "droopy",
    "shiny",
    "broad",
    "ovoid",
    "pointed",
    "feather-veined",
    "segmented",
    "spear",
    "javelin",
    "pointed",
    "smooth",
    "glossy",
    "pleated",
    "sheathed",
    "veined",
    "pinnate",
    "spined",
    "conspicuously veined",
    "oval",
    "erect",
    "shooting",
    "pinnate",
    "greenish-white",
    "bladed",
    "long",
    "plumose",
    "waxy",
    "pointed",
    "tapering",
    "rustling",
    "filtered",
    "thick",
    "sagging",
    "glossy scaled",
    "compound",
    "spine-sheathed",
    "scaled-fruits drooping under its",
    "orange fruits beneath its",
    "slightly back-bended",
    "spiny",
]