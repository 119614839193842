export const bambooSmellTypes = [
    "of fresh paper",
    "of charcoal",
    "Fresh",
    "Green",
    "Clean",
    "damp",
    "woody",
    "cloying",
    "sweet",
    "somewhat musky",
    "pungent",
    "bitter",
    "acrid",
    "of wet leaves",
    "of damp soil",
    "of moss",
    "of rotting wood",
    "of humidity",
    "of dankness",
    "of fungus",
    "earthy",
    "of wilderness",
    "fermented",
    "of a long life, hard fought",
    "of an impending famine",
    "of perserverance",
    "of generations that have perservered",
    "sugary and sweet",
    "sharp",
    "of green beans and celery",
    "of pastries",
    "of green bitterness",
    "of fresh shoots",
    "watery and clean",
    "of musty, dry greens",
    "silicone, a rubbery mess to the nose",
    "cheerfully energising",
    "sickly sweet",
    "sour and lactonic",
    "stale and sour",
    "of pickled fruit",
    "of synthetic plums",
    "of creeping mould",
    "of something rotten blooming",
    "of wet paper",
    "of an ash tray",
]

export const bambooBranchTypes = [
    "Tough",
    "Sharp",
    "Green",
    "Leafy",
    "Long",
    "slender",
    "pointy",
    "green",
    "slender",
    "strong",
    "Damp",
    "Sticky",
    "Warm",
    "Eerie",
    "Lively",
    "Green",
    "Vibrant",
    "Luscious",
    "Sorrowful",
    "Wailing",
    "bleeding",
    "pestilent",
    "insect covered",
    "hollow",
    "columnal",
    "pole-like",
    "straight and stiff",
    "broken",
    "a suspicious air and stiff",
    "a mature culm and solid",
    "a woody culm, lacking lateral",
    "a hardy demeanour with noding",
    "a fungus eaten culm and hollow",
    "unpredictable flowers and sturdy",
    "lingering cravings and clumping",
    "fortified doubts and running",
    "a sway of import and tall",
    "a short stocky base with cheerful",
    "the air of a gentleperson with hardy",
    "a tenacious character with plain",
    "simple but elegant",
    "a hollow heart with humble",
    "a tear spotted trunk and wispy",
    "a protective vibe with upright",
    "a shining stem with split",
    "a straightforward air with simple",
    "adaptable",
    "rarely seen blossoms with strong",
]

export const bambooLeafTypes = [
    "Grim",
    "Mournful",
    "Dreary",
    "Sad",
    "Macabre",
    "Gloomy",
    "Depressing",
    "Haunting",
    "Damp",
    "Chirping insects in its",
    "a breeze tugging at its",
    "Lush",
    "Vibrant",
    "torn",
    "Untamed",
    "strange",
    "elongated",
    "steadfast",
    "steady",
    "pointed",
    "brushstroke",
    "plume-like",
    "modest",
    "plain",
    "tender",
    "crunchy",
    "husk",
]