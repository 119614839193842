export const wolfFaceSentences = [
"The face has a long snout and sharp teeth.",
"The eyes are yellow or green.",
"The ears are pointy",
"it had piercing blue eyes.",
"it had a long, slender muzzle.",
"its teeth are sharp and yellow.",
"its ears are pointy and perked up",
]

export const wolfBodySentences = [
"Muscular legs with powerful paws.",
"The body of a wolf is covered in a thick, course coat of fur.",
"The body is muscular and sleek, built for speed and agility.",
"The legs are long and powerful, allowing the wolf to cover great distances with ease.",
"it has thick, luxurious fur ",
"it has strong and powerful muscles ",
"it has long, sharp claws ",
"it has sharp teeth ",
"it has intelligent eyes ",
"it has agile body ",
]

export const wolfSoundWords = [
"Howling", "Snarling", "Yipping", "Growling", "Baying", "Whimpering", "moaning", "barking", "whining", "screeching",
"yelping", "howling", "baying", "yapping", "whining", "whinnying", "barking", "growling", "snarling", "snapping",
]

export const wolfFurWords = [
"coarse", "thick", "shaggy", "curly", "course", "bristly", "mane", "hairy", "unkempt", "rustic",
"dark", "dense", "soft", "silky", "thick", "luxurious", "velvety", "downy", "hairy", "fuzzy",
]

export const wolfSmellWords = [
"Smoky",
"Musky",
"Earthy",
"Powerful",
"Grizzly",
"Fearsome",
"Intimidating",
]

export const wolfBehaviourSentences = [
"it is making a low growling noise",
"it is licking its lips",
"it is showing its teeth",
"it is looking almost scared.",
"its tail between its legs.",
"its ears flattened against its head.",
"it is baring its teeth at you",
"it is growling at you",
"it is snarling at you",
"it is foaming at the mouth",
"it is growling and snarling",
"it is Hackling",
]