export const lifeLeechFaceSentences = [
"Vampiric creature with long, sharp teeth.",
"Blood-sucking fiend with pale, sickly skin.",
"Malevolent being with cold, black eyes.",
"Dead-eyed creature with a hunger for blood.",
"Servant of darkness with hideous, slimy, writhing skin",
"Crimson eyes that seem to pierce your soul. ",
"A mouth full of razor sharp teeth. ",
"A face that is pale and gaunt. ",
"Rotting teeth",
"Dead eyes",
"Putrid breath",
"Bone-thin",
"Ragged flesh",
"Clammy skin",
"Gaunt cheeks",
"bloodstained lips",
"small, beady eyes",
"wrinkled forehead",
"sunken cheeks",
"bloodstained mouth",
]

export const lifeLeechBodySentences = [
"bony spikes protrude from its body",
"translucent, blueish skin",
"foul-smelling ichor seeps from its pores",
"eyes glowing a sickly green",
"thin and gaunt",
"eyes sunken in sockets",
"cheeks hollow",
"skeletal frame",
"limbs long and bony",
"talons for fingers",
"It has a slug-like body, with a long, sucker-like mouth.",
"It is pale and translucent, with a network of black veins running through it.",
"It is bloated and grotesque, with distended internal organs visible through its thin skin.",
]

export const lifeLeechSoundWords = [
"Sucking", "slurping", "lapping", "dripping", "oozing", "hissing", "bubbling", "gurgling", "lumbering", 
]

export const lifeLeechFurWords = [
    "Slimy",
    "glistening",
    "rubbery",
    "rough",
    "scaly",
    "bumpy",
    "veiny",
    "oozing",
    "pus-filled",
    "pustule-ridden",
    "hunched",
    "scaly",
    "oozing",
    "ragged",
    "toothless",
    "bony",
    "withered",
    "patched",
    "scabrous",
    "Maggot-infested",
    "Festering"
]

export const lifeLeechSmellWords = [
    "Fetid",
    "Rotting",
    "Putrid",
    "Stench",
    "Nauseating",
    "Pungent",
    "Reeking",
    "Malodorous",
    "Degenerate",
    "Decaying",
]

export const lifeLeechBehaviourSentences = [
"it is drooling with hunger",
"its mouth eagerly opening and closing"
]