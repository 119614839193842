export const phasedShooterFaceSentences = [
"Stern concentration lines the forehead.",
"A well-defined jawline clenches.",
"A sharp nose slopes down to a determined mouth.",
"High cheekbones",
"A stern expression with heavy eyebrows and a set jaw.",
"A sharp, angled face with a long nose and small, beady eyes.",
"Bulges beneath watery, irritated eyes.",
"A build-up of clear fluid at the creases of the nostrils.",
"Flushed, itchy skin with a sheen of sweat.",
"Clenched teeth and a tense jawline.",
]

export const phasedShooterBodySentences = [
'A mass of fleshy tentacles and growths'
]

export const phasedShooterSoundWords = [
    "Firing",
    "whizzing",
    "zipping",
    "hissing",
    "crackling",
    "popping",
    "murmuring",
    "rumbling",
    "booming",
    "crackling",
    "Penetrating",
    "electric",
    "charged",
    "humming",
    "clicking",
    "beeping",
    "soft",
    "high-pitched",
    "faint",
    "incessant",
    "audible",
    "distinct",
    "sharp",
    "snapping",
    "loud",
    "pronounced",
    "pronounced",
    "resounding",
    "reverberating",
    "sonorous"
]

export const phasedShooterFurWords = [
]

export const phasedShooterSmellWords = [
]

export const phasedShooterBehaviourSentences = [
"it is making noises you've never heard before",
"it is standing in a way that makes it look like it's about to attack",
"it is foaming at the mouth",
"it is watching you intently with its beady eyes",
"it is stalking you slowly, its arms hanging limply at its sides",
"it is making strange noises and rocking back and forth",
"it is a work of art",
"it is made of meat",
"it is wearing a mask",
"it is covered in blood",
]