export const howlerMonkeyFaceSentences = [
"Its face is covered in wrinkles.",
"It has a long snout.",
"Its eyes are large and round.",
"It has small ears.",
"Its fur is thick and shaggy.",
"Flushed face with a greenish tinge",
"Puffy lips with a pinkish hue",
"Wide, round eyes with a greenish tint",
"furry eyebrows and long eyelashes",
"protruding, greenish-pink nose",
"A large, furry face with a long snout and big, green eyes.",
"A face that is almost entirely hair, with a long, prehensile mouth.",
"A face that is part monkey, part lizard, with a long, green tongue.",
"Flushed pink cheeks with areas of green sparsely distributed.",
"A cute, slightly smushed face with a large, green-tinged mouth.",
"Two fresh, plump cheeks with a mischievous grin and a mouth that's mostly green.",

]

export const howlerMonkeyBodySentences = [
"A long, lithe body balanced precariously on skinny limbs.",
"Downy green fur covers the monkey's back, gradually lightening to a pale pink on its face and chest.",
"The monkey's huge mouth is open in a perpetual scream, revealing sharp teeth",
"A long tail hangs down from its body.",
"It has needle-like claws on its hands and feet.",
]

export const howlerMonkeySoundWords = [
    "Snarling",
    "Hissing",
    "Roaring",
    "Rumbling",
    "Growling",
    "Grunting",
    "Groaning",
    "Whimpering",
    "Snoring",
    "Snorting",
    "Hooting",
    "Howling",
    "Screeching",
    "Yowling",
    "Crying",
    "yelling",
    "Whimpering",
    "Sniffling",
    "Groaning",
    "moaning",
    "Squeaking",
    "howling",
    "chattering",
    "screeching",
    "yelling",
    "screaming",
    "cackling",
    "barking",
    "coughing",
    "hooting",
    "Chirping",
    "Hooting",
    "Shrieking",
    "Snorting",
    "Yapping",
    "caterwauling",
    "caterwaul",
    "squawk",
    "screech",
    "chatter",
    "Yawning",
    "Smacking",
    "Whistling",
    "Hooting",
    "Grunting",
    "Snorting",
    "Chattering",
    "Clicking",
    "Clucking",
    "Croaking"
]

export const howlerMonkeyFurWords = [
    "Fuzzy",
    "downy",
    "velvety",
    "silky",
    "soft",
    "puffy",
    "cuddly",
    "cottony",
    "lanugo",
    "Lush",
    "verdant",
    "riotous",
    "exuberant",
    "fervent",
    "many-hued",
    "multicoloed",
    "calescent",
    "effulgent",
    "resplendent",
    "Slimy",
    "green",
    "pink",
    "Howler",
    "Monkey",
    "fur",
    "skin",
    "texture",
    "feel",
    "rough",
    "prickly",
    "itchy",
    "dry",
    "scaly",
    "leathery",
    "hard",
    "rigid",
    "thick",
    "furry",
    "hairy"
]

export const howlerMonkeySmellWords = [
    "Pungent",
    "rank",
    "sickening",
    "fetid",
    "putrid",
    "offensive",
    "repugnant",
    "nauseating",
    "odious",
    "foul",
    "Pungent",
    "Rotten",
    "Sweltering",
    "vinegary",
    "Piquant",
    "Foul",
    "Gamey",
    "Aromatic",
    "Fragrant",
    "Buttery",
    "Sour",
    "sweet",
    "fruity",
    "musky",
    "sticky",
    "dank",
    "rank",
    "putrid",
    "pungent",
    "acrid",
    "sickening",
    "sweet",
    "musky",
    "pungent",
    "putrid",
    "acrid",
    "fetid",
    "rank",
    "reeking",
    "stinking",
    "Fruity",
    "Tropical",
    "Intoxicating",
    "Pungent",
    "Earthy",
    "Aromatic",
    "Funky",
    "Musky",
    "Creamy",
    "Buttery"
]

export const howlerMonkeyBehaviourSentences = [
"it is staring at me with its eerie green eyes.",
"it is making strange noises that sound like laughter.",
"it is baring its teeth in a menacing way.",
"it is leaping about",
"it is looking at me with its eerie green eyes",
"it is making strange noises that sound like it's laughing at me",
"it is swinging its long tail around",
"its mouth is gaping open and its tongue is lolling out",
]