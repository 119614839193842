export const woneyedBeastFaceSentences = [
"long white tusks",
"beady black eyes",
"large pink nose",
"whiskered mouth",
"Blubbery exterior ",
"Intimidating tusks ",
"Elongated, whiskered snout ",
"Deep set, beady eyes ",
"Thick, leathery skin ",
"immense bulk ",
"a face with large tusks pointing outwards",
"a face with small eyes and a large mouth",
"a face with a long nose and whiskers",
"a face with white fur and black skin",
"a face with big teeth and a",
"Fat and wrinkly",
"ginormous tusks ",
"beady little eyes",
"whiskers galore",
"pug nose",
"huge head",
"small ears",
"chubby cheeks",
"protruding belly",
"Fierce eyes glaring out from beneath a thicket of bristly hair.",
"A nose that could rival an elephant's trunk in size, and just as wrinkled.",
"A mouth full of sharp, serrated teeth perfect for tearing through flesh.",
]

export const woneyedBeastBodySentences = [
"Enormous furry body",
"Slippery blubber",
"Two ginormous tusks",
"Round eyes",
"whiskers",
"protruding belly",
"stubby flippers",
"long neck",
"Massive body with thick, blubbery skin",
"Long tusks protruding from its large mouth",
"Small, beady eyes set close together on its large head",

]

export const woneyedBeastSoundWords = [
"bellowing", "blubbering", "guttural", "harrumphing", "honking", "lumbering", "moaning", "rumbling", "snorting", "wheezing"
]

export const woneyedBeastFurWords = [
"Tough", "thick", "oily", "course", "matted", "yellowish", "blackish", "wrinkled", "scarred", "bleached",
"Dry", "course", "scaly", "tough", "wrinkled", "leathery", "cracked", "weathered", "caloused", "rough",
]

export const woneyedBeastSmellWords = [
"Hairy",
"Huge",
"Meaty",
"Smelly",
"Tusky",
"Blubbery",
"Salty",
"Stinky",
]

export const woneyedBeastBehaviourSentences = [
"the walrus is standing on its hind legs",
"the walrus is standing on its head",
"the walrus is wobbling comically",
"the walrus is flapping its flippers",
"the walrus is standing on its head",
"the walrus is baring its teeth",
]