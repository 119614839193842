import {
    GET_TENT_RECIPES_LOADING,
    GET_TENT_RECIPES_SUCCESS,
    GET_TENT_RECIPES_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getRecursivelyAsync } from '../../utils/get-recursively-async';

export const getTentRecipesSuccess = payload => ({
    type: GET_TENT_RECIPES_SUCCESS,
    payload
})

export const getTentRecipesFailure = () => ({
    type: GET_TENT_RECIPES_FAILURE
})

export const getTentRecipesAsync = payload => dispatch => {
    return dispatch(getRecursivelyAsync('tent-recipes', getTentRecipesSuccess, 0));
}