export const lingonberrySmellTypes = [
	"of cranberry",
    "of red currant",
    "of rowanberry",
    "of bog bilberry",
    "of mountain ash",
    "of whortleberry",
    "of bakeapple",
    "of garden huckleberry",
    "of Software",
    "of Raspberry",
    "of Blueberry",
    "of Cranberry",
    "of Loganberry",
    "of Boysenberry",
    "of Jostaberry",
    "of Wineberry",
    "of Amelanchier",
    "sweet and fruity",
    "woodsy and floral",
    "of creamy berried musk",
    "of sweet berries and delicate musk",
    "of fake berries",
    "intoxicating",
    "sweet and spicy",
    "of watching the rain from under cover",
    "of tangy fruits",
    "tart",
    "maladaptively floral",
    "uplifting",
    "of relief",
    "of cooling summer rain",
    "of dew and tang",
    "of pastel colours",
    "reserved",
    "of stinging fruit",
    "sharp",
    "of cooked cucumbers",
    "shy and recalcitrant",
    "of fruity gelatin",
    "of ripe red berries",
    "harmoniously tart",
    "of contentment",
    "of days of learning",
    "repellant",
    "of abstracted iris powder",
    "of drying fruit and sand",
]

export const lingonberryBranchTypes = [
	"low-growing",
    "insect-covered",
    "little holes in its",
    "salubrious",
    "hardened",
    "malleable",
    "light",
    "tidy",
    "full",
    "patterned",
    "short",
    "ice-flecked",
    "stubby",
    "hardy",
    "sturdy",
    "cold-borne",
    "snow-piled",
    "frosted",
    "tenacious",
    "dogged",
    "determined",
    "headstrong",
    "persistng",
    "resolute",
    "adamant",
    "undaunted",
    "berry-hiding",
    "fruit-bearing",
    "small berries clinging to its",
    "hooked",
    "woven",
    "stout",
    "goodly gifts on its",
    "sun-striving",
    "hard berries along its",
]

export const lingonberryLeafTypes = [
	"paw shaped",
    "bell flowers and green",
    "bell flowers and hardy",
    "trumpet flowers and broad",
    "noched",
    "wavy-edged",
    "oval",
    "pointed",
    "greenish yellow",
    "concave",
    "Woody",
    "Green",
    "chartreuse",
    "blue green",
    "bright green",
    "alternate",
    "broad",
    "winter-strong",
    "frostbitten",
    "frost-nipped",
    "small",
    "picked through",
    "bouncy",
    "springy",
    "wave-maned",
    "murky",
    "defiant",
    "forest-dyed",
    "hanging",
    "drooping",
    "welling",
    "strange",
    "mournful",
    "swollen",
    "ravenous",
    "singing",
]