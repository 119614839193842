export const hairGrassSmellTypes = [
    "of dirt",
    "cavernous",
    "of fern",
    "of wood",
    "of leaf",
    "of moss",
    "of earth",
    "of stone",
    "of moss",
    "of dew",
    "of earth",
    "of Hair",
    "of Dirt",
    "of moss",
    "of dirt",
    "of wood",
    "of tree",
    "of eucalyptus",
    "of flowers",
    "of leaves",
    "of grass",
    "of data",
    "weedy",
    "herbal",
    "of dew on broken stalks",
    "of fresh condensation",
    "of cut plants and wet buildings",
    "of moisture in a changing season",
    "of something sweet and shady",
    "of a night from your childhood",
    "of uncomfortable mineral warmth",
    "of blood-tinged dirt",
    "of the plains after a drizzle",
    "of blooming honeysuckle",
    "of mud and moss",
    "of steamed ground",
    "of wet dust",
    "of crispness in the air",
    "of the small back garden you never had",
    "of young possibilities",
    "of dewy grass and evergreen",
    "of pungent mould",
    "of damp wood",
    "of cracked open nuts",
    "of a place you miss terribly",
    "like you could eat it",
    "of tin foil",
    "of crisp lily",
    "of summertime with a salty accord",
    "of longing",
]

export const hairGrassBranchTypes = [

]

export const hairGrassLeafTypes = [
    "spiderweb",
    "needle",
    "slender",
    "golden",
    "glistening",
    "fragile",
    "crackling",
    "dry",
    "curling",
    "swirling",
    "sunsoaked",
    "sunbleached",
    "twisted",
    "braided awns atop its",
    "Soft",
    "Velvety",
    "Green",
    "Crisp",
    "Thin",
    "unkempt",
    "Wiry",
    "Tough",
    "Coarse",
    "tangle",
    "mane-like",
    "thatch",
    "tufty",
    "ornamental",
    "bunched",
    "pale green colouring with a hint of blue on its",
    "arching",
    "tiny blue-green flowers on its",
    "immortal",
    "semi-dormant",
    "sleeping",
    "recently foraged",
    "long roots and supple",
    "smoldering",
    "fine, narrow",
    "fine-textured",
    "soft and inviting",
    "easily strokable",
    "attractively tufted",
    "rich green",
    "golden rust-coloured",
    "resilient",
    "rusty-tan flowers and hair-like"
    "enduring",
    "sheltering",
    "bushy",
    "verdure",
    "clumping",
    "shrub-like",
    "ice-piercing",
    "thawing",
    "a mess of",
    "billowing",
    "a dense tangle of",
    "grasping",
    "ice-borne"
]