import { INITIALISING_PANEL_STARTED, CHANGE_CHARACTER, START_NEW_GAME, ADD_CHARACTER_PANEL_SUCCESS, GET_CHARACTER_PANELS_SUCCESS, CREATE_CHARACTER_PANEL_SUCCESS, REMOVE_CHARACTER_PANEL_SUCCESS, UPDATE_CHARACTER_PANEL_SUCCESS } from "../actionTypes";

const initialState = {
  characterPanels: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case START_NEW_GAME: {
      return { ...initialState }
    }

    case GET_CHARACTER_PANELS_SUCCESS: {
      let newCharacterPanels = action.payload;

      const existingPanels = state.characterPanels.filter(({ panelId }) => {
        // so we REMOVE any existing panels that MATCH the newly got ones...
        const isMatch = newCharacterPanels.find(newCharacterPanel => (newCharacterPanel.panelId === panelId))

        if (isMatch) {
          return false;
        }

        return true;
      })

      return {
        ...state,
        characterPanels: [...existingPanels, ...newCharacterPanels],
      }
    }

    case ADD_CHARACTER_PANEL_SUCCESS:
    case CREATE_CHARACTER_PANEL_SUCCESS: {
      return {
        ...state,
        characterPanels: [...state.characterPanels.filter(panel => (panel._id !== action.payload._id)), { ...action.payload }]
      }
    }

    case REMOVE_CHARACTER_PANEL_SUCCESS: {
      return {
        ...state,
        characterPanels: [...state.characterPanels.filter(characterPanel => (characterPanel._id !== action.payload._id))]
      }
    }

    case UPDATE_CHARACTER_PANEL_SUCCESS: {
      return {
        ...state,
        characterPanels: [...state.characterPanels.filter(characterPanel => (characterPanel._id !== action.payload._id)), { ...state.characterPanels.find(characterPanel => (characterPanel._id === action.payload._id)), ...action.payload}]
      }
    }

    default:
      return state;
  }
}
