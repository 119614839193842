import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import { newOrganisation, showOrganisation, disableKeyboardMovement, enableKeyboardMovement, showHelp, startLooking, hideAllMenus, startAreaSelect } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectCharacter,
    selectCharacters,
    selectZones,
    selectOpenZone,
    selectCharacterZoneOrganisations,
    selectAreaSelect,
    selectPanel
} from '../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import { createZoneAsync, deleteZoneAsync, updateZone } from '../../redux/actions/zone.actions'

import Menu from '../utils/menu/Menu';
import TextInput from '../utils/text-input/TextInput';

import { colyClient } from '../../services/Panel-initialisation.service';
import { client } from '../../services/client';

import { WORLD_WIDTH, WORLD_HEIGHT } from '../../services/biome-geography'

// import './Diary.css';

const RESOURCE_OPTIONS = [
    {
        text: 'Armour',
        serviceName: 'armour-instances',
    },
    {
        text: 'Clothing',
        serviceName: 'clothing-instances',
    },
    {
        text: 'Food',
        serviceName: 'food-instances',
    },
    {
        text: 'Furniture',
        serviceName: 'furniture-instances',
    },
    {
        text: 'Jewellery',
        serviceName: 'jewellery-instances',
    },
    {
        text: 'Materials',
        serviceName: 'material-instances',
    },
    {
        text: 'Metals',
        serviceName: 'metal-instances',
    },
    {
        text: 'Minerals',
        serviceName: 'mineral-instances',
    },
    {
        text: 'Tents',
        serviceName: 'tent-instances',
    },
    {
        text: 'Tools',
        serviceName: 'tool-instances',
    },
    {
        text: 'Weapons',
        serviceName: 'weapon-instances',
    },
    {
        text: 'Writing Surfaces',
        serviceName: 'writing-surface-instances',
    },
    {
        text: 'Tiles',
        serviceName: 'tile-instances',
    },
]

class Zone extends React.Component {
    state = {
        navigationHandler: this.navigationHandler.bind(this),
        isSetTextShowing: false,
        allowedResources: [ ...RESOURCE_OPTIONS ],
        type: 'Stockpile'
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.state.navigationHandler)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.state.navigationHandler);

        this.saveChanges();
    }

    navigationHandler(event) {
        if (this.state.isSetTextShowing || this.state.isResourceSelectShowing) {
            return;
        }

        if (this.isEnabled('viewEditZoneArea') && event.key === 'a') {
            this.viewEditZoneArea()
            
            return;
        }

        if (this.isEnabled('deleteOrganisation') && event.key === 'x') {
            this.props.deleteZoneAsync({ _id: this.props.zone?._id })
            this.props.hideAllMenus()
            return;
        }
    }

    editName() {
        // show input
        this.setState({
            isSetTextShowing: 'name'
        })
    }

    editDescription() {
        // show input
        this.setState({
            isSetTextShowing: 'description'
        })
    }

    editOrganisation() {
        this.setState({
            isOrganisationSelectShowing: true
        })
    }

    editResources() {
        this.setState({
            isResourceSelectShowing: true
        })
    }

    editType() {
        this.setState({
            isTypeSelectShowing: true
        })
    }

    viewEditZoneArea() {
        // this.props.hideAllMenus()
        this.props.startAreaSelect({
            position: this.props.character.position,
            areaSelectType: 'stockpile',
            isStartOfAreaSelect: true,
            zoneSettings: {
                type: this.state.type,
                description: this.state.description,
                name: this.state.name,
                allowedResources: this.state.allowedResources,
                organisation: this.state.organisation, // if hauling on behalf of an org, use this
            }
        })
    }

    saveChanges() {
        if (!this.props.zone) {
            // And then when we save on that panel, we do this thing mate.
            colyClient.room.send('doAction', {
                serviceName: 'zones',
                name: 'create',
                data: {
                    type: this.state.type,
                    description: this.state.description,
                    name: this.state.name,
                    resources: this.state.allowedResources?.map(resource => (resource.serviceName)),
                    organisationId: this.state.organisation?._id, // if hauling on behalf of an org, use this
                    characterId: !this.state.organisation ? this.props.character._id : undefined,
                    panelId: this.props.zone?.panelId || this.props.panel.id,
                    panelAreas: this.state.panelAreas,
                    z: this.props.character.z
                },
            })
            return
        }

        colyClient.room.send('doAction', {
            serviceName: 'zones',
            name: 'patch',
            data: {
                _id: this.props.zone._id,
                description: this.state.description,
                name: this.state.name,
                resources: this.state.allowedResources?.map(resource => (resource.serviceName)),
                panelAreas: this.state.panelAreas,
                panelId: this.props.zone?.panelId || this.props.panel.id
                // type and organisationId are not editable once a zone is created.
            },
        })
    }

    isEnabled(action) {
        if (action === 'editType') {
            return !this.props.zone?._id
        }
        return true
    }

    isFormValid() {
        return true
    }

    onEditSubmit(text) {
        this.setState({
            [this.state.isSetTextShowing]: text,
            isSetTextShowing: false
        })

        this.saveChanges()
    }

    onEditExit(text) {
        this.setState({
            isSetTextShowing: false
        })
    }

    onResourcesKeyPressed(option, event, selectedOption) {
        if (event.key === 'Escape') {
            this.setState({
                isResourceSelectShowing: false,
            })

            event.preventDefault();
        }
    }

    onTypeKeyPressed(option, event, selectedOption) {
        if (event.key === 'Escape') {
            this.setState({
                isTypeSelectShowing: false,
            })

            event.preventDefault();
        }
    }

    onResourceChosen(option) {
        const isExistingOption = this.state.allowedResources.find(resource => (resource.serviceName === option.serviceName))

        if (isExistingOption) {
            this.setState({
                allowedResources: [ ...this.state.allowedResources.filter(resource => (resource.serviceName !== option.serviceName)) ]
            })
            return
        }

        this.setState({
            allowedResources: [ ...this.state.allowedResources, option ]
        })
    }

    onTypeChosen(option) {        
        this.setState({
            type: option.text
        })
    }

    onOrganisationKeyPressed(option, event, selectedOption) {
        if (event.key === 'Escape') {
            this.setState({
                isOrganisationSelectShowing: false,
            })

            event.preventDefault();
        }
    }

    onOrganisationChosen(option) {
        if (!option._id) {
            this.setState({
                organisation: undefined
            })
        }

        this.setState({
            organisation: option
        })
    }

    static getDerivedStateFromProps(props, state) {
        if (props.zone !== state.zone) {
            state.zone = props.zone

            state.organisation = props.organisations.find(organisation => (organisation._id === props.zone.organisationId))

            state.name = props.zone.name
            state.description = props.zone.description
            state.type = props.zone.type
            state.allowedResources = props.zone.resources?.map(allowedResource => (RESOURCE_OPTIONS.find(option => (option.serviceName === allowedResource)))) || [ ...RESOURCE_OPTIONS ]
            state.panel = props.zone.panel
            state.panelAreas = props.zone.panelAreas
            state.type = props.zone.type || 'Stockpile'
        }

        if (props.areaSelect && props.areaSelect?.startingCoords) {
            state.panelAreas = [
                {
                    x: Math.min(props.areaSelect?.startingCoords.x, props.areaSelect?.x),
                    y: Math.min(props.areaSelect?.startingCoords.y, props.areaSelect?.y),
                    width: Math.abs(props.areaSelect?.startingCoords.x - props.areaSelect?.x) + 1,
                    height: Math.abs(props.areaSelect?.startingCoords.y - props.areaSelect?.y) + 1
                }
            ]

            state.panel = props.panel
        }
        return state;
    }

    getPanelCoordsFromId(panelId) {
        return {
            x: (panelId % WORLD_WIDTH) - 1,
            y: Math.floor(panelId / WORLD_WIDTH)
        }
    }

    getPanelDirection(panelId) {
        const zonePanelCoords = this.getPanelCoordsFromId(panelId);
        const currentPanelCoords = { x: this.props.panel.x, y: this.props.panel.y }

        const amountNorth = currentPanelCoords.y - zonePanelCoords.y
        const amountEast = currentPanelCoords.x - zonePanelCoords.x

        let panelDirectionString = '['

        if (amountNorth < 0) {
            const amountSouth = amountNorth * -1
            panelDirectionString = `[${amountSouth}S,`
        } else if (amountNorth >= 0) {
            panelDirectionString = `[${amountNorth}N,`
        }

        if (amountEast < 0) {
            const amountWest = amountEast * -1
            panelDirectionString += `${amountWest}E]`
        } else if (amountEast >= 0) {
            panelDirectionString += `${amountEast}W]`
        }

        if (amountEast === 0 && amountNorth === 0) {
            panelDirectionString = 'Current panel'
        }

        return panelDirectionString
    }

    chooseOption(option) {
        if (option.text === 'Zone Name: ') {
            this.editName()
        }

        if (option.text === 'Description: ') {
            this.editDescription()
        }

        if (option.text === 'Organisation: ') {
            this.editOrganisation()
        }

        if (option.text === 'Type: ') {
            this.editType()
        }

        if (option.text === 'Allowed resources: ') {
            this.editResources()
        }
    }

    render() {
        let locationHint = `Panel: ${this.getPanelDirection(this.props.zone?.panelId)}`

        if (this.state.panelAreas && this.state.panelAreas[0]) {
            const panelArea = this.state.panelAreas[0]
            locationHint += `, Position: [${panelArea.x},${panelArea.y}], Size: ${panelArea.width}x${panelArea.height}`
        }

        return (
            <div className="conversation-tree-wizard">
                <div className="conversation-tree-wizard-container">
                    {
                        this.state.isSetTextShowing ? (
                            <div className="conversation-wizard-edit-text-container">
                                <p className="conversation-wizard-exit"><span className="action">ESC</span> to exit</p>
                                <div className="conversation-wizard-edit-text">
                                    <TextInput onTextSubmit={this.onEditSubmit.bind(this)} text={this.state[this.state.isSetTextShowing]} exitKey="Escape" onExitKey={this.onEditExit.bind(this)} />
                                </div>
                            </div>
                        ) : ('')
                    }

                    {
                        (!this.state.isResourceSelectShowing && !this.state.isTypeSelectShowing && !this.state.isOrganisationSelectShowing) ? (
                            <Menu options={[
                                {
                                    text: 'Zone Name: ',
                                    hint: this.state.name,
                                },
                                {
                                    text: 'Description: ',
                                    hint: this.state.description
                                },
                                {
                                    text: 'Organisation: ',
                                    hint: this.state.organisation?.name || 'Personal zone'
                                },
                                {
                                    text: 'Type: ',
                                    hint: this.state.type
                                },
                                {
                                    text: 'Allowed resources: ',
                                    hint: this.state.allowedResources?.length === RESOURCE_OPTIONS.length ? 'All' : this.state.allowedResources?.map(resource => (resource.text + ' '))
                                },
                                {
                                    text: 'Location: ',
                                    hint: locationHint
                                },
                            ]}
                            optionChosen={(option) => (this.chooseOption(option))}
                            menuContainer="organisation-container"
                        />
                        ) : ('')
                    }

                    {
                        this.state.isResourceSelectShowing ? (
                            <div className="conversation-tree-wizard-container">
                                <Menu options={
                                        RESOURCE_OPTIONS.map((option) => {
                                            if (this.state.allowedResources.find(allowed => allowed.serviceName === option.serviceName)) {
                                                return {
                                                    ...option,
                                                    hint: ' Selected'
                                                }
                                            }

                                            return option
                                        })
                                    }
                                    optionChosen={this.onResourceChosen.bind(this)}
                                    keyPressed={this.onResourcesKeyPressed.bind(this)}
                                    // navigation={this.onNavigation.bind(this)}
                                    menuContainer="conversation-tree-wizard-container"
                                />
                            </div>
                        ) : null
                    }

                    {
                        this.state.isTypeSelectShowing ? (
                            <div className="conversation-tree-wizard-container">
                                <Menu options={[
                                        { text: 'Stockpile', hint: this.state.type === 'Stockpile' ? ' Selected' : '' },
                                        { text: 'Home', hint: this.state.type === 'Home' ? ' Selected' : '' },
                                    ]}
                                    optionChosen={this.onTypeChosen.bind(this)}
                                    keyPressed={this.onTypeKeyPressed.bind(this)}
                                    // navigation={this.onNavigation.bind(this)}
                                    menuContainer="conversation-tree-wizard-container"
                                />
                            </div>
                        ) : null
                    }

                    {
                        this.state.isOrganisationSelectShowing ? (
                            <div className="conversation-tree-wizard-container">
                                <Menu options={
                                        [
                                            {
                                                text: 'No Organisation (Personal zone)',
                                            },
                                            ...this.props.organisations.map(organisation => ({ ...organisation, text: organisation.name }))
                                        ].map((organisation, index) => {
                                            if (!this.state.organisation && index === 0) {
                                                return {
                                                    ...organisation,
                                                    hint: ' Selected'
                                                }
                                            }

                                            if (this.state.organisation?._id === organisation?._id) {
                                                return {
                                                    ...organisation,
                                                    hint: ' Selected'
                                                }
                                            }

                                            return {
                                                ...organisation
                                            }
                                        })
                                    }
                                    optionChosen={this.onOrganisationChosen.bind(this)}
                                    keyPressed={this.onOrganisationKeyPressed.bind(this)}
                                    // navigation={this.onNavigation.bind(this)}
                                    menuContainer="conversation-tree-wizard-container"
                                />
                            </div>
                        ) : null
                    }
                </div>
                {
                    this.state.isResourceSelectShowing ? (
                        <div className="conversation-tree-actions-container">
                            <>
                                <p className={this.isEnabled('editName') ? "" : "hidden"}><span className="action">ENTER</span> - Toggle resource</p>
                            </>
                        </div>
                    ) : null
                }
                {
                    !this.state.isResourceSelectShowing && !this.state.isOrganisationSelectShowing ? (
                        <div className="conversation-tree-actions-container">
                            <>
                                <p className={this.isEnabled('viewEditZoneArea') ? "" : "hidden"}><span className="action">A</span> - View/edit zone area</p>
                                <p className={this.isEnabled('deleteZone') ? "" : "hidden"}><span className="action">X</span> - <span className="error">Delete zone</span></p>
                            </>
                        </div>
                    ) : null
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    const characters = selectCharacters(state);
    const character = selectCharacter(state);
    const zones = selectZones(state);
    const zone = selectOpenZone(state)
    const organisations = selectCharacterZoneOrganisations(state, character._id)
    const areaSelect = selectAreaSelect(state)
    const panel = selectPanel(state)

    return {
        characters: characters.map(character => ({ ...character, text: character.name })),
        character,
        zone,
        organisations,
        areaSelect,
        panel
    }
}

export default connect(
    mapStateToProps,
    { hideAllMenus, newOrganisation, showOrganisation, startAreaSelect, deleteZoneAsync, createZoneAsync, updateZone }
)(Zone);