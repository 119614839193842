export const junglePlantTwoSmellTypes = [
	"Pungent",
    "Rancid",
    "Slimy",
    "Spoiled",
    "Putrid",
    "Nauseating",
    "Gag-inducing",
    "Toxic",
    "Venomous",
    "pungent",
    "earthy",
    "humid",
    "dank",
    "moldy",
    "musky",
    "pungent",
    "rotting",
    "sweet",
    "musky",
    "moldy",
    "dank",
    "humid",
    "soggy",
    "moist",
    "lush",
    "dank",
    "humid",
    "opulent",
    "of lianas",
    "of orchids",
    "juicy",
    "pungent",
    "rotting",
    "sweet",
    "overripe",
    "sour",
    "of mineral dust",
    "magnetically floral",
    "of fermented fruits",
    "of fungal sandalwood",
    "of dense vintage florals",
    "of sweet cedar",
    "of musky jaca fruit",
    "of odd fruit",
    "of wood and laudanum",
    "of spiced greens and leather",
]

export const junglePlantTwoBranchTypes = [
	"verdant",
    "lush",
    "exuberant",
    "invigorating",
    "tropical",
    "humid",
    "dank",
    "pervasive",
    "juicy",
    "thick",
    "green",
    "glossy",
    "rigid",
    "twisted",
    "brittle",
    "bumpy",
    "green",
    "decayed",
    "hair-like",
    "thorny",
    "lush",
    "verdant",
    "humid",
    "steamy",
    "collapsing",
    "dense",
    "youthful",
    "green",
    "square",
    "waxy",
    "thick",
    "resplendent",
    "veined"
]

export const junglePlantTwoLeafTypes = [
	"thick",
    "waxy",
    "pointed",
    "shiny",
    "green",
    "smooth",
    "glossy",
    "tapered",
    "uniform",
    "thick",
    "waxy",
    "pointed",
    "smooth",
    "glossy",
    "deep green",
    "veined",
    "ovate",
    "heart-shaped",
    "palm-sized",
    "thick",
    "waxy",
    "green",
    "glossy",
    "pointed",
    "veined",
    "smooth",
    "large",
    "leathery",
    "thick",
    "waxy",
    "glossy",
    "green",
    "veined",
    "ovate",
    "pointed",
    "lobed",
    "serrated",
    "veined",
    "thick",
    "lush",
    "green",
    "glossy",
    "waxy",
    "moss-licked",
    "mottled",
    "flowering"
]