import {
    GET_FISH_SUCCESS,
    GET_FISH_FAILURE,
    HARVEST_FISH_SUCCESS,
    HARVEST_FISH_FAILURE,
    ADD_FISH,
    UPDATE_FISH,
    REMOVE_FISH
} from '../actionTypes';
import { clearAllTiles, loadTilesSuccess } from './tile.actions';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';

import { client } from '../../services/client';

export const getFishSuccess = payload => {
    return ({
        type: GET_FISH_SUCCESS,
        payload
    })
}

export const getFishFailure = payload => ({
    type: GET_FISH_FAILURE
})

export const getFishAsync = payload => dispatch => {
    return client.service('fish-instances').find({ query: { panelId: payload.panelId } })
        .then((response) => {
            response = response.map(fish => ({ ...fish, fish: payload.fishTypes.find(type => type._id === fish.fishTypeId) }))
            dispatch(getFishSuccess(response));
        })
        .catch((error) => {
            console.error('error', error);
            dispatch(showUnknownError({ message: error.message }));
            throw new Error(error);
        })
}

export const harvestFishSuccess = payload => {
    return ({
        type: HARVEST_FISH_SUCCESS,
        payload
    })
}

export const harvestFishFailure = () => ({
    type: HARVEST_FISH_FAILURE
})

export const harvestFishAsync = payload => dispatch => {
    const { id, quantity } = payload;

    return client.service('fish-instances').patch(id, { quantity: quantity - 1 })
        .then((response) => {
            console.log('response: ', response);
            dispatch(harvestFishSuccess(response));
        })
        .catch(({ message }) => {
            dispatch(showUnknownError({ message }))
            console.error('error', message);
        })
}

export const addFish = payload => {
    return ({
        type: ADD_FISH,
        payload
    })
}

export const updateFish = payload => {
    return ({
        type: UPDATE_FISH,
        payload
    })
}

export const removeFish = payload => {
    return ({
        type: REMOVE_FISH,
        payload
    })
}
