import React from "react";
import { connect } from 'react-redux'
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import { client } from './services/client';

import Login from "./components/login/Login";
import Home from "./components/containers/home/Home";
import ResetPassword from "./components/account-management/ResetPassword";

import { getMetaAsync } from './redux/actions/meta.actions';
import { initialisingAppStarted, initialisingAppFinished } from './redux/actions/keyboard-shortcuts.actions';
import { logInLocalStorageAsync, logInAsync } from './redux/actions/user.actions';
import { selectUser } from './redux/selectors';
import { cachedReducers } from './redux/local-storage';

class App extends React.Component {
    state = {
        isLoading: true
    };

    async componentDidMount() {
        this.props.initialisingAppStarted();

        let firstAttempt;

        try {
            firstAttempt = await this.props.logInLocalStorageAsync();
        } catch(e) {
            
        }

        if (!firstAttempt?.user?._id && typeof Itch !== 'undefined') {
            // launched by itch app
            let headers = new Headers();
            headers.append('Authorization', Itch?.env?.ITCHIO_API_KEY);

            const data = await fetch(`https://itch.io/api/1/jwt/me`, { method: 'GET', headers })
                .then(function (response) {
                    // The API call was successful!
                    return response.json();
                })

            if (data && !data.errors) {
                const email = `itch-${data.api_key?.issuer?.game_id}-${data.user?.username}`;
                const password = `password-${data.user.id}`;

                // first, we try to log in.
                await this.props.logInAsync({
                    email,
                    password
                })
                    .catch((error) => {
                        // if not we create an account with username username and password id

                        // if (error === 'no user')
                        return client.service('users')
                            .create({
                                email,
                                password
                            })
                            .then(() => this.props.logInAsync({
                                email,
                                password
                            }))
                    })

                this.setState({ isLoading: false })
            }
        } else if (!firstAttempt?.user?._id) {
            this.props.initialisingAppFinished();
        }

        // const metaData = await this.props.getMetaAsync();

        // if (metaData?.version) {
        //     if (localStorage.getItem('version') !== metaData.version) {
        //         console.log('WARNING! NEW VERSION! MUST RESET LOCAL STORAGE');
        //         Object.keys(cachedReducers).forEach(reducer => {
        //             localStorage.removeItem(reducer);
        //         })
        //         localStorage.setItem('version', metaData.version)
        //         window.location.reload();
        //     }
        // }

        this.setState({ isLoading: false })
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route exact default path="/">
                        {
                            this.props.user.user.email ? 
                            (
                                <div>
                                    <Home />
                                </div>
                            )
                            : this.state.isLoading ? ('') : (
                                <div>
                                    <Login />
                                </div>
                            )
                        }
                    </Route>
                </Switch>
                <Switch>
                    <Route exact path="/reset">
                        <ResetPassword />
                    </Route>
                </Switch>
            </Router>
        )
    }
}

const mapStateToProps = state => {
    const user = selectUser(state)
    return { user }
}

export default connect(
    mapStateToProps,
    { logInLocalStorageAsync, getMetaAsync, logInAsync, initialisingAppFinished, initialisingAppStarted }
)(App);