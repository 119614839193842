export const pearlwortSmellTypes = [
    "of Rose",
    "of Jasmine",
    "of Lemon",
    "of Citronella",
    "of Ginger",
    "of Cardamom",
    "of Cinnamon",
    "of Clove",
    "of Lemon",
    "of Orange",
    "of Lilac",
    "of Jasmine",
    "of Cinnamon",
    "of Ginger",
    "of Nutmeg",
    "of Vanilla",
    "of Lavender",
    "of Sage",
    "smokey and thick",
    "herbal and relaxing",
    "surreal",
    "of salty waters",
    "of mandarine juice",
    "calone",
    "marine",
    "cheeky",
    "of milky skin",
    "of metallic petals",
    "mass-produced",
    "warm and heavy",
    "of soap bones",
    "of offal",
    "of powder and dirt",
    "sinister",
    "of oiled skin",
    "of curdled cream",
    "of musk and plastic tubing",
    "of insulation",
    "of pollen and silk",
    "of pear and meat",
    "of rusted fuselage",
    "of clean wares",
    "of dense floral pulp",
    "of yuzu-soaked burlap",
    "gag-inducing",
    "distracting",
    "of an opulent bathhouse",
    "sterile",
    "of silicone citrus",
    "of cellphone-thin rose petals",
    "magnetic",

]

export const pearlwortBranchTypes = [
	"flowers growing from its",
    "veins on its",
    "insects on its",
    "Delicate",
    "Fragile",
    "Intricate",
    "Lacey",
    "persistent",
    "stalwart",
    "headstrong",
    "moist",
    "clumping",
    "matted",
    "sprayed",
    "protective",
    "legendary",
    "tales-old",
    "fable-favoured",
    "procumbent",
    "flat",
    "sprawling",
    "creeping",
    "glandular",
    "hairy",
    "petite",
    "threadlike",
    "trailing",
    "erect",
    "cushion-soft",
    "spreading",
    "greedy",
    "oversaturated",
    "springy",
    "slender",
    "prostate",
    "thick",
]

export const pearlwortLeafTypes = [
	"Vibrant",
    "green",
    "waxy",
    "smooth",
    "thin",
    "long",
    "tapered",
    "pointy",
    "sharp",
    "slim",
    "Passionate",
    "Cheery",
    "Vibrant",
    "Playful",
    "Lively",
    "Bubbly",
    "Energetic",
    "Vital",
    "Sparkling",
    "opposite",
    "tightly packed",
    "whorl-arranged",
    "whorl-clustered",
    "small",
    "linear",
    "short",
    "stubby",
    "hairless",
    "fleshy",
    "tufted",
    "bladed",
    "globular buds under its",
    "moss-like",
    "subulate",
    "slim",
    "round seeds under its",
]