import { GET_LOCK_TYPES_SUCCESS } from "../actionTypes";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_LOCK_TYPES_SUCCESS: {
      const lockTypes = action.payload;

      if (!lockTypes){
        return [...state];
      }

      return [
        ...state,
        ...lockTypes
      ]
    }
    default:
      return state;
  }
}
