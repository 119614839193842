export const lionFaceSentences = [
    "The lion had a huge face with a big mane.",
"The face was Crafted From Gold.",
"It hadA Piercing Gaze.",
"The lion's face was very Majestic.",
"It had a strong, fierce look.",
"A big, broad face with a strong muzzle. ",
"long whiskers that twitch and quiver. ",
"A big, black nose that sniffs the air. ",
]

export const lionBodySentences = [
"The lion's fur is thick and luxurious",
"The lion's eyes are piercing and intimidating",
"The lion's mane is impressive and intimidating",
"The lion's claws are sharp and deadly",
"The lion's teeth are sharp and deadly",
"its coat is a beautiful golden color",
"its mane is thick and full",
"its eyes are a piercing green",
"its claws are sharp and deadly",
"its teeth are sharp and ferocious",
"The lion has a huge mane that is impressive and intimidating",
"The lion's fur is thick and luxurious, providing excellent camouflage in the tall grasses",
"The lion is a powerfully built animal, with a muscular body and powerful legs",
"The body of a lion is strong and muscular, with a mane of thick, shaggy hair",
"The coat is tawny, with dark brown or black spots",
"The head is large and round, with a short muzzle and big, expressive eyes",
]

export const lionSoundWords = [
    "Roaring",
    "rumbling",
    "creaking",
    "bellowing",
    "hissing",
    "growling",
    "chuckling",
    "purring",
    "mewing",
    "yowling",
    "Hissing",
    "Roaring",
    "Rumbling",
    "Snarling",
    "Growling",
    "Rumbling"
]

export const lionFurWords = [
    "Warm",
    "fuzzy",
    "golden",
    "thick",
    "soft",
    "luxurious",
    "mane",
    "intimidating",
    "noble",
    "wild",
    "hairy",
    "thick",
    "course",
    "mane",
    "gross",
    "yellow",
    "smelly",
    "fly-ridden",
    "itchy",
    "dry",
    "Tawny",
    "golden",
    "reddish-brown",
    "russet",
    "brown",
    "cream",
    "white",
    "spotted",
    "maned",
    "tufted",
    "Fierce",
    "Proud",
    "Strong",
    "Stately",
    "Courageous",
    "Majestic",
    "Ferocious",
    "Gentle",
    "Loving",
    "Wild",
    "Tawny",
    "golden",
    "lustrous",
    "thick",
    "soft",
    "plush"
]

export const lionSmellWords = [
"Pungent", "gamey", "musky", "blood", "guts", "death", "decay",
]

export const lionBehaviourSentences = [
"it is pacing back and forth",
"it is staring intently at something",
"it is licking its lips",
"it is crouching down low",
"it is licking its lips and staring at us hungrily",
"it is making grunting noises",
"it is making loud grunting noises",
"it is licking its own paw",
"it is biting its own tail",
]