import { GET_FOOD_RECIPES_SUCCESS } from "../actionTypes";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_FOOD_RECIPES_SUCCESS: {
      const foodRecipes = action.payload;

      if (!foodRecipes){
        return [...state];
      }

      return [
        ...state,
        ...foodRecipes
      ]
    }
    default:
      return state;
  }
}
