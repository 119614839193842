export const biomeMap = {
	'OCEAN': 'The High Sea',
	'SUBPOLAR_MOIST_TUNDRA': 'Tundra',
	'SUBPOLAR_WET_TUNDRA': 'Tundratic Fernlands',
	'SUBPOLAR_DRY_TUNDRA': 'Tundratic Thornlands',
	'SUBPOLAR_RAIN_TUNDRA': 'Misty Tundra',
	'BOREAL_DESERT': 'The Pits',
	'BOREAL_DRY_SCRUB': 'Pitted Scrubs',
	'BOREAL_MOIST_FOREST': 'Evergreen Forest',
	'BOREAL_WET_FOREST': 'Fur Forest',
	'BOREAL_RAIN_FOREST': 'Overgrown Fur Forest',
	'COOL_TEMPERATE_MOIST_FOREST': 'Old Growth Forest',
	'COOL_TEMPERATE_WET_FOREST': 'Inglenook Forest',
	'COOL_TEMPERATE_RAIN_FOREST': 'Swampy Forest',
	'COOL_TEMPERATE_STEPPES': 'Steppelands',
	'COOL_TEMPERATE_DESERT_SCRUB': 'Bracken Heaths',
	'COOL_TEMPERATE_DESERT': 'The Slimes',
	'WARM_TEMPERATE_DRY_FOREST': 'Fossilised Forest',
	'WARM_TEMPERATE_MOIST_FOREST': 'Primeval Forest',
	'WARM_TEMPERATE_WET_FOREST': 'Foetal Rainforest',
	'WARM_TEMPERATE_RAIN_FOREST': 'Primeordeal Rainforest',
	'WARM_TEMPERATE_THORN_SCRUB': 'Prickleands',
	'WARM_TEMPERATE_DESERT_SCRUB': 'Montane Shrublands',
	'WARM_TEMPERATE_DESERT': 'The Wastes',
	'SUBTROPICAL_DRY_FOREST': 'Painforest',
	'SUBTROPICAL_MOIST_FOREST': 'Discordant Forest',
	'SUBTROPICAL_WET_FOREST': 'Luscious Painforest',
	'SUBTROPICAL_RAIN_FOREST': 'Antiordeal Rainforest',
	'SUBTROPICAL_THORN_WOODLAND': 'Thistlewood Forest',
	'SUBTROPICAL_DESERT_SCRUB': 'Puzzlebox Scrub',
	'SUBTROPICAL_DESERT': 'The Slabs',
	'TROPICAL_DRY_FOREST': 'Crystalised Forest',
	'TROPICAL_VERY_DRY_FOREST': 'Crusty Forest',
	'TROPICAL_THORN_WOODLAND': 'Needlewood Forest',
	'TROPICAL_DESERT_SCRUB': 'Maze Scrub',
	'TROPCIAL_DESERT': 'The Sands',
	'TROPCIAL_MOIST_FOREST': 'Woneyed Jungle',
	'TROPICAL_WET_FOREST': 'Darkest Jungle',
	'TROPICAL_RAIN_FOREST': 'Deepest Jungle',
	'POLAR_ICE': 'Icy Wastes',
	'POLAR_DESERT': 'Polar Wastes',
	'MOUNTAIN': 'Maze Mountains',
	'EMPTY': ''
}