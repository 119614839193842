export const uapacaaSmellTypes = [
    "Sweet",
    "Aromatic",
    "Balsamic",
    "enticing",
    "scented",
    "fragrant",
    "redolent",
    "pungent",
    "compound",
    "fetid",
    "odiferous",
    "malodorous",
    "putrid",
    "Overwhelming",
    "Suffocating",
    "Choking",
    "Gagging",
    "Deadly",
    "Refreshing",
    "Crisp",
    "Subtle",
    "Sweet",
    "light",
    "airy",
    "fresh",
    "wonderful",
    "hypnotically floral",
    "plush and airy",
    "extremely expensive",
    "clean but musky",
    "unrealistic",
    "pure and dark",
    "of an inky musk",
    "of a mellow cloud",
    "of smokey minerals",
    "of a tannery",
    "of raw musk",
    "like petrichor",
    "of blooms in hot weather",
    "of glowing white light",
    "potent and warming",
    "of pungent citron",
    "of a noseful of tangerines",
    "and deliciously of an old memory",
    "of malt and plumeria",
    "of a lemon rind",
    "of sweet calamansi",
    "of emulsified lime",
    "of sunset fading into night",
    "of formal proceedings",
    "invasive",
]

export const uapacaaBranchTypes = [
	"sturdy",
    "straight",
    "smooth",
    "flexible",
    "thin",
    "bug bearing",
    "shedding bark",
    "home to insects",
    "gnarled",
    "multi-branched",
    "sturdy",
    "reliable",
    "flexible",
    "strong",
    "supportive",
    "helpful",
    "valuable",
    "essential",
    "important",
    "necessary",
    "Strong",
    "sturdy",
    "smooth",
    "straight",
    "thin",
    "long",
    "flexible",
    "brown",
    "bumpy",
    "dry",
    "rough and scaly Bark on its",
    "sturdy and strong",
    "small, white flowers on its",
    "seed-bearing cones on its",
    "juicy and red berries on its",
    "sticky and sweet sap covered",
    "an adaptable nature with hardened",
    "drought-hardened",
    "thick and furrowed bark and stiff",
    "fire-resistant bark and thick",
    "fungi populating its",
    "tempting but forbidden fruits on its",
    "curious cocoons lacing its",
    "domineering",
    "dry, lonely",
    "stabilising roots and light-coloured",
    "salt-encrusted stilt roots and reddish-brown",
    "small green fruits high in its",
    "curious fruits crowning its",
]

export const uapacaaLeafTypes = [
 	"Thick",
    "Green",
    "Vibrant",
    "Curved",
    "Narrow",
    "Pointed",
    "glossy",
    "smooth",
    "thin",
    "pointy",
    "dark green",
    "slightly fuzzy",
    "long",
    "slightly curved",
    "narrow",
    "delicate",
    "big",
    "green",
    "long",
    "thin",
    "pointy",
    "hard",
    "crispy",
    "fresh",
    "beautiful",
    "healthy",
    "Smooth",
    "Glossy",
    "Pointed",
    "Tapering",
    "Elongated",
    "long",
    "thin",
    "pointy",
    "sharp",
    "light green",
    "spiraled",
    "hard",
    "glossy",
    "smooth",
    "spirally arranged",
    "pinnate",
    "alternate",
    "sclerophyllous",
    "clustered small flowers and sticky",
    "small green",
    "dark green",
    "pest-bitten",
]