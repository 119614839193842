import {
    CREATE_STAIR_LOADING,
    CREATE_STAIR_SUCCESS,
    CREATE_STAIR_FAILURE,
    GET_STAIRS_LOADING,
    GET_STAIRS_SUCCESS,
    GET_STAIRS_FAILURE,
    ADD_STAIR,
    UPDATE_STAIR,
    REMOVE_STAIR,
    CLEAR_STAIRS
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

import { moveItemFailure } from './move-item.actions';

export const createStairSuccess = payload => ({
    type: CREATE_STAIR_SUCCESS,
    payload
})

export const createStairFailure = () => ({
    type: CREATE_STAIR_FAILURE
})

export const createStairAsync = payload => dispatch => {
    const { _id, stairTypeId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'stair-instances',
        name: 'create',
        data: {
            stairTypeId
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getStairsSuccess = payload => {
    return ({
        type: GET_STAIRS_SUCCESS,
        payload
    })
}

export const getStairsFailure = () => ({
    type: GET_STAIRS_FAILURE
})

export const getStairsAsync = payload => dispatch => {
    return client.service('stair-instances').find()
        .then((response) => {
            response = response.map(stair => ({ ...stair, stairType: payload.stairTypes.find(type => type._id === stair.stairTypeId) }))
            dispatch(getStairsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const addStair = payload => {
    return ({
        type: ADD_STAIR,
        payload
    })
}

export const updateStair = payload => {
    return ({
        type: UPDATE_STAIR,
        payload
    })
}

export const removeStair = payload => {
    return ({
        type: REMOVE_STAIR,
        payload
    })
}

export const clearStairs = payload => {
    return ({
        type: CLEAR_STAIRS,
        payload
    })
}
