export const sableFaceSentences = [
"A beautiful red face with dark eyes",
"An intelligent and regal face",
"A playful and mischievous face",
"A calm and serene face",
"A face that shows strength and power",
]

export const sableBodySentences = [
"Flowing lines give it an elegant look",
"The deep red hue of it is absolutely stunning",
"Rich, deep red color",
"Velvety, soft coat",
"Bright, shining eyes",
"Regal bearing and posture",
"Proud, erect tail",
"Upraised, furry ears",
"Delicate, pawed feet",
"Bright, vibrant red",
"Bold and eye-catching",
"Mysterious and alluring",
"Passionate and fiery",
"Sensual and seductive",
"Vibrant and alive",
"Rich, deep red color",
"Thick, luxurious fur",
"Sleek, elegant lines",
"Powerful, graceful movement",
"Intelligent, expressive eyes",
"Playful, affectionate nature",
"Quiet, gentle demeanour",
"Flowing lines and a sleek body",
]

export const sableSoundWords = [
    "sobbing",
    "wailing",
    "moaning",
    "howling",
    "caterwauling",
    "yelping",
    "shrieking",
    "screaming",
    "yowling",
    "bellowing",
    "Snarling",
    "Spitting",
    "Hissing",
    "Growling",
    "Chomping",
    "Whispering",
    "scampering",
    "pattering",
    "rustling",
    "tapping",
    "clattering",
    "scurrying",
    "lumbering",
    "tromping",
    "whining",
    "mewling",
    "yelping",
    "screeching",
    "caterwauling",
    "baying",
    "bellowing",
    "roaring",
    "moaning",
    "groaning",
    "Whirring",
    "beeping",
    "chirping",
    "whizzing",
    "crackling",
    "hissing",
    "whooshing",
    "glaring",
    "rumbling"
]

export const sableFurWords = [
    "glossy",
    "smooth",
    "rich",
    "deep",
    "velvety",
    "supple",
    "soft",
    "silky",
    "shining",
    "lustrous",
    "voluptuous",
    "smooth",
    "creamy",
    "lush",
    "teased",
    "flowing",
    "tangled",
    "unkempt",
    "wild",
    "thick",
    "Crimson",
    "Scarlet",
    "Ruby",
    "Garnet",
    "Wine",
    "Burgundy",
    "Fuchsia",
    "Magenta",
    "Rose",
    "peach",
    "Nubile",
    "russet",
    "ginger",
    "auburn",
    "carmine",
    "cerise",
    "crimson",
    "garnet",
    "maroon",
    "rose",
    "Fiery",
    "passionate",
    "intense",
    "bold",
    "powerful",
    "bright",
    "beautiful",
    "stunning",
    "luxurious",
    "alluring"
]

export const sableSmellWords = [
    "smokey",
    "musky",
    "woody",
    "amber",
    "sweet",
    "dry",
    "resinous",
    "spicy",
    "balsamic",
    "earthy",
    "metallic",
    "pungent",
    "rank",
    "fetid",
    "musty",
    "lousy",
    "abhorrent",
    "ghastly",
    "pungent",
    "piquant",
    "musky",
    "smoky",
    "woody",
    "amber",
    "balsamic",
    "spicy",
    "oriental",
    "Earthy",
    "Woody",
    "Spicy",
    "gamey",
    "musky",
    "fruity"
]

export const sableBehaviourSentences = [
"it is panting heavily",
"it is drooling",
"it is lunging",
"it is exposing its stomach",
"it is opening its mouth wide",
"it is making a low, rumbling noise in its throat",
"it is glaring at me with its bright red eyes",
"it is baring its teeth",
]