import React, { Component, useState } from "react";
import { connect } from 'react-redux'
import './KeyboardMenu.css';

import { logOutAsync, startNewGame } from '../../redux/actions/user.actions';

class KeyboardMenu extends React.Component {

	state = {
        handleNavigation: this.handleNavigation.bind(this),
        selectedIndex: 0
    }

	constructor() {
		super();
	}

	componentDidMount() {
		window.addEventListener('keydown', this.state.handleNavigation)
	}

    componentWillUnmount() {
        window.removeEventListener('keydown', this.state.handleNavigation)
    }

	handleNavigation(event) {
		if (event.key === 'ArrowDown') {
			let newIndex = this.state.selectedIndex + 1;

			if (newIndex < 0) {
				newIndex = 0;
			}

			if (newIndex === this.props.menuItems.length) {
				newIndex = this.props.menuItems.length - 1;
			}

			let previousIndex;

			while(newIndex !== previousIndex) {
				previousIndex = newIndex;
				if (this.props.menuItems[newIndex].isDisabled) {
					newIndex += 1;
				}

				if (this.props.menuItems[newIndex].text === ' ') {
					newIndex += 1;
				}
			}

			this.setState({
				selectedIndex: newIndex
			})
		}

		if (event.key === 'ArrowUp') {
			let newIndex = this.state.selectedIndex - 1;

			if (newIndex < 0) {
				newIndex = 0;
			}

			if (newIndex === this.props.menuItems.length) {
				newIndex = this.props.menuItems.length - 1;
			}

			let previousIndex;

			while(newIndex !== previousIndex) {
				previousIndex = newIndex;
				if (this.props.menuItems[newIndex].isDisabled) {
					newIndex -= 1;
				}

				if (this.props.menuItems[newIndex].text === ' ') {
					newIndex -= 1;
				}
			}

			this.setState({
				selectedIndex: newIndex
			})
		}

		if (event.key === ' ') {
			this.props.menuItems[this.state.selectedIndex].callback();
		}

		const navigationOption = this.props.menuItems.find(menuItem => (menuItem.hotkey === event.key));

		if (navigationOption) {
			navigationOption.callback();
		}
	}

	continue(command, print) {
		console.log('continue');
	}

	settings(command, print) {
		console.log('settings');
	}

	logout(command, print) {
        localStorage['feathers-jwt'] = null;
        this.props.logOutAsync();
        window.location.reload();
	}

	render() {
		const menuItems = this.props.menuItems.map((menuItem, index) => {
			return (
				<li key={index}>
					<span className={this.state.selectedIndex === index ? "selected" : ""}>
						{
							menuItem.text[0] === '[' ?
								(
									<span>
										{menuItem.text[0]}
										<span className="action">{menuItem.text[1]}</span>
										{menuItem.text.slice(2)}
									</span>
								) :
								(
									<span className={menuItem.isDisabled ? "disabled" : ""}>
										<span className="action">{menuItem.text[0]}</span>
										{menuItem.text.slice(1)}
									</span>
								)
						}
						&nbsp;
					</span>
				</li>
			)
		})
	    return (
	    	<ol className="options">
	    		{ menuItems }
	    	</ol>
	    );
	}
}

const mapStateToProps = state => {
    return {  }
}

export default connect(
    mapStateToProps,
    {
    	logOutAsync,
    	startNewGame
    }
)(KeyboardMenu);