export const thistlewoodSmellTypes = [
	"sweet",
    "bitter",
    "floral",
    "woody",
    "nutty",
    "earthy",
    "green",
    "vegetal",
    "floral",
    "nutty",
    "vegetal",
    "herbaceous",
    "green",
    "resinous",
    "balsamic",
    "nutty",
    "of malt",
    "toasty",
    "of caramel",
    "of vanilla",
    "fruity",
    "woody",
    "of plagued bark",
    "of capsicum-tipped thorns",
    "narcotic",
    "of subtle lantana",
    "of meadowlands after a downpour",
    "of throat-healing tinctures",
    "of raspy papyrus",
    "of mossy surfaces",
    "of lavendar and tonka",
    "of nighttime brugmansia",
    "of assertive florals",
    "of fecal matter",
    "of styrax and cocoa",
    "of botanical musk",
    "of puberty",
    "of burning leaves",
    "of sarsaparilla",
    "of anise and almond",
    "of crushed cumin",
    "mouth-watering",
    "fragrant and soft",
    "of spiced, rotten dry fruit",
    "of tempered copper",
    "of dusty marble",
    "challenging",
    "of horse droppings",
    "of harsh pine sap",
    "sinus-clearing",
    "of a melancholy ramble",
]

export const thistlewoodBranchTypes = [
	"ruddy",
    "umber",
    "Needle covered",
    "Pod covered",
    "hairy seedpods protruding from its",
    "Thorny",
    "succulent pods in its",
    "spines on its",
    "Gnarled",
    "Bent",
    "Curvy",
    "Forked",
    "Gnarled",
    "Large",
    "Long",
    "Twisted"
    "pendulous",
    "weeping",
    "gum oozing from its",
    "wrinkled seeds on its",
    "clump-forming",
    "crimson-barked",
    "circuitous",
    "unthawed",
    "rough, prominent",
    "brimstone",
    "close-grained bark on its",
    "unstirring",
    "ragged"
    "swollen",
    "misshapen",
    "twitching",
    "arrogant",
    "burnished",
]

export const thistlewoodLeafTypes = [
	"Tall",
    "slender",
    "green",
    "pointy",
    "sharp",
    "prickly",
    "spiny",
    "thorny",
    "needle-covered",
    "Bur covered",
    "broad",
    "Flat",
    "Green",
    "Long",
    "Narrow",
    "Pointed",
    "bell",
    "cone",
    "double",
    "flag",
    "needle",
    "sickle",
    "spruce",
    "teardrop",
    "thorn",
    "tower",
    "tufted",
    "urn-shaped",
    "cauline",
    "cobweb-wrapped",
    "writhing",
]