import { GET_EMBARK_CHARACTERS_SUCCESS } from "../actionTypes";

const initialState = {
  embarkCharacters: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_EMBARK_CHARACTERS_SUCCESS: {
      const embarkCharacters = action.payload.embarkCharacters.filter(character => character.userId === action.payload.userId);

      return {
        embarkCharacters : [...embarkCharacters]
      }
    }

    default:
      return state;
  }
}
