import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_JEWELLERY_SUCCESS, GET_TRADE_JEWELLERY_SUCCESS, ADD_JEWELLERY, UPDATE_JEWELLERY, REMOVE_JEWELLERY, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  characterJewellery: [],
  tileJewellery: [],
  tradeJewellery: [],
  jewellery: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_JEWELLERY_SUCCESS: {
      const jewellery = action.payload;

      return {
        ...state,
        jewellery: [...jewellery.filter(jewelleryInstance => jewelleryInstance.panelId)],
        characterJewellery: [...state.characterJewellery, ...jewellery.filter(jewelleryInstance => jewelleryInstance.characterId)],
        tileJewellery: [...jewellery.filter(jewelleryInstance => jewelleryInstance.tileId)],
      }
    }

    case GET_TRADE_JEWELLERY_SUCCESS: {
      const jewellery = action.payload;

      return {
        ...state,
        tradeJewellery: [...jewellery],
      }
    }

    case ADD_JEWELLERY: {
      let characterJewellery = [...state.characterJewellery];
      let tileJewellery = [...state.tileJewellery];
      let jewellery = [...state.jewellery];

      if (action.payload.characterId) {
        characterJewellery = [ ...characterJewellery, action.payload ]
      }

      if (action.payload.tileId) {
        tileJewellery = [ ...tileJewellery, action.payload ]
      }

      if (action.payload.panelId) {
        jewellery = [ ...jewellery, action.payload ]
      }

      return {
        ...state,
        characterJewellery: [ ...characterJewellery ],
        tileJewellery: [ ...tileJewellery ],
        jewellery: [ ...jewellery ],
      }
    }

    case UPDATE_JEWELLERY: {
      let characterJewellery = [...state.characterJewellery];
      let tileJewellery = [...state.tileJewellery];
      let jewellery = [...state.jewellery];

      if (action.payload.characterId) {
        characterJewellery = [
          ...characterJewellery.filter(tool => tool._id !== action.payload._id),
          { ...characterJewellery.find(tool => tool._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.tileId) {
        tileJewellery = [
          ...tileJewellery.filter(tool => tool._id !== action.payload._id),
          { ...tileJewellery.find(tool => tool._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.panelId) {
        jewellery = [
          ...jewellery.filter(tool => tool._id !== action.payload._id),
          { ...jewellery.find(tool => tool._id === action.payload._id), ...action.payload }
        ]
      }

      return {
        ...state,
        characterJewellery: [ ...characterJewellery ],
        tileJewellery: [ ...tileJewellery ],
        jewellery: [ ...jewellery ],
      }
    }

    case REMOVE_JEWELLERY: {
      return {
        ...state,
        characterJewellery: [ ...state.characterJewellery.filter(tool => (tool._id !== action.payload._id)) ],
        tileJewellery: [ ...state.tileJewellery.filter(tool => (tool._id !== action.payload._id)) ],
        jewellery: [ ...state.jewellery.filter(tool => (tool._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
