import React from "react";
import { connect } from 'react-redux'

import client from '../../services/client';

import { logInAsync } from '../../redux/actions/user.actions';
import { resetPasswordAsync } from '../../redux/actions/account-management.actions';

class ResetPassword extends React.Component {
    constructor() {
        super();

        this.state = {
            email: "",
            password: "",
            isLogInShowing: true,
            isSignUpShowing: false,
            isFormValid: false,
            isLogInErrorShowing: false,
            isSignUpErrorShowing: false,
        };
    }

    resetPassword() {
        if (!this.state.isFormValid) {
            return;
        }

        const { password } = this.state;

        const mockedUrl = window.location.href.replace(/#\/reset/g,"");
        const url = new URL(mockedUrl);
        const token = url.searchParams.get("token");

        this.setState({ isFormValid: false })

        this.props.resetPasswordAsync({ 
            action: 'resetPwdLong',
            value: { token, password }
        })
        .then(() => {
            window.history.pushState({}, document.title, '/');
            window.location.pathname = '/';
        })
        .catch(() => (this.setState({ isLogInErrorShowing: true, isFormValid: true })))
    }

    updateInput(key, value) {
        this.setState({ [key]: value })

        setTimeout(() => {
            if (this.state.password && this.state.password.length > 5) {

                if (this.state.confirmPassword  === this.state.password) {
                    this.setState({ isFormValid: true });
                } else {
                    this.setState({ isFormValid: false });
                }
            } else {
                this.setState({ isFormValid: false });
            }
        })
    }

    render() {
        return (
            <main className="login-page">
                <div className="login">
                    <img className="intro-image" src="assets/landingpage-2.png"/>

                    <div className="login-main-bar main-bar">
                        <input
                            className="password"
                            onChange={e => this.updateInput('password', e.target.value)}
                            value={this.state.password}
                            placeholder="password"
                            type="password"/>

                        <input
                            className="password"
                            onChange={e => this.updateInput('confirmPassword', e.target.value)}
                            value={this.state.confirmPassword}
                            placeholder="confirm password"
                            type="password"/>

                        <div className="buttons">
                            <span
                                className={this.state.isFormValid ? "login-button" : "disabled-button"}
                                onClick={() => this.resetPassword()}>
                                reset password
                            </span>
                        </div>
                    </div>

                    <div className={this.state.isLogInErrorShowing ? "login-error" : "login-error hidden"}>
                        Reset token has expired, please reset password again
                    </div>
                </div>
            </main>
        );
    }
}

export default connect(
    null,
    { resetPasswordAsync }
)(ResetPassword);