export const mackerelFaceSentences = [
    "Finely textured skin with a satin sheen",
"Well-defined features with a strong jawline",
"High cheekbones and a sharp nose",
"Full, pouty lips",
"Intense, dark eyes",
"unerring gaze, dark eyes, curving mouth, hooked nose, high forehead, bristling eyebrows, weather-beaten skin, sharp chin, hollow cheeks",
"Eyes like cold, black beads.",
"A face wrinkled and weathered like the waves it swims in.",
"A long, sharp nose hooked like a dagger.",
"A mouth full of sharp, glistening teeth.",
"A long, sleek face with an angular jawline.",
"Prominent, almond-shaped eyes that shimmer in the light.",
"A glossy, midnight black complexion with a smooth, velvety texture.",
"Full, plump lips that are always slightly parted.",
"Deep black eyes that seem to suck you in.",
"A long, sleek nose that curves slightly at the end.",
"Full, pouty lips that look inviting to kiss.",
"High, defined cheekbones that give the face an angular look."
]

export const mackerelBodySentences = [
    "It's sleek and long, with a bulbous head and beady eyes.",
"It has a dark blue-green back, with silver sides and a white belly.",
"There's a distinctive dark stripe running along its back.",
"it's body is long and black.",
"It has a slender body with small scales.",
"it's body is covered in a black mucous.",
"It has a long, pointed nose.",
"Glistening black scales",
"Wet and slimy to the touch",
"Sharp, pointy fins",
"A long, slender body",
"Two small, beady eyes",
"A large, gaping mouth",
"A forked tongue",
"it's body is long and slender, with a tapered belly and a long, forked tail.",
"It is covered in small, iridescent scales that shimmer in the light.",
"The fish's back is a deep, dark blue, while its sides are a silver-grey.",
"jet black body",
"iridescent scales",
"long and sleek body",
"pointy snout",
"top predators in the ocean",
"iconic 'V' shaped fins",
"torpedo shaped body",
]

export const mackerelSoundWords = [
    "slithering",
    "shimmering",
    "wriggling",
    "squirming",
    "snapping",
    "flopping",
    "slapping",
    "smacking",
    "crashing",
    "thrashing",
    "Hissing",
    "Sizzling",
    "Sputtering",
    "crackling",
    "macking",
    "Slapping",
    "Flicking",
    "Jittering",
    "Beating",
    "Banging",
    "Hissing",
    "Slithering",
    "Gliding",
    "Sliding",
    "Pushing",
    "Sneaking",
    "Crawling",
    "Darting",
    "Lurking",
    "Stalking"
]

export const mackerelFurWords = [
    "glossy",
    "sheeny",
    "inky",
    "velvety",
    "sooty",
    "pitch-black",
    "coal-black",
    "ebony",
    "sable",
    "jet-black",
    "glossy",
    "rich",
    "deep",
    "inky",
    "lustrous",
    "midnight",
    "obsidian",
    "onyx",
    "raven",
    "sable",
    "Glossy",
    "Sleek",
    "Shiny",
    "Soft",
    "Supple",
    "Silky",
    "murky",
    "inky",
    "shadowy",
    "dusky",
    "swarthy",
    "dark",
    "black",
    "midnight",
    "ebon",
    "sable"
]

export const mackerelSmellWords = [
    "Pungent",
    "Smelly",
    "Dirty",
    "Stinky",
    "Fishy",
    "Nasty",
    "Reeking",
    "Pungeant",
    "Malodorous",
    "Foul",
    "Wet",
    "Oily",
    "Fishy",
    "Salty",
    "Putrid",
    "Stench",
    "Rot",
    "Nauseating",
    "Pungent",
    "Reeking",
    "Pungent",
    "Ammonia-like",
    "Fishy",
    "Salty",
    "Musky",
    "Earthy",
    "Wet",
    "Slippery",
    "Slimy",
    "Decaying",
    "pungent",
    "rank",
    "vinegary",
    "fishy",
    "salty",
    "sea-weedy",
    "squalid",
    "odious",
    "noisome",
    "fetid",
    "Smelly",
    "Rotten",
    "Stinky",
    "Nasty",
    "Foul",
    "Disgusting",
    "putrid",
    "malodorous",
    "bury",
    "stench"
]

export const mackerelBehaviourSentences = [
    "it is staring at you with its beady little eyes",
    "it is making strange noises",
    "it is thrashing about wildly",
    "it is foaming at the mouth",
    "it is watching you from the shadows",
    "it is stalking you",
    "it is always in my nightmares",
    "it is following you",
    "it is standing in the middle of the road",
    "it is watching us right now",
    "it is following us",
    "it is stalking us",
    "it is trying to intimidate us",
    "it is staring at you",
    "it is following you",
    "it is making strange noises",
    "it is behaving oddly",
    "it is making you feel uneasy",
    "it is unraveling",
    "it is deflating",
    "it is sprouting tentacles",
    "it is youlting",
    "it is vomiting black liquid",
    "it is levitating",
]