import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import { disableKeyboardMovement, enableKeyboardMovement, showHelp, startLooking, hideAllMenus } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectCharacter,
     selectEventMessages
} from '../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';

import { client } from '../../services/client';

import './Events.css';

import Menu from '../utils/menu/Menu';

const EventTypes = {
    TALK_TO_WORLD: 'TALK_TO_WORLD',
    TALK_TO_CHARACTER: 'TALK_TO_CHARACTER',
    TALK_TO_FURNITURE: 'TALK_TO_FURNITURE',
    LEAVING: 'LEAVING',
    ENTERING: 'ENTERING',
    FIGHTING: 'FIGHTING',
    ROTTING_ITEM: 'ROTTING_ITEM',
    POINTING: 'POINTING',
    GIVING_ITEM: 'GIVING_ITEM',
    TRADE_OFFER: 'TRADE_OFFER'
}

class Events extends React.Component {
    state = {
        preferences: [
            EventTypes.TALK_TO_WORLD,
            EventTypes.TALK_TO_CHARACTER,
            EventTypes.TALK_TO_FURNITURE,
            EventTypes.LEAVING,
            EventTypes.ENTERING,
            EventTypes.FIGHTING,
            EventTypes.ROTTING_ITEM,
            EventTypes.POINTING,
            EventTypes.GIVING_ITEM,
            EventTypes.TRADE_OFFER,
        ],
        navigationHandler: this.navigationHandler.bind(this),
        top: 0
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.state.navigationHandler)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.state.navigationHandler);
    }

    getMoreMessages() {
        if (this.state.isGettingMore) {
            return;
        }

        this.setState({
            isGettingMore: true
        })

        store.dispatch(getMoreMessagesAsync({
            characterId: this.props.character._id,
            skip: this.props.messages.length
        }))
            .finally(() => {
                this.setState({
                    isGettingMore: false
                })
            })
    }

    isEventsListAtTop() {
        if (this.state.top >= 0) {
            return true;
        }

        return false
    }

    isEventsListAtBottom() {
        const STEP_SIZE = 40;
        const eventsContainer = document.getElementsByClassName('events-inner')[0]

        if (!eventsContainer) {
            return false;
        }

        if (this.state.top * -1 > eventsContainer.clientHeight - (2 * STEP_SIZE)) {
            return true
        }

        return false
    }

    navigationHandler(event) {
        const STEP_SIZE = 40;

        if (event.key === 'ArrowDown') {
            if (this.isEventsListAtBottom()) {
                return
            }
            
            this.setState({
                top: this.state.top - STEP_SIZE
            })

            this.getMoreMessages();

            return;
        }

        if (event.key === 'ArrowUp') {
            if (this.isEventsListAtTop()) {
                return;
            }
            // find top element and move it below visible frame
            this.setState({
                top: this.state.top + STEP_SIZE
            })

            return;
        }


        if (event.key === 'T' || event.key === 't') {
            this.setState({
                top: 0
            })
            this.addOrRemovePrefence(EventTypes.TALK_TO_WORLD)
        }

        if (event.key === 'C' || event.key === 'c') {
            this.setState({
                top: 0
            })
            this.addOrRemovePrefence(EventTypes.TALK_TO_CHARACTER)
        }

        if (event.key === 'F' || event.key === 'f') {
            this.setState({
                top: 0
            })
            this.addOrRemovePrefence(EventTypes.TALK_TO_FURNITURE)
        }

        if (event.key === 'L' || event.key === 'l') {
            this.setState({
                top: 0
            })
            this.addOrRemovePrefence(EventTypes.LEAVING)
        }

        if (event.key === 'A' || event.key === 'a') {
            this.setState({
                top: 0
            })
            this.addOrRemovePrefence(EventTypes.ENTERING)
        }

        if (event.key === 'X' || event.key === 'x') {
            this.setState({
                top: 0
            })
            this.addOrRemovePrefence(EventTypes.FIGHTING)
        }

        if (event.key === 'R' || event.key === 'r') {
            this.setState({
                top: 0
            })
            this.addOrRemovePrefence(EventTypes.ROTTING_ITEM)
        }

        if (event.key === 'P' || event.key === 'p') {
            this.setState({
                top: 0
            })
            this.addOrRemovePrefence(EventTypes.POINTING)
        }

        if (event.key === 'G' || event.key === 'g') {
            this.setState({
                top: 0
            })
            this.addOrRemovePrefence(EventTypes.GIVING_ITEM)
        }

        if (event.key === 'O' || event.key === 'o') {
            this.setState({
                top: 0
            })
            this.addOrRemovePrefence(EventTypes.TRADE_OFFER)
        }
    }

    addOrRemovePrefence(preference) {

        if (this.state.preferences.find(currentPreference => currentPreference === preference)) {
            this.setState({
                preferences: [ ...this.state.preferences.filter(currentPreference => (currentPreference !== preference)) ]
            })
        } else {
            this.setState({
                preferences: [
                    ...this.state.preferences,
                    preference
                ]
            })
        }
    }

    formatDistance(result, number) {
        result = result.replace('Minutes', 'm');
        result = result.replace('Hours', 'h');
        result = result.replace('Days', 'd');
        result = result.replace('Months', 'mo');
        result = result.replace('Years', 'y');

        result = result.replace('lessThan', '<')
        result = result.replace('about', '');
        result = result.replace('over', '>');

        result = result.replace('x', number)
        result = result.replace('X', number)

        return result;
    }

    isActivePreference(preference) {
        return this.state.preferences.find(currentPreference => currentPreference === preference);
    }

    render() {
        const filteredMessages = [ ...this.props.messages ]?.filter(message => (this.state.preferences.indexOf(message.type) !== -1))

        const messages = filteredMessages.map((message, index) => {
            const characterName = message.characterName
            let targetCharacterName = message.targetCharacterName;

            if (!targetCharacterName) {
                targetCharacterName = 'Unknown';
            }

            return (
                <div className="event" key={index}>
                    <p>
                        <span className="event-date">
                            &nbsp;{formatDistance(new Date(message.createdAt), new Date(), { locale: { formatDistance: this.formatDistance.bind(this) } })}&nbsp;
                        </span>
                        <span className={message.type === "TALK_TO_CHARACTER" ? "" : "hidden"}>
                            {characterName} said to {targetCharacterName} <span className="shortcut">"{message.text}"</span>
                        </span>
                        <span className={(message.type === "TALK_TO_WORLD" && characterName) ? "" : "hidden"}>
                            {characterName} said <span className="shortcut">"{message.text}"</span>
                        </span>
                        <span className={(message.type === "TALK_TO_WORLD" && !characterName) ? "" : "hidden"}>
                            <span className="system-message-text">{message.text}</span>
                        </span>
                        <span className={(message.type === "TALK_TO_CHARACTER" || message.type === "TALK_TO_WORLD") ? "hidden" : ""}>
                            {message.text}
                        </span>
                    </p>
                </div>
            )
        })
        return (
            <div className="events-component">
                <div className="events-inner" style={{'top': this.state.top}}>
                    {messages}
                </div>

                <div className="events-arrows">
                    <div className={this.isEventsListAtTop() ? "arrow-up disabled" : "arrow-up"}>^</div>
                    <div className={this.isEventsListAtBottom() ? "arrow-down disabled" : "arrow-down"}>^</div>
                </div>

                <div className="events-filters">
                    <span><span className={this.isActivePreference(EventTypes.TALK_TO_WORLD) ? "shortcut" : "disabled"}>T</span> Show town chats</span>
                    <span><span className={this.isActivePreference(EventTypes.TALK_TO_CHARACTER) ? "shortcut" : "disabled"}>C</span> Show private chats</span>
                    <span><span className={this.isActivePreference(EventTypes.TALK_TO_FURNITURE) ? "shortcut" : "disabled"}>F</span> Show furniture chats</span>
                    <span><span className={this.isActivePreference(EventTypes.LEAVING) ? "shortcut" : "disabled"}>L</span> Show people leaving</span>
                    <span><span className={this.isActivePreference(EventTypes.ENTERING) ? "shortcut" : "disabled"}>A</span> Show people arriving</span>
                    <span><span className={this.isActivePreference(EventTypes.FIGHTING) ? "shortcut" : "disabled"}>X</span> Show fighting</span>
                    <span><span className={this.isActivePreference(EventTypes.ROTTING_ITEM) ? "shortcut" : "disabled"}>R</span> Show rotting items</span>
                    <span><span className={this.isActivePreference(EventTypes.POINTING) ? "shortcut" : "disabled"}>P</span> Show pointing</span>
                    <span><span className={this.isActivePreference(EventTypes.GIVING_ITEM) ? "shortcut" : "disabled"}>G</span> Show giving items</span>
                    <span><span className={this.isActivePreference(EventTypes.TRADE_OFFER) ? "shortcut" : "disabled"}>O</span> Show trade offers</span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const character = selectCharacter(state)
    const messages = selectEventMessages(state);

    return {
        character,
        messages
    }
}

export default connect(
    mapStateToProps,
    { hideAllMenus, getMoreMessagesAsync }
)(Events);