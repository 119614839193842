export const sheepFaceSentences = [
"it has a soft, fluffy face.",
"it has a gentle face.",
"it has a serene face.",
"it has a surprised face.",
"it has a distrustful face.",
"it has a tired face",
"White fur with black spots",
"Large, floppy ears",
"A black nose",
"Dark eyes",
"it has a black face.",
"it has a long neck.",
"it has small eyes.",
"it has big ears.",
"it has a short nose.",
"Puffy white cheeks",
"A black nose",
"Gentle eyes",
"Fluffy ears",
"A soft coat",
"A wobbling walk",
"Eating grass",
]

export const sheepBodySentences = [
"Stout, stocky body ",
"Proud head and curved horns ",
"Petite hooves ",
"Lively eyes ",
"it has with black spots",
"it has with long wool",
"it's body is covered in wool.",
"it has a protruding belly.",
"it has four legs.",
"it has two ears.",
"it has a small head.",
"it has black eyes.",
]

export const sheepSoundWords = [
        "Baaing",
    "Bleating",
    "Braying",
    "Calling",
    "Cheeping",
    "Chirping",
    "Clucking",
    "Cooing",
    "Crowing",
    "Cackling",
    "nose-snuffling",
    "heavy-breathing",
    "head-butting",
    "wool-ruffling",
    "tail-swishing",
    "hoof-tapping",
    "grass-munching",
    "water-slurping",
    "mud-squelching",
    "contented-bleating",
    "Baaing",
    "Bleating",
    "Braying",
    "Bugling",
    "Calling",
    "Clucking",
    "grunting",
    "lowing",
    "moaning",
    "bleating",
    "baaing",
    "bleating",
    "lambing",
    "bleating",
    "cud-chewing",
    "drowsing",
    "nibbling",
    "grazing"
]

export const sheepFurWords = [
"Shearling",
"Fleece",
"Downy",
"Fluffy",
"Woolly",
"hairy",
"unshorn",
"hairy",
"Soft",
]

export const sheepSmellWords = [
"Foul",
"Stench",
"Pungent",
"Reeking",
"Odorous",
"Nauseating",
"Intolerable",
"Fetid",
"Disgusting",
"Repulsive",
"putrid",
"rank",
"stinking",
"fetid",
"reeking",
"pungent",
"mephitic",
"foul",
"malodorous",
]

export const sheepBehaviourSentences = [
"it is shaking",
"it is sweating",
"it is breathing heavily",
"it is bald",
"it is blind",
"it is blue",
"it is Brown",
"it is deaf",
"it is drab",
"it is fat",
"it is gloomy",
"it is giving you the stink eye",
"it is baa-ing loudly",
]