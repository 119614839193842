import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_CRIMINALS_SUCCESS, ADD_CRIMINAL, UPDATE_CRIMINAL, REMOVE_CRIMINAL, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  criminals: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_CRIMINALS_SUCCESS: {
      const criminals = action.payload;

      return {
        ...state,
        criminals: [...criminals],
      }
    }

    case ADD_CRIMINAL: {
      let criminals = [...state.criminals];

      criminals = [ ...criminals, action.payload ]

      return {
        ...state,
        criminals: [ ...criminals ],
      }
    }

    case UPDATE_CRIMINAL: {
      let criminals = [...state.criminals];

      criminals = [
        ...criminals.filter(tool => tool._id !== action.payload._id),
        { ...criminals.find(tool => tool._id === action.payload._id), ...action.payload }
      ]

      return {
        ...state,
        criminals: [ ...criminals ],
      }
    }

    case REMOVE_CRIMINAL: {
      return {
        ...state,
        criminals: [ ...state.criminals.filter(tool => (tool._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
