export const turnipSmellTypes = [
	"of burnt paper",
    "of moth balls",
    "of copper",
    "of ginger",
    "of garlic",
    "of mustard",
    "tuberose",
    "of mouldy bread",
    "sickly sweet",
    "of rotting cabbage",
    "of vinegar",
    "of wet dog",
    "of damp clothes",
    "of urine",
    "of carrot",
    "of wet dog",
    "of damp earth",
    "of freshly cut grass",
    "of cabbage",
    "of stewed beans",
    "of waterlogged wood",
    "of damp leaves",
    "of mushrooms",
    "of sulphur",
    "earthy",
    "of rotting vegetation",
    "of compost",
    "of manure",
    "of animal carcass",
    "sickly sweet",
    "dank and moist",
    "charred",
    "of burnt toast",
    "of rotting food",
    "of stale beer",
    "pungent",
    "acrid",
    "of ammonia",
    "of repetitive strains",
    "of rosey roots",
    "of spiced vine fruits",
    "of cold, arrange flowers",
    "of peppery beetroot",
    "of overturned stone",
    "quiet and contemplative",
    "of green rosebuds",
    "of spicy sweets",
    "astringent",
    "of soup from a coffin",
    "of an impression of reeds",
    "of game afoot",
]

export const turnipBranchTypes = [
	"black mud on its",
    "green",
    "Gnarled",
    "Barren",
    "Dead",
    "Desolate",
    "Dry and crusty",
    "Mossy and slimy",
    "Rotten and stinking",
    "bugs crawling all over its",
    "proud",
    "delicious-looking",
    "protective",
    "abundant",
    "spindly",
    "creature-chewn",
    "inflorescent",
    "clustered flowers on its",
    "blighted",
    "splitting",
    "larvae-infested",
    "girthy",
    "sugar-rich",
    "brittle",
    "smooth and leafy",
    "corky",
    "clickbeetles on its",
]

export const turnipLeafTypes = [
	"unique",
    "Valuable",
    "beautiful",
    "vibrantly lime",
    "russet",
    "sepia",
    "umber",
    "Russet",
    "Weird",
    "aromatic",
    "delicately arranged",
    "vigorous",
    "strong",
    "sparse",
    "cylindrical",
    "blunt",
    "lanceolate",
    "ovate",
    "robust",
    "white blisters on its",
    "unhealthy",
    "wilted",
    "flies congregating on its",
]