export const crustyTreeSmellTypes = [
   "of a Bouquet of dried flowers",
    "of Chlorine",
    "of Diesel",
    "Dusty",
    "of Eucalyptus",
    "Fruity",
    "of rot",
    "sickly",
    "morose",
    "of decay",
    "of coconut",
    "of vanilla",
    "of jasmine",
    "of plumeria",
    "of dried leaves",
    "of resin",
    "of terpentine",
    "of sap",
    "of salt",
    "of sugar",
    "of butter",
    "of flour",
    "of nutmeg",
    "of cinnamon",
    "of ginger",
    "of baking soda",
    "of a springtime evening",
    "tea-like",
    "of pears and succulents",
    "creamy and clean",
    "uplifting",
    "of rooted orris",
    "of sour bergamot",
    "of powdered orris",
    "antiseptic",
    "of a tangy, floral blush",
    "sharp and metallic",
    "biting, dusty plastic",
    "of iris and soap",
    "spellbinding",
    "of wood alcohol",
    "dry and woody",
    "of citrus and dry earth",
    "of velvety wood",
    "of floral sand",
    "musty and waxy",
    "doughy and sweet",
    "rooty",
    "chalky",
    "of blooming beauty",
    "of earthen wax",
    "of rooted mandarin",
    "suede with a mild citrus",
    "of sunny, floral hedione",
    "of starchy iris",
    "fuzzy and velveteen",
    "unhealthy",

]

export const crustyTreeBranchTypes = [
    "tough",
    "Dried",
    "Withered",
    "Dead",
    "Decaying",
    "Rotting",
    "weeping",
    "rotting",
    "steep",
    "slippery",
    "dismal",
    "useless",
    "frightening",
    "Snapping",
    "Angry",
    "Hateful",
    "desiccated",
    "Dry",
    "Gnarled",
    "Lonely",
    "parched",
    "hollow",
    "papery",
    "bark-plated",
    "thickly-plated",
    "rigid",
    "storm-worn",
    "oddly-tapered",
    "weather-beaten",
]

export const crustyTreeLeafTypes = [
    "spots on its",
    "shaking",
    "jagged-edged",
    "jigsaw",
    "tightly bunched",
    "vibrant",
    "neon",
    "wind-shaken",
    "crisp",
    "fragile",
    "flaky",
    "wrinkled",
    "dry",
    "bendable",
    "veins on its",
    "enormous",
    "overgrown",
    "sprawling",
    "congested",
    "tangled",
    "knotted",
    "bone-dry",
    "sap-sucked",
    "yellowing",
    "dead",
    "somnambulant sun-patterns cast by its"
]