export const spawnerFaceSentences = [
	"A gruesome, toothy grin",
	"A set of beady, bloodshot eyes",
	"A scaly, greenish complexion",
	"A long, snake-like tongue",
	"A set of sharp, spiky teeth",
	"A toothy maw lined with razor sharp teeth dripping with saliva",
	"Narrow, beady eyes glaring menacingly from beneath a furrowed brow",
	"A bulbous nose ringed with slimy, wriggling tentacles",
	"Pustules and boils dotting its face",
	"It has a human-like face, but with large, furry ears",
	"Its eyes are bloodshot and bulging, and its mouth is full of sharp teeth",
	"It has a long, snake-like tongue",
	"Jagged, pointy teeth",
	"Two beady, lifeless eyes",
	"Drool perpetually oozing from mouth",
	"A single nostril, crooked and flared",
	"Pockmarked and bulbous nose",
	"Slitted, pupil-less eyes that glow a eerie green"
]

export const spawnerBodySentences = [
	"This hulking beast has a body that is covered in thick, scaly skin.",
"Its large, muscular frame is filled with an immense amount of power, and it seems to exude an aura of strength and danger.",
"Covered in spikes and oozing with slime.",
"Its enormous maw was lined with razor sharp teeth, and it drooled",
"Repulsive, oozing sores cover its body.",
"Clusters of sharp spines protrude from its back.",
"Mucous-covered plates of thick,armor-like skin ",
"A long, barbed tail ",
"Spiked, muscular legs",
]

export const spawnerSoundWords = [
	    "Hissing",
    "roaring",
    "screeching",
    "growling",
    "snarling",
    "deranged",
    "feral",
    "guttural",
    "animalistic",
    "monstrous",
    "blasts",
    "bites",
    "booms",
    "burps",
    "cackles",
    "chews",
    "clanging",
    "screeches",
    "stomps",
    "scaly",
    "reptilian",
    "deathly",
    "thundering",
    "teeth-gnashing",
    "gut-wrenching",
    "ear-splitting",
    "bellowing",
    "bellicose",
    "blusterous",
    "boisterous",
    "clamorous",
    "droning",
    "howling",
    "roaring",
    "rumbling",
    "thunderous",
    "belching",
    "burping",
    "grunting",
    "hiccupping",
    "hissing",
    "howling",
    "moaning",
    "retching",
    "snarling",
    "growling"
]

export const spawnerFurWords = [
	"Beastly",
"Disgusting",
"Fearsome",
"Gross",
"Hideous",
"Intimidating",
"Menacing",
"Repulsive",
"Terrifying",
"Ugly",
"Hairy",
"Furry",
"Scaly",
"Slimy",
"Oozy",
"Flaky",
"crusty",
"Fuzzy",
"Woolly",
"Downy",
"Hairy",
"Scaly",
"Furry",
"Spiky",
"Slimy",
"Slippery",
"Wet",
"Dry",
"Crusty",
"Flaky",
"Beastly",
"Feral",
"Vicious",
"Wild",
"Untamed",
"Ferocious",
"Sinister",
"Menacing",
"Intimidating",
"Foreboding",
]

export const spawnerSmellWords = [
    "Pungent",
    "Putrid",
    "Rancid",
    "Stench",
    "Odor",
    "Nauseating",
    "Reeking",
    "Foul",
    "Fetid",
    "Malodorous",
    "Foul",
    "Pungent",
    "Rancid",
    "Overpowering",
    "Intoxicating",
    "nauseating",
    "putrid",
    "vile",
    "disgusting",
    "wretched",
    "rank",
    "pungent",
    "fetid",
    "gamy",
    "malodorous",
    "noisome",
    "redolent",
    "reeky",
    "ripe",
    "foul",
    "Stench",
    "Rancid",
    "Reek",
    "Pungent",
    "Fetid",
    "Skanky",
    "Decayed",
    "Stale",
    "Rotten",
    "Putrid",
    "Pungent",
    "acrid",
    "fetid",
    "rank",
    "reeking",
    "stinking",
    "putrid",
    "foul",
    "malodorous",
    "nauseating"
]

export const spawnerBehaviourSentences = [
	"it is watching you with its intelligent eyes",
"it is stalking you with its deadly claws",
"it is following you with its hungry mouth",
"it is slithering towards you with its slimy body",
"it is watching you with its beady eyes",
"it is drooling saliva from its mouth",
"it is grunting and snorting",
"it is screeching and flailing its arms",
"it is foaming at the mouth",
"it dribbles incessantly from its mouth",
"it is almost impossible to look at directly",
"it is watching you with its beady eyes",
"it is licking its lips",
"it is drooling",
"it is foaming at the mouth",
"it is panting heavily"
]