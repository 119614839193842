export const barleySmellTypes = [
    "malty",
    "sweet",
    "toasty",
    "nutty",
    "caramelly",
    "grainy",
    "earthy",
    "bready",
    "robust",
    "nutty",
    "toasty",
    "earthy",
    "malty",
    "biscuity",,
    "grainy",
    "haylike",
    "nutty",
    "toasty",
    "caramelly",
    "toffee-like",
    "of burnt sugar",
    "caramelly",
    "toasty",
    "nutty",
    "malted",
    "grainy",
    "of cereal",
    "dry",
    "bitter",
    "nutty",
    "malted",
    "toasty",
    "grainy",
    "earthy",
    "bready",
    "yeasty",
    "herbal",
    "of warmth and almond",
    "dry and chalky",
    "of dessicated tartness",
    "of sweet, crumbly cereal",
    "powdery and airy",
    "of plow and land",
    "demure",
    "sour and sweet",
    "of insect repellant",
    "of dandelion",
    "of stepping into the warmth",
    "of a mill",
    "of dreamy fields",
    "of a sweet and musky jam",
    "vomit-inducing",
    "sickening",
    "of earthy wildflowers",
    "of the sharp, blue sky",
    "of distilled grains",
    "of tangy fruit and powder",
    "of sheltering grains",
    "of the grains of home",
    "of powder of the earth and dirt",
    "of fermented yeast",
    "of fermentation",
]

export const barleyBranchTypes = [
    "russet",
    "lissome",
    "leafy",
    "laciniate",
    "pliant",
    "glaucous",
    "downy",
    "tawny",
    "umbrinous",
    "russet",
    "hushed",
    "drowned",
    "tarnished",
    "remote",
    "wooden",
    "shrunken",
    "stalled",
    "brown",
    "russet",
    "golden",
    "straw-coloured",
    "sun-kissed",
    "honeyed",
    "malted",
    "buttery",
    "toasty",
    "nutty",
    "gentle",
    "fragrant",
    "intoxicating",
    "luxurious",
    "gleaming",
    "translucent",
    "honey-hued",
    "honied",
    "amber",
    "stolen",
    "sunrise",
    "daffodil",
    "butterscotch"
    "bristly awns and two-row",
    "spiky awns and strong straw",
    "long rachilla hairs with resistant",
    "hints of spot blotch with stiff straw",
    "protein-rich yield with smooth awns and plump kernels along its",
    "a nodding head with semismooth awns and cream",
    "soft awns heavy with dew and parchment-coloured",
    "downy awns which remind you of a pet you once kept and rough",
    "a movement like sea spray in the field and six-row",
    "fed life for thousands of years and carries the weight on its",
    "brittle spikes defending its seeds and yielding",
    "a quiet prescence with coarse",
    "shattering spikes with unyielding",
    "round, river-fed",
    "upheld civilisations with its",
    "headstrong grains with upright",
    "stem-rusted",
    "drooping leaves and bristly"
]

export const barleyLeafTypes = []