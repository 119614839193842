export const elephantGrassSmellTypes = [
    "of Dry grass",
    "of Sandy grass",
    "of pipe tobacco",
    "of hay",
    "of fresh-cut grass",
    "of cumin",
    "of ginger",
    "of nutmeg",
    "of eucalyptus",
    "of Straw",
    "of Dry leaves",
    "Woody",
    "Earthy",
    "Sun-baked",
    "Sugary",
    "Nutty",
    "Pungent",
    "Slightly citrusy",
    "of Behemoths",
    "musky",
    "big",    
    "wrinkled",
    "of Soot",
    "pleasant but pointless",
    "big, empty spaces",
    "of overgrown wetlands",
    "of jasmine and corn dough",
    "of fresh maize",
    "of animalic jasmine",
    "of heady white florals",
    "of steam from a jasmine tea",
    "funky and earthy",
    "of spicy nappies",
    "of a stained pistil",
    "of plastic and passionflower",
    "lively, like a full bouquet",
    "humid and lived in",
    "of smoky incense",
    "of bitter grapes and cloves",
    "ambiently woodsy",
    "of watery jasmine",
    "of flowers blooming in the woods",
    "of jungle rains",
    "of lush plants and resin",
    "wheaty and beachy",
    "bizarre",
    "salty",
    "of a jasmine with sharp teeth",
    "of turqouise and dangerous rivers",
    "of flowers in monsoon season",
    "of vines and heavy rain",
    "of dewy soap"
    "of wet and meaty almond",
    "of wet, sun-baked rocks",
    "of rot and bathwater",
    "of old tea leaves",
]

export const elephantGrassBranchTypes = [
]

export const elephantGrassLeafTypes = [
	"towering",
    "coarse",
    "masted",
    "tall",
    "lofty",
    "cloud-scraping",
    "steep",
    "soaring",
    "large",
    "tough",
    "sharp",
    "sun-scraping",
    "tan",
    "golden",
    "hefty",
    "substantial",
    "sturdy",
    "spear-point",
    "green",
    "narrow",
    "pointy",
    "sharp",
    "flexible",
    "sturdy",
    "weak",
    "flimsy",
    "swooshing",
    "soughing",
    "tufted",
    "Goldenrod",
    "hair like",
    "stringy",
    "wispy",
    "fluffy",
    "downy",
    "twiggy",
    "grassy",
    "leafy",
    "woody",
    "stalky",
    "beige",
    "brittle",
    "twisted",
    "yellow",
    "fluffy",
    "domineering",
    "leering",
    "aggressive",
    "hardy",
    "reckless",
    "sword",
    "tufted",
    "hairy tufts on its",
    "feathery plumes on its",
    "upright",
    "hollow",
    "crushing roots and long",
    "powerful roots and spearing",
    "reed-like",
]