import { GET_WORKSHOP_TYPES_LOADING, GET_WORKSHOP_TYPES_SUCCESS } from '../actionTypes';
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';

export const getWorkshopTypesSuccess = payload => {
    return ({
        type: GET_WORKSHOP_TYPES_SUCCESS,
        payload
    })
}

export const getWorkshopTypesAsync = payload => dispatch => {
    return client.service('workshop-types').find()
		.then((response) => {
            dispatch(getWorkshopTypesSuccess(response));
            return response
		})
        .catch((error) => {
            console.error('error', error);
        })
           
}