import React, { useCallback, useMemo, useState, useEffect } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, useReadOnly, Slate } from 'slate-react'
import { diffWords, diffChars } from 'diff';

import {
  Editor,
  Transforms,
  createEditor,
  Descendant,
  Element as SlateElement,
} from 'slate'
import { withHistory } from 'slate-history'
import './EditWritingSurface.css';

const HOTKEYS = {
}

const EXIT = 'shift+enter';
const CLOSE = 'cmd+UpArrow'

const LIST_TYPES = ['numbered-list', 'bulleted-list']

// Define a deserializing function that takes a string and returns a value.
const deserialize = string => {
  // Return a value array of children derived by splitting the string.
  return string.split('\n').map(line => {
    return {
      children: [{ text: line }],
    }
  })
}

const serialize = value => {
  return (
    value
      // Return the string content of each paragraph in the value's children.
      .map(n => Node.string(n))
      // Join them all with line breaks denoting paragraphs.
      .join('\n')
  )
}

const isAboveWritingSurfaceLength = (value, props) => {
  let numberOfLines = 0;
  let lengthOfLastLine = 0;

  const maxNumberOfLines = Number(props.height.split('calc(')[1].split('*')[0]);
  const charactersPerLine = Math.floor(Number(props.width.split('ch')[0]));

  if (!value) {
    return;
  }

  value.forEach(({ children }) => {
    const child = children.reduce((childString, child) => {
      childString += child.text
      return childString;
    }, '');

    const childLength = child.length

    if (childLength === 0) {
      numberOfLines++;
    }

    numberOfLines += Math.ceil(childLength / charactersPerLine)

    lengthOfLastLine = childLength % charactersPerLine
  })

  if (numberOfLines > maxNumberOfLines) {
    return true;
  }

  if (numberOfLines === maxNumberOfLines) {
    return lengthOfLastLine >= (charactersPerLine - 1)
  }

  return false;
}

let previousValue;
let updatedValue;

const EditWritingSurface = (props) => {
  let [value, setValue] = useState(initialValue)

  if (props.text) {
    if (typeof props.text === "string") {
      value = deserialize(props.text)
    } else {
      value = props.text;
    }
  }

  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  const readOnly = useReadOnly(() => (props.paints.length === 0))

  useMemo(() => {
     Transforms.select(editor, { offset: 0, path: [0, 0] });
  }, []);

  return (
    <div className="writing-surface"
        style={{'backgroundColor': 'var(--black)', color: 'var(--white)'}}
        onMouseDown={event => {
          event.preventDefault()
        }}
    >
      
      <Slate
        maxLength={props.maxLength}
        editor={editor}
        value={value}
        onChange={(value, event, other) => {
          const isAboveMaxLength = isAboveWritingSurfaceLength(value, props)
          if (!isAboveMaxLength) {
            setValue(value)
            updatedValue = value;
          } else {
            Transforms.select(editor, { offset: 0, path: [0, 0] })
            const lastChildIndex = editor.children.length - 1
            const lastChild = editor.children[lastChildIndex];

            const lastChildChildrenIndex = lastChild.children.length - 1
            const lastChildColor = lastChild.children[lastChildChildrenIndex].color;
            const lastChildText = lastChild.children[lastChildChildrenIndex].text;

            const newState = [ ...editor.children ]
            newState.pop();

            newState.push({
              type: 'paragraph',
              children: [{ text: lastChildText.substr(0, Math.min(lastChildText.length - 1, props.maxLength)), color: lastChildColor }]
            })

            editor.children = [ ...newState ];

            Transforms.select(editor, Editor.end(editor, []));
          }
        }}
      >
        {
          props.isReadOnly ? ('') :
          <div className="buttons" style={{'flexBasis': props.paints.length > 10 ? '74rem' : '37rem', 'gridTemplateColumns': props.paints.length > 10 ? 'auto auto' : 'auto'}}>
            {
              props.paints?.map((paint, index) => {
                HOTKEYS['mod+' + paint.materialType.shortcut] = { color: paint.materialType.colourHex };
                HOTKEYS['mod+shift+' + paint.materialType.shortcut] = { backgroundColor: paint.materialType.colourHex };
                return (
                  <div key={index}>
                    <MarkButton shortcut={paint.materialType.shortcut} hexValue={paint.materialType.colourHex} format={"color"} icon="C" />
                    <MarkButton shortcut={paint.materialType.shortcut} hexValue={paint.materialType.colourHex} format={"backgroundColor"} icon="BG" />
                  </div>
                )
              })
            }
          </div>
        }
        <div className="surface"
          style={{
            width: props.width,
            height: props.height
          }}
        >
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder=""
            readOnly={props.paints.length === 0}
            spellCheck
            autoFocus={true}
            onKeyDown={(event) => {
              if (isHotkey(EXIT, event)) {
                if (props.onSave) {
                  props.onSave(updatedValue || value);
                }

                event.preventDefault();
                return;
              }

              if (props.isReadOnly) {
                if (event.code !== 'Escape') {
                  event.preventDefault();
                }
                return;
              } else {
                if (event.code === 'Escape') {
                  if (props.onClose) {
                    props.onClose()
                  }
                }
              }

              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault()
                  const mark = HOTKEYS[hotkey]
                  const markKey = Object.keys(mark)[0];
                  const value = Object.values(mark)[0];
                  toggleMark(editor, markKey, value)
                }
              }

              const marks = Editor.marks(editor)

              const paintColor = props.paints.find(paint => (paint.materialType?.colourHex === marks?.color));
              const paintBackground = props.paints.find(paint => (paint.materialType?.colourHex === marks?.backgroundColor));

              if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown' && event.key !== 'ArrowRight'  && event.key !== 'ArrowLeft' && event.key !== 'Escape') {
                if (!paintColor) {
                  toggleMark(editor, 'color', props.paints[0].materialType?.colourHex);
                }

                if (marks.backgroundColor && !paintBackground) {
                  event.preventDefault();
                  return;
                }
              }

              if (
                props.maxLength &&
                isAboveWritingSurfaceLength(updatedValue, props)
              ) {
                event.preventDefault()
                return false
              }
            }}
          />
        </div>

        <div className="menu-actions">
          <span className="action-shortcut"><span className="shortcut">SHIFT</span> + <span className="shortcut">ENTER</span> Save</span>
          <span className={props.maxLength ? "" : "hidden"}>&nbsp;(max. <span className="hint">{props.maxLength}</span> characters)</span>
        </div>
      </Slate>

    </div>
    )
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  })
  const newProperties = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format, value) => {
  const isActive = isMarkActive(editor, format, value)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, value || true)
  }
}

const isBlockActive = (editor, format) => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    })
  )

  return !!match
}

const isMarkActive = (editor, format, value) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === value : false
}

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.backgroundColor) {
    attributes.style = { ...attributes.style, 'backgroundColor': leaf.backgroundColor || 'var(--white)' }
  }

  if (leaf.color) {
    attributes.style = { ...attributes.style, 'color': leaf.color || 'var(--white)' }
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <button
      className={isBlockActive(editor, format) ? 'active' : ''}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <span>{icon}</span>
    </button>
  )
}

const MarkButton = ({ format, icon, hexValue, shortcut }) => {
  const editor = useSlate()
  return (
    <button
      key={hexValue}
      className={isMarkActive(editor, format, hexValue) ? 'mark-button selected' : 'mark-button'}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format, hexValue || true)
      }}
    >
      <div className="color-key"
        style={{
          'backgroundColor': icon === 'BG' && hexValue || 'var(--black)',
          'color': icon === 'C' && hexValue || 'var(--white)'
        }}
      >Aa</div>
      <span><span className="action">CMD</span>&nbsp;+&nbsp;<span className={icon === 'BG' ? "action" : "hidden"}>SHIFT</span><span className={icon === 'BG' ? "" : "hidden"}> + </span><span className="action">{shortcut}</span></span>
    </button>
  )
}

const initialValue = []

export default EditWritingSurface