import React from "react";
import { connect } from 'react-redux'
import {
    selectIsFirstInitialisation
} from '../../../redux/selectors';

import { randomNumberBetween } from '../../../services/geography';

import './Loading.css';

class Loading extends React.Component {
    state = {
        loadingStrings: [
            'Sensing plants',
            'Listening for animals',
            'Feeling vibrations',
            'Checking for traps',
            'Detecting mine entrances',
            'Watching for fires',
            'Waiting for birdsong',
            'Tasting the air',
            'Sniffing for smoke',
            'Looking out for other people',
        ],
        loadingStringIndex: 0,
        interval: undefined
    }

    componentDidMount() {
        const randomStartingIndex = randomNumberBetween(this.state.loadingStrings.length - 1);
        let index = randomStartingIndex;

        this.setState({
            loadingStringIndex: index
        })

        let dotsCount = 0;
        let dotsString = ''

        const interval = setInterval(() => {
            dotsCount++;
            dotsString = ''

            if (dotsCount === 4) {
                dotsCount = 0
            }

            if (Math.random() < 0.25) {
                index++;
                dotsCount = 0;

                if (index === this.state.loadingStrings.length) {
                    index = 0;
                }

                this.setState({
                    loadingStringIndex: index
                })
            }

            for (var i=0; i < dotsCount; i++){
                dotsString += '.';
            }

            this.setState({
                dotsString
            })
        }, 500)

        setTimeout(() => {
            this.setState({
                showingState: 'showing'
            })
        })

        this.setState({
            interval,
        })
    }

    componentWillUnmount() {
        clearInterval(this.state.interval)
    }

    render() {
        return (
            this.props.isFirstInitialisation ? (
                <div className="loading-screen-container">
                    <div className={"loading-screen " + this.state.showingState}>
                        <img className="intro-image" src="assets/landingpage-2-b.png"/>
                        <div className="loading-text-container"></div>
                        <span className="loading-text">
                            {this.state.loadingStrings[this.state.loadingStringIndex]}
                            <span className="loading-dots">{this.state.dotsString}</span>
                        </span>
                    </div>
                </div>
            ) : (
                <div className={"subsequent-loading-screen " + this.state.showingState}>
                    <div className="loading-text-container"></div>
                    <span>
                        {this.state.loadingStrings[this.state.loadingStringIndex]}
                        <span className="loading-dots">{this.state.dotsString}</span>
                    </span>
                </div>
            )
        )
    }
}

const mapToStateProps = state => {
    const isFirstInitialisation = selectIsFirstInitialisation(state);

    return { isFirstInitialisation }
}

export default connect(
    mapToStateProps,
    {
        
    }
)(Loading);